<ng-container *ngIf="validUrlGenerated">
  <div class="fullPreviewHeader">
    <span class="file-name-box">     
      <span class="file-name" (click)="viewFileinfo(mediaArr[index])">
        {{mediaArr[index]?.name}}
        <img [ngbPopover]="fileInfoPopover" [outsideClick]="true" placement="auto" container="body" src="../../../../assets/1x/tabler-icon-info-circle.png"/>
      </span>
    </span>
    <!-- FILE INFO POPOVER -->
        <ng-template #fileInfoPopover>
          <div class="file-info-popover"> 
              <ng-container>
                  <span class="d-block align-middle file-info-head f-l-b">{{'drive.fileInfo' | translate}}</span> 
                  <span class="file-info" ><span class="file-info-item f-m-b">{{'drive.fileName' | translate}}</span> <span  class="file-info-item-value f-m-r"> : {{fileInfo?.name}}</span></span>
                  <span class="file-info"><span class="file-info-item">{{'drive.fileSize' | translate}}</span><span class="file-info-item-value">:  {{fileInfo?.size_in_bytes}} 
                  </span> </span> 
              </ng-container>
          </div>
      </ng-template>

    <!-- <span class="download-button" *ngIf="mediaArr[index]?.size_in_bytes!=''" style="right: 141px;">Total Size :{{mediaArr[index]?.size_in_bytes}}</span>
    <span class="download-button" (click)="setDocumentUrl(mediaArr[index],index)">Download</span> -->
    <div class="download-button">
    <img src="assets/img/icons/pencil.svg" alt="" (click)="editImageModal(item.fileObjArr[index],customFieldType.MULTIIMAGE,item.key)">
    <span class="download-right-bar"><img src="assets/1x/feather-download.svg" alt="" style="width: 15px;" (click)="setDocumentUrl(mediaArr[index],index)"></span>
    <img src="assets/1x/cross-white.png" style="width: 25px;" alt="" (click)="closePreviwModal()">
  </div>
  </div>
  <div class="fullFilePreview" *ngIf="!mediaArr[index].notValidExt && (mediaArr[index].file_size<=15000000 || ['SingleVideo','MultipleVideo','SingleAudio','MultipleAudio','SingleImage','MultipleImage'].includes(mediaArr[index].type))">
    <span class="left-button" *ngIf="mediaArr[index].type == 'MultipleFile' && index!=0 || mediaArr[index].type == 'MultipleImage' && index!=0 || mediaArr[index].type == 'MultipleVideo' && index!=0 || mediaArr[index].type == 'MultipleAudio' && index!=0"
    (click)="video=false;audio=false;moveToPreviousPreview()">
      <img src="../../../../assets/Icons_Folder/1x/leftArrow.svg"/>
    </span>
      <ng-container *ngIf="mediaArr[index].type == 'SingleFile' || mediaArr[index].type == 'MultipleFile'">
          <ng-container>
            <ngx-doc-viewer [url]="mediaArr[index]?.url" [viewer]="commonUtils.applyDocViewerBasedOnFileEXtension(mediaArr[index]?.url)" style="width: 99%; height: 98%"></ngx-doc-viewer>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="mediaArr[index].type == 'SingleImage' || mediaArr[index].type == 'MultipleImage'">
          <div class="custom-image-main">
            <img src="{{ mediaArr[index]?.url }}" class="image-in-modal" />
          </div>
        </ng-container>
        <ng-container *ngIf="mediaArr[index].type == 'SingleVideo' && video || mediaArr[index].type == 'MultipleVideo' && video">
          <div class="custom-image-main">
            <video #vedioAudioPreviewPlayer class="videoFieldModal" controls autoplay preload="metadata">
              <source src="{{ mediaArr[index]?.url }}" type="video/mp4" />
            </video>
          </div>
        </ng-container>
        <ng-container *ngIf="mediaArr[index].type == 'SingleAudio' && audio || mediaArr[index].type == 'MultipleAudio' && audio">
          <div class="custom-image-main">
            <!-- <h5 style="position: absolute;top: 2rem;left:2rem;color:white;">{{mediaArr[index]?.name}}</h5> -->
            <img class="audioSvg" src="assets/1x/audio-icon.svg" alt="video">
            <audio #vedioAudioPreviewPlayer class="videoFieldModal" controls autoplay preload="metadata">
              <source src="{{ mediaArr[index]?.url }}" type="audio/mp3" />
            </audio>
          </div>
        </ng-container>
        <span class="right-button" id="survey-next-video-button" *ngIf="mediaArr[index].type == 'MultipleFile' && mediaArr.length-1 != index || mediaArr[index].type == 'MultipleImage' && mediaArr.length-1 != index || mediaArr[index].type == 'MultipleVideo' && mediaArr.length-1 != index || mediaArr[index].type == 'MultipleAudio' && mediaArr.length-1 != index"
        (click)="video=false;audio=false;moveToNextPreview()">
          <img src="../../../../assets/Icons_Folder/1x/rightArrow.svg" alt="moveToRIght" class="move-to-right" />
        </span> 
  </div>

  <div class="fullFilePreview" *ngIf="mediaArr[index].notValidExt && (mediaArr[index].file_size<=15000000 || ['SingleVideo','MultipleVideo','SingleAudio','MultipleAudio','SingleImage','MultipleImage'].includes(mediaArr[index].type))" style="position: relative;justify-content: center;display: flex;align-items: center;">
    <span class="left-button" *ngIf="mediaArr[index].type == 'MultipleFile' && index!=0 || mediaArr[index].type == 'MultipleImage' && index!=0 || mediaArr[index].type == 'MultipleVideo' && index!=0 || mediaArr[index].type == 'MultipleAudio' && index!=0"
    (click)="video=false;audio=false;moveToPreviousPreview()">
      <img src="../../../../assets/Icons_Folder/1x/leftArrow.svg"/>
    </span>
    <div class="middleForNotPreview" style="text-align:center;">
      <span class="files">{{'form.unknownExtension' | translate}}</span><br>
      <a  href="{{ mediaArr[index]?.url }}" target="_blank">
      <button class="downloadBtn"><img src="../../../assets/1x/feather-download.svg" style="margin:5px;">{{'common.download' | translate}}</button>
      </a>  
    </div>
    <span class="right-button" id="survey-next-video-button" *ngIf="mediaArr[index].type == 'MultipleFile' && mediaArr.length-1 != index || mediaArr[index].type == 'MultipleImage' && mediaArr.length-1 != index || mediaArr[index].type == 'MultipleVideo' && mediaArr.length-1 != index || mediaArr[index].type == 'MultipleAudio' && mediaArr.length-1 != index"
  (click)="video=false;audio=false;moveToNextPreview()">
    <img src="../../../../assets/Icons_Folder/1x/rightArrow.svg" alt="moveToRIght" class="move-to-right" />
  </span>
  </div>


  <div class="fullFilePreview" *ngIf="mediaArr[index].file_size>15000000 && !(['SingleVideo','MultipleVideo','SingleAudio','MultipleAudio'].includes(mediaArr[index].type))" style="position: relative;justify-content: center;display: flex;align-items: center;">
    <span class="left-button" *ngIf="mediaArr[index].type == 'MultipleFile' && index!=0 || mediaArr[index].type == 'MultipleImage' && index!=0 || mediaArr[index].type == 'MultipleVideo' && index!=0 || mediaArr[index].type == 'MultipleAudio' && index!=0"
    (click)="video=false;audio=false;moveToPreviousPreview()">
      <img src="../../../../assets/Icons_Folder/1x/leftArrow.svg"/>
    </span>
    <div class="middleForNotPreview" style="text-align:center;">
    <span class="files">{{'form.filePreviewNotGenerated' | translate}}</span><br>
    <a  href="{{ mediaArr[index]?.url }}" target="_blank">
    <button class="downloadBtn"><img src="../../../assets/1x/feather-download.svg" style="margin:5px;">{{'common.download' | translate}}</button>
    </a>  
  </div>
  <span class="right-button" id="survey-next-video-button" *ngIf="mediaArr[index].type == 'MultipleFile' && mediaArr.length-1 != index || mediaArr[index].type == 'MultipleImage' && mediaArr.length-1 != index || mediaArr[index].type == 'MultipleVideo' && mediaArr.length-1 != index || mediaArr[index].type == 'MultipleAudio' && mediaArr.length-1 != index"
  (click)="video=false;audio=false;moveToNextPreview()">
    <img src="../../../../assets/Icons_Folder/1x/rightArrow.svg" alt="moveToRIght" class="move-to-right" />
  </span>
  </div>
</ng-container>

<ng-container *ngIf="!validUrlGenerated">
  <div class="fullFilePreview" style="position: relative;justify-content: center;display: flex;align-items: center;">
    <div class="middleForNotPreview" style="text-align:center;">
      <app-shimmer-loader  [type]="'Spinner'" [circleWidth]="50" [color]="'white'"></app-shimmer-loader> 
    </div>
    </div>
</ng-container>
