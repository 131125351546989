<div class="itemGrid">
    <ng-template #connectedBoard></ng-template>
    <span *ngIf="gridReady" tabindex="0" class="expandGridBtn float-right themeBgColor" (click)=expandGrid(gridExpandedView)>
        <i class="fa fa-expand" aria-hidden="true"></i>
    </span>
</div>

<ng-template #gridExpandedView let-modal>
    <!-- <ng-container *ngIf='showGrid'> -->
        <div class="modal-content" id="gridInfoPopup" tabindex="-1">
            <div class="prevlead">
                <div class="row">
                  <div class="col-12 pl-4">
                    <i class="fa fa-long-arrow-left back-arrow" role="button" (click)="goToPreviousItem=false;itemModalRef2.close()"></i>
                    <span  class="cursorPointer pl-4">
                      <strong>#{{currentLead?.seq_id}}</strong> 
                    </span>
                  </div>
                </div>
            </div>
            <div class="expand-grid-popup mt-1 h-100">
                <app-item-list-grid 
                [currentLead]="currentLead"
                [isItemGridExpendView]="true"
                [tabIndex]="tabIndex" 
                [dashId]="dashId"
                [showHiddenFields]="showHiddenFields"
                [subtaskFilter]="subtaskFilter"
                [isCurrentBoardReadonly]="isCurrentBoardReadonly"
                [connectedKey]="connectedKey"
                (itemRemove)="removeItemFromBoard($event)" 
                (onItemOpen)="onItemOpen?.emit($event)"
                (itemCreate)="setItemToBoard($event)" 
                (itemUpdate)="updateItemToBoard($event)"
                [boardConnectedItemIds]="boardConnectedItemIds"
                [customFieldsOfMainBoard]="customFieldsOfMainBoard">
                </app-item-list-grid>   
            </div>
        </div>
    <!-- </ng-container> -->
</ng-template>
