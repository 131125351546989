import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ConstantService } from "../../../../Enums/Constant.service";
import { CommonUtils } from "../../../services/CommonUtils.service";
import { DashboardUtilsService } from "../../../services/dashboard-utils.service";
import { HttpTransferService } from "../../../services/httpTransfer.service";

@Component({
  selector: "app-lead-dependency",
  templateUrl: "./lead-dependency.component.html",
  styleUrls: ["./lead-dependency.component.scss"],
})
export class LeadDependencyComponent implements OnInit {
  isActive: boolean = true;
  @Input("dashId") dashId: any;
  @Input("leadId") leadId: any;
  @Input("dashUsers") dashUsers: any[];
  @Input("dashInitial") dashInitial: any;
  @Output("UpdateCount") UpdateCount = new EventEmitter<any>();
  users: any[] = [];
  leadObj: {};
  dependsOnItemsIdArr: any[] = [];
  dependentsItemIdArr: any[] = [];
  dependsOnLeadsArr: any[] = [];
  dependentsLeadsArr: any[] = [];
  bucketOfCorrespondingDash: any;
  searchLeadList: any[] = [];
  dashboardUrl: string;
  subject: Subject<any> = new Subject();
  inputType = this.constantService.getInputType();
  noResponse: boolean = false;
  dependsOnLeadsArrStatus: boolean = false;
  orgUsersJson: any;

  constructor(
    private httpTransfer: HttpTransferService,
    private commonUtils: CommonUtils,
    private constantService: ConstantService,
    private dashboardUtils: DashboardUtilsService
  ) {
    this.dashboardUrl = this.constantService.getDashboardUrl();
  }

  async ngOnInit() {
    this.orgUsersJson = await this.dashboardUtils.getOrgUsers();
    this.leadQueryCorrespondingToLeadId(this.leadId);
    this.getBucketsCorrespondingToDashId();
    this.debounceApiCallWithDelay();
  }
  onDependsOnClick() {
    this.isActive = true;
    setTimeout(() => {
      document.getElementById("dependsOnInput").focus();
    }, 500);
  }
  onDependentItemsClick() {
    this.isActive = false;
    setTimeout(() => {
      document.getElementById("dependsOnInput").focus();
    }, 500);
  }
  getBucketsCorrespondingToDashId() {
    this.httpTransfer
      .getBucketsCorrespondingToDahBoard(this.dashId)
      .subscribe((res : any) => {
        this.bucketOfCorrespondingDash = res.result?.buckets || [];
      });
  }
  debounceApiCallWithDelay() {
    this.subject.pipe(debounceTime(1000)).subscribe((text) => {
      this.searchLeadForDependsOn(text);
    });
  }

  searchLeadForDependsOn(searchText) {
    let inputJson = {};
    this.searchLeadList = [];
    inputJson["search_params"] = {"search_text":searchText};
    this.httpTransfer.getLeadQuery(inputJson, this.dashId).subscribe((res : any) => {
      if (res.result.leadResponse.length == 0) {
        this.noResponse = true;
      } else {
        this.noResponse = false;
      }
      for (let i = 0; i < res.result.leadResponse.length; i++) {
        let leadObj = this.commonUtils.creatingJsonStructureOfLeadForTableView(res.result.leadResponse[i]);
        this.searchLeadList.push(leadObj);
      }
    });
  }

  validateLetters(val) {
    this.dependsOnLeadsArrStatus = true;
    this.subject.next(val);
  }
  addItem(leadData, flag) {
    if (flag) {
      this.addDependsOnItem(leadData);
    } else {
      this.addDependentItem(leadData);
    }
  }
  addDependsOnItem(leadData) {
    let dependsOnLeadId = leadData._id;
    let dependenciesObj = { dependencies: {} };
    this.dependsOnItemsIdArr.push(dependsOnLeadId);
    dependenciesObj["dependencies"]["depends_on"] = this.dependsOnItemsIdArr;
    dependenciesObj["dependencies"]["dependents"] = this.dependentsItemIdArr;
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(
        dependenciesObj,
        this.dashId,
        this.leadId
      )
      .subscribe((res : any) => {
        if (res.status == 200) {
          this.leadQueryCorrespondingToLeadId(this.leadId, "addDependsOn");
          this.updateDependenciesCount();
          // this.searchText='';
        }
      });
  }
  addDependentItem(leadData) {
    let dependentLeadId = leadData._id;
    let dependenciesObj = { dependencies: {} };
    this.dependentsItemIdArr.push(dependentLeadId);
    dependenciesObj["dependencies"]["depends_on"] = this.dependsOnItemsIdArr;
    dependenciesObj["dependencies"]["dependents"] = this.dependentsItemIdArr;
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(
        dependenciesObj,
        this.dashId,
        this.leadId
      )
      .subscribe((res : any) => {
        if (res.status == 200) {
          this.leadQueryCorrespondingToLeadId(this.leadId, "addDependent");
          this.updateDependenciesCount();
          // this.searchText = '';
        }
      });
  }
  leadQueryCorrespondingToLeadId(leadId, addConstant?) {
    var leadIdList = [];
    leadIdList.push(leadId);
    var leadObjQuery = {};
    leadObjQuery["lead_id"] = leadIdList;
    this.httpTransfer
      .getLeadQuery(leadObjQuery, this.dashId)
      .subscribe((res : any) => {
        if (res.status === 200) {
          if (addConstant == "addDependsOn") {
            this.getDependsOnItems(res.result.leadResponse[0]);
          } else if (addConstant == "addDependent") {
            this.getDependentsItems(res.result.leadResponse[0]);
          } else {
            this.getDependsOnItems(res.result.leadResponse[0]);
            this.getDependentsItems(res.result.leadResponse[0]);
          }
          this.searchLeadList = [];
        }
      });
    setTimeout(() => {
      if(document.getElementById("dependsOnInput")){
        document.getElementById("dependsOnInput").focus();
      }
      
    }, 500);
  }
  getDependsOnItems(data) {
    this.dependsOnItemsIdArr = [];
    this.dependsOnLeadsArr = [];
    if (data["dependencies"] && data["dependencies"]["depends_on"] != undefined) {
      this.dependsOnItemsIdArr = data["dependencies"]["depends_on"];
    }
    if (this.dependsOnItemsIdArr.length > 0) {
      this.dependsOnItemsIdArr.forEach((item) => {
        var leadIdList = [];
        leadIdList.push(item);
        var leadObjQuery = {};
        leadObjQuery["lead_id"] = leadIdList;
        this.httpTransfer
          .getLeadQuery(leadObjQuery, this.dashId)
          .subscribe((res : any) => {
            if (res.status === 200) {
              this.leadObj = this.commonUtils.creatingJsonStructureOfLeadForTableView(res.result.leadResponse[0]);
              this.dependsOnLeadsArr.push(this.leadObj);
              this.dependsOnLeadsArrStatus = false;
            }
            // this.getuserImageForDependsOnItem();
          });
      });
    }
  }
  getDependentsItems(data) {
    this.dependentsLeadsArr = [];
    this.dependentsItemIdArr = [];
    if (data["dependencies"]["dependents"] != undefined) {
      this.dependentsItemIdArr = data["dependencies"]["dependents"];
    }
    if (this.dependentsItemIdArr.length > 0) {
      this.dependentsItemIdArr.forEach((item) => {
        var leadIdList = [];
        leadIdList.push(item);
        var leadObjQuery = {};
        leadObjQuery["lead_id"] = leadIdList;
        this.httpTransfer
          .getLeadQuery(leadObjQuery, this.dashId)
          .subscribe((res : any) => {
            if (res.status === 200) {
              this.leadObj = this.commonUtils.creatingJsonStructureOfLeadForTableView(res.result.leadResponse[0]);
              this.dependentsLeadsArr.push(this.leadObj);
              this.dependsOnLeadsArrStatus = false;
            }
          });
      });
    }
  }

  removeDependsOnItem(removeIndex) {
    this.dependsOnLeadsArr.forEach((item, index) => {
      if (removeIndex == index) {
        this.dependsOnItemsIdArr.splice(
          this.dependsOnItemsIdArr.findIndex((x) => x == item._id),
          1
        );
      }
    });
    let dependenciesObj = { dependencies: {} };
    dependenciesObj["dependencies"]["depends_on"] = this.dependsOnItemsIdArr;
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(
        dependenciesObj,
        this.dashId,
        this.leadId
      )
      .subscribe((res : any) => {
        if (res.status == 200) {
          this.leadQueryCorrespondingToLeadId(this.leadId, "addDependsOn");
          this.dependsOnLeadsArr.forEach((item, index) => {
            if (removeIndex == index) {
              this.updateDependenciesCount(this.dependsOnLeadsArr[index]);
            }
          });
        }
      });
  }
  removeDependentsItem(removeIndex) {
    this.dependentsLeadsArr.forEach((item, index) => {
      if (removeIndex == index) {
        this.dependentsItemIdArr.splice(
          this.dependentsItemIdArr.findIndex((x) => x == item._id),
          1
        );
      }
    });
    let dependenciesObj = { dependencies: {} };
    dependenciesObj["dependencies"]["dependents"] = this.dependentsItemIdArr;
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(
        dependenciesObj,
        this.dashId,
        this.leadId
      )
      .subscribe((res : any) => {
        if (res.status == 200) {
          this.leadQueryCorrespondingToLeadId(this.leadId, "addDependent");
          this.dependentsLeadsArr.forEach((item, index) => {
            if (removeIndex == index) {
              this.updateDependenciesCount(this.dependentsLeadsArr[index]);
            }
          });
        }
      });
  }
  updateDependenciesCount(leadData?) {
    this.UpdateCount.emit(leadData);
  }
  onNavigateToEditLeadPage(leadData) {
    var editUrl = this.dashboardUrl + this.dashId + "/" + leadData.seq_id;
    window.open(editUrl, "_blank");
  }
}
