import { Component, AfterViewInit, Input, ElementRef, ViewChild, OnInit, EventEmitter, Output, HostListener, SimpleChanges } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ConstantService } from 'Enums/Constant.service';
import { MediaIcons } from 'Enums/media-icons';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { BasicUtils } from 'app/services/basicUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferChatService } from 'app/services/http-transfer-chat.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';

declare var $: any;

@Component({
  selector: 'app-editor-columns',
  templateUrl: './editor-columns.component.html',
  styleUrls: ['./editor-columns.component.scss']
})
export class EditorColumnsComponent implements OnInit {
    @Input() mentionEnabled:boolean=false;
    @Input() objFilterForMultiselect: any;
    @Input() broadCastChannel:any;
    @Input() selectedLeadObj:any;
    @Input() leadInfo:any[]=[]
    @Input() multiSelect:boolean=false;
    @Output() close : EventEmitter<any> = new EventEmitter();
    @Output() onBlur : EventEmitter<any> = new EventEmitter();
    @ViewChild('popColumns') public popColumns: NgbPopover;
    @ViewChild('editableDiv') editableDiv: ElementRef;
    @ViewChild('attachFile') attachFile: ElementRef;
    @ViewChild('columnBtn',{static : true}) columnBtn: ElementRef;
    @Output() isFullScreenEmit : EventEmitter<any> = new EventEmitter();
    @Input() mediaArr:any[]=[];
    @Input() fileEvent:any[]=[];
    @Input() dashId:any;
    @Input() columnsJson: any;
    @Input() columnIndex: any;
    @Input() attachment:any[]=[];
    @Input() htmlContent: any;
    @Input() viewOnly:boolean=false;
    @Input() summerNoteEditor : boolean =true; 
    mediaIcons = MediaIcons;
    @Input() editorText: string='';
    @Input() subject: any;
    @Input() type :any;
    @Input() emailSignatureForEditor:any;
    @Input() leadObj:any;
    @Input() tempType:string=''
    mediaDisable:boolean = false;
    showEditor:boolean = true;
    @Input() isFullScreen:boolean;
    summernoteToolbar:any=[
        ['style', ['bold', 'italic']],
        ['font', ['strikethrough']],
        ['columnBtn', ['customButton']],
        ['attachement', ['attachementButton']],
        ['advanceBtn',['advancedOptionsButton']]
       ]
    summernoteToolbarForView:any=[
    // ['style', ['bold', 'italic']],
    // ['font', ['strikethrough']],
    ['columnBtn', ['customButton']],
    ['style', ['bold', 'italic']],
    ['font', ['strikethrough']],
    ['insert', ['link']],
    ["color", ["color"]],
    ["para", ["ul", "ol", "paragraph"]],
    ['view', ['codeview']],
    ]
     advancedToolbarOptions = [
      ['style', ['bold', 'italic']],
        ['font', ['strikethrough']],
        ['columnBtn', ['customButton']],
        ['attachement', ['attachementButton']],
        ['fontname', ['fontname']],
      ['insert', ['link', 'picture', 'video']],
      ["color", ["color"]],
      ["para", ["ul", "ol", "paragraph"]],
      ['view', ['codeview']],
      ['advanceBtn',['advancedOptionsButton']],
    ];
       summerNoteconfig: {
        placeholder: string;
        tabsize: number;
        height: string;
        uploadImagePath: string;
        toolbar: (string | string[])[][];
        fontNames: string[];
      };
      storedCursorPosition: Range;
  templateId: any;
  previewIndex: number = 0 ;
  filePrevMedia: any[]=[];
  isBasicToolbar: boolean = true;
  selectedIndex:any=0;
  chatCaption:any;
  count: any=0;
  orgUserArr: any[]=[];
  customFieldArr: any[]=[];
    constructor(public constantService : ConstantService,public modalServices : NgbModal,private basicUtils:BasicUtils,private commonUtils : CommonUtils,private httpTransfer : HttpTransferService,private dashboardUtils: DashboardUtilsService){
        this.summerNoteconfig = this.constantService.getSummerNoteConfig();
    }
    async ngOnInit(){
      let orgUsersJson = await this.dashboardUtils.getOrgUsers(); 
      this.orgUserArr = Object.values(orgUsersJson);
      let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
      let dashJson = JSON.parse(JSON.stringify(dashboardJson))
      this.customFieldArr = JSON.parse(JSON.stringify(dashJson[this.dashId]['CUSTOM_FORM'])) || {};
      this.customFieldArr = this.customFieldArr.filter(d => d.type != 'Button')
    }



    

    ngAfterViewInit() {
        console.log(this.htmlContent,this.columnsJson)
        if(this.htmlContent){
            Object.keys(this.columnsJson).forEach(element=>{
                let hoverContent = this.columnsJson[element]?.algorithm ? this.columnsJson[element].display_key + this.columnsJson[element]?.algorithm : this.columnsJson[element]?.display_key
                this.htmlContent = this.htmlContent.replaceAll(`{{${this.columnsJson[element].index}}}`,`<span contenteditable="false" style='width:fit-content;border: 1px solid #707070;border-radius: 7px;'><b hidden="true">{{${this.columnsJson[element].key}}}</b><span class="columsTootltip" style="margin:0 5px;" data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content='`+hoverContent+`'>${this.columnsJson[element].display_key}</span><i class="fa fa-close" id='removeColumn_${this.columnsJson[element].index}'></i>${" "}</span>`)
              })
        }
        if(!this.summerNoteEditor){
          this.editableDiv.nativeElement.innerHTML = this.htmlContent ? this.htmlContent+"&nbsp;" : ''
         }
        //  `<input placeholder='Enter subject' style='border: none;width: 100%;' [(ngModel)]='this.htmlContent'>`
         else{
          this.initializeSummerNote()
         }
        this.showPopover()
    }
  


    @HostListener('click', ['$event']) onClick(btn) {
      let idValue= btn.target.id;
      const parts = idValue.split("_");
      if(parts[0]=='removeColumn'){
        const valueAfterUnderscore = parts[1];
        console.log("target item remove",valueAfterUnderscore,this.columnsJson)
        $(btn.target).parent().remove();
        Object.values(this.columnsJson).forEach((ele: any, i: number) => {
          if (String(ele.index) === valueAfterUnderscore) {
              delete this.columnsJson[i.toString()]; // Remove the element from the object
          }
      });
      }
      if(!this.multiSelect){
        this.sentText()
      }
    }
    
  initializeSummerNote() {
    setTimeout(() => {
      const newToolbar = !this.isBasicToolbar ? this.advancedToolbarOptions : this.summernoteToolbar;
      const viewOnlyOption=this.summernoteToolbarForView
      console.log("viewtype",this.tempType)
      console.log(newToolbar,this.viewOnly)
      $(this.editableDiv?.nativeElement).summernote({
        placeholder: '',
        toolbar: this.tempType==='Pdf'?viewOnlyOption:newToolbar,
        buttons: {
          // Custom button to toggle between basic and advanced toolbar options
          customButton: (context) => {
            var ui = $.summernote.ui;
            // Create a button
            var button = ui.button({
              contents: '<span id="columnPicker"></span>',
              tooltip: 'Insert {{ }}',
              container: "body",
              click: () => {  
              this.mediaDisable = false;
              this.summerNoteEditor = true
               }
            });
            return button.render();
          },
          attachementButton: () => {
            var ui = $.summernote.ui;
            // Create a button
            var button = ui.button({
              contents: '<img  src="../../../assets/1x/attachMedia.svg" style="height:16px;">',
              tooltip: 'Insert {{ }}',
              container: "body",
              click: () => {
                this.openFileInput()
              }
            });
            return button.render();
          },
          advancedOptionsButton: (context) => {
            const ui = $.summernote.ui;
            const button = ui.button({
              contents: '<i class="fa fa-cogs"></i>',
              tooltip: 'Toggle Advanced Options',
              container: 'body',
              click: () => {
                this.showEditor = false;
               // Destroy and recreate Summernote instance in the destroy callback
               this.advancedToolbarOptionsEditor()
              },
            });
            return button.render();
          },
        },
        callbacks: {
          onBlur: (e) => {
            this.getCareAtPositionForComment()
          },
        },
      })
      if (this.htmlContent && this.type!=='EMAIL') {
        $(this.editableDiv.nativeElement).summernote('code', this.htmlContent)
      }
      if(this.type=='EMAIL' && this.emailSignatureForEditor){
        $(this.editableDiv.nativeElement).summernote('code',this.emailSignatureForEditor)
      }
      $('#columnBtn').appendTo($('#columnPicker'))
    }, 50)
  }

  advancedToolbarOptionsEditor(){
    console.log(this.isFullScreen)
    this.isBasicToolbar =  !this.isBasicToolbar    
    if(this.type!='EMAIL'){
      this.htmlContent = $(this.editableDiv.nativeElement).summernote('code')
    }
    else{
      this.emailSignatureForEditor = $(this.editableDiv.nativeElement).summernote('code')
      this.isFullScreenEmit.emit()
    }
    setTimeout(()=>{this.showEditor = true},20)
    this.initializeSummerNote()
  }


  openFileInput() {
    $(this.attachFile.nativeElement).val('');
     $(this.attachFile.nativeElement).click();
  }

      getCareAtPositionForComment(){
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
          this.storedCursorPosition = selection.getRangeAt(0);
        }
          this.sentText()
      }

      onUploadFile(event){
        let files = event.target.files 
        if (event.target.files && event.target.files[0]) {
          var filesAmount = event.target.files.length;
          for (let i = 0; i < filesAmount; i++) {
            this.fileEvent.push(files[i])
            var reader = new FileReader();
            let previewData = {};
            reader.onload = (event: any) => {
              previewData["file_name"] = files[i].name;
              previewData["path"] = event.target.result;
              previewData["type"] = files[i].type.split("/")[0];
              if(this.mediaArr?.length<=0 && this.editorText.trim()?.length>0){
                previewData['caption'] = this.editorText
              }
              this.mediaArr.push(previewData);    
              this.editorText = ""      
            };
            reader.readAsDataURL(event.target.files[i]);
          }
        }
        this.sentText()
    }

    removeFile(i){
        if(this.selectedIndex>i){
        this.selectedIndex--
        }
        if(this.selectedIndex==i && this.selectedIndex==this.mediaArr.length-1){
        this.selectedIndex=0
        }
        this.attachment?.splice(i,1)
        this.mediaArr?.splice(i,1)
        if(this.fileEvent?.length){
          this.fileEvent.splice(i,1)
        }
        this.getMediaIndex(this.selectedIndex)
        this.sentText()
       }

       sendColumnEmit(columnsObj){
          this.popColumns.close()
          this.insertText(columnsObj)
       }

       insertText(columnsObj) {
        this.popColumns.close()
        this.makingColumnjson(columnsObj)
          const fragment = document.createDocumentFragment();
          const newElement = document.createElement('div');
            let hoverContent = columnsObj.algorithm ? columnsObj?.display_key + `(${columnsObj.algorithm})` : columnsObj?.display_key
            newElement.innerHTML = `<span contenteditable='false' style='width:fit-content;border: 1px solid #707070;border-radius: 7px;background-color:#E9EFFB;'><b hidden="true">{{${columnsObj?.key}}}</b><span class="columsTootltip" style="margin:0 5px;" data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content='`+hoverContent+`'>${columnsObj?.display_key}</span><i class="fa fa-close" id='removeColumn_${columnsObj.index}'></i>${" "}</span><br>`
           this.showPopover()
          while (newElement.firstChild) {
            fragment.appendChild(newElement.firstChild);
          }
          console.log(newElement.innerHTML)
       //   this.storedCursorPosition.insertNode(textNode);
       if (this.storedCursorPosition) {
        this.storedCursorPosition.insertNode(fragment);
        this.storedCursorPosition = null;
        }
        else{
          const targetElement = this.editableDiv.nativeElement; // Replace with the actual ID
          if (targetElement && !this.summerNoteEditor) {
            // Insert the node as the first child of the target element
            targetElement.insertBefore(fragment, targetElement.firstChild);
          }
          else{
            $(this.editableDiv.nativeElement).summernote('pasteHTML', `<span contenteditable='false' style='width:fit-content;border: 1px solid #707070;border-radius: 7px;background-color:#E9EFFB;'><b hidden="true">{{${columnsObj?.key}}}</b><span class="columsTootltip" style="margin:0 5px;" data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content='`+hoverContent+`'>${columnsObj?.display_key}</span><i class="fa fa-close" id='removeColumn_${columnsObj.index}'></i>${" "}</span>`);
          }
        }
          this.columnIndex++;
        this.setFocusOnDiv()
        this.sentText()
      }
      
      setFocusOnDiv(){
        const contentEditableElement = this.editableDiv.nativeElement;
        const range = document.createRange();
      // Set the range to the end of the content
      range.selectNodeContents(contentEditableElement);
      //range.setStartAfter(fragment.lastChild)
      range.collapse(false);
      // Create a Selection object and set the range
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      // Set the focus on the contenteditable div
      contentEditableElement.focus();      
      }
      
      makingColumnjson(columnsObj){
        let obj = columnsObj.algorithm ? {'key':columnsObj?.key,'display_key': columnsObj?.display_key,'index':this.columnIndex,'dashId': this.dashId,'customField': columnsObj?.isCustom,'algorithm' : columnsObj.algorithm,'mirror':columnsObj?.mirror ? columnsObj?.mirror : false,'mirror_column_key':columnsObj?.mirror_column_key} : {'key':columnsObj?.key,'display_key': columnsObj?.display_key,'index':this.columnIndex,'dashId': this.dashId,'customField':columnsObj?.isCustom,'mirror':columnsObj?.mirror ? columnsObj?.mirror : false,'mirror_column_key':columnsObj?.mirror_column_key}
        this.columnsJson[this.columnIndex]= obj     
      }
      
      showPopover(){
        setTimeout(()=>{
          $(document).ready($('.columsTootltip').popover())
         },100)
      }

     
    evaluateText(){
  let text = this.summerNoteEditor ? $(this.editableDiv.nativeElement).summernote("code") : this.editableDiv.nativeElement.textContent
      console.log(text,this.columnsJson) 
      if(this.columnsJson){
        Object.keys(this.columnsJson).forEach(element=>{
          let  replaceVal = this.summerNoteEditor ? `<span contenteditable="false" style='width:fit-content;border: 1px solid #707070;border-radius: 7px;background-color:#E9EFFB;'><b hidden="true">{{${this.columnsJson[element].key}}}</b><span class="columsTootltip" style="margin:0 5px;" data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content=${this.columnsJson[element].display_key}>${this.columnsJson[element].display_key}</span><i class="fa fa-close" id='removeColumn_${this.columnsJson[element].index}'></i>${" "}</span>`  : `{{${this.columnsJson[element].key}}}${this.columnsJson[element].display_key} `
          if(this.summerNoteEditor){
            text.replace(/<span.*?>.*?<\/i>\s<\/span>/s, async (match, item) => {
              Object.keys(this.columnsJson).forEach(ele=>{
              if(match.includes(this.columnsJson[ele].key) && match.includes(this.columnsJson[ele].display_key)){
                text = text.replace(/<span.*?>.*?<\/i>\s<\/span>/s,`{{${this.columnsJson[ele].index}}}`)
              }
            })
            }) 
          }
          else{
            text = text.replace(replaceVal,`{{${this.columnsJson[element].index}}} `)
           }
        }) 
      }
      console.log(text,this.subject)
      this.summerNoteEditor ? this.editorText = text ? text : this.editorText : this.subject = text ? text :this.subject
    }
     
    sentText(){
      let obj={};
      if(this.type!=='EMAIL'){
        if(this.showEditor){
          this.evaluateText()
        }
        console.log(this.editorText)
        obj['editorMessage'] = this.editorText;
        obj['columnsJson'] = this.columnsJson;
        obj['columnIndex'] = this.columnIndex;
        obj['mediaArr'] = this.mediaArr;
        obj['fileEvent'] = this.fileEvent;
        obj['attachment'] = this.attachment;
        obj['subject'] = this.subject;
        console.log(obj)
        this.onBlur.emit(obj)
      }
      else{
        obj['emailBody'] = $(this.editableDiv.nativeElement)?.summernote('code')
        obj['mediaArr'] = this.mediaArr;
        obj['fileEvent'] = this.fileEvent;
        obj['attachment'] = this.attachment || [];
        console.log(obj)
        this.onBlur.emit(obj)
      }
    }  

    open(del,id,i) {
      this.templateId = id
      this.previewIndex = i;    
      console.log(this.mediaArr) 
       this.mediaArr.forEach(data=>{
        let obj={}
        let type = data?.type.toUpperCase()
        if(type == 'IMAGE'){
          obj['type'] = 'MultipleImage'
        }
        if(type == 'VIDEO'){
          obj['type'] = 'MultipleVideo'
        }
        if(type == 'DOCUMENT' || type == 'APPLICATION' || type == 'FILE'){
          obj['type'] = 'MultipleFile'
        }
        obj['name'] =  data?.file_name
        obj['url']  =  data?.file_url ? data?.file_url : data?.path
        obj['path'] = data?.path
        this.filePrevMedia.push(obj)
       })
     let modalRef = this.modalServices.open(del, { size: 'lg' , windowClass: 'file preview-modal'})
      this.basicUtils.storeModlRef(modalRef) 
    }

    columnValueemit(value){
      console.log(value)
      this.popColumns.close()
        let isText = $(this.editableDiv.nativeElement).summernote("code")
        if(isText?.length){
          $(this.editableDiv.nativeElement).summernote('restoreRange'); 
          $(this.editableDiv.nativeElement).summernote('editor.insertText', value);
          $(this.editableDiv.nativeElement).summernote('saveRange');
        }
        else{
          $(this.editableDiv.nativeElement).summernote('code', value )
        }
    }

    mediaEmitForColumns(columnMedia){
      console.log(columnMedia)
      this.popColumns.close()
      columnMedia?.attachment.forEach(m=>{
    let obj = {}
        obj['file_name'] = m?.file_name,
        obj['path'] = m?.file_path,
        obj['url'] = m?.url,
        obj['type'] = m?.type.toLowerCase() //m?.file_type ? (m?.file_type.toUpperCase() === 'FILE' ? 'DOCUMENT' : m?.file_type.toUpperCase()) : m?.type.toUpperCase();
        this.mediaArr.push(obj)
        this.attachment.push({'file_name': m?.file_name,'path':m?.file_path,'type':m?.type.toUpperCase()})
      })
      this.sentText()
    }

    getTemplateMessage(value){
      this.popColumns.close()
      let isText = $(this.editableDiv.nativeElement).summernote("code")
      if(isText?.length){
        $(this.editableDiv.nativeElement).summernote('restoreRange'); 
        $(this.editableDiv.nativeElement).summernote('pasteHTML', value);
        $(this.editableDiv.nativeElement).summernote('saveRange');
      }
      else{
        $(this.editableDiv.nativeElement).summernote('pasteHTML', value )
      }
    }
    getMediaIndex(i){
      this.selectedIndex=null;
      setTimeout(()=>{
        this.selectedIndex=i
      },5)
    }

    sendBroadCast(){
      console.log(2)
      if(this.mediaArr.length && this.fileEvent?.length){
        this.fileEvent.forEach(async (e,index)=>{
          this.commonUtils.onUploadFile([e], 'LEAD',null, this.setFilePathToArrOfCustomFileds.bind(this),this.mediaArr[index]);
        })
      } 
      else{
        this.sendMessage() 
      }
    }

    setFilePathToArrOfCustomFileds(fileUrlPath,callbackParams) {
      console.log(fileUrlPath,callbackParams);
      this.count = this.count+1;
      fileUrlPath.forEach(e=>{
         let fileJson ={}
         fileJson['file_name'] = e['file_name']+"."+e['file_path']?.split('.').pop();
         fileJson['file_path'] = e['file_path'];
         if(callbackParams?.caption?.trim()?.length>0){
           fileJson['caption'] = this.commonUtils.decodeEntitiesWithAttributes(callbackParams?.caption)
         }
         fileJson['file_type'] = e['media_type'].toUpperCase()=='TEXT' ? 'DOCUMENT' : e['media_type'].toUpperCase()
         this.attachment.push(fileJson)
         console.log(this.count)
        if(this.count == this.fileEvent?.length){
          this.count = 0
          this.sendMessage()
        }
      })
  }

  async sendMessage(){
    let inputJson = {}
    inputJson['filter_properties']=this.objFilterForMultiselect
    if(!this.mentionEnabled && this.broadCastChannel){
      let str = this.editorText;
      inputJson['channel_comment']={
        "message_data":{'text': this.commonUtils.decodeEntitiesWithAttributes(this.editorText)},
        "channel_id": this.broadCastChannel?._id,
        "type":"EXTERNAL_OUTBOUND",
        "is_broadcast":true,
      } 
      if(this.attachment?.length){
        inputJson['channel_comment']["message_data"] = {'attachments': this.attachment}
      }
      if(this.columnsJson){
        inputJson['channel_comment']["value_eval"]={'value_map':this.columnsJson,'value_from':"EVALUATE",'operation_type':"COMPUTETEXT"}
      }
    }
    else{
      let commentParsedString;
      inputJson['update_json']={}
      if(this.editorText?.trim()?.length>0){
        commentParsedString = this.commonUtils.decodeEntitiesWithAttributes(this.editorText)
      }
      let isUserMentionedFlag = false;
      let mentionsUsers=[]
      if(commentParsedString && commentParsedString!==''){
        this.orgUserArr.forEach(element => {
          if (commentParsedString.includes(element._id) && !mentionsUsers[element._id]) 
          {
              isUserMentionedFlag = true;
              let count= Object.keys(mentionsUsers).length;
              commentParsedString = commentParsedString.split("{{"+element._id+"}}@"+element.name).join("{{"+count+"}}");
              let obj={
                "type":"USER",
                "type_id": element._id
              }
              mentionsUsers[element._id]=obj  
          }
        });
        if(Object.keys(mentionsUsers).length>0){
          inputJson['update_json']["mentions"]=Object.values(mentionsUsers);
        }
        if(inputJson['update_json']["message_data"]){
          inputJson['update_json']["message_data"]['text']= commentParsedString
        }else{
          inputJson['update_json']["message_data"] = {'text' : commentParsedString};
        }
      }
        if(this.attachment?.length){
          this.attachment.forEach(m=>{
            let commentInput = '';
            if(m?.caption){
              commentInput = this.commonUtils.decodeEntitiesWithAttributes(m?.caption)
            }
            this.orgUserArr.forEach(element => {
              if (commentInput!=='' && commentInput.includes(element._id) && !mentionsUsers[element._id]) 
              {
                  isUserMentionedFlag = true;
                  let count= Object.keys(mentionsUsers).length;
                  m.caption = commentInput.split("{{"+element._id+"}}@"+element.name).join("{{"+count+"}}");
                  let obj={
                    "type":"USER",
                    "type_id": element._id
                  }
                  mentionsUsers[element._id]=obj  
              }
            });
          })
          if(Object.keys(mentionsUsers).length>0){
            inputJson['update_json']["mentions"]=Object.values(mentionsUsers);
          }
          inputJson['update_json']["message_data"] = {'attachments': this.attachment}
        }
        if(this.columnsJson){
          inputJson['update_json']["value_eval"] = {'value_map':this.columnsJson,'value_from':"EVALUATE",'operation_type':"COMPUTETEXT"}
        }
    }
    this.sendMsgForMultiSelect(inputJson)
  }

  sendMsgForMultiSelect(inputJson){
    this.httpTransfer.updateMultipleDashboard(this.dashId,inputJson).subscribe((res : any) =>{
      if(res.status==200){
        this.mediaArr = [];this.attachment = [];this.fileEvent=[];this.editorText = "";this.columnIndex = {};this.columnIndex = 0;
        this.close.emit();
      }
      })
  }



  closeEmit(){
    this.close.emit();
  }


}