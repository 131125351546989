<div>
  <!-- Header -->
  <div class="d-flex align-items-center justify-content-between mb-2 pb-2 border-bottom border-secondary">
    <span class="m-0 headerText">Pdf Templates</span>

    <span *ngIf="!showSearchBar" class="d-inline-flex gap-2 align-items-center">
      <i class="fa fa-search fa-2x btn btn-outline-secondary border-white rounded px-1" (click)="showSearchBar=true"></i>
      <button *ngIf="!isReadOnly" type="button" class="btn btn-primary rounded btn-sm addNewButton" (click)="openTemplate(addNewPdfTemplate);">+ New</button>
    </span>
    <span *ngIf="showSearchBar" class="border rounded p-2">
      <input type="text" style="border:none;" #searchbox placeholder="Search" (blur)="showSearchBar=false" (input)="filterItem(searchbox.value)"/>
    </span>
  </div>

    <!-- Body -->
  <div style="max-height: 260px; overflow-y: auto;">
    <ng-container *ngIf="loader;else list">
      <div class="d-flex justify-content-center align-items-center my-2">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only"> {{'common.loading' | translate}}...</span>
        </div>
      </div>
    </ng-container>
    <ng-template #list>

          <button class="btn btn-outline-secondary border-white text-muted px-0 py-1 d-flex align-items-center w-100" type="button" (click)="templateStatus.custom=!templateStatus.custom" data-toggle="collapse" data-target="#collapseCustom"  aria-controls="collapseCustom">
            <i class="fas" [ngClass]="{'fa-chevron-down': !templateStatus.custom, 'fa-chevron-up': templateStatus.custom }"></i> 
            <span>Custom Templates</span>
            <div class="border-top border-secondary" style="flex-grow: 1;"></div>
          </button>
          <div id="collapseCustom" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
            <ng-container *ngFor="let item of whatsappTemplates">
              <div class="templateRow mb-1 pb-1" role="button" (mouseover)="item.showOptions = true" (mouseleave)="item.showOptions = false">
                <label class="m-0 templateText" [ngbTooltip]="item.template_name" triggers="hover" placement="top"  container="body" tooltipClass="tooltipPopup">{{item.template_name | truncate: 30}}</label>
                <div *ngIf="item.showOptions">
                  <button role="button" type="button" class="btn btn-outline-info border-white rounded mr-2 btn-sm" (click)="getMessageFromTemplate(item,previewTemplateModal);$event.stopPropagation()">Preview</button>
                  <button role="button" type="button" class="btn btn-outline-primary border-white rounded btn-sm" (click)="convertHtmlToPdf(item);$event.stopPropagation()">{{'whatsapp.use' | translate}}</button>
                </div>
                <div class="iconDiv" style="margin-right: 12px;" *ngIf="!item.showOptions">
                  <span class="mr-2" *ngIf="item?.shared"><app-dashboard-icon  [name]="'sharedIcons'" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon></span>
                </div>
              </div> 
            </ng-container>
            <div *ngIf="whatsappTemplates?.length==0" style="margin-top: 5px;" class="mb-2">
              <h5 style="font-weight: bold;margin-left: 50px;color: gray;">No custom templates available</h5>
            </div>
          </div>

          <button class="btn btn-outline-secondary border-white text-muted px-0 py-1 d-flex align-items-center w-100" type="button" (click)="templateStatus.pronnel=!templateStatus.pronnel" data-toggle="collapse" data-target="#collapsePronnel"  aria-controls="collapsePronnel">
            <i class="fas" [ngClass]="{'fa-chevron-down': !templateStatus.pronnel, 'fa-chevron-up': templateStatus.pronnel }"></i> 
            <span>Pronnel Templates</span>
            <div class="border-top border-secondary" style="flex-grow: 1;"></div>
          </button>
          <div id="collapsePronnel" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">  
            <ng-container *ngFor="let item of pronnelTemplates">
              <div class="templateRow mb-1 pb-1" role="button" (mouseover)="item.showOptions = true" (mouseleave)="item.showOptions = false" >
                <label class="m-0 templateText">{{item.template_name | truncate: 30}}</label>
                <div class="optionDiv" *ngIf="item.showOptions">
                    <button role="button" type="button" class="btn btn-outline-info border-white rounded mr-2 btn-sm" (click)="getMessageFromTemplate(item,previewTemplateModal);$event.stopPropagation()">Preview</button>
                    <button role="button" type="button" class="btn btn-outline-primary border-white rounded btn-sm" (click)="convertHtmlToPdf(item);$event.stopPropagation()">{{'whatsapp.use' | translate}}</button>
                </div>
                <div class="iconDiv" style="margin-right: 12px;" *ngIf="!item.showOptions">
                    <span class="mr-2"><app-dashboard-icon [name]="'sharedIcons'" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon></span>
                </div>
            </div>
            </ng-container>
            <div *ngIf="pronnelTemplates?.length==0" style="margin-top: 5px;">
              <h5 style="font-weight: bold;margin-left: 50px;color: gray;">No pronnel templates available</h5>
            </div>
          </div>

    </ng-template>
  </div>
</div>

<ng-template #addNewPdfTemplate let-modal class="add-pdf-template-modal">
    <div style="height:100%;">
      <app-channel-template [personalTemplates]="isTemplatesPersonal" [tempType]="tempType" [templateCount]="whatsappTemplates?.length" [isTemplateViewCall]="true" [type]="'PDF'" [dashId]="dashId" (closeModal)="modal.dismiss();getTemplatesForChannel()"></app-channel-template>
    </div>
</ng-template>



<!--Channels Templates-->
<ng-template #previewTemplateModal let-modal class="add-pdf-template-modal">
    <div style="height:100%;">
      <app-channel-template [personalTemplates]="isTemplatesPersonal" [tempType]="tempType"  [preview]="true" [viewOnly]="true" [editTemplateCall]="true" [templatesToEdit]="selectedTemplates" [leadInfo]="leadInfo" [templateCount]="whatsappTemplates.length" [isTemplateViewCall]="true" [type]="'PDF'" [dashId]="dashId" (closeModal)="modal.dismiss();getTemplatesForChannel();addOpenEmitter()" (useTemplate)="convertHtmlToPdf($event);modal.dismiss()"></app-channel-template>
    </div>
</ng-template>