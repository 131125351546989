import { Injectable } from '@angular/core';
import { ConstantService } from '../Enums/Constant.service';
import { CommonUtils } from './services/CommonUtils.service';
import { HttpTransferService } from './services/httpTransfer.service';
import { SortingutilsService } from './sortingutils.service';

@Injectable({
  providedIn: 'root'
})
export class FormulautilsService {

  requireApiCall:boolean=false;
  constructor(
    private constantService: ConstantService,
    private httpService:HttpTransferService,
    private SortingUtils : SortingutilsService,
    private commonUtils: CommonUtils,
  ) {}
  async getUpdatedFormulaValue(newLead,newLeadCurrentGroup,message,filterObj,groupsArr?,actionType?){
    filterObj=JSON.parse(JSON.stringify(filterObj))
    let isLeadPassedFilter=await this.SortingUtils.isNewLeadPassFilter(newLead,filterObj)
    let updateField={};
    (message?.additional_attributes?.updates || [])?.forEach(item => {
      if(item.custom_fields) item?.custom_fields.forEach(data=>{updateField[data.field_key]=data}) 
      else updateField[item.field_key]= item
    });
    let groupsData=this.getGroupData(filterObj,updateField,actionType,groupsArr,newLeadCurrentGroup,newLead)
    let formulaApplied =filterObj.apply_formula
    let itemValues={...newLead,...(newLead?.custom_fields || {})}
    this.requireApiCall=false;
    let newFormulaValues={}
    for(let i=0;i<groupsData.length;i++){
      let data=groupsData[i]
      let actionType=isLeadPassedFilter ? data?.action : 'Remove'
      let previousFormulaValues=this.getPreviousFormulaDefaultValues(data.formulaValue,formulaApplied)
      let newFormula=this.getCalcuatedFormulaJson(formulaApplied,itemValues,previousFormulaValues,updateField,data?.totalRecorde,actionType)
      if(this.requireApiCall){break;}
      newFormulaValues[data?.groupId] =newFormula
    }
    if(this.requireApiCall)newFormulaValues=await this.getDataFromApi(filterObj,newLead?.dashboard_id,groupsData);
    return newFormulaValues
  }
  getPreviousFormulaDefaultValues(previousValue?,formulaApplied?){
      let previousFormulaJson={}
      if(previousValue && previousValue?.length){
        previousValue.forEach(obj=>{
          let res={}
          if(!previousFormulaJson[obj?.customFieldKey])previousFormulaJson[obj?.customFieldKey]={}
          res[obj?.formulaKey]=obj?.value
          Object.assign(previousFormulaJson[obj?.customFieldKey], res)
        })
      }  
      else if(previousValue && Object.keys(previousValue).length>0){
        Object.keys(previousValue).forEach(key=>{previousFormulaJson[key]=previousValue[key][0]})
      }else{
        Object.keys(formulaApplied).forEach(key=>{
          previousFormulaJson[key]={}
          formulaApplied[key].forEach(field=>{
            let res={}
            res[field]=0
            Object.assign(previousFormulaJson[key], res)
          })
        })
      }
    return previousFormulaJson;
  }
  getGroupData(fiterObj,updateField,actionType,groupsArr,group,newLead?){
    let groupBy = fiterObj.grouping_details?.group_by
    let groupKeyJson = this.constantService.getGroupKeyJson()[groupBy] 
    let groupKey=groupKeyJson ? groupKeyJson.key : groupBy
    // case if current group is not exist in grouplist 
    if(!group || group?.group_id===undefined)group={group_id:updateField[groupKey]?.new_value}
    let requireData=[{'groupId' : group['group_id'],'formulaValue' : group['formulaContents'] || (group['formulaInfo']?.length>0 ? group['formulaInfo'][0] : []),'totalRecorde':group['total_records'] || 0,'action':actionType}]
    if(actionType=='Update' && updateField.hasOwnProperty(groupKey)){
      let previousGroup=groupsArr.filter(group=>group?.group_id===updateField[groupKey]?.previous_value || group?.group_id==null && !(updateField[groupKey]?.previous_value))
      if(previousGroup.length>0 && updateField[groupKey]?.previous_value!==updateField[groupKey]?.new_value){
        previousGroup=previousGroup[0]
        requireData[0]['action']="Add"
        requireData=[requireData[0],{groupId : previousGroup['group_id'],formulaValue : previousGroup['formulaContents'] || (previousGroup['formulaInfo']?.length>0 ? previousGroup['formulaInfo'][0] : []),totalRecorde:previousGroup['total_records'],action:'Remove'}]
        // for when lead already exit on group (kanban)
        if(group?.leadContents){
          let leadInGroup=group?.leadContents?.find(lead=>lead._id==newLead._id)
          if(leadInGroup){
            requireData[0]['totalRecorde']=requireData[0]['totalRecorde']-1;
            requireData[1]['totalRecorde']=requireData[1]['totalRecorde']+1;
          }
        }
      }
    }
    return requireData;

  }
  async getDataFromApi(filterObj,dashId,groupsData?){
    let groupIds=groupsData?.map(group=>group?.groupId);
    var inputJson = this.commonUtils.getFilterJsonForQuery({},filterObj);
    inputJson['pagination_details']= {start_index : 0,page_size: 1}
    let groupBy = inputJson?.grouping_details?.group_by
    if(groupBy && groupBy != 'none'){
      inputJson["grouping_details"]['max_group_size']=1
      let groupKeyJson = this.constantService.getGroupKeyJson()[groupBy] 
      if(groupKeyJson){
          if(groupKeyJson.filter_key!=null) inputJson[groupKeyJson.filter_key] = groupIds           
      }else{
          inputJson['custom_column'] = {}
          inputJson['custom_column'][groupBy] = groupIds
      }
    }
    let res : any=await this.httpService.getLeadQuery(inputJson,dashId).toPromise();
    let currentGroups=res?.result?.grouping_details?.groups || []
    let formulaData={}
    currentGroups.forEach(group=>formulaData[group?.value]=group.formula_details[0])
    return formulaData
  }
  getCalcuatedFormulaJson(formulaApplied,leadCustom,previousFormulaJson,updateField,totalLeadInGroup,actionType?){
    let newFormula=JSON.parse(JSON.stringify(previousFormulaJson))
    let emptyvalue=['{}','[]','']
    let newTotal= (actionType!='Update') ? (actionType=='Add' ? totalLeadInGroup + 1 :  totalLeadInGroup - 1) : totalLeadInGroup
    if(newTotal<1){return []}
    Object.keys(formulaApplied).forEach(key=>{
      if(!updateField.hasOwnProperty(key) && actionType=='Update'){
        newFormula[key]=[newFormula[key]]
        return
      } 
      let field=formulaApplied[key]
      let previousValue= updateField[key] && updateField[key]?.hasOwnProperty('previous_value')  ? updateField[key]['previous_value'] : null
      // date type custom field if value is empty obj
      if(leadCustom[key] && emptyvalue?.includes(JSON.stringify(leadCustom[key]))){leadCustom[key]=null} 
      if(previousValue && emptyvalue?.includes(JSON.stringify(previousValue))){ previousValue=null }
      
      if(field.includes('COUNT_ALL')){
        newFormula[key]['COUNT_ALL']=newTotal
      }
      if(field.includes('SUM')){
        let subValue=actionType!='Update' ? (actionType=='Add' ? (leadCustom[key] || 0) :  -(leadCustom[key] || 0)) : (leadCustom[key] || 0)-(previousValue || 0)
        newFormula[key]['SUM']=previousFormulaJson[key]['SUM']+subValue
      }
      if(field.includes('TOTAL_ATTACHMENT_SIZE')){
        let totalPreviousSize=0;
        let totalNewSize=0;
        if(previousValue?.length) previousValue.forEach(item=>totalPreviousSize+=item.size_in_bytes)
        if(leadCustom[key]?.length) leadCustom[key].forEach(item=>totalNewSize+=item.size_in_bytes)
        let subValue=(actionType!='Update') ? (actionType=='Add' ? totalNewSize :  -totalNewSize) : totalNewSize-totalPreviousSize
        newFormula[key]['TOTAL_ATTACHMENT_SIZE']=(previousFormulaJson[key]['TOTAL_ATTACHMENT_SIZE'] || 0)+subValue
      }
      if(!previousValue && leadCustom[key] && actionType=='Update' || (actionType=='Add' && leadCustom[key]) || (actionType=='Remove' && !leadCustom[key])){
        if(field.includes('COUNT_EMPTY') && actionType!='Add') newFormula[key]['COUNT_EMPTY']=previousFormulaJson[key]['COUNT_EMPTY']-1;
        if(field.includes('COUNT_NONEMPTY') && actionType!='Remove' ) newFormula[key]['COUNT_NONEMPTY']=previousFormulaJson[key]['COUNT_NONEMPTY']+1;
        if(field.includes('PERCENT_EMPTY')){
          let count =actionType!='Add' ? 1 : 0
          let emptyCount=(((previousFormulaJson[key]['PERCENT_EMPTY'])/100)*totalLeadInGroup)-count;
          newFormula[key]['PERCENT_EMPTY']= (emptyCount/newTotal)*100
        }
        if(field.includes('PERCENT_NONEMPTY')){
          let count =actionType!='Remove' ? 1 : 0
          let emptyCount=(((previousFormulaJson[key]['PERCENT_NONEMPTY'])/100)*totalLeadInGroup)+ count;
          newFormula[key]['PERCENT_NONEMPTY']= (emptyCount/newTotal)*100
        }
      }else if(previousValue &&  !leadCustom[key] && actionType=='Update' || (actionType=='Add' && !leadCustom[key]) || (actionType=='Remove' && leadCustom[key])){
        if(field.includes('COUNT_EMPTY') && actionType!='Remove') newFormula[key]['COUNT_EMPTY']=previousFormulaJson[key]['COUNT_EMPTY']+1;
        if(field.includes('COUNT_NONEMPTY') && actionType!='Add') newFormula[key]['COUNT_NONEMPTY']=previousFormulaJson[key]['COUNT_NONEMPTY']-1;
        if(field.includes('PERCENT_EMPTY')){
          let count =actionType!='Remove' ? 1 : 0
          let emptyCount=((previousFormulaJson[key]['PERCENT_EMPTY']/100)*totalLeadInGroup) + count;
          newFormula[key]['PERCENT_EMPTY']= (emptyCount/newTotal)*100
        }
        if(field.includes('PERCENT_NONEMPTY')){
          let count =actionType!='Add' ? 1 : 0
          let emptyCount=((previousFormulaJson[key]['PERCENT_NONEMPTY']/100)*totalLeadInGroup)-count;
          newFormula[key]['PERCENT_NONEMPTY']= (emptyCount/newTotal)*100
        }
      }
      if(field.includes('MIN')){
        if((leadCustom[key]<previousFormulaJson[key]['MIN']) || (leadCustom[key]==previousFormulaJson[key]['MIN'] && actionType!='Remove')|| (previousFormulaJson[key]['MAX']===0 && leadCustom[key]!=null && newTotal==1)){
          newFormula[key]['MIN']=leadCustom[key]
        }else if(leadCustom[key]!=null){
          this.requireApiCall=true
        }
      } 
      if(field.includes('MAX')){
        if((leadCustom[key]>previousFormulaJson[key]['MAX']) || (leadCustom[key]==previousFormulaJson[key]['MAX']  && actionType!='Remove') || (previousFormulaJson[key]['MAX']===0 && leadCustom[key]!=null && newTotal==1)){
          newFormula[key]['MAX']=leadCustom[key]
        }else if(leadCustom[key]!=null){
          this.requireApiCall=true
        }
      } 
      if(field.includes('COUNT_UNIQUE') && leadCustom[key]){
        this.requireApiCall=true
      }
      if(field.includes('AVG')){
        let newAvg =0
        let oldEmptyCount=0
        let newEmptyCount=0
        if(newFormula[key]['COUNT_NONEMPTY']){ 
          oldEmptyCount=previousFormulaJson[key]['COUNT_NONEMPTY']
          newEmptyCount=newFormula[key]['COUNT_NONEMPTY']
        }
        else if(newFormula[key]['COUNT_EMPTY']){ 
          oldEmptyCount=totalLeadInGroup-previousFormulaJson[key]['COUNT_EMPTY']
          newEmptyCount=newTotal-newFormula[key]['COUNT_EMPTY']
        }
        else if(newFormula[key]['PERCENT_NONEMPTY']){ 
          oldEmptyCount=(previousFormulaJson[key]['PERCENT_NONEMPTY']/100)*totalLeadInGroup
          newEmptyCount=(newFormula[key]['PERCENT_NONEMPTY']/100)*totalLeadInGroup
        }
        else if(newFormula[key]['PERCENT_EMPTY']){ 
          oldEmptyCount=totalLeadInGroup-((previousFormulaJson[key]['PERCENT_EMPTY']/100)*totalLeadInGroup)
          newEmptyCount=newTotal-((newFormula[key]['PERCENT_EMPTY']/100)*newTotal)
          
        }
        else{this.requireApiCall=true }
        oldEmptyCount=oldEmptyCount<=0 ? 1 : oldEmptyCount
        newEmptyCount=newEmptyCount<=0 ? 1 : newEmptyCount
        newAvg=((previousFormulaJson[key]['AVG'] * oldEmptyCount)+((leadCustom[key] || 0)-(previousValue || 0)))/newEmptyCount
        newFormula[key]['AVG']=newAvg
      }
      newFormula[key]=[newFormula[key]]
    })
    return newFormula
  }
}
