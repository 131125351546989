<div class="editorParent" [class.disabled]="viewOnly">
    <div class="editorContainerBox" *ngIf="summerNoteEditor">
    <div class="editorContent">
        <ng-container  *ngIf="!multiSelect">
            <div style="flex-grow: 1;" class="editorForMultiSelect">
                <ng-container *ngIf="showEditor">
                    <div #editableDiv></div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container  *ngIf="(multiSelect && mediaArr?.length<=0 || !multiSelect)">
            <app-content-editable-input-note style="width: 100%;" [broadCastChannel]="broadCastChannel" [objFilterForMultiselect]="objFilterForMultiselect" [customFieldArr]="customFieldArr" [internalChat]="internalChat"
            [mentionEnabled]="mentionEnabled" [dashId]="dashId" [htmlContent]="editorText" [columnIndex]="columnIndex ? columnIndex : 0"
            [columnsJson]="columnsJson ? columnsJson : {}" [showAttachmentIcon]="true" (uploadFile)="fileChannel.value='';fileChannel.click()"
            [broadCastChannel]="broadCastChannel" [multiselect]="multiSelect" (onBlur)="editorText=$event.text;columnsJson=$event.columnsJson;columnIndex=$event.columnIndex;" (sendBroadCast)="sendBroadCast()" (closeEmit)="closeEmit()"></app-content-editable-input-note>
            <input #fileChannel type="file" class="inputFile" name="fileChannel" (change)="onUploadFile($event)" multiple>
        </ng-container>
        <div *ngIf="mediaArr?.length" class="mediaBox" [ngClass]="{'w-100:' : multiSelect}">
            <div class="mdaBlock mt-2 mb-1 f-l-r-r">
                <ng-container *ngFor="let media of mediaArr;let i= index">
                        <div class="mediaDiv" *ngIf="media.type=='image'" (click)="$event?.stopPropagation();getMediaIndex(i)"><img  style="width:100px;height:100px;object-fit: contain;" [src]="media?.url ? media?.url : media?.path"><span (click)="removeFile(i)" class="dltin"><img  src="../../../assets/1x/image-delete-ico.png" class="deleteico"></span>
                            <span class="fileNameU" 
                            style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                            {{media?.file_name}}</span>
                            <div class="middle" *ngIf="!multiSelect">
                                <button type="button"  class="btn image-options-for-singleimage" (click)="open(previewModal,media._id,i)">
                                <img src="../../../../assets/1x/doc-view.png" />
                                </button> 
                            </div>
                        </div>
                        <div class="mediaDiv" *ngIf="media.type=='video'" (click)="$event?.stopPropagation();getMediaIndex(i)"> <video style="width:100px;height:100px;" [src]="media?.url ? media?.url : media?.path"></video><span class="dltin" (click)="removeFile(i)"><img src="../../../assets/1x/image-delete-ico.png" class="deleteico"></span>
                            <span class="fileNameU" 
                            style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                            {{media?.file_name}}</span>
                            <div class="middle" *ngIf="!multiSelect">
                                <button type="button"  class="btn image-options-for-singleimage" (click)="open(previewModal,media._id,i)">
                                <img src="../../../../assets/1x/doc-view.png" />
                                </button> 
                            </div>
                        </div>
                        <div class="mediaDiv" *ngIf="media.type!='video' && media.type!='image'" (click)="$event?.stopPropagation();getMediaIndex(i)"><app-dashboard-icon [name]="(mediaIcons.hasOwnProperty(media?.file_name?.split('.').pop())) ? media?.file_name?.split('.').pop() :'txt'" [iconJson]="mediaIcons" size="95"></app-dashboard-icon> 
                          <span class="dltin" (click)="removeFile(i)"><img src="../../../assets/1x/image-delete-ico.png" class="deleteico"></span>
                            <span class="fileNameU" 
                            style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                            {{media?.file_name}}</span>
                            <div class="middle" *ngIf="!multiSelect">
                                <button type="button"  class="btn image-options-for-singleimage" (click)="open(previewModal,media._id,i)">
                                <img src="../../../../assets/1x/doc-view.png" />
                                </button> 
                            </div>
                        </div>  
                </ng-container> 
            </div>
                <div *ngIf="multiSelect" class="editorDivLoad">
                    <ng-container *ngIf="multiSelect && selectedIndex!=null">
                    <app-content-editable-input-note style="width: 100%;" [broadCastChannel]="broadCastChannel" [objFilterForMultiselect]="objFilterForMultiselect" [customFieldArr]="customFieldArr" [broadCastChannel]="broadCastChannel" [mentionEnabled]="mentionEnabled"
                    [dashId]="dashId" [htmlContent]="mediaArr[selectedIndex]?.caption" [columnIndex]="columnIndex ? columnIndex : 0"
                    [columnsJson]="columnsJson ? columnsJson : {}" [showAttachmentIcon]="true" (uploadFile)="commentFileChannel.value='';commentFileChannel.click()"
                    [multiselect]="multiSelect" (onBlur)="mediaArr[selectedIndex].caption=$event.text;columnsJson=$event.columnsJson;columnIndex=$event.columnIndex;" (sendBroadCast)="sendBroadCast()" (closeEmit)="close.emit();"></app-content-editable-input-note>
                    <input #commentFileChannel type="file" class="inputFile" name="commentFileChannel" (change)="onUploadFile($event)" multiple>
                    </ng-container>
                </div>
        </div>
    </div>
    <div class="editorBottom" *ngIf="(multiSelect && mediaArr?.length<=0 || !multiSelect)">
        <div class="w-100 columnBox" *ngIf="showEditor">
             <span class="cursorPointer" id="columnBtn" #columnBtn [ngbPopover]="customColumns" #popColumns="ngbPopover" [autoClose]="'outside'">{{ '\{\{ \}\}'}}</span>
             <input #attachFile type="file" class="inputFile" name="commentFile" (change)="onUploadFile($event)" multiple>
        </div>
    </div>
    </div>

    <div *ngIf="!summerNoteEditor" style="display: flex;">
        <div class="editorContentForSubject">
            <div style="width:90%;outline: none;" placeholder="Enter subject" contenteditable="true" #editableDiv
            (blur)="getCareAtPositionForComment(i,$event.target.id)"></div>
            <div class="subjectColumn"><span class="cursorPointer" [ngbPopover]="customColumns" #popColumns="ngbPopover" container="body" [autoClose]="'outside'" (click)="mediaDisable=true;summerNoteEditor=false;">{{ '\{\{ \}\}'}}</span></div>
        </div>
    </div>
</div>


<ng-template #customColumns>
    <ng-container *ngIf="type!='EMAIL'">  
  <app-columns-chip [dashId]="dashId" [mediaDisable]="mediaDisable" (sendColumnEmit)="sendColumnEmit($event)"></app-columns-chip>
   </ng-container>

  <ng-container *ngIf="type=='EMAIL'">
    <lead-columns-picker [dashId]="dashId" [leadObj]="leadObj" (columnValueemit)="columnValueemit($event)" (mediaEmitForColumns)="mediaEmitForColumns($event)"></lead-columns-picker>
  </ng-container>
</ng-template>


<ng-template #previewModal let-modal>
    <div class="previewBodyModal" style="height: 100%;max-width: 100%;">
      <i class="fa fa-close bg-white previewCross" (click)="modal.dismiss()"></i>
      <app-file-preview [mediaArr]="filePrevMedia" [index]="previewIndex" [type]="'CHANNEL_TEMPLATES'" [dashId]="dashId" [typeId]="templateId"></app-file-preview>
    </div>
  </ng-template>