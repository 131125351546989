import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AttributesIcons } from '../../../../../Enums/attributes-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonUtils } from '../../../../services/CommonUtils.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-dashboard-icon',
  template: `<img *ngIf="icon && !isSvg" class="icon-library" [src]="icon" [ngStyle]="{'height': size+'px' ,'width': size+'px'}">
  <div *ngIf="icon && isSvg" #divsvg></div>`,
  styles: [`.icon-library{max-width: 100px;}`]
})
export class DashboardIconComponent implements OnInit {
  icon: string;
  @Input() iconJson=AttributesIcons
  @Input() size = 16;
  @Input() name;
  @Input() isSvg:boolean=false;
  @Input() color:any='#3571de'
  @ViewChild('divsvg') divsvg: ElementRef;
  url:any;
  constructor(public sanitizer : DomSanitizer,public commonUtils : CommonUtils,private http: HttpClient,) {
   }
  ngOnInit(): void {
    if(this.iconJson)this.icon = this.iconJson[this.name]
    if(!this.icon){
      this.icon = "assets/1x/automation-icon-3571de.svg"
    }
  }
  ngAfterViewInit(){
    if(this.isSvg)this.loadSvg();
  }

  private loadSvg(): void {
    this.http.get(this.icon, { responseType: 'text' }).subscribe(svgContent => {
      // Create an SVG element from the string
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(svgContent, 'image/svg+xml');
      const svgElement = svgDocument.querySelector('svg');
      if (svgElement) {
        // Change color if specified
        if (this.color) {
          const paths = svgElement.querySelectorAll('path');
          paths.forEach(path => {
            path?.setAttribute('stroke',this.color)
          });
        }
        if (this.size){
          svgElement.setAttribute('width',`${this.size}px`)
          svgElement.setAttribute('height',`${this.size}px`)
        }
        // Append the SVG to the div
        this.divsvg?.nativeElement.appendChild(svgElement);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['name']){
      if(this.iconJson) this.icon = this.iconJson[this.name]
      if(!this.icon){
        this.icon = "assets/1x/automation-icon-3571de.svg"
      }
      if(this.isSvg){
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.icon);
      }
    }
  }

 
 
  handleSvgLoad(target: EventTarget) :void {
    const objectElement = target as HTMLObjectElement;
    const svgElement = objectElement.contentDocument?.querySelector('svg');
    let elementsWithFill = svgElement.querySelectorAll('[fill]');
    if (!elementsWithFill.length) {
      elementsWithFill = svgElement.querySelectorAll('[stroke]');
    }
    elementsWithFill.forEach(element => {
      const fillValue = element.getAttribute('fill');
      const strokeValue = element.getAttribute('stroke');
      const svgFillValue = svgElement.getAttribute('fill');
      const svgStrokeValue = svgElement.getAttribute('stroke');
      if (fillValue && fillValue !== 'none') {
        element.setAttribute('fill', this.color) // Replace "red" with the desired color
      }
      if (strokeValue && strokeValue !== 'none') {
        element.setAttribute('stroke',this.color) // Replace "blue" with the desired color
      }
      if (svgFillValue !== 'none') {
        svgElement.setAttribute('fill', this.color)
         // Replace "red" with the desired color
      }
      if (svgStrokeValue) {
        svgElement.setAttribute('stroke',this.color) 
        element.setAttribute('stroke', 'none')
        // Replace "blue" with the desired color
      }
    });
    if (svgElement) {
      svgElement.addEventListener('click', (event: Event) => this.propagateClickToParent(event, target));
    }
  } 
    
  propagateClickToParent(event: Event,target) {
    event?.preventDefault();
    event?.stopPropagation();
    (target as HTMLObjectElement).dispatchEvent(new MouseEvent('click', { bubbles: true }));
  }

}
