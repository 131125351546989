import { ComponentFactoryResolver, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { ReusableModule } from '../../reusable.module/reusable.module';
import { LeadAssignUserComponent } from './lead-assign-user/lead-assign-user.component';
import { LeadBucketChangeComponent } from './lead-bucket-change/lead-bucket-change.component';
import { LeadColloboratorsComponent } from './lead-colloborators/lead-colloborators.component';
import { LeadDatePickerComponent } from './lead-date-picker/lead-date-picker.component';
import { LeadDependencyComponent } from './lead-dependency/lead-dependency.component';
import { LeadModalHeaderComponent } from './lead-modal-header/lead-modal-header.component';
import { LeadOptionTemplateComponent } from './lead-option-template/lead-option-template.component';
import { LeadSprintComponent } from './lead-sprint/lead-sprint.component';
import { LeadTagsComponent } from './lead-tags/lead-tags.component';
import { CreateLeadModalComponent } from './create-lead-modal/create-lead-modal.component';
import { SharedComponentsModule } from '../shared-components/shared.components.module';
import { EditCustomComponent } from './editCustom/components/editCustom.component';
import { LeadPriorityComponent } from './lead-priority/lead-priority.component';
import { ItemFullPopupComponent } from './item-full-popup/item-full-popup.component';
import { ItemListComponent } from './item-list/item-list.component';
import { DpDatePickerModule} from 'ng2-date-picker';
import { AutosizeModule } from 'ngx-autosize';
import { FullItemDependencySubTaskCheckListComponent } from './full-item-dependency-sub-task-check-list/full-item-dependency-sub-task-check-list.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { SearchItemsComponent } from '../../reusable/search-items/search-items/search-items.component';
import { LinkyModule } from 'ngx-linky';
import { TimelineComponent } from '../../reusable/timeline/timeline.component';
import { NotesViewComponent } from '../../views/dashboard/notes-view/notes-view.component';
import { AttachmentComponent } from '../../reusable/attachment/attachment.component';
import { ParentChildLeadComponent } from '../../reusable/parent-child-lead/parent-child-lead.component';
import { UserSelectedComponent } from '../shared-components/multi-user/user-selected.component';
import { MarkDuplicateLeadComponent } from '../../reusable/mark-duplicate-lead/mark-duplicate-lead.component';
import { NotesEditorComponent } from '../../views/dashboard/notes-view/notes-editor/notes-editor/notes-editor.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { CommunicateComponent } from '../shared-components/communicate/communicate/communicate.component';
import { LeadItemAttributeComponent } from './lead-item-attribute/lead-item-attribute.component';

import { SinglePhaseComponent } from '../dashboard-reusables/single-phase/single-phase.component';
import { TimeTrackingComponent } from './time-tracking/time-tracking.component';
// import { LocationItemSettingsComponent } from './location-item-settings/location-item-settings.component';
import { ChecklistSettingComponent } from './checklist-setting/checklist-setting.component';
import { ItemListGridComponent } from './item-list-grid/item-list-grid.component';
import { TranslateModule } from '@ngx-translate/core';
import { LeadMappingComponent } from './lead-mapping/lead-mapping.component';
import { IncludeCountrySelectionComponent } from './include-country-selection/include-country-selection.component';
import { ItemViewComponent } from './item-view/item-view.component';
import { FiltersModule } from 'app/views/filters/filters.module';
import { SubtaskConnectboardFilterComponent } from './subtask-connectboard-filter/subtask-connectboard-filter';
// import { FiltersComponent } from 'app/views/filters/filters.component';
// import { DriveAttachmentComponent } from './drive-attachmnet-selection/drive-attachment.component';
import { RightPopupComponent } from 'app/right-popup/right-popup.component';
import { AppViewModule } from '../app-view/app-view.module';
import { NonEmbeddedViewOptionComponent } from './non-embedded-view-option/non-embedded-view-option.component';
import { ContactAndOrganizationBoardComponent } from './contact-and-organization-board/contact-and-organization-board.component';
import { MultipleMobileHandleComponent } from './multiple-mobile-handle/multiple-mobile-handle.component';
import { QlcNoteOptionsComponent } from 'app/views/dashboard/notes-view/qlc-note-options/qlc-note-options.component';
import { QlTaskRenderComponent } from 'app/views/dashboard/notes-view/ql-task-render/ql-task-render.component';

@NgModule({
  declarations: [
    LeadAssignUserComponent,
    LeadBucketChangeComponent,
    LeadColloboratorsComponent,
    LeadDatePickerComponent,
    LeadDependencyComponent,
    LeadModalHeaderComponent,
    LeadOptionTemplateComponent,
    LeadSprintComponent,
    LeadTagsComponent,   
    CreateLeadModalComponent,
    EditCustomComponent,
    LeadPriorityComponent,
    ItemFullPopupComponent,
    ItemListComponent,
    FullItemDependencySubTaskCheckListComponent,
    SearchItemsComponent,
    TimelineComponent,
    NotesViewComponent,
    AttachmentComponent,
    ParentChildLeadComponent,
    UserSelectedComponent,  
    MarkDuplicateLeadComponent,
    NotesEditorComponent,
    CommunicateComponent,
    LeadItemAttributeComponent,
    SinglePhaseComponent,
    // LocationItemSettingsComponent,
    TimeTrackingComponent,
    ChecklistSettingComponent,
    ItemListGridComponent,
    LeadMappingComponent,
    IncludeCountrySelectionComponent,
    ItemViewComponent,
    SubtaskConnectboardFilterComponent,
    // DriveAttachmentComponent,
    RightPopupComponent,
    NonEmbeddedViewOptionComponent,
    ContactAndOrganizationBoardComponent,
    MultipleMobileHandleComponent,
    QlTaskRenderComponent,
    QlcNoteOptionsComponent
    // FiltersComponent
  ],
  exports: [
    LeadAssignUserComponent,
    LeadBucketChangeComponent,
    LeadColloboratorsComponent,
    LeadDatePickerComponent,
    LeadDependencyComponent,
    LeadModalHeaderComponent,
    LeadOptionTemplateComponent,
    LeadSprintComponent,
    LeadTagsComponent,
    CreateLeadModalComponent,
    EditCustomComponent,
    LeadPriorityComponent,
    ItemFullPopupComponent,
    ItemListComponent,
    FullItemDependencySubTaskCheckListComponent,
    SearchItemsComponent,
    TimelineComponent,
    NotesViewComponent,
    AttachmentComponent,
    ParentChildLeadComponent,
    UserSelectedComponent,  
    MarkDuplicateLeadComponent,
    NotesEditorComponent,
    QlTaskRenderComponent,
    CommunicateComponent,
    LeadItemAttributeComponent,
    SinglePhaseComponent,
    // LocationItemSettingsComponent,
    TimeTrackingComponent,
    ChecklistSettingComponent,
    LeadMappingComponent,
    IncludeCountrySelectionComponent,
    ItemViewComponent,
    SubtaskConnectboardFilterComponent,
    // DriveAttachmentComponent,
    RightPopupComponent,
    ItemListGridComponent,
    NonEmbeddedViewOptionComponent,
    ContactAndOrganizationBoardComponent,
    MultipleMobileHandleComponent
    // FiltersComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReusableModule,
    PopoverModule.forRoot(),
    NgSelectModule,
    NgbModule, 
    InfiniteScrollModule,
    SharedComponentsModule,
    DpDatePickerModule,
    AutosizeModule,
    LinkyModule,
    NgxSummernoteModule,
    NgxDocViewerModule,
    ModalModule.forRoot(),
    FormsModule, ReactiveFormsModule,
    TranslateModule,
    FiltersModule,
    AppViewModule
  ]
})
export class LeadReusableModule {
}
