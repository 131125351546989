import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ConstantService } from '../../../../Enums/Constant.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from '../../../services/toaster.service';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ActivityIcons, commonIcons } from 'Enums/attributes-icons';

@Component({
  selector: 'app-item-bulk-selection',
  templateUrl: './item-bulk-selection.component.html',
  styleUrls: ['./item-bulk-selection.component.scss']
})
export class ItemBulkSelectionComponent implements OnInit {
  @Input('dashUsers') users: any[];
  @Input('dashId') selectedDashId: any;
  @Input('leadInfo') bulkLeadInfo: any[];
  @Input('bucketInfo') bulkBucketInfo: any[];
  @Input('dashboardTags') tagsCorrespondingToDash: any[];
  @Input('dashBoardSprint') dashBoardSprint: any[];
  @Input() bulkSelectionData: any[];
  @Input() bucketArray: any[];
  @Input() isSelectedAll:boolean=false
  @Input() filterDataObj: any;
  @Input() multiSelectGroupArray: any;
  @Input() excludeInMultiselectBucket: any;
  @Input() funnelarr:any;
  @Output() updateLeadBucket = new EventEmitter<any>();
  @Output() commentImageData = new EventEmitter<any>();
  @Output() clearDate = new EventEmitter<any>();
  @Output() updateDate = new EventEmitter<any>();
  @Output() bulkOperationsCompleted= new EventEmitter();
  @Output() multiSelectLeadIds = new EventEmitter<any>();
  commentMediaArr:any[]=[]
  @Output() removeListItem = new EventEmitter<any>();
  @Input() isBoardListUpdate: boolean=false;
  @Input() boardListDetail: any;
  selectedStartDate: Date;
  sprintEnabed:boolean=false
  selectedEndDate: Date;
  leadUpdateConstant: string;
  dragLeadConstant: string;
  deleteLeadConstant: string;
  createLeadConstant: string;
  refreshLeadConstant: string;
  isDateActive: boolean = true;
  datePickerConfig = {
    format: "DD-MM-YYYY",
    locale: moment.locale(),
    closeOnSelect: false,
  };
  dashboardUserRole: any[] = [];
  objFilterForMultiselect:any;
  groupKeyJson:any;
  selectedGroupBy:any
  itemAttributesObj: any;
  itemTerminology: any={};
  mergeItemModalRef:any
  dashboardChannels: any[]=[];
  activityIcons = ActivityIcons;
  broadCastChannel:any;
  columnIndex:any=0;
  columnsJson:any={};
  commentObj:any;
  customFieldArr: any[]=[];
  commonIcons=commonIcons;
  selectedField: any;
  attachment: any[]=[];
  fileEvent: any[]=[];
  tempMediaArray: any[]=[];
  dashInfo: any={};
  constructor(private constantService:ConstantService, private httpTransfer: HttpTransferService, private dashboardUtils: DashboardUtilsService,private commonutils : CommonUtils,    private ngbModalService: NgbModal,
    ) {
    this.leadUpdateConstant = this.constantService.getLeadUpdateConstant();
    this.dragLeadConstant = this.constantService.getDragLeadConstant();;
    this.deleteLeadConstant = this.constantService.getDeleteLeadConstant();
    this.createLeadConstant = this.constantService.getCreateLeadConstant();
    this.refreshLeadConstant = this.constantService.getRefreshLeadConstant();
    
    
   }

  async ngOnInit() {
    $('#bottombarPanel')?.addClass('disableBottombar') 
    this.itemTerminology=this.dashboardUtils.getDashboardTerminoloy(this.selectedDashId)
    this.groupKeyJson=this.constantService.getGroupKeyJson()
    let dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.selectedDashId])
    console.log(dashboardInfo)
    this.dashInfo = this.dashboardUserRole = dashboardInfo[this.selectedDashId]?.DASHBOARD_INFO || {}
    this.dashboardUserRole = dashboardInfo[this.selectedDashId]?.DASHBOARD_INFO?.role || [];
    this.sprintEnabed = dashboardInfo[this.selectedDashId]?.DASHBOARD_INFO?.sprint_enabled
    this.objFilterForMultiselect = this.commonutils.getFilterJsonForQuery({}, this.filterDataObj);
    this.objFilterForMultiselect= JSON.parse(JSON.stringify(this.objFilterForMultiselect))
    let existingCustomFields = await this.dashboardUtils.getDashboardExistingCustomFormIfPresent(this.selectedDashId);
    if(existingCustomFields?.length)this.itemAttributesObj = this.commonutils.handleExistingItemAttributes(existingCustomFields)
    let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.selectedDashId)
    let dashJson = JSON.parse(JSON.stringify(dashboardJson))
    this.customFieldArr = JSON.parse(JSON.stringify(dashJson[this.selectedDashId]['CUSTOM_FORM'])) || {};
      await  this.getChannels()
  }


  filterObjectForMultiselect(selectedField){
    this.selectedField = selectedField
    let leadIdArr=[]
        this.objFilterForMultiselect = this.commonutils.getFilterJsonForQuery({}, this.filterDataObj);
        let leadIds=[]
        if(this.funnelarr){
          for(var j=0;j<this.funnelarr.length;j++){
            leadIds=[...leadIds,...this.funnelarr[j].leadIds]
          }
          for (var i = 0; i < this.bulkSelectionData.length; ++i) {
            if(leadIds.includes(this.bulkSelectionData[i].leadInfo._id)){
              leadIdArr.push(this.bulkSelectionData[i].leadInfo._id)
            }
          }
        }
        else{
          for (var i = 0; i < this.bulkSelectionData.length; ++i) {
              leadIdArr.push(this.bulkSelectionData[i].leadInfo._id)
            
          }
        }
        if(this.multiSelectGroupArray && this.multiSelectGroupArray.length>0){
          
          let groupBy=this.objFilterForMultiselect?.grouping_details?.group_by || "bucket"
          // if(groupBy){ this.objFilterForMultiselect.grouping_details['max_group_size']=null}
          if(this.objFilterForMultiselect.grouping_details){
            delete this.objFilterForMultiselect.grouping_details
          }
          this.selectedGroupBy=this.groupKeyJson[groupBy]
          if(groupBy && !this.groupKeyJson[groupBy]){
            let selectedGroupArray=[...this.multiSelectGroupArray]
            if(selectedGroupArray?.includes(null)){selectedGroupArray.push("")} // empty field on case of empty string and null value
            this.objFilterForMultiselect["custom_column"]={[groupBy]:selectedGroupArray}
          }
          else if(this.selectedGroupBy.filter_key!=null){
            this.objFilterForMultiselect[this.selectedGroupBy.filter_key]=this.multiSelectGroupArray
          }
          this.objFilterForMultiselect["exclude_item_id"]=this.excludeInMultiselectBucket
          this.multiSelectLeadIds.emit([])
          
        }
        else{
          this.objFilterForMultiselect["lead_id"]=leadIdArr
          this.multiSelectLeadIds.emit(leadIdArr)
        }

  }

  setCurrentAndPreviousBucket(leadId,destinationBucketId,bucketId) {
    this.updateLeadBucket.emit({leadId:leadId,destinationBucketId:destinationBucketId,bucketId:bucketId})
  }

  onGettingImageData(imageData) {
    this.commentImageData.emit({imageData:imageData})
  }

  onStartDateClick() {
    this.isDateActive = false;
  }
  onEndDateClick() {
    this.isDateActive = true;
  }

  openDeleteItemModal(mpodalContent) {
    this.ngbModalService.open(mpodalContent, { size : 'sm'});
  }

  deleteItemInBulk(isDelete,isPermenentDelete?) {

    if(this.bulkLeadInfo.length){
       
      let inputJson={}
      if(this.isBoardListUpdate) {
        inputJson["item_id"] = this.objFilterForMultiselect.lead_id;
        inputJson["item_id_operation"] = "REMOVE"
        this.httpTransfer.updateBoardList(this.boardListDetail.dashboard_id, this.boardListDetail._id, inputJson).subscribe((res : any) => {
          this.closeBulkModeOperation();
          this.removeListItem.emit(inputJson);
      })       
      }else {
        inputJson['filter_properties'] = this.objFilterForMultiselect
        if (isPermenentDelete) {
          inputJson['update_json'] = {
            "is_permanently_deleted": isPermenentDelete
          }
        }
        else {
          inputJson['update_json'] = {
            "is_deleted": isDelete
          }
        }
        this.httpTransfer.updateMultipleDashboard(this.selectedDashId,inputJson).subscribe(res=>{
        if(res.status == 200){
          this.closeBulkModeOperation()
        }
      })
      }
    }
   
  }

  movingToTrash(leadId, bucket?) {
    var leadObj = {};
    leadObj["lead_id"] = [leadId];
    this.httpTransfer
      .deleteLead(this.selectedDashId , leadId)
      .subscribe((res : any) => {
        if (res.status === 200) {
          this.closeBulkModeOperation();
         // this.toaster.Success(res.result.message);
          // this.updateBoardView();
        }
      });
  }
  
  trashBucketId(trashBucketId: any, leadObj: {}, selectedDashId: number) {
    throw new Error('Method not implemented.');
  }

  closeBulkModeOperation(){
    this.bulkOperationsCompleted.emit();
  }
  ngOnDestroy(){
    $('#bottombarPanel')?.removeClass('disableBottombar') 
  }

  openMergeIemModal(mpodalContent) {
    this.mergeItemModalRef=this.ngbModalService.open(mpodalContent, { size : 'xl',windowClass: 'mergeModal'});
  }


  async getChannels(){
    let dashboardJson = await this.dashboardUtils.getDashboardChannels([this.selectedDashId])
    let dashJson = JSON.parse(JSON.stringify(dashboardJson))
    this.dashboardChannels = dashJson[this.selectedDashId]['CHANNELS'] || [];  
    console.log("dashboard-channel",this.dashboardChannels)
  }

  getData(event){
    console.log(event)
  }

  onBlurEmit(event){
    console.log(event)
    this.tempMediaArray = event?.mediaArr
    this.fileEvent = event?.fileEvent
    this.attachment = event?.attachment || []
    //this.emailBody = event?.emailBody
   }

  
   sendBroadCastMessage(){
    /*   if(this.tempMediaArray.length && this.fileEvent?.length){
        this.commonUtils.onUploadFile(this.fileEvent, 'LEAD',null, this.setFilePathToArrOfCustomFileds.bind(this));
      } 
      else{
        this.sendEmail() 
      } */
   }
   

}
