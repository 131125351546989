
      <div class="modal-body py-3">
        <form autocomplete="off" (ngSubmit)="onSubmit()">
            <!-- User Name -->
          <div class="form-group">
            <label for="userName">{{'email.userName' | translate}}</label>
            <input type="text" class="form-control" autocomplete="false" id="userName" name="userName1" [(ngModel)]="userName" required>
          </div>
  
          <!-- Password -->
          <div class="form-group">
            <label for="password">{{'email.Password' | translate}}</label>
            <input type="password" class="form-control" autocomplete="false" id="password" name="password1" [(ngModel)]="password" required>
          </div>

          <div class="row"> 

            <div class="form-group col-6">
              <label for="smtpServerName">{{'email.smtpservername' | translate}}</label>
              <input type="text" class="form-control" id="smtpServerName" name="smtpServerName" [(ngModel)]="smtpServerName" required>
            </div>
            <div class="form-group col-6">
              <label for="smtpPort">{{'email.smtpport' | translate}}</label>
              <input type="number" class="form-control" id="smtpPort" name="smtpPort" [(ngModel)]="smtpPort" required>
            </div>
          </div>
          <!-- SMTP Server Name -->
  
          <!-- SMTP Port -->
  
          <div class="row">
            <!-- IMAP Server Name -->
            <div class="form-group col-6">
              <label for="imapServerName">{{'email.imapservername' | translate}}</label>
              <input type="text" class="form-control" id="imapServerName" name="imapServerName" [(ngModel)]="imapServerName" required>
            </div>
    
            <!-- IMAP Port -->
            <div class="form-group col-6">
              <label for="imapPort">{{'email.imapport' | translate}}</label>
              <input type="number" class="form-control" id="imapPort" name="imapPort" [(ngModel)]="imapPort" required>
            </div>
          </div>
          <div class="float-right p-2">
            <button type="submit" class="btn btn-primary mr-3" >{{'email.save' | translate}}</button>
            <button type="button" class="btn btn-secondary" (click)="closeModal('cancelCall')">{{'email.cancel' | translate}}</button>
          </div>
        </form>
      </div> 