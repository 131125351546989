
<div class="selection-input-box" [class.key-focus]="!isGrid" (click)="!value?.country_code && (fieldType!=customFieldType?.MOBILE && fieldType!='MultipleMobile') && countryPopover?.open()" [placement]="['bottom','bottom-right','bottom-left','auto']" #countryPopover="ngbPopover" [container]="isGrid ? 'body' : ''"  popoverClass="gridtableCell" [ngbPopover]="editable && countryTemplate" [autoClose]="'outside'" triggers="manual" (shown)="searchText='';handeKeyInput('Add')" (hidden)="handeKeyInput()" [tabindex]="isGrid ? -1 :0">
  <!-- for mobile and currency? -->
  <div *ngIf="fieldType==customFieldType?.CURRENCY" class="cell-box-inner" #errorPopover="bs-popover" placement="bottom" container="body" [popover]="errorPopoverTemp" [outsideClick]="true" triggers="manual" [ngClass]="{'red-border': (!(value.value < 0 || value.value > 0 || value.value == 0 || value.value== null) || valueField?.invalid) && (valueField?.dirty || valueField?.touched) ,'enable-editor-box': countryPopover?.isOpen() || enableEditor }">
      <div class="left-box">
          <div class="value-wrapper cell-input-box" [ngbPopover]="!countryPopover?.isOpen() && !enableEditor && (countryData[value?.country_code] || editable) && selectedInfoTemp" triggers="hover" [placement]="['top','top-left','top-right','auto']" [positionTarget]="codeDiv">
            <span class="country-code" (click)="countryPopover?.open();searchTextClear(searchText)" #codeDiv>
              <ng-container *ngIf="countryData[value?.country_code];else codeText" >
                <ng-template *ngTemplateOutlet="dialCodeTemp"></ng-template>
              </ng-container>
              <ng-template #codeText>
                <app-dashboard-icon   [name]="fieldType" [size]="14"></app-dashboard-icon>
              </ng-template>
            </span>
            <div class="w-100" [ngStyle]="{'display':!value?.country_code ? 'none' :''}" (click)="editable && enableEditor=true">
              <input #inputBox class="value-input" placeholder="{{(fieldType==customFieldType?.MOBILE ? 'placeholders.enterNumber' : 'placeholders.enterAmount' ) | translate}}" [(ngModel)]="value.value"  #valueField="ngModel"  [disabled]="!editable || !value?.country_code || fieldType==customFieldType?.COUNTRY" 
              (input)="(!(value.value < 0 || value.value > 0 || value.value == 0 || value.value == null) || valueField?.invalid) && (valueField?.dirty || valueField?.touched) ? errorPopover?.show() : errorPopover?.hide()"  
              (blur)="onValueInput(value)" 
              (keydown.enter)="$event.preventDefault();onValueInput(value)">
            </div>
            <div class="text-box w-100" style="opacity: 0.5;" *ngIf="!value?.country_code">
              <ng-container *ngIf="['Mobile','MultipleMobile'].includes(fieldType)">{{'placeholders.enterNumber' | translate}}</ng-container>
              <ng-container *ngIf="fieldType==customFieldType?.CURRENCY">{{'placeholders.enterAmount' | translate}}</ng-container>
            </div>
          </div>  
          <div class="cell-value-box" *ngIf="value?.country_code">
            <ng-template *ngTemplateOutlet="dialCodeTemp"></ng-template>
            <ng-contanier *ngIf="value?.value;else emptyInput">{{' '+value?.value}}</ng-contanier>
            <ng-template #emptyInput>
              <span style="opacity: 0.3;" *ngIf="['Mobile','MultipleMobile'].includes(fieldType)"> 0000000000</span>
              <span style="opacity: 0.3;" *ngIf="fieldType==customFieldType?.CURRENCY"> 0000</span>
            </ng-template>
          </div>
        </div> 
  </div>
  <!-- for country -->
  <div *ngIf="fieldType==customFieldType?.COUNTRY" class="cell-box-inner cursorPointer" [class.key-focus]="!isGrid"  [ngClass]="{'enable-editor-box': countryPopover?.isOpen() || enableEditor }" (click)="countryPopover?.open();searchTextClear(searchText)" [tabindex]="isGrid ? -1 :0">
    <div class="left-box">
        <div class="value-wrapper cell-input-box" *ngIf="!value?.country_code">
          <span class="country-code"><app-dashboard-icon   [name]="fieldType" [size]="14"></app-dashboard-icon></span>
          <div class="text-box w-100" style="opacity: 0.5;">{{'placeholders.selectConutry' | translate}}</div>
        </div>  
        <div class="text-box" *ngIf="value?.country_code">
          {{countryData[value?.country_code]?.flag+' '+(value?.value || '')}}
        </div>
    </div> 
  </div>
</div>

<div *ngIf="['MultipleMobile', 'Mobile'].includes(fieldType)" class="outlineBlue">
  <ng-container *ngIf="editEnabled">
    <div class="w-100" *ngIf="value?.country_code" class="d-flex justify-content-between">
      <div [ngClass]="{'isGridView': isGrid}">
        <span (click)="countryPopover?.open();searchTextClear(searchText)"><ng-template *ngTemplateOutlet="dialCodeTemp"></ng-template></span>
        <input #inputBox class="value-input w-auto p-0 pl-1" style="width: 10rem !important;" placeholder="{{'placeholders.enterNumber'| translate}}" [(ngModel)]="newNumber" #valueField="ngModel" [ngbPopover]="errorPopoverTemp" #errorPopover="ngbPopover" [autoClose]="false" container="body"
        (input)="(!(newNumber < 0 || newNumber > 0 || newNumber == 0 || newNumber == null) || valueField?.invalid) && (valueField?.dirty || valueField?.touched) ? errorPopover?.open() : errorPopover?.close()" 
        (keydown.enter)="$event.preventDefault();value.value=newNumber;onValueInput(value);editEnabled=false">
      </div>
      <div class="d-inline-flex justify-content-between gap-2">
        <button style="background-color: green;border-radius: 5px;border: none;" [ngClass]="{'editIcon': isGrid }"><i class="fa fa-check" aria-hidden="true" (click)="value.value=newNumber;onValueInput(value);editEnabled=false;searchText='';countryList=copyCountryCode"></i></button>
        <button style="background-color: red ;border-radius: 5px;border: none;" [ngClass]="{'editIcon': isGrid }"><i class="fa fa-times" aria-hidden="true" (click)="editEnabled=false;searchText='';countryList=copyCountryCode"></i></button>
      </div>
    </div>
    <i *ngIf="!value?.country_code" class="faded d-block" (click)="countryPopover?.open()">Enter country code</i>
  </ng-container>
  <ng-container *ngIf="!editEnabled">
    <div class="position-relative">
      <div [ngClass]="{'valueInGrid': isGrid}">
        <ng-container *ngIf="value?.country_code && value?.value;else notCallable">
          <a href="tel://{{countryData[value?.country_code]?.dial_code}}{{value.value}}" class="colorFlag">{{countryData[value?.country_code]?.dial_code}} {{value.value}}</a>
        </ng-container>
        <ng-template #notCallable>
          <span (click)="editEnabled=true; !value.country_code && countryPopover?.open()">
            <ng-container *ngIf="value?.country_code"><ng-template *ngTemplateOutlet="dialCodeTemp"></ng-template></ng-container>
            <ng-contanier *ngIf="value?.value;else emptyInput">{{' '+value?.value}}</ng-contanier>
            <ng-template #emptyInput>
              <span style="opacity: 0.3;"> 0000000000</span>
            </ng-template>
          </span>
        </ng-template>
      </div>
      <div class="absolutePos" *ngIf="value?.country_code && value?.value">
          <span #WTRef="ngbPopover" [ngbPopover]="whatsAppTemplates" [autoClose]="'outside'" container="body"></span>
            <ng-template #whatsAppTemplates>
              <app-channel-template-picker [leadInfo]="selectedLeadObj" [mobileField]="true" [dashId]="dashId" [sprintArr]="sprintArr" [orgUsersJson]="orgUsersJson" (sendTemplateEmit)="openTemplateEditor($event);WTRef.close();WTEditRef.open()">
              </app-channel-template-picker>
              <a class="btn btn-primary webButton mt-2" style="margin-left: 37px;" (click)="WTRef.close()" [href]="getWhatsappLink(countryData[value?.country_code]?.dial_code, value.value)" target="_blank"><span class="mx-auto">Write your own text</span></a>
            </ng-template>
          <span #WTEditRef="ngbPopover" [ngbPopover]="editTemplate" [autoClose]="'outside'" container="body"></span>
          <div *ngIf="!isGrid" class="d-inline-flex justify-content-between gap-1 editOption" style="background-color: whitesmoke;">
            <i *ngIf="value.country_code && value.value && !isReadOnly && showWhatsappredirect" role="button" class="fa fa-whatsapp mr-1 icons" aria-hidden="true" (click)="WTRef.open()"></i>
            <i class="fa fa-clone mr-1 icons" role="button" aria-hidden="true" *ngIf="value.value" (click)="copyToClipboardModern((value?.country_code?countryData[value?.country_code]?.dial_code:'')+value.value)"></i>
            <i *ngIf="!isReadOnly" class="fa fa-pencil mr-1 icons" role="button" aria-hidden="true" (click)="editEnabled=true;newNumber=value.value"></i>
            <i *ngIf="!disableDeletion" (click)="optionValue?.open()" class="fa fa-times icons" aria-hidden="true" role="button"></i>
            <span #optionValue="ngbPopover" [ngbPopover]="confirmationpopup" #removepopup="ngbPopover" placement="auto" container="body" [autoClose]="'outside'"></span>
            <ng-template #confirmationpopup>
                <div>
                  <div class="p-2">Are you sure you want to remove mobile.</div>
                  <div style="justify-content: center;display: flex;align-items: center;column-gap: 20px;">
                    <span class="btn btn-success" style="border-radius: 10px;" (click)="removeNumberField(value);optionValue?.close()">{{'multiSelect.yes' | translate}}</span>
                    <span class="btn btn-danger" style="border-radius: 10px;" (click)="optionValue?.close()" >{{'multiSelect.no' | translate}}</span> 
                  </div>
                </div>
              </ng-template>
          </div>
          <div *ngIf="fieldType==='Mobile' && indexOfMobile==0 && value?.value!=''" class="contract-primary-mobile"><span style="padding-left: 3px;">Primary</span></div>
          <span class="text-primary bg-light px-2 py-1 rounded count-indicator" *ngIf="isGrid && valueCopy.length>1">+{{valueCopy.length - 1}}</span>
          <div (click)="grid.open()" *ngIf="isGrid && editable" class="d-inline-flex justify-content-between gap-1 editOption ml-1" role="button">
            <span class="d-inline-flex justify-content-around align-items-center transparentButton">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </span>
          </div>
          <span #grid="ngbPopover" [ngbPopover]="editable && gtidTemplates" popoverClass="modifiedPopover" [autoClose]="'outside'" container="body"></span>
          <ng-template #gtidTemplates>
            <div class="p-0">
              <div *ngIf="value.country_code && value.value && !isReadOnly" role="button" (click)="WTRef.open()" class="backDrop p-1 rounded">
                <i  class="fa fa-whatsapp icons" aria-hidden="true" ></i><span class="f-s-r">&nbsp;&nbsp;Whatsapp Message</span>
              </div>
              <div role="button" *ngIf="value.value" class="backDrop p-1 rounded" (click)="copyToClipboardModern((value?.country_code?countryData[value?.country_code]?.dial_code:'')+value.value)">
                <i class="fa fa-clone icons" aria-hidden="true" ></i><span class="f-s-r">&nbsp;&nbsp;Copy</span>
              </div>
              <div *ngIf="!isReadOnly && fieldType == 'MultipleMobile'" role="button" class="backDrop p-1 rounded" (click)="manageNumbers.emit(true); grid.close()">
                <i class="fa fa-pencil icons"  aria-hidden="true"></i><span class="f-s-r">&nbsp;&nbsp;Manage Numbers</span>
              </div>
              <div *ngIf="!isReadOnly && fieldType == 'Mobile'" role="button" class="backDrop p-1 rounded" (click)="editEnabled=true;newNumber=value.value">
                <i class="fa fa-pencil icons"  aria-hidden="true"></i><span class="f-s-r">&nbsp;&nbsp;Edit</span>
              </div>
              <!-- <div *ngIf="valueCopy?.length > 1 && fieldType==='MultipleMobile'" (click)="removeNumberField(value)"  class="backDrop">
              <i class="fa fa-times" aria-hidden="true"></i><span class="f-s-r"> &nbsp;&nbsp;Remove</span>
              </div> -->
            </div>
          </ng-template>
          <ng-template #editTemplate>
            <div class="p-2 templateEditPopover">
              <app-content-editable-input-note [columnSelection]="false"
                [sendOnChange]="true"
                [dashId]="dashId"
                [selectedLeadObj]="selectedLeadObj"
                [htmlContent]="templateContentToSend"
                (sendEditorText)="getTemplateMedia($event)"
                (getTemplateData)="getTemplateMedia($event)">
              </app-content-editable-input-note>
              <div class="d-flex justify-content-between align-items-center">
                <span>To: {{countryData[value?.country_code]?.dial_code}}-{{value.value}}</span>
                <a style="border: none;" class="sendMessage" [href]="getWhatsappLink(countryData[value?.country_code]?.dial_code, value.value, templateContentToSend)" target="_blank" (click)="WTEditRef?.close()"><i class="fa fa-paper-plane-o fa-lg" style="color: white;" aria-hidden="true"></i></a>
              </div>
            </div>
          </ng-template>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #countryTemplate>
  <div class="country-contanier">
    <div class="country-searchb-box">
      <input id="search-input" style="border:none; width:90%" class="search" placeholder="search" [(ngModel)]="searchText" (input)="onSearchCode(searchText)">
      <span class="m-1" style="width:10%"><i class="fa fa-search"></i></span>
    </div>
    <div class="country-info-wrapper" #container>
      <div *ngFor="let country of countryList;let index=index">
        <diV *ngIf="country" [class.selected-field]="selectedIndex==index" (mouseenter)="selectedIndex=index" class="country-info" (click)="selectedCountry(country)">
          <div class="d-flex" style="flex:1;padding: 3px;">
            <div class="mr-1">{{country?.flag}}</div>
            <div>{{country?.name}}</div>
          </div>
          <div>
            <ng-container *ngIf="['Mobile','MultipleMobile'].includes(fieldType)">{{country?.dial_code}}</ng-container> 
            <ng-container *ngIf="fieldType==customFieldType?.CURRENCY">{{country?.text}}</ng-container> 
          </div>
        </diV>
      </div>
    </div>
    <div *ngIf="value?.country_code" class="selected-value-wrapper">
      <span>
        <b>
        <ng-container  [ngSwitch]="true">
          <ng-container *ngSwitchCase="['Mobile','MultipleMobile'].includes(fieldType)">Selected Dial Code</ng-container>
          <ng-container *ngSwitchCase="fieldType==customFieldType?.COUNTRY">Selected Country</ng-container>
          <ng-container *ngSwitchCase="fieldType==customFieldType?.CURRENCY">Selected Currency</ng-container>
        </ng-container>
        </b>
      </span>
      <diV class="selected-info">
        <div class="selected-info" style="padding: 3px;flex: 1;">
          <div class="mr-1"><ng-template *ngTemplateOutlet="dialCodeTemp"></ng-template></div>
          <div *ngIf="fieldType!=customFieldType?.COUNTRY">({{countryData[value?.country_code]?.flag+' '+countryData[value?.country_code]?.name}})</div>
          <div *ngIf="fieldType==customFieldType?.COUNTRY">{{countryData[value?.country_code]?.name}}</div>
        </div>
        <div (click)="selectedCountry(null)" class="removebtn">
          <i class="fa fa-times crossIcon"></i>
        </div>
      </diV>
    </div> 
  </div>
</ng-template>

<ng-template #errorPopoverTemp>
  <div class="text-danger">
    <b *ngIf="['Mobile','MultipleMobile'].includes(fieldType)">Please enter valid mobile number</b>
    <b *ngIf="fieldType==customFieldType?.CURRENCY">Please enter valid amount</b>
  </div>
</ng-template>

<ng-template #selectedInfoTemp>
  <div class="selected-info" style="padding: 3px;flex: 1;white-space: nowrap;">
    <ng-container *ngIf="countryData[value?.country_code];else emptyCode">
      <div class="mr-1"><ng-template *ngTemplateOutlet="dialCodeTemp"></ng-template></div>
      <div *ngIf="fieldType!=customFieldType?.COUNTRY">({{countryData[value?.country_code]?.flag+' '+countryData[value?.country_code]?.name}})</div>
      <div *ngIf="fieldType==customFieldType?.COUNTRY">{{countryData[value?.country_code]?.name}}</div>
    </ng-container>
    <ng-template #emptyCode>
      <div *ngIf="['Mobile','MultipleMobile'].includes(fieldType)">{{('placeholders.select' | translate) +' '+ ('placeholders.code' | translate)}}</div>
      <div *ngIf="fieldType==customFieldType?.CURRENCY">{{'placeholders.selectCurrency' | translate}}</div>
    </ng-template>
  </div>
</ng-template>

<ng-template #dialCodeTemp>
  <ng-container  [ngSwitch]="true">
    <ng-container *ngSwitchCase="['Mobile','MultipleMobile'].includes(fieldType)">{{countryData[value?.country_code]?.dial_code}}</ng-container>
    <ng-container *ngSwitchCase="fieldType==customFieldType?.COUNTRY">{{countryData[value?.country_code]?.flag}}</ng-container>
    <ng-container *ngSwitchCase="fieldType==customFieldType?.CURRENCY">{{countryData[value?.country_code]?.currencyCode}}</ng-container>
  </ng-container>
</ng-template>

