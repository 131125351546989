import { Directive, ElementRef, AfterViewInit, HostListener, Renderer2 } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[appOverflowCheck]'
})
export class OverflowCheckDirective implements AfterViewInit {
  private originalText: string = '';

  constructor(private el: ElementRef, private renderer: Renderer2, private tooltipDirective: NgbTooltip ) {}

  ngAfterViewInit() {
    this.applyEllipsis();
    this.setupTooltip();
  }

  setupTooltip() {
    if (this.isOverflowing()) {
      this.tooltipDirective.ngbTooltip = this.originalText
      this.tooltipDirective.placement = 'auto'
      this.tooltipDirective.container = 'body'
      this.tooltipDirective.tooltipClass  = 'tooltipPopup'
    }
    else{
      this.tooltipDirective.disableTooltip = true
    }
  }

  private applyEllipsis() {
    this.originalText = this.el.nativeElement.innerText;
    this.renderer.setStyle(this.el.nativeElement, 'text-overflow', 'ellipsis');
    this.renderer.setStyle(this.el.nativeElement, 'white-space', 'nowrap');
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
  }

  private isOverflowing(): boolean {
    const element = this.el.nativeElement;
    return element.scrollWidth > element.clientWidth;
  }
}
