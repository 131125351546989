import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { DatePickerComponent } from 'ng2-date-picker';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from '../../../services/toaster.service';


@Component({
  selector: 'app-lead-date-picker',
  templateUrl: './lead-date-picker.component.html',
  styleUrls: ['./lead-date-picker.component.scss']
})
export class LeadDatePickerComponent implements OnInit {
  @Output() closePopover = new EventEmitter<any>();
  @Input() selectedStartDateObj: any;
  @Input() selectedEndDateObj: any;
  @Input() selectedDashId: any;
  @Input() leadInfo: any[];
  @Input() leadId: string;
  @Input() scrollerRequired: boolean = false;
  @Output() clearDateTime = new EventEmitter<any>();
  @Output() setDateTime = new EventEmitter<any>();
  @Output() closeDateModalOnCancel = new EventEmitter<string>();

  @Input() objFilterForMultiselect: any;

  constructor(private httpTransfer: HttpTransferService,
    private toaster: ToasterService) {}

  ngOnInit(): void {

  }


  onDateUpdate(date){
    let leadObj={}
    if(date.hasOwnProperty('from'))leadObj['start_date']=date?.from
    if(date.hasOwnProperty('to'))leadObj['end_date']=date?.to;

    if(this.leadInfo && Object.keys(leadObj).length>0) {
      if(this.leadInfo.length>0) {
        if(this.leadInfo.length>1){
          let inputJson={}
          inputJson['filter_properties']=this.objFilterForMultiselect
          inputJson['update_json']=leadObj
          this.httpTransfer.updateMultipleDashboard(this.selectedDashId,inputJson).subscribe((res : any) =>{
            this.closePopover.emit(true)
          })
        }
        else{
          this.updateItemDate(leadObj,this.leadInfo[0]._id,);
        }

      } else {
        this.toaster.Errors("Please Select Items");
      }
    } else {
      this.setDateTime.emit(leadObj)
    }
      this.closeDateModalOnCancel.emit('');
  }
  


  updateItemDate(leadObj,leadId) {
    this.httpTransfer
    .updateLeadCorrespondingToLeadId(leadObj, this.selectedDashId, leadId)
    .subscribe((res : any) => {
      if (res.status == 200) {
      }
    });
  }

}
