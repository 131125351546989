
<div class="search">
  <input #searchInput style="border:none;width:90%" class="" placeholder="Search Phase"  [(ngModel)]="searchText" (input)="filterPhases(searchText)">
  <span style="width:10%" class="m-1"><i class="fa fa-search"></i></span>
</div>
<div class="sprintselect-cnr" #container>
    <ul *ngFor="let sprintData of copyDashBoardSprint;let index=index">
      <li *ngIf="selectedPhase != sprintData._id && ((!sprintData['is_archive'] && sprintData.current_status!='STOPPED') || ((sprintData['is_archive'] || sprintData.current_status=='STOPPED') && archiveAndHidden))" [class.selected-field]="selectedIndex==index" (click)="onSprintSelect(sprintData._id)">
        <diV class="d-flex">
          <div class="overflow-hidden text-truncate" style="max-width: 80%;margin-right: 2px;">{{sprintData.name}}</div> 
          <span *ngIf="sprintData.is_hidden"><img src="../../../assets/img/icons/eye.svg" /></span>
          <span *ngIf="sprintData.current_status=='STARTED'" class="pdot"></span>
          <span *ngIf="sprintData.current_status=='STOPPED'" class="cdot"></span>
        </diV>
        <div>{{sprintData.planned_start_date}} <span *ngIf="!sprintData.planned_start_date">{{'form.noStartDate' | translate}}</span> - {{sprintData.planned_end_date}}<span *ngIf="!sprintData.planned_end_date">{{'form.noEndDate' | translate}}</span></div>
      </li>
    </ul>
  <div class="col-md-12 col-12 py-2 text-center" *ngIf="dashBoardSprint.length < 1">
    <b>{{'editcustom.noPhaseAvailable' | translate}}</b>
  </div>
  <div class="col-md-12 col-12 py-2 text-center" *ngIf="dashBoardSprint.length>0 && copyDashBoardSprint.length==0">
    <b>{{'editcustom.noPhaseFound' | translate}}</b>
  </div>
</div>

<div *ngIf="dashBoardSprint.length > 0"  style="display: flex;align-items: center; justify-content: end; padding-top: 5px;">
  <input style="width: 11px;height: 11px; border:1px solid #eaeaea; margin-right: 0.2rem;" type="checkbox" value="archive" [(ngModel)]="archiveAndHidden">
  <p class="mb-0">Show Completed</p>
</div>

<div class="col-md-12 col-12 py-2 text-center" *ngIf="dashBoardSprint.length > 0">
  <div [class.selected-field]="selectedIndex==copyDashBoardSprint.length" class="text-center">
    <button   (click)="onSprintSelect(null)" type="button" class="btn btn-lg btn-sm filterBtn ">{{'editcustom.removePhase' | translate}}</button>
  </div>
  <div *ngIf="this.dashboardUserRole?.includes('DASHBOARD_ADMIN')" [class.selected-field]="selectedIndex==-1" >
    <span class="text-center small cursorPointer" (click)="openQuickAddPhaseModal(quickAddPhase)">{{'navSidebar.addPhase' | translate}}</span>
  </div>
</div>


<ng-template #quickAddPhase let-modal>
  <div class="modal-header border-bottom my-1">
    <div class="modal-title f-xl-sb" id="modal-basic-title"> {{'navSidebar.addPhase' | translate}}</div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body py-0 f-m-r">
    <app-single-phase [dashIdSelected]="dashId"  [addPhaseForm]="addPhaseForm" [selectedSprint]="selectedSprintForStatus" [isCreate]="true" [isUpdate]="false" (showPhase)="modal.dismiss()"></app-single-phase>

  </div>
</ng-template>