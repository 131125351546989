import { Component, HostListener, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ConstantService } from '../../../../Enums/Constant.service';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}
@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})

export class FilePreviewComponent implements OnInit {

@Input() type:any;
@Input() dashId:any = undefined;
@Input() typeId:any = undefined;
@Input() mediaArr:any;
@Input() index:number;
@Input() customFieldType: any;
@Input() item: any;
@ViewChild('vedioAudioPreviewPlayer', { static: false }) vedioAudioPreviewPlayer: ElementRef;
@Output() openEditImageModal = new EventEmitter<{ file: any, fileType?: any, label?: any }>();
@Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
tempArr:any[]=["mp4","webm","ogg","jpeg", "gif", "png", "apng", "svg", "bmp", "bmp ico", "png ico" ,"css","html",".php"
 ,"doc","docx","js","xls","xlsx","ppt","pptx","pdf","svg","zip","xml","jpg","mkv","mp3","m4a","wav","csv",'mov']
validUrlGenerated: boolean = false
retryCount = 0;
video:boolean=true;
audio:boolean=true;
fileInfo: any;
  MediaType: { IMAGE: string[]; VIDEO: string[]; FILE: any[]; AUDIO: string[];};
constructor(private commonUtils : CommonUtils,
  private httpTransfer: HttpTransferService,
  private constant : ConstantService) { 
    this.MediaType = this.constant.getMediaTypes()
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.video = false;
      this.audio=false;
      this.moveToNextPreview();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.video = false;
      this.audio=false
      this.moveToPreviousPreview();
    }
  }
    ngOnInit() {
      console.log(this.mediaArr, this.typeId)
      this.checkExtension()
    if(this.commonUtils?.isFileURLExpired(this.mediaArr[this.index]['url'])){
      this.generateNewUrl();
    }
    else{
      this.getFileSize()
    }
    this.mediaArr.forEach(item=>{
      let extention = item?.name?.split('.').pop()
      if(item?.type == 'SingleFile' || item?.type == 'MultipleFile' ){
        if(this.MediaType.IMAGE.includes(extention)){
          item.type = item?.type.replace('File','Image')
        }
        if(this.MediaType.VIDEO.includes(extention)){
          item.type = item?.type.replace('File','Video')
        }
        if(this.MediaType.AUDIO.includes(extention)){
          item.type = item?.type.replace('File','Audio')
        }
      }
    })
  }

  ngAfterViewInit(){
    this.addAndRemoveLoadedMetaDataEvent(true)
  }
  
  addAndRemoveLoadedMetaDataEvent(isAdd){
    if(this.vedioAudioPreviewPlayer?.nativeElement){
      this.vedioAudioPreviewPlayer?.nativeElement?.removeEventListener('loadedmetadata',this.resetVideoDuration)
      if(isAdd)this.vedioAudioPreviewPlayer?.nativeElement?.addEventListener('loadedmetadata',this.resetVideoDuration)
    }
  }

  resetVideoDuration=()=>{
    if (this.vedioAudioPreviewPlayer?.nativeElement?.duration === Infinity) {
      this.vedioAudioPreviewPlayer.nativeElement.controls=false
      this.vedioAudioPreviewPlayer.nativeElement.currentTime = 10000000;
      setTimeout(() => {
        this.vedioAudioPreviewPlayer.nativeElement.currentTime = 0; // to reset the time, so it starts at the beginning
        this.vedioAudioPreviewPlayer.nativeElement.controls=true
      },400);
    }
  }
  
  async getFileSize(){
    if(['SingleFile','MultipleFile'].includes(this.mediaArr[this.index].type)){
    try{   
        this.mediaArr[this.index].file_size = await this.commonUtils.getFileSizeFromUrl(this.mediaArr[this.index].url)
        this.validUrlGenerated = true
        this.retryCount = 0
    }
    catch(e){
      if(e?.status==0 && this.retryCount<2){
        this.retryCount++
        if(this.typeId)
         await this.generateNewUrl()
      }     
      
    }}
    else{
      this.validUrlGenerated = true
      this.retryCount = 0
    }
  }
 
 async generateNewUrl(){ 
  this.validUrlGenerated = false
  if(this.type == 'SURVEY_FORM'){
    let inputJson={
      'dashboard_id' : [this.dashId],
      'form_id' : [this.typeId],
    }
    let res : any=await this.httpTransfer.getAllFormCorrespondingToDashBoard(inputJson).toPromise()
    let result = res.result.form_response[0].fields
    this.mediaArr[this.index]['url'] = this.getNewDownloadUrlFromPath(result,this.mediaArr[this.index]['path'],null)  
  }
  else if(this.type == 'MEDIA'){
    const dataObj = {
      item_id: [this.typeId],
     }
    let res : any=await this.httpTransfer.getDashboardMedia(dataObj).toPromise()
    let result = res.result.media_files
    this.mediaArr[this.index]['url'] = this.getNewDownloadUrlFromPath(result,this.mediaArr[this.index]['path'],null)
  }
  else if(this.type == 'ITEM'){
    let inputJson={
      'dashboard_id' : [this.dashId],
      'lead_id' : [this.typeId],
    }
    let res : any=await this.httpTransfer.getLeadQuery(inputJson , this.dashId).toPromise()
        let result = res.result.leadResponse[0].custom_fields
        
        this.mediaArr[this.index]['url'] = this.getNewDownloadUrlFromPath(result,this.mediaArr[this.index]['path'],null)
        
  }
  else if(this.type == 'COMMENT'){
    let inputJson = {
      dashboard_id: [this.dashId],
      comment_id  : [this.typeId]
    };
    let res : any=await this.httpTransfer.getCommentCorrespondingLead(inputJson).toPromise()
    let result = res.result.commentsInfo[0]
    this.mediaArr[this.index]['url'] = this.getNewDownloadUrlFromPath(result,this.mediaArr[this.index]['path'],null)
  }
  else if(this.type == 'CHANNEL_TEMPLATES'){
    let inputJson = {
      template_id  : [this.typeId]
    };
    let res : any=await this.httpTransfer.getEmailTemplate(inputJson).toPromise()
    let result = res.result.commentsInfo[0]
    this.mediaArr[this.index]['url'] = this.getNewDownloadUrlFromPath(result,this.mediaArr[this.index]['path'],null)
  }
  this.getFileSize()

 }

 getNewDownloadUrlFromPath(json: any, path: String, parentJson: any){
  let result: any = null;
  if(json instanceof Array){
      for(let i=0;i<json.length;i++){
        result = result || this.getNewDownloadUrlFromPath(json[i], path, json)
      }
    }
  else if(typeof json === 'string'){
      if(json === path){
        result = parentJson['file_url'] || parentJson['url']
      }
    }
  else if(json instanceof Object){
    let keys = Object.keys(json)
    for(let i=0;i<keys.length;i++){
      result = result || this.getNewDownloadUrlFromPath(json[keys[i]], path, json)
    }
  } 
  return result;
 }
 moveToPreviousPreview(){
  
  if(this.index!= 0){
    this.index = this.index - 1
  }
  if(this.commonUtils.isFileURLExpired(this.mediaArr[this.index]['url'])){
    this.generateNewUrl();
  }
  else{
    this.getFileSize()
  }
  if(!this.video){
    setTimeout(()=>{
      this.video = true
      this.addAndRemoveLoadedMetaDataEvent(true)
    },100)
  }
  if(!this.audio){
    setTimeout(()=>{
      this.audio = true
      this.addAndRemoveLoadedMetaDataEvent(true)
    },100)
  }
 }
 moveToNextPreview(){
  if(this.mediaArr.length-1 != this.index){
    this.index = this.index + 1
  }
  if(this.commonUtils.isFileURLExpired(this.mediaArr[this.index]['url'])){
    this.generateNewUrl();
  }
  else{
    this.getFileSize()
  }
  if(!this.video){
    setTimeout(()=>{
      this.video = true
      this.addAndRemoveLoadedMetaDataEvent(true)
    },100)
  }
  if(!this.audio){
    setTimeout(()=>{
      this.audio = true
      this.addAndRemoveLoadedMetaDataEvent(true)
    },100)
  }
 }
 checkExtension(){
  this.mediaArr.forEach(item=>{
    item['notValidExt'] = false
    if(item?.extension && this.tempArr.includes(item?.extension?.toLowerCase()))
      item.name = item?.name + '.' + item?.extension
    else{
      let extention = item?.name?.split('.').pop()
        if(!this.tempArr.includes(extention?.toLowerCase())){
          item['notValidExt'] = true
      }
    }
  })
 }


  setDocumentUrl(item,index) {
    this.commonUtils.downloadMediaURL(item,index,true)

  }

  editImageModal(file: any, fileType?: any, label?: any): void {
    this.closeModal.emit();
    this.openEditImageModal.emit({file: [file], fileType: fileType,
     label: label});
  }

  viewFileinfo(value) {
    this.fileInfo = value;
  }
  closePreviwModal() {
    this.closeModal.emit();
  }
  
 ngOnDestroy() {
  document.removeEventListener('window:keyup', this.keyEvent);
  this.addAndRemoveLoadedMetaDataEvent(false)
  }
}
