import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import { NgbModal, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { commonIcons } from "Enums/attributes-icons";
import { CommonUtils } from "app/services/CommonUtils.service";
import { DashboardUtilsService } from "app/services/dashboard-utils.service";
import { HttpTransferService } from "app/services/httpTransfer.service";
import { ToasterService } from "app/services/toaster.service";
import { Subscription } from "rxjs";
import { MqttmessagingService } from "app/services/mqttmessaging.service";
import { ConstantService } from "Enums/Constant.service";
import { MessageService } from "app/message.service";


@Component({
    selector: 'app-internalChat-view',
    templateUrl: './internalChat-view.component.html',
    styleUrls: ['./internalChat-view.component.scss']
  })
  
  export class InternalChatViewComponent implements OnInit {
    @Output() minimizeRight = new EventEmitter<any>();
   @Input() selectedDashId : any;
   @ViewChild('userPopup') public userPopup: NgbPopover;
   @ViewChild('popInfo', { static: false }) popInfo: NgbPopover;
   title:string;
   enableInput:boolean=false;
   commonIcons = commonIcons;
   chatArr: any[]=[];
   @Input() selectedChatObj: any;
   renameEnable:boolean=false;
   chatMsgcurrentPage: any = 1;
   pageSizeForChatMsg: number = 10;
   chatMsgTotal_Page: number;
   selectedChatUserMsgarr: any[]=[];
   showChat:boolean=false;
   searchActive: boolean = false;
   dashboardJsonSub: Subscription;
   dashUserList: any[]=[];
   loginId=localStorage.getItem('id');
    exitUserObj: any;
    mqttSubscription:any;
    @Input() bottomBarCall:boolean=false;
    @Output() close = new EventEmitter<void>();
    orgUsersJson: {};
    resultArrayFromOrgUserArray : any;
    @Input() collaboratorArr: any[] = [];
    removeFromSelected: any;
    constructor(private httpTransfer : HttpTransferService,private modalService:NgbModal,private toaster : ToasterService,private commonutils : CommonUtils,private dashboardUtils : DashboardUtilsService,public MqttmessagingService : MqttmessagingService, private msgservice: MessageService,){
     this.listenForMqttMessages()
    }

    listenForMqttMessages() {
      this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
        if (message) {
            this.updatesFromWss(message)
        }
      });
    }
    

    async updatesFromWss(messagees){
      let message = JSON.parse(messagees); 
      console.log(message)
      if (message[ConstantService.constant.OBJECTTYPEKEY] === "COMMENT" && message[ConstantService.constant.ACTIVITYTYPEKEY] === "ADD") {
        const leadId = message?.additional_attributes?.details?.lead_id;
        const index = this.chatArr.findIndex(m => m?._id === leadId);
        if (index >= 0) {
            const removeObj = this.chatArr.splice(index, 1);
            if (removeObj?.length) {
                this.chatArr.unshift(removeObj[0]);
            }
        }
      }
      else if(message[ConstantService.constant.OBJECTTYPEKEY] === "LEAD" && message[ConstantService.constant.ACTIVITYTYPEKEY] === "UPDATE"){
        let leadIndex = this.chatArr.findIndex(m=>m?._id === message?.additional_attributes?.details?._id)
        if(leadIndex>=0){
          this.chatArr[leadIndex]['read_comment_details'] = message?.additional_attributes?.details?.read_comment_details
          this.chatArr[leadIndex]['comment_details'] = message?.additional_attributes?.details?.comment_details
          this.unreadCommentCount()
        }
      }
    }

     async ngOnInit() {
      this.dashboardJsonSub = this.dashboardUtils.getUpdatedDashboardJson().subscribe((res : any) => {
        res = JSON.parse(JSON.stringify(res ||  {}))
        let dashboardUser = res[this.selectedDashId]?.DASHBOARD_USER || [];
        this.dashUserList = dashboardUser
        console.log(this.dashUserList)
      } )
      this.getInternalChat()
      this.orgUsersJson= await this.dashboardUtils.getOrgUsers(); 
      this.resultArrayFromOrgUserArray = Object.keys(this.orgUsersJson).map(key => {
        const { _id, name, email } = this.orgUsersJson[key];
        return { _id, name, email };
      });
    }


    getInternalChat(){
      let param={
         sorting_details: {
              "sort_by": "comments",
              "sort_order": "DESC",
          },
        //  "dashboard_id":[this.selectedDashId]
      }
         this.httpTransfer.getInternalChatQuery(param).subscribe(async res=>{
            if(res.status==200){
              this.chatArr = res?.result?.chats
              this.unreadCommentCount()
              this.selectedChatObj= this.selectedChatObj ? this.selectedChatObj : res?.result?.chats[0]
              this.collaboratorArr = this.resultArrayFromOrgUserArray.filter(user => this.selectedChatObj.lead_colloborators_users.includes(user._id));
            }
            this.showChat=true;
         })
    }

    getSelectedChat(chat){
      this.selectedChatObj = null
      this.selectedChatUserMsgarr = []
      setTimeout(() => {
        this.selectedChatObj = chat
      }, 5);
    }

    openWarningModal(modalContent,item) {
      this.popInfo.close();
      this.modalService.open(modalContent, { size: 'sm' });
     }

    deleteRule(){
       this.httpTransfer.deleteInternalChat(this.selectedChatObj?._id).subscribe(res=>{
          if(res.status==200){
            this.chatArr = this.chatArr.filter(m=>m?._id !== this.selectedChatObj?._id)
            this.getSelectedChat(this.chatArr[0])
            this.toaster.Success("Deleted Successfully");

          }
       })
    }

    createInternalChat(param='create'){
      let value = {'title':this.title}
      if(param=='create'){
        this.httpTransfer.createInternalChat(value).subscribe(res=>{
          if(res.status==200){
            this.enableInput= false;
            this.renameEnable=false;
            this.title=""
            this.getInternalChat()
          }
      }) 
      }
      else{
        this.httpTransfer.updateInternalChat(value,this.selectedChatObj?._id).subscribe(res=>{
          if(res.status==200){
            this.enableInput= false;
            this.renameEnable=false;
            this.title="";
            this.getInternalChat()
          }

        })
      }
         
    }

    selectedUserForshared(user){
      this.selectedChatObj.channel_type = "COLLABORATED"
      let obj = {
        "channel_type":this.selectedChatObj.channel_type,
        "lead_colloborators_users":[...this.selectedChatObj.lead_colloborators_users,...[user?._id]]
      }
      this.httpTransfer.updateInternalChat(obj,this.selectedChatObj?._id).subscribe(res=>{
        if(res.status==200){
          this.selectedChatObj.lead_colloborators_users = this.selectedChatObj.lead_colloborators_users.filter(u=>u?._id!==user?._id)
          this.selectedChatObj.lead_colloborators_users.push(user?._id)
          this.checkForsharedUserList()
        }
      }) 
    }

    toggleShared(event){
      console.log(event)
      let param={"channel_type":event?.target?.checked ? "SHARED" : "COLLABORATED",}
      this.httpTransfer.updateInternalChat(param,this.selectedChatObj?._id).subscribe(res=>{
        if(res.status==200){
          this.selectedChatObj.channel_type = param?.channel_type
        }
      })
    }

    checkForsharedUserList(){
      console.log(this.selectedChatObj)
      this.selectedChatObj.dashUser = this.dashUserList
      this.selectedChatObj.selectedUser = this.selectedChatObj.dashUser.filter(user => this.selectedChatObj?.lead_colloborators_users.includes(user._id));
      this.selectedChatObj.searchUserList = this.selectedChatObj.dashUser.filter(user => !this.selectedChatObj?.lead_colloborators_users.includes(user._id));
      this.selectedChatObj.searchCopyList = this.selectedChatObj.dashUser.filter(user => !this.selectedChatObj?.lead_colloborators_users.includes(user._id));
    }

    removeSharedUserOfNote(loginUser,i){
      this.selectedChatObj.selectedUser.splice(i,1)
      this.selectedChatObj.lead_colloborators_users = this.selectedChatObj?.lead_colloborators_users.filter(i=>i !== loginUser?._id)
      this.selectedChatObj.searchUserList.push(loginUser)
      this.selectedChatObj.searchCopyList.push(loginUser)
      this.httpTransfer.updateInternalChat({'lead_colloborators_users':this.selectedChatObj.lead_colloborators_users},this.selectedChatObj?._id).subscribe(res=>{
        if(res.status==200){
        }
      }) 
    }

    open(content,data) {
      this.exitUserObj = data
      this.userPopup.close()
      this.modalService.open(content, { size: 'sm' })
    }
   
    exitUser(){
      this.selectedChatObj.lead_colloborators_users = this.selectedChatObj?.lead_colloborators_users.filter(i=>i !== this.loginId)
      this.httpTransfer.updateInternalChat({'lead_colloborators_users':this.selectedChatObj.lead_colloborators_users},this.selectedChatObj?._id).subscribe(res=>{
        if(res.status==200){
          this.checkForsharedUserList()
          this.selectedChatObj = null
          this.getInternalChat()
        }
      }) 
    }

    filterUser(val) {
      val = val.toLowerCase();
      this.selectedChatObj.searchUserList = this.selectedChatObj.searchCopyList.filter(
        (user) => user.name.toLowerCase().indexOf(val) !== -1 || user.email.toLowerCase().indexOf(val) !== -1 || !val
      );
    }

    unreadCommentCount(){
      this.chatArr.forEach(e=>{
        e = this.commonutils.calcuateCommentCount(e,e)
        e['unreadCount'] = e?.totalCommentCount - e?.totalReadCommentCount
      })
      console.log(this.chatArr)
    }

    closeChat() {
      this.close.emit();
    }
    
    removefromMinimize() {
      let obj = {
        'minimizeId': [this.selectedChatObj._id],
        "update_type": "REMOVE",
        'view':"INTERNAL_CHAT"
      }
      this.minimizeRight.emit(obj)
    }

    selectedUserList(user){
      if(user._id.includes('@')){
        user = {
          name:`External user(${user._id})`,
          email:user._id,
          isDashUser:false,
          is_external_user:true,
          _id:user._id
        }
      }
      this.collaboratorArr.push(user);
      var leadObj = {};
      leadObj["lead_colloborators_users"] = this.collaboratorArr.map(user => user._id);
      this.httpTransfer.updateInternalChat(leadObj,this.selectedChatObj?._id).subscribe(res=>{
        if(res.status==200){
        }
      }) 
    }

    removeFromSelectedList(item){
      this.removeFromSelected = item; 
      for(let x=0;x<this.collaboratorArr.length;x++){
        if(this.collaboratorArr[x]._id == item._id){
         this.collaboratorArr.splice(x,1)
        }
      }
      var leadObj = {};
      leadObj["lead_colloborators_users"] = this.collaboratorArr.map(user => user._id);
      this.httpTransfer.updateInternalChat(leadObj,this.selectedChatObj?._id).subscribe(res=>{
        if(res.status==200){
        }
      }) 
    }
    minimizeChat() {
      let obj = {
        'minimizeId': [this.selectedChatObj._id],
        "update_type": "APPEND",
        'view':"INTERNAL_CHAT"
      }
      this.minimizeRight.emit(obj)
    }
  }