<div class="textAreaEditorbox key-tab" *ngIf="!whatsappCall && !commentChat" (focus)="focusOnSummerNote()" tabindex="0">
<ng-container *ngIf="type=='editableDiv'">
     <!--formatting Bar-->
     <div class="context-menu" *ngIf="isPopoverVisible">
      <button class="editBtn first" (click)="applyBold()">B</button>
      <button class="editBtn" (click)="applyItalic()"><em>I</em></button>
      <button class="editBtn" (click)="applyStrikethrough()"><s>U</s></button>
    </div>
    <div class="msg-textarea key-focus" (keypress)="onKeyUp($event,itemData)" contenteditable="true" #editableDiv tabindex="0"
    (blur)="getCareAtPositionForComment(i,$event.target.id)" (keyup)="commentInput();showContextMenu($event);mentionEnabled && openMentionPanel()"
    (focusout)="colseMentionPanel($event)" (mouseup)="showContextMenu($event)"> 
  </div>
</ng-container>
<ng-container *ngIf="type=='summerNote'">
    <div #editableDiv></div>
</ng-container>
  <div class="memtionsDiv" *ngIf="isMentionPanelOpen">
    <app-user-list [searchFororgUsersJson]="commentMentions" (selectedUser)="insertText($event,'MENTION')"></app-user-list>
  </div>
  <div class="w-100 columnBox justify-content-between" *ngIf="columnSelection">
    <div class="d-flex align-items-center" style="gap:8px;"><span *ngIf="!multiselect" class="columnsBtn cursorPointer" [ngbPopover]="customColumns" #popColumns="ngbPopover" [autoClose]="'outside'">{{ '\{\{ \}\}'}}Columns</span>
      <span *ngIf="multiselect" class="cursorPointer iconBox-20" [ngbPopover]="customColumns" #popColumns="ngbPopover" [autoClose]="'outside'">{{ '\{\{ \}\}'}}</span>
      <span class="iconBox-20" *ngIf="multiselect && broadCastChannel && broadCastChannel?.type=='WHATSAPP'" (click)="openPreApproveMsgModal(preArrovedMsgScreenModal)"><app-dashboard-icon [size]="20"  [name]="'Comment'" [isSvg]="true" [color]="'#000'"></app-dashboard-icon></span>
      <span class="attachMediaBtn iconBox-20" *ngIf="showAttachmentIcon" (click)="uploadFile.emit()"><app-dashboard-icon [size]="16"  [name]="'attach'"></app-dashboard-icon></span>
  </div>
    <button *ngIf="multiselect" class="sendBroadCastChat themeChild" (click)="sendBroadCast.emit()">Send</button> 
  </div>
</div>

<!--For Whatsapp-->
<div class="textAreaEditorbox" *ngIf="whatsappCall" style="display: flex;align-items: center;">
  <div style="width:90%;">
    <ng-container *ngIf="type=='editableDiv'">
      <div class="msg-textarea" contenteditable="true" #editableDiv style="height:50px;height: 50px;border-right: none;border-radius: 8px 0px 0px 8px;"
      (blur)="getCareAtPositionForComment(i,$event.target.id)" (keyup)="commentInput();openMentionPanel()"
      (focusout)="colseMentionPanel($event)" (keydown)="handleKeydown($event)"> 
    </div>
  </ng-container>
  </div>
  <div style="width:10%;">
    <div class="whatsappColumn cursorPointer" [ngbPopover]="customColumns" #popColumns="ngbPopover" container="body" [autoClose]="'outside'">{{ '\{\{ \}\}'}}</div>  
  </div>
</div>

<!--For Comment-chat view-->
<ng-container *ngIf="!whatsappCall && commentChat">
    <!--formatting Bar-->
    <div class="context-menu" *ngIf="isPopoverVisible">
      <button class="editBtn first" (click)="applyBold()">B</button>
      <button class="editBtn" (click)="applyItalic()"><em>I</em></button>
      <button class="editBtn" (click)="applyStrikethrough()"><s>U</s></button>
    </div>
    <div class="w-100" style="display: flex;justify-content: center; align-items: center;padding:0 10px;" *ngIf="rplyObj && rplyEnable">
       <div class="w-100">
        <div class="receiverName">
          <div class="leftBox" *ngIf="!rplyObj?.message_data?.attachments">
              <div class="replyText">
                <ngx-avatars class="mr-1" [name]="rplyObj?.rplyUserName ? rplyObj?.rplyUserName : orgUsersJson[rplyObj?.created_by]?.name" [src]="rply?.rplyUserProfilePic ? rply?.rplyUserProfilePic : orgUsersJson[rplyObj?.created_by]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                <span class="rplyMsg" [innerHTML]="rplyObj?.message_data?.text"></span>
              </div>
                <div class="rightBox" (click)="removeReply()">
                  <app-dashboard-icon style="height:100%;display: flex;align-items: center;cursor: pointer;" [size]="16" [name]="'Cross'" [iconJson]="lxAttributesIcons"></app-dashboard-icon>
                </div>
          </div>
          <div class="leftBox" *ngIf="rplyObj?.message_data?.attachments?.length">
            <div class="w-100">
              <div class="w-100" style="display: flex;align-items: center;">
                <ngx-avatars class="mr-1" [name]="rplyObj?.rplyUserName ? rplyObj?.rplyUserName : orgUsersJson[rplyObj?.created_by]?.name" [src]="rply?.rplyUserProfilePic ? rply?.rplyUserProfilePic : orgUsersJson[rplyObj?.created_by]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                <span class="rplyMsg" [innerHTML]="rplyObj?.message_data?.attachments[0]?.caption"></span>  
              </div>
              <div class="w-100">
                <div class="align" *ngIf="rplyObj?.message_data?.attachments[0]?.file_type=='IMAGE'"><app-dashboard-icon [isSvg]="true" [size]="22" [name]="'SingleImage'" [iconJson]="lxAttributesIcons" [color]="'#666666'"></app-dashboard-icon><span>{{rplyObj?.message_data?.attachments[0]?.file_name}}</span></div>
                <div class="align" *ngIf="rplyObj?.message_data?.attachments[0]?.file_type=='VIDEO'"><app-dashboard-icon [isSvg]="true" [size]="22" [name]="'SingleVideo'" [iconJson]="lxAttributesIcons" [color]="'#666666'"></app-dashboard-icon><span>{{rplyObj?.message_data?.attachments[0]?.file_name}}</span></div>
                <div class="align" *ngIf="rplyObj?.message_data?.attachments[0]?.file_type!='IMAGE' && rplyObj?.message_data?.attachments[0]?.file_type!='VIDEO' && rplyObj?.message_data?.attachments[0]?.file_type!='AUDIO'"><app-dashboard-icon [isSvg]="true" [size]="22" [name]="'SingleFile'" [iconJson]="lxAttributesIcons" [color]="'#666666'"></app-dashboard-icon><span>{{rplyObj?.message_data?.attachments[0]?.file_name}}</span></div>    
                <div class="align" *ngIf="rplyObj?.message_data?.attachments[0]?.file_type=='AUDIO'"><app-dashboard-icon [isSvg]="true" [size]="22" [name]="'SingleAudio'" [iconJson]="lxAttributesIcons" [color]="'#666666'"></app-dashboard-icon><span>{{rplyObj?.message_data?.attachments[0]?.file_name}}</span></div>
              </div>
             </div>
            <div>
              <div class="mediaDiv" *ngIf="rplyObj?.message_data?.attachments[0].file_type=='IMAGE'"><img  style="width:auto;max-height:30px;object-fit: contain;" [src]="rplyObj?.message_data?.attachments[0].file_url"></div>
              <div class="mediaDiv" *ngIf="rplyObj?.message_data?.attachments[0].file_type=='VIDEO'"><video style="width:100%;height:100%;" [src]="rplyObj?.message_data?.attachments[0]?.file_url"></video></div>
              <div class="mediaDiv" *ngIf="rplyObj?.message_data?.attachments[0].file_type=='AUDIO'"><audio style="height:auto;" controls><source [src]="rplyObj?.message_data?.attachments[0].file_url" type="audio/mp3"/></audio></div>
                <div class="mediaDiv" *ngIf="rplyObj?.message_data?.attachments[0].file_type=='IMAGE' && rplyObj?.message_data?.attachments[0].file_type=='VIDEO' && rplyObj?.message_data?.attachments[0].file_type=='AUDIO'"><app-dashboard-icon [isSvg]="true" [size]="22" [name]="'SingleFile'" [iconJson]="lxAttributesIcons" [color]="'#666666'"></app-dashboard-icon></div>
            </div>
              <div class="rightBox" (click)="removeReply()">
                <app-dashboard-icon style="height:100%;display: flex;align-items: center;cursor: pointer;" [size]="16" [name]="'Cross'" [iconJson]="lxAttributesIcons"></app-dashboard-icon>
              </div>
        </div>
      </div>
       </div>
    </div>
    <div *ngIf="recordingStarted" class="position-relative">
      <div class="d-flex timerBar borderRadius" [ngStyle]="{'background-color': commonUtils.getCurrentThemeColor(),'color': '#ffffff'}">
        <ng-container *ngIf="recordingStarted===true">
          <span style="display: flex;column-gap: 5px;">
            <button class="btn btn-outline-light position-relative stopButton"  (click)="stopScreenRecording();">
              <span class="d-inline-block position-absolute stopButtonInner"></span>
            </button>
             {{recordingTimeForVideo}}
          </span>
        </ng-container>
        <ng-container *ngIf="recordingStarted==='loading'">
          <div class="loaderBox" [ngStyle]="{'background-color': commonUtils.getCurrentThemeColor(),'color': '#ffffff'}">
            <button class="btn btn-primary" [ngStyle]="{'background-color': commonUtils.getCurrentThemeColor(),'color': '#ffffff'}" type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{'editcustom.loading' | translate}}  
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="recordingStarted==='name'">
          <span class="pt-1">
            <span class="mx-2">Video title:</span>
            <input type="text" #addTitleforVideo [(ngModel)]="screenRecordVideo.name"/>
          </span>
          <button class="btn mx-2 previewButton" type="button" (click)="submitScreenRecord()">Preview & Send</button>
        </ng-container>
    </div>
    </div>
    <div class="commentBox">
      <div class="chatMentionPanel" *ngIf="isMentionPanelOpen && currentActiveTab=='commentTab'">
        <app-user-list [searchFororgUsersJson]="commentMentions" (selectedUser)="insertText($event,'MENTION')"></app-user-list>
      </div>
      <ng-container *ngIf="!disableEditor">
      <div class="chatIcon" *ngIf="!disableAttachColumns">
        <span style="cursor:pointer;" *ngIf="showAttachmentIcon" [ngbPopover]="attachment" #popAttach="ngbPopover" [autoClose]="'outside'" placement="top-left" (click)="openTemplatepanel=false;"><app-dashboard-icon [size]="22" [name]="'Attachment'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
        <span class="cursorPointer" *ngIf="!internalChat" [ngbPopover]="customColumnsForChat" #popColumnsForChat="ngbPopover" [autoClose]="'outside'"><app-dashboard-icon [size]="22" [name]="'Columns'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
        <span *ngIf="selectedChatObj?.channelType == 'WHATSAPP'" (click)="openPreApproveMsgModal(preArrovedMsgScreenModal)"><img src="../../../assets/1x/comment.svg"></span>
      </div>
      <div class="chatBoxMain">
       <div class="commentMsgBox" [attr.contenteditable]="isContentEditable ? 'true' : 'false'" #editableDiv
       (blur)="getCareAtPositionForComment(i,$event.target.id);sendHtml()" (keyup)="openMentionPanel();showContextMenu($event)"
       (mouseup)="showContextMenu($event)" (keydown)="handleKeydown($event)" (dragover)="disableDragEvents($event)"
       (drop)="disableDragEvents($event)" (paste)="handlePaste($event)" (focus)="onFocus()"> 
     </div> 
      </div>
      <div class="chatIcon"><button class="btn sendBtn" [disabled]="disabelSendBtn" [ngStyle]="{'opacity':  (disabelSendBtn) ? '0.4' : '1'}" (click)="sendChatMsg()"><app-dashboard-icon style="height:100%;display: flex;align-items: center;" [size]="22" [name]="'SendWhite'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></button></div>
    </ng-container>

    <ng-container *ngIf="disableEditor">
      <div class="w-100 preTemp" *ngIf="selectedChatObj?.type=='WHATSAPP'">
        <span>{{'whatsapp.selectFromPreapproved' | translate}}</span>
        <button class="premsgBtn" (click)="openPreApproveMsgModal(preArrovedMsgScreenModal)">{{'whatsapp.message' | translate}}</button>
      </div>
      <div class="w-100 preTemp" *ngIf="selectedChatObj?.type!='WHATSAPP' || selectedChatObj?.type!='VIBER'">
        <span>{{'whatsapp.fbinstaMsg' | translate}}</span>
      </div>
    </ng-container>

    </div>
</ng-container>


  <!--columns-->
  <ng-template #customColumns class="popOverColumns">
    <app-automation-columns [filterCall]="filterCall"  [isHeader]="multiselect ? false : true" [dashboardVariable]="dashboardVariable" [dashVariableArr]="dashVariableArr" [customfieldType]="customfieldType" [assignVariableCall]="assignVariableCall" [appTemplateCall]="appTemplateCall" [templateVariableArr]="templateVariableArr"  [showIndex]="showIndex" [item]="item" [dashId]="dashId" [customFieldArr]="customFieldArr" (selectedColumn)="insertText($event);popColumns.close()" [isTriggeredNonItemField]="isTriggeredNonItemField" [catchHookReqJson]="catchHookReqJson" [triggerType]="triggerType" [columnsJson]="columnsJson" (selectedFunctionsEmit)="selectedFunctionsEmit($event)" (closeEmit)="popColumns.close()"></app-automation-columns>
  </ng-template>


  <!--Attachment-->
  <ng-template #attachment>
    <div class="optionsContainerField" *ngIf="!openTemplatepanel">
      <div class="attachRow"><span (click)="file.value='';file.click()">Document</span>
        <input #file class="mediaInput" type="file" name="file" (change)="onUploadFile($event)" accept="application/pdf, .xls,.xlsx,.doc,.docx ,application/zip,.rar,.7zip,application/vnd.oasis.opendocument.text" multiple/>
      </div>
      <div class="attachRow"><span (click)="video.value='';video.click()">Video</span>
        <input #video class="mediaInput" type="file" name="video" accept="video/*" (change)="onUploadFile($event)" multiple/>
      </div>
      <div class="attachRow"><span (click)="image.value='';image.click()">Image</span> 
        <input #image class="mediaInput" type="file" name="image" accept="image/*" (change)="onUploadFile($event)" multiple/>
      </div>
      <div class="attachRow"><span (click)="audio.value='';audio.click()">Audio</span> 
        <input #audio class="mediaInput" type="file" name="audio" accept="audio/*" (change)="onUploadFile($event)" multiple/>
      </div>
      <div class="attachRow" *ngIf="currentActiveTab==='commentTab' && !recordingStarted"><span [ngbPopover]="!isReadOnly && recordPopover" #popRecord="ngbPopover" placement="right" container="body" >Start recording</span> 
      </div>
      <ng-template #recordPopover>
        <div (click)="requestMediaPermission(true,VIDEO)" class="cursorPointer px-2 pb-1 mb-2" style="border-bottom: solid rgb(133, 133, 133) 1px;">With audio</div>
        <div (click)="requestMediaPermission(false,VIDEO)" class="cursorPointer px-2">Without audio</div>
      </ng-template>
      <div class="attachRow" *ngIf="currentActiveTab!='commentTab'"><span (click)="openTemplatepanel=true;">Templates</span> 
      </div>
    </div>
    <div class="optionsContainerFieldForTemplate" *ngIf="openTemplatepanel">
      <app-channel-template-picker [leadInfo]="selectedLeadObj" [dashId]="dashId" [sprintArr]="sprintArr" [orgUsersJson]="orgUsersJson" (sendTemplateEmit)="useTemplateData($event)" (closeEmit)="popAttach.close();openTemplatepanel = false"></app-channel-template-picker>
    </div>
  </ng-template>


  <ng-template #customColumnsForChat>
    <ng-container>
      <lead-columns-picker [dashId]="dashId" [leadObj]="selectedLeadObj" (columnValueemit)="columnValueemit($event)" (closeEmit)="popColumnsForChat.close()" (mediaEmitForColumns)="mediaEmitForColumns($event)"></lead-columns-picker>
    </ng-container>
  </ng-template>


  <!--Whatsapp Pre Approved Template-->
  <ng-template #preArrovedMsgScreenModal let-modal>
    <div class="modal-header border-bottom preMsgHeader">
      <h4 class="modal-title" id="modal-basic-title processModalTitle"> {{'whatsapp.preapproved' | translate}}</h4>
    </div>
    <div style="height: calc(100% - 50px);">
        <i class="fa fa-close bg-white previewCross" (click)="modal.dismiss()"></i>
        <app-whatsapp-pre-approve-messages [broadCastChannel]="broadCastChannel" [objFilterForMultiselect]="objFilterForMultiselect" [multiselect]="multiselect" [selectedChannelObj]="selectedChatObj" [preApprovedData]="preApprovedTemplates" [leadInfo]="selectedLeadObj" [dashId]="dashId" [sprintArr]="sprintArr" [orgUsersJson]="orgUsersJson" (emitCloseModal)="modal.close()"></app-whatsapp-pre-approve-messages>
    </div>
 </ng-template>

 <ng-container *ngIf="openScreenRecorderPlayer">
  <app-screen-recording leadId={{leadId}} (recordingStarted)="recordingStarted=true;startTimer()" [audioFlag]="audioFlag"
    (recordedData)="getScreenRecordedData($event); recordingStarted='name';"></app-screen-recording>
</ng-container>

<ng-template #mediaPermissionModal let-modal>
  <div class="modal-body text-center py-3">
    <h3>Alert</h3> 
    <img src="../../../assets/1x/info.png"><br><br>
    Microphone is blocked click camera block icon in your browser's address bar!!
  </div>
</ng-template>


<span style="display: none;" [ngbPopover]="functionPop" #popFunctionOpen="ngbPopover" container="body" [autoClose]="'outside'" triggers="manual">Open</span>

<ng-template #functionPop>
  <div id="innerfunctionpopover">
    <app-automation-columns [isValid]="isValid" [dashboardVariable]="dashboardVariable" [dashVariableArr]="dashVariableArr" [filterCall]="filterCall" [customfieldType]="customfieldType" [appTemplateCall]="appTemplateCall" [templateVariableArr]="templateVariableArr" [selectedFunction]="selectedEditFuncObj" [showIndex]="showIndex" [item]="item" [dashId]="dashId" [customFieldArr]="customFieldArr" (selectedColumn)="insertText($event);popColumns.close()" [isTriggeredNonItemField]="isTriggeredNonItemField" [catchHookReqJson]="catchHookReqJson" [triggerType]="triggerType" [columnsJson]="columnsJson" [isFunctionEnable]="true" (selectedFunctionsEmit)="selectedFunctionsEmit($event)" (closeEmit)="popColumns.close();popFunctionOpen?.close();"></app-automation-columns>
 </div>
</ng-template>