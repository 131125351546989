import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MediaIcons } from '../../../../Enums/media-icons';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {v4 as uuidv4} from 'uuid';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { ConstantService } from '../../../../Enums/Constant.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { AttributesLxIcons } from 'Enums/attributes-icons';
import { BasicUtils } from 'app/services/basicUtils.service';

declare var $: any;
@Component({
  selector: 'app-expand-thread-view',
  templateUrl: './expand-thread-view.component.html',
  styleUrls: ['./expand-thread-view.component.scss']
})
export class ExpandThreadViewComponent implements OnInit {
  @Input()selectedEmailThreadViewDisplay :any;
  @Input()selectedEmailObj :any;
  @Input()dashId :any;
  @Input()sprintArr :any;
  @Input()leadInfo :any;
  @Input()communicationCall: boolean=true;
  @Input() showBackButton: boolean = false;
  @Input() isChatExpand:boolean = false;
  @Output() backToAllEmails = new EventEmitter();
  allComments:any[]=[]
  lastReplyMessage:any[]=[]
  commentForModal:any[]
  usersEmailsAllowedToReply:any[] = []
  mediaIcons = MediaIcons;
  lxAttributesIcons = AttributesLxIcons
  filePreviewModal: any;
  currentIndex = -1;
  signatureSeparator = ''
  @ViewChild("replyAllPop") replyAllPop: NgbPopover;
  @ViewChild("replyPopover") replyPopover: NgbPopover;
  @ViewChild("popEmail1") popEmail1: NgbPopover; 
  @ViewChild("popEmail2") popEmail2: NgbPopover; 
  @ViewChild("popEmailR3") popEmailR3: NgbPopover;
  emailEditorEmbeddedAttachments: {} = {}
  signatureId: string = null
  selectedEmail: any = undefined
  defaultSignatures:any = null
  isReplyAll:boolean = false;
  fromEmailObj:any = {};
  userEmailsConnectedWithAliases:any[] =[]
  from: any
  summernoteConfig: any = {}
  mediaArr:any[]=[];
  emailSubject:string="No Subject"
  replyPop:any=null;
  emailSignatureForEditor: any;
  isFullScreen: boolean=false;
  constructor(public commonUtils : CommonUtils,
    private dashboardUtils: DashboardUtilsService,
    private constantService: ConstantService,
    private modalService: NgbModal,
    private basicUtils:BasicUtils,
    private httpTransfer: HttpTransferService) { }

  async ngOnChanges(changes){
      if(changes.selectedEmailThreadViewDisplay){
        let arrivedData: any[] = [...(this.selectedEmailThreadViewDisplay[0])];
        let pushedCommentIds = arrivedData.map(ele=> ele._id)
        if(this.lastReplyMessage.length){
          if(!this.allComments.some(entry=>entry._id==this.lastReplyMessage[0]._id)){
            this.allComments.push(this.lastReplyMessage[0])
          }
        }
        if(this.lastReplyMessage.length && this.lastReplyMessage[0]?.email_object?.smtp_thread_id === this.selectedEmailThreadViewDisplay[0][0]?.email_object?.smtp_thread_id)
          this.allComments.reverse().forEach(ele=>{
            if(!pushedCommentIds.includes(ele._id)){
              arrivedData.push(ele)
              pushedCommentIds.push(ele._id)
            }
          })
        this.allComments = []
        this.lastReplyMessage = []
        arrivedData.forEach(ele=>{
          ele['openFullView']=false
          this.handleReplyBody(ele)
          this.allComments.push(ele)
        })
        this.lastReplyMessage.push(this.allComments.shift())
        // this.handleReplyBody(this.lastReplyMessage)
        this.lastReplyMessage[0]['openFullView']=true
        if(this.allComments.length==0 && this.lastReplyMessage[0].email_object.smtp_thread_id){
          await this.getAllCommentOfSmtp(this.lastReplyMessage[0].email_object.smtp_thread_id)
        }
        if(!this.allComments[0]?.email_object?.subject){
          this.emailSubject = this.lastReplyMessage[0]?.email_object?.subject || "No Subject"
        }
        else this.emailSubject = this.allComments[0].email_object.subject
        this.allComments.reverse()
      }
      else{
        console.log("not working",this.fromEmailObj)
      }
      this.allComments=this.removeDuplicates(this.allComments);
      this.initializeValue()
    }

  ngOnInit(): void {
  }
  setEmailValues(){
    this.from = this.selectedEmailObj?.from
    // this.changeEmail()
    this.selectedEmail = this.selectedEmailObj?.selectedEmail
    this.defaultSignatures = this.selectedEmailObj?.defaultSignatures
    this.usersEmailsAllowedToReply = this.selectedEmailObj?.usersEmailsAllowedToReply
    this.userEmailsConnectedWithAliases = this.selectedEmailObj?.userEmailsConnectedWithAliases
  }
  
  log(view,select,comment){
    view=true
    let emptyArray=[]
    emptyArray.push(select)
    select['commentForModal']=emptyArray
  }

  initializeSummerNote(value?) {
    if(!this.from)
      this.from = this.selectedEmailObj?.from
      value = this.commonUtils.getSelectedEmailBody(value,this.selectedEmail,this.defaultSignatures,this.signatureId,this.signatureSeparator)
      if(value?.length)
      {
        this.emailSignatureForEditor = value
      }
   /*  setTimeout(() => {
      $('#itemEmailSummerNote .note-image-input').first().prop('accept', 'image/jpeg,image/jpg,image/png')
      $('#itemEmailSummerNote')?.summernote({
        placeholder: "Write",
        toolbar: this.summernoteConfig.toolbar,
        callbacks: {
          onImageUpload: (files, editor, welEditable) => {
            //JPEG, PNG SUPPORTED, others not
            this.commonUtils.onUploadFile(files, 'LEAD', undefined, this.insertImage.bind(this))
          },
          onMediaDelete: ($target, editor, $editable) => {
            if(this.emailEditorEmbeddedAttachments[$target.attr('id')])
              delete this.emailEditorEmbeddedAttachments[$target.attr('id')]       
            $target.remove();
          }
        }
      });
      if(!value) value = ''
      if(!value?.includes(`signature-${this.signatureId}`)){
        if(this.selectedEmail?._id && this.defaultSignatures?.defaultReplyEmailSignature?._id){
          value += `${this.signatureSeparator} <span class="signature-${this.signatureId}"> ${this.defaultSignatures?.defaultReplyEmailSignature?.signature} </span>`
        }else if(!this.selectedEmail && this.defaultSignatures?.defaultNewEmailSignature?._id){ 
          value += `${this.signatureSeparator} <span class="signature-${this.signatureId}"> ${this.defaultSignatures?.defaultNewEmailSignature?.signature} </span>`    
        }
      }
      if(value?.length)
      {
        this.emailSignatureForEditor = value
      }
      {$('#itemEmailSummerNote').summernote('code', value)}
      $('#itemEmailSummerNote').on('summernote.keydown', function(we, e) {
        $('#itemEmailSummerNote').summernote('saveRange');
       });

    }, 500); */
  }

  closeAllPopups(){
    this.replyAllPop?.close()
    this.replyPopover?.close()
    this.popEmail1?.close()
    this.popEmail2?.close()
    this.popEmailR3?.close()
    this.replyPop?.close()
  }

  
  changeEmail(event?){
    this.fromEmailObj = this.userEmailsConnectedWithAliases?.find(item => item.id == this.from)
  }

  

  initializeValue(){
    this.signatureId = uuidv4()
    this.isFullScreen = false
    if(Array.isArray(this.selectedEmail?.subject) && this.selectedEmail?.subject?.length) this.selectedEmail.subject = this.selectedEmail?.subject[0]
    this.dashboardUtils.getUpdatedDashboardJson().subscribe(res => {
      this.sprintArr = res[this.dashId]?.SPRINT || [];
    })
    this.setEmailValues()
    this.summernoteConfig = this.constantService.getSummerNoteConfig()
  }

  handleReplyBody(email){
    let backquoteForReply = `<br><br>
    <backquote class="gmail_quote" style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex;">
      <div dir="ltr" class="gmail_attr">
      On ${email?.receivedTime ? email?.receivedTime?.date : email?.createdTime?.date} at ${email?.receivedTime ? email?.receivedTime?.time : email?.createdTime?.time} &lt;<a href="mailto:${email?.email_object?.from?.address}">${email?.email_object?.from?.address}</a>&gt; wrote:<br>       
      </div>`
    if(email?.email_object?.body?.html) { 
      email.email_object.body.backQuoteHtmlReply = `${backquoteForReply} ${email?.email_object?.body?.html} </backquote>`
    }if(email?.body?.text)  {
      email.email_object.body.backQuoteTextReply = `${backquoteForReply} ${email.email_object.body?.text} </backquote>`
    }
      email?.email_object?.attachments?.forEach(file => {
      file.extension = file?.file_name?.split('.').pop()
      file.name = file?.file_name
      file.type = file.type == 'IMAGE' ? 'MultipleImage' : (file.type == 'VIDEO' ? 'MultipleVideo' : 'MultipleFile')
    })
  }

  openPreviewPopup(index: number, template: TemplateRef<any>,selectedEmail) {
    console.log("selectedEmail",selectedEmail,this.leadInfo)
    this.mediaArr=[]
    let type=''
    selectedEmail.forEach(e=>{
      let obj={}
        obj['type'] = e?.type
        obj['name'] =  e?.file_name
        obj['url']  =  e?.url
        obj['path'] =  e?.path
        obj['size_in_bytes'] =  e?.size_in_bytes? this.commonUtils.bytes_to_size(e?.size_in_bytes) :''
        this.mediaArr.push(obj)
    })
    this.filePreviewModal = this.modalService.open(template, { size: 'lg' , windowClass: 'file preview-modal'}) 
    this.currentIndex = index;
    this.basicUtils.storeModlRef(this.filePreviewModal)
  }

  setFromObject(from){
    this.fromEmailObj = {};
    this.usersEmailsAllowedToReply.forEach(ele=>{
      if(ele.email_address==from.address){
        this.from=ele.id;
        this.fromEmailObj=JSON.parse(JSON.stringify(ele))
      }
    })
    if(Object.keys(this.fromEmailObj).length == 0){
      this.fromEmailObj = this.usersEmailsAllowedToReply[0];
      this.from = this.fromEmailObj?.id
    }
  }
  onFullview(){
    this.isFullScreen = !this.isFullScreen
  }

  async getAllCommentOfSmtp(smtp:string){
    if(!smtp || typeof(smtp)!=='string') return;
    var inputJson={
      dashboard_id:[this.dashId],
      smtp_thread_id:[smtp],
      type : ["EMAIL"]
    }
    await this.httpTransfer.getCommentCorrespondingLead(inputJson).toPromise().then((res:any)=>{
      this.allComments=res.result['commentsInfo'].filter(ele=>ele._id!==this.lastReplyMessage[0]._id);
      this.allComments.forEach(ele=>{
        this.commonUtils.formatCommentObject(ele);
        this.selectedEmailThreadViewDisplay[0].push(ele)
        ele['openFullView']=false
        this.handleReplyBody(ele)
      })
    })
    // this.selectedEmailThreadViewDisplay[0].push(this.allComments.filter(ele=>this.selectedEmailThreadViewDisplay[0]._id!=ele._id))
  }

  removeDuplicates = (arr) => {
    const uniqueArray = [];
    arr.forEach((item) => {
      if (!uniqueArray.some((uniqueItem) => uniqueItem._id === item._id)) {
        uniqueArray.push(item);
      }
    });
    return uniqueArray;
  };


}
