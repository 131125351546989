import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AttributesLxIcons } from "Enums/attributes-icons";
import { CommonUtils } from "app/services/CommonUtils.service";
import { HttpTransferService } from "app/services/httpTransfer.service";

@Component({
    selector: 'app-function-popover',
    templateUrl: './function-popover.component.html',
    styleUrls: ['./function-popover.component.scss']
  })
  export class FunctionPopoverComponent implements OnInit {
    functionType:string='TEXT'
    @Input() automationCall:boolean=false;
    lxAttributesIcons = AttributesLxIcons;
    functionList: any[]=[];
    showFunctionList: boolean = false;
    filterFunctionList: any[]=[];
    searchActive:boolean=false;
    @Output() getFunctionsEmit  : EventEmitter<any> = new EventEmitter();
    searchFunctionList: any[]=[];
    constructor(public httpTransfer : HttpTransferService,public commonutils : CommonUtils){
    }
    ngOnInit(): void {
      this.getFunction()  
    } 

    getFunction(){
        this.httpTransfer.getFunctions().subscribe(res=>{
          if(res.status==200){
            this.functionList = res?.result?.functions.map((func, index) => ({ ...func, uniqueid: this.commonutils.getRandomString(10) })); // Adding unique ID
            this.searchFunctionList = [...this.functionList]
            this.filterFunction()
          }
        })
    }

    filterFunction(){
        console.log("call")
        this.showFunctionList = false;
        this.filterFunctionList = !this.automationCall ? this.functionList.filter(m=>m.category==this.functionType) : this.functionList.filter(x=>x?.return_type=='number')
        this.searchFunctionList = [...this.filterFunctionList]
        this.showFunctionList = true;
    }

    searchFunc(val) {
      val = val.toLowerCase();
      this.filterFunctionList = this.searchFunctionList.filter(
        (func) => func.name.toLowerCase().indexOf(val) !== -1  || !val
      );
    }

  }