import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from '../../../services/toaster.service';
import { commonIcons } from 'Enums/attributes-icons';

@Component({
  selector: 'app-group-create',
  templateUrl: './group-create.component.html',
  styleUrls: ['./group-create.component.scss']
})
export class GroupCreateComponent implements OnInit {

  constructor(
    private commonUtils :CommonUtils,
    private httpTransfer:HttpTransferService,
    private toaster:ToasterService,
    private dashboardUtils: DashboardUtilsService
  ) { }
  createGroupForm:any;
  creatingGroup:boolean=false
  commonIcons = commonIcons;
  @Input() createGroupModelRef: NgbModalRef;
  @Input() selectedGroup:any=null
  @Output() updateField: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.createGroupForm = new FormGroup({
      group_name: new FormControl('', [Validators.required,Validators.maxLength(25), CommonUtils.minLengthWithTrimValidator]),
      description: new FormControl(''),
      is_private: new FormControl('', [Validators.required])
    });
    if(this.selectedGroup){
      let groupData = {}
      groupData["group_name"] = this.selectedGroup.group_name;
      groupData["description"] = this.selectedGroup.description;
      groupData["is_private"] = this.selectedGroup.is_private;
      this.createGroupForm.patchValue(groupData);

    }
  }
  onCreateGroup() {
    if(this.createGroupForm.valid) {
      this.creatingGroup=true;
      this.httpTransfer.createGroup(this.createGroupForm.value).subscribe((res : any) => {
        if(res !== undefined && res && res.status==200) {
          this.creatingGroup=false;
          this.toaster.Success('Group has been added successfully!');
          this.updateField.emit();
          this.createGroupModelRef.close()
        }
      },error=>{
        this.creatingGroup=false;
        this.createGroupModelRef.close()
      })
    }
  }
  onUpdateGroup(){
    if(this.createGroupForm.valid){
      this.creatingGroup=true;
      this.httpTransfer.updateGroup(this.selectedGroup._id,this.createGroupForm.value).subscribe((res : any) => {
        if(res!== undefined && res && res.status==200) {
            this.creatingGroup=false;
            this.toaster.Success("Group Updated Successfully");
            this.selectedGroup.group_name = this.createGroupForm.get('group_name').value;
            this.selectedGroup.description = this.createGroupForm.get('description')?.value;
            this.selectedGroup.is_private = this.createGroupForm.get('is_private').value;
            this.createGroupModelRef.close()
          }
        },error=>{
          this.creatingGroup=false;
          this.createGroupModelRef.close()
        })
    }
  }
}
