

<div class="fiscalPopBody mt-3">
    <div>
        <span style="color: #6D6E6F;">
            Select the day and month that marks the beginning of your fiscal year
        </span>
    </div>
    <div style="display: flex;width: 100%;column-gap: 5px;align-items: center;">
        <div class="addSubDateSpan">
            <span>Day</span>

            <ng-select style="height:fit-content;" [multiple]="false" bindLabel="display_key" [closeOnSelect]="true" 
            [clearable]="false" [ngModelOptions]="{standalone: true}" [(ngModel)]="copiedDateObj.fiscal_year.start_day" (ngModelChange)="dateObjChanged()">
              <ng-option *ngFor="let item of dayOptions" [value]="item.key"  [ngClass]="{'disableOption':!isThisDayAllowed(item.key)}" [disabled]="!isThisDayAllowed(item.key)">
                  {{item.display_key}}
              </ng-option>
             </ng-select>
        </div>
        <div class="addSubDateSpan">
            <span>Month</span>
            <ng-select style="height:fit-content;" [multiple]="false" bindLabel="display_key" [closeOnSelect]="true"
                [clearable]="false" [ngModelOptions]="{standalone: true}" [(ngModel)]="copiedDateObj.fiscal_year.start_month" (ngModelChange)="dateObjChanged()">
                    <ng-option *ngFor="let item of monthOptions" [value]="item.key"  [ngClass]="{'disableOption':!isThisMonthAllowed(item.key)}" [disabled]="!isThisMonthAllowed(item.key)">
                    {{item.display_key}}
                    </ng-option>
            </ng-select>
        </div>
    </div>
    <div style="display: flex;flex-direction: column;width: 100%;row-gap: 5px;">
        <div style="display: flex;width: 100%;column-gap: 5px; align-items: center;">
            <span for="" class="textHead"> Select Period </span>
        </div>

        <div style="display: flex;width: 100%;column-gap: 5px;align-items: center;">
                <ng-select style="height:fit-content; width: 200px;" [multiple]="false" bindLabel="display_key" [closeOnSelect]="true"
                [clearable]="false" [ngModelOptions]="{standalone: true}" [(ngModel)]="copiedDateObj.filter_cycle.month" (ngModelChange)="dateObjChanged()">
                    <ng-option *ngFor="let item of filterCycleOptions" [value]="item.key">
                    {{item.display_key}}
                    </ng-option>
                </ng-select>
            
        </div>
    </div>
    <div style="display: flex;flex-direction: column;width: 100%;row-gap: 5px;">
        <div style="display: flex;width: 100%;column-gap: 5px; align-items: center;">
            <span for="" class="textHead"> Evaluate Period </span>
        </div>

        <div style="display: flex;flex-direction: column;width: 100%;row-gap: 5px;">
            <div>
                <span>Calculate from Frequency </span>
                <!-- <input type="number" class="addSubDateInput" [(ngModel)]="copiedDateObj.relative_date.day"  (ngModelChange)="dateObjChanged()"> -->
            </div>
            <div  style="display: flex;flex-direction: column;row-gap: 5px;">
                <ng-select style="height:fit-content; width: 200px;" [multiple]="false" bindLabel="display_key" [closeOnSelect]="true"
                [clearable]="false" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedfiscalPeriod" (ngModelChange)="fiscalPeriodChange()">
                    <ng-option *ngFor="let item of fiscalperiodOptions" [value]="item.key">
                    {{item.display_key}}
                    </ng-option>
                </ng-select>
                <span *ngIf="selectedfiscalPeriod==='custom'"  style="display: flex;column-gap: 10px;align-items: center;color: #6D6E6F;">
                    <span>Enter Value</span>
                    <input type="number" class="addSubDateInput" 
                    [(ngModel)]="copiedDateObj.eval_period.relative_period" (ngModelChange)="dateObjChanged()" (focusout)="validateRelativePeriod()">
                </span>
            </div>
            <div *ngIf="isInvalidInput">
                <span class="text-danger">*Please Enter Valid Value.</span>
            </div>
            
        </div>
    </div>
    <div style="display: flex;width: 100%;column-gap: 5px; align-items: center;">
        <input type="checkbox" class="filterCheckBox"  [(ngModel)]="copiedDateObj.equivalent_period_till_today" (ngModelChange)="dateObjChanged()">
        <span for="" class="textHead"> Equivalent Period with respect to today’s date </span>
    </div>

    <div>
        <span class="finalFiscalDate">
            <span>
                This will evaluate from {{fiscalStartDateString}} to {{fiscalEndDateString }}
            </span>
        </span>
    </div>

    <div>
        <span *ngIf="!isInvalidInput" class="saveBtn" (click)="saveFiscalDate()">
            Save
        </span>
        <span *ngIf="isInvalidInput" class="saveBtnDisabled">
            Save
        </span>
    </div>

</div>