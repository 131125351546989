import { Component, Input, OnInit, Sanitizer, SecurityContext,Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ConstantService } from '../../../../../Enums/Constant.service';
import { MediaIcons } from '../../../../../Enums/media-icons';
import { CommonUtils } from '../../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../../services/httpTransfer.service';
import { ToasterService } from '../../../../services/toaster.service';

declare var $: any;

@Component({
  selector: 'app-whatsapp-editor',
  templateUrl: './whatsapp-editor.component.html',
  styleUrls: ['./whatsapp-editor.component.scss']
})

export class WhatsappEditorComponent implements OnInit {
  @Input() templateName:string;
  @Input() personalTemplates:boolean;
  @Input() dashIdSelected: string;
  @Input() autoReply:boolean
  @Input() autoReplyEnabled:boolean
  @Input() isCreatingTemplate: boolean;
  @Input() whatsappTemplate:any
  @Input() whatsappSettings:any
  @Input() sentence:any
  @Input() itemCall:boolean=false;
  @Input() isAdmin:boolean=false
  @Input() isReadOnly:boolean=false
  @Output() closeModal = new EventEmitter();
  isSaving:boolean=false
  mediaIcons = MediaIcons;
  whatsappSettingRule:string
  autoreplyTime:number
  selectedText: string;
  summerNoteconfig: {
    placeholder: string;
    tabsize: number;
    height: string;
    uploadImagePath: string;
    toolbar: (string | string[])[][];
    fontNames: string[];
  };
  enableSummernote:boolean=false;
  oRange: any;
  context: string;
  isOpen: boolean = false;
  arrOfCustomField: any;
  whatsappMedia: any[]=[];
  fileEvent: any[]=[];
  attachment: any[]=[];
  emptyAutoReply:boolean=false;
  constructor(    private constantService: ConstantService, 
    private httpTransfer: HttpTransferService,
    private dashboardUtils:DashboardUtilsService,
    private commonUtils : CommonUtils,
    private toaster: ToasterService ) { 
   this.summerNoteconfig = this.constantService.getSummerNoteConfig();

  }
  setFilePathToArrOfCustomFileds(fileUrlPath,filename) {
    console.log(fileUrlPath);
    fileUrlPath.forEach(e=>{
       let obj ={}
      obj['file_name'] = e.file_name+"."+e.path.split('.').pop(),
      obj['path'] = e.path
      if(e.media_type =='image')
      {
        obj['type'] = 'IMAGE'
      }
      else if(e.media_type =='video')
      {
        obj['type'] = 'VIDEO'
      } 
      else{
          obj['type'] = 'DOCUMENT'
      }
      this.attachment.push(obj)
    }) 
      if(this.isCreatingTemplate){this.createTemplate()}
      else{this.updateTemplate()} 
  }

  async ngOnInit(){
    if(this.autoReply && !this.isAdmin){
      this.enableSummernote=true;
    }
    if(this.whatsappTemplate?.attachments?.length){
    this.checkForattachments()
    }
    let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashIdSelected)
    this.arrOfCustomField = JSON.parse(JSON.stringify(dashboardJson[this.dashIdSelected]['CUSTOM_FORM']));
      if(this.sentence!=null && this.sentence.length){
        this.sentence = this.transform(this.sentence)
        this.sentence = this.sentence.replaceAll('\n','<br>')
      }
    this.summerNoteconfig = this.constantService.getSummerNoteConfig();
    this.initializeSummerNote()
    if(this.autoReply){
      if(this.whatsappSettings){
        this.whatsappSettingRule="ON_ITEM_CREATION"
        this.autoreplyTime=0
      }
      if(this.whatsappSettings.auto_reply_settings){
        this.whatsappSettingRule=this.whatsappSettings.auto_reply_settings.rule
        this.autoreplyTime=this.whatsappSettings.auto_reply_settings.time_in_hours
      }
    }
    
   }

   ngOnChanges(changes: SimpleChanges) {
    $('#summernotewhatsapp').summernote('destroy');
    this.summerNoteconfig = this.constantService.getSummerNoteConfig();
    this.initializeSummerNote()
   }
   
   initializeSummerNote() {
    setTimeout(() => {
      console.log(this.summerNoteconfig);
      $('#summernotewhatsapp').summernote({
        placeholder: "Write",
       // fontNames: ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Merriweather'],
         toolbar: [
          // [groupName, [list of button]]
          ['style', ['bold', 'italic']],
          ['font', ['strikethrough']],
        ],  
        callbacks : {
          onPaste: function (e) {
            let text = ((e.originalEvent || e).clipboardData).getData('text/HTML')
            if(text==null || text==""){
                text = ((e.originalEvent || e).clipboardData).getData('Text')
            }
            var bufferText = text;
              const regex = /(style=".+?")/gm;
              const regexLink = /(href=".+?")/gm;

             // The substituted value will be contained in the result variable
              let result = bufferText.replace(regex, '')
              console.log(result)
              e.preventDefault();
              document.execCommand('insertHtml', false, result);
          }
        },
      } );
      console.log(this.sentence);    
      if(((this.autoReply && !this.isAdmin) || this.isReadOnly || (!this.isAdmin && !this.personalTemplates)) && !this.itemCall) {
        $("#summernotewhatsapp").summernote('disable');
      } 
      // if(!this.isAdmin && !this.personalTemplates && !this.itemCall) {
      //   $("#summernotewhatsapp").summernote('disable');
      // }
      $("#summernotewhatsapp").summernote('code', this.sentence );
      $('#summernotewhatsapp').on('summernote.keydown', function(we, e) {
        $('#summernotewhatsapp').summernote('saveRange');
       });
    }, 100);
  }

  checkForattachments(){
   this.whatsappTemplate.attachments.forEach(e=>{
    let previewData = {};
    let obj = {}
      previewData["file_name"] = e.file_name;
      previewData["path"] =  e.url;
      previewData["type"] = e.type.toLowerCase()
      obj["file_name"] = e.file_name;
      obj["path"] =  e.path;
      obj["type"] = e.type ;
      this.whatsappMedia.push(previewData);   
      this.attachment.push(obj)       
   })
  }

  transform(value: string): string {
    const regex = new RegExp(/([*])(?:(?=(\\?))\2.)*?\1/g, 'gi');;
    let str = value.replace(regex, (match) => `<b>${match.replace(/\*/g,'')}</b>`);
     const regex2 = new RegExp(/([_])(?:(?=(\\?))\2.)*?\1/g, 'gi');;
    str = str.replace(regex2, (match) => `<i>${match.replace(/\_/g,'')}</i>`);
    const regex3 = new RegExp(/([~])(?:(?=(\\?))\2.)*?\1/g, 'gi');;
    str = str.replace(regex3, (match) => `<strike>${match.replace(/\~/g,'')}</strike>`);
   /*   const regex4 = new RegExp(/\```([a-z\s+0-9\@]+)```/g, 'gi');;
    str = str.replace(regex4, (match) => `<tt style="font-family : monospace">${match.replace(/\```([a-z\s+0-9\@]+)```/g,'')}</tt>`);
      */
    str = str.replace(/\`{3}(.*?)\`{3}/g, `<tt style="font-family: monospace">$1</tt>`)
    return str
    }

  send(){
    let str = $("#summernotewhatsapp").summernote("code")
    const regexLink = /( href=".+?")/gm;
    const regexLink2 = /(target=".+?")/gm;
    str = str.replaceAll('<b><br></b>','<br>').replaceAll('<i><br></i>','<br>').replaceAll('<strike><br></strike>','<br>')
    str = str.replace(regexLink,'').replace(regexLink2,'')
    str = str.replaceAll('<a >','').replaceAll('</a>','')
    str = str.replaceAll('&nbsp;','').replaceAll('<p>','').replaceAll('</p>','\n')
    str = str.replaceAll('</div>','').replaceAll('<div>','\n')
    str = str.replaceAll('<br>','\n')
    str = str.replaceAll('<b>','*').replaceAll('</b>','*')
    str = str.replaceAll('<i>','_').replaceAll('</i>','_')
    str = str.replaceAll('<strike>','~').replaceAll('</strike>','~')
    str = str.replaceAll('<tt>','```').replaceAll('</tt>','```')
    const regex = /(style=".+?")/gm;
    // The substituted value will be contained in the result variable
    str = str.replace(regex, '');
    str = str.replaceAll('<span>','').replaceAll('</span>','')

    return str
  }

  saveTemplate(){
     if(this.whatsappMedia.length && this.fileEvent?.length){
      this.commonUtils.onUploadFile(this.fileEvent, 'LEAD',null, this.setFilePathToArrOfCustomFileds.bind(this));
    } 
    else{
       if(this.isCreatingTemplate){this.createTemplate()}
       else{this.updateTemplate()} 
    }
  }

  createTemplate(){
    let message=this.send()
    let inputData= {
      "message":message,
      "type":"WHATSAPP",
      "attachments" : this.attachment,
      "template_name":this.templateName,
      ...(!this.personalTemplates && {dashboard_id:this.dashIdSelected}),

    }
    if(message.length){
      this.httpTransfer.createEmailTemplate(inputData).subscribe((res : any) =>{
        if(res.status==200){
          this.closeModal.emit();
          this.isSaving=false
        }
      },
        error=>{
          console.log("error while creating template") 
        }
      )
    }
    else{
      this.toaster.Errors("Enter Template Message")
    }

    
  }

  updateTemplate(){
    let template_id=this.whatsappTemplate["_id"]
    let message=this.send()
    let inputData={
      "message":message,
      "type":"WHATSAPP",
      "attachments" : this.attachment,
      "template_name":this.templateName,
    }
    if(message.length){
      this.httpTransfer.updateEmailTemplate(template_id,inputData).subscribe((res : any) =>{
        if(res.status==200){
          this.closeModal.emit();
          this.isSaving=false
        }
      },
        error=>{
          console.log("error while updating template") 
        }
      )
    }
    else{
      this.toaster.Errors("Please Enter Template Message")
    }
   
  }

  saveAutoReplySetting(){
    let message=this.send()
    let messagetest=message.replaceAll('\n',' ')
    messagetest=messagetest.trim()

    if( messagetest.length ){
      this.emptyAutoReply=false 
      let input={
        "whatsapp_settings":{
          "auto_reply": true,
          "auto_reply_settings":{
            "message":message,
            "rule":this.whatsappSettingRule,
            "time_in_hours": this.autoreplyTime
          }
        }
      }
      this.httpTransfer.updateDashBoardCorrespondingToOrganisation(input,null,this.dashIdSelected).subscribe((res : any) =>{
        this.closeModal.emit();
        this.isSaving=false
      },
        error=>{
          if(error.status==500){
            console.log(error.status)
          } 
        }
      )
    }
    else{
      this.emptyAutoReply=true 
    }
  }

  disableAutoreply(){
    let input={
      "whatsapp_settings":{
        "auto_reply": false
      }
    }
    this.httpTransfer.updateDashBoardCorrespondingToOrganisation(input,null,this.dashIdSelected).subscribe((res : any) =>{
      this.closeModal.emit();
    },
      error=>{
        if(error.status==500){
          console.log(error.status)
        } 
      }
    )
  }

  removeFile(i){
   this.whatsappMedia.splice(i,1)
   this.attachment.splice(i,1)
   if(this.fileEvent?.length){
     this.fileEvent.splice(i,1)
   }
   if(!this.whatsappMedia.length){
    this.sentence = $("#summernotewhatsapp").summernote("code"),
    $('#summernotewhatsapp').summernote('destroy');
    this.summerNoteconfig = this.constantService.getSummerNoteConfig();
    this.initializeSummerNote()
   }
  }

  getcursorPosition(){
    this.sentence = this.send()
  }
  destroy(){
    $('#summernotewhatsapp').summernote('destroy');
    this.summerNoteconfig = this.constantService.getSummerNoteConfig();
    this.initializeSummerNote()
  }

  getcustomvalue(item){
    $('#summernotewhatsapp').summernote('restoreRange'); 
    $('#summernotewhatsapp').summernote('editor.insertText', ` {{customField.${item.key}}} `);
    $('#summernotewhatsapp').summernote('saveRange');
  }
  getextravalue(item){
    $('#summernotewhatsapp').summernote('restoreRange'); 
    $('#summernotewhatsapp').summernote('editor.insertText', ` {{${item}}} `);
    $('#summernotewhatsapp').summernote('saveRange');
  }
 
  addTextOnCursor(value){
    $('#summernotewhatsapp').summernote('restoreRange'); 
    $('#summernotewhatsapp').summernote('editor.insertText', ` ${value} `);
   $('#summernotewhatsapp').summernote('saveRange');
  }

  onUploadFile(event){
    let files = event.target.files 
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.fileEvent.push(files[i])
        var reader = new FileReader();
        let previewData = {};
        reader.onload = (event: any) => {
          previewData["file_name"] = files[i].name;
          previewData["path"] = event.target.result;
          previewData["type"] = files[i].type.split("/")[0];
          this.whatsappMedia.push(previewData);          
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
    this.sentence = $("#summernotewhatsapp").summernote("code"),
    $('#summernotewhatsapp').summernote('destroy');
    this.summerNoteconfig = this.constantService.getSummerNoteConfig();
    this.initializeSummerNote()
  }
}





