    <div class="checkList-container">
      <div class="checkInput">
        <textarea [(ngModel)]="addCheckText" rows="2" class="border-0 pb-0 addInput"
          (keyup.enter)="addCheckListItem(addCheckText)" type="text" placeholder="Add Checklist Item"></textarea>
      </div>
      <ng-container *ngIf="checkList.length>0">
        <ng-container *ngFor="let item of checkList; let i=index">
          <div class="row checkList">
            <div class="col-md-1" (click)="updateState(i)">
              <img *ngIf="item.state==checkListState.COMPLETED" class="checkListIcon"
                src="assets/1x/pronnel_iconArtboard 29.png" alt="">
              <img *ngIf="item.state==checkListState.NOT_COMPLETED" class="checkListIcon"
                src="assets/1x/pronnel_iconArtboard 28.png" alt="">
            </div>
            <div *ngIf="!(isEdit && i==editIndex)" class="col-md-8">{{ item.summary}}</div>
            <div *ngIf="isEdit && i==editIndex" class="col-md-8"><textarea type="text" rows="2" [placeholder]="item.summary" [(ngModel)]="item.summary" (blur)="updateCheckListEdit()" (keyup.enter)="updateCheckListEdit()"></textarea></div>
            <div class="col-md-1" (click)="editCheckItem(i)"><i class="fas fa-pencil-alt"></i></div>
            <div class="col-md-1" (click)="deleteCheckItem(i)"><img class="checkListIcon"
                src="../../../assets/1x/checklist-remove-ico.svg" alt=""></div>
          </div>
        </ng-container>
      </ng-container>
    </div>