<div class="mx-0 w-100 timeTrackerComponent">
    <div class="startTimeBtn d-inline-flex key-tab" *ngIf="!isReadOnly && (item.process=='AUTOMATED' || item.process=='BOTH') && view!=='GRID'">
      <i *ngIf="!item.pause && item.disabled" class="fa fa-play-circle greyBtn mt-2 key-focus" placement="bottom" triggers="hover" [popover]="filtersTemplate"  tabindex="0"
      [outsideClick]="true"></i>
      <i *ngIf="!item.pause && !item.disabled" (click)="item.pause=true;createTimeTrackerStart(item)" class="fa fa-play-circle mt-2 key-focus" aria-hidden="true" tabindex="0"></i>
      <i *ngIf="item.pause && item.disabled"  class="fa fa-pause-circle greyBtn mt-2 key-focus" placement="bottom" triggers="hover" [popover]="filtersTemplate" tabindex="0"
      [outsideClick]="true"></i>
      <i *ngIf="item.pause && !item.disabled" (click)="item.pause=false;createTimeTrackerStop(item)" class="fa fa-pause-circle mt-2 key-focus" tabindex="0" aria-hidden="true"></i>
    </div>
    <span *ngIf="!isReadOnly && (item.process=='AUTOMATED' || item.process=='BOTH') && view=='GRID'" class="key-tab">
      <i *ngIf="!item.pause && item.disabled" class="fa fa-play-circle greyBtn key-focus" placement="bottom" triggers="hover" [popover]="filtersTemplate" tabindex="0"
      [outsideClick]="true"></i>
      <i *ngIf="!item.pause && !item.disabled" (click)="item.pause=true;createTimeTrackerStart(item)" class="fa fa-play-circle key-focus" tabindex="0" aria-hidden="true"></i>
      <i *ngIf="item.pause && item.disabled"  class="fa fa-pause-circle greyBtn key-focus" placement="bottom" triggers="hover" tabindex="0" [popover]="filtersTemplate"
      [outsideClick]="true"></i>
      <i *ngIf="item.pause && !item.disabled" (click)="item.pause=false;createTimeTrackerStop(item)" class="fa fa-pause-circle key-focus" tabindex="0" aria-hidden="true"></i>
    </span>
    <ng-template #filtersTemplate>
      <ng-container>
          <div class="filterCounts">
              <div>{{item.message}}</div>
          </div>
      </ng-container>
  </ng-template>
  <div class=" ml-3 timeValueBox key-tab" container="body" [autoClose]="'outside'" tabindex="0"
    [ngbPopover]="timeTracker" (click)="timeManual=false;logDetails(item);selectedTimeTracker=item">
    <div style="width: 70px;">{{item.timer}}</div>
    <i *ngIf="view == 'GRID'" class="fa fa-chevron-circle-down ml-3"  aria-hidden="true"></i>
    <i *ngIf="view == 'KANBAN'" class="fa fa-chevron-circle-down float-right mt-1 mr-2 ml-3"  aria-hidden="true"></i>
  </div>
   <!----Log Details Button-->
   <div *ngIf="view !== 'GRID' && view !== 'KANBAN'" class="logDetailsButton mt-2 key-tab" style="float: right;"  container="body" [autoClose]="'outside'" tabindex="0"
   [ngbPopover]="timeTracker" (click)="timeManual=false;logDetails(item);selectedTimeTracker=item">
    Log details</div>
  <!-- <div *ngIf="isPublicLead" class="mt-1">
    {{item.timer}}
</div> -->
</div>
<ng-template #timeTracker>
    <div class="timerBox" *ngIf="!timeManual" style="overflow-y: auto;overflow-x: hidden;">
      <div class="row pb-1 mb-2" style="border-bottom:1px solid rgb(243, 239, 239)">
        <div class="col h5 align-items-center">
          {{'timeTracker.timeTrackerLogs' | translate}}
        </div>
        <div class="col">
          <button *ngIf="!isReadOnly && (dashboardUserRole.includes('DASHBOARD_ADMIN') || ((selectedTimeTracker['process']=='MANUAL' || selectedTimeTracker['process']=='BOTH') && !selectedTimeTracker.manualdisabled))" class="btn btn-outline-primary float-right manualBtn" (click)="timeManual=true;endTime='';startTime='';"><i class="fa fa-circle mr-2" aria-hidden="true" style="color:#006296;"></i>
            {{'timeTracker.manaulSession' | translate}}</button>
            <button *ngIf="isReadOnly && (!dashboardUserRole.includes('DASHBOARD_ADMIN') && (!(selectedTimeTracker['process']=='MANUAL' || selectedTimeTracker['process']=='BOTH') || selectedTimeTracker.manualdisabled))" disabled class="btn btn-outline-primary float-right manualBtn greyBtn" placement="bottom" triggers="hover" [popover]="manualTemplate"
            [outsideClick]="true"><i class="fa fa-circle mr-2" aria-hidden="true" style="color:#006296;"></i>
              {{'timeTracker.manaulSession' | translate}}</button>
        </div>
        <ng-template #manualTemplate>
          <ng-container>
              <div class="filterCounts">
                  <div>{{item.message}}</div>
              </div>
          </ng-container>
      </ng-template>
      </div>
      <table>
        <tr *ngFor="let data of timeTrackerList">
          <td style="width:2%"><i *ngIf="data['update_type']=='MANUAL'" class="fa fa-circle mr-2" aria-hidden="true" style="color:#006296;"></i></td>
          <td style="width:30%"> <ngx-avatars class="mr-1 d-inline-block" [name]="orgUsersJson[data?.for_user_id]?.name" [src]="orgUsersJson[data?.for_user_id]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
            {{orgUsersJson[data?.for_user_id]?.name}}</td>
          <td style="width:20%">
            <div class="mt-3">
              {{data.start_time | date:'dd MMM yyyy'}}<br>
              <p style="color: #006296;">{{data.start_time | date:'h:mm a'}}</p>
            </div>
          </td>
          <td style="width:20%;">
            <div class="mt-3" *ngIf="data.end_time">
              {{data.end_time | date:'dd MMM yyyy'}}<br>
              <p style="color: #006296;">{{data.end_time | date:'h:mm a'}}</p>
            </div>
            <div *ngIf="!data.end_time && !dashboardUserRole.includes('DASHBOARD_ADMIN')">
              Tracker running
            </div>
            <div class="mt-1" *ngIf="!isPublicLead  && (!data.end_time && dashboardUserRole.includes('DASHBOARD_ADMIN'))">
              <i (click)="createTimeTrackerStop(data);data.end_time=mnt.now()" class="fa fa-pause-circle fa-2x mx-auto" aria-hidden="true"></i>
            </div>
          </td>
          <td style="width:16%">
            {{data.time_difference}}
          </td>
          <td style="width:5%">
            <img class="mr-3 ml-2" role="button" src="../../../assets/common-use-icon-20dp/info-6d6e6f.svg" style="width: 0.8em;" #popOvertimeTrackerInfo="ngbPopover" container="body" [autoClose]="'outside'"
            [ngbPopover]="!isReadOnly && timeTrackerInfo">
          </td>
          <ng-template #timeTrackerInfo>
            <div style="width:280px;height:82px;">
              <!-- <div class="mt-2 ml-2 mb-3">
                Added by :<ngx-avatars class="mr-1 d-inline-block ml-2" [name]="orgUsersJson[data?.updated_by]?.name" [src]="orgUsersJson[data.updated_by]?.image_url" size="25" initialsSize="2" ></ngx-avatars>{{orgUsersJson[data?.updated_by]?.name}}
              </div>
              <div class="mt-2 ml-2 mb-3">
                Added on : {{data.end_time | date:'dd MMM yyyy'}}
              </div> -->
              <table class="mt-3">
                <tr>
                  <td style="width: 30%;" class="pb-3">
                    {{'timeTracker.AddedBy' | translate}}
                  </td>
                  <td style="width: 4%;" class="pb-3">
                    :
                  </td>
                  <td style="width: 66%;" class="pb-3">
                    <ngx-avatars class="mr-1 d-inline-block" [name]="orgUsersJson[data?.updated_by]?.name" [src]="orgUsersJson[data?.updated_by]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                      {{orgUsersJson[data?.updated_by]?.name}}
                  </td>
                </tr>
                <tr>
                  <td style="width: 30%;">
                    {{'timeTracker.addedOn' | translate}}
                  </td>
                  <td style="width: 4%;">
                    :
                  </td>
                  <td style="width: 66%;">
                    {{data.end_time | date:'dd MMM yyyy'}}
                  </td>
                </tr>
              </table>
            </div>
          </ng-template>
          <td style="width:5%">
            <img *ngIf="!isPublicLead && ((data.for_user_id ==userId && (selectedTimeTracker['process']=='MANUAL' || selectedTimeTracker['process']=='BOTH')) || dashboardUserRole.includes('DASHBOARD_ADMIN') )" class="mr-3" role="button" src="../../../assets/1x/formrow-delete-ico.png" style="width: 0.8em;" (click)="removeTimeTrackerEntry(data._id,data.for_user_id)">
          </td>
        </tr>
      </table>
    </div>
    <div class="timerBox" style="height: auto;" *ngIf="timeManual">
      <div class="row pb-1 mb-2" style="border-bottom:1px solid rgb(243, 239, 239)">
        <div class="col h5 align-items-center">
          <i class="fa fa-arrow-left mr-2" aria-hidden="true" (click)="timeManual=false;startTime='';endTime=''"></i>
          {{'timeTracker.addManualSessions' | translate}}
        </div>
      </div>
      <div class="mx-3 row mb-2">
        <div class="col" *ngIf="dashboardUserRole.includes('DASHBOARD_ADMIN')">
          <ng-select [items]="dashboardUser" [searchFn]="commonUtils.onSearchCode" [multiple]="false" bindLabel="name" [closeOnSelect]="true" placeholder="Select user"
            bindValue="_id" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedUserForTimeTracker" [clearable]="false">
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="d-flex align-items-center">
                <div class="d-flex">
                  <ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                </div>  
                <div class="d-flex flex-column">
                  <span>{{item?.name}}</span>
                  <small class="my-0">{{item?.email}}</small>
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="col" *ngIf="!dashboardUserRole.includes('DASHBOARD_ADMIN')">
          <ng-select [disabled]="!dashboardUserRole.includes('DASHBOARD_ADMIN')" [multiple]="false" [searchFn]="onSearchCode" [closeOnSelect]="true" placeholder="Select user" [(ngModel)]="selectedUserForTimeTracker">
            <ng-option *ngFor="let data of dashboardUser" [value]="data._id">
                {{data.name}}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="mx-3 row mb-3">
        <div class="col">
          {{'timeTracker.Session' | translate}}
        </div>
      </div>
        <div class="mx-3 row mb-4">
            <div class="col-5 datePickerTimer">
              <div class=" timetrackerbtn" [ngbPopover]="!isReadOnly && startdatePopover" #popDateStart="ngbPopover" placement="auto" popoverClass="datePickerPopover"
              #popOver="ngbPopover" [autoClose]="'outside'" (click)="$event.stopPropagation()">
                <img class="mr-2" src="../../../assets/1x/calender-ico.svg">
                <span>{{'timeTracker.startTime' | translate}}</span>
              </div>
              <div *ngIf="startTime?.time" class="d-inline-block ml-2">
                {{startTime | dateCalcuate:"dateTime"}}
              </div>
            </div> 
            <div class="col-2">
              <i class="fa fa-minus mt-3 ml-3" aria-hidden="true"></i>
            </div>
            <div class="col-5 datePickerTimer">
              <button class="timetrackerbtn" [ngbPopover]="!isReadOnly && enddatePopover" #popDateEnd="ngbPopover" placement="auto" popoverClass="datePickerPopover"
              #popOver="ngbPopover" [autoClose]="'outside'" (click)="$event.stopPropagation()">
                <img class="mr-2" src="../../../assets/1x/calender-ico.svg">
                <span>{{'timeTracker.endTime' | translate}}</span>
              </button>
              <div *ngIf="endTime?.time" class="d-inline-block ml-2">
                {{ endTime | dateCalcuate:"dateTime"}}
              </div>
            </div>
          <ng-template #startdatePopover>
            <date-picker (click)="$event.stopPropagation()"
              [dateRange]="{'from':startTime}"
              [focusDate]="startTime"
              [dateConfig]="{'maxDate':{time:maxDate?.getTime()}}"
              (onDateSave)="startTime = $event;popDateStart?.close()"
              (onDateClear)="startTime = null;popDateStart?.close()">
            </date-picker>
          </ng-template>
          <ng-template #enddatePopover>
            <date-picker (click)="$event.stopPropagation()"
              [dateRange]="{'to':endTime}"
              [focusDate]="endTime"
              [dateConfig]="{'maxDate':{time:maxDate?.getTime()}}"
              (onDateSave)="endTime = $event;popDateEnd.close()"
              (onDateClear)="endTime = null;popDateEnd.close()">
            </date-picker>
          </ng-template>
        </div>
        <div class="manualEntryFooter">
          <button *ngIf="startTime && endTime && selectedUserForTimeTracker && (startTime.time<endTime.time)" class="btn  mx-3 mt-2 mb-2 px-4" style="border-radius: 13px; background-color: #FF3F41;color: white;" (click)="manualTimerEntry(selectedTimeTracker)">{{'common.save' |translate}}</button>
          <button *ngIf="!(startTime && endTime && selectedUserForTimeTracker && (startTime.time<endTime.time))" disabled class="btn  mx-3 mt-2 mb-2 px-4" style="border-radius: 13px; background-color: grey;color: white;">{{'common.save' |translate}}</button>
          <button (click)="startTime='';endTime='';" class="btn  mx-3 mt-2 mb-2 px-4" style="border-radius: 13px; background-color: #B2B2B2;color: black;">{{'common.cancel' |translate}}</button>
        </div>
    </div>
  </ng-template>
