import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConstantService } from "Enums/Constant.service";
import { AttributesIcons, AttributesLxIcons, commonIcons } from "Enums/attributes-icons";
import { MessageService } from "app/message.service";
import { CommonUtils } from "app/services/CommonUtils.service";
import { CustomStorageService } from "app/services/custom-storage.service";
import { DashboardUtilsService } from "app/services/dashboard-utils.service";
import { HttpTransferService } from "app/services/httpTransfer.service";
import { MqttmessagingService } from "app/services/mqttmessaging.service";
import { SortingutilsService } from "app/sortingutils.service";
import { setTime } from "ngx-bootstrap/chronos/utils/date-setters";
import { Subscription } from "rxjs";
import { BasicUtils } from "app/services/basicUtils.service";

@Component({
    selector: 'app-chat-view',
    templateUrl: './chat-view.component.html',
    styleUrls: ['./chat-view.component.scss']
  })
  
  export class ChatViewComponent implements OnInit {
  @Input() selectedDashId: string;
  @Input() isChatExpand:boolean=false;
  @Input() leadInfo: any;
  @Input() internalChats:boolean=false;
  @Input() currentViewId:any
  lxAttributesIcons = AttributesLxIcons
  attributesIcons = AttributesIcons;
  showLoader :boolean = true;
  commentArr: any[]=[];
  orgUsersJson: any;
  selectedChannelObj: any;
  selectedLeadObj: any;
  selectedChatUserMsgarr: any;
  currentPopoverRef: any;
  chatUsersArr: any[]=[];
  mqttSubscription: any;
  dashboardChannels: any[]=[];
  showChatWindow:boolean = false;
  filterSub: Subscription;
  preApprovedTemplateForChannelSub: Subscription;
  @Input() filterDataObj: any;
  chatUserLeadArr: any[]=[];
  loginUserId: string;
  preApproveedTemplate:any[]=[]
  disableEditor : boolean = false;
  chatUserProfileUrl: any;
  commentUpdate: any;
  commentCurrentPage: number =1;
  pageSizeForComments: number = 40;
  commentTotal_Page: number;
  emailThreadData: any = null;
  emailLeadInfoMap: Map<string, string> = new Map<string, string>();
  chatMsgcurrentPage: any = 1;
  pageSizeForChatMsg: number = 20;
  chatMsgTotal_Page: number;
  userEmailConnections:any={};
  searchText:string;
  isFilterApply: boolean = false;
  restoreScrollPosition: any;
  itemPreviewData:any=[];
  chatGroupType : any[]=[{display_key:"Open",key:"OPEN"},{display_key:"Close",key:"CLOSED"}]
  selectedchatGroupType:any[]=["OPEN"];
  @Input() chatLeftPanelForBottombar:boolean;
  @Input() channelIds:any=null;

  constructor(private httpTransfer : HttpTransferService,public dashboardUtils : DashboardUtilsService,public commonUtils : CommonUtils,public MqttmessagingService : MqttmessagingService, private msgservice: MessageService,public customStorageService : CustomStorageService,
    private modalServices : NgbModal, public sortingUtils : SortingutilsService,private basicUtils:BasicUtils,private cdr : ChangeDetectorRef )
  {
    this.loginUserId = this.customStorageService.getItem("id");
    this.listenForMqttMessages();
    this.filterSub = this.msgservice.getFilterDataObject().subscribe(data => {
      if(data)
      this.isFilterApply = true;
      this.filterDataObj = data;
     let filterDataObj = this.commonUtils.cleanFilterDataObject(this.filterDataObj)
     let saveFilterPayload = this.commonUtils.getFilterJsonForQuery({},filterDataObj)
     this.commentCurrentPage = 1
     this.commentArr = []
     this.showLoader = true;
     this.selectedChannelObj = {}
     this.selectedChatUserMsgarr = []
     this.getChannelMessages(saveFilterPayload,false)
    })
    this.preApprovedTemplateForChannelSub=this.msgservice.getPreApprovedTemplateForChannel().subscribe(data => {
      if(data){
        this.preApproveedTemplate = data;
      }
    });
  }
  
  async ngOnInit() {
      this.orgUsersJson = await this.dashboardUtils.getOrgUsers(); 
      let dashboardJson = await this.dashboardUtils.getDashboardChannels([this.selectedDashId])
       let dashJson = JSON.parse(JSON.stringify(dashboardJson))
       this.dashboardChannels = dashJson[this.selectedDashId]['CHANNELS'] || [];  
       this.getChatUser()
       this.getUserMailConnections()
  }

  listenForMqttMessages() {
    this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
      if (message) {
          this.updatesFromWss(message)
      }
    });
  }

  async updatesFromWss(messagees){
    let dashboardJson = await this.dashboardUtils.getDashboardChannels([this.selectedDashId])
    let dashJson = JSON.parse(JSON.stringify(dashboardJson))
    this.dashboardChannels = dashJson[this.selectedDashId]['CHANNELS'] || [];
    let message = JSON.parse(messagees); 
    let commentObj=message?.additional_attributes?.details
    console.log(message)
    if(commentObj.type=='EMAIL'){
      let index=this.commentArr.findIndex(ele=>ele.type=='EMAIL' && ele?.email_object?.smtp_thread_id===commentObj?.email_object?.smtp_thread_id)
      // commentObj['smtp_thread_count']=5
      this.commentArr.forEach(ele=>{
        if(ele?.email_object?.smtp_thread_id===commentObj?.email_object?.smtp_thread_id){
          commentObj.smtp_thread_count=1+(ele?.smtp_thread_count || 0)
        }
      })
      if(index>-1){
        this.commentArr.splice(index,1);
      }
      this.commentArr.unshift(commentObj)
      this.commentArr = [...this.commentArr]
      if(this.selectedChannelObj?.type=='EMAIL' && this.selectedChannelObj?.email_object?.smtp_thread_id===commentObj?.email_object?.smtp_thread_id){
        this.selectedChannelObj=this.commentArr[0]
      }
    }
    if (message[ConstantService.constant.OBJECTTYPEKEY] == "COMMENT" &&  commentObj?.message_group_id == this.selectedChannelObj?.message_group_id && this.selectedChannelObj?.chat_user_id == commentObj?.chat_user_id && this.selectedChannelObj?.channel_id == commentObj?.channel_id && ['EXTERNAL_INBOUND','EXTERNAL_OUTBOUND','EMAIL'].includes(commentObj?.type)){
      this.handleUpdateForSendMsg(message)
    }
    else if (message[ConstantService.constant.OBJECTTYPEKEY] == "COMMENT" && ['EXTERNAL_INBOUND','EXTERNAL_OUTBOUND'].includes(commentObj?.type) && (!this.isChatExpand || this.isChatExpand && this.leadInfo?._id == message?.additional_attributes?.details?._id)){
        this.handleUpdateForCommentType(message)
    }
     if (message[ConstantService.constant.OBJECTTYPEKEY] == "LEAD" && this.selectedDashId == message?.additional_attributes?.details?.dashboard_id && (!this.isChatExpand || this.isChatExpand && this.leadInfo?._id == message?.additional_attributes?.details?._id) && (message[ConstantService.constant.ACTIVITYTYPEKEY] == ConstantService.constant.UPDATEACTIVITYTYPE || message[ConstantService.constant.ACTIVITYTYPEKEY] == "DELETE")){
      let index = this.chatUserLeadArr.findIndex(m=>m._id == message?.additional_attributes?.details?._id)
      if(index==-1){
        this.chatUserLeadArr.push(message?.additional_attributes?.details)
      }
      else{
        this.chatUserLeadArr[index] = message?.additional_attributes?.details
      }
      this.getUpdateReadUnreadCount()
      let filterLead = this.commentArr.filter(l=>l.lead_id == message?.additional_attributes?.details?._id)
      filterLead = filterLead.filter((comment, index, arr) => arr.findIndex(c => c.lead_id === comment.lead_id && c.type === 'EMAIL') === index);
      filterLead.forEach(async i=>{
        let index = this.chatUserLeadArr.findIndex(m=>m?._id == i?.lead_id)
        if(this.isFilterApply && this.filterDataObj && index>=0){
        let filterDataObj = this.commonUtils.cleanFilterDataObject(this.filterDataObj)
        let saveFilterPayload = this.commonUtils.getFilterJsonForQuery({},filterDataObj)
          let sortJson = await this.sortingUtils.getIndexForNewLead(
            this.chatUserLeadArr[index],
            saveFilterPayload,
            [this.chatUserLeadArr[index]],
            this.selectedDashId
        )
        if(sortJson?.index<0){
          console.log(this.selectedChannelObj)
          let commentIndex = this.commentArr.findIndex(l=>l.lead_id == this.chatUserLeadArr[index]?._id)
          if(this.selectedChannelObj?.lead_id == this.commentArr[commentIndex]?.lead_id)  {
            this.selectedChannelObj = {}
            this.commentArr.splice(commentIndex,1)
          this.selectedChannelObj = this.commentArr[0]
          let selectedLead = this.chatUserLeadArr.filter(m=>m._id == this.selectedChannelObj?.lead_id)
          this.selectedLeadObj = selectedLead[0]
          let obj={}
          obj["selectedLeadObj"] = selectedLead[0];
          this.msgservice.setExternalChatData(obj);
           if(this.selectedChannelObj.type !== "EMAIL"){
            let selectedChannel = this.dashboardChannels.filter(i=>i._id == this.commentArr[0]?.channel_id)
            let selectedchatUser = this.chatUsersArr.filter(m=>m._id==this.commentArr[0]?.chat_user_id)
            this.selectedChannelObj['channelType'] = selectedChannel[0]?.type
            this.selectedChannelObj['channelName'] = selectedChannel[0]?.name
            this.selectedChannelObj['chatUserName'] = selectedchatUser[0]?.name
            this.selectedChannelObj['profile_pic'] = selectedchatUser[0]?.profile_pic
            this.getselectedChatMsg(false)
          }
        }
        }
        }
      })
      setTimeout(()=>{
        this.mapDataForEmail()
      },3000)

     // this.getUnreadCountMsg()


    } 
  }


  async handleUpdateForCommentType(message){
    let index = this.commentArr.findIndex(i=>i.chat_user_id == message?.additional_attributes?.details?.chat_user_id && i?.lead_id == message?.additional_attributes?.details?.lead_id && i?.channel_id == message?.additional_attributes?.details?.channel_id)
    let channel = this.dashboardChannels.filter(i=>i._id == message?.additional_attributes?.details?.channel_id)
    let chatUser = []
    let filterLeadIndex;
    let sortJson;
    let statusUpdate = false;
    this.commentUpdate = message?.additional_attributes?.details
    if(index>=0){
      if(message[ConstantService.constant.ACTIVITYTYPEKEY]=='UPDATE' && message?.additional_attributes['updated_keys']?.length && message?.additional_attributes['updated_keys'][0]=="status"){
        statusUpdate = true
        message?.additional_attributes?.details?.status_details?.received_time ?
        this.commentUpdate['receivedTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.received_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.received_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.received_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.received_time)['time'] 
        : this.commentUpdate['sentTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.sent_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.sent_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.sent_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.sent_time)['time']   
        if(message?.additional_attributes?.details.status_details?.delivered_time) {
          this.commentUpdate['deliverTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.delivered_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.delivered_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.delivered_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.delivered_time)['time'] 
        }
        if(message?.additional_attributes?.details?.status_details?.read_time) {
          this.commentUpdate['readTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.read_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.read_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.read_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.read_time)['time'] 
        }
        if(message?.additional_attributes?.details?.status_details?.deleted_time) {
          this.commentUpdate['deletedTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.deleted_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.deleted_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.deleted_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.deleted_time)['time'] 
        }
        console.log(this.commentUpdate)
      }
      else{
        this.commentArr.splice(index,1)
      }
     // this.commentArr = this.commentArr.filter(i=>i.chat_user_id != message?.additional_attributes?.details?.chat_user_id)
     chatUser = this.chatUsersArr.filter(m=>m._id==message?.additional_attributes?.details?.chat_user_id)
     if(!chatUser.length){
      let res = await this.httpTransfer.getChatUserQuery({'dashboard_id' : [this.selectedDashId],'channel_id':[message?.additional_attributes?.details?.channel_id]}).toPromise()
      chatUser = res.result?.chatUsers?.filter(m=>m._id==message?.additional_attributes?.details?.chat_user_id)
    }
     this.commentUpdate['channelType'] = channel[0]?.type
     this.commentUpdate['channelName'] = channel[0]?.name
     this.commentUpdate['chatUserName'] = chatUser[0]?.name
     this.commentUpdate['profile_pic'] = chatUser[0]?.profile_pic
     filterLeadIndex = this.chatUserLeadArr.findIndex(m=>m._id == message?.additional_attributes?.details?.lead_id)
    }   
    else{
      let res = await this.httpTransfer.getChatUserQuery({'dashboard_id' : [this.selectedDashId],'channel_id':[message?.additional_attributes?.details?.channel_id]}).toPromise()
      chatUser = res.result?.chatUsers?.filter(m=>m._id==message?.additional_attributes?.details?.chat_user_id)
      if(this.chatUserLeadArr.findIndex(m=>m._id == message?.additional_attributes?.details?.lead_id) == -1){
      let res : any=await this.httpTransfer.getLeadQuery({"lead_id" : message?.additional_attributes?.details?.lead_id}).toPromise()
        this.chatUserLeadArr = [...this.chatUserLeadArr,...res.result?.leadResponse]
        filterLeadIndex = this.chatUserLeadArr.findIndex(m=>m._id == message?.additional_attributes?.details?.lead_id)
     } 
     else{
      filterLeadIndex = this.chatUserLeadArr.findIndex(m=>m._id == message?.additional_attributes?.details?.lead_id)
     }
     this.commentUpdate['channelType'] = channel[0]?.type
     this.commentUpdate['channelName'] = channel[0]?.name
     this.commentUpdate['chatUserName'] = chatUser[0]?.name
     this.commentUpdate['profile_pic'] = chatUser[0]?.profile_pic
    }
    if(!statusUpdate){
      message?.additional_attributes?.details?.status_details?.received_time ?
      this.commentUpdate['receivedTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.received_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.received_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.received_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.received_time)['time'] 
      : this.commentUpdate['sentTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.sent_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.sent_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.sent_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.sent_time)['time']   
      if(message?.additional_attributes?.details.status_details?.delivered_time) {
       this.commentUpdate['deliverTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.delivered_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.delivered_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.delivered_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.delivered_time)['time'] 
     }
     if(message?.additional_attributes?.details?.status_details?.read_time) {
       this.commentUpdate['readTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.read_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.read_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.read_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.read_time)['time'] 
     }
     if(message?.additional_attributes?.details?.status_details?.deleted_time) {
      this.commentUpdate['deletedTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.deleted_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.deleted_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.deleted_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.deleted_time)['time'] 
    }
    }
    if(this.isFilterApply && this.filterDataObj && filterLeadIndex>=0){
      sortJson = await this.sortingUtils.getIndexForNewLead(
        this.chatUserLeadArr[filterLeadIndex],
        this.filterDataObj,
        [this.chatUserLeadArr[filterLeadIndex]],
        this.selectedDashId
    )
    if(sortJson?.index>=0){
      if(statusUpdate){
        this.commentArr[index] = this.commentUpdate
      }
      else{
        let dummyIndex = this.commentArr.findIndex(i=>i.chat_user_id == message?.additional_attributes?.details?.chat_user_id && i?.lead_id == message?.additional_attributes?.details?.lead_id && i?.channel_id == message?.additional_attributes?.details?.channel_id)
        if(dummyIndex>-1){
          this.commentArr.splice(dummyIndex,1)
        }
        if(!this.isChatExpand || (this.isChatExpand && this.leadInfo?._id !== this.commentUpdate?.lead_id)){
          this.commentArr.unshift(this.commentUpdate)
        }

      }        
      this.getUpdateReadUnreadCount(message?.additional_attributes?.details)
    }
  }
  else{
    if(statusUpdate){
      this.commentArr[index] = this.commentUpdate
    }
    else{
      let dummyIndex = this.commentArr.findIndex(i=>i.chat_user_id == message?.additional_attributes?.details?.chat_user_id && i?.lead_id == message?.additional_attributes?.details?.lead_id && i?.channel_id == message?.additional_attributes?.details?.channel_id)
      if(dummyIndex>-1){
        this.commentArr.splice(dummyIndex,1)
      }
      this.commentArr.unshift(this.commentUpdate)
    } 
    this.getUpdateReadUnreadCount(message?.additional_attributes?.details)
  } 
  }


  async handleUpdateForSendMsg(message){
    if(message?.additional_attributes?.details?.type!='EMAIL'){
    let leadData = this.commentArr.filter(q=>q?.message_group_id === message.additional_attributes.details.message_group_id)
    if(leadData?.length && leadData[0]?.lead_id){  
      message.additional_attributes.details['lead_id'] = leadData[0].lead_id?.length>0 ? [leadData[0].lead_id]: message.additional_attributes?.lead_id ? [message.additional_attributes?.lead_id] : message.additional_attributes.details['lead_id']
      if(message?.activity_type=='ADD'){
      this.httpTransfer.readCommentApi({comment_id:message?.additional_attributes?.details?._id,message_group_id:message.additional_attributes.details.message_group_id},this.selectedDashId,message.additional_attributes.details['lead_id'][0]).subscribe(res=>{})
    }
    let obj = await this.commonUtils.chatLive(message,this.selectedDashId,this.chatUsersArr,this.selectedChatUserMsgarr,this.selectedChannelObj,this.commentArr,this.disableEditor)
    this.disableEditor = obj?.disableEditor
    this.selectedChatUserMsgarr = obj?.selectedChatUserMsgarr
     this.commentArr = obj?.commentArr
     this.getUpdateReadUnreadCount(message?.additional_attributes?.details)
   }
   let obj={}
   obj["selectedChatUserMsgarr"] = this.selectedChatUserMsgarr;
   this.msgservice.setExternalChatData(obj)
    }
  }

  getUpdateReadUnreadCount(commentObj?){
      setTimeout(()=>{
        console.log(this.chatUsersArr)
        console.log(this.commentArr)
        this.commentArr.forEach(m=>{
          let filterLeadIndex = this.chatUsersArr.findIndex(p=>p?._id == m?.chat_user_id)
          let chatUser = `${m?.channel_id}_${m?.chat_user_id}`
          if(filterLeadIndex>=0 && this.chatUsersArr[filterLeadIndex]['read_comment_details'] && this.chatUsersArr[filterLeadIndex]['read_comment_details'][this.loginUserId]){
            m['unreadCount'] = this.chatUsersArr[filterLeadIndex]?.read_comment_details[this.loginUserId][chatUser]?.read_count ? this.chatUsersArr[filterLeadIndex]?.comment_details[chatUser]?.count - this.chatUsersArr[filterLeadIndex]?.read_comment_details[this.loginUserId][chatUser]?.read_count : this.chatUsersArr[filterLeadIndex]?.comment_details[chatUser]?.count
            /*  Object.keys(this.chatUserLeadArr[filterLeadIndex]['read_comment_details'][this.loginUserId]).forEach(user=>{
              if(user == chatUser){
                 m['unreadCount'] = this.chatUserLeadArr[filterLeadIndex]?.read_comment_details[this.loginUserId][chatUser]?.read_count ? this.chatUserLeadArr[filterLeadIndex]?.comment_details[chatUser]?.count - this.chatUserLeadArr[filterLeadIndex]?.read_comment_details[this.loginUserId][chatUser]?.read_count : this.chatUserLeadArr[filterLeadIndex]?.comment_details[chatUser]?.count
              }
              else{
                m['unreadCount'] = this.chatUserLeadArr[filterLeadIndex]?.comment_details[chatUser]?.count || 0
           }
            }) */
          }
       /*  this.chatUserLeadArr[filterLeadIndex] = this.commonUtils.calcuateCommentCount(this.chatUserLeadArr[filterLeadIndex],this.chatUserLeadArr[filterLeadIndex])
        let copyleadInfoForcomment=JSON.parse(JSON.stringify(this.chatUserLeadArr[filterLeadIndex]))
        m['unreadCount'] = copyleadInfoForcomment?.totalCommentCount - copyleadInfoForcomment?.totalReadCommentCount
       */ })
      },200)  
  }

  getChatUser(){
    let channelIdArray = this.dashboardChannels.map(e=>e?._id)
   this.httpTransfer.getChatUserQuery({'dashboard_id' : [this.selectedDashId],'channel_id':channelIdArray}).subscribe(res=>{
   this.chatUsersArr= res.result?.chatUsers || []
   let filterDataObj = this.commonUtils.cleanFilterDataObject(this.filterDataObj)
   let saveFilterPayload = this.commonUtils.getFilterJsonForQuery({},filterDataObj)
   this.getChannelMessages(saveFilterPayload,false)
   })
  }

  getPopOverRef(popRef){
    this.currentPopoverRef = popRef;
    this.basicUtils.isPopoverOpen(popRef)
  }

  updateLeadModalView() {
    let leadId = this.selectedLeadObj._id
    this.httpTransfer.getLeadQuery({lead_id:[leadId]}, this.selectedDashId)
    .subscribe((res : any) => {
      if (res.status == 200) {
        var eachLeadObj = {};
        eachLeadObj = this.commonUtils.creatingJsonStructureOfLeadForTableView(res.result.leadResponse[0]);
        this.selectedLeadObj = eachLeadObj;
        let obj={}
        obj["selectedLeadObj"] = eachLeadObj;
        this.msgservice.setExternalChatData(obj);
      }
    });
  }

  getChannelMessages(saveFilterPayload?,pagination?){
    var inputJson = {
     dashboard_id: [this.selectedDashId],
     type : ["MESSAGE_GROUP"],
     communication:true,
      grouping_details : {
      "group_by": [
      "channel_id",
       "chat_user_id",
       "smtp_thread_id"
      ], 
      "max_group_size" : 1,
     //  "start_index" : 0
       }, 
    }
     inputJson["pagination_details"] = {
      "page_size" : this.pageSizeForComments,
      "start_index": this.commentArr?.length
    } 
    if(this.isChatExpand){
      inputJson['lead_id'] = [this.leadInfo?._id]
    }
    if(saveFilterPayload){
      saveFilterPayload['dashboard_id'] = this.selectedDashId;
      delete saveFilterPayload?.grouping_details
      saveFilterPayload['group_type'] = this.selectedchatGroupType
      inputJson['item_filter']= saveFilterPayload 
     }
      this.httpTransfer
      .getCommentCorrespondingLead(inputJson)
      .subscribe((res : any) => {
        if(res.status==200){
          this.commentArr= !this.commentArr?.length ? res.result["commentsInfo"] : [...this.commentArr,...(res.result["commentsInfo"].filter(e=>!this.commentArr.some(entry=> entry._id === e._id)))]
          if(this.channelIds?.length) {
          const filteredChannels = this.commentArr.filter(channel => this.channelIds.includes(channel._id));
          this.commentArr= filteredChannels;
          }
          this.commentTotal_Page = res?.result?.pagination_details?.total_pages;
          this.showLoader = false;
          if(this.commentArr?.length){
            this.commentArr = this.commentArr.filter(l=>l?.type != 'INTERNAL')
            this.getLeadForChat();
            this.selectedChannelObj = this.commentArr[0]
            if(this.selectedChannelObj.type !== "EMAIL"){
              let selectedChannel = this.dashboardChannels.filter(i=>i._id == this.commentArr[0]?.channel_id)
              let selectedchatUser = this.chatUsersArr.filter(m=>m._id==this.commentArr[0]?.chat_user_id)
              this.selectedChannelObj['channelType'] = selectedChannel[0]?.type
              this.selectedChannelObj['channelName'] = selectedChannel[0]?.name
              this.selectedChannelObj['chatUserName'] = selectedchatUser[0]?.name
              this.selectedChannelObj['profile_pic'] = selectedchatUser[0]?.profile_pic
              this.getselectedChatMsg(pagination)
            }
            this.commentArr.forEach(async e=>{
               if(e.type != "EMAIL"){
                  let channel = this.dashboardChannels.filter(i=>i._id == e?.channel_id)
                  let chatUser = this.chatUsersArr.filter(m=>m._id==e?.chat_user_id)
                  if(!chatUser?.length){
                    let res = await this.httpTransfer.getChatUserQuery({
                      'dashboard_id': [this.selectedDashId],
                      'channel_id': [e?.channel_id]
                    }).toPromise();
                    chatUser = res?.result?.chatUsers.filter(m=>m._id==e?.chat_user_id)
                  }
                  e['channelType'] = channel[0]?.type
                  e['channelName'] = channel[0]?.name
                  e['chatUserName'] = chatUser[0]?.name
                  e['profile_pic'] = chatUser[0]?.profile_pic
                  e?.status_details?.received_time ? 
                  e['receivedTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(e?.status_details?.received_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(e?.status_details?.received_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(e?.status_details?.received_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(e?.status_details?.received_time)['time'] 
                  : e['sentTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(e?.status_details?.sent_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(e?.status_details?.sent_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(e?.status_details?.sent_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(e?.status_details?.sent_time)['time'] 
               }
              })
          }
        }
        this.cdr?.detectChanges()
      })
  }

  getCommentsOnScroll(){
     let saveFilterPayload;
  if(this.filterDataObj){
  let filterDataObj = this.commonUtils.cleanFilterDataObject(this.filterDataObj)
   saveFilterPayload = this.commonUtils.getFilterJsonForQuery({},filterDataObj)
  }
  this.commentCurrentPage = this.commentCurrentPage + 1;
    if (this.commentCurrentPage <= this.commentTotal_Page) {
      this.getChannelMessages(saveFilterPayload);
    } 
  }

    async getLeadForChat(item?){
   //  if((item && item.type == "EMAIL") || this.selectedChannelObj.type == "EMAIL") return;
   if(!item){
    // let leadIdArray = this.commentArr.map(e=>e?.lead_id)
    let leadIdArray = Array.from(new Set(this.commentArr.flatMap(e => e?.lead_id).flat()));

    var param={"lead_id" : leadIdArray,"is_deleted" : [true,false]}
    let res : any=await this.httpTransfer.getLeadQuery(param).toPromise()
    this.chatUserLeadArr = res.result?.leadResponse || []
    this.mapDataForEmail();
   }
   let selectedLead = this.chatUserLeadArr.filter(m=>m._id == this.selectedChannelObj?.lead_id)
   this.selectedLeadObj = selectedLead[0]
   let obj={}
   obj["selectedLeadObj"] = selectedLead[0];
   this.msgservice.setExternalChatData(obj);
   this.cdr.detectChanges()
      setTimeout(()=>{
        this.getUpdateReadUnreadCount(this.selectedChannelObj)
        this.cdr.detectChanges()
   },100) 
  }

  mapDataForEmail(){
    this.commentArr.forEach(entry=>{
      if(entry.type == "EMAIL"){
        let leadInfo = this.chatUserLeadArr.filter(f=>entry.lead_id==f._id)[0]
        if(leadInfo) {
          this.emailLeadInfoMap.set(entry.lead_id, leadInfo);
        }
      }
    })
  }

   filteChatMessages(){
    this.getselectedChatMsg(false)
  }


  getSelectedChatUserDetails(comment){
    //this.httpTransfer.readCommentApi({}).subscribe(res={})
    setTimeout(()=>{
        if(this.showChatWindow){
          this.showChatWindow=false;
          this.searchText=null;
          this.selectedChannelObj = comment;
          this.getLeadForChat(comment);
          this.getselectedChatMsg(false)
        }
    },100)
    let obj ={};
    obj["comment"]=comment
    this.msgservice.setExternalChatData(obj)
  }
  
 async getselectedChatMsg(pagination=true,scrollRestore?){
    if(!pagination){
      this.chatMsgcurrentPage = 1;
      this.pageSizeForChatMsg = 20;
      this.chatMsgTotal_Page = 0;
      this.selectedChatUserMsgarr = []
    }
    if(this.selectedChannelObj.type == "EMAIL") return;
    var inputJson = {
     // 'dashboard_id': [this.selectedDashId],
      'chat_user_id': [this.selectedChannelObj?.chat_user_id],
       'channel_id': [this.selectedChannelObj?.channel_id],
     // 'lead_id': [this.selectedChannelObj?.lead_id],
      'type':["EXTERNAL_OUTBOUND","EXTERNAL_INBOUND"],
      "pagination_details" : {
      "page_number" : this.chatMsgcurrentPage,
      "page_size" : this.pageSizeForChatMsg
     },
    }
    if(this.searchText?.length){
      inputJson['search_params']= {
        "search_text" : this.searchText,
        "search_columns" : ["text","caption"]
      }
    }
    let res : any=await  this.httpTransfer.getCommentCorrespondingLead(inputJson).toPromise()
      if(res.status==200){
        if(!pagination){
          this.chatMsgcurrentPage = 1;
          this.pageSizeForChatMsg = 20;
          this.chatMsgTotal_Page = 0;
          this.selectedChatUserMsgarr = []
        }
        this.selectedChatUserMsgarr = this.selectedChatUserMsgarr?.length ? [...res.result?.commentsInfo.reverse(),...this.selectedChatUserMsgarr] : res.result?.commentsInfo.reverse();
        let obj= {};
        obj["selectedChatUserMsgarr"] = this.selectedChatUserMsgarr
        this.msgservice.setExternalChatData(obj)
        this.chatMsgTotal_Page = res?.result?.pagination_details?.total_pages;
         this.chatMsgFormat(scrollRestore)
      }
      this.cdr.detectChanges()
  }

 chatMsgFormat(scrollRestore?){
  const promises = this.selectedChatUserMsgarr.map(async (m) => {
    let chatUser = this.chatUsersArr.filter(x => x._id == m?.chat_user_id);
    if (!chatUser.length) {
      let res = await this.httpTransfer.getChatUserQuery({
        'dashboard_id': [this.selectedDashId],
        'channel_id': [m?.channel_id]
      }).toPromise();
      this.cdr.detectChanges()
      chatUser = res.result?.chatUsers?.filter(z => z._id == m?.chat_user_id);
      m['chatUserName'] = chatUser[0]?.name;
      m['profile_pic'] = chatUser[0]?.profile_pic;
    }
    m['chatUserName'] = chatUser[0]?.name;
    m['profile_pic'] = chatUser[0]?.profile_pic;
      m?.status_details?.received_time ? 
      m['receivedTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.received_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.received_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.received_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.received_time)['time'] 
      : m['sentTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.sent_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.sent_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.sent_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.sent_time)['time'] 
      if(m?.status_details?.delivered_time) {
        m['deliverTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.delivered_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.delivered_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.delivered_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.delivered_time)['time'] 
      }
      if(m?.status_details?.read_time) {
        m['readTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.read_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.read_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.read_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.read_time)['time'] 
      }
      if(m?.status_details?.deleted_time) {
        m['deletedTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.deleted_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.deleted_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.deleted_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.deleted_time)['time'] 
      }
      if(m.reply_comment_id){
        let reply = this.selectedChatUserMsgarr.filter(n=>n._id == m.reply_comment_id)
        m['replyMsgObj'] = reply?.length ? reply[0] : {}
        m['rplyUserName'] = reply?.length ? reply[0] : {}
        let chatRplyUser = this.chatUsersArr.filter(m=>m._id== reply[0]?.chat_user_id)
        m['rplyUserName'] = chatRplyUser[0]?.name
        m['rplyUserProfilePic'] = chatRplyUser[0]?.profile_pic
      }
    })
    Promise.all(promises).then(() => {
      this.showChatWindow = true;
      if(scrollRestore){
        this.restoreScrollPosition = scrollRestore
       }
    });
  }
  
  
  getChatMsgOnScroll(scrollRestore){
   this.chatMsgcurrentPage = this.chatMsgcurrentPage + 1;
    if (this.chatMsgcurrentPage <= this.chatMsgTotal_Page) {
      this.getselectedChatMsg(true,scrollRestore);
    } 
  }


  profilePreview(modal,item) {
    if(item?.profile_pic){
      this.chatUserProfileUrl = item?.profile_pic
      let modalRef = this.modalServices.open(modal, { size: 'lg' , windowClass: 'file preview-modal'})
      this.basicUtils.storeModlRef(modalRef)
    }
  }

  ngOnDestroy() {
    this.filterSub?.unsubscribe();
    this.mqttSubscription?.unsubscribe();
    this.preApprovedTemplateForChannelSub?.unsubscribe();
  }

  getUserMailConnections(){
    const data = {
      user_id: [localStorage.getItem('id')]
    }
    this.httpTransfer.getEmailConnections({}).subscribe((res : any) => {
      if(res !== undefined && res) { 
        this.userEmailConnections = res
      }
    })
  }

  async updateFromEmailComponent(emailThreads){
    console.log(emailThreads.selectedEmailThreadViewDisplay, this.selectedChannelObj)
    if(!this.selectedChannelObj) this.emailThreadData = emailThreads

    else setTimeout(()=>{if(this.selectedChannelObj?.type == "EMAIL" && this.selectedChannelObj?.email_object?.smtp_thread_id == emailThreads.selectedEmailThreadViewDisplay[0][0]?.email_object?.smtp_thread_id){
          this.emailThreadData = emailThreads
        }
      }, 50)
  }

  groupTypeFilter(){
    let filterDataObj = this.commonUtils.cleanFilterDataObject(this.filterDataObj)
     let saveFilterPayload = this.commonUtils.getFilterJsonForQuery({},filterDataObj)
     this.commentCurrentPage = 1
     this.commentArr = []
     this.showLoader = true;
     this.selectedChannelObj = {}
     this.selectedChatUserMsgarr = []
     this.getChannelMessages(saveFilterPayload,false)
  }
  
  }

  
