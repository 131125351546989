import { Injectable } from "@angular/core";

declare var toastr: any;

@Injectable({
  providedIn: "root",
})
export class ToasterService {
  constructor() {}

  Success(title: string, message?: string) {
    toastr.remove();
    toastr.success(title, message);
  }

  Errors(title: string, message?: string) {
    toastr.remove();
    toastr.error(title, message);
  }

  removetoast() {
    toastr.remove();
  }
}
