import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-right-popup',
  templateUrl: './right-popup.component.html',
  styleUrls: ['./right-popup.component.scss']
})
export class RightPopupComponent {
  @Input() title: string;
  @Output() close = new EventEmitter<void>();

  closePopup(): void {
    this.close.emit();
  }
}
