import { ChangeDetectionStrategy, ChangeDetectorRef,Component, EventEmitter,HostListener,Input, OnInit, Output, ViewChild,SimpleChanges, ElementRef} from '@angular/core';
import { KeyboardMonitorService } from 'app/services/keyboardMonitor.service';
import { MqttmessagingService } from 'app/services/mqttmessaging.service';
import { AttributesIcons, ItemIcons } from 'Enums/attributes-icons';
import { ConstantService } from '../../../../Enums/Constant.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

@Component({
  selector: 'item-full-popup',
  templateUrl: './item-full-popup.component.html',
  styleUrls: ['./item-full-popup.component.scss']
})
export class ItemFullPopupComponent implements OnInit {
  @Output() minimizeRight = new EventEmitter<any>();
  @Input() showMinimizedIcon:boolean=false;
  @Input() leadId: string;
  @Input() dashId: string;
  @Input() tableInfo:any;
  @Input() hideClosebtn:boolean=false
  @Input() isTemplateCall:boolean=false;
  @Input()publicLead:boolean=false
  @Input() tabIndex: number = 1;
  @Input() itemConfig:any={};
  @Input() leadObj:any;
  @Input() selectedEmailObj: any= undefined;
  @Input() itemStateData:any=[]
  @Input() currentItemViewInfo:any={
    infoWidth: 60,
    widthUnit:'%'
  }
  @Output() emitCloseLeadModal = new EventEmitter<any>()
  @Output() emitIsThreadView = new EventEmitter<any>();
  @Output() onitemViewTypeChange = new EventEmitter<any>();
  @Output() onItemChange = new EventEmitter<any>();
  @Output() onOtherItemOpen = new EventEmitter<any>();
  @Output() emitValueForDepend = new EventEmitter<any>();
  @ViewChild('itemHeader') itemHeader: any;
  @ViewChild('itemInfoViewport') itemInfoViewport:ElementRef
  isReadOnly:boolean=false
  isThreadView:any=false;
  currentActiveTab = 'activityTab';
  tagsCorrespondingToDash: any[] = [];
  bucketArray: any[] = [];
  dasUsers: any[] = [];
  dashBoardSprintArr: any=[];
  dashboardUserRole: any=[];
  roles: { ADMIN: string; SALES: string; ACCESS: string; SUPER_ADMIN: string; DASHBOARDADMIN: string; READONLY: string; };
  dashboardJsonSub: any;
  publicLeadCustomFieldData: any;
  whatsappEnable: boolean = false;
  emailEnable: boolean = false;
  dashboardInfo: {};
  showHiddenFields:any={};
  chatCommentArr: any[]=[];
  isRecordingInprogress: any={status:false};
  isChatExpand:boolean=false
  enableCommunictaion: boolean = false;
  dataState:any=[]
  itemHierarachySeqArray: any=[];
  widthInfo:any={
    minWidthInPercentage:20,
    maxWidthInPercentage:90
  } 
  initialData: any={};
  activityTabOptions:any=[{key:"all",name:"All",type:"all"},{key:"comment",name:"Comment",type:"comment"},{key:"chat",name:"Chat",type:"chat"}]
  currentActiveActivityTab:any='all'
  itemIcons = ItemIcons;
  showSubtaskFilter:boolean=false;
  connectedBoardIds:any[]=[];
  connectedBoardValuekey:string='';
  subtaskTimer=1500;
  connectedBoardIdsList:any
  filterPopupInfo:any={}
  mqttSubscription: any;
  appsDetails: any={};
  connectedExternalApps: any[]=[];
  selectedApp:any={};
  nonEmbeddedListArray:any[]=[];
  attributesIcons = AttributesIcons;
  selectedBoardList:any=null;
  dashBoardCustArr:any=null;
  createNewDependency:boolean=false;
  countOfLeadsHere:number=0
  constructor(private httpTransfer: HttpTransferService,
    private commonUtils: CommonUtils,
    private constantService: ConstantService,
    private dashboardUtils: DashboardUtilsService,
    private mqttmessagingService: MqttmessagingService,
    private cdr: ChangeDetectorRef,
    private keyboardMonitor:KeyboardMonitorService
  ) {
    this.mqttSubscription = this.mqttmessagingService.getMqttMessage().subscribe(message => {
      if (message) {
          this.updatesFromWss(message)
      }
    });
    this.roles = this.constantService.getRole();
  }

  async ngOnInit() { 
    if(!this.tableInfo){
      this.itemConfig['showLoader']=true
      if(this.currentItemViewInfo?.viewType=='RIGHT'){
        this.currentActiveTab='detailsTab';
      }
      this.dashboardJsonSub = await this.dashboardUtils.getUpdatedDashboardJson().subscribe(async(res : any) => {
        this.tagsCorrespondingToDash = res[this.dashId]?.TAG_MAP || [];
        this.bucketArray = res[this.dashId]?.BUCKET || [];
        this.dasUsers = res[this.dashId]?.DASHBOARD_USER || [];
        this.dashBoardSprintArr = res[this.dashId]?.SPRINT || [];
        this.dashBoardCustArr = res[this.dashId]?.CUSTOM_FORM || [];
        this.connectedExternalApps = res[this.dashId]?.DASHBOARD_INFO?.externalapp_item_view || []
        console.log(this.connectedExternalApps)
        this.cdr?.detectChanges()
      });
      this.dashboardInfo=await this.dashboardUtils.getAndSyncAllDashboardData([this.dashId],true);
      if(this.leadId && !this.leadObj)this.leadQueryCorrespondingToLeadId();
      else delete this.itemConfig['showLoader']
      if(!this.publicLead){
        this.dashboardUserRole = this.dashboardInfo[this.dashId]?.DASHBOARD_INFO?.role || [];  
      }
  
      // open subtask parent filter
      if(this.itemStateData.length>0 && this.itemStateData[this.itemStateData?.length-1]?.subtaskParentObj?.type){
        this.openRightPopup(this.itemStateData[this.itemStateData?.length-1]?.subtaskParentObj)
        delete this.itemStateData[this.itemStateData?.length-1]?.subtaskParentObj
      }
      if(this.itemStateData.length>0 && this.itemStateData[this.itemStateData?.length-1]?.mentionView){
        this.currentActiveTab = 'activityTab'
      }
    }
    this.appsDetails = await this.dashboardUtils.fetchAppDetails(this.dashId) || {}
    console.log(this.appsDetails)
    this.cdr?.detectChanges()
  }

  updatesFromWss(messagees) {
    let message = JSON.parse(messagees);
    let itemObj=message?.additional_attributes?.details;
    if(this.tableInfo){
      if (message[this.constantService.getActivityTypeKey()] == this.constantService.getUpdateActivityType() && message[this.constantService.getObjectTypeKey()] == this.constantService.getLeadObjectType() && itemObj?._id == this.tableInfo?.item?._id) {
        if(this.tableInfo?.fieldInfo?.fieldType=='Board'){
          this.tableInfo.boardConnectedItemIds=itemObj?.custom_fields ? (itemObj?.custom_fields[this.tableInfo?.connectedKey] || []) : []
        }else if(this.tableInfo?.fieldInfo?.fieldType=='SubTask'){
          this.tableInfo.boardConnectedItemIds=itemObj?.child_lead_id || []
        }
      }
    }
  }

  updateTheCount(event){
    this.countOfLeadsHere=0
    event?.forEach(ele=>{
      this.countOfLeadsHere=this.countOfLeadsHere+ele['connecteBoardIds']?.length
    })
  }

  leadQueryCorrespondingToLeadId(emailclose?,event?) {
    var leadObjQuery = {};
    if(this.leadId)leadObjQuery["lead_id"]=[this.leadId]
    if (this.isTemplateCall)leadObjQuery["item_type"] = ["TEMPLATE"]
    leadObjQuery["is_deleted"] = [false,true]
    
    this.httpTransfer
      .getLeadQuery(leadObjQuery, this.dashId)
      .subscribe(async (res : any) => {
        if (res.status === 200) {
          this.leadObj = this.commonUtils.creatingJsonStructureOfLeadForTableView(res.result.leadResponse[0]);
          if(!this.dashId){
            this.dashId = this.leadObj?.dashboard_id
            this.dashboardInfo=await this.dashboardUtils.getAndSyncAllDashboardData([this.dashId],true);
            if(!this.publicLead){
              this.dashboardUserRole = this.dashboardInfo[this.dashId]?.DASHBOARD_INFO?.role || [];  
            }
          }
          if(this.itemStateData?.length>0)this.itemStateData[this.itemStateData.length-1].seqId=this.leadObj?.seq_id
          if(res.result.custom_fields_data){
            this.publicLeadCustomFieldData = res.result.custom_fields_data || []
          }
          if(emailclose){this.selectedEmailObj=event}
          this.itemHeader?.refreshHeader(this.leadObj)
          delete this.itemConfig['showLoader']
          this.cdr.detectChanges();
          this.nonEmbeddedView( this.dashBoardCustArr)
        }
      });
  }

  setSelectedEmail(event){
    if(!event){this.leadQueryCorrespondingToLeadId(true,event)}
    else{ this.selectedEmailObj=event }
  }

  ngOnDestroy() {
    this.dashboardJsonSub?.unsubscribe();
    this.mqttSubscription?.unsubscribe();
  }

  // new code


  updateItemState(item,showBoardHierarchy=false){
        let itemInfo={itemId:item?._id,boardId:item?.dashboard_id,seqId:item?.seq_id,tableInfo:item?.tableInfo,showBoardHierarchy:showBoardHierarchy}
    if(this.itemConfig?.itemOutsideOpen){
      this.onOtherItemOpen.emit(itemInfo)
    }else{
      this.itemStateData.push(itemInfo)
    }
  }

  // start : resize code 

  startResize(event: MouseEvent): void {
    event.stopPropagation()
    this.initialData.resizing=true
    this.initialData['initialX'] = event.clientX;  
    document.body.style.userSelect = 'none'; // Prevent text selection during resizing
  }

  @HostListener('document:mousemove', ['$event'])
  onResize(event: MouseEvent): void {
    if(this.initialData?.resizing){
      const widthDiff = event.clientX - this.initialData['initialX'];
      let containerWidth=this.itemInfoViewport.nativeElement.offsetWidth
      let currentWidthInPx=(this.currentItemViewInfo.infoWidth/100)*containerWidth
      let newWidth=currentWidthInPx + widthDiff
      let newWithInPercentage=(newWidth/this.itemInfoViewport.nativeElement.offsetWidth)*100
      if(newWithInPercentage>=this.widthInfo['minWidthInPercentage'] && newWithInPercentage<=this.widthInfo['maxWidthInPercentage']){
        this.initialData['initialX']= event.clientX;
        this.currentItemViewInfo.infoWidth=newWithInPercentage
      }
    }
  }

  @HostListener('document:mouseup')
  stopResize() {
    if(this.initialData.resizing){
      this.initialData.resizing = false;
      this.onitemViewTypeChange?.emit(this.currentItemViewInfo);
      document.body.style.userSelect = 'auto'; // Restore text selection
    }
  }
  
  // end resize
  
  checkForConnectedId(event){
    this.connectedBoardIds=event
  }

  updateItemField(item){
    this.connectedBoardValuekey=item.key
  }

  updateConnectedIds(item){
    this.connectedBoardIdsList=item
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['connectedBoardIds']){
     this.connectedBoardIds = changes['connectedBoardIds']?.currentValue ? changes['connectedBoardIds']?.currentValue :[]
     }
   }
   openRightPopup(data?){
    this.filterPopupInfo={
      callType:data.type,
      connectedBoardkey:data?.item?.key,
      item:data?.item,
      connectedBoardId:[data?.connectedBoardId],
      connectedItemsIds:data?.connectedItemsIds,
      activityObj:data?.activityObj
    }
    if(this.showSubtaskFilter){
      this.showSubtaskFilter = false;
      setTimeout(() => {this.showSubtaskFilter= true;}, 5);
    }else{
      this.showSubtaskFilter = true;
    } 
  }

  scrollTO(id) {
    if(this.currentItemViewInfo?.viewType=='RIGHT')this.currentActiveTab='detailsTab';
    setTimeout(() => {
      document.getElementById(id).scrollIntoView({behavior: "smooth",block: "start",inline: "nearest"});
    },5);
  }

  changeItemType(item){
    let inputJson = {item_type:(item?.item_type==='ITEM_INTERNAL' ? "ITEM_PUBLIC" : "ITEM_INTERNAL")};
    item['item_type']=inputJson['item_type'];
    this.httpTransfer.updateLeadCorrespondingToLeadId(inputJson, this.dashId, this.leadId).subscribe((res) => {});
  }

  updateTableItem(type,connectedItemIds,leadData,insertIdIndex?){
    if(!connectedItemIds)connectedItemIds=[]
    let json={}
    if(type=='Remove'){
      let index =connectedItemIds.findIndex(el => el === leadData._id);
      if(index >=0)connectedItemIds.splice(index,1);
      json={ parent_id:''}
    }else if(type=='Update')
      connectedItemIds=leadData
    else if(type=='Add'){
      if(insertIdIndex>-1 && insertIdIndex<=connectedItemIds?.length){
        connectedItemIds.splice(insertIdIndex,0,leadData._id)
      } 
      else if(!connectedItemIds?.includes(leadData._id)){
        connectedItemIds.push(leadData._id);
      }
      json={ parent_id: this.tableInfo?.item?._id }
    }
    if(!connectedItemIds?.length)connectedItemIds=null
    if(['Board','MultipleBoard'].includes(this.tableInfo?.fieldInfo?.fieldType)){
      this.httpTransfer.updateLeadCorrespondingToLeadId({custom_fields:{[this.tableInfo?.connectedKey]:connectedItemIds}},this.tableInfo?.item?.dashboard_id, this.tableInfo?.item?._id).subscribe((res:any)=>{
        if (res?.status === 200) {}
      })
    }else{
      this.httpTransfer.updateLeadCorrespondingToLeadId(json,this.tableInfo?.item?.dashboard_id,leadData._id).subscribe((res : any) => {
        if (res.status === 200) {}
      })
    }
  }

  selectedAppInstance(event){
    console.log(event)
    this.httpTransfer.updateDashBoardCorrespondingToOrganisation(event, null, this.dashId).subscribe((res : any) => {
      if (res !== undefined && res) { 
       if(res.status==200){
        this.connectedExternalApps.push(event?.app_item_view_details?.data)
       }
      }
    })
  }

  changeAppFeatur(apps){
    this.currentActiveTab='';
    this.selectedApp={};
    setTimeout(()=>{
      this.currentActiveTab = 'appsTab';
      this.selectedApp = apps
    },10)
  }

  nonEmbeddedView(array){
    this.countOfLeadsHere=0
    array.forEach(ele=>{
      if(['Board','MultipleBoard'].includes(ele?.type) && !ele?.embedded_view){
        ele['connecteBoardIds']=this.leadObj?.custom_fields[ele?.key]?this.leadObj?.custom_fields[ele?.key]:[]
        this.nonEmbeddedListArray.push(ele)
        this.countOfLeadsHere=this.countOfLeadsHere+ele['connecteBoardIds']?.length
      }
    })
      }

  isBoardSelected(key){
    if(this.selectedBoardList!=null && this.selectedBoardList.key===key){return true}
    else{return false}
   }

   dependencyCall(event){
    if(['Blocking','Waiting on'].includes(this.filterPopupInfo?.callType)){
      let obj={
        ids:event?.ids,
        type:event?.callType=='Blocking'?'dependents':'depends_on'
      }
     this.addDependsOnItem(obj)
    }
   }

   addDependsOnItem(leadData) {
    let dependsOnItemsIdArr=[];
    let dependentsItemIdArr=[]
    if(this.leadObj?.dependencies?.depends_on) {
      this.leadObj?.dependencies?.depends_on ? dependsOnItemsIdArr = [...this.leadObj?.dependencies?.depends_on] : dependsOnItemsIdArr;
    }
    if(this.leadObj?.dependencies?.dependents) {
      this.leadObj?.dependencies?.dependents ? dependentsItemIdArr = [...this.leadObj?.dependencies?.dependents] : dependentsItemIdArr;
    }
    let dependenciesObj = { dependencies: {} };
    if(!Array.isArray(dependsOnItemsIdArr))dependsOnItemsIdArr = [];
    if(!Array.isArray(dependentsItemIdArr))dependentsItemIdArr = [];
    if(leadData?.type==='dependents'){leadData?.ids?.forEach(ele=>{
      dependentsItemIdArr?.push(ele)
    })}
    if(leadData?.type==='depends_on'){leadData?.ids?.forEach(ele=>{
      dependsOnItemsIdArr?.push(ele)
    })}
    if(dependsOnItemsIdArr) dependenciesObj["dependencies"]["depends_on"] = [...dependsOnItemsIdArr];
    if(dependentsItemIdArr) dependenciesObj["dependencies"]["dependents"] = [...dependentsItemIdArr];
      this.httpTransfer.updateLeadCorrespondingToLeadId(dependenciesObj, this.dashId, this.leadObj._id).subscribe((res : any) => {
        if (res.status == 200) {
        }
      },
      (err)=>{
        console.log("Errors")
      });
  }

}

