import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { Subject } from 'rxjs';
import { ConstantService } from '../../../../Enums/Constant.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { debounceTime } from "rxjs/operators";
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';


@Component({
  selector: 'app-search-items',
  templateUrl: './search-items.component.html',
  styleUrls: ['./search-items.component.scss']
})
export class SearchItemsComponent implements OnInit {
  @Input() dashboardId: string;
  @Input() searchText = '';
  @Input() currentItemId: string;
  @Input() itemsIdToAvoid = [];
  @Input() isBlocking : boolean;
  @Input() isSubTaskSearchOpen : boolean;
  searchLeadList: any[] = [];
  subject: Subject<any> = new Subject();
  inputType = this.constantService.getInputType();
  bucketOfCorrespondingDash: any;
  noResponse: boolean;
  users: any[];
  searchInput = '';
  @Output() clickedItem = new EventEmitter<any>();
  @Output() onGetSearch = new EventEmitter<any>();
  orgUsersJson: any;
  lock = false;
  searchInitial:boolean=true

  constructor(private httpTransfer: HttpTransferService,
    private constantService: ConstantService,
    private cdr:ChangeDetectorRef,
    private commonUtils: CommonUtils,
    private dashboardUtils: DashboardUtilsService) {

   }

   ngOnChanges(changes:SimpleChange) {
    if(changes['searchText']) {
      if(this.searchText?.length>=2){
        this.subject.next(this.searchText);
        this.searchInitial=true;
      }
      if(this.searchText===''){
        this.subject.next(this.searchText);
        this.searchInitial=false;
      }
    }
  }

  async ngOnInit() {
    if(this.itemsIdToAvoid.length === 0) this.itemsIdToAvoid = []
    this.orgUsersJson = await this.dashboardUtils.getOrgUsers();
    this.getBucketsCorrespondingToDashId();
    this.getUsersCorrespondingToDashId();
    this.debounceApiCallWithDelay();
    this.cdr.detectChanges()
  }

  getUsersCorrespondingToDashId() {
    this.httpTransfer.getUsersCorrespondingToDashBoard(this.dashboardId).subscribe((res : any) => {
      if(res) {
        this.users = this.commonUtils.sortingUsersInAlphabeticalOrder(res?.result);
      }
    })

  }

  getBucketsCorrespondingToDashId() {
    this.httpTransfer
      .getBucketsCorrespondingToDahBoard(this.dashboardId)
      .subscribe((res : any) => {
        this.bucketOfCorrespondingDash = res.result?.buckets || [];
      });
  }

  debounceApiCallWithDelay() {
    this.subject.pipe(debounceTime(1000)).subscribe((text) => {
        this.searchItemList(text);
    });
  }


  searchItemList(searchText) { 
    let inputJson = {};
    this.searchLeadList = [];
    inputJson["search_params"] = {"search_text":searchText};
    if(this.isSubTaskSearchOpen){
      inputJson["possible_child_for_item"] = this.currentItemId
    }
    if(this.lock){
      setTimeout(this.searchItemList.bind(this,searchText), 100);
      return
    }
    this.lock = true
    this.httpTransfer.getLeadQuery(inputJson, this.dashboardId).subscribe((res : any) => {
      if (res.result.leadResponse.length == 0) {
        this.noResponse = true;
      } else {
        this.noResponse = false;
      }
      this.lock = false;
      if(this.searchText!='')
      this.searchInitial=false
      for (let i = 0; i < res.result.leadResponse.length; i++) {
        if(this.currentItemId != res.result.leadResponse[i]._id && !this.itemsIdToAvoid.includes(res.result.leadResponse[i]._id)) {
          let leadObj = this.commonUtils.creatingJsonStructureOfLeadForTableView(res.result.leadResponse[i]);
          this.searchLeadList.push(leadObj);
        }
      }
      this.onGetSearch.emit(null)
      this.cdr.detectChanges()
    }); 
  }

  returnClickedItem(item,index) {
    this.itemsIdToAvoid.push(item._id)
    this.searchLeadList.splice(index,1)
    this.clickedItem.emit(item)
    this.cdr.detectChanges()
  }

}
