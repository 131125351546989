import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production && !["https://staging.pronnel.com/pronnel/","https://dev1api.pronnel.com/","https://dev2api.pronnel.com/"].includes(environment.hostUrl)) {
  enableProdMode();
  window.console.log = () => { }
}

platformBrowserDynamic().bootstrapModule(AppModule, {
    preserveWhitespaces: true
})
