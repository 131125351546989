export const MediaIcons ={
    "pdf": "assets/1x/mediaIcons/pdf.svg",
    "doc": "assets/1x/mediaIcons/doc.svg",
    "msword": "assets/1x/mediaIcons/doc.svg",
    "excel": "assets/1x/mediaIcons/xls.svg",
    "docx": "assets/1x/mediaIcons/docx.svg",
    "document": "assets/1x/mediaIcons/docx.svg",
    "css": "assets/1x/mediaIcons/css.png",
    "psd": "assets/1x/mediaIcons/psd.svg",
    "zip": "assets/1x/mediaIcons/zip.svg",
    "odt": "assets/1x/mediaIcons/odt.svg",
    "csv": "assets/1x/mediaIcons/csv.svg",
    "xlsx": "assets/1x/mediaIcons/xlsx.svg",
    "ogg": "assets/1x/mediaIcons/ogg.svg",
    "mp4": "assets/1x/mediaIcons/mp4.svg",
    "wmv": "assets/1x/mediaIcons/wmv.svg",
    "webm": "assets/1x/mediaIcons/webm.svg",
    "avi": "assets/1x/mediaIcons/avi.svg",
    "mov": "assets/1x/mediaIcons/mov.svg",
    "ppt": "assets/1x/mediaIcons/ppt.svg",
    "ms-powerpoint": "assets/1x/mediaIcons/ppt.svg",
    "sheet": "assets/1x/mediaIcons/xlsx.svg",
    "rtf": "assets/1x/mediaIcons/rtf.svg",
    "ods": "assets/1x/mediaIcons/ods.svg",
    "xls": "assets/1x/mediaIcons/xls.svg",
    "ms-excel": "assets/1x/mediaIcons/xls.svg",
    "xml": "assets/1x/mediaIcons/xml.svg",
    "json": "assets/1x/mediaIcons/json.svg",
    "html": "assets/1x/mediaIcons/html.svg",
    "txt": "assets/1x/mediaIcons/file.svg",
    "mp3": "assets/1x/mediaIcons/file.svg",
    "img": "assets/1x/mediaIcons/img.svg",
    "audio": "assets/new-icon-6d6e6f-20dp/audio-6d6e6f.svg",
    "pptx": "assets/1x/mediaIcons/ppt.svg",
    "htm": "assets/1x/mediaIcons/html.svg",
    "js": "assets/1x/mediaIcons/jason.svg",
    "jpg": "assets/1x/mediaIcons/img.svg",
    "jpeg": "assets/1x/mediaIcons/img.svg",
    "svg": "assets/1x/mediaIcons/img.svg",
    "png": "assets/1x/mediaIcons/img.svg",
    "gif": "assets/1x/mediaIcons/file.svg",
    "webp": "assets/1x/mediaIcons/img.svg",
    "ai": "assets/1x/mediaIcons/file.svg",
    'url':"assets/1x/mediaIcons/url.svg",
    "audios": "assets/1x/mediaIcons/audio-6d6e6f.svg",
}      