import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
// Import Containers
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { RegisterComponent } from "./views/register/register.component";
import { AuthGuardService } from "./auth-guard.service";

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./views/change-password/change-password.module').then(m => m.ChangePasswordModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'oauth/:id',
    loadChildren: () => import('./oAuth/oAuth.module').then(m => m.OAuthModule),
    canActivate: [AuthGuardService],
  },
  {
    path: '404',
    component: P404Component,
  },
  {
    path: '500',
    component: P500Component,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'org/settings',
    loadChildren: () =>
      import('./views/organization-settings/organization-settings.module').then(m => m.OrganizationSettingsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./components/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuardService],
  },
   {
    path: '',
    loadChildren: () =>
      import('./components/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuardService],
  }, 
  {
    path: 'boards',
    loadChildren: () => import('./internal-dashboard/internal-dashboard.module').then(m => m.InternalDashboardModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'user',
    loadChildren: () => import('./user-mentions/user-mentions.module').then(m => m.UserMentionsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'notes',
    loadChildren: () =>
      import('./views/personal-notes/personal-notes.module').then(m => m.PersonalNotesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'organisation',
    loadChildren: () =>
      import('./views/organisation/organization.module').then(m => m.OrganizationModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile',
    loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'items',
    loadChildren: () => import('./all-dashboards-view/all-dashboards-view.module').then(m => m.AllDashboardsViewModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'org/user',
    loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule),
    canActivate:[AuthGuardService]
  },
  {
    path: 'org/group',
    loadChildren: () => import('./components/grouplist/grouplist.module').then(m => m.GrouplistModule),
    canActivate:[AuthGuardService]
  },
  {
    path: 'form/:id',
    loadChildren: () => import('./form/form.module').then(m => m.FormModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'mobile/board/:boardId/view/:viewId/formula/:formulaId',
    loadChildren: () => import('./data-table-link/data-table-link.module').then(m => m.DataTableLinkModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'mobile/note/:id',
    loadChildren: () => import('./note-link/note-link.module').then(m => m.NoteLinkModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'note/:id',
    loadChildren: () => import('./note-link/note-link.module').then(m => m.NoteLinkModule),
    canActivate: [AuthGuardService]
  },
  {
    path:'shareditem/:id',
    loadChildren: () => import('./views/editLead/editLead.module').then(m => m.EditLeadModule),
    canActivate:[AuthGuardService]
  },
  { 
    path: "boardgraph/:id", 
    loadChildren: () => import("./dashboard-graph/dashboard-graph.module").then((m) => m.DashboardGraphModule),
    canActivate:[AuthGuardService] 
  },
  {
    path: 'board',
    loadChildren: () => import('./containers/dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule),
    canActivate:[AuthGuardService]
  },
  {
    path: 'board/:id',
    loadChildren: () => import('./containers/dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule),
    canActivate:[AuthGuardService]
  },
  { 
    path: "board/:id/:leadId", 
    loadChildren: () => import('./views/editLead/editLead.module').then(m => m.EditLeadModule),
    canActivate:[AuthGuardService]
  },
  {
    path: 'embedboard/:id',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuardService],
  },
  { path: '**', component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
