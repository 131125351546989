<div class="connect-email-icons mt-2">
    <div (click)="connectEmail('GOOGLE')" class="cursorPointer">
      <img class="gmailIcon" src="../../../assets/1x/gmail-icon.svg" [ngStyle]="{'width': imageSize}">
      <div>Gmail</div>
    </div>
    <div (click)="connectEmail('MICROSOFT')" class="cursorPointer">
      <img class="outlookIcon" src="../../../assets/1x/outlook-icon.svg" [ngStyle]="{'width': imageSize}">
      <div>Outlook</div>
    </div>
    <!-- <div (click)="connectEmail('ZOHOMAIL')" class="cursorPointer">
      <img class="outlookIcon pt-3 pb-2" src="../../../assets/1x/zohomaillogo.svg" [ngStyle]="{'width': imageSize}">
      <div>Zohomail</div>
    </div> -->
    <div (click)="openImapSmtpModal(createConnectionContent)" class="cursorPointer" *ngIf="!isCalendar">
      <img class="outlookIcon" src="../../../assets/1x/icons8-send-email-64.png" [ngStyle]="{'width': imageSize}">
      <div>IMAP & SMTP</div>
    </div>
  </div>

  
  <ng-template #createConnectionContent let-modal>
    <div class="modal-header border-bottom">
      <h4 class="modal-title" id="modal-basic-title">Create SMTP/IMAP Connection</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <app-imap-smtp-connection (closeModalEvent)="closeModal();createConnectionRef?.dismiss()" (saveConnectionEvent)="closeModal();createConnectionRef?.dismiss()" [createConnectionRef]="createConnectionRef"></app-imap-smtp-connection>
  </ng-template>