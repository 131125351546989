import { keyframes } from '@angular/animations';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomStorageService {
  SESSION_STORAGE_KEYS = ['organisationid','token','dashboard_live_update_key_expiry_time','USERGROUP_DATA','DASHBOARD_DATA','RedirectionLink','USER_SUCCESSFULLY_VERIFIED','viewSpecificForUser','loaderIndex']

  constructor() { }

  setItem(key: string, value: string) {
    if(this.SESSION_STORAGE_KEYS.includes(key)){
       sessionStorage.setItem(key,value);
       if(localStorage.getItem(key) === null && key != 'token' || key=='organisationid') {
        localStorage.setItem(key, value);
       }
    } else {
      localStorage.setItem(key, value);
    }
    
  }


  getItem(item: string) {
    this.handleLogoutAndLogin();
    if(this.SESSION_STORAGE_KEYS.includes(item)){
      return sessionStorage?.getItem(item) ? sessionStorage.getItem(item) : localStorage.getItem(item);
    }
    return localStorage.getItem(item);
  }

  clear(){
    localStorage.clear();
    sessionStorage.clear();
  }

  removeItem(key: string) {
    if(this.SESSION_STORAGE_KEYS.includes(key)){
      sessionStorage.removeItem(key);
   }
   localStorage.removeItem(key);
  }

  //Handle case of different user login in different tabs. Same user should be visible in all tabs. 
  //If from one tab user is logged out then it should logout from all tabs
  handleLogoutAndLogin(){
    //clearSessionStorageTime is not present (Generally when new tab is opened), but clearLocalStorageTime is present
    // In this case just set clearSessionStorageTime
    if((!sessionStorage.getItem("clearSessionStorageTime") && localStorage.getItem("clearLocalStorageTime"))){
      sessionStorage.setItem("clearSessionStorageTime", ""+new Date().getTime());
    }
    //Other tab was logged out. In that case clearLocalStorageTime won't be present but 
    //clearSessionStorageTime would be present. So in this case clear the sessionStorage and reload it will 
    //reach to login window
    if((sessionStorage.getItem("clearSessionStorageTime") && !localStorage.getItem("clearLocalStorageTime"))){
      sessionStorage.clear();
      window.location.reload();
    }
    //Session storage was cleared before local storage, so referesh the window. 
    //Basically in one of the tabs other user is logged out, so any other window should refresh automatically
    if(
      (sessionStorage.getItem("clearSessionStorageTime") && localStorage.getItem("clearLocalStorageTime") &&
        +(sessionStorage.getItem("clearSessionStorageTime")) < +(localStorage.getItem("clearLocalStorageTime")))){
        sessionStorage.clear();
        sessionStorage.setItem("clearSessionStorageTime", ""+new Date().getTime());
        window.location.reload();
    }
  }
}
