
              <!-- dependency  -->
              <div *ngIf="!isPublicLead && !dependencyPermission?.isPresent"class="row g-0 editleadcustominputsrow dependency-field" id="{{'dependency'+currentLead?._id}}">
                  <div class="d-flex mx-0 w-100" style="column-gap: 52px;">
                    <label class="fieldLabelBox f-l-r mb-0">
                      <app-dashboard-icon [size]="14"  [name]="'Dependency'" class="labelimg"></app-dashboard-icon>
                      <span class="cardTag add-truncate-property-predefined" appOverflowCheck [ngbTooltip]>
                        <span class="tagText" [ngbTooltip]="dependencyPermission?.display_name?.length>15 ? dependencyPermission?.display_name : ''" placement="auto" container="body"  tooltipClass="tooltipPopup">{{dependencyPermission?.display_name}}</span>
                      </span>
                    </label>
                      <span *ngIf="!isReadOnly" class="addCreateConnectBtn key-tab" style="border: 1px solid;" aria-hidden="true"placement="auto" #popChartOptions="ngbPopover" popoverClass="modifiedPopover"
                      [ngbPopover]="dependencyOption" container="body" [outsideClick]="true" tabindex="0" role="button" (click)="addDependency ? addDependency=false : addDependency=true">
                        <span class="iconBox"><img src="../../../../../assets/1x/add-icon-3571de-10px.svg"></span>
                        <spn>{{'editcustom.addDependency' | translate}}</spn>
                      </span>
                      <ng-template #dependencyOption>
                        <div role="button" style="padding:5px;width: 270px;height: 135px;display: grid;align-items: end;">
                          <div class="f-l-r-r pb-1 option-for-dependency dependency-dependent-options-dropdown" (click)="showSubtaskParentModal?.emit('Blocking')"><span>Blocking</span></div>
                          <div class="f-l-r-r pb-1 option-for-dependency dependency-dependent-options-dropdown" (click)="showSubtaskParentModal?.emit('Waiting on')"><span>Waiting on</span></div>
                          <div class="f-l-r-r pb-1 option-for-dependency dependency-dependent-options-dropdown" (click)="createNewDependency?.emit(true)"><span>Create new</span></div>
                        </div>
                      </ng-template>
                  </div>
                  <div *ngIf="currentLead?.dependencies" class="row g-0 mx-0 fieldValueBox w-100">
                    <ng-container *ngIf="currentLead?.dependencies?.depends_on?.length>0">
                      <div style="padding-top: 5px;">
                        <div>
                          <div style="display: flex;justify-content: space-between;">
                            <div>
                              <i role="button" *ngIf="!dependencyEnable" class="fas fa-chevron-down p-0" style="color: gray;" (click)="dependencyEnable=true"></i>
                              <i role="button" *ngIf="dependencyEnable" class="fas fa-chevron-up p-0" style="color: gray;" (click)="dependencyEnable=false"></i><span class="f-s-m" style="color: red;padding-left: 5px;line-height: 16px;">Waiting on</span>
                            </div>
                            <div role="button" (click)="showSubtaskParentModal?.emit('Waiting on')">
                              <i [ngClass]="{'color:gray':isFinalBucket}" class="fa fa-search" style="color:black;"></i>
                            </div>
                          </div>
                        </div>
                          <ng-container *ngIf="!dependencyEnable">
                            <app-item-list-grid [tabIndex]="tabIndex" [dashId]="dashId" [showBoardName]="false" [showHiddenFields]="showHiddenFields" [isCurrentBoardReadonly]="isReadOnly" [currentLead]="currentLead" [subtaskFilter]="true" [customFieldsOfMainBoard]="arrayOfCustomFiled" [boardConnectedItemIds]="currentLead?.dependencies?.depends_on" [fieldInfo]="{fieldName:'Waiting on',fieldType:'SubTask'}"
                              (itemRemove)="removeDependency($event._id, true)" (itemCreate)="addItem($event?.item,true);" (onItemOpen)="onItemOpen?.emit($event)"(onExpendTable)="onItemOpen?.emit($event)"class="w-100"></app-item-list-grid>
                              <!-- <app-item-list  [tabIndex]="tabIndex" class="w-100" [itemIdList]="currentLead?.dependencies?.depends_on" [dashId]="dashId" [isBlocking]="false" [showBoardName]="false" (itemRemove)="removeDependency($event._id, true)" [currentLead]="currentLead" [showRemoveIcon]="!isReadOnly" (onItemOpen)="onItemOpen?.emit($event)"></app-item-list> -->
                          </ng-container>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="currentLead?.dependencies?.dependents?.length>0">
                      <div style="padding-top: 5px;">
                        <div>
                          <div style="display: flex;justify-content: space-between;">
                            <div>
                              <i role="button" (click)="dependentEnable=true" *ngIf="!dependentEnable" class="fas fa-chevron-down p-0" style="color: gray;"></i>
                              <i role="button" (click)="dependentEnable=false" *ngIf="dependentEnable" class="fas fa-chevron-up p-0" style="color: gray;"></i><span class="f-s-m" style="color: red;padding-left: 5px;line-height: 16px;">Blocking</span>
                            </div>
                            <div role="button" (click)="showSubtaskParentModal?.emit('Blocking')">
                              <i [ngClass]="{'color:gray':isFinalBucket}" class="fa fa-search" style="color:black;"></i>
                            </div>
                          </div>
                          </div>
                          <ng-container *ngIf="!dependentEnable">
                            <app-item-list-grid [tabIndex]="tabIndex" [dashId]="dashId" [showBoardName]="false" [showHiddenFields]="showHiddenFields" [isCurrentBoardReadonly]="isReadOnly" [currentLead]="currentLead"[subtaskFilter]="true" [customFieldsOfMainBoard]="arrayOfCustomFiled" [boardConnectedItemIds]="currentLead?.dependencies?.dependents" [fieldInfo]="{fieldName:'Blocking',fieldType:'SubTask'}"
                              (itemRemove)="removeDependency($event._id, false)" (itemCreate)="addItem($event?.item,false);" (onItemOpen)="onItemOpen?.emit($event)"(onExpendTable)="onItemOpen?.emit($event)"class="w-100"></app-item-list-grid>
                              <!-- <app-item-list  [tabIndex]="tabIndex" class="w-100" [itemIdList]="currentLead?.dependencies?.dependents" [dashId]="dashId" [isBlocking]="true" [showBoardName]="false" (itemRemove)="removeDependency($event._id, false)" [currentLead]="currentLead" [showRemoveIcon]="!isReadOnly" (onItemOpen)="onItemOpen?.emit($event)"></app-item-list> -->
                          </ng-container>
                      </div>
                    </ng-container>
                  </div> 
                  <div *ngIf="!currentLead && (dependenciesObj?.dependencies?.depends_on?.length>0 || dependenciesObj?.dependencies?.dependents?.length>0)" class="row mx-0 fieldValueBox">
                    <app-item-list class="w-100" [itemIdList]="dependenciesObj?.dependencies?.depends_on" [tabIndex]="tabIndex" [dashId]="dashId" [isBlocking]="false" [showBoardName]="false" (itemRemove)="removeDependency($event._id, true)" [showRemoveIcon]="!isReadOnly" (onItemOpen)="onItemOpen?.emit($event)"></app-item-list>
                    <app-item-list class="w-100" [itemIdList]="dependenciesObj?.dependencies?.dependents" [tabIndex]="tabIndex" [dashId]="dashId" [isBlocking]="true" [showBoardName]="false" (itemRemove)="removeDependency($event._id, false)" [showRemoveIcon]="!isReadOnly" (onItemOpen)="onItemOpen?.emit($event)"></app-item-list>
                  </div>
              </div>
              <!-- sub task  -->
              <div *ngIf="currentLead && !subtaskPermission?.isPresent" class="row g-0 editleadcustominputsrow" id="{{'sub-task'+currentLead?._id}}">
                <div class="d-flex mx-0 w-100" >
                  <label class="fieldLabelBox f-l-r m-0" style="width: 25%;">
                    <app-dashboard-icon [size]="14" [name]="'SubTask'" class="labelimg"></app-dashboard-icon>
                    <span class="cardTag add-truncate-property-predefined" appOverflowCheck [ngbTooltip]>
                      <span class="tagText" [ngbTooltip]="subtaskPermission?.display_name?.length>15 ? subtaskPermission?.display_name : ''" placement="auto" container="body"  tooltipClass="tooltipPopup">{{subtaskPermission?.display_name}}</span>
                    </span>
                  </label>
                  <div class="d-flex align-items-center flex-grow-1" [ngClass]="{'beforeSearch':!addSubTask,'afterSearch':addSubTask}" style="width: 75%;">
                    <div *ngIf="!isReadOnly" style="width: 100%;">
                      <div style="display: flex;justify-content: end;" [ngbTooltip]="isFinalBucket ? 'For final bucket you cannot add subitem':''" tooltipClass="tooltipPopup" triggers="hover" placement="auto">
                        <button [disabled]="isFinalBucket" *ngIf="!dashAccess?.no_dashboard_user && !dashAccess?.is_raedOnly && !isPublicLead" class="addCreateConnectBtn btn py-0 key-tab" tabindex="0" (click)="addSubTask ? addSubTask=false : addSubTask=true; setquickSubTask=false; scrollTO('sub-task'+currentLead['_id']); searchLeadList=[];showSubtaskParentModal?.emit('Add Sub Item')">
                          <i [ngClass]="{'color:gray':isFinalBucket}" class="fa fa-search" style="color:black;"></i>
                        </button>
                        <button [disabled]="isFinalBucket"  class="addCreateConnectBtn btn py-0 key-tab" tabindex="0" *ngIf="!dashAccess?.no_dashboard_user && !dashAccess?.is_raedOnly && !isPublicLead" (click)="createSubTask(addcustomlead); addSubTask=false; searchLeadList=[]">
                          <span class="iconBox"><img src="../../../../assets/1x/add-icon-3571de-10px.svg"></span>
                          <span>{{'common.create' | translate}}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="setquickSubTask && createPermissioninBucket" class="subTaskCreationBox" (keydown.enter)="createSubTaskLead()">
                  <textarea [(ngModel)]="creationtext" (keydown.enter)="false" (blur)="createSubTaskLead()" (keydown.tab)="$event.preventDefault()" [ngModelOptions]="{standalone: true}" autosize class="w-100 emptytextarea" [minRows]="3" id="subtaskTextInput">
                  </textarea>
                </div>
                <div class="col-md-12 px-0">
                  <app-item-list-grid                 
                  [tabIndex]="tabIndex" 
                  [dashId]="dashId" 
                  [showBoardName]="false" 
                  [showHiddenFields]="showHiddenFields"
                  [isCurrentBoardReadonly]="isReadOnly"
                  [currentLead]="currentLead"
                  [subtaskFilter]="true"
                  [customFieldsOfMainBoard]="arrayOfCustomFiled"
                  [boardConnectedItemIds]="currentLead?.child_lead_id"
                  [fieldInfo]="{fieldName:('editcustom.subTask' | translate),fieldType:'SubTask'}"
                  (itemRemove)="removeSubitem($event._id)" 
                  (itemCreate)="setItemToBoard($event,$event?.item,$event?.index)" 
                  (onItemOpen)="onItemOpen?.emit($event)"
                  (onExpendTable)="onItemOpen?.emit($event)"
                  class="w-100"
                  ></app-item-list-grid>
                </div>
              </div>
              
              <!-- Duplicate items  -->
              <div *ngIf="!isPublicLead && currentLead && (currentLead?.duplicate_details?.is_marked_duplicate || duplicatedByItemsIds.length>0)" class="row editleadcustominputsrow" id="sub-task">
                <div class="row mx-0 w-100" style="justify-content: space-between;">
                  <label class="fieldLabelBox f-l-r m-0">
                    <app-dashboard-icon [size]="14"  [name]="'duplicate'" class="labelimg"></app-dashboard-icon>
                    <span> {{'editcustom.duplicateItem' | translate}}</span>
                  </label>
                </div>
                <div class="row mx-0 fieldValueBox">
                  <app-item-list *ngIf="currentLead?.duplicate_details?.is_marked_duplicate" [tabIndex]="tabIndex" [itemIdList]="[currentLead?.duplicate_details?.duplicate_item_id]" [isDuplicateOf]="true" [dashId]="dashId" [showBoardName]="false" [showRemoveIcon]="false" (onItemOpen)="onItemOpen?.emit($event)"></app-item-list>
                  <app-item-list *ngIf="duplicatedByItemsIds.length>0" [itemIdList]="duplicatedByItemsIds" [tabIndex]="tabIndex" [isDuplicateOf]="false" [dashId]="dashId" [showBoardName]="false" [showRemoveIcon]="false" (onItemOpen)="onItemOpen?.emit($event)"></app-item-list>
                </div>
              </div>
            <ng-template #addcustomlead let-modal>
                  <app-create-lead-modal [dashId]="dashId" [subTaskCreation]="true" [leadIdForSubTask]="currentLead._id" (createLeadModalCloseEvent)="modal.dismiss();" style="height: 100%;">
                  </app-create-lead-modal>
            </ng-template>
