import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild } from "@angular/core";
import { AttributesIcons } from "Enums/attributes-icons";
import { MessageService } from "app/message.service";

@Component({
  selector: 'multi-level-dropdown',
  templateUrl: './multi-level-dropdown.component.html',
  styleUrls: ['./multi-level-dropdown.component.scss']
})
export class MultilevelDropdownComponent implements OnInit {
    @Input() buttonLabel :string = "Apps";
    @Input() appConnectionJson:any;
    @Input() viewType: string="APP_BOARD_VIEW";
    @Output() selectedAppInstance : EventEmitter<any> = new EventEmitter();
    isDropdownOpen:boolean = false;
    @Input() isDropLeft: boolean=false;
    appFeatureArray: any[];
    selectedFeature: any={}
    attributesIcons = AttributesIcons;

    
    constructor(private renderer :Renderer2){ }

    ngOnInit(): void {
        console.log(this.appConnectionJson)
        this.appFeatureArray = this.extractFeatures();
        console.log(this.appFeatureArray);
        this.isDropdownOpen = false;
    }

    extractFeatures(){
        let featuresArray: any[] = [];
        for (const key in this.appConnectionJson) {
          if (this.appConnectionJson[key].features) {
            featuresArray.push(...this.appConnectionJson[key].features);
          }
        }
        return featuresArray;
      };
    
  
    toggleDropdown(event?) {
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  
    toggleSubmenu(item: any,event,index) {
        item.isOpen = !item.isOpen;
        console.log(this.appFeatureArray)
        console.log(this.appConnectionJson)
      if(index>=0){
        this.selectedFeature = item
        event?.stopPropagation();
        event?.preventDefault();
        console.log(item)
      }
      else if(index<0){
        console.log(event)
        this.createView(item,event)
      }
    }

    createView(item,event){
        let param = {}
        if(this.viewType == 'APP_BOARD_VIEW'){
                param["view"]= {
                    "name": this.selectedFeature?.name,
                    "view_type": this.viewType,
                    "externalapp_details":{
                        "externalapp_id": item?.externalapp_id,
                        "externalapp_feature_id":this.selectedFeature?._id,
                        "externalapp_instance_id":item?._id,
                        "view_url": this.selectedFeature?.build_url
                    }
                }
        }
        else if(['ITEM_VIEW','ITEM_OPTION'].includes(this.viewType)){
            param["app_item_view_details"]= {
                "data": {
                "name": this.selectedFeature?.name,
                "externalapp_id":item?.externalapp_id,
                "externalapp_feature_id":this.selectedFeature?._id,
                "externalapp_instance_id":item?._id,
                "view_url": this.selectedFeature?.build_url,
                "view_type": this.viewType,
                },
                "update_type": "APPEND"
                }
        }
          else if(this.viewType == 'BOARD'){
            param={
                "name": this.selectedFeature?.name,
                "externalapp_details":{
                        "externalapp_id":item?.externalapp_id,
                        "externalapp_feature_id":this.selectedFeature?._id,
                        "externalapp_instance_id":item?._id,
                        "view_url": this.selectedFeature?.build_url
                    },
            }
            }
            else if(this.viewType=="WIDGET"){
              param["type"]= "APP_WIDGET",
              param["name"]= this.selectedFeature?.name,
              param["graph_type"]= "APP_WIDGET",
              param["externalapp_details"]= {
                "externalapp_id":item?.externalapp_id,
                "externalapp_feature_id":this.selectedFeature?._id,
                "externalapp_instance_id":item?._id,
                "view_url": this.selectedFeature?.build_url
           }
            }

          this.isDropdownOpen = false;
          this.closeAllSubmenus(this.appFeatureArray)
          this.selectedAppInstance?.emit(param)
    }

    closeAllSubmenus(appFeatureArray,isMain=true) {
      appFeatureArray.forEach(item => {
        delete item?.isOpen;
      });
      if(isMain){
        Object.keys(this.appConnectionJson).forEach(key=>{
          this.closeAllSubmenus(this.appConnectionJson[key].connection,false)
       })
      }
    }


    
}