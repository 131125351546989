import { ChangeDetectorRef, Compiler, Component, ElementRef, EventEmitter, Injector, Input, IterableDiffers, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'; 
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { Subscription } from 'rxjs';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ConstantService } from 'Enums/Constant.service';
import { BasicUtils } from 'app/services/basicUtils.service';

@Component({
  selector: 'app-item-list-grid',
  templateUrl: './item-list-grid.component.html',
  styleUrls: ['./item-list-grid.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemListGridComponent implements OnInit {

  itemIdList = [];
  @Input()  boardConnectedItemIds:any = [];
  @Input() dashId: string;
  @Input() tabIndex;
  @Input() isCurrentBoardReadonly:boolean=false;
  @Input() currentLead: any;
  @Input() isPublicLead: boolean = false
  @Input() customFieldsOfMainBoard: any[] = [];
  @Input() connectedKey:string = null
  @Input() fieldInfo:any = {}
  @Input() showHiddenFields: boolean = false;
  @Input () subtaskFilter:boolean = false;
  @Input () showingColumnKeys:any = null;
  @Input() isItemGridExpendView =false
  dashboardUserRole: any[] = []
   
  @Output() itemRemove = new EventEmitter<any>();
  @Output() itemCreate = new EventEmitter<any>();
  @Output() itemUpdate = new EventEmitter<any>();
  @Output() onItemOpen = new EventEmitter<any>();
  @Output() onExpendTable=new EventEmitter<any>();
  @Output() gridLoadedCheck=new EventEmitter<any>();

  @ViewChild("connectedBoard", { read: ViewContainerRef }) connectedBoard!: ViewContainerRef;
  @ViewChild('leadModalTemplateSecond') leadModalTemplateSecond: ElementRef;
  itemModalRef2: NgbModalRef;
  gridReady: boolean = false
  dashboardInfo: any = {}
  customFields: any[] = []
  tagsCorrespondingToDash: any[] = [];
  tagsCorrespondingToDashMap: any = {};
  bucketArray: any[] = [];
  users: any = [];
  dashBoardSprint = [];
  mirrorColumnsOfMainBoard: any = {}
  columnsRequired: any[] = []
  itemRemoveSubscription:Subscription;
  itemCreateSubscription:Subscription;
  itemUpdateSubscription:Subscription;
  itemGridReadySubscription:Subscription;
  mqttSubscription:Subscription; 
  dashboardJsonSub:Subscription;
  itemOpenSubscription:Subscription;
  gridLoadSubscription:Subscription;
  filterDataObj: any = {
    sort_params: [{sort_by: 'lead_id', order: 'DSC'},],
    view_type: 'GRID',
    grouping_details: {
      group_by: 'none',
      max_group_size:  null,
      start_index: 0,
      sort_order: 'ASC'
    }
  }
  iterableDiffer: any;
  gridViewRef:any;
  defaultColumnForSubTask:any[]=[];
  gridLoaded:boolean=false;
  currentBoardInfo:any={}
  requireColumn:any=[]

  constructor( 
    private dashboardUtils: DashboardUtilsService, 
    private compiler: Compiler, private injector: Injector, 
    private httpTransfer: HttpTransferService, 
    private iterableDiffers: IterableDiffers,
    private basicUtils:BasicUtils,
    private ngbModalService:NgbModal,
    private cdr: ChangeDetectorRef,
  ) { 
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  async handleLeadInsertOrRemove(newItemIdList){
      this.itemIdList = this.itemIdList?.filter(id => id)
      let data= this.calculateEditDistanceWithIndices(this.itemIdList,newItemIdList)
      let addedIndices=data?.addedIndices || []
      let removedIndices=data?.removedIndices || []
      this.filterDataObj['lead_id']=newItemIdList.filter(id => id)
      let addedLeadIds=[]
      for( let index of removedIndices){
        this.gridViewRef?.removeItem({rowId:this.itemIdList[index]?._id,_id:null})
      }
      for( let index of addedIndices){
        addedLeadIds.push(newItemIdList[index])
      }
      if(addedLeadIds?.length>0){
        let res=await this.httpTransfer?.getLeadQuery({dashboard_id: [this.dashId], lead_id:addedLeadIds})?.toPromise()
        res?.result?.leadResponse?.forEach(lead=>{
            let data=JSON.stringify({additional_attributes:{details:lead,dashboard_id:this.dashId},activity_type:ConstantService.constant.ADDACTIVITYTYPE,object_type:ConstantService.constant.LEADOBJECTTYPE})
            this.gridViewRef?.subscribedMessagePushToQueue(data)
        })
      }
      this.itemIdList=newItemIdList
      this.cdr?.detectChanges()
  }

  calculateEditDistanceWithIndices(array1, array2) {
    const m = array1?.length || 0;
    const n = array2?.length || 0;
  
    // Create a 2D matrix to store the edit distances
    const dp = [];
    for (let i = 0; i <= m; i++) {
      dp[i] = [];
      for (let j = 0; j <= n; j++) {
        dp[i][j] = 0;
      }
    }
  
    // Fill in the matrix with edit distances
    for (let i = 1; i <= m; i++) {
      dp[i][0] = i;
    }
  
    for (let j = 1; j <= n; j++) {
      dp[0][j] = j;
    }
  
    for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        if (array1[i - 1] === array2[j - 1]) {
          dp[i][j] = dp[i - 1][j - 1];
        } else {
          dp[i][j] = Math.min(dp[i - 1][j], dp[i][j - 1]) + 1;
        }
      }
    }
  
    // Find added and removed elements
    const addedIndices = [];
    const removedIndices = [];
  
    let i = m;
    let j = n;
    while (i > 0 || j > 0) {
      if (i > 0 && dp[i][j] === dp[i - 1][j] + 1) {
        removedIndices.push(i - 1);
        i--;
      } else if (j > 0 && dp[i][j] === dp[i][j - 1] + 1) {
        addedIndices.push(j - 1);
        j--;
      } else {
        i--;
        j--;
      }
    }
  
    addedIndices.reverse();
    removedIndices.reverse();
  
    return { editDistance: dp[m][n], addedIndices, removedIndices };
  }

  async ngOnInit() {
        if(this.currentLead?._id && this.subtaskFilter || !this.currentLead?._id){
      this.itemIdList=[...this.boardConnectedItemIds]
    }
    else if(this.currentLead?._id){
      this.itemIdList = this.currentLead?.custom_fields[this.connectedKey]
      this.itemIdList = [...new Set(this.itemIdList)] 
    }
    let dashboadJson=await this.dashboardUtils.getAndSyncAllDashboardData([this.dashId],true)   
    this.dashboardJsonSub= this.dashboardUtils.getUpdatedDashboardJson().subscribe((res : any) => {
      this.dashboardUserRole = res[this.dashId]?.DASHBOARD_INFO?.role || [];
      res = JSON.parse(JSON.stringify(res ||  {}))
      this.currentBoardInfo=res[this.dashId]?.DASHBOARD_INFO || {}
      this.tagsCorrespondingToDash = res[this.dashId]?.TAG || [];
      this.tagsCorrespondingToDashMap=res[this.dashId]?.TAG_MAP || {};
      this.users = res[this.dashId]?.DASHBOARD_USER || [];
      this.dashBoardSprint =res[this.dashId]?.SPRINT || []
      this.customFields = res[this.dashId]?.CUSTOM_FORM || [];
      this.bucketArray = res[this.dashId]?.BUCKET || []      
    }); 
    this.getColumnsRequired()
    await this.importGrid()
    this.cdr?.detectChanges()
  }

  ngDoCheck() {
    if (this.boardConnectedItemIds) {
      let changes = this.iterableDiffer?.diff(this.boardConnectedItemIds);
      if (changes) {
        if(JSON.stringify(this.boardConnectedItemIds)!=JSON.stringify(this.itemIdList)){
          this.handleLeadInsertOrRemove([...this.boardConnectedItemIds]) 
        }
      }
    }
  }

 async getColumnsRequired(){
    if(!this.subtaskFilter){
      this.requireColumn=[]
      this.mirrorColumnsOfMainBoard =  this.customFieldsOfMainBoard?.reduce((columnKeysData,field) =>{if(field?.type == 'Mirror' &&  field.connected_board_id == this.dashId && field.connected_board_key == this.connectedKey && (field?.hidden!=='ALWAYS' || this.showHiddenFields)){columnKeysData[field.connected_board_column_key]={display_key:field?.display_key}}return columnKeysData},{})
      if(this.mirrorColumnsOfMainBoard['start_date'] || this.mirrorColumnsOfMainBoard['end_date']){
        this.mirrorColumnsOfMainBoard["start_end_date"]={}
        delete this.mirrorColumnsOfMainBoard['start_date']
        delete this.mirrorColumnsOfMainBoard['end_date']
      }
      if(this.mirrorColumnsOfMainBoard['update_date']){
        this.mirrorColumnsOfMainBoard['last_updated']= this.mirrorColumnsOfMainBoard['update_date']
        delete this.mirrorColumnsOfMainBoard['update_date']
      }
      this.handleColumnHide(false);
   
    }
    else{
      this.requireColumn=['id','title']
      let viewsData={}
      let dash=this.dashId
      viewsData= await this.dashboardUtils.getDashboardInformation([dash])
      viewsData[this.dashId]?.EXISTING_CUSTOM_FORM?.forEach(value=>{
        if(!value?.hidden){
          let obj={columnKey:value.key,isHide : false,headerName:value?.display_key,isCustom:false}
          this.defaultColumnForSubTask.push(obj);
        }
      })
      let arrayField=[{itemName: "Last Updated", isHide: false, isCustom: false, columnKey:'last_updated'},
      { itemName: "Created By", isHide: false, isCustom: false, columnKey:'created_by'},
      { itemName: "Create Date", isHide: false, isCustom: false, columnKey:'create_date'},
      { itemName: "Phase", isHide: false, isCustom: false, columnKey:'sprint',key:'sprint_id'}]
      this.defaultColumnForSubTask=[...this.defaultColumnForSubTask,...arrayField]
      console.log("customfield array",this.customFields)
      let mirrorColumnsOfMainBoard=[]
      this.customFields.forEach(ele=>{
        if(!ele?.hidden){
          mirrorColumnsOfMainBoard.push(ele)
        }
      })
      mirrorColumnsOfMainBoard.forEach(ele=>{
           let obj={columnKey:ele.key,isHide : false,headerName:ele?.display_key,isCustom:true}
        this.defaultColumnForSubTask.push(obj);
      })
    }
    this.cdr?.detectChanges()
  }
  handleColumnHide(showAll){
    let filledColumnKeys=[]
    this.columnsRequired=this.showingColumnKeys ? ['title'] : []
    let columnSeuence=this.currentBoardInfo?.board_preference?.connected_board || {}
    columnSeuence[this.connectedKey]?.item_table_column_seq?.forEach(data=>{
      let columnKey=data.columnKey
      if(this.mirrorColumnsOfMainBoard[data.columnKey] && !filledColumnKeys.includes(columnKey)){
        filledColumnKeys.push(columnKey)
        let obj={columnKey:columnKey,isHide : !this.showingColumnKeys || showAll || columnKey=='title'? false : !this.showingColumnKeys?.includes(columnKey) ,headerName:this.mirrorColumnsOfMainBoard[columnKey]?.display_key,width:(this.isItemGridExpendView && data?.expendViewWidth ? data.expendViewWidth : data.width),widthInfo:{width:data.width,expendViewWidth:data.expendViewWidth}}
        this.columnsRequired.push(obj);
  
      }
    })
    Object.keys(this.mirrorColumnsOfMainBoard)?.forEach(columnKey=>{
      if(!filledColumnKeys.includes(columnKey)){
        let obj={columnKey:columnKey,isHide : !this.showingColumnKeys || showAll || columnKey=='title'? false : !this.showingColumnKeys?.includes(columnKey) ,headerName:this.mirrorColumnsOfMainBoard[columnKey]?.display_key}
        this.columnsRequired.push(obj);
        filledColumnKeys.push(columnKey)
      }
    })
    if(!filledColumnKeys.includes('id'))this.columnsRequired.splice(0,0,{columnKey:'id',isHide : !this.showingColumnKeys || showAll ? false : true})
    if(!filledColumnKeys.includes('title'))this.columnsRequired.splice(1,0,{columnKey:'title',isHide : false})

    this.gridViewRef?.onColumnSelectionChange(this.columnsRequired)
  }

  async importGrid(){
    if(this.gridLoaded)return;
    this.gridLoaded=true;
    if(this.itemIdList?.length==0){
      if(this.currentLead?._id && this.subtaskFilter || !this.currentLead?._id){
        this.itemIdList=[...this.boardConnectedItemIds]
      }
      else if(this.currentLead?._id){
        this.itemIdList = this.currentLead?.custom_fields[this.connectedKey]
        this.itemIdList = [...new Set(this.itemIdList)] 
      }
    }
    this.filterDataObj['lead_id']= this.itemIdList
    this.filterDataObj['dashboard_id']= [this.dashId]
    this.connectedBoard?.clear()
    const { GridViewModule } =  await import("../../../grid-view/grid-view.module");
    const moduleFactory = await this.compiler.compileModuleAsync(GridViewModule);
    const moduleRef = moduleFactory.create(this.injector);
    const componentFactory = moduleRef.instance.getComponent();
    const { instance } = this.connectedBoard?.createComponent(componentFactory);    
    instance.viewType="itemView";
    instance.isItemGridExpendView=this.isItemGridExpendView
    instance.itemGridReadOnly=this.isCurrentBoardReadonly
    instance.connectedBoardkey=this.connectedKey;
    instance.currentLead=this.currentLead
    instance.selectedDashId = this.dashId 
    instance.filterDataObj = this.filterDataObj
    instance.arrayOfCustomFiled = this.customFields
    instance.bucketArray = this.bucketArray
    instance.dashboardUserRole  =  this.dashboardUserRole 
    instance.tagsCorrespondingToDash = this.tagsCorrespondingToDash
    instance.tagsCorrespondingToDashMap = this.tagsCorrespondingToDashMap
    instance.dashboardUsers = this.users
    instance.isGroupBy = false
    instance.dashBoardSprint = this.dashBoardSprint
    instance.viewTableColumnSeq = this.subtaskFilter?this.defaultColumnForSubTask:this.columnsRequired
    instance.fixAndRequireColumns=this.requireColumn
    this.gridViewRef=instance
    this.itemRemoveSubscription = instance.leadRemoved.subscribe((item) =>{
      this.itemRemove.emit(item);
      this.cdr?.detectChanges()
    });
    this.itemCreateSubscription = instance.leadCreated.subscribe(data =>{
      this.itemCreate.emit({item:{_id:data?.leadId},index:data?.index});
      this.cdr?.detectChanges()
    })
    this.itemUpdateSubscription = instance.leadUpdated.subscribe(item =>{
      this.itemUpdate.emit(item);
      this.itemIdList=item
      this.cdr?.detectChanges()
    })
    this.itemGridReadySubscription = instance.emitGridReady.subscribe(item =>{
      this.gridReady = true
      this.cdr?.detectChanges()
    })
    this.itemOpenSubscription = instance.onItemOpen.subscribe(item =>{
      this.onItemOpen.emit(item)
      this.cdr?.detectChanges()
    })
    this.gridLoadSubscription = instance.isGridLoaded.subscribe(item =>{
      this.gridLoadedCheck.emit(item)
      this.cdr?.detectChanges()
    })
    this.gridLoaded=false;
  }
  removeItemFromBoard(event){
    this.itemRemove.emit(event);
  }
  setItemToBoard(event){
    this.itemCreate.emit(event);
  }
  updateItemToBoard(event){
    this.itemUpdate.emit(event);
  }


  expandGrid(modalRef){ 
    if(this.currentLead?._id){
      let dataJson={
        item:this.currentLead,
        boardId:this.dashId,
        showHiddenFields:this.showHiddenFields,
        subtaskFilter:this.subtaskFilter,
        isCurrentBoardReadonly:this.isCurrentBoardReadonly,
        connectedKey:this.connectedKey,
        boardConnectedItemIds:this.boardConnectedItemIds,
        customFieldsOfMainBoard:this.customFieldsOfMainBoard,
        fieldType:'Board',
        fieldInfo:this.fieldInfo
      }
      this.onExpendTable.emit({boardId:this.dashId,tableInfo:dataJson})
    }else{
          this.itemModalRef2 = this.ngbModalService.open(modalRef, { size: 'xl', windowClass: 'iteminItemModal' });
          this.basicUtils.storeModlRef(this.itemModalRef2)  
    } 
    this.cdr?.detectChanges() 
  }

  ngOnDestroy() {
    this.itemCreateSubscription?.unsubscribe()
    this.itemRemoveSubscription?.unsubscribe()
    this.itemUpdateSubscription?.unsubscribe()
    this.dashboardJsonSub?.unsubscribe()
    this.itemGridReadySubscription?.unsubscribe()
    this.mqttSubscription?.unsubscribe()
    this.itemOpenSubscription?.unsubscribe();
    this.gridLoadSubscription?.unsubscribe();
  } 

  async ngOnChanges(changes: SimpleChanges) {
    if(changes.showHiddenFields?.currentValue!=undefined && changes.showHiddenFields?.currentValue!=changes.showHiddenFields?.previousValue){
      this.getColumnsRequired()
      await this.importGrid()
    }
    
  }
}
