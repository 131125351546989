import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attributesLabel'
})
export class AttributesLabelPipe implements PipeTransform {

  transform(value: unknown,arg1: any, arg2: string, arg3): unknown {
    if(arg1 && Object.keys(arg1).length>0 && arg1[arg2]) {
        value = arg1[arg2].label;
        return value;
    } else {
      return arg3;
    }
  }

}
