
              <!-- sub task  -->
  <div *ngIf="currentLead" class="w-100 h-100" id="{{'sub-task'+currentLead?._id}}" keyboardMonitor>
    <ng-container *ngIf="filterOpen">
      <div *ngIf="filterOpen" class="d-flex mx-0 w-100" style="justify-content: space-between; border-bottom: 1px solid #e1e1e1;">
    <div class="d-flex">
      <div *ngIf="!isReadOnly" style="display: flex;justify-content: end;">
            <div class="filterHolder subtaskFilter">
              <app-filters 
                [selectedDashId]="connectedBoardIds.length? connectedBoardIds[0] : dashId"
                [showFilter]="true"
                [subtaskFilter]="true"
                [arrayOfCustomFiled]="arrayOfCustomFiled"
                [withoutTagsFilter]="true"
                [nonFinalBucketsFilter]="true"
                [localFilterDataObj]="localFilterDataObj"
                (closeFilterEmit)="filterOpen = !$event;closeFilter()"
                (resetFilterEmit)="searchInput='';resetFilter();filterOpen=false;filterPopoverHolder?.close()"
                (applyFilterEmit)="searchItemList($event);filterOpen=false;filterPopoverHolder?.close()"
              ></app-filters>
            </div>
      </div>
    </div>
  </div>
  </ng-container>
    <div class="headerPart">
      <div class="nameSearchFilter">
          <span *ngIf="!connectedBoardIds.length && ['Add Sub Item','Blocking','Waiting on'].includes(callType)" class="f-s-r" style="width: 25%;font-weight: 500;font-size: 20px !important;line-height: 20px;">{{callType}}</span>
          <span *ngIf="connectedBoardIds.length && callType===''" class="f-s-r" style="width: 25%;font-weight: 500;font-size: 20px !important;line-height: 20px;"></span>
          <span *ngIf="!connectedBoardIds.length && callType==='Add Parent'" class="f-s-r" style="width: 25%;font-weight: 500;font-size: 20px !important;line-height: 20px;">Add Parent</span>
          <span style="position: relative; display: inline-block; width: 60%;">
            <input class="addInput key-tab" id="dependsOnInput" #searchSubtask [readonly]="isReadOnly" type="text" placeholder="{{ 'placeholders.seachPlaceholderText' | translate }}"  [(ngModel)]="searchInput" (keyup.enter)="searchOnEnter($event)" style="padding-right: 30px;" tabindex="0">
            <i class="fa fa-search" style="position: absolute; top: 45%; right: 5px; transform: translateY(-50%);color: black;" (click)="searchOnEnter()"></i>
          </span>
        
          <span style="width: 7%;padding-left: 8px;" (click)="filterOpen=true" role="button" class="key-tab" tabindex="0">
            <img class="icon-library" src="assets/1x/Filter.svg" style="height: 14px; width: 14px;">
          </span>
          <span style="width: 5%;" class="key-tab" (click)="closeTheModal()" tabindex="0">
            <img role="button" src="../../../../assets/common-use-icon-20dp/close.svg" style="width: 20px;height: 20px;">
          </span>
      </div>
    </div>
    <div class="bodyPart">
      <div class="d-flex justify-content-center align-items-center" style="height: 36px;">
        <span>Showing {{numberOfLeadsLoaded}}/{{totalCountLead}} items, <a role="button" style="color: rgb(43, 202, 223);"(click)="getDataOnScroll()" class="key-tab" tabindex="0">click here</a> to load all.</span>
      </div>
      <div #viewComponent></div>
    </div>
    <div class="footerPart">
        <div class="addAndCloseBtn"> 
          <button *ngIf="!iSProcessing"  [ngClass]="{'disable-add-board-button':SingleBoardSelection && (excludeInMultiselectGroup?.length>1 || multiselectLeadIds?.length>1 || arrayOfLeads?.length>1)}"  type="button" [disabled]="SingleBoardSelection && (excludeInMultiselectGroup?.length>1 || multiselectLeadIds?.length>1 || arrayOfLeads?.length>1)" class="btn key-tab" tabindex="0" style="color: white;" (click)="callType==='Add Parent' ? addParentItem() :['Blocking','Waiting on'].includes(callType) ?dependency():addSubtask()">Add & Close</button>
          <button *ngIf="iSProcessing"  type="button" class="btn d-flex align-items-center key-tab" tabindex="0" style="color: white;" [disabled]="true">
            <div class="spinner-border mr-1" role="status" style="width: 15px;height: 15px"><span class="sr-only"></span></div>
            <span>{{'common.processing' | translate}}...</span>
          </button>
        </div>
    </div>
  </div>
