<div class="position-relative row mx-0 fieldValueBox checkListBox">
  <div class="mediaBox row mx-0 w-100">
    <!-- <label class="col-md-4 editleadcustominputscol my-auto"  style="display: flex; align-items:center; column-gap: 7px;"><app-dashboard-icon [size]="17"  [name]="item.type"></app-dashboard-icon>{{item.display_key}}<span ngIf="item.mandatory" style="color: red;"></span></label> -->
  <div  class="row mx-0 w-100 justify-content-end" [ngClass]="{'gridCall':(gridCall) , 'notGridCall' :(!gridCall)} " *ngIf="(!isReadOnly && !isPreview) && !checklistList?.length" >
    <span class="cursorPointer addCreateConnectBtn" (click)="openChecklist=true">
      <span class="iconBox"><img src="../../../../assets/1x/add-icon-3571de-10px.svg">
      </span> {{'editcustom.addCheckList' | translate}}
    </span>
  </div>           
  <div *ngIf="openChecklist" class="col-md-12 editleadcustominputscol checkListEditingArea" (blur)="addCheckListItem(item)">
    <!-- <div style="float:right; display: flex; column-gap: 10px;margin-bottom: 5px;" >
      <div *ngIf="item?.checkListText?.trim()?.length" class="checklistBtnsDone" (click)="$event.stopPropagation();addCheckListItem(item)">Done</div>
      <div *ngIf="item?.checkListText?.trim()?.length" class="checklistBtnsClear" (click)="$event.stopPropagation();item.checkListText='';">Clear</div>
      <div (click)="openChecklist=false" class="checklistBtnsClose ">{{'editcustom.close' | translate}}</div>      
    </div> -->
      <textarea style="width: 100%;" [(ngModel)]="item.checkListText" [readonly]="isReadOnly" autosize  [minRows]="1" class="pb-0 addInput"
      (keyup.enter)="addCheckListItem(item)"  type="text" placeholder="Type your text here.."></textarea>
      <span class="text-danger" *ngIf="item.checkListDuplicate">Duplicate not allowed</span>
  </div>
  <div *ngIf="checklistList?.length" class="d-flex checklistContainer flex-column w-100" >
    <ng-container *ngFor="let checklist of checklistList; let i=index;trackBy:trackByFn">
      <div [ngbPopover]="!isReadOnly && deleteChecklist"  #popoverChecklistDelete="ngbPopover"
      [autoClose]="'outside'" triggers="manual">
      <div *ngIf="item?.isEdit && i==item?.editIndex" class="row checkListEditingArea mx-0" (blur)="updateCheckListEdit(checklistList[i],i)">
        <!-- <div style="float: right;display: flex; column-gap: 10px;margin-bottom: 5px;justify-content: end; width: 100%;" >
          <div *ngIf="checklistList[i].summary?.trim()?.length" class="checklistBtnsDone" (click)="$event.stopPropagation();updateCheckListEdit(checklistList[i],i)">Done</div>
          <div *ngIf="checklistList[i].summary?.trim()?.length" class="checklistBtnsClear" (click)="$event.stopPropagation();checklistList[i].summary='';">Clear</div>
          <div (click)="item.isEdit=false" class="checklistBtnsClose">{{'editcustom.close' | translate}}</div>      
        </div> -->
        <textarea type="text" autosize class="pb-0 addInput" [minRows]="1" [placeholder]="checklist.summary" [(ngModel)]="checklistList[i].summary"  (keyup.enter)="updateCheckListEdit(checklistList[i],i)"></textarea>
        <span class="text-danger" *ngIf="checklist.checkListDuplicate">Duplicate not allowed</span>
      </div>
      <div class="checkList d-flex" *ngIf="!(item?.isEdit && i==item?.editIndex)">
        <div class="checkDetails">
          <div *ngIf="!(item?.isEdit && i==item?.editIndex)" (click)="!isReadOnly && updateState(i,item)" class="iconBox">
            <img *ngIf="checklist.state=='COMPLETED'" class="checkListIcon"
              src="assets/1x/pronnel_iconArtboard 29.png" alt="">
            <img *ngIf="checklist.state=='NOT_COMPLETED'" class="checkListIcon"
              src="assets/1x/pronnel_iconArtboard 28.png" alt="">
          </div>
          <div *ngIf="!(item?.isEdit && i==item?.editIndex) && !(view=='grid')" class="checkListText">{{ checklist.summary}}</div>
          <div *ngIf="!(item?.isEdit && i==item?.editIndex) && (view=='grid')" class="checkListText">{{ checklist.summary | truncate:35}}</div>
        </div>
        <!-- <div *ngIf="item?.isEdit && i==item?.editIndex" class="col-md-8"><textarea type="text" autosize class="pb-0 addInput" [minRows]="1" [placeholder]="checklist.summary" [(ngModel)]="checklist.summary" (blur)="updateCheckListEdit(item)" (keyup.enter)="updateCheckListEdit(item)"></textarea></div> -->

        <div *ngIf="!isReadOnly" class=" checklistOptions" [ngClass]="{'checklistOptionsSelected':popoverChecklistDelete?.isOpen() }"  >
          <span class="iconBox editIcon" *ngIf="!(item?.isEdit && i==item?.editIndex)" (click)="!isReadOnly && editCheckItem(i,item)">
            <img src="../../../../assets/common-use-icon-20dp/edit-6d6e6f.svg" class="imgDefault">
            <img src="../../../../assets/common-use-icon-20dp/edit-3571de.svg" class="imgHover">

          </span>
          <span class="iconBox deleteIcon" *ngIf="!(item?.isEdit && i==item?.editIndex)" (click)="popoverChecklistDelete.open();$event.stopPropagation()">
            <img src="../../../../assets/common-use-icon-20dp/delete-6d6e6f.svg" class="imgDefault">
            <img src="../../../../assets/common-use-icon-20dp/delete-FE2030.svg" class="imgHover">
            </span>
        </div>
                
      </div>
        <ng-template #deleteChecklist>
          <div class="modal-body text-center py-3">
            <h3>{{'common.confirmation' | translate}}</h3> 
            <img src="../../../assets/1x/info.png"><br><br>
            {{'common.deleteConfirmation' | translate}}<br><br>
            <button type="button" class="btn btn-danger rounded px-4 mr-3" (click)="!isReadOnly && deleteCheckItem(i,item);popoverChecklistDelete.close()">{{'common.confirm' | translate}}</button>
            <button type="button" class="btn rounded cancelbtn px-4" (click)="popoverChecklistDelete.close()">{{'common.cancel' | translate}}</button>
          </div>
        </ng-template>
    </div>
    </ng-container>
  </div>
  
  <div *ngIf="(!isReadOnly && !isPreview) && item?.checklistInput?.length"  class="row mx-0 w-100 justify-content-end" [ngClass]="{'gridCall':(gridCall) , 'notGridCall' :(!gridCall)} ">
    <span class="cursorPointer addCreateConnectBtn" (click)="openChecklist=true"><img src="../../../../assets/1x/add-icon-3571de-10px.svg">
       {{'editcustom.addCheckList' | translate}}
    </span>
  </div>
  </div>
</div>