<!-- when you will close modal onclick cancel and confirm button, close this div "deletedvcnr" 
close dropdown onclick on delete like Edit
Delete function already working -->

<div class="deletedvcnr">
<div class="deletepopup">
  <h3>{{title}}</h3> 
  <img src="assets/1x/info.png"><br><br>
  {{description}}<br><br>
  <button type="button" class="btn btn-danger rounded px-4 mr-3" (click)="onSelect(true)">{{'common.confirm' | translate}}</button>
  <button type="button" class="btn rounded cancelbtn px-4" (click)="onSelect(false)">{{'common.cancel' | translate}}</button>
</div>
</div>
