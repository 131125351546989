import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { MessageService } from "../message.service";
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: "root",
})
export class MessagingService {
  isCurrentTabACtive:boolean=false
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private msgService: MessageService
  ) {
    document.addEventListener("visibilitychange",()=> {
      if (document.visibilityState === 'visible') {
        this.isCurrentTabACtive=true
      } else {
        this.isCurrentTabACtive=false
      }
    });
  }

  requestPermission() {
    return this.angularFireMessaging.requestToken.pipe(
      map(
      (token) => {
        return token;
      },
        (err) => {
          console.error("Unable to get permission to notify.", err);
        }
      ));
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      this.msgService.sendPushNotificationChangeEvent(payload);
      const audio = new Audio('../../assets/notification-assets/sound.mp3');
      audio.play();
      if(!this.isCurrentTabACtive){
        let option=  { 
          body: payload?.notification?.body,
          icon: "../../assets/notification-assets/icon.png",
        }
        const notification = new Notification(payload?.notification?.title, option);
      }
    });
  }
}
