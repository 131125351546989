import { ComponentFactoryResolver, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
// import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select'; 
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxSummernoteModule } from 'ngx-summernote'; 
import { ReusableModule } from '../reusable.module/reusable.module';
import { ReusableComponentModule } from '../reusable/reusableComponent.module';
import { BoardViewComponent } from './board-view.component';
import { LinkyModule } from "ngx-linky";
import { AutosizeModule } from 'ngx-autosize'; 
import { SharedComponentsModule } from '../all-reusables/shared-components/shared.components.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll'; 
import { ViewBasedReusablesModule } from '../all-reusables/view-based-reusables/view-based-reusables.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { LeadReusableModule } from '../all-reusables/lead-reusables/lead-reusables.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [BoardViewComponent],
  exports: [BoardViewComponent],
  imports: [
    CommonModule,
    AngularMultiSelectModule,
    NgSelectModule,
    NgbModule,
    ReusableModule,
    ReusableComponentModule,
    DragDropModule,
    InfiniteScrollModule,
    DpDatePickerModule,
    // FileUploadModule, 
    NgxSummernoteModule,
    NgxDocViewerModule,
    PickerModule,
    HttpClientModule,
    LinkyModule,
    AutosizeModule,
    SharedComponentsModule,
    ViewBasedReusablesModule,
    NgxDropzoneModule,
    LeadReusableModule,
    ModalModule.forRoot(),
    TranslateModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class BoardViewModule {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  
  getComponent() {
    return this.componentFactoryResolver.resolveComponentFactory(
      BoardViewComponent
    );
  }
 }
