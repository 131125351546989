    <div class="row mb-2">
        <div class="col-8">
            <h5>Sub Items</h5>
        </div>
        <div class="col-4">
            <button class="addbtn border-0" (click)="openAddChildrenLeadModal(parentChildLeadTemplate)">Add Item</button>
        </div>
    </div>
    
    <div  class="containerBody">
        <div class="subitemscnr" *ngFor="let childLead of childrenOfOpenedLead" [ngStyle]="{'border-left-color':childLead?.priority=='0' ? '#C9C9C9': childLead?.priority=='1' ? '#D5D500': childLead?.priority=='2' ? '#E7971E': childLead?.priority=='3' ? '#FF544C' : ''}">
            <span class="closebtn" (click)="deleteChildFromParentLead(childLead._id)">
                <i class="fa fa-times"></i>
            </span>
        
            <div class="row">
                <div class="col-md-2 pr-0 leadSeqId">
                    <span>#{{childLead.seq_id}}</span>
                </div>
                <div class="col-md-6 leadTitle">
                    <span>{{childLead.bucketName | truncate: 10}}</span>
                </div>
                <div class="col-md-4 pl-0">
                    <span *ngIf="childLead.leadAssignedToUser?.userImageUrl" class="subitemuserimg"><img [src]="childLead.leadAssignedToUser?.userImageUrl"alt="User Image"></span>
                    <span *ngIf="!childLead.leadAssignedToUser?.userImageUrl" class="nameInitial text-light"[ngStyle]="{'background-color': childLead.leadAssignedToUser?.initialColor}">{{childLead.leadAssignedToUser?.initials || 'A'}}</span>
                    <span *ngIf="childLead.leadAssignedToUser?.name" class="userName">{{childLead.leadAssignedToUser?.name | truncate: 6}}</span>
                    <span *ngIf="!childLead.leadAssignedToUser?.name" class="userName">Assigne</span>
                </div>
            </div>
            <div class="row my-2" [class]="{'pb-0': (!childLead?.start_date && !childLead?.end_date)}">
                <div class="col-12 details">
                    {{childLead.title | truncate : 60}}
                </div>
            </div>
            <div *ngIf="childLead?.start_date || childLead?.end_date" class="row">
                <div class="col-2 pr-0"><img src="assets/1x/calender-ico.png" class=" "></div>
                <div *ngIf="childLead.start_date" class="col-5 pl-1 caldate">
                     Start <br> <span class="text-dark">{{childLead.start_date}}</span>
                </div>
                <div *ngIf="childLead.end_date" class="col-5 caldate">
                    End <br> <span class="text-dark">{{childLead.end_date}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="showLoader && childrenOfOpenedLead.length <= 0" class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
                
              </div>      
        </div>
        <div *ngIf="!showLoader && childrenOfOpenedLead.length == 0" class="d-flex justify-content-center mt-4">
            <h5>No child lead found.</h5>
        </div>
    </div>

<ng-template #parentChildLeadTemplate let-modal>
    <div class="modal-header border-bottom-0">
      <h5 class="modal-title">{{'leadOption.addSubItem' | translate}} </h5>
      <button type="button" class="roundclose" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0 mb-5">
        <app-parent-child-lead [dashId]="dashId" [parentLeadId]="parentLeadId" [dashBuckets]="dashBuckets" [parentLead]="parentLead" [dashBoardSprint]="dashBoardSprintArr"></app-parent-child-lead>
    </div>
</ng-template>