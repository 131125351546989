import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-dropdown-template',
  templateUrl: './email-dropdown-template.component.html',
  styleUrls: ['./email-dropdown-template.component.scss']
})
export class EmailDropdownTemplateComponent implements OnInit {
  @Input()lastMessage :any;
  constructor() { }

  ngOnInit(): void {
  }

}
