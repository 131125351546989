import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConstantService } from '../../../../Enums/Constant.service';
import { AutomationUtilsService } from '../../../services/automation-utils.service';

@Component({
  selector: 'app-number-operation',
  templateUrl: './number-operation.component.html',
  styleUrls: ['./number-operation.component.scss']
})
export class NumberOperationComponent implements OnInit {
  @Input() item:any
  @Input() listBasedOnType:any
  @Input() operationArr:any
  @Input() automationCall :boolean=false;
  @Input() customFieldArr:any=[];
  @Input() currencyCall:boolean=false;

  @Output() operationUpdated : EventEmitter<any> = new EventEmitter();

  constructor(private constantService:ConstantService,public automationUtils: AutomationUtilsService) { }

  ngOnInit(): void {
    console.log(this.operationArr)

  }

  checkforMirrorField(value,item){
    console.log(value)
    if(this.automationCall){
      if(item.mirrorAlgorithm){
        delete item.mirrorAlgorithm
      }
      if(item.operand!='manual'){
        let key=value.split('custom_fields.')[1]?.split('}}')[0]
        // let type=this.customFieldArr.filter(e=>e.key==key)
        let type=this.customFieldArr.filter(ele=> 
          ((['Currency'].includes(ele?.type)) && value?.includes("."+ele?.key+".")) 
          || ((!['Currency'].includes(ele?.type)) && value?.endsWith("."+ele?.key+"}}")) )
        if(type[0]?.type=='Mirror'){
          item.mirrorJson=this.automationUtils.getOptionForMirrorColumns(type[0]?.mirror_column_type)
          item.showMirror=true
        }
        else{
          item.showMirror=false
        }
      }
      else{
        item.showMirror=false
      }
    }
  }

  addMoreOperation(){
    if(!this.operationArr){
      this.operationArr = []
      let obj = {'operation' : 'OPERATOR_PLUS','operand':'','inputValue':0,'operationType' : 'OPERATION'}
      this.operationArr.push(obj)
    }
    else{
      let obj = {'operation' : 'OPERATOR_PLUS','operand':'','inputValue':0,'operationType' : 'OPERATION'}
      this.operationArr.push(obj)  
    }
    this.operationUpdated.emit(true)
  }

  addMoreOperationGroup(){
    if(!this.operationArr){
      this.operationArr = []
      let arr=[{'operation' : 'OPERATOR_PLUS','operand':'','inputValue':0,'operationType' : 'OPERATION' }]
      let operationObj={'operation' : 'OPERATOR_PLUS','operationType' : 'GROUP','operationArr':arr}
      this.operationArr.push(operationObj)
    }
    else{
      let arr=[{'operation' : 'OPERATOR_PLUS','operand':'','inputValue':0,'operationType' : 'OPERATION' }]
      let operationObj={'operation' : 'OPERATOR_PLUS','operationType' : 'GROUP','operationArr':arr}
      this.operationArr.push(operationObj)
    }
    this.operationUpdated.emit(true)

  }

  removeOperation(operationArr,i){
      operationArr.splice(i,1)
      this.operationUpdated.emit()
  }
}
