import { Component, OnInit, Input, ChangeDetectorRef, Compiler, Injector, ApplicationRef, ViewChild, ViewContainerRef, ViewEncapsulation, Output, EventEmitter, HostListener, Renderer2, ElementRef, SimpleChange } from '@angular/core';
import { HttpTransferService } from 'app/services/httpTransfer.service';
import { MessageService } from 'app/message.service';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { Subscription } from "rxjs";
import { NgbModal, ModalDismissReasons,NgbModalRef, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { AttributesIcons } from 'Enums/attributes-icons';
import { Router } from '@angular/router';
import { isThisSecond, min } from 'date-fns';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

@Component({
  selector: 'bottom-bar-section',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BottomBarComponent implements OnInit {
    @Input() showNoteView: boolean = false;
  @Input() currentRoute:any;
  @ViewChild("viewComponent", { read: ViewContainerRef })
  attributesIcons = AttributesIcons;
  viewComponent!: ViewContainerRef;
  currentOpenViewId: string | null = null;
  currentOpenView: any;
  showNote1View: boolean = false;
  showNote2View: boolean = false;
  @Input() showActivityView: boolean;
  @Input() showEditActivityView: boolean = false;
  openedActivitiesCount: number = 0;
  openedActivities: { title: string, view: string, data?: any, otherInfo?:any, leadData?:any,id?:any}[] = [];
  showPopover: boolean = false;
  @Input() activity: any= {
    dashboard_id: '6630f9c45fe8690026be068f',
    dashboard_name: 'Call Logs'
  };
  @Input() dashId: string= "6627b40b87725600430b37f5";
  @Input() leadInfo: any;
  @Input() users: any=[];
  @Input() currentViewId: any=null;
  @Input() activityId: string = "66542eac98b7c70026a20bbd";
  showBar:boolean=false;
  bottomBarOptions: { name: string }[] = [
    { name: "NOTES" },
    { name: "ITEMS" },
    { name: "INTERNAL_CHATS" },
    { name: "CHANNELS" },
    { name: "CALENDAR" },
  ];
  currentActiveTab = '';
  showRight:boolean=false;
  noteId: string = "";
  notes: any[] = [];
  countNotes:any;
  pinnedOptions: any[] = [];
  isSearchActive = false;
  searchText = '';
  isExpandItemGroup: boolean = false;
  leadResult: any[] = [];
  enableInput:boolean=false;
  groupName: string;
  filterDataJsonForSideView: any;
  filterSub: Subscription;
  @Input() filterDataObj: any;
  createdItemGroups: any[] = [];
  createdChannels: any[] = [];
  isFullScreen = false;
  localFilterDataObj :any={};
  bottomBarFilter = true;
  chatArr: any[]=[];
  minimizedChatsIds: any[] = [];
  minimizedNotesIds: any[] = [];
  minimizedChannelIds: any[] = [];
  minimizeOpen:boolean=false;
  minimizeNoteOpen:boolean=false;
  currentOpenViewData: any;
  otherInfo:any;
  leadData:any;
  chatLeftPanelForBottombar:boolean=false;
  channelData:any;
  chatUsersArr:any[]=[];
  arrayOfCustomField = [
    {
      "uId": 1717991900785,
      "display_key": "Image1",
      "mandatory": false,
      "type": "MultipleImage",
      "default_value": "",
      "is_default_enabled": false,
      "key": "image1",
      "hidden": "NEVER",
      "checked": false,
      "locked": false
    },
    {
      "uId": 1717991900786,
      "display_key": "Video1",
      "mandatory": false,
      "type": "MultipleVideo",
      "default_value": "",
      "is_default_enabled": false,
      "key": "video1",
      "hidden": "NEVER",
      "checked": false,
      "locked": false
    },
    {
      "uId": 1717991900787,
      "display_key": "Audio1",
      "mandatory": false,
      "type": "MultipleAudio",
      "default_value": "",
      "is_default_enabled": false,
      "key": "audio1",
      "hidden": "NEVER",
      "checked": false,
      "locked": false
    }
  ];
  bucketArray = [
    {
      position: {
        left: 200,
        top: 200
      },
      bucket_followed_users: [],
      is_destination: false,
      isDeleted: false,
      _id: '6627b42be09fca002667e11a',
      name: 'open',
      dashboard_id: '6627b40b87725600430b37f5',
      aging: {
        is_applicable: false
      },
      bucketseq: 0,
      update_date: 1713878059556,
      create_date: 1713878059556,
      inbound: {
        all_buckets: {
          filter: false
        }
      },
      create_lead: {
        filter: false
      },
      __v: 0
    },
    {
      position: {
        left: 400,
        top: 200
      },
      bucket_followed_users: [],
      is_destination: false,
      isDeleted: false,
      _id: '6627b434e09fca002667e30d',
      name: 'in progress',
      dashboard_id: '6627b40b87725600430b37f5',
      aging: {
        is_applicable: false
      },
      bucketseq: 1,
      update_date: 1713934017328,
      create_date: 1713878068219,
      inbound: {
        all_buckets: {
          filter: false
        }
      },
      create_lead: {
        filter: false
      },
      __v: 0,
      updated_by: '63e4a195ca4a1200219d0513'
    },
    {
      position: {
        left: 600,
        top: 200
      },
      bucket_followed_users: [],
      is_destination: false,
      isDeleted: false,
      _id: '6627b43ee09fca002667e321',
      name: 'done',
      dashboard_id: '6627b40b87725600430b37f5',
      aging: {
        is_applicable: false
      },
      bucketseq: 2,
      update_date: 1713878078504,
      create_date: 1713878078504,
      inbound: {
        all_buckets: {
          filter: false
        }
      },
      create_lead: {
        filter: false
      },
      __v: 0
    }
  ];
  currentItemGroup:any= null;
  showChatInRight:boolean = false;
  selectedChatObj:any = null;
  selectedChatTitle:string= "";
  title:string;
  calendarModalRef:NgbModalRef;
  contactBoardInfo: any[]=[];
  selectedContactBoardId: string="";
  arrayOfCustomFiled=[];
  openContactBoardFilter:boolean = false;
  selectedContactBoardData:any=null;
  disableLoader:boolean = false;
  selectedChannelObj: any;
  selectedChatUserMsgarr: any[] = [];
  selectedChatUserMsgs: any[] = [];
  selectedLeadObj: any=null;
  showRightPanelForExternalChat:boolean = false;
  selectedleadResponces: any[]=[];
  itemSettingConfig:any={}
  isBottombarPinned: boolean=false;
  minimizedItemIds: any[]=[];
  leadArr: any[]=[];
  countItem: any;
  minimizeItemOpen: boolean=false;
  priorityJson:any={};
  popoverRef: NgbPopover;
  minimizeChannelOpen: boolean=false;
  showBottomBar: boolean=false;
  editFilter: boolean=false;
  stackAreaArray: any[]=[];
  constructor(private httpTransfer: HttpTransferService, private cdr: ChangeDetectorRef, private msgservice: MessageService, public commonUtils: CommonUtils,private router: Router,
    private modalService: NgbModal,private compiler: Compiler, private injector: Injector,private app: ApplicationRef,
    private dashboardUtils : DashboardUtilsService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.priorityJson = this.commonUtils.priorityJson;
    this.msgservice.getSaveMinimizeResult().subscribe(res => {
      if(res?.id) {
        this.fetchPinnedOptions();
      }
    });
  }

  async ngOnInit() {
    this.getInternalChat();
    this.fetchPinnedOptions();
    this.getNoteDetails()
    let res = await this.httpTransfer.queryUserPreference().toPromise()
    this.isBottombarPinned = res?.preferences?.is_nav_pinned
  }

 
  async handleOpenView(view: string, title: string, data?: any, otherInfo?: any, leadInfo?: any) {
     // await this.addCurrentOpenViewToOpenedActivities();
    /*   if(!this.currentOpenViewId){
    this.currentOpenViewId = data?._id || null;
    this.currentOpenView = view || null;
    this.currentOpenViewData = data || null;
    this.otherInfo = otherInfo || null;
    this.leadData=leadInfo || null;
    } */
  //  this.openedActivities = this.openedActivities.filter(item => item.data._id !== this.currentOpenViewId);
    switch (view) {
      case 'Notes':
        this.showRight = true;
        break;
      case 'Activity':
        this.showActivityView = true;
        break;
      case 'Edit Activity':
        this.showEditActivityView = true;
        break;
      case 'Internal Chat':
        this.showChatInRight = true;
        break;
      case 'Channels':
        this.showRightPanelForExternalChat = true;
        break;
    }
   // this.addOpenedActivity(title, view, data, otherInfo, leadInfo);
    this.itemSettingConfig={}
    this.currentItemGroup=null
    this.handleStackArea(data,view,title)
   // this.computeOpenedActivitiesCount();
  }


  handleStackArea(data,view,title){
    this.minimizedNotesIds = this.minimizedNotesIds.filter(m=>m!==data?._id)
    this.minimizedChannelIds = this.minimizedChannelIds.filter(m=>m!==data?._id)
    this.minimizedChatsIds = this.minimizedChatsIds.filter(m=>m!==data?._id)
    this.minimizedItemIds = this.minimizedItemIds.filter(m=>m!==data?._id)
    this.stackAreaArray.push({'title':title,'data':data,'view':view,'id':data?._id})
    console.log(this.stackAreaArray)
  }

 /*  computeOpenedActivitiesCount(): void {
    this.openedActivitiesCount = this.openedActivities.length;
  } */

  toggleSearch(): void {
    this.isSearchActive = !this.isSearchActive;
  }

 /*  addOpenedActivity(title: string, view: string, data?: any, otherInfo?:any, leadData?:any,upcomingOpenData?): void {
    console.log(this.currentOpenViewData,upcomingOpenData)
    const existingActivity = this.openedActivities.find(activity => activity.view === view && activity?.data._id === data?._id);
    if (!existingActivity && this.currentOpenViewId !== data?._id) {
      let prevOpenViewData = JSON.parse(JSON.stringify(this.currentOpenViewData))
      this.openedActivities.push({ title:prevOpenViewData?.title ? prevOpenViewData?.title : title , view, data:prevOpenViewData, otherInfo, leadData,id:data?._id});
      this.currentOpenViewId = data?._id || null;
      this.currentOpenView = view || null;
      this.currentOpenViewData = data || null;
      this.otherInfo = otherInfo || null;
      this.leadData=leadData|| null;  
      console.log(this.openedActivities)
    }
  } */

  /* addCurrentOpenViewToOpenedActivities(): void {
    console.log(this.currentOpenView)
    console.log(this.minimizedNotesIds)
    console.log(this.currentOpenViewData)
    const currentTitle = this.getCurrentOpenTitle(this.currentOpenView);
    let currentMessages: any;
    let currentLeadObj: any;
    if(this.currentOpenView=="Channels") {
     currentMessages = this.getCurrentData('Messages');
     currentLeadObj = this.getCurrentData('Lead');
    }
    if (this.currentOpenView && currentTitle) {
      const existingActivity = this.openedActivities.find(activity => activity?.data?._id === this.currentOpenViewId);
      if (!existingActivity && !this.minimizedNotesIds.includes(this.currentOpenViewData?._id) && !this.minimizedChannelIds.includes(this.currentOpenViewData?._id) && !this.minimizedChatsIds.includes(this.currentOpenViewData?._id) && !this.minimizedItemIds.includes(this.currentOpenViewData?._id)) {
        this.openedActivities.push({ title: currentTitle, view: this.currentOpenView, data: this.currentOpenViewData, otherInfo: currentMessages, leadData: currentLeadObj });
      }
    }
  } */

  getCurrentOpenTitle(view): string | null {
    const currentViewId = this.currentOpenViewId;
    if(view == "Notes") {
    const currentNote = this.notes.find(note => note._id === currentViewId);
    return currentNote ? currentNote.title : null;
    }
    if(view == "Internal Chat") {
      const currentChat = this.chatArr.find(chat => chat._id === currentViewId);
      return currentChat ? currentChat.title : null;
    }
    if(view == "Channels") {
      const comment = this.selectedChatUserMsgs.find(comment => comment._id === currentViewId);
      const chatUser = this.chatUsersArr.find(user => user._id === comment.chat_user_id);
      return chatUser ? chatUser.name : null;
    }
  }

  getCurrentData(dataType: string): any | null {
    const currentViewId = this.currentOpenViewId;
    const comment = this.selectedChatUserMsgs.find(comment => comment._id === currentViewId);
    if (!comment) return null;

    const chatUser = this.chatUsersArr.find(user => user._id === comment.chat_user_id);
    if (!chatUser) return null;

    const chatMessages = this.selectedChatUserMsgs.filter(user => user.chat_user_id === chatUser._id);
    if (!chatMessages) return null;

    if (dataType === 'Messages') {
        return chatMessages;
    } else if (dataType === 'Lead') {
        const filteredUserMsgs: any = chatMessages.find(msg => msg?.lead_id?.length > 0);
        return filteredUserMsgs ? this.selectedleadResponces.find(lead => lead._id === filteredUserMsgs.lead_id[0]) : null;
    }
    return null;
  }

  togglePopover(): void {
    this.showPopover = true;
  }

  handleTitleClick(index: number): void {
    this.showRightPanelForExternalChat = false;
    this.showRight=false;
    this.showChatInRight = false;
    this.popoverRef?.close()
    let prevStackData = this.stackAreaArray[index];
    this.stackAreaArray.splice(index,1)
    // this.handleOpenView(view, this.openedActivities[index].title, data?.id);
   // this.addCurrentOpenViewToOpenedActivities();
   // this.removeOpenedActivity(view, data?.data?._id)
   this.handleStackArea(prevStackData?.data,prevStackData?.view,prevStackData?.title)
    if(prevStackData?.view=="Notes") {
    setTimeout(()=>{
      this.showRight=true;
    this.showChatInRight=false;
    this.showRightPanelForExternalChat=false;
    this.noteId = prevStackData?.id;
    },50)
    } else if(prevStackData?.view=="Internal Chat") {
      setTimeout(()=>{
        this.showChatInRight = true;
        this.showRight=false;
        this.showRightPanelForExternalChat=false;
        this.selectedChatTitle=prevStackData?.title;
        this.selectedChatObj = prevStackData?.data;
      },50)
    }
    else if(prevStackData?.view=="Channels") {
      setTimeout(()=>{
        this.showRightPanelForExternalChat = true;
        this.showRight=false;
        this.showChatInRight = false;
        this.selectedChannelObj=prevStackData?.data;
        this.selectedChatUserMsgarr=prevStackData?.otherInfo;
        this.selectedLeadObj=prevStackData?.leadData;
      },50)
    }
  }

  fetchPinnedOptions() {
    this.httpTransfer.getUserSettings().subscribe(res => {
      if (res) {
        this.pinnedOptions = res?.user_bottom_bar_settings?.pin;
        this.createdItemGroups = res?.user_bottom_bar_settings?.item_groups;
        this.createdChannels = res?.user_bottom_bar_settings?.existing_channel_chats;
        let minimizedIds = res?.user_bottom_bar_settings?.minimized_chat;
        let minimizedNotesIds = res?.user_bottom_bar_settings?.minimized_note;
        let minimizedChannelIds = res?.user_bottom_bar_settings?.minimized_channel;
        let minimizedItemIds = res?.user_bottom_bar_settings?.minimized_item_id;
        this.minimizedChatsIds = minimizedIds;
        this.minimizedNotesIds = minimizedNotesIds;
        this.minimizedChannelIds = minimizedChannelIds;
        this.minimizedItemIds = minimizedItemIds;
        if(this.createdChannels?.length) {
          this.createdChannels.forEach(async d=>{
            let dashboardJson = await this.dashboardUtils.getAndSyncAllDashboardData([d?.dashboard_id],false)
            d['name'] = dashboardJson[d?.dashboard_id]?.DASHBOARD_INFO?.dashboard_name
          })
          this.getChatUser(this.createdChannels)
        }
      }
      if(this.stackAreaArray?.length>1){
        let idsToRemove = new Set(this.stackAreaArray.map(dataItem => dataItem.id));
        // Filter minimizedNotesIds to exclude items that are in idsToRemove
        this.minimizedNotesIds = this.minimizedNotesIds.filter(item => !idsToRemove.has(item));
        this.minimizedChannelIds = this.minimizedChannelIds.filter(item => !idsToRemove.has(item));
        this.minimizedChatsIds = this.minimizedChatsIds.filter(item => !idsToRemove.has(item));
        this.minimizedItemIds = this.minimizedItemIds.filter(item => !idsToRemove.has(item));
      }
      const groupedData = {};
      this.minimizedChannelIds?.length && this.minimizedChannelIds.forEach(item => {
        if (groupedData[item.dashboard_id]) {
          groupedData[item.dashboard_id].push(item.channel_id);
        } else {
          groupedData[item.dashboard_id] = [item.channel_id];
        }
      });
      const outputArray = Object.keys(groupedData).map(dashboard_id => {
        return {
          "dashboard_id": dashboard_id,
          "channel_id": groupedData[dashboard_id]
        };
      });
      this.channelData = outputArray;
      this.showBar=true
    });
  }

  getChatUser(channels) {
    const dashboardIds = channels.map(channel => channel.dashboard_id);
    this.httpTransfer.getChatUserQuery({ 'dashboard_id': dashboardIds }).subscribe(res => {
      this.chatUsersArr = res.result?.chatUsers || [];
      const channelIds = [...new Set(this.chatUsersArr.map(channel => channel.channel_id))];
      this.httpTransfer.getCommentCorrespondingChat({ 'channel_id': channelIds }).subscribe((res: any) => {
        this.selectedChatUserMsgs = [...res.result?.commentsInfo.reverse(), ...this.selectedChatUserMsgs];
        let leadIds = [];
        this.selectedChatUserMsgs.forEach(user => {
          if (Array.isArray(user.lead_id) && user.lead_id.length > 0) {
            leadIds = leadIds.concat(user.lead_id);
          }
        });
        this.httpTransfer.getLeadQuery({ lead_id: leadIds })
          .subscribe((res: any) => {
            if (res.status == 200) {
              this.selectedleadResponces = res?.result?.leadResponse;
            }
          });
      });
    });
  }

  onChangeUserSetting(selectedSetting: any, isPinned: boolean) {
    const updateType = isPinned ? "APPEND" : "REMOVE";
    const inputJson = {
      "bottom_bar_settings": {
        "pin": [selectedSetting],
        "update_type": updateType
      }
    };
    this.httpTransfer.createUserSettings(inputJson).subscribe(res => {
      if (res) {
        this.fetchPinnedOptions();
      }
    });
  }
  
  handleOptionClick(selectedOption, calendarModal?) {
    this.minimizeOpen=false;
    this.minimizeItemOpen=false;
    this.minimizeNoteOpen=false;
    this.minimizeChannelOpen=false;
    this.showBottomBar = true
    this.currentActiveTab=selectedOption;
    if(selectedOption == "NOTES") {
      this.notes = []
      this.getNoteDetails()
    }
    if(selectedOption == "INTERNAL_CHATS") {
      this.chatArr = []
      this.getInternalChat();
    }
    if(selectedOption == "CALENDAR") {
      this.openCalendar(calendarModal);
    }
    //this.shareNotesPopover.open();
    // if(selectedOption == "CHANNELS") {
    //   this.openChatPopup();
    // }
  }
  async openCalendar(modalContent) {
    this.calendarModalRef =  this.modalService.open(modalContent, { size: 'lg' , windowClass: 'calendarModalView' });
    let newNode = document.createElement('div');
    newNode.id = 'placeholder';
    newNode.classList.add('placeholder-calendar');
    document.getElementById('calendarComponent').appendChild(newNode);
    const { GlobalCalendarModule } =  await import("../all-reusables/global-calendar/global-calendar.module");
    const moduleFactory = await this.compiler.compileModuleAsync(GlobalCalendarModule);
    const moduleRef = moduleFactory.create(this.injector);
    const componentFactory = moduleRef.instance.getComponent();
    const ref = componentFactory.create(this.injector, [], newNode);
    this.app.attachView(ref.hostView);
    ref.instance.calendarModalRef=this.calendarModalRef;
  }
  getNoteDetails() {
    let data = {
      "my_notes": true
    }
    this.httpTransfer.getNote(data).subscribe((res : any) => {
      this.notes = res.result.notes;
      this.countNotes= res.result.notes.length;
    })
  }

  getInternalChat(){
    let param={
       sorting_details: {
            "sort_by": "comments",
            "sort_order": "DESC",
        }, 
     }
       this.httpTransfer.getInternalChatQuery(param).subscribe(async res=>{
          if(res.status==200){
            this.chatArr = res?.result?.chats;
            this.unreadCommentCount()
          }
       })
  }
  unreadCommentCount(){
    this.chatArr.forEach(e=>{
      e = this.commonUtils.calcuateCommentCount(e,e)
      e['unreadCount'] = e?.totalCommentCount - e?.totalReadCommentCount
    })
  }
  showNoteRight(data) {
    this.showRight = false;
    this.showChatInRight=false;
    this.showRightPanelForExternalChat=false;
    this.noteId = data?._id
    setTimeout(()=>{
      this.handleOpenView('Notes', data?.title, data);  
    },10)
  }
  createdNote() {
    let inputJson = {
      "title":"Untitled Note",
      "user_id": [localStorage.getItem("id")],
      "note_text": "[]",
    }
    setTimeout(() => {
      this.httpTransfer.createNote(inputJson).subscribe((res : any) => {
        if (res.status == 200) {
          this.noteId = res.result.note_id
          this.getNoteDetails();
          this.showRight=true
        }
        this.cdr?.detectChanges()
      })
    }, 100);
  }
  onSearchChange(searchText: string) {
    this.searchText = searchText;
    let inputJson = {
      'search_params':
      {
        search_text: searchText,
        search_field: ["title"]
      },
      "my_notes": true
    }
    this.httpTransfer.getNote(inputJson).subscribe((res : any) => {
      this.notes = res.result.notes;
      this.countNotes= res.result.notes.length;
    })
  }


  createItemGroup(param='create'){
    if(this.groupName?.trim()?.length>0){
      const updateType = param ? "APPEND" : "REMOVE";
      const inputJson = {
        bottom_bar_settings: {
          item_groups: [
            {
              group_name: this.groupName,
            }
          ],
          update_type: updateType
        }
      };
      this.httpTransfer.createUserSettings(inputJson).subscribe(res => {
        if (res) {
          this.fetchPinnedOptions();
          this.enableInput = false;
        }
      });
    }
    else{
      this.enableInput = false;
    }
  }
  openModal(modal){
    if(this.isFullScreen) return;
    this.isFullScreen=true
    const modalRef = this.modalService.open(modal, {size: 'md', windowClass: 'fullScreenPopOverOpne',})

    modalRef.hidden.subscribe(()=>{
    });
  }


showInternalChatRight(data) {
  this.showChatInRight=true;
  this.showRight=false;
  this.showRightPanelForExternalChat=false;
  this.selectedChatTitle=data?.title;
  this.selectedChatObj = data;
  this.handleOpenView('Internal Chat', this.selectedChatTitle, data);   
  
}

createInternalChat(param='create'){
  let value = {'title':this.title}
  if(param=='create'){
    this.httpTransfer.createInternalChat(value).subscribe(res=>{
      if(res.status==200){
        this.enableInput= false;
        this.title=""
        this.getInternalChat()
      }
  }) 
  }
}
handleMinimizeClick () {
  this.currentActiveTab='';
  this.minimizeNoteOpen=false;
  this.minimizeOpen=true;
  this.minimizeItemOpen=false;
  this.minimizeChannelOpen=false;
  this.showBottomBar=true
  const matchingChats = this.chatArr.filter(chat => this.minimizedChatsIds.includes(chat._id));
  this.chatArr = matchingChats
}
handleNoteMinimizeClick (noteIds) {
  let inputJson = {
    "note_id": noteIds
  }
  this.httpTransfer.getNote(inputJson).subscribe((res : any) => {
    this.notes = res.result.notes;
    this.countNotes= res.result.notes.length;
  })
  this.currentActiveTab='';
  this.minimizeOpen=false;
  this.minimizeNoteOpen=true;
  this.minimizeItemOpen=false;
  this.minimizeChannelOpen=false;
  this.showBottomBar=true;
  const matchingNotes = this.notes.filter(note => this.minimizedNotesIds.includes(note._id));
  this.notes = matchingNotes
}
  async handleItemMinimizeClick (itemIds) {
  console.log(itemIds)
  let input = {}
  input["lead_id"] = itemIds.map(e => e?.item_id);
  let res: any = await this.httpTransfer.getLeadQuery(input).toPromise()
  this.leadArr = res.result?.leadResponse
  this.currentActiveTab='';
  this.minimizeOpen=false;
  this.minimizeItemOpen=true;
  this.minimizeNoteOpen=false;
  this.minimizeChannelOpen=false;
  this.showBottomBar=true;
}

handleChannelMinimizeClick(){
  this.currentActiveTab='';
  this.minimizeOpen=false;
  this.minimizeItemOpen=false;
  this.minimizeNoteOpen=false;
  this.minimizeChannelOpen=true;
  this.showBottomBar=true;
}
nameValidate(name:string) {
  return name.replace(/_/g, ' ');
}
showExistingChatOptions() {
  console.log("channels ", this.createdChannels)
  this.disableLoader=true
  let inputJson = {"status":["ACTIVE"],"board_type": ["CONTACT"]}
    this.httpTransfer.getDashBoardForAdmin(inputJson).subscribe((result:any) => {
      if(!this.createdChannels?.length){
     this.contactBoardInfo = result.result.dashboards
      }
      else{
        const existingChannelChatIds = this.createdChannels?.length && this.createdChannels.map(chat => chat.dashboard_id);
        this.contactBoardInfo = result.result.dashboards.filter((dashboard: any) => {
          return !existingChannelChatIds?.includes(dashboard.dashboard_id);
        });
      }
      this.disableLoader=false;
  })
  this.minimizeChannelOpen=false;
  this.minimizeItemOpen=false;
  this.minimizeNoteOpen=false;
  this.minimizeOpen=false;
}
async openFilterForContactBoard(board,modal) {
  console.log(board)
  this.selectedContactBoardId = board.dashboard_id;
  this.selectedContactBoardData=board;
  await this.dashboardUtils.getDashboardCustomForms([this.selectedContactBoardId]).then(res=>{
    this.arrayOfCustomFiled = res[this.selectedContactBoardId].CUSTOM_FORM;
    console.log(this.commonUtils.converFilterObjAccordingToFilter({},this.arrayOfCustomFiled))
    this.localFilterDataObj = this.commonUtils.converFilterObjAccordingToFilter(board?.filter ? board?.filter : {},this.arrayOfCustomFiled)
    this.openContactBoardFilter = true;
    this.modalService.open(modal, { size : "md" , windowClass: 'bottomfilterModalClass'});
  })
}
async applyBoardFilter(item){
  let filterDataObj = structuredClone(this.localFilterDataObj)
  filterDataObj = this.commonUtils.cleanFilterDataObject(filterDataObj,this.arrayOfCustomField)
  let saveFilterPayload = this.commonUtils.getFilterJsonForQuery({}, filterDataObj)
  saveFilterPayload["dashboard_id"] = [this.selectedContactBoardId]
  console.log("save filter ", saveFilterPayload)
  this.chatLeftPanelForBottombar=true;
  this.openContactBoardFilter=false;
  const inputJson = {
    bottom_bar_settings: {
      existing_channel_chats: [
        {
          dashboard_id: this.selectedContactBoardId,
          filter: saveFilterPayload,
          name: this.selectedContactBoardData.dashboard_name
        }
      ],
      update_type: !this.editFilter ? "APPEND" : "UPDATE"
    }
  };
  this.httpTransfer.createUserSettings(inputJson).subscribe(res => {
    if (res) {
      this.fetchPinnedOptions();
      this.enableInput = false;
    }
  });
}
closeFilteremit(){
  this.openContactBoardFilter=false;
}
resetFilter(){
  this.filterDataObj = this.commonUtils.createDefaultFilterDataObj();
  this.selectedContactBoardData = this.filterDataObj
  this.localFilterDataObj = this.filterDataObj
  console.log(this.selectedContactBoardData,this.filterDataObj)
}
openChatPopup() {
  this.closeBottomPop()
  this.msgservice.getExternalChatData().subscribe(res => {
    if(res?.comment) {
      this.selectedChannelObj=res?.comment;  
    }
    if(res?.selectedChatUserMsgarr) {
      this.selectedChatUserMsgarr=res?.selectedChatUserMsgarr;
    }
    if(res?.selectedLeadObj) {
      this.selectedLeadObj=res?.selectedLeadObj;
    }
  });
  if(this.selectedChannelObj && this.selectedChatUserMsgarr && this.selectedLeadObj) {
    this.showRightPanelForExternalChat=true;
    this.showRight=false;
    this.showChatInRight=false;
    this.callForMinimize();
  }
}
callForMinimize() {
  this.handleOpenView('Channels', this.selectedChannelObj?.chatUserName, this.selectedChannelObj, this.selectedChatUserMsgarr, this.selectedLeadObj);
}

async minimizeChannel() {
  this.showRightPanelForExternalChat=false;
  let inputJson = {
    "bottom_bar_settings": {
      "minimized_channel": [
        {
          "item_id": this.selectedChannelObj?.lead_id,
          "dashboard_id": this.selectedChannelObj?.dashboard_id,
          "channel_id": this.selectedChannelObj?._id
        }
      ],
      "update_type": "APPEND"
    }
  }  
 await this.minimizeRightOpenView(null,inputJson)
}
async removefromMinimize() {
  let inputJson = {
    "bottom_bar_settings": {
      "minimized_channel": [
        {
          "item_id": this.selectedChannelObj?.lead_id,
          "dashboard_id": this.selectedChannelObj?.dashboard_id,
          "channel_id": this.selectedChannelObj?._id
        }
      ],
      "update_type": "REMOVE"
    }
  } 
  await this.minimizeRightOpenView(null,inputJson)
  this.showRightPanelForExternalChat = false;
}

//  


onFilterChange(item?,isreset=false){
  delete this.localFilterDataObj['include_activities']
  delete this.localFilterDataObj['associated_activity_workspace_board'];
  this.localFilterDataObj['dashboard_id']=this.currentItemGroup?.connected_board_id || []
  if(this.currentItemGroup?.include_activities){
   this.localFilterDataObj['include_activities']=true
   this.localFilterDataObj['associated_activity_workspace_board']=[...this.localFilterDataObj['dashboard_id']];
   if(this.itemSettingConfig?.boardInfoData && !this.currentItemGroup?.connected_board_id?.includes(null)){
     let boardIds=this.currentItemGroup?.connected_board_id?.reduce((list,id)=>{ return [...list,...(this.itemSettingConfig.boardInfoData[id]?.connected_activity_types?.map(board=>board?.board_id) || [])]},[]);
     this.localFilterDataObj.dashboard_id=[...this.localFilterDataObj?.dashboard_id,...(boardIds || [])]
   }
  }
  if(!this.currentItemGroup?.connected_board_id?.length || this.currentItemGroup?.connected_board_id?.includes(null) || !this.localFilterDataObj?.dashboard_id?.length ){
    delete this.localFilterDataObj['associated_activity_workspace_board'];
    delete this.localFilterDataObj['dashboard_id']
  }
  // handle filter
  if(isreset){
    this.localFilterDataObj = this.commonUtils.resetFilterDataObj(this.localFilterDataObj,this.arrayOfCustomField);
  }
  this.localFilterDataObj = this.commonUtils.cleanFilterDataObject(this.localFilterDataObj,this.arrayOfCustomField)
  this.localFilterDataObj = this.commonUtils.getFilterJsonForQuery({},this.localFilterDataObj)
  this.currentItemGroup.filter=structuredClone(this.localFilterDataObj);
  delete this.itemSettingConfig?.view
  this.currentItemGroup.filterCount= this.commonUtils.countAppliedFilter(this.currentItemGroup.filter)
  // create json
  let json={
    'include_activities':this.currentItemGroup?.include_activities,
    'connected_board_id':this.currentItemGroup?.connected_board_id,
    'group_id':this.currentItemGroup?.group_id,
    'group_name':this.currentItemGroup?.group_name,
    'filter':this.currentItemGroup.filter
  }
  // hit query
  const inputJson = {bottom_bar_settings: {item_groups:[json],update_type: "UPDATE"}};
  this.httpTransfer.createUserSettings(inputJson).subscribe(res => {
    if (res) {}
  });
  this.currentItemGroup.showItem=false;
  setTimeout(() => {this.currentItemGroup.showItem=true},2);
}

onConnectedBoardChange(){
  if(this.currentItemGroup?.dummy_connected_board_id?.includes(null)){
    this.currentItemGroup.dummy_connected_board_id=this.currentItemGroup?.dummy_connected_board_id?.length && this.currentItemGroup?.dummy_connected_board_id[this.currentItemGroup?.dummy_connected_board_id?.length-1]==null ? [null] : this.currentItemGroup?.dummy_connected_board_id?.filter(id=>id)
  }
} 
updateItemsGroupSetting(){
  let resetFilter=(this.currentItemGroup.connected_board_id?.length>this.currentItemGroup.dummy_connected_board_id?.length || !this.currentItemGroup.dummy_connected_board_id?.includes(null) && this.currentItemGroup.connected_board_id?.includes(null) || this.currentItemGroup.dummy_connected_board_id?.includes(null) && !this.currentItemGroup.connected_board_id?.includes(null))
  this.currentItemGroup.include_activities=this.currentItemGroup.dummy_include_activities;
  this.currentItemGroup.connected_board_id= this.currentItemGroup.dummy_connected_board_id;
  this.onFilterChange(null,resetFilter)
}

changeItemGroup(params) {
  if(this.currentItemGroup?.group_id && this.currentItemGroup?.group_id == params?.group_id){
    this.isExpandItemGroup = false;
    this.currentItemGroup=null
  }else{
    this.isExpandItemGroup=true
    this.currentItemGroup = params;
    delete this.currentItemGroup?.showItem
    setTimeout(() => {this.currentItemGroup.showItem=true},2); 
    if(params && !params?.filter){
      this.currentItemGroup.filter={
        grouping_details:{"group_by": "none","max_group_size": 50,"start_index": 0,"sort_order": "ASC"},
        search_params:{"search_text": ""},
        sort_params:[{"sort_by": "index","order": "DSC"}]
      }
    }
    this.currentItemGroup.dummy_include_activities=this.currentItemGroup?.include_activities ? true : false;
    this.currentItemGroup.dummy_connected_board_id=[...(this.currentItemGroup?.connected_board_id || [null])];
    let localFilterDataObj =  this.commonUtils.converFilterObjAccordingToFilter(this.currentItemGroup.filter,[]);
    this.localFilterDataObj = structuredClone(localFilterDataObj);
    this.currentItemGroup.filterCount= this.commonUtils.countAppliedFilter(this.currentItemGroup.filter) 

  }

}
onItemOpen(data){
  console.log(data)
  let item=data?.item ? data?.item : data
  // if user select activity item after that he/she select non activity item so we can recreate item view because activity item open in right view
  if(this.itemSettingConfig.itemType!=item?.item_type){
    this.itemSettingConfig.showItemPreview=false;
    setTimeout(() => {this.itemSettingConfig.showItemPreview=true;},2);
  }else{
    this.itemSettingConfig.showItemPreview=true
  }
  this.itemSettingConfig.itemType=item?.item_type;
  this.itemSettingConfig.itemPreviewData=[{itemId:data?.itemId,boardId:data.boardId,item:data?.item}];
}

getDasahboarData(){
  if(this.itemSettingConfig?.boardDataList?.length){
    return 
  }
  let inputJson = {
    status:["ACTIVE"],
    get_my_boards: true,
    "type": ["BOARD"],
    "access_type":  ["PRIVATE", "INTERNAL"]
  }
  this.httpTransfer.getDashBoardForAdmin(inputJson).subscribe((res)=>{ 
    this.itemSettingConfig['boardDataList']=[{dashboard_id:null,dashboard_name:'All'}];
    this.itemSettingConfig['boardInfoData']={};
    (res['result']['dashboards'] || [])?.forEach(data=>{
      this.itemSettingConfig?.boardDataList?.push(data);
      this.itemSettingConfig.boardInfoData[data?.dashboard_id]=data
    })   
  });  
}


isBottomBarPinned(){
  let pref ={
    'is_nav_pinned': !this.isBottombarPinned
  }
  this.httpTransfer.saveUserPreference({preferences:pref}).subscribe((res : any) =>{
    if(res?.status==200){
      this.isBottombarPinned = !this.isBottombarPinned 
    }
  })
}

@HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    const appRootElement = this.elementRef.nativeElement; // Reference to <app-root>
    const appRootRect = appRootElement.getBoundingClientRect();
    // Get the y-coordinate of the cursor
    const cursorY = event.clientY;
    // Check if the cursor is within the bottom 60px of the <app-root> element
      if (cursorY >= appRootRect.bottom - 60 && !this.isBottombarPinned) {
        console.log('Cursor is within the bottom 60px of <app-root>');
        const bottomBarElement = this.elementRef?.nativeElement?.querySelector('.bottomBarContentDiv');
        if(bottomBarElement){this.renderer?.addClass(bottomBarElement, 'hover');}
        // Add any action you want to trigger when the cursor is in this area
      } else {
        console.log('Cursor is not in the bottom 60px area',this.popoverRef?.isOpen());
               // Remove the hover class if the cursor is not in the bottom 60px area
          const bottomBarElement = this.elementRef?.nativeElement?.querySelector('.bottomBarContentDiv');
          if(bottomBarElement && !this.popoverRef?.isOpen()){this.renderer?.removeClass(bottomBarElement, 'hover');}
        }
  }

  getPopRef(popover: NgbPopover) {
    this.closeBottomPop()
    setTimeout(()=>{this.popoverRef = popover
    },5)
  }

  removeConnectBoardForChannel(index) {
    let inputJson = {
      "bottom_bar_settings": {
        "existing_channel_chats": [this.createdChannels[index]],
        "update_type": "REMOVE"
      }
    };
    this.httpTransfer.createUserSettings(inputJson).subscribe(res => {
      if (res) {
        this.createdChannels.splice(index,1)
      }
    });
  }
  
 
  closeBottomPop(){
    this.popoverRef?.close()
    this.popoverRef = null
  }

  async minimizeRightOpenView(event,json?){
  if(event?.update_type=="REMOVE"){
     this.currentOpenView =null;
     this.currentOpenViewData=null;
     this.currentOpenViewId=null;
  }
   if(!json){}
   let param= json ? json : {}
   if(event?.view=='NOTES'){
    this.stackAreaArray = this.stackAreaArray.filter(s=>s?.id!== event?.minimizeId)
    param["bottom_bar_settings"]= {
    'minimized_note': [event?.minimizeId],
    "update_type": event?.update_type
    }
   }
   else if(event?.view=='ITEM'){
    this.stackAreaArray = this.stackAreaArray.filter(s => !event?.minimizeId?.includes(s?.id));
    param["bottom_bar_settings"]= {
    'minimized_item_id': event?.minimizeId,
    "update_type": event?.update_type
    }
   }
   else if(event?.view=='INTERNAL_CHAT'){
    this.stackAreaArray = this.stackAreaArray.filter(s => !event?.minimizeId?.includes(s?.id));
    param["bottom_bar_settings"]= {
    'minimized_chat': event?.minimizeId,
    "update_type": event?.update_type
    }
   }
   let res = await this.httpTransfer.createUserSettings(param).toPromise()
   this.fetchPinnedOptions()     
   }
  }

