<div class="parentChildComponent">
  <!-----display opened lead information------->
  <div class="row g-0 mx-0 w-100 mt-1 mb-2">
    <app-item-list  [leadDataList]="[leadInfo]"  [dashId]="leadInfo.dashboard_id" [showRemoveIcon]="false" [showBoardName]="false" class="w-100" [isReadOnly]="true"></app-item-list>
  </div>
    <!-------Search box for lead search------>
    <div>
      <div class="row mx-0 w-100">
        <div class="col-md-12 px-0 form-group mb-0" >
          <label for="lead" class="control-label modalLabel">{{ addOrChangeParent ? ('leadOption.parent.parentItem' | translate :{ term: "Item" | terminology: itemTerminology?.plural : 'Item' }) : ('leadOption.subItems' | translate :{ term: "Item" | terminology: itemTerminology?.plural : 'Item' }) }}</label> 
          <br> 
          <span *ngIf="addOrChangeParent"  class="col-md-12 px-0 form-group mb-0">{{'leadOption.parent.subItemhaveOneParent' | translate :{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}</span>
          <ng-select  class="custom" #st  placeholder="{{addOrChangeParent ? ('leadOption.parent.addParentfromExisting' | translate : { term: 'Item' | terminology: itemTerminology?.plural : 'Item' }) : ('leadOption.searchItembyID' | translate :{ term: 'Item' | terminology: itemTerminology?.singular : 'Item' })  }}"  appearance="outline" name="selectParentLead" #selectDropdown (search)="filterLead($event)"
          [multiple]="false" [closeOnSelect]="true" [searchable]="true" [clearable]="true" [(ngModel)]="selectedLead" [disabled]="dummySubItemList.length>0 && addOrChangeParent" (click)="addSubItemLeadInList(selectedLead);selectDropdown.handleClearClick()" 
          [virtualScroll]="true" (scrollToEnd)="gettingLeadDataOnScroll()">
            <ng-option *ngFor="let lead of dashboardLeadArr" [value]="lead">
              <div class ="p-0 mb-0 leadCardBoxs w-100 border-bottom">
                  <div class="leadHeaderRows w-100" style="justify-content: space-between;">
                    <span class="leadIdDetails">
                      <span class="priorityTag">
                        <span class="prioLabelTag" [style.background]="commonUtils?.priorityJson[lead?.priority]?.color" >{{commonUtils?.priorityJson[lead?.priority]?.label}}</span>
                      </span>
                      <span class="mx-2">#{{lead?.seq_id}}</span>
                    </span>
                    <button type="button" class="close pull-right" aria-label="Close" style="width: 10%;">
                      <span aria-hidden="true">&#43;</span>
                    </button>
                  </div>
                  <div class="leadDescriptionBox w-100">
                    <p class="mb-0 leadDescription ">
                      {{lead.title | slice:0:130}}
                    </p>
                  </div>
                </div>
              </ng-option>
          </ng-select>
        </div>
      </div>  
    </div>
    <!-- slected lead -->
    <div class="row g-0 mx-0 w-100 mt-2" style="max-height: 210px;overflow: scroll;" *ngIf="dummySubItemList.length>0">
      <app-item-list [leadDataList]="dummySubItemList" [dashId]="leadInfo.dashboard_id" [showRemoveIcon]="true" [showBoardName]="false" (itemRemove)="removeSubItemLeadInList($event)" [showDeletePopover]="false" class="w-100" [isReadOnly]="true" #showSubTask ></app-item-list>
    </div>
    <!-------Bottom Buttons-->
    <div class="col-md-12 px-0 bottom-btns mx-1 my-3 w-100">
      <button  type="button" 
              [disabled]="addedSubItemList.length==0 && removedSubItemList.length==0"  data-dismiss="modal" 
              (click)="UpdateChildLead();closeModel();"
              class="btn btnStyleS donebtn"> {{'common.done' | translate}}</button>   
      <button type="button" data-dismiss="modal" aria-label="Close" (click)="closeModel()"
          class="btn closeBtn btnStyleS"> {{'common.cancel' | translate}}</button>
    </div>
</div>
