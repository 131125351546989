import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';


@Component({
  selector: 'app-multiple-mobile-handle',
  templateUrl: './multiple-mobile-handle.component.html',
  styleUrls: ['./multiple-mobile-handle.component.scss']
})
export class MultipleMobileHandleComponent implements OnInit {
  @Input() leadObj = null
  @Input() dashId = ''
  @Input() editable = false
  @Input() countryJson = []
  @Input() currentValue: any = []
  @Input() gridView = false
  @Output() onValueUpdate = new EventEmitter<any[]>()
  @Output() close = new EventEmitter<boolean>()

  items = [];
  showMore:boolean=true

    
  constructor(){
    // this.customFieldType = this.constantService.getCustomTypes();
  }

  async ngOnInit() {
    
  }
  copyToClipboardModern(text) {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch(err => {
        console.error('Unable to copy text to clipboard', err);
      });
  }


  ngOnChanges(changes: SimpleChanges): void {
    console.log(new Date().toLocaleTimeString(), 'changes', this.currentValue)
    if(Array.isArray(this.currentValue)){this.items = [...this.currentValue]}else if(typeof this.currentValue == 'object'){this.items = [{...this.currentValue}]}
    console.log(new Date().toLocaleTimeString(), 'changes', this.currentValue)
    this.validateNumber()
  }

  addNumber() {
    this.items.push({mobile_number: '', country_code: null})
    this.validateNumber()
  }

  deleteNumber(index){
    console.log('item deletion', index)
    this.items.splice(index, 1)
    this.onValueUpdate.emit(this.items);
  }

  onUpdate(index, item){
    console.log('updated, ', this.items, index, item)
    if(item){
      this.items.splice(index, 1, item)
    }
    else{
      this.items.splice(index, 1)
    }
    this.onValueUpdate.emit(this.items);
    this.validateNumber()
  }

  trackByFn(index){
    return index;
  }

  makePrimary(index){
    console.log('make primary', index)
    const delItem = this.items.splice(index, 1);
    this.items = [delItem, ...this.items]
    this.onValueUpdate.emit(this.items)
  }

  validateNumber(){
    this.showMore = true; 
    console.log("items",this.items)
    if ((Object.keys(this.items).length === 0 && this.items.constructor === Object) || (Array.isArray(this.items) && this.items.length === 1 && Object.keys(this.items[0]).length === 0)) {
      this.showMore = false;
      return; 
    }
    const hasNullCountryCode = this.items?.some(value => value.country_code === null);
    if (hasNullCountryCode) {
      this.showMore = false;
    }
  }

}
