import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BasicUtils } from "app/services/basicUtils.service";
import { MediaIcons } from "../../../Enums/media-icons";
import { CommonUtils } from "../../services/CommonUtils.service";
import { HttpTransferService } from "./../../services/httpTransfer.service";
declare var $: any;
@Component({
  selector: "app-attachment",
  templateUrl: "./attachment.component.html",
  styleUrls: ["./attachment.component.css"],
})
export class AttachmentComponent implements OnInit {

  @Input() dashId: string;
  @Input() leadId: string;

  images: any[] = [];
  videos: any[] = [];
  audios: any[] = [];
  files: any[] = [];
  imgIndex: number = 0;
  videoIndex: number = 0
  audioIndex: number = 0
  showLoader: boolean=false
  mediaIcons = MediaIcons;
  previewObj: any = {};
  modalServices: any;
  modalRef: any;
  mediaArr: any[]=[];

  constructor(
    private httpTransfer: HttpTransferService,
    private modalService: NgbModal,
    private  commonUtils: CommonUtils,
    private basicUtils:BasicUtils,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.showLoader=true;
    const dataObj = {
      item_id: [this.leadId],
      dashboard_id:[this.dashId]
    }
    this.httpTransfer.getDashboardMedia(dataObj).subscribe((res : any) => {
      if(res.status === 200) {
        res.result.media_files.forEach((result)=> {          
          switch(result.type) {
            case 'IMAGE':
              let imgType = result?.file_path?.split('.').pop();
              result.fileType=imgType
              this.images.push(result);
              break;

            case 'VIDEO':
              let videoType = result?.file_path?.split('.').pop();
              result.fileType=videoType
              this.videos.push(result);
              break;

              case 'AUDIO':
                let audioType = result?.file_path?.split('.').pop();
                result.fileType=audioType
                this.audios.push(result);
                break;

            default :
              let fileType = result?.file_path?.split('.').pop();
              result.fileType=fileType
              this.files.push(result);
              break;

          }
        })
        setTimeout(() => {
          this.showLoader=false
          this.cdr.detectChanges();
        }, 5);
         
      }
      this.cdr.detectChanges();
    })

  }

  getFileTypeImgPath(fileType: string) {
    switch (fileType) {
      case '.pdf':
        //return 'assets/images/pdfImg.jpeg';
        return '/assets/1x/pdf.png'

      case '.xlsx':
        //return 'assets/1x/doc_logo.png';
        //return 'assets/images/xlsImg.png';
        return '/assets/1x/xls.png'

      case '.xml':
        return 'assets/images/xmlImg.png';

      case '.xls':
        //return 'assets/images/xlsImg.png';
        return '/assets/1x/xls.png'

      case '.csv':
        return 'assets/images/csvImg.png';

      case '.html':
        return 'assets/images/htmlImg.png';

      case '.json':
        return 'assets/images/jsonImg.png';

      case '.ods':
        return 'assets/images/odsImg.png';

      case '.ppt':
        return 'assets/images/pptImg.png';

      case '.svg':
        return 'assets/images/svgImg.png';

      case '.txt':
        return 'assets/images/txtImg.png';

      case '.doc' || '.docx':
        return 'assets/images/wordImg.png';

      case '.rar':
        return 'assets/images/zipImg.png';

      case '.zip':
        return 'assets/1x/zip.png';

      case '.rtl':
        return 'assets/images/rtlImg.png';

      default:
        return 'assets/1x/doc.png'
    }
  }

  downloadFile(dataurl, filename) {
    let anchorTag = document.createElement("a");
    anchorTag.href = dataurl;
    anchorTag.setAttribute("target", '_blank');
    anchorTag.setAttribute("download", filename);
    anchorTag.click();
  }

  openModalForPreview(modalContent, index: number, type?) {    
    this.imgIndex = index;    
    if(type == 'IMAGE'){
      this.mediaArr = []
      this.images.forEach(item=>{
        let tempObj={}
        tempObj['type'] = 'MultipleImage'
        tempObj['name'] =  item?.file_name +"." + item.fileType
        tempObj['url']  =  item?.url
        tempObj['path'] =  item?.path
        this.mediaArr.push(tempObj)
      })
    }
    if(type == 'VIDEO'){
      this.mediaArr = []
      this.videos.forEach(item=>{
        let tempObj={}
        tempObj['type'] = 'MultipleVideo'
        tempObj['name'] =  item?.file_name +"." + item.fileType
        tempObj['url']  =  item?.url
        tempObj['path'] =  item?.path
        this.mediaArr.push(tempObj)
      })
    }
    if(type == 'AUDIO'){
      this.mediaArr = []
      this.audios.forEach(item=>{
        let tempObj={}
        tempObj['type'] = 'MultipleVideo'
        tempObj['name'] =  item?.file_name +"." + item.fileType
        tempObj['url']  =  item?.url
        tempObj['path'] =  item?.path
        this.mediaArr.push(tempObj)
      })
    }
    if(type == 'FILE' || type == 'DOCUMENT'){
      this.mediaArr = []   
      this.files.forEach(item=>{
        let tempObj={}
        tempObj['type'] = 'MultipleFile'
        tempObj['name'] =  item?.file_name +"." + item.fileType
        tempObj['url']  =  item?.url
        tempObj['path'] =  item?.path
        this.mediaArr.push(tempObj)
      })
    }
    
    
    this.modalRef = this.modalService.open(modalContent, { size: 'lg' , windowClass: 'file preview-modal'}) 
    this.basicUtils.storeModlRef(this.modalRef)
  }

  increaseOrDecreaseImgIndex(inIncriment: boolean) {
    if(this.imgIndex == 0 && !inIncriment) {
      this.imgIndex = 0;
    } else if(this.imgIndex !== 0 && !inIncriment){
      this.imgIndex -= 1;
    } else if(this.imgIndex == (this.images.length - 1) && inIncriment) {
      this.imgIndex  = this.images.length - 1;
    } else {
      this.imgIndex += 1
    }    
  }

  increaseOrDecreaseVideoIndex(inIncriment: boolean) {
    if(this.videoIndex == 0 && !inIncriment) {
      this.videoIndex = 0;
    } else if(this.videoIndex !== 0 && !inIncriment){
      this.videoIndex -= 1;
    } else if(this.videoIndex == (this.videos.length - 1) && inIncriment) {
      this.videoIndex  = this.videos.length - 1;
    } else {
      this.videoIndex += 1
    }
  }

  setDocumentUrl(item,index) {
    this.commonUtils.downloadMediaURL(item,index)
    this.cdr.detectChanges();
  }
}
