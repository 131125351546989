<div *ngIf="isThreadView;else (selectedEmail && defaultRender) " style="height: 100%;">
   <div *ngIf="selectedEmail?.smtp_thread_id && dataToSend?.length; else noData" style="height: 100%;">
      <app-expand-thread-view *ngIf="dataToSend.length" [selectedEmailThreadViewDisplay]="dataToSend" [sprintArr]="sprintArr" [selectedEmailObj]="selectedEmailObj" [leadInfo]="leadInfo" [dashId]="dashId"></app-expand-thread-view>
   </div>
   <ng-template #noData>
      No comments avaialable
   </ng-template>
</div>
<ng-template #defaultRender class=" scrollerContainer " id="expandedEmailView">
   <div class=" row subjectSection p-2 m-2 d-flex flex-row justify-content-between align-items-center">
      <div class="col-10 left-arrow-subject d-flex flex-row align-items-center" style="gap: 10px;">
         <span *ngIf="!isFullyExpanded">
            <img (click)="backToAllEmails.emit()" class="left-arrow cursorPointer mx-auto my-auto imageSize" src="../../../assets/1x/newdash-arrow.svg">
         </span>            
         <div class="replyMailSubject" style="width: 380px;display: inline-block;
         overflow: hidden;
         text-overflow: ellipsis;">{{selectedEmail?.subject | titlecase}}</div>
      </div>
      <div class="col-2 replyEmail reply-3-dots">
         <span *ngIf=" usersEmailsAllowedToReply?.length"
            class="cursorPointer"
            placement="auto" 
            [ngbPopover]="newEmail" 
            #popEmail2="ngbPopover"
            container="body" 
            [autoClose]="'outside'"
            (click)="isFullScreen=false;$event.stopPropagation(); isReplyAll=false;initializeSummerNote(selectedEmail?.body?.html ? selectedEmail?.body?.backQuoteHtmlReply : selectedEmail?.body?.backQuoteTextReply )">{{'email.reply' | translate}}</span>
            <span class="cursorPointer" [ngbPopover]="newEmail" #popEmailR3="ngbPopover" container="body" [autoClose]="'outside'" [outsideClick]="'outside'"placement="auto" ></span>
         <span *ngIf=" usersEmailsAllowedToReply?.length">
            <img 
            [ngbPopover]="replyAllPopover"  
            #replyAllPop="ngbPopover"
            container="body" placement="bottom" 
            [outsideClick]="'outside'" aria-hidden="true" 
            popoverClass="replyAllPopover"
            triggers="manual"
            [autoClose]="'outside'"
            (click)="replyAllPop.open()"
            class="right-3dots ml-3 cursorPointer imageSize" src="../../../assets/1x/vertdots.svg">
            <ng-template #replyAllPopover>
               <span class="cursorPointer"                                                
               (click)="$event.stopPropagation();popEmailR3.open();replyAllPop.close(); isReplyAll=true;initializeSummerNote(selectedEmail?.body?.html ? selectedEmail?.body?.backQuoteHtmlReply : selectedEmail?.body?.backQuoteTextReply )">{{'email.replyAll' | translate}}</span>
            </ng-template>
         </span>
         
      </div>
   </div>

   <ng-container *ngIf="isFullyExpanded; else notFullyExpanded">
      <div class="d-flex flex-row address-block ml-2">
         <div class="mr-1 pr-1 mt-1">
            <ngx-avatars class="mr-1" [name]="selectedEmail.from?.address" [src]="undefined" size="25" initialsSize="2" ></ngx-avatars>
         </div>
         <div class="d-flex flex-column">
            <span class="from-address">{{selectedEmail.from?.address}}</span>
            <div class="to-address-block">
               <span class="to-address">To: {{selectedEmail?.to[0]?.address}}</span>
               <img class="cursorPointer imageSize"
               [ngbPopover]="toFromEmailDropDown"  
               #dropDownEmail="ngbPopover"
               container="body" placement="bottom" 
               [outsideClick]="'outside'" aria-hidden="true" 
               popoverClass="toFromEmailDropDown"
               triggers="manual"
               [autoClose]="'outside'"
               (click)="dropDownEmail.open();$event.stopPropagation();"
               class="right-3dots ml-3 cursorPointer"
               src="../../../assets/1x/arrow-dropdown.svg">
               <ng-template #toFromEmailDropDown>
                  <app-email-dropdown-template [lastMessage]="dropDownData"></app-email-dropdown-template>
                </ng-template>

            </div>
         </div>
      </div>
   </ng-container>
   <ng-template #notFullyExpanded>
      <div class="address-block-2 mt-3 ml-4 mr-4 p-2">
         <div>
            <span class="label">{{'email.from' | translate}}</span> 
            <span>: {{selectedEmail?.addresses?.from}}</span>
         </div>
         <div *ngIf="selectedEmail.addresses?.to">
            <span class="label">{{'email.to' | translate}}</span>
            <span>: {{selectedEmail?.addresses?.to}}</span>
         </div>
         <div *ngIf="selectedEmail.addresses?.cc">
            <span class="label">{{'email.cc' | translate}}</span>
            <span>: {{selectedEmail?.addresses?.cc}}</span>
         </div>
         <div *ngIf="selectedEmail.addresses?.bcc">
            <span class="label">{{'email.bcc' | translate}}</span>
            <span>: {{selectedEmail?.addresses?.bcc}}</span>
         </div>
         <div>
            <span class="label">{{'email.date' | translate}}</span> 
            <span *ngIf="selectedEmail?.receivedTime">: {{selectedEmail?.receivedTime?.date}} {{selectedEmail?.receivedTime?.time}}</span>
            <span *ngIf="selectedEmail?.createdTime">: {{selectedEmail?.createdTime?.date}} {{selectedEmail?.createdTime?.time}}</span>
         </div>
      </div>
   </ng-template>

     <div class="email-body p-4 ">
          <div *ngIf="selectedEmail?.body?.html" [innerHtml]="selectedEmail?.body?.html | safehtml"></div>
          <div *ngIf="!selectedEmail?.body?.html">{{selectedEmail?.body?.text}}</div> 
 
          <div class="attachments" *ngIf="selectedEmail?.attachments?.length">
             <span class="attachment-count font-weight-bold  ">{{selectedEmail?.attachments?.length}} {{'email.attachments' | translate}}</span>
             <div class="attachment-container imgcnr mt-1 pb-0 imageSize">
                <div  class="outer"> 
                   <div *ngFor="let attachment of selectedEmail?.attachments; let i = index" class="videoFileParent d-flex justify-content-between p-1 pr-3 py-3">      
                      <ng-container>
                         <div class="video-upload d-flex flex-column">
                              <div class="icon-section">
                                  <app-dashboard-icon [name]="attachment.extension" [iconJson]="mediaIcons" size="90px"></app-dashboard-icon>                                    
                              </div>
                              <div class="media-prop">
                                  <div class="play-delete flex-column">
                                      <div class="d-flex justify-content-center" >
                                          <a type="button"   href="{{attachment?.url}}" target="_blank">
                                            <img src="../../../assets/1x/doc-download.png" class="imageSize" />
                                          </a>
                                      </div>
                                      <div class="d-flex justify-content-around">
                                          <span class="btns image-options-for-singleimage p-0"> 
                                              <img src="../../../assets/1x/doc-view.png"  (click)="openPreviewPopup(i, videoPlayerModalNested,selectedEmail?.attachments)" class="imageSize" /> 
                                          </span> 
                                      </div>
                                  </div>
                              </div>
                              {{attachment.file_name | truncate:12 | titlecase}}
                         </div>      
                      </ng-container> 
                   </div>
               </div>
             </div>
          </div>
       </div>  

</ng-template>

 <!--Email Modal-->
   <ng-template #newEmail>
      <div class="modal-md">

         <div class="emailPopover" (click)="$event.stopPropagation()" [style.width.px]="isFullScreen ? 1050 : 600">
            <div class="emailHeader">
               <ng-container *ngIf="selectedEmail;">
                  <div style="width:40%;">
                     <!-- <span class="modal-title">{{'email.replyTo' | translate}}</span> -->
                     <ng-container *ngIf="isReplyAll">{{'email.replyAll' | translate}}</ng-container>  
                     <ng-container *ngIf="!isReplyAll">{{'email.reply' | translate}}</ng-container>  
                  </div>
                  
                  <div style="width:60%;display: flex;align-items: center;justify-content: flex-end;">
                     <span style="width:5%;" (click)="onFullview()"><img src="../../../assets/1x/fullScreen.svg" style="height: 16px;width: 16px;"></span>
                     <span>
                        <ng-select style="width:250px;" 
                        [clearable]="false" 
                        (change)="changeEmail($event)"
                        [items]="usersEmailsAllowedToReply" 
                        bindLabel="value" 
                        bindValue="id" 
                        [(ngModel)]="from" 
                        placeholder="Select From">
                           <ng-template ng-option-tmp let-item="item" let-index="index">
                                 <span>{{item?.value}}</span>
                              </ng-template>
                        </ng-select>
                     </span>
                     <!-- <img src="/assets/1x/flip.svg" style="margin-right:5px;height:12px;"> -->
                     <i class="fa fa-close cursorPointer ml-2" (click)="closeAllPopups()"></i>
                  </div>
               </ng-container>           
            </div>
            <div class="emailPopBody" (click)="$event.stopPropagation()">
               <app-send-email 
               [dashId]="dashId" 
               [sprintArr]="sprintArr" 
               [isReplyAll]="isReplyAll" 
               [replyingEmail]="selectedEmail" 
               [leadInfo]="leadInfo" 
               [from]="fromEmailObj"  
               (closeModal)="closeAllPopups();from='';" 
               [emailSignatureForEditor]="emailSignatureForEditor"
               (isFullScreenEmit)="isFullScreen=true"
               [embeddedImageAttachments]="emailEditorEmbeddedAttachments"></app-send-email>
            </div>
         </div>
      </div>
   </ng-template>
   
   <ng-template #videoPlayerModalNested let-modal>
      <div class="previewBodyModal" style="height: 100%;max-width: 100%;position: relative;">
        <i class="fa fa-close bg-white previewCross" (click)="modal.dismiss()"></i>
        <app-file-preview [mediaArr]="selectedEmail?.attachments" [index]="currentIndex" [type]="'ITEM'"></app-file-preview>
      </div>
   </ng-template>
   