export const editorIcons ={
    "bold":"assets/1x/text-editor-icons/tabler-icon-bold.svg",
    "underline":"assets/1x/text-editor-icons/tabler-icon-underline.svg",
    "italic":"assets/1x/text-editor-icons/tabler-icon-italic.svg",
    "alignCenter":"assets/1x/text-editor-icons/tabler-icon-align-center.svg",
    "alignRight":"assets/1x/text-editor-icons/tabler-icon-align-right.svg",
    "alignLeft":"assets/1x/text-editor-icons/tabler-icon-align-left.svg",
    "strikethrough":"assets/1x/text-editor-icons/tabler-icon-strikethrough.svg",
    "subscript":"assets/1x/text-editor-icons/tabler-icon-subscript.svg",
    "superscript":"assets/1x/text-editor-icons/tabler-icon-superscript.svg",
    "eraser":"assets/1x/text-editor-icons/tabler-icon-eraser.svg",
    "upCirecleChecvron":"assets/1x/text-editor-icons/tabler-icon-circle-chevron-up.svg",
    "downCirecleChecvron":"assets/1x/text-editor-icons/tabler-icon-circle-chevron-down.svg",
    "colorFormate":"assets/1x/text-editor-icons/formatting.svg",
}