import { Pipe, PipeTransform, ElementRef } from '@angular/core';

@Pipe({
  name: 'gradient'
})
export class GradientPipe implements PipeTransform {
  transform(elemetRef: HTMLElement | null): any {
    if (!elemetRef) {
        return {}; // Return an empty style object if pinElement is null
      }
    const gradientContainer = document.querySelector('#gradientContainer') as HTMLElement;
    if (!gradientContainer) {
        console.error('Gradient container not found');
        return {};
      }
    // Get the computed gradient style of the container
    const gradientStyle = getComputedStyle(gradientContainer).backgroundImage;
    // Calculate the width of the container and the bottom bar
    const containerWidth = gradientContainer.offsetWidth;
    const bottomBarWidth = elemetRef?.offsetWidth;
    // Calculate the position of the bottom bar in terms of percentage
    const startPercent = (elemetRef?.offsetLeft / containerWidth) * 100;
    const endPercent = ((elemetRef?.offsetLeft + bottomBarWidth) / containerWidth) * 100;
    let style = {
        'background-image': gradientStyle,
        'background-size': `${containerWidth}px 100%`,
         'background-position': `${startPercent}% 0`,
       // 'background-position': `${endPercent}% 0`,
       // 'background-repeat': 'no-repeat',
      };
      console.log(style)
      return style;
  }

}
