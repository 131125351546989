<div id="mainDiv" class="vks mediaFilesComponent">
  <div class="tab-content" id="myTabContent" style="height: calc(100% - 40px); overflow-y: auto; padding-bottom: 30px;">
    <div class="m-1" *ngIf="showLoader">
      <app-shimmer-loader [type]="'CardField'" [cardCount]="15" [header]="false" [count]="1" [width]="'98px'" [height]="98" [radius]="10" [rowGap]="14" [columnGap]="12"></app-shimmer-loader>
    </div>
    <div class="tab-pane fade show active" id="image" role="tabpanel" aria-labelledby="image-tab">
      <div class="editmediacrollable leadfullpagecomtscroll">
          <ng-container>
            <div class="col-md-12 text-center" >
              <div *ngIf="images.length==0 && !showLoader" style="text-align:center;">
                  <div class="empty">
                  <img class="emptyImg" src="../../../assets/img/icons/empty.svg" />
                  <h5 class="mt-5">{{'media.noImagesMessage' | translate}}</h5>
                </div>
              </div>
            </div>
        </ng-container>
        <div class="imgcnr" *ngFor="let image of images; let imgIndex = index">
          <div class="attach-show-image-in-multiple-image">
            <img [src]="image?.url" alt="Image">
            <span class="fileName mt-1" >
              {{image?.file_name.replace('.'+image?.fileType,'') | truncate:6 }}{{'.'+image?.fileType}}
          </span>
            <span class="fullfileName mt-1">
              {{image?.file_name}}
            </span>
            <span class="mediaOptions">
              <span class="openMedia" (click)="openModalForPreview(deleteModal, imgIndex, image.type)">
                <img style="height:20px ;" src="../../../../assets/1x/expand.svg" />
              </span>
              <span class="openMedia" (click)="setDocumentUrl(image,imgIndex)">
                <img style="height:30px ;" src="../../../../assets/1x/doc-download.png" />
              </span>
            </span>
          </div>
            <!-- <div class="video-cnr">
              <img [src]="image" (click)="openImgModal(imgModalContent, imgIndex)">
              <span class="dltin"><img (click)="removeFile(i,item.type)" src="../../../assets/1x/image-delete-ico.png" class="deleteico"></span>
            </div> -->
        </div>
      </div>
    </div>

    <div class="tab-pane fade" id="video" role="tabpanel" aria-labelledby="video-tab">
      <div class="editmediacrollable leadfullpagecomtscroll">
            <ng-container>
              <div class="col-md-12 text-center" >
                <div *ngIf="videos.length==0 && !showLoader" style="text-align:center;">
                    <div class="empty">
                    <img class="emptyImg" src="../../../assets/img/icons/empty.svg" />
                    <h5 class="mt-5">{{'media.noVideosMessage' | translate}}</h5>
                  </div>
                </div>
              </div>
          </ng-container>
          <div class="imgcnr" *ngFor="let video of videos; let videoIndex = index">
            <div class="attach-show-image-in-multiple-image">
              <video controls>
                <source [src]="video?.url" type="video/mp4">
              </video>
              <span class="fileName mt-1" >
                {{video?.file_name.replace('.'+video?.fileType,'') | truncate:6 }}{{'.'+video?.fileType}}
            </span>
              <span class="fullfileName mt-1">
                {{video?.file_name}}
              </span>
              <!-- <span class="downloadOrSearch" (click)="openModalForPreview(deleteModal, videoIndex, video.type)">
                <i class="fa fa-search"></i>
              </span> -->

              <span class="mediaOptions">
                <span class="openMedia" (click)="openModalForPreview(deleteModal, videoIndex, video.type)">
                  <img style="height:20px ;" src="../../../../assets/1x/expand.svg" />
                </span>
                <span class="openMedia" (click)="setDocumentUrl(video,videoIndex)">
                  <img style="height:30px ;" src="../../../../assets/1x/doc-download.png" />
                </span>
              </span>
            </div>
            <!-- <video [src]="video" controls></video>
            <span class="downloadOrSearch" (click)="openVideoModal(videoModalContent, videoIndex)">
              <i class="fa fa-search"></i>
            </span> -->
          </div>
      </div>
    </div>

    <div class="tab-pane fade" id="audio" role="tabpanel" aria-labelledby="video-tab">
      <div class="editmediacrollable leadfullpagecomtscroll">
            <ng-container>
              <div class="col-md-12 text-center" >
                <div *ngIf="audios.length==0 && !showLoader" style="text-align:center;">
                    <div class="empty">
                    <img class="emptyImg" src="../../../assets/img/icons/empty.svg" />
                    <h5 class="mt-5">{{'media.noAudioMessage' | translate}}</h5>
                  </div>
                </div>
              </div>
          </ng-container>
          <div *ngFor="let audio of audios; let audioIndex = index" style="margin-left: 3rem;">
            <div class="audioCard">
              <img class="audioImage" src="assets/1x/audio.svg" alt="video">
              <audio controls class="audioPlayer">
                <source [src]="audio?.url" type="audio/mp3">
              </audio>
              <span class="fileName" class="audioNameDisplay">
                {{audio?.file_name | truncate:25}}
              </span>
              <span class="downloadOrSearch" (click)="openModalForPreview(deleteModal, audioIndex, audio.type)">
                <i class="fa fa-search"></i>
              </span>
            </div>
            <!-- <video [src]="video" controls></video>
            <span class="downloadOrSearch" (click)="openVideoModal(videoModalContent, videoIndex)">
              <i class="fa fa-search"></i>
            </span> -->
          </div>
      </div>
    </div>

    <div class="tab-pane fade" id="files" role="tabpanel" aria-labelledby="files-tab">
      <div class="editmediacrollable leadfullpagecomtscroll">
          <ng-container>
            <div class="col-md-12 text-center" >
              <div *ngIf="files.length==0 && !showLoader" style="text-align:center;">
                  <div class="empty">
                  <img class="emptyImg" src="../../../assets/img/icons/empty.svg" />
                  <h5 class="mt-5">{{'media.noFilesMessage' | translate}}</h5>
                </div>
              </div>
            </div>
        </ng-container>
        <div class="imgcnr" style="text-align: center" *ngFor="let file of files; let fileIndex = index">
          <!-- <img [src]="getFileTypeImgPath(file.fileType)" (click)="downloadFile(file.url, 'file')"> -->
          <span class="attach-show-image-in-multiple-image">
            <app-dashboard-icon [name]="(mediaIcons.hasOwnProperty(file?.fileType)) ? file?.fileType : 'txt'" [iconJson]="mediaIcons" size="95"></app-dashboard-icon>
            <span class="fileName mt-1" >
                {{file?.file_name.replace('.'+file?.fileType,'') | truncate:6 }}{{'.'+file?.fileType}}
            </span>
            <span class="fullfileName mt-1">
              {{file?.file_name}}
            </span>
            <!-- <span class="downloadOrSearch" (click)="openModalForPreview(deleteModal, fileIndex, file.type)">
              <i class="fa fa-search"></i>
            </span> -->
            <span class="mediaOptions">
              <span class="openMedia" (click)="openModalForPreview(deleteModal, fileIndex, file.type)">
                <img style="height:20px ;" src="../../../../assets/1x/expand.svg" />
              </span>
              <span class="openMedia" (click)="setDocumentUrl(file,fileIndex)">
                <img style="height:30px ;" src="../../../../assets/1x/doc-download.png" />
              </span>
            </span>

          </span>
          <!-- <div class="video-cnr">
            <img [src]="getFileTypeImgPath(file.fileType)" (click)="downloadFile(file.url, 'file')">
            <span class="dltin"><img (click)="removeFile(i,item.type)" src="../../../assets/1x/image-delete-ico.png" class="deleteico"></span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="position-relative bottomMediaTabs row mx-0 w-100">
      <ul class="nav nav-tabs h-100 p-0" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="image-tab" data-toggle="tab" href="#image" role="tab" aria-controls="image"
            aria-selected="true">{{'media.images' | translate}} </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="video-tab" data-toggle="tab" href="#video" role="tab" aria-controls="video"
            aria-selected="false">{{'media.videos' | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="files-tab" data-toggle="tab" href="#files" role="tab" aria-controls="files"
            aria-selected="false">{{'media.files' | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="files-tab" data-toggle="tab" href="#audio" role="tab" aria-controls="files"
            aria-selected="false">{{'media.audio' | translate}}</a>
        </li>
      </ul>
  </div>
</div>


<ng-template #deleteModal let-modal>
  <div class="previewBodyModal" style="height: 100%;max-width: 100%;">
    <i class="fa fa-close bg-white previewCross" (click)="modal.dismiss()"></i>
    <app-file-preview [mediaArr]="mediaArr" [index]="imgIndex" [type]="'MEDIA'" [dashId]="dashId" [typeId]="leadId"></app-file-preview>
  </div>
</ng-template>