<div>
  <div *ngIf="gridView" class="pb-1 mb-2" style="display: flex;justify-content: space-between;height: 35px;border-bottom: 1px solid gray;">
    <div class="f-xl-m" style="line-height: 24px;display: flex;justify-content: center;align-items: center;">Manage Email</div>
    <div style="display: flex;justify-content: center;align-items: center;">
      <div style="padding-right: 4px">
        <span style="float: right;border-radius: 4px;width: 77px;display: flex;justify-content: center;color: #3269CC;" role="button" (click)="addNumber()"><span class="iconBox">Add more</span></span>
      </div>
      <div style="line-height: 24px;display: flex;justify-content: center;align-items: center;" (click)="close.emit(true)"><button type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button></div>
    </div>
  </div>
  <div class="d-flex all-multiple-mobiles-list" *ngFor="let item of items; let i = index">
    <div style="flex-grow: 1;">
      <app-include-country-selection [fieldType]="'Mobile'" [disableDeletion]="gridView" [selectedLeadObj]="leadObj" [dashId]="dashId" [editable]="editable" [countryJson]="countryJson" [currenValue]="item" (onValueUpdate)="onUpdate(i, $event)"></app-include-country-selection>
    </div>
    <div *ngIf="i == 0 && item?.country_code"  class="contract-primary-mobile"><span style="padding-left: 3px;">Primary</span></div>
    <div *ngIf="gridView" class="showMoreOption" (click)="optionSpan?.open()"><button class="btn btn-outline-light px-2 py-1 btn-sm text-dark"><i class="fa fa-ellipsis-v"></i></button>
    </div>
    <span  #optionSpan="ngbPopover" [ngbPopover]="options" [autoClose]="'outside'" popoverClass="modifiedPopover" container="body"></span>
    <ng-template #options>
      <div class="d-flex flex-column" style="min-width: 8rem;">
        <div *ngIf="i !== 0" class="rounded text-nowrap"><button class="btn btn-light bg-white w-100 btn-sm" style="text-align: left;" (click)="makePrimary(i)">Make Primary</button></div>
        <div class="rounded text-nowrap"><button class="btn btn-light bg-white w-100 btn-sm" style="text-align: left;" (click)="deletetion.open();">Delete</button></div>
      </div>
    </ng-template>
    <span [ngbPopover]="confirmationpopup" #deletetion="ngbPopover" [autoClose]="true" container="body" trigger="manual"></span>
    <ng-template #confirmationpopup>
      <div>
        <div class="p-2">Are you sure you want to remove mobile.</div>
        <div style="justify-content: center;display: flex;align-items: center;column-gap: 20px;">
          <span class="btn btn-success" style="border-radius: 10px;" (click)="deleteNumber(i)">{{'multiSelect.yes' | translate}}</span>
          <span class="btn btn-danger" style="border-radius: 10px;" (click)="deletetion?.close()" >{{'multiSelect.no' | translate}}</span> 
        </div>
      </div>
    </ng-template>
  </div>
  <div *ngIf="showMore && !gridView" class="fieldValueBox" style="margin-top: 5px;">
    <div *ngIf="!gridView" class="f-l-b" style="float: right;border-radius: 4px;width: 70px;justify-content: center;color: gray;line-height: 24px;" role="button" (click)="addNumber()"><span class="iconBox" style="width:60px !important;padding-left: 3px;">Add more</span></div>
  </div>
</div>