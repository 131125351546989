import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from '../../../services/toaster.service';


@Component({
  selector: 'app-lead-tags',
  templateUrl: './lead-tags.component.html',
  styleUrls: ['./lead-tags.component.scss']
})
export class LeadTagsComponent implements OnInit {
  @Input() dashboardTags:any[];
  @Input() dashboardTagsJson:any;
  @Input() leadInfo:any;
  @Input() dashId:number;
  @Input() selectedTagsId = [];
  @Input() isGrid: boolean = false
  @Input() bulkSelectionFlag:boolean;
  @Input() showManage : boolean = true;
  @Output() updateLead = new EventEmitter<any>();
  @Output() showCreateTag = new EventEmitter<boolean>();
  @Output() removedTag = new EventEmitter<any>();
  @Output() addedTag = new EventEmitter<any>();
  dashboardUserRole:any[]=[];
  tagIdList: any[] = [];
  searchListTags: any[];
  searchedDashTags: any[]=[];
  searchStatus: boolean=false;
  searchText: string;
  selectedTagCount = 0
  @Input() objFilterForMultiselect: any;
  dashboardJsonSub: any;
  itemAttributesObj: any;
  @ViewChild('container', { static: false }) container!: ElementRef;
  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
  selectedIndex: number=0;

  constructor(private httpTransfer: HttpTransferService,private toaster:ToasterService,
    private commonUtils: CommonUtils, private dashboardUtils: DashboardUtilsService) {
      this.dashboardJsonSub = this.dashboardUtils.getUpdatedDashboardJson().subscribe((res : any) => {
        let tagsJson=res[this.dashId]?.TAG_MAP || {};
        this.dashboardTags= Object.values(tagsJson)
      });
   }

  async ngOnInit() {
    const dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.dashId])
    this.dashboardUserRole = dashboardInfo[this.dashId]?.DASHBOARD_INFO?.role || [];
    let existingCustomFields = await this.dashboardUtils.getDashboardExistingCustomFormIfPresent(this.dashId);
    if(existingCustomFields?.length)this.itemAttributesObj = this.commonUtils.handleExistingItemAttributes(existingCustomFields)
    if(this.dashboardTagsJson) this.dashboardTags= Object.values(dashboardInfo[this.dashId].TAG_MAP || {})
      this.dashboardTags?.forEach(element=>{
        element['selected'] = false;
      })
     if(!this.bulkSelectionFlag && this.leadInfo?.length) {
      if(this.leadInfo[0].tag_id)
        this.dashboardTags.forEach(tag=>{
          if(this.leadInfo[0].tag_id && this.leadInfo[0].tag_id.includes(tag.tag_id )){
            tag['selected'] = true;
          }
        })
    }
    if(this.selectedTagsId.length>0) {
      this.dashboardTags.forEach(tag=>{
        if(this.selectedTagsId.includes(tag.tag_id)) {
          tag['selected'] = true;
        }
      });
    }
    this.searchListTags = [...this.dashboardTags];
    this.filterTags(null)
    this.countSelectedTags();
  }
  
  
  ngAfterViewInit() {
    this.searchInput.nativeElement.focus();
  } 
  countSelectedTags() {
    this.dashboardTags.forEach(element=>{
      if(element['selected'] == true){
        this.selectedTagCount++;
      }
    })
  }

  onSearchTags() {
    // this.getTagsToAdd();
  }

  filterTags(val) {
    this.searchedDashTags = this.searchListTags.filter(
      (tag) => tag.name.toLowerCase().indexOf(val?.toLowerCase()) !== -1 || !val
    );
    this.selectedIndex=0;
  }

  getTagsToAdd() {
      this.searchListTags.forEach((item) => {
        if (item.selected) {
          this.searchListTags.splice(
            this.searchListTags.findIndex((element) => element.selected == true),
            1
          );
        }
      });
  }

  async setSelectedTagsClicked(tagId) {
    for (var i = 0; i < this.dashboardTags.length; ++i) {
      if (this.dashboardTags[i].tag_id == tagId) {
        if(this.dashboardTags[i].selected) {
          this.dashboardTags[i].selected = false
          this.selectedTagCount--;
          if(this.isGrid) this.removedTag.emit(this.dashboardTags[i])
        } else {
          if(this.selectedTagCount < 3){
            this.dashboardTags[i].selected = true;
            this.selectedTagCount++;
            if(this.isGrid) this.addedTag.emit(this.dashboardTags[i])
          }
        }
      }
    }
    for (var i = 0; i < this.dashboardTags.length; ++i) {
      if (this.dashboardTags[i].selected &&  !this.tagIdList.includes(this.dashboardTags[i].tag_id)) {
        this.tagIdList.push(this.dashboardTags[i].tag_id);
      }
      if (!this.dashboardTags[i].selected &&  this.tagIdList.includes(this.dashboardTags[i].tag_id)) {
        let index=this.tagIdList.indexOf(this.dashboardTags[i].tag_id)
        this.tagIdList.splice(index,1)
      }
    }
    if(this.leadInfo && !this.isGrid) {
      if(this.leadInfo.length>0) {

        if(this.leadInfo.length>1){

          let inputJson={}
       
          inputJson['filter_properties']=this.objFilterForMultiselect
          inputJson['update_json']={
            "tag_id": this.tagIdList
          }
          if(this.selectedTagCount <= 3)
          this.httpTransfer.updateMultipleDashboard(this.dashId,inputJson).subscribe()
        }
        else{
          if(this.selectedTagCount <= 3)
          await this.updateLeadForTagData(this.leadInfo[0]._id, tagId, this.leadInfo[0].bucket_id);
        }

      } else {
        this.toaster.Errors("Please Select Items");
      }
    } else {
      this.updateLead.emit(this.tagIdList);
    }
  }

  async updateLeadForTagData(leadId, tagId, bucketId) {
    var inputJson = {};
    this.tagIdList = [];
    // this.tagIdList = this.dashboardTags.map(tag => {if(tag.selected)return tag.tag_id})
      for (var i = 0; i < this.dashboardTags.length; ++i) {
        if (this.dashboardTags[i].selected) {
          this.tagIdList.push(this.dashboardTags[i].tag_id);
        }
      }
    inputJson["tag_id"] = this.tagIdList;
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(inputJson, this.dashId, leadId)
      .subscribe((res : any) => {
        if (res.status == 200) {
          this.updateLead.emit({'leadId':leadId,'bucketId':bucketId,"tag_id": this.tagIdList});
          this.searchStatus = false; 
        }
      });
  }
  openCreateTags() {
    this.showCreateTag.emit(true);
  }
  ngOnDestroy() {
    this.dashboardJsonSub?.unsubscribe();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Tab':
        event.preventDefault()
        if(this.selectedIndex == -1){
          this.selectedIndex=0;
        }else{
          this.selectedIndex=-1;
        }
        break;
      case 'ArrowUp':
        if (this.selectedIndex >0) {
          this.selectedIndex--;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
        }
        else if(this.selectedIndex==-1){
          this.selectedIndex=0
        }
        break;
      case 'ArrowDown':
        if (this.selectedIndex < this.searchedDashTags.length - 1) {
          this.selectedIndex++;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
        }
        break;
      case 'Enter':
        if(this.selectedIndex==-1){
          this.openCreateTags()
        }
        else if(0<=this.selectedIndex && this.selectedIndex<this.searchedDashTags?.length){
          this.setSelectedTagsClicked(this.searchedDashTags[this.selectedIndex]?.tag_id)
        }
      }
    }
}
