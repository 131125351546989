import Quill from 'quill';


// create inline custom blot
const InlineEmbed = Quill.import('blots/embed') as any;
class CustomBlot extends InlineEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute('data-component', value.component); 
    node.setAttribute('data-row',value.dataInfo); 
    node.setAttribute('data-block-id',value.dataBlockId);
    node.setAttribute('contenteditable',false)
    node.classList.add('qlc-task-renderer');
    let data=JSON.parse(value.dataInfo || '{}');
    if(data.id)node.setAttribute('data-id',data.id); 
    if(data.type)node.setAttribute('data-type',data.type);
    return node;
  }
  static value(node) {
    let data={
      component: node.getAttribute('data-component'),
      dataInfo: node.getAttribute('data-row'), // Retrieve data-id attribute
      dataBlockId:node.getAttribute('data-block-id'),
    };
    return data
  }
}
CustomBlot.blotName = 'qlCustomComponent';
CustomBlot.tagName = 'span';


// create block custom blot
const BlockEmbed = Quill.import('blots/block/embed') as any;
class BlockCustomBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute('data-component', value.component); 
    node.setAttribute('data-row',value.dataInfo); 
    node.setAttribute('data-block-id',value.dataBlockId);
    node.setAttribute('contenteditable',false)
    node.classList.add('qlc-task-renderer');
    let data=JSON.parse(value.dataInfo || '{}');
    if(data.id)node.setAttribute('data-id',data.id); 
    if(data.type)node.setAttribute('data-type',data.type);
    if(value.width)node.style.width=value.width;
    if(value.height)node.style.height=value.height;
    if(data.hidePreview){
      node.setAttribute('data-preview','hide');
    }
    // resize 
    if(data.resizeable){
      node.setAttribute('data-resizeable',"true");
      node.classList.add('qlc-resize-container');
      ['qlc-left-resize-handle','qlc-right-resize-handle','qlc-bottom-resize-handle']?.forEach(data=>{
        const resizeHandle = document.createElement('div');
        resizeHandle.className = data;
        resizeHandle.classList.add('qlc-resize-handle');
        resizeHandle.appendChild(document.createElement('div'))
        node.appendChild(resizeHandle);
        this.attachResizeHandler(node, resizeHandle);
      })
    }
    return node;
  }

  static value(node) {
    let data={
      component: node.getAttribute('data-component'),
      dataInfo: node.getAttribute('data-row'), // Retrieve data-id attribute
      dataBlockId:node.getAttribute('data-block-id'),
    };
    if(node.getAttribute('data-resizeable')=="true"){
      data['width']=node.style.width;
      data['height']=node.style.height
    }
    return data
  }

  static attachResizeHandler(node, handle) {
    let isResizing = false;
    handle.addEventListener('mousedown', (e) => {
      isResizing = true;
      const initialWidth = node.offsetWidth;
      const initialHeight = node.offsetHeight;
      const initialMouseX = e.clientX;
      const initialMouseY = e.clientY;
      
      function resize(e) {
        if (isResizing) {
          document.body.style.userSelect = 'none'
          document.body.style.cursor=window.getComputedStyle(handle).cursor;
          node?.classList?.add('qlc-resizing-enable')
          const newWidth = initialWidth + (e.clientX - initialMouseX);
          const newHeight = initialHeight + (e.clientY - initialMouseY);
          if(newWidth>160){
            node.style.width = `${newWidth}px`;
            node.style.height = `${newHeight}px`;
          }
        }
      }
      function stopResize() {
        isResizing = false;
        document.body.style.userSelect = 'auto'
        document.body.style.cursor='auto'
        node?.classList?.remove('qlc-resizing-enable')
        window.removeEventListener('mousemove', resize);
        window.removeEventListener('mouseup', stopResize);
      }
      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResize);
    });
  }
}
BlockCustomBlot.blotName = 'qlBlockCustomComponent';
BlockCustomBlot.tagName = 'div';


// create or add custom class scope or parchment
const Parchment = Quill.import('parchment');
const CustomClassAttributor = new Parchment.ClassAttributor('customClass', 'qlc', {
  scope: Parchment.Scope.INLINE
});


// Create a custom table blot
const TABLE_CSS_CLASS = 'custom-table';
let  TableFormat=Quill.import('formats/table') as any;
class TableBlot extends TableFormat {
  static create(value) {
    let node = super.create();
    if (value) {
      node.setAttribute('data-id', value);
    }
    return node;
  }
  static formats(node) {
    return node.getAttribute('data-id');
  }
  format(name, value) {
    if (name === 'table') {
      this.domNode.setAttribute('data-id', value);
    } else {
      super.format(name, value);
    }
  }
}


Quill.register('formats/table', TableBlot); // register custom table blot
Quill.register(CustomBlot); // Register the embed inline blot with Quill
Quill.register(BlockCustomBlot); // Register the block type cusytom blot with Quill
Quill.register(CustomClassAttributor, true); // Register the class with Quill
