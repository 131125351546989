<!------Dashboard name and other lead information header-->
<div class="item-header">
    <!---Item pop-up header for existing item-->
    <div  class="item-header-wrapper" >
      <div class="left-container">
        <ng-container *ngIf="itemStateData?.length>=2">
          <div class="previous-item" >
            <div class="previous-item-back-btn" (click)="backToPreviousState()">
              <span *ngIf="!itemStateData[itemStateData?.length-2]?.backToboard" class="mr-1 text-overfow-hidden">
                <img  src="../../../assets/1x/newdash-arrow.svg " width="18px" height="18px"></span>
              <!-- <ng-container *ngIf="itemStateData[itemStateData?.length-2]?.backToboard">{{'Back To Board'}}</ng-container> -->
              <span class="homeTabFromItem" *ngIf="itemStateData[itemStateData?.length-2]?.backToboard"><app-dashboard-icon [name]="'home'" size="20" [iconJson]="navBarIcons"></app-dashboard-icon><span class="f-xl-m homeTextFromItem">{{'left-navbar.home' | translate}}</span> </span>
              <ng-container *ngIf="itemStateData[itemStateData?.length-2]?.tableInfo">{{itemStateData[itemStateData?.length-2]?.tableInfo?.fieldInfo?.fieldName}}</ng-container>
              <ng-container *ngIf="itemStateData[itemStateData?.length-2]?.seqId">{{'#'+itemStateData[itemStateData?.length-2]?.seqId}}</ng-container>
            </div>
          </div>
          <div class="partition-div"><div class="partition-bar"></div></div>
        </ng-container>
        <!-- start hierarchy content -->
        <div class="hierarchy-container f-xl-b">
          <ng-container *ngIf="!isPublicLead && leadObj && !tableInfo && leadObj?.item_type!=='ACTIVITY_COMMENT'">
            <!-- Folter name -->
            <ng-container *ngIf="itemConfig?.showBoardHierarchy">
              <ng-container *ngIf="dashboardFolterName">
                <div class="hierarchy-field">
                  <span><app-dashboard-icon [size]="20" [name]="'folder'" [iconJson]="itemIcons"></app-dashboard-icon></span>
                  <span class="text">{{dashboardFolterName}}</span>
                </div>
                <div class="hierarchy-arrow">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </ng-container>
              <!-- board name -->
              <ng-container *ngIf="boardInfo?.dashboard_name">
                <div class="hierarchy-field">
                  <span><img src="../../../assets/new-icon-6d6e6f-20dp/board-6d6e6f.svg" ></span>
                  <span class="text" (click)="itemStateData[itemStateData?.length-2]?.backToboard && backToboard()" [ngClass]="{'changeOnFullViewItem':itemStateData[itemStateData?.length-2]?.backToboard}">{{boardInfo?.dashboard_name}}</span>
                </div>
                <div class="hierarchy-arrow">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </ng-container>
            </ng-container>
            <!-- item  sequence  -->
            <div class="hierarchy-field">
              <span *ngIf="leadObj?.parent_id" >
                <app-dashboard-icon [size]="20" [name]="'hierarchy'" [iconJson]="itemIcons"></app-dashboard-icon>
              </span>
              <span *ngIf="leadObj?.parent_id && !isShowHierarchy" class="cursorPointer item-option" (click)="isShowHierarchy=true;leadQueryCorrespondingToLeadSeqId();"><i class="fas fa-ellipsis-h p-0"></i></span>
              <ng-container *ngIf="leadHierarachySeqArray.length>3">
                <span (click)="updateItemState(leadHierarachySeqArray[leadHierarachySeqArray?.length-1])" class="cursorPointer item-option">#{{leadHierarachySeqArray[leadHierarachySeqArray?.length-1]?.seq_id}}</span>
                <span><i class="fas fa-chevron-right"></i></span>
                <span class="d-flex">...</span> 
                <span><i class="fas fa-chevron-right"></i></span>
                <span   class="cursorPointer item-option">#{{leadHierarachySeqArray[0]?.seq_id}}</span>
              </ng-container>
              <ng-container *ngIf="leadHierarachySeqArray.length<=3">
                <ng-container *ngFor="let item of leadHierarachySeqArray.slice().reverse();let i=index">
                    <span (click)="i<(leadHierarachySeqArray?.length-1) && updateItemState(item)" class="cursorPointer item-option">#{{item?.seq_id}}</span>
                    <span *ngIf="i<leadHierarachySeqArray.length-1"><i class="fas fa-chevron-right"></i></span>
                </ng-container>
              </ng-container>
            </div>
            <!--item info  -->
            <div  class="hierarchy-field">
              <img *ngIf="!isLeadInTemplate && !isPublicLead" [ngbPopover]="itemInfoTemplate " #popItemInfoTemplate="ngbPopover"
                    placement="auto" container="body" [autoClose]="'outside'" role="button" (click)="$event.stopPropagation(); getPopOverRef(popItemInfoTemplate)"
                    src="assets/common-use-icon-20dp/info-6d6e6f.svg">
            </div>
            <ng-template #itemInfoTemplate> 
              <app-item-info [selectedDashId]="dashId" [leadInfo]="leadObj" [dashName]="boardInfo?.dashboard_name"></app-item-info>
            </ng-template> 
          </ng-container>
          <ng-container *ngIf="!isPublicLead && leadObj && !tableInfo && leadObj?.item_type=='ACTIVITY_COMMENT'">
            <div class="hierarchy-field">
              <span class="iconBox-20 boardIconBox" [ngStyle]="{'background-color': data?.color || commonUtils.getCurrentThemeColor()}"><i [class]="data?.icon" class="faicon"></i></span>
              <span class="text">{{boardInfo?.dashboard_name}}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="itemStateData?.length>=1 && tableInfo">
            <div class="hierarchy-field">
              <span><app-dashboard-icon [size]="20" [name]="tableInfo?.fieldInfo?.fieldType"></app-dashboard-icon></span>
              <span class="text">{{tableInfo?.fieldInfo?.fieldName}}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="itemConfig?.showLoader">
            <!-- loader  -->
            <div class="hierarchy-field ml-1">
              <div class="spinner-border" role="status" style="width: 15px;height: 15px;color: blue;"><span class="sr-only"></span></div>
              <span>{{'common.loading' | translate}}...</span>
            </div>
          </ng-container>    
        </div>
        <!-- End hierarchy content -->
      </div>
      <div *ngIf="!isPublicLead" class="right-container">
          <ng-container *ngIf="!tableInfo && (currentItemView!='RIGHT' || currentItemView=='RIGHT' && itemConfig?.enableShowEmptyBtn)" >
            <div class="item-editional-option">
              <span *ngIf="itemConfig?.enableShowEmptyBtn">
                <div class="form-check form-switch show-empty" (click)="showHiddenFields.showEmptyFields=!showHiddenFields.showEmptyFields">
                  {{!showHiddenFields.showEmptyFields ? 'Show more' : 'Show less'}}
                </div>
              </span>
              <ng-container *ngIf="currentItemView!='RIGHT'">
                <!-- start public icon  -->
                <span class="option-btn" [class.activeOption]="publicPopover?.isOpen()" [ngbPopover]="leadObj && !isReadOnly && itemPublicTemplate" triggers="click" placement="bottom" [autoClose]="'outside'" 
                  #publicPopover="ngbPopover" (click)="$event.stopPropagation(); getPopOverRef(publicPopover)">
                  <img [src]="leadObj?.item_type==='ITEM_PUBLIC' ? '../../../assets/common-use-icon-20dp/public-item-FE2030.svg' : '../../../assets/common-use-icon-20dp/private-item-6d6e6f.svg'" alt="public item">
                </span>
                <ng-template  #itemPublicTemplate>
                  <div style="width: 265px;">
                    <input (click)= "changeItemType(leadObj)" type="checkbox" [checked]="leadObj?.item_type==='ITEM_PUBLIC'">
                    <span><strong> {{'item.markAsPublic' | translate}} </strong>  </span>
                    <p>{{'item.publicItemCheckInfo' | translate :{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }}</p>
                  </div>
                </ng-template>
                <!-- end : public icon -->
                <!-- Start SubItem  -->
                <span class="option-btn" placement="bottom" [ngbTooltip]="subtaskUpdateName" tooltipClass="tooltipPopup" (click)="leadObj && scrollTO('sub-task'+leadId)">
                      <span class="subItm ICO"><app-dashboard-icon [size]="16"  [name]="'SubTask'"></app-dashboard-icon></span>
                      {{leadObj?.child_lead_id?.length}}
                </span>
                <!-- End subItem -->
                <!-- Start dependency  -->
                <span class="option-btn" placement="bottom" [ngbTooltip]="dependencyUpdateName" tooltipClass="tooltipPopup" (click)="leadObj && scrollTO('dependency'+leadId)">
                    <span *ngIf="leadObj?.dependencies?.depends_on?.length>0">{{leadObj?.dependencies?.depends_on?.length}}</span>
                    <span><app-dashboard-icon [size]="16"  [name]="'Dependency'"></app-dashboard-icon></span>
                    <span *ngIf="leadObj?.dependencies?.dependents?.length>0">{{leadObj?.dependencies?.dependents?.length}}</span>
                </span>
                <!-- End : dependency -->
                <!-- Start Collaborator -->
                <span class="option-btn" [class.activeOption]="collaboratorPopover?.isOpen()"  [placement]="'bottom'" containerClass="shadow" [ngbPopover]="leadObj && !isReadOnly && leadUsersTemplate" #collaboratorPopover="ngbPopover" container="body" [autoClose]="'outside'" (click)="$event.stopPropagation(); getPopOverRef(leadPopover)">
                  <span placement="bottom" [ngbTooltip]="'Collaborator'" class="ICO" tooltipClass="tooltipPopup" style="align-items: center; justify-content: center;">
                      <app-dashboard-icon [size]="16"  [name]="'Collaborator'"></app-dashboard-icon>
                  </span>
                </span>   
                <ng-template #leadUsersTemplate>
                  <app-lead-colloborators [leadObj]="leadObj" dashId={{dashId}}
                      (UpdateCount)="leadPopover.close()"></app-lead-colloborators>
                </ng-template> 
                <!-- End : Collaborator -->
              </ng-container>
            </div>
            <div class="partition-div"><div class="partition-bar"></div></div>
          </ng-container>
          <div  class="item-regular-option">
            <ng-container *ngIf="currentItemView!='RIGHT' && itemConfig?.showPreviousNextItemBtn">
              <span class="option-btn" (click)="onItemChange?.emit({changeType:'previousItem'})">
                <i class="fas fa-chevron-left"></i>
              </span>
              <span class="option-btn" (click)="onItemChange?.emit({changeType:'nextItem'})">
                <i class="fas fa-chevron-right"></i>
              </span>
            </ng-container>
            <span *ngIf="itemConfig?.enableItemViewChangeBtn" class="option-btn" [class.activeOption]="itemviewOptionPopover?.isOpen()" [ngbPopover]="itemViewSelection" #itemviewOptionPopover="ngbPopover" placement="bottom-right" container="body" [autoClose]="true"  (click)="$event.stopPropagation(); getPopOverRef(itemviewOptionPopover)" popoverClass="modal-header-popover">
              <app-dashboard-icon [size]="24" [name]="'layout'" [iconJson]="itemIcons"></app-dashboard-icon>
            </span>
            <ng-template #itemViewSelection>
              <div class="item-view-options f-l-r">
                <div class="view-type" (click)="onitemViewTypeChange?.emit('RIGHT')"><span style="width: 100%;">Side view</span><span *ngIf="currentItemView=='RIGHT'"  style="flex: 1;"><i class="fas fa-check check p-0"></i></span></div>
                <div class="view-type" (click)="onitemViewTypeChange?.emit('EMBEDDED')"><span style="width: 100%;">item pop-up view</span><span *ngIf="currentItemView=='EMBEDDED'" style="flex: 1;"><i class="fas fa-check check p-0"></i></span></div>
                <div class="view-type" (click)="onitemViewTypeChange?.emit('FULL')"><span style="width: 100%;">Full Screen view</span><span *ngIf="currentItemView=='FULL'" style="flex: 1;"><i class="fas fa-check check p-0"></i></span></div>
              </div>
            </ng-template>
            <span *ngIf="!tableInfo && leadObj?.item_type!=='ACTIVITY_COMMENT'"  class="option-btn" [class.activeOption]="optionsPopover?.isOpen()" [ngbPopover]="leadObj && !isLeadInTemplate && !isInternalBoardReadOnly && leadOptionTemplate" #optionsPopover="ngbPopover" placement="bottom-right" container="body" [autoClose]="true" 
            (click)="$event.stopPropagation(); getPopOverRef(optionsPopover)" popoverClass="threeDotsPopover">
              <img src="../../../assets/common-use-icon-20dp/threeDots-icon.svg" >
            </span>
            <ng-template #leadOptionTemplate>
              <app-lead-option-template 
              [isOpenedFullView]="true"
              [showHiddenFields]="showHiddenFields"
              [dashboardUserRole]="dashboardUserRole"
              [isReadOnly]="isReadOnly"
              [leadInfo]="leadObj" 
              [selectedDashId]="dashId"
              [dashBoardSprint]="dashBoardSprintArr"
              (showSubtaskParentModal)="showSubtaskParentModal.emit($event)">
              </app-lead-option-template>
            </ng-template>
            <ng-container>
              <span (click)="minimizeItem(item);modalClose?.emit(false)"><img src="../../../assets/1x/minimize.png" class="mr-1"/></span>
            </ng-container>
            <ng-container *ngIf="!hideClosebtn" >
              <span  class="option-btn item-close-btn" role="button" (click)="!itemConfig?.isRecordingInprogress && modalClose?.emit(false)"  [ngbPopover]="itemConfig?.isRecordingInprogress && recordingStop" #stopRecord="ngbPopover" [placement]="['bottom-right','bottom','auto']" container="body"  [autoClose]="'outside'"><img src="../../../../assets/common-use-icon-20dp/close.svg"></span>
              <ng-template #recordingStop>
                <div style="display: flex;align-items: center;flex-direction: column;row-gap: 10px; width: 300px;">
                    <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;row-gap: 10px;">
                      <h6>{{'helpingText.recordingCancelOnCloseItem' | translate}}</h6>
                    </div>
                    <div style="display: flex;column-gap: 10px;">
                      <button class="btn btn-success" (click)="stopRecord?.close();modalClose?.emit(false)">{{'multiSelect.yes' | translate}}</button>
                      <button class="btn btn-danger" (click)='stopRecord?.close()'>{{'multiSelect.no' | translate}}</button>
                    </div>
                </div>
                </ng-template>
            </ng-container>
            
        </div> 
      </div>
</div>

