
import { style } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CustomStorageService } from 'app/services/custom-storage.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';

@Component({
  selector: 'ai-assist',
  templateUrl: './ai-assist.component.html',
  styleUrls: ['./ai-assist.component.scss'],
})
export class AiAssistComponent implements OnInit {

  aiChatFunctions:any = {
      TONE: ["Professional", "Casual", "Straightforward", "Confident", "Friendly", "Persuasive", "Neutral"],
      LENGTH: ["Concise", "Moderate", "Detailed", "Make Shorter", "Make Longer", "Summarize"],
      STYLE: ["Formal", "Informal", "Descriptive", "Analytical", "Narrative", "Technical"],
      DEPARTMENT: ["Marketing", "Sales", "Customer Success", "Engineering", "HR", "Operations"]
  }
  seletedChatFunction:any={
    length: [],tone:[],style:[],department:[]
  }
  orgData:any={}
  currentUserId:any={}
  chatList:any=[];
  sendText:any;
  isLoading:boolean=false
  @Input() editSelectionText:boolean=true
  @Input() selectedText:any
  @Output() closeModal= new EventEmitter<any>();
  @ViewChild('editableDiv') editableDiv: ElementRef;
  @ViewChild('viewPort') viewPort: ElementRef;

  constructor(private dashboardUtils: DashboardUtilsService,private customStorageService : CustomStorageService,private httpTransfer: HttpTransferService,public cdr: ChangeDetectorRef,) { }

  async ngOnInit(): Promise<void> {
    if(this.selectedText)this.sendText=this.selectedText
    this.orgData=await this.dashboardUtils.orgUsersJson;
    this.currentUserId=this.customStorageService.getItem('id');
    this.cdr.detectChanges()
  }
  ngAfterViewInit(){
    this.editableDiv?.nativeElement?.focus()
  }

  onKeydown(event){
    if (event.key === 'Enter') {
      if (event.ctrlKey) {
      } else {
          // Submit the content
          event.preventDefault(); // Prevent the default Enter action
          this.sendMsg(this.editableDiv.nativeElement.textContent)
      }
  }
  }
  sendMsg(text){
    let filteOutFunction=Object.keys(this.seletedChatFunction).reduce((json,key)=>{if(this.seletedChatFunction[key]?.length>0)json[key]=this.seletedChatFunction[key];return json},{});
    let json=  {"input_text":text};
    if(Object.keys(filteOutFunction)?.length){
      json["functions"]=filteOutFunction
    }
    if(text?.trim()?.length>0){
      this.isLoading=true; // start loader;
      this.sendText=null
      this.editableDiv.nativeElement.textContent=''
      let userChat={_id:this.chatList?.length,userId:this.currentUserId,text:text,username:this.orgData[this.currentUserId]?.name,showUserName:'You'}
      let aiChat={_id:this.chatList?.length+1,userId:'ai',text:'',username:'AI',showUserName:'AI',loading:true,responseId:this.chatList?.length}
      this.chatList.push(userChat);
      this.chatList.push(aiChat);
      this.cdr.detectChanges();
      this.httpTransfer.getChatCompletions(json).subscribe(res=>{
        if(res.status==200){
          this.chatList[this.chatList.length-1].text=res?.result?.message_output
          delete this.chatList[this.chatList.length-1].loading
        }
        this.isLoading=false;
        setTimeout(() => {this.editableDiv?.nativeElement?.focus();},5); 
        this.cdr.detectChanges()
      },err=>{this.isLoading=false; this.cdr.detectChanges()})
    }
  }


}
