import { Component, Input, OnInit } from '@angular/core';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';

@Component({
  selector: 'app-user-info-card',
  templateUrl: './user-info-card.component.html',
  styleUrls: ['./user-info-card.component.scss']
})
export class UserInfoCardComponent implements OnInit {
  @Input() user:any=null
  @Input() userId:any=null
  userListData: any=null;
  constructor(
    private dashboardUtils:DashboardUtilsService
  ) { }

  ngOnInit(): void {
    if(this.userId){
      this.dashboardUtils.orgUsersJsonUpdate$.subscribe((res : any)=>{
        this.userListData=res
        this.user=this.userListData[this.userId]
        this.user.image_url=this.user?.image_url
      })
    }
  }

}
