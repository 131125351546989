<div class="container markAsDuplicate">
  <div class="row Activity Filter">
    <div class="col-md-1"></div>
    <div class="col-md-12  my-auto">
      <form  ngform="">
        <div class="row g-0 mx-0 w-100 mt-3" style="row-gap: 10px;">
          <!-- current lead detaild box  -->
          <app-item-list [itemIdList]="[leadInfo._id]" [dashId]="leadInfo.dashboard_id" [showRemoveIcon]="false" [showBoardName]="false" class="w-100" [isReadOnly]="true"></app-item-list>
          <!-- section of leads start here -->
          <div *ngIf="!isDuplicateItem" class="row g-0 mx-0 form-group w-100 mb-0" style="flex-direction:column;">
                <label for="lead" class="control-label modalLabel">{{'leadOption.duplicate.duplicateItem' | translate:{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }} </label>
                <ng-select *ngIf="selectedLead==null" class="custom" #st  placeholder="{{'leadOption.searchItembyID' | translate :{ term: 'Item' | terminology: itemTerminology?.singular : 'Item' } }}" appearance="outline" name="leadSelect"
                [multiple]="false" [closeOnSelect]="false" [searchable]="true" [clearable]="true"  [(ngModel)]="selectedLead" (search)="OnSearchLeadItem($event)">
                  <ng-option *ngFor="let lead of searchLeadList" [value]="lead">
                    <div class ="p-0 mb-0 leadCardBoxs w-100 border-bottom">
                        <div class="leadHeaderRows w-100" style="justify-content: space-between;">
                          <span class="leadIdDetails">
                            <span class="priorityTag">
                              <span [style.background]="commonUtils?.priorityJson[lead.priority]?.color" class="prioritylabelL" >{{commonUtils?.priorityJson[lead.priority]?.label}}</span>  
                            </span>
                            <span class="mx-2 leadTitle">#{{lead?.seq_id}}</span>
                          </span>
                          <span type="button" class="close pull-right mr-2" (click)="selectedLead=null">&#43;</span>
                        </div>
                        <div class="leadDescriptionBox w-100">
                          <p class="mb-0 detailsa">{{ lead.title | truncate : 120}}</p>
                        </div>
                    </div>
                  </ng-option>
                </ng-select>
                <!-- sected lead deatils box -->
                <div  class="mt-1 w-100" *ngIf="selectedLead!=null">
                  <app-item-list [itemIdList]="[selectedLead._id]" [dashId]="selectedLead.dashboard_id" [showRemoveIcon]="true" [showBoardName]="false" (itemRemove)="selectedLead=null" [showDeletePopover]="false" class="w-100" [isReadOnly]="true" ></app-item-list>
                </div>
          </div>
          <!-- section of lead end here -->
          <div  class="mx-0 form-group g-0 w-100 " style="flex-direction:column;">
            <label for="lead" class="control-label modalLabel">{{'leadOption.duplicate.movetoBucket' | translate}} </label>
            <ng-select *ngIf="selectedBucket==null && bucketList!=null" class="custom" [(ngModel)]="selectedBucket" [ngModelOptions]="{standalone: true}"
              appearance="outline" [searchable]="false" [clearable]="false" placeholder="{{'leadOption.duplicate.selectBucket' | translate}}">
              <ng-option *ngFor="let bucket of bucketList" [value]="bucket">
                <div class="border-bottom p-1 w-100">{{bucket?.name}}</div>
              </ng-option>
            </ng-select>
            <div  class="leadcard bucketCards w-100" *ngIf="selectedBucket!=null">
                <span class="detailsa" style="width: 90%;">{{selectedBucket.name | truncate : 30}}</span>
                <span type="button" class="close pull-right" aria-label="Close" (click)="selectedBucket=null">&times;</span>
            </div>
            <span *ngIf="isDuplicateItem"  class="mt-2">moving item #<b>{{leadInfo?.seq_id}}</b> to Non final bucket </span>  
            <span *ngIf="!isDuplicateItem" class="mt-2">moving item #<b>{{leadInfo?.seq_id}}</b> to final bucket </span> 
          </div> 
          <div class="text-right bottom-btns w-100 my-3">
            <button type="button" [disabled]="(!isDuplicateItem && (selectedBucket==null || selectedLead==null)) || (isDuplicateItem && selectedBucket==null)" (click)="moveLeadToBucket()" data-dismiss="modal"
            class="btn movebtn btnStyleS">{{'leadOption.duplicate.move' | translate}}</button>
            <button type="button" data-dismiss="modal" aria-label="Close" (click)="closeModel()"
              class="btn closeBtn btnStyleS">{{'common.cancel' | translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>