import { Pipe, PipeTransform } from "@angular/core";
import { CommonUtils } from "./services/CommonUtils.service";

@Pipe({
  name: "dateCalcuate",
})
export class DateCalculatePipe implements PipeTransform {
  constructor(private commonUtils: CommonUtils ) {}
  transform(value: any, args?: any): any {
    let finalDate=''
    if(value){
      let date
      if(value?.date && !value?.is_time_added && !isNaN(value?.date)){
        let timeFrom1900=this.commonUtils.calculateTimestampSince1900(value?.date)
        date=new Date(timeFrom1900)
      }else if(value.time){
        date=new Date(value.time)
      }
      if(date && date?.toString() != "Invalid Date"){
        if(args=='date' || !args || args=='dateTime'){
          let dateObj=this.commonUtils?.getHumanReadableDateAndTimeFromDate(date,true,false);
          finalDate =dateObj?.date + (args=='dateTime' && value?.is_time_added ? '-'+dateObj?.time : '')
        }
        else if(value?.is_time_added && args=='time'){
          finalDate =this.commonUtils?.getHumanReadableDateAndTimeFromDate(date,true,false)['time']
        }
      }
    }
    return finalDate;
  }

}
