import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  constructor() { }

  private messageData = new Subject<any>();
  private bucketData = new Subject<any>();
  private dashBoardData = new Subject<any>();
  private flagData = new Subject<any>();
  private leadData = new Subject<any>();
  private customFieldData = new Subject<any>();
  private userInfoData = new Subject<any>();
  private resData = new Subject<any>();
  private editCustomData = new Subject<any>();
  private clickedLead = new Subject<any>();
  private pushNotificationSubject = new Subject<any>();
  private sortingSubject = new Subject<any>();
  private sortingSubjectFromDashboard = new Subject<any>();
  private editPageSubject = new Subject<any>();
  private leadDataSubject = new Subject<any>();
  private dashBucketsSubject = new BehaviorSubject<any>(null);
  private showTimelineFlag = new Subject<any>();
  searchText = new Subject<string>();
  dashIdForCustomForm = new Subject<string>();
  private paramDashId = new Subject<string>();
  private dashboards = new Subject<any>();
  private isLeftNavbarOpen = new Subject<boolean>();
  private myActionFilter = new Subject<any>();
  private showChildrenLead = new Subject<any>();
  private hideChildrenLead = new Subject<any>();
  private viewType = new Subject<any>();
  private bulkSelectionMode = new Subject<any>();
  private collapseIcon = new Subject<boolean>();
  private selectedItem = new Subject<boolean>();
  private isLeftMenuRefreshRequired = new Subject<boolean>();
  private expandOrCollapseGridGroups = new Subject<boolean>();
  private formSubject = new Subject<any>();
  private currentRouteSubject = new Subject<any>();
  private clickTemObj = new BehaviorSubject<any>(null);
  private filterDataObj = new Subject<any>();
  private refreshDashboard = new Subject<any>();
  private columnsSubject = new BehaviorSubject<any>(null);
  private eventRowHeight = new BehaviorSubject<any>(null);
  private settingclick = new Subject<boolean>();
  private openGraphSettings = new Subject<boolean>();
  private openCreateBlankItemPopup = new Subject<any>();
  private leadForConnectedBoard = new Subject<any>();
  private filterObjForSetting = new BehaviorSubject<any>(null);
  private preApprovedTemplateForChannel = new BehaviorSubject<any>(null)
  private updateVeiwForFormula = new BehaviorSubject<any>(null);
  private privateView = new Subject<any>();
  private templateMessageChannel = new BehaviorSubject<any>(null)
  private itemInfoSub =new Subject<any>();
  private itemOpenSub =new Subject<any>();
  private isDashcollapse = new Subject<boolean>();
 private themeColor = new Subject<any>()
  private typeOfView =new Subject<any>();
  private isDockedWidget = new Subject<boolean>();
  private isNewList = new Subject<any>();
  private isMinimzeNote = new Subject<any>();
  private externalChatData = new Subject<any>();
  private workFolderCustom: any=null;
  private showModalSubject = new BehaviorSubject<any>(null);
  showModal$ = this.showModalSubject.asObservable();
  private closeSettingSidebar=new Subject<any>();
  private workFolderData:any=null;
  private isGraphViewOnly = new Subject<boolean>();
  private isOverViewCollapsed = new Subject<boolean>();
  


  sendMessage(message: string) {
    this.messageData.next({ text: message });
  }

  clearMessage() {
    this.messageData.next(null);
  }

  getMessage(): Observable<any> {
    return this.messageData.asObservable();
  }

  sendbuckets(msg: string, buckets: any, flag?) {
    this.bucketData.next({ msg: msg, buckets: buckets, flag: flag });
  }

  getbuckets(): Observable<any> {
    return this.bucketData.asObservable();
  }

  sendcreatedboard(obj: any) {
    this.dashBoardData.next(obj);
  }

  getcreatedboard(): Observable<any> {
    return this.dashBoardData.asObservable();
  }

  sendflag(show: any) {
    this.flagData.next(show);
  }

  getflag(): Observable<any> {
    return this.flagData.asObservable();
  }

  sendupdatebuck(show: any) {
    this.bucketData.next(show);
  }

  getupdatebuck(): Observable<any> {
    return this.bucketData.asObservable();
  }



  sendDashBoardData(dash: any) {
    this.dashBoardData.next(dash);
  }

  getDashBoardData() {
    return this.dashBoardData.asObservable();
  }

  sendingLeadFromBucketToTrash(data: any) {
    this.leadData.next(data);
  }

  gettingLeadFromBucketToTrash() {
    return this.leadData.asObservable();
  }

  sendCustData(data: any) {
    this.customFieldData.next(data);
  }

  getCustData() {
    return this.customFieldData.asObservable();
  }



  sendUserOwnInfo(data: any) {
    this.userInfoData.next(data);
  }

  getUserOwnInfo() {
    return this.userInfoData.asObservable();
  }

  sendBucketsCorrespondingToDash(data: any) {
    this.bucketData.next(data);
  }

  getBucketsCorrespondingToDash() {
    return this.bucketData.asObservable();
  }

  sendCreatedDataRes(data: any) {
    this.resData.next(data);
  }

  getCreatedDataRes() {
    return this.resData.asObservable();
  }

  sendListOfUsers(data: any) {
    this.userInfoData.next(data);
  }

  getListOfUsers() {
    return this.userInfoData.asObservable();
  }

  sendEditCustomData(data) {
    this.editCustomData.next(data);
  }

  getEditCustomData() {
    return this.editCustomData.asObservable();
  }

  sendBucketSeq(data) {
    this.clickedLead.next(data);
  }

  getBucketSeq() {
    return this.clickedLead.asObservable();
  }

  sendPushNotificationChangeEvent(msg) {
    this.pushNotificationSubject.next(msg);
  }

  getPushNotificationChangeEvent() {
    return this.pushNotificationSubject.asObservable();
  }

  sendSortingInfo(msg, order) {
    this.sortingSubject.next({ msg: msg, order: order });
  }

  getSortingInfo() {
    return this.sortingSubject.asObservable();
  }

  sendEditPageInfo(data) {
    this.editPageSubject.next(data);
  }

  getEditPageInfo() {
    return this.editPageSubject.asObservable();
  }

  sendLeadList(data) {
    this.leadDataSubject.next(data);
  }

  getLeadList() {
    return this.leadDataSubject.asObservable();
  }

  sendSortingInfoFromDashboard(msg, order) {
    this.sortingSubjectFromDashboard.next({ msg: msg, order: order });
  }

  getSortingInfoFromDashboard() {
    return this.sortingSubjectFromDashboard.asObservable();
  }
  sendDashBuckets(dashBuckets) {
    this.dashBucketsSubject.next(dashBuckets);
  }
  getDashBuckets(): Observable<any> {
    return this.dashBucketsSubject;
  }
  sendShowTimelineValue(flag) {
    this.showTimelineFlag.next(flag);
  }
  getHideTimelineValue(): Observable<any> {
    return this.showTimelineFlag;
  }

  setSearchText(text: string) {
    this.searchText.next(text);
  }

  getSearchText(): Observable<string> {
    return this.searchText;
  }

  setDashIdForCustomForm(dashId) {
    this.dashIdForCustomForm.next(dashId);
  }

  getDashIdForCustomForm(): Observable<string> {
    return this.dashIdForCustomForm;
  }
  setDashIdFromQueryParams(dashObj) {
    this.paramDashId.next(dashObj);
  }
  getDashIdFromQueryParams(): Observable<any> {
    return this.paramDashId;
  }


  setLeftNavOpenStatus(isOpen: boolean) { 
    this.isLeftNavbarOpen.next(isOpen); 
  } 
    
  getLeftNavOpenStatus(): Observable<any> { 
      return this.isLeftNavbarOpen; 
    }

  sendMyActionFilter(data) {
    this.myActionFilter.next(data)
  }

  getMyActionFilter(): Observable<any> {
    return this.myActionFilter;
  }

  sendShowChildrenLeadStatus(flag) {
    this.showChildrenLead.next(flag);
  }

  getShowChildrenLeadStatus(): Observable<any> {
    return this.showChildrenLead;
  }

  setCurrentViewId(viewId) {
    this.viewType.next(viewId);
  }

  getCurrentViewId(): Observable<any> {
    return this.viewType.asObservable();
  }
  collapseIconChanged(flag) {
    this.collapseIcon.next(flag)
  }
  getCollepseIconChanged(): Observable<any> {
    return this.collapseIcon.asObservable();
  }
  dashViewCollapse(flag){
    console.log("set")
    this.isDashcollapse.next(flag)
  }
  getdashViewCollapse(): Observable<any> {
    console.log("get")
    return this.isDashcollapse.asObservable();
  }
  setFormView(formId) {
    this.formSubject.next(formId);
  }
  setLeftMenuRefreshRequired(item) {
    this.isLeftMenuRefreshRequired.next(item)
  }
  getLeftMenuRefreshRequired(): Observable<any> {
    return this.isLeftMenuRefreshRequired.asObservable();
  }
  setExpandOrCollapseGridGroups(item:boolean) {
    this.expandOrCollapseGridGroups.next(item)
  }
  getExpandOrCollapseGridGroups(): Observable<any> {
    return this.expandOrCollapseGridGroups.asObservable();
  }
  getFormView(): Observable<any> {
    return this.formSubject.asObservable();
  }
  setCurrentRoute(route) {
    this.currentRouteSubject.next(route);
  }
  getCurrentRoute(): Observable<any> {
    return this.currentRouteSubject.asObservable();
  }
  sendTemObj(data) {
    this.clickTemObj.next(data);
  }

  getTemObj(){
    return this.clickTemObj.asObservable();
  }

  setFilterDataObject(data){
    this.filterDataObj.next(data);
  }

  getFilterDataObject(){
    return this.filterDataObj.asObservable();
  }
  setFilterObjectForSetting(data){
    this.filterObjForSetting.next(data);
  }

  getFilterObjectForSetting(){
    
    return this.filterObjForSetting.asObservable();
  }
  setPreApprovedTemplateForChannel(data){
    this.preApprovedTemplateForChannel.next(data); 
  }
  getPreApprovedTemplateForChannel(){
    return this.preApprovedTemplateForChannel.asObservable();
  }
  setSettingClick(flag) {
    this.settingclick.next(flag)
  }
  getSettingClick(): Observable<any> {
    return this.settingclick.asObservable();
  }
  setColumnsSubject(data){
    this.columnsSubject.next(data);
  }

  getColumnsSubject(){
    return this.columnsSubject.asObservable();
  }

  setEventRowHeight(data){
    this.eventRowHeight.next(data);
  }

  getEventRowHeight(){
    return this.eventRowHeight.asObservable();
  }
  setBulkSelectionMode(flag) {
    this.bulkSelectionMode.next(flag);
  }
  getBulkSelectionMode(): Observable<boolean> {
    return this.bulkSelectionMode.asObservable();
  }
  setDashboardRefresh(flag) {
    this.refreshDashboard.next(flag);
  }
  getDashboardRefresh(): Observable<boolean> {
    return this.refreshDashboard.asObservable();
  }

  setUpdateVeiwForFormula(data){
    this.updateVeiwForFormula.next(data);
  }

  getUpdateVeiwForFormula(){
    return this.updateVeiwForFormula.asObservable();
  }
  setOpenGraphSettings(setting) {
    this.openGraphSettings.next(setting);
  }
  getOpenGraphSettings() {
    return this.openGraphSettings.asObservable();
  } 
  setOpenCreateBlankItemPopup(setting) {
    this.openCreateBlankItemPopup.next(setting);
  }
  getOpenCreateBlankItemPopup() {
    return this.openCreateBlankItemPopup.asObservable();
  } 
  setLeadForConnectedBoard(setting) {
    this.leadForConnectedBoard.next(setting);
  }
  getLeadForConnectedBoard() {
    return this.leadForConnectedBoard.asObservable();
  } 
  createPrivateView(filterobj){
    this.privateView.next(filterobj);
  }
  getPrivateView() {
    return this.privateView.asObservable();
  } 
  setChannelTemplateMessage(data){
    this.templateMessageChannel.next(data); 
  }
  getChannelTemplateMessage(){
    return this.templateMessageChannel.asObservable();
  }

  getItemPopupInfo(){
    return this.itemInfoSub.asObservable();
  }

  setItemPopupInfo(data){
    this.itemInfoSub.next(data)
  }

  sendThemeColor(data) {
    this.themeColor.next(data);
  }

  getThemeColor() {
    return this.themeColor.asObservable();
  }
  getViewInfo(){
    return this.typeOfView.asObservable();
  }

  setViewInfo(data){
    this.typeOfView.next(data)
  }

  getDockWidgetInfo(){
    return this.isDockedWidget.asObservable();
  }

  setDockWidgetInfo(data){
    this.isDockedWidget.next(data)
  }

  setViewOrEditSettings(data){
    this.isGraphViewOnly.next(data)
  }

  getViewOrEditSettings(){
    return this.isGraphViewOnly.asObservable();
  }
  setOpenAddList(data){
    this.isNewList.next(data)
  }
  getOpenAddList(){
    return this.isNewList.asObservable()
  }
  setExternalChatData(data) {
    this.externalChatData.next(data);
  }
  getExternalChatData() {
    return this.externalChatData.asObservable()
  }
  getCloseManegeCustom(){
    return this.workFolderCustom
  }
  setCloseManegeCustom(data){
    this.workFolderCustom=data
  }
  getPreviousDataOfProcess(){
    return this.workFolderData
  }
  setPreviousDataOfProcess(data){
    this.workFolderData=data
  }
  openModal(data) {
    this.showModalSubject.next(data);
  }
  closeModal() {
    this.showModalSubject.next(null);
  }
  getCloseSettingSidebar(){
    return this.closeSettingSidebar.asObservable()
  }
  setCloseSettingSidebar(data){
    this.closeSettingSidebar.next(data);
  }
  setOveriViewCollapsed(data){
    this.isOverViewCollapsed.next(data)
  }
  getOveriViewCollapsed(){
    return this.isOverViewCollapsed.asObservable()
  }

  setSaveMinimizeResult(data) {
    this.isMinimzeNote.next(data)
  }
  getSaveMinimizeResult() {
    return this.isMinimzeNote.asObservable()
  }
}
