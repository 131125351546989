import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtils } from '../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../services/dashboard-utils.service';
import { HttpTransferService } from '../../services/httpTransfer.service';
import { filter } from 'rxjs-compat/operator/filter';
import { AutomationUtilsService } from '../../services/automation-utils.service';
import { MessageService } from 'app/message.service';

@Component({
  selector: 'app-whatsapp-pre-approve-messages',
  templateUrl: './whatsapp-pre-approve-messages.component.html',
  styleUrls: ['./whatsapp-pre-approve-messages.component.scss']
})
export class WhatsappPreApproveMessagesComponent implements OnInit {
  @Output() whatsappPreApproved:EventEmitter<any> = new EventEmitter();
  @Input() objFilterForMultiselect:any;
  @Input() broadCastChannel:any;
@Input() preApprovedData:any;
@Input() dashId:any;
@Input() leadInfo: any;
@Input() selectedReceiver:any;
@Input() sprintArr:any;
@Input() orgUsersJson:any;
// for automation send whatsapp
@Input() automationCall:boolean=false
@Input() automationObj:any
@Input() isTriggeredNonItemField:any;
@Input() whatsappColumnJson: any;
@Input() columnIndex: number;
@Output() selectedTemplateForAutomation = new EventEmitter<any>()
columnsFor:any='whatsapp'
@Output() emitCloseModal = new EventEmitter<any>()
@Output() updatedAutomation = new EventEmitter<any>()
@Input() selectedChannelObj : any;
@ViewChild('popColumns') public popColumns : NgbPopover
@ViewChild('popColumnsForAutomation') public popColumnsForAutomation : NgbPopover
@Input() multiselect:boolean=false;

  selectedMsg: any;
  value: any[]=[];
  temparr: any = new Map;
  arrOfCustomField: any[]=[];
  storeIndex: any;
  showMirrorOptions:boolean=false;
  selectedMirrorObject:any;
  algorithm:any;
  constructor(private httpTransfer : HttpTransferService,
    private dashboardUtils : DashboardUtilsService,
    private commonUtils : CommonUtils,
    private automationUtils : AutomationUtilsService,
    private msgservice: MessageService) { }

  async ngOnInit() {
    
    
    let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
    this.arrOfCustomField = JSON.parse(JSON.stringify(dashboardJson[this.dashId]['CUSTOM_FORM']));
    if(this.preApprovedData.length){
      for(let i=0;i<this.preApprovedData.length;i++){
        if(this.preApprovedData[i].status != 'REJECTED'){
          this.selectedMsg = this.preApprovedData[i]
          break;
        }
      }
      this.selectedMsg.components.forEach(e=>{
        if(e.type == 'BODY'){
          let value = []
          value = e.text.split('{{')
          value.splice(0,1)   
          console.log(value);
           value.forEach(e=>{
            console.log(e);
            let obj={}
            obj['value'] = e
            obj['columnValue']=''
            this.value.push(obj)
            console.log(this.value);     
          })     
        }
      })
    }
    console.log(this.automationObj, this.preApprovedData)
    if(this.automationCall ){
      if(this.automationObj?.whatsappObject?.selectedMsgId){
        let selectedMessagearr=this.preApprovedData.filter(e=>e.id==this.automationObj?.whatsappObject?.selectedMsgId)
        this.selectedTemplate(selectedMessagearr[0])
      }
      else{
        this.selectedTemplate(this.selectedMsg)
      }
    }


  }
  selectedTemplate(item){
    this.selectedMsg = item
    this.selectedMsg = item
    this.value = []
    this.selectedMsg.components.forEach(e=>{
      if(e.type == 'BODY'){
        let value = []
          value = e.text.split('{{')
          value.splice(0,1)   
           value.forEach(e=>{
            console.log(e);
            let obj={}
            obj['value'] = e
            obj['columnValue']=''
            this.value.push(obj)
      })
    }
  })
  if(this.automationCall){
    if(this.automationObj?.whatsappObject?.selectedMsgId){
      if(this.automationObj?.whatsappObject?.selectedMsgId!=this.selectedMsg.id){
        this.automationObj['whatsappColumns']=[]
        this.updatedAutomation.emit()
      }
      else{
        this.automationObj?.whatsappColumns
        this.value=[]
        for(let i=0;i<this.automationObj?.whatsappColumns.length;i++){
          let obj={}
          obj['columnValue']=this.automationObj?.whatsappColumns[i]?.text
          this.value.push(obj)
        }
      }
    }
      this.selectPreapprovedMessageforAutomation()
    }
  }
  getValue(value,index){
    if(value!=''){
      this.temparr.set(index+1,value)
    }
  }
  getIndex(index){
    this.storeIndex = index
  }

  async getcustomvalue(item){
    console.log(this.leadInfo,item)
    this.popColumns.close()
    let currencyJson =[]
  if(item.type == 'Currency' || item.type=='Mobile'){
  currencyJson   = await this.dashboardUtils.getCurrencyJson() 
  }
  let value = await this.commonUtils.checkForCustomFieldValueOnLead(item,this.leadInfo)
  this.value[this.storeIndex].columnValue = value
  }
  getextravalue(item){
    this.popColumns.close()
    this.value[this.storeIndex].columnValue = this.commonUtils.checkforNonCustomValue(item ,this.leadInfo)
  }


  getValueforAutomation(event){    
    this.popColumns.close()
    this.value[this.storeIndex].columnValue=event.value
    this.value[this.storeIndex].columnKey=`{{${event.key}}}`
    if(event.algorithm){
      this.value[this.storeIndex].algorithm=`{{${event.algorithm}}}`
    }
    this.value[this.storeIndex].columnKey=`{{${event.key}}}`
    this.selectPreapprovedMessageforAutomation()
  }


  async selectPreapprovedMessageforAutomation(){
    let arr =[]
    let text=""
    console.log(this.value);
    this.value?.forEach(e=>{
      let obj={
        type: "TEXT",
        text: e.columnValue,
      }
      arr.push(obj)
    })
      this.selectedMsg.components.forEach(e=>{
        if(e.type == 'BODY'){
          text = e.text
        }
      })
    this.automationObj.whatsappObject['selectedMsgId']=this.selectedMsg.id,
    this.automationObj.whatsappObject['selectedMsgName']=this.selectedMsg.name,
    this.automationObj.whatsappObject['selectedMsgLanguage']=this.selectedMsg.language,
    this.automationObj.whatsappObject['parameters']=arr
    this.automationObj['whatsappColumnJson'] = this.whatsappColumnJson,
    this.automationObj.whatsappObject['text'] = text
  }


  sendMsg(){
    let i=1;
    let arr =[]
    let text=""
    console.log(this.value);
    this.value.forEach(e=>{
      if(e.columnValue!==""){
        let obj={
          type: "TEXT",
          text: e.columnValue
        }
        arr.push(obj)
      }
    })
    this.selectedMsg.components.forEach(e=>{
      if(e.type == 'BODY'){
        text = e.text
      }
    })
    let inputJson={}
    inputJson['chat_user_id'] = this.selectedChannelObj?.chat_user_id
    inputJson['channel_id'] = this.selectedChannelObj?.channel_id
    inputJson["temporary_id"] = this.commonUtils.getRandomString(10)
    inputJson["type"]= "EXTERNAL_OUTBOUND",
    inputJson['message_meta_data']= {
      'template' : {
        'name' : this.selectedMsg?.name,
        'body' : arr,
        "language": this.selectedMsg?.language,
        'id' : this.selectedMsg?.id,
        'body_text' : text
      }
    }
   if(!this.multiselect){
    this.httpTransfer
    .postCommentCorrespondingLead(inputJson, this.dashId, this.selectedChannelObj?.lead_id).subscribe((res : any) => {
     if(res.status==200){
      this.msgservice.setPreApprovedTemplateForChannel(this.preApprovedData)
      this.emitCloseModal.emit()
     }
    })
   }
   else{
    let param = {}
    param['filter_properties']=this.objFilterForMultiselect
    param['channel_comment']={
        "message_meta_data": inputJson['message_meta_data'],
        "channel_id": this.broadCastChannel?._id,
        "type":"EXTERNAL_OUTBOUND",
        "is_broadcast":true,
      }
      this.httpTransfer.updateMultipleDashboard(this.dashId,param).subscribe((res : any) =>{
        if(res.status==200){
          this.emitCloseModal.emit()
        }
        })
   }
  }

  async getValuesForMirroColumns(item){
    this.popColumns.close()
    let value = await this.commonUtils.getMirrorForWhatsapp(item,this.leadInfo,this.selectedReceiver)
    if(value){
      this.value[this.storeIndex].columnValue = value
    }
  }
}
