import { Injectable } from '@angular/core';
import { ConstantService } from '../Enums/Constant.service';
import { CommonUtils } from './services/CommonUtils.service';
import { HttpTransferService } from './services/httpTransfer.service';
import { SortingutilsService } from './sortingutils.service';

@Injectable({
  providedIn: 'root'
})
export class ResourceLockerService {
  lockedResources = new Map();
  lockingAvailable: boolean = true;
  static instance: ResourceLockerService;
  constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ResourceLockerService();
    }
    return this.instance;
  }
  async acquireLock(resourceIdentifier, expirationTimeInMilliseconds = 5000, retryCount = 1000, retryInterval = 10) {
    let currentTime = Date.now();
    let expirationTime = this.lockedResources.get(resourceIdentifier);
    while (expirationTime && retryCount > 0 && currentTime < expirationTime) {
      // The resource is locked, and it's still within the expiration period; retry after the retryInterval
      await new Promise(resolve => setTimeout(resolve, retryInterval));
      currentTime = Date.now();
      expirationTime = this.lockedResources.get(resourceIdentifier);
      retryCount--;
      if (retryInterval < 1) {
        console.log("Lock could not be acquired for", resourceIdentifier)
      }
    }

    // Set a new lock with an expiration time
    if (this.lockingAvailable) {
        try{
          this.lockingAvailable = false;
          const newExpirationTime = Date.now() + expirationTimeInMilliseconds;
          this.lockedResources.set(resourceIdentifier, newExpirationTime);
          // Automatically release the lock after the specified time
          setTimeout(() => {
            this.releaseLock(resourceIdentifier);
          }, expirationTimeInMilliseconds);
        }
        finally{
          this.lockingAvailable = true;
        }
    }
    else {
      await this.acquireLock(resourceIdentifier, expirationTimeInMilliseconds, retryCount, retryInterval)
    }

  }


  releaseLock(resourceIdentifier) {
    this.lockedResources.delete(resourceIdentifier);
  }
}

