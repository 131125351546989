<div class="email-section">
    <div *ngIf="lastMessage?.email_object?.from?.address">
      <span class="label1">From:</span>
      <span class="address">{{lastMessage?.email_object?.from?.address }}</span>
    </div>
    <div *ngIf="lastMessage?.email_object?.to.length > 0">
      <span style="margin-left: 0.8rem;" class="label1">To:</span>
      <div class="address-group">
        <ng-container *ngFor="let toAddress of lastMessage?.email_object?.to; let i = index">
          <span class="address">{{toAddress.address }}</span>
        </ng-container>
      </div>
    </div>
    <div *ngIf="lastMessage?.email_object?.cc.length > 0">
      <span style="margin-left: 0.8rem;" class="label1">Cc:</span>
      <div class="address-group">
        <ng-container *ngFor="let ccAddress of lastMessage?.email_object?.cc; let i = index">
          <span class="address">{{ccAddress.address }}</span><br>
        </ng-container>
      </div>
    </div>
    <div *ngIf="lastMessage?.email_object?.bcc.length > 0">
      <span style="margin-left: 0.4rem;" class="label1">Bcc:</span>
      <div class="address-group">
        <ng-container *ngFor="let bccAddress of lastMessage?.email_object?.bcc; let i = index">
          <span class="address">{{bccAddress.address }}</span><br>
        </ng-container>
      </div>
    </div>
    <div>
    <div>
      <span style="margin-left: 0.1rem;" class="label1">Date:</span>
      <span class="datetime">
        {{lastMessage?.receivedTime?.date || lastMessage?.createdTime?.date }} 
        {{lastMessage?.receivedTime?.time || lastMessage?.createdTime?.time }}
      </span>
    </div>
</div>
