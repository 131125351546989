import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AttributesIcons } from "Enums/attributes-icons";

@Component({
    selector: 'color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
  })
  export class ColorPickerComponent implements OnInit {
   @Output() colorChange = new EventEmitter<string>();
   @Input() showLabel:boolean=false;
   @Input() predefineColor:any[]=['#93395F', '#076465', '#A85519', '#1D5E86', '#67554D','#076465', '#7F5A0B', '#0B2B7F', '#B06701', '#7F0B0B'];
   @Input() selectedColor:string="#9FA4AC";
   attributesIcons = AttributesIcons;
   constructor(){}
  
  ngOnInit(): void {    
  }


  }