<div class="qlc-task-render w-100 h-100" contenteditable="false" >
  <div contenteditable="false" *ngIf="dataJson?.type=='item'" class="task-btn" [ngbPopover]="item?._id  && itemInfo" #itemPopoverRef="ngbPopover" [placement]="['top','auto']" container="body" [autoClose]="'outside'" [openDelay]="300" [closeDelay]="500" triggers="mouseenter:mouseleave" (click)="item?._id && onUpdateRenderer.emit({updateType:'OpenPreview',data_type:dataJson?.type,item:item})">
      {{item?._id ? '#'+item?.seq_id+'('+(item?.title | truncate:40)+')' : 'unKnown'}}
  </div>
  <div contenteditable="false" *ngIf="dataJson?.type=='activity' || dataJson?.type=='note'" class="task-btn" (click)="item?._id && onUpdateRenderer.emit({updateType:'OpenPreview',data_type:dataJson?.type,item:item})">
      <app-dashboard-icon *ngIf="dataJson?.type=='note'" [name]="'Notes'"[size]="15"></app-dashboard-icon>
      <app-dashboard-icon  *ngIf="dataJson?.type=='activity'" [name]="'activity'" [size]="15" [isSvg]="true" [color]="'black'" [iconJson]="itemIcons"></app-dashboard-icon>
      <span class="text-overfow-hidden">{{item?.title || 'unknown'}}</span>
  </div>
  <div contenteditable="false" *ngIf="dataJson?.type=='user'"  style="color:blue" [ngbPopover]="item?._id  && itemInfo" #itemPopoverRef="ngbPopover" placement="top" container="body" [autoClose]="'outside'" [closeDelay]="1000" triggers="hover">
    {{'@'+(item?.name || 'unknown')}}
  </div>
  <!-- media file upload loader -->
  <div contenteditable="false"  *ngIf="dataJson?.type=='mediaUploading'">
    <div class="media-container">
      <div  class="d-flex media-header-wrapper">
        <div class="media-title" > 
          <app-dashboard-icon  *ngIf="dataJson.uploading" [size]="15" [name]="'SingleFile'"></app-dashboard-icon>
          <app-dashboard-icon  *ngIf="!dataJson.uploading" [size]="20" [name]="'Alert'" [iconJson]="commonIcons" ></app-dashboard-icon>
          <span class="text-overfow-hidden">{{dataJson?.fileName}}</span>
        </div>
        <div class="media-option-wrqpper d-flex px-2 align-items-center">
          <ng-container *ngIf="dataJson.uploading">
            <app-shimmer-loader  class="mr-2" [type]="'DefaultSpinner'" [circleWidth]="10" [color]="commonUtils.getCurrentThemeColor()"></app-shimmer-loader>
            <span>{{'common.uploading' | translate}}...</span> 
          </ng-container>
          <ng-container *ngIf="!dataJson.uploading">
            <span class="text-danger mr-1" style="white-space: nowrap;">Fail To Upload</span> 
            <app-dashboard-icon  class="optionDetailBox media-option" [size]="15" [name]="'Cross'" [iconJson]="commonIcons" (click)="onUpdateRenderer.emit({updateType:'Remove'})"></app-dashboard-icon>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="dataJson?.type=='media' || dataJson?.type=='noteMedia' || dataJson?.type=='embed' || dataJson?.type=='boardView'">
    <ng-container *ngTemplateOutlet="previewItemTemp;context:{previewItem:{dataUrl:(dataJson?.url || item?.image_download_url || item?.paths?.path || item?.paths?.url),dataName:(item?.name || item?.file_name || embedOption[dataJson?.embed_type]?.name || dataJson?.boardInfo?.dashboard_name || 'Fail to Load'),isLoaded:(item && (dataJson?.type=='media' || dataJson?.type=='noteMedia') || dataJson?.type=='embed' || dataJson?.type=='boardView')}}"></ng-container>
  </ng-container>
</div>

<ng-template #previewItemTemp let-previewItem="previewItem">
  <div class="media-container">
    <!-- {{item?.name}} -->
    <div  class="d-flex media-header-wrapper">
      <a  class="media-title" [href]="previewItem?.dataUrl" (click)="!previewItem?.isLoaded && $event.preventDefault();" target="_blank" draggable="false">
        <ng-container  *ngIf="previewItem?.isLoaded">
          <ng-container *ngIf="dataJson?.type=='boardView'">
            <span class="iconBox-20 boardIconBox" [ngStyle]="{'background-color': dataJson?.boardInfo?.icon_color || commonUtils?.getCurrentThemeColor()}"><i [class]="dataJson?.boardInfo?.icon" class="faicon"></i></span>
          </ng-container>
          <app-dashboard-icon *ngIf="item?.file_type=='IMAGE'" [size]="15"  [name]="'SingleImage'"></app-dashboard-icon>
          <app-dashboard-icon *ngIf="item?.file_type=='VIDEO'" [size]="15"  [name]="'SingleVideo'"></app-dashboard-icon>
          <app-dashboard-icon *ngIf="item?.file_type=='AUDIO'" [size]="15"  [name]="'SingleAudio'"></app-dashboard-icon>
          <app-dashboard-icon *ngIf="(dataJson?.type=='media' || dataJson?.type=='noteMedia') && item?.file_type!=='IMAGE' && item?.file_type!=='VIDEO' && item?.file_type!=='AUDIO'"   [size]="15" [name]="'SingleFile'"></app-dashboard-icon>
        </ng-container>
        <app-dashboard-icon *ngIf="!previewItem?.isLoaded" [size]="15"  [name]="'Alert'" [iconJson]="commonIcons"></app-dashboard-icon>
        <span class="text-overfow-hidden">{{previewItem?.dataName}}</span>
      </a>
      <div class="d-flex media-option-wrqpper">
        <ng-container *ngIf="previewItem?.isLoaded">
          <a class="optionDetailBox media-option hide-option"  [href]="previewItem?.dataUrl" target="_blank" draggable="false">
            <app-dashboard-icon [name]="'Expand'" [size]="12"></app-dashboard-icon>
          </a>
          <app-dashboard-icon *ngIf="!dataJson.dummyUrl" class="appInnerIconBox optionDetailBox media-option hide-option" [name]="'downloadOutline'" [size]="20" [iconJson]="commonIcons" (click)="commonUtils.downloadImage(previewItem?.dataUrl,previewItem?.dataName)"></app-dashboard-icon>
          <app-dashboard-icon *ngIf="dataJson?.type=='media' || dataJson?.type=='noteMedia'" class="appInnerIconBox optionDetailBox media-option" [name]="'fullScreen'" [size]="12" [iconJson]="commonIcons" (click)="onUpdateRenderer.emit({updateType:'OpenPreview',data_type:dataJson?.type,item:item})"></app-dashboard-icon>
        </ng-container>
        <app-dashboard-icon [ngbPopover]="editoptions" #editoptionsRef="ngbPopover" [placement]="['bottom-right','top-right']" autoClose="outside" container="body" class="appInnerIconBox optionDetailBox media-option" [name]="'threeDot'" [size]="12"></app-dashboard-icon>
        <ng-template #editoptions>
          <div >
            <ng-container *ngIf="previewItem?.isLoaded">
              <div class="f-l-r optionDetailBox"  (click)="editoptionsRef?.close();dataJson.hidePreview=(dataJson.hidePreview ? false : true);onUpdateRenderer.emit({updateType:'Preview',showPreview:!dataJson.hidePreview})">
                <app-dashboard-icon [size]="20" [iconJson]="commonIcons" [name]="dataJson.hidePreview ? 'eyeVisibleOutline' : 'eyeHiddenOutline'" ></app-dashboard-icon>
                <span>{{dataJson.hidePreview ?  'Show Preview' : 'Hide Preview' }}</span>
              </div>
              <div *ngIf="previewItem?.dataUrl" class="f-l-r optionDetailBox" (click)="editoptionsRef?.close();copyUrl(previewItem?.dataUrl)">
                <app-dashboard-icon [size]="20" [iconJson]="commonIcons" [name]="'Copy'"></app-dashboard-icon>
                <span>Copy Url</span>
              </div>
            </ng-container>
            <div  class="f-l-r optionDetailBox" (click)="editoptionsRef?.close();onUpdateRenderer.emit({updateType:'Remove'})" >
              <app-dashboard-icon [size]="20" [iconJson]="commonIcons" [name]="'Delete'"></app-dashboard-icon>
              <span class="text-danger">Remove</span>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="media-body-wrapper qlc-resize-disable" *ngIf="previewItem?.isLoaded && !dataJson.hidePreview">
      <ng-container *ngIf="!dataJson.dummyUrl && (dataJson?.type=='media' || dataJson?.type=='noteMedia')">
        <ng-container *ngTemplateOutlet="mediaTemplateRef"></ng-container>
      </ng-container>
      <ng-container *ngIf="dataJson.dummyUrl">
        <div  class="frame-loader w-100 h-100">
          <app-shimmer-loader [type]="'Full'"></app-shimmer-loader>
        </div>
        <iframe class="w-100 h-100 position-relative"   [src]="dataJson.dummyUrl" frameborder="0"  allowfullscreen  referrerpolicy="no-referrer-when-downgrade"></iframe>
      </ng-container>
    </div>
</div>
</ng-template>

<ng-template #itemInfo>
      <div (mouseenter)="itemPopoverRef?.open()" (mouseleave)="itemPopoverRef?.close()" >
           <ng-conatiner  *ngIf="dataJson?.type=='item'" ><app-item-list [itemIdList]="[item._id]"  [dashId]="item.dashboard_id" [showRemoveIcon]="false" [showBoardName]="false" class="w-100" [isReadOnly]="true"></app-item-list></ng-conatiner>
           <ng-container  *ngIf="dataJson?.type=='user'" > <app-user-info-card [user]="item"></app-user-info-card></ng-container>
      </div>
</ng-template>

<ng-template #mediaTemplateRef>
      <ng-container *ngFor="let file of [item] let i = index">
        <ng-container  *ngIf="file?.file_type=='IMAGE';else checkAudio">
          <img class="media-box" [src]="file?.paths?.url || file?.image_download_url" />
        </ng-container>
        <ng-template #checkAudio>
          <ng-container *ngIf="file?.file_type=='AUDIO';else checkVideo">
            <div class="autio-effect" [class.audio-paused]="audioPreviewPlayer?.paused">
              <ng-container *ngFor="let item of [].constructor(20);"><span class="audio-wave"></span></ng-container>
            </div>
            <audio #audioPreviewPlayer class="w-100"  (pause)="cdr?.detectChanges()" (play)="cdr?.detectChanges()" controls preload="metadata">
              <source [src]="file?.image_download_url" type="audio/mp3" />
            </audio>
            <!-- <app-dashboard-icon  [name]="'audio'" [iconJson]="mediaIcons" size="100"></app-dashboard-icon>  -->
          </ng-container>
          <ng-template #checkVideo>
            <ng-container *ngIf="file?.file_type=='VIDEO'; else otherFiles">
              <video #myVideo class="media-box" controls preload="metadata">
                <source [src]="file?.paths?.url || file?.image_download_url" type="video/mp4" />
              </video>
            </ng-container>
            <ng-template #otherFiles>
              <ngx-doc-viewer [url]="file?.paths?.url || file?.image_download_url" [viewer]="commonUtils.applyDocViewerBasedOnFileEXtension(file?.paths?.url || file?.image_download_url)" style="width: 99%; height: 98%"></ngx-doc-viewer>
              <!-- <app-dashboard-icon [name]="mediaIcons[file.extension] ? file.extension : 'txt'" [iconJson]="mediaIcons" size="100"></app-dashboard-icon>  -->
            </ng-template>
          </ng-template>
        </ng-template>
      </ng-container>
    </ng-template>

    