<!--Facebook Login-->
<script>
     window.fbAsyncInit = function() {
       FB.init({
         appId: '362269573030914',
                 cookie: true, // Enable cookies to allow the server to access the session.
                 xfbml: true,  // Parse social plugins on this webpage.
                 version: 'v18.0',
       });
       /* FB.getLoginStatus(function(response) {
         statusChangeCallback(response);
       }); */
     };
     window.fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {appId: '362269573030914', feature: 'whatsapp_embedded_signup'});
</script>
<script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>
<div class="mr-2">
     <button *ngIf="!showLoader" class="fbLogin" (click)="loginWithChannel()">Login with Facebook</button>
     <button *ngIf="showLoader" class="fbLogin" style="opacity: 0.75;" [disabled]="true">Loading....</button>
     <!-- <h2>OR</h2> -->
<!--      <fb:login-button scope="public_profile,email" config_id="3140181312958359" onlogin="checkLoginState();"></fb:login-button>
 -->    <div id="status"></div>
  </div>
  

<!-- <div class="fb-login-button" data-size="large" data-auto-logout-link="false" data-use-continue-as="true" data-scope="public_profile,email" data-app-id="YOUR_CONFIGURATION_ID"></div>
 -->