import { ChangeDetectionStrategy, ChangeDetectorRef,Component, EventEmitter,HostListener,Input, IterableDiffers, OnInit, Output, SimpleChange, TemplateRef, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BasicUtils } from 'app/services/basicUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';


@Component({
  selector: 'item-view',
  templateUrl: './item-view.component.html',
  styleUrls: ['./item-view.component.scss']
})
export class ItemViewComponent implements OnInit {
  @Output() minimizeRight = new EventEmitter<any>();
  @Input() showMinimizedIcon:boolean=false;
  @Input() itemData=[]
  @Input() currentViewId:any;
  @Input() currentBoardId:any;
  @Input() itemViewType:any=null;
  @Output() closeItemView = new EventEmitter<any>()
  @Output() onItemChange = new EventEmitter<any>();
  @Output() onOtherItemOpen = new EventEmitter<any>();
  @ViewChild('itemView') itemViewTemRef: TemplateRef<any>;
  iterableDiffer:any;
  reloadItem:boolean=true
  modalRef:any;
  isCloseItemView:boolean=true
  itemViewInfo:any={
    viewType: 'EMBEDDED',
    infoWidth: 60,
    widthUnit:'%'
  }
  boardViewInfo:any={}
  constructor(private ngbModalService: NgbModal,private iterableDiffers: IterableDiffers,private cdr: ChangeDetectorRef,private httpTransfer: HttpTransferService,private dashboardUtils : DashboardUtilsService) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  ngOnInit() {  
    if(this.currentViewId && this.currentBoardId){
      this.dashboardUtils.getDashboardViews(this.currentBoardId).then(res=>{
        this.boardViewInfo=res;
        this.itemViewType=res[this.currentViewId]?.personal_settings?.item_settings?.view || this.itemViewInfo['viewType'];
        this.itemViewInfo['viewType']=this.itemViewType;
        let itemSetting=res[this.currentViewId]?.personal_settings?.item_settings
        this.itemViewInfo['infoWidth']=itemSetting?.info_size && itemSetting?.info_size_unit  ? itemSetting?.info_size : this.itemViewInfo['infoWidth'];
        this.openItemInModal();
        this.cdr.detectChanges()
        this.httpTransfer.changeItemModalStatus(this.itemData[this.itemData?.length-1] ?.itemId)

      }) 
    }
  }  


  ngOnDestroy() {
    this.httpTransfer.removedLeadFromModalStatus(this.itemData[this.itemData?.length-1] ?.itemId)
  }

  ngAfterViewInit(){
    if(!this.currentViewId){
      this.itemViewInfo['viewType']=this.itemViewType || this.itemViewInfo['viewType'];
      this.openItemInModal();
    }
  }
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.itemData);
    if (changes) {
      this.reloadItem=false;
      setTimeout(() => {this.reloadItem=true;this.cdr.detectChanges()},0);
    }
  }

  openItemInModal(){
    if(this.itemViewInfo?.viewType=='FULL' || this.itemViewInfo?.viewType=='EMBEDDED'){
      this.isCloseItemView=true
      this.modalRef=this.ngbModalService.open(this.itemViewTemRef, {size: 'xl', windowClass: (this.itemViewType=='FULL' ? 'item-fullscreen-view' : 'item-embedded-view'),backdrop : 'static',keyboard : false});
      this.modalRef.result.then((result) => { if(this.isCloseItemView)this.closeItemView.emit(null)},
      (reason) => {if(this.isCloseItemView)this.closeItemView.emit(null)});
      this.cdr.detectChanges()
    }
    
  }

  updateView(viewInfo){
    if(viewInfo?.viewType && this.itemViewType!=viewInfo?.viewType){
      this.itemViewType=viewInfo?.viewType
      this.itemViewInfo['viewType']=viewInfo?.viewType;
      this.isCloseItemView=false
      this.modalRef?.close()
      this.openItemInModal()
      this.cdr.detectChanges()
    }
    let personaSettings={personal_settings:{item_settings:{view:viewInfo?.viewType,info_size:viewInfo?.infoWidth,info_size_unit:viewInfo?.widthUnit}}}
    this.itemViewInfo['infoWidth']=viewInfo?.infoWidth;
    if(this.currentViewId && this.currentBoardId){
      this.httpTransfer.updateView(personaSettings,this.currentViewId,this.currentBoardId).subscribe(res => {});
      if(!this.boardViewInfo[this.currentViewId]?.personal_settings)this.boardViewInfo[this.currentViewId].personal_settings={}
      this.boardViewInfo[this.currentViewId].personal_settings.item_settings=personaSettings?.personal_settings?.item_settings// update view data
    }
    this.cdr.detectChanges()
  }
}
