import { Component, OnInit, ViewChild, Inject } from "@angular/core";

@Component({
  selector: "loader",
  templateUrl: "loader.component.html",
})
export class LoaderComponent {
  constructor() {}

  ngOnInit() {}
}
