import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomStorageService } from './custom-storage.service';

@Injectable({
  providedIn: 'root'
})
export class BasicUtils {

  modalRef : any[]=[];
  popOverOpenclosestatus: any;

  constructor(
    private customStorageService: CustomStorageService,
    private translate:TranslateService,
  ) { }

  storeModlRef(ref){
    this.modalRef.push(ref);
  }

  isPopoverOpen(value){
    this.popOverOpenclosestatus = value
  }

  closeCurrentModal(){
    if(this.modalRef?.length && (this.popOverOpenclosestatus?.isOpen() != undefined && !this.popOverOpenclosestatus?.isOpen() || this.popOverOpenclosestatus?.isOpen() === undefined)){
      let lastref = this.modalRef.pop()
      lastref.close()
    }
  }

  setSystemLanguage(){
    let lang=this.customStorageService.getItem('language')
    if(lang==="SAME_AS_SYSTEM"){
      lang=window.navigator.language
    }
    let langCode=lang?.toLowerCase().substring(0,2)
    if(!langCode){
      return;
    }
    this.translate.use(langCode).subscribe({error:err =>{
      this.translate.use(this.translate.getDefaultLang())
    }})
  }
}





