<div class="container" *ngIf="items?.length">
    <div class="row options-row" *ngIf="customItem?.display_key">
        <div class="option-text">{{customItem.display_key}}</div>
    </div>
    <div class="mirror-table-body">
        <ng-container *ngFor="let item of items;let index=index">
            <div class="options-row">
                <div *ngIf="!columnView" class="option-text cursorPointer" style="width: 30%;">#{{item.seq_id}}</div>
                <ng-container *ngIf="customField.mirror_column_type === 'Priority'">
                    <div class="option-text priorityMirrorColumn" style="flex-direction: row;">
                        <span [ngStyle]="{'background-color': priorityJson[item?.value].color}" class="priorityBg">
                        {{priorityJson[item?.value].label}}
                        </span>
                        <ng-container *ngIf="!columnView">{{priorityJson[item?.value].name}}</ng-container> 
                    </div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === 'Bucket'">
                    <div class="option-text">{{item?.value}}</div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === 'SingleUser'">
                    <span class="assignusr option-text" style="width: auto;" *ngIf="item?.value && orgUsersJson && orgUsersJson[item?.value]"> 
                        <ngx-avatars class="mr-1" [name]="orgUsersJson[item?.value].name ? orgUsersJson[item?.value].name : 'Assign'" [src]="orgUsersJson[item?.value].image_url" size="25" initialsSize="2" ></ngx-avatars>
                        <span *ngIf="!columnView">{{orgUsersJson[item?.value]?.name ? orgUsersJson[item?.value].name : 'Assign'}}</span>
                    </span>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === 'Sprint'">
                    <div class="option-text">{{item?.value | titlecase}}</div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === 'Tag'">
                    <div class="option-text tagMirror" >
                        <span *ngFor="let tagname of item?.value" class="cardtag">{{tagname}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === 'Text'">
                    <div class="option-text">{{item?.value}}</div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === 'Date' || customField.mirror_column_type === 'DateTime'">
                    <div class="option-text">{{item?.value | date:'dd/MM/yyyy'}}</div>
                </ng-container>   
                <ng-container  *ngIf="customField.mirror_column_type === 'Numeric' ||
                    customField.mirror_column_type === 'Email' ||
                    customField.mirror_column_type === constantService.constant.CUSTOMTYPES.PERCENTAGE ||
                    customField.mirror_column_type === constantService.constant.CUSTOMTYPES.COUNTRY ||
                    customField.mirror_column_type === constantService.constant.CUSTOMTYPES.RADIO ||
                    customField.mirror_column_type === constantService.constant.CUSTOMTYPES.CHECKBOX ||
                    customField.mirror_column_type === constantService.constant.CUSTOMTYPES.MULTISELECT ||
                    customField.mirror_column_type === constantService.constant.CUSTOMTYPES.DROPDOWN ||
                    customField.mirror_column_type === constantService.constant.CUSTOMTYPES.IPADDRESS">
                    <div class="option-text">{{item?.value}}</div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === constantService.constant.CUSTOMTYPES.LOCATION">
                    <div class="option-text">{{item?.value?.description}}</div>
                    <div class="open-map" *ngIf="item?.value && item?.value?.latitude">
                        <a  class="btn btn-outline-primary open-map-btn f-m-r" target="_blank" rel="noopener noreferrer" href="https://maps.google.com/?q={{item?.value?.latitude}},{{item?.value?.longitude}}">Open in map</a>
                    </div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === constantService.constant.CUSTOMTYPES.URL">
                    <div class="option-text">
                        <a [href]="item?.validatedUrl" target="_blank">
                            {{item?.value}}
                        </a>
                    </div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === constantService.constant.CUSTOMTYPES.MULTI_URL">
                    <div class="option-text" *ngIf="item?.value">
                        <ng-container *ngFor="let url of item?.value">
                            <a [href]="item?.validatedUrl" target="_blank">
                                {{url}}
                            </a> 
                        </ng-container>
                      
                    </div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === 'Mobile'">
                    <div class="option-text" *ngIf="item?.value">
                        {{item?.value?.full_mobile_number}}
                    </div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === constantService.constant.CUSTOMTYPES.CURRENCY">
                    <div class="option-text">
                        {{item?.value?.currency}}: 
                        <span class="ml-1">{{item?.value?.amount || 0}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === constantService.constant.CUSTOMTYPES.MULTIUSER">
                    <div class="option-text"> 
                        <ng-container *ngIf="item?.value?.length">
                            <ng-container *ngFor="let userId of item?.value">
                                <ng-container *ngIf="orgUsersJson && orgUsersJson[userId]"> 
                                    <div class="d-flex flex-row" style="align-items: center;">
                                        <ngx-avatars class="mr-1" [name]="orgUsersJson[userId]?.name ? orgUsersJson[userId]?.name : 'No name'" 
                                            [src]="orgUsersJson[userId]?.image_url" size="25">
                                        </ngx-avatars>
                                        <span>{{orgUsersJson[userId]?.name ? orgUsersJson[userId]?.name : 'No name'}}</span> 
                                    </div>
                                </ng-container>
                            </ng-container>   
                        </ng-container>            
                    </div>
                </ng-container>
                <!-- checklist -->
                <ng-container *ngIf="customField.mirror_column_type === 'CheckList'">
                    <div class="option-text">
                      <ng-container *ngFor="let checklist of item.value">
                        <div class="option-text">
                            <img *ngIf="checklist.state=='COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 29.png" alt="">
                            <img *ngIf="checklist.state=='NOT_COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 28.png" alt="">
                            {{checklist?.summary | truncate: 20}}
                        </div>
                      </ng-container>
                    </div>
                </ng-container>    
                <ng-container *ngIf="customField.mirror_column_type === 'Rating'">
                    <div class="option-text">
                        <ngb-rating 
                            [(rate)]="item.value" 
                            [max]="5" 
                            style="font-size: 20px;"
                            [readonly]="true">
                        </ngb-rating>
                    </div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === constantService.constant.CUSTOMTYPES.TEXTAREA">
                    <div class="option-text boardTxtArea" [innerHTML]="item?.value"></div>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === constantService.constant.CUSTOMTYPES.IMAGEFIELD || customField.mirror_column_type === constantService.constant.CUSTOMTYPES.VIDEO || customField.mirror_column_type === constantService.constant.CUSTOMTYPES.AUDIO || customField.mirror_column_type === constantService.constant.CUSTOMTYPES.FILEUPLOAD">
                    <diV class="d-flex cursorPointer">
                        <app-dashboard-icon [name]="customField.mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                        <span  (click)="openMediaPreview(item?.item_id, videoPlayerModalNested, 0)"
                            class="option-text">{{item?.value?.file_name | truncate: 20}}
                        </span>   
                    </diV>
                </ng-container>
                <ng-container *ngIf="customField.mirror_column_type === constantService.constant.CUSTOMTYPES.MULTIIMAGE || customField.mirror_column_type === constantService.constant.CUSTOMTYPES.MULTIAUDIO || customField.mirror_column_type === constantService.constant.CUSTOMTYPES.MULTIFILEUPLOAD || customField.mirror_column_type === constantService.constant.CUSTOMTYPES.MULTIVIDEO">
                    <div class="option-text">
                        <ng-container *ngFor="let file of item?.value; let index = index">
                            <span class="d-flex cursorPointer" (click)="openMediaPreview(item?.item_id, videoPlayerModalNested, index)">
                                <app-dashboard-icon [name]="customField.mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                                <span class="option-text">{{file.file_name | truncate: 20}}</span>
                            </span>
                            <br>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="columnView && index<(items?.length-1)" >,</ng-container>
        </ng-container>
    </div>
</div>

<ng-template #videoPlayerModalNested let-modal>
    <div class="previewBodyModal" style="height: 100%;max-width: 100%;position: relative;">
      <i class="fa fa-close bg-white previewCross" (click)="modal.dismiss()"></i>
      <app-file-preview [mediaArr]="mediaPreview?.mediaArr" [index]="mediaPreview?.index" [type]="'ITEM'" [dashId]="mediaPreview?.dashId" [typeId]="mediaPreview?.leadId"></app-file-preview>
    </div>
</ng-template>
