<div  class="filterNew"  #container [ngClass]="{'filterAdvanced':(localFilterDataObj?.advanced_enable),'filterNewForAutomation':(automationCall || isTriggeredNonItemField),'subtaskFilter':(subtaskFilter),'settingFilter' : settingCall }" [ngStyle]="{
  'position': bottomBarFilter || originalautomationCall ? 'relative' : 'absolute'
}">
    <!---Filter Header-->
    <div class="filterPopoverBox px-0" style="height: 40px;" popoverTitle="Filter" *ngIf="!originalautomationCall">
      <div class="popoverttlbg themeBgColor">
        <span *ngIf="!connectedDashboardArr?.length || enableMultipleBoardFilter"><i class="fa fa-filter" aria-hidden="true"></i> {{'dashboard.subHeader.filter' | translate}}</span>
        <ng-select  [virtualScroll]="true" *ngIf="connectedDashboardArr?.length>0 && !enableMultipleBoardFilter" [clearable]="false" [clearSearchOnAdd]="true" [items]="connectedDashboardArr" [multiple]="false" bindLabel="dashboard_name"
          [closeOnSelect]="true" bindValue="dashboard_id" [(ngModel)]="selectedDashId"
          (ngModelChange)="onFilterDashboardSelect($event)"></ng-select>
          <div class="headerRightArea">
            <div  *ngIf="!onlyAdvanceFilter">
              <label>Advanced</label>
              <span class="float-right ">
                <div class="switch-div ">
                  <label class="switch key-tab" tabindex="0">
                      <input type="checkbox"  [(ngModel)]="localFilterDataObj.advanced_enable" (ngModelChange)="makeDataForAdvanceFilter($event)">
                      <span class="slider round"></span>            
                  </label> 
                </div>
              </span>
            </div>
          <span>
            <img (click)="closeFilter()" src="../../../assets/1x/popover-close.png" role="button" class="key-tab" tabindex="0">
          </span>
          </div>
      </div>
    </div>

    <!----Filter Middle Content Area-->
    <div  *ngIf="!localFilterDataObj?.advanced_enable" class="container containerElem filtrpopover">
      <div class="row g-0 filterMiddleContent">
        <!--Search-->
        <div class="col-12 mt-2">
          <label class="filterLabel row g-0 mt-2">{{'dashboard.filter.search' | translate}}</label>
          <div class="row g-0 mt-1 pr-0" *ngIf="!automationCall">
            <input type="text" class="filterDate" name="from" [(ngModel)]="localFilterDataObj?.search_params.search_text" placeholder=" {{'placeholders.seachPlaceholderText' | translate}}">
          </div>
          <div class="col-12 mt-1 pr-0" *ngIf="automationCall">
            <app-content-editable-input-note [htmlContent]="localFilterDataObj?.search_params.search_text" [item]="actionobj" [filterCall]="true" [columnsJson]="filterColumnJson ? filterColumnJson : {}" [columnIndex]="columnIndex ? columnIndex : 0" [showIndex]="false" [dashId]="dashId" [customFieldArr]="triggerItemcustomFields"  [isTriggeredNonItemField]="false" (onBlur)="localFilterDataObj?.search_params.search_text=$event.text;filterColumnJson=$event.columnsJson;columnIndex=$event.columnIndex" [catchHookReqJson]="catchHookReqJson" [triggerType]="triggerType"></app-content-editable-input-note>
          </div>
        </div>

    
        <!--Assignee-->
        <div *ngIf="orgFiltersUsersForAssigneeArr.length>0" class="col-12 mt-2">
          <label class="filterLabel trimLongName" for="assignee">{{itemAttributesObj?.assigned_to?.label | attributesLabel: itemAttributesObj: 'assigned_to': 'dashboard.filter.assignee' | translate}}</label>
          <div class="col-12 mt-1 assignee-drop-down">
            <ng-select  [virtualScroll]="true" [items]="orgFiltersUsersForAssigneeArr" [multiple]="true" bindLabel="name" [closeOnSelect]="true" [clearSearchOnAdd]="true" bindValue="_id" [searchFn]="commonUtils.onSearchCode"
            [(ngModel)]="localFilterDataObj['assigned_to']" placeholder="Select Assignee">
              <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="flt_user">
                  <div class="user">
                    <div class="align">
                      <ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                    </div>  
                    <div class="box">
                      <span>{{item?.name}}</span>
                      <p class="my-0">{{item?.email}}</p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <!--Assign by-->
        <div class="col-12 mt-2">
          <label class="filterLabel" for="assignee">{{'dashboard.filter.assignedBy' | translate}}</label>
          <div class="col-12 mt-1">
            <ng-select  [virtualScroll]="true" [items]="orgFiltersUsersArrForAssignedBy" [multiple]="true" bindLabel="name" [clearSearchOnAdd]="true" [closeOnSelect]="true" bindValue="_id" [searchFn]="commonUtils.onSearchCode"
              [(ngModel)]="localFilterDataObj['assigned_by_user']" placeholder="Select Assigned By">
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="flt_user">
                  <div class="user">
                    <div class="align">
                      <ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                    </div>  
                    <div class="box">
                      <span>{{item?.name}}</span>
                      <p class="my-0">{{item?.email}}</p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <!--Created By-->
        <div class="col-12 mt-2">
          <div class="filterLabel row g-0 mt-2">{{'dashboard.filter.createdBy' | translate}}</div>
          <div class="row g-0 mt-2 pr-0">
            <ng-select  [virtualScroll]="true" [items]="orgFiltersUsersArrForCreator" [multiple]="true" bindLabel="name" [clearSearchOnAdd]="true" [closeOnSelect]="true" bindValue="_id" [searchFn]="commonUtils.onSearchCode"
                [(ngModel)]="localFilterDataObj['created_by']" placeholder="Select Created By">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="flt_user">
                    <div class="user">
                      <div class="align">
                        <ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                      </div>  
                      <div class="box">
                        <span>{{item?.name}}</span>
                        <p class="my-0">{{item?.email}}</p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
          </div>
        </div>
        <!--Collaborators-->
        <div class="col-12 mt-2" *ngIf="orgFiltersUsersForCollArr.length>0">
          <div class="filterLabel row g-0 mt-2">{{'dashboard.filter.collaborators' | translate}}</div>
          <div class="row g-0 mt-2 pr-0">
            <ng-select  [virtualScroll]="true" [items]="orgFiltersUsersForCollArr" [multiple]="true" bindLabel="name" [closeOnSelect]="true" [clearSearchOnAdd]="true" bindValue="_id" [searchFn]="commonUtils.onSearchCode"
                [(ngModel)]="localFilterDataObj['lead_colloborators_users']" placeholder="Select collaborators">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="flt_user">
                    <div class="user">
                      <div class="align">
                        <ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                      </div>  
                      <div class="box">
                        <span>{{item?.name}}</span>
                        <p class="my-0">{{item?.email}}</p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
          </div>
        </div>
        <!--Priority-->
        <div class="col-12 mt-2">
          <label class="filterLabel trimLongName" for="priority">{{itemAttributesObj?.priority?.label | attributesLabel: itemAttributesObj: 'priority': 'dashboard.filter.priority' | translate}}</label>
          <div class="col-12 mt-1">
            <ng-select  [virtualScroll]="true" [items]="priorityArray" [multiple]="true" bindLabel="value" [closeOnSelect]="false" [clearSearchOnAdd]="true"
            bindValue="id" [(ngModel)]="localFilterDataObj['priority']" (ngModelChange)="triggerItemPriority()" placeholder="Select Priority">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="flt_user">
                <span *ngIf="item.id==3" class="priorityLabel" [style.background]="constantService?.priority_colors[item.id]">U</span>
                <span *ngIf="item.id==2" class="priorityLabel" [style.background]="constantService?.priority_colors[item.id]">H</span>
                <span *ngIf="item.id==1" class="priorityLabel" [style.background]="constantService?.priority_colors[item.id]">M</span>
                <span *ngIf="item.id==0" class="priorityLabel" [style.background]="constantService?.priority_colors[item.id]">L</span>
                <span> {{item.value}}</span><br>
              </div>
            </ng-template>
          </ng-select>
          </div>
        </div>
        <!--Tags-->
        <div class="col-12 mt-2">
          <div class="col-12 px-0" style="display: flex; justify-content:space-between; align-items:center;">
            <label class="filterLabel trimLongName" for="tags">{{itemAttributesObj?.tag_id?.label | attributesLabel: itemAttributesObj: 'tag_id': 'dashboard.filter.tags' | translate}}</label>
          </div>
          <div class="col-12 mt-1">
            <ng-select  [virtualScroll]="true" *ngIf="tagsCorrespondingToDashFilter?.length" [items]="tagsCorrespondingToDashFilter" [clearSearchOnAdd]="true" [multiple]="true" bindLabel="name" [closeOnSelect]="false"
            bindValue="tag_id" [(ngModel)]="localFilterDataObj['tag_id']" placeholder="Select Tags">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <span class="trimLongName">{{item.name}}</span>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <!--Select Bucket-->
        <div *ngIf="filterDropdownBucketList.length>0" class="col-12 mt-2">
          <div class="col-12 px-0" style="display: flex; justify-content:space-between; align-items:center;">
            <label class="col p-0 filterLabel trimLongName" for="priority">{{itemAttributesObj?.bucket_id?.label | attributesLabel: itemAttributesObj: 'bucket_id': 'dashboard.filter.selectBucket' | translate}}</label>
            <span class="col p-0 justify-content-end filterCheckboxArea">
              <input type="checkbox" class="filterCheckBox" [(ngModel)]="nonFinalBucketsFilter" (ngModelChange)="setNonFinalBuckets()">
              <label for="vehicle1 trimLongName" class="my-0"> {{itemAttributesObj?.bucket_id?.label ?  'Non Final '+itemAttributesObj?.bucket_id?.label : 'dashboard.filter.nonFinalBuckets' | translate}}</label>
            </span>
          </div>
          <div class="col-12 mt-1">
            <ng-container *ngIf="filterDropdownBucketList.length>0">
              <ng-select  [virtualScroll]="true" [readonly]="nonFinalBucketsFilter" [items]="filterDropdownBucketList" [multiple]="true" [clearSearchOnAdd]="true" bindLabel="name" [closeOnSelect]="false"
                bindValue="_id" [(ngModel)]="localFilterDataObj['bucket_id']" placeholder="Select Buckets">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div style="display:flex">
                        <div style="width:80%">
                          <span class="pull-left trimLongName">{{item.name}}</span>
                        </div>
                        <div *ngIf="item.is_destination" class="text-center" style="width:15%;">
                          <i class="far fa-check-circle finalBucketIcon" style="color:#55b98a ; padding : 0px"></i>
                          <p  class="trxtGrayColor">{{'dashboard.filter.finalBucket' | translate}}</p>
                      </div> 
                    </div> 
                </ng-template>
              </ng-select>
            </ng-container>
          </div>
        </div>
        <!--Sprint-->  
        <div *ngIf="dashBoardSprintForFilter?.length>2" class="col-12 mt-2">
          <div *ngIf="dashBoardSprintForFilter" class="filterLabel row g-0 mt-2">{{'setting.phase' | translate}}</div>
          <div *ngIf="dashBoardSprintForFilter" class="row g-0 mt-2 pr-0">
                <ng-select  [virtualScroll]="true" [items]="dashBoardSprintForFilter" dropdownPosition="top" [clearSearchOnAdd]="true" [multiple]="true" bindLabel="name" [closeOnSelect]="false"
              bindValue="_id" [(ngModel)]="localFilterDataObj.sprint_id" placeholder="Select phase">
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div style="display:flex">
                  <div style="width:80%">
                    <span class="trimLongName">{{item.name}}</span><br>
                    <p class="trxtGrayColor"*ngIf="item.startDateFormat && item.endDateFormat">{{item.startDateFormat}} - {{item.endDateFormat}}</p>
                    <p class="trxtGrayColor" *ngIf="!item.startDateFormat && !item.endDateFormat"></p>
                    <p class="trxtGrayColor" *ngIf="item.startDateFormat && !item.endDateFormat">{{item.startDateFormat}}- {{'form.noEndDate' | translate}}</p>
                    <p class="trxtGrayColor" *ngIf="!item.startDateFormat && item.endDateFormat">{{'form.noStartDate' | translate}} -{{item.endDateFormat}}</p>
                  </div>
                  <div *ngIf="!item.is_archive" class="pull-right" style="width:20%;">
                    <i *ngIf="item.current_status=='STARTED'" class="dot"></i>
                    <i *ngIf="item.current_status=='STOPPED'" class="dot activedone"></i> 
                    <p class="trxtGrayColor" *ngIf="item.current_status=='STARTED' && !item.is_archive">In Progress</p>
                    <p class="trxtGrayColor" *ngIf="item.current_status=='STOPPED' && !item.is_archive">Completed</p>
                 </div>
                 <div *ngIf="item.is_archive" class="pull-right" style="width:20%;">
                    <img class="arcicon"src="../../../assets/img/icons/archive.svg"/>
                  </div>
                  <div *ngIf="item.is_hidden" class="pull-right" style="width:20%;">
                    <img src="../../../assets/img/icons/eye.svg" />
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <!--Form-->  
        <div *ngIf="dashboardFormsArr?.length>0" class="col-12 mt-2">
          <div *ngIf="dashboardFormsArr" class="filterLabel row g-0 mt-2">{{'setting.forms' | translate}}</div>
          <div *ngIf="dashboardFormsArr" class="row g-0 mt-2 pr-0">
                <ng-select  [virtualScroll]="true" [items]="dashboardFormsArr" dropdownPosition="top" [multiple]="true" [clearSearchOnAdd]="true" bindLabel="form_name" [closeOnSelect]="false"
              bindValue="_id" placeholder="Select Forms" [(ngModel)]="localFilterDataObj.survey_form_id"></ng-select>
          </div>
        </div>

         <ng-template #datePopoverFrom>
          <date-picker  [dateRange]="{'from':localFilterDataObj[currentDateKey]?.from}" [dateConfig]="{'maxDate':localFilterDataObj[currentDateKey]?.to}" [showHeader]="false" [showClearOrSaveBtn]="false" [focusDate]="localFilterDataObj[currentDateKey]?.from" (onDateSelect)="popoverRef?.hide();localFilterDataObj[currentDateKey].from = {'date':$event?.date,'time':$event?.time}"></date-picker>
        </ng-template>
        <ng-template #datePopoverTo>
          <date-picker  [dateRange]="{'to':localFilterDataObj[currentDateKey]?.to}" [dateConfig]="{'minDate':localFilterDataObj[currentDateKey]?.from}" [focusDate]="localFilterDataObj[currentDateKey]?.to" [showHeader]="false" [showClearOrSaveBtn]="false" (onDateSelect)="popoverRef?.hide();localFilterDataObj[currentDateKey].to = {'date':$event?.date,'time':$event?.time}"></date-picker>
        </ng-template>
        <!--Create Date -->
        <div class="col-12 mt-2">
          <div class="filterLabel col-12">{{'dashboard.filter.createDate' | translate}}</div>
          <div class="row" *ngIf="!automationCall">
            <!-- | date:'shortDate' -->
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="from">{{'dashboard.filter.from' | translate}}</label>
              <div class="dateInput" container="body" [popover]="datePopoverFrom" placement="auto" #createDateFromPopup="bs-popover" [outsideClick]="true" containerClass="datePickerPopover" (click)="popoverRef=createDateFromPopup;currentDateKey='create_date'">
                {{localFilterDataObj?.create_date?.from | dateCalcuate:"dateTime"}}
                <span *ngIf="!localFilterDataObj?.create_date?.from" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.create_date.from = null" class="closeIcon">x</span>
              </div>
              
            </div>
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="to">{{'dashboard.filter.to' | translate}}</label>
              <div class="dateInput" container="body" [popover]="datePopoverTo" placement="auto" #createDateToPopup="bs-popover" [outsideClick]="true" (click)="popoverRef=createDateToPopup;currentDateKey='create_date'">
                {{localFilterDataObj?.create_date?.to | dateCalcuate:"dateTime"}}
                <span *ngIf="!localFilterDataObj?.create_date?.to" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.create_date.to = null" class="closeIcon">x</span>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="automationCall">
            <!-- | date:'shortDate' -->
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="from">{{'dashboard.filter.from' | translate}}</label>
              <div *ngIf="localFilterDataObj?.create_date && (localFilterDataObj?.create_date.from!='customDate' && !isTypeNumber(localFilterDataObj?.create_date.from)) ">
                <ng-select  [virtualScroll]="true"  [items]="dateOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
              bindValue="_id" [(ngModel)]="localFilterDataObj.create_date.from" (ngModelChange)="localFilterDataObj?.create_date.from = $event"></ng-select>
              </div>
              <div *ngIf="localFilterDataObj?.create_date.from=='customDate' || isTypeNumber(localFilterDataObj?.create_date.from)" class="dateInput" container="body" [popover]="datePopoverFrom" placement="auto" containerClass="datePickerPopover" #createDateFromPopup="bs-popover" [outsideClick]="true" (click)="popoverRef=createDateFromPopup;currentDateKey='create_date'">
                <span *ngIf="localFilterDataObj?.create_date.from!='customDate'">{{localFilterDataObj?.create_date?.from | dateCalcuate:"dateTime"}}</span> 
                <span *ngIf="!localFilterDataObj?.create_date.from || localFilterDataObj?.create_date.from=='customDate'" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.create_date.from = null" class="closeIcon">x</span>
              </div>
              
            </div>
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="to">{{'dashboard.filter.to' | translate}}</label>
              <div *ngIf="localFilterDataObj?.create_date && (localFilterDataObj?.create_date.to!='customDate' && !isTypeNumber(localFilterDataObj?.create_date.to)) ">
                <ng-select  [virtualScroll]="true"  [items]="dateOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
              bindValue="_id" [(ngModel)]="localFilterDataObj.create_date.to" (ngModelChange)="localFilterDataObj?.create_date.to = $event"></ng-select>
              </div>
              <div *ngIf="localFilterDataObj?.create_date.to=='customDate' || isTypeNumber(localFilterDataObj?.create_date.to)" class="dateInput" container="body" [popover]="datePopoverTo" placement="auto" #createDateToPopup="bs-popover" [outsideClick]="true" (click)="popoverRef=createDateToPopup;currentDateKey='create_date'">
                <span *ngIf="localFilterDataObj?.create_date.to!='customDate'">{{localFilterDataObj?.create_date?.to | dateCalcuate:"dateTime"}}</span> 
                <span *ngIf="!localFilterDataObj?.create_date.to || localFilterDataObj?.create_date.to=='customDate'" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.create_date.to = null" class="closeIcon">x</span>
              </div>
            </div>
          </div>
        </div>

       
        <!--Start Date-->
        <div class="col-12 mt-2">
          <div class="row g-0 mx-0" style="display: flex; justify-content:space-between; align-items:center;">
            <label class="col p-0 filterLabel">{{'dashboard.filter.startDate' | translate}}</label>
            <span class="col p-0 justify-content-end filterCheckboxArea">
              <input type="checkbox" class="filterCheckBox" [(ngModel)]="localFilterDataObj.start_date.isNull" (ngModelChange)="resetDateFilter('start_date')">
              <label for="vehicle1" class="my-0">{{'dashboard.filter.withoutStartDate' | translate}}</label>
            </span>
          </div>
          <div class="row" *ngIf="!automationCall">
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="from">{{'dashboard.filter.from' | translate}}</label>
              <div class="dateInput" container="body" [popover]="!localFilterDataObj?.start_date.isNull && datePopoverFrom" placement="auto" containerClass="datePickerPopover" #startDateFromPopup="bs-popover" [outsideClick]="true" (click)="popoverRef=startDateFromPopup;currentDateKey='start_date'">
                {{localFilterDataObj?.start_date?.from | dateCalcuate:"dateTime"}}
                <span *ngIf="!localFilterDataObj?.start_date?.from" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.start_date.from = null" class="closeIcon">x</span>
              </div>
            </div>
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="to">{{'dashboard.filter.to' | translate}}</label>
              <div class="dateInput" container="body" [popover]="!localFilterDataObj?.start_date.isNull && datePopoverTo" placement="auto" containerClass="datePickerPopover" #startDateToPopup="bs-popover" [outsideClick]="true" (click)="popoverRef=startDateToPopup;currentDateKey='start_date'">
                {{localFilterDataObj?.start_date?.to | dateCalcuate:"dateTime"}}
                <span *ngIf="!localFilterDataObj?.start_date?.to" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.start_date.to = null" class="closeIcon">x</span>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="automationCall">
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="from">{{'dashboard.filter.from' | translate}}</label>
              <div *ngIf="localFilterDataObj?.start_date && (localFilterDataObj?.start_date.from!='customDate' && !isTypeNumber(localFilterDataObj?.start_date.from)) ">
                <ng-select  [virtualScroll]="true"  [items]="dateOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
              bindValue="_id" [(ngModel)]="localFilterDataObj.start_date.from" (ngModelChange)="localFilterDataObj?.start_date.from = $event"></ng-select>
              </div>
              <div *ngIf="localFilterDataObj?.start_date.from=='customDate'|| isTypeNumber(localFilterDataObj?.start_date.from)" class="dateInput" container="body" [popover]="datePopoverFrom" #startDateFromPopup="bs-popover" placement="auto" containerClass="datePickerPopover" [outsideClick]="true" (click)="popoverRef=startDateFromPopup;currentDateKey='start_date'">
                <span *ngIf="localFilterDataObj?.start_date.from!='customDate' ">{{localFilterDataObj?.start_date?.from | dateCalcuate:"dateTime"}}</span> 
                <span *ngIf="!localFilterDataObj?.start_date.from || localFilterDataObj?.start_date.from=='customDate'" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.start_date.from = null" class="closeIcon">x</span>
              </div>
              
            </div>
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="to">{{'dashboard.filter.to' | translate}}</label>
              <div *ngIf="localFilterDataObj?.start_date && (localFilterDataObj?.start_date.to!='customDate' && !isTypeNumber(localFilterDataObj?.start_date.to)) ">
                <ng-select  [virtualScroll]="true"  [items]="dateOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
              bindValue="_id" [(ngModel)]="localFilterDataObj.start_date.to" (ngModelChange)="localFilterDataObj?.start_date.to = $event"></ng-select>
              </div>
              <div *ngIf="localFilterDataObj?.start_date.to=='customDate' || isTypeNumber(localFilterDataObj?.start_date.to)" class="dateInput" container="body" [popover]="datePopoverTo" #startDateToPopup="bs-popover" placement="auto" [outsideClick]="true" (click)="popoverRef=startDateToPopup;currentDateKey='start_date'">
                <span *ngIf="localFilterDataObj?.start_date.to!='customDate'">{{localFilterDataObj?.start_date?.to | dateCalcuate:"dateTime"}}</span> 
                <span *ngIf="!localFilterDataObj?.start_date.to || localFilterDataObj?.start_date.to=='customDate'" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.start_date.to = null" class="closeIcon">x</span>
              </div>
            </div>
          </div>

        </div>

        <!--Due Date-->
        <div class="col-12 mt-2">
          <div class="row g-0 mx-0" style="display: flex; justify-content:space-between; align-items:center;">
            <label class="col p-0 filterLabel">{{'dashboard.filter.dueDate' | translate}}</label>
            <span class="col p-0 justify-content-end filterCheckboxArea">
              <input type="checkbox" class="filterCheckBox" [(ngModel)]="localFilterDataObj.due_date.isNull" (ngModelChange)="resetDateFilter('due_date')">
              <label for="vehicle1" class="my-0">{{'dashboard.filter.withoutDueDate' | translate}}</label>
            </span>
          </div>
          <div class="row" *ngIf="!automationCall">
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="from">{{'dashboard.filter.from' | translate}}</label>
              <div class="dateInput" container="body" [popover]="datePopoverFrom" placement="auto" [outsideClick]="true" containerClass="datePickerPopover" #dueDateFromPopup="bs-popover" (click)="popoverRef=dueDateFromPopup;currentDateKey='due_date'">
                {{localFilterDataObj?.due_date?.from | dateCalcuate:"dateTime"}}
                <span *ngIf="!localFilterDataObj?.due_date?.from" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.due_date.from = null" class="closeIcon">x</span>
              </div>
            </div>
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="to">{{'dashboard.filter.to' | translate}}</label>
              <div class="dateInput" container="body" [popover]="datePopoverTo" placement="auto" [outsideClick]="true" containerClass="datePickerPopover" #dueDateToPopup="bs-popover" (click)="popoverRef=startDateToPopup;currentDateKey='due_date'">
                {{localFilterDataObj?.due_date?.to | dateCalcuate:"dateTime"}}
                <span *ngIf="!localFilterDataObj?.due_date?.to" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.due_date.to = null" class="closeIcon">x</span>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="automationCall">
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="from">{{'dashboard.filter.from' | translate}}</label>
              <div *ngIf="localFilterDataObj?.due_date && (localFilterDataObj?.due_date.from!='customDate' && !isTypeNumber(localFilterDataObj?.due_date.from)) ">
                <ng-select  [virtualScroll]="true"  [items]="dateOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
              bindValue="_id" [(ngModel)]="localFilterDataObj.due_date.from" (ngModelChange)="localFilterDataObj?.due_date.from = $event"></ng-select>
              </div>
              <div *ngIf="localFilterDataObj?.due_date.from=='customDate' || isTypeNumber(localFilterDataObj?.due_date.from)" class="dateInput" container="body" [popover]="datePopoverFrom" placement="auto" containerClass="datePickerPopover" [outsideClick]="true" #dueDateFromPopup="bs-popover" (click)="popoverRef=dueDateFromPopup;currentDateKey='due_date'">
                <span *ngIf="localFilterDataObj?.due_date.from!='customDate'">{{localFilterDataObj?.due_date?.from | dateCalcuate:"dateTime"}}</span> 
                <span *ngIf="!localFilterDataObj?.due_date.from || localFilterDataObj?.due_date.from=='customDate'" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.due_date.from = null" class="closeIcon">x</span>
              </div>
              
            </div>
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="to">{{'dashboard.filter.to' | translate}}</label>
              <div *ngIf="localFilterDataObj?.due_date && (localFilterDataObj?.due_date.to!='customDate' && !isTypeNumber(localFilterDataObj?.due_date.to)) ">
                <ng-select  [virtualScroll]="true"  [items]="dateOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
              bindValue="_id" [(ngModel)]="localFilterDataObj.due_date.to" (ngModelChange)="localFilterDataObj?.due_date.to = $event"></ng-select>
              </div>
              <div *ngIf="localFilterDataObj?.due_date.to=='customDate' || isTypeNumber(localFilterDataObj?.due_date.to)" class="dateInput" container="body" [popover]="datePopoverTo" placement="auto" containerClass="datePickerPopover" [outsideClick]="true" #dueDateToPopup="bs-popover" (click)="popoverRef=dueDateToPopup;currentDateKey='due_date'">
                <span *ngIf="localFilterDataObj?.due_date.to!='customDate'">{{localFilterDataObj?.due_date?.to | dateCalcuate:"dateTime"}}</span> 
                <span *ngIf="!localFilterDataObj?.due_date.to || localFilterDataObj?.due_date.to=='customDate'" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.due_date.to = null" class="closeIcon">x</span>
              </div>
            </div>
          </div>
        </div>
        <!--Last Updated-->
        <div class="col-12 mt-2">
          <div class="filterLabel col-12 mt-2">{{'dashboard.filter.lastUpdatedDate' | translate}}</div>
          <div class="row" *ngIf="!automationCall">
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="assignee">{{'dashboard.filter.from' | translate}}</label>
              <div class="dateInput" container="body" [popover]="datePopoverFrom" placement="auto" [outsideClick]="true" containerClass="datePickerPopover" #updateDateFromPopup="bs-popover" (click)="popoverRef=updateDateFromPopup;currentDateKey='last_updated'">
                {{localFilterDataObj?.last_updated?.from | dateCalcuate:"dateTime"}}
                <span *ngIf="!localFilterDataObj?.last_updated?.from" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.last_updated.from = null" class="closeIcon">x</span>
              </div>
            </div>
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="assignee">{{'dashboard.filter.to' | translate}}</label>
              <div class="dateInput" container="body" [popover]="datePopoverTo" placement="auto" [outsideClick]="true" containerClass="datePickerPopover" #updateDateToPopup="bs-popover" (click)="popoverRef=updateDateToPopup;currentDateKey='last_updated'">
                {{localFilterDataObj?.last_updated?.to | dateCalcuate:"dateTime"}}
                <span *ngIf="!localFilterDataObj?.last_updated?.to" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.last_updated.to = null" class="closeIcon">x</span>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="automationCall">
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="from">{{'dashboard.filter.from' | translate}}</label>
              <div *ngIf="localFilterDataObj?.last_updated && (localFilterDataObj?.last_updated.from!='customDate' && !isTypeNumber(localFilterDataObj?.last_updated.from)) ">
                <ng-select  [virtualScroll]="true"  [items]="dateOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
              bindValue="_id" [(ngModel)]="localFilterDataObj.last_updated.from" (ngModelChange)="localFilterDataObj?.last_updated.from = $event"></ng-select>
              </div>
              <div *ngIf="localFilterDataObj?.last_updated.from=='customDate' || isTypeNumber(localFilterDataObj?.last_updated.from)" class="dateInput" container="body" [popover]="datePopoverFrom" containerClass="datePickerPopover" placement="auto" [outsideClick]="true" #updateDateFromPopup="bs-popover" (click)="popoverRef=updateDateFromPopup;currentDateKey='last_updated'">
                <span *ngIf="localFilterDataObj?.last_updated.from!='customDate'">{{localFilterDataObj?.last_updated?.from | dateCalcuate:"dateTime"}}</span> 
                <span *ngIf="!localFilterDataObj?.last_updated.from || localFilterDataObj?.last_updated.from=='customDate'" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.last_updated.from = null" class="closeIcon">x</span>
              </div>
              
            </div>
            <div class="col-6 pr-0">
              <label class="filterLabel col-12" for="to">{{'dashboard.filter.to' | translate}}</label>
              <div *ngIf="localFilterDataObj?.last_updated && (localFilterDataObj?.last_updated.to!='customDate' && !isTypeNumber(localFilterDataObj?.last_updated.to)) ">
                <ng-select  [virtualScroll]="true"  [items]="dateOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
              bindValue="_id" [(ngModel)]="localFilterDataObj.last_updated.to" (ngModelChange)="localFilterDataObj?.last_updated.to = $event"></ng-select>
              </div>
              <div *ngIf="localFilterDataObj?.last_updated.to=='customDate' || isTypeNumber(localFilterDataObj?.last_updated.to)" class="dateInput" container="body" [popover]="datePopoverTo" containerClass="datePickerPopover" placement="auto" [outsideClick]="true" #updateDateToPopup="bs-popover" (click)="popoverRef=updateDateToPopup;currentDateKey='last_updated'">
                <span *ngIf="localFilterDataObj?.last_updated.to!='customDate'">{{localFilterDataObj?.last_updated?.to | dateCalcuate:"dateTime"}}</span> 
                <span *ngIf="!localFilterDataObj?.last_updated.to || localFilterDataObj?.last_updated.to=='customDate'" class="datePlaceHolderText">DD/MM/YYYY</span>
                <span (click)="$event.stopPropagation();localFilterDataObj?.last_updated.to = null" class="closeIcon">x</span>
              </div>
            </div>
          </div>
        </div>

             <!--Leads-->
             <div class="col-12 mt-2">
              <label class="filterLabel row g-0 mt-2">{{'dashboard.filter.itemsOfBoard' | translate  :{ term: "Item" | terminology: itemTerminology?.plural : 'Item' } }}</label>
              <ng-select  [virtualScroll]="true" [items]="dashBoardLeadList" [multiple]="true" [(ngModel)]="localFilterDataObj['lead_id']" [closeOnSelect]="false" placeholder="Select" [clearSearchOnAdd]="true"
              bindValue="_id" [searchFn]="getSearchMirrorLead" (focus)="getLeadsofBoard()">
              <ng-template ng-option-tmp ng-label-tmp let-item="item">
                <span *ngIf="item?.title"><span *ngIf="item?.seq_id">#</span>{{item.seq_id}} {{item.title}} </span>
                <span *ngIf="!item?.title"> {{'dashboard.filter.deletedItems' | translate :{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }} </span> 
              </ng-template>
            </ng-select>
            </div>

        <!--Custom Field value-->
        <div class="col-12 customFieldFilters">
          <!-- <strong class="filterLabel col-12 mt-2">Advance Filters</strong> -->
          <ng-container *ngFor="let customField of filterCopyOfCustomFields">
             <!--user-->
         <div *ngIf="customField.type === 'SingleUser' || customField.type === 'MultiUser'" class="col-12 mt-2">
          <label class="filterLabel" for="tags">{{customField.display_key}}</label>
            <ng-select  [virtualScroll]="true" [items]="customField.type === 'SingleUser' ? orgFiltersSingelUsersArr : orgFiltersMultiUsersArr" [multiple]="true" bindLabel="name" [closeOnSelect]="true" bindValue="_id" [searchFn]="commonUtils.onSearchCode"
            [(ngModel)]="localFilterDataObj.custom_column[customField.key]">
              <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="flt_user">
                  <div class="user">
                    <div class="align">
                      <ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                    </div>  
                    <div class="box">
                      <span>{{item?.name}}</span>
                      <p class="my-0">{{item?.email}}</p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-select>
        </div>
            <!--Number Field-->
          <ng-container *ngIf="(customField.type === constantService.constant.CUSTOMTYPES.NUMBER || customField.type === constantService.constant.CUSTOMTYPES.PERCENTAGE) && !automationCall && localFilterDataObj?.custom_column[customField.key]">
              <div class="filterLabel col-12 mt-2">{{customField.display_key}}</div>
              <div class="row g-0 mt-1">
                <div class="col-6 pr-0">
                  <label class="filterLabel" for="from">{{'dashboard.filter.from' | translate}}</label>
                  <input type="number" placeholder="Enter Number" class="filterDate" name="from" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from">
                </div>
                <div class="col-6 pr-0">
                  <label class="filterLabel" for="to">{{'dashboard.filter.to' | translate}}</label> 
                  <input type="number" placeholder="Enter Number" class="filterDate" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to">
                </div>
              </div>
              <span style="color:red;font-size: 8px;width:100%;text-align: center;" *ngIf="localFilterDataObj.custom_column[customField.key].from>localFilterDataObj.custom_column[customField.key].to">From should not be smaller than To</span>
          </ng-container>

          <ng-container *ngIf="(customField.type === constantService.constant.CUSTOMTYPES.NUMBER || customField.type === constantService.constant.CUSTOMTYPES.PERCENTAGE) && automationCall">
            <label class="filterLabel col-12 mt-2">{{customField.display_key}}</label>
            <div class="row g-0 w-100 mx-0">
              <div class="col-6 px-0">
                <label class="filterLabel col-12 px-0" for="from">{{'dashboard.filter.from' | translate}}</label>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key] && (localFilterDataObj?.custom_column[customField.key]?.from!='customNumber' && !isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.from)) ">
                  <ng-select  [virtualScroll]="true"  [items]="numberOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from" (ngModelChange)="localFilterDataObj.custom_column[currentDateKey].from = $event"></ng-select>
                </div>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key]?.from=='customNumber' || isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.from)" class="dateInput">
                  <input type="number" placeholder="Enter Number" class="filterDate" style="border: none !important;" name="from" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from">
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].from = null" class="closeIcon">x</span>
                </div>
                
              </div>
              <div class="col-6 pr-0 pl-1">
                <label class="filterLabel col-12 px-0" for="to">{{'dashboard.filter.to' | translate}}</label>
                 <div *ngIf="localFilterDataObj?.custom_column[customField.key] && (localFilterDataObj?.custom_column[customField.key]?.to!='customNumber' && !isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.to)) ">
                  <ng-select  [virtualScroll]="true"  [items]="numberOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to" (ngModelChange)="localFilterDataObj.custom_column[currentDateKey].to = $event"></ng-select>
                </div>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key]?.to=='customNumber' || isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.to)" class="dateInput" >
                  <input type="number" placeholder="Enter Number" class="filterDate" style="border: none !important;" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to">
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].to = null" class="closeIcon">x</span>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- currency -->
          <ng-container *ngIf="customField.type === constantService.constant.CUSTOMTYPES.CURRENCY && !automationCall && localFilterDataObj?.custom_column[customField.key]">
            <div class="filterLabel col-12 mt-2">{{customField.display_key}}</div>
            <div class="row g-0 w-100 mx-0">
              <div class="col-6 pr-0 pl-0">
                <label class="filterLabel" for="from">{{'dashboard.filter.from' | translate}}</label>
                <input type="number" placeholder="Enter Amount" class="filterDate" name="from" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from">
              </div>
              <div class="col-6 pr-0 pl-1">
                <label class="filterLabel" for="to">{{'dashboard.filter.to' | translate}}</label>
                <input type="number" placeholder="Enter Amount" class="filterDate" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to">
              </div>
            </div>
            <span style="color:red;font-size: 8px;width:100%;text-align: center;" *ngIf="localFilterDataObj.custom_column[customField.key].from>localFilterDataObj.custom_column[customField.key].to">From should not be smaller than To</span>
          </ng-container> 
          <ng-container *ngIf="customField.type === constantService.constant.CUSTOMTYPES.CURRENCY && automationCall">
            <label class="filterLabel col-12 mt-2">{{customField.display_key}}</label>
            <div class="row g-0 w-100 mx-0">
              <div class="col-6 px-0">
                <label class="filterLabel col-12 px-0" for="from">{{'dashboard.filter.from' | translate}}</label>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key] && (localFilterDataObj?.custom_column[customField.key]?.from!='customAmount' && !isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.from)) ">
                  <ng-select  [virtualScroll]="true"  [items]="currencyOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from" (ngModelChange)="localFilterDataObj.custom_column[currentDateKey].from = $event"></ng-select>
                </div>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key]?.from=='customAmount' || isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.from)" class="dateInput">
                  <input type="number" placeholder="Enter Amount" class="filterDate" style="border: none !important;" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from">
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].from = null" class="closeIcon">x</span>
                </div>
                
              </div>
              <div class="col-6 pr-0 pl-1">
                <label class="filterLabel col-12 px-0" for="to">{{'dashboard.filter.to' | translate}}</label>
                 <div *ngIf="localFilterDataObj?.custom_column[customField.key] && (localFilterDataObj?.custom_column[customField.key]?.to!='customAmount' && !isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.to)) ">
                  <ng-select  [virtualScroll]="true"  [items]="currencyOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to" (ngModelChange)="localFilterDataObj.custom_column[currentDateKey].to = $event"></ng-select>
                </div>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key]?.to=='customAmount' || isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.to)" class="dateInput" >
                  <input type="number" placeholder="Enter Amount" class="filterDate" style="border: none !important;" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to">
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].to = null" class="closeIcon">x</span>
                </div>
              </div>
            </div>
          </ng-container>


          <!-- text type -->
          <ng-container *ngIf="['Email','MultipleEmail','Text','Url','Textarea','MultiUrl'].includes(customField?.type)">
            <div class="filterLabel col-12 mt-2">{{customField.display_key}}</div>
            <div class="col-12 mt-1 pr-0" *ngIf="!automationCall">
               <input placeholder="Enter Text" *ngIf="customField.type!='Text' && customField.type!='Textarea'" type="text" class="filterDate" name="from" [(ngModel)]="localFilterDataObj.custom_column[customField.key]">
              <textarea placeholder="Enter Text" *ngIf="(customField.type=='Text' || customField.type=='Textarea')" class="columsEditor" autosize [minRows]="3" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" (click)="cursorPosition($event)" (keyup)="cursorPosition($event)"></textarea>
              <span *ngIf="automationCall && !isTriggeredNonItemField" style="position: absolute;
              bottom: 6px;margin-left:-30px" placement="auto" [ngbPopover]="customColumns" #popColumns="ngbPopover" container="body" [autoClose]="'outside'" (click)="columnsCall(customField)">{{ '\{\{ \}\}'}}</span>
            </div> 
            <div class="col-12 mt-1 pr-0" *ngIf="automationCall">
              <app-content-editable-input-note [htmlContent]="localFilterDataObj.custom_column[customField.key]" [item]="actionobj" [filterCall]="true" [customfieldType]="customField.type" [columnsJson]="filterColumnJson ? filterColumnJson : {}" [columnIndex]="columnIndex ? columnIndex : 0" [showIndex]="false"[dashId]="dashId" [customFieldArr]="triggerItemcustomFields"  [isTriggeredNonItemField]="false" (onBlur)="localFilterDataObj.custom_column[customField.key]=$event.text;filterColumnJson=$event.columnsJson;columnIndex=$event.columnIndex" [filterCustomColumnKey]="customField.key" [catchHookReqJson]="catchHookReqJson" [triggerType]="triggerType"></app-content-editable-input-note>
            </div>
          </ng-container> 
          <!--Rating field -->
          <ng-container *ngIf="customField.type === constantService.constant.CUSTOMTYPES.RATING && !automationCall && localFilterDataObj.custom_column[customField.key]">
            <div class="filterLabel col-12 mt-2">{{customField.display_key}} </div>
              <div class="row mx-0 w-100 g-0">
                <div class="col-6 pr-0 pl-0">
                  <label class="filterLabel" for="from">{{'dashboard.filter.from' | translate}}</label>
                  <input type="number" placeholder="Enter Number" class="filterDate" name="from" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from">
                </div>
                <div class="col-6 pr-0 pl-1">
                  <label class="filterLabel" for="to">{{'dashboard.filter.to' | translate}}</label>
                  <input type="number" placeholder="Enter Number" class="filterDate" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to">
                </div>
              </div>
            <span style="color:red;font-size: 8px;width:100%;text-align: center;" *ngIf="localFilterDataObj.custom_column[customField.key].from>localFilterDataObj.custom_column[customField.key].to">From should not be smaller than To</span>
          </ng-container>
          <ng-container *ngIf="customField.type === constantService.constant.CUSTOMTYPES.RATING && automationCall">
            <label class="filterLabel col-12 mt-2">{{customField.display_key}}</label>
            <div class="row g-0 w-100 mx-0">
              <div class="col-6 px-0">
                <label class="filterLabel col-12 px-0" for="from">{{'dashboard.filter.from' | translate}}</label>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key] && (localFilterDataObj?.custom_column[customField.key]?.from!='customRating' && !isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.from)) ">
                  <ng-select  [virtualScroll]="true"  [items]="ratingOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from" (ngModelChange)="localFilterDataObj.custom_column[currentDateKey].from = $event"></ng-select>
                </div>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key]?.from=='customRating' || isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.from)" class="dateInput">
                  <input type="number" placeholder="Enter Number" class="filterDate"  style="border: none !important;" name="from" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from">
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].from = null" class="closeIcon">x</span>
                </div>
                
              </div>
              <div class="col-6 pr-0 pl-1">
                <label class="filterLabel col-12 px-0" for="to">{{'dashboard.filter.to' | translate}}</label>
                 <div *ngIf="localFilterDataObj?.custom_column[customField.key] && (localFilterDataObj?.custom_column[customField.key]?.to!='customRating' && !isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.to)) ">
                  <ng-select  [virtualScroll]="true"  [items]="ratingOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to" (ngModelChange)="localFilterDataObj.custom_column[currentDateKey].to = $event"></ng-select>
                </div>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key]?.to=='customRating' || isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.to)" class="dateInput" >
                  <input type="number" placeholder="Enter Number" class="filterDate"  style="border: none !important;" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to">
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].to = null" class="closeIcon">x</span>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-template #customDatePopoverFrom>
            <date-picker  *ngIf="currentDateKey" [dateRange]="{'from':localFilterDataObj?.custom_column[currentDateKey]?.from}" [dateConfig]="{'maxDate':localFilterDataObj.custom_column[currentDateKey]?.to}" [showHeader]="false" [showClearOrSaveBtn]="false" [focusDate]="localFilterDataObj?.custom_column[currentDateKey]?.from" (onDateSelect)="popoverRef?.hide();localFilterDataObj.custom_column[currentDateKey].from ={'date':$event?.date,'time':$event?.time}"></date-picker>
          </ng-template>
          <ng-template #customDatePopoverTo>
            <date-picker  *ngIf="currentDateKey" [dateRange]="{'to':localFilterDataObj.custom_column[currentDateKey]?.to}" [dateConfig]="{'minDate':localFilterDataObj.custom_column[currentDateKey]?.from}" [focusDate]="localFilterDataObj.custom_column[currentDateKey]?.to" [showHeader]="false" [showClearOrSaveBtn]="false" (onDateSelect)="popoverRef?.hide();localFilterDataObj.custom_column[currentDateKey].to = {'date':$event?.date,'time':$event?.time}"></date-picker>
          </ng-template>

          <!--Date Field-->
          <ng-container *ngIf="customField.type === constantService.constant.CUSTOMTYPES.DATE && !automationCall">
            <label class="filterLabel col-12 mt-2">{{customField.display_key}}</label>
            <div class="row g-0 w-100 mx-0">
              <div class="col-6 px-0">
                <label class="filterLabel col-12 px-0" for="from">{{'dashboard.filter.from' | translate}}</label>
                <div class="dateInput" container="body" [popover]="customDatePopoverFrom" placement="auto" [outsideClick]="true" containerClass="datePickerPopover" #customDateFromPopup="bs-popover" (click)="popoverRef=customDateFromPopup;currentDateKey=customField.key">
                  <span *ngIf="localFilterDataObj?.custom_column[customField.key]?.from">{{localFilterDataObj?.custom_column[customField.key].from | dateCalcuate:"dateTime"}}</span> 
                  <span *ngIf="!localFilterDataObj?.custom_column[customField.key]?.from" class="datePlaceHolderText">DD/MM/YYYY</span>
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].from = null" class="closeIcon">x</span>
                </div>
                
              </div>
              <div class="col-6 pr-0 pl-1">
                <label class="filterLabel col-12 px-0" for="to">{{'dashboard.filter.to' | translate}}</label>
                <div class="dateInput" container="body" [popover]="customDatePopoverTo" placement="auto" [outsideClick]="true" containerClass="datePickerPopover" #customDateToPopup="bs-popover" (click)="popoverRef=customDateToPopup;currentDateKey=customField.key">
                  <span *ngIf="localFilterDataObj?.custom_column[customField.key]?.to">{{localFilterDataObj?.custom_column[customField.key]?.to | dateCalcuate:"dateTime"}}</span>
                  <span *ngIf="!localFilterDataObj?.custom_column[customField.key]?.to" class="datePlaceHolderText">DD/MM/YYYY</span>
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].to = null" class="closeIcon">x</span>
                
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="customField.type === constantService.constant.CUSTOMTYPES.DATE && automationCall">
            <label class="filterLabel col-12 mt-2">{{customField.display_key}}</label>
            <div class="row g-0 w-100 mx-0">
              <div class="col-6 px-0">
                <label class="filterLabel col-12 px-0" for="from">{{'dashboard.filter.from' | translate}}</label>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key] && (localFilterDataObj?.custom_column[customField.key]?.from!='customDate' && !isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.from)) ">
                  <ng-select  [virtualScroll]="true"  [items]="dateOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from" (ngModelChange)="localFilterDataObj.custom_column[currentDateKey].from = $event"></ng-select>
                </div>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key]?.from=='customDate' || isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.from)" class="dateInput" container="body" [popover]="customDatePopoverFrom" placement="auto" containerClass="datePickerPopover" [outsideClick]="true" #customDateFromPopup="bs-popover" (click)="popoverRef=customDateFromPopup;currentDateKey=customField.key">
                  <span *ngIf="localFilterDataObj?.custom_column[customField.key]?.from!='customDate'">{{localFilterDataObj?.custom_column[customField.key].from | dateCalcuate:"dateTime"}}</span> 
                  <span *ngIf="!localFilterDataObj?.custom_column[customField.key]?.from || localFilterDataObj?.custom_column[customField.key]?.from=='customDate'" class="datePlaceHolderText">DD/MM/YYYY</span>
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].from = null" class="closeIcon">x</span>
                </div>
                
              </div>
              <div class="col-6 pr-0 pl-1">
                <label class="filterLabel col-12 px-0" for="to">{{'dashboard.filter.to' | translate}}</label>
                 <div *ngIf="localFilterDataObj?.custom_column[customField.key] && (localFilterDataObj?.custom_column[customField.key]?.to!='customDate' && !isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.to)) ">
                  <ng-select  [virtualScroll]="true"  [items]="dateOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to" (ngModelChange)="localFilterDataObj.custom_column[currentDateKey].to = $event"></ng-select>
                </div>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key]?.to=='customDate' || isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.to)" class="dateInput" container="body" [popover]="customDatePopoverTo" placement="auto" containerClass="datePickerPopover" [outsideClick]="true" #customDateToPopup="bs-popover" (click)="popoverRef=customDateToPopup;currentDateKey=customField.key">
                  <span *ngIf="localFilterDataObj?.custom_column[customField.key]?.to!='customDate'">{{localFilterDataObj?.custom_column[customField.key].to | dateCalcuate:"dateTime"}}</span> 
                  <span *ngIf="!localFilterDataObj?.custom_column[customField.key]?.to || localFilterDataObj?.custom_column[customField.key]?.to=='customDate'" class="datePlaceHolderText">DD/MM/YYYY</span>
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].to = null" class="closeIcon">x</span>
                </div>
              </div>
            </div>
          </ng-container>


          <!--Dropdown-->
          <div class="col-12 mt-2 hoverNgSelectP" *ngIf="customField.type === constantService.constant.CUSTOMTYPES.DROPDOWN">
            <label class="filterLabel" for="dropdown">{{customField.display_key}}</label>
              <ng-select [virtualScroll]="true" [items]="customField.permissible_values" [multiple]="true" bindLabel="k" [closeOnSelect]="false"
              bindValue="k" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" placeholder="Select">
                <ng-template ng-option-tmp let-item="item">
                  <div [style.background-color]="item.c || '#ffffff'" [ngStyle]="{'color': commonUtils.getContrastColor(item?.c)}" class="custom-option">
                    {{ item.v }}
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="custom-multi-select-label" style="padding-bottom: 5px;display: flex;">
                    <div *ngFor="let item of items" class="custom-multi-select-item" style="display: flex;padding-bottom: 3px;padding-right: 5px;">
                      <div [style.background-color]="item.c || '#ffffff'" class="custom-options-for-multi">
                        <div style="display: flex;">
                          <div class="text-over-flow" [ngStyle]="{'color': commonUtils.getContrastColor(item?.c)}">{{ item.v }}</div> <div class="clear-icon" (click)="removeItem(item, clear)"><img src="../../../assets/1x/new-cross.svg"/></div>
                        </div>
                      </div>
                      <!-- <span style="padding-top:5px" class="clear-icon" (click)="removeItem(item, clear)">&#10005;</span> -->
                    </div>
                  </div>
                </ng-template>
                </ng-select> 
          </div>
          <!---board-->
          <div class="col-12 mt-2" *ngIf="['Board','MultipleBoard'].includes(customField.type)">
            <label class="filterLabel" for="dropdown">{{customField.display_key}}</label>
            <ng-select  [virtualScroll]="true" [items]="(connectedBoardData[customField.connected_board_id] ||[])" [multiple]="true" [closeOnSelect]="false" placeholder="Select" [clearSearchOnAdd]="true"
              bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" (focus)="callListOfData(customField.connected_board_id)" [searchFn]="getSearchMirrorLead">
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              <span *ngIf="item?.seq_id">#</span>{{item.seq_id}} {{item.title}}
            </ng-template>
            </ng-select>
          </div>
          <!--Checkbox-->
          <div class="col-12 mt-2 hoverNgSelectP" *ngIf="customField.type === constantService.constant.CUSTOMTYPES.CHECKBOX">
            <label class="filterLabel" for="checkbox">{{customField.display_key}}</label>
            <ng-select [virtualScroll]="true" [items]="customField.permissible_values" [multiple]="true" bindLabel="value.k" [closeOnSelect]="false"
            bindValue="value.k" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" placeholder="Select">
                <ng-template ng-option-tmp let-item="item">
                  <div [style.background-color]="item.value.c || '#ffffff'" class="custom-option" [ngStyle]="{'color': commonUtils.getContrastColor(item?.value?.c)}">
                    {{ item.value.v }}
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="custom-multi-select-label" style="padding-bottom: 5px;display: flex;">
                    <div *ngFor="let item of items" class="custom-multi-select-item" style="display: flex;padding-bottom: 3px;padding-right: 5px;">
                      <div [style.background-color]="item.value.c || '#ffffff'" class="custom-options-for-multi">
                        <div style="display: flex;">
                          <div class="text-over-flow" [ngStyle]="{'color': commonUtils.getContrastColor(item?.value?.c)}">{{ item.value.v }}</div> <div class="clear-icon" (click)="removeItem(item, clear)"><img src="../../../assets/1x/new-cross.svg"/></div>
                        </div>
                      </div>
                      <!-- <span style="padding-top:5px" class="clear-icon" (click)="removeItem(item, clear)">&#10005;</span> -->
                    </div>
                  </div>
                </ng-template>
                </ng-select> 
          </div>
          <!--Radio field-->
          <div class="col-12 mt-2 hoverNgSelectP" *ngIf="customField.type === constantService.constant.CUSTOMTYPES.RADIO">
            <label class="filterLabel" for="radio">{{customField.display_key}}</label>
            <ng-select [virtualScroll]="true" [items]="customField.permissible_values" [multiple]="true" bindLabel="value.k" [closeOnSelect]="false"
            bindValue="value.k" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" placeholder="Select">
                <ng-template ng-option-tmp let-item="item">
                  <div [style.background-color]="item.value.c || '#ffffff'" class="custom-option" [ngStyle]="{'color': commonUtils.getContrastColor(item?.value?.c)}">
                    {{ item.value.v }}
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="custom-multi-select-label" style="padding-bottom: 5px;display: flex;">
                    <div *ngFor="let item of items" class="custom-multi-select-item" style="display: flex;padding-bottom: 3px;padding-right: 5px;">
                      <div [style.background-color]="item.value.c || '#ffffff'" class="custom-options-for-multi">
                        <div style="display: flex;">
                          <div class="text-over-flow" [ngStyle]="{'color': commonUtils.getContrastColor(item?.value?.c)}">{{ item.value.v }}</div> <div class="clear-icon" (click)="removeItem(item, clear)">
                            <svg width="16" height="16" viewBox="0 0 16 16" [fill]="commonUtils.getContrastColor(item?.value?.c)" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 4L4 12M4 4L12 12" stroke="#353E4E" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </div>
                        </div>
                      </div>
                      <!-- <span style="padding-top:5px" class="clear-icon" (click)="removeItem(item, clear)">&#10005;</span> -->
                    </div>
                  </div>
                </ng-template>
                </ng-select> 
          </div>
          <!--MultiSelect field-->
          <div class="col-12 mt-2 hoverNgSelectP" *ngIf="customField.type === constantService.constant.CUSTOMTYPES.MULTISELECT">
            <label class="filterLabel" for="multiselect">{{customField.display_key}}</label>
            <ng-select [virtualScroll]="true" [items]="customField.permissible_values" [multiple]="true" bindLabel="k" [closeOnSelect]="false"
            bindValue="k" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" placeholder="Select">
                <ng-template ng-option-tmp let-item="item">
                  <div [style.background-color]="item.c || '#ffffff'" [ngStyle]="{'color': commonUtils.getContrastColor(item?.c)}" class="custom-option">
                    {{ item.v }}
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="custom-multi-select-label" style="padding-bottom: 5px;display: flex;">
                    <div *ngFor="let item of items" class="custom-multi-select-item" style="display: flex;padding-bottom: 3px;padding-right: 5px;">
                      <div [style.background-color]="item.c || '#ffffff'" class="custom-options-for-multi">
                        <div style="display: flex;">
                          <div class="text-over-flow" [ngStyle]="{'color': commonUtils.getContrastColor(item?.c)}">{{ item.v }}</div> <div class="clear-icon" (click)="removeItem(item, clear)"><img src="../../../assets/1x/new-cross.svg"/></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                </ng-select> 
          </div>
          <!--Country field-->
          <div class="col-12 mt-2" *ngIf="customField.type === constantService.constant.CUSTOMTYPES.COUNTRY">
            <label class="filterLabel" for="multiselect">{{customField.display_key}}</label>
            <ng-select  [virtualScroll]="true" [searchFn]="onSearchCountry"  [clearable]="true" [multiple]="true" bindLabel="name"
          [closeOnSelect]="true" 
             [(ngModel)]="localFilterDataObj.custom_column[customField.key]"
            placeholder="{{'placeholders.selectConutry' | translate}}">
            <ng-option *ngFor="let item of countryListOption" [value]="item.name" style="width:16rem" appendTo="body">
              {{item.flag}} {{item.name}}
            </ng-option>
          </ng-select>
          </div>
          <!-- Mobile field -->
          <div class="col-12 mt-2" *ngIf="['Mobile','MultipleMobile'].includes(customField?.type)">
            <label class="filterLabel" for="multiselect">{{customField.display_key}}</label>
            <input type="text" class="filterDate" name="from" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" placeholder=" {{'placeholders.searchByMobile' | translate}}">
          </div>
          <!-- Mobile field -->
          

          <!-- Mirror fields -->
           <!-- text type -->
           <ng-container *ngIf="customField.mirror_column_type === constantService.constant.CUSTOMTYPES.TEXT || customField.mirror_column_type === constantService.constant.CUSTOMTYPES.EMAIL
           || customField.mirror_column_type === constantService.constant.CUSTOMTYPES.URL || customField.mirror_column_type === constantService.constant.CUSTOMTYPES.TEXTAREA || customField.mirror_column_type === constantService.constant.CUSTOMTYPES.MULTI_URL ">
             <div class="filterLabel col-12 mt-2">{{customField.display_key}} - {{connectedDashboardJson[customField.mirror_dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}</div>
             <div class="col-12 mt-1 pr-0" *ngIf="!automationCall">
               <input type="text" class="filterDate" placeholder="Enter Text" name="from" [(ngModel)]="localFilterDataObj.custom_column[customField.key]">
             </div>
             <div class="col-12 mt-1 pr-0" *ngIf="automationCall">
              <app-content-editable-input-note [htmlContent]="localFilterDataObj.custom_column[customField.key]" [item]="actionobj" [filterCall]="true" [customfieldType]="customField.mirror_column_type" [columnsJson]="filterColumnJson ? filterColumnJson : {}" [columnIndex]="columnIndex ? columnIndex : 0" [showIndex]="false"[dashId]="dashId" [customFieldArr]="triggerItemcustomFields"  [isTriggeredNonItemField]="false" (onBlur)="localFilterDataObj.custom_column[customField.key]=$event.text;filterColumnJson=$event.columnsJson;columnIndex=$event.columnIndex" [filterCustomColumnKey]="customField.key" [catchHookReqJson]="catchHookReqJson" [triggerType]="triggerType"></app-content-editable-input-note>
            </div>
           </ng-container>
           <!-- multiselect checkbox dropdown radio type mirror -->
           <ng-container *ngFor="let field of connectedDashboardJson[customField.mirror_dashboard_id]?.CUSTOM_FORM">
            <ng-container *ngIf="customField.mirror_column_key === field.key">
            <div class="col-12 mt-2" *ngIf="customField.mirror_column_type === constantService.constant.CUSTOMTYPES.MULTISELECT ||
            customField.mirror_column_type === constantService.constant.CUSTOMTYPES.DROPDOWN ||
            customField.mirror_column_type === constantService.constant.CUSTOMTYPES.CHECKBOX ||
            customField.mirror_column_type === constantService.constant.CUSTOMTYPES.RADIO">
              <label class="filterLabel" for="multiselect">{{customField.display_key}} - {{connectedDashboardJson[customField.mirror_dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}</label>
              <ng-select  [virtualScroll]="true" [items]="field.permissible_values" [multiple]="true" bindLabel="value" [closeOnSelect]="false"
              bindValue="value" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" placeholder="Select"></ng-select>
            </div>
          </ng-container>
           </ng-container>
           <!-- Date type mirror  -->
           <ng-container *ngIf="customField.mirror_column_type === constantService.constant.CUSTOMTYPES.DATE && !automationCall">
            <label class="filterLabel col-12 mt-2">{{customField.display_key}} - {{connectedDashboardJson[customField.mirror_dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}</label>
            <div class="row g-0 w-100 mx-0">
              <div class="col-6 px-0">
                <label class="filterLabel col-12 px-0" for="from">{{'dashboard.filter.from' | translate}}</label>
                <div class="dateInput" container="body" [popover]="customDatePopoverFrom" placement="auto" [outsideClick]="true" containerClass="datePickerPopover" #customDateFromPopup="bs-popover" (click)="popoverRef=customDateFromPopup;currentDateKey=customField.key">
                  <span *ngIf="localFilterDataObj?.custom_column[customField.key]?.from">{{localFilterDataObj?.custom_column[customField.key].from | dateCalcuate:"dateTime"}}</span> 
                  <span *ngIf="!localFilterDataObj?.custom_column[customField.key]?.from" class="datePlaceHolderText">DD/MM/YYYY</span>
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].from = null" class="closeIcon">x</span>
                </div>
                
              </div>
              <div class="col-6 pr-0 pl-1">
                <label class="filterLabel col-12 px-0" for="to">{{'dashboard.filter.to' | translate}}</label>
                <div class="dateInput" container="body" [popover]="customDatePopoverTo" placement="auto" [outsideClick]="true" containerClass="datePickerPopover" #customDateToPopup="bs-popover" (click)="popoverRef=customDateToPopup;currentDateKey=customField.key">
                  <span *ngIf="localFilterDataObj?.custom_column[customField.key]?.to">{{localFilterDataObj?.custom_column[customField.key].to | dateCalcuate:"dateTime"}}</span>
                  <span *ngIf="!localFilterDataObj?.custom_column[customField.key]?.to" class="datePlaceHolderText">DD/MM/YYYY</span>
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].to = null" class="closeIcon">x</span>
                
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="customField.mirror_column_type === constantService.constant.CUSTOMTYPES.DATE && automationCall">
            <label class="filterLabel col-12 mt-2">{{customField.display_key}}</label>
            <div class="row g-0 w-100 mx-0">
              <div class="col-6 px-0">
                <label class="filterLabel col-12 px-0" for="from">{{'dashboard.filter.from' | translate}}</label>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key] && (localFilterDataObj?.custom_column[customField.key]?.from!='customDate' && !isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.from)) ">
                  <ng-select  [virtualScroll]="true"  [items]="dateOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from" (ngModelChange)="localFilterDataObj.custom_column[currentDateKey].from = $event"></ng-select>
                </div>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key]?.from=='customDate' || isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.from)" class="dateInput" container="body" [popover]="customDatePopoverFrom" placement="auto" containerClass="datePickerPopover" [outsideClick]="true" #customDateFromPopup="bs-popover" (click)="popoverRef=customDateFromPopup;currentDateKey=customField.key">
                  <span *ngIf="localFilterDataObj?.custom_column[customField.key]?.from!='customDate'">{{localFilterDataObj?.custom_column[customField.key].from | dateCalcuate:"dateTime"}}</span> 
                  <span *ngIf="!localFilterDataObj?.custom_column[customField.key]?.from || localFilterDataObj?.custom_column[customField.key]?.from=='customDate'" class="datePlaceHolderText">DD/MM/YYYY</span>
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].from = null" class="closeIcon">x</span>
                </div>
                
              </div>
              <div class="col-6 pr-0 pl-1">
                <label class="filterLabel col-12 px-0" for="to">{{'dashboard.filter.to' | translate}}</label>
                 <div *ngIf="localFilterDataObj?.custom_column[customField.key] && (localFilterDataObj?.custom_column[customField.key]?.to!='customDate' && !isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.to)) ">
                  <ng-select  [virtualScroll]="true"  [items]="dateOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to" (ngModelChange)="localFilterDataObj.custom_column[currentDateKey].to = $event"></ng-select>
                </div>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key]?.to=='customDate' || isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.to)" class="dateInput" container="body" [popover]="customDatePopover" placement="auto" containerClass="datePickerPopover" [outsideClick]="true" #customDateToPopup="bs-popover" (click)="popoverRef=customDateToPopup;currentDateKey=customField.key">
                  <span *ngIf="localFilterDataObj?.custom_column[customField.key]?.to!='customDate'">{{localFilterDataObj?.custom_column[customField.key].to | dateCalcuate:"dateTime"}}</span> 
                  <span *ngIf="!localFilterDataObj?.custom_column[customField.key]?.to || localFilterDataObj?.custom_column[customField.key]?.to=='customDate'" class="datePlaceHolderText">DD/MM/YYYY</span>
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].to = null" class="closeIcon">x</span>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- Country Field Mirror Start-->
          <ng-container *ngIf="(customField.mirror_column_type === constantService.constant.CUSTOMTYPES.COUNTRY)" >
              <div class="col-12 mt-2">
              <label class="filterLabel" for="multiselect">{{customField.display_key}} - {{connectedDashboardJson[customField.mirror_dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}</label>
              <ng-select class="row w-100 mx-0" [virtualScroll]="true" [searchFn]="onSearchCountry"  [clearable]="true" [multiple]="true" bindLabel="name"
              [closeOnSelect]="true" 
               [(ngModel)]="localFilterDataObj.custom_column[customField.key]"
              placeholder="{{'placeholders.selectConutry' | translate}}">
              <ng-option *ngFor="let item of countryListOption" [value]="item.name" style="width:16rem" appendTo="body">
                {{item.flag}} {{item.name}}
              </ng-option>
              </ng-select>
              </div>
          </ng-container>
          <!-- Country Field Mirror End-->

          <!-- Mobile field Mirror-->
          <div class="col-12 mt-2" *ngIf="(customField.mirror_column_type === constantService.constant.CUSTOMTYPES.MOBILE)">
            <label class="filterLabel" for="multiselect">{{customField.display_key}}</label>
            <input type="text" class="filterDate" name="from" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" placeholder=" {{'placeholders.searchByMobile' | translate}}">
          </div>
          <!-- Mobile field Mirror-->
          
           <!-- numaric rang type mirror  -->
          <ng-container *ngIf="(customField.mirror_column_type === constantService.constant.CUSTOMTYPES.NUMBER ||
          customField.mirror_column_type === constantService.constant.CUSTOMTYPES.PERCENTAGE ||
          customField.mirror_column_type === constantService.constant.CUSTOMTYPES.CURRENCY ||
          customField.mirror_column_type === constantService.constant.CUSTOMTYPES.RATING) && !automationCall && localFilterDataObj?.custom_column[customField.key]">
            <div class="filterLabel col-12 mt-2">{{customField.display_key}} - {{connectedDashboardJson[customField.mirror_dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}</div>
            <div class="row g-0 w-100 mx-0">
              <div class="col-6 pr-0">
                <label class="filterLabel" for="from">{{'dashboard.filter.from' | translate}}</label>
                <input type="number" class="filterDate" placeholder="Enter Number" name="from" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from">
              </div>
              <div class="col-6 pr-0">
                <label class="filterLabel" for="to">{{'dashboard.filter.to' | translate}}</label>
                <input type="number" class="filterDate" placeholder="Enter Number" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to">
              </div>
            </div>
            <span style="color:red;font-size: 8px;width:100%;text-align: center;" *ngIf="localFilterDataObj?.custom_column[customField.key]?.from>localFilterDataObj?.custom_column[customField.key]?.to">From should not be smaller than To</span>
          </ng-container> 

          <ng-container *ngIf="(customField.mirror_column_type === constantService.constant.CUSTOMTYPES.NUMBER ||
          customField.mirror_column_type === constantService.constant.CUSTOMTYPES.PERCENTAGE ||
          customField.mirror_column_type === constantService.constant.CUSTOMTYPES.CURRENCY ||
          customField.mirror_column_type === constantService.constant.CUSTOMTYPES.RATING) && automationCall && localFilterDataObj?.custom_column[customField.key]">
            <label class="filterLabel col-12 mt-2">{{customField.display_key}}</label>
            <div class="row g-0 w-100 mx-0">
              <div class="col-6 px-0">
                <label class="filterLabel col-12 px-0" for="from">{{'dashboard.filter.from' | translate}}</label>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key] && (localFilterDataObj?.custom_column[customField.key]?.from!='customRating' && !isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.from)) ">
                  <ng-select  [virtualScroll]="true"  [items]="ratingOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from" (ngModelChange)="localFilterDataObj.custom_column[currentDateKey].from = $event"></ng-select>
                </div>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key]?.from=='customRating' || isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.from)" class="dateInput">
                  <input type="number" placeholder="Enter Number" class="filterDate"  style="border: none !important;" name="from" [(ngModel)]="localFilterDataObj.custom_column[customField.key].from">
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].from = null" class="closeIcon">x</span>
                </div>
                
              </div>
              <div class="col-6 pr-0 pl-1">
                <label class="filterLabel col-12 px-0" for="to">{{'dashboard.filter.to' | translate}}</label>
                 <div *ngIf="localFilterDataObj?.custom_column[customField.key] && (localFilterDataObj?.custom_column[customField.key]?.to!='customRating' && !isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.to)) ">
                  <ng-select  [virtualScroll]="true"  [items]="ratingOptions" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to" (ngModelChange)="localFilterDataObj.custom_column[currentDateKey].to = $event"></ng-select>
                </div>
                <div *ngIf="localFilterDataObj?.custom_column[customField.key]?.to=='customRating' || isTypeNumber(localFilterDataObj?.custom_column[customField.key]?.to)" class="dateInput" >
                  <input type="number" placeholder="Enter Number" class="filterDate"  style="border: none !important;" [(ngModel)]="localFilterDataObj.custom_column[customField.key].to">
                  <span (click)="$event.stopPropagation();localFilterDataObj?.custom_column[customField.key].to = null" class="closeIcon">x</span>
                </div>
              </div>
            </div>
          </ng-container>

          
             <!--Priority-->
        <div *ngIf="customField.mirror_column_type === 'Priority'" class="col-12 mt-2">
          <label class="filterLabel" for="priority">{{'dashboard.filter.priority' | translate}} - {{connectedDashboardJson[customField.mirror_dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}</label>
            <ng-select  [virtualScroll]="true" [items]="priorityArray" [multiple]="true" bindLabel="value" [closeOnSelect]="false"
            bindValue="id" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" placeholder="Select">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="flt_user">
                <span *ngIf="item.id==3" class="priorityLabel" [style.background]="constantService?.priority_colors[item.id]">U</span>
                <span *ngIf="item.id==2" class="priorityLabel" [style.background]="constantService?.priority_colors[item.id]">H</span>
                <span *ngIf="item.id==1" class="priorityLabel" [style.background]="constantService?.priority_colors[item.id]">M</span>
                <span *ngIf="item.id==0" class="priorityLabel" [style.background]="constantService?.priority_colors[item.id]">L</span>
                <span> {{item.value}}</span><br>
              </div>
            </ng-template>
          </ng-select>
        </div>
         <!--Assignee-->
         <div *ngIf="customField.mirror_column_type === 'SingleUser' || customField.mirror_column_type === 'MultiUser'" class="col-12 mt-2">
          <label class="filterLabel" for="tags">{{customField.display_key}} - {{connectedDashboardJson[customField.mirror_dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}</label>
            <ng-select  [virtualScroll]="true" [items]="orgFiltersUsersArr" [multiple]="true" bindLabel="name" [closeOnSelect]="true" bindValue="_id" [searchFn]="commonUtils.onSearchCode"
            [(ngModel)]="localFilterDataObj.custom_column[customField.key]" placeholder="Select">
              <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="flt_user">
                  <div class="user">
                    <div class="align">
                      <ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                    </div>  
                    <div class="box">
                      <span>{{item?.name}}</span>
                      <p class="my-0">{{item?.email}}</p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-select>
        </div>
        <!--Tags-->
        <div *ngIf="customField.mirror_column_type === 'Tag'" class="col-12 mt-2">
            <label class="filterLabel" for="tags">{{'dashboard.filter.tags' | translate}} - {{connectedDashboardJson[customField.mirror_dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}</label>
            <ng-select  [virtualScroll]="true" [items]="connectedDashboardJson[customField.mirror_dashboard_id]?.TAG" [multiple]="true" bindLabel="name" [closeOnSelect]="false"
            bindValue="tag_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" placeholder="Select">

          </ng-select>
        </div>
        <!--Select Bucket-->
        <div *ngIf="customField.mirror_column_type === 'Bucket'" class="col-12 mt-2">
            <label class="filterLabel" for="priority">{{'dashboard.filter.selectBucket' | translate}} - {{connectedDashboardJson[customField.mirror_dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}</label>
              <ng-select  [virtualScroll]="true" [items]="connectedDashboardJson[customField.mirror_dashboard_id]?.BUCKET" [multiple]="true" bindLabel="name" [closeOnSelect]="false"
                bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" placeholder="Select">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div style="display:flex">
                        <div style="width:80%">
                          <span class="pull-left text-center">{{item.name}}</span>
                        </div>
                        <div *ngIf="item.is_destination" class="text-center" style="width:15%;">
                          <i class="far fa-check-circle finalBucketIcon" style="color:#55b98a ; padding : 0px"></i>
                          <p  class="trxtGrayColor">{{'dashboard.filter.finalBucket' | translate}}</p>
                      </div> 
                    </div> 
                </ng-template>
              </ng-select>
        </div>
        <!--Sprint-->  
        <div *ngIf="connectedDashboardJson[customField.mirror_dashboard_id]?.SPRINT?.length>1 && customField.mirror_column_type === 'Sprint'" class="col-12 mt-2">
          <div *ngIf="connectedDashboardJson[customField.mirror_dashboard_id]?.SPRINT" class="filterLabel  mt-2">{{'setting.phase' | translate}} - {{connectedDashboardJson[customField.mirror_dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}</div>
          <div *ngIf="connectedDashboardJson[customField.mirror_dashboard_id]?.SPRINT" class=" mt-2 pr-0">
                <ng-select  [virtualScroll]="true" [items]="connectedDashboardJson[customField.mirror_dashboard_id]?.SPRINT" dropdownPosition="top" [multiple]="true" bindLabel="name" [closeOnSelect]="false"
              bindValue="_id" [(ngModel)]="localFilterDataObj.custom_column[customField.key]" placeholder="Select">
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div style="display:flex">
                  <div style="width:80%">
                    <span class="">{{item.name}}</span><br>
                    <p class="trxtGrayColor"*ngIf="item.startDateFormat && item.endDateFormat">{{item.startDateFormat}} - {{item.endDateFormat}}</p>
                    <p class="trxtGrayColor" *ngIf="!item.startDateFormat && !item.endDateFormat"></p>
                    <p class="trxtGrayColor" *ngIf="item.startDateFormat && !item.endDateFormat">{{item.startDateFormat}}- {{'form.noEndDate' | translate}}</p>
                    <p class="trxtGrayColor" *ngIf="!item.startDateFormat && item.endDateFormat">{{'form.noStartDate' | translate}} -{{item.endDateFormat}}</p>
                  </div>
                  <div *ngIf="!item.is_archive" class="pull-right" style="width:20%;">
                    <i *ngIf="item.current_status=='STARTED'" class="dot"></i>
                    <i *ngIf="item.current_status=='STOPPED'" class="dot activedone"></i> 
                    <p class="trxtGrayColor" *ngIf="item.current_status=='STARTED' && !item.is_archive">In Progress</p>
                    <p class="trxtGrayColor" *ngIf="item.current_status=='STOPPED' && !item.is_archive">Completed</p>
                 </div>
                 <div *ngIf="item.is_archive" class="pull-right" style="width:20%;">
                    <img class="arcicon"src="../../../assets/img/icons/archive.svg"/>
                  </div>
                  <div *ngIf="item.is_hidden" class="pull-right" style="width:20%;">
                    <img src="../../../assets/img/icons/eye.svg" />
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

          </ng-container>
        </div>
        <div class="col-12">
          <!-- <label class="filterLabel " for="priority">Other Filters</label> -->
          <!-- <div class="priorityBtn"> -->
            <div class="mt-2">
              <label> {{'dashboard.filter.publicItem' | translate :{ term: "Item" | terminology: itemTerminology?.plural : 'Item' } }}</label>
              <span class="float-right mt-n1">
                <div class="switch-div mr-1">
                  <label class="switch">
                      <input type="checkbox" [value]="localFilterDataObj.item_type.length>0 ? localFilterDataObj.item_type[0] : 'ITEM_PUBLIC'" [checked]="localFilterDataObj.item_type.length>0 ? true : false" (change)="onOtherFiltersChanges($event, 'item_type')">
                      <span class="slider round"></span>            
                  </label> 
                </div>
              </span>
            </div>
            <div class="mt-2">
              <label> {{'dashboard.filter.starred' | translate}}</label>
              <span class="float-right mt-n1">
                <div class="switch-div mr-1">
                  <label class="switch">
                      <input type="checkbox" [value]="localFilterDataObj.starred.length>0 ? localFilterDataObj.starred[0] : loggedInUserId" [checked]="localFilterDataObj.starred.length>0 ? true : false" (change)="onOtherFiltersChanges($event, 'starred')">
                      <span class="slider round"></span>            
                  </label> 
                </div>
              </span>
            </div>
            <div class="mt-2">
              <label> {{'dashboard.filter.myMentions' | translate}}</label>
              <span class="float-right mt-n1">
                <div class="switch-div mr-1">
                  <label class="switch">
                      <input type="checkbox" [checked]="localFilterDataObj.mentions.status.length>0 ? true : false" (change)="onOtherFiltersChanges($event, 'mentions')">
                      <span class="slider round"></span>            
                  </label> 
                </div>
              </span>
            </div>
            <div class="mt-2">
              <label> {{'dashboard.filter.deadlineOverdue' | translate}}</label>
              <span class="float-right mt-n1">
                <div class="switch-div mr-1">
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="localFilterDataObj.deadline_over">
                      <span class="slider round"></span>            
                  </label> 
                </div>
              </span>
            </div>
            <div class="mt-2" *ngIf="!automationCall">
              <label> {{'dashboard.filter.deletedItems' | translate :{ term: "Item" | terminology: itemTerminology?.plural : 'Item' } }}</label>
              <span class="float-right mt-n1">
                <div class="switch-div mr-1">
                  <label class="switch">
                      <input type="checkbox" [value]="localFilterDataObj.is_deleted.length>0 ? localFilterDataObj.is_deleted[0] : true" [checked]="localFilterDataObj.is_deleted.length>0 ? true : false" (change)="onIsdeletedFiltersChanges()">
                      <span class="slider round"></span>            
                  </label> 
                </div>
              </span>
            </div>
            <div class="mt-2">
              <label> {{'dashboard.filter.externalCollaborators' | translate}}</label>
              <span class="float-right mt-n1">
                <div class="switch-div mr-1">
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="localFilterDataObj.have_external_collaborators">
                      <span class="slider round"></span>            
                  </label> 
                </div>
              </span>
            </div>
          <!-- </div> -->
          <!-- <p *ngIf="!quickFilterName" style="color: red;">Filter Name Is Required</p> -->
        </div>

        
        <!--Sort by-->
        <div class="col-12 mt-2" *ngIf="localFilterDataObj?.sort_params">
            <label class="filterLabel col-12" for="sortby">{{'dashboard.filter.sortBy' | translate}}</label>
            <div class="row g-0 mt-1">
              <div class="col-6 pr-0">
                <ng-select  [virtualScroll]="true" [items]="sortByOptionsViewList" [clearable]="false" dropdownPosition="top" [multiple]="false" bindLabel="display_key"
                  [closeOnSelect]="false" bindValue="sortByKey" [(ngModel)]="localFilterDataObj?.sort_params[0].sort_by" (change)="onSortingChange($event)"></ng-select>
              </div>
              <div class="col-6 pr-0">
                <ng-select  [virtualScroll]="true" [items]="sortOrder" [clearable]="false" dropdownPosition="top" [multiple]="false" bindLabel="option" [closeOnSelect]="false"
                  bindValue="value" [(ngModel)]="localFilterDataObj?.sort_params[0].order"></ng-select>
              </div>
            </div>
        </div>
        <div *ngIf="showQuickFilterNameField" class="col-12">
          <label class="filterLabel " for="priority">{{'dashboard.filter.filterName' | translate}}</label>
          <div class="priorityBtn" id="save-filter-name">
            <input type="text" class="filterDate" [(ngModel)]="quickFilterName">
          </div>
          <p *ngIf="!quickFilterName" style="color: red;">{{'dashboard.filter.filterNameIsRequired' | translate}}</p>
        </div>
      </div>
    </div>

    <!----Advance Filter Middle Area-->
    <div *ngIf="localFilterDataObj?.advanced_enable" class="container containerElem filtrpopover advancedFilterArea" [ngStyle]="{'height':  originalautomationCall ? '100%' : 'calc(100% - 90px)'}" >
      <app-advance-filter class="w-100" [arrayOfCustomFiled]="arrayOfCustomFiled" [actionobj]="actionobj" [selectedDashId]="selectedDashId" [localFilterDataObj]="localFilterDataObj" [advancedFilterArr]="advancedFilterArr" [advancedFilterData]="advancedFilterData" [triggerItemcustomFields]="triggerItemcustomFields" (updateAdvanceFilter)="validateAdvancedFilter()" (advanceColumnFilter)="filterColumnJson=$event.columnsJson;columnIndex=$event.columnIndex" [automationCall]="automationCall" [isTriggeredNonItemField]="isTriggeredNonItemField"  [catchHookReqJson]="catchHookReqJson" [triggerType]="triggerType" [filterColumnJson]="filterColumnJson ? filterColumnJson : {}" [columnIndex] ="columnIndex ? columnIndex : 0"></app-advance-filter>
      <div *ngIf="showQuickFilterNameField" class="col-12 mt-2">
        <label class="filterLabel " for="priority">{{'dashboard.filter.filterName' | translate}}</label>
        <div class="priorityBtn" id="save-filter-name">
          <input type="text" class="filterDate" [(ngModel)]="quickFilterName">
        </div>
        <p *ngIf="!quickFilterName" style="color: red;">{{'dashboard.filter.filterNameIsRequired' | translate}}</p>
      </div>
    </div>


    <!--Filter Bottom Buttons-->
    <div class="row g-0 filterBottomBox" *ngIf="!originalautomationCall">
      <!-- <div class="row g-0 filter-btn-row g-0 pb-1 pt-3"> -->
        <div class="col-12 filterBottomButtons">
          <button *ngIf="!quickFilterName && !automationCall && !dashboardGraphCall && !isTriggeredNonItemField && !subtaskFilter" [disabled]="openInTemplateOrInternalBoard || (localFilterDataObj?.advanced_enable && advancedFilterhasError)" type="button" class="btn filterBtn mr-3 key-tab" tabindex="0" (click)="showQuickFilterNameField=true;scrollToElementById('save-filter-name')">{{'dashboard.filter.SaveFilter' | translate}}</button>
          <button *ngIf="quickFilterName?.length>0 && !automationCall" type="button" class="btn filterBtn mr-3 key-tab" tabindex="0" (click)="onSaveFilter()">{{'dashboard.filter.SaveFilter' | translate}}</button>
          <button  type="button" *ngIf="!settingCall" (click)="resetFilter(true);showFilter = false" class="btn filterBtn mr-3 key-tab" tabindex="0">{{'dashboard.filter.clearAll' | translate}}</button>
          <button type="button" *ngIf="!bulkSelectionMode && !settingCall" (click)="applyFilter(); showFilter = false" class="btn filterBtn key-tab" tabindex="0" [disabled]="localFilterDataObj?.advanced_enable && advancedFilterhasError">{{'dashboard.filter.applyFilter' | translate}}</button>
          <button type="button" *ngIf="bulkSelectionMode && !settingCall" [ngbPopover]="confirmPopover" #confirmPop="ngbPopover" placement="auto" container="body"
           [autoClose]="'outside'" class="btn filterBtn key-tab" tabindex="0" [disabled]="localFilterDataObj?.advanced_enable && advancedFilterhasError">{{'dashboard.filter.applyFilter' | translate}}
           <ng-template #confirmPopover>
             <div style="display: flex;align-items: center;flex-direction: column;row-gap: 10px; width: 300px;">
               <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;row-gap: 10px;">
                 <span style="text-align: center;">Applying filter will resets all selected items. Are you sure you want apply filter?</span>
               </div>
               <div style="display: flex;column-gap: 10px;">
                 <button class="btn btn-success" (click)="applyFilter(); showFilter = false">{{'multiSelect.yes' | translate}}</button>
                 <button class="btn btn-danger" (click)="confirmPop.close()">{{'multiSelect.no' | translate}}</button>
               </div>
             </div>
         </ng-template>
          </button>


        </div>
        
      <!-- </div> -->
    </div>
  </div>

  