import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class KeyboardMonitorService {
  keyIndexConfig:any={}
  containerInstances:any=[]
  constructor(
  ) {
  }

  addKeyboardMonitoring(container,tagPreference='auto'){
    if(!this.containerInstances.length){
      document.addEventListener('keydown',this.handleKeyboardEvent)
    }
    this.containerInstances.push({container:container,tagPreference:tagPreference})
  }

  removeKeyboardMonitoring(container?){
    delete this.keyIndexConfig[this.containerInstances.length-1]
    this.containerInstances.splice(this.containerInstances.length-1,1)
    if(!this.containerInstances.length){
      document.removeEventListener('keydown',this.handleKeyboardEvent)
    }
  }


  handleKeyboardEvent= (event: any): void => {
    if(['Tab','Enter','ArrowDown','ArrowUp']){
      let key =this.containerInstances.length-1;
      let container=this.containerInstances[key]?.container
      if(!this.keyIndexConfig[key])this.keyIndexConfig[key]={}
      let checkPopoverOpen=document.querySelector('.popover-body')
      if(checkPopoverOpen && !(checkPopoverOpen?.contains(container?.nativeElement))){
        return null
      }

      if(event.key === 'Tab' ){
        if(this.keyIndexConfig?.currentFocus){
          let focusElement=document.activeElement
          if(focusElement.tagName=="BODY"){
            this.keyIndexConfig?.currentFocus.focus()
          }
        }
        if(this.containerInstances[key]?.tagPreference=='manual'){
          event.preventDefault()
          event.stopPropagation()
          this.keyboardKeyMechanism(key,container?.nativeElement,{elementAccessClass:'key-tab',keyId:'tabkey',indexDecreasingCondition:(event.key === 'Tab' && event?.shiftKey),indexIncreasingCondition:(event.key === 'Tab')})
        }
      }else if(event.key=='ArrowDown' || event.key=='ArrowUp'){
        let focusTabEle=this.keyIndexConfig[key]?.tabkey?.focusEleRef;
        this.keyboardKeyMechanism(key,focusTabEle,{elementAccessClass:'key-updown',keyId:'updownkey',indexDecreasingCondition:(event.key=='ArrowUp'),indexIncreasingCondition:(event.key=='ArrowDown')})
      }
      else if(event.key=='ArrowLeft' || event.key=='ArrowRight'){
        let focusTabEle=this.keyIndexConfig[key]?.tabkey?.focusEleRef;
        this.keyboardKeyMechanism(key,focusTabEle,{elementAccessClass:'key-leftrightarrow',keyId:'leftrightKey',indexDecreasingCondition:(event.key=='ArrowLeft'),indexIncreasingCondition:(event.key=='ArrowRight')})
      }
      else if(event.key === 'Enter'){
        let curentFocusElement=document.activeElement as HTMLElement
        if(curentFocusElement?.tabIndex>-1){
          this.keyIndexConfig.currentFocus=curentFocusElement
        }
        let focusElement=["INPUT",'TEXTAREA']?.includes(curentFocusElement?.tagName) || curentFocusElement?.hasAttribute('contenteditable') && curentFocusElement?.getAttribute('contenteditable')=='true'
        if(!focusElement && curentFocusElement?.tabIndex>-1)curentFocusElement?.click()
      }
    }
  }

  keyboardKeyMechanism(indexKey,container,keyConfig){
    let customOptionsElement=container?.querySelectorAll('.'+keyConfig?.elementAccessClass)
    if(customOptionsElement?.length){
      let isIndexChange=false
      
      if(!this.keyIndexConfig[indexKey])this.keyIndexConfig[indexKey]={}
      if(!this.keyIndexConfig[indexKey][keyConfig?.keyId])this.keyIndexConfig[indexKey][keyConfig?.keyId]={}

      let elementIndex=this.keyIndexConfig[indexKey][keyConfig?.keyId]?.index
      if(elementIndex==null || elementIndex==undefined || elementIndex<0 || elementIndex>(customOptionsElement?.length-1))elementIndex=-1
      if(keyConfig?.indexDecreasingCondition){
        if(elementIndex>(customOptionsElement?.length-1))elementIndex=customOptionsElement?.length
        if(elementIndex>0){
          isIndexChange=true
          elementIndex--
        }
      }else if(keyConfig?.indexIncreasingCondition){
        if(elementIndex<customOptionsElement?.length-1){
          isIndexChange=true
          elementIndex++
        }
      }
      if(isIndexChange){
        let keyFocusElements =customOptionsElement[elementIndex]?.querySelectorAll('.key-focus')
        this.keyIndexConfig[indexKey][keyConfig?.keyId].focusEleRef= keyFocusElements?.length ? keyFocusElements[0] : customOptionsElement[elementIndex]
        if(this.keyIndexConfig[indexKey][keyConfig?.keyId]?.focusEleRef?.disabled){
          elementIndex--
          this.keyIndexConfig[indexKey][keyConfig?.keyId].focusEleRef=this.keyIndexConfig[indexKey].currentFocus
        }
        this.keyIndexConfig[indexKey][keyConfig?.keyId].index=elementIndex;
        this.keyIndexConfig[indexKey].currentFocus=this.keyIndexConfig[indexKey][keyConfig?.keyId]?.focusEleRef
        this.keyIndexConfig[indexKey]?.currentFocus?.focus()
      }
    }

  }


}
