import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpTransferService } from "../../../services/httpTransfer.service";
import { ConstantService } from "../../../../Enums/Constant.service";
import { CommonUtils } from "../../../services/CommonUtils.service";
import { DashboardUtilsService } from "../../../services/dashboard-utils.service";
import { ToasterService } from '../../../services/toaster.service';
import { MediaIcons } from '../../../../Enums/media-icons';

import { AttributesIcons,AttributesLxIcons  } from '../../../../Enums/attributes-icons';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

declare var $: any;
@Component({
  selector: 'app-lead-item-attribute',
  templateUrl: './lead-item-attribute.component.html',
  styleUrls: ['./lead-item-attribute.component.scss']
})
export class LeadItemAttributeComponent implements OnInit {
  @Output() closePopover = new EventEmitter<any>();
  @Input() leadInfo:any[];
  @Input() dashId:number;
  @Input() selectedPhase:any;
  @Input() bulkSelectionFlag:boolean;
  @Input() objFilterForMultiselect: any;

  
  selectedCustomField: any[] = [];
  multipleCustomData: any[] = [];
  currencyJson: any[]=[];
  dashBoards: any[]=[];
  customFieldType:any
  selectedItemAttribute:any
  attributesIcons = AttributesIcons;
  lxAttributesIcons = AttributesLxIcons;
  AttributesIcons=AttributesIcons
  mediaIcons = MediaIcons;

  countryCodeJson : any=[]
  userDialCode : any;
  mobileNumber:any;
  currencyCode:any
  currencyAmount:any
  customFieldsValue:any
  removeExistingValue:boolean=false
  userList: any[]=[];
  users: any[];
  activeDash: boolean=true;
  selectedUsers: any={};
  removeUserFromList: any;
  dashboardUser: any[];
  orgUsersJson: any;
  removeFromSelected: any;
  boardInputArr:any[]=[]

  fileEvent: any[]=[];
  showUploadMediaArr: any[]=[];
  attachment:any[]=[]
  isUploading:boolean=false
  checklistOperation:any;
  addchecklist:any
  checklistStateUpdate:boolean=false
  addingURL:boolean=false
  addURLText:string

  summerNoteconfig: {
    placeholder: string;
    tabsize: number;
    height: string;
    uploadImagePath: string;
    toolbar: (string | string[])[][];
    fontNames: string[];
  };
  itemTerminology: any={};


  //for Parent Lead
  searchTextSubject = new Subject<any>()
  selectedParentLead : any=null 
  selectedLead : any
  dashboardLeadArr: any[] = []
  startIndex: number = 0;
  leadPaginationPayloadObj = {};
  leadTotalRecords: any;




  constructor(
    private httpTransfer: HttpTransferService,
    public constantService: ConstantService,
    private commonutils: CommonUtils,
    private dashboardUtils: DashboardUtilsService,
    private toaster:ToasterService
  ) {  
    this.customFieldType = this.constantService.getCustomTypes();
    this.summerNoteconfig = this.constantService.getSummerNoteConfig();
   }

   async setFilePathToArrOfCustomFileds(fileUrlPath,filename) {   
    fileUrlPath.forEach(e=>{
      let obj ={}
      obj['name'] = e.file_name,
      obj['path'] = e.file_path
      obj['image_download_url'] = e.image_download_url
      obj['extension'] =e?.file_path?.split(".").pop();

      this.attachment.push(obj)
    })  
    this.updateCustomFields(this.selectedItemAttribute,this.attachment)   
  }

  async ngOnInit() {
    this.itemTerminology=this.dashboardUtils.getDashboardTerminoloy(this.dashId)
    this.checklistOperation=null
    this.countryCodeJson = await this.dashboardUtils.getCurrencyJson()
    this.initializeSummerNote()
    let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
    this.multipleCustomData = JSON.parse(JSON.stringify(dashboardJson[this.dashId]['CUSTOM_FORM']));
    this.multipleCustomData=this.multipleCustomData.filter(field =>field.type!="Mirror")
    this.multipleCustomData=this.multipleCustomData.filter(field =>!field.unique)
    console.log(this.multipleCustomData)
  }


  initializeSummerNote(){
    setTimeout(() => {
       $('#summernoteTextArea').summernote({
      placeholder: 'write here...',
      toolbar: this.summerNoteconfig.toolbar,
    });
    }, 100);
  }


  updateCustomFields(customField,value?){
    console.log(customField)
    let fieldToUpdate=customField.key
    let fieldKey 
    let operation="ADD"
    let matchPrevious={}
    if(customField.type=="CheckList" && this.checklistStateUpdate){
      fieldKey=customField.key+"[*].state"
      operation="UPDATE"
      matchPrevious={
        "field_key":"custom_fields."+fieldKey,
        "algorithm":"CONTAINS",
        "value":value[0]=="COMPLETED" ? "NOT_COMPLETED" : "COMPLETED"
      }
    }
    else{
      fieldKey=customField.key
    }

    if(customField=='Collaborator'){
      fieldToUpdate={'lead_colloborators_users':value}
    }
    else if(customField=='Parent'){
      fieldToUpdate={'parent_id':value}
    }
    else if(customField.type=="Mobile" && !this.removeExistingValue){
      fieldToUpdate={[customField.key]:{
        "country_code":value[0],
        "mobile_number":value[1]
      }}
    }
    else if(customField.type=="Currency" && !this.removeExistingValue){
      fieldToUpdate={[customField.key]:{
        "currency":value[0],
        "amount":value[1]
      }}
    }
    else if((customField.type=="SingleImage" ||  customField.type=="SingleVideo"  || customField.type=="SingleAudio" || customField.type=="SingleFile") && !this.removeExistingValue){
      fieldToUpdate={[customField.key]:value[0]}
    }
    else if(customField.type=="Textarea" && !this.removeExistingValue){
      fieldToUpdate={[customField.key]:$("#summernoteTextArea").summernote("code")}
    }
    else if(customField.type=="Location" && !this.removeExistingValue){
      fieldToUpdate={[customField.key]:{
        "place_id":value,

      }}
    }
    else{
      fieldToUpdate={[customField.key]:value}
    }

    if(this.leadInfo) {
      if(this.leadInfo.length>0) {


          let inputJson={}
        
          inputJson['filter_properties']=this.objFilterForMultiselect

          if(customField=='Collaborator' || customField=='Parent'){
            inputJson['update_json']=fieldToUpdate   
          }
          else if((customField.type=="MultipleImage" ||  customField.type=="MultipleVideo" ||  customField.type=="MultipleAudio"  ||  customField.type=="MultipleFile" || customField.type=="CheckList" || customField.type=="MultiUser" || customField.type=="MultiUrl" || customField.type=="Board") && !this.removeExistingValue ){
            let fieldOperationArr=[]
            for(var i=0;i<value.length;i++){

              let fieldOperationObject={
                "field_key":"custom_fields."+customField.key,
                "operation":operation,
                "data":{
                  "value":value[i],
                  "field_key":"custom_fields."+fieldKey
                },
                "match_previous":matchPrevious
              }
              fieldOperationArr.push(fieldOperationObject)
            }
            inputJson['update_json']={
              "field_operation":fieldOperationArr
            }
            
          }
          else{
            inputJson['update_json']={
              "custom_fields":fieldToUpdate              
            }
          }

          this.httpTransfer.updateMultipleDashboard(this.dashId,inputJson).subscribe((res : any) => {
            if(res.status == 200) {
              this.checklistOperation=null
              this.customFieldsValue=null
              this.selectedItemAttribute=null
              this.removeExistingValue=false
              this.isUploading=false
              this.attachment=[]
              this.showUploadMediaArr=[]
          }
          this.closePopover.emit(true)
        })
        
        

      } else {
        this.toaster.Errors("Please Select Items");
      }
    } 

  }

  pickIp() {
    this.httpTransfer.getIpAddress().subscribe((res:any)=>{
     this.customFieldsValue= res.ip
      
  })
  }

  mobilenumber(dialcode,number){
    this.customFieldsValue=[]
    this.customFieldsValue[0]=dialcode.code
    this.customFieldsValue[1]=number
  }


  curenncyValue(currencyCode,amount){
    this.customFieldsValue=[]
    this.customFieldsValue[0]=currencyCode
    this.customFieldsValue[1]=amount
  }


  changeCheckboxarray(val){
    let index= this.customFieldsValue.indexOf(val)
    if(index==-1){
      this.customFieldsValue.push(val)
    }
    else if(index>-1){
      this.customFieldsValue.splice(index,1)
    }
  }



  setItemToBoard(item, leadData, type, key) {
    this.customFieldsValue.push(leadData._id)
  }


  removeItemFromBoard(item, leadData, type, key) {
    let index = this.customFieldsValue.findIndex(el => el === leadData._id);
    if(index >=0) {
      this.customFieldsValue.splice(index,1);
    }
}


  selectedUserList(user){
    let userIds=[]
    if (this.selectedItemAttribute["type"] == "SingleUser"){
      this.userList=[]
      this.userList.push(user)
      this.customFieldsValue=user._id
    }
    if (this.selectedItemAttribute["type"] == "MultiUser"){
      this.userList.push(user)
      for(let q=0;q<this.userList.length;q++){
        userIds.push(this.userList[q]._id)
      }
      this.customFieldsValue=userIds
    }
    if(this.selectedItemAttribute=='Collaborator'){
      this.userList.push(user)
      for(let q=0;q<this.userList.length;q++){
        userIds.push(this.userList[q]._id)
      }
      this.customFieldsValue=userIds
    }
  }

  removeFromSelectedList(item){
    if (this.selectedItemAttribute["type"] == "SingleUser"){
      this.customFieldsValue=[]
    }
    if (this.selectedItemAttribute["type"] == "MultiUser"){
      let itemId=this.customFieldsValue.indexOf(item._id)
      this.customFieldsValue.splice(itemId,1)
    }
    if(this.selectedItemAttribute=='Collaborator'){
      let itemId=this.customFieldsValue.indexOf(item._id)
      this.customFieldsValue.splice(itemId,1)
    }
    for(let i=0;i<this.userList.length;i++){
      if(item._id == this.userList[i]._id){
         this.userList.splice(i,1)
      }
    }
    this.removeFromSelected = item
  }


  UploadFiles(event){
    this.customFieldsValue = []
    let files = event.target.files
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.fileEvent.push(files[i])
        var reader = new FileReader();
        let previewData = {};
        reader.onload = (event: any) => {
          previewData["file_name"] = files[i].name;
          previewData["file_path"] = event.target.result;
          previewData["file_type"] = files[i].type.split("/")[0];
          previewData['fileEvent'] = files[i]
          this.showUploadMediaArr.push(previewData);
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  removeFile(i){
    this.showUploadMediaArr.splice(i,1)
  }

  async mediaUpload(){
    this.isUploading=true
    let arr = []
    if(this.showUploadMediaArr.length && this.fileEvent?.length){
      this.showUploadMediaArr.forEach(e=>{
          arr.push(e.fileEvent)
      })
      this.showUploadMediaArr = []
      this.commonutils.onUploadFile(arr, 'LEAD',null, this.setFilePathToArrOfCustomFileds.bind(this));
    }
  }


  makechecklist(state,val?){
    this.customFieldsValue=[]
    let input
    if(val){
      this.checklistStateUpdate=false
      input=
        {summary: val,state: state }
    }
    else{
      this.checklistStateUpdate=true
      input=state
    }
    this.customFieldsValue.push(input)
    if(!val){
      this.updateCustomFields(this.selectedItemAttribute,this.customFieldsValue) 
    }
  }

  makeMultiURLarr(urlvalue){
    console.log(this.customFieldsValue)
    if(urlvalue.trim().length){
      this.customFieldsValue.push(urlvalue)
    }
    this.addURLText=""
    this.addingURL=false
  }
  removeUrl(urlvalue){
    console.log(this.customFieldsValue)
    let index=this.customFieldsValue.indexOf(urlvalue)
    if(index>-1){
      this.customFieldsValue.splice(index,1)
    }
  }


  //for Parent Lead


  addingParentLead(){
    this.getDashboardBuckets();
    this.debounceApiCallWithDelay();
  }

  filterLead(val) {
    var searchtext = val?.term;
    this.searchTextSubject.next(val?.term);
  }

  debounceApiCallWithDelay() {
    this.searchTextSubject.pipe(debounceTime(1000)).subscribe((text) => {
      this.leadPaginationPayloadObj["search_params"] = {"search_text":text};
      //if(text.length > 0 && text.length < 3) return;
      this.startIndex = 0
      this.leadPaginationPayloadObj['pagination_details'].start_index = this.startIndex;
      if(text.length>=2){
        this.getDashboardLeads(text);
      }else{
        this.getDashboardLeads();
      }
      
    });
  }
  getDashboardBuckets() {
    const bucketIdArr = [];
    this.httpTransfer.getBucketsCorrespondingToDahBoard(this.dashId).subscribe((res : any) => {
      if (res) {
        res = res.result?.buckets || [];
        res.forEach(bucket => {
          if (!bucket.istrash) {
            bucketIdArr.push(bucket._id);
          }
        });
        this.leadPaginationPayloadObj['bucket_id'] = bucketIdArr;
        this.getDashboardLeads();
      }
    })
  }

  getDashboardLeads(leadSeqIdOrTitle?) {
    this.dashboardLeadArr=[]
    this.httpTransfer.getLeadQuery(this.leadPaginationPayloadObj, this.dashId).subscribe((res : any) => {
        this.startIndex = this.startIndex + res.result.pagination_details.page_size;
        this.leadTotalRecords = res.result.pagination_details.total_records;
        if (res.status === 200) {
          res.result.leadResponse.forEach(lead=> {
           
              this.dashboardLeadArr.push(lead)
             
          })
        }
      })
  }


}
