<div style="height:100%;display: flex;flex-direction: column;row-gap: 10px;" #formulaViewPort>
    <div class="bottomBarFormula" *ngIf="!hideValidBar && !showFormulPreview">
        <div>
            <div class="flex formulaBar" *ngIf="!automationCall">
                    <div  [class.disabled]="numberCustomField.length===0" class="formulaLabel" (click)="numberCustomField.length>0 && insertFormula('SUM')">{{'graph.sum' | translate}}</div>
                    <div  [class.disabled]="numberCustomField.length===0" class="formulaLabel" (click)="numberCustomField.length>0 && insertFormula('AVG')">{{'graph.average' | translate}}</div>
                    <div  [class.disabled]="numberCustomField.length===0" class="formulaLabel" (click)="numberCustomField.length>0 && insertFormula('MEDIAN')">{{'graph.median' | translate}}</div>
                    <div  [class.disabled]="numberCustomField.length===0" class="formulaLabel" (click)="numberCustomField.length>0 && insertFormula('MIN')">{{'graph.min' | translate}}</div>
                    <div  [class.disabled]="numberCustomField.length===0" class="formulaLabel" (click)="numberCustomField.length>0 && insertFormula('MAX')">{{'graph.max' | translate}}</div>
                    <div  [class.disabled]="numberCustomField.length===0" class="formulaLabel" (click)="numberCustomField.length>0 && insertFormula('COUNT')">{{'graph.count' | translate}}</div>
                    <div  [class.disabled]="numberCustomField.length===0" class="formulaLabel" (click)="numberCustomField.length>0 && insertFormula('COUNT_ITEM')">{{'graph.totalCount' | translate}}</div>
                    <div  class="formulaLabel" (click)="insertInput()">123</div>
            </div>
            <div class="flex formulaBar" *ngIf="automationCall">
                <div  class="formulaLabel" (click)="insertInput()">123</div>
                <div class="formulaLabel"  [ngbPopover]="functionPopover" #popFunction="ngbPopover" placement="auto" [autoClose]="'outside'">f(X)</div>
                <div  class="formulaLabel" [ngbPopover]="columnFuncPop" #popColumnFunc="ngbPopover" placement="auto" [autoClose]="'outside'" ><span *ngIf="dashboardVariable && dashVariableArr?.length">Variables</span><span *ngIf="!dashboardVariable && !dashVariableArr?.length">Columns</span></div>
            </div>
        </div>
        <div>
            <div class="flex operatorBar">
                <div class="operatorLabel" (click)="insertOperator('+')">+</div>
                <div class="operatorLabel" (click)="insertOperator('-')">-</div>
                <div class="operatorLabel" (click)="insertOperator('*')">X</div>
                <div class="operatorLabel" (click)="insertOperator('/')">/</div>
                <div class="operatorLabel" (click)="insertOperator('(')">(</div>
                <div class="operatorLabel" (click)="insertOperator(')')">)</div>
            </div>
         </div>
    </div>      
    <div style="height:80%;border: 2px solid #ccc;border-radius: 8px;" [ngClass]="{'previewActive': showFormulPreview}">
       <div #dynamicFormulaEditor></div>
       <span *ngIf="hideValidBar && !showFormulPreview" style="cursor:pointer !important;" (click)="hideValidBar=false;"><img src="../../../assets/1x/editBlackIcon.svg"/></span>
    </div>
    <div class="bottomBarFormula" *ngIf="!hideValidBar && !showFormulPreview">
        <div class="bottomText">
            Click on formula to see more option
        </div>    
       <div class="buttonBar">
           <button class="btnSave" (click)="validateArray()">Validate</button>
           <button class="btnSave" [disabled]="!isValid" [ngClass]="{'disableBtn' : !isValid}" (click)="saveFormula()">Apply</button>
       </div>
    </div>
   </div>

<ng-template #myFormulaTemplate let-formulaData let-index="index" let-info="info">
    <div [ngClass]="{'msg-textarea': index === 0}" #editableDiv [attr.contenteditable]="!hideValidBar ? 'true' : 'false'"  class="functionContent"
        (blur)="getCareAtPositionForComment($event)" (click)="isValid = false;" (focus)="getCareAtPositionForComment($event)">
        <div style="display: flex;align-items: center;">
            <div class="w-100 fontSize formulaParent">
                <ng-container *ngFor="let data of formulaData; let innerIndex = index">
                    &nbsp;<span></span>
                    <span [attr.data-id]="data.uniqueid" class="operatorBox cursorPointer" [ngbPopover]="removefunctions" #popRemove="ngbPopover" [autoClose]="'outside'" *ngIf="data?.operator">{{data?.operator}}</span><span></span>
                    <span *ngIf="(data?.operation_type !== undefined && data?.operation_type !== null) && !data?.showInput && !data?.isFunction" [ngClass]="{'columnChip' : data?.selectedColumnObj?.label}" [attr.contenteditable]="data?.selectedColumnObj?.label ? 'false' : 'true'" [class.formulaUnique]="data.uniqueid" [attr.data-id]="data.uniqueid"
                        (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave($event)" [ngbPopover]="!hideValidBar && removefunctions" #popRemove="ngbPopover" [autoClose]="'outside'">
                        {{data?.operation_type}}
                    <ng-container *ngIf="!data?.manually && !data?.isColumn">(</ng-container></span>&nbsp;<span></span>
                    <span *ngIf="data?.operation_type !== undefined && data?.operation_type !== null && !data?.showInput && data?.isFunction" [ngClass]="{'columnChip' : data?.selectedColumnObj?.label}" contenteditable="false" [class.formulaUnique]="data.uniqueid" [attr.data-id]="data.uniqueid"
                        (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave($event)" [ngbTooltip]="functooltipDesc" [ngbPopover]="!hideValidBar && removefunctions" #popRemove="ngbPopover" [autoClose]="'outside'" tooltipClass="tooltipPopup">
                        {{data?.operation_type}}
                        <ng-template #functooltipDesc>
                            <div>
                                <div class="w-100">{{data?.funcJson?.display_name}} (
                                    <ng-container *ngFor="let itemo of data?.funcJson?.parameters;let argsIndex= index">
                                       <div>{{data?.funcJson?.display_name.charAt(0).toLowerCase()}}{{argsIndex+1}}({{itemo?.type}})</div>
                                    </ng-container>
                                )
                                </div>
            <!--                     <div><{{functionData?.return_type}}></div>
             -->                    <div>{{data?.funcJson?.long_description}}</div>
                                    <div>{{data?.funcJson?.example}}</div>
                            </div>
                        </ng-template>
                    <ng-container *ngIf="!data?.manually && !data?.isColumn">(</ng-container></span>
                    <ng-template #removefunctions>
                        <div class="funcRemovePopover">
                                <div class="paramOptionRow" (click)="removefunction(innerIndex,info)"><span>Remove</span></div>
                                <div class="paramOptionRow" *ngIf="data?.manually" (click)="data.showInput=true"><span>Edit</span></div>
                        </div> 
                    </ng-template>
                    <span *ngIf="data?.showInput"><input id="input" type="number" [(ngModel)]="data.operation_type" (blur)="data.showInput=false;"/></span>
                    <ng-container *ngFor="let col of data?.columns; let colIndex = index">
                        <span class="column cursorPointer" *ngIf="data?.columns?.length>1" [ngbPopover]="removeColumn" #popRemoveCol="ngbPopover" [autoClose]="'outside'">{{col?.display_key}}</span>
                        <span class="column cursorPointer" *ngIf="data?.columns?.length<=1">{{col?.display_key}}</span>
                        <span *ngIf="data?.columns?.length-1 !== colIndex" class="separator">,</span>
                        <ng-template #removeColumn>
                            <div class="funcRemovePopover">
                                    <div class="paramOptionRow" (click)="removeSelectedColumn(colIndex,innerIndex)"><span>Remove</span></div>
                            </div> 
                        </ng-template>
                    </ng-container>
                    <div *ngIf="data?.operation_type !== undefined && data?.operation_type !== null && !data?.manually && data?.columns?.length" class="cursorPointer" [ngbPopover]="formulaParams" #popformulaParams="ngbPopover"
                        [autoClose]="'outside'"><img src="../../../assets/1x/tabler-icon-circle-plus.svg" style="height:20px;width:20px;"/></div>
                    <div *ngIf="data?.funcJson?.parameters?.length" style="display: flex;" contenteditable="false">
                        <ng-container *ngFor="let param of data?.funcJson?.parameters;let i = index"> 
                             <span class="danger" [ngbPopover]="!hideValidBar && functionParams" #popParamOption="ngbPopover" placement="auto" [autoClose]="'outside'">{{data?.operation_type.charAt(0).toLowerCase()}}{{i+1}} : ({{param?.type}}) :{{param?.sub_type}}</span>
                             <ng-container *ngIf="param?.formulaObj?.length">
                                <!--                                 <div class="isParam">{{functionData.display_name.charAt(0).toLowerCase()}}{{i+1}}: ({{item?.type}}) :{{item?.sub_type}}</div>
                                 --> <ng-container *ngTemplateOutlet="myFormulaTemplate; context:{ $implicit:param?.formulaObj,index:innerIndex+1,info:{index:i,arr:data?.funcJson?.parameters,parentIndex:innerIndex}}"></ng-container>
                             </ng-container> 
                           
                             <span class="danger" *ngIf="(param?.type==='array' || param?.sub_type==='array')">[</span>
                             <span *ngIf="param.showInput && (param.type=='string' || param?.sub_type=='string') && param?.name!='timeUnit'"><input type="text" id="input" [(ngModel)]="param.value" (blur)="param.showInput=false"/></span>
                             <span *ngIf="param.showInput && (param.type=='number' || param?.sub_type=='number') && param?.name!='quantity'"><input type="number" id="input" [(ngModel)]="param.value" (blur)="param.showInput=false"/></span>
                             <span *ngIf="param.showInput && item.type=='number' && item?.name=='quantity'"><span class="calculateDate" (click)="param.value=param.value-1">-</span><input type="number" style="border-radius: 0px;" [(ngModel)]="param.value"/><span class="calculateDate" (click)="param.value= +param.value+1">+</span></span>
                             <span *ngIf="param.showInput && param.type=='string' && param?.name=='timeUnit'"><ng-select [items]="dateUnitArr" bindLabel="display_key" bindValue="key" appendTo="body" [clearable]="false" [(ngModel)]="param.value"></ng-select></span>
                             <span *ngIf="param?.type=='boolean'"><ng-select [items]="booleanArr" bindLabel="display_key" bindValue="key" [clearable]="false" [(ngModel)]="param.value"></ng-select></span>
                             <span *ngIf="!param.showInput && (param?.value || param?.value>=0) && param.type!='date' && param.type!='array'" class="paramValue" [ngClass]="{'columnChip' : param?.selectedColumnObj?.label}">{{param?.value}}</span>
                             <span *ngIf="param.type=='date'">
                                <div class="d-inline-block ml-2 mb-2 mt-2" 
                                [ngbPopover]="datePopover" #popDate="ngbPopover" placement="auto" popoverClass="datePickerPopover"
                                [autoClose]="'outside'" (click)="$event.stopPropagation()">
                                <div class="d-inline-block" *ngIf="param.showInput">
                                  <img src="../../../assets/1x/calender-ico.svg" class="calendarImg">
                                </div>
                                <div class="d-inline-block ml-2" *ngIf="param?.value?.date">
                                  {{param?.value | dateCalcuate:"dateTime"}}
                                </div> 
                                </div> 
                             <ng-template #datePopover>
                                <date-picker 
                                [dateRange]="{'from':param.value}"
                                [focusDate]="param.value"
                                (onDateSave)="param.value=$event;popDate.close()"
                                (onDateClear)="param.value = '';popDate.close()">
                                </date-picker>
                            </ng-template> 
                            </span>
                             <ng-template #functionParams>
                                <div class="paramPopover">
                                    <ng-container *ngIf="(param?.type!=='array' && param?.sub_type!=='array' && param.type!=='boolean')">
                                    <div class="paramOptionRow" (click)="popParamOption.close();formulaParamInput(param)"><span *ngIf="!param.value || (param.isColumn && param?.selectedColumnObj)">Enter Manually</span><span *ngIf="param.value && !param.isColumn && !param?.selectedColumnObj">Edit</span></div>
                                    <div class="paramOptionRow" container="body" [ngbPopover]="columnPop" #popColumns="ngbPopover" placement="auto" [autoClose]="'outside'"><span *ngIf="dashboardVariable && dashVariableArr?.length">Variables</span><span *ngIf="!dashboardVariable && !dashVariableArr?.length">Columns</span></div>
                                    <div class="paramOptionRow" container="body" [ngbPopover]="functionPopoverParam" #popupParamFunc="ngbPopover" placement="auto" [autoClose]="'outside'"><span>Functions</span></div>
                                    <div class="paramOptionRow" *ngIf="param.type=='rest'" (click)="addParameForRestType(data?.funcJson?.parameters,i)" ><span>Add More Options</span></div>
                                    <div class="paramOptionRow" *ngIf="param.type=='rest'" (click)="removeParameForRestType(data?.funcJson?.parameters,i)"><span>Remove</span></div>                     
                                </ng-container>
                                <ng-container *ngIf="(param?.type==='array' || param?.sub_type==='array')">
                                    <div class="paramOptionRow" (click)="showInputforArrayTypeParams(param,'string')"><span>Enter Text</span></div>
                                    <div class="paramOptionRow" (click)="showInputforArrayTypeParams(param,'number')"><span>Enter Number</span></div>
                                    <div class="paramOptionRow" container="body" [ngbPopover]="functionPopoverParamArray" #popupParamFuncArr="ngbPopover" placement="auto" [autoClose]="'outside'"><span>Functions</span></div>
                                    <div class="paramOptionRow" *ngIf="paramIndex>=0" (click)="deleteInputforArrayTypeParams(param)"><span>Delete</span></div>
                                </ng-container>
                                </div>
                             </ng-template>   
                             <ng-template #functionPopoverParamArray>
                                <div class="funContainer">
                                  <app-function-popover [automationCall]="automationCall" (getFunctionsEmit)="showInputforArrayTypeParams(param,'func',$event)"></app-function-popover>
                                </div>
                              </ng-template>
                             <ng-template #columnPop>
                                <app-automation-columns [dashboardVariable]="dashboardVariable" [dashVariableArr]="dashVariableArr" [filterCall]="true" [isHeader]="false" [showIndex]="false" [item]="item" [dashId]="dashId" [customFieldArr]="customFieldArr" [isTriggeredNonItemField]="isTriggeredNonItemField" [catchHookReqJson]="catchHookReqJson" [triggerType]="triggerType" [columnsJson]="columnsJson" (closeEmit)="popColumns.close()" (selectedColumn)="insertColumns($event,param)"></app-automation-columns>
                             </ng-template> 
                             <span *ngIf="data?.funcJson?.parameters?.length!==i+1">,</span>
                             <ng-conatiner *ngFor="let innerItem of param?.params;let paramindex=index">
                                <ng-container *ngIf="innerItem?.funcJson">
                                    <!--                                 <div class="isParam">{{functionData.display_name.charAt(0).toLowerCase()}}{{i+1}}: ({{item?.type}}) :{{item?.sub_type}}</div>
                                     --> <ng-container *ngTemplateOutlet="myFormulaTemplate; context:{ $implicit:[innerItem],index:paramindex+1,info:{index:i,arr:innerItem?.funcJson?.parameters,parentIndex:innerIndex}}"></ng-container>
                                 </ng-container> 
                                <span>
                                    <span *ngIf="innerItem.showInput && innerItem.sub_type=='string'"><input type="text" id="input" [(ngModel)]="innerItem.value" (blur)="innerItem.showInput=false"/></span>
                                    <span *ngIf="innerItem.showInput && innerItem.sub_type=='number'"><input type="number" id="input" [(ngModel)]="innerItem.value" (blur)="innerItem.showInput=false"/></span>
                                    <span *ngIf="!innerItem.showInput && innerItem?.value" class="paramValue arrayParamInput" (click)="paramIndex=paramindex;" [ngbPopover]="functionParams" #popParameterInfo="ngbPopover" placement="auto" [autoClose]="'outside'">{{innerItem?.value}}
            <!--                             <span class="dotsInfo" (click)="paramIndex=innerIndex;" [ngbPopover]="functionParams" #popParameterInfo="ngbPopover" placement="auto" [autoClose]="'outside'"><img _ngcontent-utx-c310="" src="../../../assets/1x/vertdots.svg" style="height:12px;width:12px;"></span>
             -->                        </span>
                                    <span *ngIf="param?.params?.length!==paramindex+1">,</span>
                                </span>
                            </ng-conatiner>
                             <span class="danger" *ngIf="(param?.type==='array' || param?.sub_type==='array')">
                                <span class="plus" (click)="paramIndex=-1;" [ngbPopover]="!hideValidBar && functionParams" #popParameterParam="ngbPopover" placement="auto" [autoClose]="'outside'">
                                    <div style="cursor: pointer;width: 18px;height: 18px;border-radius: 3px;display: flex;align-items: center;justify-content: center;"><i class="fa fa-plus" style="color: #727985;line-height: normal"></i></div>
                                </span>
                             ]</span>
                             <ng-template #functionPopoverParam>
                                <div class="funContainer">
                                  <app-function-popover [automationCall]="automationCall" (getFunctionsEmit)="getFunctionsEmit($event,param)"></app-function-popover>
                                </div>
                              </ng-template>
                        </ng-container>
                    </div>    
                    <span *ngIf="data?.operation_type !== undefined && data?.operation_type !== null && !data?.manually && !data?.isColumn" contenteditable="true" [class.formulaUnique]="data.uniqueid" [attr.data-id]="data.uniqueid">)</span>
                    <ng-template #formulaParams>
                        <div class="formulaPopoverBody">
                            <ng-container *ngFor="let field of data?.columnList">
                                <div class="paramOptionRow" (click)="insertColumn(innerIndex,field)"><span>{{field?.display_key}} ( {{field?.dashboard_name}} )</span></div>
                            </ng-container>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #functionPopover>
    <div class="funContainer">
      <app-function-popover [automationCall]="automationCall" (getFunctionsEmit)="getFunctionsEmit($event,innerIndex)"></app-function-popover>
    </div>
  </ng-template> 

  <ng-template #columnFuncPop>
    <app-automation-columns [dashboardVariable]="dashboardVariable" [dashVariableArr]="dashVariableArr" [filterCall]="true" [isHeader]="false" [showIndex]="false" [item]="item" [dashId]="dashId" [customFieldArr]="customFieldArr" [isTriggeredNonItemField]="isTriggeredNonItemField" [catchHookReqJson]="catchHookReqJson" [triggerType]="triggerType" [columnsJson]="columnsJson" (closeEmit)="popColumns.close()" (selectedColumn)="insertColFun($event)"></app-automation-columns>
 </ng-template>   


