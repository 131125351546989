import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { HttpTransferService } from "app/services/httpTransfer.service";
import { ToasterService } from "app/services/toaster.service";
import { ConstantService } from "Enums/Constant.service";

@Component({
    selector: 'app-board-icon',
    templateUrl: './board-icon.component.html',
    styleUrls: ['./board-icon.component.scss']
  })
  export class BoardIconComponent implements OnInit {
  @Output() changeIcon = new EventEmitter<any>;
  @Input() dashId:any;
  @Input() selectedColor:any='#03a9f4'
  faIconsList: any;
  
  constructor(public constant : ConstantService,private httpTransfer : HttpTransferService,private toasterService : ToasterService){}

  ngOnInit(){
  this.faIconsList = this.constant.getFaIcons()
  }


  selectDashboardIcon(icon){
    this.httpTransfer.updateDashBoardCorrespondingToOrganisation({ icon: icon,icon_color:this.selectedColor }, null, this.dashId).subscribe((res : any) => {
      if (res !== undefined && res.status == 200) {  
        this.changeIcon.emit()  
        this.toasterService.Success('Successfully changed icon')
      }
    })
  }
}