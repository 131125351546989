import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { CommonUtils } from '../../services/CommonUtils.service';
import { CustomStorageService } from '../../services/custom-storage.service';
import { DashboardUtilsService } from '../../services/dashboard-utils.service';
import { HttpTransferService } from '../../services/httpTransfer.service';
import { ToasterService } from '../../services/toaster.service';
import { commonIcons } from 'Enums/attributes-icons';

@Component({
  selector: 'app-group-member',
  templateUrl: './group-member.component.html',
  styleUrls: ['./group-member.component.scss']
})
export class GroupMemberComponent implements OnInit {

  constructor(
    private commonUtils :CommonUtils,
    private httpTransfer:HttpTransferService,
    private toaster:ToasterService,
    private dashboardUtils: DashboardUtilsService,
    private customStorageService : CustomStorageService
  ) { }
  @Input() selectedGroup:any=null
  @Input()  openInpopover:boolean=false
  @Input() addMemberOnly:boolean=false;
  @Output() openConfirmationPopup: EventEmitter<any> = new EventEmitter<any>();
  @Output() openAddMemberModel : EventEmitter<any> = new EventEmitter<any>();
  @Output() updateOnRemoveUser: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateOnAddUser: EventEmitter<any> = new EventEmitter<any>();
  @Output() setComponentRef: EventEmitter<any> = new EventEmitter<any>();
  allUsers : any=[]
  allmembersOfSelectedGroup : any=[]
  isloggedInUserGroupAdmin : boolean=false
  loginUserId:any=null
  removingUser:any=null
  currentView:any="GroupMember"
  removeCollaborator: boolean=false;
  removeCollaboratorAndAssignee: boolean=false;
  nonGroupMembersList:any=[]
  nonGroupMembersListCopy:any=[]
  disableAddMemberButtonUntilRes:boolean=false
  commonIcons = commonIcons;
  async ngOnInit() {
    this.setComponentRef.emit()
    if(this.addMemberOnly){
      this.currentView='AddMembers'
    }
    if(this.selectedGroup.group_id)this.selectedGroup._id=this.selectedGroup.group_id
    this.loginUserId = this.customStorageService.getItem("id");
    await this.getOrgUsers()
    this.filterGroupUsers()
  }
  ngOnChanges(changes:SimpleChange) {

      this.filterGroupUsers();
  }

  async getOrgUsers(){
    let dashboardList=await this.dashboardUtils.getOrgUsers();
    this.allUsers = Object.values(dashboardList);
  }
  filterGroupUsers(){
    this.allmembersOfSelectedGroup=[]
    this.nonGroupMembersList=[]
    this.nonGroupMembersList=JSON.parse(JSON.stringify(this.allUsers));;
    
    this.selectedGroup?.group_members.forEach(member=> {
      const userIndexInAll =this.allUsers.findIndex(user => user._id === member.user_id);
      const userIndexInNonMembers =this.nonGroupMembersList.findIndex(user => user._id === member.user_id);
      if(userIndexInAll >= 0) {
        this.allUsers[userIndexInAll].group_admin = member.group_admin;
        this.allmembersOfSelectedGroup.push(this.allUsers[userIndexInAll]);
      }
      if(userIndexInNonMembers>-1){
        this.nonGroupMembersList.splice(userIndexInNonMembers,1);
      }
    })
    this.nonGroupMembersListCopy=[...this.nonGroupMembersList]
    this.isloggedInUserGroupAdmin=false
    let logginUserindex=this.selectedGroup?.group_members.findIndex(user=>user.user_id==this.loginUserId);
    if(logginUserindex>-1){
      this.isloggedInUserGroupAdmin=this.selectedGroup?.group_members[logginUserindex]?.group_admin
    }
  }
  addOrUpdateUserInGroup(selectedUser: any,isAddingUser?,isUpdateingUser?) {
    let selectUserIndex=this.selectedGroup?.group_members.findIndex(user=>user.user_id==selectedUser._id);
    let user=null
    if(selectUserIndex>-1){
      user=this.selectedGroup?.group_members[selectUserIndex];
    }
    let data={}
    let makeAdmin=!user?.group_admin
    if(isUpdateingUser){
      data = {
        addMemberFields: [
          {
            user_id : user?.user_id,
            date_of_join: user?.date_of_join,
            expiry_date: 0,
            group_admin: makeAdmin,
            added_by: user?.added_by
          }
        ]
      }
    }
    if(isAddingUser){
      this.disableAddMemberButtonUntilRes = true;
      selectedUser.isAdding=true
      data = {
        addMemberFields: [
          {
            user_id : selectedUser._id,
            date_of_join: Date.now(),
            expiry_date: 0,
            group_admin: false,
          }
        ]
      }
    }
    this.httpTransfer.addMemberInGroup(this.selectedGroup._id, data).subscribe((res : any) => {
      if(res.status==200) {
        if(isUpdateingUser){
          selectedUser.group_admin = makeAdmin;
          let userIndex=this.selectedGroup?.group_members.findIndex(user=>user.user_id==selectedUser._id)
          if(userIndex>-1){
            this.selectedGroup.group_members[userIndex].group_admin=makeAdmin
          }
          if(this.loginUserId==selectedUser._id){
            this.isloggedInUserGroupAdmin=makeAdmin;
          }
        }
        if(isAddingUser){
          this.toaster.Success('User successfully added in the group!');
          this.disableAddMemberButtonUntilRes = false;
          this.selectedGroup?.group_members.push({user_id: selectedUser._id, date_of_join: Date.now(), group_admin: false, added_by: null})
          selectedUser.isAdding=false
          selectedUser.newAdded=true
          this.allmembersOfSelectedGroup.push(selectedUser);
          this.updateOnAddUser.emit()
        }
      }
    }, (err)=> {
      this.disableAddMemberButtonUntilRes = false;
      selectedUser.isAdding=false
    })
  }
  removeFromGroup(user){
    this.removingUser=user
    if(this.openInpopover){
      this.currentView='UserRemovingConfirmation'
    }
    else{
      this.openConfirmationPopup.emit(user._id);
    }
    
  }
  openAddMemberPopup(){
    if(this.openInpopover){
      this.filterGroupUsers();
      this.currentView='AddMembers'
    }
    else{
      this.openAddMemberModel.emit(this.selectedGroup);
    }
  }

  deleteMemberFromGroup(removeCollaborator,removeCollaboratorAndAssignee) {
    this.httpTransfer.removeMemberFromGroup(this.selectedGroup._id,this.removingUser._id,removeCollaborator,removeCollaboratorAndAssignee).subscribe((res : any) => {
      if(res !== undefined && res.status==200) {
        this.toaster.Success('User has been deleted successfully.');
        this.allmembersOfSelectedGroup = this.allmembersOfSelectedGroup.filter(user=> user._id !== this.removingUser._id)
        let delUserIndex=this.selectedGroup?.group_members.findIndex(user=>user.user_id==this.removingUser._id)
        
        if(delUserIndex>-1){
          // this.updateOnRemoveUser.emit(this.selectedGroup?.group_members[delUserIndex])
          this.selectedGroup?.group_members.splice(delUserIndex,1);
        }
      }
    })
  }
  manageSelectOption(removeCollaborator : boolean=false,removeCollaboratorAndAssignee : boolean=false){
    this.removeCollaborator= removeCollaborator
    this.removeCollaboratorAndAssignee = removeCollaboratorAndAssignee
  }
  searchUsers(text:any){
    text=text.toLowerCase();
    this.nonGroupMembersList = this.nonGroupMembersListCopy.filter(user=> user.name.toLowerCase().includes(text) || user?.email.toLowerCase().includes(text));
  }
}
