import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CropperPosition, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
// import { ImageDrawingComponent } from 'ngx-image-drawing';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { MediaIcons } from 'Enums/media-icons';
import { ContentEditableInputNoteComponent } from 'app/all-reusables/dashboard-reusables/content-editable-input-note/content-editable-input-note.component';
import { ConstantService } from 'Enums/Constant.service';
import { MessageService } from 'app/message.service';
import * as markerjs2 from 'markerjs2';
import * as cropro from 'cropro';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements OnInit {

  imageFileUrl : any=[]
  @Input() rplyObj:any;
  @Input() imageFiles : any=[]
  @Input() itemData : any
  @Input() fileType ='SingleImage'
  @Input() isChatView:boolean = false;
  @Input() selectedLeadObj:any;
  @Input() dashId : any;
  @Input() currentActiveTab:any;
  @Output() closeModal = new EventEmitter();
  @Output() editImage: EventEmitter<any> = new EventEmitter();
  @Output() sendChatAttachment : EventEmitter<any> = new EventEmitter();
  @ViewChild(ContentEditableInputNoteComponent) editor!: ContentEditableInputNoteComponent;
  @ViewChild('widgetsContent') widgetsContent: ElementRef;
  @ViewChild('imageRef',{ static: false }) imageRef:any
  @ViewChild('renderer',{ static: false }) renderer:any
  showLoader:boolean=false
  imageBaseUrl   : any =null
  editType="";
  transform: ImageTransform = {};
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  selectedImgIndex=0
  mediaIcons = MediaIcons;
  showEditor: boolean = false;
  MediaType: { IMAGE: string[]; VIDEO: string[]; FILE: any[]; AUDIO: string[];};
  markerArea:any
  cropper:any
  cropBlobFile:any;


  
  constructor(private dashboardUtils:DashboardUtilsService,
    private httpTransfer:HttpTransferService,
    private commonUtils:CommonUtils,
    public constant : ConstantService,
    public msgservice : MessageService) {
     }

  async ngOnInit(){
    console.log(this.imageFiles)
    this.MediaType = this.constant.getMediaTypes()
    this.convertDataUrl(this.imageFiles)
   
  }
  ngAfterViewInit(){
    // setTimeout(()=>{this.initilizeMakerJs()},0)
  }

  // delete image
  deleteImage(index?){
    if(this.imageFileUrl.length==1){
      this.closeModal.emit()
      return
    }
    if(this.selectedImgIndex>index){
      this.selectedImgIndex--
    }
    if(this.selectedImgIndex==index && this.selectedImgIndex==this.imageFileUrl.length-1){
      this.selectedImgIndex=0
    }
    this.imageFileUrl.splice(index,1)
    this.imageBaseUrl=this.imageFileUrl[this.selectedImgIndex].url
  }

  // update imageBaseUrl value
  updateImgUrl(url){
    this.imageBaseUrl=url
    this.imageFileUrl[this.selectedImgIndex].url=url
  }

  // on submit image
  submitFiles(){
    let eventFile=[]
    for (let i = 0; i < this.imageFileUrl.length; i++) {
      let file= this.imageFileUrl[i]?.eventFile;
      if(this.imageFileUrl[i]?.originalUrl!=this.imageFileUrl[i].url){
        file=this.base64ToFile(this.imageFileUrl[i].url,this.imageFileUrl[i].name);
      }
      eventFile.push(file)
    }
    // case when user not edit image and press submit;
    if(this.imageFileUrl.length==1 && this.imageFileUrl[0]?.path && this.imageFileUrl[0]?.originalUrl==this.imageFileUrl[0].url) eventFile=[]
    this.editImage.emit(eventFile)
    this.closeModal.emit()
  }
  // change selected imahe
  changeSelectedImage(index,item){
    this.selectedImgIndex=index
    this.imageBaseUrl=item.url
    this.editor.resetText(this.imageFileUrl[index].caption)
  }

  // convert base64 image to File
  base64ToFile(data, filename) {
    const arr = data.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  // used for scrolling left and right
  moveElement(value) {
    this.widgetsContent.nativeElement.scrollLeft = this.widgetsContent.nativeElement.scrollLeft + value;
  }
  // convert and get url of image from file event
  async convertDataUrl(imageFiles){
    console.log(imageFiles)
    for (let i = 0; i < imageFiles.length; i++) {
      let file=imageFiles[i]
      console.log(file?.type)
      console.log(file?.type?.split('/')[0])
      let fileType= file?.type ? file?.type?.split('/')[0] : file?.fileType ? file?.fileType   : 'image'
      console.log(file)
      console.log(fileType)
      let previewData={}
      if(file?.image_download_url){
        // condition when url is expired
        if(this.itemData && this.commonUtils.isFileURLExpired(file?.image_download_url)){
          let newUrl=await this.getLatestUrl(file?.path)
          if(!newUrl) break;
          file.image_download_url= newUrl
        }
        let url=await this.dashboardUtils.imageToBase64Url(file?.image_download_url)
        url=url.toString().replace(":application/octet-stream;",":image/"+file?.extension+";")
        if(this.imageBaseUrl==null){
          this.imageBaseUrl=url
         this.selectedImgIndex=i
        }
        previewData["name"] = file?.extension ? file?.name ? file?.name+"."+file?.extension:file?.file_name+"."+file?.extension : file?.file_name ? file?.file_name : file?.name ;
        previewData["path"] = file?.path?file?.path:file?.file_path;
        previewData["url"] = url;
        previewData["originalUrl"] = url;
        previewData["eventFile"] = file;
        previewData["fileType"] = fileType
        if(file?.caption){
          previewData["caption"] = file?.caption
        }
        this.imageFileUrl.push(previewData)
      }else{
        if(this.imageBaseUrl==null) this.selectedImgIndex=i
        let reader = new FileReader();
        reader.onload = (event: any) => {
          if(this.imageBaseUrl==null)this.imageBaseUrl=event.target.result;
          console.log(event.target.result)
          previewData["name"] = file?.name?file?.name:file?.file_name
          previewData["url"] = file?.url ? file?.url : event.target.result;
          previewData["originalUrl"] = event.target.result;
          previewData["eventFile"] = file
          previewData["fileType"] = fileType;
          previewData["type"] = file?.type?file.type:'from_computer';
          previewData["size_in_bytes"] = file?.size_in_bytes?file.size_in_bytes:0;
          this.imageFileUrl.push(previewData)
        };
        reader.readAsDataURL(file);
      }
      console.log(this.imageFileUrl)
    }
    if(this.isChatView){
      this.msgservice.getChannelTemplateMessage().subscribe(data => {
        if(data){
          console.log(data,this.imageFileUrl)
          this.imageFileUrl[this.selectedImgIndex]['caption'] = data;
        }
      });
    }
    setTimeout(()=>{
      this.showEditor = true;
    },500)
  }

  initilizeMakerJs(){
    this.markerArea = new markerjs2.MarkerArea(this.imageRef.nativeElement as HTMLImageElement);
      setTimeout(() => {$('.__markerjs2_')?.appendTo(this.renderer?.nativeElement); },0);
      this.markerArea.renderImageQuality = 1;
      this.markerArea.availableMarkerTypes = [
        markerjs2.ArrowMarker,
        markerjs2.EllipseFrameMarker,
        markerjs2.FrameMarker,
        markerjs2.TextMarker,
        markerjs2.FreehandMarker,
      ];
      this.markerArea.position = 'left';
      // this.markerArea.renderAtNaturalSize = true;
      this.markerArea.renderImageType = 'image/jpeg';
      this.markerArea.renderImageQuality = 1;
      this.markerArea.availableMarkerTypes = this.markerArea.ALL_MARKER_TYPES;
      // enable redo, notes, zoom, and clear buttons (hidden by default)
      this.markerArea.uiStyleSettings.redoButtonVisible = true;
      // this.markerArea.uiStyleSettings.notesButtonVisible = true;
      // this.markerArea.uiStyleSettings.zoomButtonVisible = true;
      // this.markerArea.uiStyleSettings.zoomOutButtonVisible = true;
      // this.markerArea.uiStyleSettings.clearButtonVisible = true;
      // this.markerArea.uiStyleSettings.canvasRotation=true
      // this.markerArea.uiStyleSettings.toolboxColor = '#F472B6';
      // this.markerArea.uiStyleSettings.toolboxAccentColor = '#BE185D';
      this.markerArea.uiStyleSettings.toolbarStyleColorsClassName = 'toolbar-bg';
      // this.markerArea.uiStyleSettings.toolbarButtonStyleColorsClassName ='toolbar-icon';
      // this.markerArea.uiStyleSettings.toolbarActiveButtonStyleColorsClassName ='active-toolbar-icon';
      // this.markerArea.uiStyleSettings.toolboxButtonStyleColorsClassName='toolbar-icon';
      this.markerArea.uiStyleSettings.toolboxButtonRowStyleColorsClassName='toolbar-bg'
      // this.markerArea.uiStyleSettings.toolboxActiveButtonStyleColorsClassName ='active-toolbar-icon'
      this.markerArea.uiStyleSettings.toolbarOverflowBlockStyleColorsClassName = "toolbar-bg";
      // this.markerArea.uiStyleSettings
      let isImageEdit=false
      this.markerArea.addCloseEventListener(() => {});
      try{
        this.markerArea.addEventListener("render", (event) => { 
          if(event?.dataUrl && isImageEdit)this.updateImgUrl(event.dataUrl);
          isImageEdit=false
          this.switchAndSaveEditView('')
        });
        this.markerArea.addEventListener("beforeclose", (event) => {
          this.switchAndSaveEditView('')
        });
      }
      catch(e){}
      this.markerArea.addEventListener('statechange',(event)=>{isImageEdit=true})
      this.markerArea.addEventListener("markercreate", (event) => {event?.markerArea?.createNewMarker(event?.marker?.typeName);});
      this.markerArea.show();
      this.showLoader=false
  }

  insilizeCropper(){
    this.cropper = new cropro.CropArea(this.imageRef.nativeElement as HTMLImageElement);
    setTimeout(() => {$('.__cropro_')?.appendTo(this.renderer?.nativeElement); },0);
    this.cropper.renderImageType = 'image/jpeg';
    this.cropper.renderImageQuality = 1;
    this.cropper.zoomToCropEnabled = false;
    this.cropper.styles.settings.toolbarStyleColorsClassName = "toolbar-bg";
    this.cropper.addRenderEventListener(dataUrl => {
      if(dataUrl)this.updateImgUrl(dataUrl);
      this.switchAndSaveEditView('')
    });
    this.cropper.addCloseEventListener(()=>{
      this.switchAndSaveEditView('')
    })
    // handle close 
    setTimeout(()=>{
      let cropHeaderRef= document.querySelectorAll('.toolbar-bg')
      if(cropHeaderRef?.length>0){cropHeaderRef[0]?.querySelector('[title="Close"]')?.addEventListener('click',()=>{this.switchAndSaveEditView('')})}
    },1)
    this.cropper.show();
    this.showLoader=false

}

  switchAndSaveEditView(view?){
    this.editType=view 
    this.cropper=null
    this.markerArea=null
    if(view=="ImageDrawer"){
      this.showLoader=true
      setTimeout(()=>{this.initilizeMakerJs()},1)
    }
    else if(view=="Cropper"){
      this.showLoader=true
      setTimeout(()=>{this.insilizeCropper()},1)
    }
  }
  async getLatestUrl(path?){
    let inputJson={'dashboard_id' : [this.itemData.dashId],'lead_id' : [this.itemData.leadId]}
    let res : any=await this.httpTransfer.getLeadQuery(inputJson ,this.itemData.dashId).toPromise()
    let result = res.result.leadResponse[0].custom_fields;
    let currentCustomData=result[this.itemData.customKey]
    let index=currentCustomData.findIndex(data=>data.file_path==path);
    return index>-1 ? currentCustomData[index].file_url : null
  }

  sendChatMsg(){
    console.log(this.imageFileUrl)
    this.sendChatAttachment.emit(this.imageFileUrl)
  }

  mediaColumns(event){
    console.log(event)
    let arr = []
   if(event?.media?.length){
    event.media.forEach(e=>{
      let obj = {}
      obj['image_download_url'] = e?.image_download_url ? e?.image_download_url : e?.file_url ? e?.file_url : e?.url
      obj['path'] = e.file_path
      obj['name'] = e.file_name
      if(this.MediaType.IMAGE.includes(e.file_path.split('.').pop())){
      obj['type'] = "image" +"/"+e.file_path.split('.').pop()
      }
      else if(this.MediaType.VIDEO.includes(e.file_path.split('.').pop())){
        obj['type'] = "video" +"/"+e.file_path.split('.').pop()
      }
      else if(this.MediaType.AUDIO.includes(e.file_path.split('.').pop())){
        obj['type'] = "audio" +"/"+e.file_path.split('.').pop()
      }
      else{this.imageFiles
        obj['type'] = "file" +"/"+e.file_path.split('.').pop()
      }
      this.imageFiles.push(obj)
      this.showEditor = false;
      this.convertDataUrl([obj])
    })
   }
  }

}
