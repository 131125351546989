<div class="evalPopBody mt-3">

    <div style="display: flex;flex-direction: column;width: 100%;row-gap: 10px;">
        <div style="display: flex;width: 100%;column-gap: 5px; align-items: center;">
            <input type="checkbox" class="filterCheckBox"  [(ngModel)]="addSubEnable" (ngModelChange)="addSubBtnTggled($event,copiedDateObj);validateAddInput()">
            <span for="" class="textHead">  Add/Sub from Today’s Date</span>
        </div>

        <div *ngIf="addSubEnable" style="display: flex;width: 100%;column-gap: 5px;align-items: center;">
            <div class="addSubDateSpan">
                <span>Day(s)</span>
                <input type="number" class="addSubDateInput" [(ngModel)]="copiedDateObj.relative_date.day"  (ngModelChange)="dateObjChanged()" (focusout)="emptyFocusOut('day');validateAddInput()">
            </div>
            <div class="addSubDateSpan">
                <span>Month(s)</span>
                <input type="number" class="addSubDateInput" [(ngModel)]="copiedDateObj.relative_date.month"  (ngModelChange)="dateObjChanged()" (focusout)="emptyFocusOut('month');validateAddInput()">
            </div>
            <div class="addSubDateSpan">
                <span>Year(s)</span>
                <input type="number" class="addSubDateInput" [(ngModel)]="copiedDateObj.relative_date.year"  (ngModelChange)="dateObjChanged()" (focusout)="emptyFocusOut('year');validateAddInput()">
            </div>
    
        </div>
        <div *ngIf="addSubEnable && isInvalidInput">
            <span class="text-danger">*Please Enter Valid Value.</span>
        </div>
    </div>
    
    <div style="display: flex;width: 100%;column-gap: 5px;align-items: center;justify-content: space-between;">
        <span>Relative(R) Date: <span style="color:#3571DE"> {{relativeDateString}} </span>  </span>
        <span> > </span>
        <span style="color:#3571DE">R Day: {{relativeDateForInfo?.day}} , R Month: {{relativeDateForInfo?.month}} , R year: {{relativeDateForInfo?.year}}</span>        
    </div>

    <div style="display: flex;flex-direction: column;width: 100%;row-gap: 5px;">
        <div class="textHead">Evaluated Date </div>
        <div style="display: flex;width: 100%;column-gap: 5px;align-items: center;">
            <div class="addSubDateSpan">
                <span>Day</span>

                <ng-select style="height:fit-content;" [multiple]="false" bindLabel="display_key" [closeOnSelect]="true" 
              [clearable]="false" [ngModelOptions]="{standalone: true}" [(ngModel)]="copiedDateObj.day.value" (ngModelChange)="dateObjChanged()">
                <ng-option *ngFor="let item of dayOptions" [value]="item.key"  [ngClass]="{'disableOption':!isThisDayAllowed(item.key)}" [disabled]="!isThisDayAllowed(item.key)">
                    {{item.display_key}}
                </ng-option>
               </ng-select>
            </div>
            <div class="addSubDateSpan">
                <span>Month</span>

                <ng-select style="height:fit-content;" [multiple]="false" bindLabel="display_key" [closeOnSelect]="true" 
                [clearable]="false" [ngModelOptions]="{standalone: true}" [(ngModel)]="copiedDateObj.month.value" (ngModelChange)="dateObjChanged()">
                    <ng-option *ngFor="let item of monthOptions" [value]="item.key"  [ngClass]="{'disableOption':!isThisMonthAllowed(item.key)}" [disabled]="!isThisMonthAllowed(item.key)">
                    {{item.display_key}}
                    </ng-option>
                 </ng-select>

            </div>
            <div class="addSubDateSpan">
                <span>Year</span>
                <ng-select *ngIf="copiedDateObj.year.value=='{{relative_year}}'" style="height:fit-content;" [multiple]="false" bindLabel="display_key" [closeOnSelect]="true" 
                [clearable]="false" [ngModelOptions]="{standalone: true}" [(ngModel)]="copiedDateObj.year.value" (ngModelChange)="changeYearType();dateObjChanged()">
                  <ng-option *ngFor="let item of yearOptions" [value]="item.key">
                      {{item.display_key}}
                  </ng-option>
                </ng-select>
                <div *ngIf="copiedDateObj.year.value!='{{relative_year}}'" style="display: flex;justify-content: space-between;align-items: center;">
                    <input  type="number" class="addSubDateInput" [(ngModel)]="copiedDateObj.year.value" (ngModelChange)="dateObjChanged()" (focusout)="emptyFocusOut('',true);validateYear()">
                    <span class="closeIcon" (click)="copiedDateObj.year.value='{{relative_year}}';validateYear()">
                        X
                    </span>
                </div>

            </div>
            <div class="addSubDateSpan">
                <span>Adjust Week day</span>
                <ng-select style="height:fit-content;" [multiple]="false" bindLabel="display_key" [closeOnSelect]="true" 
                [clearable]="false" [ngModelOptions]="{standalone: true}" [(ngModel)]="copiedDateObj.weekday_adjust.value" (ngModelChange)="dateObjChanged()">
                  <ng-option *ngFor="let item of weekdayAdjustOption" [value]="item.key"  [ngClass]="{'disableOption':!isThisDayAllowed(item.key)}" [disabled]="!isThisDayAllowed(item.key)">
                      {{item.display_key}}
                  </ng-option>
                 </ng-select>
            </div>
    
        </div>
        <div *ngIf="isInvalidYear">
            <span class="text-danger">*Please Enter Valid Value.</span>
        </div>
           
    </div>
    <div>
        <span class="finalEvalDate">
            <span>
                Evaluated Date(according to today’s date)   
            </span>
            <span>
                {{ evalDateString }}
            </span>
        </span>
    </div>
    <div>
        <span *ngIf="!isInvalidInput && !isInvalidYear" class="saveBtn" (click)="saveEvalDate()">
            Save
        </span>
        <span *ngIf="isInvalidInput || isInvalidYear" class="saveBtnDisabled">
            Save
        </span>
    </div>
</div>