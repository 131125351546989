import { Component, OnInit, Output,EventEmitter, Input, HostListener } from '@angular/core';
import { HttpTransferService } from '../../../services/httpTransfer.service';
@Component({
  selector: 'app-location-item-settings',
  templateUrl: './location-item-settings.component.html',
  styleUrls: ['./location-item-settings.component.scss']
})
export class LocationItemSettingsComponent implements OnInit {
  @HostListener("document:click", ["$event"])
  mouseEvent(event: MouseEvent) {
    this.checkForMouseEvent();
  } 
  checkForMouseEvent() {
    if (this.prevClick) {
      this.showSuggestions = false;
    }
    this.prevClick = this.showSuggestions
} 
  @Output() selectedLocation = new EventEmitter<string>();
  @Output() selectedLocationName = new EventEmitter<string>();
  @Input() locationData :any
  @Input() isLocked :boolean=false
  @Input() automationCall :boolean=false
  @Input() multiselectCall :boolean=false
  @Input() gridSelectCall :boolean=false
  islocationsLoading:boolean=false
  locationList:any[]=[]
  currentLocation:any

  searchInput:string=''
  searchPlaceId: string=''
  showSuggestions:boolean = false;
  prevClick:boolean = false;
  constructor(
    private httpTransferService:HttpTransferService
  ) { }

  ngOnInit(): void {
    console.log("here check",this.locationData)
    if(this.locationData?.location){
      this.currentLocation=this.locationData.location
      this.searchPlaceId = this.locationData.location.place_id
      this.searchInput = this.locationData.location.description? this.locationData.location.description : this.locationData.location.latitude? this.locationData.location.latitude+ ', '+this.locationData.location.longitude:''

      this.locationList.push(this.locationData.location)
    }
    if(this.locationData && this.gridSelectCall){
      this.currentLocation=this.locationData
      this.searchPlaceId = this.locationData.place_id
      this.searchInput = this.locationData.description? this.locationData.description : this.locationData.latitude? this.locationData.latitude+ ', '+this.locationData.longitude:''

      this.locationList.push(this.locationData.location)
    }
  }

  searchLocation(text:string){
    if (text.trim() != '') {
      this.islocationsLoading = true
      text = text.trim()
      this.httpTransferService.locationSearch({ "location_search": text }).subscribe(res => {
        console.log(res)
        this.locationList = res.result.predictions
        this.islocationsLoading = false
      }, error => {
        this.islocationsLoading = false
      })
    }
  }
  locationToSearch(){
    this.selectedLocation.emit(this.searchPlaceId)
    this.selectedLocationName.emit(this.searchInput)
  }

  setLocation(data){
    if (!this.isLocked) {
      if(typeof(data) !== 'string'){
        this.currentLocation=data
        this.searchInput = data.description? data.description: data.latitude+ ', '+data.longitude
        this.searchPlaceId = data.place_id
        this.locationToSearch()
      }
      else if(data.trim().length==0){
        this.currentLocation=null
        this.searchInput = ''
        this.searchPlaceId = null
        this.locationToSearch()
      }
    }
    
  }

  ngOnDestroy() {
    document.removeEventListener('document:click', this.mouseEvent);
  }
}
