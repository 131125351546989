<ng-container >
   <div class="modalHeaderContainer">
      <div id="exampleModalLabel" class="modalHeader mb-0 f-xxl-xbb">
         {{(selectedGroup ? 'groupView.editGroup' : 'groupView.createGroup') | translate}}
         <span (click)="createGroupModelRef.close()" class="iconBox-30"><app-dashboard-icon class="iconBox-24" [size]="20"  [name]="'closeOutline'" [iconJson]="commonIcons"></app-dashboard-icon></span>
      </div> 
   </div>
   <div class="modal-body modalDataContainer f-l-r">
      <div class="row form-container te">
         <div class="col-md-12 px-4">
            <form [formGroup]="createGroupForm">
               <div class="mb-2">
                  <div class="form-group f-l-m mb-1">
                     <label for="group_name"> {{'groupView.enterGroupName' | translate}}</label>
                     <b class="text text-danger">*</b>
                     <input type="text" id="group_name" class="form-control grey-background" formControlName="group_name">
                  </div>
                  <div 
                     *ngIf="createGroupForm.get('group_name').invalid && (createGroupForm.get('group_name').dirty || createGroupForm.get('group_name').touched)"
                     class="text text-danger f-s-r">
                     <div *ngIf="createGroupForm.get('group_name').errors.required">
                        {{'common.nameValidation.reuire' | translate}}
                     </div>
                     <div *ngIf="createGroupForm.get('group_name').errors.isLengthMinimum">
                        {{'common.nameValidation.minChar' | translate}}
                     </div>
                     <div *ngIf="createGroupForm.get('group_name').errors?.maxlength && !createGroupForm.get('group_name').errors?.isLengthMinimum">
                        {{'common.nameValidation.maxChar' | translate}}
                     </div>
                  </div>
               </div>

               <div class="mb-2">
                  <div class="form-group f-l-m mb-1">
                     <label for="description">{{'common.description' | translate}}</label> 
                     <textarea id="description" class="form-control grey-background" cols="30" rows="5" formControlName="description"></textarea>
                  </div>
               </div>

               <div class="mb-2">
                  <div class="mb-2 f-l-m">{{'groupView.visibilityLevel' | translate}}</div>
                  <div class="f-l-r checkbox mb-1">
                     <label style="margin-right: 80px;">
                        <input type="radio" name="is_private" formControlName="is_private" [value]="true"> {{'groupView.privateGroup' | translate}}
                     </label>
                     <label>
                        <input type="radio" name="is_private" formControlName="is_private" [value]="false" checked> {{'groupView.publicGroup' | translate}}
                     </label>
                  </div>
                  <div
                     *ngIf="createGroupForm.get('is_private').invalid && (createGroupForm.get('is_private').dirty || createGroupForm.get('is_private').touched)"
                     class="text text-danger f-s-r">
                     <div *ngIf="createGroupForm.get('is_private').errors.required">
                        {{'groupView.selectGroupType' | translate}}
                     </div>
                  </div>
               </div>
               <div class="d-flex justify-content-end w-100 createBtn">
                  <button *ngIf="!creatingGroup" class="themeChild commonBtn-36 p-0 btn" [disabled]="createGroupForm.invalid" (click)="selectedGroup ? onUpdateGroup() : onCreateGroup()"><span class="commonBtnBox-Child-1">{{(selectedGroup ? 'common.update'  : 'common.create') | translate}}</span></button>
                  <button *ngIf="creatingGroup" class="themeChild commonBtn-36 btn p-0" [disabled]="true">
                  <div class="spinner-border" role="status" style="width: 20px;height: 20px;"><span class="sr-only">{{'common.loading' | translate}}...</span></div>  
                  {{(selectedGroup ? 'common.updating' : 'common.creating') | translate}}
               </button>
               </div>
            </form>
         </div>
      </div>
   </div>
</ng-container> 