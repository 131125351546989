import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { ConstantService } from "Enums/Constant.service";
import { MediaIcons } from "Enums/media-icons";
import { CommonUtils } from "app/services/CommonUtils.service";
import { DashboardUtilsService } from "app/services/dashboard-utils.service";
import { HttpTransferService } from "app/services/httpTransfer.service";
import { ToasterService } from "app/services/toaster.service";
import { AttributesIcons } from '../../../../Enums/attributes-icons';
declare var $: any;

@Component({
    selector: 'app-channel-template',
    templateUrl: './channel-template.component.html',
    styleUrls: ['./channel-template.component.scss']
  })
  
  export class ChannelTemplateComponent implements OnInit {
    @Input() personalTemplates:boolean=false
    @Input() dashId:any;
    @Output() closeModal : EventEmitter<any> = new EventEmitter();
    @Output() useTemplate : EventEmitter<any> = new EventEmitter();
    @ViewChild('editableDiv') editableDiv: ElementRef;
    @ViewChild('popColumns') public popColumns: NgbPopover;
    @Input() templateCount:number=0
    @Input() isTemplateViewCall:boolean=false;
    @Input() editTemplateCall:boolean=false;
    @Input() templatesToEdit:any
    @Input() preview:boolean=false
    @Input() leadInfo:any={}
    storeSelectionRangeData: {};
    allTemplates: any[]=[];
    createEnable:boolean=false;
    updateEnable:boolean=false;
    customFieldArr: any[]=[];
    summernoteToolbar:any=[
      ['style', ['bold', 'italic']],
      ['font', ['strikethrough']],
     ]
     summerNoteconfig: {
      placeholder: string;
      tabsize: number;
      height: string;
      uploadImagePath: string;
      toolbar: (string | string[])[][];
      fontNames: string[];
    };
    templateName:string;
  templateMessage: any;
  editTemplateObj: any;
  attachment: any[]=[];
  fileEvent: any[]=[];
  templateMedia: any[]=[];
  mediaIcons = MediaIcons;
  storedCursorPosition: Range;
  columnIndex: any=0;
  columnsJson: any={};
  optionEnableForColumns: boolean = false;
  selectedCustomObjForColumn: { custom_key: any; custom_display_key: any; customField: boolean;mirror :boolean };
  algorithm:string=null;
  isCreatingEnable: boolean = false;
  @Input() type: string;
  emailSetCc: any;
  emailSetTo: any;
  emailSetBcc: any;
  validateToEmail:boolean=true;
  validateCcEmail:boolean=true;
  validateBccEmail:boolean=true;
  isMentionPanelOpenTo:boolean=false;
  isMentionPanelOpenCc: boolean;
  emailArrCc: any[]=[];
  emailArrTo: any[]=[];
  isMentionPanelOpenBcc: boolean;
  emailArrBcc: any[]=[];
  orgUsersJson: any[]=[];
  searchFororgUsersJson: any;
  enableCC:boolean=false;
  enableBCC:boolean=false;
  subject: string;
  @Input()viewOnly:boolean=false;
  @Input()tempType:string='';
  editTemplateName:boolean=false;
  attributesIcons = AttributesIcons;
  constructor(public httpTransfer : HttpTransferService,public constantService : ConstantService,public toaster : ToasterService,
        public commonUtils : CommonUtils,public dashboardUtils : DashboardUtilsService){
          this.summerNoteconfig = this.constantService.getSummerNoteConfig();
          
    }
    
    async ngOnInit(){
        let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
        let dashJson = JSON.parse(JSON.stringify(dashboardJson))
        this.customFieldArr = JSON.parse(JSON.stringify(dashJson[this.dashId]['CUSTOM_FORM'])) || {}    
        const orgUserJson = await this.dashboardUtils.getOrgUsers(); 
        this.orgUsersJson = Object.values(orgUserJson)    
        this.getTemplatelist();
        if(this.editTemplateCall){
          this.editTemplate(this.templatesToEdit)
        }
    }

  

    getTemplatelist(){
        let templateCategory
        let inputData={
          "template_category": this.personalTemplates ? ["PERSONAL_TEMPLATES"] : ["BOARD_TEMPLATES"],
          "type": [this.type],
        }
        if(!this.personalTemplates){
          inputData['dashboard_id']=[this.dashId]
        }
        this.httpTransfer.getEmailTemplate(inputData).subscribe((res : any) =>{
          this.allTemplates=res.result?.templates
        },
        error=>{
          console.log("error while getting all templates") 
        }
        )
        if(this.isTemplateViewCall){
          this.templateCount=this.templateCount+1;
          this.templateName=`template ${this.templateCount}`;
          this.templateMedia=[];
          this.attachment=[];
          this.fileEvent=[];
          this.columnIndex=0;
          this.columnsJson={};
          this.templateMessage='';
          this.subject='';
          this.emailArrTo=[];
          this.emailArrCc=[];
          this.emailArrBcc=[];
          this.createEnable=true;
        }
    }



    editTemplate(template){
      this.templateMedia = []
      this.attachment = []
      this.fileEvent = []
      if(template?.value_map){
        this.columnsJson = template?.value_map
        let keysArray = Object.keys(template?.value_map);
        this.columnIndex = keysArray.length 
      }
      console.log(template)
      this.editTemplateObj = template
      this.createEnable = false;
      this.updateEnable = true;
      this.templateName = template?.template_name
      this.templateMessage = template?.message
      this.subject = template?.additional_attributes?.subject
      if(template?.attachments?.length){
        this.checkForattachments(template?.attachments)
      }
      if(this.type=='EMAIL'){
        this.setToCcBccAndAttachmentsFromTemplate(template)
      }
     }

  checkForattachments(attachments){
    attachments.forEach(e=>{
     let previewData = {};
     let obj = {}
       previewData["file_name"] = e.file_name;
       previewData["path"] =  e.url?e.url:e?.file_url?e?.file_url:e?.file_path?e.file_path:e?.path;
       previewData["type"] = e.type.toLowerCase()
       obj["file_name"] = e.file_name;
       obj["path"] =  e.path?e.path:e?.file_path;
       obj["type"] = e.type ;
       this.templateMedia.push(previewData);   
       this.attachment.push(obj)       
    })
  }

  onBlur(event){
    console.log(event)
    this.templateMessage = event?.editorMessage
    this.templateMedia = event?.mediaArr
    this.columnIndex = event?.columnIndex
    this.columnsJson =event?.columnsJson
    this.fileEvent = event?.fileEvent
    this.attachment = event?.attachment
    this.subject = event?.subject
  }


    saveTemplate(){
       this.isCreatingEnable = true
       if(this.templateMedia.length && this.fileEvent?.length){
          this.commonUtils.onUploadFile(this.fileEvent, 'LEAD',null, this.setFilePathToArrOfCustomFileds.bind(this));
        } 
        else{
          this.createTemplate()
        }  
   }

   createTemplate(){
    if(this.attachment?.length){
      this.attachment?.forEach(ele=>{
        ele['file_path']=ele?.path
        delete ele?.path
      })
    }
      let inputData={}
      inputData["message"] = this.templateMessage,
      inputData["template_name"] = this.templateName,  
      inputData["attachments"] = this.attachment,  
      inputData["value_map"] = this.columnsJson,
      inputData["type"] = this.type
      if(this.type=='EMAIL'){
        inputData['additional_attributes']= {
          to: this.emailArrTo?.map(address => address?.email),
          cc: this.emailArrCc?.map(address => address?.email),
          bcc: this.emailArrBcc?.map(address => address?.email),
          subject: this.subject,
        }
      }
      if(!this.personalTemplates){
          inputData['dashboard_id'] = this.dashId
      }
      if(this.templateMessage.length && this.createEnable){
        this.httpTransfer.createEmailTemplate(inputData).subscribe((res : any) =>{
          if(res.status==200){
            this.toaster.Success("Template created successfully")
         this.closeModal.emit();
          }
        },
          error=>{
            console.log("error while creating template") 
          }
        )
      }
      else{
          if(this.templateMessage.length && this.updateEnable){
              this.httpTransfer.updateEmailTemplate(this.editTemplateObj?._id,inputData).subscribe((res : any) =>{
                  if(res.status==200){this.closeModal.emit();}
                },
                  error=>{
                    console.log("error while updating template") 
                  }
              )
          }
       else{this.toaster.Errors("Enter Template Message")}
      }
      this.isCreatingEnable = false;
      
  }



setFilePathToArrOfCustomFileds(fileUrlPath,filename) {
    console.log(fileUrlPath);
    fileUrlPath.forEach(e=>{
      let path=e?.path?e.path:e?.file_path
       let obj ={}
      obj['file_name'] = e.file_name+"."+path?.split('.').pop(),
      obj['file_path'] = path
      if(e.media_type =='image')
      {
        obj['type'] = 'IMAGE'
      }
      else if(e.media_type =='video')
      {
        obj['type'] = 'VIDEO'
      } 
      else{
          obj['type'] = 'DOCUMENT'
      }
      this.attachment.push(obj)
    }) 
    this.createTemplate()

}


  deleteTemplate(id,index){
    this.httpTransfer.deleteMessageTemplate(id).subscribe(res=>{
      if(res.status == 200){
        this.allTemplates.splice(index,1)
        this.toaster.Success("Deleted Successfully");
      }
    })
  }

  checkEmptyEmail(type){
    if(type=='to'){
      this.validateToEmail=this.emailSetTo.trim().length? this.commonUtils.ValidateEmail(this.emailSetTo.trim()) : true;
    }
    else if(type=='cc'){
      this.validateCcEmail=this.emailSetCc.trim().length? this.commonUtils.ValidateEmail(this.emailSetCc.trim()) : true;
    }
    else if(type=='bcc'){
      this.validateBccEmail=this.emailSetBcc.trim().length? this.commonUtils.ValidateEmail(this.emailSetBcc.trim()) : true;
    }
  }

  onEmailsSetKeydown(type){
    if(type == 'to'){
      this.validateToEmail=this.emailSetTo?.length && this.commonUtils.ValidateEmail(this.emailSetTo.trim())
      if(this.validateToEmail){
        this.emailArrTo.push({ email: this.emailSetTo, name: '' });
        this.emailArrTo = this.getuniqueEmailsObjects(this.emailArrTo)
        this.emailSetTo=''
      }
      this.validateToEmail= this.emailSetTo && this.emailSetTo?.length!=0? false:true
    }
    else if(type == 'cc'){
      this.validateCcEmail=this.emailSetCc?.length && this.commonUtils.ValidateEmail(this.emailSetCc.trim())
      if(this.validateCcEmail){
        this.emailArrCc.push({ email: this.emailSetCc, name: '' });
        this.emailArrCc = this.getuniqueEmailsObjects(this.emailArrCc)
        this.emailSetCc = ''
      }
      this.validateCcEmail=this.emailSetCc && this.emailSetCc?.length!=0? false:true
    }
    else if(type == 'bcc'){
      this.validateBccEmail=this.emailSetBcc?.length && this.commonUtils.ValidateEmail(this.emailSetBcc.trim())
      if(this.validateBccEmail){
        this.emailArrBcc.push({ email: this.emailSetBcc, name: '' });
        this.emailArrBcc = this.getuniqueEmailsObjects(this.emailArrBcc)
        this.emailSetBcc = ''
      }
      this.validateBccEmail=this.emailSetBcc && this.emailSetBcc?.length!=0? false:true
    }
  }

  openMentionPanel(input,type){
    if(input?.length>0){
        this.isMentionPanelOpenTo=false;
        this.isMentionPanelOpenCc=false;
        let allAddedEmails = []
      if(type == 'to'){
        this.isMentionPanelOpenTo=true;
        allAddedEmails = this.emailArrTo?.map(email => email.address)
      }
      else if(type == 'cc'){
        this.isMentionPanelOpenCc=true;
        allAddedEmails = this.emailArrCc?.map(email => email.address)
      }
      else if(type == 'bcc'){
        this.isMentionPanelOpenBcc = true;
        allAddedEmails = this.emailArrBcc?.map(email => email.address)
      }
      let searchText=input?.toLowerCase();
      this.searchFororgUsersJson=this.orgUsersJson?.filter((data) => (data.name.toLowerCase().includes(searchText) || data.email.toLowerCase().includes(searchText)) && !allAddedEmails.includes(data.email.toLowerCase()));
      if(this.searchFororgUsersJson.length==0){
        this.isMentionPanelOpenTo=false;
        this.isMentionPanelOpenCc=false;
        this.isMentionPanelOpenBcc=false;
      }
    }
  }
  getuniqueEmailsObjects(emailObjectArray){
    const uniqueEmails = new Set();
    const uniqueArray = emailObjectArray.filter(obj => {
      if (!uniqueEmails.has(obj.email)) {
        uniqueEmails.add(obj.email);
        return true;
      }
      return false;
    });
    return uniqueArray
  }

  removeEmail(i,type){
    if(type == 'to'){
      this.emailArrTo.splice(i,1)
    }
    else if(type == 'cc'){
      this.emailArrCc.splice(i,1)
    }
    else if(type == 'bcc'){
      this.emailArrBcc.splice(i,1)
    }
  }

  colseMentionPanel(){{
    setTimeout(()=>{
      this.isMentionPanelOpenTo=false;
      this.isMentionPanelOpenCc=false;
      this.isMentionPanelOpenBcc=false;
    },200)
  }}
  selectedUser(user){ 
    if(this.isMentionPanelOpenTo){ 
      this.emailArrTo.push(user);
      this.emailArrTo = this.getuniqueEmailsObjects(this.emailArrTo)
      this.emailSetTo=''
    }else if(this.isMentionPanelOpenBcc){
      this.emailArrBcc.push(user);
      this.emailSetBcc=''
      this.emailArrBcc = this.getuniqueEmailsObjects(this.emailArrBcc)
    }else{
      this.emailArrCc.push(user);
      this.emailSetCc=''
      this.emailArrCc = this.getuniqueEmailsObjects(this.emailArrCc)
    }
    // this.isMentionPanelOpenTo ? (this.emailArrTo.push(user) , this.emailSetTo='') :  this.isMentionPanelOpenBcc ? (this.emailArrBcc.push(user), this.emailSetBcc='') : (this.emailArrCc.push(user), this.emailSetCc='')
    if(this.isMentionPanelOpenTo){
      this.emailArrTo=this.checkDublicateEntry(this.emailArrTo)
      this.validateToEmail=true
    }
    if(this.isMentionPanelOpenBcc){
      this.emailArrBcc=this.checkDublicateEntry(this.emailArrBcc)
      this.validateBccEmail=true
    }
    if(this.isMentionPanelOpenCc){
      this.emailArrCc=this.checkDublicateEntry(this.emailArrCc)
      this.validateCcEmail=true
    }
    console.log("variable1",this.emailArrTo)
    this.isMentionPanelOpenTo=false;
    this.isMentionPanelOpenBcc=false;
    this.isMentionPanelOpenCc=false;
  }
  checkDublicateEntry(arraList){
    const uniqueArrayOfObjects = arraList?.filter((user, index, self) => {
    const firstIndex = self.findIndex((u) => u.email === user.email);
    return index === firstIndex;
    });
    return uniqueArrayOfObjects
  }
  setToCcBccAndAttachmentsFromTemplate(template){
    [this.emailArrBcc, this.emailArrCc, this.emailArrTo] = [[], [], []] 
    let [data] = [template?.additional_attributes]
    data?.to?.forEach(element => {
      let user = this.orgUsersJson.filter(u=>u.email == element)
      this.emailArrTo.push({ email: element, name: user[0]?.name })
    }); 

    data?.cc?.forEach(element => {
      let user = this.orgUsersJson.filter(u=>u.email == element)
      this.emailArrCc.push({ email: element, name: user[0]?.name })
    }); 

    data?.bcc?.forEach(element => {
      let user = this.orgUsersJson.filter(u=>u.email == element)
      this.emailArrBcc.push({ email: element, name: user[0]?.name })
    }); 
    if(this.emailArrCc?.length) this.enableCC = true
    if(this.emailArrBcc?.length) this.enableBCC = true
  }

  ngOnDestroy() {
    //.removeEventListener('click', this.onClick);
  }

  convertHtmlToPdf(item){
    this.useTemplate.emit(item)
  }
  }