<div class="leadCollaboratedPopover" style="min-height: 100px;">
  <div *ngIf="!isReadOnly" >
    <app-user-selected [dashIdSelected]="dashId" [customCall]="customCall" (selectedUserList)="selectedUserList($event)" [removeFromSelected]="removeFromSelected" [userList]="collaboratorArr"></app-user-selected>
    </div>
    <div class="select" *ngIf="collaboratorArr.length">
      <div class="row g-0 mx-0" *ngFor="let item of collaboratorArr">
        <div class="column1">
          <ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="30" initialsSize="2" ></ngx-avatars>
              </div>
        <div class="column2">{{item?.name}}
          <span style="float:right"><img *ngIf="item?.isDashUser" src="../../../assets/1x/boards-folders.svg"></span>
          <span style="float:right"><img *ngIf="!item?.isDashUser && !item?.is_external_user" src="../../../assets/1x/user.svg"></span> 
        </div>
       <div class="column3" *ngIf="!isReadOnly && leadObj?.assigned_to != item._id">
         <img src="../../../assets/1x/cross-icon.svg" role="button" (click)="removeFromSelectedList(item)">
       </div>
      </div>
    </div>   
</div>