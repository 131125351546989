import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, Renderer2, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { CommonUtils } from "app/services/CommonUtils.service";
import { func } from "lib0";

@Component({
    selector: 'app-function-editor',
    templateUrl: './function-editor.component.html',
    styleUrls: ['./function-editor.component.scss']
  })
  export class FunctionEditorComponent implements OnInit {
    @ViewChild('popParameter') public popParameter: NgbPopover;
    @ViewChild('popParameterParam') public popParameterParam: NgbPopover;
    @ViewChild('popColumns') public popColumns: NgbPopover;
    @ViewChild('popFunction') public popFunction: NgbPopover;
    @ViewChild('popParameterInfo') public popParameterInfo: NgbPopover;
    @ViewChild('popParameterArgs') public popParameterArgs: NgbPopover;
    @ViewChild('myTemplate', { static: true }) myTemplate: TemplateRef<any>;
    @ViewChild('functionEditableDiv', { read: ViewContainerRef }) functionEditableDiv: ViewContainerRef;
    @ViewChild('dynamicFunctionEditor', { read: ViewContainerRef }) dynamicFunctionEditor: ViewContainerRef;
    @Output() selectedFunctionsEmit  : EventEmitter<any> = new EventEmitter();
    @Output() closeEmit  : EventEmitter<any> = new EventEmitter();
    @Input() dashboardVariable:boolean=false;
    @Input() dashVariableArr:any;
    @Input() item:any={}
    @Input() customFieldArr:any;
    @Input() isTriggeredNonItemField:boolean=false;
    @Input() dashId:any;
    @Input() showIndex:boolean=true;
    @Input() filterCall:boolean=false;
    @Input() customfieldType:any;
    @Input() catchHookReqJson : any;
    @Input() triggerType : any;
    @Input() columnsJson:any={};
    @Input() isFunctionEnable:boolean=false;
    @Input() selectedFunction: any;
    @Input() isValid:boolean=false;
    paramIndex:number=-1;
    dummyfunctionArray:any[]=[]
    dateUnitArr:any[]=[
      {display_key:"Seconds",key:"seconds"},
      {display_key:"Minutes",key:"minutes"},
      {display_key:"Hours",key:"hours"},
      {display_key:"Days",key:"days"},
      {display_key:"Months",key:"months"},
      {display_key:"Years",key:"years"},
    ]
    booleanArr:any[]=[{display_key:"True",key:true},{display_key:"False",key:false}]
    showEditor: boolean = false;
    childFunction: any;
    constructor(public commonutils : CommonUtils,private renderer: Renderer2){}

    ngOnInit(): void {
      console.log(this.selectedFunction)
      this.dummyfunctionArray.push(this.selectedFunction)
    }
    
    ngAfterViewInit(){
        this.loadDynamicTemplate()
    }


    loadDynamicTemplate() {
      let functionData = this.selectedFunction;
        this.dynamicFunctionEditor?.clear();
        const view = this.dynamicFunctionEditor.createEmbeddedView(this.myTemplate,{ $implicit:functionData});
      }
     

      showInputforParams(functionData,paramIndex){
        console.log(functionData)
        console.log(paramIndex)
        const copiedParameters = JSON.parse(JSON.stringify(functionData.parameters));
        // Reset for column if necessary
        this.resetForColumn(copiedParameters[paramIndex]);
        // Close the popover
        this.popParameter?.close();
        // Toggle showInput for each parameter
        copiedParameters.forEach((m, index) => {
          m.showInput = (paramIndex === index) ? true : false;
          // Reset properties
        /*   delete m.isColumn;
          delete m.selectedColumnObj; */
        });
        // Update the original functionData with the modified parameters
        functionData.parameters = copiedParameters;
        console.log(functionData);
      }  

      resetForColumn(item){
        if(item?.showInput || item?.isFunction || item?.isColumn || item?.selectedColumnObj || item?.funcObj){
          delete item?.showInput;
          delete item?.isFunction;
          delete item?.isColumn;
          delete item?.value;
          delete item?.selectedColumnObj;
          delete item?.funcObj
        }
        console.log(item)
      }

      insertColumns(event,item){
        console.log(event)
        console.log(item)
        this.popParameter?.close()
        this.popParameterParam?.close()
        this.popParameterInfo?.close()
        this.popParameterArgs?.close()
        let algorithm =  event?.algorithm == 'FETCH_FIRST' ? '(First value)' : event?.algorithm == 'FETCH_LAST' ? '(Lastvalue)' : event?.algorithm == 'FETCH_ALL' ? '(All value)' : ''
        event.label =event?.key?.includes('{{variable.') ? ` ${event.value} ( Automation Variable)` : event?.key?.includes('triggeredItem') ? `Triggered Item ${event.value} ${algorithm}` : event?.key?.includes('targetItem') ? `Filtered Item ${event.value} ${algorithm}` : `{{${event.value}}}`
        if(item?.type!='array' && item?.sub_type!='array'){
        this.resetForColumn(item)
        item.isColumn = true;
        item.selectedColumnObj = event
        item.value = event?.label 
        }  
        else{
          item.params = item?.params || []
          if(this.paramIndex>=0){
            this.resetForColumn(item.params[this.paramIndex])
            item.params[this.paramIndex].isColumn = true;
            item.params[this.paramIndex].selectedColumnObj = event
            item.params[this.paramIndex].value = event?.label  
            this.paramIndex = -1  
          }
          else{
            item.params.push({isColumn:true,selectedColumnObj:event,value:`${event?.label}`})
          }
        }      
      }

      getFunctionsEmit(func,item){
        this.popParameter?.close()
        this.popParameterParam?.close()
        this.popParameterInfo?.close()
        this.popParameterArgs?.close()
        if(item?.type!='array' && item?.sub_type!='array'){
        this.resetForColumn(item)
        item.funcObj= item.funcObj || []
        item.isFunction = true;
        item.funcObj.push(func)
        }
        else{
          item.params = item?.params || []
          if(this.paramIndex>=0){
            this.resetForColumn(item.params[this.paramIndex])
            item.params[this.paramIndex].isFunction = true;
            item.params[this.paramIndex].funcObj = [func]
            this.paramIndex = -1  
          }
          else{
            item.params.push({isFunction:true,funcObj:[func]})
          }
        }  
      }

     
      extractParameters(parameters) {
  const parametersTextArray = parameters.map(param => {
      if ((param?.type == "array" || param?.sub_type == "array") && param?.params?.length) {
          // Recursively process array parameters
          return this.extractParameters(param?.params);
      } else {
          if (param.funcObj?.length) {
              // If funcObj exists, recursively process it
              const funcObjResults = param.funcObj.map(params => {
                  return `${params.name}(${this.extractParameters(params.parameters)})`;
              });
              return funcObjResults.join(', ');
          } else {
              // If funcObj doesn't exist, use the parameter's value
              if (param.value?.date) {
                  let date = param.value?.is_time_added ? param.value?.date + " " + this.commonutils.getAgingTimeForHeatMap(param.value?.time)["time"] : param.value?.date;
                  return date;
              } else {
                  return param.value || '';
              }
          }
      }
  });
  return parametersTextArray.join(', ');
      }

      addParameForRestType(item,functionData){
        console.log(item)
        const copiedParam = JSON.parse(JSON.stringify(item));
        delete copiedParam?.value
        functionData?.parameters.push(copiedParam)
      }
      removeParameForRestType(functionData,i){
        console.log(functionData)
        console.log(i)
        functionData?.parameters.splice(i,1)
      }

      generateFunctionText(name, parameters) {
        console.log(parameters)
        console.log(this.extractParameters(parameters))
        const parametersText = this.extractParameters(parameters);
        const functionText = `${name}(${parametersText})`;
        return functionText;
      }

      showInputforArrayTypeParams(functionData,index,subType){
        functionData.parameters[index]['params']= functionData.parameters[index]['params'] || []
        if(this.paramIndex>=0){
          console.log(functionData.parameters[index]['params'][this.paramIndex])
          functionData.parameters[index]['params'][this.paramIndex].showInput = true;
          if(functionData.parameters[index]['params'][this.paramIndex]?.isColumn ||
          functionData.parameters[index]['params'][this.paramIndex]?.selectedColumnObj ||
          functionData.parameters[index]['params'][this.paramIndex].sub_type !== subType){
            delete functionData.parameters[index]['params'][this.paramIndex]?.isColumn
            delete functionData.parameters[index]['params'][this.paramIndex]?.selectedColumnObj
            delete functionData.parameters[index]['params'][this.paramIndex]?.value  
            functionData.parameters[index]['params'][this.paramIndex].sub_type = subType;  
          }
        }
        else{
          functionData.parameters[index]['params'].push({showInput:true,sub_type : subType})
        }
        this.popParameterParam?.close()
        console.log(functionData)
      }

      deleteInputforArrayTypeParams(functionData,index){
        functionData.parameters[index]['params'].splice(this.paramIndex,1)
        this.paramIndex = -1
      }

      removefunction(info){
        console.log(info)
        if(info?.index>=0 && info?.arr?.length){
          info?.arr.splice(info.index,1)
          console.log(this.selectedFunction)
        }
        else{
          this.selectedFunction = undefined
          this.saveFunc()
        }
     
      }
      

    saveFunc() {
      if(this.selectedFunction){
        const result = this.generateFunctionText(this.selectedFunction?.name, this.selectedFunction?.parameters);
        console.log(result);
         let obj={
             'selectedFunction' : this.selectedFunction,
             'functionLabel' : result
         }
          this.selectedFunctionsEmit.emit(obj) 
          this.closeEmit.emit()
      }
      else{
        this.closeEmit.emit()
      }
    }  


    checkForValidation(parameter){
      console.log(parameter)
      for (const param of parameter || []) {
        console.log(param)
        if(param?.funcObj?.length){
        for (const innerParam of param?.funcObj || []) {
          this.isValid = this.checkForValidation(innerParam?.parameters);
          if (!this.isValid) {
              return false; // Terminate loop if inner call returns false
          }
        }
        }
        else if(param?.params?.length){
          this.isValid = this.checkForValidation(param?.params);
          if (!this.isValid) {
              return false; // Terminate loop if inner call returns false
          }
        }
        else if((param?.isColumn && param?.selectedColumnObj) || (param?.value || param?.value === false || param?.value >=0)){
          console.log('1')
          this.isValid = true; 
        }
        else{
          console.log('2')  
          this.isValid = false;
          break;
        }
      }  
      return this.isValid;
      
    }

    onMouseEnter(event: MouseEvent) {
      const target = event.target as HTMLElement;
      const uniqueId = target.getAttribute('data-id');
      const elements = document.querySelectorAll(`[data-id="${uniqueId}"]`);
      elements.forEach(element => {
        element.classList.add('funcHovered');
      });
    }
  
    onMouseLeave(event: MouseEvent) {
      const target = event.target as HTMLElement;
      const uniqueId = target.getAttribute('data-id');
      const elements = document.querySelectorAll(`[data-id="${uniqueId}"]`);
      elements.forEach(element => {
        element.classList.remove('funcHovered');
      });
    }
   
  }