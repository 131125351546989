import { ChangeDetectionStrategy, ChangeDetectorRef, Compiler, Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChange, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConstantService } from '../../../../../Enums/Constant.service';
import { CommonUtils } from '../../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../../services/httpTransfer.service';
import { MqttmessagingService } from '../../../../services/mqttmessaging.service';
import { AttributesIcons } from 'Enums/attributes-icons';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'Enums/icons';

@Component({
  selector: 'app-communicate',
  changeDetection:ChangeDetectionStrategy.Default,
  templateUrl: './communicate.component.html',
  styleUrls: ['./communicate.component.scss']
})
export class CommunicateComponent implements OnInit {
  [x: string]: any;
  currentActiveTab = 'commentTab';
  @Input() dashId: any;
  @Input() leadInfo: any;
  @Input() header: Boolean;
  @Input() users: any[];
  @Input() bulkSelectionFlag: boolean;
  @Input() isPublicLead:boolean=false;
  @Input() emailExpandedViewAllowed:boolean = true;
  arrOfCustomField: any;
  @Input() contactTypeBoardData:any;
  @Output() emitSelectedEmail = new EventEmitter<any>();
  @Output() emitSelectedChat = new EventEmitter<any>();
  @Output() emitIsThreadView = new EventEmitter<any>();
  @ViewChild('addChatUserPopup') public addChatUserPopup: NgbPopover;
  @Input() chatCommentArr:any[]=[]; 
  @Input() chatMesssageGroupArr:any[]=[]
  @ViewChild("viewComponent", { read: ViewContainerRef })
  viewComponent!: ViewContainerRef;
  sprintArr:any;
  contactBoardId: any[]=[];
  contactTypeLeadArr: any[]=[];
  dashboardJsonSub: any;
  whatsappCall:boolean=false;
  mqttSubscription: Subscription;
  whatsAppEnabled: boolean = true
  emailEnabled: boolean = true
  dashboardInfo:any = {}
  isEmailExpanded: boolean = false
  selectedEmail: any = null;
  attributesIcons = AttributesIcons;
  chatUsersArr: any[]=[];
  dashboardChannels: any[]=[];
  showBar: boolean = false;
  @Input() selectedChannelObj:any;
  selectedChatUserMsgarr: any;
  enableChat: boolean = false;
  connectedChannelForAddChatUser: any [] = [];
  enableAddUser: boolean = false;
  selectedChatUser:any;
  showLoader: boolean;
  orgUsersJson: {};
  chatMsgcurrentPage: any = 1;
  pageSizeForChatMsg: number = 10;
  chatMsgTotal_Page: number;
  @Input() isChatExpand: boolean = false;
  disableEditor : boolean = false;
  restoreScrollPosition: any;
  chatConfig:any={
    INTERNAL:{
      name:'Comment',
      color:'#34A290'
    }
  }
  activityTypes:any=[{name:"All",key:'ALL'},{name:"Comment",key:'INTERNAL'}]
  selectedActivity:any="ALL"

  boardInfo:any;
  boardIconJson:any=Icons
  activityTypeBoardList:any=[]
  selectedActivityBoard:any
  currentCreateActivityData:any={}
  loaderConfig:any={}
  messageGroupId:any[]=[]
  constructor(private dashboardUtils : DashboardUtilsService,
    private httpTransfer : HttpTransferService,
    private MqttmessagingService : MqttmessagingService,
    private constantService : ConstantService,
    private commonUtils : CommonUtils,
    private cdr: ChangeDetectorRef,
    private compiler: Compiler, private injector: Injector) { 
      this.listenForMqttMessages();
    }

    activityTypeKey = this.constantService.getActivityTypeKey();
    updateActivityTYpe = this.constantService.getUpdateActivityType();
    typeKey = this.constantService.getObjectTypeKey();
    leadObjectTYpe = this.constantService.getLeadObjectType();

    listenForMqttMessages() {
      this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
        if (message) {
            this.updatesFromWss(message)
        }
        this.cdr?.detectChanges()
      });
    }
  
    updatesFromWss(messagees) {
      let message = JSON.parse(messagees);
      console.log(message)
      if (message[this.activityTypeKey] == this.updateActivityTYpe && message['additional_attributes']['details']['_id'] == this.leadInfo['_id']) {
        if (message[this.typeKey] == this.leadObjectTYpe) {
          this.handleLeadUpdateTypeOperation(message['additional_attributes']['details']);
        }
      }
      if (message[ConstantService.constant.OBJECTTYPEKEY] == "COMMENT" && this.selectedChannelObj?.message_group_id.includes(message?.additional_attributes?.details?.message_group_id) && message?.additional_attributes?.details?.chat_user_id == this.selectedChannelObj?.chat_user_id && message?.additional_attributes?.details?.channel_id == this.selectedChannelObj?.channel_id){
          this.handleUpdateForSendMsg(message)
      }
    }

    async handleUpdateForSendMsg(message){
      let obj = await this.commonUtils.chatLive(message,this.dashId,this.chatUsersArr,this.selectedChatUserMsgarr,this.selectedChannelObj,null,this.disableEditor)
      this.disableEditor = obj?.disableEditor
      this.selectedChatUserMsgarr = obj?.selectedChatUserMsgarr
     }
  
    handleLeadUpdateTypeOperation(message) {
      if(!this.isEmailExpanded){
        this.leadInfo = this.commonUtils.creatingJsonStructureOfLeadForTableView(message);
      }
    }


    
 async ngOnInit(){
  console.log(this.chatCommentArr)
  console.log(this.selectedChannelObj)
  if(this.isChatExpand){
    this.currentActiveTab = 'chatTab'
    this.enableChat = true
    this.viewComponent?.clear();
    const { ChatViewModule } = await import("app/chat-view/chat-view.module");
    const moduleFactory = await this.compiler.compileModuleAsync(ChatViewModule);
    const moduleRef = moduleFactory.create(this.injector);
    const componentFactory = moduleRef.instance.getComponent();
    const { instance } = this.viewComponent.createComponent(componentFactory);  
    instance.selectedDashId = this.dashId; 
    instance.isChatExpand = this.dashId; 
    instance.leadInfo = this.leadInfo
    this.cdr?.detectChanges()
  }
  this.orgUsersJson = await this.dashboardUtils.getOrgUsers(); 
  let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
  let dashboardJsonChannel = await this.dashboardUtils.getDashboardChannels([this.dashId])
  this.arrOfCustomField = JSON.parse(JSON.stringify(dashboardJson[this.dashId]['CUSTOM_FORM'] || {}));
  this.dashboardJsonSub = this.dashboardUtils.getUpdatedDashboardJson().subscribe((res : any) => {
   this.sprintArr = res[this.dashId]?.SPRINT || [];
   this.dashboardInfo = res[this.dashId]?.DASHBOARD_INFO || {};
   //this.whatsAppEnabled = this.dashboardInfo?.whatsapp_enabled || false
   this.emailEnabled = this.dashboardInfo?.email_enabled || false
   let dashJson = JSON.parse(JSON.stringify(dashboardJsonChannel))
   this.dashboardChannels =JSON.stringify(dashJson[this.dashId]['CHANNELS'])? JSON.parse(JSON.stringify(dashJson[this.dashId]['CHANNELS'])) : {};  
  });
  this.contactTypeBoardData.forEach(e=>{
    this.contactBoardId.push(e.dashboardInfo.dashboard_id)
  })
  if(this.chatCommentArr?.length){
    console.log(this.chatCommentArr)
    this.messageGroupId = this.chatCommentArr.map(event => event._id);
    this.getChatUser()
    this.getselectedChatMsg(this.selectedChannelObj)
  }
  else{
    this.showBar = true;
  }
  this.getCommentsForLead()
  this.getActivityBorad()
  this.cdr?.detectChanges()
  }


  async ngOnChanges(changes:SimpleChange) { 
   /*  if(changes['selectedChannelObj'] && this.this.selectedChannelObj) {
      this.getselectedChatMsg(this.selectedChannelObj)
    } */
  }

  

 

  getChatUser(){
    let channelIdArray = this.dashboardChannels.map(e=>e?._id)
    this.httpTransfer.getChatUserQuery({'dashboard_id' : [this.dashId],'channel_id':channelIdArray}).subscribe(res=>{
    this.chatUsersArr= res.result?.chatUsers || []
    this.chatCommentArr.forEach(e=>{
      let channel = this.dashboardChannels.filter(i=>i._id == e?.channel_id)
      let chatUser = this.chatUsersArr.filter(m=>m._id==e?.chat_user_id)
      e['channelType'] = channel[0]?.type
      e['channelName'] = channel[0]?.name
      e['chatUserName'] = chatUser[0]?.name
      e['chatUserProfile'] = chatUser[0]?.profile_pic
    })
    this.getConnectedChannel()
    })
   }




  switchTab(currentTab,item?){
    this.selectedChannelObj = item ? item : {}
    this.currentActiveTab = ""
    setTimeout(()=>{
      this.currentActiveTab = currentTab
    },100)
    if(currentTab=='commentTab'){
      this.selectedChatUserMsgarr=[]
    }
  }
  
  reloadWhatsapp(){
    this.whatsappCall = false
    setTimeout(()=>{      
    this.whatsappCall = true
    },10)
  }

  expandedEmailView(){
    if(this.currentActiveTab == 'chatTab' || this.currentActiveTab == 'emailTab'){
      this.isEmailExpanded = this.currentActiveTab == 'emailTab' ? !this.isEmailExpanded : this.isEmailExpanded
      this.isChatExpand = !this.isChatExpand
      this.emitSelectedChat.emit(this.isChatExpand)
      if(this.isChatExpand){$('.itemRightSectionHeader').hide()}
      else{$('.itemRightSectionHeader').show()}
    }
  }


  selectedEmailEmitter(event){
    this.selectedEmail = event
    if(this.isEmailExpanded)
      this.emitSelectedEmail.emit(this.selectedEmail)
  }
 
  getselectedChatMsg(item?,scrollRestore?){
      var inputJson = {
    /*   'dashboard_id': [this.dashId],
      'chat_user_id': [this.selectedChannelObj?.chat_user_id],
       'channel_id': [this.selectedChannelObj?.channel_id],
      'lead_id': this.selectedChannelObj?.lead_id, */
      "message_group_id": Array.isArray(this.selectedChannelObj.message_group_id) ? this.selectedChannelObj.message_group_id : [this.selectedChannelObj.message_group_id],
      "pagination_details" : {
        "page_number" : this.chatMsgcurrentPage,
        "page_size" : this.pageSizeForChatMsg
       }
    }  
    this.httpTransfer
    .getCommentCorrespondingLead(inputJson)
    .subscribe((res : any) => {
      if(res.status==200){
        this.selectedChatUserMsgarr = this.selectedChatUserMsgarr?.length ? [...res.result?.commentsInfo.reverse(),...this.selectedChatUserMsgarr] : res.result?.commentsInfo.reverse();
        console.log(this.selectedChatUserMsgarr)
        this.chatMsgTotal_Page = res?.result?.pagination_details?.total_pages;
        this.selectedChatUserMsgarr.forEach(m=>{
          let chatUser = this.chatUsersArr.filter(x=>x._id == m?.chat_user_id)
          m['chatUserName'] = chatUser[0]?.name
          m['profile_pic'] = chatUser[0]?.profile_pic
          if(m.reply_comment_id){
            let reply = this.selectedChatUserMsgarr.filter(n=>n._id == m.reply_comment_id)
            console.log(reply)
            m['replyMsgObj'] = reply?.length ? reply[0] : {}
            m['rplyUserName'] = reply?.length ? reply[0] : {}
            let chatRplyUser = this.chatUsersArr.filter(m=>m._id== reply[0]?.chat_user_id)
            m['rplyUserName'] = chatRplyUser[0]?.name
            m['rplyUserProfilePic'] = chatRplyUser[0]?.profile_pic
          }
          m?.status_details?.received_time ? 
          m['receivedTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.received_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.received_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.received_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.received_time)['time'] 
          : m['sentTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.sent_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.sent_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.sent_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.sent_time)['time'] 
          if(m?.status_details?.delivered_time) {
            m['deliverTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.delivered_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.delivered_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.delivered_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.delivered_time)['time'] 
          }
          else  if(m?.status_details?.read_time) {
            m['readTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.read_time))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.status_details?.read_time))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.read_time)['time'] : this.commonUtils.getAgingTimeForHeatMap(m?.status_details?.read_time)['time'] 
          }
        })
        this.switchTab('chatTab',item)
        this.enableChat = true;
        if(scrollRestore){
          this.restoreScrollPosition = scrollRestore
         } 
        console.log(this.selectedChatUserMsgarr)
      }
    })
  }
 
  getChatMsgOnScroll(scrollRestore){
    console.log("scrolup...",this.chatMsgcurrentPage,this.chatMsgTotal_Page)
   this.chatMsgcurrentPage = this.chatMsgcurrentPage + 1;
    if (this.chatMsgcurrentPage <= this.chatMsgTotal_Page) {
      this.getselectedChatMsg(this.selectedChannelObj,scrollRestore);
    }
  }

  async getConnectedChannel(ConnectedLeadsId?) {
    try {
      this.connectedChannelForAddChatUser = [];
      this.enableAddUser = false;
      const connectedBaordList = this.arrOfCustomField.filter(e => e?.type == 'Board' && this.contactBoardId.includes(e?.connected_board_id));
      if(connectedBaordList?.length){

        for (const item of connectedBaordList) {
          const res = await this.httpTransfer.getLeadQuery({
            "dashboard_id": [item?.connected_board_id],
            "lead_id": ConnectedLeadsId ? ConnectedLeadsId : this.leadInfo.custom_fields[item.key]
          }).toPromise();
    
          const leadResponse = res.result.leadResponse;
          if(leadResponse.length){

            for (const m of leadResponse) {
              const dashboardJson = await this.dashboardUtils.getDashboardCustomForms(m?.dashboard_id);
              const connectedCustomFields = JSON.parse(JSON.stringify(dashboardJson[m?.dashboard_id]['CUSTOM_FORM']));
              Object.keys(m.custom_fields).forEach(key => {
                if (m.custom_fields[key]) {
                  let mobileNumber;
                  for (const keys in m.custom_fields) {
                    if (keys.startsWith("contact_phone_")) {
                      mobileNumber = m.custom_fields[keys].full_mobile_number?.length>=8 ? m.custom_fields[keys].full_mobile_number : null;
                      mobileNumber = mobileNumber.replace('+','')
                    }
                  }
                  console.log(mobileNumber)
                  console.log(this.chatUsersArr)
                  console.log(m.custom_fields[key])
                  const chatUser = (mobileNumber && mobileNumber!==null) ? this.chatUsersArr.filter(q => q.platform_id === mobileNumber || q.platform_id === '+'+mobileNumber) : 
                  this.chatUsersArr.filter(q => q.platform_id === m.custom_fields[key]);
                  // const channelType = connectedCustomFields.filter(l => l.key == key);
                  // const filterChannel = this.dashboardChannels.filter(p => p.type == channelType[0]?.channel_type);
                  const channelType = this.getFixedChannelKey(key);
                  const filterChannel = this.dashboardChannels.filter(p => p.type == channelType);
                  console.log(filterChannel,this.dashboardChannels,channelType)
                  const index = this.chatCommentArr.findIndex(w => w.chat_user_id == chatUser[0]?._id);
                  if(chatUser?.length){
                    for (const o of filterChannel) {
                      if (index <= 0) {
                        this.enableAddUser = true;
                        this.connectedChannelForAddChatUser.push({
                          'channelType': channelType,
                          'itemTitle': m?.title,
                          'channelName': o?.name,
                          'channelId': o?._id,
                          'chatUserId': chatUser[0]?._id,
                          'platform_id': mobileNumber ? mobileNumber : m.custom_fields[key]
                        });
                      }
                      mobileNumber = null;
                    }
                  }
                }
              });
              console.log(this.connectedChannelForAddChatUser)
              console.log(this.chatCommentArr)
              this.connectedChannelForAddChatUser = this.connectedChannelForAddChatUser.filter(channel =>  !this.chatCommentArr.some(comment => (channel?.channelId === comment?.channel_id && channel?.chatUserId === comment?.chat_user_id) ));
              this.enableAddUser = this.connectedChannelForAddChatUser?.length ? true : false 
              setTimeout(()=>{
                this.showBar = true;
                this.enableChat = true;
              },500)
              console.log(this.connectedChannelForAddChatUser);
            }
          }
          else{
            this.showBar=true;
            this.enableChat = true;
          }
        }
      }
      else{
        this.showBar=true;
        this.enableChat = true;
      }
  
      // Filter the connected channels as needed here
    } catch (error) {
      console.error(error);
    }
  }



getFixedChannelKey(key){
  switch (key){
      case 'contact_user_3f83k29fj1':
          return "TELEGRAM";
      case 'contact_user_3o3oioiiw3':
          return "VIBER";
      case 'contact_user_399fk00992':
          return "LINE";
      case 'contact_user_2342jf992':
          return "FACEBOOK";
      case 'contact_user_f929jf2902':
          return "INSTAGRAM";
      case 'contact_phone_f9eoa8dfuf':
          return "WHATSAPP";
      case 'contact_user_32jf0922':
          return "GOOGLE_BUSINESS_MESSAGES";
      case 'contact_name_qiybv1ozk6':
          return "CHAT_USER_NAME";
      default:
          return "";
  }
}
 

  createChat(item){
    console.log(item)
    this.addChatUserPopup.close()
    let commentObj={
        platform_user_id : item?.platform_id?.full_mobile_number ? item?.platform_id?.full_mobile_number : item?.platform_id
    }
     this.httpTransfer
    .createChatUser(commentObj, item?.channelId).subscribe((res : any) => {
     console.log(res)
     if(res.status==200){
      let obj={
        'chat_user_id' : res?.result?.chat_user_id,
         'channel_id' : item?.channelId,
         'lead_id' : this.leadInfo?._id,
         'dashboard_id' : this.dashId
      }
      this.showBar = false;
      this.chatCommentArr.push(obj)
      this.getChatUser()
     }
    }) 
  }

  ngOnDestroy() {
    this.mqttSubscription?.unsubscribe();
    this.dashboardJsonSub?.unsubscribe()
  }

  getCommentsForLead(scrollId?) {
    var inputJson = {
      dashboard_id: [this.dashId],
      lead_id: [this.leadInfo?._id],
    };
    this.httpTransfer.getCommentCorrespondingLead(inputJson).subscribe((res : any) => {
      console.log(res?.result)
      this.chatComment=res?.result?.commentsInfo || []
/*       if(res?.result?.commentsInfo.filter(c=>c?.type=="MESSAGE_GROUP")?.length){ 
 *//*         console.log(this.chatCommentArr)
        if(this.chatCommentArr?.length)this.selectedChannelObj =  this.chatCommentArr[0]
        this.getChatUser()
          this.httpTransfer
          .getCommentCorrespondingLead({'message_group_id':[this.selectedChannelObj?._id]})
          .subscribe((res : any) => {
            if(res.status==200){}
          })
      
 */    })
  }
// n

  async getActivityBorad(){
    let boardData=await this.dashboardUtils?.getAndSyncAllDashboardData([this.dashId])
    this.boardInfo=boardData[this.dashId]?.DASHBOARD_INFO;
  }

  getActivityTypeBoardData(popRef?){
    let boardIds=this.boardInfo?.connected_activity_types?.map(board=>board?.board_id) || []
    this.loaderConfig.addNewPopoverloader=true
    if(boardIds?.length>0){
      this.dashboardUtils?.getAndSyncAllDashboardData(boardIds,false,['ActivityBoardInfo'],[this.boardInfo?.work_folder_id])?.then((res:any)=>{
        this.activityTypeBoardList=Object.keys(res || {})?.reduce((data,boardId)=>{if(boardIds?.includes(boardId) && res[boardId]?.DASHBOARD_INFO){data?.push(res[boardId]?.DASHBOARD_INFO)};return data},[])
        popRef?.open()
        delete this.loaderConfig.addNewPopoverloader
        this.cdr?.detectChanges()
      })
    }else{
      popRef?.open()
      delete this.loaderConfig.addNewPopoverloader
      this.cdr?.detectChanges()
    }
  }

  createActivity(){
    delete this.currentCreateActivityData['isValidated']
    let json={
      "associate_lead_id":[this.leadInfo?._id],
      "type":"ACTIVITY",
      "activity_lead_obj":this.currentCreateActivityData,
      "activity_type_board_id":this.selectedActivityBoard?.dashboard_id
    }
    this.loaderConfig.createCommentloader=true
    this.httpTransfer.postCommentCorrespondingLead(json, this.dashId, this.leadInfo?._id).subscribe((res : any) => {
     if(res.status==200){
       delete this.loaderConfig.createCommentloader
     }
    },eror=>{
      delete this.loaderConfig.createCommentloader
    })
  }

  updateActivity(json,boardId,itemId,commentId){
    this.httpTransfer.updateCommentCorrespondingLead({activity_lead_obj:json},boardId,itemId,commentId).subscribe((res : any) => {

     }) 
  }
  

  
}
