import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AutomationUtilsService } from '../../../services/automation-utils.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-automation-columns',
  templateUrl: './automation-columns.component.html',
  styleUrls: ['./automation-columns.component.scss']
})
export class AutomationColumnsComponent implements OnInit {
  @ViewChild('popFunction') public popFunction: NgbPopover;
  @ViewChild('functionDiv') editableDiv: ElementRef;
  @Output() selectedColumn: EventEmitter<any> = new EventEmitter();
  @Output() selectedFunctionsEmit: EventEmitter<any> = new EventEmitter();
  @Output() closeEmit: EventEmitter<any> = new EventEmitter();
  @Input() dashboardVariable: boolean = false;
  @Input() dashVariableArr: any;
  @Input() isHeader: boolean = true;
  @Input() item: any = {}
  @Input() customFieldArr: any;
  @Input() columnsFor: any;
  @Input() isTriggeredNonItemField: boolean = false;
  @Input() dashId: any;
  @Input() showIndex: boolean = true;
  @Input() filterCall: boolean = false;
  @Input() customfieldType: any;
  @Input() catchHookReqJson: any;
  @Input() triggerType: any;
  @Input() columnsJson: any = {};
  @Input() isFunctionEnable: boolean = false;
  @Input() selectedFunction: any;
  @Input() appTemplateCall: boolean = false;
  @Input() templateVariableArr: any[] = [];
  @Input() assignVariableCall: boolean = false;

  valueTemplateVarArr = []
  indexEnable: boolean = false;
  startIndex: any = 1;
  endIndex: any = 1;
  numbers = '0123456789'
  alphabets = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  randomEnable: boolean = false;
  characterSet: any = '';
  characterlength: any = 1;
  randomNumberAllow: boolean = false;
  randomAlphabetAllow: boolean = false;
  mirrorEnable: boolean = false;
  selectedMirrorObject: any;
  showAll: boolean = false;
  columnsobj: any = {};
  algorithm: String;
  mirrorCustomFields: any[] = [];
  mirrorCustomFieldsForTarget: any[] = [];
  selectedCatchhookDisplayKey: any = {};
  catchHookjsonMap: { display_key: string; value: any; type: any; key: any, mappingKey: any, name: any, _id: any }[] = [];
  @Input() isValid: boolean = false;

  predefinedFieldsArr: any[] = [
    {display_key: 'Bucket', key: 'bucket_id.name'},
    {display_key: 'Priority', key: 'priority'},
    {display_key: 'Phase', key: 'sprint_id.name'},
    {display_key: 'Assignee', key: 'assigned_to'},
    {display_key: 'Created By', key: 'created_by'},
    {display_key: 'Updated By', key: 'updated_by'},
    {display_key: 'Assigned By', key: 'assigned_by_user'},
    {display_key: 'Summary', key: 'title'},
    {display_key: 'Start Date', key: 'start_date'},
    {display_key: 'End Date', key: 'end_date'},
    {display_key: 'Created Time', key: 'create_date'},
    {display_key: 'Updated Time', key: 'update_date'},
    {display_key: 'Sequence ID', key: 'seq_id'},
    {display_key: 'Board ID', key: 'board_id'},
  ];

  constructor(public automationUtils: AutomationUtilsService) { }

  ngOnInit(): void {
    console.log(this.catchHookReqJson)
    if (this.catchHookReqJson) {
      this.catchHookjsonMap = this.automationUtils.getCatchHookReqJson(this.catchHookReqJson?.request_body, this.catchHookjsonMap)
    }
    if (this.filterCall && this.customfieldType != 'Text' && this.customfieldType != 'Textarea' && !this.catchHookReqJson && this.customFieldArr?.length) {
      if (this.customfieldType == 'MultiUrl') {
        console.log(this.customFieldArr.filter(e => e.mirror_column_type == 'Url' || e.mirror_column_type == 'MultiUrl'))
        this.mirrorCustomFields = this.customFieldArr.filter(e => e.mirror_column_type == 'Url' || e.mirror_column_type == 'MultiUrl')
        this.customFieldArr = this.customFieldArr.filter(e => e.type == 'Url' || e.type == 'MultiUrl')
      }
      else {
        if (this.customfieldType == 'Email') { this.columnsFor = 'to' }
        this.mirrorCustomFields = this.customFieldArr.filter(e => e.mirror_column_type == this.customfieldType)
        this.customFieldArr = this.customFieldArr.filter(e => e.type == this.customfieldType || e.mirror_column_type == this.customfieldType)
      }
    }
    else {
      this.mirrorCustomFields = this.customFieldArr?.filter(l => l.type == 'Mirror')
    }
    if (this.item.customFieldForTargetBoard?.length) {
      this.mirrorCustomFieldsForTarget = this.item.customFieldForTargetBoard.filter(l => l.type == 'Mirror')
    }
    console.log(this.templateVariableArr)

    if (this.templateVariableArr?.length > 0) {
      this.getTemplateUserValue()
    }
    console.log(this.dashVariableArr)
    if (this.dashVariableArr?.length) {
      console.log(this.dashVariableArr)
      this.dashVariableArr = this.dashVariableArr.map(item => ({
        ...item,
        key: !item.var_key.includes('dashboardVariable') ? `dashboardVariable.${item.var_key}` : item.var_key
      }));
    }
  }
  async getindexColumns() {
    console.log(this.item)
    let indexValue
    let data = { 'index_start_value': this.startIndex, 'index_increment_value': this.endIndex }
    indexValue = await this.automationUtils.getIndex(this.dashId, data)
    let obj = {
      'key': indexValue,
      'value': "index",
      'indexValue': {
        'startIndex': this.startIndex,
        'endIndex': this.endIndex
      }
    }
    this.selectedColumn.emit(obj)
  }

  getTemplateUserValue() {
    if ( this.templateVariableArr.length > 0) {
      let columnTypeArr = this.templateVariableArr?.filter(ele => ele.var_type == 'VALUE')
      this.valueTemplateVarArr = this.automationUtils.createCustomFieldArrFromtempateVariables(columnTypeArr,false)
      this.valueTemplateVarArr = this.valueTemplateVarArr.map(item => ({
        ...item,
        key: !item.key.includes('automationVariable') ? `automationVariable.${item.key}` : item.key
      }));
    }
  }

  selectedColumnValue(key, value, customKey?, type?) {
    let arr = []
    if (this.catchHookReqJson?.length) {
      arr = this.catchHookjsonMap.filter(m => m.display_key == value)
    }
    this.columnsobj = {}
    this.columnsobj = {
      'key': key,
      'value': value
    }
    if (customKey) { this.columnsobj['customKey'] = customKey }
    if (type == "Mirror") {
      this.mirrorEnable = true
      this.selectedMirrorObject = !customKey.includes("target") ? this.customFieldArr.filter(e => e.key == customKey) : this.item.customFieldForTargetBoard.filter(e => e.key == customKey)
      this.showAll = ['MultipleFile', 'MultipleImage', 'MultipleVideo', 'MultipleAudio', 'MultiUrl', 'MultiUser', 'Checkbox', 'MultiSelect', 'CheckList'].includes(this.selectedMirrorObject[0].mirror_column_type) ? true : false;
    }
    else if (type == "catchHook" && arr?.length && arr[0]?.type == "ARRAY") {
      let arr = this.catchHookjsonMap.filter(m => m.display_key == value)
      this.mirrorEnable = true
      this.selectedCatchhookDisplayKey = this.columnsobj
      this.columnsobj['type'] = type
    }
    else if (this.dashboardVariable && this.dashVariableArr?.length && ['MultipleFile', 'MultipleImage', 'MultipleVideo', 'MultipleAudio', 'MultiUrl', 'MultiUser', 'Checkbox', 'MultiSelect', 'CheckList', 'MultipleEmail', 'MultipleMobile'].includes(type)) {
      this.mirrorEnable = true
      this.selectedMirrorObject = this.dashVariableArr.filter(e => e.key == customKey)
      this.showAll = ['MultipleFile', 'MultipleImage', 'MultipleVideo', 'MultipleAudio', 'MultiUrl', 'MultiUser', 'Checkbox', 'MultiSelect', 'CheckList'].includes(this.selectedMirrorObject[0].mirror_column_type) ? true : false;
    }
    else {
      this.selectedColumn.emit(this.columnsobj)
    }
  }

  setValueForMirrorColumns() {
    this.columnsobj['algorithm'] = this.algorithm
    this.selectedColumn.emit(this.columnsobj)
  }

  resetCharacterSet() {
    this.randomNumberAllow = true
    this.randomAlphabetAllow = true
    this.characterSet = this.numbers + this.alphabets
    this.characterlength = 1
  }

  changeCharacterSet(event, key) {
    console.log(event, key)
    let subString = key == 'alphabet' ? this.alphabets : this.numbers
    if (event.target.checked) {
      if (!this.characterSet.includes(subString)) {
        this.characterSet = this.characterSet + subString
        console.log('added', this.characterSet)
      }
    }
    else {
      if (this.characterSet.includes(subString)) {
        this.characterSet.replace(subString, '')
        console.log('removed', this.characterSet)
      }
    }
  }


  includeCharacters(key) {
    let subString = key == 'alphabet' ? this.alphabets : this.numbers
    if (!this.characterSet.includes(subString)) {
      this.characterSet = this.characterSet + subString
      this.removeDuplicateCharacters()
    }
  }


  async getRandomColumns() {
    console.log(this.item)
    let key = ""
    let arr = Object.values(this.columnsJson)
    let filterArr = arr?.filter(ele => ele['key']?.includes('randomString.'))
    console.log(filterArr, Object.values(this.columnsJson))
    let length = filterArr.length || 0
    key = `randomString.${length}`

    let obj = {
      'key': key,
      'value': "random",
      'randomValue': {
        'character_set': this.characterSet,
        'length': this.characterlength
      }
    }
    this.selectedColumn.emit(obj)
  }

  removeDuplicateCharacters() {
    const uniqueCharacterSet = this.characterSet.split('').filter((value, index, self) => self.indexOf(value) === index).join('');
    this.characterSet = uniqueCharacterSet;
    this.sortCharacterSet()
  }


  sortCharacterSet() {
    const numbers = [];
    const smallLetters = [];
    const capitalLetters = [];
    const specialCharacters = [];

    for (const char of this.characterSet) {
      if (char.match(/[0-9]/)) {
        numbers.push(char);
      } else if (char.match(/[a-z]/)) {
        smallLetters.push(char);
      } else if (char.match(/[A-Z]/)) {
        capitalLetters.push(char);
      } else {
        specialCharacters.push(char);
      }
    }

    numbers.sort();
    smallLetters.sort();
    capitalLetters.sort();
    specialCharacters.sort();

    this.characterSet = numbers.join('') + smallLetters.join('') + capitalLetters.join('') + specialCharacters.join('');
  }

  getFunctionsEmit(selectedFunction) {
    this.selectedFunction = selectedFunction
    console.log(this.selectedFunction)
    this.popFunction.close()
  }
}
