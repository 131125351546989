<ng-container *ngIf="itemViewInfo?.viewType=='RIGHT'">
   <div class="side-item-view-inner">
      <ng-container *ngTemplateOutlet="itemView"></ng-container>
   </div>
</ng-container>
<ng-container *ngIf="itemViewInfo?.viewType=='MANUAL'">
      <ng-container *ngTemplateOutlet="itemView"></ng-container>
</ng-container>

<ng-template #itemView let-modal>
   <ng-container *ngIf="itemData?.length>0 && reloadItem">
      <item-full-popup
      style="height: 100%;"
      [hideClosebtn]="itemViewInfo?.viewType=='MANUAL'"
      [showMinimizedIcon]="showMinimizedIcon"
      [currentItemViewInfo]="itemViewInfo"
      [itemConfig]="{showPreviousNextItemBtn:(itemData[itemData?.length-1]?.showPreviousNextItemBtn || false),showBoardHierarchy:(itemData[itemData?.length-1]?.showBoardHierarchy || false),enableItemViewChangeBtn:currentViewId,itemOutsideOpen:itemData[itemData?.length-1]?.itemOutsideOpen,hideHeader:itemData[itemData?.length-1]?.hideHeader,showSideOptions:itemData[itemData?.length-1]?.showSideOptions}"
      [itemStateData]="itemData"
      (onOtherItemOpen)="onOtherItemOpen?.emit($event)"
      [leadId]="itemData[itemData?.length-1] ?.itemId"
      [dashId]="itemData[itemData?.length-1]?.boardId"
      [leadObj]="itemData[itemData?.length-1]?.item"
      [tableInfo]="itemData[itemData?.length-1]?.tableInfo"
      (onitemViewTypeChange)="updateView($event)"
      (onItemChange)="onItemChange?.emit($event)"
      (emitCloseLeadModal)="closeItemView.emit(null);modal?.close()"
      (minimizeRight)="minimizeRight.emit($event)">
      </item-full-popup>
   </ng-container>
</ng-template>