import { Component, OnInit, Input } from "@angular/core";

declare var $: any;

@Component({
  selector: "app-image-modal",
  templateUrl: "./imageModal.component.html",
  styleUrls: ["./imageModal.component.css"],
})
export class ImageModalComponent implements OnInit {
  @Input() imageAttachment: any;

  constructor() {}

  ngOnInit() {}

  openImageAttachmentModal() {
    $("#imageAttachmentModal").modal("show");
  }

  closeModal() {
    $("#imageAttachmentModal").modal("hide");
  }
}
