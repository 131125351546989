import { Component, Input, OnInit } from '@angular/core';;

@Component({
  selector: 'app-shimmer-loader',
  templateUrl: './shimmer-loader.component.html',
  styleUrls: ['./shimmer-loader.component.scss']
})
export class ShimmerLoaderComponent implements OnInit {

  
  @Input() height:any=30;
  @Input() width:any='100vw';
  @Input() radius=0;
  @Input() count:number=1
  @Input() cardCount:number=1
  @Input() columnGap:number=30
  @Input() rowGap:number=30
  @Input() animation:any='progress'
  @Input() type=null
  @Input() header:boolean=true
  @Input() columnCount=1
  @Input() lineWidth:any=[]
  @Input() circleWidth:number=30
  @Input() circleHeight:any=null
  @Input() circleRadius:number=50
  @Input() externalStyle:any;
  @Input() color:any='black';

  constructor() { }

  ngOnInit(): void {
  }
  getListTheme(background?){
    let listTheme={ 
      height: this.height+'px',
      width:this.width,
      'border-radius': this.radius,
      left:0,
      margin:'0px 2px 4px 2px',
      display:'flex' 
    
    }
    if(background){
      listTheme['background']=background;
      listTheme['border']='1px solid #3571de80'
    }
    return listTheme;
  }

}
