import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckListComponent } from './check-list/check-list.component';
import { ItemBulkSelectionComponent } from './item-bulk-selection/item-bulk-selection.component';
import { FormsModule } from '@angular/forms';
import { ReusableComponentModule } from '../../reusable/reusableComponent.module';
import { ReusableModule } from '../../reusable.module/reusable.module';
import { SharedComponentsModule } from '../shared-components/shared.components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { LeadReusableModule } from '../lead-reusables/lead-reusables.module';
import { TranslateModule } from '@ngx-translate/core';
import { MergeItemComponent } from './merge-Items/merge-Items.component';
import { LinkyModule } from 'ngx-linky';



@NgModule({
  declarations: [
    CheckListComponent,
    ItemBulkSelectionComponent,
    MergeItemComponent
  ],
  exports: [
    CheckListComponent,
    ItemBulkSelectionComponent,
    MergeItemComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReusableModule,
    ReusableComponentModule,
    SharedComponentsModule,
    LeadReusableModule,
    PopoverModule.forRoot(),
    NgSelectModule,
    NgbModule,
    TranslateModule,
    LinkyModule
  ]
})
export class ViewBasedReusablesModule { }
