<div class="bodyContent" (click)="$event.stopPropagation()">
  <div>
      <!--To-->
      <div class="emailModalToArea" (dragover)="onEmailDropOver($event, 'to')" 
      (drop)="onEmailDrop($event, 'to',emailArrTo)">
        <div style="display: flex;width:5%;"><span>To :</span></div>

        <div class="position-relative" style="display: flex;width:88%;flex-wrap: wrap;">
          <ng-container *ngFor="let item of emailArrTo;let i= index">
            <div class="chip mt-0" class="chip mt-0" 
            draggable="true" (dragstart)="onEmailDragStart($event, i, 'to',emailArrTo)"
            (dragend)="onEmailDragEnd($event, 'to');removeEmail(i,'to')">
              <ngx-avatars class="mr-1" [name]="item.name" size="20" initialsSize="2" ></ngx-avatars>{{item.email}}
              <span class="closebtn" style="margin-left: 3px;" (click)="removeEmail(i,'to')" *ngIf="(templateSettings && !((!isAdmin && !isTemplatePersonal) || isReadOnly)) || !templateSettings">&times;</span>
            </div>
          </ng-container>

          <input type="email"  class="emailToInput" placeholder="Enter here" [(ngModel)]="emailSetTo" (ngModelChange)="checkEmptyEmail('to')" (keydown.enter)="onEmailsSetKeydown('to')" #emailSetRefTo="ngModel"
          (focus)="openMentionPanel(emailSetRefTo.value,'to')" (keyup)="openMentionPanel(emailSetRefTo.value,'to')" (focusout)="colseMentionPanel();onEmailsSetKeydown('to')"  [disabled]="templateSettings && ((!isAdmin && !isTemplatePersonal) || isReadOnly)"           >      
          <p class="w-100 mb-0" *ngIf="!validateToEmail">
            <span class="text-danger ls-025x">*{{'itemattributes.enterCorectEmail' | translate}}</span>
          </p> 
          <div class="memtionsDiv" *ngIf="isMentionPanelOpenTo">
            <app-user-list [searchFororgUsersJson]="searchFororgUsersJson" (selectedUser)="selectedUser($event)"></app-user-list>
          </div> 
        
        </div>

        <div style="display: flex;width:9%;flex-wrap: wrap;"> <span style="cursor: pointer;margin-right: 5px;" (click)="enableCC=true" *ngIf="!enableCC">Cc </span><span style="cursor: pointer;" (click)="enableBCC=true" *ngIf="!enableBCC">  Bcc</span></div>
      </div>

      <!--CC-->
      <div class="emailModalToArea" *ngIf="enableCC" (dragover)="onEmailDropOver($event, 'cc')" 
      (drop)="onEmailDrop($event, 'cc',emailArrCc)">
          <div style="display: flex;width:5%;"> <span>Cc :</span></div>

          <div style="display: flex;width:95%;flex-wrap: wrap;position:relative;">
            <ng-container *ngFor="let item of emailArrCc;let i= index">
                <div class="chip mt-0" draggable="true" (dragstart)="onEmailDragStart($event, i, 'cc',emailArrCc)"
                (dragend)="onEmailDragEnd($event, 'cc');removeEmail(i,'cc')">
                  <ngx-avatars class="mr-1" [name]="item.name" size="20" initialsSize="2" ></ngx-avatars>{{item.email}}
                  <span class="closebtn" style="margin-left: 3px;" (click)="removeEmail(i,'cc')" *ngIf="(templateSettings && !((!isAdmin && !isTemplatePersonal) || isReadOnly)) || !templateSettings">&times;</span>
                </div>
                </ng-container>
                <input type="email"  class="emailToInput" placeholder="Enter here" [(ngModel)]="emailSetCc" (keydown.enter)="onEmailsSetKeydown('cc')" (ngModelChange)="checkEmptyEmail('cc')" #emailSetRefCc="ngModel"
                (focus)="openMentionPanel(emailSetRefCc.value,'cc')" (keyup)="openMentionPanel(emailSetRefCc.value,'cc')" (focusout)="colseMentionPanel();onEmailsSetKeydown('cc')" [disabled]="templateSettings && ((!isAdmin && !isTemplatePersonal) || isReadOnly)" >  
                <p class="w-100 mb-0" *ngIf="!validateCcEmail">
                  <span class="text-danger ls-025x">*{{'itemattributes.enterCorectEmail' | translate}}</span>
                </p>    
                <div class="memtionsDiv" *ngIf="isMentionPanelOpenCc">
                  <app-user-list [searchFororgUsersJson]="searchFororgUsersJson" (selectedUser)="selectedUser($event)"></app-user-list>
                </div> 

          </div>
      </div>

      <!--BCC-->  
      <div class="emailModalToArea" *ngIf="enableBCC"  (dragover)="onEmailDropOver($event, 'bcc')" 
      (drop)="onEmailDrop($event, 'bcc',emailArrBcc)">
          <div style="display: flex;width:5%;"><span>Bcc :</span></div>

          <div style="display: flex;width:95%;flex-wrap: wrap;position:relative;">
            <ng-container *ngFor="let item of emailArrBcc;let i= index">
                <div class="chip mt-0" draggable="true" (dragstart)="onEmailDragStart($event, i, 'bcc',emailArrBcc)"
                (dragend)="onEmailDragEnd($event, 'bcc');removeEmail(i,'bcc')">
                    <ngx-avatars class="mr-1" [name]="item.name" size="20" initialsSize="2" ></ngx-avatars>{{item.email}}
                  <span class="closebtn" style="margin-left: 3px;" (click)="removeEmail(i,'bcc')" *ngIf="(templateSettings && !((!isAdmin && !isTemplatePersonal) || isReadOnly)) || !templateSettings">&times;</span>
                </div>
                </ng-container>
                <input type="email"  class="emailToInput" placeholder="Enter here" [(ngModel)]="emailSetBcc" (keydown.enter)="onEmailsSetKeydown('bcc')" (ngModelChange)="checkEmptyEmail('bcc')" #emailSetRefBcc="ngModel"
                (focus)="openMentionPanel(emailSetRefBcc.value,'bcc')" (keyup)="openMentionPanel(emailSetRefBcc.value,'bcc')" (focusout)="colseMentionPanel();onEmailsSetKeydown('bcc')" [disabled]="templateSettings && ((!isAdmin && !isTemplatePersonal) || isReadOnly)" >      
                <p class="w-100 mb-0" *ngIf="!validateBccEmail">
                  <span class="text-danger ls-025x">*{{'itemattributes.enterCorectEmail' | translate}}</span>
                </p>  
                <div class="memtionsDiv" *ngIf="isMentionPanelOpenBcc">
                  <app-user-list [searchFororgUsersJson]="searchFororgUsersJson" (selectedUser)="selectedUser($event)"></app-user-list>
                </div> 

          </div>                
      </div>

      <div class="emailModalSubjectArea" style="position:relative;">
        <div style="width:95%;">  
        <label>Subject :</label>
        <input type="text"  class="emailSubjectInput" placeholder="Enter here" [(ngModel)]="subject"  [disabled]="templateSettings && ((!isAdmin && !isTemplatePersonal) || isReadOnly)" >
        </div>
        <div style="width:10%;" class="cursorPointer">
          <button
          class="btn btn-disabled btn-rounded"
          [ngbPopover]="customColumns" 
          #popColumnsSubject="ngbPopover" 
          [autoClose]="'outside'"
          (click)="isSubjectColumn=true"
          [disabled]="templateSettings && ((!isAdmin && !isTemplatePersonal) || isReadOnly)">
          {{ '\{\{ \}\}'}}
          </button>
        </div>
      </div> 
  </div>

 
 <!--Editor With MEdia-->
  <div class="emailEditor" style="height:100%;flex-grow: 1;display: flex;" (click)="$event.stopPropagation()">
    <div class="w-100">
       <app-editor-columns [dashId]="dashId" [type]="'EMAIL'" [isFullScreen]="isFullScreen" [emailSignatureForEditor]="emailSignatureForEditor" [leadObj]="leadInfo" [fileEvent]="fileEvent" [mediaArr]="tempMediaArray" [attachment]="attachment" (onBlur)="onBlur($event)" (isFullScreenEmit)="isFullScreenEmit.emit()"></app-editor-columns>
    </div>
  </div>
</div>

<div class="emailBodyBottom mt-2" (click)="$event.stopPropagation()">
  <div style="display:flex;align-items: center;">
      <button type="button" *ngIf="!templateSettings" class="btn btn-danger rounded " [ngClass]="{'disabledSendButton': !from || (!emailArrBcc?.length && !emailArrCc?.length && !emailArrTo?.length) || uploadInProgress}" style="height: 30px;" (click)="onClickSend()" [disabled]="sendDisabled || !from || (!emailArrBcc?.length && !emailArrCc?.length && !emailArrTo?.length)">Send</button>
  </div>
  <div>
     <button class="columnsBtn" *ngIf="!templateSettings" placement="auto" [ngbPopover]="templateList" #popTemplate="ngbPopover" [autoClose]="'outside'"><img src="../../../assets/1x/comment.svg">Templates</button>
    </div>
 </div>
 <div class="d-none" id="sendEmailCode">xx</div>

 <!--Column Popup-->
 <ng-template #customColumns>
  <ng-container *ngIf="!templateSettings">
    <lead-columns-picker [dashId]="dashId" [leadObj]="leadInfo" (columnValueemit)="columnValueemit($event)" [mediaDisable]="isSubjectColumn" (closeEmit)="popColumnsSubject.close();" (mediaEmitForColumns)="mediaEmitForColumns($event)"></lead-columns-picker>
  </ng-container>
</ng-template>


 <!--- Template popup--->
<ng-template #templateList>
  <app-channel-template-picker [leadInfo]="leadInfo" [dashId]="dashId" [emailTemplates]="true" [sprintArr]="sprintArr" [orgUsersJson]="orgUsersJsonSet" (sendTemplateEmit)="getMessageFromTemplate($event)" (closeEmit)="popTemplate.close();closeModal.emit()"></app-channel-template-picker>
</ng-template>


