import { ChangeDetectorRef, Compiler, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantService } from '../../../../Enums/Constant.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { CustomStorageService } from '../../../services/custom-storage.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { MqttmessagingService } from '../../../services/mqttmessaging.service';

@Component({
  selector: 'app-full-item-dependency-sub-task-check-list',
  templateUrl: './full-item-dependency-sub-task-check-list.component.html',
  styleUrls: ['./full-item-dependency-sub-task-check-list.component.scss']
})
export class FullItemDependencySubTaskCheckListComponent implements OnInit {
  @Input() currentLead: any;
  @Input() dashId: string;
  @Input() tabIndex: number = 1;
  @Input() dependsOnItemsIdArr: any[] = [];
  @Input() dependentsItemIdArr: any[] = [];
  @Input() isPublicLead:boolean=false;
  @Input() dashboardUserRole:any
  @Input() bucketArray: any[] = [];
  @Input() showHiddenFields:any={};
  @Input() viewOnly:boolean=false;
  dependenciesObj = { dependencies: {} }
  checklistObj = {};
  addDependency = false;
  addCheckList = false;
  addSubTask = false;
  isBlocking = false;
  searchInput: string = '';
  isSubTaskSearchOpen = false;
  isDependancySearchOpen = false;
  isBoardSearchOpen = false;
  dependancy = [{ label: "Blocking", value: false }, { label: "Blocked by", value: true }]
  editIndex: any;
  addCheckText = ''
  selectedChiledLeadIds: any[] = [];
  @Output() selectedChiledLeadIdsEmit = new EventEmitter<any>();
  @Output() dependenciesObjEmit = new EventEmitter<any>();
  @Output() onItemOpen = new EventEmitter<any>();
  @Output() showSubtaskParentModal = new EventEmitter<any>();
  @Output() createNewDependency = new EventEmitter<any>();
  isEdit = false;
  isReadOnly: boolean;
  mqttSubscription: any;
  dependancyItemsToAvoidSearch: any[] = [];
  duplicatedByItemsIds: any[] = [];
  setquickSubTask: boolean = false;
  creationtext: any;
  isDashboarUser: any;
  dashAccess: { is_readOnly_user: boolean; no_dashboard_user: boolean; is_collabrator_user: any; is_deleted_lead: any; dashboard_type: any; is_template_board: boolean; is_internal_board: boolean; };
  createPermissioninBucket:boolean;
  localFilterDataObj = this.commonUtils.createDefaultFilterDataObj();
  arrayOfCustomFiled=[]
  searchLeadList=[]
  noResponse=null
  orgUsersJson: any
  bucketOfCorrespondingDash: any = [];
  filterPopoverHolder:any
  customFields: any[] = [];
  isFinalBucket:boolean=false;
  subtaskPermission={
    "isPresent":false,
    "display_name":'Sub Task'
  };
  dependencyPermission={
    "isPresent":false,
    "display_name":'Dependencies'
  };
  dependencyEnable:boolean=false
  dependentEnable:boolean=false
  constructor(private httpTransfer: HttpTransferService, private commonUtils: CommonUtils,
    private MqttmessagingService: MqttmessagingService,
    public constantService: ConstantService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private dashboardUtils : DashboardUtilsService,
    private customStorageService : CustomStorageService,
    private compiler: Compiler, private injector: Injector
    ) {
    this.listenForMqttMessages();

   }
   activityTypeKey = this.constantService.getActivityTypeKey();
   updateActivityTYpe = this.constantService.getUpdateActivityType();
   typeKey = this.constantService.getObjectTypeKey();
   leadObjectTYpe = this.constantService.getLeadObjectType();
   detailsKey = this.constantService.getDetailskEY();

   listenForMqttMessages() {
    this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
      if (message) {
          this.updatesFromWss(message)
      }
    });
  }

  updatesFromWss(messagees) {
    let message = JSON.parse(messagees);
    if (message[this.activityTypeKey] == this.updateActivityTYpe && message['additional_attributes']['details']['_id'] == this.currentLead['_id']) {
      if (message[this.typeKey] == this.leadObjectTYpe) {
        this.handleLeadUpdateTypeOperation(message['additional_attributes']['details']);
      }
    }
  }

  handleLeadUpdateTypeOperation(message) {
    this.currentLead = this.commonUtils.creatingJsonStructureOfLeadForTableView(message);
  }

  async ngOnInit() {
    this.bucketArray.forEach(ele=>{
      if(ele?.name===this.currentLead.bucket_name){
        if(ele?.is_destination) this.isFinalBucket=true
      }
    })
    this.createPermissioninBucket= await this.commonUtils.geteBucketsUserHasCreatePermission(this.dashId,this.dashboardUserRole,this.currentLead?.bucket_id)

    this.commonUtils.isDashboardReadOnly(this.dashId, this.currentLead?.lead_colloborators_users || [],this.currentLead).then((res : any) => { 
      if(!this.viewOnly){this.isReadOnly = res}
      else{this.isReadOnly=this.viewOnly}
    });
   this.dashAccess = await this.commonUtils.isLeadOrDashboardReadOnlyOrViewOny(this.dashId,this.currentLead)
    this.isDashboarUser = await this.dashboardUtils.isDashboardUser(this.customStorageService.getItem('id'), this.dashId);
    if(this.currentLead) this.getDuplicatedByItems()
    if(this.currentLead?.dependencies?.depends_on) {
      this.currentLead?.dependencies?.depends_on ? this.dependsOnItemsIdArr = [...this.currentLead?.dependencies?.depends_on] : this.dependsOnItemsIdArr;
      this.dependancyItemsToAvoidSearch = [...this.currentLead?.dependencies?.depends_on];
    }
    if(this.currentLead?.dependencies?.dependents) {
      this.currentLead?.dependencies?.dependents ? this.dependentsItemIdArr = [...this.currentLead?.dependencies?.dependents] : this.dependentsItemIdArr;
      this.dependancyItemsToAvoidSearch = [...this.dependancyItemsToAvoidSearch,...this.currentLead?.dependencies?.dependents];
    }
    this.orgUsersJson = await this.dashboardUtils.getOrgUsers();
  
    this.dashboardUtils.getDashboardCustomForms([this.dashId]).then(res=>{
      this.arrayOfCustomFiled = res[this.dashId].CUSTOM_FORM
    })
    const dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.dashId])
    const existingCustomField=dashboardInfo[this.dashId]?.EXISTING_CUSTOM_FORM
    existingCustomField?.forEach(ele=>{
      if(ele?.key==='sub_task'){this.subtaskPermission['isPresent']=ele?.hidden;
      this.subtaskPermission['display_name']=ele?.display_key}
      if(ele?.key==='dependency'){this.dependencyPermission['isPresent']=ele?.hidden;
      this.dependencyPermission['display_name']=ele?.display_key}
    })
  }

  ngOnChanges(changes: SimpleChange) {
    if(!changes['dependentsItemIdArr']?.currentValue) this.dependentsItemIdArr = [];
    if(!changes['dependsOnItemsIdArr']?.currentValue) this.dependsOnItemsIdArr = [];
    if(changes['dependentsItemIdArr']?.currentValue != changes['dependentsItemIdArr']?.previousValue) {
      this.dependenciesObj.dependencies["dependents"] = this.dependentsItemIdArr;
    }
    if(changes['dependsOnItemsIdArr']?.currentValue != changes['dependsOnItemsIdArr']?.previousValue) {
      this.dependenciesObj.dependencies["depends_on"] = this.dependsOnItemsIdArr;
    }
  }

  getDuplicatedByItems() {
    let duplicatedByItems = []
    let inputJson = {duplicated_by: [this.currentLead._id]}
    this.httpTransfer.getLeadQuery(inputJson).subscribe((res : any) => {
      if(res.status === 200) {
        duplicatedByItems = res.result.leadResponse || [];
        this.duplicatedByItemsIds = duplicatedByItems.map(item => item._id)
      }
    });
  }

  validateLetters(val) {
    this.searchInput = val;
    if (val === '') {
      this.cd.detectChanges()
    }
  }

  addItem(leadData, flag) {
    if (flag) {
      this.addDependsOnItem(leadData);
    } else {
      this.addDependentItem(leadData);
    }
  }
  addDependsOnItem(leadData) {
    let dependsOnLeadId = leadData._id;
    let dependenciesObj = { dependencies: {} };
    if(!Array.isArray(this.dependsOnItemsIdArr)) this.dependsOnItemsIdArr = [];
    this.dependsOnItemsIdArr.push(dependsOnLeadId);
    this.dependancyItemsToAvoidSearch = [...this.dependsOnItemsIdArr];
    if(this.dependsOnItemsIdArr) dependenciesObj["dependencies"]["depends_on"] = [...this.dependsOnItemsIdArr];
    if(this.dependentsItemIdArr) dependenciesObj["dependencies"]["dependents"] = [...this.dependentsItemIdArr];
    if (!this.currentLead) {
      this.dependenciesObj = {...dependenciesObj};
      this.dependenciesObjEmit.emit(this.dependenciesObj);
    } else {
      this.httpTransfer.updateLeadCorrespondingToLeadId(dependenciesObj, this.dashId, this.currentLead._id).subscribe((res : any) => {
        if (res.status == 200) {
        }
      },
      (err)=>{
        if(this.dependsOnItemsIdArr.includes(dependsOnLeadId)) {
          let index = this.dependsOnItemsIdArr.findIndex(id=>id===dependsOnLeadId);
          if(index>=0) this.dependsOnItemsIdArr.splice(index,1);
        }
      });
    }
  }
  addDependentItem(leadData) {
    let dependentLeadId = leadData._id;
    let dependenciesObj = { dependencies: {} };
    if(!Array.isArray(this.dependentsItemIdArr)) this.dependentsItemIdArr = [];
    this.dependentsItemIdArr.push(dependentLeadId);
    this.dependancyItemsToAvoidSearch = [...this.dependentsItemIdArr];
    if(this.dependsOnItemsIdArr) dependenciesObj["dependencies"]["depends_on"] = [...this.dependsOnItemsIdArr];
    if(this.dependentsItemIdArr) dependenciesObj["dependencies"]["dependents"] = [...this.dependentsItemIdArr];
    if (!this.currentLead) {
      this.dependenciesObj = {...dependenciesObj};
      this.dependenciesObjEmit.emit(this.dependenciesObj);
    } else {
      this.httpTransfer.updateLeadCorrespondingToLeadId(dependenciesObj, this.dashId, this.currentLead._id).subscribe((res : any) => {
        if (res.status == 200) {
        }
      },
      (err)=>{
        if(this.dependentsItemIdArr.includes(dependentLeadId)) {
          let index = this.dependentsItemIdArr.findIndex(id=>id===dependentLeadId);
          if(index>=0) this.dependentsItemIdArr.splice(index,1);
        }
        
      });
    }
  }

  addChildLead(childLeadId: string) {
      this.httpTransfer.updateLeadCorrespondingToLeadId({ parent_id: this.currentLead._id }, this.dashId, childLeadId).subscribe((res : any) => {
        if (res.status === 200) { }
      });
  }

  async createSubTask(modal?){
    this.setquickSubTask = await this.commonUtils.createQuickItemOrNot(this.dashId)
    if(!this.setquickSubTask || ! this.createPermissioninBucket){
      this.modalService.open(modal, {size : 'lg', windowClass: 'copyTemplateLeadItem'})
    }
    setTimeout(()=>{document.getElementById('subtaskTextInput')?.focus()},10)
    this.scrollTO('sub-task'+this.currentLead['_id'])
  }


  removeDependency(id, isBlocking) {
    if(this.dependancyItemsToAvoidSearch.includes(id)) {
      const index = this.dependancyItemsToAvoidSearch.indexOf(id)
      if(index != -1) this.dependancyItemsToAvoidSearch.splice(index,1); 
    }
    if(isBlocking) {
      let dependenciesObj = { dependencies: {} };
      const index = this.dependsOnItemsIdArr.indexOf(id)
      if(index > -1) this.dependsOnItemsIdArr.splice(index,1);
      dependenciesObj["dependencies"]["depends_on"] = [...this.dependsOnItemsIdArr];
      dependenciesObj["dependencies"]["dependents"] = [...this.dependentsItemIdArr];
      if (!this.currentLead) {
        this.dependenciesObj = dependenciesObj;
        this.dependenciesObjEmit.emit(this.dependenciesObj);
      } else {
        this.httpTransfer.updateLeadCorrespondingToLeadId(dependenciesObj, this.dashId, this.currentLead._id).subscribe((res : any) => {
          if (res.status == 200) {
          }
        });
      }
    } else {
      let dependenciesObj = { dependencies: {} };
      const index = this.dependentsItemIdArr.indexOf(id)
      if(index > -1) this.dependentsItemIdArr.splice(index,1) 
      dependenciesObj["dependencies"]["depends_on"] = [...this.dependsOnItemsIdArr];
      dependenciesObj["dependencies"]["dependents"] = [...this.dependentsItemIdArr];
      if (!this.currentLead) {
        this.dependenciesObj = dependenciesObj;
        this.dependenciesObjEmit.emit(this.dependenciesObj);
      } else {
        this.httpTransfer.updateLeadCorrespondingToLeadId(dependenciesObj, this.dashId, this.currentLead._id).subscribe((res : any) => {
          if (res.status == 200) {
          }
        });
      }
    }
  
  }
  createSubTaskLead(){
    if(!this.creationtext?.length){this.setquickSubTask = false;return}
    let leadObj={}
    leadObj["title"] = this.creationtext;
      leadObj["bucket_id"] = this.currentLead?.bucket_id;
      leadObj["parent_id"] = this.currentLead?._id;
      leadObj["priority"] = 1;
        this.httpTransfer
          .createLeadCorrespondingToDashBoard(leadObj, this.dashId)
          .subscribe((res : any) => {
            if (res.status == 200) {
              this.setquickSubTask = false
              this.creationtext = ''
            }
          })
  }

  setItemToBoard(item,value,index){
    this.addChildLead(value._id)
  }
  removeSubitem(id) {
      this.httpTransfer.updateLeadCorrespondingToLeadId({ parent_id: '' }, this.dashId, id).subscribe((res : any) => {
        if (res.status === 200) { 
          this.currentLead.child_lead_id.forEach((ele,i)=>{
            if(ele===id) this.currentLead.child_lead_id.splice(i,1)
          })
        }
      });
  }
  scrollTO(id) {
    setTimeout(()=>{document.getElementById(id).scrollIntoView({behavior: "smooth",block: "start",inline: "nearest"});},10)
  }

  ngOnDestroy() {
    this.mqttSubscription?.unsubscribe()
  } 
  

  selectedItemAfterFilter(event){
    this.addChildLead(event)
  }

  setItemToBoarddependecny(event,item,index){
    console.log("values",event,item,index)
  }


}
