import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonUtils } from '../../services/CommonUtils.service';
import { FilterPipe } from '../../filter.pipe';
@Component({
  selector: 'app-dashboard-sprint',
  templateUrl: './dashboard-sprint.component.html',
  styleUrls: ['./dashboard-sprint.component.scss']
})
export class DashboardSprintComponent implements OnInit {
  @Input() dashBoardSprint: any[] = [];
  @Input() phasePopover:boolean=false
  @Input() selectedSprint: any[] = [];
  @Input() dashboardUserRole: any[] = [];
  @Output() sprintChanged = new EventEmitter<any>();
  sprintData:any = [];
  selectedSprintIds: any[] = [];
  searchText:any
  archieveCheck:boolean=false
  isMultiSelectExpanded: boolean = false;
  dashBoardPhase: any[] = [];
  @Output() closePopover: EventEmitter<void> = new EventEmitter<void>();
  constructor(private commonUtils: CommonUtils,public datepipe: DatePipe) { }

  ngOnInit(): void {
    if(this.selectedSprint.length>1) this.isMultiSelectExpanded = !this.isMultiSelectExpanded;
    if(this.selectedSprint.length>0) this.selectedSprintIds =  this.selectedSprint.map(sprint => sprint?._id) 
    this.dashBoardPhase = [...this.dashBoardSprint];
    this.dashBoardPhase.unshift({ _id: null, name: 'No phase' });
    if(this.dashBoardPhase.length>0) {
      this.dashBoardPhase.forEach(element => {
        if(!element.is_hidden || this.dashboardUserRole.includes('DASHBOARD_ADMIN')){
          let sprintObj = {};
          sprintObj['_id']=element._id
          sprintObj["sprint"] = element;
          sprintObj["name"] = element.name;
          sprintObj["is_hidden"] = element.is_hidden;
          sprintObj["is_archive"] = element.is_archive;
          sprintObj["current_status"] = element.current_status;
          if(element.planned_start_date) sprintObj["planned_start_date"] = this.datepipe.transform(this.commonUtils.epochToDate(element.planned_start_date), 'd MMM y')
          if(element.planned_end_date) sprintObj["planned_end_date"] = this.datepipe.transform(this.commonUtils.epochToDate(element.planned_end_date), 'd MMM y')
          this.sprintData.push(sprintObj);
        }
      });
    }
    this.sortSprintIds()
  }
  onSprintSelect(sprint) {
    if (!this.isMultiSelectExpanded || sprint==null) {
      this.closePopover.emit();
    }
    if (sprint) {
      const index = this.selectedSprint.findIndex(item => item._id === sprint._id);
      if (index > -1) {
        const sprintIndex = this.selectedSprintIds.indexOf(sprint._id);
        if (sprintIndex > -1) {
          this.selectedSprintIds.splice(sprintIndex, 1);
        }
      } else {
        if (this.isMultiSelectExpanded) {
          this.selectedSprintIds.push(sprint._id);
        } else {
          this.selectedSprintIds = [sprint._id];
        }
      }
      this.sortSprintIds();
    }else{
      this.selectedSprintIds=[]
    }
    this.sprintChanged.emit(this.selectedSprintIds);
  }

  sortSprintIds(){
      let selected=this.sprintData.filter(ele => this.selectedSprintIds.includes(ele._id))
      let notSelected=this.sprintData.filter(ele => !this.selectedSprintIds.includes(ele._id))
      this.sprintData=[...selected,...notSelected]
  }
  toggleMultiSelect() {  
    this.isMultiSelectExpanded = !this.isMultiSelectExpanded;
  } 
}
