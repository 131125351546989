<div class="search">
  <input #searchInput style="border:none; width:90%" class="" [placeholder]="itemAttributesObj?.bucket_id?.label | attributesLabel: itemAttributesObj: 'bucket_id' : 'editcustom.searchBucket' | translate"  [(ngModel)]="searchText" (input)="filterBuckets(searchText)">
  <span class="m-1" style="width:10%"><i class="fa fa-search"></i></span>


</div>
<div class="bucketstatus-cnr" #container>
    <ul *ngFor="let bucketData of bucketArray;let index=index">
      <li *ngIf="!bucketData.istrash" [class.selected-field]="selectedIndex==index"   [ngStyle]="{'background-color': bucketData?.color ? bucketData.color : '','color': bucketData?.color ? commonUtils.getContrastColor(bucketData?.color) : ''}"  (click)="onBucketSelect(bucketData)" [class]="{'active':bucketData.is_destination}"><img *ngIf="bucketData.is_destination"src="assets/1x/bucket-selected.png" alt=""> {{bucketData.name}}</li>
    </ul>
    <ul *ngIf="this.bucketArray.length==0" class="text-center my-2">
      <b>{{'editcustom.noBucketsFound' | translate}}</b>
    </ul>
  </div>