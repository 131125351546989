import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'; 
import { ConstantService } from '../../../../Enums/Constant.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { CustomStorageService } from '../../../services/custom-storage.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from '../../../services/toaster.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Output("updateNotificationCount") updateNotificationCount = new EventEmitter<any>();
  notification = [];
  allNotifications = [];
  notifiactionPageSize: any = 20;
  notifiactionPageno: any = 1;
  totalNumberOfNotificationPages: any = 0;
  notificationTypeObj: { dashboard: string; dashboardUser : string; item : string; lead: string; date: string; comment: string; assignment: string; leadComment: string, activityType: { add: string; update: string; delete: string;board_update:string }; };
  readConstant: string;
  @Input() unreadNotificationCount:number=0;
  isUserHasNotAccessOnLeadAndDash:boolean=false
  showShimmerLoader : boolean=true
  showScrollLoader : boolean=false
  constructor(
    private httpTransfer: HttpTransferService,
    private customStorageService: CustomStorageService,
    private constantService: ConstantService,
    private commonUtils: CommonUtils,
    private toaster: ToasterService
    ) {
      this.notificationTypeObj = this.constantService.getNotificationTypeObj();
      this.readConstant = this.constantService.getReadConstant();
     }

  ngOnInit(): void {
    this.getNotification();
  }

  getNotification() {
    var userId = this.customStorageService.getItem("id");
    var inputJson = {};
    var paginationJson = {};
    paginationJson["page_no"] = this.notifiactionPageno;
    paginationJson["page_size"] = this.notifiactionPageSize;
    inputJson["pagination_details"] = paginationJson;
    this.httpTransfer.getNotification(userId, inputJson).subscribe((res : any) => {      
      if (res.status == 200) {
        var notificationRes = res.result.notifications;
        this.totalNumberOfNotificationPages = res.result.pagination_details.total_pages;
        notificationRes.forEach(element => {
          var outputJson = {};
          outputJson["message"] = element.body.message;
          outputJson["object_type"] = element.body.object_type;
          outputJson["activity_type"] = element.body.activity_type;
          outputJson["notification_id"] = element.notification_id;
          outputJson["notification_time"] =
            element.notification_time;
          outputJson["additional_attributes"] =
            element.body.additional_attribute;
          outputJson["message_title"] = element.body.message_title;
          outputJson["status"] = element.read;
          outputJson["lead_id"] =
          element.body.object_type_details.object_type_id;    
          this.notification.push(outputJson);
        });               
      }
      this.showShimmerLoader=false;
      this.showScrollLoader=false;
    },err=>{
      this.showShimmerLoader=false;
      this.showScrollLoader=false;
    });
  }

  gettingNotificationOnScroll() {
    this.notifiactionPageno++;
    if(this.notifiactionPageno <= this.totalNumberOfNotificationPages){
      this.showScrollLoader=true
      this.getNotification();
    }
  }

  onNavigateToEditLeadPage(seq_id, dashId,notify?) {
    let editUrl: string;
    if(notify.activity_type !== this.notificationTypeObj.activityType.delete){
      if(dashId && seq_id || dashId) {
        let urlOpenType=notify?.object_type===this.notificationTypeObj.dashboardUser && !seq_id ? "_self"  :  "_blank";
        editUrl = ((notify?.additional_attributes?.dashboard_type == 'BOARD_GRAPH') ? 'boardgraph/'  : 'board/') + (dashId && seq_id ? (dashId+"/"+seq_id) : dashId) ;
        window.open(editUrl,urlOpenType);
      } 
      else {
        return;
      }
    }
    // console.log(editUrl)
  }
  
  async markAsRead(notify?) {
    this.isUserHasNotAccessOnLeadAndDash=false
    if(notify){
      var dashId = notify.additional_attributes.dashboard_id;
      var leadSeqId = notify?.additional_attributes?.updated_item?.seq_id;
      var leadId = notify?.additional_attributes?.updated_item?._id;
      if((notify.activity_type !== this.notificationTypeObj.activityType.delete)){
        await this.getUserHasAccessOnLead(dashId,leadId)
      }
    }
    var userId = this.customStorageService.getItem("id");
    var inputJson = { 
      notification_history_id : notify ? [notify.notification_id] : [] ,
      status : this.readConstant
    };
    this.httpTransfer.updateNotification(userId, inputJson).subscribe((res : any) => {
      if (res.status == 200) {
        if(notify){
            if(!notify.status) this.updateNotificationCount.emit({type:"read",count:this.unreadNotificationCount-1})
            notify.status = true;
            if(this.isUserHasNotAccessOnLeadAndDash){
              this.toaster.Errors("you don't have access to this "+ ((dashId && leadId) ? "item" : "dashboard") )
              this.isUserHasNotAccessOnLeadAndDash=false;
            }
            else{
              this.onNavigateToEditLeadPage(leadSeqId, dashId,notify);
            }
        }
        else{
          this.updateNotificationCount.emit({type:"all",count:0})
          this.notification.forEach(element => {
            element.status = true;
          });
        }
      }
    });
  }
  async getUserHasAccessOnLead(dashId,leadId?){
    let leadObj =null
    if(dashId && leadId){
      let res : any=await this.httpTransfer.getLeadQuery({ "lead_id" : [leadId],"is_deleted" :[true,false] }, dashId).toPromise();
      leadObj= res.result.leadResponse[0];
    }
    await this.commonUtils.isLeadOrDashboardReadOnlyOrViewOny(dashId,leadObj).then((res : any) => { 
      if(res){
        
        this.isUserHasNotAccessOnLeadAndDash  = !res.is_collabrator_user && res.no_dashboard_user
      }else{
        this.isUserHasNotAccessOnLeadAndDash =false
      }
    });

  } 
}

