import { Attribute, Component, EventEmitter, Input, OnInit, Output, ViewChild, SimpleChanges } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ConstantService } from '../../../../Enums/Constant.service';
import { MediaIcons } from '../../../../Enums/media-icons';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from '../../../services/toaster.service';
import { EditorColumnsComponent } from 'app/all-reusables/editor-columns/editor-columns.component';
declare var $: any;

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class SendEmailComponent implements OnInit {
  @Output() isFullScreenEmit : EventEmitter<any> = new EventEmitter();
  @Output("closeModal") closeModal = new EventEmitter<any>();
  @Output("editorSet") editorSet = new EventEmitter<any>();
  @ViewChild('popTemplate') public popTemplate : NgbPopover
  @ViewChild('popColumns') public popColumns : NgbPopover
  @ViewChild('popColumnsSubject') public popColumnsSubject : NgbPopover
  @ViewChild(EditorColumnsComponent) editor!: EditorColumnsComponent;
  @Input() dashId:any;
  @Input() leadInfo: any;
  @Input() sprintArr:any;
  @Input() from:any;
  @Input() templateSettings:boolean=false
  @Input() emailTemplate:any = undefined
  @Input() isTemplatePersonal:boolean=false
  @Input() template_name:any
  @Input() isAdmin:boolean=false
  @Input() isReadOnly:boolean=false
  @Input() replyingEmail:any;
  @Input() isReplyAll:any;
  @Input() embeddedImageAttachments:any = {};
  @Input() emailSignatureForEditor : any;
  sendDisabled:boolean=false
  mediaIcons = MediaIcons;
  emailSetCc: any;
  emailSetTo: any;
  emailArr: any[]=[];
  orgUsersJson: any;
  searchFororgUsersJson: any;
  enableCC:boolean=false;
  enableBCC:boolean=false;
  emailArrTo: any[]=[];
  emailArrCc: any[]=[];
  isMentionPanelOpenTo: boolean;
  isMentionPanelOpenCc: boolean;
  isMentionPanelOpenBcc: boolean;
  emailArrBcc: any[]=[];
  emailSetBcc: string;
  subject: any ='';
  arrOfCustomField: any;
  fileEvent: any[]=[];
  tempMediaArray: any[]=[];
  attachment: any[]=[];
  showLoader: boolean = false;
  isSubjectColumn: boolean = false;
  EMAIL: string = 'EMAIL';
  updateTemplateID:any
  columnAttributes = ['phase', 'assignee', 'startDate', 'endDate'] 
  columnsNotAllowed:any[] = []
  uploadInProgress: boolean = false
  uploadFileCount: number = 1
  currencyJson:any
  orgUsersJsonSet:any
  selectedMirror: any;
  enableMirror: boolean = false;
  algorithm:string;
  validateToEmail:boolean=true;
  validateCcEmail:boolean=true;
  validateBccEmail:boolean=true;
  draggedEmail: string = '';
  draggedEmailSource: string = '';
  emailBody: any;
  @Input() isFullScreen:boolean;
  constructor(private modalService: NgbModal,
    private dashboardUtils : DashboardUtilsService,
    private constantService : ConstantService,
    private httpTransfer : HttpTransferService,
    private toaster:ToasterService,
    private commonUtils :CommonUtils) {  
    this.dashboardUtils.getCurrencyJson().then(country=>{
      if(country){
        this.currencyJson=country
      }
    })
  }

  setFilePathToArrOfCustomFileds(fileUrlPath,filename) {
    console.log(fileUrlPath);
    fileUrlPath.forEach(e=>{
       let obj ={}
      obj['file_name'] = e.file_name+"."+e.path.split('.').pop(),
      obj['path'] = e.path
      if(e.media_type =='image')
      {
        obj['type'] = 'IMAGE'
      }
      else if(e.media_type =='video')
      {
        obj['type'] = 'VIDEO'
      } 
      else{
          obj['type'] = 'DOCUMENT'
      }
      this.attachment.push(obj)
    }) 
    this.sendEmail()

}


  async ngOnInit(){
    const orgUserJson = await this.dashboardUtils.getOrgUsers(); 
    this.orgUsersJsonSet = Object.values(orgUserJson)
    let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
    this.arrOfCustomField = JSON.parse(JSON.stringify(dashboardJson[this.dashId]['CUSTOM_FORM']));

    this.httpTransfer.getEmailSuggestions().toPromise().then((res : any) => {
      if(res?.email_suggestions?.emails){
        let suggestions = res?.email_suggestions?.emails
        suggestions?.forEach (suggestion => suggestion.email = suggestion.address)
        this.searchFororgUsersJson = suggestions
        this.orgUsersJson = suggestions
      }
    })

    this.columnsNotAllowed = this.constantService.getCustomColumnsNotAllowedForTemplates()

    if(this.templateSettings && this.emailTemplate){      
      this.setToCcBccAndAttachmentsFromTemplate(this.emailTemplate)             
      this.updateTemplateID = this.emailTemplate._id
      this.subject = this.emailTemplate?.additional_attributes?.subject;
    }
    
    if(this.replyingEmail)
    console.log(this.replyingEmail)
      this.replyEmailHandler()
  }

  replyEmailHandler(isUpdate?){
    let oldEmailArrTo=[...this.emailArrTo]
    let oldEmailArrToFilter=oldEmailArrTo?.filter(ele=>ele)
    this.emailArrTo = []
    let oldEmailArrCc=[...this.emailArrCc]
    let oldEmailArrCcFilter=oldEmailArrCc?.filter(ele=>ele)
    this.emailArrCc = []
    let from = this.replyingEmail?.from
    if(this.from?.email_address != from.address){
      from.email = from.address
      this.emailArrTo.push(from)
      if(this.isReplyAll && !isUpdate){
        this.replyingEmail?.cc?.forEach(item => {
          if(this.from?.email_address == item.address || this.emailArrTo[0]?.email == item.address) return
          item.email = item.address
          this.emailArrCc?.push(item)
        })
        this.replyingEmail?.to?.forEach(item => {
          if(this.from?.email_address == item.address || this.emailArrTo[0]?.email == item.address) return
          item.email = item.address
          this.emailArrCc?.push(item)
        })
      }
    }else{
      this.replyingEmail?.to?.forEach(to => {
        if(from.address == to.address) return
        let toObj = {...to}
        toObj.email =  to.address
        this.emailArrTo.push(toObj)
      })       
      if(this.isReplyAll){
        this.replyingEmail?.cc?.forEach(item => {
          item.email = item.address
          this.emailArrCc?.push(item)
        })
      }
    }
    this.emailArrCc=[...oldEmailArrCcFilter,...this.emailArrCc]
    this.emailArrCc=this.checkDublicateEntry(this.emailArrCc)
    this.emailArrTo=[...oldEmailArrToFilter,...this.emailArrTo]
    this.emailArrTo=this.checkDublicateEntry(this.emailArrTo)
    this.subject = this.replyingEmail?.subject
    if(!this.subject?.startsWith('Re: ')) this.subject = 'Re: ' + this.subject;
    this.emailArrCc = this.emailArrCc?.filter(cc => cc?.email != from.address)
    if(this.emailArrCc?.length) this.enableCC = true
  }

  ngOnChanges(changes: SimpleChanges) {
    this.sendDisabled = false
    if(changes.from && this.replyingEmail)
      this.replyEmailHandler(true)    
  }

  onEmailsSetKeydown(type){
    if(type == 'to'){
      this.validateToEmail=this.emailSetTo?.length && this.commonUtils.ValidateEmail(this.emailSetTo.trim())
      if(this.validateToEmail){
        this.emailArrTo.push({ email: this.emailSetTo, name: '' });
        this.emailArrTo = this.getuniqueEmailsObjects(this.emailArrTo)
        this.emailSetTo=''
      }
      this.validateToEmail= this.emailSetTo && this.emailSetTo?.length!=0? false:true
    }
    else if(type == 'cc'){
      this.validateCcEmail=this.emailSetCc?.length && this.commonUtils.ValidateEmail(this.emailSetCc.trim())
      if(this.validateCcEmail){
        this.emailArrCc.push({ email: this.emailSetCc, name: '' });
        this.emailArrCc = this.getuniqueEmailsObjects(this.emailArrCc)
        this.emailSetCc = ''
      }
      this.validateCcEmail=this.emailSetCc && this.emailSetCc?.length!=0? false:true
    }
    else if(type == 'bcc'){
      this.validateBccEmail=this.emailSetBcc?.length && this.commonUtils.ValidateEmail(this.emailSetBcc.trim())
      if(this.validateBccEmail){
        this.emailArrBcc.push({ email: this.emailSetBcc, name: '' });
        this.emailArrBcc = this.getuniqueEmailsObjects(this.emailArrBcc)
        this.emailSetBcc = ''
      }
      this.validateBccEmail=this.emailSetBcc && this.emailSetBcc?.length!=0? false:true
    }
  }

  openMentionPanel(input,type){
    if(input?.length>0){
        this.isMentionPanelOpenTo=false;
        this.isMentionPanelOpenCc=false;
        let allAddedEmails = []
      if(type == 'to'){
        this.isMentionPanelOpenTo=true;
        allAddedEmails = this.emailArrTo?.map(email => email.address)
      }
      else if(type == 'cc'){
        this.isMentionPanelOpenCc=true;
        allAddedEmails = this.emailArrCc?.map(email => email.address)
      }
      else if(type == 'bcc'){
        this.isMentionPanelOpenBcc = true;
        allAddedEmails = this.emailArrBcc?.map(email => email.address)
      }
      let searchText=input?.toLowerCase();
      this.searchFororgUsersJson=this.orgUsersJson?.filter((data) => (data.name.toLowerCase().includes(searchText) || data.email.toLowerCase().includes(searchText)) && !allAddedEmails.includes(data.email.toLowerCase()));
      if(this.searchFororgUsersJson.length==0){
        this.isMentionPanelOpenTo=false;
        this.isMentionPanelOpenCc=false;
        this.isMentionPanelOpenBcc=false;
      }
    }
  }

  removeEmail(i,type){
    if(type == 'to'){
      this.emailArrTo.splice(i,1)
    }
    else if(type == 'cc'){
      this.emailArrCc.splice(i,1)
    }
    else if(type == 'bcc'){
      this.emailArrBcc.splice(i,1)
    }
  }

  colseMentionPanel(){{
    setTimeout(()=>{
      this.isMentionPanelOpenTo=false;
      this.isMentionPanelOpenCc=false;
      this.isMentionPanelOpenBcc=false;
    },200)
  }}

  getuniqueEmailsObjects(emailObjectArray){
    const uniqueEmails = new Set();
    const uniqueArray = emailObjectArray.filter(obj => {
      if (!uniqueEmails.has(obj.email)) {
        uniqueEmails.add(obj.email);
        return true;
      }
      return false;
    });
    return uniqueArray
  }

  selectedUser(user){ 
    if(this.isMentionPanelOpenTo){ 
      this.emailArrTo.push(user);
      this.emailArrTo = this.getuniqueEmailsObjects(this.emailArrTo)
      this.emailSetTo=''
    }else if(this.isMentionPanelOpenBcc){
      this.emailArrBcc.push(user);
      this.emailSetBcc=''
      this.emailArrBcc = this.getuniqueEmailsObjects(this.emailArrBcc)
    }else{
      this.emailArrCc.push(user);
      this.emailSetCc=''
      this.emailArrCc = this.getuniqueEmailsObjects(this.emailArrCc)
    }
    // this.isMentionPanelOpenTo ? (this.emailArrTo.push(user) , this.emailSetTo='') :  this.isMentionPanelOpenBcc ? (this.emailArrBcc.push(user), this.emailSetBcc='') : (this.emailArrCc.push(user), this.emailSetCc='')
    if(this.isMentionPanelOpenTo){
      this.emailArrTo=this.checkDublicateEntry(this.emailArrTo)
      this.validateToEmail=true
    }
    if(this.isMentionPanelOpenBcc){
      this.emailArrBcc=this.checkDublicateEntry(this.emailArrBcc)
      this.validateBccEmail=true
    }
    if(this.isMentionPanelOpenCc){
      this.emailArrCc=this.checkDublicateEntry(this.emailArrCc)
      this.validateCcEmail=true
    }
    console.log("variable1",this.emailArrTo)
    this.isMentionPanelOpenTo=false;
    this.isMentionPanelOpenBcc=false;
    this.isMentionPanelOpenCc=false;
  }

  //check for dublicate entry in email
  checkDublicateEntry(arraList){
    const uniqueArrayOfObjects = arraList?.filter((user, index, self) => {
    const firstIndex = self.findIndex((u) => u.email === user.email);
    return index === firstIndex;
    });
    return uniqueArrayOfObjects
  }



  columnValueemit(value){
    console.log(value)
    if(this.isSubjectColumn){
      if(this.subject?.length) this.subject += value
      else this.subject = value
    }
  }

  mediaEmitForColumns(columnMedia){
    console.log(columnMedia)
    columnMedia?.attachment.forEach(m=>{
      let obj = {}
      obj['file_name'] = m?.file_name,
      obj['path'] = m?.file_path,
      obj['url'] = m?.url,
      obj['type'] = m?.file_type ? (m?.file_type.toUpperCase() === 'FILE' ? 'DOCUMENT' : m?.file_type.toUpperCase()) : m?.type.toUpperCase();
      this.tempMediaArray.push(obj)
    })
  }


  setToCcBccAndAttachmentsFromTemplate(template){
    [this.emailArrBcc, this.emailArrCc, this.emailArrTo] = [[], [], []] 
    let [data] = [template?.additional_attributes]
    data?.to?.forEach(element => {
      this.emailArrTo.push({ email: element, name: '' })
    }); 

    data?.cc?.forEach(element => {
      this.emailArrCc.push({ email: element, name: '' })
    }); 

    data?.bcc?.forEach(element => {
      this.emailArrBcc.push({ email: element, name: '' })
    }); 
    if(this.emailArrCc?.length) this.enableCC = true
    if(this.emailArrBcc?.length) this.enableBCC = true
  }


  getMessageFromTemplate(item){
    console.log(item)
    this.popTemplate.close() 
    this.subject = item?.additional_attributes?.subject; 
    item?.attachments.forEach(m=>{
      let obj = {}
      obj['file_name'] = m?.file_name,
      obj['file_path'] = m?.file_path,
      obj['url'] = m?.url,
      obj['type'] = m?.file_type ? m?.file_type : m?.type
      this.tempMediaArray.push(obj)
      this.attachment.push({'file_name': m?.file_name,'file_path':m?.file_path,'type':m?.type.toUpperCase()})
    })
    this.setToCcBccAndAttachmentsFromTemplate(item) 
   // this.editorSet.emit(item?.message)
    this.editor.getTemplateMessage(item?.message)
  }


  removeFile(i){
    this.tempMediaArray.splice(i,1)
  }

  onClickSend(template_id?){
    if(this.tempMediaArray.length && this.fileEvent?.length){
      this.commonUtils.onUploadFile(this.fileEvent, 'LEAD',null, this.setFilePathToArrOfCustomFileds.bind(this));
    } 
    else{
      this.sendEmail() 
    }
  }

  getEmailBodyFromSummernote(){
    let emailBody = $("#itemEmailSummerNote").summernote('code')
    $('#sendEmailCode').empty()
    $('#sendEmailCode').append(emailBody)
    Object.keys(this.embeddedImageAttachments)?.forEach(key =>{
      $(`#sendEmailCode #${key}`).prop('src','cid:'+key)
      let file = this.embeddedImageAttachments[key]
      this.attachment.push({
        file_name: file.file_name,
        file_path: file.file_path,
        type: file.type,
        attachment_id: file.attachment_id
      })
    })
    return $(`#sendEmailCode`).html()
  }

  onBlur(event){
    console.log(event)
    this.tempMediaArray = event?.mediaArr
    this.fileEvent = event?.fileEvent
    this.attachment = event?.attachment || []
    this.emailBody = event?.emailBody
  }



  sendEmail(){
    this.sendDisabled = true
    let inputJson = {
      subject: this.subject,
      body: this.emailBody,
      dashboard_id: this.dashId,
      item_id: this.leadInfo?._id,
      email_connection_id: this.from?._id,
      from: this.from?.email_address,
      ...(this.emailArrTo?.length && {to: this.emailArrTo?.map(item => item?.email)}),
      ...(this.emailArrCc?.length && {cc: this.emailArrCc?.map(item => item?.email)}),
      ...(this.emailArrBcc?.length && {bcc: this.emailArrBcc?.map(item => item?.email)}),
      ...(!this.from?.isPrimary && {alias: this.from?.email_address}),
      ...(this.replyingEmail && {reply_email_object_id: this.replyingEmail?._id}),
      ...(this.attachment.length && {attachments: this.attachment}),
    } 
    this.httpTransfer.emailSendMessage(inputJson).subscribe((res : any) =>{
      if(res.status == 200){
       this.toaster.Success("Email Queued Succesfully");
       this.fileEvent = []
       this.attachment = []
       this.tempMediaArray = []
       this.closeModal.emit()
      }
      else{
        
      }
    }, err => {this.sendDisabled = false}) 
  }

  private setAllAttachments() {    
    this.attachment = [...this.attachment, ...this.tempMediaArray];
    if (this.attachment.length) {
      this.attachment.forEach(e => {
        delete e?.useTemplate;
        delete e?.url;
      });
    }
  }

  /* async onEmailUploadFile(event){
    let files = event.target.files
    if(files?.length) {
      this.uploadFileCount = files.length
      this.uploadInProgress = true
      $(".mediaSection").first().animate({ scrollTop: $('.mediaSection').first().prop("scrollHeight")}, 1000);
      await this.commonUtils.onUploadFile(files, 'LEAD',null, this.setFilePathToArrOfCustomFileds.bind(this));
    }
    this.editorSet.emit($("#itemEmailSummerNote").summernote("code"))
  } */

  

  mirrorEnable(item){
    this.selectedMirror = item
    this.enableMirror = true;
  }



  checkEmptyEmail(type){
    if(type=='to'){
      this.validateToEmail=this.emailSetTo.trim().length? this.commonUtils.ValidateEmail(this.emailSetTo.trim()) : true;
    }
    else if(type=='cc'){
      this.validateCcEmail=this.emailSetCc.trim().length? this.commonUtils.ValidateEmail(this.emailSetCc.trim()) : true;
    }
    else if(type=='bcc'){
      this.validateBccEmail=this.emailSetBcc.trim().length? this.commonUtils.ValidateEmail(this.emailSetBcc.trim()) : true;
    }
  }

onEmailDragStart(event: DragEvent, index: number, source: string,emailArr) {
  this.draggedEmail = emailArr[index];
  this.draggedEmailSource = source;
  event.dataTransfer.setData('text/plain', '')
}

onEmailDropOver(event: DragEvent, target: string) {
  event.preventDefault();
}

onEmailDrop(event: DragEvent, target: string,emailArr) {
  event.preventDefault();
  if (this.draggedEmailSource !== target) {
    emailArr = emailArr.filter(email => email !== this.draggedEmail);
    if (target === 'to') {
      this.emailArrTo.push(this.draggedEmail);
    } else if (target === 'cc') {
      this.emailArrCc.push(this.draggedEmail);
    } else if (target === 'bcc') {
      this.emailArrBcc.push(this.draggedEmail);
    }
    this.draggedEmail = '';
    this.draggedEmailSource = '';
  }
}

onEmailDragEnd(event: DragEvent, source: string) {
  this.draggedEmail = '';
  this.draggedEmailSource = '';
}
}
