import { ComponentFactoryResolver, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BottomBarComponent } from './bottom-bar.component';
import { LeadReusableModule } from 'app/all-reusables/lead-reusables/lead-reusables.module';
import { ReusableModule } from 'app/reusable.module/reusable.module';
import { FormsModule } from '@angular/forms'; 
import { TranslateModule } from '@ngx-translate/core';
import { BoardViewModule } from 'app/board-view/board-view.module';
import { FiltersModule } from 'app/views/filters/filters.module';
import { InternalChatViewModule } from 'app/internal-chat-view/internalChat-view.module';
import { ChatViewModule } from 'app/chat-view/chat-view.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AvatarModule } from 'ngx-avatars';
import { SharedComponentsModule } from 'app/all-reusables/shared-components/shared.components.module';
import { GradientPipe } from 'app/gradient.pipe';

@NgModule({
  declarations: [BottomBarComponent],
  exports: [BottomBarComponent],
  imports: [
    CommonModule,
    NgbModule, 
    LeadReusableModule,
    ReusableModule,
    FormsModule,
    TranslateModule,
    BoardViewModule,
    FiltersModule,
    InternalChatViewModule,
    AvatarModule,
    ChatViewModule,
    SharedComponentsModule,
    NgSelectModule,
  ],
})
export class BottomBarModule {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  getComponent() {
    return this.componentFactoryResolver.resolveComponentFactory(
        BottomBarComponent
    );
  }
 }
