import { Component, OnInit, Input, Output,EventEmitter, ComponentFactoryResolver, ViewContainerRef, ViewChild, ElementRef } from '@angular/core';
import * as Y from "yjs";
import { WebsocketProvider } from 'y-websocket'
import { QuillBinding } from "y-quill";
import Quill from "quill";
import QuillCursors from "quill-cursors";
import * as awarenessProtocol from 'y-protocols/awareness.js'
import { forkJoin, Observable, Subject } from "rxjs";
import { HttpTransferService } from '../../../../../services/httpTransfer.service';
import { environment } from '../../../../../../environments/environment';
import { Router,ActivatedRoute, ParamMap } from '@angular/router';
import {   } from '../../../../../truncate.pipe';
import { CustomStorageService } from '../../../../../services/custom-storage.service';
import { QlcNoteOptionsComponent } from '../../qlc-note-options/qlc-note-options.component';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import './customComponentBlot';
import { v4 as uuidv4 } from 'uuid';
import { CommonUtils } from 'app/services/CommonUtils.service';
import {QlTaskRenderComponent } from '../../ql-task-render/ql-task-render.component';


@Component({
  selector: 'notes-editor',
  templateUrl: './notes-editor.component.html',
  styleUrls: ['./notes-editor.component.scss']
})

export class NotesEditorComponent implements OnInit {
  noteKey: any;
  yDoc: any;
  yText: any;
  lastUpdatedTime = 0
  lastInputChangeTime = new Date().getTime()
  @Input() selectedDashId: any;
  @Input() selectedNoteId: string;
  @Input()   selectedNoteTitle: string;
  @Output() getAllDashBoardNotes: EventEmitter<any> = new EventEmitter();
  @Output() updateNotesList: EventEmitter<boolean> = new EventEmitter();
  @Output() updatedNoteText: EventEmitter<string> = new EventEmitter();
  @Output() onDataPreview:EventEmitter<string> = new EventEmitter();
  @ViewChild('noteEditor') noteEditor: ElementRef;
  webSocketConnectedFirstTime = false
  textContent: any;
  selectedNoteText:any;
  quillReady = false;
  loginUserId: any[] = [localStorage.getItem("id")];
  leadResponse:any[]=[];
  quill:any;
  searchInputsubject: Subject<any> = new Subject();
  wsProvider: any;
  timer:any;
  timerSubscribe: any
  disconnectedRetryCount = 3;
  errorType:string = "";
  myEditorId = `editor_${crypto.randomUUID().substring(0,5)}`
  targetPosition:any;
  quillOptions = {
    cursors: true,
    toolbar:{
      container: [
        [{ 'font': [] },{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline','strike',{ 'align': [] },],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'header': '1'}, { 'header': '2' }, 'blockquote', 'code-block'],
        [{ 'indent': '-1' }, { 'indent': '+1'}],
        ['link','image', 'video'],
      ],
    },
    history: {
      userOnly: true
    }
  }
  itemData:any={}
  uploadingMediaInfo:any=[]
  optionsPopoverInfo:any={
    openKey:'/',
    popoverTriggerKey:'/',
    mentionTriggerkey:'@'
  }
  
  constructor(private httpTransfer: HttpTransferService,
    private route: ActivatedRoute,
    private router: Router,
    private customStorageService: CustomStorageService,
    private dashboardUtils: DashboardUtilsService,
    private commonUtils:CommonUtils,
    private componentFactoryResolver : ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    ) {}

  ngAfterViewInit(){
    Quill.register("modules/cursors", QuillCursors);
    const element = document.getElementById(this.myEditorId) || '';
    console.log(element);
    this.quill = new Quill(element, {
      modules: this.quillOptions,
      placeholder: "Loading.....",
      theme: "snow",
    });
    this.quill.enable(false)
    this.quill.on('text-change', function(delta, oldDelta, source) {
      this.selectedNoteText = JSON.stringify(this.yText.toDelta())
      const embed = delta.ops.find((op: any) => op?.insert && (op?.insert?.qlCustomComponent || op?.insert?.qlBlockCustomComponent));
      if(embed)this.createOrInsertComponentInQuill()
      if(source=='user' && delta?.ops?.length>0){
        this.checkPopoverOpenning(delta);
        this.deleteNoteMedia(delta,oldDelta);
      }
      
      this.updatedNoteText.emit(this.selectedNoteText)
    }.bind(this))
    
    if(!this.selectedNoteId) {
      this.quill.root.dataset.placeholder = "Enter Note...."
    }
    this.noteKey = this.selectedNoteId
    this.connectToWebSocket(false)
    // A Yjs document holds the shared data
  
    // Define a shared text type on the document
    
    // "Bind" the quill editor to a Yjs text type.
    
    // Remove the selection when the iframe is blurred

    window.addEventListener("blur", () => {
      this.quill.blur();
    });

    this.quill.on('text-change', (event) => {
      this.searchInputsubject.next(this.quill.root.innerHTML)
    });
  }

  insertTable(rows: number, columns: number): void {
    // Create table HTML
    let tableHTML = '<table class="custom-table"><tbody>';
    for (let i = 0; i < rows; i++) {
      tableHTML += '<tr>';
      for (let j = 0; j < columns; j++) {
        tableHTML += '<td></td>';
      }
      tableHTML += '</tr>';
    }
    tableHTML += '</tbody></table>';
    const range = this.quill.getSelection();
    this.quill.clipboard.dangerouslyPasteHTML(range.index, tableHTML);
  }

  
  ngOnInit() {
    
    localStorage['log'] = 'true';
  }

  getNoteKeyAndDetails(){
    //this.quill.placeholder = "Loading....."
    let noteDetails = this.httpTransfer.getNote({"note_id":[this.selectedNoteId]})
    let noteKey = this.httpTransfer.getNoteKey(this.selectedNoteId, {})
    forkJoin([noteDetails,noteKey ]).subscribe((result : any)=> {
      this.selectedNoteTitle = result[0].result.notes[0].title
      this.selectedNoteText=result[0].result.notes[0].note_text
      this.updatedNoteText.emit(this.selectedNoteText)
     // this.noteKey = result[1].result.note_key+'-'+this.selectedNoteId;
      this.noteKey = this.selectedNoteId;
      let changeEditorContents = result[1].result.is_new_key
      this.connectToWebSocket(changeEditorContents)
      this.quill.root.dataset.placeholder = "Enter Note..."
    })
  }

  connectToWebSocket(changeEditorContents?: boolean){
    // A Yjs document holds the shared data
        // Define a shared text type on the document
        
        // "Bind" the quill editor to a Yjs text type.
        
        // Remove the selection when the iframe is blurred
      this.yDoc = new Y.Doc();
      this.yText = this.yDoc.getText("quill");
     // this.noteKey = "w8akvlpsqb-62c86f6eb8cdf00015050208"
      this.wsProvider = new WebsocketProvider(environment.notesWebSocketProviderUrl, this.noteKey, this.yDoc, {

        // Set this to `false` if you want to connect manually using wsProvider.connect()
        connect: true,
        // Specify a query-string that will be url-encoded and attached to the `serverUrl`
        // I.e. params = { auth: "bearer" } will be transformed to "?auth=bearer"
        params: {auth: this.customStorageService.getItem("token")}, // Object<string,string>
        // You may polyill the Websocket object (https://developer.mozilla.org/en-US/docs/Web/API/WebSocket).
        // E.g. In nodejs, you could specify WebsocketPolyfill = require('ws')
        WebSocketPolyfill: WebSocket,
        // Specify an existing Awareness instance - see https://github.com/yjs/y-protocols
        awareness: new awarenessProtocol.Awareness(this.yDoc),
        // Specify the maximum amount to wait between reconnects (we use exponential backoff).
        maxBackoffTime: 2500
      });
      
      const binding = new QuillBinding(this.yText, this.quill, this.wsProvider.awareness);
      changeEditorContents = false
      if(changeEditorContents){
        var qlEditorElement = document.getElementsByClassName("ql-editor");
        qlEditorElement[0].innerHTML = "";
        const delta = this.quill.clipboard.convert(this.selectedNoteText)
        this.quill.setContents(delta)
      }
        
       
      this.wsProvider.connect();
      //If you want to override the messages received in wsProvider, you can use the below approach
            /* let myOwnMethod =  this.wsProvider.ws.onmessage; 
            console.log(myOwnMethod);
            this.wsProvider.ws.onmessage = async (event) => {
              try{
                let messsage =JSON.parse(event.data);
                if(messsage.error_key){
                  console.log("auth failed")
                  await  this.httpTransfer.getDashBoardInfoCorrespondingToDashId(this.selectedDashId).toPromise();
                  console.log("message", "refreshed data");
                  this.connectToWebSocket();
                }
              }
              catch(error){
                myOwnMethod(event);
              }
            }  */

      this.wsProvider.on('connection-error', event =>{
        console.log('connection-error', event)
      })
      this.wsProvider.on('connection-close', async event =>{
        console.log('connection-close', event)
        if(event.code === 1006){
          this.wsProvider.disconnect()
          this.wsProvider.destroy()
          let results : any = await this.httpTransfer.getNote({note_id: [this.selectedNoteId]}).toPromise()
          
          if(results?.result?.notes.length > 0){
            this.connectToWebSocket();
          }
          else{
            this.quill.root.dataset.placeholder = "You don't have access to this note"
            this.quill.enable(false)
          }

        }
      })
      this.wsProvider.on('status', event => {
        if(event.status === 'connected'){
          this.quill.root.dataset.placeholder = "Enter Note..."
          if(this.webSocketConnectedFirstTime){
            console.log("Previous connection lost and this is new connection, again getting key from server and connecting",this.webSocketConnectedFirstTime)
            this.wsProvider.disconnect()
            this.getNoteKeyAndDetails();
          }
          this.quill.enable(true)
          this.webSocketConnectedFirstTime = !this.webSocketConnectedFirstTime
        }
        if(event.status === 'disconnected'){
          
          this.disconnectedRetryCount++;
          if(this.disconnectedRetryCount>3){
             this.wsProvider.disconnect()
          }else{
            this.wsProvider.connect();
          }
          this.quill.enable(false)
        }
        //ToDO: In case of connecting show a message to user that your connection is lost, any changes done may not be saved
         // logs "connected" or "disconnected"
      })
        
        
  }


  saveNoteText(forceSaveNote = false) {
    if(!this.noteKey){
      return
    }

    if(!forceSaveNote){
      /* if((new Date().getTime() - this.lastUpdatedTime)<2*1000){
        return
      } */
      let pauseTime = 2000 // 1sec
      if(!this.selectedNoteId){
        pauseTime = 3000  //3 sec
      }
      if((new Date().getTime() - this.lastInputChangeTime)<pauseTime ){
        this.lastInputChangeTime = new Date().getTime()
        return
      }
    }
    let text = JSON.stringify(this.quill.getContents()?.ops)
    
    this.lastUpdatedTime = new Date().getTime()
    this.lastInputChangeTime = new Date().getTime()
      let inputJson = {
        "note_text": text
      }
      if (this.selectedNoteId && text!=this.selectedNoteText ) {
       // this.noteUpdate(this.selectedNoteId, inputJson);
      }
}
  noteUpdate(noteId, inputJson) {
    this.httpTransfer.updateNote(noteId, inputJson).subscribe((res : any) => {
      if (res.status == 200) {
        this.lastUpdatedTime = new Date().getTime()
        this.updateNotesList.emit(true);
        this.selectedNoteText = inputJson.note_text
        this.updatedNoteText.emit(inputJson.note_text)
      }
    })
  }
  ngOnDestroy(){
    
   // this.saveNoteText(true)
   // this.timerSubscribe.unsubscribe() 
//    this.timer = null
    if(this.wsProvider){
      this.wsProvider.disconnect()
    }
    this.wsProvider?.destroy()
    this.closeOptionsPopover(true)
  }




  // start inser or craete component 
  //insert embed in cursor position 
  insertAngularComponent(json,qlCustom,isSynkData=true,reanderIndex?) {
    const range = reanderIndex ? {index:reanderIndex+1} : this.quill?.getSelection(true);
    if (range) {
      this.quill.deleteText(range.index-1, 1);
      this.quill?.insertEmbed(range.index-1,qlCustom,json,Quill.sources.USER);
      if(!reanderIndex)this.quill.insertText(range.index, ' ', Quill.sources.USER);
      this.quill?.setSelection(range.index+1);
      if(isSynkData) this.createOrInsertComponentInQuill()
    }
  } 

  // create compoent 
  async createComponent(component?) {
    const componentFactory = this.componentFactoryResolver?.resolveComponentFactory(component);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    return componentRef
  }

  // get data by id using quill component
  async itemDataRender(){
    const itemElements =this.noteEditor?.nativeElement?.querySelectorAll('[data-type="item"]');
    const activityItem=this.noteEditor?.nativeElement?.querySelectorAll('[data-type="activity"]');
    const noteElement=this.noteEditor?.nativeElement?.querySelectorAll('[data-type="note"]');
    const userElement=this.noteEditor?.nativeElement?.querySelectorAll('[data-type="user"]');
    const mediaElement=this.noteEditor?.nativeElement?.querySelectorAll('[data-type="media"]');
    const noteMediaElement=this.noteEditor?.nativeElement?.querySelectorAll('[data-type="noteMedia"]');
    let itemIds=[];
    [...itemElements,...activityItem].forEach(item=>{
      let itemId=item.getAttribute('data-id');
      if(itemId && itemId!=undefined && itemId!=null && !itemIds?.includes(itemId))itemIds.push(itemId)
    })
    let mediaList=[]
    mediaElement?.forEach(item=>{
      let itemId=item.getAttribute('data-id');
      if(itemId && itemId!=undefined && itemId!=null && !mediaList?.includes(itemId))mediaList.push(itemId)
    })
    let noteMedia=[]
    noteMediaElement?.forEach(item=>{
      let itemId=item.getAttribute('data-id');
      if(itemId && itemId!=undefined && itemId!=null && !noteMedia?.includes(itemId))noteMedia.push(itemId)
    })
    let noteIds=[]
    noteElement.forEach(item=>{
      let itemId=item.getAttribute('data-id');
      if(itemId && itemId!=undefined && itemId!=null && !noteIds?.includes(itemId))noteIds.push(itemId)
    })
    if(itemIds?.length>0){

      let leadQuery:any =await this.httpTransfer.getLeadQuery({lead_id:itemIds,item_type:['ACTIVITY_COMMENT','ITEM_INTERNAL']}).toPromise();
      leadQuery?.result?.leadResponse?.forEach(item=>this.itemData[item?._id]=item)
    }
    if(noteIds.length>0){
      let leadQuery:any =await this.httpTransfer.getNote({note_id:noteIds}).toPromise();
      leadQuery?.result?.notes?.forEach(item=>this.itemData[item?._id]=item)
    }
    if(mediaList.length>0){
      let leadQuery:any =await this.httpTransfer.fileSystemQuery({id:mediaList}).toPromise();
      leadQuery?.result?.files?.forEach(item=>this.itemData[item?._id]=item);
    }
    if(noteMedia?.length>0){
      let leadQuery:any =await this.httpTransfer.getNoteMedia({note_id:[this.selectedNoteId],note_media_id:noteMedia}).toPromise();
      leadQuery?.result?.note_media?.forEach(item=>this.itemData[item?._id]=item);
    }
    if(userElement?.length>0){
      let userData =await this.dashboardUtils.getOrgUsers();
      Object.keys(userData).forEach(id=>this.itemData[id]=userData[id]);
    }

  }

  // render or append component in quill inser embed
  async createOrInsertComponentInQuill(isSynkData=true) {
    if(isSynkData)await this.itemDataRender()
    setTimeout(() => {
      const elements = this.noteEditor?.nativeElement?.querySelectorAll('[data-component="qlc-component"]');
      elements.forEach(async element => {
        let insertNode=element.nodeName=='SPAN' ? element?.firstElementChild : element ;
        if(!insertNode?.lastElementChild ||insertNode?.lastElementChild?.nodeName!=='QLC-TASK-RENDER'){
          const componentRef = await this.createComponent(QlTaskRenderComponent);
          let dataJson=JSON.parse(element.getAttribute('data-row') || '{}')
          let dataBlockId=element.getAttribute('data-block-id');
          if(this.uploadingMediaInfo[dataBlockId])this.uploadingMediaInfo[dataBlockId].componentRef=componentRef.instance;
          if(this.uploadingMediaInfo[dataBlockId])dataJson.uploading=true;
          componentRef.instance['dataJson']=dataJson
          componentRef.instance['item']=this.itemData[dataJson.id]
          componentRef.instance['onUpdateRenderer']?.subscribe(res=>{
            if(res.updateType=='Remove'){
              let currentIndex = this.getEmbedIndex(dataBlockId);
              if(currentIndex>-1)this.quill.deleteText(currentIndex,1)
              delete this.uploadingMediaInfo[dataBlockId]
            }else if(res.updateType=='Preview'){
              let dataJsonInfo=JSON.parse(element.getAttribute('data-row') || '{}')
              if(!res.showPreview)dataJsonInfo.hidePreview=true;
              else delete dataJsonInfo.hidePreview;
              element.setAttribute('data-row',JSON.stringify(dataJsonInfo))
              element.setAttribute('data-preview',(res.showPreview ? 'show' :'hide'));
            }
            else if(res.updateType='OpenPreview'){
              this.onDataPreview?.emit(res)
            }
          })
          // element.innerHTML = ''; // Clear the content
          const viewContainerRef = insertNode as any; // Type assertion to any
          viewContainerRef?.appendChild(componentRef.location.nativeElement);
        }
      });
    });
  }
  // end inser or craete component 


  // strat handle note media upload and craete embed
  // get index 
  getEmbedIndex(blockId){
    let currentIndex=0
    const delta = this.quill?.getContents();
    for (const op of delta.ops) {
      if (op.insert && op.insert.qlBlockCustomComponent && op.insert?.qlBlockCustomComponent?.dataBlockId==blockId){
        return currentIndex;
      }
      currentIndex += op.insert ? (typeof op.insert === 'string' ? op.insert.length : 1) : 0;
    }
    return -1;
  }

  // upload media url to note and inser or upadte emded
  uploadFileList(data,blockId){
    let fileJson = {};
    data.forEach(fileObj => {
      fileJson['file_name'] = fileObj['file_name'] ? fileObj['file_name'] : fileObj?.name;
      fileJson['file_path'] = fileObj['path'] ? fileObj['path'] : fileObj['file_path'];
      fileJson['image_download_url'] = fileObj['image_download_url'];
      fileJson['extension'] = fileObj?.extension ? fileObj.extension : fileObj['file_path']?.split(".")?.pop();
      fileJson['type'] = 'File';
    })
    this.httpTransfer.uploadNoteMedia(this.selectedNoteId,fileJson)?.subscribe(res=>{
      if(res.status==200){
        let index=this.getEmbedIndex(blockId)
        if(index>-1){
          let json={dataInfo:JSON.stringify({type:'noteMedia',id:res?.result?.id,file_type:null,resizeable:true}),dataBlockId:blockId,component:'qlc-component'}
          this.insertAngularComponent(json,'qlBlockCustomComponent',true,index);
          delete this.uploadingMediaInfo[blockId]
        }
      }
    })
  }

  deleteNoteMedia(delta,oldDelta){
    let deleteEmbed=delta?.ops[delta?.ops?.length-1]?.delete;
    if(deleteEmbed && oldDelta?.ops?.length>0){
      let currentContents = this.quill.getContents();
      let currentBlockList=currentContents?.reduce((list,data)=>{if((typeof data?.insert === 'object') && data?.insert?.qlBlockCustomComponent)list.push(data?.insert?.qlBlockCustomComponent?.dataBlockId);return list},[]);
      oldDelta?.ops?.forEach(data=>{
        if(typeof data?.insert === 'object' && data?.insert?.qlBlockCustomComponent && !currentBlockList?.includes(data?.insert?.qlBlockCustomComponent?.dataBlockId)){
          let dataJson=JSON.parse(data?.insert?.qlBlockCustomComponent?.dataInfo || {});
          if(dataJson.type=='noteMedia' && dataJson?.id){
            this.httpTransfer?.deleteNoteMedia(this.selectedNoteId,dataJson?.id)?.subscribe(res=>{})
          }
        }
      })
    }
  }

  // end handle note media upload and craete embed

  // Start option popover open and close
  // check for popover openning / press or spsce 
  checkPopoverOpenning(delta){
    let insertChar=delta?.ops[delta?.ops?.length-1]?.insert;
    if(insertChar && [this.optionsPopoverInfo.popoverTriggerKey,this.optionsPopoverInfo.mentionTriggerkey]?.includes(insertChar)){
      let range=this.quill.getSelection();
      if(range && range.index != null){
        let textBeforeCursor = this.quill.getText(0, range.index);
        let lines = textBeforeCursor.split('\n');// Check if the cursor is at the start of the line or preceded by space/newline
        let lineBeforeCursor = lines[lines.length - 1];
        let charBeforeCursor = this.quill.getText(range.index - 2,1);  // Get the character right before the cursor
        if (range.index==1 || charBeforeCursor === ' ' || charBeforeCursor === '\n' || lineBeforeCursor.trim() === '') {
          this.optionsPopoverInfo.openKey=insertChar;
          setTimeout(() => {this.openOptionsPopover(range)},1); 
        }else{
          this.closeOptionsPopover(true)
        }
      }
    }else{
      this.closeOptionsPopover(true)
    }
  }

  // open popover and append  to body
  async openOptionsPopover(range) {
    this.closeOptionsPopover(true)
    this.optionsPopoverInfo.isOpen=true
    this.optionsPopoverInfo.popoverBackCover = document.createElement('div') as HTMLElement;
    this.optionsPopoverInfo?.popoverBackCover?.classList?.add('qlc-popover-overlay')
    this.optionsPopoverInfo?.popoverBackCover?.addEventListener('click',this.closeOptionsPopover);
    let popover = document.createElement('div') as HTMLElement;
    popover.classList.add('qlc-options-popover');
    const componentRef = await this.createComponent(QlcNoteOptionsComponent);
    if(this.optionsPopoverInfo.openKey==this.optionsPopoverInfo.mentionTriggerkey){
      componentRef.instance['viewType']='USERS'
    }
    this.optionsPopoverInfo.optionSelectSub=componentRef.instance['optionSelected'].subscribe((option)=>this.onOptionSelect(option));
    popover.appendChild(componentRef.location.nativeElement);
    document.body.appendChild(this.optionsPopoverInfo?.popoverBackCover)
    document.body.appendChild(popover);
    this.optionsPopoverInfo.popoverRef=popover;// Get the cursor's position in the editor
    let position=this.getPopoverPosition(popover,range);// Apply the calculated position to the popover
    popover.style.top = `${position?.top}px`;
    popover.style.left = `${position?.left}px`;
  }

  // get popover position according to cursor
  getPopoverPosition(popover,range){
    if (range && range.index > 0) {
      const bounds = this.quill.getBounds(range.index);
      const editor = this.noteEditor?.nativeElement?.querySelector('.ql-editor') as HTMLElement;  // Get the editor element
      const editorRect = editor.getBoundingClientRect();  // Calculate the editor's offset relative to the body
      const position = {top: bounds.top + editorRect.top,left: bounds.left + editorRect.left};    // Convert editor-relative coordinates to body-relative coordinates
      // Get the window dimensions
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const popoverWidth = popover?.offsetWidth;
      const popoverHeight = 300;// popover?.offsetHeight; hardcode because issue in get height 
      const spaceAbove = position.top;   // Calculate available space
      // Determine final position
      const finalPosition = { top: 0, left: position.left };
      // Place popover above cursor if there's enough space
      if (spaceAbove >= popoverHeight) {
        finalPosition.top = position.top - popoverHeight;
      } else {
        finalPosition.top = position.top + bounds.height; // Not enough space above, so place popover below cursor
        // Ensure popover fits within the screen if placing below
        if (finalPosition.top + popoverHeight > windowHeight) {
          finalPosition.top = windowHeight - popoverHeight;
        }
      }
      // Ensure popover does not go out of window boundaries horizontally
      if (finalPosition.left + popoverWidth > windowWidth) {
        finalPosition.left = windowWidth - popoverWidth;
      }
      if (finalPosition.left < 0) {
        finalPosition.left = 0;
      }
      return finalPosition;
    }
    
  }

  // close options popver 
  closeOptionsPopover=(isManual?)=>{
    let isClosed=false
    if(isManual){
      isClosed=true
    }else{
      const selection = this.quill.getSelection();
      if (selection) {
        const lastTypedChar = this.quill.getText(selection.index - 1, 1); 
        if (lastTypedChar!== this.optionsPopoverInfo?.openKey)isClosed=true
      }
    }
    if(isClosed){
      this.optionsPopoverInfo?.popoverBackCover?.removeEventListener('click',this.closeOptionsPopover);
      this.optionsPopoverInfo?.popoverRef?.remove()
      this.optionsPopoverInfo?.popoverBackCover?.remove()
      this.optionsPopoverInfo.optionSelectSub?.unsubscribe()
      delete this.optionsPopoverInfo.isOpen
      delete this.optionsPopoverInfo?.popoverRef
      delete this.optionsPopoverInfo?.popoverBackCover
      delete this.optionsPopoverInfo.optionSelectSub
    }
  }

  // call on option trigger or selct
  onOptionSelect(option){
    this.closeOptionsPopover(true)
    if(!option){
      return
    }else if(option?.type=='Table'){
      const range = this.quill.getSelection();
      // this.quill.insertEmbed(range.index, 'customTable', { rows:3, cols:3 });
        this.insertTable(3,3)
    }
    else if(option?.type=='Format'){
      let range = this.quill.getSelection(); // Get the selected text range
      if (range){
        this.quill.deleteText(range.index-1, 1);
        option?.formatDetails?.forEach(format=>this.quill.format(format?.formatKey,format.formatValue))
        if(option.removeFormat)this.quill.removeFormat(range.index-2,2);
      }
    }else{
      let dataBlockId=uuidv4();
      let qlCustom=option.resizeable ? 'qlBlockCustomComponent' : 'qlCustomComponent'
      let isSynkData=['item','activity','note','user','media','noteMedia']?.includes(option.type)
      if(option.type=='mediaUploading'){
        this.commonUtils.onUploadFile(option.files,'NOTE',null,this.uploadFileList.bind(this),dataBlockId);
        this.uploadingMediaInfo[dataBlockId]={'state':'Uploading'}
        option.fileName=option.files[0]?.name
        delete option?.files;
        qlCustom='qlBlockCustomComponent'
      }
      this.insertAngularComponent({dataInfo:JSON.stringify(option),component:'qlc-component',dataBlockId:dataBlockId},qlCustom,isSynkData)
    }
  }
  // End option popover open and close

}

