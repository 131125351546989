<div class="chatviewBody" [ngClass]="{'bottomBar' : isChatExpand}">
  <div class="chatViewMainDiv" *ngIf="commentArr?.length && !this.showLoader">
       <!--Left Panel-->
     <div class="chatLeftSideView" [ngStyle]="chatLeftPanelForBottombar ? { 'width': '100%' } : {'width': '35%'}">
         <div class="chatViewLeftHeader" *ngIf="!chatLeftPanelForBottombar"><span>Chats</span>
          <div style="width:40%;"><ng-select placeholder="{{'placeholders.select' | translate}}" [virtualScroll]="true" bindLabel='display_key' bindValue="key"
                        [items]="chatGroupType" [multiple]="true" [closeOnSelect]="true" (close)="groupTypeFilter()"
                        [clearable]="false" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedchatGroupType">
          </ng-select></div>
        </div>
         <div class="chatViewLeftContent" [ngStyle]="chatLeftPanelForBottombar ? { 'height': '100%' } : {'height': 'calc(100% - 51px)'}" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="getCommentsOnScroll()">
             <ng-container *ngFor="let comment of commentArr; let i = index">
               <ng-container *ngIf="comment.type!='EMAIL';else emailView">
                 <div class="row chatBox" [ngClass]="{'selectedchannel' : selectedChannelObj?._id == comment?._id && selectedChannelObj?.chat_user_id == comment.chat_user_id && selectedChannelObj?.channel_id == comment.channel_id}" [ngStyle]="chatLeftPanelForBottombar ? { 'border-left': 'none' } : {'border-left': '5px solid #3571de'}" (click)="restoreScrollPosition=undefined;showChatWindow=true;getSelectedChatUserDetails(comment)">
                   <div class="col-2 avatarBox" (click)="profilePreview(previewModal,comment)"><ngx-avatars class="mr-1" [name]="comment?.chatUserName" [src]="comment?.profile_pic" size="40" initialsSize="2" ></ngx-avatars></div>
                     <div  class="col-10 chatBoxRightContent">
                       <div class="chatLeftTop"><div class="nameBox">{{comment?.chatUserName}}</div><div class="timeBox">{{comment?.deletedTime ? comment?.deletedTime : comment?.receivedTime ? comment?.receivedTime : comment?.sentTime}}</div></div>
                       <div class="chatLeftMid" *ngIf="comment?.message_data?.text && comment?.status!='DELETED'"><div class="chatMsgLeft" [innerHTML]="comment?.message_data?.text"></div></div>
                       <div class="chatLeftMid" *ngIf="comment?.status=='DELETED'"><div class="chatMsgLeft"><span class="deletedMsg"><app-dashboard-icon  [size]="22" [name]="'Ban'" [iconJson]="lxAttributesIcons"></app-dashboard-icon>This message was deleted</span></div></div>
                       <div class="chatLeftMid" *ngIf="comment?.message_data?.attachments?.length">
                           <div class="align" *ngIf="comment?.message_data?.attachments[0]?.file_type=='IMAGE'"><app-dashboard-icon [isSvg]="true" [size]="16" [name]="'SingleImage'" [iconJson]="lxAttributesIcons" [color]="'#666666'"></app-dashboard-icon><span>Image</span></div>
                           <div class="align" *ngIf="comment?.message_data?.attachments[0]?.file_type=='VIDEO'"><app-dashboard-icon [isSvg]="true" [size]="16" [name]="'SingleVideo'" [iconJson]="lxAttributesIcons" [color]="'#666666'"></app-dashboard-icon><span>Video</span></div>
                           <div class="align" *ngIf="comment?.message_data?.attachments[0]?.file_type!='IMAGE' && comment?.message_data?.attachments[0]?.file_type!='VIDEO'"><app-dashboard-icon [isSvg]="true" [size]="16" [name]="'SingleFile'" [iconJson]="lxAttributesIcons" [color]="'#666666'"></app-dashboard-icon><span>Document</span></div>
                       </div>
                       <div class="chatLeftBottom">
                           <div class="msgBox" *ngIf="comment?.channelType"><span><app-dashboard-icon [size]="18" [name]="comment?.channelType" [iconJson]="attributesIcons"></app-dashboard-icon></span>{{comment?.channelName}}</div>
                            <div class="countIcon" *ngIf="comment?.unreadCount>0 && selectedChannelObj?._id!==comment?._id"><!-- <span class="replyIcon"><app-dashboard-icon [isSvg]="true" [size]="22" [name]="'Reply'" [iconJson]="lxAttributesIcons" [color]="'#666666'"></app-dashboard-icon></span> --><span class="countMsg">{{comment?.unreadCount}}</span></div>
                        </div>
                   </div>
               </div>
               </ng-container>
                 <!--email-->
                 <ng-template #emailView>
                   <div class="row chatBox" [ngClass]="{'selectedchannel' : selectedChannelObj?._id == comment?._id} " (click)="scrollPosition=0;showChatWindow=false;selectedChannelObj = comment;getLeadForChat(comment)">
                     <app-email [dashId]="selectedDashId" [fromChatUserEmailConnections]="userEmailConnections" [communicationCall]="true" [comment]="comment"  style="padding: 0 ;" (emitDataForThread)="updateFromEmailComponent($event)" [leadInfo]="emailLeadInfoMap.get(comment.lead_id)" [immediate]="i==0"></app-email>
                   </div>
                 </ng-template>
             </ng-container>
         </div>
     </div>
      <!--Right Panel-->
      <!-- <div *ngIf="!chatLeftPanelForBottombar"> -->
      <div class="chatRightSideView" *ngIf="selectedChannelObj.type !== 'EMAIL' && !chatLeftPanelForBottombar;else emailThreadView">
        <div class="chatViewRightHeader" *ngIf="showChatWindow">
           <div class="headerRightBar">
             <div class="col-2 avatarBox cursorPointer" (click)="profilePreview(previewModal,selectedChannelObj)"><ngx-avatars class="mr-1" [name]="selectedChannelObj?.chatUserName" [src]="selectedChannelObj?.profile_pic" size="30" initialsSize="2" ></ngx-avatars></div>
             <div class="col-10 chatBoxRightUser">
                 <div class="chatRightTop"><div class="nameBox">{{selectedChannelObj?.chatUserName}}</div></div>
                 <div class="chatLeftMid"> <div class="msgBox" *ngIf="selectedChannelObj?.channelType"><span><app-dashboard-icon [size]="18" [name]="selectedChannelObj?.channelType" [iconJson]="attributesIcons"></app-dashboard-icon></span>{{selectedChannelObj?.channelName}}</div></div>
             </div>
           </div>
           <div class="chatRightheaderDetails">
            <div class="searchBar mx-0"> 
                 <app-dashboard-icon style="height:20px;" [isSvg]="true" [size]="20" [name]="'Search'" [iconJson]="lxAttributesIcons" [color]="'#6d6e6f'"></app-dashboard-icon>
                 <input type="search" style="border:none;width:100%;" (input)="filteChatMessages()"  [(ngModel)]="searchText">
             </div> 
             <div class="itemNo"*ngIf="!isChatExpand" (click)="itemPreviewData=[{itemId:selectedLeadObj._id,boardId:selectedLeadObj?.dashboard_id}]">#{{selectedLeadObj?.seq_id}}</div>
             <div style="height:24px;">
                 <span class="userAssigneeBox" role="button" placement="auto" (click)="$event.stopPropagation(); getPopOverRef(popOverAssignee)"  #popOverAssignee="ngbPopover" container="body" [autoClose]="'outside'"
                 [ngbPopover]="assignUserTemplate">
                 <span class="userAssignee" >
                     <ngx-avatars  [name]="orgUsersJson[selectedLeadObj?.assigned_to]?.name || 'Assign'" size="20" initialsSize="2" textSizeRatio="2.2"></ngx-avatars>
                     <span>{{orgUsersJson[selectedLeadObj?.assigned_to]?.name ? orgUsersJson[selectedLeadObj?.assigned_to]?.name : 'Assign'}}</span>
                </span>
                </span>
                <ng-template #assignUserTemplate>
                 <app-lead-assign-user
                 [assignedUser]="selectedLeadObj?.assigned_to"
                 [dashId]="selectedDashId"
                 [leadInfo]="[selectedLeadObj]"
                 [bulkSelectionFlag]="false"
                 (updateLead)="updateLeadModalView($event.leadId,$event.bucketId,leadUpdateConstant);currentPopoverRef.close()"
                 ></app-lead-assign-user>
             </ng-template>
             </div>
           </div>
        </div>
        
        <div *ngIf="!showChatWindow" class="chatViewRightHeader">
         <app-shimmer-loader class="column2" style="margin-left:28px;" [rowGap]="'10'" [type]="'Avatar'" [lineWidth]="['100px','80px']" [height]="10" [count]="1" [columnGap]="0" [circleWidth]="30" [externalStyle]="{'padding':'0px'}">
         </app-shimmer-loader>
       </div> 
        <div class="chatRightMsgDiv" *ngIf="showChatWindow && selectedChatUserMsgarr?.length">
            <app-chat-messages [chatMsgcurrentPage]=chatMsgcurrentPage [chatMsgTotal_Page]="chatMsgTotal_Page" [disableEditor]="disableEditor" [dashId]="selectedDashId" [selectedChatObj]="selectedChannelObj" [selectedChatUserMsgarr]="selectedChatUserMsgarr" [selectedLeadObj]="selectedLeadObj" (getChatMsg)="getChatMsgOnScroll($event)" [restoreScrollPosition]="restoreScrollPosition"></app-chat-messages>
         </div>
 
 
 
         <div class="chatRightMsgDiv" *ngIf="!showChatWindow">
             <app-shimmer-loader class="shimmerLoader" [type]="'CardField'" [cardCount]="7" [width]="'80%'" [height]="'50'" [radius]="10" [header]="false"
             [columnGap]="15">
             </app-shimmer-loader>   
         </div>
      </div>
     <!-- </div> -->

      <ng-template #emailThreadView >
         <div class="chatRightSideView" *ngIf="emailThreadData">
           <div class="chatViewRightHeader">
            <div class="headerRightBar" >
              <div *ngIf="emailThreadData?.selectedEmailThreadViewDisplay[0][0]?.email_object?.from?.name" class="avatarBox cursorPointer"><ngx-avatars class="mr-1" [name]="emailThreadData?.selectedEmailThreadViewDisplay[0][0]?.email_object?.from?.name" size="40" initialsSize="2" ></ngx-avatars></div>
              <div *ngIf="!emailThreadData?.selectedEmailThreadViewDisplay[0][0]?.email_object?.from?.name" class="avatarBox" role="button"><ngx-avatars class="mr-1" [name]="'Unknown'" size="40" initialsSize="2" ></ngx-avatars></div>
              <!-- <div class="col-2 avatarBox cursorPointer"><app-dashboard-icon [size]="36"  [name]="'SettingEmail'" [iconJson]="attributesIcons"></app-dashboard-icon></div> -->
               <div class="nameBox" >
                  {{emailThreadData?.selectedEmailThreadViewDisplay[0][0]?.email_object?.subject | truncate:70}}
                <!-- <div class="chatLeftMid ml-3"> <div class="msgBox"><span><app-dashboard-icon [size]="18" [name]="" [iconJson]="AttributesLxIcons"></app-dashboard-icon></span>Email</div></div> -->
               </div>
             </div>
             <div class="chatRightheaderDetails">
               <div class="itemNo" *ngIf="!isChatExpand" (click)="itemPreviewData=[{itemId:selectedLeadObj._id,boardId:selectedLeadObj?.dashboard_id}]">#{{selectedLeadObj?.seq_id}}</div>
               <div style="height:24px;">
                   <span class="userAssigneeBox" role="button" placement="auto" (click)="$event.stopPropagation(); getPopOverRef(popOverAssignee)"  #popOverAssignee="ngbPopover" container="body" [autoClose]="'outside'"
                   [ngbPopover]="assignUserTemplate">
                   <span class="userAssignee" >
                       <ngx-avatars  [name]="orgUsersJson[selectedLeadObj?.assigned_to]?.name || 'Assign'" size="20" initialsSize="2" textSizeRatio="2.2"></ngx-avatars>
                       <span>{{orgUsersJson[selectedLeadObj?.assigned_to]?.name ? orgUsersJson[selectedLeadObj?.assigned_to]?.name : 'Assign'}}</span>
                  </span>
                  </span>
                  <ng-template #assignUserTemplate>
                   <app-lead-assign-user
                   [assignedUser]="selectedLeadObj?.assigned_to"
                   [dashId]="selectedDashId"
                   [leadInfo]="[selectedLeadObj]"
                   [bulkSelectionFlag]="false"
                   (updateLead)="updateLeadModalView($event.leadId,$event.bucketId,leadUpdateConstant);currentPopoverRef.close()"
                   ></app-lead-assign-user>
               </ng-template>
               </div>
             </div>
          </div>
           <app-expand-thread-view [selectedEmailThreadViewDisplay]="emailThreadData.selectedEmailThreadViewDisplay" [userEmailsConnectedWithAliases]="emailThreadData.userEmailsConnectedWithAliases" [selectedEmailObj]="emailThreadData.selectedEmailObj" [dashId]="selectedDashId" [leadInfo]="selectedLeadObj" [sprintArr]="emailThreadData.sprintArr" [isChatExpand]="isChatExpand"></app-expand-thread-view>
         </div>
      </ng-template>
  </div>
 
  <div class="chatViewMainDiv noChat" *ngIf="!commentArr?.length && !this.showLoader">
     <div>No Chats Available</div>
  </div>
 
  <!--Loading-->
  <div class="chatViewMainDiv noChat" *ngIf="this.showLoader">
     <div class="chatLeftSideView"  [ngClass]="{'w-100' : chatLeftPanelForBottombar}">
             <app-shimmer-loader [count]="chatLeftPanelForBottombar ? 2 : 5" [width]="'100%'" [height]="86" [type]="'Table'" [header]="false"></app-shimmer-loader>
     </div>
     <div class="chatRightSideView" *ngIf="!chatLeftPanelForBottombar">
     <div class="chatViewRightHeader">
         <app-shimmer-loader class="column2" style="margin-left:28px;" [rowGap]="'10'" [type]="'Avatar'" [lineWidth]="['100px','80px']" [height]="10" [count]="1" [columnGap]="0" [circleWidth]="30" [externalStyle]="{'padding':'0px'}">
         </app-shimmer-loader>
       </div> 
       <div class="chatRightMsgDiv">
         <app-shimmer-loader class="shimmerLoader" [type]="'CardField'" [cardCount]="6" [width]="'80%'" [height]="'50'" [radius]="10" [header]="false"
         [columnGap]="15">
         </app-shimmer-loader>   
     </div>
    </div>
  </div>
 </div>

 <ng-container *ngIf="itemPreviewData?.length && selectedLeadObj">
  <item-view [itemData]="itemPreviewData" [currentBoardId]="selectedDashId" [currentViewId]="currentViewId" (closeItemView)="itemPreviewData=[]" [class]="'item-side-view'"></item-view>
</ng-container>
 
 
 <!--Profile Preview-->
 <ng-template #previewModal let-modal>
     <div class="previewBodyModal" style="height: 100%;max-width: 100%;">
       <i class="fa fa-close bg-white previewCross" (click)="modal.dismiss()"></i>
       <div class="w-100" style="height:100%;">
         <div class="custom-image-main">
             <img [src]="chatUserProfileUrl" class="image-in-modal" style="height:100%;width:60%;"/>
           </div>
       </div>
     </div>
   </ng-template>