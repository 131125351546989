import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "minutesPipe",
})
export class MinutesPipePipe implements PipeTransform {
  transform(d: any): string {
    var day;
    d = Math.abs(d);
    if(d > 3600 * 24) day = Math.floor(Math.floor(d / 3600) / 24);
    if (day > 0) {
      var hour = Math.floor((d / 3600) % 24);
      var min = Math.floor((d % 3600) / 60);
      var second = Math.floor((d % 3600) % 60);
    } else {
      var hour = Math.floor(d / 3600);
      var min = Math.floor((d % 3600) / 60);
      var second = Math.floor((d % 3600) % 60);
    }

    var hDisplay = hour > 0 ? hour + (hour == 1 ? " hr " : " hrs ") : "";
    var mDisplay = min > 0 ? min + (min == 1 ? " min " : " mins ") : "";
    var sDisplay =
      second > 0 ? second + (second == 1 ? " sec " : " secs ") : "";
    var dDisplay = day > 0 ? day + (day == 1 ? " day " : " days :") : "";

    if (day > 0) {
      return dDisplay + hDisplay;
    } else {
      return dDisplay + hDisplay + mDisplay;
    }
  }
}
