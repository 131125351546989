export const Icons ={
   briefcase: 'assets/boards-random-icon-20dp/briefcase-6d6e6f.svg',
   dart: 'assets/boards-random-icon-20dp/target-6d6e6f.svg',
   flag1: 'assets/boards-random-icon-20dp/flag.svg',
   idea1: 'assets/boards-random-icon-20dp/idea.svg',
   material: 'assets/boards-random-icon-20dp/material.svg',
   rectangle: 'assets/boards-random-icon-20dp/wifi.svg',
   rocket1: 'assets/boards-random-icon-20dp/rocket.svg',
   target: 'assets/boards-random-icon-20dp/target-6d6e6f.svg',
   speaker: 'assets/boards-random-icon-20dp/speakerphone.svg',
   report: 'assets/boards-random-icon-20dp/report.svg',
   piechart: 'assets/boards-random-icon-20dp/chart.svg',
   hr: 'assets/boards-random-icon-20dp/cast.svg',
   connected: 'assets/boards-random-icon-20dp/dashboard.svg',
   calendar: 'assets/boards-random-icon-20dp/calendar.svg',
   bug: 'assets/boards-random-icon-20dp/bug.svg',
   agile: 'assets/boards-random-icon-20dp/loader.svg',
   folder:'assets/boards-random-icon-20dp/folder.svg'
   
}