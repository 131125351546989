import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConstantService } from '../../../../Enums/Constant.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';

@Component({
  selector: 'app-checklist-setting',
  templateUrl: './checklist-setting.component.html',
  styleUrls: ['./checklist-setting.component.scss']
})
export class ChecklistSettingComponent implements OnInit {
  @Input() item :any
  @Input() isReadOnly:boolean=false
  @Input() isPreview:boolean=false
  @Input() gridCall:boolean=false
  @Input() dashId:string
  @Input() leadId:string
  @Input() view:string
  @Output() onUpdateList=new EventEmitter<any>();
  checkListState: { NOT_COMPLETED: string; COMPLETED: string };
  custObj = {};
  checklistList = []
  openChecklist = false
  checklistSummeryArr=[]
  constructor(private httpTransfer:HttpTransferService, private constantService: ConstantService) { 
    this.checkListState = this.constantService.getChecklistStateConstant();
  }

  ngOnInit(): void {
    console.log('checklist is ', this.item)
    if(!this.item.checklistInput){
      this.item.checklistInput = []
      this.openChecklist=true
    }
    else if(typeof(this.item.checklistInput) == 'string'){
      this.item.checklistInput = [{summary:this.item.checklistInput, state: this.checkListState.NOT_COMPLETED}]
    }
   this.checklistList = this.item.checklistInput;
    this.checklistList.forEach(e=>{
      this.checklistSummeryArr.push(e.summary)
    })
  }


  addCheckListItem(field) {
    let checkListObj = {};
    field.checkListText = field.checkListText.trim()
    if (field.checkListText != '') {
      field.checkListText = field.checkListText.replace(/\n+$/, '');
      console.log(this.checklistSummeryArr)
      if(!this.checklistSummeryArr.includes(field.checkListText)){
        checkListObj["summary"] = field.checkListText;
        checkListObj["state"] = this.checkListState.NOT_COMPLETED;
        this.checklistList.push(checkListObj)
        this.updateChildCustomFields(true,checkListObj)
        this.checklistSummeryArr.push(field.checkListText)
        console.log(this.checklistSummeryArr)
        field.checkListText = '';
        // field.checkListDuplicate=false
        delete field?.checkListDuplicate
      }
      else{
        field.checkListDuplicate=true
      }
    }
  }

  updateState(selectedIndex, field) {
    this.checklistList.forEach((item, index) => {
      if (selectedIndex == index) {
        if (item.state == this.checkListState.COMPLETED) {
          item["state"] = this.checkListState.NOT_COMPLETED;
        } else {
          item["state"] = this.checkListState.COMPLETED;
        }
      }
    });
    this.updateChildCustomFields()
  }

  deleteCheckItem(selectedIndex) {
    this.checklistList?.splice(selectedIndex, 1);
    this.checklistSummeryArr?.splice(selectedIndex, 1);
    this.updateChildCustomFields()
  }

  editCheckItem(index, field) {
      field['editIndex'] = index;
      field['isEdit'] = true;
  }

  updateCheckListEdit(item,index) {
    item.summary = item.summary.trim()
    item.summary = item.summary.replace(/\n+$/, '');
    if(item.summary.length){
      let summeryIndex=this.checklistSummeryArr.indexOf(item.summary)
      if(summeryIndex==-1 || summeryIndex==index){
        this.item.isEdit = false;
        delete item?.checkListDuplicate
        this.updateChildCustomFields()
      }
      else{
        item.checkListDuplicate=true
      }
    }
  }

  updateChildCustomFields(isNew?,obj?) {
    this.item.checklistInput=this.checklistList;
    // this.onUpdateList.emit(this.checklistList)
    if(this.leadId){
      let key = this.item.key
      var custObj = {};
      custObj[key]=this.checklistList
      var custom_fields = [];
      custom_fields.push(custObj);
      let leadObj = {};


      if(isNew && obj){
        leadObj["item_updates"]=[
          {
              "field_key": "custom_fields."+Object.keys(custObj)[0],
              "update_type":  "PREPEND",
              "value": [obj]
          }
        ]
      }
      else{
        leadObj["custom_fields"] = custObj;
      }


      this.httpTransfer
        .updateLeadCorrespondingToLeadId(leadObj, this.dashId, this.leadId)
        .subscribe((res : any) => {
          if (res.status === 200) {
            console.log(res)
          }
        });
    }
  }

  trackByFn(index,item){
    return index
  }
}
