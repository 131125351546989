import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonUtils } from "app/services/CommonUtils.service";
import { DashboardUtilsService } from "app/services/dashboard-utils.service";

@Component({
    selector: 'lead-columns-picker',
    templateUrl: './lead-columns-picker.html',
    styleUrls: ['./lead-columns-picker.scss']
  })
  
  export class LeadColumnPickerComponent implements OnInit {
    @Output() columnValueemit  : EventEmitter<any> = new EventEmitter();
    @Output() closeEmit  : EventEmitter<any> = new EventEmitter();
    @Output() mediaEmitForColumns : EventEmitter<any> = new EventEmitter();
    @Input() dashId:any;
    @Input() leadObj:any;
    @Input() mediaDisable:boolean=false;
    arrOfCustomField:any[]=[];
    columnFetchOptionEnable: boolean = false;
    algorithm :string=null;
    selectedFieldForFetch: {};
    mirrorColumnJson: {};
    selectedMirrorObj: any;
    mediaType : any[]=['SingleFile','SingleAudio','SingleImage','SingleVideo','MultipleImage','MultipleVideo','MultipleFile','MultipleAudio']
    constructor(private dashboardUtils : DashboardUtilsService,private commonUtils : CommonUtils){

    }

   async ngOnInit(){
    if(this.dashId){
        let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
        this.arrOfCustomField = JSON.parse(JSON.stringify(dashboardJson[this.dashId]['CUSTOM_FORM']));
      }
      console.log(this.arrOfCustomField)
      if(this.mediaDisable){
        this.arrOfCustomField = this.arrOfCustomField.filter(m=>!this.mediaType.includes(m?.type) && !this.mediaType.includes(m?.mirror_column_type))
      }
     this.mirrorColumnJson = await this.commonUtils.getConnectedLeadMirrorJson(this.leadObj,this.arrOfCustomField)
      console.log(this.mirrorColumnJson)
    }
    


   async getColumnsValue(columnsKey,custom=false){
    console.log(this.selectedMirrorObj)
    if(!this.selectedMirrorObj){
    let textToInsert = !custom ? await this.commonUtils.checkforNonCustomValue(columnsKey ,this.leadObj) :
    await this.commonUtils.checkForCustomFieldValueOnLead(columnsKey ,this.leadObj,this.algorithm)
    if(custom && ['SingleFile','SingleAudio','SingleImage','SingleVideo','MultipleImage','MultipleVideo','MultipleFile','MultipleAudio'].includes(columnsKey?.type)){
        let arr = this.commonUtils.trueTypeOf(textToInsert)=="ARRAY" ? textToInsert : [textToInsert]
        if(arr?.length){
            let mediaObj={attachment : arr}
            this.mediaEmitForColumns.emit(mediaObj)    
        }
    }
    else{
        this.columnValueemit.emit(textToInsert) 
    }
    this.closeEmit.emit()
    this.columnFetchOptionEnable = false;
    this.selectedMirrorObj = {}
    this.algorithm = null 
    }
    else{
        this.getMirrorColumnValue()
    }
  }

  getColumnsCheckForFetchOptions(customObj,custom=false){
    let multiTypeArr = ['Checkbox','MultiUser','MultiSelect','MultiUrl','CheckList','MultipleImage','MultipleVideo','MultipleFile','MultipleAudio','Mirror','MultipleMobile','MultipleEmail']
    if(multiTypeArr.includes(customObj?.type)){
       this.columnFetchOptionEnable = true
       this.selectedFieldForFetch = customObj
    }
    else{
      this.getColumnsValue(customObj,custom)
    }
  }

  async getMirrorColumnValue(){
    let mediaArr= []
     let mirrorItemIds = this.leadObj['custom_fields'][this.selectedMirrorObj?.key] ? this.leadObj['custom_fields'][this.selectedMirrorObj?.key]['mirror_item_id'] : this.leadObj['custom_fields'][this.selectedMirrorObj?.connected_board_key] ? this.leadObj['custom_fields'][this.selectedMirrorObj?.connected_board_key] : []
     if(mirrorItemIds?.length && this.algorithm!==null){
        let leadObj = []
        if(['FETCH_FIRST','FETCH_LAST'].includes(this.algorithm)){
            leadObj = this.algorithm==='FETCH_FIRST' ? [this.mirrorColumnJson[mirrorItemIds[0]]] : [this.mirrorColumnJson[mirrorItemIds[mirrorItemIds?.length -1]]] 
        }
        else{
            mirrorItemIds.forEach(m=>{
                leadObj.push(this.mirrorColumnJson[m])
            })
        }
        if(leadObj?.length){
            const results = await Promise.all(leadObj.map(async leadData => {
                let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(leadData?.dashboard_id)
                let customFieldArr = JSON.parse(JSON.stringify(dashboardJson[leadData?.dashboard_id]['CUSTOM_FORM']));
                let  customObj = customFieldArr.filter(m=>m.key == this.selectedMirrorObj?.mirror_column_key)
                let textToInsert = customObj?.length ? await this.commonUtils.checkForCustomFieldValueOnLead(customObj[0],leadData,this.algorithm)
                 : await this.commonUtils.checkforNonCustomValue(this.selectedMirrorObj?.mirror_column_key ,leadData)
                 if(customObj?.length && ['SingleFile','SingleAudio','SingleImage','SingleVideo','MultipleImage','MultipleVideo','MultipleFile','MultipleAudio'].includes(customObj[0]?.type)){
                    console.log(textToInsert)
                    let arr = textToInsert?.length ?  textToInsert : []
                    console.log(arr)
                    if(arr?.length){
                        mediaArr = mediaArr.concat(arr)
                    }
                }
                this.columnFetchOptionEnable = false;
                this.selectedMirrorObj = {}
                this.algorithm = null
                return  textToInsert || null;
            }))
            // Remove null values before joining
            if(mediaArr?.length){
                this.mediaEmitForColumns.emit({attachment : mediaArr})    
            }
            let value = results.filter(result => result !== null).join(" ");
            this.columnValueemit.emit(value) 
        }
    } 
    this.closeEmit.emit()
  }
  }