import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { ConstantService } from 'Enums/Constant.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AttributesIcons } from 'Enums/attributes-icons';
import * as mdb from 'mime-db'
import { KeyboardMonitorService } from 'app/services/keyboardMonitor.service';
import { BasicUtils } from 'app/services/basicUtils.service';

declare var gapi: any;
declare var google: any;
declare var OneDrive: any


@Component({
  selector: 'app-drive-attachment',
  templateUrl: './drive-attachment.component.html',
  styleUrls: ['./drive-attachment.component.scss']
})
export class DriveAttachmentComponent implements OnInit {

  private SCOPES = 'https://www.googleapis.com/auth/drive.metadata.readonly';
  private CLIENT_ID = '70652112951-8a6uce76giuqvvdvro24390j9ph7c1e7.apps.googleusercontent.com';
  private API_KEY = 'AIzaSyCq2fbrP8DLsBTue0SCjh3QdIOOz5jw03E';
  private APP_ID = 'pronnel-375112';

  private tokenClient: any;
  private accessToken: string | null = null;
  private pickerInited = false;
  private gisInited = false;
  @ViewChild('container', { static: false }) container!: ElementRef;
  attributesIcons = AttributesIcons;
  url: string = '';
  urlName: string = '';
  templateOptionSelect:boolean=false;
  fileSelection:string='';
  formatList = {
    FILE: [
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/csv",
        "application/zip",
        "application/x-rar-compressed",
        "application/x-7z-compressed",
        "application/vnd.oasis.opendocument.text",
        "application/vnd.google-apps.document",
        "application/vnd.google-apps.spreadsheet",
        "application/odt"
    ],
    IMAGE: ['image/*'],
    AUDIO: ['audio/mpeg','audio/x-m4a', 'audio/wav', 'audio/aac', 'audio/flac', 'audio/ogg', 'audio/aiff', 'audio/x-ms-wma', 'audio/mp4', 'audio/alac', 'audio/opus', 'audio/ac3', 'audio/dsd', 'audio/amr', 'audio/midi', 'audio/basic', 'audio/x-ape', 'audio/x-matroska', 'audio/L24', 'audio/mpeg', 'audio/x-wavpack'],
    VIDEO: ["video/*"],
    DRIVE: [ "application/pdf","application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document","text/csv","application/zip",
    "application/x-rar-compressed","application/x-7z-compressed","application/vnd.oasis.opendocument.text","application/vnd.google-apps.document","application/vnd.google-apps.spreadsheet","application/odt",'image/*','audio/mpeg','audio/x-m4a', 'audio/wav', 'audio/aac', 'audio/flac', 'audio/ogg', 'audio/aiff', 'audio/x-ms-wma', 'audio/mp4', 'audio/alac', 'audio/opus', 'audio/ac3', 'audio/dsd', 'audio/amr', 'audio/midi', 'audio/basic', 'audio/x-ape', 'audio/x-matroska', 'audio/L24', 'audio/mpeg', 'audio/x-wavpack',"video/*"]
  }

  allowedExt: string[] = [];
  variablescriptElement = [];
  scriptElement = [];
  urlFetch:string=''

  @Input() singleFile = false;
  @Input() fileType = undefined;
  @Input() singleSelection:boolean=false;
  @Input() typeOfDoc:string='';
  @Input() leadInfo:any={};
  @Input() dashId:string='';
  @Input() sprintArr:any=[];
  @Input() orgUsersJson:any=[];

  @Output() filesJson : EventEmitter<any> = new EventEmitter();
  // @Output() close = new EventEmitter<boolean>();

  @ViewChild('fileUploadPopover') fileUploadPopover: NgbPopover
  selectedIndex: number=0;
  
  constructor(
    private constantService :ConstantService,
    private basicUtils:BasicUtils,
    private modal:NgbModal,private renderer: Renderer2,
    private el: ElementRef){
  }

  public open = () =>{
    this.fileUploadPopover.open();
    this.templateOptionSelect=false;
  }

  public close = () => {
    this.fileUploadPopover.close();
  }

  keyboardAccess(access=false){
    if(access){ 
      document.addEventListener('keydown',this.onKeyDown)
    }else{
      document.removeEventListener('keydown',this.onKeyDown)

    }
    
  }

  public isOpen = () => {
    return this.fileUploadPopover.isOpen();
  }

  async ngOnInit() {
    await this.loadScript();
    this.gisLoaded();
    this.templateOptionSelect=false
    // .split('/')[2]
    this.urlFetch=document.baseURI
    console.log("base uri",this.urlFetch)
    gapi.load('client:picker', this.initializePicker.bind(this));
    this.formatList[this.typeOfDoc]?.forEach(mime=>{
      if(mime.includes('*')){
        let bareType = mime.split('*')[0]
        Object.keys(mdb).forEach(key=>{
          if(key.startsWith(bareType)){
            mdb[key]?.extensions?.forEach(ext=>{
              this.allowedExt.push(ext.toLowerCase());
            })
          }
        })
      }
      else{
        mdb[mime]?.extensions?.forEach(ext=>{
          this.allowedExt.push(ext.toLowerCase());
        })
      }
    })
  }



  toPromiseScript (link:string){
    return new Promise((res, rej)=>{
      const scriptElement = this.renderer.createElement('script');
      scriptElement.src = link;
      scriptElement.onload = () => {
        res(true)
      }
      scriptElement.onerror = ()=>{
        rej(false)
      }
      this.renderer.appendChild(this.el.nativeElement, scriptElement);
      this.scriptElement.push(scriptElement);
    })

  }

  private async loadScript() {
    const links = ["https://apis.google.com/js/api.js", "https://accounts.google.com/gsi/client"]
    await Promise.all(links.map(async link=>await this.toPromiseScript(link)))
  }

  ngOnDestroy(){
    if(this.scriptElement?.length){
      this.scriptElement.forEach(ele=>this.renderer.removeChild(this.el.nativeElement, ele))
    }
  }

  emitSelectedFiles(data, isFile?){
    const json = {}
    if(isFile){
      json['files'] = data;
      json['type']=isFile
    }
    this.filesJson.emit(json);
    console.log(json)
  }

  //!Google picker
  async initializePicker() {
    await gapi.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
    this.pickerInited = true;
  }
  
  async gisLoaded() {
    this.tokenClient = await google.accounts.oauth2.initTokenClient({
      client_id: this.CLIENT_ID,
      scope: this.SCOPES,
      callback: '', // defined later
    });
    this.gisInited = true;
  }
  
  async handleAuthClick() {
    this.tokenClient.callback = async (response: any) => {
      console.log(response, gapi)
      if (response.error !== undefined) {
        throw (response);
      }
      this.accessToken = response.access_token;
      console.log(google);
      await this.createPicker();
    };

    if (this.accessToken === null) {
      this.tokenClient.requestAccessToken({prompt: 'consent'});
    } else {
      this.tokenClient.requestAccessToken({prompt: ''});
    }
  }


  googlePickerFilterTypes(picker){
    picker = picker
    .addView(new google.picker.DocsView().setIncludeFolders(true))
    return picker;
  }

  createPicker() {   
    let pickerHolder = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .setDeveloperKey(this.API_KEY)
        .setAppId(this.APP_ID)
        .setOAuthToken(this.accessToken);
    if(!this.singleSelection) pickerHolder = pickerHolder.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
    pickerHolder = this.googlePickerFilterTypes(pickerHolder)
        .addView(new google.picker.DocsUploadView())
        .setCallback(this.pickerCallback)
        .build();
    pickerHolder.setVisible(true);
  }

  pickerCallback =async (data: any) => {
    if (data.action === "picked") {
      const document = data[google.picker.Response.DOCUMENTS]
      const filesList = []
     await document?.forEach(ele=>{
        let ext = ele.name?.split('.').pop()
        if(this.allowedExt.includes(ext.toLowerCase()) || ele.name===ext){
          filesList.push({path: ele.url, name: ele.name,type:'google_drive',extension:ele.name===ext?'document':ele?.name.split(".").pop(),size_bytes:ele?.sizeBytes,image_download_url:ele.url})
        }
      })
      if(filesList.length)
        this.emitSelectedFiles(filesList,'google_drive')
    }
  }

  //!One drice options
  oneDrive = () => {
    this.launchOneDrivePicker()
        .then((result: any) => {
            if (result) {
              const filesList = []
              result?.value?.forEach(ele=>{
                console.log("selected values",ele)
                let ext = ele.name?.split('.').pop()
                if(this.allowedExt.includes(ext.toLowerCase()) || ele.name===ext){
                  filesList.push({path: ele['webUrl'], name: ele.name,type:'one_drive',extension:ele.name===ext?'document':ele?.name.split(".").pop(),size_bytes:ele?.size,image_download_url:ele["@microsoft.graph.downloadUrl"]})
                }
              })
              if(filesList.length)
                this.emitSelectedFiles(filesList,'one_drive')
            }
        }).catch(reason => {
            console.error(reason);
        });
  }

  launchOneDrivePicker() {
    return new Promise((resolve, reject) => {
        var odOptions = {
            clientId: this.constantService.constant.CONNECT_EMAIL_MICROSOFT_CLIENT_ID,
            action: "download",
            multiSelect: !this.singleSelection,
            scopes: 'files.read,files.write,offline_access',
            // openInNewWindow: true,
            advanced: {
                filter: this.allowedExt.map(e=>`.${e}`).toString(),
                // redirectUri: "http://localhost:4200/profile/user",
                // redirectUri:"/profile/user",
                redirectUri:this.urlFetch+'profile/email-settings',
            },
            success: function (files) { resolve(files); console.log(files)},
            cancel: function () { resolve(null); },
            error: function (e) { reject(e); }
        };
        console.log("options",this.urlFetch+'profile/email-settings')
        OneDrive.open(odOptions);
    });
  }


  //!For pasting the url
  pasteUrlForMediaUpload(modalcontent) {
    this.close()
    this.url='';
    this.urlName='';
    let modal=this.modal.open(modalcontent, { centered: false, scrollable: true, windowClass: 'add-link-popup drive-popup,modal-position-top' });
    this.basicUtils.storeModlRef(modal)
    // this.close.emit()
  }

  uploadUrlLink(){
    if(this.url!='' && this.urlName!=''){
      let obj={
        'type':'link',
        'extension':'link',
        'path':this.url,
        'name':this.urlName
          }
      this.emitSelectedFiles([obj],'link')
    }
  }

  onKeyDown = (event: any): void => {
    event.preventDefault()
    switch (event.key) {
      case 'Tab':
        break;
      case 'ArrowUp':
        if (this.selectedIndex >0 && !this.templateOptionSelect) {
          this.selectedIndex--;
        }
        break;
      case 'ArrowDown':
        if (this.selectedIndex < 4 && !this.templateOptionSelect) {
          this.selectedIndex++;
        }
        break;
      case 'Enter':
        let focusElement=document.getElementById('media-upload').querySelector('.key-active') as HTMLElement
        if(focusElement){
          focusElement?.click()
        }
        break;
    }
  }
}