import { Component, OnInit, Output,EventEmitter, Input, HostListener, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { co } from '@fullcalendar/core/internal-common';
@Component({
  selector: 'app-number-as-duration',
  templateUrl: './number-as-duration.component.html',
  styleUrls: ['./number-as-duration.component.scss']
})
export class NumberAsDurationComponent implements OnInit {

  @Input() value:any
  @Input() name:any=''
  @Input() readonly:boolean=false
  valueString:any=''
  showSuggestion:boolean=false
  suggestionList:any[]=[]
  @Output() valueUpdated = new EventEmitter();
  selectedIndex:any=0
  @ViewChild('container') container: ElementRef;



  constructor(
    private httpTransferService:HttpTransferService,private commonUtils:CommonUtils, private cdr: ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
    console.log(this.name)
    if(this.value){
      this.valueString=this.toDurationString(this.value)
    }
  }


  onChange() {
    const sd = this.valueString;
    const seconds = this.parseDuration(sd);

    let inputEle= document.getElementById(this.name);

    if (sd !== '' && seconds === 0) {
      if(inputEle){
        inputEle.style.borderColor = 'red';
        inputEle.focus();
      }
      this.valueUpdated.emit(null)

    } else {
      if(inputEle){
        this.valueString = this.toDurationString(seconds);
        inputEle.style.borderColor = 'green';
      }
      this.value=seconds
      console.log(this.value)
      this.valueUpdated.emit(this.value)

    }
  }


  toSeconds(ww: number, dd: number, hh: number, mm: number): number {
    let w = parseInt(ww.toString());
    let d = parseInt(dd.toString());
    let h = parseInt(hh.toString());
    let m = parseInt(mm.toString());
    if (isNaN(w)) w = 0;
    if (isNaN(d)) d = 0;
    if (isNaN(h)) h = 0;
    if (isNaN(m)) m = 0;

    let t = w * 7 * 24 * 60 * 60 + // weeks to seconds
      d * 24 * 60 * 60 +    // days to seconds
      h * 60 * 60 +          // hours to seconds
      m * 60;                // minutes to seconds
    console.log(t)
    return t;
  }

  parseDuration(sDuration: any): number {
    if (!sDuration) return 0;
    sDuration=sDuration.replaceAll('.','')

    let wrx = new RegExp(/([0-9]+)[ ]?[wW]/);
    let drx = new RegExp(/([0-9]+)[ ]?[dD]/);
    // let hrx = new RegExp(/([0-9]+)[ ]?(?![wWdDmM\s]).+/); // Modified regex
    let hrx = new RegExp(/([0-9]+)[ ]?[hH]/);
    let hrx2 = new RegExp(/([0-9]+)[ ]?[^wWdDmM]/g); // Modified regex
    let mrx = new RegExp(/([0-9]+)[ ]?[mM]/);
    let weeks = 0, days = 0, hours = 0, minutes = 0;
    if(sDuration){
      if (mrx?.test(sDuration)) {
        minutes = mrx.exec(sDuration) && mrx.exec(sDuration)!== null ?  parseInt(mrx.exec(sDuration)[1]) : minutes;
      }

      if (hrx?.test(sDuration)) {
        hours = hrx.exec(sDuration) && hrx.exec(sDuration)!== null ? parseInt(hrx.exec(sDuration)[1]) : hours;
      }
      else if (hrx2?.test(sDuration)) {
        hours = hrx2.exec(sDuration) && hrx2.exec(sDuration)!== null ? parseInt(hrx2.exec(sDuration)[1]) : hours;
      }

      if (drx?.test(sDuration)) {
        days =drx.exec(sDuration) && drx.exec(sDuration)!== null ? parseInt(drx.exec(sDuration)[1]) : days;
      }
      if (wrx?.test(sDuration)) {
        weeks =wrx.exec(sDuration) && wrx.exec(sDuration)!== null ? parseInt(wrx.exec(sDuration)[1]) : weeks;
      }
    }



    return this.toSeconds(weeks, days, hours, minutes);
  }

  toDurationString(iDuration: number): string {
    if (iDuration <= 0) return '';
    var m = Math.floor((iDuration / 60) % 60);
    var h = Math.floor((iDuration / 3600) % 24);
    var d = Math.floor((iDuration / 86400) % 7);
    var w = Math.floor(iDuration / (86400 * 7));
    var result = '';
    if (w > 0) result += w + 'w ';
    if (d > 0) result += d + 'd ';
    if (h > 0) result += h + 'h ';
    if (m > 0) result += m + 'm ';
    return result.trim();
  }

  loadSuggestions() {
    let sDuration = this.valueString.trim();
    this.showSuggestion = false;
    this.handeKeyInput()
    this.suggestionList = [];
  
    if (!sDuration) return;
    if(sDuration){
      let shouldShow=this.shouldShowSuggestions(sDuration)
      if(!shouldShow){
        return;
      }
    }

    if(sDuration?.trim()?.length && /\d$/.test(sDuration)){
      const words = sDuration.split(' ')
      const lastEle=words?.pop()
      let newString=words.join(' ');
      console.log(newString,lastEle)
  
      let currentseconds = this.parseDuration(newString);
      if (newString !== '' && currentseconds === 0) {
       
      } 
      else {
        let currentString= this.toDurationString(currentseconds)
        if(currentString.includes('h') && !currentString.includes('m')){
          this.suggestionList.push(currentString + " " + lastEle + 'm');
        }
        else if(currentString.includes('d') && (!currentString.includes('h') && !currentString.includes('m'))){
          this.suggestionList.push(currentString + " " + lastEle + 'h');
          this.suggestionList.push(currentString + " " + lastEle + 'm');
        }
        else if(currentString.includes('w') && (!currentString.includes('d') && !currentString.includes('h') && !currentString.includes('m'))){
          this.suggestionList.push(currentString + " " + lastEle + 'd');
          this.suggestionList.push(currentString + " " + lastEle + 'h');
          this.suggestionList.push(currentString + " " + lastEle + 'm');
        }
        else if( !currentString.includes('w') && !currentString.includes('d') && !currentString.includes('h') && !currentString.includes('m')){
          this.suggestionList.push(currentString + " " + lastEle + 'w');
          this.suggestionList.push(currentString + " " + lastEle + 'd');
          this.suggestionList.push(currentString + " " + lastEle + 'h');
          this.suggestionList.push(currentString + " " + lastEle + 'm');
        }
  
        if (this.suggestionList.length) {
          this.showSuggestion = true;
          this.handeKeyInput('Add')
        }
  
      }
    }



  }

  shouldShowSuggestions(input) {
    // Trim any leading or trailing whitespace
    const lastSpaceIndex = input.lastIndexOf(' ');
    const lastSubstring = lastSpaceIndex === -1 ? input : input.substring(lastSpaceIndex + 1);
    const isAllDigits = /^\d+$/.test(lastSubstring);
    return isAllDigits;
  }

  suggestionClicked($event){
    this.valueString=$event
    this.onChange()
    this.showSuggestion=false
    this.handeKeyInput()

  }


  handeKeyInput(type?){
    console.log('handeKeyInput')
    if(type=='Add'){
      this.selectedIndex=0
      setTimeout(() => {document.getElementById('search-input')?.focus(); },1); 
      document.addEventListener('keydown',this.onKeyDown)
    }else{
      document.removeEventListener('keydown',this.onKeyDown)
    }
  }

  onKeyDown= (event: any): void => {
    if(this.selectedIndex >=0){
      console.log('event.key',event.key)

      switch (event.key) {
      case 'ArrowUp':
        if (this.selectedIndex > 0) {
          this.selectedIndex--;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex)
          this.cdr.detectChanges();
        }
        break;
      case 'ArrowDown':
        if (this.selectedIndex < this.suggestionList.length - 1) {
          this.selectedIndex++;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
          this.cdr.detectChanges();
        }
        break;
      case 'Enter':
        if (this.selectedIndex>=0 && this.selectedIndex<this.suggestionList.length) {
          this.valueString=this.suggestionList[this.selectedIndex]
          this.onChange()
          this.cdr.detectChanges();
        }
        break;
    }
    }
    
  }

}
