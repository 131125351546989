import { ChangeDetectionStrategy, ChangeDetectorRef,Component,EventEmitter,OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { commonIcons, ItemIcons } from 'Enums/attributes-icons';
import { MediaIcons } from 'Enums/media-icons';
import { environment } from 'environments/environment';

@Component({
  selector: 'qlc-task-render',
  templateUrl: './ql-task-render.component.html',
  styleUrls: ['./ql-task-render.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class QlTaskRenderComponent implements OnInit {
  item:any;
  dataJson:any={};
  commonIcons:any=commonIcons;
  mediaIcons:any=MediaIcons;
  itemIcons:any=ItemIcons;
  @Output() onUpdateRenderer: EventEmitter<any> = new EventEmitter();
  @ViewChild('itemPopoverRef') itemPopoverRef: any;
  embedOption:any={embedYoutube:{name:'YouTube',type:'embedYoutube'},embedGoogleDrive:{name:'Google Drive',type:'embedGoogleDrive'},embedGoogleDocs:{name:'Google Docs',type:'embedGoogleDocs'},embedGoogleSheet:{name:'Google Sheet',type:'embedGoogleSheet'},embedGoogleSlides:{name:'Google Slides',type:'embedGoogleSlides'},embedFigma:{name:'Figma',type:'embedFigma'},embedOtherWebside:{name:'Other Webside',type:'embedOtherWebside'}}
  @ViewChild('viewComponent', { read: ViewContainerRef}) viewComponent!: ViewContainerRef;
  constructor(public cdr: ChangeDetectorRef, public commonUtils: CommonUtils,private sanitizer: DomSanitizer,private dashboardUtils:DashboardUtilsService) { }

  ngOnInit(): void {
    if(this.item?.type=='google_drive' && this.item?.paths?.path)this.dataJson.url=this.item?.paths?.path
    if(this.dataJson?.type=='boardView')this.getBoardInfo();
    if(this.dataJson?.url)this.dataJson.dummyUrl=this.sanitizer.bypassSecurityTrustResourceUrl(this.convertToIframeUrl(this.dataJson.url));
    this.cdr.detectChanges()
  }
  // url-converter.ts
  convertToIframeUrl(renderUrl: string){
    let url=renderUrl;
    if(this.dataJson?.type=='boardView'){
      return`${document.baseURI}/embedboard/${this.dataJson?.board_id}?view=${this.dataJson.view_id}`;
    }
    if (url.includes('youtube.com/watch')) {
      return url.replace('watch?v=', 'embed/'); // Convert YouTube URL to embed format
    } 
    else if (url.includes('youtu.be/')) {
      return url.replace('youtu.be/', 'www.youtube.com/embed/');     // Convert YouTube URL to embed format
    }
    else if (url.includes('google.com/document') || url.includes('google.com/presentation') || url.includes('google.com/spreadsheets')) {
      const urlObject = new URL(url);
      urlObject.search = ''; // Remove all query parameters
      return urlObject.toString();
    }
    else if (url.includes('drive.google.com')) {
      const fileIdMatch = url.match(/\/d\/([^\/]+)/);
      if (fileIdMatch && fileIdMatch[1]) {
        return `https://drive.google.com/file/d/${fileIdMatch[1]}/preview`;
      }
    }
    else if(url?.includes('figma.com')){
      const baseEmbedUrl = 'https://www.figma.com/embed?embed_host=yourwebsite&url=';
      const encodedUrl = encodeURIComponent(url);
      return `${baseEmbedUrl}${encodedUrl}`;
    }
    return url;
  }

  copyUrl(url){
    navigator.clipboard.writeText(url);
  }
  async getBoardInfo(){
    this.dataJson.url=`${document.baseURI}/board/${this.dataJson?.board_id}?view=${this.dataJson.view_id}`;
    let boardData=await this.dashboardUtils.getAndSyncAllDashboardData([this.dataJson.board_id],false,['Info']) || {};
    this.dataJson.boardInfo=boardData[this.dataJson.board_id]?.DASHBOARD_INFO || {};
    this.cdr.detectChanges();
  }


}
