import { ChangeDetectorRef, Compiler, Component, ElementRef, EventEmitter, Injector, Input, IterableDiffers, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'; 
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { Subscription } from 'rxjs';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ConstantService } from 'Enums/Constant.service';
import { MqttmessagingService } from '../../../services/mqttmessaging.service';

@Component({
  selector: 'app-contact-and-organization-board',
  templateUrl: './contact-and-organization-board.component.html',
  styleUrls: ['./contact-and-organization-board.component.scss'],
})
export class ContactAndOrganizationBoardComponent implements OnInit {
  @Input()  boardConnectedItemIds:any = [];
  @Input() dashId: string;
  @Input() isCurrentBoardReadonly:boolean=false;
  @Input() currentLead: any;
  @Input() isPublicLead: boolean = false
  @Input() connectedKey:string = null
  listOfLeads:any[]=[];
  selectedLead:any;
  mqttSubscription: any;
  iterableDiffer: any;
  isLoader:boolean=true;
  numberArray:any[]=[];
  countryData:any={}
   
  @Output() itemRemove = new EventEmitter<any>();
  @Output() itemCreate = new EventEmitter<any>();
  @Output() itemUpdate = new EventEmitter<any>();
  @Output() updateConnectBoard = new EventEmitter<any>();
  @Output() onItemOpen = new EventEmitter<any>();
  constructor( 
    private compiler: Compiler, private injector: Injector, 
    private httpTransfer: HttpTransferService, 
    private MqttmessagingService: MqttmessagingService,
    public constantService: ConstantService,
    private iterableDiffers: IterableDiffers,
    private dashboardUtils: DashboardUtilsService,
  ) { 
    this.iterableDiffer = iterableDiffers.find([]).create(null);
    this.listenForMqttMessages();
  }



  async ngOnInit() {
    let currencyJson = await this.dashboardUtils.getCurrencyJson() 
    currencyJson?.forEach(country=>{
      this.countryData[country?.code]=country
    })
    this.leadResponse()
  }


  listenForMqttMessages() {
    this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
      if (message) {
          this.updatesFromWss(message)
      }
    });
  }

  updatesFromWss(messagees) {
    let message = JSON.parse(messagees);
    if (message[this.constantService.constant.ACTIVITYTYPEKEY] ==this.constantService.constant.UPDATEACTIVITYTYPE && message['additional_attributes']['details']['_id'] == this.currentLead['_id']) {
      if (message[this.constantService.constant.OBJECTTYPEKEY] == this.constantService.constant.LEADOBJECTTYPE) {
        this.handleLeadUpdateTypeOperation(message);
      }
    }
  }

  handleLeadUpdateTypeOperation(message) {
    let updatedCustomFields=message['additional_attributes']?.updated_keys?.filter(item=>item?.custom_fields)
    updatedCustomFields=updatedCustomFields.length>0 ? updatedCustomFields[0]?.custom_fields : []
    if(message?.activity_type=='UPDATE' && updatedCustomFields?.length>0 && updatedCustomFields?.includes(this.connectedKey)){
      this.boardConnectedItemIds=message['additional_attributes']['details']['custom_fields'][this.connectedKey];
      this.leadResponse()
    }
  }

  async leadResponse(){
    this.isLoader=true
    this.listOfLeads=[]
    let inputJson={
      lead_id:this.boardConnectedItemIds,
      dashboard_id:[this.dashId]
    }
    let res : any=await  this.httpTransfer.getLeadQuery(inputJson).toPromise()
    if(res){this.isLoader=false}
    this.listOfLeads=res.result?.leadResponse
    console.log("list of lead response",this.listOfLeads)
  }

  removeItemFromBoard(event){
    // this.itemRemove.emit(event);
  }
  setItemToBoard(event){
    // this.itemCreate.emit(event);
  }
  updateItemToBoard(event){
    // this.itemUpdate.emit(event);
  }

  ngOnDestroy() {
    this.mqttSubscription?.unsubscribe()
  } 

  async ngOnChanges(changes: SimpleChanges) {
    if(changes.boardConnectedItemIds){
    //  this.leadResponse()
    }
  }

  removeConnectedLead(i,id){
    console.log(i,id)
    this.boardConnectedItemIds.forEach((element,index) => {
      if(element==id){
        this.boardConnectedItemIds.splice(index,1);
        this.listOfLeads.splice(i,1)
        this.updateConnectBoard.emit(this.boardConnectedItemIds)
      }
    });
  }

  getAvatar(item){
    const keysToCheck = ['logo_yAqhpCq5', 'profile_pic_29N2eLvy'];
    for (const key of keysToCheck) {
      if (item?.custom_fields && item?.custom_fields[key]) {
        return item.custom_fields[key]?.file_url;
      }
    }
    return item.title;
  }

  getUrlValue(array){
    for (const key of array) {
      if (this.selectedLead?.custom_fields && this.selectedLead?.custom_fields[key]) {
        return this.selectedLead?.custom_fields[key];
      }
    }
    return '';
  }
}
