import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AttributesIcons } from '../../../../Enums/attributes-icons';
import { ConstantService } from '../../../../Enums/Constant.service';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../message.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { CustomStorageService } from '../../../services/custom-storage.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { inspect } from 'util';
import { ToasterService } from '../../../services/toaster.service';
@Component({
  selector: 'app-lead-option-template',
  templateUrl: './lead-option-template.component.html',
  styleUrls: ['./lead-option-template.component.scss']
})
export class LeadOptionTemplateComponent implements OnInit {
  @Input() bucketInfo : any ={}
  @Input() leadInfo  : any 
  @Input() dashboardUserRole : any=null
  @Input() selectedDashId : any
  @Input() bucketArray : any
  @Input() finalArr : any
  @Input() dashBoardSprint : any
  @Input() isReadOnly : boolean=false
  @Input() isOpenedFullView : boolean=false
  @Input() showHiddenFields: any={};
  @Output() showSubtaskParentModal= new EventEmitter<any>();
  subTaskCreation : boolean = false;
  sameParentCreation : boolean = false;
  @ViewChild("markAsDuplicateModel") markAsDuplicateModel :  TemplateRef<any>;
  @ViewChild("confirmationDeleteModel") confirmationDeleteModel :  TemplateRef<any>;
  @ViewChild("createCopyToBoardModal") createCopyToBoardModal :  TemplateRef<any>;

  loginUserId:any;copyLeadcustomfieldarr: any[]=[];
  arrOfCustomField: any[]=[];
  showNext:boolean=false;
  dashboardJsonSub: any;
  sprintEnabled: boolean = false;
  phaseToggle: boolean = true;
  dashBoardsList: any;
  folderArr: any;
;
readMore:boolean=false;
 AttributesIcons = AttributesIcons;
  agingTimeInput : any=null
  leadModalRef : any
  addOrChangeLeadParent: boolean=false
  showDeleteItemConfirmationModal : any
  leadUrlText = environment.leadUrlText;
  destinationBucketOfCorrespondingDash : any=[]
  allBucketOfCorrespondingDash:any=[]
  markDuplicateModalRef : any
  parentChildTemplateTitle:string;
  copyLeadData: {};
  leadCopyModalRef: any;
  publicLeadUrlText=environment.publicLeadUrlText
  isDuplicateItem:boolean=false;
  itemTerminology:any={}


  //forCopyLead
  copyLeadInfo:any
  copyLeadDashId:any
  createCopyToBoardModalRef:any
  leadId:any;
  isFinalBucket:boolean=false;

  constructor(
    private ngbModalService: NgbModal,
    private httpTransfer : HttpTransferService,
    public commonUtils: CommonUtils,
    private msgservice: MessageService,
    public dashboardUtils: DashboardUtilsService,
    private customStorageService:CustomStorageService,
    private toaster : ToasterService,

  ) { }

  async ngOnInit(){
    if(this.bucketInfo?.is_destination){
      this.isFinalBucket=true
    }
    this.leadId=this.leadInfo._id
    this.dashboardUtils.getUpdatedDashboardJson().subscribe((res : any) => {
      this.bucketArray = res[this.selectedDashId]?.BUCKET || []; 
      this.bucketArray.forEach(bucket=>{
        if(bucket?._id == this.leadInfo?.bucket_id)this.bucketInfo=bucket;
        this.allBucketOfCorrespondingDash.push(bucket)
        if(bucket["is_destination"])this.destinationBucketOfCorrespondingDash.push(bucket);
      })
      this.itemTerminology=res[this.selectedDashId].DASHBOARD_INFO?.item_terminology
     
    });
    this.loginUserId=this.customStorageService.getItem('id')
    this.msgservice.sendDashBuckets(this.bucketArray);
    this.msgservice.getDashBuckets().subscribe((res : any) => {
      if(res){
        res.forEach((item) => {
          this.allBucketOfCorrespondingDash.push(item)
          if (item["is_destination"]) {
            this.destinationBucketOfCorrespondingDash.push(item);
          }
        });
      }
    });
    this.commonUtils.isLeadOrDashboardReadOnlyOrViewOny(this.selectedDashId,this.leadInfo).then((res : any) => { 
      this.isReadOnly = (res['is_collabrator_user'] && res['no_dashboard_user']) || res['is_readOnly_user'];
    });
    if(this.leadInfo.duplicate_details){
      this.isDuplicateItem=true
    }
    let dashboardJson = await this.dashboardUtils.getDashboardCustomForms([this.selectedDashId])
    dashboardJson=JSON.parse(JSON.stringify(dashboardJson))
    this.arrOfCustomField = dashboardJson[this.selectedDashId]['CUSTOM_FORM'];
    this.phaseToggle = this.sprintEnabled= dashboardJson[this.selectedDashId]?.DASHBOARD_INFO?.sprint_enabled || false;
  }
  openDeleteItemModal(mpodalContent) {
    this.ngbModalService.open(mpodalContent, { size : 'sm'});
  }
  onDeleteLead(confirmation=false){ 
    this.httpTransfer.deleteDashboardItem(this.leadInfo.dashboard_id, this.leadInfo._id,confirmation,confirmation).subscribe((res : any) => {
      if(res && res.status === 200){
        this.leadInfo.is_deleted=true
      }},err => {
        if(err.status === 500 && !confirmation && !this.leadInfo.is_deleted) {
          this.ngbModalService.open(this.confirmationDeleteModel, { size : 'sm'});
        }
      });
    
  }
  openCreateCopyModal(templateRef) {
    let custom_field_obj = this.copyLeadData["custom_fields"] || {};
    this.copyLeadcustomfieldarr.forEach(field=>{
      if(!field.checked){
        Object.keys(custom_field_obj).forEach(key => {
          if(field.key == key){
            delete this.copyLeadData["custom_fields"][key]
          }
        })
      }
     })
     if(this.copyLeadData['_id'] && (!this.subTaskCreation && !this.sameParentCreation)){
      delete this.copyLeadData['_id']
      }
    this.leadCopyModalRef = this.ngbModalService.open(templateRef, { size : 'xl', windowClass: 'copyTemplateLeadItem'});
  }

  openMarkAsDuplicate(){
   this. markDuplicateModalRef=this.ngbModalService.open(this.markAsDuplicateModel, {size : 'xl', windowClass: 'copyModal'});
  }
  openParentChildLeadModel(model,title?,manageParent?){
    if(title==='Add Sub Item' || title==='Add Parent'){
    this.showSubtaskParentModal.emit(title)
      return
    }
    this.parentChildTemplateTitle=title;
    this.addOrChangeLeadParent=manageParent
    this.ngbModalService.open(model, {size : 'xl', windowClass: 'copyModal'});
  }
  openLeadCopyModel(model){   
    if(this.leadInfo.parent_id && this.leadInfo.parent_id!=null){this.sameParentCreation = true}  
    this.copyLeadData = JSON.parse(JSON.stringify(this.leadInfo))
      let custom_field_obj = this.leadInfo["custom_fields"] || {};
      for(let i=0;i<this.arrOfCustomField.length;i++){
        Object.keys(custom_field_obj).forEach(key => {
          let value = custom_field_obj[key];
          if(value != "" && value!=null && this.arrOfCustomField[i].key == key){
            let obj = {}
            obj['key'] = key
            obj['display_key'] = this.arrOfCustomField[i].display_key
            obj['type'] = this.arrOfCustomField[i].type
            obj['checked'] = true
            if(this.arrOfCustomField[i].type=='Mirror'){              
              obj['mirror_column_type'] = this.arrOfCustomField[i].mirror_column_type
            }
            this.copyLeadcustomfieldarr.push(obj)
          }
      })
    }
    this.ngbModalService.open(model, {size: 'lg' , windowClass: 'copyModal'});
  }
  checkForAttributes(event,type){
   if(!event.target.checked){
    if(type == 'start_date'){
      this.copyLeadData[type] = null
      this.copyLeadData['end_date'] = null
    }
    else{
      type =='priority' ? this.copyLeadData[type] = '1' : this.copyLeadData[type] = null
    }
   }
   else{
    if(type == 'start_date'){
      this.copyLeadData[type] = this.leadInfo[type]
      this.copyLeadData['end_date'] = this.leadInfo['end_date']
    }
    else{
      this.copyLeadData[type] = this.leadInfo[type]
    }
   }
   
   
  }
  UpdateStarLead(updateLead : boolean) {
    let leadId=this.leadInfo._id;
    var obj = { is_starred :updateLead };
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(obj,this.selectedDashId, leadId)
      .subscribe((res : any) => {
        if(res.status==200){
          this.leadInfo.isStarred=updateLead
        }

      });
  }

  showHiddenFieldsFunction(val){
    this.showHiddenFields.ishidden=val
  }

  OpenAgingTimePopup(modal) {
    if (this.leadInfo.aging_time != null) {
      this.agingTimeInput={
        date: this.commonUtils.convertepoch(this.leadInfo.aging_time).split("-").reverse().join("/"),
        time:this.leadInfo.aging_time,
        is_time_added :true
      }
    }
    this.leadModalRef=this.ngbModalService.open(modal, { size : 'md', windowClass: 'aginTimeModal'});
   
   }
   RestoreLead(){
    var obj = { operation : "undelete" };
    this.httpTransfer.restoreDashboardItem(obj,this.selectedDashId,this.leadInfo._id)
    .subscribe((res : any) => {
      if(res.status==200){
        this.leadInfo.is_deleted=false
      }

    });
   }
  copyLeadLink() {
    const selBox = document.createElement("input");
    selBox.value = this.leadUrlText + this.selectedDashId + "/" + this.leadInfo.seq_id;
    document.body.appendChild(selBox);
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }
  updateAgingTime(agingTime : any) {
    this.httpTransfer.updateLeadCorrespondingToLeadId({aging_time: agingTime ? agingTime?.time : null},this.selectedDashId,this.leadInfo._id).subscribe((res : any) => {
    });
  }
  copyPublicItemLink(){
      const selBox = document.createElement("input");
      selBox.value = this.publicLeadUrlText + this.selectedDashId + "/" + this.leadInfo._id+"?key=public";
      document.body.appendChild(selBox);
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);

    }

    getJsonForExport(){     
      let obj={}
      // obj['dashboard_id']=[this.selectedDashId];
      obj['lead_id']=[this.leadInfo._id];
      return obj
    }

    async ExportExcel() {
      let jsonData = this.getJsonForExport();
        this.httpTransfer.postExportExcel(this.selectedDashId,jsonData).subscribe((res) => {
          if(res.status==200){
            this.toaster.Success("File Exported. Please check email for the exported file");
          }
        });
      
    }

    async ExportPdf() {
      let jsonData = this.getJsonForExport();
        this.httpTransfer.postExportPdf(this.selectedDashId,jsonData).subscribe((res) => {
          if(res.status==200){
            this.toaster.Success("File Exported. Please check email for the exported file");
          }
        });
      
    }


    openLeadCopyMappingModalToBoard(model){   
      this.ngbModalService.open(model, {size: 'lg' , windowClass: 'copyToBoardModal',  backdrop : 'static', keyboard : false,});
    }


    openLeadCopyModalToBoard(event){  
      this.copyLeadInfo=event.newLeadInfo
      this.copyLeadDashId=event.selectedDashId
      // this.ngbModalService.open(modal, {size: 'lg' , windowClass: 'copyModal'});
      this.createCopyToBoardModalRef=this.ngbModalService.open(this.createCopyToBoardModal, {size : 'lg', windowClass: 'copyTemplateLeadItem' , backdrop : 'static', keyboard : false});

    }

    onRemoveParent(){
      this.httpTransfer.updateLeadCorrespondingToLeadId({ parent_id: "" }, this.selectedDashId,this.leadInfo._id).subscribe((res : any) => {});
    }
    openInNewTab() {
      let url = environment.formHostUrl + "board/"+ this.leadInfo?.dashboard_id+'/'+this.leadInfo?.seq_id;
      window.open(url, '_blank');
    }
}
