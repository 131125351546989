<div class="mergeItemPopup">

  <div class="headerMerge">
    <div class="headerTitle">
      Merge Items
    </div>
    <div class="rightArea" >
      <div>
        <span class="flipText">Flip-view</span>
        <span class="float-right ">
          <div class="switch-div ">
            <label class="switch">
                <input type="checkbox"  [(ngModel)]="flippedView">
                <span class="slider round"></span>            
            </label> 
          </div>
        </span>
      </div>
      <div>
        <span class="closeIcon" role="button" (click)="closePopover.emit()"><img src="../../../../assets/common-use-icon-20dp/close.svg"></span>
      </div>
    </div>
  </div>
  
  <div class="mergeArea">
    <div class="listedItems">
      <div [ngClass]="{'flipped-table-container':(!flippedView),'table-container':(flippedView) }">
        <div *ngFor="let field of fieldsArr" [ngClass]="{'flipped-table-row':(!flippedView),'table-row':(flippedView) }">
          <div [ngClass]="{'flipped-cell':(!flippedView),'cell':(flippedView) }" placement="auto" triggers="hover" [ngbTooltip]="field.display_key.length>35 && field.display_key"  tooltipClass="tooltipPopup">
            <span *ngIf="field.type!='seq_id'" class="mr-2"><app-dashboard-icon [size]="16" [name]="field.type" [iconJson]="attributesIcons"></app-dashboard-icon></span>
            {{field.display_key  | truncate: 35}} <span *ngIf="field.mandatory && field.type!='seq_id'" style="color: red;">*</span>
          </div>
          <div [ngClass]="{'flipped-cell':(!flippedView),'cell':(flippedView) }" *ngFor="let item of leadInfo" [ngStyle]="{'background-color':((!field.multiple && field.value==item._id) || (field.multiple && field.value[item._id])) ? '#D5ECFC' :'#FFF'}">
            <input *ngIf="!field.multiple" type="radio" id="{{item._id}}_{{item[field.key]}}" name="{{field.key}}" [value]="field.value" (change)="field.value=item._id;changeInSelection(field.key)" [checked]="field.value==item._id" >
      
            <input *ngIf="field.multiple" type="checkbox" [(ngModel)]="field.value[item._id]" [ngModelOptions]="{standalone: true}" [checked]="field.value[item._id]"/>
      
            <!-- <div *ngIf="!field.isCustomField">{{item[field.key]}}</div> -->
            <!-- <div *ngIf="field.isCustomField">{{item.custom_fields[field.key]}}</div> -->
      
            <div class="fieldValArea">
      
              <!-- for sequence id -->
              <div *ngIf="field.type=='seq_id'">#{{item[field.key]}} </div>
      
              <!-- for priority -->
              <div *ngIf="field.type=='Priority'">
                  <div style="display:flex;">
                    <div style="text-align:left;" class="prioLabel" [style.background]="constantService?.priority_colors[item[field.key]]">
                      <span>{{priorityMap[item[field.key]].initials}}</span>
                    </div>
                    <span style="margin-left: 5px;"> {{priorityMap[item[field.key]]?.name}}</span>
                  </div>
              </div>
      
              <!-- for bucket -->
              <div *ngIf="field.type=='Bucket'" placement="auto" triggers="hover" [ngbTooltip]="bucketMap[item[field.key]].name.length>35 && bucketMap[item[field.key]].name"  tooltipClass="tooltipPopup" >{{bucketMap[item[field.key]]?.name | truncate:35}}</div>
      
               <!-- for SPRINT -->
               <div *ngIf="field.type=='Sprints'">
                <span *ngIf="item[field.key]" placement="auto" triggers="hover" [ngbTooltip]="sprintMap[item[field.key]].name.length>35 && sprintMap[item[field.key]].name"  tooltipClass="tooltipPopup" >{{sprintMap[item[field.key]]?.name | truncate: 35}}</span>
                <span *ngIf="!item[field.key]">----------</span>
              </div>
      
              <!-- for Tag -->
              <div *ngIf="field.type=='Tags'">
                <span class="d-flex align-items-center" *ngIf="(field.isCustomField && item.custom_fields[field.key]) || (!field.isCustomField && item[field.key])">
                  <ng-container *ngIf="item[field.key].length>0">
                    <ng-container *ngFor="let tagId of item[field.key]">
                        <div *ngIf="tagMap[tagId]" class="cardtag" placement="auto" triggers="hover" [ngbTooltip]="tagMap[tagId]?.name.length>7 && tagMap[tagId]?.name"  tooltipClass="tooltipPopup" >
                            <div class="tagText">{{tagMap[tagId]?.name}}</div>
                        </div>
                    </ng-container>
                </ng-container>
                </span>
                <span *ngIf="(field.isCustomField && !item.custom_fields[field.key]) || (!field.isCustomField && !item[field.key])">----------</span>
              </div>
      
               <!-- for User -->
               <div *ngIf="field.type=='SingleUser'">
                <span class="d-flex align-items-center" *ngIf="(field.isCustomField && item.custom_fields[field.key]) || (!field.isCustomField && item[field.key])">
                  <div class="d-flex align-items-center mr-1" [ngbPopover]="singleuserInfoCard" popoverClass="userInfoCardPopover" triggers="hover" placement="auto" container="body">
                    <ngx-avatars class="mr-1" *ngIf="!field.isCustomField" [name]="orgUsersJson[item[field.key]]?.name" [src]="orgUsersJson[item[field.key]]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                    <ngx-avatars class="mr-1" *ngIf="field.isCustomField" [name]="orgUsersJson[item.custom_fields[field.key]]?.name" [src]="orgUsersJson[item.custom_fields[field.key]]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                  </div>
                  <ng-template #singleuserInfoCard>
                    <app-user-info-card [user]="field.isCustomField ? orgUsersJson[item.custom_fields[field.key]] : orgUsersJson[item[field.key]]"></app-user-info-card>
                  </ng-template>
                  <span *ngIf="!field.isCustomField">{{orgUsersJson[item[field.key]]?.name}}</span>
                  <span *ngIf="field.isCustomField">{{orgUsersJson[item.custom_fields[field.key]]?.name}}</span>
                </span>
                <span *ngIf="(field.isCustomField && !item.custom_fields[field.key]) || (!field.isCustomField && !item[field.key])">----------</span>
              </div>
              
      
              <!-- for MultiUser -->
              <div *ngIf="field.type=='MultiUser'" class="d-flex align-items-center">

                <!-- for multiuser custom field -->
                <span class="d-flex align-items-center" *ngIf="(field.isCustomField && item.custom_fields[field.key])">
                  <ng-container *ngFor="let userId of item.custom_fields[field.key] | slice:0:5">
                    <div class="d-flex align-items-center mr-1"  [ngbPopover]="userInfoCard" popoverClass="userInfoCardPopover" triggers="hover" placement="auto" container="body">
                      <ngx-avatars class="mr-1"  [name]="orgUsersJson[userId]?.name" [src]="orgUsersJson[userId]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                    </div>
                    <ng-template #userInfoCard>
                      <app-user-info-card [user]="orgUsersJson[userId]"></app-user-info-card>
                    </ng-template>
                  </ng-container>
                </span>
                <span *ngIf="(field.isCustomField && item.custom_fields[field.key] && item.custom_fields[field.key].length>4)" [popover]="multiUserTemp"
                  placement="auto" container="body" class="viewMore" [outsideClick]="true">+{{item.custom_fields[field.key].length -4}} More</span>

                <!-- for collaborators -->
                <span class="d-flex align-items-center" *ngIf="(!field.isCustomField && item[field.key])">
                  <ng-container *ngFor="let userId of item[field.key] | slice:0:4">
                    <div class="d-flex align-items-center mr-1"  [ngbPopover]="userInfoCard" popoverClass="userInfoCardPopover" triggers="hover" placement="auto" container="body">
                      <ngx-avatars class="mr-1" [name]="orgUsersJson[userId]?.name" [src]="orgUsersJson[userId]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                    </div>
                    <ng-template #userInfoCard>
                      <app-user-info-card [user]="orgUsersJson[userId]"></app-user-info-card>
                    </ng-template>
                  </ng-container>           
                </span>
                <span *ngIf="(!field.isCustomField && item[field.key] && item[field.key].length>4)" [popover]="multiUserTemp"
                  placement="auto" container="body" class="viewMore" [outsideClick]="true">+{{item[field.key].length -4}} More</span>



                <ng-template #multiUserTemp>
                  <div *ngIf="(field.isCustomField)" class="viewMorePop">
                    <ng-container *ngFor="let userId of item.custom_fields[field.key]">
                        <div style="width: 100%;">
                          <span class="assignusr f-m-b" style="justify-content: flex-start; width: 100%; margin-bottom: 10px;">
                            <ngx-avatars class="mr-1" [name]="orgUsersJson[userId]?.name" [src]="orgUsersJson[userId]?.image_url" size="25">
                            </ngx-avatars>
                            <span>{{orgUsersJson[userId]?.name}}</span>
                          </span>
                        </div> 
                    </ng-container>
                  </div>
                  <div *ngIf="(!field.isCustomField)" class="viewMorePop">
                    <ng-container *ngFor="let userId of item[field.key]">
                        <div style="width: 100%;">
                          <span class="assignusr f-m-b" style="justify-content: flex-start; width: 100%; margin-bottom: 10px;">
                            <ngx-avatars class="mr-1" [name]="orgUsersJson[userId]?.name" [src]="orgUsersJson[userId]?.image_url" size="25">
                            </ngx-avatars>
                            <span>{{orgUsersJson[userId]?.name}}</span>
                          </span>
                        </div> 
                    </ng-container>
                  </div>
                  
                </ng-template>
                <span *ngIf="(field.isCustomField && (!item.custom_fields[field.key] || item.custom_fields[field.key]?.length<1)) || (!field.isCustomField && (!item[field.key] || item[field.key]?.length<1))">----------</span>
              </div>
      
      
              <!-- for text/Email/Url/IPAddress/Radiobutton/Dropdown -->
              <div *ngIf="field.type=='Email' || field.type=='IPAddress' 
               || field.type=='Country'">
                <span *ngIf="(field.isCustomField && item.custom_fields[field.key]) || (!field.isCustomField && item[field.key])">
                  <span *ngIf="!field.isCustomField" class="textVal" placement="auto" triggers="hover" [ngbTooltip]="item[field.key].length>35 && item[field.key]"  tooltipClass="tooltipPopup" >{{item[field.key] | truncate:35}}</span>
                  <span *ngIf="field.isCustomField" class="textVal" placement="auto" triggers="hover" [ngbTooltip]="item.custom_fields[field.key].length>35 && item.custom_fields[field.key]"  tooltipClass="tooltipPopup" >{{item.custom_fields[field.key] | truncate:35}}</span>
                </span>
                <span *ngIf="(field.isCustomField && !item.custom_fields[field.key]) || (!field.isCustomField && !item[field.key])">----------</span>
              </div>

              <!-- for Text -->
              <div *ngIf="field.type=='Text'">
               <span *ngIf="(field.isCustomField && item.custom_fields[field.key]) || (!field.isCustomField && item[field.key])">
                 <span *ngIf="!field.isCustomField" class="textVal" >{{item[field.key] | truncate:35}}</span>
                 <span *ngIf="field.isCustomField" class="textVal" >{{item.custom_fields[field.key] | truncate:35}}</span>
                 <span *ngIf="(!field.isCustomField && item[field.key] && item[field.key].length>35) || (field.isCustomField && item.custom_fields[field.key] && item.custom_fields[field.key].length>35)" [popover]="textAreaTemp"
                  placement="top" class="viewMore" [outsideClick]="true">Read More</span>
                  <ng-template #textAreaTemp>
                    <div class="viewMorePop">
                      <span *ngIf="!field.isCustomField" class="textVal" >{{item[field.key]}}</span>
                      <span *ngIf="field.isCustomField" class="textVal" >{{item.custom_fields[field.key]}}</span>
                    </div>                                                                                    
                  </ng-template>
               </span>
               <span *ngIf="(field.isCustomField && !item.custom_fields[field.key]) || (!field.isCustomField && !item[field.key])">----------</span>
             </div>

              <!-- for Text Area -->
              <div *ngIf="field.type=='Textarea'">
                <span *ngIf="item.custom_fields[field.key]" class="textAreaVal" [innerHtml]="item.custom_fields[field.key] | safehtml | truncate: 35" > </span>
                <span *ngIf="item.custom_fields[field.key] && item.custom_fields[field.key].length>35" [popover]="textAreaTemp"
                  placement="top" class="viewMore" [outsideClick]="true">Read More</span>
                  <ng-template #textAreaTemp>
                    <div class="viewMorePop">
                      <span [innerHtml]="item.custom_fields[field.key] | safehtml " > </span>
                    </div>                                                                                    
                  </ng-template>
                <span *ngIf="!item.custom_fields[field.key]">----------</span>
              </div>
      
              <!-- for Checkbox/Multiselect -->
              <div *ngIf="field.type=='Checkbox' || field.type=='MultiSelect' ">
                <span *ngIf="item.custom_fields[field.key] && item.custom_fields[field.key]?.length>0">{{item.custom_fields[field.key][0] | truncate: 26}}</span>
                <span *ngIf="item.custom_fields[field.key] && item.custom_fields[field.key].length>1" [popover]="checkMultiTemp"
                  placement="auto" container="body" class="viewMore" [outsideClick]="true">+{{item.custom_fields[field.key].length -1}} More</span>
                  <ng-template #checkMultiTemp>
                    <div class="viewMorePop">
                            <ng-container *ngFor="let data of item.custom_fields[field.key]">
                                <div>
                                    <span class="option-text">{{data}}</span>
                                </div> 
                            </ng-container>
                        </div>                                                                                    
                    </ng-template>
                <span *ngIf="!item.custom_fields[field.key] || item.custom_fields[field.key]?.length<1">----------</span>
              </div>
      
               <!-- for Number -->
               <div *ngIf="field.type=='Numeric' || field.type=='Radiobutton' || field.type=='Dropdown'">
                <span *ngIf="item.custom_fields[field.key] || item.custom_fields[field.key]===0">{{item.custom_fields[field.key]}}</span>
                <span *ngIf="!item.custom_fields[field.key] && item.custom_fields[field.key]!==0">----------</span>
               </div>

               <!-- for Location -->
               <div *ngIf="field.type=='Location'">
                <span *ngIf="item.custom_fields[field.key]">{{item.custom_fields[field.key]?.description}}</span>
                <span *ngIf="!item.custom_fields[field.key]">----------</span>
               </div>
      
               <!-- for Currency -->
               <div *ngIf="field.type=='Currency'">
                <span *ngIf="item.custom_fields[field.key]">{{item.custom_fields[field.key]?.currency}} {{item.custom_fields[field.key]?.amount}}</span>
                <span *ngIf="!item.custom_fields[field.key]">----------</span>
               </div>
      
               <!-- for Mobile -->
               <div *ngIf="field.type=='Mobile'">
                <span *ngIf="item.custom_fields[field.key]">{{item.custom_fields[field.key]?.full_mobile_number}} </span>
                <span *ngIf="!item.custom_fields[field.key]">----------</span>
               </div>

               <!-- for Rating -->
               <div *ngIf="field.type=='Rating'">
                <ngb-rating style="font-size: 18px; align-items: center; height: 30px; column-gap: 5px; color: #3571de !important; padding: 0px 7px;"  [readonly]="true" [(rate)]="item.custom_fields[field.key]" [max]="5" [disabled]="true" >
                 </ngb-rating>
               </div>

               <!-- for Url -->
               <div *ngIf="field.type=='Url'">
                <span *ngIf="item.custom_fields[field.key] && item.custom_fields[field.key].trim().length>0" [innerHTML]="item.custom_fields[field.key] | linky:{stripPrefix: false, truncate: { length: 20, location: 'smart' }}">{{item.custom_fields[field.key]}}</span>
                <span *ngIf="!item.custom_fields[field.key] || item.custom_fields[field.key]?.trim()?.length<0">----------</span>
               </div>

               <!-- for Multiurl -->
               <div *ngIf="field.type=='MultiUrl'">
                <span *ngIf="item.custom_fields[field.key] && item.custom_fields[field.key]?.length>0" [innerHTML]="item.custom_fields[field.key][0] | linky:{stripPrefix: false, truncate: { length: 20, location: 'smart' }}">{{item.custom_fields[field.key][0]}}</span>
                <span *ngIf="item.custom_fields[field.key] && item.custom_fields[field.key].length>1" [popover]="urlTemp"
                  placement="auto" container="body" class="viewMore" [outsideClick]="true">+{{item.custom_fields[field.key].length -1}} More</span>
                  <ng-template #urlTemp>
                    <div class="viewMorePop">
                      <ng-container *ngFor="let url of item.custom_fields[field.key]">
                        <div style="width: 200px; overflow-y: auto; scrollbar-width: thin;">
                          <span style="justify-content: flex-start; width: 100%; margin-bottom: 10px;">
                            <a href="{{url}}" target="_blank">{{url}}</a>
                          </span>
                        </div> 
                    </ng-container>
                    </div>
                  </ng-template>
                <span *ngIf="!item.custom_fields[field.key] || item.custom_fields[field.key]?.length<1">----------</span>
               </div>


               <!-- for Board -->
               <div *ngIf="field.type=='Board'">
                <span *ngIf="item.custom_fields[field.key] && item.custom_fields[field.key]?.length>0">#{{connectedItemIdJson[item.custom_fields[field.key][0]]?.seq_id}}</span>
        
                <span *ngIf="item.custom_fields[field.key] && item.custom_fields[field.key]?.length>1" [popover]="connectedBoardTemp" [autoClose]="'outside'" #popoverViewMore="ngbPopover" 
                    placement="auto" container="body" class="viewMore" [outsideClick]="true">  +{{item.custom_fields[field.key].length-1}} More</span>
                    <ng-template #connectedBoardTemp>
                        <app-item-list [itemIdList]="item.custom_fields[field.key]" [dashId]="field?.connected_board_id" [isReadOnly]=true [showRemoveIcon]=false></app-item-list>
                    </ng-template>
                <span *ngIf="!item.custom_fields[field.key] || item.custom_fields[field.key]?.length<1">----------</span>
               </div>


               <!-- for Date -->
               <div *ngIf="field.type=='Date'">
                <span *ngIf="item.custom_fields[field.key]" >{{item.custom_fields[field.key]?.date}} <span *ngIf="item.custom_fields[field.key]?.is_time_added">-{{item.custom_fields[field.key]?.time | date:'shortTime'}}</span></span>
                <span *ngIf="!item.custom_fields[field.key]">----------</span>
               </div>

                <!-- for single Media -->
                <div *ngIf="field.type=='SingleImage' || field.type=='SingleVideo' || field.type=='SingleAudio' || field.type=='SingleFile' ">
                  <span *ngIf="item.custom_fields[field.key]" class="flexRow">
                    <app-dashboard-icon [name]="field.type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                    <span>{{item.custom_fields[field.key]?.file_name | truncate: 20}}</span>                     
                  </span>
                  <span *ngIf="!item.custom_fields[field.key]">----------</span>
                </div>

                  <!-- for Multiple Media -->
                <div *ngIf="field.type=='MultipleImage' || field.type=='MultipleVideo' || field.type=='MultipleAudio' || field.type=='MultipleFile' ">
                  <span *ngIf="item.custom_fields[field.key] && item.custom_fields[field.key]?.length>0" class="flexRow">
                    <app-dashboard-icon [name]="field.type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                    <span>{{item.custom_fields[field.key][0]?.file_name | truncate: 20}}</span>           
                  </span>
                  <span *ngIf="item.custom_fields[field.key] && item.custom_fields[field.key].length>1" [popover]="multiMediaTemp"
                    placement="auto" container="body" class="viewMore" [outsideClick]="true">+{{item.custom_fields[field.key].length -1}} More</span>
                    <ng-template #multiMediaTemp>
                      <div class="viewMorePop">
                        <ng-container *ngFor="let media of item.custom_fields[field.key]">
                          <div style="width: 200px; overflow-y: auto; scrollbar-width: thin;">
                            <span style="justify-content: flex-start; width: 100%; margin-bottom: 10px;">
                              <app-dashboard-icon [name]="field.type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                              <span>{{media?.file_name | truncate: 20}}</span>     
                            </span>
                          </div> 
                      </ng-container>
                      </div>
                    </ng-template>
                  <span *ngIf="!item.custom_fields[field.key] || item.custom_fields[field.key]?.length<1">----------</span>
                 </div>

                <div *ngIf="field.type=='TimeTracker'">
                  <span *ngIf="item.custom_fields[field.key]" >{{commonUtils.timeConvert(item.custom_fields[field.key]?.time_spend_till_now)}}</span>
                  <span *ngIf="!item.custom_fields[field.key]">----------</span>
                </div>


                <div *ngIf="field.type=='CheckList'" class="flexRow">
                  <span *ngIf="item.custom_fields[field.key] && item.custom_fields[field.key]?.length>0" class="flexRow">
                    <img *ngIf="item.custom_fields[field.key][0]?.state=='COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 29.png" alt="">
                    <img *ngIf="item.custom_fields[field.key][0]?.state=='NOT_COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 28.png" alt="">
                    <span>{{item.custom_fields[field.key][0]?.summary | truncate: 25}}</span>
                  </span>
                  <span *ngIf="item.custom_fields[field.key] && item.custom_fields[field.key].length>1" [popover]="checkListTemp"
                    placement="auto" container="body" class="viewMore" [outsideClick]="true">+{{item.custom_fields[field.key].length -1}} More</span>
                    <ng-template #checkListTemp>
                      <div class="viewMorePop">
                              <ng-container *ngFor="let checklist of item.custom_fields[field.key]">
                                  <div class="flexRow"> 
                                      <img *ngIf="checklist.state=='COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 29.png" alt="">
                                      <img *ngIf="checklist.state=='NOT_COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 28.png" alt="">
                                      <span>{{checklist?.summary | truncate: 25}}</span>
                                  </div>
                              </ng-container>
                          </div>                                                                                    
                      </ng-template>
                  <span *ngIf="!item.custom_fields[field.key] || item.custom_fields[field.key]?.length<1">----------</span>
                </div>
      
            </div>
      
          </div>
      
        </div>
      
      </div>
    </div>
  </div>
  
  <div class="footerMerge">
    <div class="msgArea">
      <span class="msgWarning" *ngIf="mergeToLeadSeqId"><span class="msgTitle">Note:</span> Other Items will be merge to <span class="mergingId"> #{{mergeToLeadSeqId}}</span>. Choose the target Item carefully, as this action cannot be undone. Consider backing up important information before proceeding.  Merging Items is irreversible and will delete the original Item along with its associated data.</span>
      <!-- <span class="msgWarning"> <span class="msgTitle">Warning:</span> Merging Items is irreversible and will delete the original Item along with its associated data. Choose the target Item carefully, as this action cannot be undone. Consider backing up important information before proceeding.</span> -->
    </div>
    <div class="btnArea">
      <span class="mergeBtn"  (click)="openMergeConfirmIemModal(mergeConfimModal);">Merge</span>
    </div>


    <ng-template #mergeConfimModal let-modal>
      <div class="mergeConfimModal">
        <div class="confirmIconArea">
          <img class="confirmIcon" src="../../../assets/1x/alert-big-size.svg" alt="">
        </div>
        <div class="confirmMsg" *ngIf="!requireConfirmation">
          Merging Items is irreversible and will delete the original Item along with its associated data. Currently, you are  merging other items to  <span class="mergingId"> #{{mergeToLeadSeqId}}</span>.
        </div>
        <div class="confirmMsg" *ngIf="requireConfirmation">
           Can not complete operation as some of deleting Items having {{errMessage}}. Are you sure to merge?
        </div>
        <div class="confirmFooter">
          <span *ngIf="!merging" class="btnBox cancelBtn" (click)="modal.dismiss()">Cancel</span>
          <span *ngIf="merging" class="btnBox cancelBtn" style="opacity: 0.5;cursor: auto;">Cancel</span>
          <span *ngIf="!merging && !requireConfirmation" class="btnBox confirmMergeBtn" (click)="mergeItem()">Merge</span>
          <span *ngIf="merging && !requireConfirmation" class="btnBox confirmMergeBtn" style="opacity: 0.5;cursor: auto;">
            Merging
            <div class="spinner-border" role="status"><span class="sr-only">Merging...</span></div>  
          </span>
          <span *ngIf="!merging && requireConfirmation" class="btnBox confirmMergeBtn" (click)="mergeConfirmation()">Confirm</span>
          <span *ngIf="merging && requireConfirmation" class="btnBox confirmMergeBtn" style="opacity: 0.5;cursor: auto;">
            Confirming
            <div class="spinner-border" role="status"><span class="sr-only">Confirming...</span></div>  
          </span>
        </div>
      </div>
    </ng-template>

  </div>
</div>
