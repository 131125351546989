<div class="row preArrovebody">
    <div class="preArroveLeft">
        <div *ngFor="let item of preApprovedData" style="padding-left: 3px">
        <div class="preMessageBox" style="padding-left: 15px" *ngIf="item.status!='REJECTED'" (click)="selectedTemplate(item)" [ngClass]="{'activeItem' : item?.id == selectedMsg?.id}">
            <label class="mb-0" style="font-weight: 500;">{{item?.name}}</label>
        </div>
        </div>
    </div>
    <div class="preArroveRight">
        <div class="messageBody" *ngIf="selectedMsg" [ngClass]="{'con' : !value.length}">
          <div *ngFor="let item of selectedMsg.components">
          <p *ngIf="item.type=='BODY'">{{item.text}}</p>
          </div>
        </div>
        <div class="userValue" *ngIf="value.length" [ngClass]="{'valueBox' : automationCall}">
           <label>{{'whatsapp.addValue' | translate}}</label>
           <div *ngFor="let item of value;let index = index">           
            <div class="valuerow" *ngIf="!automationCall">  
              <div style="width:95%">
              <span> {{index+1}}
              <input type = "text" class="valueInput"  #inputText [(ngModel)]="item.columnValue" placeholder="write here"/>
              </span> 
              </div>
              <div class="columnclass" placement="auto" [ngbPopover]=" customColumns" #popColumns="ngbPopover" container="body"  [autoClose]="'outside'" (click)="getIndex(index)"> 
              <span>{{ '\{\{ \}\}'}}</span>
             </div>          
            </div>
            <div class="w-100" *ngIf="automationCall" style="display: flex;align-items: center;">
              <div style="width:5%;">{{index+1}}</div>
              <div style="width:95%;">
                <app-content-editable-input-note [htmlContent]="item.columnValue" [whatsappCall]="true" [item]="automationObj" [columnIndex]="columnIndex ? columnIndex : 0" [columnsJson]="whatsappColumnJson ? whatsappColumnJson : {}"   [dashId]="dashId" [customFieldArr]="customFieldArr" [customFieldArr]="arrOfCustomField" [isTriggeredNonItemField]="isTriggeredNonItemField" (onBlur)="item.columnValue=$event.text;whatsappColumnJson=$event.columnsJson; columnIndex=$event.columnIndex;this.updatedAutomation.emit();selectPreapprovedMessageforAutomation()"></app-content-editable-input-note>
              </div>
            </div>
           </div>
        </div>
        <div class="preApprovefooter" *ngIf="!automationCall">
           <div>
            <button class="preApprovSendBtn" (click)="sendMsg()">{{'whatsapp.send' | translate}}</button>
          </div>
        </div>
    </div>


</div>



<ng-template #customColumns>
  <div *ngIf="!automationCall" style="width:300px;height:200px;overflow-y: auto;">
      <label>{{'whatsapp.autoPopulateFields' | translate}}</label>
      <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-bottom:5px;margin-top:10px;padding-bottom: 10px;border-bottom: 1px solid #d9d9d9;">
        <span class="predefineBox" (click)="getextravalue('bucketName')">Bucket</span>
        <span class="predefineBox" (click)="getextravalue('priority')">Priority</span>
        <span class="predefineBox" (click)="getextravalue('phase')">Phase</span>
        <span class="predefineBox" (click)="getextravalue('assignee')">Assignee</span>
        <span class="predefineBox" (click)="getextravalue('summary')">Title</span>
        <span class="predefineBox" (click)="getextravalue('startDate')">Start Date</span>
        <span class="predefineBox" (click)="getextravalue('endDate')">end Date</span>
    </div>            
      <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;">
        <ng-container *ngFor="let item of arrOfCustomField">
          <span class="predefineBox" *ngIf="item.type!='Board' && item.type!='Mirror' && item.type!='Textarea' && item.type!='SingleImage' && item.type!='MultipleImage' && item.type!='SingleFile'
          && item.type!='MultipleFile' && item.type!='MultiSelect' && item.type!='MultiUser' && item.type!='SingleVideo' && item.type!='MultipleVideo' && item.type!='Checkbox' && item.type!='CheckList'" (click)="getcustomvalue(item)">{{item?.display_key}}</span>
          </ng-container>
      </div>   
      <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;">
        <ng-container *ngFor="let item of arrOfCustomField">
          <span class="predefineBox" *ngIf="item.type=='Mirror' && item.mirror_column_type!='SingleImage' && item.mirror_column_type!='MultipleImage' && item.mirror_column_type!='SingleFile'
          && item.mirror_column_type!='MultipleFile' && item.mirror_column_type!='SingleVideo' && item.mirror_column_type!='MultipleVideo' && item.mirror_column_type!='SingleAudio' && item.mirror_column_type!='MultipleAudio' && item.mirror_column_type!='Checkbox' && item.mirror_column_type!='CheckList' && item.mirror_column_type!='MultiSelect'" (click)="getValuesForMirroColumns(item)">{{item?.display_key}}</span>
          <span class="predefineBox" *ngIf="item.type=='Mirror' && (item.mirror_column_type=='Checkbox' || item.mirror_column_type=='CheckList' || item.mirror_column_type=='MultiSelect')" (click)="getValuesForMirroColumns(item)">{{item?.display_key}} (First value)</span>
        </ng-container>
      </div>  
  </div>
  <div *ngIf="automationCall">
    <app-automation-columns [item]="item" [dashId]="dashId" [customFieldArr]="arrOfCustomField" (selectedColumn)="getValueforAutomation($event);"></app-automation-columns>
  </div>
</ng-template>