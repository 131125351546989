import { Directive, ElementRef, Input, HostListener, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[minutesToHours]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MinutesToHoursDirective),
      multi: true
    }
  ]
})
export class MinutesToHoursDirective implements ControlValueAccessor {
  @Input('minutesToHours') minutes: number;

  private onChange: (value: number) => void;
  private onTouched: () => void;

  constructor(private el: ElementRef<HTMLInputElement>) {}

  // Writes the value to the input element (from minutes to hours)
  writeValue(minutes: number): void {
    if (minutes != null) {
      const hours = minutes / 60;
      this.el.nativeElement.value = hours.toString();
    }
  }

  // Registers the function to call when the value changes
  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  // Registers the function to call when the control is touched
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    const hours = parseFloat(value);
    if (!isNaN(hours)) {
      const minutes = Math.round(hours * 60);
      if (this.onChange) {
        this.onChange(minutes);
      }
    }
  }

  @HostListener('blur')
  onBlur() {
    if (this.onTouched) {
      this.onTouched();
    }
  }
}