
<div class="text-center editor-modal" > 
    <!-- image markerjs start here -->
    <div class="imaeEditorArea">
        <diV class="editor-body" id="editorBody" [ngStyle]="{'height':editType!='' ? '100%' : ''}"> 
            <!-- options left -->
            <div class="p-2">
                <div class="editButtons" *ngIf="imageFileUrl[selectedImgIndex]?.fileType=='image' && !isChatView && editType==''"> 
                    <span class="" container="body" placement="right"
                        ngbTooltip="{{'imageEditor.crop' | translate}}" tooltipClass="tooltipPopup"
                        (click)="switchAndSaveEditView('Cropper')" [ngClass]="{'activeEditin':editType=='Cropper'}">
                        <i class="fas fa-crop p-0"></i> 
                    </span>
                    <span class=""
                        container="body" placement="right" ngbTooltip="{{'imageEditor.brush' | translate}}"
                        tooltipClass="tooltipPopup" (click)="switchAndSaveEditView('ImageDrawer')"
                        [ngClass]="{'activeEditin':editType=='ImageDrawer'}">
                        <i class="fas fa-edit p-0"></i> 
                    </span> 
                </div>   
            </div>
            <!-- middle --> 
            <div class="innerEditorBody">
                <!-- image preview start here -->
                <div *ngIf="showLoader" class="loader-container editor-loader">
                    <app-shimmer-loader  [type]="'Spinner'" [circleWidth]="50" [color]="'white'"></app-shimmer-loader> 
                </div>
                <div class="media-container" style="margin-top: 20px;" *ngIf="editType==''" > 
                    <img *ngIf="imageBaseUrl && imageFileUrl[selectedImgIndex]?.fileType=='image'"
                        class="imgPreview" [src]="imageBaseUrl">
                    <video *ngIf="imageBaseUrl && imageFileUrl[selectedImgIndex]?.fileType=='video'" class="videoField" controls
                        class="previewImg">
                        <source [src]="imageBaseUrl" type="video/mp4" />
                    </video>
                    <audio *ngIf="imageBaseUrl && imageFileUrl[selectedImgIndex]?.fileType=='audio'" class="audioField pl-2 pr-2" controls><source [src]="imageBaseUrl" type="audio/mp3"/></audio>
                    <div *ngIf="imageFileUrl[selectedImgIndex]?.fileType!='image' && imageFileUrl[selectedImgIndex]?.fileType!='audio'  && imageFileUrl[selectedImgIndex]?.fileType!='video'">
                        <div><app-dashboard-icon [name]="(mediaIcons.hasOwnProperty(imageFileUrl[selectedImgIndex]?.name?.split('.').pop())) ? imageFileUrl[selectedImgIndex]?.name?.split('.').pop() : (mediaIcons.hasOwnProperty(imageFileUrl[selectedImgIndex]?.path?.split('.').pop())) ? imageFileUrl[selectedImgIndex]?.path?.split('.').pop() : 'txt'" [iconJson]="mediaIcons" size="60"></app-dashboard-icon></div>
                    </div>
                </div>
                <!-- image preview end here -->
                <div *ngIf="editType=='ImageDrawer' || editType=='Cropper'" #renderer class="media-container" [ngStyle]="{'height': editType=='ImageDrawer' ? '82%':'75%'}"> 
                    <img [src]="imageBaseUrl" #imageRef class="imgPreview" style="visibility: hidden;"/>
                </div>
                <!-- image markerjs end here --> 
            </div>
            <!-- close btn right -->
            <div class="p-2">
                <!-- close btn -->
                <span *ngIf="editType==''" type="button" class="editor-close-btn" (click)="closeModal.emit()"><i class="fas fa-close p-0"></i></span>
            </div>
        </diV>
        <div *ngIf="editType==''"  class="footerEditor"> 
            <div *ngIf="fileType =='MultipleImage'" class="cropToolbarBox">
                <span *ngIf="imageFileUrl.length>7" style="font-size: 25px;cursor:pointer" (click)='moveElement(-200)'> <i
                        class="fas fa-chevron-circle-left"></i> </span>
                        <div class="imageSelectButtons" #widgetsContent>
                        <ng-container *ngFor="let item of imageFileUrl;let index = index">
                        <div *ngIf="item?.url" class="imageBox" (click)="changeSelectedImage(index,item)" >
                            <div [ngClass]="{'activeImgBox':selectedImgIndex==index}" class="inner-image-box" > 
                                <ng-container *ngIf="item.fileType=='image'">
                                    <img width="50" height="50" [src]="item.url">
                                </ng-container> 
                           <!--      <ng-container *ngIf="item.fileType=='audio'">
                                    <audio  class="audioField pl-2 pr-2" controls><source [src]="item.url" type="audio/mp3"/></audio>                                
                                </ng-container> -->
                                <ng-container *ngIf="item.fileType=='video'">
                                    <video class="videoField" controls width="50" height="50">
                                        <source [src]="item.url" type="video/mp4" />
                                    </video>
                                </ng-container> 
                                <ng-container *ngIf="item.fileType!='video' && item.fileType!='image'">
                                    <app-dashboard-icon [name]="(mediaIcons.hasOwnProperty(item?.name?.split('.').pop())) ? item?.name?.split('.').pop() : (mediaIcons.hasOwnProperty(item?.path?.split('.').pop())) ? item?.path?.split('.').pop() : 'txt'" [iconJson]="mediaIcons" size="50"></app-dashboard-icon> 
                                </ng-container> 
                            </div>
                            <span class="removeBtn" (click)="deleteImage(index)"><i class="fas fa-trash-alt"></i></span>
                        </div>
                        </ng-container> 
                    </div>
                     <!-- upload img -->
                <div class="imageBox" *ngIf="!isChatView" (click)="imageUpload.value='';imageUpload.click()">
                    <input #imageUpload
                    class="image-upload" type="file" name="imageUpload"
                    (change)="convertDataUrl($event.target.files)" accept="image/*"
                    multiple />
                         <div class="uploadPlus"> <i class="fa fa-plus"></i> </div>
                </div>
                <div class="imageBox"*ngIf="isChatView" (click)="imageUpload.value='';imageUpload.click()">
                    <input #imageUpload
                    class="image-upload" type="file" name="imageUpload"
                    (change)="convertDataUrl($event.target.files)" accept="application/pdf, .xls,.xlsx,.doc,.docx ,application/zip,.rar,.7zip,application/vnd.oasis.opendocument.text" multiple />
                <div class="uploadPlus"> <i class="fa fa-plus"></i> </div>
            </div>
                <span *ngIf="imageFileUrl.length>7" style="font-size: 25px;cursor:pointer"
                    (click)="moveElement(200)"> <i class="fas fa-chevron-circle-right"></i> </span>
            </div>
            <div *ngIf="!isChatView" class="submission-button">
                <button class="buttons btn submitBtn"
                    (click)="submitFiles()">{{'common.done' | translate}}
                </button>
                <button class="buttons btn resetBtn"
                    *ngIf="imageFileUrl.length>0 && imageFileUrl[selectedImgIndex]?.originalUrl!=imageBaseUrl"
                    (click)="updateImgUrl(imageFileUrl[this.selectedImgIndex]?.originalUrl)">{{'common.reset' |
                    translate}}
                </button> 
            </div>
        </div>
    </div>
    <!-- input editor  -->
    <div *ngIf="isChatView && showEditor" class="msgBoxForImage" style="height: auto;"> 
        <app-content-editable-input-note [htmlContent]="imageFileUrl[selectedImgIndex]?.caption" [rplyObj]="rplyObj"
            style="width: 100%;display: flex;flex-direction: column;" [selectedLeadObj]="selectedLeadObj" [disabelSendBtn]="false"
            [dashId]="dashId" [commentChat]="true" [currentActiveTab]="currentActiveTab" (sendEditorText)="imageFileUrl[selectedImgIndex].caption = $event?.length>0 ? $event : null"
            [commentMediaArr]="commentMediaArr" (sendChat)="sendChatMsg()" (mediaColumns)="mediaColumns($event)">
        </app-content-editable-input-note>
    </div>
</div>