import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttributesIcons, AttributesLxIcons } from '../../../../Enums/attributes-icons';
import { ConstantService } from '../../../../Enums/Constant.service';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../message.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { CustomStorageService } from '../../../services/custom-storage.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from '../../../services/toaster.service';
@Component({
  selector: 'app-lead-mapping',
  templateUrl: './lead-mapping.component.html',
  styleUrls: ['./lead-mapping.component.scss']
})
export class LeadMappingComponent implements OnInit {
  @Input() bucketInfo : any 
  @Input() leadInfo  : any 
  @Input() dashboardUserRole : any=null
  @Input() dashId : any
  leadId:any
  attributesIcons = AttributesIcons;
  lxAttributesIcons = AttributesLxIcons;

  @Output() copyItemToBoard = new EventEmitter<any>();


  dashBoardsList:any
  folderArr:any={}
  mappingAreaLoaded:boolean=false

  moveToAnotherBoard:boolean=false
  @Output() transferCompleted = new EventEmitter<any>();
  currentDashBoardName:string
  newDashBoardName:string

  //variables for new board
  selectedDashId: any=null
  newLeadNonCustomArr:any[]
  newarrayOfCustomFiled:any

  //data
  priortyArr:any
  newBucketArr:any
  newPhaseArr:any
  orgFiltersUsersArr: any=[];


  //new Suggestions
  currentDashboardData:any
  newDashboardData:any
  currentDashboardCustomFields:any[]
  newDashboardCustomFields:any[]
  mappingArr:any[]
  newLeadInfo={}
  mappedKeysArr:any[]=[]


  isAdminForMappingBoard: boolean= false
  constructor(
    private ngbModalService: NgbModal,
    private httpTransfer : HttpTransferService,
    public commonUtils: CommonUtils,
    private msgservice: MessageService,
    public dashboardUtils: DashboardUtilsService,
    private customStorageService:CustomStorageService,
    private toaster : ToasterService,
    private constantService: ConstantService


  ) { }

  async ngOnInit(){
    this.leadId=this.leadInfo._id
    await this.getDashboardList()
  }


  async getDashboardList(){
    this.httpTransfer.getDashBoardForAdmin().subscribe((result:any) => {
      this.dashBoardsList = result.result.dashboards.filter(e=> e.type!="BOARD_GRAPH" && e.dashboard_id!=this.dashId);

      console.log(this.dashBoardsList)
    })
    let input={"type": ["DASHBOARD_FOLDER"],"get_my_folders": true}
    this.httpTransfer.getDashboardFolders(input).subscribe((res : any) => {
      if(res.status == 200){
        res.result.folders.forEach(l=>{
          this.folderArr[l._id] = l
        })
        }        
    })
  }




  async getPossibleMapping(){
    this.mappingAreaLoaded=false
    this.newDashboardData = await this.dashboardUtils.getAndSyncAllDashboardData([this.selectedDashId],true)
    this.currentDashboardData = await this.dashboardUtils.getAndSyncAllDashboardData([this.dashId],true)

    // this.newDashboardData = await this.dashboardUtils.getDashboardCustomForms(this.selectedDashId)
    // this.currentDashboardData = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
    this.newBucketArr= this.newDashboardData[this.selectedDashId].BUCKET

    if(this.newDashboardData[this.selectedDashId]?.DASHBOARD_INFO?.sprint_enabled){
      this.newPhaseArr=this.newDashboardData[this.selectedDashId].SPRINT
    }
    console.log(this.dashId,this.currentDashboardData)
    console.log(this.selectedDashId,this.newDashboardData)
    let userRole = this.newDashboardData[this.selectedDashId]?.DASHBOARD_INFO?.role || [];
    this.isAdminForMappingBoard= await this.commonUtils.checkUserHaveEditAccessInDashboard(userRole,false)
    this.currentDashboardCustomFields=JSON.parse(JSON.stringify(this.currentDashboardData[this.dashId]['CUSTOM_FORM']));
    this.newDashboardCustomFields=JSON.parse(JSON.stringify(this.newDashboardData[this.selectedDashId]['CUSTOM_FORM']));

    // this.newLeadNonCustomArr=this.getNonCustomArr(this.newDashboardData)
    let currentDash=this.currentDashboardData[this.dashId]?.DASHBOARD_INFO
    this.currentDashBoardName=currentDash.dashboard_name
    let newtDash=this.dashBoardsList.filter(dash=> dash.dashboard_id==this.selectedDashId)
    this.newDashBoardName=newtDash[0].dashboard_name
    this.mappingArr=this.makeMappingJason()

    this.mappingAreaLoaded=true
  }



  makeMappingJason(){
    let mappingArr=[]

    console.log(this.currentDashboardCustomFields,this.newDashboardCustomFields)
    for(let i=0;i<this.currentDashboardCustomFields.length;i++){
      let customField=this.currentDashboardCustomFields[i]
      if(customField.type=='Dropdown' || customField.type=='Radiobutton'){
        let allowedFields=this.newDashboardCustomFields.filter(ele=> (ele.type=='Dropdown' || ele.type=='Radiobutton') && (!ele.locked || (ele.locked && this.isAdminForMappingBoard))&& ele.hidden!=='ALWAYS' && ele.is_numeric==customField.is_numeric)
        if(allowedFields.length){
          this.checkForDirectMapping(customField,allowedFields,mappingArr)
        }
      }
      else if(customField.type=='Checkbox' || customField.type=='MultiSelect'){
        let allowedFields=this.newDashboardCustomFields.filter(ele=> (ele.type=='Checkbox' || ele.type=='MultiSelect') && (!ele.locked || (ele.locked && this.isAdminForMappingBoard)) && ele.hidden!=='ALWAYS' && ele.is_numeric==customField.is_numeric)
        if(allowedFields.length){
          this.checkForDirectMapping(customField,allowedFields,mappingArr)
        }
      }
      else if(['SingleImage','SingleVideo','SingleAudio','SingleFile','SingleUser','Url'].includes(customField.type)){
        let otherType= customField.type=='Url' ? 'MultiUrl' : customField.type=='SingleUser' ? 'MultiUser' : customField.type=='SingleImage' ? 'MultipleImage' : customField.type=='SingleVideo' ? 'MultipleVideo' : customField.type=='SingleAudio' ? 'MultipleAudio' : customField.type=='SingleFile' ? 'MultipleFile' :''
        let allowedFields=this.newDashboardCustomFields.filter(ele=> (ele.type==customField.type || ele.type==otherType) && (!ele.locked || (ele.locked && this.isAdminForMappingBoard)) && ele.hidden!=='ALWAYS')
        if(allowedFields.length){
          this.checkForDirectMapping(customField,allowedFields,mappingArr)
        }
      }
      else if(customField.type=='IPAddress'){
        console.log(this.newDashboardCustomFields)
        let allowedFields=this.newDashboardCustomFields.filter(ele=> (ele.type==customField.type) && (!ele.locked || (ele.locked && this.isAdminForMappingBoard)) && ele.settings=='Manual' && ele.hidden!=='ALWAYS')
        if(allowedFields.length){
          this.checkForDirectMapping(customField,allowedFields,mappingArr)
        }
      }
      else if(customField.type!='Button' && customField.type!='Mirror' && customField.type!='TimeTracker' ){
        let allowedFields=this.newDashboardCustomFields.filter(ele=> (ele.type==customField.type) && (!ele.locked || (ele.locked && this.isAdminForMappingBoard))&& ele.hidden!=='ALWAYS')
        if(allowedFields.length){
          this.checkForDirectMapping(customField,allowedFields,mappingArr)
        }
      }

    }

    return mappingArr
  }


  checkForDirectMapping(customField,allowedFields,mappingArr){
    let obj={}
    obj['display_key']=customField.display_key    
    obj['key']=customField.key
    obj['type']=customField.type
    obj['mappingOptions']=allowedFields   
    let directMappedKeyIndex= allowedFields.findIndex(ele=> ele.key==customField.key && ele.type==customField.type) 
    let directMappedDisplayKeyIndex= allowedFields.findIndex(ele=> ele.display_key==customField.display_key && ele.type==customField.type)
    obj['mapping_display_key']= directMappedKeyIndex > -1 ? allowedFields[directMappedKeyIndex].display_key : directMappedDisplayKeyIndex > -1 ? allowedFields[directMappedDisplayKeyIndex].display_key : null  
    obj['mapping_key']= directMappedKeyIndex > -1 ? allowedFields[directMappedKeyIndex].key : directMappedDisplayKeyIndex > -1 ? allowedFields[directMappedDisplayKeyIndex].key : null
    obj['mapping_type']= directMappedKeyIndex > -1 ? allowedFields[directMappedKeyIndex].type : directMappedDisplayKeyIndex > -1 ? allowedFields[directMappedDisplayKeyIndex].type : null
    // if(directMappedIndex > -1){
    //   this.mappedKeysArr.push(allowedFields[directMappedIndex].key)
    // }
    this.mappedKeysArr.push(obj['mapping_key'])
    mappingArr.push(obj)
  }

  mappingChanged(field,index,event){
    if(field.mapping_key!=null){
      let mappedFieldIndex=field.mappingOptions.findIndex(ele=> ele.key==field.mapping_key)
      if(mappedFieldIndex>-1){
        field.mapping_display_key=field.mappingOptions[mappedFieldIndex].display_key
        field.mapping_type=field.mappingOptions[mappedFieldIndex].type
      }
    }
    else{
      field.mapping_display_key=null
      field.mapping_type=null
    }
    this.mappedKeysArr[index]=field.mapping_key ? field.mapping_key : null
  }


  proceedToCopy(){
    console.log(this.leadInfo)

    let buckIndex=this.newBucketArr.findIndex(buck=> buck.name==this.leadInfo.bucket_name)
    this.newLeadInfo['bucket_id']=buckIndex >-1 ?   this.newBucketArr[buckIndex]._id   : this.newBucketArr[0]._id

    this.newLeadInfo['title']=this.leadInfo.title
    this.newLeadInfo['priority']=parseInt(this.leadInfo.priority)
    this.newLeadInfo['assigned_to']=this.leadInfo.assigned_to
    this.newLeadInfo['start_date']=this.leadInfo.start_date
    this.newLeadInfo['end_date']=this.leadInfo.end_date
    this.newLeadInfo['lead_colloborators_users']=this.leadInfo.lead_colloborators_users
    this.newLeadInfo['dashboard_id']=this.leadInfo.dashboard_id


    if(this.leadInfo.sprint_id  && this.currentDashboardData[this.dashId]?.DASHBOARD_INFO?.sprint_enabled && this.newDashboardData[this.selectedDashId]?.DASHBOARD_INFO?.sprint_enabled){
      let phaseIndex=this.newPhaseArr.findIndex(phase=> phase.name==this.leadInfo.sprint_name)
      this.newLeadInfo['sprint_id']=phaseIndex > -1 ?   this.newPhaseArr[phaseIndex]._id   : null
    }
    else{
      this.newLeadInfo['sprint_id']=null
    }

    if(this.leadInfo.tag_id && this.leadInfo.tag_id.length){
      let newTagArr=this.newDashboardData[this.selectedDashId].TAG
      let oldTagArr=this.currentDashboardData[this.dashId].TAG
      let includedTags=oldTagArr.filter(ele=> this.leadInfo.tag_id.includes(ele._id))
      let newLeadTag=[]
      for(let t=0;t<includedTags.length;t++){
        let tagIndex=newTagArr.findIndex(tag=> tag.name==includedTags[t].name)
        if(tagIndex>-1){
          newLeadTag.push(newTagArr[tagIndex]._id)
        }
      }
      this.newLeadInfo['tag_id']=newLeadTag
    }
    else{
      this.newLeadInfo['tag_id']=null
    }

    let customFields={}
    for(let j=0;j<this.mappingArr.length;j++){
      let mapValue=this.mappingArr[j]
      if(mapValue.type && mapValue.mapping_type){

        if(['Radiobutton','Dropdown'].includes(mapValue.type)){
          let mappingField=this.newDashboardCustomFields.filter(ele=>ele.key==mapValue.mapping_key)
          if( this.leadInfo.custom_fields[mapValue.key]!=null && mappingField[0].permissible_values?.includes(this.leadInfo.custom_fields[mapValue.key])){
            customFields[mapValue.mapping_key]=this.leadInfo.custom_fields[mapValue.key]
          }
        }
        else if(['Checkbox','MultiSelect'].includes(mapValue.type) && ['Checkbox','MultiSelect'].includes(mapValue.mapping_type)){
            let mappingField=this.newDashboardCustomFields.filter(ele=>ele.key==mapValue.mapping_key)
            if(this.leadInfo.custom_fields[mapValue.key]!=null && this.leadInfo.custom_fields[mapValue.key].length){
              let permissibleValues=mappingField[0].permissible_values
              let selectedVal=[]
              for(let k=0;k<this.leadInfo.custom_fields[mapValue.key].length;k++){
                let ele=this.leadInfo.custom_fields[mapValue.key][k]
                if(permissibleValues.includes(ele)){
                  selectedVal.push(ele)
                }
              }
              customFields[mapValue.mapping_key]=selectedVal
            }        
        }
        else if(['SingleImage','SingleVideo','SingleAudio','SingleFile','SingleUser','Url'].includes(mapValue.type)){
         if(['SingleImage','SingleVideo','SingleAudio','SingleFile','SingleUser','Url'].includes(mapValue.mapping_type)){
          customFields[mapValue.mapping_key]=this.leadInfo.custom_fields[mapValue.key]
         }
         else{
          customFields[mapValue.mapping_key]=[this.leadInfo.custom_fields[mapValue.key]]
         }
  
        }
        else if(mapValue.type=='Board'){
          let mappingFromField=this.currentDashboardCustomFields.filter(ele=>ele.key==mapValue.key)
          let mappingToField=this.newDashboardCustomFields.filter(ele=>ele.key==mapValue.mapping_key)
          if(this.leadInfo.custom_fields[mapValue.key] && mappingFromField[0].connected_board_id==mappingToField[0].connected_board_id ){
            customFields[mapValue.mapping_key]=this.leadInfo.custom_fields[mapValue.key]
          }
  
        }
        else if(mapValue.type==mapValue.mapping_type && this.leadInfo.custom_fields[mapValue.key]!=null){
          customFields[mapValue.mapping_key]=this.leadInfo.custom_fields[mapValue.key]
        }
      }
    }
    for(let k=0;k<this.newDashboardCustomFields.length;k++){
      let newBoardField=this.newDashboardCustomFields[k]
      if(newBoardField.is_default_enabled && !customFields[newBoardField.key]){
        customFields[newBoardField.key]=newBoardField.default_value
      }
    }

    this.newLeadInfo['custom_fields']=customFields

    console.log(this.newLeadInfo)
    let input={
      'newLeadInfo':this.newLeadInfo,
      'selectedDashId':this.selectedDashId
    }
    this.copyItemToBoard.emit(input)

  }



}