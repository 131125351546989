<div class="channelTemplateBody">
  <div style="height: 96%;">
    <div class="w-100" style="height:100%;" *ngIf="!createEnable && !updateEnable">
      <!--Header-->
      <div class="templateHeader">
        <h4 class="modal-title" id="modal-basic-title" *ngIf="!personalTemplates">{{'whatsapp.boardMessageTemplates' | translate}}</h4>
        <h4 class="modal-title" id="modal-basic-title" *ngIf="personalTemplates">{{'whatsapp.personalMessageTemplates' | translate}}</h4>  
        
        <div class="createTemplateDiv">

            <span class="addCreateConnectBtn btn py-0" (click)="templateName='';templateMedia=[];attachment=[];fileEvent=[];columnIndex=0;columnsJson={};templateMessage='';subject='';emailArrTo=[];emailArrCc=[];emailArrBcc=[];createEnable=true;">
                <span class="iconBox"><img src="../../../../assets/1x/add-icon-3571de-10px.svg"></span>
                <span>{{'whatsapp.createTemplate' | translate}}</span> 
            </span>
            <button type="button" class="close" aria-label="Close" (click)="closeModal.emit();viewOnly=false;"><span aria-hidden="true">&times;</span></button>    
        </div>
      </div>
      <!--Template List-->
      <div class="templateContainer">
        <div class="templatecontent">
          <div *ngFor="let template of allTemplates; let i = index">
              <div class="templateEmail cursorPointer" (click)="editTemplate(template)">
                  <div class="templateEmailName">
                    <label>{{template.template_name}} </label>
                  </div>
                  <div class="templateEmailOptions" style="width: 3%;height:100%">
                      <i  class="fa fa-trash" aria-hidden="true" (click)="$event.stopPropagation();deleteTemplate(template?._id,i)"></i>
                  </div>
                </div>
           </div>
        </div>
    </div>
  </div>

  <div class="w-100" style="height:100%;" *ngIf="createEnable || updateEnable">
    <div class="templateHeader">
      <div class="templateHeaderInput">
        <i *ngIf="!isTemplateViewCall" class="fa fa-long-arrow-left" aria-hidden="true" (click)="createEnable=false;updateEnable=false;"></i>
        <div style="width:90%;display: flex;align-items: center;">
          <span *ngIf="!editTemplateName" class="modal-title chartNameElipsis">{{templateName}}</span>
          <input *ngIf="editTemplateName" type="text" class="form-control templateNameInput" placeholder="Template Name" (keyup.enter)="editTemplateName=false;"
          (blur)="editTemplateName=false;" [ngModelOptions]="{standalone: true}" [(ngModel)]="templateName">
          <span class="pl-1" *ngIf="!editTemplateName && !viewOnly" (click)="editTemplateName=true" >
            <app-dashboard-icon [size]="20" [name]="'rename'" [iconJson]="attributesIcons"></app-dashboard-icon>
          </span>
        </div>
        <button style="width: 8%;" type="button" class="close" aria-label="Close" (click)="closeModal.emit()"><span aria-hidden="true" style="float: right;"><img src="../../../../assets/1x/cross-icon.svg"></span></button>
      </div>
   </div>
   <div class="templateContainer" style="height:calc(100% - 100px);">
        <ng-container *ngIf="type=='EMAIL'">
          <div class="emailParent">
            <div style="display: flex;width:5%;" class="subjectFont"><span>To :</span></div>
            <div class="position-relative" style="display: flex;width:88%;flex-wrap: wrap;">
              <ng-container *ngFor="let item of emailArrTo;let i= index">
                <div class="chip mt-0" class="chip mt-0" >
                  <ngx-avatars class="mr-1" [name]="item.name" size="20" initialsSize="2" ></ngx-avatars>{{item.email}}
                  <span *ngIf="!viewOnly" class="closebtn" style="margin-left: 3px;" (click)="removeEmail(i,'to')">&times;</span>
                </div>
              </ng-container>
    
              <input type="email" [class.disabled]="viewOnly" class="emailToInput" placeholder="Enter here" [(ngModel)]="emailSetTo" (ngModelChange)="checkEmptyEmail('to')" (keydown.enter)="onEmailsSetKeydown('to')" #emailSetRefTo="ngModel"
              (focus)="openMentionPanel(emailSetRefTo.value,'to')" (keyup)="openMentionPanel(emailSetRefTo.value,'to')" (focusout)="colseMentionPanel();onEmailsSetKeydown('to')">      
              <p class="w-100 mb-0" *ngIf="!validateToEmail">
                <span class="text-danger ls-025x">*{{'itemattributes.enterCorectEmail' | translate}}</span>
              </p> 
              <div class="memtionsDiv" *ngIf="isMentionPanelOpenTo">
                <app-user-list [searchFororgUsersJson]="searchFororgUsersJson" (selectedUser)="selectedUser($event)"></app-user-list>
              </div> 
            
            </div>
            <div style="display: flex;width:9%;flex-wrap: wrap;"> <span style="cursor: pointer;margin-right: 5px;" class="subjectFont"  (click)="enableCC=true" *ngIf="!enableCC">Cc </span><span style="cursor: pointer;" class="subjectFont" (click)="enableBCC=true" *ngIf="!enableBCC">  Bcc</span></div>
          </div>

            <!--CC-->
    <div class="emailParent" *ngIf="enableCC">
        <div style="display: flex !important;width:5%;align-items: center;" class="subjectFont"> <span>Cc :</span></div>
        <div style="display: flex;width:95%;flex-wrap: wrap;position:relative;">
          <ng-container *ngFor="let item of emailArrCc;let i= index">
              <div class="chip mt-0">
                <ngx-avatars class="mr-1" [name]="item.name" size="20" initialsSize="2" ></ngx-avatars>{{item.email}}
                <span *ngIf="!viewOnly" class="closebtn" style="margin-left: 3px;" (click)="removeEmail(i,'cc')">&times;</span>
              </div>
              </ng-container>
              <input type="email" [class.disabled]="viewOnly" class="emailToInput" placeholder="Enter here" [(ngModel)]="emailSetCc" (keydown.enter)="onEmailsSetKeydown('cc')" (ngModelChange)="checkEmptyEmail('cc')" #emailSetRefCc="ngModel"
              (focus)="openMentionPanel(emailSetRefCc.value,'cc')" (keyup)="openMentionPanel(emailSetRefCc.value,'cc')" (focusout)="colseMentionPanel();onEmailsSetKeydown('cc')" [disabled]="templateSettings && ((!isAdmin && !isTemplatePersonal) || isReadOnly)" >  
              <p class="w-100 mb-0" *ngIf="!validateCcEmail">
                <span class="text-danger ls-025x">*{{'itemattributes.enterCorectEmail' | translate}}</span>
              </p>    
              <div class="memtionsDiv" *ngIf="isMentionPanelOpenCc">
                <app-user-list [searchFororgUsersJson]="searchFororgUsersJson" [viewOnly]="viewOnly" (selectedUser)="selectedUser($event)"></app-user-list>
              </div> 

        </div>
    </div>

    <!--BCC-->  
    <div class="emailParent" *ngIf="enableBCC">
        <div style="display: flex !important;width:5%;align-items: center;" class="subjectFont"><span>Bcc :</span></div>

        <div style="display: flex;width:95%;flex-wrap: wrap;position:relative;">
          <ng-container *ngFor="let item of emailArrBcc;let i= index">
              <div class="chip mt-0">
                  <ngx-avatars class="mr-1" [name]="item.name" size="20" initialsSize="2" ></ngx-avatars>{{item.email}}
                <span *ngIf="!viewOnly" class="closebtn" style="margin-left: 3px;" (click)="!viewOnly?removeEmail(i,'bcc'):''">&times;</span>
              </div>
              </ng-container>
              <input type="email" [class.disabled]="viewOnly"  class="emailToInput" placeholder="Enter here" [(ngModel)]="emailSetBcc" (keydown.enter)="onEmailsSetKeydown('bcc')" (ngModelChange)="checkEmptyEmail('bcc')" #emailSetRefBcc="ngModel"
              (focus)="openMentionPanel(emailSetRefBcc.value,'bcc')" (keyup)="openMentionPanel(emailSetRefBcc.value,'bcc')" (focusout)="colseMentionPanel();onEmailsSetKeydown('bcc')" >      
              <p class="w-100 mb-0" *ngIf="!validateBccEmail">
                <span class="text-danger ls-025x">*{{'itemattributes.enterCorectEmail' | translate}}</span>
              </p>  
              <div class="memtionsDiv" *ngIf="isMentionPanelOpenBcc">
                <app-user-list [searchFororgUsersJson]="searchFororgUsersJson" (selectedUser)="selectedUser($event)"></app-user-list>
              </div> 

        </div>                
    </div>

    <div class="emailModalSubjectArea" style="position:relative;" [class.disabled]="viewOnly">
      <div style="width:100%;display: flex;column-gap: 10px;align-items: center;justify-content: center;">  
      <span class="subjectFont">Subject :</span>
      <app-editor-columns style="flex:1;" [htmlContent]="subject" [tempType]="tempType" [viewOnly]="viewOnly" [dashId]="dashId" [summerNoteEditor]="false" [htmlContent]="subject" [columnsJson]="columnsJson" [columnIndex]="columnIndex" [subject]="subject" [editorText]="templateMessage" (onBlur)="onBlur($event)"></app-editor-columns>
<!--         <input type="text"  class="emailSubjectInput" placeholder="Enter here" [(ngModel)]="subject">
-->        </div>
    </div> 
        </ng-container>
      <div style="flex: 1;">
        <app-editor-columns [dashId]="dashId" [htmlContent]="templateMessage" [tempType]="tempType" [viewOnly]="viewOnly" [columnsJson]="columnsJson" [columnIndex]="columnIndex" [fileEvent]="fileEvent" [mediaArr]="templateMedia" [attachment]="attachment" [subject]="subject" [editorText]="templateMessage" (onBlur)="onBlur($event)"></app-editor-columns>
       </div>
    </div>

  <div style="background-color:#EFF2F7;border-radius: 7px;">
     <div class="editorBottomBar col-12">
      <div style="    height: 50px;
      display: flex;
      align-items: center;">
        <button type="button" class="createBtn" *ngIf="createEnable && !preview" (click)="saveTemplate()" [disabled]="isCreatingEnable"><span *ngIf="isCreatingEnable" class="spinner-border"></span>Create Template</button>
        <button type="button" class="createBtn" *ngIf="updateEnable && !preview" [ngClass]="{'disabled':viewOnly}" (click)="saveTemplate()" [disabled]="isCreatingEnable || viewOnly"><span *ngIf="isCreatingEnable" class="spinner-border"></span>Update Template</button>
        <!-- <button type="button" class="createBtn" *ngIf="preview" (click)="convertHtmlToPdf(templatesToEdit)" [disabled]="isCreatingEnable"><span *ngIf="isCreatingEnable" class="spinner-border"></span>Use template</button> -->
      </div>                        
    </div>
  </div>
  </div>
  </div>
</div>

