
<div class="selection-input-box-email h-100" [class.key-focus]="!isGrid">
  <div class="cell-box-inner">
      <div>
        <div class=" editleadcustominputsrow key-tab" >
          <div class="emailColumn fieldValueBox">
            <div class="fieldValueBox">
              <div style="width:100%;margin-bottom: 5px;" class="mx-0 enableBtn" *ngFor="let entry of item?.multiEmailInput; let i = index; trackBy: trackByFn">
                  <div class="d-flex g-0 mx-0 w-100 d-inline-flex jutsify-end urlTextBox multiUrlBox pr-0" style="align-items: center;" [ngClass]="{'disabled': !editable,'errorBorder':!item.validateToEmail[i]}">
                      <input #multiUrl placeholder="{{'placeholders.enterYourEmailId' | translate}}" [required]="item.mandatory" [readonly]="!editable"
                      (blur)="editable && onValueInput()" (keydown.enter)="multiUrl?.blur();onValueInput()" (input)="validateEmailUrl();"  type="email" (ngModelChange)="multiEmailCall(item)"
                      class="modal_Custom_Lead_Input border-0  ls-025x inpt" style="height: 30px !important;background-color: transparent;color: black;" [(ngModel)]="item.multiEmailInput[i]" #url="ngModel"/>
                      <div class=" d-inline-flex" style="column-gap: 10px;">
                        <span *ngIf="editable && (item?.type==='MultipleEmail' && !isGrid) && item.multiEmailInput?.length > 1" (click)="removePopoverRef=removepopup" [ngbPopover]="confirmationpopup" #removepopup="ngbPopover" [placement]="['right','auto']" container="body" [autoClose]="'outside'" [disabled]="!editable" role="button" class="iconBox"><i class="fa fa-times" aria-hidden="true"></i>
                        </span>   
                        <div *ngIf="!isAutomationCall && (item?.type==='MultipleEmail') && i==0 && item.multiEmailInput[i]!=''" class="contract-primary-email mr-1"><span style="padding-left: 3px;">Primary</span></div>   
                      </div>
                      <div *ngIf="isGrid" style="margin: 2px;">
                        <div *ngIf="editable" (click)="optionGrid.open()" class="right-box absolute" role="button">
                          <span class="btn btn-light text-dark px-2 py-1 rounded" style="float: right;">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </span>
                        </div>  
                        <span #optionGrid="ngbPopover" [ngbPopover]="copyPrimary" popoverClass="modifiedPopover" [autoClose]="true" container="body"></span>
                        <span #gridDelete="ngbPopover" [ngbPopover]="confirmationpopup" [autoClose]="'outside'" container="body"></span>
                          <ng-template #copyPrimary>
                            <div class="p-1" style="width: 10rem;">
                              <div role="button" class="btn btn-light text-dark py-1 px-3 mb-1 w-100 bg-white rounded text-left" *ngIf="entry" (click)="copyToClipboard(entry);optionGrid?.close()">
                                <i class="fa fa-clone icons mr-3" aria-hidden="true" ></i><span class="f-s-r">Copy</span>
                              </div>
                              <div *ngIf="editable" role="button" (click)="gridDelete.open()" class="btn btn-light text-dark py-1 px-3 w-100 bg-white rounded text-left">
                                <i class="fa fa-trash icons mr-3"  aria-hidden="true"></i><span class="f-s-r">Delete</span>
                              </div>
                              <div *ngIf="editable && i!==0" role="button" (click)="makePrimary(i);optionGrid?.close()" class="btn btn-light text-dark py-1 px-3 w-100 bg-white rounded text-left">
                                <i class="fa fa-pencil icons mr-3"  aria-hidden="true"></i><span class="f-s-r">Make Primary</span>
                              </div>
                            </div>
                          </ng-template>
                      </div>
                  </div>
                  <ng-template #confirmationpopup>
                    <div>
                      <div class="p-2">Are you sure you want to remove email.</div>
                      <div style="justify-content: center;display: flex;align-items: center;column-gap: 20px;">
                        <span class="btn btn-success" style="border-radius: 10px;" (click)="removeEmail(item, i)">{{'multiSelect.yes' | translate}}</span>
                        <span class="btn btn-danger" style="border-radius: 10px;" (click)="removePopoverRef?.close()" >{{'multiSelect.no' | translate}}</span> 
                      </div>
                    </div>
                  </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="fieldValueBox mr-1" *ngIf="item?.type==='MultipleEmail' && !isGrid">
          <div *ngIf=" allEmailsPresent()" class="f-l-b" style="float: right;border-radius: 4px;width: 70px;justify-content: center;color: gray;line-height: 24px;"  role="button" (click)="editable && addNewMultiEmail(item,true);multiEmailCall(item)"><span class="iconBox f-l-m" style="color: gray;">Add more</span></div>
        </div>
        </div> 
  </div>
</div>

