import { ToasterService } from '../services/toaster.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, IterableDiffers, OnInit, Output, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal,NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MqttmessagingService } from './../services/mqttmessaging.service';;
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { AttributesIcons, commonIcons } from '../../Enums/attributes-icons';
import { ConstantService } from '../../Enums/Constant.service';
import { MessageService } from '../message.service';
import { CommonUtils } from '../services/CommonUtils.service';
import { CustomStorageService } from '../services/custom-storage.service';
import { DashboardUtilsService } from '../services/dashboard-utils.service';
import { HttpTransferService } from '../services/httpTransfer.service';
import { SortingutilsService } from '../sortingutils.service';
import { MediaSmIcons } from '../../Enums/media-sm-icons';
import { FormulautilsService } from '../formulautils.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { BasicUtils } from 'app/services/basicUtils.service';

declare var $: any;
@Component({
  selector: 'app-board-view',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './board-view.component.html',
  styleUrls: ['./board-view.component.scss']
})

export class BoardViewComponent implements OnInit {
  @Input() arrayOfCustomFiled = [];
  @Input() selectedItemForColumn: any;
  @Input() selectedDashId: number;
  @Input() multipleBoardIds : any;
  @Input() selectedDashName: string;
  @Input() filterDataObj: any;
  @Input() isSort: any;
  @Input() users = [];
  @Input() bucketArray = [];
  @Input() bucketSeqArray = [];
  @Input() bulkSelectionMode:boolean;
  @Input() dashBoardSprint = [];
  @Input() multiSelectedLeadIds = [];
  @Input() tagsCorrespondingToDash = [];
  @Input() tagsCorrespondingToDashMap = {};
  @Input() dashboardUserRole: any;
  @Input() currentViewId: any;
  @Output() emitBulkSelectionMode = new EventEmitter<any>();
  @Output() emitLeadQuerySucceeded = new EventEmitter<any>();
  @Output() onItemFilterChanged = new EventEmitter<any>();
  @Output() onItemOpen = new EventEmitter<any>();
  @Input() viewType:any='BoardView';
  @Input() disabledFilterSubscription:boolean=false;
  funnelarr = [];
  currentPageNo: number;
  selectedCustFieldKeys = [];
  mqttSubscription: any;
  arrayOfGroupField = [];
  scrollMap = new Map();
  interval: NodeJS.Timeout;
  clickedGroupId: any;
  currentLeadId: any;
  isDragging: any;
  currentGroupId: string;
  leadSeqNumber: string;
  currentGroup: any;
  selectedCurrentLead: any;
  commentSectionType: string;
  ngbLeadModalRef: any;
  quickLeadMouseClick: boolean;
  clickedInsideTheTextArea: boolean;
  isBucketFollow: boolean;
  bulkLeadInfo = [];
  bulkGroupInfo = [];
  bulkSelectionData = [];
  agingTimeInput: Date;
  leadModalRef: BsModalRef;
  selectedStartDate: Date;
  selectedEndDate: Date;
  previousGroup: any;
  dragbuckind: any;
  leadId: any;
  groupIdScroll: string;
  event: any;
  destinationGrouptId: any;
  clickQuickLeadGrouptId: any="";
  creationtext = "";
  isMouseClickedOutSide: boolean;
  isOpenedClickedLeadDropDown: any;
  enterCounter = 0;
  customFieldsArray: any[] = [];
  intervalId: NodeJS.Timeout;
  isScrollingDown: any;
  isScrollingUp: any;
  isOpenAddLead = false;
  filterSub: Subscription;
  showAllLeadChildrenFlag: boolean = false;
  @Output() emitAddCustomLead = new EventEmitter<any>();  
  @Input() selectedColumnsOptions: any;
  @Output() emitUpdatedBucketSeq = new EventEmitter<any>();
  @Input() isDashCollapse:boolean=false;
  columnEventSub: Subscription;
  showChildrenSub: Subscription;
  lock: boolean = false; 
  isReadOnly: boolean;
  isLeadInTemplate:boolean=false;
  isInternalBoardReadOnly:boolean=false;
  allowedBuckets: any[] = []; 
  attributesIcons = AttributesIcons;
  commonIcons = commonIcons;
  mirrorColumnsJson = {};
  orgUsersJson: any;
  priorityJson: {};
  iterableDiffer: any;
  iterableDifferForPhase: any;
  connectedItemsIdsArr: any[] = [];
  showLoader:boolean=false
  currencyArr: any;
  intervalIdForSeconds: NodeJS.Timeout;
  MediaSmIcons = MediaSmIcons;
  bulkSelectionModeSub: Subscription;
  currentItemIndex: any;
  mediaArr: any;
  indexForPreview: any;
  modalRef: any;
  currencyJson: {};
  filterLock: boolean;
  sortBy: string;
  sortByOrder: string;
  itemAttributesObj: any;
  isSelectedAll:boolean=false
  multiSelectGroupArray:any[]=[]
  excludeInMultiselectGroup:any[]=[]
  groupKeyJson:any;
  selectedGroupBy:any;
  wssMessagesQueue=[]
  updatesFromWssProcessing: boolean;
  formulaSub: Subscription;
  isFormulaUpdated: boolean=false;
  formulasOptionsFields : any;
  activeTrackerObj:any[]=[]
  userId: string;
  customField:any[]=[]
  popoverRef:any;
  leadUrlText = environment.leadUrlText;
  commentPopoverLeadIds:any=[]
  commentLead:any={}
  parentChildLeads: any={};
  phaseName:any=''
  phaseStartDate:any=''
  phaseEndDate:any=''
  isInValidDetails:boolean=false
  phaseData:any=''
  transferFromPhase: any;
  sprintName: any;
  incompleteItemsCount: number;
  completedItemsCount: number;
  transferPhaseList: any[];
  itemPreviewData:any= [];
  getdragLeadinfoForCalendar:any;
  isPopOverOpen:boolean=false;
  dashboardInfo: any={};
  dashboardData:any= {};
  itemPannelPreviewData: any=[];
  collapseCards:boolean=false
  boardDataListInfo:any={dashboardList:[]};
  @ViewChild('addcustomleadsplus') addcustomleadsplus: NgbModalRef;
  createLeadBucketIds: any=[];

  constructor(
    private msgservice: MessageService, 
    private httpTransfer: HttpTransferService,
    public commonUtils: CommonUtils,
    public constantService: ConstantService,
    private sortingUtils: SortingutilsService,
    private formulaUtils : FormulautilsService,
    private toaster: ToasterService,
    public cdr: ChangeDetectorRef,
    private ngbModalService: NgbModal,
    private MqttmessagingService: MqttmessagingService,
    private customStorageService: CustomStorageService,
    private basicUtils:BasicUtils,
    private dashboardUtils: DashboardUtilsService,
    private iterableDiffers: IterableDiffers
  ) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
    this.iterableDifferForPhase = iterableDiffers.find([]).create(null);
    this.currentPageNo = 1;
    this.priorityJson = this.commonUtils.priorityJson;
    this.listenForMqttMessages()
    this.showChildrenSub = this.msgservice.getShowChildrenLeadStatus().subscribe((res : any) => {
      this.setChildrenLeadShowHideForEachLead(res)
    })
    this.bulkSelectionModeSub = this.msgservice.getBulkSelectionMode().subscribe((res : any) => {
      this.bulkSelectionMode = res;
      if(!this.bulkSelectionMode) {
        this.clearBulkSelectionData();
      }
    })
    // formula subscriber
    this.formulaSub = this.msgservice.getUpdateVeiwForFormula().subscribe(data => {     
        if(this.funnelarr.length>0){
          this.isFormulaUpdated = true;
          if(this.viewType=='GraphView'){
             this.isFormulaUpdated = false
            this.updateFormulasValues()
          }
        }
    })
  }

  @HostListener("click", ["$event"])
  mouseEvent(event: MouseEvent) {
    this.checkForMouseEvent();
  }
  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    if(document.getElementById('boardViewPopupsId') && (event.deltaY < 0 || event.deltaY > 0 || event.deltaX < 0 || event.deltaX > 0)) {
      document.getElementById('boardViewPopupsId').parentElement.parentElement.remove();
    } 
  }

  onScroll = (event: any): void => {
    if(this.groupIdScroll)
    this.scrollMap.set(this.groupIdScroll,event.target.scrollTop);
   }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    var path = event['path'] || (event.composedPath && event.composedPath());
    var groupRef = path[path?.length - 15];
    var groupRefCard = path[path?.length - 13];
    if (groupRef != undefined && groupRef.id != '' && groupRefCard != undefined && groupRefCard != '') {
      let mousePosFrmBottom = $(window).height() - event.pageY;
      if (mousePosFrmBottom < 100) {
        this.isScrollingUp = false;
        this.isScrollingDown = true;
        this.groupIdScroll = groupRef.id;
      } else if ($(window).height() - mousePosFrmBottom < 180 && $(window).height() - mousePosFrmBottom > 130) {
        this.isScrollingUp = true;
        this.isScrollingDown = false;
        this.groupIdScroll = groupRef.id;
      } else {
        this.isScrollingUp = false;
        this.isScrollingDown = false;
        this.groupIdScroll = groupRef.id;
      }
      if(this.isDragging && ((!this.allowedBuckets.includes(groupRefCard.id.split('_')[1]) && this.selectedGroupBy?.group_by=='bucket') || this.filterDataObj['sort_params'][0]['sort_by'] != ConstantService.constant.SORT_BY_INDEX)) {
          groupRef.classList.add('sortedDrop');
          groupRefCard.classList.add('addBorderTextOnSortedBucket');
          let dropPera='dropPera'+ groupRefCard.id.split('_')[1]
          if(!this.allowedBuckets.includes(groupRefCard.id.split('_')[1]) && this.selectedGroupBy?.group_by=='bucket'){
            dropPera='groupDropPera' + groupRefCard.id.split('_')[1]
          }
          $('#'+dropPera)?.removeClass('d-none');
          this.scrollMap.forEach((ele, key) => {
            if (this.groupIdScroll != key) {
              $('#' + key).removeClass('sortedDrop');
              let groupCardId = $('#' + key).parent().parent().attr('id');
              $('#dropPera' + groupCardId?.split('_')[1]).addClass('d-none');
              $('#groupDropPera' + groupCardId?.split('_')[1]).addClass('d-none');
              $('#' + groupCardId).removeClass('addBorderTextOnSortedBucket');
            }
          });
      } 
    }
  }

  groupScrollDown() {
      this.intervalId = setInterval(() => {
        if(this.filterDataObj && this.filterDataObj['sort_params']['sort_by'] === ConstantService.constant.SORT_BY_INDEX) {
          if(this.isScrollingDown && this.isDragging) {
            let ele = $("#" + this.groupIdScroll);
            ele[0].scrollBy({ 
              top:  this.scrollMap.get(this.groupIdScroll),
              left: 0, 
              behavior: 'smooth' 
            });
            this.scrollMap.set(this.groupIdScroll,this.scrollMap.get(this.groupIdScroll)+150)
          } else if(this.isScrollingUp && this.isDragging){
            let ele = $("#" + this.groupIdScroll);
            ele[0].scrollBy({ 
              top:  -this.scrollMap.get(this.groupIdScroll),
              left: 0, 
              behavior: 'smooth' 
            });
            if(this.scrollMap.get(this.groupIdScroll)>150) {
              this.scrollMap.set(this.groupIdScroll,this.scrollMap.get(this.groupIdScroll)-150)
            }
          }
        }
      }, 10);
  }

  checkForMouseEvent() {
      if (this.isMouseClickedOutSide) {
        if (this.isOpenedClickedLeadDropDown != undefined) {
          this.isOpenedClickedLeadDropDown.copyLinkPopUp = false;
          this.isOpenedClickedLeadDropDown.tagsPopup = false;
        }
      }
      this.isMouseClickedOutSide = !this.isMouseClickedOutSide;
  }

  listenForMqttMessages() {
    this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
      if (message) {
        this.wssMessagesQueue.push(message)
        let random = Math.floor(Math.random() * 100)
        setTimeout(() => {this.updatesFromWss(false).then()},random )
      }
    });
  }
  async updatesFromWss(isRecursion) {
    if(this.updatesFromWssProcessing && !isRecursion){ return }//To Handle Race condition. Basically if method has called itself, then it is not locked for it
    if(this.wssMessagesQueue.length==0){
      this.updatesFromWssProcessing = false
      return
    }    
    this.updatesFromWssProcessing = true
    let message = JSON.parse(this.wssMessagesQueue[0]);
    const msgDashboardId = message?.additional_attributes?.dashboard_id
    let dashIds=this.multipleBoardIds.reduce((arr,id)=>{
      return[...arr,...(this.dashboardData[id]?.CUSTOM_FORM?.reduce((list,data)=>{if(data.type=='Board' && data.connected_board_id){list.push(data.connected_board_id)}return list},[]) || [])]},
    [])
    if(this.multipleBoardIds.length==1 &&  !this.multipleBoardIds.includes(msgDashboardId) || dashIds?.includes(msgDashboardId)) {
      if (message[ConstantService.constant.ACTIVITYTYPEKEY] == ConstantService.constant.UPDATEACTIVITYTYPE || message[ConstantService.constant.ACTIVITYTYPEKEY] == "DELETE") {
        if (message[ConstantService.constant.OBJECTTYPEKEY] ==ConstantService.constant.LEADOBJECTTYPE) {
          await this.handleExternalBoardConnectedUpdateItem(message)
        }
      }
    }
    if ((ConstantService.constant.ACTIVITYTYPEKEY in message) && this.multipleBoardIds?.includes(msgDashboardId)) {
      if (message[ConstantService.constant.ACTIVITYTYPEKEY] == ConstantService.constant.ADDACTIVITYTYPE) {
        if (message[ConstantService.constant.OBJECTTYPEKEY] == ConstantService.constant.LEADOBJECTTYPE) {
            await this.handleLeadAddTypeOperation(message);
        }
      }
      if (message[ConstantService.constant.ACTIVITYTYPEKEY] == ConstantService.constant.UPDATEACTIVITYTYPE || message[ConstantService.constant.ACTIVITYTYPEKEY] == "DELETE") {
        if (message[ConstantService.constant.OBJECTTYPEKEY] ==ConstantService.constant.LEADOBJECTTYPE) {
            await this.handleLeadUpdateTypeOperation(message);
        }
        if (message[ConstantService.constant.OBJECTTYPEKEY] == 'OTHER') {
            await this.handleLeadUpdateTypeOperation(message);
        }
      }

    }
    if(message[ConstantService.constant.ACTIVITYTYPEKEY]=='ADD' && message[ConstantService.constant.OBJECTTYPEKEY]=='TimeTracker'){
      if(!message.additional_attributes.details.end_time){
        this.activeTrackerObj=[...this.activeTrackerObj,message.additional_attributes.details]
        console.log('updated tracker',this.activeTrackerObj)
      }
    }
    if(message[ConstantService.constant.ACTIVITYTYPEKEY]=='UPDATE' && message[ConstantService.constant.OBJECTTYPEKEY]=='TimeTracker'){
      this.activeTrackerObj=this.activeTrackerObj.filter(element => element._id!=message.additional_attributes.details._id)
      console.log(this.activeTrackerObj)
    }
    this.wssMessagesQueue.splice(0,1)// remove from list
    this.cdr.detectChanges()
    this.updatesFromWss(true)
  }

  handleCommentsCountOnItem(lead){
    let leadGroup=this.getLeadGroupInfo(lead,this.funnelarr);
    let leadInfo=leadGroup['groupInfo']['leadContents']
    for (let j = 0; j < leadInfo.length; j++) {
      if (leadInfo[j]['_id'] === lead._id) {
        leadInfo[j]= this.commonUtils.calcuateCommentCount(leadInfo[j],lead)
      }
    }
  }

  async handleLeadAddTypeOperation(message) {
    let leadDetails = message?.additional_attributes?.details;
    let newLeadInfo = this.getItemJson(leadDetails)
    let groupInfo = this.getLeadGroupInfo(newLeadInfo, this.funnelarr);
    if(this.filterDataObj?.apply_formula)await this.updateFormulaField(message,newLeadInfo,groupInfo,'Add')
    console.log(this.filterDataObj,newLeadInfo)
    let sortJson = await this.sortingUtils.getIndexForNewLead(newLeadInfo,this.filterDataObj,groupInfo["groupInfo"]["leadContents"],this.selectedDashId,this.viewType=='CalenderView');
    console.log(sortJson)
    let newLeadIndex=sortJson['index'];
    sortJson['leadsData']?.forEach(lead=>{this.insertLeadAtParticularIndex(lead,newLeadIndex,sortJson['totalRecords']);newLeadIndex++;})      
  }

  async handleExternalBoardConnectedUpdateItem(message) {
    let leadDetails = message?.additional_attributes?.details;
    this.callbackFunctionForConnectedItemJson(leadDetails)    
  }

  async handleLeadUpdateTypeOperation(message) {
    let leadDetails = message?.additional_attributes?.details;
    let updateKey=message?.additional_attributes?.updated_keys || []
    // for unread comment update but not check on same time comment details update
    if(updateKey && updateKey.includes('read_comment_details') && !updateKey.includes('comment_details')){
      this.handleCommentsCountOnItem(leadDetails)
      return
    }
    let newLeadInfo = this.getItemJson(leadDetails)
    let groupInfo = this.getLeadGroupInfo(newLeadInfo, this.funnelarr);
    let sortJson = await this.sortingUtils.getIndexForNewLead(newLeadInfo,this.filterDataObj,groupInfo["groupInfo"]["leadContents"],this.selectedDashId,this.viewType=='CalenderView');
    console.log(sortJson)
    let newLeadIndex=sortJson['index']
    // set showChildren if open
    let lead=groupInfo["groupInfo"]["leadContents"]?.filter(item=>item?._id===newLeadInfo['_id']) || [];
    if(lead.length>0){
      newLeadInfo['showChildren'] = lead[0]['showChildren'] ? true : false;
    }  
    if(this.filterDataObj?.apply_formula) await this.updateFormulaField(message,newLeadInfo,groupInfo,"Update")
    this.parentChildLeads[newLeadInfo['_id']]= newLeadInfo
    if (newLeadIndex == -1) {
      let fingGroup=this.funnelarr.find(data=>data?.leadContents?.some(item =>item._id == newLeadInfo['_id']));
      this.removeLeadFromCurrentIndex(newLeadInfo, newLeadIndex);
      let checkGroup=this.funnelarr.filter(groupInfo=>groupInfo?.isDefaultGroup || groupInfo?.isIncludeInFilter || groupInfo?.leadContents?.length>0 || (this.multipleBoardIds.length==1 && this.selectedGroupBy?.type!='Country' && this.selectedGroupBy?.type!='User' || this.selectedGroupBy?.group_by=='priority'))
      if(!checkGroup.length && fingGroup)fingGroup.isDefaultGroup=true
      // enable defalut group if all group are empty
    } else {
      for(let lead of sortJson['leadsData']){
        await this.removeLeadFromCurrentIndex(lead, newLeadIndex);
        await this.insertLeadAtParticularIndex(lead,newLeadIndex,sortJson['totalRecords']);
        newLeadIndex++;
      }
      this.startTimer(sortJson['leadsData'])
      await this.getConnectedBoardItems()
      return
    }
  }

  async getConnectedBoardItems(){
    let connectedItemsIdsArr=this.connectedItemsIdsArr.filter(id=>!this.mirrorColumnsJson[id])
    if(connectedItemsIdsArr.length>0){
      await this.commonUtils.queryConnectedBoardItems(connectedItemsIdsArr,this.arrayOfCustomFiled,this.callbackFunctionForConnectedItemJson.bind(this));
    }
  }

  async updateFormulaField(message?,newLeadInfo?,groupInfo?,actionType?){
    let updatedFormulasData=await this.formulaUtils.getUpdatedFormulaValue(newLeadInfo,groupInfo["groupInfo"],message,this.filterDataObj,this.funnelarr,actionType);
    let groups=this.funnelarr.filter(group=>(Object.keys(updatedFormulasData)).some(key=>key==group.group_id || key=='null' && group.group_id==null))
    groups.forEach(group=>{group['formulaContents']=this.getConvertedFormulaJsonToArray(updatedFormulasData[group?.group_id])})
  }
  
  async removeLeadFromCurrentIndex(newLeadInfo, newLeadIndex) {
    let isPresent=false
    for (let i = 0; i < this.funnelarr.length; i++) {
      for (let j = 0; j < this.funnelarr[i]['leadContents'].length; j++) {
        if (this.funnelarr[i]['leadContents'][j]['_id'] === newLeadInfo['_id']) {
          isPresent=true
        }
      }
      if (this.funnelarr[i]['leadIds']?.includes(newLeadInfo['_id']) || isPresent) {
        isPresent=false
        this.funnelarr[i]["leadContents"] = this.funnelarr[i]["leadContents"].filter(e => e._id !== newLeadInfo._id);
        this.funnelarr[i]['leadIds']=this.funnelarr[i]["leadIds"].filter(id => id !== newLeadInfo._id);
        this.funnelarr[i]["total_records"]=this.funnelarr[i]['leadIds']?.length || 0;
        if(this.funnelarr[i]["total_records"]==0) {
          var index=this.multiSelectGroupArray.indexOf(this.funnelarr[i].group_id)
          if(index>-1){
            this.multiSelectGroupArray.splice(index,1)
          }
          if(!this.multiSelectGroupArray.length){
            this.clearBulkSelectionData()
          }
        }
        if(this.funnelarr[i]["leadContents"].length<10 && (newLeadInfo[this.selectedGroupBy?.key]!=this.funnelarr[i]?.group_id || newLeadIndex<0)) {
          await this.gettingLeadDataForIndividualGroupOnScroll(this.funnelarr[i])
        }
      }
    }
  }

  getLeadGroupInfo(newLeadInfo, funnelarr) {
    let newItemGrouptId = newLeadInfo[this.selectedGroupBy?.key]
    if(this.selectedGroupBy?.group_by=='custom_field'){
      newItemGrouptId=newLeadInfo?.custom_fields && newLeadInfo?.custom_fields[this.selectedGroupBy.key] ? newLeadInfo?.custom_fields[this.selectedGroupBy.key] : null
    }
    let groupInfo=this.funnelarr.find(group=>group.group_id==newItemGrouptId)
    let groupPresentInfo = {present: false,groupInfo: {},leadsLoaded: [],
    };
    if(groupInfo){
      groupPresentInfo["present"] = true;
      groupPresentInfo["groupInfo"] = groupInfo
    }
    return groupPresentInfo;
  }

  async insertLeadAtParticularIndex(lead, newLeadIndex,totalRecords?) {
    if (newLeadIndex != -1) {
      let newLeadInfo=this.getItemJson(lead,true);
      for (let i = 0; i < this.funnelarr.length; i++) {
        if ((this.selectedGroupBy.group_by!='custom_field' && this.funnelarr[i]["group_id"] == newLeadInfo[this.selectedGroupBy.key]) || (this.selectedGroupBy.group_by=='custom_field' && this.funnelarr[i]["group_id"]==newLeadInfo['custom_fields'][this.selectedGroupBy.key])) {
          if(this.bulkSelectionMode && (this.multiSelectGroupArray.length && this.multiSelectGroupArray.includes(this.funnelarr[i]["group_id"]))){
            if(!this.multiSelectedLeadIds.includes(newLeadInfo['_id'])){
              this.multiSelectedLeadIds.push(newLeadInfo['_id']);
            }
            let dataObj = {};
            dataObj["leadInfo"] = newLeadInfo;
            dataObj["buckInfo"] = this.funnelarr[i]["group_id"];
            this.bulkSelectionData.push(dataObj)
          }
          else if(this.bulkSelectionMode && this.multiSelectGroupArray.length && !this.multiSelectGroupArray.includes(this.funnelarr[i]["group_id"])){
            let index=this.multiSelectedLeadIds.indexOf(newLeadInfo['_id'])
            if (index > -1) {
              this.multiSelectedLeadIds.splice(index,1)
            }
          }
          let index=this.funnelarr[i]['leadContents']?.findIndex(lead=>lead._id==newLeadInfo['_id'])
          if (!this.funnelarr[i]['leadIds']?.includes(newLeadInfo['_id']) && index<0) {
            this.funnelarr[i]["leadContents"].splice(newLeadIndex,0,newLeadInfo);
            if(totalRecords==null){this.funnelarr[i]["total_records"]++;}
            else{this.funnelarr[i]["total_records"]=totalRecords}
            this.funnelarr[i]['leadIds'].push(newLeadInfo['_id'])
          }
        }
      }
      if(newLeadInfo['parent_id'] || newLeadInfo['child_lead_id']?.length>0){
        this.getParentChildOfAllLead(newLeadInfo);
      }
    }
  }

   async ngOnInit() {
     if(!this.multipleBoardIds && this.selectedDashId)this.multipleBoardIds=[this.selectedDashId]
     else if(!this.selectedDashId && this.multipleBoardIds)this.selectedDashId=this.multipleBoardIds[0]
     if(this.multipleBoardIds?.length)this.dashboardData = await this.dashboardUtils.getAndSyncAllDashboardData(this.multipleBoardIds)
     if (this.disabledFilterSubscription || this.viewType=='CalenderView' || this.viewType=='bottomBarView' || this.viewType=='GraphView') {
      this.arrayOfCustomFiled=this.commonUtils.getMultiBoardDataList(this.dashboardData,this.multipleBoardIds,'CUSTOM_FORM','key')
       setTimeout(()=>{this.initialiseBoardView()},20)
     }
     else {
       this.filterSub = this.msgservice.getFilterDataObject().subscribe(data => {
         if (data)
           this.filterDataObj = data;
         this.sortBy = this.filterDataObj.sort_params[0].sort_by;
         if (this.sortBy.includes('custom_fields.')) {
           this.sortBy = this.sortBy.split('.')[1];
         }
         if (!this.isFormulaUpdated) {
          this.currentLeadId=null
          this.onItemOpen.emit(null)
          this.itemPannelPreviewData=[]
          this.initialiseBoardView()
         } else {
           this.isFormulaUpdated = false
           this.updateFormulasValues()
         }
         this.cdr.detectChanges()
       });
     }
    this.userId= this.customStorageService.getItem('id')
    this.groupKeyJson=this.constantService.getGroupKeyJson()
    this.formulasOptionsFields=this.constantService.formulasOptionsFields;
    this.showLoader=true;
    this.orgUsersJson = await this.dashboardUtils.getOrgUsers();
    let existingCustomFields = await this.dashboardUtils.getDashboardExistingCustomFormIfPresent(this.selectedDashId);
    if(existingCustomFields?.length)this.itemAttributesObj = this.commonUtils.handleExistingItemAttributes(existingCustomFields)
    // apply columns opration selected from dashboard to board view
    this.columnEventSub = this.msgservice.getColumnsSubject().subscribe((res : any) => {
      if(res && res.length>0) {
        res.forEach(option => {
          this.getCustFieldOnLead(option, option?.isHide);
        })
      } else {
        this.selectedCustFieldKeys = [];
      }      
    });
    window.addEventListener('scroll', this.onScroll, true);
    this.groupScrollDown();
    this.checkForBucketSorting();

    for(let i=0;i<this.multipleBoardIds?.length;i++){
      this.isReadOnly=this.commonUtils.calcuateReadOnly(this.dashboardData[this.multipleBoardIds[i]]?.DASHBOARD_INFO);
      if(!this.isReadOnly)break;
    };
    this.commonUtils.isLeadOrDashboardReadOnlyOrViewOny(this.selectedDashId).then((res : any) => { 
      this.isLeadInTemplate=res.is_template_board
      this.isInternalBoardReadOnly=res.is_internal_board && res.no_dashboard_user
    });
    this.currencyArr = await this.dashboardUtils.getCurrencyJson()
    this.currencyJson = this.currencyArr?.reduce((json,data)=>{json[data.code]=data;return json},{})
    this.intervalIdForSeconds =setInterval(() => {this.updateTime()}, 300000);
    this.commonUtils.queryActiveTimeTrackerList(this.selectedDashId, this.userId).then(val=>{
      this.activeTrackerObj = val;
    })
    // get allow bucket 
    this.getAllowBucketsForCreate()
    if(!this.multipleBoardIds.length)this.getDasahboarData();
  }

  async getAllowBucketsForCreate(){
    let allowedBuckets=[]
    for(let i=0;i<this.multipleBoardIds?.length;i++){
      let buckets=await this.commonUtils.getPossibleBucketsToWhichUserHasCreatePermission((this.dashboardData[this.multipleBoardIds[i]]?.BUCKET || []),(this.dashboardData[this.multipleBoardIds[i]]?.DASHBOARD_INFO?.role || []));
      allowedBuckets=[...allowedBuckets,...(buckets || [])]
    };
    this.createLeadBucketIds = allowedBuckets?.map(bucket => bucket._id);
  }


  initialiseBoardView() {
    //crearing multiselect data everytime filter updated.
    this.clearBulkSelectionData(this.bulkSelectionMode)
    if (!this.filterLock) this.settingUpGroupData();
    else setTimeout(this.settingUpGroupData.bind(this), 100);
    this.selectedColumnsOptions?.forEach(option => {
      this.getCustFieldOnLead(option, option?.isHide);
    })
  }

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.bucketArray);
    if (changes) {
      this.updateBuckets();
    }
    
    let phasechanges = this.iterableDifferForPhase?.diff(this.dashBoardSprint);
    if (phasechanges) {
      if(this.selectedGroupBy?.group_by=='phase'){
        console.log(this.funnelarr,this.dashBoardSprint)
          this.funnelarr.forEach(group=>{
            let phaseIndex=this.dashBoardSprint.findIndex(ele=> ele?._id==group?._id)
            if(phaseIndex>-1){
              group.group_data=this.dashBoardSprint[phaseIndex]
              group.name=this.dashBoardSprint[phaseIndex]?.name
            }
          })
      }
    }


  }
  // used and call on buckets add 
  async updateBuckets() {
    let updatedArray=new Set<String>()
    this.funnelarr=this.funnelarr.map(ele=>{
      if(updatedArray.has(ele._id)) return null
      updatedArray.add(ele._id)
      return ele
    })
    this.funnelarr=this.funnelarr.filter(ele=>ele)
    if(this.selectedGroupBy && this.selectedGroupBy.group_by!='bucket' || !this.selectedGroupBy){return}
    this.getAllowBucketsForCreate()    
    // delete or remove bucket
    if (this.arrayOfGroupField.length > this.bucketArray.length) {
      let removedBucket = this.arrayOfGroupField.filter(bucket => !this.bucketArray.some(el => el._id == bucket._id));
      if(removedBucket.length>0) {
        let index = this.funnelarr.findIndex(el => el._id === removedBucket[0]._id);
        let ind = this.arrayOfGroupField.findIndex(el => el._id === removedBucket[0]._id);
        this.bucketSeqArray=this.bucketSeqArray.filter(bucket_id=>bucket_id!=removedBucket[0]._id)
        if(ind>-1) this.arrayOfGroupField.splice(index,1);
        if(index>-1) this.funnelarr.splice(index,1);
      }
    }
    // add bucket
    else if (this.bucketArray.length > this.arrayOfGroupField.length) {
      let addedBucket = this.bucketArray.filter(bucket => this.arrayOfGroupField.every(el => el._id !== bucket._id));
      let bucketDataObj = this.commonUtils.initializationOfBucketOrGroupWithLeadData(addedBucket[0]);
      if(!this.bucketSeqArray.includes(bucketDataObj['_id']))this.bucketSeqArray.push(bucketDataObj['_id']) 
      this.arrayOfGroupField.push(bucketDataObj)
      this.funnelarr.push(bucketDataObj); 
    }
    // update bucket
    else if(this.bucketArray.length != 0 && this.bucketArray.length == this.arrayOfGroupField.length){
      let bucketIndex=this.arrayOfGroupField.findIndex(data=>!(JSON.stringify(this.bucketArray).includes(JSON.stringify(data.group_data))));
      if(bucketIndex>-1){
        let updatedBucket = this.bucketArray.find(bucket => bucket._id==this.arrayOfGroupField[bucketIndex]['_id']);
        this.arrayOfGroupField[bucketIndex]['is_destination']=this.funnelarr[bucketIndex]['is_destination']=updatedBucket?.is_destination
        this.arrayOfGroupField[bucketIndex]['name']=this.funnelarr[bucketIndex]['name']=updatedBucket?.name
        this.arrayOfGroupField[bucketIndex]['group_data']=this.funnelarr[bucketIndex]['group_data']=updatedBucket
      }
    }
    if(this.bucketArray.length === 0) {
      this.arrayOfGroupField = [];
      this.funnelarr = [];
    }
    this.cdr.detectChanges()
  }

  getCustFieldOnLead(custItem, isHide) {
    let index=this.selectedCustFieldKeys.findIndex(column=>(column?.columnKey===custItem?.columnKey || column?.key===custItem?.columnKey))
    if (index==-1 && !isHide) {
      if(custItem?.isCustom){
        let customFiledIndex = this.arrayOfCustomFiled.findIndex(e => e.key  === custItem?.columnKey && e.hidden!=='ALWAYS');
        if(customFiledIndex>-1) this.selectedCustFieldKeys.push(this.arrayOfCustomFiled[customFiledIndex])
      }
      else{
        this.selectedCustFieldKeys.push(custItem);
      }
      
    } else if(index>-1 && isHide) {
        this.selectedCustFieldKeys.splice(index, 1);
    }
    this.cdr.detectChanges()
  }

  checkForBucketSorting() {
    // if(this.filterDataObj['sort_params']['sort_by'] === ConstantService.constant.SORT_BY_BUCKET) {
    //   this.filterDataObj['sort_params']['sort_by'] = ConstantService.constant.SORT_BY_INDEX;
    //   this.filterDataObj['sort_params']["order"] = "DSC";
    // }
  }


  async settingUpGroupData() {
    this.filterLock = true;
    let customData=this.filterDataObj?.dashboard_id ? this.commonUtils.getMultiBoardDataList(this.dashboardData,this.filterDataObj?.dashboard_id,'CUSTOM_FORM','key') : []
    this.filterDataObj = this.commonUtils.cleanFilterDataObject(this.filterDataObj,customData);
    if(this.filterDataObj.apply_formula){
      this.filterDataObj.apply_formula=this.commonUtils.getCalcuatedFormulas({null:[]},this.filterDataObj.apply_formula,customData)
    } 
    var inputJson = this.commonUtils.getFilterJsonForQuery({}, this.filterDataObj);
    if(!inputJson["grouping_details"] || this.viewType=='CalenderView' || this.viewType=='SplitView' || this.viewType=='bottomBarView')inputJson["grouping_details"]= { "group_by": "none", "max_group_size": 10, "start_index": 0, "sort_order": "ASC" } 
    inputJson["grouping_details"]['max_group_size'] = 10;
    if(!inputJson?.dashboard_id && this.multipleBoardIds?.length>0)inputJson.dashboard_id=this.multipleBoardIds
    this.gettingLeadDataForCurrentGroup(inputJson);

  }
  filterGroupBy(boardIds=this.multipleBoardIds,enableDefaultGroup=false){
    this.arrayOfGroupField = [];
    let [group, checkFiterArrOfGroup] = [[], []]
    let groupBy=this.filterDataObj?.grouping_details?.group_by
    if((this.selectedGroupBy?.group_by!='custom_field' && groupBy!=this.selectedGroupBy?.group_by) || (this.selectedGroupBy?.group_by=='custom_field' && groupBy!=this.selectedGroupBy?.key)) this.showLoader=true
    if(groupBy && groupBy!='none' && (this.viewType=='BoardView' || this.viewType=='GraphView')){
      this.selectedGroupBy=this.groupKeyJson[groupBy]
      if(!this.groupKeyJson[groupBy]){
        this.groupKeyJson['custom_field']['key']=groupBy
        this.groupKeyJson['custom_field']['filter_key']=groupBy
        this.selectedGroupBy=this.groupKeyJson['custom_field']
     }
      checkFiterArrOfGroup=this.filterDataObj[this.selectedGroupBy?.filter_key] || []
      if(this.selectedGroupBy?.group_by=="priority"){
        group=JSON.parse(JSON.stringify(Object.values(this.priorityJson)))
      }
      if(this.selectedGroupBy?.group_by=="dashboard_id"){
        let boardDataList=Object.keys(this.dashboardData).reduce((list,id)=>{if(this.dashboardData[id]?.DASHBOARD_INFO){list?.push({...this.dashboardData[id]?.DASHBOARD_INFO,name:this.dashboardData[id]?.DASHBOARD_INFO?.dashboard_name,_id:id})};return list},[])
        group=JSON.parse(JSON.stringify(boardDataList))
      }
      else if(this.selectedGroupBy?.group_by=="phase"){
        group=JSON.parse(JSON.stringify(this.commonUtils.getMultiBoardDataList(this.dashboardData,boardIds,'SPRINT','_id')))
        group=group.filter(phase=>(!phase.is_hidden  && !phase.is_archive) || checkFiterArrOfGroup.includes(phase._id))
        group.splice(0,0,{_id:null,name:this.selectedGroupBy?.default_value})
      }
      else if(this.selectedGroupBy?.group_by=="user" || this.selectedGroupBy?.group_by=="created_by"){
        this.selectedGroupBy.type='User'
        group=JSON.parse(JSON.stringify(Object.values(this.orgUsersJson)))
        group.splice(0,0,{_id:null,name:this.selectedGroupBy?.default_value})
      }
      else if(this.selectedGroupBy?.group_by=="bucket"){ 
        group=JSON.parse(JSON.stringify(this.commonUtils.getMultiBoardDataList(this.dashboardData,boardIds,'BUCKET','_id')))
        if(this.filterDataObj?.bucket_type?.length>0 && this.filterDataObj?.bucket_type[0] === 'NON_FINAL') {
          group = group.filter(bucket => !bucket.is_destination)
        } 
      }
      // for custom filed
      else if(this.selectedGroupBy?.group_by!="bucket"){ 
        let customDataJson=boardIds.reduce((json,id)=>{this.dashboardData[id]?.CUSTOM_FORM?.forEach(val=>{json[val.key]=val});return json},{})
        if(customDataJson[this.selectedGroupBy.key]){
          checkFiterArrOfGroup=this.filterDataObj?.custom_column ? (this.filterDataObj?.custom_column[this.selectedGroupBy.key] || []) : []
          if(customDataJson[this.selectedGroupBy.key]?.type=='Country'){
            this.selectedGroupBy.type='Country'
            group=this.currencyArr.map((item) => {return {_id: item.name,name: item.name,};});
          }else if(customDataJson[this.selectedGroupBy.key]?.type=='SingleUser'){
            this.selectedGroupBy.type='User'
            group=JSON.parse(JSON.stringify(Object.values(this.orgUsersJson)))
          }
          else{
            group=customDataJson[this.selectedGroupBy.key]?.permissible_values.reduce((list,data)=>{return [...list,{_id:data.k,name:data.v}]},[]);
          }
          if(!(customDataJson[this.selectedGroupBy.key]?.mandatory)) group.push({_id:null,name:this.selectedGroupBy?.default_value}) // hide other option 
        }
     }
    }else if(this.viewType=='BoardView' && this.bucketArray?.length>0 || this.viewType!=='BoardView' || boardIds.length>1 || !this.filterDataObj.dashboard_id){
      this.selectedGroupBy=this.groupKeyJson['none']
      group=[{_id:null,name:this.selectedGroupBy?.default_value}]
    }  
    let defaultGroups=[]
    // showing default group if lead data is empty
    if(!checkFiterArrOfGroup?.length && enableDefaultGroup && boardIds?.length>1){
      if(this.selectedGroupBy?.group_by=='priority')defaultGroups=[1]
      else if(this.selectedGroupBy?.group_by=='bucket')defaultGroups=[(group?.length ? group[0]?._id : null)]
      else defaultGroups=[null]
    }

    group.forEach(item => {
        let bucketDataObj = this.commonUtils.initializationOfBucketOrGroupWithLeadData(item);
        if(checkFiterArrOfGroup?.length) {
          if(checkFiterArrOfGroup?.includes(item._id)) {
            bucketDataObj['isIncludeInFilter']=true
            this.arrayOfGroupField.push(bucketDataObj);
          }
        } else {
          if(defaultGroups?.includes(item._id))bucketDataObj['isDefaultGroup']=true
          this.arrayOfGroupField.push(bucketDataObj);
        }
    });
    this.arrayOfGroupField=this.sortingUtils.getSortedGroupBy(this.filterDataObj.grouping_details,this.arrayOfGroupField);
  }

  gettingLeadDataForCurrentGroup(inputJson?, moveToNextItem?,scrollGroup?) {
    this.httpTransfer.getLeadQuery(inputJson).subscribe(async (res : any) => {
        if (res.status == 200) {
          this.connectedItemsIdsArr= !scrollGroup ? [] : this.connectedItemsIdsArr
          this.emitLeadQuerySucceeded.emit(true)
          this.lock = false;
          let boardIds=this.multipleBoardIds;
          // item all board data without boars ids
          if(!inputJson?.dashboard_id || !inputJson?.dashboard_id?.length){
            boardIds=res.result.leadResponse?.reduce((list,item)=>{if(item?.dashboard_id && !list.includes(item?.dashboard_id))list.push(item.dashboard_id);return list},[]) || [];
            this.dashboardData=await this.dashboardUtils.getAndSyncAllDashboardData( boardIds,false,['Tags','Sprints','Buckets',(inputJson?.include_activities ?'ActivityBoardInfo':'Info'),'CustomForms']);
          }
          if(!scrollGroup)this.filterGroupBy(boardIds,!(res.result.leadResponse.length))
          if (res.result.leadResponse.length > 0) {
            let groupdetails=res.result?.grouping_details;
            if(Object.keys(groupdetails).length>0 && !scrollGroup) {
              for (var i = 0; i < this.arrayOfGroupField.length; ++i) {
                groupdetails.groups.forEach(group => {
                    if(group.value === this.arrayOfGroupField[i]._id) {
                      if(group?.formula_details && group?.formula_details.length>0){
                          this.arrayOfGroupField[i].formulaContents=this.getConvertedFormulaJsonToArray(group?.formula_details[0]) || []
                      }
                      // this.arrayOfGroupField[i].total_records = group.data_id.length;
                      this.arrayOfGroupField[i].total_records = group.data_count;                    ;
                      this.arrayOfGroupField[i].leadContents=[]
                      let filteredItems = res.result.leadResponse.filter(item => group.data_id.some(id => id === item._id));
                      this.arrayOfGroupField[i]['leadIds']=group.data_id
                      filteredItems.forEach(lead => {
                        let eachLeadObj = this.getItemJson(lead,true);
                        this.arrayOfGroupField[i].leadContents.push(eachLeadObj);
                        if(this.multiSelectGroupArray.includes(this.arrayOfGroupField[i]._id)){
                          if(!this.multiSelectedLeadIds.includes(lead._id)){
                            this.multiSelectedLeadIds.push(lead._id);
                          }
                        }
                      })
                    }
                  })
              }
            }else {
              delete scrollGroup?.enableScrollerLoader
              let groupIndex=this.arrayOfGroupField.findIndex(group=>group.group_id===scrollGroup.group_id)
              if(groupIndex>=0){
                this.arrayOfGroupField[groupIndex].total_records = res.result.pagination_details.total_records;
                for (var j = 0; j < res.result.leadResponse.length; ++j) {
                  let eachLeadObj = this.getItemJson(res.result.leadResponse[j],true)
                  this.arrayOfGroupField[groupIndex].leadContents.push(eachLeadObj);
                  if(!this.arrayOfGroupField[groupIndex]?.leadIds.includes(eachLeadObj['_id']))this.arrayOfGroupField[groupIndex]['leadIds'].push(eachLeadObj['_id'])
                  if(this.multiSelectGroupArray.includes(this.arrayOfGroupField[groupIndex]._id)){
                    if(!this.multiSelectedLeadIds.includes(eachLeadObj['_id'])){
                      this.multiSelectedLeadIds.push(eachLeadObj['_id']);
                    }
                  }
                }
              }
            }
          }
          this.cdr.detectChanges()
          await this.getConnectedBoardItems();
          // arrange buckets on sequence order (only bucket group type)
          this.showSelectedBucketOnScreen();
          this.arrayOfGroupField.forEach((element) => {
            if (element?.leadContents?.length > 0) {
              this.startTimer(element.leadContents);
            }
          });
          if (moveToNextItem) {
            this.moveToNextLead();
          }
          this.getParentChildOfAllLead();
          if(this.showAllLeadChildrenFlag)this.setChildrenLeadShowHideForEachLead(true) 
        }else{
          this.showLoader=false;
        }
        this.cdr.detectChanges()
      },err=>{this.showLoader=false;});
  }

  callbackFunctionForConnectedItemJson(itemJson){
    this.mirrorColumnsJson[itemJson._id] = this.getItemJson(itemJson);
  }

  getItemJson(lead,isTriggerConnectedItem?){
    let item=isTriggerConnectedItem ? this.commonUtils.creatingJsonStructureOfLeadForTableView(lead, this.arrayOfCustomFiled, null, this.callbackFunctionForConnectedItems.bind(this)) : this.commonUtils.creatingJsonStructureOfLeadForTableView(lead);
    item['editable']=!(this.commonUtils.calcuateReadOnly(this.dashboardData[lead.dashboard_id]?.DASHBOARD_INFO,lead))
    return item
  } 

  callbackFunctionForConnectedItems(newConnectedItemIdArray){
    if(Array.isArray(newConnectedItemIdArray)) {
      newConnectedItemIdArray = newConnectedItemIdArray.filter(e => e)
    }
    if(newConnectedItemIdArray==null ||  !Array.isArray(newConnectedItemIdArray)){
      return
    }
    newConnectedItemIdArray.forEach(connectedItemId => {
      if(!this.connectedItemsIdsArr.includes(connectedItemId)){
        this.connectedItemsIdsArr.push(connectedItemId)
      }
    })
  }
  
  startTimer(lead) {
    this.interval = setInterval(() => {
      for (let i = 0; i < lead.length; i++) {
        lead[i].aging_time_in_seconds--;
      }
    }, 15000);
  }

  moveToNextLead() {
    this.funnelarr.forEach((element) => {
      if(element?.group_id === this.clickedGroupId) {
        for (let i = 0; i < element.leadContents.length; i++) {
          if (i == this.currentItemIndex) {
            if(element.leadContents[i]._id === this.currentLeadId) {
              this.currentItemIndex = i+1;
            } else {
              this.currentItemIndex = i;
            }
            if (i != (element.leadContents.length - 1)) {
              this.clickedLead(element.leadContents[this.currentItemIndex],element,"next",this.currentItemIndex
              );
              break;
            } else {
              this.gettingLeadDataForIndividualGroupOnScroll(element, true);
              if(element.total_records==this.currentItemIndex){
                this.currentItemIndex=this.currentItemIndex-1
              }
            }
          }
        }
      }
    });
  }

  gettingLeadDataForIndividualGroupOnScroll(currentGroup, moveToNextItem?) {


    if (!this.isDragging) {
      let inputJson = {};
      
      if(this.lock){
        setTimeout(this.gettingLeadDataForIndividualGroupOnScroll.bind(this,currentGroup, moveToNextItem), 100);
        return
      }
      inputJson = this.commonUtils.getFilterJsonForQuery(inputJson, this.filterDataObj);
      inputJson=JSON.parse(JSON.stringify(inputJson))
      delete inputJson['grouping_details']
      delete inputJson['apply_formula']
      if(this.selectedGroupBy.group_by=='custom_field'){
        inputJson['custom_column']={[this.selectedGroupBy.filter_key]:[currentGroup._id]};
      }
      else if(this.selectedGroupBy.filter_key!==null){
        inputJson[this.selectedGroupBy.filter_key]=[currentGroup._id];
      }
      if(!inputJson['dashboard_id'] && this.multipleBoardIds?.length>0)inputJson['dashboard_id']=this.multipleBoardIds

      let pagination = {};
      pagination["start_index"] = currentGroup.leadContents.length;
      pagination["page_size"] = 10;
      inputJson["pagination_details"] = pagination;
      // console.log(currentGroup.leadContents.length , currentGroup.total_records)
      
      if (currentGroup.leadContents.length < currentGroup.total_records) {
        this.lock = true;
        currentGroup.enableScrollerLoader=true
        this.gettingLeadDataForCurrentGroup(inputJson, moveToNextItem,currentGroup);
      }
    }
  }

  moveToPreviousLead() {
    this.funnelarr.forEach((element) => {
      if(element?.group_id === this.clickedGroupId) {
        element.leadContents.forEach((leadinfo, index) => {
          if (index == this.currentItemIndex) {

            if (index != 0) {
              this.clickedLead(element.leadContents[index-1],element,"previous",index-1);
            }
          }
        });
      }
    });
  }

  clickedLead(item, bucket,clickType?,index?,subtaskParentObj?) {
    this.resetEditLeadData();
    this.processData(item, bucket, index,subtaskParentObj);
    this.commentSectionType = ConstantService.constant.COMPONENTTYPE.COMMENTS;
  }


  processData(item, bucket, index?,subtaskParentObj?) {
    this.currentItemIndex = index;
    this.currentGroup = bucket;
    this.currentGroupId = bucket._id;
    this.clickedGroupId = this.currentGroupId;
    this.currentLeadId = item._id;
    this.leadSeqNumber = item.seq_id;
    this.selectedCurrentLead = item;
    let obj={itemId:item._id,boardId:item?.dashboard_id,showPreviousNextItemBtn:index!=null,subtaskParentObj:subtaskParentObj}
    if(this.viewType=='BoardView' || this.viewType=='GraphView' && item?.item_type!=="ACTIVITY_COMMENT"){
      this.itemPreviewData=[obj]
    }
    this.onItemOpen.emit({...obj,item:(item?.item_type=="ACTIVITY_COMMENT" ? item : null)})
  }

  resetEditLeadData() {
    this.currentLeadId = "";
    this.currentGroupId = "";
    this.leadSeqNumber = "";
  }

  showSelectedBucketOnScreen() {
    this.funnelarr = [];
    this.scrollMap.clear();
    if (this.arrayOfGroupField.length > 0 && this.bucketSeqArray?.length>0 && this.selectedGroupBy.group_by=='bucket') {
      let bucketIds=[]
      for (var i = 0; i < this.bucketSeqArray?.length; ++i) {
        for (var k = 0; k < this.arrayOfGroupField.length; ++k) {
          if (this.bucketSeqArray[i] == this.arrayOfGroupField[k]._id) {
            this.funnelarr.push(this.arrayOfGroupField[k]);
            bucketIds.push(this.arrayOfGroupField[k]._id);
            break;
          }
        }
      }
      if(this.arrayOfGroupField.length!=this.funnelarr.length)this.funnelarr=[...this.funnelarr,...(this.arrayOfGroupField.filter(bucket=>!bucketIds.includes(bucket._id)))]
    }else {
      this.funnelarr = this.arrayOfGroupField;
    }
    setTimeout(()=>{
      this.showLoader=false;
      this.cdr.detectChanges()
    },15)
    this.filterLock = false;
    setTimeout(()=>{this.updateScrollMap();},100)
  }

  updateScrollMap() {
    this.arrayOfGroupField.forEach((element, key) => {
      let startKey = document.getElementsByClassName('cdk-drop-list').item(key)?.id;
      this.scrollMap.set(startKey, 150);
    });
  }


  async openAddLead(group) {
    this.clickQuickLeadGrouptId = "";
    this.currentGroup = group;
    this.isOpenAddLead = await this.commonUtils.createQuickItemOrNot(this.selectedDashId);
    if (this.isOpenAddLead) {
      this.clickQuickLead(group);
    } else {
      let modalRef = this.ngbModalService.open(this.addcustomleadsplus, {size : 'lg', windowClass: 'copyTemplateLeadItem'})
      this.basicUtils.storeModlRef(modalRef)
    }
  }

  clickQuickLead(item) {
    this.creationtext = "";
    this.setQuickLeadTrue();
    this.clickQuickLeadGrouptId = item?._id;
    document.getElementById('group_' + item._id)?.scrollTo(0, 0);
  }

  setQuickLeadTrue() {
    this.quickLeadMouseClick = true;
    this.clickedInsideTheTextArea = false;
    setTimeout(() => {document.getElementById("myTextInput")?.focus();}, 20);
  }


  followBucket(bucket,followBucket?) {
    this.isBucketFollow = true;
    if(followBucket){
      this.httpTransfer.toFollowBucketByUser(this.selectedDashId, bucket._id)
      .subscribe((res : any) => {
        if (res.status === 200) {
          this.isBucketFollow = false;
          bucket.bucketFollowed = followBucket;
        }
      });
    }
    else{
      this.httpTransfer.toUnFollowBucketByUser(this.selectedDashId, bucket._id)
        .subscribe((res : any) => {
          if (res.status === 200) {
            this.isBucketFollow = false;
            bucket.bucketFollowed = followBucket;
          }
        });
    }
  }

  leadIntoBulkSelection(item, group) {
    this.multiSelectedLeadIds = [];
    this.bulkLeadInfo = [];
    this.bulkGroupInfo = [];
    let dataObj = {};
    dataObj["leadInfo"] = item;
    dataObj["buckInfo"] = group;
    if (this.bulkSelectionData.length > 0) {
      var arr = [];
      this.bulkSelectionData.forEach((element) => {

        //code for exclude lead in multiselect
        if(this.multiSelectGroupArray.includes(group._id) && this.excludeInMultiselectGroup.includes(item._id)){
          var index = this.excludeInMultiselectGroup.indexOf(item._id);
          if (index > -1) {
            this.excludeInMultiselectGroup.splice(index, 1);
            if(!this.multiSelectedLeadIds.includes(item._id)){
              this.multiSelectedLeadIds.push(item._id);
            }
            }
        }
        arr.push(element.leadInfo._id);
      });
      if (!arr.includes(item._id)) {
        this.bulkSelectionData.push(dataObj);
        this.multiSelectedLeadIds.push(item._id)
      } else {

        //code for exclude lead in multiselect
        if(this.multiSelectGroupArray.includes(group._id)){
          this.excludeInMultiselectGroup.push(item._id)
          var index = this.multiSelectedLeadIds.indexOf(item._id);
          if (index > -1) {
            this.multiSelectedLeadIds.splice(index, 1);
          }
        }

        var index = arr.indexOf(item._id);
        if (index > -1) {
          this.bulkSelectionData.splice(index, 1);
          this.multiSelectedLeadIds.splice(index, 1);
        }
      }
    } else {
      this.bulkSelectionData.push(dataObj);
    }
    this.bulkSelectionData.forEach(element=>{
      if(!this.multiSelectedLeadIds.includes(element.leadInfo._id)){
        this.multiSelectedLeadIds.push(element.leadInfo._id);
      }
      this.bulkLeadInfo.push(element.leadInfo);
      this.bulkGroupInfo.push(element.buckInfo);
    });
    
  }

  onNavigateToEditLeadPage(leadData) {
    const editUrl = this.leadUrlText + this.selectedDashId + "/" + leadData.seq_id;
    window.open(editUrl, "_blank");
  }

  getDependenciesForLead(leadId, bucket) {
    this.currentLeadId = leadId;
    this.currentGroupId = bucket._id;
    this.currentGroup = bucket;
  }

  setLeadIdForTimeline(leadData) {
    this.currentLeadId = leadData._id;
  }

  movingToTrash(leadId, bucket?) {
    var leadObj = {};
    leadObj["lead_id"] = [leadId];
    this.httpTransfer
      .movingLeadToTrash(this.trashBucketId, leadObj, this.selectedDashId)
      .subscribe((res : any) => {
        if (res.status === 200) {
          this.toaster.Success(res.result.message);
        }
      });
  }
  trashBucketId(trashBucketId: any, leadObj: {}, selectedDashId: number) {
    throw new Error('Method not implemented.');
  }
  startDateClicked(lead) {
    this.currentLeadId = lead._id;
    if (lead.start_date.value != 0) {
      this.selectedStartDate = this.commonUtils.epochToDate(
        lead.start_date.value
      );
    }
    if (lead.end_date.value != 0) {
      this.selectedEndDate = this.commonUtils.epochToDate(lead.end_date.value);
    }
    this.gettingCurrentBucketUsingBucketId(lead.bucket_id);
  }

  gettingCurrentBucketUsingBucketId(groupId) {
    for (var i = 0; i < this.funnelarr.length; ++i) {
      if (this.funnelarr[i]?.group_id === groupId) {
        this.currentGroup = this.funnelarr[i];
        return this.funnelarr[i];
      }
    }
  }

  gettingPreviousBucketUsingBucketId(buckId) {
    for (var i = 0; i < this.funnelarr.length; ++i) {
      if (this.funnelarr[i]._id === buckId) {
        this.previousGroup = this.funnelarr[i];
        return this.funnelarr[i];
      }
    }
  }

  setQuickLeadFalse() {
    this.quickLeadMouseClick = false;
    this.clickedInsideTheTextArea = true;
  }

  setComponentType(val) {
    if (val == ConstantService.constant.COMPONENTTYPE.BASICINFO) {
      this.commentSectionType = ConstantService.constant.COMPONENTTYPE.BASICINFO;
    }
    if (val == ConstantService.constant.COMPONENTTYPE.COMMENTS) {
      this.commentSectionType = ConstantService.constant.COMPONENTTYPE.COMMENTS;
    }
    if (val == ConstantService.constant.COMPONENTTYPE.TIMELINE) {
      this.commentSectionType = ConstantService.constant.COMPONENTTYPE.TIMELINE;
    }
    if (val == ConstantService.constant.COMPONENTTYPE.ATTACHMENTS) {
      this.commentSectionType = ConstantService.constant.COMPONENTTYPE.ATTACHMENTS;
    }
  }

  allowDrop(event) {
    event.preventDefault();
  }

  dragbuck(dragind) {
    this.dragbuckind = dragind;
  }

  async dragfun(group, lead) {
    this.allowedBuckets = await this.commonUtils.getAllowedBuckets(lead?.dashboard_id,group._id, this.bucketArray, lead);
    this.getdragLeadinfoForCalendar = lead
    this.previousGroup = group;
    this.currentGroupId = group._id;
    this.leadId = lead._id;
    this.isDragging = true;
  }

  onDragRelease() {
    console.log("release")
    this.getdragLeadinfoForCalendar = null;
    this.isDragging = false;
    $('#'+ this.groupIdScroll).removeClass('sortedDrop'); 
    $('.addBorderTextOnSortedBucket').removeClass('addBorderTextOnSortedBucket');
    $('.sortedDrop').removeClass('sortedDrop');
    $('.dropPera').addClass('d-none');
    $('.bucketDropPera').addClass('d-none');
  }

  onDrop(event: CdkDragDrop<string[]>, group) {
    if(this.allowedBuckets.includes(group._id) || this.selectedGroupBy.group_by!='bucket') {
      this.event = event;
      this.destinationGrouptId = group._id;
      this.currentGroup = group;
      this.getdragLeadinfoForCalendar=this.currentGroup.leadContents[event.currentIndex]
      if(this.viewType!=='CALENDAR'){
        this.moveLeadFromCurrentToDestinationGroup(event.currentIndex, event.previousIndex,group,group._id,event);
      }
    }
  }

  
  moveLeadFromCurrentToDestinationGroup(dropIndex: number, previousIndex: number, currentGroup: any, destinationGrouptId: string,event?) {
    // stop when drag start and end are same
    if(this.currentGroupId === destinationGrouptId && dropIndex ==  previousIndex || dropIndex<0) { return}
    var apiData = {
      index: {
        firstIndexLeadId: null,
        secondIndexLeadId: null,
      },
    };
    if(this.currentGroupId !== destinationGrouptId){ // check previous and current valure will not same
      if(this.selectedGroupBy.group_by=='bucket'){
        apiData["bucket_id"] = this.currentGroupId;
        apiData["destination_bucket_id"] = destinationGrouptId;
      }else if(this.selectedGroupBy.group_by=='custom_field'){
        apiData["custom_fields"]={}
        apiData["custom_fields"][this.selectedGroupBy.key]=destinationGrouptId
      }
      else if(this.selectedGroupBy.key!==null){
        apiData[this.selectedGroupBy.key]=destinationGrouptId
      }
    }
    
    if(this.currentGroupId !== destinationGrouptId) {
      if (currentGroup.leadContents[dropIndex - 1] != undefined) {
        apiData.index.secondIndexLeadId = currentGroup.leadContents[dropIndex - 1]._id;
      }
      if (currentGroup.leadContents[dropIndex] != undefined) {
        apiData.index.firstIndexLeadId = currentGroup.leadContents[dropIndex]._id;
      }
      if (currentGroup._id != destinationGrouptId)
        if (currentGroup.leadContents[dropIndex - 1] == undefined) {
          delete apiData.index.secondIndexLeadId;
        }
      if (currentGroup.leadContents[dropIndex] == undefined) {
        delete apiData.index.firstIndexLeadId;
      }
      if (currentGroup.leadContents[dropIndex - 1] == undefined && currentGroup.leadContents[dropIndex] == undefined) {
        delete apiData.index;
      }
    } else if(this.currentGroupId === destinationGrouptId) {
      if(dropIndex > previousIndex) {
        if (currentGroup.leadContents[dropIndex] != undefined) {
          apiData.index.secondIndexLeadId = currentGroup.leadContents[dropIndex]._id;
        }
        if (currentGroup.leadContents[dropIndex + 1] != undefined) {
          apiData.index.firstIndexLeadId = currentGroup.leadContents[dropIndex + 1]._id;
        }
        if (currentGroup.leadContents[dropIndex] == undefined) {
          delete apiData.index.secondIndexLeadId;
        }
        if (currentGroup.leadContents[dropIndex + 1] == undefined) {
          delete apiData.index.firstIndexLeadId;
        }
        if (currentGroup.leadContents[dropIndex] == undefined && currentGroup.leadContents[dropIndex + 1] == undefined) {
          delete apiData.index;
        }
      } else if(dropIndex < previousIndex) {
        
        if (currentGroup.leadContents[dropIndex - 1] != undefined) {
          apiData.index.secondIndexLeadId = currentGroup.leadContents[dropIndex - 1]._id;
        }
        if (currentGroup.leadContents[dropIndex] != undefined) {
          apiData.index.firstIndexLeadId = currentGroup.leadContents[dropIndex]._id;
        }
        if (currentGroup.leadContents[dropIndex - 1] == undefined) {
          delete apiData.index.secondIndexLeadId;
        }
        if (currentGroup.leadContents[dropIndex] == undefined) {
          delete apiData.index.firstIndexLeadId;
        }
        if (currentGroup.leadContents[dropIndex - 1] == undefined && currentGroup.leadContents[dropIndex] == undefined) {
          delete apiData.index;
        }
      }
    }
    {
      // form change position of item on drag (used for smooth drag)
      transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex,);
      let dragItem=this.currentGroup.leadContents[event.currentIndex]
      dragItem['stopDrag']=true;
      this.httpTransfer
        .updateLeadCorrespondingToLeadId(apiData,dragItem?.dashboard_id,dragItem?._id)
        .subscribe(
          (res : any) => {
            if (res.status == 200) {
              // for darg upadte 
              if(this.selectedGroupBy.group_by!='custom_field'){
                dragItem[this.selectedGroupBy.key]=destinationGrouptId
              }else{
                if(!dragItem?.custom_fields) dragItem['custom_fields']={}
                dragItem.custom_fields[this.selectedGroupBy.key]=destinationGrouptId
              }
              if(this.previousGroup.total_records>0 && this.previousGroup['leadIds'].includes(dragItem?._id)){
                this.previousGroup['leadIds']=this.previousGroup["leadIds"].filter(id => id !==dragItem?._id);
                this.previousGroup.total_records=this.previousGroup.total_records-1;
              }
              if(!this.currentGroup['leadIds'].includes(dragItem?._id)){
                this.currentGroup['leadIds'].push(dragItem?._id)
                this.currentGroup.total_records=this.currentGroup.total_records+1;
              }
              if(this.previousGroup["total_records"]==0) this.previousGroup["formulaContents"]=[]
              if(this.previousGroup.leadContents.length <10 && this.previousGroup.leadContents.length<this.previousGroup.total_records && (this.previousGroup.id!=this.currentGroup._id)){
                  this.gettingLeadDataForIndividualGroupOnScroll(this.previousGroup)
              }
            }
          },
          (err) => {
            // for revert item in previous group
            dragItem['stopDrag']=false
            transferArrayItem(event.container.data,event.previousContainer.data,event.currentIndex,event.previousIndex,);
            $("#transcomment").modal("hide");
            if(err.status == 500){            
              $("#dragNotAllowed").modal("show");
            }
          },
          ()=>{

          }
        );
    }
  }


  async createLeadFromTitle(_id, index, event) {
    if (this.creationtext == "\n") {
      this.clickQuickLeadGrouptId ="";
    } else if(this.creationtext != "") {
      this.enterCounter = this.enterCounter + 1;
      var leadObj = {};
      let boardId= this.selectedGroupBy.group_by=='dashboard_id' ? _id : this.selectedDashId
      if(!this.filterDataObj.dashboard_id)await  this.dashboardUtils.getAndSyncAllDashboardData([boardId],false,['Buckets'])
      let res = await this.commonUtils.getDataFromFilterObjectToCreateLead({...this.filterDataObj,dashboard_id:[boardId]},(this.dashboardData[boardId]?.CUSTOM_FORM || []),(this.dashboardData[boardId]?.BUCKET || []),(this.dashboardData[boardId]?.DASHBOARD_INFO?.role || []));
      leadObj = res[0];
      leadObj["title"] = this.creationtext;
      if(this.selectedGroupBy.group_by=='dashboard_id'){
        boardId= _id 
      }else if(this.selectedGroupBy.group_by=='custom_field'){
        let fieldInfo = this.dashboardData[boardId]?.CUSTOM_FORM?.find(val=>val.key===_id)
        if(fieldInfo || !_id)leadObj['custom_fields']={[this.selectedGroupBy.key]:_id}
      }else if(this.selectedGroupBy.key!==null && this.selectedGroupBy.key!==undefined && this.selectedGroupBy.key!=='created_by'){
        let key=this.selectedGroupBy.key=='sprint_id' ? 'SPRINT' : 'BUCKET';
        let fieldInfo = this.dashboardData[boardId][key]?.find(val=>val._id===_id)
        if(!['bucket_id','sprind_id']?.includes(this.selectedGroupBy.key) || fieldInfo || _id==null)leadObj[this.selectedGroupBy.key]=_id;
      }
      if(this.itemAttributesObj?.bucket_id?.hidden) {
        const dashboardInfo = await this.dashboardUtils.getDashboardInformation([boardId])
        const defaultBucketId = dashboardInfo[boardId]?.DASHBOARD_INFO?.default_bucket_id;
        leadObj['bucket_id'] = defaultBucketId;
      }
      if (this.enterCounter == 1) {
        this.httpTransfer
          .createLeadCorrespondingToDashBoard(leadObj,boardId)
          .subscribe((res : any) => {
            if (res.status == 200) {
              this.enterCounter = 0;
              this.creationtext = "";
            }
          }, err => {
            this.enterCounter = 0;
            this.creationtext = "";
          }
          );
      }
    }
  }

  closeQuickAddLead() {
    this.quickLeadMouseClick = false;
    this.clickedInsideTheTextArea = false;
    this.clickQuickLeadGrouptId = "";
  }

  getCustomFieldsForLeadBeforeCreation() {
    var arrayOfData = this.customFieldsArray;
    var dataObj = {};
    for (var i = 0; i < this.customFieldsArray.length; ++i) {
      this.arrayOfCustomFiled.forEach((item) => {
        if (item["key"] == arrayOfData[i]) {
          if (
            item.type == ConstantService.constant.CUSTOMTYPES.CHECKBOX &&
            item.default_value.length > 0
          ) {
            dataObj[item.key] = item.default_value;
          } else if (
            item.default_value != "" &&
            item.type == ConstantService.constant.CUSTOMTYPES.RADIO
          ) {
            const index = item.permissible_values.findIndex(el => el.value === item.default_value);
            if(index != -1) dataObj[item.key] = item.permissible_values[index].value; 
          } else if (
            item.type === ConstantService.constant.CUSTOMTYPES.DROPDOWN &&
            item.default_value != ""
          ) {
            dataObj[item.key] = item.permissible_values[item.default_value];
          } else if (
            item.default_value != "" &&
            item.default_value != undefined &&
            item.type == ConstantService.constant.CUSTOMTYPES.DATE
          ) {
            dataObj[item.key] = item.default_value;
          } else {
            if (item.default_value != "" && item.default_value != undefined) {
              dataObj[item.key] = item.default_value;
            }
          }
        }
      });
    }
    return dataObj;
  }

  setCheckBoxDefaultForLeadCreation(customFieldObj) {
    let defaultCheckArr = [];
    if (customFieldObj.default_value.length > 0) {
      customFieldObj.default_value.forEach((value) => {
        defaultCheckArr.push(customFieldObj.permissible_values[value]?.value);
      });
    }
    return defaultCheckArr;
  }

  onDropbuck(dropind) {
    this.array_move(this.funnelarr, this.dragbuckind, dropind);
    console.log("on move buckets",this.funnelarr)
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    var role = this.customStorageService.getItem("role");
    this.updatingBucketSeq();
  }

  updatingBucketSeq() {
    var funnelarrid = [];
    for (var i = 0; i < this.funnelarr.length; i++) {
      funnelarrid.push(this.funnelarr[i]._id);
    }
    this.bucketSeqArray = funnelarrid;
    for (var j = 0; j < this.arrayOfGroupField.length; ++j) {
      if (funnelarrid.indexOf(this.arrayOfGroupField[j]["_id"]) == -1) {
        funnelarrid.push(this.arrayOfGroupField[j]["_id"]);
      }
    }
    var newBucketSeqIdsObj = {};
    newBucketSeqIdsObj["bucket_seq_ids"] = funnelarrid;
    this.arrayOfGroupField = this.funnelarr
    console.log("update seq buckets",this.funnelarr)
    this.emitUpdatedBucketSeq.emit(newBucketSeqIdsObj)
  }

  ngOnChanges(changes:SimpleChange) {
    if(changes['bulkSelectionMode'] && !changes['bulkSelectionMode']?.currentValue) {
      this.clearBulkSelectionData();
      }
  }



  ngOnDestroy() {
    if(this.intervalId) {
      clearInterval(this.intervalId);
    }
    if(this.intervalIdForSeconds) {
      clearInterval(this.intervalIdForSeconds);
    }
    if(this.interval){
      clearInterval(this.interval)
    }
    window.removeEventListener('scroll', this.onScroll, true);
    document.removeEventListener('click', this.mouseEvent);
    document.removeEventListener('wheel', this.onWheel);
    document.removeEventListener('window:keydown', this.onMouseMove);
    this.filterSub?.unsubscribe();
    this.formulaSub?.unsubscribe();
    this.mqttSubscription?.unsubscribe();
    this.columnEventSub?.unsubscribe();
    this.showChildrenSub?.unsubscribe();
    this.bulkSelectionModeSub.unsubscribe();
  }

  

  clearBulkSelectionData(flag=false) {
    this.bulkSelectionData = [];
    this.multiSelectedLeadIds = [];
    this.multiSelectGroupArray=[]
    this.excludeInMultiselectGroup=[]
    this.bulkLeadInfo = [];
    this.bulkGroupInfo = [];
    this.emitBulkSelectionMode.emit(flag);
  }

  updateStarLead(leadInfo,updateLead : boolean) {
    var obj = { is_starred :updateLead };
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(obj,leadInfo?.dashboard_id, leadInfo._id).subscribe((res : any) => {
        if(res.status==200){leadInfo.isStarred=updateLead}
      });
  }

  onEditLeadModalClose(event) {
    this.closeEditLeadPopup();
  }

  closeEditLeadPopup() {
    this.currentLeadId = "";
    this.currentGroupId = "";
    this.leadSeqNumber = "";
    this.ngbLeadModalRef.close();
  }


  openDocInNewPage(path) {
    window.open(path);
  }

  closeLeadOptionContainerPopup() {
    document.getElementById('boardViewPopupsId').parentElement.parentElement.style.display = 'none';
  }

  getChildrenOfSelectedLead(leadInfo, parentLeadId: string) {
    leadInfo['showChildren'] = leadInfo.showChildren ? false : true;
  }

  setChildrenLeadShowHideForEachLead(flag) {
    this.funnelarr?.forEach(bucket => {
      bucket?.leadContents?.forEach(lead => {
        lead['showChildren'] = flag;
      })
    })
    this.cdr.detectChanges()
  }

  getParentChildOfAllLead(lead?) {
    let leadIdArr = [];
    if(lead){
      // if parent lead id not exist in parentChildLeads, store for api call
      if (lead.parent_id && !this.parentChildLeads[lead.parent_id])leadIdArr.push(lead.parent_id)
      // if child lead id not exist in parentChildLeads, store for api call
      lead.child_lead_id?.forEach(chLeadId => {if(!this.parentChildLeads[chLeadId])leadIdArr.push(chLeadId)})
    }
    else{
      this.funnelarr?.forEach(group=> {
        group?.leadContents?.forEach(lead=> {
          leadIdArr=[...leadIdArr,...(lead?.child_lead_id || []),lead?.parent_id]
        })
      })
      this.funnelarr?.forEach(group=> {
        group?.leadContents?.forEach(lead=> {
          if(leadIdArr.includes(lead._id))this.parentChildLeads[lead._id]=lead
        })
      })
    }
    leadIdArr = [...new Set(leadIdArr)].filter(id=>id && !this.parentChildLeads[id])
    if(leadIdArr.length <= 0) {
      return;
    };
    this.httpTransfer.getLeadQuery({ lead_id: leadIdArr}, this.selectedDashId).subscribe((res : any) => {
      if(res.status === 200) {
        res.result.leadResponse?.forEach(lead=> {
          this.parentChildLeads[lead._id]=this.getItemJson(lead)
        })
        this.cdr.detectChanges()
      }
    });
  }

  bulkOperationsCompleted(){
    this.bulkSelectionMode = false;
    this.clearBulkSelectionData()
  }

  updateTime(){
   for(let i =0;i<this.funnelarr.length;i++){
    this.funnelarr[i].leadContents.forEach(item=>{
      item.diff = (new Date().getTime() - item.update_date)/1000
    })
   }
  }
  openTitleEditor(leadInfo,event){
    leadInfo['isShowEditor'] = true
    leadInfo['oldTitle'] = leadInfo.title
    setTimeout(()=>document.getElementById('editTextarea_'+leadInfo._id)?.focus(),10)
  }
  updateLeadInfo(lead) {
    lead['isShowEditor'] = false
    if(lead['oldTitle'] !== lead.title){
      this.httpTransfer
        .updateLeadCorrespondingToLeadId({title:lead.title},lead?.dashboard_id, lead._id)
        .subscribe((res : any) => {
          if (res.status === 200) {      
          }
        });
    }
    delete lead['oldTitle']
  }
  removeItemFromBoard(leadData, currentItem, key) {
    let connectedItems = currentItem.custom_fields[key] || [];
    const updatedIds= connectedItems.filter(x => x !== leadData._id);
    const obj = {custom_fields: {[key]: updatedIds}};
    this.httpTransfer.updateLeadCorrespondingToLeadId(obj,currentItem.dashboard_id,currentItem._id).toPromise()
  }

  openModalForPreview(item, i, type, template) {
    this.mediaArr = []
    this.indexForPreview = i
    if(item.fileObjArr) {
      item.fileObjArr.forEach(e=>{
        let obj={}
        obj['type'] = type
        obj['name'] =  e?.name + '.' + e?.extension
        obj['url']  =  e?.image_download_url
        obj['path'] =  e?.path
        this.mediaArr.push(obj)
      })
    } else {
      let obj={}
      obj['type'] = type
      obj['name'] =  item?.file_name + '.' + this.commonUtils.getFileNameExtFromS3Path(item?.file_path).extension
      obj['url']  =  item?.file_url
      obj['path'] =  item?.file_path
      this.mediaArr.push(obj)
    }
    this.modalRef = this.ngbModalService.open(template, { size: 'lg' , windowClass: 'file preview-modal'}) 
    this.basicUtils.storeModlRef(this.modalRef)
  }

  checkForField(leadInfo,field) {
    if(leadInfo?.custom_fields && leadInfo?.custom_fields.hasOwnProperty(field.key)) {
      return true;
    } else {
      return false;
    }
  }
 
  selectAllLeadsOfBoard(currentBucket): void{
    this.isSelectedAll=true
    let bucketId = currentBucket._id;
    let index= this.multiSelectGroupArray.indexOf(bucketId)
    this.multiSelectedLeadIds = [];
    if(!this.multiSelectGroupArray?.length){
      this.excludeInMultiselectGroup=[]
    }
    this.bulkSelectionData=[]
    this.bulkLeadInfo=[]
    this.bulkGroupInfo=[]
    if(index==-1){
      this.multiSelectGroupArray.push(bucketId)
    }
    else if(index>-1){
      this.multiSelectGroupArray.splice(index,1)
    }

    this.multiSelectGroupArray.forEach(groupId=>{
      this.bulkselectionOnGroup(groupId)
    })

  }

  bulkselectionOnGroup(groupId){
    for(let buck of this.funnelarr){
      if(buck._id == groupId){
        console.log(buck.leadContents)
        for(let leads of buck.leadContents){
          let dataObj = {};
          dataObj["leadInfo"] = leads;
          dataObj["buckInfo"] = buck;
          this.bulkSelectionData.push(dataObj)
          this.multiSelectedLeadIds.push(leads._id)
          this.bulkLeadInfo.push(leads);
          this.bulkGroupInfo.push(buck);
        }
        break;
      }
    }
    }

  markBucketLeads(bucketId,deselect?){
    for(let buck of this.funnelarr){
      if(buck._id == bucketId){
        console.log(buck.leadContents)
        for(let leads of buck.leadContents){
          if(leads.iscreated)
            if(deselect && this.multiSelectedLeadIds.includes(leads._id)){
              this.isSelectedAll=false
              this.leadIntoBulkSelection(leads, buck)
            }
            else if(!deselect && !this.multiSelectedLeadIds.includes(leads._id)){
              this.leadIntoBulkSelection(leads, buck)
            }
        }
        break;
      }
    }
  }
  // formula code start here

  updateFormulasValues(){
    var inputJson = this.commonUtils.getFilterJsonForQuery({}, this.filterDataObj);
    inputJson["grouping_details"] = {
      max_group_size: 1,
      group_by: this.filterDataObj?.grouping_details?.group_by || 'none' ,
      start_index: 0
    }
    inputJson['pagination_details']= {start_index : 0,page_size: 1}
    if(!inputJson?.dashboard_id && this.multipleBoardIds?.length>0)inputJson.dashboard_id=this.multipleBoardIds
    if(this.filterDataObj?.apply_formula){
      this.httpTransfer.getLeadQuery(inputJson).subscribe(async (res : any) => {
        if(res.status==200){
          for (var i = 0; i < this.funnelarr.length; ++i) {
            res.result?.grouping_details.groups.forEach(group => {
              if(group.value === this.funnelarr[i]._id && group?.formula_details && group?.formula_details.length>0) {
                this.funnelarr[i].formulaContents=this.getConvertedFormulaJsonToArray(group?.formula_details[0]) || []
              }
            })
          }
        }
        this.cdr.detectChanges()
      })
    }else{ // case when remove formulas 
      for (var i = 0; i < this.funnelarr.length; ++i) {
            this.funnelarr[i].formulaContents=[]
      }
    }
    this.cdr.detectChanges()

  }
  // remove applied formula for item or overall
  removeAppliedFormula(customItem?,removeItemKey?){
    let obj={}
    if(removeItemKey!=null){
      let appliedFormulaList= this.filterDataObj?.apply_formula[customItem] || [];
      let index=appliedFormulaList.indexOf(removeItemKey)
      appliedFormulaList.splice(index,1)
      obj[customItem] = appliedFormulaList;
    }
    if(this.viewType=='BoardView')this.msgservice.setUpdateVeiwForFormula(obj);
    else if(this.viewType=='GraphView'){
      let customFiledsList=this.commonUtils.getMultiBoardDataList(this.dashboardData,this.multipleBoardIds,'CUSTOM_FORM','key')
      this.filterDataObj['apply_formula']=this.commonUtils.getCalcuatedFormulas(obj,this.filterDataObj['apply_formula'],customFiledsList)
      this.onItemFilterChanged.emit([{update_type:'formulas',update_value:this.filterDataObj['apply_formula'],update_key:'apply_formula'}])
      this.updateFormulasValues()
    }
  }
  // converted custom formula json to array 
  getConvertedFormulaJsonToArray(formulaDetail){
    let formulaObj=[]
    let fieldsJson=[...this.arrayOfCustomFiled,...this.constantService.getDefaultFormulasOPtions()].reduce((json,data)=>{json[data.key]=data;return json},{})
    for (const customkey in formulaDetail) {
      let customFormulas=formulaDetail[customkey]?.length ? formulaDetail[customkey][0] : formulaDetail[customkey]
      for (const formulaKey in customFormulas) {
        let obj={
          customFieldKey:customkey,
          customFieldDisplayKey: fieldsJson[customkey] ? fieldsJson[customkey]?.display_key : customkey,
          formulaKey:formulaKey,
          valueType:this.formulasOptionsFields[formulaKey]?.value_type,
          displayKey:this.formulasOptionsFields[formulaKey]?.display_key,
          value:customFormulas[formulaKey] || 0,
          convertedValue:customFormulas[formulaKey] || 0
        }
        if(obj['valueType']=='Percentage') obj['convertedValue']= ((customFormulas[formulaKey]).toFixed(2) || 0)+" %"
        else if(obj['valueType']=='Size') obj['convertedValue']= this.commonUtils.formatBytes(customFormulas[formulaKey]) || 0
        formulaObj.push(obj)
      }
    }
    return formulaObj
  }
  // formula code end here
  log(item,custItem,leadInfo){
    console.log("number",item,custItem,leadInfo)
  }
  getLeadCopy(lead){
    this.commentLead= JSON.parse(JSON.stringify(lead))
  }
  getPopoverRef(popover,lead_id){
    this.popoverRef={
      popover:popover,
      leadId:lead_id,
    }
  }
  triggerButton(lead,customKey){
    let customObj={custom_fields:{}}
    customObj["custom_fields"][customKey]={'event':"clicked"}
    this.httpTransfer.updateLeadCorrespondingToLeadId(customObj,lead?.dashboard_id,lead?._id).subscribe((res) => {
      if (res.status === 200) {}
    })
  }
  isItemFieldReadOnly(lockfield,lead){
   return  !(this.dashboardUserRole?.includes("DASHBOARD_ADMIN") || !lockfield) && (lead?.lead_colloborators_users?.includes(this.userId) || (this.dashboardUserRole && this.dashboardUserRole.length>0 && !this.dashboardUserRole?.includes("READ_ONLY"))) || this.isReadOnly
  }

  selectedPhase(phaseData){
    this.phaseData=phaseData
    this.phaseName=this.phaseData?.name
    this.phaseStartDate=this.phaseData?.planned_start_date_long || null
    this.phaseEndDate=this.phaseData?.planned_end_date_long || null
    this.checkPhaseDetailsValid()
  }

  checkPhaseDetailsValid(){
    let valid=true
    if(this.phaseName.trim().length < 3 || this.phaseName.trim().length > 100){
      valid=false
    }
    if((!this.phaseStartDate && !this.phaseEndDate) || (this.phaseStartDate && this.phaseEndDate && this.phaseEndDate<this.phaseStartDate)){
      valid=false       
    }
    this.isInValidDetails=valid
  }

  startPhase(){
    let inputJson={}

    inputJson['name']=this.phaseName
    if(this.phaseStartDate) inputJson['planned_start_date']=this.phaseStartDate
    if(this.phaseEndDate) inputJson['planned_end_date']=this.phaseEndDate
    inputJson['current_status']='STARTED'
    this.httpTransfer.updatePhaseCorrespondingToDashboard(this.selectedDashId,inputJson,this.phaseData?._id).subscribe(async res=> {
      if(res !== undefined && res && res?.status==200) {
        
      }
    })
  }


  async openTransferItems(modal,item){
  
    this.transferFromPhase=item._id;
    this.sprintName=item.name
    this.incompleteItemsCount= await this.getPhaseItemCount('NON_FINAL')  
    this.completedItemsCount= await this.getPhaseItemCount('FINAL')
  
    this.transferPhaseList=this.dashBoardSprint.filter(ele=> ele.current_status!='STOPPED' && !ele.is_archive)
    this.ngbModalService.open(modal, { size: 'md' ,windowClass: 'transferSprintModal'})
  }
  
  checkUserHaveEditAccessInDashboard() {    
    return this.commonUtils.checkUserHaveEditAccessInDashboard(this.dashboardUserRole, false);
  }
  
  async getPhaseItemCount(type){
    let input={}
    input['dashboard_id']=[this.selectedDashId]
    input['sprint_id']=[this.transferFromPhase]
    input['bucket_type']=[type]
  
    input['pagination_details']={
      'page_size':1,
      'start_index':0
    }
  
    let totalCount=0
    let res= await this.httpTransfer.getLeadQuery(input, this.selectedDashId).toPromise()
    if(res.status == 200) {
      totalCount=res.result.pagination_details.total_records ? res.result.pagination_details.total_records : 0
    }
    return totalCount
  }

  getDasahboarData(){
    if(this.multipleBoardIds.length || this.boardDataListInfo.paginationDetails && this.boardDataListInfo.paginationDetails?.total_records<=this.boardDataListInfo.dashboardList?.length){
      return 
    }
    let inputJson = {
      status:["ACTIVE"],
      get_my_boards: true,
      "type": ["BOARD"],
      "access_type":  ["PRIVATE", "INTERNAL"],
      pagination_details:{page_size:15,start_from:this.boardDataListInfo.dashboardList?.length || 0}
    }
    this.boardDataListInfo.ScrollLoaderr=true;
    this.httpTransfer.getDashBoardForAdmin(inputJson).subscribe((res)=>{ 
       (res['result']['dashboards'] || [])?.forEach(data=>{
        this.boardDataListInfo.dashboardList?.push(data.dashboard_id)
        if(!this.dashboardData[data?.dashboard_id])this.dashboardData[data?.dashboard_id]={}
        this.dashboardData[data?.dashboard_id]['DASHBOARD_INFO'] = data
       })   
       if(!this.selectedDashId && this.boardDataListInfo.dashboardList?.length)this.selectedDashId=this.boardDataListInfo.dashboardList[0]
       this.boardDataListInfo.paginationDetails=res?.result?.pagination_details
       delete this.boardDataListInfo.ScrollLoaderr
    },err=>{
      delete this.boardDataListInfo.ScrollLoaderr
    });  
  }
}
 