import { Component, Input, OnInit } from '@angular/core';
import { Role } from '../../../../Enums/Role';
import { MessageService } from '../../../message.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { CustomStorageService } from '../../../services/custom-storage.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit {
  @Input() profileCall:boolean=false
  @Input() homeCall:boolean=false
  constructor() { }

    async ngOnInit(){
}

}
