import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateDateYear'
})
export class TruncateDateYearPipe implements PipeTransform {

  transform(value: string, args: boolean): unknown {
    if(!args){
      let date = new Date(value);
      if(date.toString() != "Invalid Date"){
        return value.length > 0 ? value.substring(0,value.trim().lastIndexOf(' ')) : value;
      }else{
        return value;
      }
    }
    // if(args)
    // return value;
  }

}
