
  <div class="dropdown multi " (click)="$event.stopPropagation()">
    <button class="w-100 btn btn-default d-flex align-items-center dropdown-toggle appRowBox" type="button" (click)="toggleDropdown($event)" style="justify-content: space-between;" [ngClass]="{'boardButton' : viewType=='BOARD','hide-dropdown-toggle-after::after' : viewType=='ITEM_VIEW'}">
      <div class="appDiv">
        <span class="iconBox-20" *ngIf="viewType!='ITEM_VIEW' && viewType!='BOARD' && viewType!='ITEM_OPTION'">
        <app-dashboard-icon class="appInnerIconBox" [size]="20" [name]="'Apps'" [iconJson]="attributesIcons"></app-dashboard-icon>
        </span>
        <span>{{buttonLabel}}</span></div> 
    </button>
    <ul class="dropdown-menu showApps" *ngIf="isDropdownOpen" [class.show]="isDropdownOpen" [ngClass]="{'dropPosition': isDropdownOpen,'drop-left': isDropLeft}">
      <ng-template #recursiveList let-items let-index="index">
        <ng-container *ngFor="let item of items">
          <li [class.dropdown-submenu]="appConnectionJson[item?.externalapp_id]?.connection">
            <a class="optionDetailBox " [ngClass]="{'dropdown-toggle': index!=-1}" (click)="appConnectionJson[item?.externalapp_id]?.connection && toggleSubmenu(item,$event,index)">
              <span class="iconBox-20"><ngx-avatars class="appInnerIconBox" [name]="item.name" [src]="appConnectionJson[item?.externalapp_id]?.appInfo?.info?.icon?.image_download_url" [bgColor]="appConnectionJson[item?.externalapp_id]?.appInfo?.info?.color" size="20" initialsSize="2" ></ngx-avatars></span>
              <span class="f-l-r">{{ item.name }}</span>
            </a>
            <ul class="dropdown-menu dropPosition showApps" style="display: block !important" *ngIf="item.isOpen" [ngClass]="{'dropPosition': isDropdownOpen,'drop-left': isDropLeft}">
              <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: appConnectionJson[item?.externalapp_id]?.connection,index:-1}"></ng-container>
            </ul>
          </li>
        </ng-container>
      </ng-template>
      <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: appFeatureArray,index:0 }"></ng-container>
    </ul> 
  </div>
  
  

