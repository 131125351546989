import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "daysago",
})
export class DaysagoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    var date = new Date(value);
   
    var since;
    since = this.timeSince(date,args);

    var finalago = since + " ago";

    return finalago;
  }

  timeSince(date,isDiffCalculated?) {
    var newdate;
    newdate = new Date();
    var seconds;
    if(isDiffCalculated || date.getTime()<1576800000){
      seconds = date
    }
    else{
      seconds = Math.floor((newdate - date) / 1000);
    }
    
    var interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
      return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval + " months";
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + " minutes";
    }
    if (seconds < 0) {
      seconds = 0;
    }

    return Math.floor(seconds) + " seconds";
  }
}
