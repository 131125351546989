import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Input() title: any;
  @Input() description: any;
  @Output() action = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  onSelect(flag) {
    this.action.emit(flag);
  }

}
