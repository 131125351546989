import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonUtils } from '../../services/CommonUtils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
@Component({
  selector: 'app-sprint-transfer',
  templateUrl: './sprint-transfer.component.html',
  styleUrls: ['./sprint-transfer.component.scss']
})
export class SprintTransferComponent implements OnInit {

  @Input() dashId: string;
  @Input() completedItemsCount:number=0
  @Input() incompleteItemsCount:number=0
  @Input() sprintId: string;
  @Input() transferPhaseList:any[]=[];
  @Input() phaseCompleted:boolean=false

  isTransferPhase:boolean=false
  transferFromPhase:any
  transferToPhase:any
  itemTerminology: any={};
  selectedPhase:any
  @Output() closeModal = new EventEmitter<any>();


  constructor(private httpTransfer:HttpTransferService,private dashboardUtils: DashboardUtilsService,
    private commonUtils: CommonUtils) { }

  ngOnInit() {
    this.itemTerminology=this.dashboardUtils.getDashboardTerminoloy(this.dashId)

  }


  transferIncompleteItems(){
    let inputJson={}
    let filterProperties={}
    filterProperties['dashboard_id']=[this.dashId]
    filterProperties['sprint_id']=[this.sprintId]
    filterProperties['bucket_type']=['NON_FINAL']
    inputJson['filter_properties']=filterProperties
    inputJson['update_json']={
      'sprint_id':this.transferToPhase
    }
    this.httpTransfer.updateMultipleDashboard(this.dashId,inputJson).subscribe(res=>{
      this.closeModal.emit()
      console.log('transferred')
    })
  }


  phaseComplete(){
    let input={}
    input['current_status']='STOPPED'
    this.httpTransfer.updatePhaseCorrespondingToDashboard(this.dashId,input,this.sprintId).subscribe(async res=> {
      if(res !== undefined && res && res?.status==200) {
        if(this.isTransferPhase){
          this.transferIncompleteItems()
        }
        else{
          this.closeModal.emit()
        }
      }
    })
  }


}
