import { Component, AfterViewInit, Input, ElementRef, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';

@Component({
  selector: 'app-columns-chip',
  templateUrl: './columns-chip.component.html',
  styleUrls: ['./columns-chip.component.scss']
})
export class ColumnsChipComponent implements OnInit {
    @Output() sendColumnEmit : EventEmitter<any> = new EventEmitter();
    @Input() dashId:any;
    @Input() mediaDisable: boolean =false;
    arrOfCustomField: any[]=[];
    optionEnableForColumns:boolean=false;
    selectedCustomObj: any;
    mediaType : any[]=['SingleFile','SingleAudio','SingleImage','SingleVideo','MultipleImage','MultipleVideo','MultipleFile','MultipleAudio']
    constructor(public dashboardUtils : DashboardUtilsService){}
    async ngOnInit(){
        console.log(this.optionEnableForColumns)
        if(this.dashId){
            let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
            this.arrOfCustomField = JSON.parse(JSON.stringify(dashboardJson[this.dashId]['CUSTOM_FORM']));
          }
          if(this.mediaDisable){
            this.arrOfCustomField = this.arrOfCustomField.filter(m=>!this.mediaType.includes(m?.type) && !this.mediaType.includes(m?.mirror_column_type))
          }
    }

    getColumns(key,display_key){
       this.getcustomColumns({'key':key,'display_key':display_key})
    }

    getcustomColumns(custObj,isCustom=false,mirror=false){
        custObj['isCustom'] = isCustom
        custObj['mirror'] = mirror
        let multiTypeArr = ['Checkbox','MultiUser','MultiSelect','MultiUrl','CheckList','MultipleImage','MultipleVideo','MultipleFile','MultipleAudio','Mirror']
        if(multiTypeArr.includes(custObj?.type)){
            this.optionEnableForColumns = true;
            this.selectedCustomObj = custObj
        }
        else{
          this.sendColumnEmit.emit(custObj)
        }
    }

    
}