import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
declare var $: any;
import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpTransferService } from '../../../services/httpTransfer.service';

@Component({
  selector: 'app-audio-recording',
  templateUrl: './audio-recording.component.html',
  styleUrls: ['./audio-recording.component.scss']
})

export class AudioRecordingComponent implements OnInit {
  @Input("leadId") idx: any;
  @Output("recordedData") recordedData = new EventEmitter<any>();
  @Output("recordingStarted") recordingStarted = new EventEmitter<boolean>();
  private segmentNumber:number=1;
  private uploadingId :string=undefined;
  private uploadArray:any=[];
  private inputJson:any={};
  imagePath: string;
  isCompleted: boolean = false;
  stream:any = {}
  
  title = 'micRecorder';
//Lets declare Record OBJ
  record: any;
  //Will use this flag for toggeling recording
  recording:boolean = false;
  //URL of Blob
  url:string;
  error:any;

  constructor(
    private httpTransfer: HttpTransferService,
    private domSanitizer: DomSanitizer) {}

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  successCallback(stream) {
    var options = {
      mimeType: "audio/mp3",
      numberOfAudioChannels: 1
    }
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder
    this.record = new StereoAudioRecorder(stream, options)
    this.record.record();
  }

  stopAudioRecording() {
    this.isCompleted = true;
    this.recordingStarted.emit(false);
    this.record?.stop(this.processRecording.bind(this))
    if (this.record) {
      this.record = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream = null;
      }
    }
  }

  processRecording(blob) {
    this.updateBlobToServer(blob);
    this.url = URL.createObjectURL(blob);
    console.log("url", this.url);
  }

  errorCallback(error) {
    console.log('Can not play audio in your browser');
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(str=>{
      this.stream = str;
      this.successCallback(str);

    }).catch(this.errorCallback.bind(this));
    this.recordingStarted.emit(true);
  }


  async updateBlobToServer(binary: Blob){
    let binaryData = new File([binary], 'audio_'+(new Date().getTime())+".mp3")
    console.log(binaryData, typeof(binaryData))
    // binaryData['name'] = 'audio_'+(new Date().getTime());
    // binaryData['lastModified']=new Date().getTime()
    // binaryData['lastModifiedDate'] = new Date()
    // binaryData['type']='audio/wav'
    if(this.segmentNumber===1){
      this.inputJson["file_name"] = binaryData.name.lastIndexOf(".") != -1 ? binaryData.name.substring(0, binaryData.name.lastIndexOf(".")) : binaryData.name;
      this.inputJson["extension"] = binaryData.name.split(".").pop();
      this.inputJson["type"] = "LEAD";
      this.inputJson['file_type']=binaryData.type;
    }
      this.inputJson['part_number'] = this.segmentNumber;
  
    
      //requesting server for upload url
      let uploadResponse : any = await this.httpTransfer.largefileUploadForOrg(undefined,this.inputJson).toPromise();
      uploadResponse = uploadResponse.result.response; 
      //uploading chunk to aws via url received from server
      let result:any = await this.httpTransfer.fileUploadOnAWSServer(uploadResponse.image_upload_url,binaryData).toPromise();
      //creating an multipart array required to mark complete of upload
      this.uploadArray.push({
        ETag:result.header.get('etag'),
        PartNumber:this.segmentNumber
       })
      
      //       
      if(this.segmentNumber===1){
        this.uploadingId = uploadResponse.upload_id;
        this.imagePath = uploadResponse.image_path;
        this.inputJson['upload_id']=this.uploadingId;
        this.inputJson['image_path']=this.imagePath;
       }

        //informing server about  part that has been succesfully uploaded to aws
        await this.httpTransfer.updateMultipartInfo(this.uploadingId,{
          completed_upload_for_part:this.segmentNumber,
      }).toPromise();
      if(this.isCompleted){
        await this.onCompleteRecording();
      }
      this.segmentNumber++;
   }

   async onCompleteRecording(){
      this.inputJson['parts']=this.uploadArray;
      this.inputJson['mark_completed']=true;
      let response : any = await  this.httpTransfer.largefileUploadForOrg(undefined,this.inputJson).toPromise();
      response=response.result?.response;
      await this.httpTransfer.updateMultipartInfo(this.uploadingId,{
        completed_upload_for_part:this.segmentNumber,
        mark_completed:true
      }).toPromise();
      
      // this.rec
      let emitObj = {
        image_download_url:response.image_download_url,
        path:this.inputJson['image_path'],
        file_name:this.inputJson['file_name'],
        
      }
      this.recordedData.emit(
        emitObj
      )
      this.inputJson={};
      this.segmentNumber=1;
      this.uploadingId="";
      this.imagePath="";
      
  }

  ngOnDestroy(){
    console.log('record destroyed');
  }

}

