<div style="height:100%; overflow-y: auto;
overflow-x: hidden;">
    <div class="w-100 userField" [attr.id]="'usersDiv_' + i" *ngFor="let user of searchFororgUsersJson;let i =index">
      <div class="userDetailsBox mx-2 pt-2 cursorPointer" (click)="getUser(user)" [ngClass]="{'con' : i === selectedIndex}">
        <span class="pr-2" >
            <ngx-avatars class="mr-1" [name]="user?.name" [src]="user?.image_url" size="30" initialsSize="2" ></ngx-avatars>
        </span>
        <span class="UserNameText"><strong>{{user?.name}}</strong>
            <br>
            <span class="emailIdOrTotalMembers">{{user?.email}}</span>
        </span>
      </div>
    </div>
</div>