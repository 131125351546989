<div class="user-profile-tooltip">
   <div class="profile-row1 row">
       <div class="usrProfPic">
         <ngx-avatars *ngIf="user" [name]="user?.name" [src]="user?.image_url" textSizeRatio="2.15" size="50" initialsSize="2" ></ngx-avatars>
           <!-- <img class="profile-pic" src="{{board.profile_image}}"> -->
       </div>
       <div class="user-name-tp"><span class="user-name ml-3" style=" width: 60%;
        text-overflow: ellipsis; 
        overflow-x: hidden;">{{user?.name | titlecase}}</span></div>
   </div>
   <div class="profile-row2">
       <div><img src="../../../assets/1x/email.png"> 
           <span >{{user?.email}}</span>
       </div>
   </div>
</div>