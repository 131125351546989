import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from '../../../services/toaster.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup,FormControl,Validators,NgForm} from '@angular/forms';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { CommonUtils } from 'app/services/CommonUtils.service';
@Component({
  selector: 'app-lead-sprint',
  templateUrl: './lead-sprint.component.html',
  styleUrls: ['./lead-sprint.component.scss']
})
export class LeadSprintComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
  @ViewChild('container', { static: false }) container!: ElementRef;
  @ViewChild('quickAddPhase') quickAddPhase: TemplateRef<any>;
  @Output() closePopover = new EventEmitter<any>();
  @Input() leadInfo:any[];
  @Input() dashId:number;
  @Input() selectedPhase:any;
  @Input() bulkSelectionFlag:boolean;
  @Input() dashBoardSprint: any;
  @Input() isGridCall:boolean=false;
  @Output() updateLead = new EventEmitter<any>();

  @Input() objFilterForMultiselect: any;

  searchText:any=""
  copyDashBoardSprint: any=[]
  archiveAndHidden:boolean=false
  addPhaseForm:FormGroup
  selectedSprintForStatus:any
  dashboardUserRole: any = [];
  selectedIndex=0
  constructor(
    private httpTransfer: HttpTransferService,
    private toaster:ToasterService,
    private modalService: NgbModal,
    private commonUtils: CommonUtils,
    private dashboardUtils: DashboardUtilsService,
    ) { }

  async ngOnInit() {
    this.dashBoardSprint = this.dashBoardSprint.filter(sprint => !sprint.is_archive)
    this.copyDashBoardSprint=this.dashBoardSprint;
    let dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.dashId])
    this.dashboardUserRole = dashboardInfo[this.dashId]?.DASHBOARD_INFO?.role || [];
    // if(!this.dashboardUserRole.includes('DASHBOARD_ADMIN')){
    //   this.copyDashBoardSprint= this.copyDashBoardSprint.filter(ele=>!ele.is_hidden)
    // }
    this.addPhaseForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      planned_start_date: new FormControl(0),
      planned_end_date: new FormControl(0),
    }); 
  }

  ngAfterViewInit() {
    if(!this.isGridCall){this.searchInput.nativeElement.focus();}
  }

  async onSprintSelect(sprintId) {
    if(this.leadInfo) {
      if(this.leadInfo.length>0){

        if(this.leadInfo.length>1){

          let inputJson={}
       
          inputJson['filter_properties']=this.objFilterForMultiselect
          inputJson['update_json']={
            "sprint_id":sprintId
          }
          this.httpTransfer.updateMultipleDashboard(this.dashId,inputJson).subscribe((res : any) =>{
            this.closePopover.emit(true)
          })
        }
        else{
          await this.updateSprint(
            sprintId,
            this.leadInfo[0]._id,
            this.leadInfo[0].bucket_id);
        }

      } else {
        this.toaster.Errors("Please Select Items");
      }
    } else {
      this.updateLead.emit(sprintId);
    }
  }
  updateSprint(sprintId,leadId, bucketId) {
    this.httpTransfer.updateLeadCorrespondingToLeadId({sprint_id:sprintId}, this.dashId, leadId)
      .subscribe((res : any) => {
        if(res.status == 200) {
          this.updateLead.emit({'leadId':leadId,'bucketId':bucketId,'sprint_id':sprintId});         
          if(this.leadInfo) {
            this.leadInfo['sprint_id']=sprintId
          }
        }
      });
  }
  filterPhases(searchText) {
    searchText=searchText.trim().toLowerCase()
    this.copyDashBoardSprint=this.dashBoardSprint.filter(
        (data) => data.name.toLowerCase().indexOf(searchText) !== -1
    );
  }


  openQuickAddPhaseModal(modalContent: any) {
    this.modalService.open(modalContent, { size: 'md' });
    this.closePopover.emit(true)
  } 

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Tab':
        event.preventDefault()
        if(this.selectedIndex==-1){
          this.selectedIndex=0
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
        }
        else if(this.selectedIndex>=0 && this.selectedIndex<this.copyDashBoardSprint.length){
          this.selectedIndex=this.copyDashBoardSprint.length
        }else if(this.selectedIndex==this.copyDashBoardSprint.length && this.dashboardUserRole?.includes('DASHBOARD_ADMIN')){
          this.selectedIndex=-1
        }
        break;
      case 'ArrowUp':
        if (this.selectedIndex >0) {
          this.selectedIndex--;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
        }
        break;
      case 'ArrowDown':
        if (this.selectedIndex < this.copyDashBoardSprint.length - 1) {
          this.selectedIndex++;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
        }
        break;
      case 'Enter':
        if(0<=this.selectedIndex && this.selectedIndex<this.copyDashBoardSprint.length)this.onSprintSelect(this.copyDashBoardSprint[this.selectedIndex]?._id);
        else if(this.selectedIndex==this.copyDashBoardSprint.length)this.onSprintSelect(null);
        else if(this.selectedIndex==-1)this.openQuickAddPhaseModal(this.quickAddPhase);
        break;
    }
  }
}
