<div class="mt-3" style="display: flex;align-items: center; justify-content: space-between;">
<span>Multi Select
  <span class="switch-div mr-1 pt-3">
    <label class="switch">
        <input type="checkbox" (change)="toggleMultiSelect()" [checked]="isMultiSelectExpanded">
        <span class="slider round"></span>            
    </label> 
  </span>
</span>
<span class="clear-text" (click)="onSprintSelect(null)">Clear Selection</span>
</div>

<div *ngIf="!phasePopover" class="search searchborder mt-3">
  <input class="form-control" autofocus type="search" [(ngModel)]="searchText" placeholder="Search" aria-label="Search">
  <span><i class="fa fa-search ml-2 mt-1" style="color: #C9C9C9;"></i></span>
</div>
<div class="sprintselect-cnr scrollarea">
  <ul *ngFor="let data of sprintData | filter:searchText">
    <div>
      <li (click)="onSprintSelect(data.sprint)">
        <span [ngClass]="{'collapsed': !isMultiSelectExpanded}" ><input style="width: 11px;height: 11px; border:1px solid #eaeaea; margin-right: 0.1rem; padding-top: 0.1rem;" [checked]="selectedSprintIds.includes(data.sprint._id)" type="checkbox"></span>
        <span class="phaseName"> {{data.name}} </span>
        <span *ngIf="selectedSprintIds.includes(data.sprint._id) && !isMultiSelectExpanded" ><i class="far check fa-check-circle"></i></span>
        <span *ngIf="data.current_status=='STARTED'" class="pdot float-right"></span>
        <span *ngIf="data.current_status=='STOPPED'" class="cdot float-right"></span><br>
        <span class="float-right" *ngIf="data.is_archive" style="margin-top:0px;"> <img class="arcicon"src="../../../assets/img/icons/archive.svg"/></span>
        <span class="f-xs-l" [ngClass]="{'marginChange': isMultiSelectExpanded}">
          {{data.planned_start_date}} <span *ngIf="!data.planned_start_date"> {{'form.noStartDate' | translate}}</span> -
          {{data.planned_end_date}}<span *ngIf="!data.planned_end_date"> {{'form.noEndDate' | translate}}</span>
        </span>
      </li>
    </div>
  </ul>
</div>
