import {Directive,ElementRef,Output,HostListener,EventEmitter, ComponentFactoryResolver, ViewContainerRef, Renderer2, ComponentRef, Inject, Injector, Input} from "@angular/core";
import { TextAssistOptionsComponent } from "./text-assist-options/text-assist-options.component";
import { DOCUMENT } from "@angular/common";

@Directive({
  selector: "[textAssist]",
})
export class TextAssistDirective {
  isOutSideClick:boolean=true
  domElem:any;
  private textOptionsComponentRef: ComponentRef<TextAssistOptionsComponent> | null = null;
  @Input() optionFields:any={'suggestions':[],'aiSuggestions':[]};
  @Input() textEditable:boolean=true
  @Input() appendTo:any=null
  @Output() onOptionSelect = new EventEmitter<any>();
  eventListenersInfo:any={}

  constructor(private elementRef: ElementRef,  
    private renderer: Renderer2,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT) private document: Document) {
    }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event: MouseEvent): void {
    const selection = window.getSelection()
    const selectedText=selection?.toString();
    if (selectedText && selectedText.trim() !== '' && selection && selection.rangeCount > 0) {
      this.showTextOptions(selectedText,selection);
    } else {
      this.hideTextOptions();
    }
  }

  hangledPosition(selection){
    let rect = null;
    if(this.elementRef?.nativeElement?.tagName=='TEXTAREA' || this.elementRef?.nativeElement?.elementRef?.tagName=='INPUT'){
      rect = this.elementRef?.nativeElement?.getBoundingClientRect();
    }else{
      const range = selection?.getRangeAt(0);
      rect = range?.getBoundingClientRect();
    }
    let containerRect=this.appendTo!=='body' ? this.elementRef?.nativeElement?.getBoundingClientRect() : {left:0,top:0};
    const popoverWidth = this.domElem?.firstElementChild?.clientWidth;
    const popoverHeight = this.domElem?.firstElementChild?.clientHeight;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    let scrollLeft =0;
    let scrollTop = 0;
    if(this.appendTo=='body'){
      scrollLeft=window.scrollX;
      scrollTop=window.scrollY
    }
    let left = rect.left - containerRect.left + scrollLeft;
    let top = rect.top - containerRect.top + rect.height+ scrollTop  // Add scrollTop
    // // Adjust position to fit within the viewport
    // if (left + popoverWidth > viewportWidth + scrollLeft) {
    //   left = Math.max(viewportWidth + scrollLeft - popoverWidth - 20, scrollLeft); // 10px padding from the edge
    // }
    // if (top + popoverHeight > viewportHeight + scrollTop) {
    //   top = Math.max(viewportHeight + scrollTop - popoverHeight - 20, scrollTop); // 10px padding from the edge
    // }

    const popoverRightEdge = containerRect.left + left + popoverWidth;
    if (popoverRightEdge > windowWidth) {
      left -= (popoverRightEdge - windowWidth + 10); // 10px margin from the right edge
    }
    // Adjust if the popover goes beyond the bottom edge of the window
    const popoverBottomEdge = containerRect.top + top + popoverHeight;
    if (popoverBottomEdge > windowHeight) {
      top -= (popoverBottomEdge - windowHeight + 10); // 10px margin from the bottom edge
    }
    // Adjust if the popover goes beyond the left edge of the window
    if (containerRect.left + left < 0) {
      left = -containerRect.left + 10; // 10px margin from the left edge
    }
    // Adjust if the popover goes beyond the top edge of the window
    if (containerRect.top + top < 0) {
      top = -containerRect.top + 10; // 10px margin from the top edge
    }
    // left = Math.max(left, scrollLeft + 10); // 10px padding from the edge
    // top = Math.max(top, scrollTop + 30); // 10px padding from the edge
    const position = {
      top: `${top}px`,
      left: `${left}px`
    };
    return position
  }


  private showTextOptions(selectedText: string,selectionArea:any) {
    this.hideTextOptions();
    if (!this. textOptionsComponentRef) {
    
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TextAssistOptionsComponent);
      this. textOptionsComponentRef = this.viewContainerRef.createComponent(componentFactory);
      // this.document.body.appendChild(this.textOptionsComponentRef.location.nativeElement);
      const instance = this.textOptionsComponentRef.instance;
      instance.selectedText = selectedText;
      let selection = window.getSelection();
      instance.optionFields=this.optionFields
      instance.selectedTextInfo.selectedRange=selection.getRangeAt(0);
      instance.selectedTextInfo.elementRef=this.elementRef?.nativeElement;
      instance.selectedTextInfo.textEditable=this.textEditable
      instance.optionSelected.subscribe((option) => {
        if(option?.action)this.onOptionSelect.emit(option)
        this.hideTextOptions();
      });
      this.domElem = (this.textOptionsComponentRef.hostView as any).rootNodes[0] as HTMLElement;
      setTimeout(()=>{
        if(this.appendTo=='body')this.document.body.appendChild(this.domElem);
        let position=this.hangledPosition(selectionArea)
        instance.position = position
        // Position the component
        this.domElem.setAttribute('style',`left:${position.left};top:${position.top};position:absolute;margin-top:10px;z-index:9999;visibility:visible`);
        this.addEventsListener(instance,selectionArea)
      },1) 

    }
  }

  addEventsListener(instance,selectionArea){
    // Add an event listener to handle outside clicks
    this.eventListenersInfo.outsideClickListener = this.renderer.listen('document', 'click', (event: Event) => {
      if (this.textOptionsComponentRef && !this.domElem.contains(event.target as Node)) {
        this.hideTextOptions();
      }
    });
    this.eventListenersInfo.inputListener= this.renderer.listen('document', 'input', (event: Event) => {
      if (this.textOptionsComponentRef && !this.domElem.contains(event.target as Node)) {
        this.hideTextOptions();
      }
    });
    document.addEventListener('keydown', this.handleKeyPress.bind(this)); // close on escape
    // Set up ResizeObserver to reposition the popover if its size changes
    this.eventListenersInfo.resizeObserver = new ResizeObserver(() => {
      let position=this.hangledPosition(selectionArea)
      instance.position = position
      // Position the component
      this.domElem.setAttribute('style',`left:${position.left};top:${position.top};position:absolute;margin-top:10px;z-index:9999;visibility:visible`);
    });
    this.eventListenersInfo.resizeObserver.observe(this.domElem?.firstElementChild);
  }

  private handleKeyPress =(event: KeyboardEvent)=>{
    if (event.key === 'Escape') {
      this.hideTextOptions();
    }
  }

  // remove listeners
  removeListeners(){
    if (this.eventListenersInfo?.outsideClickListener) {
      this.eventListenersInfo?.outsideClickListener(); // This removes the listener
    }
    if(this.eventListenersInfo?.inputListener){
      this.eventListenersInfo.inputListener();
    }
    // Disconnect the ResizeObserver when the directive is destroyed
    if (this.eventListenersInfo?.resizeObserver) {
      this.eventListenersInfo?.resizeObserver?.disconnect();
    }
    document.removeEventListener('keydown',this.handleKeyPress.bind(this));
    this.eventListenersInfo={}
  }

  // hide popover 
  private hideTextOptions() {
    if (this. textOptionsComponentRef) {
      setTimeout(() => {
        this.textOptionsComponentRef?.destroy();
        this.textOptionsComponentRef = null;
        this.domElem?.remove()
      },1);  
    }
    // Remove the outside click listener
    this.removeListeners();
  }

  ngOnDestroy() {
    this.hideTextOptions()
  }
  

}
