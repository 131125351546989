<div *ngIf="bucketInfo?.name == 'Trash' && !isReadOnly" class="f-s-r" style="color: black;">
   <ng-container
       *ngIf="dashboardUserRole.include('DASHBOARDADMIN')">
       <div class="row deleteText g-0 text-start"  (click)="onDeleteLead()">
           {{'leadOption.permanentDelete' | translate}}
       </div>
   </ng-container>
</div>
<div *ngIf="bucketInfo?.name != 'Trash'" class="f-s-r" style="color: black;">
    <ng-container *ngIf="!showHiddenFields?.ishidden && isOpenedFullView">
        <div class="row deleteText g-0 text-start"
            (click)="showHiddenFieldsFunction(true)">
            {{'leadOption.showHiddenFields' | translate}}
        </div>
    </ng-container>
    <ng-container *ngIf="showHiddenFields?.ishidden && isOpenedFullView">
        <div class="row deleteText g-0 text-start" 
            (click)="showHiddenFieldsFunction(false)">
            {{'leadOption.hideHiddenFields' | translate}}
        </div>
    </ng-container>
    
   <ng-container *ngIf="!leadInfo.isStarred">
       <div class="row deleteText g-0 text-start" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
}"
           (click)="UpdateStarLead(true)">
           {{'leadOption.star' | translate}}
       </div>
   </ng-container>
   <ng-container *ngIf="leadInfo.isStarred">
       <div class="row deleteText g-0 text-start" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
}"
           (click)="UpdateStarLead(false)">
           {{'leadOption.unStar' | translate}}
       </div>
   </ng-container>
    <ng-container *ngIf="leadInfo.is_aging_time">
        <div class="row deleteText g-0 text-start" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
 || isReadOnly}"
                (click)='OpenAgingTimePopup(agingTimeModal)'>
            {{'leadOption.changeAgingTime' | translate}}
        </div>
    </ng-container>
    <ng-container *ngIf="!leadInfo.is_aging_time">
        <div class="row deleteText g-0 text-start" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
 || isReadOnly}"
                (click)='OpenAgingTimePopup(agingTimeModal)'>
            {{'leadOption.setAgingTime' | translate}}
        </div>
    </ng-container>
    <div class="row deleteText g-0 text-start" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
}"
       (click)="openInNewTab()">
       {{'common.openInNewTab' | translate}}
   </div>
    <div class="row copyLinkText" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
}"
       (click)="copyLeadLink()">
       {{'common.copyLink' | translate}}
   </div>
   <div *ngIf="leadInfo?.item_type==='ITEM_PUBLIC'" class="row copyLinkText" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
}"
       (click)="copyPublicItemLink()">
       {{'leadOption.copyPublicLink' | translate}}
   </div>
   <div [ngClass]="{'disableClick':isReadOnly}" (mouseenter)="exportPopover?.open()" (mouseleave)="exportPopover?.close()">
    <div class="row deleteText g-0 text-start" [ngbPopover]="exportPopoverTem" #exportPopover="ngbPopover" placement="left" [closeDelay]="1000" triggers="manual" >
        <span >
            {{'importExport.export' | translate}}
        </span>
        <ng-template #exportPopoverTem>
         <div class="row deleteText g-0 text-start" (click)="ExportPdf()">
            {{'importExport.exportAsPDF' | translate}}
         </div>
         <div class="row deleteText g-0 text-start" (click)="ExportExcel()">
            {{'importExport.exportAsExcel' | translate}}
         </div>
        </ng-template>
       </div>
   </div>
   <div>
        <div *ngIf="destinationBucketOfCorrespondingDash.length>0  && !isDuplicateItem" class="row copyLinkText" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
 || isReadOnly}"
            (click)="openMarkAsDuplicate()">
            {{'leadOption.markAsDuplicate' | translate}}
        </div>
        <div *ngIf="allBucketOfCorrespondingDash.length>0  && isDuplicateItem" class="row copyLinkText" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
 || isReadOnly}"
            (click)="openMarkAsDuplicate()">
            {{'leadOption.unmarkAsDuplicate' | translate}}
        </div>
        <div *ngIf="destinationBucketOfCorrespondingDash.length==0 && !isDuplicateItem" class="row disableText copyLinkText" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
 || isReadOnly}"
            placement="left-top"  popoverClass="popupclass"
            [ngbPopover]="noFinalBucketPopup" (mouseout)="noFinalBucketP.close()" (mouseenter)="noFinalBucketP.open()"  #noFinalBucketP="ngbPopover" [disabled]="true" container="body" > 
            {{'leadOption.markAsDuplicate' | translate}}
        </div>
         <div *ngIf="allBucketOfCorrespondingDash.length==0 && isDuplicateItem" class="row disableText copyLinkText" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
 || isReadOnly}"
            placement="left-top"  popoverClass="popupclass"
            [ngbPopover]="noNonFinalBucketPopup" (mouseout)="noNonFinalBucketP.close()" (mouseenter)="noNonFinalBucketP.open()"  #noNonFinalBucketP="ngbPopover" [disabled]="true" container="body" > 
            {{'leadOption.unmarkAsDuplicate' | translate}}
        </div>
        <div class="row copyLinkText" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
 || isReadOnly}"
            (click)="openLeadCopyModel(createCopyLeadModal)">
            {{'leadOption.createACopy' | translate}}
        </div>
        <div class="row copyLinkText" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
 || isReadOnly}"
            (click)="openLeadCopyMappingModalToBoard(createCopyToBoardMappingModal)">
            {{'leadOption.copyItemToAnotherBoard' | translate:{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}
        </div>
      <div *ngIf="!isFinalBucket" class="row deleteText g-0 text-start" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
 || isReadOnly}"
         (click)="openParentChildLeadModel(parentChildLeadTemplate,('Add Sub Item'),false)">
         {{'leadOption.addSubItem' | translate:{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}
        </div>
        <div *ngIf="isFinalBucket" [ngbTooltip]="isFinalBucket ? 'For final bucket you cannot add subitem':''"  tooltipClass="tooltipPopup" triggers="hover" placement="auto" class="row deleteText g-0 text-start disableClick">
         {{'leadOption.addSubItem' | translate:{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}
        </div>
        <div *ngIf="!leadInfo.parent_id" class="row deleteText g-0 text-start" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
 || isReadOnly}" (click)="openParentChildLeadModel(parentChildLeadTemplate,'Add Parent',true)">
            {{'leadOption.addParent' | translate}}
            </div>
            <div *ngIf="leadInfo.parent_id" class="row deleteText g-0 text-start" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
 || isReadOnly}" (click)="openDeleteItemModal(removeConfirmationModel);">
            Remove Parent Item
            </div>
            <div *ngIf="leadInfo.parent_id" class="row deleteText g-0 text-start" [ngClass]="{'disableClick':leadInfo.is_temp_deleted
 || isReadOnly}" (click)="openParentChildLeadModel(parentChildLeadTemplate,'Add Parent',true)">
            Change Parent Item
            </div>
            <div *ngIf="leadInfo?.is_temp_deleted
"class="row deleteText g-0 text-start" [ngClass]="{'disableClick':isReadOnly}"
            (click)="openDeleteItemModal(restoreConfirmationModel);">
            {{'common.restore' | translate}}</div>
        <div class="row deleteText g-0 text-start" *ngIf="!leadInfo?.is_temp_deleted
" [ngClass]="{'disableClick':isReadOnly}"
            (click)="openDeleteItemModal(deleteConfirmationModel);">
            {{'common.delete' | translate}}
        </div>
        <div class="row deleteText g-0 text-start" *ngIf="leadInfo.is_temp_deleted
 && (dashboardUserRole=='DASHBOARD_ADMIN' || leadInfo.created_by==loginUserId)"
        (click)="openDeleteItemModal(deleteConfirmationModel);" [ngClass]="{'disableClick':isReadOnly}">
            {{'leadOption.permanentDelete' | translate}}
    </div>
    </div>  
</div>
<!-- delete Lead modal-->
<ng-template #deleteConfirmationModel let-modal>
    <div class="modal-body text-center py-3">
      <h3>{{'common.confirmation' | translate}}</h3> 
      <img src="../../../assets/1x/info.png"><br><br>
      {{'leadOption.leadDeleteConfirmation' | translate :{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}<br><br>
      <button type="button" class="btn btn-danger rounded px-4 mr-3" (click)="onDeleteLead(); modal.dismiss()">{{'common.confirm' | translate}}</button>
      <button type="button" class="btn rounded cancelbtn px-4" (click)="modal.dismiss()">{{'common.cancel' | translate}}</button>
    </div>
  </ng-template>
<!-- Restore delete Lead modal-->
<ng-template #restoreConfirmationModel let-modal>
    <div class="modal-body text-center py-3">
      <h3>{{'common.confirmation' | translate}}</h3> 
      <img src="../../../assets/1x/info.png"><br><br>
      {{'leadOption.restoreLeadConfirmation' | translate :{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}<br><br>
      <button type="button" class="btn btn-danger rounded px-4 mr-3" (click)="RestoreLead(); modal.dismiss()">{{'common.restore' | translate}}</button>
      <button type="button" class="btn rounded cancelbtn px-4" (click)="modal.dismiss()">{{'common.cancel' | translate}}</button>
    </div>
</ng-template>
<!-- Confirmation delete Lead modal-->
<ng-template #confirmationDeleteModel let-modal>
    <div class="modal-body text-center py-3">
      <h3>{{'common.confirmation' | translate}}</h3> 
      <img src="../../../assets/1x/info.png"><br><br>
      {{'leadOption.dependLeadDeleteConfirmation' | translate :{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}<br><br>
      <button type="button" class="btn btn-danger rounded px-4 mr-3" (click)="onDeleteLead(true); modal.dismiss()">{{'common.confirm' | translate}}</button>
      <button type="button" class="btn rounded cancelbtn px-4" (click)="modal.dismiss()">{{'common.cancel' | translate}}</button>
    </div>
</ng-template>
<!-- Set Agging Time modal-->
<ng-template #agingTimeModal let-modal>
    <div class="mainDiv">
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{'leadOption.agingTime' | translate}}</h4>
            <button type="button" class="close pull-right" aria-label="Close"(click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body p-0" style="margin-top: -20px !important;">
            <date-picker  (click)="$event.stopPropagation()" 
            [dateRange]="{'from':agingTimeInput}"
            [focusDate]="agingTimeInput"
            (onDateSave)="updateAgingTime($event);modal.dismiss()"
            (onDateClear)="updateAgingTime(null);modal.dismiss()">
            </date-picker>
        </div>
    </div>
</ng-template>
<ng-template>
    <app-confirmation-modal (click)="$event.stopPropagation()"  [title]="'Confirmation'" [description]="'Deleting item has dependency on other items! still want to delete.'" (action)="showDeleteItemConfirmationModal = false; deleteConfirmedItem($event)"></app-confirmation-modal>
 </ng-template>
<!-- mark-duplicate-modal -->
<ng-template class="center" #markAsDuplicateModel let model>
    <div class="modal-content center">
        <div class="modal-header border-bottom pb-0">
            <h5 *ngIf="!isDuplicateItem" class="modal-title pull-left">{{'leadOption.markAsDuplicate' | translate}}</h5>
            <h5 *ngIf="isDuplicateItem" class="modal-title pull-left">{{'leadOption.unmarkAsDuplicate' | translate}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="markDuplicateModalRef.close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="">
            <app-mark-duplicate-lead [leadId]="leadInfo._id" [dashId]="selectedDashId" 
           (close)="markDuplicateModalRef.close()"
           [leadInfo]="leadInfo" [isDuplicateItem]="isDuplicateItem">
           </app-mark-duplicate-lead> 
       </div>
    </div>
</ng-template>

  <!-- createLeadCopyModal lead-modal-->
<ng-template #createLeadCopyModal>
    <app-create-lead-modal style="height: 100%;"
         [dashId]="selectedDashId" [leadInfo]="copyLeadData"
        [isItemCopy]="true" [subTaskCreation]="subTaskCreation" [leadIdForSubTask]="sameParentCreation ? copyLeadData?.parent_id : (subTaskCreation ? copyLeadData._id : null)" 
        [sameParentCreation]="sameParentCreation"
        (createLeadModalCloseEvent)="leadCopyModalRef.close()" >
    </app-create-lead-modal>
</ng-template>
<ng-template #noFinalBucketPopup>
    <div class="file-info-popover" style="width:200px;"> 
        <p>{{'leadOption.noFinalBucketForMarkDuplicate' | translate:{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}</p>
    </div>
</ng-template>  
<ng-template #noNonFinalBucketPopup>
    <div class="file-info-popover" style="width:200px;"> 
        <p>{{'leadOption.noNonFinalBucketForMarkDuplicate' | translate :{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}</p>
    </div>
</ng-template>  


<!-- Creates a copy-->
<ng-template #createCopyLeadModal let-modal>
    <div class="modal-content">
    <div class="modal-header border-bottom pb-0">
      <h5 class="modal-title pull left">{{'leadOption.createACopy' | translate}}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
        <div class="modal-body">
            <div class="headCopyItem">
                <div class="prioLabel" [style.background]="constantService?.priority_colors[leadInfo.priority]">
                <span *ngIf="leadInfo.priority==0">L</span>
                <span *ngIf="leadInfo.priority==1">M</span>
                <span *ngIf="leadInfo.priority==2">H</span>
                <span *ngIf="leadInfo.priority==3">U</span>
                </div>
                <span class="leadTitle MontserratFont">#{{leadInfo.seq_id}}</span>
          </div>
          <div style="margin:10px;max-height:100px;overflow-y: auto;overflow-x: hidden;">
            <p class="leadDescription mb-0" style="color:black;" *ngIf="!readMore">{{leadInfo.title | truncate:200}}
                <span (click)="readMore=true" class="readLessMoreBtnText" *ngIf="leadInfo.title.length>120" style="color: #2e4765; cursor: pointer;">
                    {{'editcustom.readMore' | translate}}</span> 
            </p>
            <p class="leadDescription mb-0" style="color:black;" *ngIf="readMore">{{leadInfo.title}}
                <span (click)="readMore=false" class="readLessMoreBtnText" style="color: #2e4765; cursor: pointer;">
                    {{'editcustom.readLess' | translate}}</span> 
            </p>
          </div>
          <div style="margin-top:30px;">
            <label style="color:black;">{{'editcustom.attributesToInclude' | translate}}</label>
            <div class="row">
                <div class="col-md-4">
                    <span><input type="checkbox" class="mr-1" checked="true" (change)="checkForAttributes($event,'priority')"/>
                        <span><app-dashboard-icon  [name]="'Priority'" [iconJson]="AttributesIcons"></app-dashboard-icon></span>
                        {{'dashboard.filter.priority' | translate}}</span>
                </div>
                <div class="col-md-4">
                    <span><input type="checkbox" class="mr-1" checked="true" (change)="checkForAttributes($event,'assigned_to')"/>
                        <span><app-dashboard-icon  [name]="'Assignee'" [iconJson]="AttributesIcons"></app-dashboard-icon></span>
                        {{'dashboard.filter.assignee' | translate}}</span>
                </div>
                <div class="col-md-4">
                    <span><input type="checkbox" class="mr-1" checked="true" (change)="checkForAttributes($event,'tags')"/>
                        <span><app-dashboard-icon  [name]="'Tags'" [iconJson]="AttributesIcons"></app-dashboard-icon></span>
                        {{'dashboard.filter.tags' | translate}}</span>
                </div>
            </div>
            <div class="row">    
                <div class="col-md-4">
                    <span><input type="checkbox" class="mr-1" checked="true" (change)="checkForAttributes($event,'title')"/>
                        <span><app-dashboard-icon  [name]="'Text'" [iconJson]="AttributesIcons"></app-dashboard-icon></span>
                        {{'editcustom.summary' | translate}}</span>
                </div>
                <div class="col-md-4">
                    <span><input type="checkbox" class="mr-1" [checked]="phaseToggle" [disabled]="!sprintEnabled" (change)="checkForAttributes($event,'sprint_id')"/>
                        <span><app-dashboard-icon  [name]="'Sprints'" [iconJson]="AttributesIcons"></app-dashboard-icon></span>
                        {{'form.phase' | translate}}</span>
                </div>
                <div class="col-md-4">
                    <span><input type="checkbox" class="mr-1" checked="true" (change)="checkForAttributes($event,'start_date')"/>
                        <span><app-dashboard-icon  [name]="'Date'" [iconJson]="AttributesIcons"></app-dashboard-icon></span>
                        {{'itemattributes.date' | translate}}</span>
                </div>
            </div>
            <div class="row">    
                <div class="col-md-4">
                    <span><input type="checkbox" class="mr-1" checked="true" (change)="checkForAttributes($event,'lead_colloborators_users')"/>
                        <span><app-dashboard-icon  [name]="'Collaborator'" [iconJson]="AttributesIcons"></app-dashboard-icon></span>
                        {{'dashboard.filter.collaborators' | translate}}</span>
                </div>
                <div class="col-md-4">
                    <span><input type="checkbox" class="mr-1" checked="true" (change)="checkForAttributes($event,'bucket_id')"/>
                        <span><app-dashboard-icon  [name]="'Bucket'" [iconJson]="AttributesIcons"></app-dashboard-icon></span>
                        {{'form.bucket' | translate}}</span>
                </div>
                <div class="col-md-4" *ngIf="leadInfo.parent_id && leadInfo.parent_id!=null">
                    <span><input type="checkbox" class="mr-1" [(ngModel)]="sameParentCreation" [disabled]="subTaskCreation" />
                        <span><app-dashboard-icon  [name]="'Bucket'" [iconJson]="AttributesIcons"></app-dashboard-icon></span>
                        {{'editcustom.parent' | translate}}</span>
                </div>
            </div>
          </div>
          <div class="customBox" style="margin-top:15px;">
            <label style="color:black;">{{'editcustom.customAttributes' | translate}}</label>
            <div class="row">
                 <div class="col-md-4" *ngFor="let field of copyLeadcustomfieldarr">
                    <span><input type="checkbox" class="mr-1" [(ngModel)]="field.checked"/>
                        <span *ngIf="field.type!='Mirror'"><app-dashboard-icon  [name]="field.type" [iconJson]="AttributesIcons"></app-dashboard-icon></span>
                        <span *ngIf="field.type=='Mirror'"><app-dashboard-icon  [name]="field.mirror_column_type" [iconJson]="AttributesIcons"></app-dashboard-icon></span>
                        {{field.display_key}}</span>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-bottom pb-0">
            <span><input type="checkbox" class="mr-1" [disabled]="sameParentCreation" [(ngModel)]="subTaskCreation"/>{{'editcustom.connectAsSubTask' | translate}}</span>
            <button type="button" class="nextBtn" (click)="modal.dismiss();openCreateCopyModal(createLeadCopyModal)">{{'editcustom.next' | translate}}
            </button>
          </div>
    </div>
</ng-template>


<ng-template #createCopyToBoardMappingModal let-modal>
    <div class="mappingModal">
        <div class="mapping-modal-header">
            <p class="modal-title pull left m-0"> {{'leadOption.copyItemToAnotherBoard' | translate :{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}  </p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="mapping-modal-body">
            <app-lead-mapping
            [dashboardUserRole]="dashboardUserRole"
            [bucketInfo]="bucketInfo"
            [leadInfo]="leadInfo" 
            [dashId]="selectedDashId"
            (transferCompleted)="modal.dismiss()"
            (copyItemToBoard)="openLeadCopyModalToBoard($event);modal.dismiss()"
            ></app-lead-mapping>
        </div>
    </div>
</ng-template>

  <!-- createLeadCopyModal lead-modal-->
<ng-template #createCopyToBoardModal>
    <app-create-lead-modal 
    style="height: 100%;"
    [dashId]="copyLeadDashId" [movefromDashboardId]="selectedDashId" [moveLeadId]="leadId"
    [leadInfo]="copyLeadInfo" [isItemCopy]="true" [creatingFromlist]="false"
    (leadmovedToOtherBoard)="copyLeadInfo.is_deleted=true" 
    (createLeadModalCloseEvent)="createCopyToBoardModalRef.close()" [copyToOtherBoard]="true">
    </app-create-lead-modal>
</ng-template>

<!-- delete Lead modal-->
<ng-template #removeConfirmationModel let-modal>
    <div class="modal-body text-center py-3">
      <h3>{{'common.confirmation' | translate}}</h3> 
      <img src="../../../assets/1x/info.png"><br><br>
      {{'leadOption.parentDeleteConfirmation' | translate :{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}<br><br>
      <button type="button" class="btn btn-danger rounded px-4 mr-3" (click)="onRemoveParent(); modal.dismiss()">{{'common.confirm' | translate}}</button>
      <button type="button" class="btn rounded cancelbtn px-4" (click)="modal.dismiss()">{{'common.cancel' | translate}}</button>
    </div>
  </ng-template>