import { MqttmessagingService } from '../../../services/mqttmessaging.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnDestroy,
  TemplateRef,
  ChangeDetectorRef,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ConstantService } from '../../../../Enums/Constant.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { BasicUtils } from 'app/services/basicUtils.service';
import { AttributesIcons, ItemIcons, NavBarIcons } from 'Enums/attributes-icons';
import { MessageService } from 'app/message.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-lead-modal-header",
  templateUrl: "./lead-modal-header.component.html",
  styleUrls: ["./lead-modal-header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LeadModalHeaderComponent implements OnInit, OnDestroy {
  @Output() minimizeRight = new EventEmitter<any>();
  @Input() showMinimizedIcon:boolean=false;
  @Input("dashId") dashId: any;
  @Input("leadId") leadId: any;
  @Input("dashBoardSprintArr") dashBoardSprintArr: any[];
  @Input() hideClosebtn:boolean=false
  @Input() leadObj: any;
  @Input() isTemplateCall: boolean=false; 
  @Input() showHiddenFields:any={};
  @Input() currentItemView:any=null
  @Input() tableInfo:any=null
  @Input() itemStateData:any=[]
  @Input() itemConfig:any={}  
  @Input() isReadOnly: boolean=false;
  @Input() isPublicLead:boolean=false;
  @Output("modalClose") modalClose = new EventEmitter<Boolean>();
  @Output() onitemViewTypeChange = new EventEmitter<any>();
  @Output() onItemChange = new EventEmitter<any>();
  @Output() updateLead = new EventEmitter<any>();
  @Output() showSubtaskParentModal = new EventEmitter<any>();
  @Output("refreshLeadCard") refreshLeadCard = new EventEmitter<Boolean>();
  dashName: any;
  bucketOfCorrespondingDash: any[] = [];
  currentBucketInfo: any;
  isStarred: boolean = false;
  interval: any;
  mqttSubscription: Subscription;
  leadHierarachySeqArray: any[] = [];
  updatingleadHierarachy:boolean=false
  allowedBuckets: any[]=[];
  isLeadInTemplate:boolean=false
  isInternalBoardReadOnly:boolean=false
  currentLeadDetails: any;
  dashboardUserRole:any;
  itemTerminology: any={};
  dashboardFolterName:any;
  itemIcons = ItemIcons;
  navBarIcons = NavBarIcons;
  isShowHierarchy:boolean=false
  boardInfo: any={};
  subtaskUpdateName:string='Sub Task';
  dependencyUpdateName:string='Dependencies'

  constructor(
    private httpTransfer: HttpTransferService,
    private commonUtils: CommonUtils,
    private constantService: ConstantService,
    private MqttmessagingService: MqttmessagingService,
    private dashboardUtils: DashboardUtilsService,
    private cdr: ChangeDetectorRef,
    private basicUtils:BasicUtils,
    private msgservice: MessageService,
    private router: Router, 
  ) {
    this.listenForMqttMessages();
  }
  
  activityTypeKey = this.constantService.getActivityTypeKey();
  updateActivityTYpe = this.constantService.getUpdateActivityType();
  typeKey = this.constantService.getObjectTypeKey();
  leadObjectTYpe = this.constantService.getLeadObjectType();
  detailsKey = this.constantService.getDetailskEY();

  listenForMqttMessages() {
    this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
      if (message) {
          this.updatesFromWss(message)
      }
    });
  }

  updatesFromWss(messagees) {
    let message = JSON.parse(messagees);
    if (message[this.activityTypeKey] == this.updateActivityTYpe && message['additional_attributes']['details']['_id'] == this.leadObj['_id']) {
      if (message[this.typeKey] == this.leadObjectTYpe) {
        this.handleLeadUpdateTypeOperation(message['additional_attributes']['details']);
      }
    }
  }

  handleLeadUpdateTypeOperation(message) {
    this.leadObj = this.commonUtils.creatingJsonStructureOfLeadForTableView(message);
    if(this.leadObj?.parent_id != this.currentLeadDetails?.parent_id && !this.updatingleadHierarachy && this.isShowHierarchy){
      this.updatingleadHierarachy=true
      this.cdr.detectChanges()
      this.leadQueryCorrespondingToLeadSeqId()
    }
  }

  async ngOnInit() {
    this.leadHierarachySeqArray=[this.leadObj];
    let dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.dashId])
    if(this.isPublicLead){
       this.isReadOnly=true;
    }else{
      this.dashboardUserRole = dashboardInfo[this.dashId]?.DASHBOARD_INFO?.role || [];
      this.commonUtils.isLeadOrDashboardReadOnlyOrViewOny(this.dashId).then((res : any) => { 
          this.isLeadInTemplate=res.is_template_board
          this.isInternalBoardReadOnly=res.is_internal_board && res.no_dashboard_user;
      });
      this.boardInfo =dashboardInfo[this.dashId]?.DASHBOARD_INFO || {}
      if(this.itemConfig?.showBoardHierarchy){
        if(dashboardInfo[this.dashId]?.DASHBOARD_INFO?.dashboard_folder_id){
          let dashboardFolder=await this.dashboardUtils.getDashboardFolderData([dashboardInfo[this.dashId]?.DASHBOARD_INFO?.dashboard_folder_id])
          this.dashboardFolterName=dashboardFolder[dashboardInfo[this.dashId]?.DASHBOARD_INFO?.dashboard_folder_id]?.name
        }
      }
    }
    document.addEventListener('keydown',this.handleEscapseKey)
    dashboardInfo[this.dashId]?.EXISTING_CUSTOM_FORM?.forEach(ele=>{
      if(ele?.key==='sub_task'){this.subtaskUpdateName=ele?.display_key}
      if(ele?.key==='dependency'){this.dependencyUpdateName=ele?.display_key}
    })
  }

  // refresh item data of current lead
  refreshHeader(lead?){
    this.leadObj=lead
    this.leadHierarachySeqArray=[this.leadObj]
    if(!this.isPublicLead){
      this.commonUtils.isDashboardReadOnly(this.dashId, this.leadObj?.lead_colloborators_users || [],this.leadObj).then((res : any) => { 
        this.isReadOnly = res;     
      });
    }
   
  }

  async getAllowedBuckets(bucket, item) {
    let allowedBucketsIds = []
    allowedBucketsIds = await this.commonUtils.getAllowedBuckets(this.dashId, bucket._id, this.bucketOfCorrespondingDash, item);
    allowedBucketsIds.push(bucket._id);
    this.allowedBuckets = this.bucketOfCorrespondingDash.filter(bucketJson => allowedBucketsIds.includes(bucketJson._id))
  }

  leadQueryCorrespondingToLeadSeqId(leadHierarachy?) {
    if(leadHierarachy){
      this.leadHierarachySeqArray=[this.leadObj];
      if(leadHierarachy.length>0)this.arrangeLeadHierarchy(this.leadObj?.parent_id,leadHierarachy);
      this.settingUpLeadData(this.leadObj);
      this.currentLeadDetails = this.leadObj
    }
    else{
      var leadIdList = [];
      leadIdList.push(this.leadId);
      var leadObjQuery = {};
      leadObjQuery["lead_id"] = leadIdList;
      leadObjQuery['hierarchy_data']  = true;
      if(this.isTemplateCall){leadObjQuery['item_type'] = ["TEMPLATE"]}
      this.httpTransfer
        .getLeadQuery(leadObjQuery, this.dashId)
        .subscribe((res : any) => {
          this.updatingleadHierarachy=false;
          if (res.status === 200) {
            this.leadObj=res.result.leadResponse[0]
            this.leadHierarachySeqArray=[this.leadObj];
            let hierararchyData = res?.result?.hierararchy_data;
            if(hierararchyData.length>0)this.arrangeLeadHierarchy(this.leadObj?.parent_id,hierararchyData);
            this.settingUpLeadData(this.leadObj);
            this.currentLeadDetails = res.result.leadResponse[0]
            this.cdr.detectChanges()
          }
      });
      this.cdr.detectChanges()
    }
    
  }

  updateItemState(item){
    this.itemStateData.push({itemId:item?._id,boardId:item?.dashboard_id,seqId:item?.seq_id})
  }
  
  arrangeLeadHierarchy(parentId,hierararchyData=[]) {
    for (let i = 0; i < hierararchyData.length; i++) {
      if (hierararchyData[i]._id == parentId) {
        this.leadHierarachySeqArray.push(hierararchyData[i]);
        if(hierararchyData[i]?.parent_id) {
          this.arrangeLeadHierarchy(hierararchyData[i].parent_id,hierararchyData);
        } else {
          break;
        }
      }
    }
  }

  settingUpLeadData(data) {
    this.leadObj = this.commonUtils.creatingJsonStructureOfLeadForTableView(data);
    if (data.starred.length>0) {
      this.isStarred = true;
    } else {
      this.isStarred = false;
    }

    let flag = this.dashBoardSprintArr.some(
      (x) => this.leadObj["sprint_id"] == x._id
    );
    if (!flag) {
      this.leadObj["sprint_id"] = undefined;
    }
    this.bucketOfCorrespondingDash.forEach((bucket) => {
      if (bucket["_id"] == this.leadObj["bucket_id"]) {
        this.currentBucketInfo = bucket;
      }
    });
    this.startTimer(this.leadObj);
  }

  startTimer(lead) {
    this.interval = setInterval(() => {
      if (lead.aging_time_in_seconds > 0) {
        lead.aging_time_in_seconds--;
      } else {
        lead["is_aging_time"] = false;
      }
    }, 1000);
  }

  
  scrollTO(id) {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  getPopOverRef(popRef){
    this.basicUtils.isPopoverOpen(popRef)
  }

  // set or remove public item
  changeItemType(item){
    let inputJson = {item_type:(item?.item_type==='ITEM_INTERNAL' ? "ITEM_PUBLIC" : "ITEM_INTERNAL")};
    item['item_type']=inputJson['item_type'];
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(inputJson, this.dashId, this.leadId)
      .subscribe((res) => {
        this.refreshLeadCard.emit(true);
      });
  }

  // go to previous item
  backToPreviousState(){
    if(this.itemStateData[this.itemStateData?.length-2]?.backToboard){
      this.router.navigate(['/home/welcome']);
    }else{
      this.itemStateData.splice(this.itemStateData?.length-1,1)
    }
  }

  backToboard() {
    this.router.navigate(['/board/'+this.itemStateData[this.itemStateData?.length-2]?.boardId]);
  }

  handleEscapseKey=(event: any): void=>{
    if(event.key=='Escape'){
      let popover=document.querySelector('.popover-body');
      if(!popover){
        let el=document.querySelector('.item-close-btn') as HTMLElement
        el?.click()
      }
    }
  }

  minimizeItem(item) {
    let obj = {
      'minimizeId': [{'item_id':this.leadId,'dashboard_id':this.dashId}],
      "update_type": "APPEND",
      'view':"ITEM"
    }
    this.minimizeRight.emit(obj)
  }

  ngOnDestroy() {
    this.mqttSubscription?.unsubscribe();
    if(this.interval){
      clearInterval(this.interval)
    }
    document.removeEventListener('keydown',this.handleEscapseKey)
  }




}
