<div class="itemInfoBox">
    <div class="row mx-0">
        <div class="col-md-12 text-left itemInfo">{{'editcustom.itemInfo' | translate :{ term: 'Item' | terminology: itemTerminology?.singular : 'Item' } }}</div>
    </div>
    <div class="row mx-0 mt-1">
        <div class="col-5 itemInfoText">{{'template.boardName' | translate}}</div>
        <div class="col-7 infoValueText">: {{dashName}}</div>
        <div class="col-5 itemInfoText">{{'drive.createdDate' | translate}}</div>
        <div class="col-7 infoValueText">: {{leadInfo.createdate | date}}</div>
        <div class="col-5 itemInfoText">{{'drive.createdBy' | translate}}</div>
        <div class="col-7 infoValueText" *ngIf="orgUsersJson">: {{orgUsersJson[leadInfo.created_by].name}}</div>
        <div class="col-5 itemInfoText">{{'drive.lastModified' | translate}}</div>
        <div class="col-7 infoValueText">: {{leadInfo.update_date | date}}</div>
    </div>
</div>