<div class="addPhase f-m-r">
    <form [formGroup]="addPhaseForm">
        <div class="form-group mb-2">
          <label for="phaseName" class="mb-1">{{'navSidebar.phaseName' | translate}}:</label>
          <input type="text" class="form-control" id="phaseName" formControlName="name" placeholder="{{'phase.enterPhaseName' | translate}}">
        </div>
        <div
          *ngIf="addPhaseForm.get('name').invalid && (addPhaseForm.get('name').dirty || addPhaseForm.get('name').touched)"
          class="text text-danger mb-2 f-s-r">
          <div *ngIf="addPhaseForm.get('name')?.errors.required">
            {{'navSidebar.phaseNameRequired' | translate}}
          </div>
          <div *ngIf="addPhaseForm.get('name')?.errors.minlength && !addPhaseForm.get('name')?.errors.pattern">
            {{'navSidebar.phaseNameMinChar' | translate}}
          </div>
          <div *ngIf="addPhaseForm.get('name')?.errors.maxlength && !addPhaseForm.get('name')?.errors.pattern">
            {{'navSidebar.phaseNameMaxChar' | translate}}
          </div>
          <div *ngIf="addPhaseForm.get('name')?.errors.pattern">
            {{'navSidebar.nameValidation' | translate}}
          </div>
        </div>
        <div class="form-group mb-3" *ngIf="!selectedSprintStatus">
          <label for="startDate" class="mb-1">{{'date.startDate' | translate}}:</label>
          <input type="date" id="startDate" class="form-control" (change)="checkTime()" formControlName="planned_start_date">
        </div>
        <div class="form-group" *ngIf="!selectedSprintStatus">
          <label for="endDate" class="mb-1">{{'date.endDate' | translate}}:</label>
          <input type="date" id="endDate" class="form-control" (change)="checkTime()" formControlName="planned_end_date">
          <div *ngIf="addPhaseForm.get('planned_end_date').invalid" class="text text-danger mb-2 f-s-r">
            {{'navSidebar.endDategreater' | translate}}
          </div>
        </div>
        <div class="form-group mb-3" *ngIf="selectedSprintStatus">
            <label for="startDate" class="mb-1">{{'date.startDate' | translate}}:</label>
            <input type="date" id="startDate" class="form-control" disabled="true" (change)="checkTime()" formControlName="planned_start_date">
          </div>
          <div class="form-group" *ngIf="selectedSprintStatus">
            <label for="endDate" class="mb-1">{{'date.endDate' | translate}}:</label>
            <input type="date" id="endDate" class="form-control" disabled="true" (change)="checkTime()" formControlName="planned_end_date">
        </div>
      </form>
      <button class="phasebtn" *ngIf="isCreate" [disabled]="addPhaseForm.invalid" (click)="onSavePhase()">{{'phase.createPhase' | translate}}</button>
      <button class="phasebtn" *ngIf="isUpdate" [disabled]="addPhaseForm.invalid" (click)="onUpdatePhase()">{{'phase.updatePhase' | translate}}</button>
</div>