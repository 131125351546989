
<!-- loader  -->
<div *ngIf="isLoading"  style="background:#fcfbfcbf;height: 100vh;width: 100vw;display: flex;justify-content: center;align-items: center;position: absolute;z-index: 1000;" id="main-loader" [innerHtml]="constantService.loaderConfig['loader_'+customStorageService?.getItem('loaderIndex')]" ></div> 
<div class="themeParent h-100" [ngStyle]="{'background':(router?.url?.includes('/embedboard') ? 'white' :'')}" id="gradientContainer"  [class]="showTheme ? selectedColor : ''">
  <div class="main-conatiner">
  <!--   <div *ngIf="showNavBar" id="nav-bar" class="collapsed-nav">
    </div> --> 
    <div id="containt-page" class="w-100 h-100">
        <router-outlet></router-outlet>
    </div>
  <!--   <div *ngIf="customStorageService.getItem('isAuthenticated')" [ngClass]="{'bottom-bar-hidden':isDontShowBottomBar}" class="bottom-bar" >
      <bottom-bar-section></bottom-bar-section>
   </div> -->
 </div>

 <div id="bottombar"></div>

  <!--  <div class="bottomBar" id="bottombar" [ngClass]="{'pinnedBottomBar' : isBottombarPinned}">
      <bottom-bar-section [isBottombarPinned]="isBottombarPinned" (pinnedEmit)="pinnedEmit($event)"></bottom-bar-section>
   </div>    -->
</div>


<ng-template #noAccesInItemModel let-modal>
  <div class="modal-body text-center py-3">
    <h3>{{'common.alert' | translate}}</h3> 
    <img src="../../../assets/1x/info.png"><br><br>
    {{'common.noAccess' | translate}}<br><br>
    <button type="button" class="btn btn-danger rounded px-4" [routerLink]="['/home/welcome']" (click)="modal.dismiss();noAccesInItemModelRef=null">{{'common.ok' | translate}}</button>
  </div>
</ng-template>
<!-- lost inter modal  -->
<div class="modal fade bd-example-modal-sm" id="lostInternetConnection"  tabindex="100" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body text-center py-3">
        <h3>Alert</h3> 
        <img src="../../../assets/1x/info.png"><br><br>
        Please check your internet connection!
      </div>
    </div>
  </div>
</div>

<ng-template #pricingErrorModal let-modal>
  <div id="createPricingComponent" style="flex: 1;">
    <div id="loadingIndicator"><app-shimmer-loader [height]="4" [width]="10" [type]="'Header'" [count]="2"></app-shimmer-loader></div>
    <ng-container *ngIf="showHeader">
      <div style="border-bottom:1px solid var(--border-color);">
        <div class="d-flex justify-content-between align-items-center">
          <div></div>
          <!-- <div class="modal-title plan-error-message-text f-xl-r" id="modal-basic-title">{{'pricing.currentPlanLimit' | translate}}</div> -->
         <button type="button" class="close" aria-label="Close" (click)="modal.dismiss();msgService?.closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-title f-xxxl-bb pb-2" id="modal-basic-title">
          <span class="f-xxxl-bb" style="line-height:26.36px;color:#000000">{{'pricing.upgradePlanText' | translate}}</span>
          <span class="f-xxxl-bb pl-1 pr-1" style="line-height:26.36px;" style="color:#3571de !important">{{'pricing.unlock' | translate}}</span>
          <span class="f-xxxl-bb" style="line-height:26.36px;color:#000000">{{'pricing.newToolsAndFeature' | translate}}</span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
