<!-- dashboard cardView -->
    <div class="elements h-100" *ngIf="showLoader" [ngClass]="{'collapseHeadSet' : isDashCollapse}">
        <app-shimmer-loader *ngIf="viewType=='BoardView' || viewType=='GraphView'" [type]="'Kanban'" [count]="10" [columnCount]="6"></app-shimmer-loader>
        <app-shimmer-loader *ngIf="viewType=='CalenderView' || viewType=='SplitView' || viewType=='bottomBarView'" class="w-100" [count]="8" [height]="100" [type]="'CardField'" [rowGap]="0" [header]="false"></app-shimmer-loader>
    </div>
    <ng-container *ngIf="(funnelarr.length>0 && arrayOfGroupField.length>0  || viewType!=='BoardView')&& !showLoader">
        <div cdkDropListGroup #container2 class="elements board-card-view" cdkScrollable [class.split-board-view]="viewType=='SplitView' || viewType=='CalenderView' || viewType=='bottomBarView'"  >
            <ng-container *ngFor="let groupInfo of funnelarr;let i=index">
            <div *ngIf="groupInfo?.isDefaultGroup || groupInfo?.isIncludeInFilter || groupInfo?.leadContents?.length>0 || (multipleBoardIds.length==1 && selectedGroupBy?.type!='Country' && selectedGroupBy?.type!='User' || selectedGroupBy?.group_by=='priority')" class="col-12 col-md-6 col-lg-4 col-xl-3  beforesearch" [attr.id]="'group_'+groupInfo?.group_id" [class.notAllowToDrop]="isDragging && getdragLeadinfoForCalendar && groupInfo?.group_data?.boardIds && !groupInfo?.group_data?.boardIds?.includes(getdragLeadinfoForCalendar?.dashboard_id)">
                <div [attr.id]="'dropPera'+groupInfo?.group_id" class="dropPera d-none">
                    <h4>{{'board.dropHereToMoveThiscolumn' | translate}} </h4>
                    <h6>{{'board.thisBoardSortedBy' | translate}} {{sortBy}}</h6>
                </div>
                <div [attr.id]="'groupDropPera'+ groupInfo?.group_id" class="dropPera d-none">
                    <h4>{{'board.notAllowedToMoveInBucket' | translate}}</h4>
                </div>
                <div class="card" [ngStyle]="{
                    'border-color': groupInfo.is_destination ? '#41A668' : '',
                    'border-color': (groupInfo?.group_data?.color && selectedGroupBy?.group_by === 'bucket') ? groupInfo.group_data.color : ''}">
                    <!------Bucket haeader-->
                    <div *ngIf="viewType=='BoardView' || viewType=='GraphView'" class="cardhead f-xl-m position-relative bucketHead w-100"
                        [draggable]="selectedGroupBy?.group_by=='bucket'" (dragover)="allowDrop($event)" (dragstart)="dragbuck(i)"
                        [cdkDragDisabled]="selectedGroupBy?.group_by!='bucket' || viewType=='SplitView'"
                        (drop)="onDropbuck(i)">
                        <div *ngIf="viewType=='BoardView' && !isReadOnly" class="form-group multiSelectGroupBox"  [ngClass]="{'multiFlex' : multiSelectGroupArray.includes(groupInfo?.group_id)}" (click)="$event.stopPropagation()">
                            <input type="checkbox" [checked]="multiSelectGroupArray.includes(groupInfo?.group_id)" (click)="selectAllLeadsOfBoard(groupInfo)" [id]="'group_checkbox_'+groupInfo?.group_id">
                                <label for="{{'group_checkbox_'+groupInfo.group_id}}"> </label>
                        </div>
                        <div class="d-flex flex-row w-75 align-items-center" [ngClass]="{'w-75':selectedGroupBy?.group_by!='phase','width65':selectedGroupBy?.group_by=='phase'}">
                            <img *ngIf="groupInfo.is_destination" class="mr-1" src="../../assets/1x/check-icon-41A668.svg" alt="">
                            <span [popoverClass]="selectedGroupBy?.group_by=='user' ? 'userInfoCardPopover' : ''" triggers="hover" placement="auto" container="body" 
                            [ngbPopover]="(selectedGroupBy?.group_by=='phase' || selectedGroupBy?.group_by=='user' || groupInfo?.name?.length>22) && groupInfo?.group_id && groupInfoPopover" class="groupName">
                                {{groupInfo.name}}
                            </span>
                            <span [ngClass]="{'finalBucketLeads' : groupInfo.is_destination}" class="badge f-l-b totalBucketLeads">{{groupInfo.total_records}}</span>
                            <!-- popover start -->
                            <ng-template #groupInfoPopover>
                                <ng-container  *ngIf="orgUsersJson[groupInfo?.group_id]; else phaseInfo">
                                        <app-user-info-card [user]="orgUsersJson[groupInfo?.group_id]"></app-user-info-card>
                                </ng-container>
                                <ng-template #phaseInfo>    
                                    <ng-container *ngIf="selectedGroupBy?.group_by=='phase'; else fullNameInfo">
                                        <app-dashboard-sprint [dashBoardSprint]="[groupInfo?.group_data]" [phasePopover]="true"></app-dashboard-sprint>
                                    </ng-container>
                                    <ng-template #fullNameInfo>
                                        <div style="max-width:340px">{{groupInfo?.name}}</div>
                                    </ng-template>
                                </ng-template>    
                            </ng-template>
                            <!-- popover end -->
                        </div>
                        <div  class="d-inline-flex text-right align-items-center">
                            <span *ngIf="selectedGroupBy?.group_by=='phase' && groupInfo?.group_data?._id" aria-hidden="true" placement="auto" #popPhaseOptions="ngbPopover"
                            [ngbPopover]="editPhaseTemplate" container="body" [autoClose]="'outside'" (click)="selectedPhase(groupInfo?.group_data)">
                                <span *ngIf="groupInfo?.group_data?.current_status=='STARTED'" class="phasechip inprogressChip"
                                ngbTooltip="In Progress" tooltipClass="tooltipPopup" triggers="hover" placement="auto">
                                    <app-dashboard-icon name="InprogressPhase" [size]="20" [iconJson]="attributesIcons"></app-dashboard-icon>
                                </span>
                                <span *ngIf="groupInfo?.group_data?.current_status=='STOPPED'" class="phasechip completedChip"
                                ngbTooltip="Completed" tooltipClass="tooltipPopup" triggers="hover" placement="auto">
                                    <app-dashboard-icon name="CompletedPhase" [size]="20" [iconJson]="attributesIcons"></app-dashboard-icon>
                                </span>
                                <span *ngIf="groupInfo?.group_data?.current_status!='STARTED' && groupInfo?.group_data?.current_status!='STOPPED'" class="phasechip startPhaseChip"
                                ngbTooltip="Start" tooltipClass="tooltipPopup" triggers="hover" placement="auto">
                                    <app-dashboard-icon name="StartPhase" [size]="20" [iconJson]="attributesIcons"></app-dashboard-icon>
                                </span>

                                <ng-template #editPhaseTemplate>
                                    <div class="editPhasePop">
                                      <div class="editPhaseHeader">
                                        Phase Detail
                                      </div>
                                      <div class="phaseEditArea">
                                        <div class="editContent">
                                          <span>Name</span>
                                          <span>          
                                            <input type="text" class="editInputBox" [(ngModel)]="phaseName" #editphaseName  placeholder="{{'phase.enterPhaseName' | translate}}" (ngModelChange)="checkPhaseDetailsValid()" [disabled]="!checkUserHaveEditAccessInDashboard() || (groupInfo?.group_data?.current_status=='STARTED' || groupInfo?.group_data?.current_status=='STOPPED')">
                                          </span>
                                          <span class="text-danger f-xs-l" *ngIf="editphaseName.value.trim().length > 100">
                                            *{{'navSidebar.phaseNameMaxChar' | translate}}
                                          </span>
                                          <span class="text-danger f-xs-l" *ngIf="editphaseName.value.trim().length < 3">
                                            *{{'navSidebar.phaseNameMinChar' | translate}}                
                                          </span>
                                        </div>
                                        <div class="editContent">
                                          <span>Start date</span>
                                          <span> 
                                            <span class="editDateBox" [ngbPopover]="checkUserHaveEditAccessInDashboard() && (groupInfo?.group_data?.current_status!='STARTED' && groupInfo?.group_data?.current_status!='STOPPED') && customDatePopoverFrom" #startDatePopup="ngbPopover" popoverClass="datePickerPopover" placement="auto" [autoClose]="'outside'" (click)="$event.stopPropagation();currentDateKey='from'">
                                              <span *ngIf="phaseStartDate">{{{date : phaseStartDate} | dateCalcuate:"dateTime"}}</span> 
                                              <span *ngIf="!phaseStartDate" class="datePlaceHolderText">DD/MM/YYYY</span>
                                            </span>         
                                          </span>
                                          <ng-template #customDatePopoverFrom>
                                            <date-picker [dateRange]="{'from':{date:phaseStartDate}}" [dateConfig]="{'maxDate':{date:phaseEndDate}}" [focusDate]="{date:phaseStartDate}" [showHeader]="false" [showClearOrSaveBtn]="false" (onDateSelect)="phaseStartDate = $event?.date;checkPhaseDetailsValid();startDatePopup?.close()"></date-picker>
                                        </ng-template>
                                        </div>
                                        <div class="editContent">
                                          <span>End date</span>
                                          <span>   
                                            <span class="editDateBox" [ngbPopover]="checkUserHaveEditAccessInDashboard() && (groupInfo?.group_data?.current_status!='STARTED' && groupInfo?.group_data?.current_status!='STOPPED') && customDatePopoverTo" placement="auto" popoverClass="datePickerPopover" [autoClose]="'outside'" #endDatePopup="ngbPopover" (click)="$event.stopPropagation();currentDateKey='to'">
                                              <span *ngIf="phaseEndDate">{{{date:phaseEndDate} | dateCalcuate:"dateTime"}}</span> 
                                              <span *ngIf="!phaseEndDate" class="datePlaceHolderText">DD/MM/YYYY</span>
                                            </span>         
                                          </span>
                                          <ng-template #customDatePopoverTo>
                                            <date-picker [dateRange]="{'to':{date:phaseEndDate}}" [dateConfig]="{'minDate':{date:phaseStartDate}}" [focusDate]="{date:phaseEndDate}" [showHeader]="false" [showClearOrSaveBtn]="false" (onDateSelect)="phaseEndDate = $event?.date;checkPhaseDetailsValid();endDatePopup?.close()"></date-picker>
                                        </ng-template>
                                        </div>
                                        <span class="text-danger f-xs-l" *ngIf="phaseStartDate && phaseEndDate && (phaseEndDate < phaseStartDate)">
                                          *{{'navSidebar.endDategreater' | translate}}
                                        </span>
                                        
                                        <div *ngIf="phaseData.current_status=='STOPPED' || phaseData.current_status=='STARTED'" class="actualDateArea">
                                          <span>Actual Dates</span>
                                          <span class="actualdatesVal">
                                            <span style="width: 50%;">Start: {{phaseData?.actual_start_date | date:'dd/MM/yyyy'}}</span>
                                            <span style="width: 50%;">End: {{phaseData?.actual_end_date | date:'dd/MM/yyyy'}}</span>
                                          </span>
                                        </div>
                                        <div class="editphaseBottom">
                                          <span class="btncss updateBtn" *ngIf="checkUserHaveEditAccessInDashboard() && isInValidDetails && groupInfo?.group_data?.current_status!='STARTED' && groupInfo?.group_data?.current_status!='STOPPED'" (click)="startPhase();popPhaseOptions.close()">Start Phase</span>
                                          <span class="btncss updateBtnDisabled" *ngIf="checkUserHaveEditAccessInDashboard() && !isInValidDetails && groupInfo?.group_data?.current_status!='STARTED' && groupInfo?.group_data?.current_status!='STOPPED'">Start Phase</span>

                                          <span class="btncss updateBtn" *ngIf="checkUserHaveEditAccessInDashboard() && groupInfo?.group_data?.current_status=='STARTED'" (click)="openTransferItems(transferModal,phaseData);popPhaseOptions.close()">Complete</span>
                                          <span class="btncss updateBtn" *ngIf="!checkUserHaveEditAccessInDashboard() || groupInfo?.group_data?.current_status=='STOPPED'" (click)="popPhaseOptions.close()">Close</span>

                                        </div>
                            
                            
                                      </div>
                                    </div>
                                  </ng-template>


                                  <ng-template #transferModal let-modal>
                                    <div class="modal-md">
                                      <div class="modalheader">
                                        <p class="transferHead text-truncate">
                                           {{sprintName}}
                                        </p>
                                      </div>
                                     <app-sprint-transfer [dashId]="selectedDashId" [sprintId]="transferFromPhase" 
                                     [completedItemsCount]="completedItemsCount" [incompleteItemsCount]="incompleteItemsCount" 
                                     [transferPhaseList]="transferPhaseList" (closeModal)="modal.dismiss()"></app-sprint-transfer>
                                    </div>
                                  </ng-template>

                            </span>

                            <!---Add bucket Button-->
                            <ng-container *ngIf="!isReadOnly && (createLeadBucketIds?.includes(groupInfo?.group_id) || selectedGroupBy?.group_by!='bucket')">
                                <ng-container *ngIf="selectedGroupBy?.group_by!=='dashboard_id' && (multipleBoardIds.length>1 || multipleBoardIds?.length==0 && viewType?.includes('GraphView'));else other">
                                    <span  class="addbtn f-m-b p-0 text-center d-flex cursorPointer d-flex" style="column-gap: 2px;padding-right: 2px;" [ngClass]="{'finalBucketAddButton' : groupInfo.is_destination}">
                                        <span style="column-gap: 2px" class="d-flex" (click)="openAddLead(groupInfo); container.scrollTop = 0"  [ngbTooltip]="('helpingText.addItem' | translate)" placement="auto" triggers="hover" tooltipClass="tooltipPopup">
                                            <app-dashboard-icon class="iconBox-16" [size]="16" [name]="dashboardData[selectedDashId]?.DASHBOARD_INFO?.icon" [iconJson]="attributesIcons"></app-dashboard-icon>
                                            <span>{{'bucket.add' | translate}}</span>
                                        </span>
                                        <span style="padding: 2px;border-left: 1px solid #3571de80;cursor: pointer;" [ngClass]="{'finalBucketAddButton' : groupInfo.is_destination}" #boardSelectPopoverRef="ngbPopover" container="body" [autoClose]="'outside'" [ngbPopover]="boardIconsSelect" [placement]="['bottom-right','bottom','top-right','auto']" popoverClass="modifiedPopover">
                                            <i class="fa fa-chevron-down" aria-hidden="true"></i> 
                                        </span>
                                        <ng-template #boardIconsSelect>
                                            <div style="max-height: 160px;overflow: auto;width: 130px;" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="getDasahboarData()" [scrollWindow]="false">
                                              <ng-container *ngFor="let id of (multipleBoardIds.length ? multipleBoardIds : boardDataListInfo.dashboardList)">
                                                <span *ngIf="dashboardData[id]?.DASHBOARD_INFO" class="board-icon-btn" [class.disabled-board-icon-btn]="!dashboardData[id]?.DASHBOARD_INFO?.role || dashboardData[id]?.DASHBOARD_INFO?.role?.includes('READ_ONLY')" (click)="selectedDashId=id;boardSelectPopoverRef?.close();clickQuickLeadGrouptId=''">
                                                  <span><app-dashboard-icon [size]="15" [name]="dashboardData[id]?.DASHBOARD_INFO?.icon" [iconJson]="attributesIcons"></app-dashboard-icon></span>
                                                  <span style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{dashboardData[id]?.DASHBOARD_INFO?.dashboard_name}}</span>
                                                </span>
                                              </ng-container>
                                              <div *ngIf="boardDataListInfo?.ScrollLoaderr" class="allChat-wrapper-footer d-flex justify-content-center align-items-center p-2">
                                                <div *ngFor="let item of [].constructor(3)" class="spinner-grow text-dark" role="status" style="width: 10px;height:10px;">
                                                  <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            </div>
                                          </ng-template>
                                    </span>
                                </ng-container>
                                <ng-template #other>
                                    <span  class="addbtn f-m-b cursorPointer iconBox-30" role="button" (click)="openAddLead(groupInfo); container.scrollTop = 0" [ngClass]="{'finalBucketAddButton' : groupInfo.is_destination}"
                                    [ngbTooltip]="('helpingText.addItem' | translate)" placement="auto" triggers="hover" tooltipClass="tooltipPopup">
                                        <!-- <img *ngIf="!groupInfo.is_destination" src="../../assets/1x/addButton-3571de.svg" class="mx-0"> 
                                        <img *ngIf="groupInfo.is_destination" src="../../assets/1x/addButton-41A668.svg" class="mx-0">  -->
                                        <!-- <span>{{'bucket.add' | translate}}</span> -->
                                        <app-dashboard-icon class="appInnerIconBox" [name]="'addOutline'"  [size]="20" [iconJson]="commonIcons"></app-dashboard-icon>
                                    </span>
                                </ng-template>
                            </ng-container>
                            <spam  *ngIf="selectedGroupBy?.group_by=='bucket'" class="iconBox-24 dotsVerticalOption" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                <app-dashboard-icon class="appInnerIconBox" [name]="'dotsVertical'"  [size]="20" [iconJson]="commonIcons"></app-dashboard-icon>
                            </spam>
                            <div  class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <div class="dropdown-item f-m-r" *ngIf="!groupInfo?.bucketFollowed"
                                    (click)="followBucket(groupInfo,true)">{{'bucket.follow' | translate}}  
                                </div>
                                <div class="dropdown-item f-m-r" *ngIf="groupInfo?.bucketFollowed"
                                    (click)="followBucket(groupInfo,false)">{{'bucket.unfollow' | translate}}  
                                </div>
                            </div>
                        </div>
                    </div>  
                    <!------Bucket Items list body-->
                    <div class="funnel_Lead_Container" [ngClass]="{'shorted':bulkSelectionMode && bulkLeadInfo.length>0,'collapseHead' : isDashCollapse}" infiniteScroll [infiniteScrollDistance]="2"
                        [infiniteScrollThrottle]="50" [cdkDropListAutoScrollStep]="15" 
                        (scrolled)="gettingLeadDataForIndividualGroupOnScroll(groupInfo)" [scrollWindow]="false"
                        cdkDropList [cdkDropListData]="groupInfo?.leadContents" 
                        (cdkDropListDropped)="onDrop($event,groupInfo)" #container
                        [attr.id]="'group_'+groupInfo?.group_id">
                        <!-- formula div start here -->
                        <ng-container *ngIf="groupInfo?.formulaContents && groupInfo?.formulaContents?.length>0 && (viewType=='BoardView' || viewType=='GraphView')">
                                <div class="formulaCard"  >
                                    <div class="formulaCardInfo f-s-r">
                                        <ng-container *ngFor="let formulaInfo of groupInfo?.formulaContents | slice:0:2;let i = index">
                                            <div class="py-1 row mx-0 d-flex" style="align-items: center; justify-content: center;" >
                                                <div class="pl-2" style="width:calc(100% - 30px)">{{(formulaInfo?.displayKey)+" "+formulaInfo?.customFieldDisplayKey+" : "+formulaInfo?.convertedValue}}</div>
                                                <div class="cursorPointer removeFormulaIcon" (click)="removeAppliedFormula(formulaInfo?.customFieldKey,formulaInfo?.formulaKey)">&times;</div>
                                            </div>
                                        </ng-container>
                                        <div *ngIf="groupInfo?.formulaContents?.length>2" class="py-1 row mx-0 pr-2" style="justify-content: end;">
                                            <div class="pl-2 cursorPointer" [ngbPopover]="formulasInfoPopover" #formulasPopover="ngbPopover" placement="auto" container="body" [autoClose]="'outside'" popoverClass="formulasPopover" > {{'common.viewAll' | translate}} </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- formula popover start here -->
                                <ng-template #formulasInfoPopover>
                                        <div class="py-1 row mx-0 f-s-r mb-1">
                                            <div class="pl-2" style="width:70%">{{'bucket.appliedFormulas' | translate}} </div>
                                            <div style="width:30%" class="cursorPointer" (click)="removeAppliedFormula(null,null)">{{'bucket.removeAll' | translate}}</div>
                                        </div>
                                        <div class="formulaCardInfo f-s-r">
                                            <ng-container *ngFor="let formulaInfo of groupInfo?.formulaContents;let i = index">
                                                <div class="py-1 row mx-0" style="display: flex; align-items: center; justify-content: center;">
                                                    <div class="pl-2" style="width:calc(100% - 30px);">{{(formulaInfo?.displayKey)+" "+formulaInfo?.customFieldDisplayKey+" : "+formulaInfo?.convertedValue}}</div>
                                                    <div  class="cursorPointer removeFormulaIcon" (click)="removeAppliedFormula(formulaInfo?.customFieldKey,formulaInfo?.formulaKey)">&times;</div>
                                                </div>
                                            </ng-container>
                                        </div>
                                </ng-template>
                        </ng-container>
                        <!-- formula popover end here -->
                        <!-- formula div end here -->
                        <ng-container *ngIf="groupInfo?.group_id===clickQuickLeadGrouptId">
                            <div (keyup.esc)="closeQuickAddLead()"
                                (keyup.enter)="createLeadFromTitle(groupInfo?.group_id,j,$event)"
                                (keydown.tab)="$event.preventDefault()"
                                (click)="setQuickLeadFalse()">
                                <textarea [(ngModel)]="creationtext" (keydown.enter)="false"
                                    [ngModelOptions]="{standalone: true}" autosize class="emptytextarea"
                                    [minRows]="3" id="myTextInput" (focusout)="creationtext?.trim().length ? createLeadFromTitle(groupInfo?.group_id,j,$event) : closeQuickAddLead()">
                                </textarea>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let leadInfo of groupInfo.leadContents;let j=index">
                            <ng-container *ngIf="leadInfo && leadInfo?.iscreated">
                                <div class="element cardcnr shadow-sm" [class.selected-item]="leadInfo._id==currentLeadId" [cdkDragBoundary]="viewType=='GraphView' ? '.board-card-view' : null"
                                    cdkDrag (cdkDragStarted)="dragfun(groupInfo,leadInfo)" [cdkDragDisabled]="isReadOnly || leadInfo?.stopDrag || leadInfo?.isShowEditor || viewType=='SplitView' || viewType==='bottomBarView'"
                                    (cdkDragEnded)="onDragRelease()" cdkDragPreviewContainer="parent">
                                    <!-------When Parent Lead Present------>
                                    <div *ngIf="leadInfo?.parent_id && parentChildLeads[leadInfo?.parent_id]" class="previousleadcnr lprior " (click)="clickedLead(parentChildLeads[leadInfo?.parent_id], groupInfo);$event.stopPropagation()" [ngStyle]="{'border-top-color': !itemAttributesObj?.priority?.hidden ? constantService?.priority_colors[parentChildLeads[leadInfo?.parent_id]?.priority] : '#eee' }"> 
                                        <span class="d-flex align-items-center parentDeatilsBox">
                                            <span class="parentTagCapsule">Parent</span>
                                            <div class="d-flex align-items-center">
                                                <span class="f-s-m" (click)="onNavigateToEditLeadPage(parentChildLeads[leadInfo?.parent_id]);$event.stopPropagation()">{{parentChildLeads[leadInfo?.parent_id]?.seq_id_text}}</span>
                                                <span *ngIf="!itemAttributesObj?.bucket_id?.hidden" class="f-s-r">- {{parentChildLeads[leadInfo?.parent_id]?.bucket_name}}</span>
                                            </div>
                                        </span>
                                        <span *ngIf="!itemAttributesObj?.assigned_to?.hidden" class="userprof f-m-b" (click)="$event.stopPropagation()" placement="auto"  #assigneePopOver="ngbPopover" container="body"  [ngbPopover]="parentChildLeads[leadInfo?.parent_id]?.editable && assignUserTemplateParent" [autoClose]="'outside'">
                                            <span class="userprof"  [ngbPopover]="parentChildLeads[leadInfo?.parent_id]?.assigned_to && userInfoCard" popoverClass="userInfoCardPopover" triggers="hover" placement="auto" container="body">
                                                <span *ngIf="!parentChildLeads[leadInfo?.parent_id]?.assigned_to" class="iconBox-30 hoverBg"><img src="../../assets/common-use-icon-20dp/add-user-6d6e6f.svg" alt="add-assignee" class="iconBox-20"></span>
                                                <ngx-avatars *ngIf="parentChildLeads[leadInfo?.parent_id]?.assigned_to"  [name]="orgUsersJson[parentChildLeads[leadInfo?.parent_id]?.assigned_to]?.name || 'Assign'" [src]="orgUsersJson[parentChildLeads[leadInfo?.parent_id]?.assigned_to]?.image_url" size="20" initialsSize="2" ></ngx-avatars>
                                            </span>
                                            <ng-template #assignUserTemplateParent>
                                                <app-lead-assign-user  [assignedUser]="orgUsersJson[parentChildLeads[leadInfo?.parent_id]?.assigned_to]" [dashId]="leadInfo?.dashboard_id" [leadInfo]="[parentChildLeads[leadInfo?.parent_id]]" [bulkSelectionFlag]="false" (close)="assigneePopOver.close()" ></app-lead-assign-user>
                                            </ng-template>
                                        </span>
                                        <ng-template #userInfoCard>
                                            <app-user-info-card [user]="orgUsersJson[parentChildLeads[leadInfo?.parent_id]?.assigned_to]"></app-user-info-card>
                                        </ng-template>
                                    </div>
                                    <div class="position-relative topBorder" #selectedLead 
                                    [ngStyle]="{'border-top': !(leadInfo?.parent_id && parentChildLeads[leadInfo?.parent_id])? 'none': ''}"
                                        (click)="clickedLead(leadInfo,groupInfo, null,j)"
                                        >
                                        <div *ngIf="viewType=='BoardView'  && !isReadOnly" class="form-group multiSelectCheckBox"  [ngClass]="{'multiFlex' : multiSelectedLeadIds?.length}"
                                            (click)="$event.stopPropagation()">
                                            <input type="checkbox" [checked]="multiSelectedLeadIds.includes(leadInfo._id)" (click)="leadIntoBulkSelection(leadInfo,groupInfo)"
                                                [id]="leadInfo._id" [disabled]="!multiSelectGroupArray.includes(groupInfo?.group_id) && multiSelectGroupArray.length">
                                            <input type="checkbox" [checked]="multiSelectedLeadIds.includes(leadInfo._id)" (click)="leadIntoBulkSelection(leadInfo,groupInfo)"
                                                [id]="leadInfo._id">
                                            <label for="{{leadInfo._id}}"> </label>
                                        </div>
                                        <div class="cardsprint">
                                            <!------Conatin priority, lead id, Phase, Star----->
                                            <span class="cardSprintHeadLeft">
                                                <span *ngIf="!itemAttributesObj?.priority?.hidden" class="priority" [ngbPopover]="leadInfo.editable && priorityTemplate"
                                                #popPriorityTemplate="ngbPopover" placement="right" container="body"
                                                [autoClose]="'outside'" (click)="$event.stopPropagation()"
                                                [style.background]="constantService?.priority_colors[leadInfo.priority]">
                                                <span class="f-s-m">{{priorityJson[leadInfo.priority]?.name}}</span>
                                                <ng-template #priorityTemplate>
                                                    <span id="boardViewPopupsId">
                                                        <app-lead-priority (click)="$event.stopPropagation()"
                                                            [leadInfo]="[leadInfo]" [dashId]="leadInfo?.dashboard_id" (closePopover)="popPriorityTemplate?.close()"
                                                            [bulkSelectionFlag]="false">
                                                        </app-lead-priority>
                                                    </span>
                                                </ng-template>
                                                </span>
                                                <span class="sprintno d-flex">
                                                   <div class="d-flex align-items-center">
                                                        <span *ngIf="multipleBoardIds?.length>1 && dashboardData[leadInfo?.dashboard_id]?.DASHBOARD_INFO" container="body" placement="auto" ngbTooltip="{{dashboardData[leadInfo?.dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}" tooltipClass="tooltipPopup">
                                                            <app-dashboard-icon class="iconBox-16" [size]="16" [name]="dashboardData[leadInfo?.dashboard_id]?.DASHBOARD_INFO?.icon" [iconJson]="attributesIcons"></app-dashboard-icon>
                                                        </span>
                                                        <span class="cursorPointer f-m-sb" (click)="onNavigateToEditLeadPage(leadInfo);$event.stopPropagation()">{{leadInfo?.seq_id_text}}</span>
                                                        <img class="cursorPointer"  *ngIf="!isLeadInTemplate" [ngbPopover]="itemInfoTemplate"
                                                            #popItemInfoTemplate="ngbPopover" placement="right" container="body"
                                                            [autoClose]="'outside'" (click)="$event.stopPropagation()"
                                                            [src]="groupInfo?.is_destination ? '../../../assets/common-use-icon-20dp/info-6d6e6f.svg' : '../../../assets/common-use-icon-20dp/info-6d6e6f.svg'">
                                                   </div>
                                                    <ng-template #itemInfoTemplate>
                                                        <div id="boardViewPopupsId">
                                                            <app-item-info [selectedDashId]="leadInfo?.dashboard_id" [leadInfo]="leadInfo" [users]="users" [dashName]="dashboardData[leadInfo?.dashboard_id]?.DASHBOARD_INFO?.dashboard_name"></app-item-info>
                                                        </div>
                                                    </ng-template>
                                                    <!-----When Phase Enabled-->
                                                    <ng-container *ngIf="dashboardData[leadInfo?.dashboard_id]?.SPRINT?.length>0 && dashboardData[leadInfo?.dashboard_id]?.DASHBOARD_INFO?.sprint_enabled">
                                                        <a class="f-xs-l cursorPointer" #leadSprintPopover="ngbPopover" role="button" placement="auto" container="body" [ngbPopover]="leadInfo.editable && sprintTemplate" (click)="$event.stopPropagation();" [autoClose]="'outside'">
                                                            <ng-container *ngIf="!leadInfo?.sprint_id">{{'board.noPhase' | translate}}</ng-container>
                                                            <ng-container *ngIf="leadInfo?.sprint_id">
                                                                <ng-container *ngFor="let sprintData of dashboardData[leadInfo?.dashboard_id]?.SPRINT"><span *ngIf="sprintData._id == leadInfo.sprint_id"  [ngbTooltip]="sprintData?.name?.length>10 && sprintData?.name" triggers="hover" placement="auto" tooltipClass="tooltipPopup">{{sprintData.name | truncate : 10}}</span></ng-container>
                                                            </ng-container>
                                                        </a>
                                                        <ng-template #sprintTemplate>
                                                            <ng-container>
                                                                <app-lead-sprint (click)="$event.stopPropagation();"
                                                                    [leadInfo]="[leadInfo]" [dashId]="leadInfo?.dashboard_id"
                                                                    [bulkSelectionFlag]="false"
                                                                    [dashBoardSprint]="dashboardData[leadInfo?.dashboard_id]?.SPRINT || []"
                                                                    [selectedPhase]="leadInfo.sprint_id"
                                                                    (closePopover)="leadSprintPopover.close()"
                                                                    >
                                                                </app-lead-sprint>
                                                            </ng-container>
                                                        </ng-template>
                                                    </ng-container>
                                                </span>
                                                
                                            </span>
                                            <!------Conatin priority, lead id, Phase, Star End Here----->
                                            <ng-template #leadDependencyTemplate>
                                                <div class="container">
                                                    <app-lead-dependency leadId={{leadInfo._id}}
                                                        dashId={{leadInfo?.dashboard_id}} [dashUsers]="dashUsersArr"
                                                        dashInitial={{selectedDashNameInitial}}>
                                                    </app-lead-dependency>
                                                </div>
                                            </ng-template>
                                            <span *ngIf="leadInfo.leadTotalCheckList" (click)="$event.stopPropagation()"
                                                class="boardchecklist mr-0" placement="auto"
                                                #leadCheckListPopover="ngbPopover" container="body"
                                                [ngbPopover]="leadInfo.editable && leadCheckListTemplate" [autoClose]="'outside'">
                                                <!-- <img  src="/assets/1x/checklist-icon.svg"> -->
                                                <app-dashboard-icon [size]="17"  [name]="'CheckList'"></app-dashboard-icon>
                                                {{leadInfo.leadCompletedCheckItem}}/{{leadInfo.leadTotalCheckList}}
                                            </span>
                                            <ng-template #leadCheckListTemplate>
                                                <div class="container" (click)="$event.stopPropagation()">
                                                    <app-check-list [leadId]="leadInfo._id" [dashId]="leadInfo?.dashboard_id">
                                                     </app-check-list>
                                                </div>
                                            </ng-template>
                                            <!--- car header right side-->
                                            <span class="vertdt cardHeaderRight f-s-r" *ngIf="viewType=='BoardView' || viewType=='GraphView'">
                                                <span class="star">
                                                    <img *ngIf="leadInfo.isStarred"
                                                        (click)="leadInfo.editable && updateStarLead(leadInfo,false);$event.stopPropagation()"
                                                        src="../../../assets/1x/star.svg">
                                                    <ng-container
                                                            *ngIf="(viewType=='BoardView' || viewType=='GraphView') && (leadInfo.dependsOnCount>0 || leadInfo.dependentsCount>0)">              
                                                            <span class="d-flex align-items-center dependencyStatus">
                                                                {{leadInfo.dependsOnCount}} 
                                                                <span (click)="getDependenciesForLead(leadInfo._id,groupInfo);$event.stopPropagation()"
                                                                #leadDependencyPopover="ngbPopover"
                                                                [ngbPopover]="leadInfo.editable && leadDependencyTemplate" [autoClose]="'outside'" placement="auto" container="body">
                                                                    <app-dashboard-icon [size]="16"  [name]="'Dependency'"></app-dashboard-icon>
                                                                </span>
                                                                {{leadInfo.dependentsCount}}
                                                            </span>
                                                    </ng-container>
                                                </span>
                                                <ng-container *ngIf='!leadInfo.is_aging_time'>
                                                    <span class="time f-s-r">
                                                        <img src="../../../assets/common-use-icon-20dp/time-6d6e6f.svg" class="iconBox-16">{{leadInfo.diff | daysago: 'true'}}</span>
                                                </ng-container>
                                                <ng-template #leadTimelineTemplate>
                                                    <div class="timelinePopover">
                                                        <app-timeline dashId={{leadInfo?.dashboard_id}} item_id={{leadInfo._id}}
                                                            [height]="25" dashboard_initial={{selectedDashNameInitial}}
                                                            [isHeaderFilterRequired]="false"
                                                            [isHeaderQuickFilterRequired]="false"
                                                            [isHeaderRequired]="false"
                                                            [isRightSideFilterRequired]="false"></app-timeline>
                                                    </div>
                                                </ng-template>
                                                <ng-container *ngIf='leadInfo.is_aging_time'>
                                                    <span class="time liveCounter againgtxtLeft" id="live-Counter" *ngIf="leadInfo.aging_time_in_seconds>0"><img
                                                            src="../../../assets/common-use-icon-20dp/deadline-bom-6d6e6f.svg" class="iconBox-16 m-0">{{leadInfo.aging_time_in_seconds | minutesPipe}} Left</span>
                                                    <span class="time againgtxtExceed" id="live-Counter" *ngIf="leadInfo.aging_time_in_seconds<0"><img
                                                            src="../../../assets/common-use-icon-20dp/deadline-bom-active-fe2030.svg" class="iconBox-16 m-0">{{leadInfo.aging_time_in_seconds | minutesPipe}} Over-due</span>
                                                </ng-container>
                                                <ng-container>
                                                        <span  [ngbPopover]="!isLeadInTemplate && !isInternalBoardReadOnly && leadOptionTemplate"
                                                        #optionsPopover="ngbPopover" placement="auto" container="body" (click)="$event.stopPropagation();getPopoverRef(optionsPopover,leadInfo._id)"
                                                        [autoClose]="true" class="leadCardOptions iconBox-30" [ngStyle]="{'display': optionsPopover?.isOpen() ? 'flex' : ''}">
                                                            <span class="appInnerIconBox" >
                                                               <app-dashboard-icon [size]="20" class="appInnerIcons" [name]="'dotsVertical'" [iconJson]="commonIcons"></app-dashboard-icon>
                                                            </span>
                                                        </span>
                                                </ng-container>
                                                <ng-template #leadOptionTemplate>
                                                    <div id="boardViewPopupsId" >
                                                        <div>
                                                        <app-lead-option-template 
                                                        [isReadOnly]="!leadInfo.editable"
                                                        [bucketInfo]="groupInfo"
                                                        [dashboardUserRole]="dashboardUserRole"
                                                        [leadInfo]="leadInfo" 
                                                        [selectedDashId]="leadInfo?.dashboard_id"
                                                        [dashBoardSprint]="dashboardData[leadInfo?.dashboard_id]?.SPRINT || []"
                                                        (showSubtaskParentModal)="clickedLead(leadInfo,groupInfo, null,j,$event)">
                                                        </app-lead-option-template>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </span>
                                        </div>
                                        <div class="carddetails lead-title" *ngIf="!leadInfo?.isShowEditor">
                                            <p class="lead-title-txt ls-05x mb-0 f-l-r">{{leadInfo.title}} 
                                                <span *ngIf="leadInfo.editable" (click)="$event.stopPropagation();openTitleEditor(leadInfo,$event)" class="editTitle  kanbamTitleEditIcon">
                                                    <img  src="../../../assets/1x/editBlackIcon.svg" /></span>
                                            </p>
                                        </div>
                                        <div class="carddetails lead-title" *ngIf="leadInfo?.isShowEditor" (click)="$event.stopPropagation()">
                                            <textarea [(ngModel)]="leadInfo.title" autosize class="emptytextarea"
                                            [id]="'editTextarea_'+leadInfo._id"
                                            [readonly]="!leadInfo.editable" [minRows]="1"
                                            [ngModelOptions]="{standalone: true}"
                                             #title ="ngModel"
                                             (click)="$event.stopPropagation()"
                                             (blur)="updateLeadInfo(leadInfo)"
                                             >
                                            </textarea>
                                        </div>

                                        <ng-container *ngIf="viewType=='BoardView' || viewType=='GraphView'">
                                            <!-- tags if value -->
                                            <div class="cardbtmcnr f-s-r"  *ngIf="!itemAttributesObj?.tag_id?.hidden && leadInfo.tag_id?.length>0">
                                                <span class="iconBox-30" style="margin-left: -6px;"   placement="auto"  #leadTagPopOver="ngbPopover" [autoClose]="'outside'" container="body" [ngbPopover]="leadInfo.editable && tagPopTemplate" (click)="showCreateTag=false;$event.stopPropagation();getPopoverRef(leadTagPopOver,leadInfo._id)"><img class="iconBox-20" [src]="groupInfo.is_destination?'../../../assets/common-use-icon-20dp/tag-3571de.svg':'../../../assets/common-use-icon-20dp/tag-3571de.svg'" ></span>
                                                <div *ngIf="leadInfo.tag_id?.length>0" class="tagListBox">
                                                    <ng-container *ngFor="let tagId of leadInfo.tag_id">
                                                        <div *ngIf="dashboardData[leadInfo?.dashboard_id]?.TAG_MAP && dashboardData[leadInfo?.dashboard_id]?.TAG_MAP[tagId]" class="cardtag" [placement]="['top','top-left','top-right','auto']" triggers="hover" [ngbTooltip]="dashboardData[leadInfo?.dashboard_id]?.TAG_MAP[tagId]?.name?.length>7 && dashboardData[leadInfo?.dashboard_id]?.TAG_MAP[tagId]?.name"  tooltipClass="tooltipPopup" >
                                                            <div class="tagText">{{dashboardData[leadInfo?.dashboard_id]?.TAG_MAP[tagId]?.name}}</div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <!-- dates(start and end) if value -->
                                            <div class="cardbtmcnr f-s-r"  *ngIf="!itemAttributesObj?.start_end_date?.hidden && (leadInfo?.start_date?.time || leadInfo?.end_date?.time)">
                                                <div class="d-flex align-items-center" (click)="$event.stopPropagation()">
                                                    <div class="d-flex iconBox-30" style="margin-left: -6px;" [ngbPopover]="leadInfo.editable && datePopover"  placement="auto" container="body" #datePopOver="ngbPopover" popoverClass="datePickerPopover" [autoClose]="'outside'" (click)="popoverRef=datePopOver">
                                                        <img class="iconBox-20" (click)="startDateClicked(leadInfo)"  src="../../../assets/common-use-icon-20dp/calendar-3571de.svg">
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center dateGroupBox">
                                                    <div *ngIf="leadInfo?.start_date?.dateLabel" class="startDateTime">
                                                        <span *ngIf="leadInfo?.start_date?.dateLabel && !leadInfo?.end_date?.dateLabel">Starts</span>
                                                        <div class="d-flex" ><span>{{leadInfo?.start_date | dateCalcuate:"date"}}</span><span class="startTime" *ngIf="leadInfo.start_date.is_time_added">{{','+(leadInfo.start_date | dateCalcuate:"time")}}</span></div>
                                                    </div>
                                                    <span *ngIf="leadInfo?.start_date?.dateLabel && leadInfo?.end_date?.dateLabel">-</span>
                                                    <div *ngIf="leadInfo?.end_date?.dateLabel" class="endDateTime" (click)="$event.stopPropagation()">
                                                        <span *ngIf="!leadInfo?.start_date?.dateLabel && leadInfo?.end_date?.dateLabel">Ends</span>
                                                        <div class="d-flex" ><span>{{leadInfo?.end_date | dateCalcuate:"date"}}</span><span class="endTime" *ngIf="leadInfo.end_date.is_time_added">{{','+(leadInfo.end_date | dateCalcuate:"time")}}</span></div>
                                                    </div>
                                                </div>
                                                <ng-template #datePopover>
                                                    <div id="boardViewPopupsId">
                                                        <app-lead-date-picker (click)="$event.stopPropagation()" [selectedStartDateObj]="leadInfo?.start_date"   [selectedEndDateObj]="leadInfo?.end_date"  [selectedDashId]="leadInfo?.dashboard_id"  [leadInfo]="[leadInfo]"   (closeDateModalOnCancel)="datePopOver?.close()"></app-lead-date-picker>
                                                    </div>
                                                </ng-template>  
                                            </div>
                                            <!-- comment subtask and assignee if value -->
                                            <div class="cardbtmcnr  commentSubTaskBoxAssignee f-s-r" *ngIf="(leadInfo?.totalCommentCount-leadInfo?.totalReadCommentCount)>0 || commentPopoverLeadIds.includes(leadInfo._id) || leadInfo?.child_lead_id?.length > 0 || (leadInfo.assigned_to && !itemAttributesObj?.assigned_to?.hidden)">
                                                <div class="commentSubTaskBox">
                                                    <div class="d-flex align-items-center" style="margin-left: -6px;">
                                                        <span class="iconBox-30"  placement="auto" [ngbPopover]="leadInfo.editable && leadCommentTemplate" #commentPopOver="ngbPopover" container="body" [autoClose]="'outside'" (click)="getLeadCopy(leadInfo);$event.stopPropagation()" (hidden)="commentPopoverLeadIds=[]" (shown)="commentPopoverLeadIds=[leadInfo._id]"><img src="../../../assets/common-use-icon-20dp/comment-3571de.svg" class="iconBox-20"></span>
                                                        <span *ngIf="(leadInfo?.totalCommentCount-leadInfo?.totalReadCommentCount)>0"> {{leadInfo.totalCommentCount-leadInfo.totalReadCommentCount}}
                                                            <span *ngIf="leadInfo.tagged_count && leadInfo.tagged_count != 0">@{{leadInfo.tagged_count}}</span>
                                                        </span>               
                                                    </div>
                                                    <div class="d-flex" *ngIf="leadInfo?.child_lead_id?.length > 0" (click)="$event.stopPropagation()" [ngbTooltip]="(leadInfo?.closed_subitem_id ? leadInfo?.closed_subitem_id?.length : '0') + ' of ' +leadInfo.child_lead_id.length +' sub item closed'" triggers="hover" placement="auto" tooltipClass="tooltipPopup">
                                                        <div class="d-flex align-items-center" (click)="getChildrenOfSelectedLead(leadInfo, leadInfo._id);">
                                                            <span class="iconBox-30"><img class="iconBox-20" src="assets/new-icon-6d6e6f-20dp/subtask-6d6e6f.svg" alt="sub Itemm img"> </span>
                                                            <span>{{leadInfo.child_lead_id.length}}</span>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div  *ngIf="!itemAttributesObj?.assigned_to?.hidden" class="assignusr iconBox-30" (click)="$event.stopPropagation()">
                                                    <div [ngbPopover]="!assigneePopOver?.isOpen() && leadInfo.assigned_to && userInfoCard" popoverClass="userInfoCardPopover" triggers="hover" placement="auto" container="body" class="appInnerIconBox">
                                                        <span class="appInnerIconBox" placement="auto" #assigneePopOver="ngbPopover" [autoClose]="'outside'" [ngbPopover]="leadInfo.editable && assignUserTemplate" (click)="getPopoverRef(assigneePopOver,leadInfo._id);$event.stopPropagation()" container="body">
                                                            <span *ngIf="!leadInfo.assigned_to" class="appInnerIconBox"> <img src="../../assets/common-use-icon-20dp/add-user-6d6e6f.svg" class="iconBox-24"></span>
                                                            <ngx-avatars *ngIf="leadInfo.assigned_to" [name]="orgUsersJson[leadInfo.assigned_to]?.name ? orgUsersJson[leadInfo.assigned_to]?.name : 'Assign'" [src]="orgUsersJson[leadInfo.assigned_to]?.image_url" size="30" initialsSize="2" sizeRatio="2.2" ></ngx-avatars>
                                                        </span>
                                                    </div>
                                                    <ng-template #assignUserTemplate>
                                                            <app-lead-assign-user   [assignedUser]="orgUsersJson[leadInfo.assigned_to]" [dashId]="leadInfo?.dashboard_id" [leadInfo]="[leadInfo]"  [bulkSelectionFlag]="false"  (close)="assigneePopOver.close()"></app-lead-assign-user>
                                                    </ng-template>
                                                </div> 
                                            </div>
                                            <!----Comment, tag, Date and Assignee on hover if value is empty-->
                                            <div *ngIf="((!leadInfo.assigned_to || itemAttributesObj?.assigned_to?.hidden)  && (!(leadInfo.totalCommentCount-leadInfo.totalReadCommentCount>0) && !commentPopoverLeadIds.includes(leadInfo._id) || commentPopoverLeadIds.includes(leadInfo._id+'emptyCount'))  && !leadInfo?.child_lead_id?.length) ||  (!leadInfo.tag_id?.length && !itemAttributesObj?.tag_id?.hidden) || (!(leadInfo?.start_date?.time || leadInfo?.end_date?.time) && !itemAttributesObj?.start_end_date?.hidden)" #hoverDiv class="cardbtmcnr emptyValued" style="column-gap: 60px;" [ngClass]="{'hideNoValueIcon' : !leadTagPopOverInner?.isOpen() && !datePopOverInner?.isOpen() && !commentPopOverInner?.isOpen() && !assigneePopOverInner?.isOpen() && (!popoverRef || popoverRef && (!popoverRef?.popover?.isOpen() || popoverRef?.popover?.isOpen() && popoverRef?.leadId!=leadInfo?._id))}" >
                                                <!-- <div style="width:80%;display: flex" class="cardbtmcnr"> -->
                                                <div [style]="itemAttributesObj?.tag_id?.hidden || leadInfo.tag_id?.length ? 'display:none;' : '' " class="iconBox-30">
                                                    <span [ngbTooltip]="!isPopOverOpen?('helpingText.Tag' | translate):''" tooltipClass="tooltipPopup" triggers="hover" placement="auto" class="appInnerIconBox">
                                                        <img class="iconBox-20" [src]="groupInfo.is_destination?'../../../assets/common-use-icon-20dp/tag-6d6e6f.svg':'../../../assets/common-use-icon-20dp/tag-6d6e6f.svg'" #leadTagPopOverInner="ngbPopover" placement="auto" container="body" [autoClose]="'outside'" [ngbPopover]="leadInfo.editable && tagPopTemplate" (click)="showCreateTag=false;$event.stopPropagation();isPopOverOpen=!isPopOverOpen;getPopoverRef(leadTagPopOverInner,leadInfo._id)" (hidden)="isPopOverOpen=false">
                                                    </span>
                                                </div>
                                                <div [style]="itemAttributesObj?.start_end_date?.hidden || (leadInfo?.start_date?.time || leadInfo?.end_date?.time) ? 'display:none;' : ''">
                                                    <div class="d-flex iconBox-30" [ngbPopover]="leadInfo.editable && datePopover" placement="auto" container="body" #datePopOverInner="ngbPopover" [autoClose]="'outside'"(click)="$event.stopPropagation();popoverRef=datePopOverInner" popoverClass="datePickerPopover">
                                                        <span class="appInnerIconBox" [ngbTooltip]="('helpingText.startEndDate' | translate)" tooltipClass="tooltipPopup" triggers="hover" placement="auto">
                                                            <img class="iconBox-20" (click)="startDateClicked(leadInfo)" src="../../../assets/common-use-icon-20dp/calendar-6d6e6f.svg">
                                                        </span>  
                                                    </div>
                                                    <ng-template #datePopover>
                                                        <div id="boardViewPopupsId">
                                                            <app-lead-date-picker (click)="$event.stopPropagation()" [selectedStartDateObj]="leadInfo?.start_date"   [selectedEndDateObj]="leadInfo?.end_date"  [selectedDashId]="leadInfo?.dashboard_id"  [leadInfo]="[leadInfo]"   (closeDateModalOnCancel)="datePopOverInner?.close()"></app-lead-date-picker>
                                                        </div>
                                                    </ng-template>  
                                                </div> 
                                                <div [style]="!((!leadInfo.assigned_to && !itemAttributesObj?.assigned_to?.hidden || itemAttributesObj?.assigned_to?.hidden)  && (!(leadInfo.totalCommentCount-leadInfo.totalReadCommentCount>0) && !commentPopoverLeadIds.includes(leadInfo._id) || commentPopoverLeadIds.includes(leadInfo._id+'emptyCount')) && !leadInfo?.child_lead_id?.length) ? 'display:none;' : '' "
                                                [ngbTooltip]="('helpingText.Comment' | translate)"  tooltipClass="tooltipPopup" triggers="hover" placement="auto" class="iconBox-30">
                                                    <img class="iconBox-20" src="../../../assets/common-use-icon-20dp/comment-6d6e6f.svg" placement="auto" container="body" [ngbPopover]="leadInfo.editable && leadCommentTemplate" #commentPopOverInner="ngbPopover" [autoClose]="'outside'" (click)="getLeadCopy(leadInfo);$event.stopPropagation()" (hidden)="commentPopoverLeadIds=[]" (shown)="commentPopoverLeadIds=[leadInfo._id+'emptyCount']">           
                                                </div>
                                                <div [style]="!((!leadInfo.assigned_to && !itemAttributesObj?.assigned_to?.hidden)  && !((leadInfo.totalCommentCount-leadInfo.totalReadCommentCount)>0) && !commentPopoverLeadIds.includes(leadInfo._id) && !leadInfo?.child_lead_id?.length) || itemAttributesObj?.assigned_to?.hidden ? 'display:none;' : '' "
                                                [ngbTooltip]="('helpingText.assignee' | translate)" tooltipClass="tooltipPopup" triggers="hover" placement="auto" (click)="$event.stopPropagation()" class="iconBox-30">
                                                    <span class="appInnerIconBox" style="display:flex" placement="auto"  #assigneePopOverInner="ngbPopover" [autoClose]="'outside'" [ngbPopover]="leadInfo.editable && assignUserTemplate" (click)="getPopoverRef(assigneePopOverInner,leadInfo._id);$event.stopPropagation()" container="body">
                                                        <span *ngIf="!leadInfo.assigned_to" class="iconBox-20"><img src="../../assets/common-use-icon-20dp/add-user-6d6e6f.svg" class="appInnerIconBox"></span>
                                                    </span>
                                                </div> 
                                                <ng-template #assignUserTemplate>
                                                    <app-lead-assign-user   [assignedUser]="orgUsersJson[leadInfo.assigned_to]" [dashId]="leadInfo?.dashboard_id" [leadInfo]="[leadInfo]"  [bulkSelectionFlag]="false"  (close)="assigneePopOverInner.close()"></app-lead-assign-user>
                                                </ng-template>
                                            </div> 
                                                <ng-template #leadCommentTemplate>
                                                    <div class="commentPopver" style="height:300px;">
                                                        <app-chat-messages [dashId]="leadInfo?.dashboard_id"  [selectedLeadObj]="commentLead" [currentActiveTab]="'commentTab'" [disableAttachColumns]="true"></app-chat-messages>
                                                    </div>
                                                </ng-template>                  
                                                <ng-template #userInfoCard>
                                                    <ng-container>
                                                        <app-user-info-card
                                                            [user]="orgUsersJson[leadInfo?.assigned_to]">
                                                        </app-user-info-card>
                                                    </ng-container>
                                                </ng-template>
                                                <ng-template #tagPopTemplate>
                                                    <ng-container *ngIf="!showCreateTag">
                                                        <app-lead-tags (click)="$event.stopPropagation()"
                                                            [dashboardTagsJson]="dashboardData[leadInfo?.dashboard_id]?.TAG_MAP || []"
                                                            [leadInfo]="[leadInfo]" [dashId]="leadInfo?.dashboard_id"
                                                            [bulkSelectionFlag]="false"
                                                            (showCreateTag)="showCreateTag = $event">
                                                        </app-lead-tags>
                                                    </ng-container>
                                                    <ng-container *ngIf="showCreateTag">
                                                        <app-create-tag (click)="$event.stopPropagation()"
                                                            (updateDashboardTags)="showCreateTag=false"
                                                            [dashId]="leadInfo?.dashboard_id"
                                                            (closeManageTag)="showCreateTag = $event"
                                                            (showConfirmationModal)="showConfirmationModalFlag = $event"
                                                            [confirmationAction]="confirmationAction">
                                                        </app-create-tag>
                                                    </ng-container>
                                                </ng-template>
                                            <!-----Columns Value-->
                                            <ng-container *ngIf="selectedCustFieldKeys?.length">
                                                <table class="card-column-table table-striped  mb-0 optionsTable" >
                                                    <tbody >
                                                        <tr *ngFor="let customItem of selectedCustFieldKeys">
                                                            <td *ngIf="customItem?.hidden!=='ALWAYS'" style="padding:0" (click)="$event.stopPropagation()">
                                                                <div *ngIf="customItem.type !== 'Mirror' && customItem?.isCustom==false" class="row mx-0 leadCustKeys py-1">
                                                                    <div class="col-md-4 custKeyValue f-m-m pl-2 pr-0">
                                                                        <app-dashboard-icon [name]="customItem.type" [iconJson]="attributesIcons"></app-dashboard-icon>
                                                                        {{ itemAttributesObj && itemAttributesObj[customItem.columnKey]?.label | attributesLabel: itemAttributesObj: customItem.columnKey : customItem?.itemName | truncate: 12}}
                                                                    </div>
                                                                    <div class="col-md-8 pl-1 f-s-r MontserratFont" style="display: flex; flex-direction: column; justify-content: center; row-gap: 10px;">
                                                                        <ng-container *ngIf=
                                                                        "customItem?.type == 'Bucket'">{{leadInfo?.bucket_name}}</ng-container>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="customItem.type !== 'Mirror' && customItem?.isCustom!=false" class="row mx-0 leadCustKeys py-1">
                                                                    <div class="col-md-4 custKeyValue f-m-m pl-2 pr-0 viewText">
                                                                        <app-dashboard-icon [name]="customItem.type" [iconJson]="attributesIcons"></app-dashboard-icon>
                                                                        {{customItem.display_key}}
                                                                    </div>
                                                                <ng-container *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.BUTTON">
                                                                        <button (keydown.enter)="$event.preventDefault()" (click)="!isItemFieldReadOnly(customItem.locked,leadInfo) && triggerButton(leadInfo,customItem.key)" [disabled]="isItemFieldReadOnly(customItem.locked,leadInfo)" class="mx-4 btn f-s-r ml-1 triggerButton">{{customItem?.display_key | truncate : 12}}</button>
                                                                </ng-container>        
                                                                <ng-container *ngFor="let field of leadInfo.custom_fields | keyvalue">
                                                                    <ng-container *ngIf="customItem.key == field.key">
                                                                                <div class="col-md-8 pl-1 f-s-r MontserratFont" style="display: flex; flex-direction: column; justify-content: center; row-gap: 10px;"
                                                                                *ngIf="field.value || field.value == 0 || field.value != '' || field.value.length>0">
                                                                                    <ng-container
                                                                                    *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.DATE && field.value?.date">
                                                                                    <span class="option-text">{{field.value | dateCalcuate:"dateTime"}}</span>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.MOBILE">
                                                                                    <span class="option-text" *ngIf="field?.value?.country_code && currencyJson">
                                                                                        {{currencyJson[field?.value?.country_code].dial_code}}
                                                                                        <span *ngIf="field?.value?.mobile_number">{{field.value.mobile_number}}</span>
                                                                                    </span>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.CHECKLIST">
                                                                                    <ng-container *ngFor="let checklist of field.value | slice:0:2">
                                                                                        <span class="assignusr" style="justify-content: flex-start;">
                                                                                        <img *ngIf="checklist.state=='COMPLETED'" class="checkListIcon"
                                                                                            src="assets/1x/pronnel_iconArtboard 29.png" alt="">
                                                                                        <img *ngIf="checklist.state=='NOT_COMPLETED'" class="checkListIcon"
                                                                                            src="assets/1x/pronnel_iconArtboard 28.png" alt="">
                                                                                        <span>{{checklist?.summary | truncate: 20}}</span>
                                                                                        </span>
                                                                                    </ng-container>
                                                                                    <span *ngIf="field.value && field.value.length>2" class="viewMoreBtn cursorPointer">
                                                                                        <span [ngbPopover]="checklistTemp" placement="auto" container="body" #popoverViewMore="ngbPopover" (click)="getPopoverRef(popoverViewMore,leadInfo._id)">
                                                                                            {{'board.viewMore' | translate}} +{{field.value.length -2}}
                                                                                        </span>
                                                                                    </span>
                                                                                    <ng-template #checklistTemp>
                                                                                        <div style="max-height: 250px; overflow-y:auto; scrollbar-width:thin;">
                                                                                        <ng-container *ngFor="let checklist of field.value">
                                                                                            <div style="width: 200px; overflow-y: auto; scrollbar-width: thin;">
                                                                                                <span class="assignusr " style="justify-content: flex-start; width: 100%; margin-bottom: 10px;">
                                                                                                    <img *ngIf="checklist.state=='COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 29.png" alt="">
                                                                                                    <img *ngIf="checklist.state=='NOT_COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 28.png" alt="">
                                                                                                    <span>{{checklist?.summary | truncate: 20}}</span>
                                                                                                </span>
                                                                                            </div> 
                                                                                        </ng-container>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.TIME_TRACKER">
                                                                                <app-time-tracking [itemKey]='field.key' [dashId]="leadInfo?.dashboard_id" [dashboardUser]="users" [leadObj]="leadInfo" [activeTrackerList]="activeTrackerObj" [timeSpent]="field.value?.time_spend_till_now" [view]="'KANBAN'"></app-time-tracking>
                                                                                </ng-container>
                                                                                <ng-container *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.TEXTAREA">
                                                                                    <span [innerHtml]="field.value | safehtml | truncate: 20" class="boardTxtArea"> </span>
                                                                                </ng-container>
                                                                                <ng-container *ngIf="customItem.type === constantService.constant.CUSTOMTYPES.SINGLEUSER">
                                                                                    <span class="assignusr f-m-b" style="justify-content: flex-start;">
                                                                                        <ngx-avatars class="mr-1" [name]="orgUsersJson[field.value]?.name" [src]="orgUsersJson[field.value]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                                                                                        <span>{{orgUsersJson[field.value]?.name | truncate: 15}}</span>
                                                                                    </span>
                                                                                    <!-- <br> -->
                                                                                </ng-container>
                                                                                <ng-container *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.MULTIUSER">
                                                                                    <ng-container *ngFor="let userId of field.value | slice:0:2">
                                                                                    <span class="assignusr f-m-b" style="justify-content: flex-start;">
                                                                                        <ngx-avatars class="mr-1" [name]="orgUsersJson[userId]?.name" [src]="orgUsersJson[userId]?.image_url" size="25">
                                                                                        </ngx-avatars>
                                                                                        <span>{{orgUsersJson[userId]?.name | truncate: 20}}</span>
                                                                                    </span>
                                                                                    </ng-container>
                                                                                    <span *ngIf="field.value && field.value.length>2" class="viewMoreBtn cursorPointer">
                                                                                        <span [ngbPopover]="multiUserTemp" placement="auto" container="body" #popoverViewMore="ngbPopover" (click)="getPopoverRef(popoverViewMore,leadInfo._id)">
                                                                                            {{'board.viewMore' | translate}} +{{field.value.length -2}}
                                                                                        </span>
                                                                                    </span>
                                                                                    <ng-template #multiUserTemp>
                                                                                        <div style="max-height: 250px; overflow-y:auto; scrollbar-width:thin;">
                                                                                            <ng-container *ngFor="let userId of field.value">
                                                                                                <div style="width: 200px; overflow-y: auto; scrollbar-width: thin;">
                                                                                                <span class="assignusr f-m-b" style="justify-content: flex-start; width: 100%; margin-bottom: 10px;">
                                                                                                    <ngx-avatars class="mr-1" [name]="orgUsersJson[userId]?.name" [src]="orgUsersJson[userId]?.image_url" size="25">
                                                                                                    </ngx-avatars>
                                                                                                    <span>{{orgUsersJson[userId]?.name}}</span>
                                                                                                </span>
                                                                                                </div> 
                                                                                            </ng-container>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.CURRENCY">
                                                                                    <span>
                                                                                        <span *ngIf="field?.value?.currency">{{currencyJson[field?.value?.currency]?.currencyCode}}: </span>
                                                                                        <span *ngIf="field?.value?.amount || field?.value?.amount === 0">{{field.value.amount || 0}}</span>
                                                                                    </span>
                                                                                </ng-container>
                                                                                <!-- <ng-container
                                                                                    *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.COUNTRY">
                                                                                    <span>
                                                                                        <span *ngIf="field?.value?.country">{{currencyJson[field?.value?.country]?.countryName}}: </span>
                                        
                                                                                    </span>
                                                                                </ng-container> -->
                                                                                <ng-container *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.LOCATION">
                                                                                    <span>
                                                                                        <span *ngIf="field?.value?.description || field?.value?.latitude">{{field?.value?.description? field.value.description: field?.value?.latitude? field.value.latitude +', '+ field.value.longitude : ''}}</span>
                                                                                        <a *ngIf="field?.value?.description || field?.value?.latitude" class="btn btn-outline-primary ml-2" style="border-radius: 1rem;padding:0 !important;width: 6rem;" target="_blank" rel="noopener noreferrer" href="https://maps.google.com/?q={{field.value.latitude}},{{field.value.longitude}}">Open in map</a>
                                                                                    </span>
                                                                                </ng-container>
                                                                                <ng-container *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.NUMBER">
                                                                                    <ng-container  *ngFor="let number of customField">
                                                                                        <span *ngIf="number.key==field.key">
                                                                                            <span *ngIf="number?.label && number?.align=='left'">{{number.label}} </span>
                                                                                            <span> {{field.value}} </span>
                                                                                            <span *ngIf="number?.label && number?.align=='right'"> {{number.label}}</span>
                                                                                        </span>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.RATING && (field.value!=''&& field.value!=0 && field.value!=null && field.value!=undefined)">
                                                                                    <ngb-rating [(rate)]="field.value" [readonly]="true" [max]="5">
                                                                                    </ngb-rating>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.URL">
                                                                                        <span [innerHTML]="field.value | linky:{stripPrefix: false, truncate: { length: 32, location: 'smart' }}"></span>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.MULTI_URL">
                                                                                    <span  [innerHTML]="field.value[0] | linky:{stripPrefix: false, truncate: { length: 20, location: 'smart' }}">{{field.value[0]}}</span>
                                                                                    <span *ngIf="field.value && field.value.length>1" [ngbPopover]="urlTemp"
                                                                                    placement="auto" container="body" class="viewMore">+{{field.value.length -1}}</span>
                                                                                    <ng-template #urlTemp>
                                                                                        <div style="max-height: 250px; overflow-y:auto; scrollbar-width:thin;">
                                                                                        <ng-container *ngFor="let url of field.value">
                                                                                            <div style="width: 200px; overflow-y: auto; scrollbar-width: thin;">
                                                                                            <span style="justify-content: flex-start; width: 100%; margin-bottom: 10px;">
                                                                                                <a href="{{url}}" target="_blank">{{url}}</a>
                                                                                            </span>
                                                                                            </div> 
                                                                                        </ng-container>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.CHECKBOX || customItem.type == constantService.constant.CUSTOMTYPES.MULTISELECT">
                                                                                <ng-container *ngIf="field.value && field.value.length>0">
                                                                                    <ng-container *ngFor="let data of field.value | slice:0:2">
                                                                                        <span>{{data | truncate: 26}}</span>
                                                                                    
                                                                                    </ng-container>
                                                                                    <span *ngIf="field.value && field.value.length>2" class="viewMoreBtn cursorPointer">
                                                                                        <span [ngbPopover]="checkBoxTemp" placement="auto" container="body" #popoverViewMore="ngbPopover" (click)="getPopoverRef(popoverViewMore,leadInfo._id)">
                                                                                            {{'board.viewMore' | translate}} +{{field.value.length -2}}
                                                                                        </span>
                                                                                    </span>
                                                                                        <ng-template #checkBoxTemp>
                                                                                            <div style="
                                                                                                    width: 200px; 
                                                                                                    max-height: 250px; 
                                                                                                    overflow-y: auto; 
                                                                                                    scrollbar-width: thin; 
                                                                                                    display: flex;
                                                                                                    flex-direction: column;
                                                                                                    row-gap: 10px;
                                                                                                    ">
                                                                                                    <ng-container *ngFor="let data of field.value">
                                                                                                        <div>
                                                                                                            <span class="option-text">{{data}}</span>
                                                                                                        </div> 
                                                                                                    </ng-container>
                                                                                                </div>                                                                                    
                                                                                        </ng-template>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.BOARD">
                                                                                    <ng-container *ngFor="let connectedItemId of field.value | slice:0:2">
                                                                                    <span class="option-text con-item-seq" *ngIf="mirrorColumnsJson[connectedItemId]?.seq_id">#{{mirrorColumnsJson[connectedItemId].seq_id}}</span><span class="option-text" *ngIf="mirrorColumnsJson[connectedItemId]?.title">{{mirrorColumnsJson[connectedItemId].title | truncate:25}}</span>
                                                                                    </ng-container>
                                                                                    <span *ngIf="field?.value && field.value.length>2" [ngbPopover]="connectedBoardTemp" [autoClose]="'outside'" #popoverViewMore="ngbPopover" (click)="getPopoverRef(popoverViewMore,leadInfo._id)"
                                                                                    placement="auto" container="body" > {{'board.viewMore' | translate}} +{{field.value.length-2}}</span>
                                                                                    <ng-template #connectedBoardTemp>
                                                                                        <app-item-list [itemIdList]="field.value" [dashId]="customItem.connected_board_id" (itemRemove)="removeItemFromBoard($event,leadInfo,customItem.key)" (onItemOpen)="clickedLead($event,null)"></app-item-list>
                                                                                    </ng-template>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.IMAGEFIELD || customItem.type == constantService.constant.CUSTOMTYPES.VIDEO || customItem.type == constantService.constant.CUSTOMTYPES.AUDIO || customItem.type == constantService.constant.CUSTOMTYPES.FILEUPLOAD">
                                                                                    <ng-container *ngIf="field.value">
                                                                                        <span (click)="openModalForPreview(field.value,0,customItem.type,videoPlayerModalNested)">
                                                                                            <app-dashboard-icon [name]="customItem.type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                                                                                        <span class="option-text">{{field.value.file_name | truncate: 10}}</span>
                                                                                        </span>
                                                                                    </ng-container>
                                                                                </ng-container>
                            
                                                                                <ng-container
                                                                                *ngIf="customItem.type == constantService.constant.CUSTOMTYPES.MULTIIMAGE || customItem.type == constantService.constant.CUSTOMTYPES.MULTIVIDEO || customItem.type == constantService.constant.CUSTOMTYPES.MULTIAUDIO || customItem.type == constantService.constant.CUSTOMTYPES.MULTIFILEUPLOAD">
                                                                                <ng-container *ngIf="field.value && field.value.length>0">
                                                                                    <ng-container *ngFor="let file of field.value | slice:0:2">
                                                                                        <span (click)="openModalForPreview(file,0,customItem.type,videoPlayerModalNested)">
                                                                                            <app-dashboard-icon [name]="customItem.type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                                                                                            <span class="option-text">{{file.file_name | truncate: 20}}</span>
                                                                                        </span> 
                                                                                    
                                                                                    </ng-container>
                                                                                    <span *ngIf="field.value && field.value.length>2"  class="viewMoreBtn cursorPointer">
                                                                                        <span [ngbPopover]="multiImgTemp" placement="auto" container="body" #popoverViewMore="ngbPopover" (click)="getPopoverRef(popoverViewMore,leadInfo._id)">
                                                                                            {{'board.viewMore' | translate}} +{{field.value.length -2}}</span>
                                                                                        </span>
                                                                                        <ng-template #multiImgTemp>
                                                                                            <div style="width: 200px; max-height: 250px; display: flex; flex-direction: column; row-gap: 10px; overflow-y: auto; scrollbar-width: thin;">
                                                                                                <ng-container *ngFor="let file of field.value">
                                                                                                    <div (click)="openModalForPreview(file,0,customItem.type,videoPlayerModalNested)">
                                                                                                        <app-dashboard-icon [name]="customItem.type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                                                                                                        <span class="option-text">{{file.file_name | truncate: 25}}</span>
                                                                                                    </div> 
                                                                                                
                                                                                                </ng-container>
                                                                                            </div>
                                                                                            
                                                                                        </ng-template>
                                                                                </ng-container>
                                                                            </ng-container>
                                                    
                                                                            <!-- leadInfo?.custom_fields[customItem.key].mirror_column_type -->
                                                                                <ng-container *ngIf="customItem.type != constantService.constant.CUSTOMTYPES.DATE &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.MOBILE &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.URL &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.MULTI_URL &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.TEXTAREA && 
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.RATING &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.IMAGEFIELD &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.VIDEO &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.AUDIO &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.SINGLEUSER &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.MULTIUSER &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.FILEUPLOAD &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.CURRENCY &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.MULTIIMAGE &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.MULTIVIDEO &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.MULTIAUDIO &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.MULTIFILEUPLOAD &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.BOARD &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.CHECKBOX &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.MULTISELECT &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.CHECKLIST &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.BUTTON &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.LOCATION &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.TIME_TRACKER &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.NUMBER &&
                                                                                customItem.type != constantService.constant.CUSTOMTYPES.MIRROR"
                                                                                >
                                                                                <span>{{field.value | truncate: 26}}</span>
                                                                                </ng-container>
                                                                                </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                                </div>
                                                                <div *ngIf="customItem.type == 'Mirror'" class="row leadCustKeys py-1">
                                                                        <div class="col-md-4 custKeyValue pl-2 pr-0 f-m-m">
                                                                            <!-- leadInfo?.custom_fields[customItem.key]?.mirror_column_key -->
                                                                            <app-dashboard-icon name="{{customItem?.type}}-{{customItem?.mirror_column_type}}" [iconJson]="attributesIcons"></app-dashboard-icon>
                                                                            {{customItem.display_key | truncate: 12}}
                                                                        </div>
                                                                        <div class="col-md-8 pl-1 custValue  f-s-r" style="display: flex; flex-direction:column; row-gap: 10px; justify-content: center; padding-right: 1px;">
                                                                                <ng-container *ngIf="leadInfo?.custom_fields[customItem.key]">
                                                                                <ng-container *ngFor="let mirrorItemId of leadInfo?.custom_fields[customItem.key].mirror_item_id | slice:0:2">
                                                                                    <!-- check for mirror item keys in current item -->
                                                                                <ng-container *ngIf="mirrorColumnsJson[mirrorItemId] && mirrorColumnsJson[mirrorItemId][leadInfo?.custom_fields[customItem.key]?.mirror_column_key]; else elseTemp">
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Priority'">
                                                                                        <div class="d-flex" style="column-gap: 2px;">
                                                                                            <span class="option-priority" [ngStyle]="{'background-color': priorityJson[mirrorColumnsJson[mirrorItemId].priority].color}">{{priorityJson[mirrorColumnsJson[mirrorItemId].priority].label}}</span>
                                                                                            <span class="option-text">{{priorityJson[mirrorColumnsJson[mirrorItemId].priority].name}}</span>
                                                                                        
                                                                                        </div>
                                                                                        
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Bucket'">
                                                                                        <div class="option-text">{{mirrorColumnsJson[mirrorItemId].bucket_name}}</div>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'SingleUser'">
                                                                                        <span class="assignusr justify-content-start f-m-b">
                                                                                            <ngx-avatars class="mr-1" [name]="orgUsersJson[mirrorColumnsJson[mirrorItemId][leadInfo?.custom_fields[customItem.key]?.mirror_column_key]]?.name ? orgUsersJson[mirrorColumnsJson[mirrorItemId][leadInfo?.custom_fields[customItem.key]?.mirror_column_key]]?.name : 'Assign'" [src]="orgUsersJson[mirrorColumnsJson[mirrorItemId][leadInfo?.custom_fields[customItem.key]?.mirror_column_key]]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                                                                                            <span>{{orgUsersJson[mirrorColumnsJson[mirrorItemId][leadInfo?.custom_fields[customItem.key]?.mirror_column_key]]?.name ? orgUsersJson[mirrorColumnsJson[mirrorItemId][leadInfo?.custom_fields[customItem.key]?.mirror_column_key]]?.name : 'Assign'}}</span>
                                                                                        </span>
                                                                                        <!-- <br> -->
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Sprint'">
                                                                                        <div class="option-text">{{mirrorColumnsJson[mirrorItemId]?.sprint_name | async}}</div>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Tag'">
                                                                                        <div *ngFor="let tag of mirrorColumnsJson[mirrorItemId].tags" class="option-text">{{tag.name}}</div>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Text'">
                                                                                        <div class="option-text">{{mirrorColumnsJson[mirrorItemId].title}}</div>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Date'">
                                                                                        <div class="option-text">
                                                                                            {{mirrorColumnsJson[mirrorItemId][leadInfo?.custom_fields[customItem.key]?.mirror_column_key] | dateCalcuate:"dateTime"}}
                                                                                        </div>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'DateTime'">
                                                                                        <div class="option-text">{{mirrorColumnsJson[mirrorItemId][leadInfo?.custom_fields[customItem.key]?.mirror_column_key] | daysago}}</div>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                            
                                                                                <!-- check for mirror item keys in current item custom fields  -->
                                                                                <!-- *ngIf="mirrorColumnsJson[mirrorItemId]?.custom_fields && mirrorColumnsJson[mirrorItemId]?.custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]" -->
                                                                                <ng-template #elseTemp>
                                                                                    <ng-container *ngIf="commonUtils.checkForFieldInMirror(mirrorItemId,leadInfo,customItem.key,mirrorColumnsJson)">
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Numeric' ||
                                                                                        leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Text' ||
                                                                                        leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Email' ||
                                                                                        leadInfo?.custom_fields[customItem.key].mirror_column_type === constantService.constant.CUSTOMTYPES.COUNTRY ||
                                                                                        leadInfo?.custom_fields[customItem.key].mirror_column_type === constantService.constant.CUSTOMTYPES.PERCENTAGE ||
                                                                                        leadInfo?.custom_fields[customItem.key].mirror_column_type === constantService.constant.CUSTOMTYPES.RADIO ||
                                                                                        leadInfo?.custom_fields[customItem.key].mirror_column_type === constantService.constant.CUSTOMTYPES.CHECKBOX ||
                                                                                        leadInfo?.custom_fields[customItem.key].mirror_column_type === constantService.constant.CUSTOMTYPES.MULTISELECT ||
                                                                                        leadInfo?.custom_fields[customItem.key].mirror_column_type === constantService.constant.CUSTOMTYPES.DROPDOWN ||
                                                                                        leadInfo?.custom_fields[customItem.key].mirror_column_type === constantService.constant.CUSTOMTYPES.IPADDRESS ||
                                                                                        leadInfo?.custom_fields[customItem.key].mirror_column_type === constantService.constant.CUSTOMTYPES.URL">
                                                                                        <div class="option-text">{{mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]}}</div>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Mobile'">
                                                                                        <div class="option-text" *ngIf="currencyJson">{{currencyJson[mirrorColumnsJson[mirrorItemId]?.custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]?.country_code]?.dial_code}}-{{mirrorColumnsJson[mirrorItemId]?.custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]?.mobile_number}}</div>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Button'">
                                                                                        <button class="mx-3 btn f-s-r triggerButton" [disabled]="true">{{ customItem?.connected_board_column_display_key }}</button>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Date'">
                                                                                        <div class="option-text">
                                                                                            {{mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key] | dateCalcuate:"dateTime"}}
                                                                                        </div>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === constantService.constant.CUSTOMTYPES.LOCATION">
                                                                                        <div class="option-text">{{mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]?.description}}</div>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'CheckList'">
                                                                                        <ng-container *ngFor="let checklist of mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key] | slice:0:1">
                                                                                            <div class="option-text">
                                                                                                <img *ngIf="checklist.state=='COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 29.png" alt="">
                                                                                                <img *ngIf="checklist.state=='NOT_COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 28.png" alt="">
                                                                                                {{checklist?.summary | truncate: 20}}
                                                                                            </div>
                                                                                        </ng-container>
                                                                                        <span *ngIf="mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key].length>1">
                                                                                            <span class="cursorPointer" [ngbPopover]="checklistTemp"  placement="auto"
                                                                                            container="body">{{'board.viewMore' | translate}} +{{mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key].length -1}}</span>
                                                                                        </span>
                                                                                        <ng-template #checklistTemp>
                                                                                            <ng-container *ngFor="let checklist of mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]">
                                                                                                <div style="width: 200px; max-height: 250px; overflow-y: auto; scrollbar-width: thin;"  >
                                                                                                    <img *ngIf="checklist.state=='COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 29.png" alt="">
                                                                                                    <img *ngIf="checklist.state=='NOT_COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 28.png" alt="">
                                                                                                    {{checklist?.summary | truncate: 20}}
                                                                                                </div>
                                                                                            </ng-container>
                                                                                        </ng-template>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Rating'">
                                                                                        <ngb-rating [(rate)]="mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]" [readonly]="true" [max]="5">
                                                                                        </ngb-rating>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type == constantService.constant.CUSTOMTYPES.CURRENCY">
                                                                                    <span *ngIf="mirrorColumnsJson[mirrorItemId]?.custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]">
                                                                                        <span>{{currencyJson[mirrorColumnsJson[mirrorItemId]?.custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]?.currency]?.currencyCode}}: </span>
                                                                                        <span >{{mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]?.amount || 0}}</span>
                                                                                    </span>
                                                                                </ng-container>
                                                                                <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === constantService.constant.CUSTOMTYPES.SINGLEUSER">
                                                                                    <span class="assignusr justify-content-start f-m-b">
                                                                                        <ngx-avatars class="mr-1" [name]="orgUsersJson[mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]]?.name" [src]="orgUsersJson[mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                                                                                        <span>{{orgUsersJson[mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]]?.name | truncate: 15}}</span>
                                                                                    </span>
                                                                                    <!-- <br> -->
                                                                                </ng-container>
                                                                                <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type == constantService.constant.CUSTOMTYPES.MULTIUSER">
                                                                                    <ng-container *ngFor="let userId of mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key] | slice:0:1">
                                                                                        <span class="assignusr justify-content-start f-m-b">
                                                                                            <ngx-avatars class="mr-1" [name]="orgUsersJson[userId]?.name" [src]="orgUsersJson[userId]?.image_url"
                                                                                                size="25">
                                                                                            </ngx-avatars>
                                                                                            <span>{{orgUsersJson[userId]?.name | truncate: 20}}</span>
                                                                                        </span>
                                                                                    </ng-container>
                                                                                    <span *ngIf="mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key].length>1">
                                                                                        <span class="cursorPointer" [ngbPopover]="multiUserTemp"  placement="auto"
                                                                                        container="body">{{'board.viewMore' | translate}} +{{mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key].length -1}}</span>
                                                                                    </span>
                                                                                    <ng-template #multiUserTemp>
                                                                                        <ng-container *ngFor="let userId of mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]">
                                                                                            <div style="width: 200px; max-height: 250px; overflow-y: auto; scrollbar-width: thin;"  >
                                                                                                <span class="assignusr f-m-b" style="justify-content: flex-start; width: 100%; margin-bottom: 10px;">
                                                                                                    <ngx-avatars class="mr-1" [name]="orgUsersJson[userId]?.name" [src]="orgUsersJson[userId]?.image_url"
                                                                                                        size="25">
                                                                                                    </ngx-avatars>
                                                                                                    <span>{{orgUsersJson[userId]?.name}}</span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </ng-container>
                                                                                    </ng-template>
                                                                                </ng-container>
                                                                                    <ng-container *ngIf="leadInfo?.custom_fields[customItem.key].mirror_column_type === 'Textarea'">
                                                                                        <div class="option-text boardTxtArea" [innerHTML]="mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key] | safehtml | truncate: 20"></div>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="[constantService.constant.CUSTOMTYPES.IMAGEFIELD,constantService.constant.CUSTOMTYPES.VIDEO,constantService.constant.CUSTOMTYPES.AUDIO,constantService.constant.CUSTOMTYPES.FILEUPLOAD]?.includes(leadInfo?.custom_fields[customItem.key].mirror_column_type)">
                                                                                        <span (click)="openModalForPreview(mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key],0,leadInfo?.custom_fields[customItem.key].mirror_column_type,videoPlayerModalNested)">
                                                                                            <app-dashboard-icon [name]="leadInfo?.custom_fields[customItem.key].mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                                                                                            <span class="option-text">{{mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key].file_name | truncate: 10}}</span>
                                                                                            <span>.{{commonUtils.getFileNameExtFromS3Path(mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key].file_path).extension}}</span>
    
                                                                                        </span>
                                                                                    </ng-container>
                                                                            
                                                                                    <ng-container *ngIf="[constantService.constant.CUSTOMTYPES.MULTIIMAGE,constantService.constant.CUSTOMTYPES.MULTIVIDEO,constantService.constant.CUSTOMTYPES.MULTIAUDIO,constantService.constant.CUSTOMTYPES.MULTIFILEUPLOAD]?.includes(leadInfo?.custom_fields[customItem.key].mirror_column_type)">
                                                                                        <ng-container *ngFor="let file of mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key] | slice:0:2">
                                                                                            <span (click)="openModalForPreview(file,0,leadInfo?.custom_fields[customItem.key].mirror_column_type,videoPlayerModalNested)">
                                                                                                <app-dashboard-icon [name]="leadInfo?.custom_fields[customItem.key].mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                                                                                                <span class="option-text">{{file.file_name | truncate: 20}}</span>
                                                                                                <span>.{{commonUtils.getFileNameExtFromS3Path(file.file_path).extension}}</span>
                                                                                            </span>
                                                                                    
                                                                                        </ng-container>
                                                                                        <span *ngIf="mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key].length>2" [ngbPopover]="multiImageTemp" placement="auto"
                                                                                            container="body">{{'board.viewMore' | translate}} +{{mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key].length -2}}</span>
                                                                                        <ng-template #multiImageTemp>
                                                                                            <ng-container *ngFor="let file of mirrorColumnsJson[mirrorItemId].custom_fields[leadInfo?.custom_fields[customItem.key]?.mirror_column_key]">
                                                                                                <div (click)="openModalForPreview(file,0,leadInfo?.custom_fields[customItem.key].mirror_column_type,videoPlayerModalNested)">
                                                                                                    <app-dashboard-icon [name]="leadInfo?.custom_fields[customItem.key].mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                                                                                                    <span class="option-text">{{file.file_name | truncate: 20}}</span>
                                                                                                    <span>.{{commonUtils.getFileNameExtFromS3Path(file.file_path).extension}}</span>
                                                                                                </div>
                                                                                            </ng-container>
                                                                                        </ng-template>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                                </ng-template>
                                                                                </ng-container>
                                                                                    <!-----Connected Board-->
                                                                                    <ng-container *ngIf="leadInfo.custom_fields[customItem.key].mirror_column_type === 'Board'">
                                                                                        <ng-container *ngFor="let connectedItemId of leadInfo.custom_fields[customItem.key]?.mirror_item_id | slice:0:2">
                                                                                            <span class="option-text con-item-seq" *ngIf="mirrorColumnsJson[connectedItemId]?.seq_id">#{{mirrorColumnsJson[connectedItemId].seq_id}}</span><span class="option-text" *ngIf="mirrorColumnsJson[connectedItemId]?.title">{{mirrorColumnsJson[connectedItemId].title | truncate:25}}</span>
                                                                                            </ng-container>
                                                                                            <span *ngIf="leadInfo.custom_fields[customItem.key].mirror_item_id && leadInfo.custom_fields[customItem.key].mirror_item_id.length>2" [ngbPopover]="connectedBoardTemp" #popoverViewMore="ngbPopover" (click)="getPopoverRef(popoverViewMore,leadInfo._id)"
                                                                                            placement="auto" container="body"> {{'board.viewMore' | translate}} +{{leadInfo.custom_fields[customItem.key].mirror_item_id.length-2}}</span>
                                                                                            <ng-template #connectedBoardTemp>
                                                                                                <!--  (itemRemove)="removeItemFromBoard($event,leadInfo,customItem.key)" -->
                                                                                                <app-item-list [itemIdList]="leadInfo.custom_fields[customItem.key].mirror_item_id" [dashId]="customItem.connected_board_id" [showRemoveIcon]="false" [isReadOnly]="true" (onItemOpen)="clickedLead($event,null)"></app-item-list>
                                                                                            </ng-template>
                                                                                    </ng-container>
                                                                                <div class="viewMoreBtn cursorPointer" *ngIf="leadInfo.custom_fields[customItem.key].mirror_item_id?.length>2  && leadInfo.custom_fields[customItem.key].mirror_column_type !== 'Board'">
                                                                                <span [ngbPopover]="mirrorPriorityTemp" placement="auto" container="body" > 
                                                                                    {{'board.viewMore' | translate}} +{{leadInfo.custom_fields[customItem.key].mirror_item_id.length -2}}
                                                                                    </span>
                                                                                </div>
                                                                                <ng-template #mirrorPriorityTemp>
                                                                                    <app-mirror-column-options [optionsArr]="leadInfo.custom_fields[customItem.key].mirror_item_id" [currencyJson]="currencyJson" [customItem]="customItem" [currentItem]="leadInfo" [mirrorColumnsJson]="mirrorColumnsJson"></app-mirror-column-options>
                                                                                </ng-template>
                                                                                </ng-container>
                                                                        </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </ng-container>
                                        </ng-container>
                                    </div>

                                    <ng-container *ngIf="leadInfo.showChildren" class="cursorDefault" (click)="$event.stopPropagation()">
                                        <ng-container *ngFor="let childLeadId of leadInfo.child_lead_id">
                                            <div *ngIf="parentChildLeads[childLeadId] && !parentChildLeads[childLeadId]?.is_deleted" class="subItemBorder row mx-1 mt-2" (click)="clickedLead(parentChildLeads[childLeadId], groupInfo);$event.stopPropagation()" [style.border-left-color]="constantService?.priority_colors[parentChildLeads[childLeadId]?.priority]">
                                                <div class="col-12 px-2" style="display: flex; justify-content: space-between;">
                                                    <span class="lIdbuckNameStyle">
                                                        <span (click)="onNavigateToEditLeadPage(parentChildLeads[childLeadId]);$event.stopPropagation()">{{parentChildLeads[childLeadId]?.seq_id_text}}</span>
                                                        <span class="bluetxt"> - {{parentChildLeads[childLeadId]?.bucket_name}} </span>
                                                    </span>
                                                    <span class="userprof f-m-b" (click)="$event.stopPropagation()" placement="auto" container="body" #assigneePopOver="ngbPopover" container="body" [ngbPopover]="parentChildLeads[childLeadId]?.editable && assignUserTemplateChild" [autoClose]="'outside'">
                                                        <span class="userprof"  [ngbPopover]="parentChildLeads[childLeadId].assigned_to && userInfoCard" popoverClass="userInfoCardPopover" triggers="hover" placement="auto"  container="body">
                                                            <span *ngIf="!parentChildLeads[childLeadId].assigned_to" class="iconBox-30 hoverBg"><img src="../../assets/common-use-icon-20dp/add-user-6d6e6f.svg" alt="add-asginee" class="iconBox-24"></span>
                                                            <ngx-avatars class="mr-1" *ngIf="parentChildLeads[childLeadId].assigned_to" [name]="orgUsersJson[parentChildLeads[childLeadId]?.assigned_to]?.name || 'Assign'" [src]="orgUsersJson[parentChildLeads[childLeadId].assigned_to]?.image_url" size="24" initialsSize="2" ></ngx-avatars>
                                                        </span>
                                                    </span>
                                                    <ng-template #assignUserTemplateChild>
                                                        <app-lead-assign-user [assignedUser]="orgUsersJson[parentChildLeads[childLeadId].assigned_to]" [dashId]="leadInfo?.dashboard_id" [leadInfo]="[parentChildLeads[childLeadId]]" [bulkSelectionFlag]="false" (close)="assigneePopOver.close()"></app-lead-assign-user>
                                                    </ng-template>
                                                    <ng-template #userInfoCard>
                                                        <app-user-info-card [user]="orgUsersJson[parentChildLeads[childLeadId].assigned_to]"></app-user-info-card>
                                                    </ng-template>
                                                </div>
                                                <div class="col-12 px-2">
                                                    {{parentChildLeads[childLeadId]?.title | truncate : 70}}
                                                </div>
                                            </div>

                                        </ng-container>
                                    </ng-container>
                                    
                                </div>
                            </ng-container>
                        </ng-container>
                        <div *ngIf="groupInfo?.enableScrollerLoader" class="d-flex text-center justify-content-center p-2" style="background-color: white;position: sticky;bottom: 0px;">
                            <app-shimmer-loader  [type]="'Spinner'" [circleWidth]="30" [color]="commonUtils.getCurrentThemeColor()"></app-shimmer-loader>
                        </div>
                        <!-- show empty icon -->
                        <ng-container *ngIf="!groupInfo.leadContents?.length && (viewType=='CalenderView' || viewType=='SplitView' || viewType=='bottomBarView')">
                            <div class="no-data-found">
                                <img src="../../assets/img/icons/empty.svg" />
                                <span class="f-xl-m mt-1">{{'editcustom.noRecordFound' | translate}}</span> 
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            </ng-container>
            <ng-container *ngIf="viewType=='SplitView'">
                <ng-container *ngTemplateOutlet="itemViewPannel"></ng-container>
            </ng-container>
            <div class="showMobileViewMsg">
                <p>For better user experience open in wide screen.</p>
            </div>
            <div *ngIf="isSidebarOpen" class="lastboarcol"></div>
        </div>
    </ng-container>
<!-- preview modal -->
  <ng-template #videoPlayerModalNested let-modal>
    <div class="previewBodyModal" style="height: 100%;max-width: 100%;position: relative;">
      <i class="fa fa-close bg-white previewCross" (click)="modal.dismiss()"></i>
      <app-file-preview [mediaArr]="mediaArr" [index]="indexForPreview" [type]="'ITEM'" [dashId]="selectedDashId" [typeId]="currentLeadId"></app-file-preview>
    </div>
  </ng-template>

  <!-- multi select -->
  <ng-container *ngIf="multiSelectedLeadIds?.length || multiSelectGroupArray?.length">
    <app-item-bulk-selection
    class="d-flex align-items-center justify-content-center"
    [dashUsers]="users"
    [bulkSelectionData]="bulkSelectionData"
    [dashId]="selectedDashId"
    [leadInfo]="bulkLeadInfo"
    [dashboardTags]="tagsCorrespondingToDash"
    [dashBoardSprint]="dashBoardSprint"
    [bucketArray]="bucketArray"
    [selectedStartDate]="selectedStartDate"
    [selectedEndDate]="selectedEndDate"
    (bulkOperationsCompleted)="bulkOperationsCompleted()"
    [isSelectedAll]="isSelectedAll"
    [filterDataObj]="filterDataObj"
    [multiSelectGroupArray]="multiSelectGroupArray"
    [excludeInMultiselectBucket]="excludeInMultiselectGroup"
    [funnelarr]="funnelarr"
    >
    </app-item-bulk-selection>
  </ng-container>

<div class="modal fade" id="dragNotAllowed" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog " role="document">

    <div class="modal-content">
      <div class="modal-header text-right mt-2 pb-0">
        <div data-dismiss="modal" aria-label="Close" aria-hidden="true" class="close"><span aria-hidden="true">×</span>
        </div>
      </div>

      <div class="modal-body text-center pb-5">
        <h4 class="modal-title pb-3">        {{'board.notAllowed' | translate}}</h4>
        <img src="../../../assets/1x/info.png"><br>
        <br>
        {{'board.youAreNotAllowedToMoveLeadInBucket' | translate}}
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="itemPreviewData?.length">
    <item-view [itemData]="itemPreviewData" [currentBoardId]="selectedDashId" [currentViewId]="viewType=='GraphView' ? null : currentViewId"  (closeItemView)="itemPreviewData=[]" (onItemChange)="$event?.changeType=='previousItem' && moveToPreviousLead();$event?.changeType=='nextItem' && moveToNextLead()" [class]="'item-side-view'"></item-view>
</ng-container>
<!-- item right container -->
<ng-template #itemViewPannel>
    <div class="item-container" style="flex: 1;background-color: white;overflow: auto;">
        <ng-container *ngIf="itemPannelPreviewData?.length">
            <item-view [itemData]="itemPannelPreviewData" [itemViewType]="'MANUAL'" (onItemChange)="$event?.changeType=='previousItem' && moveToPreviousLead();$event?.changeType=='nextItem' && moveToNextLead()" (onOtherItemOpen)="itemPreviewData=[$event]"></item-view>
        </ng-container>
        <ng-container *ngIf="!itemPannelPreviewData?.length">
            <div class="no-item-selected">
                <img src="../../assets/img/icons/empty.svg" />
                <span class="f-xl-m mt-1">No Item Selected</span> 
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #addcustomleadsplus let-modal>
    <app-create-lead-modal [dashId]="selectedDashId"  (createLeadModalCloseEvent)="modal.dismiss();" [bucketSelect]="selectedGroupBy?.group_by=='bucket' ? currentGroup?._id : null" style="height: 100%;"></app-create-lead-modal>
  </ng-template>
