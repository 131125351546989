<div class="createLeadComponent container" #createItemViewport keyboardMonitor>
    <!----- Header Area -->
    <div class="mediumrow row mx-0">
        <div class="mx-0 w-100 itemFullViewHeader" style="justify-content: space-between;">
            <div *ngIf="!callFromDependency" class="itemLeftSideHeader">
                <div class="leadDataBox d-inline-flex">
                    <span><img src="../../../assets/new-icon-6d6e6f-20dp/board-6d6e6f.svg" ></span>
                    <span class="dashBoardNameBox"><span class="dashboardname">{{dashboardInfo?.dashboard_name}}</span></span>
                </div>
                <div class="leadPropertyBox d-inline-flex">
                    <span *ngIf="!isTemplateCall" (click)="scrollTO('dependency')" role="button">{{dependenciesObj?.dependencies?.depends_on.length}}<app-dashboard-icon [size]="16" class="iconBox"  [name]="'Dependency'"></app-dashboard-icon>{{dependenciesObj?.dependencies?.dependents.length}}</span>
                    <span class="d-flex">
                        <span [ngbPopover]="!viewOnly && leadUsersTemplate" #popCollab="ngbPopover" class="key-tab" tabindex="0"
                        container="body" [autoClose]="'outside'" (click)="$event.stopPropagation(); getPopOverRef(popCollab)" role="button">
                        <app-dashboard-icon [size]="16" class="iconBox"  [name]="'Collaborator'"></app-dashboard-icon>
                        </span>
                        <span *ngIf="leadInfo?.lead_colloborators_users?.length>0">{{leadInfo?.lead_colloborators_users?.length}}</span>
                    </span> 
                    <ng-template #leadUsersTemplate>
                        <app-lead-colloborators dashId={{dashId}} (selectedCollaborator)="leadInfo['lead_colloborators_users']=$event" [leadObj] = "leadInfo" [itemQueryType]="itemQueryType" [isItemCopy]="isItemCopy">
                        </app-lead-colloborators>
                    </ng-template>
                </div>
            </div>
            <div *ngIf="callFromDependency" class="itemLeftSideHeader dependency-create-option">
                <div style="height: 64px;display: flex;justify-content: space-between;padding-top: 18px;">
                    <div class="selected-dependency cursorPointer" [ngStyle]="{'background-color': commonUtils.getCurrentThemeColor() + '1f','color': commonUtils.getCurrentThemeColor()}" [ngbPopover]="viewListTemp" #viewPopoverRef="ngbPopover" [placement]="['bottom-right','bottom','auto']" autoClose="outside" popoverClass="modifiedPopover">{{ getBlockingLabel() }}</div>
                </div>
            </div>
                <div class=" iconBox" style="border: none !important; width: 20px; height: 20px;">
                    <span *ngIf="!itemConfig?.isRecordingInprogress" class="tprgtico key-tab" tabindex="0" role="button" (click)="closeCreateLeadModal()"><img src="../../../../assets/common-use-icon-20dp/close.svg"></span>
                    <span *ngIf="itemConfig?.isRecordingInprogress" class="tprgtico key-tab" tabindex="0" role="button" 
                    [ngbPopover]="!viewOnly && recordingStop" #stopRecord="ngbPopover" placement="auto" container="body"
                #popOver="ngbPopover" [autoClose]="'outside'" ><i class="fas fa-times"></i>
                        <ng-template #recordingStop>
                        <div style="display: flex;align-items: center;flex-direction: column;row-gap: 10px; width: 300px;">
                            <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;row-gap: 10px;">
                            <h6>{{'helpingText.recordingCancelOnCloseItem' | translate}}</h6>
                            </div>
                            <div style="display: flex;column-gap: 10px;">
                            <button class="btn btn-success" (click)="stopRecord.close();closeCreateLeadModal()">{{'multiSelect.yes' | translate}}</button>
                            <button class="btn btn-danger" (click)='stopRecord.close()'>{{'multiSelect.no' | translate}}</button>
                            </div>
                        </div>
                        </ng-template>
                    </span>
                </div>
        </div>
    </div>

    <!-----Middle Area  or Body Area-->
    <div class="middleContentArea mx-0">
        <div class="mx-0 itemContentArea">
            <app-edit-custom *ngIf="refreshCustomFields"
            [dashBoardSprintArr]="dashBoardSprint || []" 
            [tagsCorrespondingToDash]="dashTags" 
            [bucketArray]="arrayOfBucket || []"
            [dashId]="dashId"
            [leadId]="leadId"
            [leadObj]="leadInfo"
            [editTitle]="title"
            [addTitile]="true"
            [itemQueryType]="itemQueryType"
            [triggeredFromCreateACopy]="createCopy"
            (isLeadCreated)="isLeadCreated = $event"
            [bucketId]="bucketSelect"
            [addItem]="addItem"
            [copyToOtherBoard]="copyToOtherBoard"
            [dashboardUserRole]="dashboardUserRole"
            (onEdit)="customFieldsObj = $event"
            (onTitleChange)="title = $event;$event?.length==0 && (isLeadCreated=false)"
            [isTemplateCall]="isTemplateCall"
            [viewOnly]="viewOnly"
            [itemConfig]="itemConfig"
            [createLeadCall]="true"
            >
            </app-edit-custom>
            <app-full-item-dependency-sub-task-check-list *ngIf="!isTemplateCall" [dashId]="dashId"
            class="px-2"
            [dependsOnItemsIdArr]="dependenciesObj?.depends_on"
            [dependentsItemIdArr]="dependenciesObj?.dependents"
            [viewOnly]="viewOnly"
            (dependenciesObjEmit)="dependenciesObj = $event"></app-full-item-dependency-sub-task-check-list>
        </div>
    </div>

    <!---- Bottom area -->
    <div class="mx-0 leadBottomBox">
        <span *ngIf="!callFromDependency" class="leftTextBox">
            <span class="createAnotherItem " style="display: flex; align-items:center;" *ngIf="(!isTemplateCall || isItemCopy) && !copyToOtherBoard">
                <input class="mr-2" type="checkbox" name="check" [(ngModel)]="isCreateAnother" class="key-tab" tabindex="0"> 
                <span>{{'leadOption.createAnotherItem' | translate :{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}</span>
            </span>
            <span *ngIf="(!isTemplateCall || isItemCopy) && !copyToOtherBoard ">{{'leadOption.createdItem' | translate :{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }} {{createdItems}}</span>
            <span class="pull-left template-name" *ngIf="(isTemplateCall && !isItemCopy) && !copyToOtherBoard"><input type="textbox" [class.disabled]="viewOnly" placeholder="Template Name" [(ngModel)]="templateName" class="key-tab" tabindex="0"> <img class="star" src="../../../assets/img/icons/star.svg"/></span>
        </span>
        <span class="rightBtnBox" [ngStyle]="{'width':callFromDependency ? '100%' : '30%'}">
            <ng-container *ngIf="!(isTemplateCall && leadId) && !copyToOtherBoard" >
                <button *ngIf="!creatingLead"  (click)="createNewCustomLeadCorrToBucket()" [disabled]="!isLeadCreated || !isTemplateCall && (!leadInfo?.bucket_id ||  title?.length==0) || isTemplateCall && !templateName?.length" type="button" class="btn btn-lg copyItemButton key-tab" tabindex="0">+ {{'common.create' | translate}}</button>
                <button *ngIf="creatingLead" [disabled]="true" type="button" class="btn btn-lg copyItemButton ">
                    <div class="spinner-border" role="status"><span class="sr-only">{{'common.loading' | translate}}...</span></div>  
                    {{'common.creating' | translate}}
                </button>
            </ng-container>
            <ng-container *ngIf="(isTemplateCall && leadId) && !copyToOtherBoard" >
                <button *ngIf="!creatingLead" (click)="updateTemplateCustomFields()" [disabled]="!isLeadCreated || viewOnly || !templateName?.length" type="button" class="btn btn-lg copyItemButton key-tab" tabindex="0">+ {{'common.update' | translate}}</button>
                <button *ngIf="creatingLead" [disabled]="true" type="button" class="btn btn-lg copyItemButton ">
                    <div class="spinner-border" role="status"><span class="sr-only">{{'common.loading' | translate}}...</span></div>  
                    {{'common.updating' | translate}}
                </button>
            </ng-container>
            <ng-container *ngIf="!(isTemplateCall && leadInfo) && copyToOtherBoard">
                <!-- move -->
                <button *ngIf="!movingLead"  (click)="moveleadToOtherBoard()" [disabled]="!isLeadCreated" type="button" class="btn btn-lg copyItemButton key-tab" tabindex="0">{{'common.move' | translate}}</button>
                <button *ngIf="movingLead" [disabled]="true" type="button" class="btn btn-lg copyItemButton ">
                    <div class="spinner-border" role="status"><span class="sr-only">{{'common.moving' | translate}}...</span></div>  
                    {{'common.moving' | translate}}
                </button>
                <!-- create -->
                <button *ngIf="!creatingLead"  (click)="createNewCustomLeadCorrToBucket()" [disabled]="!isLeadCreated" type="button" class="btn btn-lg copyItemButton key-tab" tabindex="0">{{'common.copy' | translate}}</button>
                <button *ngIf="creatingLead" [disabled]="true" type="button" class="btn btn-lg copyItemButton ">
                    <div class="spinner-border" role="status"><span class="sr-only">{{'common.copying' | translate}}...</span></div>  
                    {{'common.copying' | translate}}
                </button>
            </ng-container>

        </span>
    </div>
</div>
<ng-template #viewListTemp>
    <div>
        <ng-container *ngFor="let view of dependancy">
            <div (click)="isBlocking=view?.value;viewPopoverRef?.close()" class="dependency-options-dropdown" style="width: 120px;height: 30px;">
                <span>{{view?.label}}</span>
            </div>
        </ng-container>
    </div>
  </ng-template>