import { FilterPipe } from './../filter.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaysagoPipe } from '../daysago.pipe';
import { LoaderComponent } from './../views/loader/loader.component';
import { TruncatePipe } from '../truncate.pipe';
import { QuillDeltaTextPipe } from '../pipes/quilldeltatext.pipe';
import { AttributesLabelPipe } from '../pipes/attributes-label.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TerminologyPipe } from '../pipes/terminology.pipe';
import { NumberTransformPipe } from 'app/number-formating.pipe';


@NgModule({
  declarations: [
    DaysagoPipe,
    LoaderComponent,
    FilterPipe,
    TruncatePipe,
    QuillDeltaTextPipe,
    AttributesLabelPipe,TerminologyPipe,NumberTransformPipe
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports:[
    DaysagoPipe,
    LoaderComponent,
    FilterPipe,
    TruncatePipe,
    QuillDeltaTextPipe,
    AttributesLabelPipe,TerminologyPipe,NumberTransformPipe
  ]
})
export class ReusableModule { }
