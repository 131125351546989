import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from '../../../services/toaster.service';

@Component({
  selector: 'app-lead-bucket-change',
  templateUrl: './lead-bucket-change.component.html',
  styleUrls: ['./lead-bucket-change.component.scss']
})
export class LeadBucketChangeComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
  @ViewChild('container', { static: false }) container!: ElementRef;
  @Output() closePopover = new EventEmitter<any>();
  @Input() leadInfo:any[];
  @Input() dashId:number;
  @Input() bulkSelectionFlag:boolean;
  @Input() bucketArray: any=[];
  @Input() objFilterForMultiselect: any;
  @Output() updateLead = new EventEmitter<any>();
  @Output() updateBuckets = new EventEmitter<any>();
  searchText:any="";
  copyBucketArray: any[];
  allowBucketIdsArray:any=[]
  itemAttributesObj: any;
  selectedIndex=0;
  constructor(private httpTransfer: HttpTransferService,private toaster:ToasterService
    ,public commonUtils:CommonUtils, private dashboardUtils: DashboardUtilsService) { }

  async ngOnInit(){
    if(this.leadInfo?.length==1 && this.leadInfo){
      this.allowBucketIdsArray=await this.commonUtils.getAllowedBuckets(this.dashId,this.leadInfo[0]?.bucket_id,this.bucketArray,this.leadInfo[0])
      
      
      this.bucketArray=this.bucketArray.filter(
        (data) => this.allowBucketIdsArray.includes(data._id)
      )
    }
    let existingCustomFields = await this.dashboardUtils.getDashboardExistingCustomFormIfPresent(this.dashId);
    if(existingCustomFields?.length)this.itemAttributesObj = this.commonUtils.handleExistingItemAttributes(existingCustomFields)
    this.copyBucketArray=this.bucketArray
  }

  ngAfterViewInit() {
    this.searchInput.nativeElement.focus();
  }

  onBucketSelect(bucket) {
    let destination_bucket_id=bucket?._id
    if(this.leadInfo) {
      if(this.leadInfo.length>0) {
        
        if(this.leadInfo.length>1){
     
          let inputJson={}
       
          inputJson['filter_properties']=this.objFilterForMultiselect
          inputJson['update_json']={
            "destination_bucket_id":destination_bucket_id
          }
          this.httpTransfer.updateMultipleDashboard(this.dashId,inputJson).subscribe((res : any) =>{
            this.closePopover.emit(true)
          })
        }
        else{
          this.updateBucket(
                destination_bucket_id,
                this.leadInfo[0]._id,
                this.leadInfo[0].bucket_id,
                bucket
              );
        }

      } else {
        this.toaster.Errors("Please Select Items");
      }
    } else {
      this.updateLead.emit(destination_bucket_id);
    }
    
  }

  updateBucket(destination_bucket_id,leadId, bucket_id,bucketInfo?) {
    var leadObj = {};
    leadObj["destination_bucket_id"] = destination_bucket_id;
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(leadObj, this.dashId, leadId)
      .subscribe((res : any) => {
        if(res.status == 200) {
          this.updateBuckets.emit({'leadId':leadId,'destination_bucket_id':destination_bucket_id,'bucketId':bucket_id})
          this.updateLead.emit({'leadId':leadId,'bucketId':bucket_id,'bucket_name':bucketInfo?.name,'destination_bucket_id':destination_bucket_id});
          if(this.leadInfo) {
            this.leadInfo['bucket_id']=destination_bucket_id
          }
        }
      });
  }
  filterBuckets(searchText) {
      searchText=searchText.trim().toLowerCase()
      this.bucketArray=this.copyBucketArray.filter(
          (data) => data.name.toLowerCase().indexOf(searchText) !== -1
      );
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Tab':
        event.preventDefault()
        if(this.selectedIndex == this.bucketArray.length-1){
          this.selectedIndex=0;
        }else{
          this.selectedIndex++;
        }
        break;
      case 'ArrowUp':
        if (this.selectedIndex >0) {
          this.selectedIndex--;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
        }
        break;
      case 'ArrowDown':
        if (this.selectedIndex < this.bucketArray.length - 1) {
          this.selectedIndex++;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
        }
        break;
      case 'Enter':
        if(0<=this.selectedIndex && this.selectedIndex<this.bucketArray?.length){
          this.onBucketSelect(this.bucketArray[this.selectedIndex])
        }
      }
    }

}
