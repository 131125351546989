

<div *ngIf="!selectedItemAttribute" class="itemattributes">

    <div class="itemattributeslist" (click)="addingParentLead();selectedItemAttribute='Parent'">
        <span><app-dashboard-icon [iconJson]="AttributesIcons"  [name]="'Parent'"></app-dashboard-icon></span>
        <label>{{'editcustom.parent' | translate}}</label>
    </div>
    <div class="itemattributeslist" (click)="selectedItemAttribute='Collaborator'; userList=[]; customFieldsValue=[]">
        <span><app-dashboard-icon [iconJson]="AttributesIcons"  [name]="'Collaborator'"></app-dashboard-icon></span>
        <label>{{'dashboard.filter.collaborators' | translate}}</label>
    </div>
    <ng-template ngFor let-item [ngForOf]="multipleCustomData" let-index="index" let-isLast="last">
        <div *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.TEXT" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.TEXT" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.TEXTAREA" class="itemattributeslist" (click)="initializeSummerNote();selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.TEXTAREA" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.NUMBER" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.NUMBER" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.EMAIL" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.EMAIL" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.MOBILE" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.MOBILE" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.DATE" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.DATE" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.VIDEO" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.VIDEO" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.MULTIVIDEO" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.MULTIVIDEO" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.AUDIO" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.AUDIO" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.MULTIAUDIO" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.MULTIAUDIO" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.FILEUPLOAD" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.FILEUPLOAD" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.MULTIFILEUPLOAD" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.MULTIFILEUPLOAD" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.IMAGEFIELD" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.IMAGEFIELD" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.MULTIIMAGE" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.MULTIIMAGE" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.RADIO" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.RADIO" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.DROPDOWN" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.DROPDOWN" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.CHECKBOX" class="itemattributeslist" (click)="selectedItemAttribute=item; customFieldsValue=[]">
            <span ><app-dashboard-icon  [name]="customFieldType.CHECKBOX" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.RATING" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.RATING" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.URL" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.URL" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.MULTI_URL" class="itemattributeslist" (click)="selectedItemAttribute=item;customFieldsValue=[]">
            <span ><app-dashboard-icon  [name]="customFieldType.MULTI_URL" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>


        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.CURRENCY" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.CURRENCY" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.COUNTRY" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.COUNTRY" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.LOCATION" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.LOCATION" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.MULTISELECT" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.MULTISELECT" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.IPADDRESS && item.settings == 'Manual'" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.IPADDRESS" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.CHECKLIST" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.CHECKLIST" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <!-- <div  *ngIf="item.type == customFieldType.BUTTON" class="itemattributeslist" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.BUTTON" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div> -->

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.USER" class="itemattributeslist" (click)="selectedItemAttribute=item; userList=[]; customFieldsValue=[]">
            <span ><app-dashboard-icon  [name]="customFieldType.USER" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="(item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY') && item.type == customFieldType.MULTIUSER" class="itemattributeslist" (click)="selectedItemAttribute=item;userList=[]; customFieldsValue=[]">
            <span ><app-dashboard-icon  [name]="customFieldType.MULTIUSER" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="item.type == customFieldType.BOARD" class="itemattributeslist" (click)="selectedItemAttribute=item; customFieldsValue=[]">
            <span ><app-dashboard-icon  [name]="customFieldType.BOARD" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label>{{item.display_key}} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

        <div  *ngIf="item.type == customFieldType.MIRROR" (click)="selectedItemAttribute=item">
            <span ><app-dashboard-icon  [name]="customFieldType.MIRROR" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            <label *ngIf="!item.activeEdit">{{item.display_key }} <img class="star" *ngIf="item.mandatory" src="../../../assets/img/icons/star.svg"/></label>
        </div>

    </ng-template>
    <div *ngIf="!multipleCustomData.length"> 
        <div class="align-center align-middle" >
            <div class="empty">
                <img class="w-70 h-70" src="../../../assets/img/icons/empty.svg"/>
            </div>
            <div class="col-12 text-center">
              <p class="my-5">{{'setting.noCustomAttributesText' | translate}}</p>
            </div>
          </div>
    </div>

</div>





<div *ngIf="selectedItemAttribute">


    <div *ngIf="selectedItemAttribute=='Collaborator' && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{'dashboard.filter.collaborators' | translate}}</div>
                </div>
                <div *ngIf="customFieldsValue?.length>0" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <div>
                    <app-user-selected [dashIdSelected]="dashId"  (selectedUserList)="selectedUserList($event)" [removeFromSelected]="removeFromSelected" [userList]="userList"></app-user-selected>
                    <div class="select" *ngIf="userList.length">
                      <div class="row w-100" *ngFor="let item of userList" style="flex-wrap: nowrap;">
                        <div class="userNameBox">
                          <ngx-avatars class="mr-1" [name]="item.name" [src]="item.image_url" size="30" initialsSize="2" ></ngx-avatars>
                          <span>{{item.name}}</span>
                        </div>
                      <div class="rightIconBox">
                        <span style="float:right"><img *ngIf="item?.isDashUser" src="../../../assets/1x/boards-folders.svg"></span>
                        <span style="float:right"><img *ngIf="!item?.isDashUser" src="../../../assets/1x/user.svg"></span> 
                        <img src="../../../assets/1x/cross-icon.svg" (click)="removeFromSelectedList(item)">
                      </div>
                      </div>
                  </div>
                </div>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
               
            </div>
        </div>
    </div>
    <div *ngIf="selectedItemAttribute=='Parent' && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{'editcustom.parent' | translate}}</div>
                </div>
                <div *ngIf="customFieldsValue" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody"> 
                <div >
                    <div class="row mx-0 w-100">
                      <div class="col-md-12 px-0 form-group mb-0" >
                        <div style="display: flex;flex-direction:column">
                            <span class="col-md-12 px-0 form-group mb-0">{{'leadOption.parent.subItemhaveOneParent' | translate:{ term: "Item" | terminology: itemTerminology?.singular : 'Item' } }}</span>
                            <span class="text-danger">*{{'multiSelect.itemnotUpdatedCyclicDependency' | translate:{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }}</span>
                        </div>
                        <ng-select  class="custom" #st  placeholder="{{'leadOption.parent.addParentfromExisting' | translate:{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }}" appearance="outline" name="selectParentLead" #selectDropdown (search)="filterLead($event)"
                        [multiple]="false" [closeOnSelect]="true" [searchable]="true" [clearable]="true" [(ngModel)]="selectedLead" [disabled]="selectedParentLead!=null" (click)="selectedParentLead=selectedLead ;selectDropdown.handleClearClick();customFieldsValue=selectedParentLead?._id" >
                          <ng-option *ngFor="let lead of dashboardLeadArr" [value]="lead">
                            <div class ="p-0 mb-0 leadCardBoxs w-100 border-bottom">
                                <div class="leadHeaderRows w-100" style="justify-content: space-between;">
                                  <span class="leadIdDetails">
                                    <span class="priorityTag">
                                      <span class="prioLabelTag" [style.background]="commonUtils?.priorityJson[lead?.priority]?.color" >{{commonUtils?.priorityJson[lead?.priority]?.label}}</span>
                                    </span>
                                    <span class="mx-2">#{{lead?.seq_id}}</span>
                                  </span>
                                  <button type="button" class="close pull-right" aria-label="Close" style="width: 10%;">
                                    <span aria-hidden="true">&#43;</span>
                                  </button>
                                </div>
                                <div class="leadDescriptionBox w-100">
                                  <p class="mb-0 leadDescription ">
                                    {{lead.title | slice:0:130}}
                                  </p>
                                </div>
                              </div>
                            </ng-option>
                        </ng-select>
                      </div>
                    </div>  

                    <div class="row mx-0 w-100 mt-2 h-20" >
                        <div class="row mx-0 w-100 leadCardBoxs leadCard border-bottom"  *ngIf="selectedParentLead!=null">
                                        <div class="leadHeaderRows w-100" style="justify-content: space-between;">
                                            <span class="leadIdDetails">
                                              <span class="priorityTag">
                                                <span class="prioLabelTag" [style.background]="commonUtils?.priorityJson[selectedParentLead?.priority]?.color" >{{commonUtils?.priorityJson[selectedParentLead?.priority]?.label}}</span>
                                              </span>
                                              <span class="mx-2">#{{selectedParentLead?.seq_id}}</span>
                                            </span>

                                            <button type="button" class="close pull-right" aria-label="Close" (click)="selectedParentLead.readMore=null; selectedParentLead=null;customFieldsValue=null" style="width: 10%;">
                                              <span aria-hidden="true">&times;</span>
                                            </button>
                                          </div>
                                          <div class=" leadDescriptionBox w-100">
                                            <div *ngIf="selectedParentLead?.title.length<130">
                                              <p class="mb-0">{{selectedParentLead?.title}}</p>
                                            </div>
                                            <div *ngIf="selectedParentLead?.title.length>130">
                                              <p *ngIf="!selectedParentLead.readMore" class="mb-0">{{selectedParentLead?.title | slice:0:125}}...<span (click)="selectedParentLead.readMore=true" class="readLessMoreBtnText">Read more</span> </p>
                                              <p *ngIf="selectedParentLead.readMore" class="mb-0">{{selectedParentLead?.title}}</p>
                                            </div>
                                          </div>
                        </div>
                    </div>
                  </div>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
               
            </div>
        </div>
    </div>

    <div *ngIf="selectedItemAttribute.type==customFieldType.TEXT && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="customFieldsValue && customFieldsValue.trim().length" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <textarea [minRows]="3" [maxRows]="8" placeholder="{{'multiSelect.writeHere' | translate}}" class="textFieldEditArea" [(ngModel)]="customFieldsValue" autosize>
                </textarea>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
               
            </div>
        </div>
    </div>

    <div *ngIf="selectedItemAttribute.type==customFieldType.TEXTAREA && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <div id="summernoteTextArea" style="height:50%;width: 100%;"></div>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span (click)="removeExistingValue=true;customFieldsValue=null">{{'multiSelect.removeExistingValue' | translate}} </span>
            </div>
        </div>
    </div>

    <div *ngIf="selectedItemAttribute.type==customFieldType.NUMBER && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="customFieldsValue || customFieldsValue==0" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true" ></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <input class="inputItemAttribute" placeholder="{{'multiSelect.writeHere' | translate}}" type="number" [(ngModel)]="customFieldsValue"/>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="selectedItemAttribute.type==customFieldType.EMAIL && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div  *ngIf="(customFieldsValue && customFieldsValue.trim().length) && !(email.invalid && (email.dirty || email.touched))" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true" ></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <input class="inputItemAttribute" placeholder="{{'multiSelect.writeHere' | translate}}" type="email"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"  [(ngModel)]="customFieldsValue"  #email="ngModel"/>
                <div *ngIf="email.invalid && (email.dirty || email.touched)">
                    <span class="text-danger">*{{'editcustom.enterCorrectEmail' | translate}}</span>
                  </div>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="selectedItemAttribute.type==customFieldType.MOBILE && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null;mobileNumber=null; userDialCode=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="customFieldsValue" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true" ></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null;mobileNumber=null; userDialCode=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <div style="display:flex;">
                    <ng-select style="height:100%;width: 100px;border: 1px solid black; border-radius: 5px;"   [multiple]="false" [closeOnSelect]="true" [searchFn]="onSearchCode" [items]="countryCodeJson"
                                    [searchable]="true" bindLabel="dial_code" bindValu="code" [clearable]="false" [ngModelOptions]="{standalone: true}" [(ngModel)]="userDialCode" [searchFn]="commonutils.onSearchCodeCurrency" id="userDialCode" name="userDialCode" (change)="mobilenumber(userDialCode,mobileNumber ? mobileNumber:'')" requied>
                                    <ng-template ng-option-tmp style="height:100%;" let-item="item" let-item$="item$" let-index="index"  let-search="searchTerm">
                                    {{item.flag}}
                                    <span class="f-m-b ">{{item.dial_code}}</span><br/>
                                    <span class="f-xs-l">{{item.name}}</span>
                                    </ng-template>
                                </ng-select>
                        <input type="number" placeholder="{{'multiSelect.writeHere' | translate}}" id="mobileNumber" pattern="[0-9,-,+,(,)]{3,16}" [max]="15" [readonly]="!userDialCode" [(ngModel)]="mobileNumber" (input)=" mobilenumber(userDialCode,mobileNumber)">
                    </div>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>


    <div *ngIf="selectedItemAttribute.type==customFieldType.DATE && !removeExistingValue">
        <div class="itemattributesUpdateForDate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="customFieldsValue" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true" ></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons"  (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody my-1">
                <date-picker 
                (onDateSave)="customFieldsValue = $event"
                (onDateClear)="customFieldsValue=null">
                </date-picker>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="selectedItemAttribute.type==customFieldType.RADIO && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div  *ngIf="customFieldsValue!=null" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <div style="height: 215px; overflow-y: auto ;">
                    <div class="radioOptionBox"
                          *ngFor="let data of selectedItemAttribute.permissible_values">
                          <div style="width: 300px;display: flex;column-gap: 5px; align-items: center;">
                            <ng-container >
                              <input type="radio" name="{{ selectedItemAttribute.key }}" [value]="customFieldsValue" (change)="customFieldsValue=data">
                              <label style="display: flex; align-items: center;">{{ data?.k }}</label>
                            </ng-container>
                        </div>
                    </div>
                </div>
                </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>


    <div *ngIf="selectedItemAttribute.type==customFieldType.CHECKBOX && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="customFieldsValue?.length" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <div style="height: 215px; overflow-y: auto ;">
                    <div class="radioOptionBox"
                          *ngFor="let data of selectedItemAttribute.permissible_values">
                          <div style="width: 300px;  display: flex;column-gap: 5px; align-items: center;">
                            <ng-container >
                              <input type="checkbox" (change)="changeCheckboxarray(data)">
                              <label  style="display: flex; align-items: center;">{{ data?.k }}</label>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue.length && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>


    <div *ngIf="selectedItemAttribute.type==customFieldType.DROPDOWN && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="customFieldsValue!=null" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons"  (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <ng-container >
                    <ng-select placeholder="{{'placeholders.select' | translate}}"  [virtualScroll]="true"
                [items]="selectedItemAttribute.permissible_values" bindLabel='v' bindValue="k" [multiple]="false" [closeOnSelect]="true"                       
                [clearable]="false" [(ngModel)]="customFieldsValue" ></ng-select>
                  </ng-container>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="selectedItemAttribute.type==customFieldType.URL && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="(customFieldsValue && customFieldsValue.trim().length)" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true" ></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <input class="inputItemAttribute" placeholder="{{'multiSelect.writeHere' | translate}}" type="text" pattern="/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
                " [(ngModel)]="customFieldsValue" #url="ngModel" required/>
                
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="selectedItemAttribute.type==customFieldType.MULTI_URL && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null;addingURL=false"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="customFieldsValue && customFieldsValue.length" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true" ></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null;addingURL=false"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <div *ngIf="!addingURL" style="height: 50px;">
                    <span class="cursorPointer addUrlText" (click)="addingURL=true"><i class="fa fa-plus" aria-hidden="true"></i> New</span>
                    <!-- <button class="btn btn-primary rounded" (click)="addingURL=true"><i class="fa fa-plus" aria-hidden="true"></i> New</button> -->
                </div>
                <div *ngIf="addingURL" style="height: 50px;">
                    <input class="inputItemAttribute" placeholder="{{'multiSelect.writeHere' | translate}}" type="text" pattern="/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g" 
                    [(ngModel)]="addURLText" #url="ngModel" (blur)="makeMultiURLarr(addURLText);" (keyup.enter)="makeMultiURLarr(addURLText);" required/>
                </div>
                <div class="urllist" *ngIf="customFieldsValue && customFieldsValue.length">
                    <div class="row m-2 urllink" *ngFor="let url of customFieldsValue; let i=index">
                        <div style="width: 250px;">
                            {{url}}
                        </div>
                        <div class="col-md-1 urldelete" (click)="removeUrl(url)"><img src="../../../assets/1x/checklist-remove-ico.svg" alt=""></div>
                    </div>
                </div>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>


    <div *ngIf="selectedItemAttribute.type==customFieldType.RATING && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="customFieldsValue" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true" ></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <ngb-rating style="font-size: 50px;"  [max]="5" [(rate)]="customFieldsValue">
              </ngb-rating>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="(selectedItemAttribute.type==customFieldType.MULTIIMAGE || selectedItemAttribute.type==customFieldType.IMAGEFIELD || selectedItemAttribute.type==customFieldType.VIDEO || selectedItemAttribute.type==customFieldType.MULTIVIDEO || selectedItemAttribute.type==customFieldType.FILEUPLOAD || selectedItemAttribute.type==customFieldType.MULTIFILEUPLOAD || selectedItemAttribute.type==customFieldType.AUDIO || selectedItemAttribute.type==customFieldType.MULTIAUDIO) && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null;showUploadMediaArr=[]"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="showUploadMediaArr.length" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="mediaUpload()"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null;showUploadMediaArr=[]"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">

                <div *ngIf="selectedItemAttribute.type==customFieldType.IMAGEFIELD && !isUploading">
                    <button class="uploadBtn" (click)="uploadSingleImage.value=null;uploadSingleImage.click()" [disabled]="showUploadMediaArr.length==1"> {{'multiSelect.uploadImage' | translate}}</button>
                    <input #uploadSingleImage type="file" class="inputFile" name="ImageFile" accept="image/*" (change)="UploadFiles($event)"
                    single >
                </div>
                <div *ngIf="selectedItemAttribute.type==customFieldType.MULTIIMAGE && !isUploading">
                    <button class="uploadBtn" (click)="uploadMultiImage.value=null;uploadMultiImage.click()"> {{'multiSelect.uploadImages' | translate}}</button>
                    <input #uploadMultiImage type="file" class="inputFile" name="ImageFile" accept="image/*" (change)="UploadFiles($event)"
                    multiple >
                </div>
                
                <div *ngIf="selectedItemAttribute.type==customFieldType.VIDEO && !isUploading">
                    <button class="uploadBtn" (click)="uploadSingleVideo.value=null;uploadSingleVideo.click()" [disabled]="showUploadMediaArr.length==1"> {{'multiSelect.uploadVideo' | translate}}</button>
                    <input #uploadSingleVideo type="file" class="inputFile" name="ImageFile" accept="video/*" (change)="UploadFiles($event)"
                    single>
                </div>
                <div *ngIf="selectedItemAttribute.type==customFieldType.MULTIVIDEO && !isUploading">
                    <button class="uploadBtn" (click)="uploadMultiVideo.value=null;uploadMultiVideo.click()"> {{'multiSelect.uploadVideos' | translate}}</button>
                    <input #uploadMultiVideo type="file" class="inputFile" name="ImageFile" accept="video/*" (change)="UploadFiles($event)"
                    multiple>
                </div>

                <div *ngIf="selectedItemAttribute.type==customFieldType.AUDIO && !isUploading">
                    <button class="uploadBtn" (click)="uploadSingleAudio.value=null;uploadSingleAudio.click()" [disabled]="showUploadMediaArr.length==1"> {{'multiSelect.uploadAudio' | translate}}</button>
                    <input #uploadSingleAudio type="file" class="inputFile" name="ImageFile" accept="audio/*" (change)="UploadFiles($event)"
                    single>
                </div>
                <div *ngIf="selectedItemAttribute.type==customFieldType.MULTIAUDIO && !isUploading">
                    <button class="uploadBtn" (click)="uploadMultiAudio.value=null;uploadMultiAudio.click()"> {{'multiSelect.uploadAudios' | translate}}</button>
                    <input #uploadMultiAudio type="file" class="inputFile" name="ImageFile" accept="audio/*" (change)="UploadFiles($event)"
                    multiple>
                </div>

                <div *ngIf="selectedItemAttribute.type==customFieldType.FILEUPLOAD && !isUploading">
                    <button class="uploadBtn" (click)="uploadSingleFile.value=null;uploadSingleFile.click()" [disabled]="showUploadMediaArr.length==1"> {{'multiSelect.uploadFile' | translate}}</button>
                    <input #uploadSingleFile type="file" class="inputFile" name="ImageFile" 
                    accept="application/pdf, .xls,.xlsx,.doc,.docx ,application/zip,.rar,.7zip,application/vnd.oasis.opendocument.text" (change)="UploadFiles($event)"
                    single>
                </div>
                <div *ngIf="selectedItemAttribute.type==customFieldType.MULTIFILEUPLOAD && !isUploading">
                    <button class="uploadBtn" (click)="uploadMultiFile.value=null;uploadMultiFile.click()"> {{'multiSelect.uploadFiles' | translate}}</button>
                    <input #uploadMultiFile type="file" class="inputFile" name="ImageFile" 
                    accept="application/pdf, .xls,.xlsx,.doc,.docx ,application/zip,.rar,.7zip,application/vnd.oasis.opendocument.text" (change)="UploadFiles($event)"
                    multiple>
                </div>

                <div  *ngIf="isUploading">
                    <button  type="button" class="uploadBtn" [disabled]="true">
                        <div class="spinner-border" role="status" style="width: 15px;height: 15px;"><span class="sr-only">Loading...</span></div> 
                        {{'importExport.uploading' | translate}}...</button>
                </div>

                <div style="width:275px;height:140px;border: 2px solid #ddd;border-bottom: none;overflow-x: hidden;
                overflow-y: auto;" *ngIf="showUploadMediaArr.length">
                    <div class="mdaBlocks mt-2 mb-1 f-l-r-r">
                        <ng-container *ngFor="let media of showUploadMediaArr;let i= index">
                            <div class="mediaDiv" *ngIf="media.file_type=='image'"><img  style="width:100px;height:100px;object-fit: contain;" [src]="media?.url ? media?.url : media?.file_path"><span class="dltin"><img (click)="removeFile(i)" src="../../../assets/1x/image-delete-ico.png" class="deleteico"></span>
                                <span class="fileNameU" 
                                style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                {{media?.file_name}}</span>
                            </div>
                            <div class="mediaDiv" *ngIf="media.file_type=='video'"> <video style="width:100px;height:100px;" [src]="media?.url ? media?.url : media?.file_path"></video><span class="dltin"><img (click)="removeFile(i)" src="../../../assets/1x/image-delete-ico.png" class="deleteico"></span>
                                <span class="fileNameU" 
                                style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                {{media?.file_name}}</span>
                            </div>
                            <div class="mediaDiv" *ngIf="media.file_type=='audio'" style="justify-content: center;align-items: center;"> <app-dashboard-icon [name]="customFieldType.AUDIO" [iconJson]="lxAttributesIcons" size="80"></app-dashboard-icon> 
                                <span class="dltin"><img (click)="removeFile(i)" src="../../../assets/1x/image-delete-ico.png" class="deleteico"></span>
                                <span class="fileNameU" 
                                style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                {{media?.file_name}}</span>
                            </div>
                            <div class="mediaDiv" *ngIf="media.file_type!='video' && media.file_type!='image' && media.file_type!='audio'"><app-dashboard-icon [name]="(mediaIcons?.hasOwnProperty(media?.file_name?.split('.').pop())) ? media?.file_name?.split('.').pop() :'txt'" [iconJson]="mediaIcons" size="95"></app-dashboard-icon> 
                                <span class="dltin"><img (click)="removeFile(i)" src="../../../assets/1x/image-delete-ico.png" class="deleteico"></span>
                                <span class="fileNameU" 
                                style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                {{media?.file_name}}</span>
                            </div>                           
                        </ng-container> 
                    </div>
                
                </div>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="(!showUploadMediaArr.length && !isUploading) && !selectedItemAttribute.mandatory " (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>



    <div *ngIf="selectedItemAttribute.type==customFieldType.CURRENCY && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null;currencyCode=null;currencyAmount=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div  *ngIf="customFieldsValue" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons"  (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null;currencyCode=null;currencyAmount=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody" style="display:flex;">
                <ng-select style="height:36px; width: 125px;" [items]="countryCodeJson" 
                placeholder="Select Currency"  [(ngModel)]="currencyCode" id="currencyCode" name="currencyCode"
                [clearable]="false" [multiple]="false" bindLabel="currencyCode"  [searchFn]="commonutils.onSearchCodeCurrency"
                bindValue="code" [closeOnSelect]="true" >
                {{item | json}}
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  {{item.flag}}
                  {{item.text}} <br/>
                  <small class="small">{{item.name}}</small>
                </ng-template>
              </ng-select>
              <input style="height: 35px; width: 130px;" placeholder="Enter Amount" [readonly]="!currencyCode" type="number"  [(ngModel)]="currencyAmount" (input)="curenncyValue(currencyCode,currencyAmount)" />
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span  *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="selectedItemAttribute.type==customFieldType.COUNTRY && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null;currencyCode=null;currencyAmount=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div  *ngIf="customFieldsValue" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons"  (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null;currencyCode=null;currencyAmount=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody" style="display:flex;">
                <ng-select style="height:36px; width: 200px;" [items]="countryCodeJson" 
                placeholder="Select Country"  [(ngModel)]="customFieldsValue" id="currencyCode" name="currencyCode"
                [clearable]="false" [multiple]="false" bindLabel="name"
                bindValue="name" [closeOnSelect]="true" >
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    {{item.flag}} {{item.name}}
                </ng-template>
              </ng-select>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span  *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="selectedItemAttribute.type==customFieldType.LOCATION && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null;currencyCode=null;currencyAmount=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div  *ngIf="customFieldsValue" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons"  (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null;currencyCode=null;currencyAmount=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody" style="display:flex;">
                <app-location-item-settings style="width: 100%;padding: 10px;" [locationData]="item" [multiselectCall]="true" (selectedLocation)="customFieldsValue=$event"></app-location-item-settings>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span  *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>


    <div *ngIf="selectedItemAttribute.type==customFieldType.MULTISELECT && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <ng-select [items]="selectedItemAttribute.permissible_values" [multiple]="true" [closeOnSelect]="true" [(ngModel)]="customFieldsValue" ></ng-select>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>


    <div *ngIf="selectedItemAttribute.type==customFieldType.CHECKLIST && !removeExistingValue">

        <div class="itemattributesUpdate" *ngIf="checklistOperation==null">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
            </div>
            <div class="itemattributesUpdateBody" >
                <div style="display: flex; flex-direction: column;">
                    <span class="checklistOptions" (click)="checklistOperation='newChecklist'">{{'multiSelect.addNewChecklist' | translate}}</span>
                    <span class="checklistOptions" (click)="checklistOperation='markAllCompleted'">{{'multiSelect.markAllCompleted' | translate}}</span>
                    <span class="checklistOptions" (click)="checklistOperation='markAllIncompleted'">{{'multiSelect.markAllIncompleted' | translate}}</span>
                </div>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>

        <div class="itemattributesUpdate" *ngIf="checklistOperation=='newChecklist'">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="checklistOperation=null"></i>
                    <div>{{'multiSelect.addChecklist' | translate}}</div>
                </div>
                <div *ngIf="addchecklist" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="checklistOperation=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody" >
                <div style="display: flex; flex-direction: column;">
                    <textarea [minRows]="3" placeholder="{{'multiSelect.writeHere' | translate}}" class="textFieldEditArea" 
                    [(ngModel)]="addchecklist" (blur)="makechecklist('NOT_COMPLETED',addchecklist)">
                    </textarea>
                </div>
            </div>
        </div>


        <div class="itemattributesUpdate" *ngIf="checklistOperation=='markAllCompleted' || checklistOperation=='markAllIncompleted'">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="checklistOperation=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
            </div>
            <div class="itemattributesUpdateBody" >
                <div style="display: flex; flex-direction: column;">
                    <div  *ngIf="checklistOperation=='markAllCompleted'">
                        <span>{{'multiSelect.areYouSureMarkAllCompleted' | translate}}</span>
                    </div>
                    <div  *ngIf="checklistOperation=='markAllIncompleted'">
                        <span>{{'multiSelect.areYouSureMarkAllIncompleted' | translate}}</span>
                    </div>
                    <div style="display: flex; column-gap:10px">
                        <button *ngIf="checklistOperation=='markAllCompleted'" style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="makechecklist('COMPLETED')">Yes</button>
                        <button *ngIf="checklistOperation=='markAllIncompleted'" style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="makechecklist('NOT_COMPLETED')">Yes</button>
                        <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="removeExistingValue=false">No</button>
                    </div>
                </div>
            </div>
        </div>


    </div>


    <div *ngIf="selectedItemAttribute.type==customFieldType.IPADDRESS && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div  *ngIf="customFieldsValue && !(ipField.invalid && (ipField.dirty || ipField.touched))" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true" ></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <input class="inputItemAttribute" placeholder="{{'multiSelect.writeHere' | translate}}" type="string"
                pattern="^(?!.*\.$)((?!0\d)(1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$"  [(ngModel)]="customFieldsValue"  #ipField="ngModel"/>
                <div class="mt-1"><button class="btn-primary" style="height:36px;" (click)="pickIp()">Pickip</button></div>
                <div *ngIf="ipField.invalid && (ipField.dirty || ipField.touched)">
                    <div *ngIf="ipField?.errors?.required">
                      <span class="validationText ls-025x">{{'itemattributes.ip' | translate}} {{'editcustom.Required' | translate}}. </span>
                    </div>
                    <div *ngIf="ipField?.errors?.pattern">
                      <span class="validationText ls-025x">{{'editcustom.enterCorrectIP' | translate}}</span>
                    </div>
                  </div>
                  
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>


    <div *ngIf="selectedItemAttribute.type==customFieldType.MULTIUSER && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="customFieldsValue?.length>0" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true" ></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <div>
                    <app-user-selected [dashIdSelected]="dashId"  (selectedUserList)="selectedUserList($event)" [removeFromSelected]="removeFromSelected" [userList]="userList"></app-user-selected>
                    <div class="select" *ngIf="userList.length">
                      <div class="row w-100" *ngFor="let item of userList" style="flex-wrap: nowrap;">
                        <div class="userNameBox">
                          <ngx-avatars class="mr-1" [name]="item.name" [src]="item.image_url" size="30" initialsSize="2" ></ngx-avatars>
                          <span>{{item.name}}</span>
                        </div>
                      <div class="rightIconBox">
                        <span style="float:right"><img *ngIf="item?.isDashUser" src="../../../assets/1x/boards-folders.svg"></span>
                        <span style="float:right"><img *ngIf="!item?.isDashUser" src="../../../assets/1x/user.svg"></span> 
                        <img src="../../../assets/1x/cross-icon.svg" (click)="removeFromSelectedList(item)">
                      </div>
                      </div>
                  </div>
                </div>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span  *ngIf="!customFieldsValue?.length>0 && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>


    <div *ngIf="selectedItemAttribute.type==customFieldType.USER && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="customFieldsValue?.length>0" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true" ></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons"  (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <div>
                    <app-user-selected [dashIdSelected]="dashId"  (selectedUserList)="selectedUserList($event)" [removeFromSelected]="removeFromSelected" [userList]="userList"></app-user-selected>
                    <div class="select" *ngIf="userList.length">
                      <div class="row w-100" *ngFor="let item of userList" style="flex-wrap: nowrap;">
                        <div class="userNameBox">
                          <ngx-avatars class="mr-1" [name]="item.name" [src]="item.image_url" size="30" initialsSize="2" ></ngx-avatars>
                          <span>{{item.name}}</span>
                        </div>
                      <div class="rightIconBox">
                        <span style="float:right"><img *ngIf="item?.isDashUser" src="../../../assets/1x/boards-folders.svg"></span>
                        <span style="float:right"><img *ngIf="!item?.isDashUser" src="../../../assets/1x/user.svg"></span> 
                        <img src="../../../assets/1x/cross-icon.svg" (click)="removeFromSelectedList(item)">
                      </div>
                      </div>
                  </div>
                </div>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue?.length>0 && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="selectedItemAttribute.type==customFieldType.BOARD && !removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null; customFieldsValue=null"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
                <div *ngIf="customFieldsValue.length" style="display: flex; column-gap:10px">
                    <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="selectedItemAttribute=null; customFieldsValue=null"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="itemattributesUpdateBody">
                <input class="pb-0 addInput MontserratFont" id="dependsOnInput" #searchInput
              type='text' placeholder=" {{'placeholders.seachPlaceholderText' | translate}}" (input)='selectedItemAttribute.searchText = searchInput.value'>
                <div class="itemList" *ngIf="selectedItemAttribute?.searchText?.length">
                    <app-search-items [dashboardId]="selectedItemAttribute.connected_board_id" [searchText]="selectedItemAttribute.searchText" (clickedItem)="setItemToBoard(selectedItemAttribute, $event, customFieldType.BOARD ,selectedItemAttribute.key)"></app-search-items>
                </div>
                <div class="" style="max-height:300px;overflow-y: auto;overflow-x: hidden;">
                    <app-item-list [itemIdList]="customFieldsValue"  [dashId]="selectedItemAttribute.connected_board_id" [showBoardName]="false" (itemRemove)="removeItemFromBoard(selectedItemAttribute, $event, customFieldType.BOARD ,selectedItemAttribute.key)" [currentLead]="leadObj"></app-item-list>
                </div>
            </div>
            <div class="itemattributesUpdateFooter" role="button">
                <span *ngIf="!customFieldsValue.length && !selectedItemAttribute.mandatory" (click)="removeExistingValue=true;customFieldsValue=null"> {{'multiSelect.removeExistingValue' | translate}}</span>
            </div>
        </div>
    </div>


    <!-- Remove Existing Value -->
    <div *ngIf="removeExistingValue">
        <div class="itemattributesUpdate">
            <div class="itemattributesUpdateHeader">
                <div style="display: flex; column-gap:10px; align-items: center;font-size: 14px;" role="button">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectedItemAttribute=null;removeExistingValue=false"></i>
                    <div>{{selectedItemAttribute.display_key}}</div>
                </div>
            </div>
            <div class="itemattributesUpdateBody" >
                <div style="display: flex; flex-direction: column;">
                    <div>
                        <span>{{'multiSelect.areYouSureRemoveExistingValue' | translate}}</span>
                    </div>
                    <div style="display: flex; column-gap:10px">
                        <button style="background-color: green;" class="itemattributesUpdateHeaderButtons" (click)="updateCustomFields(selectedItemAttribute,customFieldsValue)">Yes</button>
                        <button style="background-color: red ;"class="itemattributesUpdateHeaderButtons" (click)="removeExistingValue=false">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    



</div>
