
<div *ngFor="let value of operationArr;let index= index" class="numberBox" style="margin: 10px 0;">
    <div style="width: 50%;" *ngIf="index>0">
        <ng-select [items]="constantService.constant.operationOnNumber" bindLabel="display_key" bindValue="key" [clearable]="false" [(ngModel)]="value.operation" (ngModelChange)="operationUpdated.emit()"></ng-select>
    </div>
    <div class="operandBox" *ngIf="value.operationType=='OPERATION'">
        <span class="operationCross" *ngIf="operationArr.length>1 && ((currencyCall && index!=0) || !currencyCall)" (click)="removeOperation(operationArr,index)"><img src="../../../assets/1x/whiteBin.svg"/></span> 
         <div *ngIf="value?.operand!='manual' && ((currencyCall  && index!=0) || !currencyCall)" style="display: flex;column-gap: 10px;">
            <ng-select [items]="listBasedOnType" [ngClass]="{'numberselectFormirror' : automationCall && value.showMirror,'numberselect': !value.showMirror}" bindLabel="display_key" bindValue="key" [clearable]="false"  [(ngModel)]="value.operand" (ngModelChange)="checkforMirrorField(value.operand,value);operationUpdated.emit();"></ng-select>
            <ng-select class="selectmirrorAlgorithm"  *ngIf="automationCall && value.showMirror" [items]="value.mirrorJson" bindLabel="display_key" bindValue="key" [clearable]="false"  [ngModelOptions]="{standalone: true}" [(ngModel)]="value.mirrorAlgorithm" (ngModelChange)="operationUpdated.emit()"></ng-select>
        </div>
        <!--For Currency-->
        <div *ngIf="value?.operand!='manual' && currencyCall && index==0" style="display: flex;column-gap: 10px;justify-content: space-between;">
         <div style="width:100%;display: flex;border-radius: 5px;border-color:1px solid #ccc;" *ngIf="!value.showMirror">
          <span class="advanceCurrencyOption">Amount</span>
          <ng-select [items]="listBasedOnType" [ngClass]="{'numberselectFormirror' : automationCall && value.showMirror,'numberselect': !value.showMirror}" bindLabel="display_key" bindValue="key" [clearable]="false"  [(ngModel)]="value.operand" (ngModelChange)="checkforMirrorField(value.operand,value);operationUpdated.emit();"></ng-select>
        </div>
        <div style="width:100%;display: flex;border-radius: 5px;border-color:1px solid #ccc;justify-content: space-between;" *ngIf="value.showMirror">
           <div style="width:48%;display: flex;">
            <span class="advanceCurrencyOption" style="width:20%;">Amount</span>
            <ng-select style="width:100%;" [items]="listBasedOnType" [ngClass]="{'numberselectFormirror' : automationCall && value.showMirror,'numberselect': !value.showMirror}" bindLabel="display_key" bindValue="key" [clearable]="false"  [(ngModel)]="value.operand" (ngModelChange)="checkforMirrorField(value.operand,value);operationUpdated.emit();"></ng-select>
           </div>
           <div style="width:48%;">
            <ng-select style="width:100%;" class="selectmirrorAlgorithm"  *ngIf="automationCall && value.showMirror" [items]="value.mirrorJson" bindLabel="display_key" bindValue="key" [clearable]="false"  [ngModelOptions]="{standalone: true}" [(ngModel)]="value.mirrorAlgorithm" (ngModelChange)="operationUpdated.emit()"></ng-select>
           </div>
           </div>
        </div>
        <div *ngIf="value?.operand=='manual'  && ((currencyCall  && index!=0) || !currencyCall)" class="numberInputArea" >
            <input class="numberInput" type=number [(ngModel)]="value.inputValue" (ngModelChange)="operationUpdated.emit()"/>  
            <span class="crossIcon" (click)="value.operand='';value.inputValue=0;operationUpdated.emit()"><i class="fa fa-close"></i></span>     
        </div>
        <div *ngIf="value?.operand=='manual'  && currencyCall  && index==0" class="numberInputArea" style="width:100%;display: flex;border-radius: 5px;border-color:1px solid #ccc;">
            <span class="advanceCurrencyOption">Amount</span>
            <input class="numberInput" type=number [(ngModel)]="value.inputValue" (ngModelChange)="operationUpdated.emit()"/>  
            <span class="crossIcon" (click)="value.operand='';value.inputValue=0;operationUpdated.emit()"><i class="fa fa-close"></i></span>     
        </div>
    </div>
    <div class="operandBoxGroup" *ngIf="value.operationType=='GROUP'">
        <span class="operationCross" *ngIf="operationArr.length>1" (click)="removeOperation(operationArr,index)"><img src="../../../assets/1x/whiteBin.svg"/></span> 
        <app-number-operation [automationCall]="automationCall" [customFieldArr]="customFieldArr" [listBasedOnType]="listBasedOnType" [operationArr]="value?.operationArr" (operationUpdated)="operationUpdated.emit()"></app-number-operation>
    </div>
</div>

<div style="display:flex;flex-direction:row;column-gap:10px">
    <span class="addOperationBtn" (click)="addMoreOperation()">+ <span class="addOperationGroup">{{'automation.operation' | translate}}</span> </span>
    <span class="addOperationBtn" (click)="addMoreOperationGroup()">+ <span class="addOperationGroup">{{'automation.operationGroup' | translate}}</span></span>
  </div>