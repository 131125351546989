import { ChangeDetectorRef, Compiler, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantService } from '../../../../Enums/Constant.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { CustomStorageService } from '../../../services/custom-storage.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { Subscription } from 'rxjs';
import { KeyboardMonitorService } from 'app/services/keyboardMonitor.service';

@Component({
  selector: 'app-subtask-connectboard-filter',
  templateUrl: './subtask-connectboard-filter.html',
  styleUrls: ['./subtask-connectboard-filter.scss']
})
export class SubtaskConnectboardFilterComponent implements OnInit {
  @Input() currentLead: any;
  @Input() dashId: string;
  @Input() isPublicLead:boolean=false;
  @Input() edicustomView:boolean=false;
  @Input() dashboardIdOfConnect:string=''
  @Input() connectedBoardIds:any[]=[];
  @Input() connectedBoardValuekey:string='';
  @Input() connectedBoardIdsList:any[]=[];
  @Input() viewOfRight:boolean=false
  @Input() callType:string=''
  @Input() activityInfo:any=null
  @Output() selectedListItem = new EventEmitter<any>();
  @Output() showSubtaskFilter = new EventEmitter<any>();
  @Output() showBoardListView = new EventEmitter<any>();
  @Output() getAllDashBoardNotes = new EventEmitter<any>();
  @Output() getAllBoardLists = new EventEmitter<any>();
  @Output() resetValues = new EventEmitter<any>();
  @ViewChild("viewComponent", { read: ViewContainerRef })
  viewComponent!: ViewContainerRef;
  @ViewChild("searchSubtask") searchSubtask:ElementRef;
  isReadOnly: boolean;
  isDashboarUser: any;
  dashAccess: { is_readOnly_user: boolean; no_dashboard_user: boolean; is_collabrator_user: any; is_deleted_lead: any; dashboard_type: any; is_template_board: boolean; is_internal_board: boolean; };
  localFilterDataObj = this.commonUtils.createDefaultFilterDataObj();
  arrayOfCustomFiled=[];
  arrayOfCurrentBoard=[]
  searchLeadList=[]
  noResponse=null
  orgUsersJson: any
  bucketOfCorrespondingDash: any = [];
  filterPopoverHolder:any
  customFields: any[] = [];
  filterOpen:boolean=false;
  gridViewRef:any;
  gridValues:any[]=[]
  filterDataObj:any={};
  loadAllData:Subscription;
  gridViewRefSub:any
  searchInput:string=''
  itemBulkSubscription:Subscription;
  excludeInMultiselectGroup:any[]=[];
  multiselectLeadIds:any[]=[];
  rowData:any;
  totalCountLead:number;
  numberOfLeadsLoaded:number;
  @Input() noteId: any=null;
  arrayField=[{itemName: "Last Updated", isHide: false, isCustom: false, columnKey:'last_updated'},
    { itemName: "Created By", isHide: false, isCustom: false, columnKey:'created_by'},
    { itemName: "Create Date", isHide: false, isCustom: false, columnKey:'create_date'},
    { itemName: "Phase", isHide: false, isCustom: false, columnKey:'sprint',key:'sprint_id'}]
  selectedParentId:string='';
  appliedFilterSet:any;
  iSProcessing:any=false;
  @Input() listType: any;
  @Input() listName: any;
  @Input() connectToBoard: any;
  SingleBoardSelection:boolean=false;
  @Input() connectedItemIds: any[]= [];
  @Input() isUpdateList: any;
  @Input() selectedListId: any;

  constructor(private httpTransfer: HttpTransferService, private commonUtils: CommonUtils,
    public constantService: ConstantService,
    private dashboardUtils : DashboardUtilsService,
    private customStorageService : CustomStorageService,
    private compiler: Compiler, private injector: Injector,
    private cdr: ChangeDetectorRef,
    private commonUitls: CommonUtils,
    ) {

   }
   activityTypeKey = this.constantService.getActivityTypeKey();
   updateActivityTYpe = this.constantService.getUpdateActivityType();
   typeKey = this.constantService.getObjectTypeKey();
   leadObjectTYpe = this.constantService.getLeadObjectType();
   detailsKey = this.constantService.getDetailskEY();

   ngAfterViewInit(){
    this.searchSubtask.nativeElement?.focus()
   }

  async ngOnInit() {
    if(['Add Sub Item','Add Parent','Blocking','Waiting on'].includes(this.callType)){
      this.connectedBoardIds=[]
    }
    if(this.connectedBoardIds.length){
      this.callType=''
    }
    this.commonUtils.isDashboardReadOnly(this.dashId, this.currentLead?.lead_colloborators_users || [],this.currentLead).then((res : any) => { 
      this.isReadOnly = res;
    });
    this.dashAccess = await this.commonUtils.isLeadOrDashboardReadOnlyOrViewOny(this.dashId,this.currentLead)
    this.isDashboarUser = await this.dashboardUtils.isDashboardUser(this.customStorageService.getItem('id'), this.dashId);
    this.orgUsersJson = await this.dashboardUtils.getOrgUsers();
    await this.dashboardUtils.getDashboardCustomForms(this.connectedBoardIds.length? this.connectedBoardIds:[this.dashId]).then(res=>{
      let resDashId=this.connectedBoardIds.length? this.connectedBoardIds[0]:this.dashId
      this.arrayOfCustomFiled = res[resDashId].CUSTOM_FORM;
      this.arrayOfCurrentBoard= res[this.dashId].CUSTOM_FORM;
    })
    if(this.connectedBoardValuekey && this.arrayOfCurrentBoard?.length>0){
      this.SingleBoardSelection = this.arrayOfCurrentBoard.some(ele => ele?.key === this.connectedBoardValuekey && ele?.type === 'Board');
    }
    this.connectedBoardIds.length?this.getColumnsRequiredForConnectedBoard():this.getColumnsRequired()
    this.filterDataObj = {
      'dashboard_id':this.connectedBoardIds.length? this.connectedBoardIds :[this.dashId],
      grouping_details:{
        "group_by": "none",
        "max_group_size": 100,
        "start_index": 0,
        "sort_order": "ASC"
    },
    'bucket_type':['NON_FINAL'],
    search_params:{"search_text": ""},
    sort_params:[{"sort_by": "index","order": "DSC"}],
    }
    if(this.currentLead?.child_lead_id?.length || this.currentLead?.parent_id!=null || this.currentLead?._id){
      let obj=[]
      if(this.currentLead?.child_lead_id?.length){
        this.currentLead?.child_lead_id.forEach(ele=>{
          obj.push(ele)
        })
      }
      if(this.currentLead?.parent_id!=null) obj.push(this.currentLead?.parent_id)
      this.filterDataObj["exclude_item_id"]= obj
      obj.push(this.currentLead._id)
      this.totalCountLead=await this.getLeadCount(obj);
      if(this.totalCountLead<100){
        this.numberOfLeadsLoaded=this.totalCountLead
      }
      else{
        this.numberOfLeadsLoaded=100
      }
    }
    if(['Blocking','Waiting on'].includes(this.callType)){
      if(this.currentLead?.dependencies?.depends_on?.length>0){this.filterDataObj["exclude_item_id"]=[...this.filterDataObj["exclude_item_id"],...this.currentLead?.dependencies?.depends_on]}
      if(this.currentLead?.dependencies?.dependents?.length>0){this.filterDataObj["exclude_item_id"]=[...this.filterDataObj["exclude_item_id"],...this.currentLead?.dependencies?.dependents]}
    }
    if(this.connectedBoardIds.length && this.connectedBoardIdsList.length){
      this.filterDataObj["exclude_item_id"]= this.connectedBoardIdsList
    }
    if(this.noteId && this.connectedItemIds?.length>0) {
      this.filterDataObj["exclude_item_id"]= this.connectedItemIds
    }
    if(this.callType==='Add Sub Item'){this.filterDataObj["possible_child_for_item"]=this.currentLead._id}
    if(this.callType==='Add Parent'){this.filterDataObj["possible_parent_for_item"]=this.currentLead._id}
    this.appliedFilterSet=JSON.parse(JSON.stringify(this.filterDataObj))
    this.loadFilterDataObj();
    this.importGrid()
  }

  ngOnChanges(changes: any) {
  }

  addChildLead(childLeadId: string) {
    this.selectedListItem.emit(childLeadId)
  }

  async getLeadCount(obj?){
    let inputJson={
      "exclude_item_id":obj
    };
    let dash=this.connectedBoardIds.length? this.connectedBoardIds[0]:this.dashId
    let res=await this.httpTransfer.getLeadQuery(inputJson,dash).toPromise()
    return res?.result?.pagination_details?.total_records
  }

  scrollTO(id) {
    setTimeout(()=>{document.getElementById(id).scrollIntoView({behavior: "smooth",block: "start",inline: "nearest"});},10)
  } 
  
  loadFilterDataObj(){
    if(this.searchLeadList.length == 0)
      this.localFilterDataObj = this.commonUtils.createDefaultFilterDataObj()
  }

  searchItemList(event?) { 
    this.filterDataObj=this.commonUtils.cleanFilterDataObject(this.localFilterDataObj, this.arrayOfCustomFiled)
    let inputJson:any = this.commonUtils.getFilterJsonForQuery({}, this.filterDataObj);
    delete inputJson['apply_formula']
    inputJson['grouping_details']=this.appliedFilterSet?.grouping_details;
    inputJson['exclude_item_id']=this.appliedFilterSet?.exclude_item_id;
    inputJson['dashboard_id']=this.appliedFilterSet?.dashboard_id?this.appliedFilterSet?.dashboard_id:this.connectedBoardIds.length? this.connectedBoardIds[0] :this.dashId ;
    inputJson['possible_child_for_item']=this.appliedFilterSet?.possible_child_for_item;
    inputJson['possible_parent_for_item']=this.appliedFilterSet?.possible_parent_for_item;
   this.searchInput=inputJson['search_params']["search_text"]
    this.appliedFilterSet={...inputJson}
    console.log("applied filter",inputJson)
      this.gridViewRefSub?.gettingLeadDataForTableView(inputJson);
      this.cdr.detectChanges();
  }

  addBulkSubtasks(){
    this.selectedListItem.emit()
    // this.searchLeadList = []
  }

  closeTheModal(){
    this.showSubtaskFilter.emit(false)
  }

  async importGrid(){
    const { GridViewModule } =  await import("../../../grid-view/grid-view.module");
    const moduleFactory = await this.compiler.compileModuleAsync(GridViewModule);
    const moduleRef = moduleFactory.create(this.injector);
    const componentFactory = moduleRef.instance.getComponent();
    const { instance } = this.viewComponent.createComponent(componentFactory);   
    instance.viewType="subtaskView";
    instance.selectedDashId = this.connectedBoardIds.length? this.connectedBoardIds[0] :this.dashId 
    instance.itemGridReadOnly = false
    instance.filterDataObj = this.filterDataObj;
    this.gridViewRefSub=instance
    instance.viewTableColumnSeq = this.gridValues
    this.gridViewRef=instance;
    instance.singleSelection = this.callType==='Add Parent';

    this.itemBulkSubscription = instance.bulkSelectedSubLead.subscribe((item) =>{
      this.excludeInMultiselectGroup=item?.bulkLeadInfo;
      this.multiselectLeadIds=item?.excludeInMultiselectGroup 
      this.rowData=item?.data
      console.log("items",item)
      if(item?._id){this.selectedParentId=item?._id}
    });
    this.cdr?.detectChanges()
  }

  getDataOnScroll(){
    let table=this.gridViewRefSub?.tableData[0]
    if(table){
      let inputJson=JSON.parse(JSON.stringify(this.filterDataObj))
      delete inputJson['grouping_details']
      delete inputJson['apply_formula']
      let pagination = {};
      pagination["start_index"] = table.rowData.filter(item=>!item?.emptyRow)?.length;
      pagination["page_size"] = 100;
      inputJson["pagination_details"] = pagination;
      this.gridViewRefSub?.gettingLeadDataForTableView(inputJson,table);
      this.numberOfLeadsLoaded=this.totalCountLead;
      this.cdr.detectChanges();
    }
  }

  searchOnEnter(event?){
    this.filterDataObj=this.commonUtils.cleanFilterDataObject(this.localFilterDataObj, this.arrayOfCustomFiled)
    let inputJson:any = this.commonUtils.getFilterJsonForQuery({}, this.filterDataObj);
    delete inputJson['apply_formula']
    inputJson['grouping_details']=this.appliedFilterSet?.grouping_details;
    inputJson['dashboard_id']=this.appliedFilterSet?.dashboard_id?this.appliedFilterSet?.dashboard_id:this.connectedBoardIds.length? this.connectedBoardIds[0] :this.dashId ;
    inputJson['exclude_item_id']=this.appliedFilterSet?.exclude_item_id;
    inputJson['possible_child_for_item']=this.appliedFilterSet?.possible_child_for_item;
    inputJson['possible_parent_for_item']=this.appliedFilterSet?.possible_parent_for_item;
    if(this.searchInput!=null || this.searchInput!==''){
      inputJson['search_params']["search_text"]=this.searchInput;
    }
    this.appliedFilterSet={...inputJson}
    this.gridViewRefSub?.gettingLeadDataForTableView(inputJson);
    this.cdr.detectChanges();
  }

  // resetFilter(){
  //   let localFilterData=this.filterDataObj
  //   this.commonUtils.resetFilterDataObj(this.localFilterDataObj,this.arrayOfCustomFiled);
  //   this.localFilterDataObj = this.commonUtils.cleanFilterDataObject(this.localFilterDataObj,this.arrayOfCustomFiled);
  //   this.localFilterDataObj=this.commonUtils.createDefaultFilterDataObj();
  //   this.gridViewRefSub?.gettingLeadDataForTableView(localFilterData);
  //   this.cdr.detectChanges();
  // }

  resetFilter(){
    let localFilterData=this.filterDataObj
    localFilterData = this.commonUtils.createDefaultFilterDataObj();
    this.localFilterDataObj = localFilterData;
    this.searchItemList(null)
  }

  ngOnDestroy() {
    this.itemBulkSubscription?.unsubscribe();
    this.loadAllData?.unsubscribe()
  }

  addSubtask(){
    let inputJson={}
    let arrayOfLeads=[]
    this.excludeInMultiselectGroup.forEach(ele=>{
      arrayOfLeads.push(ele?._id)
    })
    this.iSProcessing=true
    console.log(this.activityInfo)
    if(this.activityInfo){
      let activityId =  this.activityInfo?.message_group_id ? this.activityInfo?.message_group_id :  this.activityInfo?._id
      let itemIds=(this.rowData?.selectedTable && !this.multiselectLeadIds.length) ? this.excludeInMultiselectGroup : (this.multiselectLeadIds.length>0 && this.rowData.selectedRow) ? this.multiselectLeadIds : arrayOfLeads
      if(this.activityInfo.updateType=='connectedItem'){
        // let  associateId=itemIds?.reduce((arr,id)=>{if(!this.activityInfo?.lead_id?.includes(id))arr?.push(id);return arr},[])
        let updatedList=[...(this.connectedBoardIdsList || []),...itemIds]
        let json={custom_fields:{[this.connectedBoardValuekey]:updatedList}}
        if(this.activityInfo.isCreate){
          this.selectedListItem.emit({newList:updatedList,updatedType:'ActivityConnectedItem'})
          itemIds?.forEach(itemId=>this.connectedBoardIdsList?.push(itemId))
          this.showSubtaskFilter.emit(false);
        }else{
          this.httpTransfer.updateCommentCorrespondingLead({activity_lead_obj:json},this.currentLead?.dashboard_id,this.currentLead?._id,activityId).subscribe((res : any) => {
            if(res.status==200){
              if(!this.activityInfo?.activity_lead_obj.custom_fields)this.activityInfo.activity_lead_obj.custom_fields={}
              this.activityInfo.activity_lead_obj.custom_fields[this.connectedBoardValuekey]=[...(this.connectedBoardIdsList || []),...itemIds]
              
            }
            this.showSubtaskFilter.emit(false)
            this.iSProcessing=false
          },err=>{ this.iSProcessing=false})
        }
      }else{
        let  associateId=itemIds?.reduce((arr,id)=>{if(!this.activityInfo?.lead_id?.includes(id))arr?.push(id);return arr},[])
        this.httpTransfer.updateCommentCorrespondingLead({associate_lead_id:[...(this.activityInfo?.lead_id || []),...associateId]},this.currentLead?.dashboard_id,this.currentLead?._id,activityId).subscribe((res : any) => {
          if(res.status==200){
            this.activityInfo.lead_id=[...(this.activityInfo?.lead_id || []),...associateId]
          }
          this.showSubtaskFilter.emit(false)
          this.iSProcessing=false
        },err=>{ this.iSProcessing=false})
      }
    }
    else if(!this.connectedBoardIds.length && !this.noteId && !this.listType){
      if(this.rowData?.selectedTable && !this.multiselectLeadIds.length){
        inputJson={
          "filter_properties":{
            "dashboard_id":this.connectedBoardIds.length? this.connectedBoardIds :[this.dashId],
          },
        }
      }
      else if(this.multiselectLeadIds.length>0 && this.rowData.selectedRow){
        inputJson={
          "filter_properties":{
            "dashboard_id":this.connectedBoardIds.length? this.connectedBoardIds :[this.dashId],
            "exclude_item_id":this.multiselectLeadIds
          },
        }
      }
      else{
        inputJson={
          "filter_properties":{
            "dashboard_id":this.connectedBoardIds.length? this.connectedBoardIds :[this.dashId],
            "lead_id":arrayOfLeads
          },
        }
      }
      inputJson['update_json']={
        "parent_id":this.currentLead._id
      }
      let dash=this.connectedBoardIds.length? this.connectedBoardIds[0] :this.dashId
      this.httpTransfer.updateMultipleDashboard(dash,inputJson).subscribe(res=>{
        if(res.status == 200){
          this.showSubtaskFilter.emit(false)
          this.iSProcessing=false
        }
      },err=>{ this.iSProcessing=false})
    }
    else if(this.noteId) {
      let inputJson = {
        "item_id": arrayOfLeads,
        'update_type':'APPEND'
      }
      this.httpTransfer.updateNote(this.noteId, inputJson).subscribe((res : any) => {
        if (res.status == 200) {
          this.showSubtaskFilter.emit(false);
          this.iSProcessing=false;
          this.getAllDashBoardNotes.emit(true);
        }

      },err=>{ this.iSProcessing=false})
    }else if(this.listType) {
      let inputJson = {
        "item_id": arrayOfLeads
      }
      if(this.isUpdateList) {
        inputJson["item_id_operation"] = "ADD";
        this.httpTransfer.updateBoardList(this.connectToBoard, this.selectedListId, inputJson).subscribe((res : any) => {
          if (res.status == 200) {
            this.showSubtaskFilter.emit(false);
            this.iSProcessing=false;
            this.resetValues.emit();
          }
  
        },err=>{ this.iSProcessing=false})
      }else{
        inputJson['name']= this.commonUtils.capitalizeFirstLetter(this.listName)
        inputJson['list_type']=this.listType.toUpperCase()
        inputJson["connected_board_id"]=this.dashId;
      this.httpTransfer.createBoardList(this.connectToBoard, inputJson).subscribe((res : any) => {
        if (res.status == 200) {
          this.showSubtaskFilter.emit(false);
          this.iSProcessing=false;
          this.resetValues.emit();
          this.getAllBoardLists.emit();
        }

      },err=>{ this.iSProcessing=false})
    }
    }
    else{
      let leadObj={}
      if(this.SingleBoardSelection){
        leadObj["custom_fields"]={[this.connectedBoardValuekey]:(this.rowData?.selectedTable && !this.multiselectLeadIds.length) ? this.excludeInMultiselectGroup : (this.multiselectLeadIds.length>0 && this.rowData.selectedRow) ? this.multiselectLeadIds : arrayOfLeads}
      }
      else{
        leadObj['item_updates']=[{
          'field_key':"custom_fields."+this.connectedBoardValuekey,
          'update_type':'APPEND',
          'value':(this.rowData?.selectedTable && !this.multiselectLeadIds.length) ? this.excludeInMultiselectGroup : (this.multiselectLeadIds.length>0 && this.rowData.selectedRow) ? this.multiselectLeadIds : arrayOfLeads
        }]
      }
      this.httpTransfer
        .updateLeadCorrespondingToLeadId(leadObj, this.dashId, this.currentLead._id)
        .subscribe((res : any) => {
          if (res.status === 200) {
            this.showSubtaskFilter.emit(false)
            this.iSProcessing=false
          }
          this.cdr.detectChanges();
        },err=>{ this.iSProcessing=false});
    }
  }
  async getColumnsRequired(){
    let viewsData={}
    let dash=this.connectedBoardIds.length?this.connectedBoardIds[0]:this.dashId
    viewsData=await this.dashboardUtils.getDashboardInformation(this.connectedBoardIds.length?[this.connectedBoardIds[0]]:[this.dashId])
    const dataArray = Object.keys(viewsData);
    dataArray.forEach((ele:any)=>{
      if(ele===dash){
        viewsData[ele]?.EXISTING_CUSTOM_FORM?.forEach(value=>{
          if(value?.hidden!=='ALWAYS'){
            let obj={columnKey:value.key,isHide : false,headerName:value?.display_key,isCustom:false}
            this.gridValues.push(obj);
          }
        })
      }
    })
    this.gridValues=[...this.gridValues,...this.arrayField]
    let mirrorColumnsOfMainBoard=[]
    this.arrayOfCustomFiled.forEach(ele=>{
      if(ele?.hidden!=='ALWAYS'){
        mirrorColumnsOfMainBoard.push(ele)
      }
    })
    mirrorColumnsOfMainBoard.forEach(ele=>{
        let obj={columnKey:ele.key,isHide : false,headerName:ele?.display_key,isCustom:true}
      this.gridValues.push(obj);
    })
    this.cdr?.detectChanges()
  }

  async getColumnsRequiredForConnectedBoard(){
    let viewsData={}
    let dash=this.connectedBoardIds.length?this.connectedBoardIds[0]:this.dashId
    viewsData=await this.dashboardUtils.getDashboardInformation(this.connectedBoardIds.length?[this.connectedBoardIds[0]]:[this.dashId])
    const dataArray = Object.keys(viewsData);
    let mirrorColumnsOfMainBoard=[]
    this.arrayOfCurrentBoard.forEach(field=>{
      if(field?.type == 'Mirror' &&  field.connected_board_id == this.connectedBoardIds[0] && field.connected_board_key == this.connectedBoardValuekey){
        let obj={[field.connected_board_column_key]:{display_key:field?.display_key}}
        mirrorColumnsOfMainBoard.push(obj)
      }
    })
    mirrorColumnsOfMainBoard?.forEach(columnKeys=>{
      Object.keys(columnKeys)?.forEach(columnKey=>{
        if(columnKey=='start_date' || columnKey=='end_date'){
          columnKey="start_end_date"
        }
        if(columnKey=='update_date'){
          columnKey='last_updated'
          // = this.mirrorColumnsOfMainBoard['update_date']
        }
        let obj={columnKey:columnKey,isHide : false,headerName:columnKeys[columnKey]?.display_key}
      this.gridValues.push(obj);
      })
    })
    dataArray.forEach((ele:any)=>{
      if(ele===dash){
        viewsData[ele]?.EXISTING_CUSTOM_FORM?.forEach(value=>{
          if(value?.hidden!=='ALWAYS' && (value?.key!=='title')){
            let obj={columnKey:value.key,isHide : false,headerName:value?.display_key,isCustom:false}
            this.gridValues.push(obj);
          }
        })
      }
    })
    this.gridValues=[...this.gridValues,...this.arrayField];
    this.cdr?.detectChanges()
  }

  addParentItem(){
    this.iSProcessing=true
    this.httpTransfer.updateLeadCorrespondingToLeadId({ parent_id: this.selectedParentId }, this.dashId, this.currentLead._id).subscribe((res : any) => {
      if (res.status === 200) {
        this.showSubtaskFilter.emit(false)
      }
      this.iSProcessing=false
      this.cdr.detectChanges();
    },err=>{ this.iSProcessing=false});
  }

  closeFilter(){
    this.localFilterDataObj= this.commonUitls.createDefaultFilterDataObj();
    this.commonUitls.resetFilterDataObj(this.localFilterDataObj,this.arrayOfCustomFiled)
    let localFilterDataObj =  this.commonUitls.converFilterObjAccordingToFilter(this.appliedFilterSet,this.arrayOfCustomFiled)
    this.localFilterDataObj = localFilterDataObj
    this.localFilterDataObj = structuredClone(this.localFilterDataObj);
  }

  dependency(){
    let arrayOfLeads=[]
    this.excludeInMultiselectGroup.forEach(ele=>{
      arrayOfLeads.push(ele?._id)
    })
    let itemIds=(this.rowData?.selectedTable && !this.multiselectLeadIds.length) ? this.excludeInMultiselectGroup : (this.multiselectLeadIds.length>0 && this.rowData.selectedRow) ? this.multiselectLeadIds : arrayOfLeads
    console.log('selected items',itemIds)
    this.selectedListItem.emit({ids:itemIds,'callType':this.callType});
    this.showSubtaskFilter.emit(false);
    this.iSProcessing=false
  }

}
