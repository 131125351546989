import {Pipe, PipeTransform} from '@angular/core';
import {QuillDeltaToHtmlConverter} from 'quill-delta-to-html';
import { CommonUtils } from '../services/CommonUtils.service';

 
@Pipe({
    name: 'quillDeltaText'
})
export class QuillDeltaTextPipe implements PipeTransform {
    constructor(
        private commonUtils: CommonUtils
    ){

    }

    transform(value:string) {
        try{
            var converter = new QuillDeltaToHtmlConverter(JSON.parse(value), {})
            let pureText =  this.commonUtils.extractTextFromHtmlContent(converter.convert());
            return pureText;
        }
        catch(e){
            console.log("error in quilldelta Pipe",value,e)
        }
        return ""
        
        
    }
}
 