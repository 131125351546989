<div style="width:300px;height:200px;overflow-y: auto;">
    <ng-container *ngIf="!columnFetchOptionEnable">
    <label>Autopopulate fields from your board</label>
    <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-bottom:5px;margin-top:10px;padding-bottom: 10px;border-bottom: 1px solid #d9d9d9;">
      <span class="predefineBox" (click)="getColumnsValue('bucket_id')">Bucket</span>
      <span class="predefineBox" (click)="getColumnsValue('priority')">Priority</span>
      <span class="predefineBox" (click)="getColumnsValue('phase')">Phase</span>
      <span class="predefineBox" (click)="getColumnsValue('assigned_to')">Assignee</span>
      <span class="predefineBox" (click)="getColumnsValue('title')">Title</span>
      <span class="predefineBox" (click)="getColumnsValue('start_date')">Start Date</span>
      <span class="predefineBox" (click)="getColumnsValue('end_date')">end Date</span>
      <span class="predefineBox" (click)="getColumnsValue('create_date')">Created Date</span>
      <span class="predefineBox" (click)="getColumnsValue('update_date')">Updated Time</span>
    </div>   
    <!--Custom Fields-->         
    <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;" *ngIf="arrOfCustomField?.length">
        <label class="w-100">Custom Fields</label>
        <ng-container *ngFor="let item of arrOfCustomField">
        <span class="predefineBox" *ngIf="item.type!='Board' && item.type!='Mirror' && item.type!='Textarea' && item.type!='Button'" (click)="getColumnsCheckForFetchOptions(item , true)">{{item?.display_key}}</span>
        </ng-container>
    </div> 
    <!--Mirror Columns-->
    <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;border-top: 1px solid #d9d9d9;" *ngIf="arrOfCustomField?.length">
        <label style="width:100%;margin-bottom: 0px;">Mirror Fields</label>
        <ng-container *ngFor="let fields of arrOfCustomField">
          <span class="predefineBox" *ngIf="fields.type=='Mirror' && fields.mirror_column_type!='Textarea' && fields.mirror_column_type!='Button'" (click)="selectedMirrorObj = fields;columnFetchOptionEnable = true;">{{fields?.display_key}}</span>
          </ng-container>
        </div>  
    </ng-container>
      <!--Fetch Options-->
      <div style="width:300px;height:200px;overflow-y: auto;" *ngIf="columnFetchOptionEnable">
        <div style="display:flex;column-gap: 10px;align-items: center;"> 
          <span class="backButton" (click)="columnFetchOptionEnable=false;algorithm=null;selectedFieldForFetch={};selectedMirrorObj={}">
            <img src="../../../assets/1x/angle-left-4d4d4d.svg" style="height: 14px;"/>
          </span>
          <span class="f-xl-m truncate-text">Select Options for {{selectedFieldForFetch?.display_key}} </span>
         </div>
        <div style="display:flex;align-items: center;margin-top:10px;">
          <input type="radio" id="html" name="fav_language" value="FETCH_FIRST" [(ngModel)]="algorithm">
          <label for="html" class="shifts f-l-r">Fetch first value</label>
        </div>
        <div style="display:flex;align-items: center;margin-top:10px;">
          <input type="radio" id="html" name="fav_language" value="FETCH_ALL" [(ngModel)]="algorithm">
          <label for="html" class="shifts f-l-r">Fetch all value</label>
        </div>
        <div style="display:flex;align-items: center;margin-top:10px;">
          <input type="radio" id="html" name="fav_language" value="FETCH_LAST" [(ngModel)]="algorithm">
          <label for="html" class="shifts f-l-r">Fetch last value</label><br>
        </div>
        <div style="display:flex;justify-content: right;align-items: center;margin-top: 10px;margin-right:15px;">
          <button class="btn Sendbtn" style="height:30px;" type="button" (click)="columnFetchOptionEnable=false;getColumnsValue(selectedFieldForFetch,true)">Save</button>
      </div> 
      </div>
</div> 