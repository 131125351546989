<div class="modalBodyArea">
  <div class="modalMiddleArea">
    <div class="boardSelection">
        <div style="display: flex;align-items: center;padding: 5px;" class="fontLabel w-100">{{'automation.boardName' | translate}}</div>
        <div class="w-100" style="display: flex;align-items: center;padding: 5px;">
          <ng-select [items]="dashBoardsList" class="w-100"  [virtualScroll]="true"
            placeholder="{{'placeholders.selectBoard' | translate}}" bindLabel="dashboard_name"
            [clearable]="false" bindLabel="dashboard_name" bindValue="dashboard_id"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedDashId" (ngModelChange)="getPossibleMapping()">
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div style="text-align:left;">{{item.dashboard_name}}
                <b *ngIf="folderArr[item.dashboard_folder_id]">({{folderArr[item.dashboard_folder_id].name}})</b>
              </div>
            </ng-template>
          </ng-select></div>
    </div>
    
    
    
    <div *ngIf="selectedDashId && mappingAreaLoaded && !newBucketArr?.length"  style="padding: 10px;">
      <span class="fontMessage">
        {{'leadOption.noBucketForMapping' | translate}}
      </span>
    </div>
    
    <div class="mappingFields" *ngIf="selectedDashId && mappingAreaLoaded && newBucketArr?.length">
      <div *ngIf="mappingArr?.length" class="mappingFieldBox">
        <div *ngFor="let field of mappingArr;let f=index">

          <div class="mappingBox">
            <div style="width: 44%;align-items: center;justify-content: center;display: flex;flex-direction: column;">
              <div class="boardNameText">{{currentDashBoardName}}</div>
              <div class="currentMapping">
  
                <span class="mr-2"><app-dashboard-icon [size]="16" [name]="field.type" [iconJson]="attributesIcons"></app-dashboard-icon></span>
                <span class="fontValue">{{field.display_key}}</span>
  
              </div>
            </div>
            <div style="width: 12%;align-items: center;justify-content: center;display: flex; padding-top: 20px;">
              <img src="../../../assets/1x/IconRightArrow.svg" style="height: 16px; width: 16px;"/>
            </div>
            <div style="width: 44%;align-items: center;justify-content: center;display: flex;flex-direction: column;">
              <div class="boardNameText">{{newDashBoardName}}</div>
                <ng-select class="w-100" bindLabel="display_key" bindValue="key" dropdownPosition="auto" [clearable]="true" 
                [(ngModel)]="field.mapping_key" (ngModelChange)="mappingChanged(field,f,$event)" (clear)="field.mapping_key=null;mappingChanged(field,f,$event)">
                    <ng-option *ngFor="let item of field.mappingOptions" [value]="item.key" 
                     [ngClass]="{'disableOption':mappedKeysArr.includes(item.key)}" [disabled]="mappedKeysArr.includes(item.key)">
                    {{item.display_key}}
                    </ng-option>
                 </ng-select>
            </div>
          </div> 
        </div>
      </div>
      <div *ngIf="!mappingArr?.length" style="padding: 10px;">
        <span class="fontMessage">
          {{'leadOption.noPossibleMapping' | translate}}
        </span>
      </div>
  
    </div>
  </div>
  <div class="modalBottomArea" *ngIf="selectedDashId && mappingAreaLoaded && newBucketArr?.length">
    <div style="width: 85%;display: flex;align-items: center;justify-content: flex-start;padding: 10px 15px;">
      <span class="fontFootNote"> {{'leadOption.customFieldMappingwarningMessage' | translate}} </span>
    </div>
    <div style="width: 15%;display: flex;align-items: center;justify-content: flex-end;padding: 10px 15px;">    
      <div class="copyItemButton" (click)="proceedToCopy();">
        <span >{{'editcustom.next' | translate}}</span>
      </div> 
    </div>
  </div>
</div>


