import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { ToasterService } from '../../../services/toaster.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ConstantService } from '../../../../Enums/Constant.service';
import { BasicUtils } from 'app/services/basicUtils.service';
import { KeyboardMonitorService } from 'app/services/keyboardMonitor.service';

@Component({
  selector: 'app-create-lead-modal',
  templateUrl: './create-lead-modal.component.html',
  styleUrls: ['./create-lead-modal.component.scss']
})
export class CreateLeadModalComponent implements OnInit {
  @Input() leadInfo: any;
  @Input() dashId: any;
  @Input() bucketSelect: any[];
  @Input() creatingFromlist: boolean;
  @Input() copyToOtherBoard:boolean = false;
  @Input() viewOnly:boolean=false;
  @Input() title: any;
  @Input() selectedStartDate: Date;
  @Input() selectedEndDate: Date;
  @Input() isTemplateCall:boolean=false;
  @Input() selectedTempIdForUpdate:string;
  @Input() isItemCopy:boolean = false;
  @Input() itemQueryType: string;
  @Input() subTaskCreation :boolean = false;
  @Input() sameParentCreation : boolean = false;
  @Input() leadIdForSubTask:any;
  @Input() movefromDashboardId:any
  @Input() moveLeadId:any;
  @Input() addItem:boolean=false;
  @Input() callFromDependency:boolean=false;
  @Output() createLeadModalCloseEvent = new EventEmitter<any>();
  @Output() newItemCreated = new EventEmitter<any>();
  @Output() emitCreatedItemId = new EventEmitter<any>();
  @Output() updateTemplateList=new EventEmitter();
  @Output() leadmovedToOtherBoard = new EventEmitter<any>();
  @ViewChild('createItemViewport', { static: false }) createItemViewport: ElementRef; 
  dependenciesObj: any = {};
  arrayOfBucket: any[];
  dashBoardSprint: any;
  dashTags: any[];
  isLeadCreated: boolean = false;
  templateName:string;
  isCreateAnother = false;
  customFieldsObj: any;
  leadId: any;
  createCopy: boolean = false;
  selectedFieldsCopy: {};
  createdItems: number = 0;
  dashboardUserRole: any;
  dashboardJsonSub: any;
  refreshCustomFields = true;
  showCreateTag: boolean = false;
  creatingLead:boolean=false
  itemAttributesObj: any;
  dashboardInfo: any;
  itemTerminology:any={}
  existingCustom:any
  movingLead:boolean=false
  itemConfig:any={};
  dependancy:any[]=[{'label':'Blocking','value':'dependents'},{'label':'Waiting on','value':'depends_on'}]
  isBlocking='dependents'


  constructor(private commonUtils:CommonUtils,private toastService : ToasterService, private httpTransfer:HttpTransferService, private dashboardUtils: DashboardUtilsService,public constantService : ConstantService,private basicUtils:BasicUtils) {}

  async ngOnInit() {
    if(this.isTemplateCall){this.isLeadCreated = true}
    if(this.dashId) this.listenToDashboardDataChanges();
    let dashboradData = await this.dashboardUtils.getDashboardInformation([this.dashId])
    this.dashboardInfo=dashboradData[this.dashId]?.DASHBOARD_INFO || {}
    this.dashboardUserRole = this.dashboardInfo?.role || [];
    this.existingCustom=dashboradData[this.dashId]['EXISTING_CUSTOM_FORM']
    this.itemTerminology=this.dashboardInfo?.item_terminology
    if(this.isTemplateCall){
      this.dashboardUtils.getAndSyncAllDashboardData([this.dashId],true,['Tags','Sprints','Buckets'])
    }
    if(this.dashId) {
      let existingCustomFields = await this.dashboardUtils.getDashboardExistingCustomFormIfPresent(this.dashId);
      if(existingCustomFields?.length)this.itemAttributesObj = this.commonUtils.handleExistingItemAttributes(existingCustomFields)
    }
    if(this.leadInfo && this.isTemplateCall)this.templateName=this.leadInfo?.template_name
    
    if(this.leadInfo) {
      let dummyleadInfo=JSON.parse(JSON.stringify(this.leadInfo))
      this.createCopy = true;
      if(!this.leadInfo?._id){
        await this.resetModalValues()
        Object.keys(dummyleadInfo).forEach(key=>{
          this.leadInfo[key] = dummyleadInfo[key]
        })
      }
      else{
        this.leadInfo =dummyleadInfo
      }
      this.isLeadCreated = !this.copyToOtherBoard ? true : this.isLeadCreated;
      this.setCreateleadJsonFromCopyLead(this.leadInfo);
    } else {
      this.resetModalValues();
    }
  }


  listenToDashboardDataChanges() {
    this.dashboardJsonSub = this.dashboardUtils.getUpdatedDashboardJson().subscribe((res : any) => {
      this.dashTags = res[this.dashId]?.TAG_MAP || [];
      this.arrayOfBucket = res[this.dashId]?.BUCKET || [];
      this.dashBoardSprint = res[this.dashId]?.SPRINT || []
    });
  }

  ngOnDestroy() {
    this.dashboardJsonSub?.unsubscribe();
  }
  
  setCreateleadJsonFromCopyLead(leadObj) {;
    this.leadId = leadObj['_id'];
    this.customFieldsObj = leadObj['custom_fields'];
    this.dependenciesObj = leadObj['dependencies'];
    this.title = leadObj['title'];
  }
  
  async createNewCustomLeadCorrToBucket() {
    // this.isLeadCreated = true;
    let payload = await this.createInputJsonOfItem();
    if(this.isTemplateCall){
     payload['item_type']="TEMPLATE"
     payload['template_name']= this.templateName
    }
    if(this.subTaskCreation || this.sameParentCreation){
      payload['parent_id']= this.leadIdForSubTask
    }
  if(!this.isTemplateCall && payload['bucket_id'] && payload['title'] || this.isTemplateCall && payload['bucket_id']){
    this.creatingLead=true;
    this.httpTransfer.createLeadCorrespondingToDashBoard(payload,this.dashId).subscribe((res : any) =>{
      if(res.status == 200) {
        this.emitCreatedItemId.emit(res.result.lead_id)
        if(!this.isCreateAnother) this.closeCreateLeadModal()
        this.creatingLead=false;
        if(this.isTemplateCall){
          this.toastService.Success("Template Created Successfully")
          this.closeCreateLeadModal()
        }
        // this.isLeadCreated = false;
        if(!this.isCreateAnother){
          if(this.callFromDependency){this.newItemCreated.emit({ids:[res.result.lead_id],type:this.isBlocking})}
          else{this.newItemCreated.emit(res.result.lead_id);}
        }
        if(this.isCreateAnother) {
          this.resetModalValues().then(()=>{
            this.populateSavedData();
          });
        }
      } else {
        this.creatingLead=false;
        // this.isLeadCreated = false;
      }
    }
    ,error=>{
      this.creatingLead=false;
    });
  }else{
    this.creatingLead=false;
  }
  }


  async moveleadToOtherBoard(){
    this.movingLead=true
    let payload = await this.createInputJsonOfItem();
    payload['new_dashboard_id']=this.dashId
    payload['index']={ position: 'first'}
    this.httpTransfer.updateLeadCorrespondingToLeadId(payload, this.movefromDashboardId, this.moveLeadId).subscribe((res : any) =>{
        if(res.status == 200) {
          this.movingLead=false;
          this.emitCreatedItemId.emit(res.result.lead_id)
          this.closeCreateLeadModal()
          this.leadmovedToOtherBoard.emit(true)

        } else {
          this.movingLead=false;
        }
      }
      ,error=>{
        this.movingLead=false;
      });
  
  }

  async createInputJsonOfItem() {
    let inputJson = {};
    Object.keys(this.leadInfo || {}).forEach(key=>{
      if(['assigned_to','bucket_id','tag_id','sprint_id','priority','start_date','end_date','lead_colloborators_users']?.includes(key)){
        if(this.isTemplateCall || (this.leadId && this.isItemCopy || !this.leadId) && this.leadInfo[key]){
          inputJson[key]=this.leadInfo[key]
        }
      }
    })
    inputJson['priority'] = parseInt(this.leadInfo['priority']);
    if(inputJson?.hasOwnProperty('tag_id') && inputJson['tag_id']===null){
      delete inputJson['tag_id']
    }
    if((this.leadId && this.isItemCopy || !this.leadId)){
      if(inputJson['start_date'] && !inputJson['start_date']?.date){
        delete inputJson['start_date']
      }
      if(inputJson['start_date'] && !inputJson['start_date']?.date){
        delete inputJson['start_date']
      }
    }

    this.handleCustomFieldsWhileCreatingLead()
    this.title ? inputJson['title'] = this.title : null
    this.dependenciesObj ? inputJson['dependencies'] = this.dependenciesObj.dependencies : null;
    this.customFieldsObj ? inputJson['custom_fields'] = this.customFieldsObj : null;
    // removing time and user id from customfields if it contains buttuns start
    if (inputJson['custom_fields']) {      
      for (const key in inputJson['custom_fields']) {
        if (inputJson['custom_fields'][key] && inputJson['custom_fields'][key]?.event) {
          delete inputJson['custom_fields'][key].time;
          delete inputJson['custom_fields'][key].user_id;
        }
      }      
    }
    this.selectedFieldsCopy = inputJson
    return inputJson;
  }

  handleCustomFieldsWhileCreatingLead() {
    if(this.customFieldsObj==null || this.customFieldsObj==undefined){
       this.customFieldsObj={}
    }
    let keys = Object.keys(this.customFieldsObj) || [];
    keys.forEach(key => {
      let valueInString=JSON.stringify(this.customFieldsObj[key] || '')
      if(['','null','undefined','[]','{}','[""]'].includes(valueInString) || this.customFieldsObj[key]?.length==0) {
        delete this.customFieldsObj[key]
      }
    })
  }

  async resetModalValues() {
    this.title = '';
    this.existingCustom?.forEach(ele=>{
      if(ele.key=='title' && ele.is_default_enabled){
        this.title=ele.default_value
      }
    })
    this.leadInfo={}
    let res =await this.commonUtils.getDataFromFilterObjectToCreateLead({dashboard_id:[this.dashId]},[], this.arrayOfBucket, this.dashboardUserRole)
    this.leadInfo=res[0]
    if(this.bucketSelect)this.leadInfo.bucket_id=this.bucketSelect
    this.customFieldsObj = {};
    this.dependenciesObj = {};
  }
  closeCreateLeadModal() {
    this.createLeadModalCloseEvent.emit();
  }

  populateSavedData() {
    Object.keys(this.selectedFieldsCopy || {}).forEach(key=>{
      if(this.leadId || !this.leadId && this.selectedFieldsCopy[key])this.leadInfo[key]=this.selectedFieldsCopy[key]
    })
    this.title =this.selectedFieldsCopy['title']
    this.dependenciesObj =this.selectedFieldsCopy['dependencies']
    this.customFieldsObj=this.selectedFieldsCopy['custom_fields']

    this.refreshCustomFields = false;
    setTimeout(()=>{this.refreshCustomFields = true;})
    this.createdItems = 1 + this.createdItems;
  }

  scrollTO(id) {
    let dependencyElementRef= this.createItemViewport?.nativeElement.querySelector('.dependency-field')
    if(dependencyElementRef){
      dependencyElementRef.scrollIntoView({behavior: "smooth",block: "start",inline: "nearest"})
    }

  }
  
  getPopOverRef(popRef){
    this.basicUtils.isPopoverOpen(popRef)
  }

  async updateTemplateCustomFields() {
    let payload = await this.createInputJsonOfItem();
    if (this.isTemplateCall) {
      payload['item_type'] = this.itemQueryType?this.itemQueryType:'TEMPLATE'
      if (this.templateName != this.leadInfo?.template_name) payload['template_name'] = this.templateName
    }
    this.creatingLead=true
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(payload, this.dashId, this.leadId)
      .subscribe((res : any) => {
        if (res.status === 200) {
          this.closeCreateLeadModal()
          this.updateTemplateList.emit()
        }
        this.creatingLead=false
      },err=>{
        this.creatingLead=false
      });
  }

  getBlockingLabel() {
    const foundDependency = this.dependancy?.find(dep => dep?.value === this.isBlocking);
    return foundDependency ? foundDependency.label : '';
  }
 }
