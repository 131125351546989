
<div class="leadListWrapper">
  <div *ngIf="!isGridEditor && showBoardName" class="float-left">{{'editcustom.label' | translate}} : <b>{{dashboardName|titlecase}}</b></div>
  <ng-container *ngIf="isLoader"> 
      <div class="d-flex justify-content-center align-items-center my-2">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">{{'editcustom.loading' | translate}}  </span>
        </div>
      </div> 
  </ng-container>

  <div *ngIf="!isLoader && itemsList.length>0" [ngClass]="{'subTaskList': showBoardName}">
    <ng-container *ngFor="let item of itemsList;let i=index">
       <div class="itemCardView row g-0 mx-0 w-100 border-1">

            <!----Upper Row List --->
            <div class="upperRowList row g-0 mx-0 w-100">
               <div class="column-left d-flex flex-direction-row align-items-center align-content-center justify-content-between">
                <div *ngIf="isBlocking != undefined && isBlocking === true" class="col-md-3 px-0 blockIngBy">{{'editcustom.blocking' | translate}}</div>
                <div *ngIf="isBlocking != undefined && isBlocking === false" class="col-md-3 px-0 blockIngBy">Waiting on</div>
                <div *ngIf="isDuplicateOf != undefined && isDuplicateOf === true" class="col-md-3 px-0 blockIngBy">{{'editcustom.duplicateOf' | translate}}</div>
                <div *ngIf="isDuplicateOf != undefined && isDuplicateOf === false" class="col-md-3 px-0 blockIngBy">{{'editcustom.duplicatedBy' | translate}}</div>
                <div class="mr-1 d-flex align-items-center">
                  <div [ngbPopover]="!item.isReadOnly && priorityTemplate"
                  #popPriorityTemplate="ngbPopover" placement="right" role="button"
                  [autoClose]="'outside'">
                  <span [style.background]="commonUtils?.priorityJson[item.priority]?.color || 'prioritylabelL'" class="prioLabel" >{{commonUtils?.priorityJson[item.priority]?.label}}</span>
                  </div>
                  <span class="leadTitle MontserratFont" (click)="$event.stopPropagation();itemSeqClicked(item,leadModalTemplateSecond)">#{{item.seq_id}}</span>
                  <ng-template #priorityTemplate>
                     <app-lead-priority (click)="$event.stopPropagation()"
                         [leadInfo]="[item]" [dashId]="dashId"
                          [bulkSelectionFlag]="false">
                     </app-lead-priority>
                  </ng-template>
                </div>
                <div class="mr-1">
                  <ng-container *ngIf="dashBoardSprint?.length>0">
                    <a class="phaseName" #leadSprintPopover="ngbPopover"
                        *ngIf="!item.sprint_id" role="button" placement ="auto"
                        [ngbPopover]="!item.isReadOnly && sprintTemplate"
                        (click)="$event.stopPropagation();" [autoClose]="'outside'">{{'editcustom.noPhase' | translate}}</a>
                </ng-container>
                  <ng-container *ngFor="let sprint of dashBoardSprint">
                    <ng-container *ngIf="sprint._id==item.sprint_id">
                      <div class="d-flex phaseBucketName" style="column-gap: 5px;align-items: center;" #leadSprintPopover="ngbPopover" role="button"
                      placement="auto" [ngbPopover]="!item.isReadOnly && sprintTemplate" [autoClose]="'outside'">
                      <app-dashboard-icon [size]="16" class="iconBox"  [name]="'Sprints'" ></app-dashboard-icon>
                        <span class="textStyleS">{{sprint.name  | truncate : 7}}</span>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-template #sprintTemplate>
                    <ng-container>
                        <app-lead-sprint (click)="$event.stopPropagation();"
                            [leadInfo]="[item]" [dashId]="dashId"
                            [bulkSelectionFlag]="false"
                            [dashBoardSprint]="dashBoardSprint"
                            [selectedPhase]="item.sprint_id"
                            >
                        </app-lead-sprint>
                    </ng-container>
                  </ng-template>
                </div>                  
                <div class="mr-1">
                  <ng-container *ngFor="let bucket of bucketArray">
                    <ng-container *ngIf="bucket._id==item.bucket_id">
                      <div class="d-flex phaseBucketName" style="column-gap: 5px;align-items: center;" placement="auto" [ngbPopover]="!item.isReadOnly && bucketTemplate" #popItemBucket="ngbPopover"
                       [autoClose]="'outside'" role="button">
                        <app-dashboard-icon [size]="16" class="iconBox" [name]="'Bucket'" ></app-dashboard-icon>
                        <span class="textStyleS">{{bucket.name}}</span>
                      </div>
                      <ng-template #bucketTemplate>
                        <ng-container>
                          <app-lead-bucket-change (click)="$event.stopPropagation();" [leadInfo]="[item]"
                            [dashId]="dashId" [bucketArray]="bucketArray"
                             [bulkSelectionFlag]="true"
                                >
                           </app-lead-bucket-change>
                         </ng-container>
                    </ng-template>
                    </ng-container>
                  </ng-container>  


                </div>  
               </div>
               <div class="column-right">
                <ng-container *ngIf='item.is_aging_time'>
                  <span class="ls-025x againgtxtLeft" style="color: #000 !important; font-size: 12px;" id="live-Counter" *ngIf="item.aging_time_in_seconds>0"><img
                          src="../../../assets/1x/agingTimeBlack.svg">{{item.aging_time_in_seconds | minutesPipe}} Left</span>
                  <span class="ls-025x againgtxtExceed" style="color: #FF544C !important; font-size: 12px;" id="live-Counter" *ngIf="item.aging_time_in_seconds<0"><img
                          src="../../../assets/1x/aging-icon.jpg">{{item.aging_time_in_seconds | minutesPipe}} Over-due</span>
              </ng-container>
              <div class="col-1 p-0 col-md-1" [ngbPopover]="showDeletePopover && deletePopOver" #delete="ngbPopover" placement="auto"
                #popOver="ngbPopover" [autoClose]="'outside'" (click)="!showDeletePopover && removeItem(item,i)">
                <span *ngIf="showRemoveIcon" class="px-0"><img src="assets/1x/close-gray.svg" alt="close" role="button"></span>
              </div>
              <!----subtask remove pop-up-->
                <ng-template #deletePopOver>
                  <div>
                    <span>{{'editcustom.areYouSureToRemove' | translate}}</span>
                    <button class="btn btn-success" (click)="removeItem(item,i);delete.close()">{{'multiSelect.yes' | translate}}</button>
                    <button class="btn btn-danger" (click)='delete.close()'>{{'multiSelect.no' | translate}}</button>
                  </div>
                  </ng-template>
              </div>
            </div>

            <!---Middle row----->
            <div class="middlerRowList row g-0 mx-0 w-100">
              <p class="leadDescription mb-0" *ngIf="!item.showFullTitle && !item.readMore">{{item.title | truncate:120}}
                <span (click)="item.readMore=true" class="readLessMoreBtnText" *ngIf="item.title.length>120" style="color: #2e4765; cursor: pointer;">
                  {{'editcustom.readMore' | translate}}</span> 
                  <img src="../../../assets/1x/editBlackIcon.svg" class="leadTitleEditIcon" *ngIf="!item.showFullTitle && !item.isReadOnly" (click)="openEditorForTitle(item)"/>
              </p>
              <p class="leadDescription mb-0" *ngIf="!item.showFullTitle && item.readMore">{{item.title}}
                <span (click)="item.readMore=false" class="readLessMoreBtnText" style="cursor: pointer; color: #2e4765;">{{'editcustom.readLess' | translate}}</span> 
                <img src="../../../assets/1x/editBlackIcon.svg" class="leadTitleEditIcon" *ngIf="!item.showFullTitle && !item.isReadOnly" (click)="openEditorForTitle(item)"/>
              </p>
              <textarea *ngIf="item.showFullTitle" (blur)="updateItemTitle(item)" [(ngModel)]="item.title"
                        style="border-radius: 4px;
                              border: 1px solid #e1e1e1;
                              width: 100%;">
                        {{item.title}}
              </textarea> 
            </div>

            <!-------Bottom Row list-->
            <div class="bottomRowList row g-0 mx-0 w-100">
              <div class="column-left">
                <!-- <div class="col-md-2 px-0"> -->
                   <img class="msgBox" src="../../../assets/1x/comment.svg" role="button" placement="auto" [ngbPopover]="!item.isReadOnly && leadCommentTemplate"
                   #commentPopOver="ngbPopover"
                   [autoClose]="'outside'"/>
                   <p class="mb-0 TextFontSize ">{{item.comment_count}}</p>
                   <ng-template #leadCommentTemplate>
                    <div class="commentPopver">
                      <app-chat-messages [dashId]="dashId"  [selectedLeadObj]="item" [currentActiveTab]="'commentTab'"></app-chat-messages>
                    </div>
                   </ng-template>
                <!-- </div> -->
              
                  <div class="d-flex pl-2" style="align-items: center;">
                      <img src="../../../assets/1x/calender-ico.svg" role="button" style="width: 16px; height: 16px;" 
                      [ngbPopover]="!item.isReadOnly && datePopover" popoverClass="datePickerPopover"
                   #popDate="ngbPopover" placement="auto"
                  [autoClose]="'outside'">
                      <span class="TextFontSize pl-2"> Start : {{item.start_date | dateCalcuate:"date"}}<br>
                      <span *ngIf="item.start_date?.is_time_added">{{item.start_date | dateCalcuate:"time"}}</span></span>
                      <span class="ml-4 TextFontSize"> End : {{item.end_date | dateCalcuate:"date"}}<br>
                      <span *ngIf="item.end_date?.is_time_added">{{item.end_date | dateCalcuate:"time"}}</span></span>                      
                  </div>
                    <ng-template #datePopover>
                      <div>
                          <app-lead-date-picker (click)="$event.stopPropagation()"
                          [selectedStartDateObj]="item?.start_date"
                          [selectedEndDateObj]="item?.end_date"
                              [selectedDashId]="dashId"
                              [leadInfo]="[item]"
                              (closeDateModalOnCancel)="popDate.close()">
                          </app-lead-date-picker>
                      </div>
                    </ng-template>
                                  
               </div>
               <div class="column-right">
                <div class="tableDiv" *ngIf="!isPublicLead" style="justify-content: end" (click)="$event.stopPropagation()">
                  <ngx-avatars class="mr-1 userNameS" [name]="orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'" [src]="orgUsersJson[item.assigned_to]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                    <span class="textStyleS" placement="auto" #assigneePopOver="ngbPopover" [ngbPopover]="!item.isReadOnly && assignUserTemplateParent" [autoClose]="'outside'">{{orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'}}</span>
                </div>

                <ng-template #assignUserTemplateParent>
                  <ng-container>
                      <app-lead-assign-user 
                          [assignedUser]="orgUsersJson[item?.assigned_to]"
                          [dashId]="dashId" [leadInfo]="[item]"
                          [bulkSelectionFlag]="false"
                          (close)="assigneePopOver.close()"
                          >
                      </app-lead-assign-user>
                  </ng-container>
              </ng-template>
              </div>
            </div>
       </div>
    </ng-container>
  </div> 
  <div *ngIf="!isLoader && itemsList.length==0 && itemListforBoard" class="w-100" style="align-items: center;justify-content: center;">
    <span style="color: #6d6e6f80 !important;justify-content: center;display: flex;">Select or Create Item in connected Board</span>
  </div>
</div>


<ng-template #leadModalTemplateSecond let-modal>
    <ng-container *ngIf='showLeadModal'>
        <div class="modal-content" id="leadModalInfoModal" tabindex="-1">
            <ng-container *ngIf="clickedItem">
                <item-full-popup
                [tabIndex]="tabIndex+1"
                [leadId]="clickedItem._id"
                [dashId]="clickedItem.dashboard_id"
                [previousItemSeqId]="currentLead?.seq_id"
                [previousItemDashboardId]="currentLead?.dashboard_id"
                [newModalRef]="itemModalRef"
                (emitCloseLeadModal)="modal.close()"
                (emitImageData)="onGettingImageData($event)"
                style="height: 100%;"
                [hideClosebtn]="false"
                [publicLead]="isPublicLead"
                >
                </item-full-popup>
            </ng-container>
        </div>
    </ng-container>
</ng-template>


<ng-template #addcustomlead let-modal>
  <app-create-lead-modal [dashId]="dashId" (emitCreatedItemId)="connectCreateItemTOCurrentItem($event)" (createLeadModalCloseEvent)="modal.dismiss();" style="height: 100%;">
  </app-create-lead-modal>
</ng-template>




