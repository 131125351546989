import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ConstantService } from "../../../../Enums/Constant.service";
import { HttpTransferService } from "../../../services/httpTransfer.service";

@Component({
  selector: "app-check-list",
  templateUrl: "./check-list.component.html",
  styleUrls: ["./check-list.component.scss"],
})
export class CheckListComponent implements OnInit {
  checkList: any[] = [];
  @Input("dashId") dashId: any;
  @Input("leadId") leadId: any;
  @Input() bucketInfo: any
  @Output("UpdateCount") UpdateCount = new EventEmitter<any>();
  addCheckText: string;
  checkListState: { NOT_COMPLETED: string; COMPLETED: string };
  isEdit: boolean = false;
  editIndex: any;

  constructor(
    private httpTransfer: HttpTransferService,
    private constantService: ConstantService
  ) {
    this.checkListState = this.constantService.getChecklistStateConstant();
  }

  ngOnInit() {
    this.getLeadChecklistItem();
  }
  addCheckListItem(ChecklistItem) {
    let checkListObj = {};
    if(ChecklistItem.trim() != '') {
      checkListObj["summary"] = ChecklistItem;
      checkListObj["state"] = this.checkListState.NOT_COMPLETED;
      this.checkList.push(checkListObj);
      this.updateCheckList({checklist:this.checkList});
    }
  }

  getLeadChecklistItem() {
    var leadIdList = [];
    leadIdList.push(this.leadId);
    var leadObjQuery = {};
    leadObjQuery["lead_id"] = leadIdList;
    this.httpTransfer
      .getLeadQuery(leadObjQuery, this.dashId)
      .subscribe((res : any) => {
        if (res.status === 200) {
          this.getUpdatedChecklist(res.result.leadResponse[0]);
          this.addCheckText = "";
        }
      });
  }
  getUpdatedChecklist(data) {
    this.checkList = [];
    data.checklist.forEach((item) => {
      this.checkList.push(item);
    });
  }
  updateState(selectedIndex) {
    this.checkList.forEach((item, index) => {
      if (selectedIndex == index) {
        if (item.state == this.checkListState.COMPLETED) {
          item["state"] = this.checkListState.NOT_COMPLETED;
        } else {
          item["state"] = this.checkListState.COMPLETED;
        }
      }
    });
    this.updateCheckList({checklist:this.checkList});
  }
  deleteCheckItem(selectedIndex) {
    this.checkList.forEach((item, index) => {
      if (selectedIndex == index) {
        this.checkList.splice(index, 1);
      }
    });
    this.updateCheckList({checklist:this.checkList});
  }
  updateCheckListCount() {
    this.UpdateCount.emit({'leadId':this.leadId,'bucket':this.bucketInfo});
  }
  editCheckItem(index) {
    this.editIndex = index
    this.isEdit = true;
  }

  updateCheckList(checkListPayload) {
      this.httpTransfer
      .updateLeadCorrespondingToLeadId(
        checkListPayload,
        this.dashId,
        this.leadId
      )
      .subscribe((res : any) => {
        if (res.status == 200) {
          this.getLeadChecklistItem();
          this.updateCheckListCount();
          this.isEdit = false;
        }
      });
  }
  updateCheckListEdit() {
    this.updateCheckList({checklist:this.checkList})
  }
}
