import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MqttmessagingService {

  private mqttMessageSubject = new Subject<any>();
  constructor() {}

  sendMqttMessage(wsMsg: any) {
    this.mqttMessageSubject.next(wsMsg);
  }

  clearMqttMessages() {
    this.mqttMessageSubject.next(null);
  }

  getMqttMessage(): Observable<any> {
    return this.mqttMessageSubject.asObservable();
  }
}
