import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtils } from '../../services/CommonUtils.service';
import { HttpTransferService } from '../../services/httpTransfer.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-list-children-lead',
  templateUrl: './list-children-lead.component.html',
  styleUrls: ['./list-children-lead.component.scss']
})
export class ListChildrenLeadComponent implements OnInit {

  @Input() dashId: string;
  @Input() parentLeadId: string;
  @Input() parentLead: any;
  @Input() dashBoardSprintArr: any
  childrenOfOpenedLead: any[] = [];
  showLoader: boolean = true;
  dashBuckets: any;
  @Input() users: any
  @Output() closeListChildrenModal = new EventEmitter<boolean>()

  constructor(
    private httpTransfer: HttpTransferService,
    private modalservice: NgbModal,
    private commonUtils : CommonUtils,
    private toaster: ToasterService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.getBucketsForDashboard();
  }

  getBucketsForDashboard() {
    this.httpTransfer.getBucketsCorrespondingToDahBoard(this.dashId).subscribe((res : any) => {
      if(res) {
        this.dashBuckets = res.result?.buckets || [];
        this.getChildrenOfSelectedLead();
      }
    })
  }

  getChildrenOfSelectedLead() {
    this.showLoader = true;
    let payloadObj = {};
    payloadObj['parent_id'] = [this.parentLeadId];
    this.httpTransfer.getLeadQuery(payloadObj, this.dashId).subscribe((res : any) => {
      if (res.status === 200) {
        this.childrenOfOpenedLead = res.result.leadResponse;
        this.childrenOfOpenedLead?.forEach(lead=> {
          this.dashBuckets?.forEach(bucket=> {
            if(lead.bucket_id === bucket._id) {
              lead.bucketName = bucket.name;
            }
          })
          lead.start_date = lead.start_date ? this.datePipe.transform(lead.start_date, 'yyyy/MM/dd') : '';
          lead.end_date = lead.end_date ? this.datePipe.transform(lead.end_date, 'yyyy/MM/dd') : '';
        })
        this.addUsersInLead();
      }
    })
  }

  addUsersInLead() {
    this.childrenOfOpenedLead.forEach(lead => {
      this.users.forEach(user => {
        if (lead.assigned_to === user.id) {
          lead.leadAssignedToUser = user;
        }
      })
    })

    this.showLoader = false;
  }

  deleteChildFromParentLead(childLeadId: string) {
    let payloadObj = { parent_id: '' };
    this.httpTransfer.updateLeadCorrespondingToLeadId(payloadObj, this.dashId, childLeadId).subscribe((res : any) => {
      if (res.status === 200) {
        const delIndex = this.childrenOfOpenedLead.findIndex(lead=> lead._id === childLeadId);        
        if(delIndex > -1) {          
          this.childrenOfOpenedLead.splice(delIndex, 1);
        }
        this.toaster.Success(res.result.message);
      }
    })
  }

  openAddChildrenLeadModal(modalContent: any) {
    this.closeListChildrenModal.emit(true);
    this.modalservice.open(modalContent, { size: 'md'});

  }

}
