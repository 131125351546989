
<div *ngIf="leadObj" class="row g-0 editleadcustominputsrow" style="padding: 10px;" id="{{'sub-task'+leadObj?._id}}">
<ng-container *ngFor="let selectedBoardList of nonEmbeddedListArray;let tabIndex=index">
  <div style="padding-bottom: 10px;">
    <div class="d-flex mx-0 w-100" >
      <label class="fieldLabelBox f-s-r m-0" style="width: 25%;">
        <app-dashboard-icon [size]="14" [name]="'Board'" class="labelimg"></app-dashboard-icon>
        <span>{{selectedBoardList?.display_key}}</span>
      </label>
      <div class="d-flex align-items-center flex-grow-1" [ngClass]="{'beforeSearch':!addSubTask,'afterSearch':addSubTask}" style="width: 75%;">
        <div *ngIf="!isReadOnly" style="width: 100%;">
          <div style="display: flex;justify-content: end;">
            <button *ngIf="!isReadOnly && !isPublicLead && ((selectedBoardList?.type!='Board') || (selectedBoardList?.type=='Board' && selectedBoardList?.connecteBoardIds?.length==0))" class="addCreateConnectBtn btn py-0 key-tab" tabindex="0" (click)="addSubTask ? addSubTask=false : addSubTask=true; setquickSubTask=false; searchLeadList=[];showConnectedBoardFilter?.emit({type:' ',item:selectedBoardList,connectedBoardId:selectedBoardList?.connected_board_id,connectedItemsIds:selectedBoardList?.connecteBoardIds,isCreate:(leadId ? false : true)})">
              <i [ngClass]="{'color:gray':isFinalBucket}" class="fa fa-search" style="color:black;padding-right: 2px;"></i>
              <span *ngIf="selectedBoardList?.table_view !== false">{{'editcustom.searchLeads' | translate :{ term: 'Items' | terminology: item?.itemTerminology?.plural : 'Items' } }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 px-0">
      <ng-container *ngIf="!['CONTACT','ORGANIZATION']?.includes(dashboardInfo[this.selectedBoardList.connected_board_id]?.DASHBOARD_INFO?.board_type);else contactTypeBoard">
        <ng-container *ngIf="selectedBoardList?.table_view !== false; else itemList">
          <app-item-list-grid                 
          [tabIndex]="tabIndex" 
          [dashId]="selectedBoardList.connected_board_id" 
          [showBoardName]="false" 
          [showHiddenFields]="showHiddenFields?.ishidden"
          [connectedKey]="selectedBoardList.key"
          [isCurrentBoardReadonly]="isReadOnlyField(selectedBoardList.locked)"
          [customFieldsOfMainBoard]="dashBoardCustArr"
          (itemRemove)="removeItemFromBoard(selectedBoardList, $event, selectedBoardList?.type ,selectedBoardList.key)" 
          (itemCreate)="setItemToBoard(selectedBoardList,$event?.item, selectedBoardList?.type ,selectedBoardList.key,$event?.index)" 
          (itemUpdate)="updateItemToBoard(selectedBoardList,$event,selectedBoardList?.type,selectedBoardList.key)"
          [currentLead]="leadObj"
          [fieldInfo]="{fieldName:selectedBoardList?.display_key,fieldType:selectedBoardList?.type}"
          [boardConnectedItemIds]="selectedBoardList?.connecteBoardIds"
          (onItemOpen)="onItemOpen?.emit($event)"
          (onExpendTable)="onItemOpen?.emit($event)"
          (gridLoadedCheck)="isGridLoaded($event)"
          class="w-100"
          ></app-item-list-grid>
        </ng-container>
        <ng-template #itemList > 
          <div class="row g-0 mx-0 w-100 mediaBox">
              <app-item-list [itemIdList]="selectedBoardList.boardInput" [tabIndex]="tabIndex" [dashId]="selectedBoardList.connected_board_id" 
                  [showBoardName]="false" [itemListforBoard]="true" (itemRemove)="removeItemFromBoard(item, $event, selectedBoardList?.type ,item.key)" 
                  [currentLead]="leadObj" style="width: 100%;" (onItemOpen)="onItemOpen?.emit($event)"></app-item-list>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #contactTypeBoard>
        <app-contact-and-organization-board class="w-100" [dashId]="selectedBoardList?.connected_board_id" (onItemOpen)="onItemOpen?.emit($event)" [connectedKey]="selectedBoardList?.key" [currentLead]="leadObj" [boardConnectedItemIds]="selectedBoardList.connecteBoardIds" (updateConnectBoard)="selectedBoardList.connecteBoardIds=$event;onValueInput()"></app-contact-and-organization-board>
      </ng-template>
    </div>
  </div>
</ng-container>
</div>
