import { CustomStorageService } from './services/custom-storage.service';
import { HttpTransferService } from './services/httpTransfer.service';
import { ConstantService } from "./../Enums/Constant.service";
import { Injectable } from "@angular/core";
import { CommonUtils } from './services/CommonUtils.service';
import { DashboardUtilsService } from './services/dashboard-utils.service';
import { MessageService } from './message.service';

@Injectable({
  providedIn: "root",
})
export class SortingutilsService {
  
  rangeFilterTypes = ["Numeric", "Date", "Percentage","Rating"];
  absoluteValueFilterTypes = ["Dropdown", "Radiobutton", "Checkbox", "People", "MultiSelect","Board","Country","MultiUrl", "Url", "Text", "Email", "Textarea","SingleUser","MultiUser"];
  jsonValueFilterType=["Mobile"]
  leadData:any=[]
  totalRecords:any=null

  constructor(private constantService: ConstantService,
    private customStorageService: CustomStorageService,
    private httpService:HttpTransferService,
    private commonUtils: CommonUtils,
    private dashboardUtils: DashboardUtilsService,
    private msgservice: MessageService) {}


  async getIndexForNewLead(
    newLeadInfo,
    filterObjData,
    loadedLeads,
    dashId,
    isLeadPassForBothFilter=false
  ) {
    this.leadData=[newLeadInfo]
    this.totalRecords=null;
    let leadIndex = -1; 
    if(loadedLeads) {
      let leadsLoaded = loadedLeads.slice(0);
      leadsLoaded = leadsLoaded.filter(e=>e._id!==newLeadInfo._id);
      if(await this.isNewLeadPassFilter(newLeadInfo,filterObjData,isLeadPassForBothFilter)) {
        console.log(filterObjData,'filterObjData');
        if (filterObjData?.sort_params?.length>0) {
          let sortingType = filterObjData["sort_params"][0];
          let leadKey=!(sortingType["sort_by"]).includes('custom_fields.') ? this.constantService.constant.sortByOptions[sortingType["sort_by"]]?.lead_key : sortingType["sort_by"]
          leadIndex = await this.getIndexAfterkeySorting(leadsLoaded,newLeadInfo,leadKey,filterObjData);
        }else{
          leadIndex = 0;
        }
      }
    } 
    this.msgservice.setItemPopupInfo({index:leadIndex<loadedLeads?.length || leadIndex<0 ? leadIndex : loadedLeads.length ,itemInfo:newLeadInfo})
    


    return {index: leadIndex<loadedLeads?.length || leadIndex<0 ? leadIndex : loadedLeads.length  , actualIndex : leadIndex ,  leadsData:this.leadData ,totalRecords:this.totalRecords}
    // return leadIndex;
  }

 async isNewLeadPassFilter(newLeadInfo,filterObjData,isLeadPassForBothFilter=false) {
    let filterObj = {};
    let loggedInUserId=this.customStorageService.getItem('id')
    let advancedFilterEnable=false
    let hierarchyEnable = false
    if("and" in filterObjData || "or" in filterObjData){
      let arr= filterObjData?.and ? filterObjData?.and : filterObjData?.or
      hierarchyEnable= arr.find(element=>element['key']=='parent_id')
      if(hierarchyEnable){
        let newJson=arr.find(element=>('and' in element || 'or' in element))
        if(newJson?.and || newJson?.or){
          advancedFilterEnable=true
        }
      }
      else{
        advancedFilterEnable=true
      }

    }
    let restrictedPassed= await this.checkIfLeadPassRestrictedFilter(newLeadInfo)
    if(!restrictedPassed){
      return false
    }
    if(newLeadInfo.is_deleted){
      return false
    }
    if(!advancedFilterEnable || isLeadPassForBothFilter){
      if("search_params" in filterObjData && filterObjData.search_params?.search_text.length>1) {
        filterObj['search_to'] = false;
        if(newLeadInfo.title?.search(new RegExp(filterObjData["search_params"]["search_text"], "i"))>=0)  filterObj['search_to'] = true;
        if(newLeadInfo["custom_fields"] &&  JSON.stringify(Object.values(newLeadInfo["custom_fields"]) || []).search(new RegExp(filterObjData["search_params"]["search_text"], "i"))>=0) filterObj['search_to'] = true;
  
        if(filterObjData.search_params?.search_text.startsWith("#")){
          let number = Number.parseInt(filterObjData.search_params?.search_text.substring(1))
          if(number && number>0){
            if(newLeadInfo.seq_id === number){
              filterObj['search_to'] = true;
            }
          }
        }
      }
      if("dashboard_id" in filterObjData && filterObjData.dashboard_id.length>0) {
        filterObj['dashboard_id'] = false;
        filterObjData["dashboard_id"].forEach(element => {
          if(element === newLeadInfo.dashboard_id) {
            filterObj['dashboard_id'] = true;
          }
        });
      }
  
      if("assigned_to" in filterObjData && filterObjData.assigned_to.length>0) {
        filterObj['assigned_to'] = false;
        filterObjData["assigned_to"].forEach(element => {
          if(element === newLeadInfo.assigned_to) {
            filterObj['assigned_to'] = true;
          }
          else if(element==='{{EVAL_LOGGED_IN_USER}}' && newLeadInfo.assigned_to===loggedInUserId){
            filterObj['assigned_to'] = true;
          }
        });
      }
      if("assigned_by_user" in filterObjData && filterObjData.assigned_by_user.length>0) {
        filterObj['assigned_by_user'] = false;
        filterObjData["assigned_by_user"].forEach(element => {
          if(newLeadInfo?.assigned_by_user.includes(element)) {
            filterObj['assigned_by_user'] = true;
          }
          else if(element==='{{EVAL_LOGGED_IN_USER}}' && newLeadInfo?.assigned_by_user.includes(loggedInUserId)){
            filterObj['assigned_by_user'] = true;
          }
        });
      }
      if ("created_by" in filterObjData && filterObjData.created_by.length > 0) {
        filterObj['created_by'] = false;
        filterObjData["created_by"].forEach(element => {
          if (element === newLeadInfo.created_by) {
            filterObj['created_by'] = true;
          }
          else if(element==='{{EVAL_LOGGED_IN_USER}}' && newLeadInfo.created_by===loggedInUserId){
            filterObj['created_by'] = true;
          }
        });
      }
      if("bucket_id" in filterObjData && filterObjData.bucket_id.length>0) {
        filterObj['bucket_id'] = false;
        filterObjData["bucket_id"].forEach(element => {
          if(element === newLeadInfo.bucket_id) {
            filterObj['bucket_id'] = true;
          }
        });
      }
      if("lead_id" in filterObjData && filterObjData.lead_id.length>0) {
        filterObj['lead_id'] = false;
        filterObjData["lead_id"].forEach(element => {
          if(element === newLeadInfo._id) {
            filterObj['lead_id'] = true;
          }
        });
      }
      if("priority" in filterObjData && filterObjData.priority.length>0) {
        filterObj['priority'] = false;
        filterObjData["priority"].forEach(element => {
          if(element === parseInt(newLeadInfo.priority)) {
            filterObj['priority'] = true;
          }
        });
      }
      if("tag_id" in filterObjData && filterObjData.tag_id.length>0) {
        filterObj['tag_id'] = false;
        newLeadInfo.tag_id.forEach(element => {
          filterObjData["tag_id"].forEach(tag => {
            if(element === tag) {
              filterObj['tag_id'] = true;
            }
          });
        });
      }
       if("last_updated" in filterObjData && (filterObjData['last_updated']['from'] || filterObjData['last_updated']['to'])) {
        filterObj['last_updated'] = this.checkIfValueIsInRange(newLeadInfo.updated_time,filterObjData['last_updated']['from'],filterObjData['last_updated']['to']);
       }
       if("create_date" in filterObjData && (filterObjData['create_date']['from'] || filterObjData['create_date']['to'])) {
        filterObj['create_date'] = this.checkIfValueIsInRange(newLeadInfo?.create_date,filterObjData['create_date']['from'],filterObjData['create_date']['to'])
       }
       if("start_date" in filterObjData && (filterObjData['start_date']['isNull'])) {
        filterObj['start_date'] = false;
        if(!newLeadInfo?.start_date?.date) {
          filterObj['start_date'] = true;
        }
       }
       if("start_date" in filterObjData && (filterObjData['start_date']['from'] || filterObjData['start_date']['to'])) {
        filterObj['start_date'] = this.checkIfValueIsInRange(newLeadInfo?.start_date,filterObjData['start_date']['from'],filterObjData['start_date']['to']);
       }
       if("due_date" in filterObjData && (filterObjData['due_date']['isNull'])) {
        filterObj['due_date'] = false;
        if(!newLeadInfo.end_date?.date) {
          filterObj['due_date'] = true;
        }
       }
       if("due_date" in filterObjData && (filterObjData['due_date']['from'] || filterObjData['due_date']['to'])) {
        filterObj['due_date'] = this.checkIfValueIsInRange(newLeadInfo.end_date,filterObjData['due_date']['from'],filterObjData['due_date']['to']);;
       }
  
      if ("sprint_id" in filterObjData && filterObjData.sprint_id?.length > 0) {
        filterObj['sprint_id'] = false;
        filterObjData["sprint_id"].forEach(id => {
          if (newLeadInfo.sprint_id === id) {
            filterObj['sprint_id'] = true;
          }
          else if(id==='{{EVAL_IN_PROGRESS_SPRINT}}'){
            let sprintArray = this.dashboardUtils.getDashboardSprintIfPresent(newLeadInfo.dashboard_id) || []
            for(let sprint of sprintArray){
              if(newLeadInfo.sprint_id === sprint._id && sprint.current_status=='STARTED'){
                filterObj['sprint_id'] = true;
              }
            }
          }
        });
      }
      else{
        let sprintArray = this.dashboardUtils.getDashboardSprintIfPresent(newLeadInfo.dashboard_id) || []
        let hiddenAndArchivedSprintIds = []
        for(let sprint of sprintArray){
          if( sprint.is_archived){
            hiddenAndArchivedSprintIds.push(sprint._id)
          }
        }
        if(hiddenAndArchivedSprintIds.includes(newLeadInfo.sprint_id)){
          filterObj['sprint_id'] = false;
        }
      }
  
      if ("lead_colloborators_users" in filterObjData && filterObjData.lead_colloborators_users.length > 0) {
        filterObj['lead_colloborators_users'] = false;
        filterObjData["lead_colloborators_users"].forEach(id => {
          if (newLeadInfo.lead_colloborators_users.includes(id)) {
            filterObj['lead_colloborators_users'] = true;
          }
          else if(id==='{{EVAL_LOGGED_IN_USER}}' && newLeadInfo.lead_colloborators_users.includes(loggedInUserId)){
            filterObj['lead_colloborators_users'] = true;
          }
        });
      }
      if ("have_external_collaborators" in filterObjData && filterObjData.have_external_collaborators) {
        filterObj['have_external_collaborators'] = false;
        if (newLeadInfo.external_collaborator_user_ids.length>0) {
          filterObj['have_external_collaborators'] = true;
        }
      }
      if ("deadline_over" in filterObjData && filterObjData.deadline_over) {
        filterObj['deadline_over'] = false;
        if (newLeadInfo?.aging_time  && newLeadInfo?.aging_time < new Date().getTime()) {
          filterObj['deadline_over'] = true;
        }
      }
      if ("item_type" in filterObjData && filterObjData.item_type.length>0) {
        filterObj['item_type'] = false;
        if (filterObjData.item_type.includes(newLeadInfo?.item_type)) {
          filterObj['item_type'] = true;
        }
      }
      if ("starred" in filterObjData && filterObjData.starred.length>0) {
        filterObj['starred'] = false;
        if (newLeadInfo.isStarred) {
          if (newLeadInfo.starred.map(el => el.user_id == this.customStorageService.getItem('id')).length > 0) {
            filterObj['starred'] = true;
          }
        }
      }
      if("mentions" in filterObjData && filterObjData.mentions.status.length>0) {
        filterObj['mentions'] = false;
        if(filterObjData["mentions"]['status'] == 'UNREAD') {
          if(newLeadInfo.mentions.map(el => el.user_id == this.customStorageService.getItem('id'))?.length>0) {
            filterObj['mentions'] = true;
          }
        }
      }

      if ("is_deleted" in filterObjData && filterObjData.is_deleted.length>0) {
        filterObj['is_deleted'] = false;
        filterObjData["is_deleted"].forEach(id => {
          if (newLeadInfo.is_temp_deleted === id ) {
            filterObj['is_deleted'] = true;
          }
        });
      }
      else{
        if (newLeadInfo.is_temp_deleted === true) {
          filterObj['is_deleted'] = false;
        }
      }
  
      if ("survey_form_id" in filterObjData && filterObjData.survey_form_id.length > 0) {
        filterObj['survey_form_id'] = false;
        filterObjData["survey_form_id"].forEach(id => {
          if (newLeadInfo?.survey_form_id.includes(id)) {
            filterObj['survey_form_id'] = true;
          }
        });
      }
  
      if ("bucket_type" in filterObjData && filterObjData.bucket_type.length > 0) {
        filterObj['bucket_type'] = false;
        let bucketArray = this.dashboardUtils.getDashboardBucketIfPresent(newLeadInfo.dashboard_id)
        if(!bucketArray || bucketArray.length ===0){
          filterObj['bucket_type'] = true;
        }else{
        filterObjData["bucket_type"].forEach(id => {
          if(id === 'FINAL'){
            bucketArray.forEach((buckJson) =>{
              if(newLeadInfo.bucket_id === buckJson._id && buckJson.is_destination){
                filterObj['bucket_type'] = true;
              }
            })
          }
          else if(id === 'NON_FINAL'){
            bucketArray.forEach((buckJson) =>{
              if(newLeadInfo.bucket_id === buckJson._id && !buckJson.is_destination){
                filterObj['bucket_type'] = true;
              }
            })
          }
        });
      }
      }

      if ("action_on_me" in filterObjData) {
        filterObj['action_on_me'] = false;
        if (this.customStorageService.getItem('id') === newLeadInfo.assigned_to) {
          filterObj['action_on_me'] = true;
        }
        if (newLeadInfo.unread_tagged_count.map(el => el.user_id == this.customStorageService.getItem('id'))?.length > 0) {
          filterObj['action_on_me'] = true;
        }
      }
      // on hierarchy filter apply
      if ( "and" in filterObjData && hierarchyEnable) {
        filterObj['and'] = false;
        filterObjData['and']?.forEach(field=>{
          // for sub task only
          if(field.key=="parent_id" && field?.operator=="IS_NOT_EMPTY" && newLeadInfo?.parent_id){
            filterObj['and'] = true;
          }
          // for parent only
          if(field.key=="parent_id" && field?.operator=="IS_EMPTY" && !newLeadInfo?.parent_id){
            filterObj['and'] = true;
          }
  
        })
  
      }
  
   
  
      let isLeadPassed = this.isNewLeadPassCustomeFilter(newLeadInfo,filterObjData)
      //To add custom field filter like above
       
       for (const key in filterObj) {
         if(!filterObj[key]) {
          isLeadPassed = false;
         }
        }
        if(newLeadInfo.item_type  === 'TEMPLATE' || newLeadInfo.item_type  === 'TEMPLATE_SHARED') isLeadPassed = false;
        if(isLeadPassed && isLeadPassForBothFilter){
          let ischeckAdvanceFilter= await this.checkIfLeadPassedAdvancedFilter(newLeadInfo,filterObjData)
          isLeadPassed = (ischeckAdvanceFilter!=undefined && ischeckAdvanceFilter!=null) ? ischeckAdvanceFilter ? true : false  : isLeadPassed
        }
          return isLeadPassed;

    }
    else if(advancedFilterEnable){
      let isLeadPassed= this.checkIfLeadPassedAdvancedFilter(newLeadInfo,filterObjData)
      if(isLeadPassed!=undefined && isLeadPassed!=null){
        return isLeadPassed;
      }
    }

  }

  async checkIfLeadPassedAdvancedFilter(newLeadInfo,filterObjData){

    // if("dashboard_id" in filterObjData && filterObjData.dashboard_id.length>0){

    //   if(filterObjData.dashboard_id[0]==newLeadInfo?.dashboard_id){
      let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(newLeadInfo?.dashboard_id)
        let havePhase= dashboardJson[newLeadInfo?.dashboard_id]['SPRINT'].length ? true : false
        let jsonlistForAdvanceFilter= this.commonUtils.getNonCustomFields(havePhase)
        let customTypeArr = JSON.parse(JSON.stringify(dashboardJson[newLeadInfo?.dashboard_id]['CUSTOM_FORM'])); 

        jsonlistForAdvanceFilter=await this.commonUtils.makeCustomArrForAdvancedFilter(customTypeArr,jsonlistForAdvanceFilter)
        let booleanTypeJson=this.commonUtils.getBooleanTypeFields()
        let newjsonlistForAdvanceFilter= Object.assign({}, jsonlistForAdvanceFilter, booleanTypeJson);
        
        let arr=filterObjData?.and ? filterObjData?.and : filterObjData?.or
        let operator= filterObjData.and ? 'and' : 'or'
        let isLeadPassed=await this.checkRecursivelyForAdvancedFilter(newLeadInfo,arr,newjsonlistForAdvanceFilter,operator)
        return isLeadPassed
    //   }
    // }
  }

  async checkRecursivelyForAdvancedFilter(newLeadInfo,filterArr,newjsonlistForAdvanceFilter,operator){
    for(let i=0; i<filterArr.length; i++){
      let ele=filterArr[i]
      if(((ele?.and && ele?.and?.length) || (ele?.or && ele?.or?.length))){
        let arr=ele?.and ? ele?.and : ele?.or
        let op= ele.and ? 'and' : 'or'
        let isPassedGroup=await this.checkRecursivelyForAdvancedFilter(newLeadInfo,arr,newjsonlistForAdvanceFilter,op)
        if(operator=='and' && !isPassedGroup){
          return isPassedGroup
        }
        else if(operator=='or' && isPassedGroup){
          return isPassedGroup
        }
      }
      else if(ele?.key){
        let field=newjsonlistForAdvanceFilter[ele?.key]
        let isPassed=false

        if(field.type=="Boolean" || field.type=="ItemType" || field.type=="ExternalCollaborator"){
          // for deleted 
          isPassed = this.isPassedBoolean(ele, isPassed, newLeadInfo); 
        }



       // for tag type
       else if(field.type=="Tags" ){
        isPassed = this.isPassedTags(ele, isPassed, newLeadInfo);
      }

      else if(field.type=="Priority" || field.type=="Bucket" ){
        isPassed = this.isPassedPriorityBucket(ele, newLeadInfo, isPassed);
      }

      else if(field.type=="Sprint"){
        isPassed = this.isPassedSprint(newLeadInfo, ele, isPassed);
      }

        else if(field?.type=='Text' || field?.type=='Textarea' || field?.type=='Email' || field?.type=='Url' || field?.type=='IPAddress'){
          isPassed = this.isPassedTextEmailURLIP(ele, newLeadInfo, isPassed);
        }

        else if(field?.type=='Checkbox' || field?.type=='MultiSelect' || field?.type=='Dropdown' || field?.type=='Radiobutton' || field?.type=='Country'  || field?.type=='Board'){
          isPassed = this.isPassedCheckMultiRadioDropCountry(ele, isPassed, newLeadInfo, field);
        }

        else if(field?.type=='MobileCode' || field?.type=='MobileNumber' || field?.type=='CurrencyCode' || field?.type=='CurrencyAmount'){
          isPassed = this.isPassedCurrencyMobile(ele, isPassed, newLeadInfo);   
        }

        else if(field?.type=='Numeric' || field?.type=='Rating'){
          isPassed = this.isPassedNumericRatings(ele, isPassed, newLeadInfo);   
        }

        else if(field?.type=='SingleUser' || field?.type=='MultiUser'){
          isPassed = this.isPassedUserType(ele, newLeadInfo, isPassed, field);
        }

        else if(field?.type=='Date' || field?.type=='DateTime'){
          isPassed = this.isPassedDateType(ele, newLeadInfo, field, isPassed);
        }

        else if(field?.type=='SingleImage' || field?.type=='MultipleImage' || field?.type=='SingleVideo' || field?.type=='MultipleVideo'
        || field?.type=='SingleAudio' || field?.type=='MultipleAudio' || field?.type=='SingleFile' || field?.type=='MultipleFile' || field?.type=='Location'){
          isPassed = this.isPassedMediaType(ele, isPassed, newLeadInfo);
        }
        else if(field.type=="lead"){
          isPassed = this.isPassedItemId(newLeadInfo, ele, isPassed);
        }


        if(operator=='and' && !isPassed){
          return isPassed
        }
        else if(operator=='or' && isPassed){
          return isPassed
        }

      }

    }
    // if loop complted then return true for 'and' & false for 'or'
    if(operator=='and'){
      return true
    }
    else{
      return false
    }
  }

  async checkIfLeadPassRestrictedFilter(newLeadInfo){
      const dashboardInfo = await this.dashboardUtils.getDashboardInformation([newLeadInfo.dashboard_id])
      const userRole = new Set(dashboardInfo[newLeadInfo.dashboard_id]?.DASHBOARD_INFO?.role || [])
      let passed=true
      if(userRole.size === 1 && userRole.has("RESTRICTED_ACCESS")){
        passed=false
        let hierarchyChild=await this.commonUtils.getHierachyChild(this.dashboardUtils.getOrgUserIfPresent(),this.customStorageService.getItem('id'))
        newLeadInfo.lead_colloborators_users?.forEach(user=>{
          if(hierarchyChild.includes(user)){
            passed= true
          }
        })
      }
      return passed
    
  }

  private isPassedItemId(newLeadInfo: any, ele: any, isPassed: boolean) {
    let leadValue = newLeadInfo[ele.key];
    if (ele?.operator == 'IS_ONE_OF') {
      isPassed = ele.value?.includes(leadValue) ? true : false;
    }
    else if (ele?.operator == 'IS_NOT_ONE_OF') {
      isPassed = ele.value?.includes(leadValue) ? false : true;
    }
    return isPassed;
  }


  private isPassedCurrencyMobile(ele: any, isPassed: boolean, newLeadInfo: any) {
    let key = ele.key.split('.')[1];
    let ObjectKey=ele.key.split('.')[2];
    let newLeadField = newLeadInfo.custom_fields;
    if (ele?.operator == 'EQUALS_TO') {
      isPassed = newLeadField[key] &&  newLeadField[key][ObjectKey] &&  newLeadField[key][ObjectKey]== ele.value ? true : false;
    }
    else if (ele?.operator == 'NOT_EQUALS_TO') {
      isPassed = newLeadField[key] &&  newLeadField[key][ObjectKey] &&  newLeadField[key][ObjectKey]== ele.value ? false : true;
    }
    else if (ele?.operator == 'IS_EMPTY') {
      isPassed = (newLeadField[key] == null || !newLeadField[key][ObjectKey] ||  !newLeadField[key][ObjectKey]==null) ? true : false;
    }
    else if (ele?.operator == 'IS_NOT_EMPTY') {
      isPassed = (newLeadField[key] == null || !newLeadField[key][ObjectKey] ||  !newLeadField[key][ObjectKey]==null) ? false : true;
    }
    else if (ele?.operator == 'IS_ONE_OF' || ele?.operator == 'IS_NOT_ONE_OF') {
      let isValIncluded = false;
      for (let i = 0; i < ele.value?.length; i++) {
        if (newLeadField[key] && newLeadField[key][ObjectKey] && newLeadField[key][ObjectKey]==ele.value[i]) {
          isValIncluded = true;
          break;
        }
      }
      
      isPassed = isValIncluded && ele?.operator == 'IS_ONE_OF' ? true : !isValIncluded && ele?.operator == 'IS_NOT_ONE_OF' ? true : false;
    }
    else if (ele?.operator == 'RANGE') {
      isPassed = newLeadField[key] && newLeadField[key][ObjectKey] && (newLeadField[key][ObjectKey] > ele.value.from && newLeadField[key][ObjectKey] < ele.value.to) ? true : false;
    }
    return isPassed;
  }

  private isPassedMediaType(ele: any, isPassed: boolean, newLeadInfo: any) {
    let key = ele.key.split('.')[1];
    if (ele?.operator == 'IS_EMPTY') {
      isPassed = !newLeadInfo.custom_fields[key] || (newLeadInfo.custom_fields[key] == null || newLeadInfo.custom_fields[key] == '') ? true : false;
    }
    else if (ele?.operator == 'IS_NOT_EMPTY') {
      isPassed = newLeadInfo.custom_fields[key] && (newLeadInfo.custom_fields[key] != null && newLeadInfo.custom_fields[key] != '') ? true : false;
    }
    return isPassed;
  }

  private isPassedBoolean(ele: any, isPassed: boolean, newLeadInfo: any) {
    if (ele.key == "is_temp_deleted") {
      if (ele?.operator == 'EQUALS_TO') {
        isPassed = newLeadInfo[ele.key] == ele.value ? true : false;
      }
      else if (ele?.operator == 'NOT_EQUALS_TO') {
        isPassed = newLeadInfo[ele.key] == ele.value ? true : false;
      }
      else if (ele?.operator == 'IS_ONE_OF') {
        isPassed = ele.value.includes(newLeadInfo[ele.key]) ? true : false;
      }
      else if (ele?.operator == 'IS_NOT_ONE_OF') {
        isPassed = ele.value.includes(newLeadInfo[ele.key]) ? false : true;
      }
    }


    // for starred need to check
    else if (ele.key == "starred") {
      let isStarred = false;
      if (newLeadInfo.starred && newLeadInfo.starred.length) {
        if (newLeadInfo.starred.map(el => el.user_id == this.customStorageService.getItem('id')).length > 0) {
          isStarred = true;
        }
      }
      isPassed = (ele?.value == true && isStarred ) || (ele?.value == false && !isStarred) ? true : false;
    }


    // for external collaborators
    else if (ele.key == "external_collaborator_user_ids") {
      if (ele?.operator == 'IS_EMPTY') {
        isPassed = newLeadInfo.external_collaborator_user_ids && newLeadInfo.external_collaborator_user_ids?.length ? false : true;
      }
      else if (ele?.operator == 'IS_NOT_EMPTY') {
        isPassed = newLeadInfo.external_collaborator_user_ids && newLeadInfo.external_collaborator_user_ids?.length ? true : false;
      }
    }


    // for Public Items 
    else if (ele.key == "item_type") {
      if (ele?.operator == 'EQUALS_TO') {
        isPassed = newLeadInfo[ele.key] == ele.value ? true : false;
      }
      else if (ele?.operator == 'NOT_EQUALS_TO') {
        isPassed = newLeadInfo[ele.key] == ele.value ? true : false;
      }
      else if (ele?.operator == 'IS_ONE_OF') {
        isPassed = ele.value.includes(newLeadInfo[ele.key]) ? true : false;
      }
      else if (ele?.operator == 'IS_NOT_ONE_OF') {
        isPassed = ele.value.includes(newLeadInfo[ele.key]) ? false : true;
      }
    }


    // for Public Items need to check
    else if (ele.key == "aging_time") {
      let agingPassed = newLeadInfo?.aging_time && newLeadInfo?.aging_time < new Date().getTime() ? true : false;
      if (ele?.operator == 'EQUALS_TO') {
        isPassed = ele.value == agingPassed ? true : false;
      }
      else if (ele?.operator == 'NOT_EQUALS_TO') {
        isPassed = ele.value == agingPassed ? false : true;
      }
      else if (ele?.operator == 'IS_ONE_OF') {
        isPassed = ele.value.includes(agingPassed) ? true : false;
      }
      else if (ele?.operator == 'IS_NOT_ONE_OF') {
        isPassed = ele.value.includes(agingPassed) ? false : true;
      }
    }
    return isPassed;
  }

  private isPassedTags(ele: any, isPassed: boolean, newLeadInfo: any) {
    if (ele?.operator == 'IS_EMPTY') {
      isPassed = !newLeadInfo[ele.key] || !newLeadInfo[ele.key].length ? true : false;
    }
    else if (ele?.operator == 'IS_NOT_EMPTY') {
      isPassed = newLeadInfo[ele.key] && newLeadInfo[ele.key].length ? true : false;
    }
    else if (ele?.operator == 'EQUALS_TO') {
      isPassed = newLeadInfo[ele.key] && newLeadInfo[ele.key].includes(ele.value) ? true : false;
    }
    else if (ele?.operator == 'NOT_EQUALS_TO') {
      isPassed = newLeadInfo[ele.key] && newLeadInfo[ele.key].includes(ele.value) ? false : true;
    }
    else if (ele?.operator == 'IS_ONE_OF' || ele?.operator == 'IS_NOT_ONE_OF') {
      let isTagIncluded = false;
      for (let i = 0; i < ele.value?.length; i++) {
        if (newLeadInfo[ele.key] && newLeadInfo[ele.key].includes(ele.value[i])) {
          isTagIncluded = true;
          break;
        }
      }
      isPassed = isTagIncluded && ele?.operator == 'IS_ONE_OF' ? true : !isTagIncluded && ele?.operator == 'IS_NOT_ONE_OF' ? true : false;

    }
    return isPassed;
  }

  private isPassedPriorityBucket(ele: any, newLeadInfo: any, isPassed: boolean) {
    let leadValue = ele.type == "Priority" ? parseInt(newLeadInfo[ele.key]) : newLeadInfo[ele.key];
    if (ele?.operator == 'EQUALS_TO') {
      isPassed = leadValue == ele.value ? true : false;
    }
    else if (ele?.operator == 'NOT_EQUALS_TO') {
      isPassed = leadValue != ele.value ? true : false;
    }
    else if (ele?.operator == 'IS_EMPTY') {
      isPassed = (leadValue == null || leadValue == '') ? true : false;
    }
    else if (ele?.operator == 'IS_NOT_EMPTY') {
      isPassed = (leadValue != null && leadValue != '') ? true : false;
    }
    else if (ele?.operator == 'IS_ONE_OF') {
      isPassed = ele.value?.includes(leadValue) ? true : false;
    }
    else if (ele?.operator == 'IS_NOT_ONE_OF') {
      isPassed = ele.value?.includes(leadValue) ? false : true;
    }
    return isPassed;
  }

  private isPassedSprint(newLeadInfo: any, ele: any, isPassed: boolean) {
    let leadValue = newLeadInfo[ele.key];
    if (ele?.operator == 'EQUALS_TO' || ele?.operator == 'NOT_EQUALS_TO') {
      let phasePassed = false;
      if (leadValue == ele.value) {
        phasePassed = true;
      }
      else if (ele.value === '{{EVAL_IN_PROGRESS_SPRINT}}') {
        let sprintArray = this.dashboardUtils.getDashboardSprintIfPresent(newLeadInfo.dashboard_id) || [];
        for (let i = 0; i < sprintArray?.length; i++) {
          if (leadValue === sprintArray[i]._id && sprintArray[i].current_status == 'STARTED') {
            phasePassed = true;
            break;
          }
        }
      }
      isPassed = ele?.operator == 'EQUALS_TO' ? phasePassed : !phasePassed;

    }

    else if (ele?.operator == 'IS_EMPTY') {
      isPassed = (leadValue == null || leadValue == '') ? true : false;
    }
    else if (ele?.operator == 'IS_NOT_EMPTY') {
      isPassed = (leadValue != null && leadValue != '') ? true : false;
    }
    else if (ele?.operator == 'IS_ONE_OF' || ele?.operator == 'IS_NOT_ONE_OF') {
      let phasePassed = false;
      ele.value?.forEach(id => {
        if (leadValue === id) {
          phasePassed = true;
        }
        else if (id === '{{EVAL_IN_PROGRESS_SPRINT}}') {
          let sprintArray = this.dashboardUtils.getDashboardSprintIfPresent(newLeadInfo.dashboard_id) || [];
          for (let i = 0; i < sprintArray?.length; i++) {
            if (leadValue === sprintArray[i]._id && sprintArray[i].current_status == 'STARTED') {
              phasePassed = true;
              break;
            }
          }
        }
      });
      isPassed = ele?.operator == 'IS_ONE_OF' ? phasePassed : !phasePassed;
    }
    return isPassed;
  }

  private isPassedCheckMultiRadioDropCountry(ele: any, isPassed: boolean, newLeadInfo: any, field: any) {
    let key = ele.key.split('.')[1];

    if (ele?.operator == 'IS_EMPTY') {
      isPassed = !newLeadInfo.custom_fields[key] || newLeadInfo.custom_fields[key].length ? true : false;
    }
    else if (ele?.operator == 'IS_NOT_EMPTY') {
      isPassed = newLeadInfo.custom_fields[key] && newLeadInfo.custom_fields[key].length ? true : false;
    }
    else if (ele?.operator == 'EQUALS_TO') {
      isPassed = ((field?.type == 'Checkbox' || field?.type == 'MultiSelect') && newLeadInfo.custom_fields[key] && newLeadInfo.custom_fields[key]?.includes(ele.value)) ? true : newLeadInfo.custom_fields[key] && newLeadInfo.custom_fields[key] == ele.value ? true : false;
    }
    else if (ele?.operator == 'NOT_EQUALS_TO') {
      isPassed = ((field?.type == 'Checkbox' || field?.type == 'MultiSelect') && newLeadInfo.custom_fields[key] && newLeadInfo.custom_fields[key]?.includes(ele.value)) ? false : newLeadInfo.custom_fields[key] && newLeadInfo.custom_fields[key] == ele.value ? false : true;
    }
    else if (ele?.operator == 'IS_ONE_OF' || ele?.operator == 'IS_NOT_ONE_OF') {
      let isValueIncluded = false;
      for (let i = 0; i < ele.value?.length; i++) {
        if (newLeadInfo.custom_fields[key] && newLeadInfo.custom_fields[key].includes(ele.value[i])) {
          isValueIncluded = true;
          break;
        }
      }
      isPassed = isValueIncluded && ele?.operator == 'IS_ONE_OF' ? true : !isValueIncluded && ele?.operator == 'IS_NOT_ONE_OF' ? true : false;

    }
    return isPassed;
  }

  private isPassedDateType(ele: any, newLeadInfo: any, field: any, isPassed: boolean) {
    let key = ele.key == "start_date" || ele.key == "end_date" || ele.key == "update_date" || ele.key == "created_time" ? ele.key : ele.key.split('.')[1];
    let newLeadField = ele.key == "start_date" || ele.key == "end_date" || ele.key == "update_date" || ele.key == "created_time" ? newLeadInfo : newLeadInfo.custom_fields;
    let dateField = field?.type == 'Date' ? (newLeadField[key]?.is_time_added ? newLeadField[key]?.time : newLeadField[key]?.date) : newLeadField[key];
    if (ele?.operator == 'IS_EMPTY') {
      isPassed = !dateField ? true : false;
    }
    else if (ele?.operator == 'IS_NOT_EMPTY') {
      isPassed = dateField ? true : false;
    }    
    else if (ele?.operator == 'RANGE') {
      let dateFilter = ele.value.type == 'CUSTOM' ? { 'from': this.commonUtils.calculateTimestampSince1900(ele.value.date?.from), 'to': this.commonUtils.calculateTimestampSince1900(ele.value.date?.to) } : this.commonUtils.calcFiscalPeriod(ele?.value.eval_json);
      isPassed = dateField && (dateField > dateFilter.from && dateField < dateFilter.to) ? true : false;
    }
    else {
      let dateFilter = ele.value.type == 'CUSTOM' ? this.commonUtils.calculateTimestampSince1900(ele.value.date) : new Date(this.commonUtils.calculateEvalDate(ele?.value.eval_json).evaluated_date);
      if (ele?.operator == 'IS_GREATER_THAN') {
        isPassed = dateField && dateField > dateFilter ? true : false;
      }
      else if (ele?.operator == 'IS_LESS_THAN') {
        isPassed = dateField && dateField < dateFilter ? true : false;
      }
      else if (ele?.operator == 'IS_GREATER_THAN_OR_EQUALS_TO') {
        isPassed = dateField && dateField >= dateFilter ? true : false;
      }
      else if (ele?.operator == 'IS_LESS_THAN_OR_EQUALS_TO') {
        isPassed = dateField && dateField <= dateFilter ? true : false;
      }
    }
    return isPassed;
  }

  private isPassedUserType(ele: any, newLeadInfo: any, isPassed: boolean, field: any) {
    let key = ele.key == "assigned_to" || ele.key == "created_by" || ele.key == "updated_by" || ele.key == "lead_colloborators_users" ? ele.key : ele.key.split('.')[1];
    let newLeadField = ele.key == "assigned_to" || ele.key == "created_by" || ele.key == "updated_by" ? newLeadInfo : newLeadInfo.custom_fields;
    if (ele?.operator == 'EQUALS_TO') {
      let userVal = ele.value != '{{EVAL_LOGGED_IN_USER}}' ? ele.value : this.customStorageService.getItem('id');
      isPassed = field?.type == 'SingleUser' && newLeadField[key] && newLeadField[key] == userVal ? true : field?.type == 'MultiUser' && newLeadField[key] && newLeadField[key].includes(userVal) ? true : false;

    }
    else if (ele?.operator == 'NOT_EQUALS_TO') {
      let userVal = ele.value != '{{EVAL_LOGGED_IN_USER}}' ? ele.value : this.customStorageService.getItem('id');
      isPassed = field?.type == 'SingleUser' && newLeadField[key] && newLeadField[key] == userVal ? false : field?.type == 'MultiUser' && newLeadField[key] && newLeadField[key].includes(userVal) ? false : true;
    }
    else if (ele?.operator == 'IS_EMPTY') {
      isPassed = field?.type == 'SingleUser' && (!newLeadField[key] || newLeadField[key] == null || newLeadField[key] == '') ? true : field?.type == 'MultiUser' && newLeadField[key] && newLeadField[key].length > 0 ? true : false;
    }
    else if (ele?.operator == 'IS_NOT_EMPTY') {
      isPassed = field?.type == 'SingleUser' && (newLeadField[key] && newLeadField[key] != null && newLeadField[key] != '') ? true : field?.type == 'MultiUser' && newLeadField[key] && newLeadField[key].length > 0 ? true : false;
    }

    else if (ele?.operator == 'IS_ONE_OF' || ele?.operator == 'IS_NOT_ONE_OF') {
      let isUserIncluded = false;
      for (let i = 0; i < ele.value?.length; i++) {
        let userVal = ele.value[i] != '{{EVAL_LOGGED_IN_USER}}' ? ele.value[i] : this.customStorageService.getItem('id');
        if (field?.type == 'MultiUser' && newLeadField[key] && newLeadField[key].includes(userVal)) {
          isUserIncluded = true;
          break;
        }
        else if (field?.type == 'SingleUser' && newLeadField[key] && newLeadField[key] == userVal) {
          isUserIncluded = true;
          break;
        }
      }
      isPassed = isUserIncluded && ele?.operator == 'IS_ONE_OF' ? true : !isUserIncluded && ele?.operator == 'IS_NOT_ONE_OF' ? true : false;

    }
    return isPassed;
  }

  private isPassedNumericRatings(ele: any, isPassed: boolean, newLeadInfo: any) {
    let key = ele.key.split('.')[1];
    if (ele?.operator == 'IS_EMPTY') {
      isPassed = (!newLeadInfo.custom_fields[key] || newLeadInfo.custom_fields[key] == null || newLeadInfo.custom_fields[key] == '') ? true : false;
    }
    else if (ele?.operator == 'IS_NOT_EMPTY') {
      isPassed = (newLeadInfo.custom_fields[key] != null && newLeadInfo.custom_fields[key] != '') ? true : false;
    }
    else if (ele?.operator == 'IS_GREATER_THAN') {
      isPassed = newLeadInfo.custom_fields[key] && newLeadInfo.custom_fields[key] > ele.value ? true : false;
    }
    else if (ele?.operator == 'IS_LESS_THAN') {
      isPassed = newLeadInfo.custom_fields[key] && newLeadInfo.custom_fields[key] < ele.value ? true : false;
    }
    else if (ele?.operator == 'IS_GREATER_THAN_OR_EQUALS_TO') {
      isPassed = newLeadInfo.custom_fields[key] && newLeadInfo.custom_fields[key] >= ele.value ? true : false;
    }
    else if (ele?.operator == 'IS_LESS_THAN_OR_EQUALS_TO') {
      isPassed = newLeadInfo.custom_fields[key] && newLeadInfo.custom_fields[key] <= ele.value ? true : false;
    }
    else if (ele?.operator == 'RANGE') {
      isPassed = newLeadInfo.custom_fields[key] && (newLeadInfo.custom_fields[key] > ele.value.from && newLeadInfo.custom_fields[key] < ele.value.to) ? true : false;
    }
    return isPassed;
  }

  private isPassedTextEmailURLIP(ele: any, newLeadInfo: any, isPassed: boolean) {
    let key = ele.key == "title" ? ele.key : ele.key.split('.')[1];
    let newLeadField = ele.key == "title" ? newLeadInfo : newLeadInfo.custom_fields;
    if (ele?.operator == 'EQUALS_TO') {
      isPassed = newLeadField[key] && newLeadField[key] == ele.value ? true : false;
    }
    else if (ele?.operator == 'NOT_EQUALS_TO') {
      isPassed = !newLeadField[key] || newLeadField[key] != ele.value ? true : false;
    }
    else if (ele?.operator == 'IS_EMPTY') {
      isPassed = (newLeadField[key] == null || newLeadField[key] == '') ? true : false;
    }
    else if (ele?.operator == 'IS_NOT_EMPTY') {
      isPassed = (newLeadField[key] != null && newLeadField[key] != '') ? true : false;
    }
    else if (ele?.operator == 'CONTAINS') {
      isPassed = newLeadField[key] && newLeadField[key]?.toLowerCase().includes(ele.value?.toLowerCase()) ? true : false;
    }
    else if (ele?.operator == 'DOES_NOT_CONTAIN') {
      isPassed = newLeadField[key] && newLeadField[key]?.toLowerCase().includes(ele.value?.toLowerCase()) ? false : true;
    }
    return isPassed;
  }

  checkIfValueIsInRange(actualValue, filterLowerValue, filterUpperValue){
    if(actualValue && typeof actualValue==='object'){
      actualValue=actualValue?.is_time_added ? actualValue?.time : actualValue?.date
    }
    // if(actualValue === null || actualValue=== undefined){
    //   return true
    // }
    if(filterLowerValue && typeof filterLowerValue==='object'){
      filterLowerValue=filterLowerValue?.is_time_added ? filterLowerValue?.time : filterLowerValue?.date
    }
    if(filterUpperValue && typeof filterUpperValue==='object'){
      filterUpperValue=filterUpperValue?.is_time_added ? filterUpperValue?.time : filterUpperValue?.date
    }
    // We are checking filterValue to undefined and null as there can be a case where filter is only applied on from value, so in this case to value should not matter
    if((filterLowerValue===undefined || filterLowerValue===null || actualValue>= filterLowerValue) 
      && (filterUpperValue===undefined || filterUpperValue ===null  || actualValue<=filterUpperValue)) {
        return true
      }
    return false
  }

  isNewLeadPassCustomeFilter(newLeadInfo,filterObjData){
    let customFieldsList = this.dashboardUtils.getDashboardCustomFormIfPresent(newLeadInfo.dashboard_id)
    let isLeadPassed = true;
    let filterPassedMap = {};

    customFieldsList?.forEach((element) => {
        let key = element['key'];
        if (filterObjData['custom_column']&& filterObjData['custom_column'][key]) {
          if (this.rangeFilterTypes.includes(element['type'])) {
            this.checkIfNewLeadPassedRangeCustomFilter(filterObjData,newLeadInfo,key, filterPassedMap,element['type']);
          }else if(this.absoluteValueFilterTypes.includes(element['type'])) {
            this.checkIfNewLeadPassedAbsoluteCustomFilter(filterObjData,newLeadInfo,key,filterPassedMap);
          }else if(this.jsonValueFilterType.includes(element['type'])){
            this.checkIfNewLeadPassedJsonCustomFilter(filterObjData,newLeadInfo,key,filterPassedMap)
          }
      }
    });
    for (var key of Object.keys(filterPassedMap)) {
      let value = filterPassedMap[key]
      if (!value) {
        isLeadPassed = value;
      }
    }

    return isLeadPassed;
  }

  checkIfNewLeadPassedRangeCustomFilter(filterObjData,newLeadInfo,customFieldKey, filterPassedMap,type) {
    let value = newLeadInfo['custom_fields'] ? newLeadInfo['custom_fields'][customFieldKey] : null
    if(type=='Date' && value){
      value=value?.is_time_added ? value?.time : value?.date
    }
    if(filterObjData['custom_column']  && (value!=null && value!="" && value!=undefined) && (filterObjData['custom_column'][customFieldKey]["from"] || filterObjData['custom_column'][customFieldKey]["to"])){
      filterPassedMap[customFieldKey] = this.checkIfValueIsInRange(value,filterObjData['custom_column'][customFieldKey]["from"],filterObjData['custom_column'][customFieldKey]["to"])

    }
  }

  checkIfNewLeadPassedAbsoluteCustomFilter(filterObjData,newLeadInfo,customFieldKey, filterPassedMap) {
    if(filterObjData['custom_column'] && filterObjData['custom_column'][customFieldKey]){
      filterPassedMap[customFieldKey] = false
      let filterValues = typeof(filterObjData['custom_column'][customFieldKey])=='string' ? [filterObjData['custom_column'][customFieldKey]] : filterObjData['custom_column'][customFieldKey] || [];
      let leadValue=(JSON.stringify(newLeadInfo['custom_fields'][customFieldKey]) || '')?.toLowerCase();
      let loggedInUserId=this.customStorageService.getItem('id')
      filterValues?.forEach(filterValue => {
        if(newLeadInfo['custom_fields'] && (newLeadInfo['custom_fields'][customFieldKey]!=null && newLeadInfo['custom_fields'][customFieldKey]!='') && (leadValue.includes(filterValue.toString().toLowerCase()) || (filterValue=='{{EVAL_LOGGED_IN_USER}}' && leadValue.includes(loggedInUserId)))){
          filterPassedMap[customFieldKey] = true
        }
      })
    }

  }

  checkIfNewLeadPassedJsonCustomFilter(filterObjData,newLeadInfo,customFieldKey, filterPassedMap) {
    if(filterObjData['custom_column'] && filterObjData['custom_column'][customFieldKey]){
      filterPassedMap[customFieldKey] = false
      let keysOfCustomFielsVale=Object.keys(newLeadInfo['custom_fields'][customFieldKey] || {})
      for(let key of keysOfCustomFielsVale){
        let value=(newLeadInfo['custom_fields'][customFieldKey][key] || '')?.toString().replace(/null/g,'').toLowerCase() // replace null if come on string eg "null"
        if(value?.includes(filterObjData['custom_column'][customFieldKey]?.toString().toLowerCase()) && key!='country_code'){ // country code only for mobile field 
          filterPassedMap[customFieldKey] = true
          break;
        }
      }
    }
  }


  async getIndexAfterkeySorting(
    leadsLoaded,
    newLeadInfo,
    key,
    filterObjData
  ) {
    leadsLoaded.push(newLeadInfo);
    let leadIndex;
    let sortedLeads = [];
    if(key === 'lead_id'){
      sortedLeads = this.sortJsonArrayInCaseOfLeadIdSort(leadsLoaded, filterObjData);
    }
    else if(key!=='relevance'){
      let sortingArr=filterObjData?.sort_params?.reduce((arr,data)=>{return [...arr,{key:(!data?.sort_by?.includes('custom_fields.') ? this.constantService.constant.sortByOptions[data.sort_by]?.lead_key : data.sort_by),order:data.order}]},[])
      sortedLeads = this.sortJsonArray(leadsLoaded,sortingArr);
    } 
    else if(key === 'relevance'){
      sortedLeads = this.sortJsonArrayInCaseOfRelevance(leadsLoaded, filterObjData);
    }
    leadIndex = this.getNewLeadIndexAfterSorting(sortedLeads, newLeadInfo);
    if(leadIndex<sortedLeads.length-1 || key=='lead_id'){
      return leadIndex;
    } 
    else if(leadIndex >= sortedLeads.length-1) {
      let inputJson = {};
      inputJson = this.commonUtils.getFilterJsonForQuery(inputJson, filterObjData);
      let groupBy = filterObjData?.grouping_details?.group_by
      if(groupBy)inputJson["grouping_details"]['max_group_size'] = null
      if(groupBy && groupBy != 'none'){
        let groupKeyJson = this.constantService.getGroupKeyJson()[groupBy] 
        if(groupKeyJson){
            let value = newLeadInfo[groupKeyJson?.key];
            if(groupBy=='priority') value= parseInt(value)
            inputJson[groupKeyJson.filter_key] = [value]            
          }else if(Object.keys(newLeadInfo?.custom_fields)?.length && newLeadInfo?.custom_fields[groupBy]!==null && newLeadInfo?.custom_fields[groupBy]!==undefined){
            inputJson['custom_column'] = {}
            inputJson['custom_column'][groupBy] = [newLeadInfo?.custom_fields[groupBy]]
          }
      } 
      let pagination = {};
      pagination["start_index"] =leadIndex>=5 ? leadIndex-5 : 0
      pagination["page_size"] = 10;
      inputJson["pagination_details"] = pagination;
      delete inputJson['grouping_details']
      delete inputJson['apply_formula']
      let res=await this.httpService.getLeadQuery(inputJson,newLeadInfo.dashboard_id).toPromise()
      this.leadData=res.result?.leadResponse || []
      let lastLeadIndex=this.leadData?.findIndex(item=>item._id==(sortedLeads[leadIndex>0 ? leadIndex-1 : 0]?._id))
      let indexOfNewLead=this.leadData?.findIndex(item=>item._id==newLeadInfo?._id);
      leadIndex=indexOfNewLead>-1 ? pagination["start_index"]+indexOfNewLead : -1 ;
      this.leadData=this.leadData.splice(lastLeadIndex,lastLeadIndex+5)
      this.totalRecords=res.result?.pagination_details?.total_records || 0
      return leadIndex;
    } 
    else {
      return -1;
    }    
  }

  sortJsonArrayHandler(a,b,key,way,previousKeysSorting?,sortSequence=[]){
    let ascendingOrder = this.constantService.getAscOrder();
    let descendingOrder = this.constantService.getDscOrder();
    if(key.includes('custom_fields.')) {
      var x = a[key.split('.')[0]][key.split('.')[1]];
      var y = b[key.split('.')[0]][key.split('.')[1]];
      if((typeof x)==='string' || (typeof y)==='string'){
        x=x ? x : ''
        y=y ? y : ''
      }else{
        x=x ? x : 0
        y=y ? y : 0
      }
    } else {
      var x = a[key];
      var y = b[key];
    }
    // comment sorting
    if(this.constantService.constant.sortByOptions['comments'].lead_key==key){
      let timeOfCommentsX=Object.values(x || {default:{time:0}})?.map(comment=>comment['time'] || 0)
      let timeOfCommentsY=Object.values(y || {default:{time:0}})?.map(comment=>comment['time'] || 0)
      x = way === descendingOrder ? Math.max(...timeOfCommentsX) : Math.min(...timeOfCommentsX)
      y = way === descendingOrder ? Math.max(...timeOfCommentsY) : Math.min(...timeOfCommentsY)
    }
    // only for date Type fields (eg start and end date)
    if(x && (typeof x ) === 'object' && x?.hasOwnProperty("time")){
      x = x?.time ? x?.time : 0;
    }
    if(y && (typeof y === 'object' && y?.hasOwnProperty("time"))){
      y = y?.time ? y?.time : 0;
    }
    if(key==this.constantService.getAssignToNameKey() || key==this.constantService.constant.SORT_BY_CREATEDBY){
      let  users=this.dashboardUtils.getOrgUserIfPresent();
      x=x ? users[x]?.name : '';
      y=y ? users[y]?.name : '';
    }
    // end
    if((key != 'index' && ((typeof x)==='string' || (typeof y)==='string')) || key==this.constantService.constant.sortByOptions['priority']){
      x = x?.toString()?.toLowerCase() 
      y = y?.toString()?.toLowerCase()
    }
    var sortResult
    if (way === ascendingOrder) {
       sortResult = x < y ? -1 : x > y ? 1 : 0;
    }
    if (way === descendingOrder) {
      sortResult = x > y ? -1 : x < y ? 1 : 0;
    }
    // handle muliple  sorting
    if(sortResult === 0 && previousKeysSorting){
      for(let sort of sortSequence){
        if(!previousKeysSorting.includes(sort.key)){
          sortResult = this.sortJsonArrayHandler(a,b,sort.key,sort.order,[...previousKeysSorting,sort.key],sortSequence);
          break;
        }
      }
    }
    return sortResult;
  }
  sortJsonArray(arr,sortingArr) {
    let dumeySortArr=[...sortingArr,...[{key:this.constantService.constant.sortByOptions['index'].lead_key,order:this.constantService.getDscOrder()},{key:this.constantService.constant.sortByOptions['create_date'].lead_key,order:this.constantService.getDscOrder()}]]
    let startSortObj=dumeySortArr[0];
    return arr.sort((a, b) => {
      return this.sortJsonArrayHandler(a,b,startSortObj.key,startSortObj.order,[startSortObj.key],dumeySortArr);
    });
  }

  sortJsonArrayInCaseOfLeadIdSort(leadsArray, filterObjData){
    let sortedLeadIds = filterObjData?.lead_id
    if(!sortedLeadIds?.length || sortedLeadIds?.length == 1 && sortedLeadIds[0] == null){
      return []
    }
    let sortedLeads =[]
    let leadJsonMap ={}
    leadsArray.forEach((lead)=>{leadJsonMap[lead._id]=lead})
    sortedLeadIds?.forEach((leadId)=>{if(leadJsonMap[leadId])sortedLeads.push(leadJsonMap[leadId])})
    return sortedLeads
  }
  /**
   * 
   * @param array > 0	sort a after b
< 0	sort a before b
=== 0
   * @param key 
   * @param sortingOrder 
   */
  sortJsonArrayInCaseOfRelevance(leadsArray, filterObjData){
    let relevanceTime = filterObjData?.sort_params[0]?.relevance_time || new Date().getTime()
    leadsArray.forEach((lead) =>{
      if(lead?.aging_time && !lead?.aging_priority){
        lead.aging_priority = lead.aging_time<relevanceTime?lead.aging_time:relevanceTime
      }
      else if(!lead?.aging_time){
        lead.aging_priority = relevanceTime
      }
    })
    
   return leadsArray.sort((a,b)=>{
      
      let sortOrder = 0;
      //Sorting in basis of Aging priority
      
      if(a.aging_priority > b.aging_priority ){
        return 1;
      }else if(a.aging_priority < b.aging_priority){
        return -1;
      }
      else{
        sortOrder = 0;
      }
      

      //Following will be executed when both doesn't have aging_priority or aging_priority is equal
      if(a.priority >b.priority){
        return -1;
      }
      else if(a.priority < b.priority){
        return 1;
      }else{
        if(a.create_date > b.create_date){
          return -1;
        }
        else if(a.create_date < b.create_date){
          return 1;
        }
        else{
          return 0;
        }
      }
      
    })
  }


  getNewLeadIndexAfterSorting(sortedLeads, newLeadInfo) {
    for (let i = 0; i < sortedLeads.length; i++) {
      if (sortedLeads[i]["_id"] == newLeadInfo["_id"]) {
        return i;
      }
    }
    return -1
  }
  getActivityIndex(activityList,newActivity,key,order){
    activityList=activityList?.filter(activity=>activity?._id!=newActivity?._id) || []
    activityList.push(newActivity)
    let sortedDsta= this.sortJsonArray(activityList,[{key:key,order:order}]);
    let index = this.getNewLeadIndexAfterSorting(sortedDsta, newActivity);
    return index
  }


  getSortedGroupBy(groupingDetails,groupList,groupIds?) {
    let groupBy = groupingDetails?.group_by
    let sortBy=groupingDetails?.sort_order ? groupingDetails?.sort_order : 'ASC'
    let key=groupBy=='priority' ? 'group_id' : 'name'
    let sortedArray=groupList=JSON.parse(JSON.stringify(groupList))
    if(groupBy && !(['bucket','phase','none'].includes(groupBy))){
      let defaultGroup=groupList.find(group=>group?.group_id==null)
      groupList=groupList.filter(group=>((groupIds &&  groupIds?.includes(group.group_id)) || !groupIds) && group?.group_id!=null)
      sortedArray=this.sortJsonArray(groupList,[{key:key,order:sortBy}])
      // set manually default group position
      if(defaultGroup){
        if(sortBy=='ASC') sortedArray.unshift(defaultGroup)
        else sortedArray.push(defaultGroup)
      }
      if(groupIds){groupIds = sortedArray.map(data => data.group_id);}
    }
    else if(['bucket','phase'].includes(groupBy) && groupIds){
      groupIds=groupList.reduce((arr,data)=>{if(groupIds?.includes(data.group_id)){arr.push(data.group_id)}return arr},[]);
    }
    return groupIds ? groupIds : sortedArray

  }



  
}
