import { HttpHeaders } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { NgbModal, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { AttributesIcons } from "Enums/attributes-icons";
import { CommonUtils } from "app/services/CommonUtils.service";
import { CustomStorageService } from "app/services/custom-storage.service";
import { DashboardUtilsService } from "app/services/dashboard-utils.service";
import { HttpTransferService } from "app/services/httpTransfer.service";
import { el, te } from "date-fns/locale";

@Component({
    selector: 'app-template-picker',
    templateUrl: './template-picker.component.html',
    styleUrls: ['./template-picker.component.scss']
  })
  export class TemplatePickerComponent implements OnInit {
    @Input() dashId:any;
    @Input() leadInfo: any;
    @Input() sprintArr:any;
    @Input() orgUsersJson:any;
    @Input() emailTemplates: boolean = false;
    @Input() mobileField:boolean=false;
    @Input() fileUploadPopover:any
    @Input() tempType:string=''
    @Input() templateOptionSelect:boolean=false;
    @Output() sendTemplateEmit : EventEmitter<any> = new EventEmitter();
    @Output() closeEmit : EventEmitter<any> = new EventEmitter();
    @Output() pdfConverted : EventEmitter<any> = new EventEmitter();
    @Output() templateOpenEmitter : EventEmitter<any> = new EventEmitter();
    whatsappTemplates: any[]=[];
    searchTemplate: any[]=[];
    customFieldArr: any[]=[];
    currencyJson:any[]=[]
    connecteLeadIdArr: any[]=[];
    mirrorColumnsJson: any = {};
    mediaArr:any[]=[]
    showList: boolean = false;
    postQuery: any;
    constructor(public httpTransfer : HttpTransferService,public commonUtils : CommonUtils,public dashboardUtils : DashboardUtilsService,private modalService : NgbModal,
      private customStorageService: CustomStorageService,private commonutils : CommonUtils,private renderer:Renderer2,) { }
    loader:boolean=true;
    showSearchBar: boolean = false;
    templateStatus = {
      custom: true,
      pronnel: true
    }
    pronnelTemplates = []
    searchPronnelTemplate = [];
    selectedTemplates:any;
    attributesIcons = AttributesIcons;
    isReadOnly:boolean=false;
    
  
    async ngOnInit() {
      this.isReadOnly = await this.commonutils.isDashboardReadOnly(this.dashId);
      let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
      let dashJson = JSON.parse(JSON.stringify(dashboardJson))
      this.customFieldArr = JSON.parse(JSON.stringify(dashJson[this.dashId]['CUSTOM_FORM'])) || {};  
      this.mirrorColumnsJson = await this.commonUtils.getConnectedLeadMirrorJson(this.leadInfo,this.customFieldArr)
      this.currencyJson = await this.dashboardUtils.getCurrencyJson() 
      console.log(this.leadInfo)
        this.getTemplatesForChannel()
    }



    getTemplatesForChannel(){
      this.loader=true
        let inputJson={
          'dashboard_id' : [this.dashId],
          "template_category": ["BOARD_TEMPLATES", "PERSONAL_TEMPLATES"],
          "type": ["PDF",'PRONNEL_PDF'],
        }
        this.httpTransfer.getEmailTemplate(inputJson).subscribe((res : any) =>{
          res?.result?.templates.forEach(ele=>{
            if(ele?.type==='PRONNEL_PDF'){
              this.pronnelTemplates.push(ele)
            }
            else{this.whatsappTemplates.push(ele)}
          })
          this.whatsappTemplates.forEach(ele=>{
            ele['showOptions']=false;
          })
          this.searchTemplate.forEach(ele=>{
            ele['showOptions']=false;
          })
          this.searchTemplate =  [...this.whatsappTemplates]
          this.searchPronnelTemplate = [...this.pronnelTemplates]

          this.loader=false
        })
    }

    filterItem(val) {
        val = val.toLowerCase();
        this.whatsappTemplates = this.searchTemplate.filter(
          (item) => item.template_name.toLowerCase().indexOf(val) !== -1 || !val
        );
        this.pronnelTemplates = this.searchPronnelTemplate.filter(
          (item) => item.template_name.toLowerCase().indexOf(val) !== -1 || !val
        );
    }  


    async getMessageFromTemplate(item,modal) {
      this.fileUploadPopover?.close()
     this.selectedTemplates=item
     this.modalService.open(modal, {size : 'md', windowClass: 'channelTemplateEditorModal'})
  }
  

    async getMirrorFieldsValue(value_map) {
      let textToInsert = '';
      let mirrorItemIds = this.leadInfo.custom_fields[value_map?.key]?.mirror_item_id;
      if (mirrorItemIds?.length && value_map?.algorithm !== null) {
          let leadObj = [];
          if (['FETCH_FIRST', 'FETCH_LAST'].includes(value_map?.algorithm)) {
              leadObj = value_map?.algorithm === 'FETCH_FIRST' ?
                  [this.mirrorColumnsJson[mirrorItemIds[0]]] :
                  [this.mirrorColumnsJson[mirrorItemIds[mirrorItemIds?.length - 1]]];
          } else {
              mirrorItemIds.forEach(m => {
                  leadObj.push(this.mirrorColumnsJson[m]);
              });
          }
          if (leadObj?.length) {
              const results = await Promise.all(leadObj.map(async leadData => {
                  let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(leadData?.dashboard_id);
                  let customFieldArr = JSON.parse(JSON.stringify(dashboardJson[leadData?.dashboard_id]['CUSTOM_FORM']));
                  let customObj = customFieldArr.filter(m => m.key == value_map?.mirror_column_key);
                  let result = customObj?.length ?
                      await this.commonUtils.checkForCustomFieldValueOnLead(customObj[0], leadData, value_map?.algorithm) :
                      await this.commonUtils.checkforNonCustomValue(value_map?.mirror_column_key, leadData);
                  console.log(result)
                      if (customObj?.length && ['SingleFile', 'SingleAudio', 'SingleImage', 'SingleVideo', 'MultipleImage', 'MultipleVideo', 'MultipleFile', 'MultipleAudio'].includes(customObj[0]?.type) && result) {
                      let arr = this.commonUtils.trueTypeOf(result) == "ARRAY" ? result : [result];
                      console.log(arr)
                      if (arr?.length) {
                          this.mediaArr = this.mediaArr.concat(arr)
                      }
                      result = '';
                  }
                  return result;
              }));
              textToInsert = results.join(" ");
          }
      }
      return textToInsert;
  }

  openChannelTemplateModal(modal){
    this.closeEmit.emit()
    this.modalService.open(modal,{ size: 'lg',windowClass: 'channelTemplateEditorModal'})
  }

  openTemplate(modal){
    this.fileUploadPopover?.close()
    console.log("modal template",modal)
    this.modalService.open(modal, {size : 'md', windowClass: 'channelTemplateEditorModal'})
  }

  convertHtmlToPdf(itemValue){
    let item={...itemValue}
    console.log("item",item,item?.value_map)
    let json={
      'html':item?.message,
      'file_name':item?.template_name,
      'item_id':this.leadInfo?._id
    }
    let obj={
      'value_from':'EVALUATE',
      'operation_type':'COMPUTETEXT',
    }
    if(item?.value_map){
      Object.values(item?.value_map).forEach((ele:any)=>{
        if(ele?.key==='bucket_id' || ele?.key==='phase' || ele?.key==='priority' || ele?.key==='start_date' || ele?.key==='end_date' || ele?.key==='create_date' || ele?.key==='update_date' ||  ele?.key==='assigned_to' ||  ele?.key==='title'){
          if(ele?.key==='bucket_id'){
            ele['key']='triggeredItem.'+ele?.key+'.name'
          }
          if(ele?.key==='phase'){
            ele['key']='triggeredItem.sprint_id.name'
          }
          else{
            ele['key']='triggeredItem.'+ele?.key
          }
        }
        else{
          ele['key']='triggeredItem.custom_fields.'+ele?.key
        }
      })
      obj['value_map']=item?.value_map
      json['value_eval']=obj
    }
    this.httpTransfer.htmlToPdfConvert(json).subscribe((res :{result: Blob}) =>{
      console.log("res",res)
      res.result['name']=item?.template_name+'.pdf';
      this.pdfConverted.emit(res?.result)
      // let url = URL.createObjectURL(res?.result)
      // let ele = document.createElement('a')
      // ele.href=url
      // ele.download=item?.template_name;
      // ele.click()
    })
  }

  addOpenEmitter(){
    this.fileUploadPopover?.open();
    this.templateOpenEmitter.emit(true)
  }
  
}