import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  @Input() searchFororgUsersJson : any[]=[]
  selectedIndex: number = 0; 
  @Output() selectedUser: EventEmitter<string> = new EventEmitter();
  @ViewChild('userListConatiner') userListConatiner: ElementRef;
  @ViewChild('usersDiv') usersDivs: ElementRef;
  constructor(
    private renderer: Renderer2, private selectedElement: ElementRef, private containerElement: ElementRef
  ) { }

  ngOnInit(): void {
  }
  getUser(user){
  this.selectedUser.emit(user)
  }
 

   // Scroll to keep selected user in view
   scrollToSelectedUser() {
    if (this.selectedIndex >= 0 && this.selectedIndex < this.searchFororgUsersJson.length) {
      const selectedItem = this.selectedElement.nativeElement;
      if (selectedItem) {
        const container = this.containerElement.nativeElement;
        // Use Renderer2 to adjust scroll position
        this.renderer.setProperty(container, 'scrollTop', selectedItem.offsetTop - container.offsetTop);
      }
    }
  }

  // Handle arrow key events
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      this.selectedIndex = Math.min(this.selectedIndex + 1, this.searchFororgUsersJson.length - 1);
      event.preventDefault();
      this.scrollToSelectedUser();
    } else if (event.key === 'ArrowUp') {
      this.selectedIndex = Math.max(this.selectedIndex - 1, -1);
      event.preventDefault();
      this.scrollToSelectedUser();
    }
    const selectedDiv = document.getElementById(`usersDiv_${this.selectedIndex}`);
    if (selectedDiv) {
      selectedDiv.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    if(event.key === 'Enter'){
      console.log(this.searchFororgUsersJson[this.selectedIndex])
      this.selectedUser.emit(this.searchFororgUsersJson[this.selectedIndex])
    }
  }
  

  ngOnDestroy() {
    document.removeEventListener('window:keydown', this.handleKeyboardEvent);
    }
}
