import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '../../../services/toaster.service';

@Component({
  selector: 'app-imap-smtp-connection',
  templateUrl: './imap-smtp-connection.component.html',
  styleUrls: ['./imap-smtp-connection.component.scss']
})
export class ImapSmtpConnectionComponent implements OnInit {
  @Input() connectionModalRef: NgbModalRef; // Connection data for update
  @Input() emailConnection: any; // Connection data for update
  @Output() closeModalEvent = new EventEmitter<any>();
  @Output() saveConnectionEvent = new EventEmitter<any>();
 
  connectionId: string = null;
  smtpServerName: string;
  smtpPort: number = 465;
  imapServerName: string;
  imapPort: number = 993;
  userName: string;
  password: string;
  connectionData: any;

  constructor(private httpTransfer: HttpTransferService,private toasterService: ToasterService) { }

  ngOnInit() {
    // If connectionData is provided, pre-fill the form fields for update
    this.connectionData = this.emailConnection?.imap_smtp_info
    if (this.connectionData) {
      this.connectionId = this.connectionData?._id;
      this.smtpServerName = this.connectionData?.smtp?.host;
      this.smtpPort = this.connectionData?.smtp?.port || 465;
      this.imapServerName = this.connectionData?.imap?.host;
      this.imapPort = this.connectionData?.imap?.port || 993;
      this.userName = this.emailConnection?.email_address;
      this.password = this.connectionData?.password;
    }
  }

  closeModal(call?) {
    if(call!='cancelCall'){
      this.toasterService.Success(`Successfully connected ${this.userName}`)
    }
    this.connectionModalRef?.dismiss();
    this.closeModalEvent.emit();
  }

  async onSubmit() {
    const connection = {
      smtp_host: this.smtpServerName,
      smtp_port: this.smtpPort,
      imap_host: this.imapServerName,
      imap_port: this.imapPort,
      username: this.userName,
      password: this.password
    };


    if(!this.connectionId){
      let res = await this.httpTransfer.connectImapSmtpEmail(connection).toPromise()
      
      if (res !== undefined && res) {
        this.closeModal();
      }
    }else{
      let res = await this.httpTransfer.updateConnectImapSmtpEmail(connection, this.emailConnection?._id).toPromise()
      if (res !== undefined && res) {
        this.closeModal();
      }
    }

    // Emit the connection object for saving
    // this.saveConnectionEvent.emit(connection);
  }

}
