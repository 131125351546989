import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { CustomStorageService } from '../../../services/custom-storage.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from '../../../services/toaster.service';
import { commonIcons } from 'Enums/attributes-icons';


@Component({
  selector: 'app-lead-assign-user',
  templateUrl: './lead-assign-user.component.html',
  styleUrls: ['./lead-assign-user.component.scss']
})
export class LeadAssignUserComponent implements OnInit {
  @Output() closePopover = new EventEmitter<any>();
  @Input() leadInfo:any;
  @Input() dashId: any;
  @Input() assignedUser: any;
  @Input() bulkSelectionFlag:boolean;
  @Input() isGrid:boolean = false;
  @Output() updateLead = new EventEmitter<any>();
  searchStatus: boolean = false;
  viewBoardEnable: true;
  searchText: string;
  searchDashUsers: any[]=[];
  searchListUsers: any[]=[];
  orgFiltersUsersArr: any[] = [];
  showOrg: boolean = false;
  dashUser: any;
  dashboardDataSub: any;
  @Input()hideDashBoardUsers:boolean=false;
  @Input()hideViewAllUsers:boolean=false;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
  @ViewChild('container', { static: false }) container!: ElementRef;
  @Input() objFilterForMultiselect: any;
  itemAttributesObj: {};
  selectedIndex=0;
  commonIcons=commonIcons;

  constructor(
    private httpTransfer: HttpTransferService,
    private toaster:ToasterService,
    private commonUtils: CommonUtils,
    private customStorageService: CustomStorageService,
    private dashUtils: DashboardUtilsService
    ) {
      
    }

  async ngOnInit() {
    this.dashboardDataSub = await this.dashUtils.getUpdatedDashboardJson().subscribe((res : any) => {
      this.dashUser = res[this.dashId]?.DASHBOARD_USER || [];
    });
    await this.dashUtils.getAndSyncAllDashboardData([this.dashId],true,['DashboardUsers'])
    let existingCustomFields = await this.dashUtils.getDashboardExistingCustomFormIfPresent(this.dashId);
    if(existingCustomFields?.length)this.itemAttributesObj = this.commonUtils.handleExistingItemAttributes(existingCustomFields)
    this.dashUtils.orgUsersJsonUpdate$.subscribe((res : any)=>{
      let orgUsersJson=res;
      this.dashUser.forEach(user => {
        orgUsersJson[user._id]['dashUser'] = true; 
      });
      this.orgFiltersUsersArr = Object.values(orgUsersJson);
      this.orgFiltersUsersArr = this.orgFiltersUsersArr.filter(user => !user.is_bot);
    })
    this.searchListUsers = [...this.dashUser];
    this.removeSelfAssigne();
    this.searchListUsers = this.searchDashUsers;
    if(this.hideViewAllUsers){
      this.showAll()
    }

  }

  ngAfterViewInit(){
    this.searchInput.nativeElement.focus();
  }


  filterUser(val) {
    val = val?.toLowerCase();
    this.searchDashUsers = this.searchListUsers.filter(
      (user) => user?.name && user?.name?.toLowerCase()?.includes(val) || user?.email && user?.email?.toLowerCase()?.includes(val) || !val
    );
    this.selectedIndex=0
  }

  removeSelfAssigne() {
    for(var i=0;i<this.searchListUsers.length;i++){
      if(this.assignedUser!=undefined && this.searchListUsers[i]['_id']!==this.assignedUser ){
        this.searchDashUsers.push(this.searchListUsers[i]);
        this.searchDashUsers=[...this.searchDashUsers]
      }
      else{
        if(this.assignedUser==undefined){
        for(var i=0;i<this.searchListUsers.length;i++){
        this.searchDashUsers.push(this.searchListUsers[i]);
        this.searchDashUsers=[...this.searchDashUsers]
        }
      }
      }
    }

  }

  async updateAssignUserClicked(userId) {
    this.close.emit();
    if(this.isGrid) this.updateLead.emit(userId);
    else{
      if(this.leadInfo) {
        if(this.leadInfo.length>0) {

          if(this.leadInfo.length>1){
       
            let inputJson={}
         
            inputJson['filter_properties']=this.objFilterForMultiselect
            inputJson['update_json']={
              "assigned_to":userId
            }
            this.httpTransfer.updateMultipleDashboard(this.dashId,inputJson).subscribe((res : any) =>{
              this.closePopover.emit(true)

            })
          }
          else{
             await this.updateAssignUserForLead(
                this.leadInfo[0]._id,
                userId,
                this.leadInfo[0].bucket_id
              ); 
          }
  
        } else {
          this.toaster.Errors("Please Select Items");
        }
      } else {
        this.updateLead.emit(userId);
      }
    }
  }

  async updateAssignUserForLead(leadId,userId,bucketId) {
    var leadObj = {};
    leadObj["assigned_to"] = userId;
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(leadObj, this.dashId, leadId)
      .subscribe((res : any) => {
        if (res.status === 200) {          
          this.updateLead.emit({'leadId':leadId,'bucketId':bucketId,assigned_to:leadObj["assigned_to"]});
        }
      });
  }

  updateLeadToAssignMeClicked() {
    let userId = this.customStorageService.getItem("id");
    this.updateAssignUserClicked(userId);
  }
  showAll(){
    if(this.showOrg){
      this.searchDashUsers = this.orgFiltersUsersArr
      this.searchListUsers = [...this.searchDashUsers]
    }
    else
    {
      this.searchListUsers =[]
      this.searchDashUsers = []
      this.searchListUsers = [...this.dashUser];
      this.removeSelfAssigne();
    }    
    if(this.searchText) this.filterUser(this.searchText)
    this.selectedIndex=0;
  }
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Tab':
        event.preventDefault()
        if(this.selectedIndex==-1){
          this.selectedIndex=0
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
        }
        else if(this.selectedIndex>=0 && this.selectedIndex<this.searchDashUsers.length){
          this.selectedIndex=this.assignedUser || this.bulkSelectionFlag ? this.searchDashUsers.length  : -1
        }else if(this.selectedIndex==this.searchDashUsers.length){
          this.selectedIndex=-1
        }
        break;
      case 'ArrowUp':
        if (this.selectedIndex >=0) {
          this.selectedIndex--;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
        }
        break;
      case 'ArrowDown':
        if (this.selectedIndex < this.searchDashUsers.length - 1) {
          this.selectedIndex++;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
        }
        break;
      case 'Enter':
        if(this.selectedIndex==-1)this.showAll()
        else if(this.selectedIndex<this.searchDashUsers.length)this.updateAssignUserClicked(this.searchDashUsers[this.selectedIndex]?._id);
        else this.updateAssignUserClicked(null);
        break;
    }
  }

  
}
