import { TruncateDateYearPipe } from './../truncate-date-year.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MentionModule } from 'angular-mentions';
import { ReusableModule } from './../reusable.module/reusable.module';
import { AutosizeModule} from 'ngx-autosize';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { ReusableRoutingModule } from './reusableComponent-routing.module'; 
import { InfiniteScrollModule} from 'ngx-infinite-scroll';
import { NgxPaginationModule} from 'ngx-pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DpDatePickerModule} from 'ng2-date-picker';
import { NgxSummernoteModule } from 'ngx-summernote';
import { SafehtmlPipe } from '../safehtml.pipe';
import { NgxDocViewerModule } from 'ngx-doc-viewer'; 
import { MinutesPipePipe } from '../minutes-pipe.pipe';
import { KeyValuePipeComponent } from './key-value-pipe.pipe';
import { DashboardSprintComponent } from './dashboard-sprint/dashboard-sprint.component';
import { LinkyModule } from 'ngx-linky'; 
import { ListChildrenLeadComponent } from './list-children-lead/list-children-lead.component';
// import { ItemAttributeComponent } from '../all-reusables/dashboard-reusables/item-attribute/item-attribute/item-attribute.component';
import { MirrorColumnOptionsComponent } from './mirror-column-options/mirror-column-options.component';
import { GroupMemberComponent } from './group-member/group-member.component';
import { SharedComponentsModule } from '../all-reusables/shared-components/shared.components.module';
import { DashboardReusablesModule } from '../all-reusables/dashboard-reusables/dashboard-reusables.module';
import { LeadReusableModule } from '../all-reusables/lead-reusables/lead-reusables.module';
import { TranslateModule } from '@ngx-translate/core';
import { SprintTransferComponent } from './sprint-transfer/sprint-transfer.component';



@NgModule({
    declarations: [
        TruncateDateYearPipe,
        KeyValuePipeComponent,
        DashboardSprintComponent,
        ListChildrenLeadComponent,
        MirrorColumnOptionsComponent,
        GroupMemberComponent,
        SprintTransferComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        NgbModule,
        MentionModule,
        ReusableRoutingModule,
        ReusableModule,
        AutosizeModule,
        InfiniteScrollModule,
        NgxPaginationModule,
        PopoverModule.forRoot(),
        NgxSummernoteModule,
        NgxDocViewerModule,
        DpDatePickerModule,
        ReactiveFormsModule,
        FormsModule,
        DragDropModule,
        PopoverModule,
        LinkyModule,
        SharedComponentsModule,
        LeadReusableModule,
        TranslateModule
        // DashboardReusablesModule
    ],
    exports: [
        TruncateDateYearPipe,
        FormsModule,
        ReactiveFormsModule,
        DashboardSprintComponent,
        ListChildrenLeadComponent,
        MirrorColumnOptionsComponent,
        GroupMemberComponent,
        SprintTransferComponent
    ]
})
  export class ReusableComponentModule { }