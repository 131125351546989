
<div class="table-content h-100" >
   <div class="table-content-inner" #viewport [class.table-with-gantt]="gridSettingConfig?.ganttChart" [class.collapse-table]="gantConfig.tableCollapse || gantConfig.tableViewWidth==0" >
      <div class="table-wrapper" cdkDropListGroup  cdkScrollable (scroll)="onScroll($event);gantContainer && gantContainer.nativeElement.scrollTop=tableContainer.scrollTop" #tableContainer [style.width.px]="gridSettingConfig?.ganttChart ? (gantConfig.tableCollapse ? 0 : gantConfig?.tableViewWidth) : null">
         <div *ngIf="gridSettingConfig?.ganttChart" class="group-header-wrapper table-common-header">
            <div class="table-row table-header-row">
               <ng-container *ngTemplateOutlet="headerTemp;context:{table:{},tableIndex:-1}"></ng-container>
            </div>
         </div>
         <ng-container *ngFor="let table of tableGroupData;let groupIndex=index">
         <div *ngIf="checkToGroup()>=groupIndex || checkToGroup()==-1" class="table-group-wrapper" [id]="'table_'+table.tableId" [class.collapse-table]="table?.isCollapsed" >
            <!--table group header start here -->
            <div class="group-header-wrapper">
               <div class="group-info-row" *ngIf="table?.tableInfo">
                  <div class="group-info-inner">
                     <ng-container *ngIf="!table?.tableInfo?.showLoader">
                        <div class="collapse-table-btn" (click)="collapsedOrExpandTable([table?.tableId],!table?.isCollapsed)">
                           <i class="angle fa fa-2x mr-1" style="font-size:17px" [ngClass]="{'fa-angle-right': table?.isCollapsed, 'fa-angle-down': !table?.isCollapsed}"></i>  
                        </div>
                        <div class="table-name" [placement]="'top'" container="body" [popover]="table?.tableInfo?.tableName?.length>70 && (table?.tableInfo?.tableName)" triggers='hover' containerClass="tableDetailsTooltip">{{table?.tableInfo?.tableName | truncate : 70}}</div>
                        <div class="record-count">{{'('+table.totalRecordCount+')'}}</div>
                        <ng-container *ngFor="let temp of table?.tableInfo?.infoTemplates">
                           <div *ngIf="temp" class="table-info-template">
                              <ng-container *ngTemplateOutlet="temp; context:{ $implicit:table?.tableInfo}"></ng-container>
                           </div>
                        </ng-container> 
                     </ng-container>
                     <ng-container *ngIf="table?.tableInfo?.showLoader">
                        <div class="shimmer-container shimmer-loader" style="width:200px;"></div> 
                     </ng-container>
                  </div>
               </div>
               <div *ngIf="!gridSettingConfig?.ganttChart" class="table-row table-header-row" #tableRow>
                  <ng-container *ngTemplateOutlet="headerTemp;context:{table:table,tableIndex:groupIndex}"></ng-container>
               </div>
            </div>
            <!--table group header end here -->
            <!--table group content  start here -->
            <div *ngIf="!table?.isCollapsed" class="group-body-wrapper"  #container1 cdkDropList (cdkDropListDropped)="onItemRowDrop($event,table)" [cdkDropListData]="table?.rowData" cdkDropListLockAxis="y" [ngStyle]="{'pointer-events': draggingInfo['columnDragging'] ? 'none' : ''}" [infiniteScrollThrottle]="40" [cdkDropListAutoScrollStep]="10">
               <ng-container *ngFor="let item of table?.rowData;let index=index;trackBy : trackByFn"  >
                  <div  class="table-row table-body-row" [row-id]="item.rowId" #tableRow [ngClass]="{'empty-row' : item?.emptyRow }" cdkDrag cdkDragPreviewContainer="parent" [cdkDragDisabled]="item?.emptyRow || !enabledRowDrag || true" cdkDragBoundary=".table-content"  (cdkDragStarted)="draggingInfo['rowDragging']=true;draggingInfo['draggingRowId']=item.rowId;previousRowTable=table" (cdkDragReleased)="rowDragEnd($event)" (click)="item?.emptyRow && emptyRowConfig?.focusOnColumnKey && enabledDisabledRowInputBox(emptyRowConfig?.focusOnColumnKey,item?.rowId,true);">
                     <ng-container *ngIf="isRowDataVisibile(tableRow,item.rowId)">
                        <ng-container *ngTemplateOutlet="rowTemp; context:{item:item,table:table,index:index,tableIndex:groupIndex}"></ng-container>
                     </ng-container>
                  </div>
                  <ng-container *ngIf="subGridConfig[item.rowId] && item?.isSubGridCollapse && !subGridConfig[item.rowId]?.loading">
                     <ng-container *ngTemplateOutlet="innerGridTable; context:{itemRowId:item?.rowId}"></ng-container>
                  </ng-container>
               </ng-container>
               <!-- row loader rowa -->
               <ng-container *ngIf="table.requireScrolling && !table?.isCollapsed && loaderArr?.length>0">
                  <ng-container *ngFor="let item of loaderArr;let index=index;trackBy : trackByFn"  >
                     <div  class="table-row table-body-row">
                        <ng-container *ngTemplateOutlet="rowTemp; context:{item:item,table:table}"></ng-container>
                     </div>
                  </ng-container> 
               </ng-container>
                <!-- empty row when rowdata is empty (note : row add because empty rowData has problem of drop) -->
                <!-- <div *ngIf="!table?.rowData?.length" class="table-row table-body-row" #tableRow  style="height: 5px;" cdkDrag >
               </div> -->
            </div>
            <div class="group-footer-wrapper" *ngIf="table?.createRowConfig && !table?.isCollapsed && !table.requireScrolling">
               <!-- create  row -->
               <div *ngIf="table?.createRowConfig" class="table-row table-body-row create-row" (click)="table?.createRowConfig?.editable && focusOnCreateRow(table);onCellClick(table?.createRowConfig.onClick,null,null,table,null)">
                  <ng-container >
                     <ng-container *ngFor="let pinnedInfo of pinnedData?.pinnedTypes">
                        <div [ngClass]="pinnedInfo?.pinned ? 'table-pinned-column-wrapper' : 'table-unpinned-column-wrapper'">
                           <ng-container *ngIf="pinnedInfo?.pinned && hierarchyLevel>0"> <ng-container *ngTemplateOutlet="arrowTemp"></ng-container></ng-container>
                           <div  class="table-column-wrapper-inner">
                              <ng-container *ngFor="let column of pinnedData?.categoriesColumnData[pinnedInfo?.pinned || false];let colIndex=index">
                                 <div class="column-header" [ngClass]="column?.cellClass ? column?.cellClass : ''" [style.width.px]="setColumnWidth(column)">
                                    <ng-container *ngFor="let temp of column?.cellTemplates">
                                       <div *ngIf="temp" class="cell-template">
                                          <ng-container *ngTemplateOutlet="temp; context:{ $implicit:null,table:table}"></ng-container>
                                       </div>
                                    </ng-container> 
                                    <ng-container *ngIf="table?.createRowConfig?.columnKey==column.columnKey">
                                       <div  class="column-header-inner editor-input" >
                                          <ng-container *ngIf="table?.createRowConfig?.editable;else notEditable">
                                             <input [type]="column?.inputType || 'text'" placeholder="{{table?.createRowConfig?.placeholder}}" [id]="'createrow_'+table.tableId+'_'+column.columnKey" (blur)="onCreateRowEdit(table,column.columnKey)" (keydown.escape)="onCreateRowEdit(table,column.columnKey)" (keydown.enter)="onCreateRowEdit(table,column.columnKey)" (paste)="onPaste($event,groupIndex,table.rowData.length,colIndex,false,false,true)" [(ngModel)]="createRowData[table.tableId+'_'+column.columnKey]">
                                          </ng-container>
                                          <ng-template #notEditable>
                                             <div class="not-editable">{{table?.createRowConfig?.placeholder}}</div>
                                          </ng-template>
                                       </div>  
                                    </ng-container>
                                 </div>
                              </ng-container>
                           </div>
                        </div>
                     </ng-container>
                  </ng-container>
               </div>
            </div>
            <div class="group-footer-wrapper footer-body" *ngIf="table?.bottomRowData?.length>0">
               <!-- bottom formula row -->
               <ng-container *ngFor="let item of table?.bottomRowData;let index=index">
                  <div class="table-row table-footer-row" #tableRow>
                     <ng-container *ngFor="let pinnedInfo of pinnedData?.pinnedTypes">
                        <div [ngClass]="pinnedInfo?.pinned ? 'table-pinned-column-wrapper' : 'table-unpinned-column-wrapper'" >
                           <ng-container *ngIf="pinnedInfo?.pinned && hierarchyLevel>0"> <ng-container *ngTemplateOutlet="arrowTemp"></ng-container></ng-container>
                           <div class="table-column-wrapper-inner">
                              <ng-container *ngFor="let column of pinnedData?.categoriesColumnData[pinnedInfo?.pinned || false]">
                                 <div  class="column-header" [ngClass]="column?.cellClass ? column?.cellClass : ''"  [style.width.px]="setColumnWidth(column)">
                                    <div class="column-header-inner" [style.width]="column?.cellRenderer ? '100%' : ''">
                                       <div  class="cell-renderer" *ngIf="column?.cellRenderer" [style.width.px]="">
                                          <div  [col-field]="column?.field" [row-id]="'footer_#'+table?.tableId" #viewContainerRef></div>  
                                       </div> 
                                    </div>
                                 </div>
                              </ng-container>
                           </div>
                        </div>
                     </ng-container>
                  </div>
               </ng-container>
            </div>
            <!--table group content end here -->
         </div>
         </ng-container>
      </div>
      <ng-container *ngIf="gridSettingConfig?.ganttChart">
         <div class="gant-wrapper">
            <ng-container *ngTemplateOutlet="ganttTemp"></ng-container>
         </div>
      </ng-container>
   </div>
</div>

<!-- context menu popover -->
<ng-template #rightClickOptions>
   <div #contextMenuRef></div>
</ng-template>

<!-- row template  -->
<ng-template #rowTemp let-item="item" let-table="table" let-index="index" let-tableIndex="tableIndex">
   <ng-container *ngFor="let pinnedInfo of pinnedData?.pinnedTypes">
      <div [ngClass]="pinnedInfo?.pinned ? 'table-pinned-column-wrapper' : 'table-unpinned-column-wrapper'">
         <ng-container *ngIf="pinnedInfo?.pinned && hierarchyLevel>0"> <ng-container *ngTemplateOutlet="arrowTemp"></ng-container></ng-container>
         <div  class="table-column-wrapper-inner" [class.checked-row]="item?.checked">
            <ng-container *ngFor="let column of pinnedData?.categoriesColumnData[pinnedInfo?.pinned || false];let colIndex=index">
               <div class="column-header" #columnRef [class.enable-selection-cell]="sectionCell?.tableIndex==tableIndex && sectionCell?.rowIndex==index && (pinnedInfo?.pinned && sectionCell?.columnIndex==colIndex || !pinnedInfo?.pinned  && sectionCell?.columnIndex==colIndex+pinnedData?.categoriesColumnData[true]?.length)" [row-index]="index" [column-index]="colIndex"
               [class.defer-selection-cell]="enableDeferClass(index,colIndex+(pinnedInfo?.pinned  ? 0 : pinnedData?.categoriesColumnData[true]?.length),tableIndex)"
               (mouseenter)="upadateReselection(tableIndex,index,colIndex,pinnedInfo?.pinned)"
               [ngClass]="column?.cellClass ? column?.cellClass : ''"  #contextmenuPopover="bs-popover" [placement]="null" container="body" [popover]="rightClickOptions" [outsideClick]="true" triggers='manual' containerClass="gridtableCell contextmenuPopup" (contextmenu)="openContextMenuPopover(contextmenuPopover,$event,table,item,column,index)" [style.width.px]="setColumnWidth(column)" (click)="storeCurrentColumnRowIndex($event,tableIndex,index,colIndex,pinnedInfo?.pinned,columnRef);" [class.disabled-column]="!emptyRowConfig?.editableColumnKeys?.includes(column?.columnKey) && item?.emptyRow" >
                  <div class="column-header-wrapper" [class.disabledEdit]="!(sectionCell?.tableIndex==tableIndex && sectionCell?.rowIndex==index && (pinnedInfo?.pinned && sectionCell?.columnIndex==colIndex || !pinnedInfo?.pinned  && sectionCell?.columnIndex==colIndex+pinnedData?.categoriesColumnData[true]?.length)) && column?.doubleClick" (click)="column?.enableEditor && column?.editable && (item?.editable!=false) && enabledDisabledRowInputBox(column?.field,item?.rowId,true,$event);">
                     <div *ngIf="!(column?.onlyEnabledInRow && !column?.onlyEnabledInRow?.matchKeyValue?.includes(item[column?.onlyEnabledInRow?.matchKey])) && !(editRowColumnData?.rowId==item.rowId && editRowColumnData?.columnField==column.field) || column?.enableEditor" [style.display]="editRowColumnData?.rowId==item.rowId && editRowColumnData?.columnField==column.field || showLoader(item,column)? 'none' : ''" class="column-header-inner" [style.width]="column?.cellRenderer ? '100%' : ''">
                        <ng-container *ngFor="let temp of column?.cellTemplates">
                           <div *ngIf="temp" class="cell-template">
                              <ng-container *ngTemplateOutlet="temp; context:{ $implicit:item,table:table}"></ng-container>
                           </div>
                        </ng-container>  
                        <!-- row drag handle -->
                        <div *ngIf="column?.rowDragHandle && enabledRowDrag" class="drag-handle" cdkDragHandle><img src="../../../assets/1x/drag-dots.png"></div>
                        <!-- row selection checkbox  -->
                        <div *ngIf="column?.selectionCheckbox" class="checkbox-input" (click)="$event.stopPropagation()" ><input [disabled]="item?.emptyRow || !column?.editable || table.disabledCheckbox || (singleSelection && singleSelection!=item._id)"  [checked]="table.checked && !item?.emptyRow" [(ngModel)]="item.checked" (change)="onCheckboxChange(column,item,table?.tableId,item.checked,'row')"  type="checkbox"/></div>
                        <div *ngIf="column?.insertEmptyRowButton && !hierarchyLevel" class="add-row-button" (click)="$event.stopPropagation();!item?.emptyRow && (column?.insertEmptyRowButtonConfig?.insertRow || column?.insertEmptyRowButtonConfig?.insertRow===null) && addEmptyRow(item,table);onCellClick(column?.insertEmptyRowButtonConfig?.onClick,column,item,table,index)"><i class="fa fa-plus"></i></div>
                        <!-- custom component insert template -->
                        <div  class="cell-renderer" *ngIf="column?.cellRenderer">
                           <div  [col-field]="column?.field" [row-id]="item.rowId" #viewContainerRef></div>  
                        </div> 
                        <!-- value data -->
                        <div *ngIf="!column?.cellRenderer && item[column?.field]"  class="column-title" >{{item[column?.field]}}</div>
                     </div>
                     <div *ngIf="column?.locked" class="locked-box" [placement]="'top'" container="body" [popover]="column.lockedTooltipText && (column.lockedTooltipText)" triggers='hover' containerClass="tableDetailsTooltip">
                        <i class="fas fa-lock"></i>
                     </div>
                     <div *ngIf="column?.onlyEnabledInRow && !column?.onlyEnabledInRow?.matchKeyValue?.includes(item[column?.onlyEnabledInRow?.matchKey])" class="blocked-box"></div>
                     <ng-container *ngIf="!showLoader(item,column) && column?.enableEditor && column?.editable && (editRowColumnData?.rowId==item.rowId && editRowColumnData?.columnField==column.field)">
                        <div  class="column-header-inner editor-input"><input [type]="column?.inputType || 'text'" [ngClass]="{'emptyRowInput':item?.emptyRow && !(editRowColumnData?.rowId==item.rowId && editRowColumnData?.columnField==column.field)}" placeholder="Add value" [id]="column.field+'_'+item.rowId" (blur)="onUpdateCell(item[column?.field],column.field,item)" (keydown.enter)="$event.preventDefault();onUpdateCell(item[column?.field],column.field,item)" [(ngModel)]="item[column?.field]" (paste)="item?.emptyRow && onPaste($event,tableIndex,index,colIndex)"></div>  
                     </ng-container>
                     <ng-container *ngIf="showLoader(item,column)">
                        <div *ngIf="column?.cellLoaderTemplate;else defaultLoader">
                           <ng-container *ngTemplateOutlet="column?.cellLoaderTemplate"></ng-container>
                        </div>
                        <ng-template #defaultLoader>
                        <div class="shimmer-container" [ngClass]="column?.cellLoaderClass ? column?.cellLoaderClass : 'shimmer-loader'"></div> 
                        </ng-template>
                     </ng-container>
                  </div>
                  <ng-container *ngIf="enableSelection(tableIndex,index,colIndex,pinnedInfo?.pinned)">
                     <div class="selector-drag-handle" (mousedown)="startReseection()"></div>
                  </ng-container>
               </div>
            </ng-container>
         </div>
      </div>
   </ng-container>
</ng-template>

<!-- sub grid  -->
<ng-template #innerGridTable let-itemRowId="itemRowId">
   <div class="inner-subgrid-table">
      <grid-table #gridTableRef
      [hierarchyLevel]="hierarchyLevel+1"
      (onGridTableReady)="subGridConfig[itemRowId].gridTableRef=$event"
      [columnData]="columnData"
      [pinnedData]="pinnedData"
      [subGridConfig]="subGridConfig"
      [contextMenuConfig]="contextMenuConfig" 
      [tableGroupData]="subGridConfig[itemRowId]" 
      [columnConfig]="columnConfig"
      [globalParams]="globalParams" 
      (onColumnResize)="onColumnResize?.emit($event)" 
      (onRowDrop)="onRowDrop?.emit($event)"
      (onCellValueChanged)="onCellValueChanged?.emit($event)"
      (onColumnMove)="onColumnMove?.emit($event)"
      (onSelectionChange)="onSelectionChange?.emit($event)"
      (onScrolling)="onScrolling.emit($event)">
      </grid-table>
   </div>
</ng-template>

<ng-template #arrowTemp>
   <div [style.width.px]="hierarchyLevel*20" style="display: flex;">
      <div *ngFor="let item of [].constructor(hierarchyLevel);let i=index" class="allow-column" [style.width.px]="20">
         <div *ngIf="i==hierarchyLevel-1" class="allow-column-inner"></div>
      </div>
   </div>
</ng-template>

<ng-template #headerTemp let-groupIndex="tableIndex" let-table="table">
   <ng-container *ngFor="let pinnedInfo of pinnedData?.pinnedTypes">
      <div [ngClass]="{'table-pinned-column-wrapper':pinnedInfo?.pinned, 'unpinned-item-drag':pinnedInfo?.pinned && draggingInfo?.pinned===false, 'unpinned-item-entered' : pinnedInfo?.pinned && draggingInfo?.enablePinnedListInfoBox ,'table-unpinned-column-wrapper' : !pinnedInfo?.pinned  }"  cdkDropList 
      [cdkDropListData]="pinnedData?.categoriesColumnData[pinnedInfo?.pinned || false]" (cdkDropListDropped)="onColumnDrop($event,pinnedInfo?.pinned)" cdkDropListOrientation="horizontal" [ngStyle]="{'pointer-events': draggingInfo['rowDragging'] ? 'none' : ''}">
         <ng-container *ngIf="pinnedInfo?.pinned && hierarchyLevel>0"> <ng-container *ngTemplateOutlet="arrowTemp"></ng-container></ng-container>
         <div class="table-column-wrapper-inner" [style.width.px]="pinnedInfo?.pinned && draggingInfo?.pinnedDefaultwidth &&  draggingInfo?.enableUnpinnedListInfoBox ? draggingInfo?.pinnedDefaultwidth : ''">
            <ng-container *ngFor="let column of pinnedData?.categoriesColumnData[pinnedInfo?.pinned || false]">
               <div  class="column-header" [style.width.px]="setColumnWidth(column)" [class.grabCursor]="column?.enableColumndrag" cdkDrag cdkDragPreviewContainer="parent" (cdkDragStarted)="onDragStart(pinnedInfo?.pinned)"  (cdkDragEntered)="onDragEntered($event,pinnedInfo?.pinned)" [cdkDragDisabled]="!column?.enableColumndrag || initialData?.resizing && initialData?.column?.columnKey==column?.columnKey">
                  <!-- column  drag preview  -->
                  <div *cdkDragPreview class="columnCdkDragPreview" [style.width.px]="setColumnWidth(column)">
                     <div class="column-header-info">
                        <div class="column-header-inner"><span class="column-title">{{column?.headerName}}</span></div>
                     </div>
                  </div>
                  <div class="column-header-info" #columnOptions="bs-popover" [popover]="columnConfig?.columnOptionsTemp && columnConfig?.columnOptionsTemp" placement="bottom" [outsideClick]="true" container="body" [popoverContext]="{'column':column,popoverRef:columnOptions}" triggers="manual" containerClass="gridtableCell">
                     <div class="column-header-inner">
                        <!-- table selection checkbox  -->
                        <div *ngIf="column?.selectionCheckbox && groupSelection"  class="checkbox-input" [class.enable-selection-cell]="sectionCell.tableIndex==groupIndex && sectionCell.rowIndex==-1"><input type="checkbox" (change)="onCheckboxChange(column,table,table?.tableId,table.checked,'table')" [(ngModel)]="table.checked"/></div>
                        <span class="column-title">{{column?.headerName}}<span *ngIf="column?.required" class="require-icon"> *</span></span>
                     </div>
                     <ng-container *ngIf="columnConfig?.columnTemp">
                        <ng-container *ngFor="let temp of columnConfig?.columnTemp">
                           <ng-container *ngTemplateOutlet="temp; context:{'column':column}"></ng-container>
                        </ng-container> 
                     </ng-container>
                     <div *ngIf="column?.columnHeader?.enableOptionsBtn && columnConfig?.columnOptionsTemp" class="column-options-btn" (click)="columnOptions?.show()"> <img src="../../../assets/common-use-icon-20dp/threeDots-icon.svg" ></div>
                  </div>
                  <div *ngIf="column?.resizable && !draggingInfo?.columnDragging && !draggingInfo?.rowDragging && (!initialData?.resizing || initialData?.column?.columnKey==column?.columnKey)" class="resizer-handle" (mousedown)="startResize($event,column)">
                     <div class="resizer-handle-inner" [style.display]="initialData?.column?.columnKey==column?.columnKey ? 'block' : ''" ></div>
                  </div>
               </div>
            </ng-container>
            <ng-container *ngIf="columnConfig?.enabledPinned && pinnedInfo?.pinned && draggingInfo?.enablePinnedListInfoBox" >
               <div class="pinned-placeholder">
                  <div *ngIf="columnConfig?.maxPinnedCount<=pinnedData?.categoriesColumnData[pinnedInfo?.pinned || false]?.length;else dropHere"  style="color: red;"><b>{{ 'Only Allow '+columnConfig?.maxPinnedCount+' pinned columns'}}</b></div>
                  <ng-template #dropHere><div>Drop here to Pinned</div></ng-template>
               </div>
            </ng-container>
         </div>
      </div>
   </ng-container>
</ng-template>

<ng-template #ganttTemp>
   <div class="gantt-container"  [ngClass]="{'gantt-dark-mode':gantConfig.enableDarkMode}" >
      <div class="container-resize-handler">
         <div class="container-resize-handler-inner" (mousedown)="startResize($event,'ganttViewport')">
            <div class="collapse-handle" (mousedown)="$event.stopPropagation()" (click)="collapseGridInGantt($event)">
               <i *ngIf="gantConfig?.tableCollapse" class="fas fa-chevron-right p-0"></i>
               <i *ngIf="!gantConfig?.tableCollapse" class="fas fa-chevron-left p-0"></i>
            </div>
         </div>
      </div>
      <div class="gantt-setting-btn" cdkDrag cdkDragBoundary=".gantt-container">
         <div class="gantt-options-btn">
            <div class="option">
               <div class="option-inner"  #timePopover="bs-popover" [placement]="'bottom'" [popover]="gantTimeOptions" (click)="timePopover.show()" triggers="manual" [outsideClick]="true" containerClass="gridtableCell">
                  <span>{{gantConfig?.currentChartType}}</span>
                  <span>
                     <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 9L12 15L18 9" stroke="#353E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                     </svg>
                  </span> 
               </div>
            </div>
            <div  class="option">
               <div class="option-inner" [class.disable-option-Btn]="gantConfig?.currentChartType==gantConfig?.gantChartViewTypes[gantConfig?.gantChartViewTypes?.length-1]" (click)="zoomInOut(1)">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M7 10H13M10 7V13M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z" stroke="#353E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
               </div>
            </div>
            <div class="option" style="border: none;">
               <div [class.disable-option-Btn]="this.gantConfig.currentChartType==gantConfig.gantChartViewTypes[0]" (click)="zoomInOut(-1)" class="option-inner">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M7 10H13M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z" stroke="#353E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
               </div>
            </div>
            <div   class="drag-setting-bar-btn" (mouseenter)="event?.stopPropagation();">
               <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 9L21 12M21 12L18 15M21 12H15M6 9L3 12M3 12L6 15M3 12H9M9 18L12 21M12 21L15 18M12 21V15M15 6L12 3M12 3L9 6M12 3V9" stroke="#353E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
            </div>
         </div>
         <ng-template #themeOptions>
            <div style="width: 112px;">
               <div  class="gant-time-option form-switch " style="justify-content: start;">
                  <input class="form-check-input m-0" style="position: unset;" type="checkbox" role="switch" id="flexSwitchCheckDefault" [(ngModel)]="gantConfig.enableDarkMode"> Dark Mode
               </div>
            </div>
         </ng-template>
         <ng-template #gantTimeOptions>
            <div style="width: 68px;">
               <div (click)="createGantHeader('Years');timePopover?.hide()" class="gant-time-option">Years</div>
               <div (click)="createGantHeader('Quarters');timePopover?.hide()" class="gant-time-option">Quaters</div>
               <div (click)="createGantHeader('Months');timePopover?.hide()" class="gant-time-option">Months</div>
               <div (click)="createGantHeader('Weeks');timePopover?.hide()" class="gant-time-option">Weeks</div>
               <div (click)="createGantHeader('Days');timePopover?.hide()" class="gant-time-option">Days</div>
            </div>
         </ng-template>
      </div>
      <div class="gantt-wrapper" (scroll)="tableContainer && tableContainer.scrollTop=gantContainer.scrollTop;onGanttScroll($event)" #gantContainer>
        <div class="gantt-wrapper-inner">
         <!-- gantt chart header start here   -->
         <div class="gantt-header group-header-wrapper table-common-header">
            <div class="gantt-header-wrapper">
               <ng-container *ngFor="let field of gantHeader">
                  <div class="date-field-wrapper">
                     <div class="main-date-field">{{field.label}}</div>
                     <div class="sub-date-field">
                        <ng-container *ngFor="let sub of field?.subValues">
                           <div class="sub-date-field-inner" [style.width.px]="getHeaderDummySubCellWidth()">{{sub?.subLabel}}</div>
                        </ng-container>
                     </div>
                  </div>
               </ng-container>
            </div>
         </div>
         <!-- gantt chart header end here   -->
         <div class="gantt-body-wrapper"> 
            <!-- gantt backgroup start here (it a layer that is showing in background)  -->
            <div class="gantt-background-container">
               <ng-container *ngFor="let field of gantHeader">
                  <ng-container *ngFor="let sub of field?.subValues;let index=index">
                     <div [class.weekend]="sub.weekday===0 || sub.weekday===6 || field.weekday==0 || field.weekday==6" [class.partision-border]="index==0 || index==field?.subValues?.length" [style.width.px]="getHeaderDummySubCellWidth()"></div>
                  </ng-container>
               </ng-container>
            </div>
            <!-- gantt backgroup start here   -->
            <ng-container *ngTemplateOutlet="ganttContent;context:{tableGroupDataList:tableGroupData}"></ng-container>
            <ng-container *ngIf="gantConfig?.setDependencyData">
               <svg class="dependency-apply-arrow" >
                  <line [attr.x1]="gantConfig?.setDependencyData?.startX" [attr.y1]="gantConfig?.setDependencyData?.startY" [attr.x2]="gantConfig?.setDependencyData?.mouseX" [attr.y2]="gantConfig?.setDependencyData?.mouseY" marker-end="url(#arrowhead)" class="arrow-line"></line>
                  <defs>
                    <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
                      <polygon points="0 0, 10 3.5, 0 7" />
                    </marker>
                  </defs>
                </svg>
            </ng-container>
         </div>
        </div>
      </div>
   </div>
</ng-template>

<ng-template #ganttContent let-tableGroupDataList="tableGroupDataList">
   <ng-container *ngFor="let table of tableGroupDataList;let groupIndex=index">
      <div *ngIf="checkToGroup()>=groupIndex || checkToGroup()==-1" class="table-group-wrapper w-100" [id]="'gantt_'+table.tableId" >
         <div class="group-header-wrapper">
            <div class="group-info-row" *ngIf="table?.tableInfo">
            </div>
         </div>
         <div *ngIf="!table?.isCollapsed" class="group-body-wrapper" >
            <ng-container *ngFor="let item of table?.rowData;let index=index;trackBy : trackByFn"  >
               <div class="table-row gantt-body-row" #itemRef (mousemove)="changeBarPosition($event,item)" [class.not-allow-dependency-drop]="gantConfig?.setDependencyData && gantConfig?.setDependencyData?.dragging && gantConfig?.setDependencyData?.item && gantConfig?.setDependencyData?.item[gantConfig?.dependenciesValidation?.itemKeyMatch]!=item[gantConfig?.dependenciesValidation?.itemKeyMatch]">
                  <!-- focus on current item bar  -->
                  <div *ngIf="item.startDate?.time || item.endDate?.time" class="fetch-to-bar">
                     <div class="bar-focus-btn" (click)="scrollToBar(item)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                           <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                        </svg>
                     </div>
                  </div>
                  <div  class="item-bar"[id]="'gantt_'+item.rowId"  [style.left.px]="(item.startDate?.time || item.endDate?.time) ? calculateLeft(item?.startDate?.time,item.endDate?.time,item.rowId) : gantConfig?.moveBar?.mouseEvent" [class.dragging-bar]="item.rowId==barDragData.rowId || item.rowId==gantConfig?.setDependencyData?.item?.rowId" [class.empty-date-item]="!item.startDate?.time && !item.endDate?.time" [draggable]="false">
                     <!-- item date range bar -->
                     <div *ngIf="gantConfig?.editable && item.editable!=false && !gantConfig?.setDependencyData?.dragging && !item.startDate?.time && !item.endDate?.time && gantConfig?.moveBar?.rowId==item?.rowId || item.startDate?.time || item.endDate?.time"
                     [bar_row_id]="item.rowId"
                     class="item-bar-inner" [style.background-color]="item?.ganttInfo?.barColor" [style.width.px]="getBarWidth(item?.startDate?.time,item?.endDate?.time,item.rowId)"  (click)="!item.startDate?.time && !item.endDate?.time && gantConfig.moveBar.freezBar=true"
                     #barInfoPopover="bs-popover" [placement]="null" container="body" [popover]="gantConfig?.barInfoTemp" [popoverContext]="{ item: item,popoverRef: barInfoPopover}" [outsideClick]="true" containerClass="gridtableCell bar-info-popover" triggers="manual" (click)="openBarInfoPopover($event,barInfoPopover,item)" 
                     ></div>
                     <!-- dependency start here -->
                     <div class="dependency-arrow" *ngIf="item?.dependencies?.dependents && item.startDate?.time && item.endDate?.time">
                        <div class="start-line d-line"></div>
                        <ng-container *ngFor="let dependency of getDependentItemInfoList(item)">
                           <div class="wrap-line " [style]="dependency.below ? 'top:0px;' : 'bottom:8px;flex-direction:row-reverse;'">
                              <div class="verticle-line d-line" [style.height.px]="(getHeaderDummySubCellWidth()*(dependency?.index-dependency.currentIdIndex)-(dependency.leftCorner ? 0 : 15)+(dependency.below ? 0 : 4))"></div>
                              <div class="horizontal-line d-line" [style.width.px]="dependency.widthOfHorizontal+(dependency.leftCorner ? -50 : 50)" [style.left.px]="dependency.leftCorner ? 0 : null" [style.right.px]="dependency.leftCorner ? null : 0"></div>
                              <div class="end-wrapper" [style]="dependency.leftCorner ? ('right:'+'-'+(dependency.widthOfHorizontal+(dependency.leftCorner ? -32 : 32)+(dependency.below ? 0 : 4))+'px;bottom:-8px;justify-content:end;') : ('left:'+'-'+(dependency.widthOfHorizontal+(dependency.leftCorner ? -50 : 50))+'px;')" [style.top.px]="dependency.below ? 25 : 0" >
                                 <div *ngIf="!dependency.leftCorner" class="verticle-line-arrow d-line" [style.height.px]="18"></div>
                                 <div class="horizontal-line-arrow d-line"></div>
                                 <div class="link-arrow" ></div>
                              </div>
                           </div>
                        </ng-container>
                     </div>
                     <!-- dependency end here -->
                     <!-- left bar handle start here -->
                     <div class="task-bar-handle task-from-date" >
                        <ng-container *ngIf="gantConfig?.editable && item.editable!=false && (!gantConfig?.setDependencyData?.dragging || item.rowId==gantConfig?.setDependencyData?.item?.rowId)">
                           <div *ngIf="item.startDate?.time || item.endDate?.time" class="item-link-point" (mousedown)="onMouseDown($event, item)"></div>
                           <div class="item-drag" (mousedown)="startBarResizing('from',$event,item)">
                              <i class="fas fa-chevron-left"></i>
                           </div>
                        </ng-container>
                     </div>
                     <!-- left bar handle end here -->
                     <!-- right bar handle start here -->
                     <div class="task-bar-handle task-to-date">
                        <ng-container *ngIf="gantConfig?.editable && item.editable!=false && (!gantConfig?.setDependencyData?.dragging || item.rowId==gantConfig?.setDependencyData?.item?.rowId)">
                           <div class="item-drag" (mousedown)="startBarResizing('to',$event,item)">
                              <i class="fas fa-chevron-right"></i>
                           </div>
                           <div *ngIf="item.startDate?.time || item.endDate?.time" class="item-link-point" (mousedown)="onMouseDown($event, item)"></div>
                        </ng-container>
                     </div>
                     <!-- right bar handle end here -->
                     <div *ngIf="item?.ganttInfo?.captionText && (item.startDate?.time || item.endDate?.time)" class="item-caption"><span class="item-caption-text">{{item?.ganttInfo?.captionText}}</span></div>
                  </div>
               </div>
               <!-- subtask gannt append here -->
               <ng-container *ngIf="subGridConfig[item.rowId] && item?.isSubGridCollapse && !subGridConfig[item.rowId]?.loading">
                  <ng-container *ngTemplateOutlet="ganttContent; context:{tableGroupDataList:subGridConfig[item?.rowId]}"></ng-container>
               </ng-container>
            </ng-container>     
         </div>
         <div class="group-footer-wrapper" *ngIf="table?.createRowConfig && !table?.isCollapsed && !table.requireScrolling">
            <!-- create  row -->
            <div *ngIf="table?.createRowConfig" class="table-row table-body-row create-row" (click)="table?.createRowConfig?.editable && focusOnCreateRow(table);">
            </div>
         </div>
         <div class="group-footer-wrapper footer-body"  *ngIf="table?.bottomRowData?.length>0">
            <!-- bottom formula row -->
            <ng-container *ngFor="let item of table?.bottomRowData;let index=index">
               <div class="table-row table-footer-row" #tableRow></div>
            </ng-container>   
         </div>
      </div>
   </ng-container>
</ng-template>