<input  placeholder="Action Duration" type="text" class="numberDuration" [readonly]="readonly"
(blur)="!readonly && onChange();" (keydown.enter)="!readonly && onChange();showSuggestion=false" [id]="name" (input)="!readonly && loadSuggestions()"
[(ngModel)]="valueString"/>

<div class="suggetions" *ngIf="!readonly && showSuggestion">
    <div style=" width: 100%;" *ngFor="let suggestion of suggestionList;let index=index">
        <div class="durationOpt"  [class.selectedDurationOpt]="selectedIndex==index" (mouseenter)="selectedIndex=index"  (click)="suggestionClicked(suggestion)">
          <span>{{suggestion}}</span>
        </div>
      </div>
</div>