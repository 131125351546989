<ng-container *ngIf="isLoader"> 
    <div class="d-flex justify-content-center align-items-center my-2">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">{{'editcustom.loading' | translate}}  </span>
      </div>
    </div> 
</ng-container>
<ng-container *ngIf="!isLoader && listOfLeads?.length==0">
    <div style="display: flex;justify-content: center;align-items: center;color: #6d6e6f80;"><span>No leads connected</span></div>
</ng-container>
<ng-container *ngIf="!isLoader &&listOfLeads?.length>0">
    <div style="display: flex;flex-wrap: wrap;" class="contact-and-org-connected-lead">
        <ng-container *ngFor="let item of listOfLeads; let index=index;trackBy:trackByFnList">
            <div>
                <div style="display: flex;padding: 5px;" class="contact-each-lead" role="button">
                    <div style="display: flex;" placement="auto" #popChartOptions="ngbPopover" popoverClass="modifiedPopover" [ngbPopover]="contactUrlEdit" container="body" triggers="hover" [autoClose]="'outside'" (click)="onItemOpen?.emit(item);$event?.stopPropagation()">
                
                        <ng-container *ngIf="item?.custom_fields['logo_yAqhpCq5_simg']?.file_url || item?.custom_fields['profile_pic_29N2eLvy_simg']?.file_url">
                            <ngx-avatars class="mr-1" [name]="item?.title" [src]="item?.custom_fields['logo_yAqhpCq5_simg']?.file_url || item?.custom_fields['profile_pic_29N2eLvy_simg']?.file_url" size="25" initialsSize="2" ></ngx-avatars>
                        </ng-container>
                        <ng-container *ngIf="!item?.custom_fields['logo_yAqhpCq5_simg']?.file_url && !item?.custom_fields['profile_pic_29N2eLvy_simg']?.file_url">
                            <ngx-avatars class="mr-1" [name]="item?.title" [src]="item?.title" size="25" initialsSize="2"></ngx-avatars>
                        </ng-container>
                        <span>{{item?.title}}</span>
                    </div>
                    <div class="show-lead-remove-icon">
                        <span role="button" (click)="removeConnectedLead(index,item?._id)"><i class="fa fa-times" aria-hidden="true"></i></span>
                    </div>
                </div>
            </div>
            <ng-template #contactUrlEdit>
                <div style="padding:5px">
                    <div>
                        <div style="background-color: #EFF2F7;width:100%;height: 70px;border-radius: 5px;" class="add-url-text">
                            <div style="display: flex;justify-content: center;padding: 10px;">
                                <ng-container *ngIf="item?.custom_fields['logo_yAqhpCq5_simg']?.file_url || item?.custom_fields['profile_pic_29N2eLvy_simg']?.file_url">
                                    <ngx-avatars class="mr-1" [name]="item?.title" [src]="item?.custom_fields['logo_yAqhpCq5_simg']?.file_url || item?.custom_fields['profile_pic_29N2eLvy_simg']?.file_url" size="25" initialsSize="2" ></ngx-avatars>
                                </ng-container>
                                <ng-container *ngIf="!item?.custom_fields['logo_yAqhpCq5_simg']?.file_url && !item?.custom_fields['profile_pic_29N2eLvy_simg']?.file_url">
                                    <ngx-avatars class="mr-1" [name]="item?.title" [src]="item?.title" size="25" initialsSize="2"></ngx-avatars>
                                </ng-container>
                                <span>{{item?.title}}</span>
                            </div>
                            <div style="display: flex; padding-left: 10px;">
                                <span class="icon-width-css">
                                    <a *ngIf="item.custom_fields['contact_user_e2NB6Dm3j'] || item.custom_fields['contact_user_x2uQrF0e3']" [href]="getUrlValue(['contact_user_e2NB6Dm3j', 'contact_user_x2uQrF0e3'])" target="_blank">
                                        <app-dashboard-icon [size]="18" [name]="'NEW_FACEBOOK'"></app-dashboard-icon>
                                    </a>
                                    <app-dashboard-icon *ngIf="!item.custom_fields['contact_user_e2NB6Dm3j'] && !item.custom_fields['contact_user_x2uQrF0e3']" [size]="18" [name]="'NEW_FACEBOOK'" class="grayScale-icon"></app-dashboard-icon>
                                </span>
                                <span class="icon-width-css">
                                    <a *ngIf="item.custom_fields['contact_user_hp77fmHtX'] || item.custom_fields['contact_user_TJe6L0Er5']" [href]="getUrlValue(['contact_user_hp77fmHtX', 'contact_user_TJe6L0Er5'])" target="_blank">
                                        <app-dashboard-icon [size]="12" [name]="'LINKDIN'"></app-dashboard-icon>
                                    </a>
                                    <app-dashboard-icon *ngIf="!item.custom_fields['contact_user_hp77fmHtX'] && !item.custom_fields['contact_user_TJe6L0Er5']" [size]="12" [name]="'LINKDIN'" class="grayScale-icon"></app-dashboard-icon>
                                </span>
                                <span class="icon-width-css">
                                    <a *ngIf="item.custom_fields['contact_user_q8M5zYwj2'] || item.custom_fields['contact_user_9XfYrmFu7']" [href]="getUrlValue(['contact_user_q8M5zYwj2', 'contact_user_9XfYrmFu7'])" target="_blank">
                                        <app-dashboard-icon [size]="12" [name]="'INSTAGRAM'"></app-dashboard-icon>
                                    </a>
                                    <app-dashboard-icon *ngIf="!item.custom_fields['contact_user_q8M5zYwj2'] && !item.custom_fields['contact_user_9XfYrmFu7']" [size]="12" [name]="'INSTAGRAM'" class="grayScale-icon"></app-dashboard-icon>
                                </span>
                                <span class="icon-width-css">
                                    <a *ngIf="item.custom_fields['contact_user_qTX2Jx0j2'] || item.custom_fields['contact_user_K28bcgPZL']" [href]="getUrlValue(['contact_user_qTX2Jx0j2', 'contact_user_K28bcgPZL'])" target="_blank">
                                        <app-dashboard-icon [size]="12" [name]="'LINE'"></app-dashboard-icon>
                                    </a>
                                    <app-dashboard-icon *ngIf="!item.custom_fields['contact_user_qTX2Jx0j2'] && !item.custom_fields['contact_user_K28bcgPZL']" [size]="12" [name]="'LINE'" class="grayScale-icon"></app-dashboard-icon>
                                </span>
                            </div>
                        </div>
                        <ng-container *ngIf="item?.custom_fields['contact_email_qiybv1ozk4']">
                            <ng-container *ngFor="let data of item?.custom_fields['contact_email_qiybv1ozk4'];let i=index">
                                <div style="padding-top: 3px;" [ngClass]="{'contact-first-child-color':i==0}">{{data}}</div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="item?.custom_fields['contact_phone_f9eoa8dfuf']">
                            <ng-container *ngFor="let data of item?.custom_fields['contact_phone_f9eoa8dfuf'];let i=index">
                                <div style="padding-top: 3px;display: flex;justify-content: space-between;">
                                    <div [ngClass]="{'contact-first-child-color':i==0}">{{countryData[data?.country_code]?.dial_code}} {{data?.mobile_number}}</div>
                                    <!-- <div>
                                        <i  role="button" class="fa fa-whatsapp mr-2 icons" aria-hidden="true" (click)="WTRef.open()"></i>
                                        <i class="fa fa-clone mr-2 icons" role="button" aria-hidden="true" (click)="copyToClipboardModern((value?.country_code?countryData[value?.country_code]?.dial_code:'')+value.value)"></i>
                                    </div> -->
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="item?.custom_fields['company_phone_number_hir9p69xrx']">
                            <ng-container *ngFor="let data of item?.custom_fields['company_phone_number_hir9p69xrx'];let i=index">
                                <div style="padding-top: 3px;display: flex;justify-content: space-between;">
                                    <div [ngClass]="{'contact-first-child-color':i==0}">{{countryData[data?.country_code]?.dial_code}} {{data?.mobile_number}}</div>
                                    <!-- <div>
                                        <i  role="button" class="fa fa-whatsapp mr-2 icons" aria-hidden="true" (click)="WTRef.open()"></i>
                                        <i class="fa fa-clone mr-2 icons" role="button" aria-hidden="true" (click)="copyToClipboardModern((value?.country_code?countryData[value?.country_code]?.dial_code:'')+value.value)"></i>
                                    </div> -->
                                </div>
                            </ng-container>
                        </ng-container>
                      <div>
                      </div>
                    </div>
                  </div>
            </ng-template>
        </ng-container>
    </div>
    <div>

    </div>
</ng-container>
