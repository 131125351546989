import {
  Directive,
  ElementRef,
  Output,
  HostListener,
  EventEmitter,
} from "@angular/core";

@Directive({
  selector: "[appClickOutSide]",
})
export class ClickOutSideDirective {
  isOutSideClick:boolean=true
  constructor(private elementRef: ElementRef) {}
  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();

  @HostListener("document:click", ["$event", "$event.target"])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (clickedInside) {
      this.isOutSideClick=false
    }else if(!this.isOutSideClick){
      this.clickOutside.emit(event);
    }
  }
}
