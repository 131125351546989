<div class="options-container">
      <ng-container *ngIf="!viewType">
            <div *ngIf="optionFields.editorOptions"  class="options-fields-box">
                  <div class="options-header f-s-m">{{'Editor Options'}}</div>
                  <div  class="f-l-r editor-option-wrapper">
                        <span class="editor-optoin" (click)="addEditor('bold')"><app-dashboard-icon [size]="20"  [name]="'bold'" [iconJson]="editorIcons" ></app-dashboard-icon></span>
                        <span class="editor-optoin" (click)="addEditor('underline')"><app-dashboard-icon [size]="20"  [name]="'underline'" [iconJson]="editorIcons" ></app-dashboard-icon></span>
                        <span class="editor-optoin" (click)="addEditor('italic')"><app-dashboard-icon [size]="20"  [name]="'italic'" [iconJson]="editorIcons" ></app-dashboard-icon></span>
                        <span class="editor-optoin" (click)="addEditor('justifyright')"><app-dashboard-icon [size]="20"  [name]="'alignLeft'" [iconJson]="editorIcons" ></app-dashboard-icon></span>
                        <span class="editor-optoin" (click)="addEditor('justifycenter')"><app-dashboard-icon [size]="20"  [name]="'alignCenter'" [iconJson]="editorIcons" ></app-dashboard-icon></span>
                        <ng-container *ngIf="expendEditorOptions">
                              <span class="editor-optoin" (click)="addEditor('justifyleft')"><app-dashboard-icon [size]="20"  [name]="'alignLeft'" [iconJson]="editorIcons" ></app-dashboard-icon></span>
                              <span class="editor-optoin" (click)="addEditor('foreColor')" ><app-dashboard-icon [size]="20"  [name]="'colorFormate'" [iconJson]="editorIcons" ></app-dashboard-icon></span>
                              <span class="editor-optoin" (click)="addEditor('strikethrough')" ><app-dashboard-icon [size]="20"  [name]="'strikethrough'" [iconJson]="editorIcons" ></app-dashboard-icon></span>
                              <span class="editor-optoin" (click)="addEditor('superscript')" ><app-dashboard-icon [size]="20"  [name]="'superscript'" [iconJson]="editorIcons" ></app-dashboard-icon></span>
                              <span class="editor-optoin" (click)="addEditor('subscript')" ><app-dashboard-icon [size]="20"  [name]="'subscript'" [iconJson]="editorIcons" ></app-dashboard-icon></span>
                              <span class="editor-optoin" (click)="addEditor('removeFormat')" ><app-dashboard-icon [size]="20"  [name]="'eraser'" [iconJson]="editorIcons" ></app-dashboard-icon></span>
                        </ng-container>
                        <span class="editor-optoin" (click)="expendEditorOptions=!expendEditorOptions"><app-dashboard-icon [size]="20"  [name]="expendEditorOptions ? 'upCirecleChecvron' : 'downCirecleChecvron'" [iconJson]="editorIcons" ></app-dashboard-icon></span>
                  </div>
            </div>
            <div *ngIf="optionFields.suggestions" class="options-fields-box">
                  <div class="options-header f-s-m">{{'Suggestions'}}</div>
                  <div *ngIf="!optionFields.suggestions?.length || optionFields?.suggestions?.includes('CreateItem')" class="options-fields f-l-r" (click)="selectOption('Create')">
                        <span style="width: 20px;" class="d-flex justify-content-center align-item-center" ><img src="../../../assets/img/icons/plus.svg"  role="button"/></span>
                        <span>Create New Items</span>
                  </div>
                  <div *ngIf="!optionFields.suggestions?.length || optionFields?.suggestions?.includes('CreateSubtask')" class="options-fields f-l-r" (click)="selectOption('Subtask')">
                        <app-dashboard-icon [size]="20"  [name]="'SubTask'"></app-dashboard-icon>
                        <span>Create Sub Items</span>
                  </div>
                  <div *ngIf="!optionFields.suggestions?.length || optionFields?.suggestions?.includes('AiAssist')" class="options-fields f-l-r" (click)="openAiAssist(aiAssist)">
                        <app-dashboard-icon [size]="20"  [name]="'aiAssist'"></app-dashboard-icon>
                        <span>Ai Assist</span>
                  </div>
            </div>
            <div *ngIf="optionFields.chatSuggestions" class="options-fields-box">
                  <div class="options-header f-s-m">{{'Suggestions'}}</div>
                  <div *ngIf="!optionFields.suggestions?.length || optionFields?.suggestions?.includes('Copy')" class="options-fields f-l-r" (click)="selectOption('copy',this.selectedText)">
                        <app-dashboard-icon [size]="20"  [name]="'copyIcon'"></app-dashboard-icon>
                        <span>Copy</span>
                  </div>
                  <!-- <div *ngIf="!optionFields.suggestions?.length || optionFields?.suggestions?.includes('AskChatBot')" class="options-fields f-l-r" (click)="selectOption('copy')">
                        <app-dashboard-icon [size]="20"  [name]="'msgChatBot'"></app-dashboard-icon>
                        <span>Ask Chat Bot</span>
                  </div> -->
                  <div *ngIf="!optionFields.suggestions?.length || optionFields?.suggestions?.includes('AiAssist')" class="options-fields f-l-r" (click)="openAiAssist(aiAssist)">
                        <app-dashboard-icon [size]="20"  [name]="'aiAssist'"></app-dashboard-icon>
                        <span>AI Assist</span>
                  </div>
            </div>

            <div *ngIf="optionFields.aiSuggestions"  class="options-fields-box">
                  <div class="options-header f-s-m">{{'AI Sugesstion'}}</div>
                  <div class="options-fields f-l-r" (click)="openSumrizedText($event,'SummarisedText')">
                        <span>Summarised Text</span>
                  </div>
                  <div class="options-fields f-l-r" (click)="openSumrizedText($event,'ImprovedText')">
                        <span>Improved Text</span>
                  </div>
            </div>
      </ng-container>
      <ng-container *ngIf="viewType=='SummarisedText' || viewType=='ImprovedText'">
            <ng-container *ngTemplateOutlet="summarizedTextTemp"></ng-container>
      </ng-container>
</div>
<ng-template #summarizedTextTemp>
      <div  class="summarized-content">
            <div class="header-content">
                  <div class="buttons-wrapper">
                        <app-dashboard-icon [size]="20"  [name]="'aiAssist'"></app-dashboard-icon>
                        <span>{{viewType=='SummarisedText' ? 'Summarized Text' : 'Improved Text'}}</span>
                  </div>
            </div>
            <div class="body-content">
                  <textarea class="w-100 h-100"  [(ngModel)]="summarizedText" (focusout)="reStoreSelectionrange()">

                  </textarea>
            </div>
            <div class="footer-content">
                  <div class="left-buttons buttons-wrapper justify-content-start">
                        <button *ngIf="selectedTextInfo.textEditable" class="button-fields themeChild" (click)="replaceText(this.summarizedText)">
                              <app-dashboard-icon [size]="15"  [name]="'replaceWhite'"></app-dashboard-icon>
                              <span>Replace</span>
                        </button>
                        <button class="button-fields themeChild" (click)="selectOption('copy',this.summarizedText)">
                              <app-dashboard-icon style="width: 15px;height: 15px;" [size]="15"  [name]="'copyIcon'" [isSvg]="true" [color]="'white'"></app-dashboard-icon>
                              <span>Copy</span>
                        </button>
                  </div>
                  <div class="right-buttons buttons-wrapper justify-content-end">
                        <button class="button-fields" (click)="selectOption(null)">
                              <span>Cancel</span>
                        </button>
                        <button class="button-fields" (click)="regenerateText()">
                              <span>Regenerate</span>
                        </button>
                  </div>
            </div>
      </div>
</ng-template>

<ng-template #aiAssist let-modal>
      <ai-assist class="w-100 h-100"  [selectedText]="selectedText" [editSelectionText]="optionFields.chatSuggestions || !selectedTextInfo.textEditable? false : true" (closeModal)="modal.dismiss()"></ai-assist>
</ng-template>
