
<div class="transferPhaseModal">
  <div class=" transferItemArea">
    <span class="transferItemText">{{'setting.completedItems' | translate :{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }} : {{completedItemsCount}}</span>
    <span class="transferItemText">{{'setting.incomplteItems' | translate :{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }} : {{incompleteItemsCount}}</span>

    <span class="checkboxTransfer" *ngIf="!phaseCompleted">
      <input type="checkbox" class="dont" [(ngModel)]="isTransferPhase"/>
      <span>{{'setting.transferIncomptedItems' | translate :{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }}</span>
    </span>

    <span class=" phaseSelect" *ngIf="isTransferPhase || phaseCompleted">
      <span class="transferItemText" *ngIf="phaseCompleted">{{'setting.transferIncomptedItemsTo' | translate :{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }} :</span>
      <ng-select class=" w-100" [items]="transferPhaseList" [clearSearchOnAdd]="true" [multiple]="false" bindLabel="name" [closeOnSelect]="true"
              bindValue="_id" [clearable]="false" [(ngModel)]="transferToPhase" placeholder="Select phase">
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div style="display:flex">
                  <div style="width:80%">
                    <span class="">{{item.name}}</span><br>
                    <p class="trxtGrayColor"*ngIf="item.startDateFormat && item.endDateFormat">{{item.startDateFormat}} - {{item.endDateFormat}}</p>
                    <p class="trxtGrayColor" *ngIf="!item.startDateFormat && !item.endDateFormat"></p>
                    <p class="trxtGrayColor" *ngIf="item.startDateFormat && !item.endDateFormat">{{item.startDateFormat}}- {{'form.noEndDate' | translate}}</p>
                    <p class="trxtGrayColor" *ngIf="!item.startDateFormat && item.endDateFormat">{{'form.noStartDate' | translate}} -{{item.endDateFormat}}</p>
                  </div>
                  <div *ngIf="!item.is_archive" class="pull-right" style="width:20%;">
                    <i *ngIf="item.current_status=='STARTED'" class="dot"></i>
                    <p class="trxtGrayColor" *ngIf="item.current_status=='STARTED' && !item.is_archive">In Progress</p>
                 </div>
                 <div *ngIf="item.is_archive" class="pull-right" style="width:20%;">
                    <img class="arcicon"src="../../../assets/img/icons/archive.svg"/>
                  </div>
                </div>
              </ng-template>
      </ng-select>
    </span>

  </div>

  <div class="transferBottom">
    <button class="btn btnclass cancelBtn" (click)="closeModal.emit();">{{'common.cancel' | translate}}</button>
    <button *ngIf="!phaseCompleted" class="btn btnclass markdoneBtn" (click)="phaseComplete();" [disabled]="(isTransferPhase && !transferToPhase)">{{'setting.completePhase' | translate}}</button>
    <button *ngIf="phaseCompleted" class="btn btnclass markdoneBtn" (click)="transferIncompleteItems();" [disabled]="!transferToPhase">{{'setting.transfer' | translate}}</button>

  </div>
</div>
