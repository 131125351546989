  <div class="assignuser-cnr">
    <!--searcg bar-->
    <div class="searchBar">
      <div class="searchAssignee"><input #searchInput placeholder="Search name" (input)="filterUser(searchText)" [(ngModel)]="searchText"><span class="d-flex"><i class="fa fa-search"></i></span></div>
    </div>
    <!--View All user-->
      <div class="w-100 optionDetailBoxHeaderRow assigneeViewAll">
        <span class="f-s-r primaryColor"  (mouseenter)="selectedIndex=-1">{{'editcustom.viewAllUsers' | translate}}</span>
      <label class="switch toggle">
        <input type="checkbox" [(ngModel)]="showOrg" (ngModelChange)="showAll()">
        <span class="slider round"></span>
      </label> 
      </div>
      <div class="w-100 optionDetailBoxHeaderRow f-s-r assigneeSubHead"><ng-container *ngIf="!showOrg">Board</ng-container><ng-container *ngIf="showOrg">All</ng-container> Users</div>
    <!--User list-->
      <div #container class="optionDetailBoxBody assigneePopBody">
      <ng-container *ngFor="let user of searchDashUsers;let index=index">
        <div class="optionDetailBox assigneePopBox backgroundHover" [ngClass]="{'backgroundHover' : selectedIndex==index}" *ngIf="!user.is_deleted" (click)="updateAssignUserClicked(user._id)" (mouseenter)="selectedIndex=index">
          <div class="align">
            <ngx-avatars class="mr-1" [name]="user?.name" [src]="user?.image_url" size="25" initialsSize="2" ></ngx-avatars>
          </div>  
          <div class="box f-l-r">
            <span class="f-l-r capitalizeFirstLetter">{{user?.name}}</span>
            <p class="my-0 f-s-r assigneeEmail">{{user?.email}}</p>
        </div>
        </div>
      </ng-container>
    </div>
    <!--Remove Assignee-->
    <div *ngIf="assignedUser || bulkSelectionFlag" class="removeAssigneeBar backgroundHover"  (mouseenter)="selectedIndex=searchDashUsers?.length">
           <div class="removeAssignBox" (click)="updateAssignUserClicked(null)">
            <span><app-dashboard-icon [size]="20" [name]="'User'" [iconJson]="commonIcons"></app-dashboard-icon></span>
            <span class="f-l-r removeAssigneeText">{{'common.remove' | translate}} {{itemAttributesObj?.assigned_to?.label | attributesLabel: itemAttributesObj: 'assigned_to':'dashboard.filter.assignee' | translate}}</span>
<!--             <button (click)="updateAssignUserClicked(null)" type="button" class="btn btn-lg btn-sm filterBtn f-m-b">{{'common.remove' | translate}} {{itemAttributesObj?.assigned_to?.label | attributesLabel: itemAttributesObj: 'assigned_to':'dashboard.filter.assignee' | translate}}</button>
 -->           </div>
    </div>
  </div>