<div [ngClass]="{'scroll':!communicationCall , 'CommunicationScroll' :communicationCall} ">
    <div class="subjectSection m-2 my-3 d-flex align-items-center" *ngIf="!isChatExpand">
        <span *ngIf="showBackButton && !communicationCall" style="display: inline-block;" class="mx-2" (click)="backToAllEmails.emit()">
            <img style="margin-bottom: 42px;margin-top: 3px;" class="left-arrow cursorPointer mx-auto my-auto imageClass" src="../../../assets/1x/newdash-arrow.svg">       
        </span>    
        <span class="m-2 justify-content-between " style="font-weight: bold;font-size: 1.25em;display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;">
            {{emailSubject | titlecase}}
        </span>           
    </div>
    <ng-container *ngFor="let commentEmail of allComments;let i=index">
        <div class="single-email emailBody singleEmailContent px-3 mx-2" [ngStyle]="{ 'border-color': (commentEmail?.status == 'FAILED') ? 'red' : '#B2B2B2' }" (click)="$event.stopPropagation();commentEmail.openFullView=!commentEmail.openFullView;log(commentEmail.openFullView,commentEmail,allComments[i])">
            <div>
                <div class="mb-2">
                    <div class="emailTitleRow mb-1">
                        <div class="reply-and-date">
                            <span>{{commentEmail?.receivedTime?.date || commentEmail?.createdTime?.date}} {{commentEmail?.receivedTime?.time || commentEmail?.createdTime?.time}}</span>
                        </div>
                        <div class="replyEmail reply-3-dots">
                            <span *ngIf="commentEmail?.status == 'FAILED' "><app-dashboard-icon  [size]="22" [name]="'Alert'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>

                          <span  *ngIf="usersEmailsAllowedToReply?.length" class="cursorPointer"placement="auto" [ngbPopover]="newEmail" #popEmail2="ngbPopover"container="body" [autoClose]="'outside'" [ngbTooltip]="'email.reply' | translate" tooltipClass="tooltipPopup"
                             (click)="isFullScreen=false;$event.stopPropagation();selectedEmail=commentEmail.email_object;setFromObject(commentEmail?.email_object?.from); replyPop=popEmail2; isReplyAll=false;initializeSummerNote(commentEmail?.email_object?.body?.html ? commentEmail?.email_object?.body?.backQuoteHtmlReply : commentEmail?.email_object?.body?.backQuoteTextReply )"><i class="fa fa-reply" aria-hidden="true"></i></span>
                             <span class="cursorPointer" [ngbPopover]="newEmail" #popEmailR3="ngbPopover" container="body" [autoClose]="'outside'" [outsideClick]="'outside'"placement="auto" (click)="isFullScreen=false;"></span>
                          <span>
                              <span *ngIf="usersEmailsAllowedToReply?.length" (click)="$event.stopPropagation();replyAllPop.open()" class="mr-2">
                                  <img [ngbPopover]="replyAllPopover"  #replyAllPop="ngbPopover" container="body" placement="auto" [outsideClick]="'outside'" aria-hidden="true" popoverClass="replyAllPopover" triggers="manual" [autoClose]="'outside'"  class="right-3dots ml-3 cursorPointer imageClass" src="../../../assets/1x/vertdots.svg">
                                  <ng-template #replyAllPopover>
                                     <span class="cursorPointer"                                            
                                     (click)="$event.stopPropagation();selectedEmail=commentEmail.email_object;setFromObject(commentEmail?.email_object?.from);replyPop=popEmailR3;popEmailR3.open();replyAllPop.close(); isReplyAll=true;initializeSummerNote(commentEmail?.email_object.body?.html ? commentEmail?.email_object?.body?.backQuoteHtmlReply : commentEmail?.email_object?.body?.backQuoteTextReply )"><i class="fa fa-reply-all mr-2" aria-hidden="true"></i>{{'email.replyAll' | translate}}</span>
                                  </ng-template>
                              </span>
                          </span>
                          
                       </div>
                    </div>
                    <div class="d-flex flex-row address-block">
                        <div class="mr-1 pr-1 mt-1">
                            <ngx-avatar class="mr-1" [name]="commentEmail?.email_object.from?.address" [src]="undefined" size="25" initialsSize="2" ></ngx-avatar>
                        </div>
                        <div class="d-flex flex-column">
                            <span class="from-address">{{commentEmail?.email_object.from?.address}}</span>
                            <div class="to-address-block">
                                <span class="to-address">To: {{commentEmail?.email_object?.to[0]?.address}}</span>
                                 <img class="cursorPointer" [ngbPopover]="toFromEmailDropDown"  #dropDownEmail="ngbPopover" container="body" placement="bottom" [outsideClick]="'outside'" aria-hidden="true" popoverClass="toFromEmailDropDown" triggers="manual" [autoClose]="'outside'" (click)="dropDownEmail.open();$event.stopPropagation();" class="right-3dots ml-3 cursorPointer imageClass" src="../../../assets/1x/arrow-dropdown.svg">
                                 <ng-template #toFromEmailDropDown>
                                  <app-email-dropdown-template [lastMessage]="commentEmail"></app-email-dropdown-template>
                                  </ng-template>
                            </div>
                            
                        </div>
                    </div>
                    <div class="p-0">
                          <ng-conatiner *ngIf="commentEmail.openFullView">
                            <ng-template *ngTemplateOutlet="fullViewList; context: {$implicit: commentEmail.commentForModal}"></ng-template>
                          </ng-conatiner>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngFor="let lastMessage of lastReplyMessage">
      <div class="single-email emailBody singleEmailContent px-3 mx-2" [ngStyle]="{ 'border-color': (lastMessage?.status == 'FAILED') ? 'red' : '#B2B2B2' }" (click)="$event.stopPropagation(); lastMessage.openFullView = !lastMessage.openFullView">
         <div class="mb-2">
             <div class="emailTitleRow mb-1">
                 <div class="reply-and-date">
                     <span>{{lastMessage?.email_object?.receivedTime?.date || lastMessage?.email_object?.createdTime?.date}} {{lastMessage?.email_object?.receivedTime?.time || lastMessage?.email_object?.createdTime?.time}}</span>
                 </div>
                 <div class="replyEmail reply-3-dots">
                    <span *ngIf="lastMessage?.status == 'FAILED' "><app-dashboard-icon  [size]="22" [name]="'Alert'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>

                  <span *ngIf=" usersEmailsAllowedToReply?.length" class="cursorPointer" placement="auto" [ngbPopover]="newEmail" #popEmail2="ngbPopover" container="body" [autoClose]="'outside'" [ngbTooltip]="'email.reply' | translate" tooltipClass="tooltipPopup"
                     (click)="$event.stopPropagation();selectedEmail=lastMessage.email_object;setFromObject(lastMessage?.email_object?.from); isReplyAll=false;initializeSummerNote(lastMessage?.email_object?.body?.html ? lastMessage?.email_object?.body?.backQuoteHtmlReply : lastMessage?.email_object?.body?.backQuoteTextReply );replyPop=popEmail2"><i class="fa fa-reply" aria-hidden="true"></i></span>
                     <span class="cursorPointer" [ngbPopover]="newEmail" #popEmailR3="ngbPopover" container="body" [autoClose]="'outside'" [outsideClick]="'outside'"placement="auto" ></span>
                  <span *ngIf=" usersEmailsAllowedToReply?.length" class="mr-2">
                     <img [ngbPopover]="replyAllPopover"  #replyAllPop="ngbPopover" container="body" placement="auto" [outsideClick]="'outside'" aria-hidden="true" popoverClass="replyAllPopover" triggers="manual" [autoClose]="'outside'" (click)="replyAllPop.open();popOverClose=popEmailR3;$event.stopPropagation();" class="right-3dots ml-3 cursorPointer imageClass" src="../../../assets/1x/vertdots.svg">
                     <ng-template #replyAllPopover>
                        <span class="cursorPointer"                                           
                        (click)="$event.stopPropagation();selectedEmail=lastMessage.email_object;setFromObject(lastMessage?.email_object?.from);popEmailR3.open();replyAllPop.close();isReplyAll=true;initializeSummerNote(lastMessage?.email_object.body?.html ? lastMessage?.email_object.body?.backQuoteHtmlReply : lastMessage?.email_object.body?.backQuoteTextReply );replyPop=popEmailR3"><i class="fa fa-reply-all mr-2" aria-hidden="true"></i>{{'email.replyAll' | translate}}</span>
                     </ng-template>
                  </span>
                  
               </div>
             </div>
             <div class="d-flex flex-row address-block">
                 <div class="mr-1 pr-1 mt-1">
                     <ngx-avatar class="mr-1" [name]="lastMessage?.email_object?.from?.address" [src]="undefined" size="25" initialsSize="2" ></ngx-avatar>
                 </div>
                 <div class="d-flex flex-column">
                     <span class="from-address">{{lastMessage?.email_object?.from?.address}}</span>
                     <div class="to-address-block">
                         <span class="to-address">To: {{lastMessage?.email_object?.to[0]?.address}}</span>
                         <img class="cursorPointer" [ngbPopover]="toFromEmailDropDownForObject"  #dropDownEmail="ngbPopover" container="body" placement="bottom" [outsideClick]="'outside'" aria-hidden="true" popoverClass="toFromEmailDropDownForObject" triggers="manual"
                           [autoClose]="'outside'" (click)="dropDownEmail.open();$event.stopPropagation();" class="right-3dots ml-3 cursorPointer imageClass" src="../../../assets/1x/arrow-dropdown.svg">
                           <ng-template #toFromEmailDropDownForObject>
                            <app-email-dropdown-template [lastMessage]="lastMessage"></app-email-dropdown-template>
                           </ng-template>
                      </div>
                 </div>
             </div>
  
         </div>
         <div *ngIf="lastMessage.openFullView" class="smallPadding">
          <ng-template *ngTemplateOutlet="fullViewList; context: {$implicit: lastReplyMessage}"></ng-template>
        </div>
     </div>
    </ng-container>
    <ng-template #fullViewList let-selectedEmail>
        <div *ngIf="selectedEmail" class="belowEmail" id="expandedEmailView">
         <ng-container *ngFor="let selectedEmail of selectedEmail">
            <div class="email-body p-2 sans" style="padding-left: 0.5rem !important;">
               <!-- <div *ngIf="selectedEmail?.email_object?.body?.html" [innerHtml]="selectedEmail?.email_object?.body?.html"></div>
               <div *ngIf="!selectedEmail?.email_object?.body?.html">{{selectedEmail?.email_object?.body?.text}}</div>  -->
               <div *ngIf="selectedEmail?.email_object?.body?.html" [innerHtml]="selectedEmail?.email_object?.body?.html | safehtml" class="breakWords"></div>
               <div *ngIf="!selectedEmail?.email_object?.body?.html" class="breakWords">{{selectedEmail?.email_object?.body?.text}}</div>
               <div class="attachments" *ngIf="selectedEmail?.email_object?.attachments?.length">
                  <span class="attachment-count font-weight-bold  ">{{selectedEmail?.email_object?.attachments?.length}} {{'email.attachments' | translate}}</span>
                  <div class="attachment-container imgcnr mt-1 pb-0" (click)="$event.stopPropagation();">
                     <div  class="outer"> 
                        <div *ngFor="let attachment of selectedEmail?.email_object?.attachments; let i = index" class="videoFileParent d-flex justify-content-between p-1 pr-3 py-3">      
                           <ng-container>
                              <div class="video-upload d-flex flex-column">
                                   <div class="icon-section">
                                       <app-dashboard-icon [name]="attachment?.path ? attachment?.path?.split('.').pop() : attachment.extension" [iconJson]="mediaIcons" size="90px"></app-dashboard-icon>                                    
                                   </div>
                                   <div class="media-prop">
                                       <div class="play-delete flex-column">
                                           <div class="d-flex justify-content-center" >
                                               <a type="button"   href="{{attachment?.url}}" target="_blank">
                                                 <img class="imageClass" src="../../../assets/1x/doc-download.png" />
                                               </a>
                                           </div>
                                           <div class="d-flex justify-content-around">
                                               <span class="btns image-options-for-singleimage p-0"> 
                                                   <img class="imageClass" src="../../../assets/1x/doc-view.png"  (click)="openPreviewPopup(i, videoPlayerModalNested,selectedEmail?.email_object?.attachments)" /> 
                                               </span> 
                                           </div>
                                       </div>
                                   </div>
                                   {{attachment.file_name | truncate:12 | titlecase}}
                              </div>      
                           </ng-container> 
                        </div>
                    </div>
                  </div>
               </div>
            </div>  
         </ng-container>
          </div>
    </ng-template>
  </div>
  
  <!-- New Email Modal-->
  <ng-template #newEmail>
    <div class="modal-md">
  
       <div class="emailPopover" (click)="$event.stopPropagation()" [style.width.px]="isFullScreen ? 1050 : 600">
          <div class="emailHeader">
             <ng-container *ngIf="selectedEmail;">
                <div style="width:40%;">
                   <!-- <span class="modal-title">{{'email.replyTo' | translate}}</span> -->
                   <ng-container *ngIf="isReplyAll">{{'email.replyAll' | translate}}</ng-container>  
                   <ng-container *ngIf="!isReplyAll">{{'email.reply' | translate}}</ng-container>  
                </div>
                
                <div style="width:60%;display: flex;align-items: center;justify-content: flex-end;">
                    <span style="width:5%;" (click)="onFullview()"><img src="../../../assets/1x/fullScreen.svg" style="height: 16px;width: 16px;"></span>
                    <span>
                      <ng-select style="width:250px;" [clearable]="false" (change)="changeEmail($event)" [items]="usersEmailsAllowedToReply" bindLabel="value" bindValue="id" [(ngModel)]="from" placeholder="Select From">
                         <ng-template ng-option-tmp let-item="item" let-index="index">
                               <span>{{item?.value}}</span>
                            </ng-template>
                      </ng-select>
                   </span>
                   <!-- <img src="/assets/1x/flip.svg" style="margin-right:5px;height:12px;"> -->
                   <i class="fa fa-close cursorPointer ml-2" (click)="closeAllPopups()"></i>
                </div>
             </ng-container>           
          </div>
          <div class="emailPopBody" (click)="$event.stopPropagation()">
             <app-send-email [dashId]="dashId" [sprintArr]="sprintArr" [isReplyAll]="isReplyAll" [replyingEmail]="selectedEmail" [leadInfo]="leadInfo" [from]="fromEmailObj" (closeModal)="closeAllPopups();from='';" [embeddedImageAttachments]="emailEditorEmbeddedAttachments"
              [emailSignatureForEditor]="emailSignatureForEditor" (isFullScreenEmit)="isFullScreen=true"></app-send-email>
          </div>
       </div>
    </div>
  </ng-template>
  
  <ng-template #videoPlayerModalNested let-modal>
    <div class="previewBodyModal" style="height: 100%;max-width: 100%;position: relative;">
      <i class="fa fa-close bg-white previewCross" (click)="modal.dismiss()"></i>
      <app-file-preview [mediaArr]="mediaArr" [index]="currentIndex" [type]="'ITEM'"></app-file-preview>
    </div>
  </ng-template>