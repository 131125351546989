import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CustomStorageService } from "app/services/custom-storage.service";

@Component({
    selector: 'delete',
    templateUrl: './delete-confirmation.component.html',
    styleUrls: ['./delete-confirmation.component.scss'],
  })
  export class DeleteConfirmationComponent implements OnInit {
    @Output() closeEmit : EventEmitter<any> = new EventEmitter();
    @Output() deleteEmit : EventEmitter<any> = new EventEmitter();
    @Input() warningTitle : any;
    @Input() deletedItemId:any;
    selectedThemeColor: string;
    constructor(private customStorageService : CustomStorageService){}

    ngOnInit(): void {
     const theme = this.customStorageService.getItem('theme');
      this.selectedThemeColor = (theme !== "undefined" && theme !== "null" && theme !== null && theme !== undefined) ? theme : 'theme-royalBlue';          
    }



  }