<span #fileUploadPopover="ngbPopover" [placement]="typeOfDoc==='DRIVE'?'bottom-right':'auto'" container="body" [autoClose]="'outside'" [ngbPopover]="uploadFile" popoverClass="gridtableCell modifiedPopover" (shown)="keyboardAccess(true)" (hidden)="keyboardAccess(false)"></span>

<ng-template #uploadFile>
  <div *ngIf="!templateOptionSelect" style="width: 270px; min-height: 127px;" class="uploadFileHover p-1">
      <div class="p-1" id="media-upload" >
          <div role="button" (click)="multiDocumentFile.click();"  class="d-flex align-items-center gap-3 p-2 backDrop" [class.key-active]="selectedIndex==0" (mouseenter)="selectedIndex=0">
            <span class="iconsWidth"><app-dashboard-icon [name]="'attachment'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon></span>
            <span style="margin-left: 17px;" class="driveText">From Computer</span>
          </div>
          <div role="button" *ngIf="typeOfDoc==='FILE'" class="d-flex align-items-center gap-3 p-2 backDrop" (click)="templateOptionSelect=true;" [class.key-active]="selectedIndex==1" (mouseenter)="selectedIndex=1" >
            <span class="iconsWidth"><app-dashboard-icon [name]="'template'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon></span>
            <span style="margin-left: 17px;" class="driveText">From PDF Template</span>
          </div>
          <div role="button" *ngIf="typeOfDoc!=='DRIVE'"  class="d-flex align-items-center gap-3 p-2 backDrop" (click)="pasteUrlForMediaUpload(linkModalContent)" [class.key-active]="selectedIndex==2" (mouseenter)="selectedIndex=2" >
            <span class="iconsWidth"><app-dashboard-icon [name]="'url'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon></span>
            <span style="margin-left: 17px;" class="driveText">Paste Url</span>
          </div>
          <div role="button"  class="d-flex align-items-center gap-3 p-2 backDrop" (click)="handleAuthClick()" [class.key-active]="selectedIndex==3" (mouseenter)="selectedIndex=3" >
            <span class="iconsWidth"><app-dashboard-icon [name]="'googleDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon></span>
            <span style="margin-left: 17px;"class="driveText"> Google Drive</span>
          </div>
          <div role="button"  class="d-flex align-items-center gap-3 p-2 backDrop" (click)="oneDrive()" [class.key-active]="selectedIndex==4" (mouseenter)="selectedIndex=4" >
            <span class="iconsWidth"><app-dashboard-icon [name]="'oneDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon></span>
            <span style="margin-left: 17px;" class="driveText">One Drive</span>
          </div>
        </div>
  </div>
  <div *ngIf="templateOptionSelect" style="width: 365px; min-height: 286px;border-radius: 10px;" class="uploadFileHover p-1">
      <app-template-picker [tempType]="'Pdf'" [leadInfo]="leadInfo" [dashId]="dashId" [sprintArr]="sprintArr" [fileUploadPopover]="fileUploadPopover" [templateOptionSelect]="templateOptionSelect" [orgUsersJson]="orgUsersJson" (templateOpenEmitter)="templateOptionSelect=$event;fileUploadPopover?.open();" (pdfConverted)="emitSelectedFiles([$event], 'from_computer');popCloseRequest=false"></app-template-picker>
  </div>
</ng-template>

<input type="file" class="d-none" #multiDocumentFile [multiple]="!singleSelection" (change)="emitSelectedFiles($event.target.files, 'from_computer')"
  [accept]="typeOfDoc && this.formatList[typeOfDoc]? formatList[typeOfDoc].join(','): '/'"/>
<ng-template #linkModalContent let-modal>
  <div id="paste-url-modal" keyboardMonitor>
    <div class="modal-header border-bottom my-1 w-100">
      <div style="width: 100%;display: flex;justify-content: center;">
        <h5 class="modal-title" id="modal-basic-title">Paste file url</h5>
      </div>
      <div>
        <button type="button" class="float-right" aria-label="Close" (click)="modal.dismiss()" style="border: none;
          background-color: #ffffff00;">
        <img src="assets/1x/close-gray.svg" alt="remove">
      </button>
      </div>
    </div>
  
    <div class="my-3 text-center modal-body">
      <label style="float: left;padding-bottom: 8px;" class="submitLabel">File url</label>
        <input [(ngModel)]="url" class="form-control mb-3 key-tab" type="text" placeholder="http://" tabindex="0"/>
      <label style="float: left;padding-bottom: 8px;" class="submitLabel">Url display name</label>
        <input [(ngModel)]="urlName" class="form-control key-tab" type="text" placeholder="e.g Drive link" tabindex="0"/>
    </div>
  
    <div class="py-3 text-right px-3 modal-footer">
        <button (click)="uploadUrlLink();modal.dismiss()" [ngClass]="{'disabelSaveButton':url==='' || urlName===''}" [disabled]="url==='' || urlName===''" class=" ml-auto submitLink submitLabel key-tab" tabindex="0">Save</button>
      </div>
  </div>    
</ng-template> 
<pre id="content" style="white-space: pre-wrap; width: 0; height: 0;" class="m-0"></pre>
