 <div class="optionDetailBoxBody">
  <div class="search-tag-box">
    <span><i class="fa fa-search" style="color:#C9C9C9"></i></span>
    <input #searchInput [placeholder]="itemAttributesObj?.tag_id?.label | attributesLabel: itemAttributesObj: 'tag_id' : 'Search Tag'" (input)="filterTags(searchText)" [(ngModel)]="searchText" (click)="onSearchTags()">
  </div>
  <div class="optionDetailBoxBody backgroundHover" style="gap:4px;padding:8px 0px;overflow-y: auto;overflow-x: hidden;" #container>
    <span *ngIf="selectedTagCount >=3">{{'editcustom.maxThreeTags' | translate}}</span>
      <ng-container *ngFor="let data of searchedDashTags;let index=index">
        <div class="optionDetailBoxHeaderRow tagRow" *ngIf="!data.is_deleted" (click)="setSelectedTagsClicked(data.tag_id)" [ngStyle]="{'opacity':  (selectedTagCount > 2 && (!data.selected)) ? '0.5' : '1', 'background-color': data?.color ? data.color : '','color': data?.color ? commonUtils.getContrastColor(data?.color) : ''}" [class.selected-tag]="selectedIndex==index" (mouseenter)="selectedIndex=index">
          <span style="width:85%" class="overflow-hidden text-truncate text-nowrap">{{data.name}}</span>
          <img class="smallSelection" *ngIf="data.selected" src="../../../assets/1x/bucket-selecteda.png">
        </div>
      </ng-container>
  </div>
  <div class="text-center manageTagBtn pt-1" *ngIf="showManage" [ngClass]="{'backgroundHover' : selectedIndex==-1}" (mouseenter)="selectedIndex=-1">
    <div *ngIf="dashboardUserRole?.includes('DASHBOARD_ADMIN')" class="manageTagAlignment" (click)="openCreateTags();$event.stopPropagation()">
     <span *ngIf="itemAttributesObj?.tag_id?.label"><img class="pr-2" src="../../../assets/common-use-icon-20dp/settings-outline-6d6e6f.svg">Manage</span>{{itemAttributesObj?.tag_id?.label | attributesLabel: itemAttributesObj: 'tag_id' : 'editcustom.manageTag' | translate}}</div>
  </div>
</div>