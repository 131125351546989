
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'terminology'
})
export class TerminologyPipe implements PipeTransform {
  transform(value: string,arg1: string, arg2: string,) {
    if(arg1!=null && arg1!=''){
        return arg1.substring(0, 10)
    }
    else{
        return arg2.substring(0, 10)
    }

  }
}