import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { isArray } from 'ngx-bootstrap/chronos';
import { ConstantService } from '../../../../Enums/Constant.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from '../../../services/toaster.service';

@Component({
  selector: 'app-lead-priority',
  templateUrl: './lead-priority.component.html',
  styleUrls: ['./lead-priority.component.scss']
})
export class LeadPriorityComponent implements OnInit {
  @Output() closePopover = new EventEmitter<any>();
  @Input() leadInfo:any[];
  @Input() dashId:number;
  @Input() bulkSelectionFlag:boolean;
  @Input() objFilterForMultiselect: any;

  @Output() updateLead = new EventEmitter<any>();
  priority: number;
  selectedIndex=0;

  constructor(private httpTransfer: HttpTransferService,private toaster:ToasterService, public commonUtils: CommonUtils,public constService : ConstantService) { }

  ngOnInit(): void {
    if(!this.bulkSelectionFlag && this.leadInfo) {
      this.priority = this.leadInfo[0].priority;
    }
    if(this.leadInfo?.length==1){
      let index={3:0,2:1,1:2,0:3}
      this.selectedIndex=index[this.leadInfo[0].priority]
    }
  }

  async updatePriorityClicked(priority) {
    if(this.leadInfo) {
      if(this.leadInfo.length>0) {


        if(this.leadInfo.length>1){
         
          let inputJson={}
         
          inputJson['filter_properties']=this.objFilterForMultiselect
          inputJson['update_json']={
            "priority":priority
          }
          this.httpTransfer.updateMultipleDashboard(this.dashId,inputJson).subscribe((res : any) =>{
            this.closePopover.emit(true)
          })
        }
        else{
          await this.updatePriority(
                priority,
                this.leadInfo[0]._id,
                this.leadInfo[0].bucket_id
              );
            this.closePopover.emit(true)
        }

      } else {
        this.toaster.Errors("Please Select Items");
      }
    } else {
      this.updateLead.emit(priority);
    }
    
  }

  async updatePriority(priority, leadId, bucketId) {
    var leadObj = {};
    leadObj["priority"] = parseInt(priority);
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(leadObj, this.dashId, leadId)
      .subscribe((res : any) => {
        if(res.status == 200) {
          this.updateLead.emit({'leadId':leadId,'bucketId':bucketId,'priority':leadObj["priority"]});
          if(this.leadInfo) {
            this.leadInfo['priority']=parseInt(priority);
          }
        }
      });
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowUp':
        if (this.selectedIndex > 0) {
          this.selectedIndex--;
        }
        break;
      case 'ArrowDown':
        if (this.selectedIndex <3) {
          this.selectedIndex++;
        }
        break;
      case 'Enter':
        if (this.selectedIndex>=0 && this.selectedIndex<4) {
          let priority={3:0,2:1,1:2,0:3}
          this.updatePriorityClicked(priority[this.selectedIndex])
        }
        break;
    }
  }
}
