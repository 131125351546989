import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonUtils } from '../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../services/dashboard-utils.service';
import { ConstantService } from '../../../Enums/Constant.service';
import { AttributesIcons, AttributesLxIcons } from '../../../Enums/attributes-icons';
import * as moment from 'moment';
import { HttpTransferService } from '../../services/httpTransfer.service';

@Component({
  selector: 'app-evaluate-date',
  templateUrl: './evaluate-date.component.html',
  styleUrls: ['./evaluate-date.component.scss']
})
export class EvaluateDateComponent implements OnInit {

  // @Input() selectedDashId: string;
  @Input() dateObj: any;


  copiedDateObj:any


  relativeDateForInfo:any={}

  currentDate:any

  dayOptions:any[]=[]
  monthArr:any[]=[]
  monthOptions:any[]=[]
  yearOptions:any[]=[]
  weekdayAdjustOption:any[]=[]

  addSubEnable:boolean=false

  newRelativeDate:any;
  newEvalDate:any;
  evalDateString:any
  relativeDateString:any

  isInvalidInput:boolean=false
  isInvalidYear:boolean=false


  @Output() dateUpdated = new EventEmitter()



  constructor(
    private dashboardUtils: DashboardUtilsService,
    public commonUtils: CommonUtils,
    public constantService: ConstantService,
    private httpTransferService:HttpTransferService

  ) { }

  ngOnInit() {

    if(this.dateObj && (this.dateObj.relative_date.day!=0 || this.dateObj.relative_date.month!=0 || this.dateObj.relative_date.year!=0)){
      this.addSubEnable=true
    }

    this.copiedDateObj=JSON.parse(JSON.stringify(this.dateObj))
    console.log(this.copiedDateObj)
    this.makeOptionsArr()

    this.dateObjChanged()

    this.currentDate = new Date();


  }

  makeOptionsArr(){
    this.monthArr=this.commonUtils.monthNames

    for(let i=1;i<32;i++){
      this.dayOptions.push({'display_key':i, 'key':i})
    }
    for(let j=1;j<this.monthArr.length+1;j++){
      this.monthOptions.push({'display_key':this.monthArr[j-1], 'key': j })
    }
    let dayNames = ["Sun","Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ]
    for(let k=-7;k<8;k++){
      this.weekdayAdjustOption.push({'display_key':k==0 ? 'Any' : k < 0 ? `Last ${dayNames[(-k)-1]}` : k > 0 ? `Next ${dayNames[k-1]}` :'', 'key': k })
    }

    this.dayOptions.unshift({'display_key':'R Day', 'key':'{{relative_day}}'})
    this.monthOptions.unshift({'display_key':'R Month', 'key':'{{relative_month}}'})
    this.yearOptions.push({'display_key':'R Year', 'key':'{{relative_year}}'})
    this.yearOptions.push({'display_key':'Custom Year', 'key':'custom_year'})
  }




  
  saveEvalDate(){
    this.dateUpdated.emit(this.copiedDateObj)
  }


  isThisDayAllowed(day){
    let val=true
    if(day=='{{relative_day}}'){
      val=true
    }
    else if([4,6,9,11].includes(this.copiedDateObj.month['value'])){
      val=day<31 ? true : false
    }
    else if(this.copiedDateObj.month['value']==2){
      val=day<29 ? true : false
    }
    return val
  }


  isThisMonthAllowed(month){
    let val=true
    if(month=='{{relative_month}}'){
      val=true
    }
    else if([4,6,9,11].includes(month)){
      val= this.copiedDateObj.day['value'] < 31 || this.copiedDateObj.day['value']=='{{relative_day}}' ? true :false
    }
    else if(month==2){
      val= this.copiedDateObj.day['value'] < 29 || this.copiedDateObj.day['value']=='{{relative_day}}' ? true : false
    }

    return val
  }
  

  changeYearType(){
    if(this.copiedDateObj.year['value']!='{{relative_year}}'){
      this.copiedDateObj.year['value']=this.currentDate.getFullYear()
    }
  }


  dateObjChanged(){
    let response = this.commonUtils.calculateEvalDate(this.copiedDateObj)
    this.newRelativeDate = response.relative_date;
    this.newEvalDate = response.evaluated_date;
    this.evalDateString = this.commonUtils.getDateText(this.newEvalDate)
    this.relativeDateString = this.commonUtils.getDateText(this.newRelativeDate)
    
    
    this.relativeDateForInfo['day']=this.relativeDateString.split(" ")[0]
    this.relativeDateForInfo['month']=this.relativeDateString.split(" ")[1]
    this.relativeDateForInfo['year']=this.relativeDateString.split(" ")[2]
  }


  addSubBtnTggled(event,obj){
    if(!event){
      obj.relative_date.day=0
      obj.relative_date.month=0
      obj.relative_date.year=0
      this.dateObjChanged()
    }
  }

  emptyFocusOut(key,evalYear?){
    if(!evalYear){
      if(this.copiedDateObj.relative_date[key]==null){
        this.copiedDateObj.relative_date[key]=0
      }
    }
    else{
      if(this.copiedDateObj.year.value==null){
        this.copiedDateObj.year.value=this.currentDate.getFullYear()
      }
    }
    this.dateObjChanged()
  }


  validateAddInput() {
    const relative_date = this.copiedDateObj.relative_date;
    if (this.addSubEnable && (!Number.isInteger(relative_date.day) || !Number.isInteger(relative_date.month) || !Number.isInteger(relative_date.year))) {
      this.isInvalidInput = true;
    } else {
      this.isInvalidInput = false;
    }
  }

  validateYear(){
    const val=this.copiedDateObj.year.value
    if (!Number.isInteger(val) && val!='{{relative_year}}') {
      this.isInvalidYear = true;
    } else {
      this.isInvalidYear = false;
    }
  }

}
