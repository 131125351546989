import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, Sanitizer, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ConstantService } from '../../../../Enums/Constant.service';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AttributesLxIcons } from 'Enums/attributes-icons';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { MediaIcons } from 'Enums/media-icons';
import { text } from 'express';
import { HttpTransferService } from 'app/services/httpTransfer.service';
import { MessageService } from 'app/message.service';
import { AutomationUtilsService } from 'app/services/automation-utils.service';
import { CustomStorageService } from 'app/services/custom-storage.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ScreenRecordingComponent } from 'app/all-reusables/shared-components/screen-recording/screen-recording.component';
import { Subscription } from 'rxjs-compat';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-content-editable-input-note',
  templateUrl: './content-editable-input-note.component.html',
  styleUrls: ['./content-editable-input-note.component.scss']
})
export class ContentEditableInputNoteComponent implements OnInit {
  @Input() multiselect:boolean=false;
  @Input() broadCastChannel:any;
  @Output() sendBroadCast: EventEmitter<any> = new EventEmitter();
  @Output() uploadFile : EventEmitter<any> = new EventEmitter();
  @Output() onBlur : EventEmitter<any> = new EventEmitter();
  @Output() onChange : EventEmitter<any> = new EventEmitter();
  @Output() onCreate : EventEmitter<any> = new EventEmitter();
  @Output() onOutsideClick  : EventEmitter<any> = new EventEmitter();
  @Output() sendChat : EventEmitter<any> = new EventEmitter();
  @Output() sendEditorText : EventEmitter<any> = new EventEmitter();
  @Output() getTemplateData : EventEmitter<any> = new EventEmitter();
  @Output() mediaColumns  : EventEmitter<any> = new EventEmitter();
  @Output() rplyDisable  : EventEmitter<any> = new EventEmitter();
  @Output() openScreenRecorderPlayers  : EventEmitter<any> = new EventEmitter();
  @Output() closeEmit : EventEmitter<any> = new EventEmitter();
  @ViewChild('editableDiv') editableDiv: ElementRef;
  @ViewChild('popColumns') public popColumns: NgbPopover;
  @ViewChild('popFunc') public popFunc: NgbPopover;
  @ViewChild('popColumnsForChat') public popColumnsForChat: NgbPopover;
  @ViewChild('popAttach') public popAttach: NgbPopover;
  @ViewChild('functionPop', { static: true }) functionPop: TemplateRef<any>;
  //@ViewChild('functionPopover', { static: false }) functionPopover: ViewContainerRef;
  @ViewChild('functionPopover', { read: ViewContainerRef }) functionPopover: ViewContainerRef;
  @ViewChild('functionTooltip', { static: false }) functionTooltip: ElementRef;
  @ViewChild('popFunctionOpen') public popFunctionOpen: NgbPopover;
  @Input() dashboardVariable:boolean=false;
  @Input() dashVariableArr:any;
 @Input() isTriggeredNonItemField:boolean=false;
 @Input() item:any={};
 @Input() dashId:any;
 @Input() customFieldArr:any;
 @Input() type:string="editableDiv";
 @Input() orgUsersJson:any;
 @Input() htmlContent:any;
 @Input() showAttachmentIcon:boolean=false;
 storedCursorPosition: Range;
 @Input() columnIndex:any=0;
 @Input() columnsJson:any={};
 @Input() showIndex:boolean=true;
 @Input() filterCall:boolean=false;
 @Input() customfieldType:any;
 @Input() filterCustomColumnKey:any;
 @Input() columnSelection:boolean=true;
 @Input() commentChat:boolean=false;
 @Input() summernoteToolbar:any=[
  ['style', ['bold', 'italic']],
  ['font', ['strikethrough']],
 ]
 @Input() whatsappCall:boolean=false;
 @Input() catchHookReqJson:any;
 @Input() rplyObj:any;
 @Input() disableAttachColumns:boolean=false;
 @Input() internalChat:boolean=false;
 @Input() appTemplateCall: boolean = false;
 @Input() templateVariableArr: any[]=[];
 @Input() assignVariableCall: boolean = false;
 @Input() objFilterForMultiselect:any;
 commentMentions: any[];
 isMentionPanelOpen: boolean;
 storeSelectionRangeData: {};
 summerNoteconfig: {
  placeholder: string;
  tabsize: number;
  height: string;
  uploadImagePath: string;
  toolbar: (string | string[])[][];
  fontNames: string[];
};
  textJson:any= {};
  isOutsideEditor:boolean=false;
  lxAttributesIcons = AttributesLxIcons
  @Input() commentMediaArr: any[]=[];
  previewIndex: number;
  mediaArr: any[];
  modalRef:any;
  mediaIcons = MediaIcons;
  maxHeight: any=180;
  lineHeight: any=20;
  @Input() selectedChatObj:any;
  @Input() currentActiveTab:any;
  isTextSelected: boolean = false;
  isPopoverVisible: boolean = false;
  popoverTop: number = 0;
  popoverLeft: number = 0;
  arrOfCustomField: any[]=[];
  @Input() selectedLeadObj:any;
  sprintArr: any[]=[];
  @Input() rplyEnable :boolean = false;
  @Input() triggerType : any;
  @Input() mentionEnabled:boolean=true
  channelTemplates: any;
  preApprovedTemplates: any[]=[];
  @Input() disableEditor: boolean = false;
  @Input() selectedChatUserMsgarr:any[]=[];
  @Input() disabelSendBtn:boolean = true;
  @Input() sendOnChange:boolean =false;
  @Input() itemData:any={}
  @Input() viewType:string=''
  openTemplatepanel:boolean=false;
  isContentEditable:boolean=true;
  audioFlag: any;
  openScreenRecorderPlayer: boolean = false;
  recordingStarted: boolean|string = false;
  screenRecordVideo: any = {};
  timerInterval = null;
  recordingTimeForVideo: string = '00:00:00';
  @ViewChild('mediaPermissionModal') mediaPermissionModal: TemplateRef<any>;
  @ViewChild(ScreenRecordingComponent) screenRecordingComponent: ScreenRecordingComponent;
  dashboardJsonSub: Subscription;
  selectedEditFuncObj: any;
  popover:any;
  storedcurrentChipId: any;
  isValid:boolean=false;
  @ViewChild('addTitleforVideo') addTitleforVideo!: ElementRef<HTMLInputElement>;
  dashboardUser: any[]=[];
  constructor(private renderer: Renderer2,public constantService : ConstantService,private dashboardUtils: DashboardUtilsService,private commonUtils : CommonUtils,private modalService:NgbModal,public httpTransfer : HttpTransferService,public automationUtils : AutomationUtilsService,    private customStorageService: CustomStorageService,private modalServices: BsModalService,
    public sanitizer : DomSanitizer,private elementRef: ElementRef,

    ) { }

  async ngOnInit() {
    if(!this.orgUsersJson?.length){
      const orgUserJson = await this.dashboardUtils.getOrgUsers(); 
      this.orgUsersJson = Object.values(orgUserJson);
    }
    if(this.dashId){
      this.dashboardJsonSub = this.dashboardUtils.getUpdatedDashboardJson().subscribe((res : any) => {
        this.sprintArr = res[this.dashId]?.SPRINT || [];
      })   
      this.dashboardUser = await this.commonUtils.getDashboardUsersFromDashboardId(this.dashId)
      let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
      this.arrOfCustomField = JSON.parse(JSON.stringify(dashboardJson[this.dashId]['CUSTOM_FORM']));
      if(["WHATSAPP","FACEBOOK","INSTAGRAM","VIBER"].includes(this.selectedChatObj?.channelType)){
        await this.disableEditorPanel()
      }
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if(["WHATSAPP","FACEBOOK","INSTAGRAM","VIBER"].includes(this.selectedChatObj?.channelType)){
     await this.disableEditorPanel()
    }
    }

  openPreApproveMsgModal(modal){
    let data = {
      "dashboard_id" : this.dashId,
      "channel_id" : this.broadCastChannel ? this.broadCastChannel?._id : this.selectedChatObj?.channel_id
    }
    this.httpTransfer.whatsappPreApproved(data).subscribe((res : any) =>{
      this.preApprovedTemplates = res.result.data
     this.modalService.open(modal, { size: 'xl', windowClass: 'preapprovemsgModal'})
     if(res?.status==200 && this.multiselect){
      this.closeEmit.emit()
     }
    })
  }

 
  getTemplatesForWhatsapp(){
    let inputJson={
      'dashboard_id' : [this.dashId],
      "template_category": ["BOARD_TEMPLATES", "PERSONAL_TEMPLATES"]
    }
    this.httpTransfer.getEmailTemplate(inputJson).subscribe((res : any) =>{
      this.channelTemplates = res.result.templates
      //this.searchTemplate =  res.result.templates
    })
  }

  openPopover(event: Event) {console.log("1111111")}
  ngAfterViewInit() {
    console.log(this.htmlContent,this.columnsJson)
    if(this.htmlContent){
      Object.keys(this.columnsJson).forEach(element=>{
        if(!this.columnsJson[element]?.operationTypeFunction){
          if(this.columnsJson[element].display_key.includes('@')){
            this.htmlContent = this.htmlContent.replace(`{{${this.columnsJson[element].index}}}`,`<span contenteditable='false' style='color:blue'><b hidden="true">{{${this.columnsJson[element].key}}}</b><span >${this.columnsJson[element].display_key}</span></span>`)
          }
          else{
            this.htmlContent = this.htmlContent.replace(`{{${this.columnsJson[element].index}}}`,`<span contenteditable='false' style='width:fit-content;border: 1px solid #707070;border-radius: 7px;background-color:#E9EFFB;'><b hidden="true">{{${this.columnsJson[element].key}}}</b><span class="columsTootltip" style="margin:0 5px;" data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content="${this.columnsJson[element].display_label}">${this.columnsJson[element].display_key}</span><i class="fa fa-close" id="removeColumn"></i>${" "}</span>`)
          
          }
        }
         else{
          this.htmlContent = this.htmlContent.replace(`{{${this.columnsJson[element]?.index}}}`,`<span contenteditable='false' style='width:fit-content;border: 1px solid #707070;border-radius: 7px;background-color:#E9EFFB;' data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content="${this.columnsJson[element]?.functionLabel}" id="functionTooltip" class="columsTootltip ${this.columnsJson[element]?.index}">${this.columnsJson[element]?.functionLabel}<i class="fa fa-close" id="removeColumn"></i>${" "}</span>`)
        } 
      })
    }
   if(this.type=='summerNote'){
    this.columnSelection = false;
    this.summerNoteconfig = this.constantService.getSummerNoteConfig();
   this.initializeSummerNote()
   }
   else{
    this.editableDiv.nativeElement.innerHTML = this.htmlContent ? this.htmlContent : ''
   }
   this.showPopover()
   this.onCreate.emit(null);
  }

  
  getCareAtPositionForComment(){
    const selection = window.getSelection();
    console.log(selection)
    if (selection.rangeCount > 0) {
      this.storedCursorPosition = selection.getRangeAt(0);
      console.log(this.storedCursorPosition)
    }
   this.sendTextEmitter()
  }

  @HostListener('click', ['$event']) onClick(btn) {
    console.log(btn)
    let idValue= btn.target.id;
    if(idValue=='removeColumn'){
      $(btn.target).parent().remove()
    }
    else if (idValue=='functionTooltip'){
      console.log($(btn.target))
      console.log(this.columnsJson)
      this.popFunctionOpen.positionTarget = btn.target
      this.selectedEditFuncObj = this.columnsJson[btn.target?.classList[1]]?.functionObj
      this.selectedEditFuncObj['index'] = this.columnsJson[btn.target?.classList[1]]?.index
      this.storedcurrentChipId = $(btn.target)
      this.isValid = true;
      this.popFunctionOpen.open()
    } 
  }


  @HostListener('mouseenter', ['$event']) onMouseenter(event) {
    this.isOutsideEditor=false;
  }
  @HostListener('mouseleave', ['$event']) onMouseleave(event) {
    this.isOutsideEditor=true;
  }
  
  insertText(event,type='COLUMN') {
    console.log(event,type,this.storedCursorPosition)
    this.isMentionPanelOpen=false;
    this.makingColumnjson(event,type)
      const fragment = document.createDocumentFragment();
      const newElement = document.createElement('div');
     // let textNode = document.createElement('span')
     // newElement.setAttribute('contenteditable', 'true');
    //  newElement.style.display = "inline-block"
    let algorithm =  event?.algorithm == 'FETCH_FIRST' ? '(First value)' : event?.algorithm == 'FETCH_LAST' ? '(Lastvalue)' : event?.algorithm == 'FETCH_ALL' ? '(All value)' : ''
      if(type=='COLUMN'){
        let hoverContent =event?.key?.includes('{{variable.') ? ` ${event.value} ( Automation Variable)  ${algorithm}` : event?.key?.includes('triggeredItem') ? `Triggered Item ${event.value} ${algorithm}` : event.value=="index" ? `Start Value : ${event.indexValue.startIndex}<br>increment Value :${event.indexValue.endIndex}`: event.value=="random" ? `Random String of Length ${event.randomValue.length}, characters: ${event.randomValue.character_set}` : `Filtered Item ${event.value} ${algorithm}`
        newElement.innerHTML = `<span contenteditable='false' style='width:fit-content;border: 1px solid #707070;border-radius: 7px;background-color:#E9EFFB;'><b hidden="true">{{${event.key}}}</b><span class="columsTootltip" style="margin:0 5px;" data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content='`+hoverContent+`'>${event.value}</span><i class="fa fa-close" id="removeColumn"></i>${" "}</span><br>`
       this.showPopover()
      }
      else{
        newElement.innerHTML =`<span contenteditable='false' style='color:blue'><b hidden="true">{{${event?._id}}}</b><span>@${event?.name}</span></span>`;
        this.storeSelectionRangeData['anchoreNode'].replaceData(this.storeSelectionRangeData['atIndex'],this.storeSelectionRangeData['searchText'].length+1,'') 
      }
       while (newElement.firstChild) {
        fragment.appendChild(newElement.firstChild);
      } 
       const emptyTextNode = document.createTextNode('');
      // Insert the empty text node after the new span element
      fragment.appendChild(emptyTextNode); 
      const range = document.createRange();
   //   this.storedCursorPosition.insertNode(emptyTextNode);
   if (this.storedCursorPosition && (event?.value!==null || event?._id!==null)) {
    this.storeSelectionRangeData ?  this.storeSelectionRangeData['range']?.insertNode(fragment) : this.storedCursorPosition.insertNode(fragment)
      this.storedCursorPosition = null;
   }
   else{
    const targetElement = this.editableDiv.nativeElement; // Replace with the actual ID
    if (targetElement && this.type!='summerNote') {
      // Insert the node as the first child of the target element
      targetElement.insertBefore(fragment, targetElement.firstChild);
    }
    else{
      let hoverContent = event?.key?.includes('{{variable.') ? ` ${event.value} ( Automation Variable) ${algorithm}` : event?.key?.includes('triggeredItem') ? `Triggered Item ${event.value} ${algorithm}` : event.value=="index" ? `Start Value : ${event.indexValue.startIndex}<br>increment Value :${event.indexValue.endIndex}` : `Filtered Item ${event.value} ${algorithm}`
      $(this.editableDiv.nativeElement).summernote('pasteHTML', `<span contenteditable='false' style='width:fit-content;border: 1px solid #707070;border-radius: 7px;background-color:#E9EFFB;'><b hidden="true">{{${event.key}}}</b><span class="columsTootltip" style="margin:0 5px;" data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content='`+hoverContent+`'>${event.value}</span><i class="fa fa-close" id="removeColumn"></i>${" "}</span>`);
    }
  }
      this.columnIndex++;
      this.setFocusOnDiv()
      this.sendTextEmitter()
  }

  setFocusOnDiv(){
      const contentEditableElement = this.editableDiv.nativeElement;
      const range = document.createRange();
    // Set the range to the end of the content
    range.selectNodeContents(contentEditableElement);
    //range.setStartAfter(fragment.lastChild)
    range.collapse(false);
    console.log(range)
    // Create a Selection object and set the range
    const selection = window?.getSelection();
    console.log(selection)
    selection.removeAllRanges();
    selection.addRange(range);
    // Set the focus on the contenteditable div
    contentEditableElement.focus();
  }

  onFocus(){
    if(!this.editableDiv.nativeElement?.innerHTML?.trim()?.length){
      this.editableDiv.nativeElement.innerHTML = '<span><br></span>'
    }
  }

  openMentionPanel(){
    this.isMentionPanelOpen=false;
    const pattern = / @|&nbsp;@/g;
    let selection=window.getSelection(); // section
      let nodeContent=" "+selection.anchorNode.textContent; // get text from anchoreNode
      // Replace '&nbsp;' with a regular space
      nodeContent = nodeContent.replace(/\u00A0/g, ' ');
      let atIndex=nodeContent.lastIndexOf(' @',selection.anchorOffset+1) // get index of @ from last of cursor position
      if(nodeContent.length>1 && atIndex>=0){
        this.isMentionPanelOpen=true;
        let searchText=nodeContent.substring(atIndex+2,selection.anchorOffset+1)?.toLowerCase(); // get text after @ and before cursor text
        this.commentMentions= !this.internalChat ? this.orgUsersJson.filter((data) => data.name.toLowerCase().includes(searchText) || data.email.toLowerCase().includes(searchText)) : this.dashboardUser.filter((data) => data.name.toLowerCase().includes(searchText) || data.email.toLowerCase().includes(searchText));
        let loggedInUserId=this.customStorageService.getItem('id')
        this.commentMentions=this.commentMentions.filter(data=> data._id!=loggedInUserId)
        if(this.commentMentions.length==0 || searchText=='@')this.isMentionPanelOpen=false;
        this.storeSelectionRangeData={'range':selection.getRangeAt(0),'anchoreNode':selection.anchorNode,'searchText':searchText,'atIndex':atIndex,'selection':selection} // store data
        this.storedCursorPosition = selection.getRangeAt(0);
      }
  }

  colseMentionPanel(){
      setTimeout(()=>{this.isMentionPanelOpen=false},200)
  } 
    
  commentInput(){
      let commentInput = this.editableDiv.nativeElement.textContent
      if(commentInput?.length==0) {
        this.editableDiv?.nativeElement?.empty()
      }
      if(this.sendOnChange){
        this.sendHtml()
      }
  }

  initializeSummerNote() {
    this.storedCursorPosition = null
    setTimeout(() => {
      $(this.editableDiv.nativeElement).summernote({
      placeholder: '',
      toolbar: this.summernoteToolbar,
      disableDragAndDrop: true,
      callbacks : {
        onBlur:(e) =>{this.getCareAtPositionForComment()},
        onChange:(e)=>{this.onChange.emit(e)},
        onKeydown:(e)=>{},
        onfocusout:(e)=>{this.colseMentionPanel.bind(this)},
        onKeyup:(e)=>{
          if (this.mentionEnabled) {
              this.openMentionPanel();  // Ensure it's invoked
          }
        }
      },
    });
    this.columnSelection = true;
    if(this.htmlContent){this.updateSummernoteText(this.htmlContent)}
  },500)
  }
 

  sendHtml(isReturn=false){
    let text = null;
    console.log(this.columnsJson)
    if (this.editableDiv.nativeElement.textContent?.trim()?.length>0) {
      text = this.editableDiv.nativeElement.innerHTML
      this.sendEditorText.emit(text)
    }
    else if(!isReturn){
      this.sendEditorText.emit("")
    }
    if(isReturn){
      return text;
    }
  }


  makingColumnjson(event,type){
    let algorithm =  event?.algorithm == 'FETCH_FIRST' ? '(First value)' : event?.algorithm == 'FETCH_LAST' ? '(Lastvalue)' : event?.algorithm == 'FETCH_ALL' ? '(All value)' : ''
   if(event?.type!='catchHook'){
    if(type=='COLUMN'){
      event.value=="index" ?
        this.columnsJson[this.columnIndex]={'key': event.key,'display_key': `${event.value}`,'index':this.columnIndex,'start_value': event.indexValue.startIndex,'end_value': event.indexValue.endIndex,'customKey': null,'display_label': `Start Value : ${event.indexValue.startIndex}<br>increment Value :${event.indexValue.endIndex}`, 'type':type}
      :event.value=="random" ?
      this.columnsJson[this.columnIndex]={'key': event.key,'display_key': `${event.value}`,'index':this.columnIndex,'character_set': event.randomValue.character_set,'length': event.randomValue.length,'customKey': null,'display_label': `Random String of Length ${event.randomValue.length}, characters: ${event.randomValue.character_set}` , 'type':type}
      : event.algorithm ? 
      this.columnsJson[this.columnIndex]={'key':event.key,'display_key':`${event.value}`,'index':this.columnIndex,'display_label':event.key.includes('{{variable.') ? `${event.value} ( Automation Variable)` : event.key.includes('triggeredItem') ? `Triggered Item ${event.value} ${algorithm}` : `Filtered Item ${event.value} ${algorithm}`,'dashId': event.key.includes('triggeredItem')? this.dashId : this.item.filterDashId ,'customKey': event.customKey ? event.customKey : null ,'type':type,'filterColumnKey': this.filterCustomColumnKey ? this.filterCustomColumnKey : '' ,'algorithm':event?.algorithm}     
      : this.columnsJson[this.columnIndex]={'key':event.key,'display_key':`${event.value}`,'index':this.columnIndex,'display_label':event.key.includes('{{variable.') ? ` ${event.value} ( Automation Variable)` : event.key.includes('triggeredItem') ? `Triggered Item ${event.value}` : `Filtered Item ${event.value}`,'dashId': event.key.includes('triggeredItem')? this.dashId : this.item.filterDashId ,'customKey': event.customKey ? event.customKey : null ,'type':type,'filterColumnKey': this.filterCustomColumnKey ? this.filterCustomColumnKey : ''}     
     }  
        else{
          this.columnsJson[this.columnIndex]={'key': event._id,'display_key':`@${event.name}`,'index':this.columnIndex,'type':type}
        }
   }
   else{
   event.algorithm ? 
  this.columnsJson[this.columnIndex]={'key':event.key,'display_key':`${event.key}`,'index':this.columnIndex,'display_label': `${event.key} ${algorithm}`,'dashId': this.dashId,'customKey': event.customKey ? event.customKey : null,'type':'CATCHHOOK','algorithm':event?.algorithm}     
  : this.columnsJson[this.columnIndex]={'key':event.key,'display_key':`${event.value}`,'index':this.columnIndex,'display_label': `${event.key}`,'dashId': this.dashId ,'customKey': event.customKey ? event.customKey : null ,'type': 'CATCHHOOK'}     

    }
  }

  sendTextEmitter(type?){
    console.log(this.editableDiv.nativeElement.textContent)
    let text = this.type=='summerNote' ? $(this.editableDiv.nativeElement).summernote("code") : this.multiselect ? this.editableDiv.nativeElement.innerHTML : this.editableDiv.nativeElement.textContent
    console.log(text,this.columnsJson) 
    Object.keys(this.columnsJson).forEach(element=>{
      if(!this.columnsJson[element]?.operationTypeFunction){
        let  replaceVal = (this.type=='summerNote' || this.multiselect) ? `<span contenteditable="false" style='width:fit-content;border: 1px solid #707070;border-radius: 7px;background-color:#E9EFFB;'><b hidden="true">{{${this.columnsJson[element].key}}}</b><span class="columsTootltip" style="margin:0 5px;" data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content=${this.columnsJson[element].display_label}>${this.columnsJson[element].display_key}</span><i class="fa fa-close" id="removeColumn"></i>${" "}</span>` : `{{${this.columnsJson[element].key}}}${this.columnsJson[element].display_key} `  
        if(this.type=='summerNote' || this.multiselect){
          text.replace(/<span.*?>.*?<\/i>\s<\/span>/s, async (match, item) => {
            Object.keys(this.columnsJson).forEach(ele=>{
            if(match.includes(this.columnsJson[ele].key) && match.includes(this.columnsJson[ele]?.display_label)){
              text = text.replace(/<span.*?>.*?<\/i>\s<\/span>/s,`{{${this.columnsJson[ele].index}}}`)
            }
          })
          })
        }
        else{
        text = text.replace(replaceVal,`{{${this.columnsJson[element].index}}}`)
        }
      }
      else{
        let  replaceVal = (this.type=='summerNote' || this.multiselect) ? `<span contenteditable='false' style='width:fit-content;border: 1px solid #707070;border-radius: 7px;background-color:#E9EFFB;' data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content="${this.columnsJson[element]?.functionLabel}" id="functionTooltip" class="columsTootltip ${this.columnsJson[element]?.index}">${this.columnsJson[element]?.functionLabel}<i class="fa fa-close" id="removeColumn"></i>${" "}</span>` : `${this.columnsJson[element]?.functionLabel}`  
        if(this.type=='summerNote'){
          text.replace(/<span.*?>.*?<\/i>\s<\/span>/s, async (match, item) => {
            Object.keys(this.columnsJson).forEach(ele=>{
            if(match.includes(this.columnsJson[ele]?.functionLabel)){
              text = text.replace(/<span.*?>.*?<\/i>\s<\/span>/s,`{{${this.columnsJson[ele].index}}}`)
            }
          })
          })
        }
        else{
          text = text.replace(replaceVal,`{{${this.columnsJson[element].index}}}`)
        }
      }
    }) 
    if((text?.trim() !== "" && text?.trim() !== null) || this.commentMediaArr?.length){
      this.disabelSendBtn = false;
    }
    this.textJson ={
      'text': text,
      'columnsJson' : this.columnsJson,
      'columnIndex':this.columnIndex
    }
    console.log(this.textJson)
    this.onBlur.emit(this.textJson)
    if(this.isOutsideEditor){this.onOutsideClick.emit(this.textJson)}
  }

  showPopover(){
    setTimeout(()=>{
      $(document).ready($('.columsTootltip').popover())
     },100)
  }

  updateSummernoteText(text){
    $(this.editableDiv.nativeElement).summernote('code',text);
  }

  onUploadFile(event?){
    this.disabelSendBtn = false;
   this.popAttach.close();
   var files = [];
   console.log(event)
   files = event.target?event.target.files :event;
   if (event?.target?.files && event?.target?.files[0]) {
    var filesAmount = event.target.files.length;
    for (let i = 0; i < filesAmount; i++) {
      this.commentMediaArr.push(files[i]);
    /*    var reader = new FileReader();
      let previewData = {};
      reader.onload = (event: any) => {/* 
        previewData["name"] = files[i].name;
        previewData["url"] = event.target.result;
        previewData["type"] = files[i].type.split("/")[0];
        previewData["fileEvent"] = files[i] 
        this.commentMediaArr.push(files[i]);
        //console.log(previewData)
      }; */
     // reader.readAsDataURL(event.target.files[i]);
    } 
  }
  else this.commentMediaArr.push(files);
 
 
  }

  removeMedia(i){
    this.commentMediaArr.splice(i,1)
  }

  handleKeydown(event) {
    const element = event.target;
    if (event.key === 'Enter') {
      if (event.shiftKey) {} 
      else {
        // Prevent the default Enter behavior
        event.preventDefault();
        if(!this.isMentionPanelOpen){
       this.sendHtml()
       this.sendChatMsg()
 //       this.enableDisableEditor(false)
        }
      }
    }
  }

  sendChatMsg(){
    let text = this.editableDiv.nativeElement.textContent
    let sendObj={
      'text' : this.editableDiv.nativeElement.innerHTML
    }
    if(this.rplyObj){
      sendObj['reply_comment_id'] = this.rplyObj?._id
    }
    if(this.multiselect){
      sendObj['columnIndex']=this.columnIndex,
      sendObj['columnsJson']=this.columnsJson
    }
    this.removeReply()
    if(text?.trim() !== "" && text?.trim() !== null || !this.disabelSendBtn){
      this.disabelSendBtn = false;
      this.sendChat.emit(sendObj)
      this.disabelSendBtn = true;
      this.setFocusOnDiv()
    }
  }

  resetText(text?){
    console.log("reset")
    this.editableDiv.nativeElement.innerHTML = text ? text : ''
  }

  removeReply(){
      this.rplyDisable.emit()
  }



  showContextMenu(event: MouseEvent) {
    const selectedText = window.getSelection()?.toString();
    const range = window.getSelection()?.getRangeAt(0);
   this.isPopoverVisible = !!selectedText;
   const contextMenu = document.querySelector('.context-menu') as HTMLElement;
    // Position the context menu
  /*   if(this.isPopoverVisible){
      contextMenu.style.display = 'block';
      contextMenu.style.position = 'absolute';
    //  contextMenu.style.left = `${event.clientX}px`;
      contextMenu.style.top = `${event.clientY}px`;
    } else {
      contextMenu.style.display = 'none';
    } */

  }

    async disableEditorPanel(){
      var inputJson = {
        'dashboard_id': [this.dashId],
        'chat_user_id': [this.selectedChatObj?.chat_user_id],
         'channel_id': [this.selectedChatObj?.channel_id],
        'type' : ["EXTERNAL_INBOUND"],
        "pagination_details" : {
        "page_number" : 1,
        "page_size" : 1
       },
      }
      await this.httpTransfer.getCommentCorrespondingLead(inputJson).toPromise().then((res:any)=>{
      if(res.status==200){
        console.log(res.result["commentsInfo"][0])
        let receivedMsg = res.result["commentsInfo"][0]
        if(receivedMsg){
          console.log(this.isTimeValidForSendingMessage(receivedMsg['status_details']?.received_time))
          this.isTimeValidForSendingMessage(receivedMsg['status_details']?.received_time)
        }
        else{
          this.disableEditor = true
        }
    }
  })
    }
  

  applyBold() {
    document.execCommand('bold', false, null);
    this.isPopoverVisible = false;
  }

  applyItalic() {
    document.execCommand('italic', false, null);
    this.isPopoverVisible = false;
  }

  applyStrikethrough() {
    document.execCommand('strikethrough', false, null);
    this.isPopoverVisible = false;
  }

  columnValueemit(columnValue){
    const textNode = document.createTextNode(columnValue);
    if(this.storedCursorPosition){
      this.storedCursorPosition.insertNode(textNode);
    }
    else{
    const targetElement = this.editableDiv.nativeElement; // Replace with the actual ID
    if (targetElement) {
      // Insert the node as the first child of the target element
      targetElement.insertBefore(textNode, targetElement.firstChild);
    }
    }
    let text = this.editableDiv.nativeElement.textContent
        if(text?.trim() !== "" && text?.trim() !== null || !this.disabelSendBtn){
        this.disabelSendBtn = false;
      }
    this.setFocusOnDiv()
  }

  focusOnSummerNote(){
    if(this.type=='summerNote'){ 
      $(this.editableDiv.nativeElement).summernote('focus')
    }
  }

    mediaEmitForColumns(media){
      let obj={'text': this.sendHtml(true) ,'media' : media?.attachment}
      console.log(obj)
      this.mediaColumns.emit(obj)
    }

    isTimeValidForSendingMessage(receiveTime){
        var end = Math.floor(Date.now())
        const diff = end - receiveTime //e.whatsapp_object.received_time;
        if(diff >= 86400000){
          this.disableEditor = true;
        }
        else{
          this.disableEditor = false
        }
      }

      disableSendBtn(){
        this.disabelSendBtn = false;
      }
    

      useTemplateData(event){
        console.log(event)
        if(event?.attachments?.length){
          this.commentMediaArr = event?.attachments
          this.getTemplateData.emit(event)
        }
        else{
        const fragment = document.createDocumentFragment();
        const newElement = document.createElement('div');
        newElement.innerHTML = event?.message
        while (newElement.firstChild) {
          fragment.appendChild(newElement.firstChild);
        }
        if (this.storedCursorPosition && this.storedCursorPosition !== null) {
        this.storedCursorPosition.insertNode(fragment);
        }
        else{
          const targetElement = this.editableDiv.nativeElement; // Replace with the actual ID
        // Check if the target element is found
        if (targetElement) {
          // Insert the node as the first child of the target element
          targetElement.insertBefore(fragment, targetElement.firstChild);
        }
        } 
        this.storedCursorPosition = null;
        this.popAttach.close()
        this.openTemplatepanel = false;
        this.setFocusOnDiv()
        }
      }

      enableDisableEditor(value){
        setTimeout(() => {
          this.isContentEditable = value ? value : !this.isContentEditable
        }, 10);
      }

      async requestMediaPermission(audioFlag) {
        this.audioFlag = audioFlag;
        this.commonUtils.requestForRecording(audioFlag)
          .then(this.openScreenRecorder.bind(this))
          .catch(()=>this.modalServices.show(this.mediaPermissionModal))
      }

      openScreenRecorder() {
          this.openScreenRecorderPlayer = false;
          setTimeout(()=>this.openScreenRecorderPlayer = true, 100)
      }

      stopScreenRecording() {
        this.recordingStarted='loading'
        this.screenRecordingComponent.stopScreenRecording();
        setTimeout(()=>{
          this.addTitleforVideo.nativeElement.focus();
        },5000)
      }

      getScreenRecordedData(data) {
        data['type']='video/mp4'
        this.screenRecordVideo = data;
      }

      submitScreenRecord(){
        this.stopTimer();
        this.openScreenRecorderPlayer = false;
        if(this.screenRecordVideo) this.onUploadFile(this.screenRecordVideo);
        this.recordingStarted = false;
        this.screenRecordVideo = {};
      }

      startTimer(){
        let startTime=new Date().getTime()
        if(!this.timerInterval)
        this.timerInterval=setInterval(() =>{
        this.recordingTimeForVideo = this.commonUtils.timeConvert(new Date().getTime()-startTime)
        },1000)
      }

      stopTimer(){
        if(this.timerInterval) clearInterval(this.timerInterval);
        this.recordingTimeForVideo = '00:00:00';
      }

       // Add this method to disable dragover and drop events
  disableDragEvents(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  handlePaste(event: ClipboardEvent) {
   const items = (event.clipboardData || (window as any).clipboardData).items;
   for (const item of items) {
    if (item.type.indexOf('image') !== -1) {
      const blob = item.getAsFile();
        // Handle the pasted image blob as an attachment
        this.commentMediaArr.push(blob)
      }
    }
  }
  
      
      ngOnDestroy() {
        this.dashboardJsonSub?.unsubscribe();
        document.removeEventListener('click', this.onClick);
        document.removeEventListener('mouseenter', this.onMouseenter);
        document.removeEventListener('mouseleave', this.onMouseleave);
        if(this.timerInterval) clearInterval(this.timerInterval)
      }


  async selectedFunctionsEmit(functionObj) {
    console.log(functionObj)
    if(functionObj?.selectedFunction?.index>=0 && this.storedcurrentChipId){
      console.log(this.storedcurrentChipId)
      await this.makingColumnForFunction(functionObj,functionObj?.selectedFunction?.index)
      //delete this.columnsJson[functionObj?.selectedFunction?.index]
      this.storedcurrentChipId.html(`${functionObj?.functionLabel}`+'<i class="fa fa-close" id="removeColumn"></i>')
      this.storedcurrentChipId = {}
      return '';
    }
    else{
    await this.makingColumnForFunction(functionObj)
    const fragment = document.createDocumentFragment();
    const newElement = document.createElement('div');
    newElement.innerHTML = `<span contenteditable='false' style='width:fit-content;border: 1px solid #707070;border-radius: 7px;background-color:#E9EFFB;'><span  data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content="${functionObj?.functionLabel}" id="functionTooltip" class="columsTootltip ${this.columnIndex}">${functionObj?.functionLabel}</span><i class="fa fa-close" id="removeColumn"></i>${" "}</span> `
    while (newElement.firstChild) {
      fragment.appendChild(newElement.firstChild);
    }
    const emptyTextNode = document.createTextNode('');
    fragment.appendChild(emptyTextNode);
    console.log(this.storedCursorPosition)
    if (this.storedCursorPosition) {
      this.storeSelectionRangeData ? this.storeSelectionRangeData['range']?.insertNode(fragment) : this.storedCursorPosition.insertNode(fragment)
      this.storedCursorPosition = null;
    }
    else {
      const targetElement = this.editableDiv.nativeElement; // Replace with the actual ID
      if (targetElement && this.type!='summerNote') {
        targetElement.insertBefore(fragment, targetElement.firstChild);
      }
      else{
        $(this.editableDiv.nativeElement).summernote('pasteHTML', `<span contenteditable='false' style='width:fit-content;border: 1px solid #707070;border-radius: 7px;background-color:#E9EFFB;'><span data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content="${functionObj?.functionLabel}" id="functionTooltip" class="columsTootltip ${this.columnIndex}">${functionObj?.functionLabel}</span><i class="fa fa-close" id="removeColumn"></i>${" "}</span>`);
      }
    }
    this.columnIndex++;
    }
    this.showPopover()
    this.sendTextEmitter()
  }

  async makingColumnForFunction(functionObj,prevIndex?){
    let arr = []
    console.log(functionObj?.selectedFunction)
    for (const param of functionObj?.selectedFunction?.parameters || []) {
      if(param?.params?.length){
        let newArray = []
          for(const innerParam of param?.params){
            const result = await this.processParameter(innerParam);
            if (result !== undefined) {
                console.log(result);
                newArray.push(result);
            }
          }
          // Push the new array into the main arr
          arr.push(newArray);
      }
      else{
        let obj = await this.processParameter(param);
        console.log(obj)
        arr.push(obj);  
      }
    }  
    console.log(arr)
    let value_eval={
    "value_from": "EVALUATE",
    "operation_type": "COMPUTEFUNCTION",
    "value_data": {
        "function_name": functionObj?.selectedFunction?.name,
        "args": arr
        }
    }
    console.log(this.columnsJson)
    if(prevIndex>=0){
      this.columnsJson[prevIndex]={'value_eval':value_eval,'functionObj':functionObj?.selectedFunction,'functionLabel':functionObj?.functionLabel,'index':prevIndex,'operationTypeFunction':true,'display_label':functionObj?.functionLabel}
    }
    else{
      this.columnsJson[this.columnIndex]={'value_eval':value_eval,'functionObj':functionObj?.selectedFunction,'functionLabel':functionObj?.functionLabel,'index':this.columnIndex,'operationTypeFunction':true,'display_label':functionObj?.functionLabel}
    }
  }



  async processParameter(param,arr=[]) {
    if (param?.isColumn && param?.selectedColumnObj) {
      return {
        value_eval: {
          value_from: "EVALUATE",
          operation_type: (param?.type == 'string' || param?.sub_type == 'string' )? "COMPUTETEXT" : (param?.type == 'number' || param?.sub_type == 'number') ? "COMPUTENUMBER" : param?.type ? param?.type?.toUpperCase() : "COMPUTETEXT",
          value_data: `{{${param?.selectedColumnObj?.key}}}`,
          algorithm: param?.selectedColumnObj?.algorithm ? param?.selectedColumnObj?.algorithm : 'DIRECT_VALUE'
        }
      };
    } else if (param?.funcObj?.length) {
      let tempArr = [];
      let name = "";
      for (const params of param.funcObj) {
        name = params?.name;
        // Use Promise.all to wait for all async calls in the loop
        const innerParamsPromises = params?.parameters.map(innerParam => this.processParameter(innerParam));
        const innerParamsResults = await Promise.all(innerParamsPromises);
        tempArr.push(...innerParamsResults);
      }
      return {
        value_eval: {
          value_from: "EVALUATE",
          operation_type: "COMPUTEFUNCTION",
          value_data: { "function_name": name, "args": tempArr }
        }
      };
    } 
    else if(param?.value){
        return { value_eval: { value_from: param?.value?.date ? "OBJECT" : param?.sub_type ? param?.sub_type.toUpperCase() : param?.type ? param?.type?.toUpperCase() : "COMPUTETEXT", value_data: param?.value } };
      }
  }

  onKeyUp(event: KeyboardEvent,item) {
    if(this.viewType==='gridChecks'){
      let bool=this.commonUtils.onKeyBoardPress(event,item)
      if(bool){
        event.preventDefault()
      }
    }
  }

  

  }




