import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getPriority } from 'os';
import { BehaviorSubject, Observable } from 'rxjs-compat';
import { ConstantService } from '../../Enums/Constant.service';
import { MessageService } from '../message.service';
import { CustomStorageService } from './custom-storage.service';
import { HttpTransferService } from './httpTransfer.service';
import { MqttmessagingService } from './mqttmessaging.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardUtilsService {
  dashboardJson = {};
  orgUsersJson = {}
  dashboardJsonUpdate$ = new BehaviorSubject<any>(this.dashboardJson);
  orgUsersJsonUpdate$ = new BehaviorSubject<any>(this.orgUsersJson);  
  currencyArr: any={};
  currencyStoredJson: any={}
  broadcastChannelReceiver = new BroadcastChannel("Pronnel_Broadcast__Receiver");
  broadcastChannelSender = new BroadcastChannel("Pronnel_Broadcast_Sender");
  userOrgJson:any=[]
  colorsData:any=[]
  sleepTime:any;
  groupByDefaultColumnTypes = ['Dropdown', 'SingleSelect', 'Radiobutton', 'Country', 'Rating','SingleUser']// 'SingleUser'
  viewsData: any={};
  mqttSubscription: any;
  dashboardDataInfo:any={'DASHBOARD_INFO':[],'TAG':[],"SPRINT":[],"BUCKET":[],"DASHBOARD_USER":[],"CUSTOM_FORM":[],"CHANNELS":[]}
  dashboardFolderData:any= {};
  workFolderJson: any={};
  appDetails: any={};
  pronnelQuickFilter: any[]=[];

  constructor(private customStorageService: CustomStorageService,
    private httpTransfer: HttpTransferService,
    public datepipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private MqttmessagingService: MqttmessagingService,
    public constantService: ConstantService) {
      this.handleBroadcastChannel()
      this.handleUpdateDashboardData();
      this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
        if (message)this.updatesFromWss(message)
      });
    }
    ngOnInit(){
      if(this.customStorageService.getItem('isAuthenticated')) this.getOrgUsers().then(() =>{})
    }

    updatesFromWss(messagees) {
      let message = JSON.parse(messagees);
      let messageData=message?.additional_attributes;
      const msgDashboardId = messageData?.dashboard_id;
      // const msgDashboardId = message?.additional_attributes?.dashboard_id;
      // if(msgDashboardId === )
      if (message[ConstantService.constant.OBJECTTYPEKEY] == "BUCKET" || 
            message[ConstantService.constant.OBJECTTYPEKEY] == "DASHBOARD_USER" || 
            message[ConstantService.constant.OBJECTTYPEKEY] == "DASHBOARD" || 
            message[ConstantService.constant.OBJECTTYPEKEY] == "SPRINT" || 
            message[ConstantService.constant.OBJECTTYPEKEY] == "TAG" || 
            message[ConstantService.constant.OBJECTTYPEKEY] == "CUSTOM_FORM") {
         if(message[ConstantService.constant.ACTIVITYTYPEKEY] == ConstantService.constant.UPDATEACTIVITYTYPE) {
          this.handleUpdateDashboardAttribute(message, message[ConstantService.constant.OBJECTTYPEKEY], msgDashboardId);
         }
         if(message[ConstantService.constant.ACTIVITYTYPEKEY] == ConstantService.constant.ADDACTIVITYTYPE) {
          this.handleAddDashboardAttribute(message, message[ConstantService.constant.OBJECTTYPEKEY], msgDashboardId);
         }
         if(message[ConstantService.constant.ACTIVITYTYPEKEY] == "DELETE") {
          this.handleDeleteDashboardAttribute(message, message[ConstantService.constant.OBJECTTYPEKEY], msgDashboardId);
         }
         if(message[ConstantService.constant.ACTIVITYTYPEKEY] == 'UPDATE') {
          this.handleUpdateDashboardAttributeField(message, message[ConstantService.constant.OBJECTTYPEKEY], msgDashboardId);
         }
      }
      if(message[ConstantService.constant.OBJECTTYPEKEY] == "DASHBOARD" && message[ConstantService.constant.ACTIVITYTYPEKEY] == 'UPDATE'){
        if(!this.dashboardJson[msgDashboardId]){ 
           this.dashboardJson[msgDashboardId] = {'DASHBOARD_INFO':{}}
        }
        Object.keys(messageData?.details).forEach(key=>{
          this.dashboardJson[msgDashboardId].DASHBOARD_INFO[key]=messageData?.details[key]
        })
        this.dashboardJsonUpdate$.next(this.dashboardJson)

      }
      if (message[ConstantService.constant.OBJECTTYPEKEY] == 'USER'){
          let details=message?.additional_attributes?.details;
            if(message[ConstantService.constant.ACTIVITYTYPEKEY] == "DELETE"){
              details.is_deleted=true;
              this.orgUsersJson[details?._id]=details;
              this.orgUsersJsonUpdate$.next(this.orgUsersJson)
            }
            else if(message[ConstantService.constant.ACTIVITYTYPEKEY] == 'UPDATE'){
              if(this.customStorageService.getItem("id")==details?._id){
                this.customStorageService.setItem("role",details?.role)
                this.customStorageService.setItem("name",details?.name)
              }
              if(details){
                this.orgUsersJson[details?._id]=details;
                this.syncUserImages([details?._id]);
              }
            }
        }
    }
    async getDashboardFolderData(folderId=[]){
      let filterFolderIds=folderId?.filter(id=>id && !this.dashboardFolderData[id])
      if(filterFolderIds?.length>0){
        let inputJson = {
          type: ["DASHBOARD_FOLDER"],
          dashboard_folder_id: filterFolderIds,
        }
        let folder=await this.httpTransfer.getDashboardFolders(inputJson).toPromise();
        folder?.result?.folders?.forEach((folder)=>{
          this.dashboardFolderData[folder._id]=folder
         })
        
      }
      return this.dashboardFolderData
    }

    handleUpdateDashboardAttribute(message, oprationType, dashId) {
      if(!this.dashboardJson[dashId] || oprationType=='DASHBOARD') return
      if(oprationType == 'CUSTOM_FORM') {
        let settingDetails = message?.additional_attributes?.details;
        this.dashboardJson[dashId]["CUSTOM_FORM"] = settingDetails?.fields;
        this.dashboardJson[dashId]["EXISTING_CUSTOM_FORM"] = settingDetails?.existing_fields;
      } else if(oprationType == 'DASHBOARD_USER') {
        let settingDetails = message?.additional_attributes?.details;
        this.dashboardJson[dashId]["DASHBOARD_USER"] = settingDetails;
        let index=settingDetails.findIndex(user=>user._id==this.customStorageService.getItem("id"));
        if(index>-1){
          if(this.dashboardJson[dashId].DASHBOARD_INFO){
            this.dashboardJson[dashId].DASHBOARD_INFO.role=settingDetails[index]?.role || []
          }
          if(!this.dashboardJson[dashId].DASHBOARD_INFO && settingDetails[index]?.role.length>0){
            Object.keys(this.dashboardDataInfo).forEach(key=>{
              this.dashboardDataInfo[key]=this.dashboardDataInfo[key].filter(ele=>ele!=dashId)
            })
            delete this.dashboardJson[dashId]
          }
        }
      } else {
        let settingDetails = message?.additional_attributes?.details
        const index = this.dashboardJson[dashId][oprationType].findIndex(element => element._id === settingDetails._id);
        if(index > -1) this.dashboardJson[dashId][oprationType][index] = settingDetails;
        if(oprationType=='TAG') this.dashboardJson[dashId]['TAG_MAP'][settingDetails?.tag_id] = settingDetails;
      }
      this.dashboardJsonUpdate$.next(this.dashboardJson)
    }
  
    handleAddDashboardAttribute(message, oprationType, dashId) {
    let operationTypes = ['TAG', 'BUCKET', 'SPRINT']
    if(this.dashboardJson[dashId] && operationTypes.includes(oprationType)){
        let settingDetails = message?.additional_attributes?.details;
        if(!this.dashboardJson[dashId][oprationType]) {
          this.dashboardJson[dashId][oprationType] = [];  
        }
        if(oprationType=='SPRINT')settingDetails=this.getConvertDate(settingDetails)
        this.dashboardJson[dashId][oprationType].push(settingDetails)
        if(oprationType=='TAG') this.dashboardJson[dashId]['TAG_MAP'][settingDetails?.tag_id] = settingDetails;
        this.dashboardJsonUpdate$.next(this.dashboardJson)
      }
    }

    handleDeleteDashboardAttribute(message, oprationType, dashId) {
    let operationTypes = ['TAG', 'BUCKET', 'SPRINT']
    if(this.dashboardJson[dashId] && operationTypes.includes(oprationType)){
        let settingDetails = message?.additional_attributes?.details;
        const index = this.dashboardJson[dashId][oprationType].findIndex(element => element._id === settingDetails._id);
        if(index > -1) this.dashboardJson[dashId][oprationType].splice(index,1);
        if(oprationType=='TAG') delete this.dashboardJson[dashId]['TAG_MAP'][settingDetails?.tag_id];
        this.dashboardJsonUpdate$.next(this.dashboardJson)
      } 
    }
    handleUpdateDashboardAttributeField(message, oprationType, dashId) {
      let operationTypes = ['SPRINT']
      let settingDetails = message?.additional_attributes?.details;
      let updateKeys = message?.additional_attributes?.updated_keys || []
      if(oprationType=='DASHBOARD' && updateKeys.includes('sprint_enabled')){
        if(settingDetails?.sprint_enabled){
          delete this.dashboardJson[dashId]['SPRINT']
          this.dashboardDataInfo['SPRINT']=this.dashboardDataInfo['SPRINT']?.filter(id=>id!=dashId)
          this.setDashboardSprints([dashId])
        }else{
          this.dashboardJson[dashId]['SPRINT']=[]
        }
      }
      if(this.dashboardJson[dashId] && operationTypes.includes(oprationType)){
          const index = this.dashboardJson[dashId][oprationType].findIndex(element => element._id === settingDetails._id);
          if(oprationType=='SPRINT')settingDetails=this.getConvertDate(settingDetails)
          if(index > -1) this.dashboardJson[dashId][oprationType].splice(index,1,settingDetails);
          this.dashboardJsonUpdate$.next(this.dashboardJson)
        } 
      }

  getDashboardJson(dashId) {
    return this.dashboardJson[dashId]
  } 

  setdashboardBuckets(dashboardIdArray) {
    if(!this.checkIfDashboardDataPresent(dashboardIdArray,"BUCKET")) {
      this.getDashboardBuckets(dashboardIdArray).then(() => {
       this.dashboardJsonUpdate$.next(this.dashboardJson)
      });
    } else {
      this.dashboardJsonUpdate$.next(this.dashboardJson)
    }
      
  }

  setDashboardSprints(dashboardIdArray){
      if(!this.checkIfDashboardDataPresent(dashboardIdArray,"SPRINT")) {
        this.getDashboardSprint(dashboardIdArray).then(() => {
          this.dashboardJsonUpdate$.next(this.dashboardJson)
        });
      } else {
        this.dashboardJsonUpdate$.next(this.dashboardJson)
      }
   
  }

  setDashboardTag(dashboardIdArray) {
    if(!this.checkIfDashboardDataPresent(dashboardIdArray, "TAG")) {
      this.getDashboardTags(dashboardIdArray).then((  ) => {
        this.dashboardJsonUpdate$.next(this.dashboardJson)
      });
    } else {
      this.dashboardJsonUpdate$.next(this.dashboardJson)
    } 
  }

  setDashboardCustomForms(dashboardIdArray) {
    if(!this.checkIfDashboardDataPresent(dashboardIdArray, 'CUSTOM_FORM')) {
      this.getDashboardCustomForms(dashboardIdArray).then(()=>{
        this.dashboardJsonUpdate$.next(this.dashboardJson)
      });
    } else {
      this.dashboardJsonUpdate$.next(this.dashboardJson)
    }
    
  }


  setdashboardUsers(dashboardIdArray) {
    if(!this.checkIfDashboardDataPresent(dashboardIdArray,"DASHBOARD_USER")) {
      this.getDashboardUsers(dashboardIdArray).then(()=>{
        this.dashboardJsonUpdate$.next(this.dashboardJson);
      })      
    } else {
      this.dashboardJsonUpdate$.next(this.dashboardJson);
    }
  }

  async isDashboardUser(userId,dashboardId) {
    let isDashboardUser = false;
    if(this.dashboardJson[dashboardId]?.DASHBOARD_USER?.length>0) {
      this.dashboardJson[dashboardId].DASHBOARD_USER.some(user => user._id === userId) ? isDashboardUser = true : isDashboardUser = false;
    } else {
      let dashboardJson= await this.getDashboardUsers([dashboardId]) || {}
      let dashUsers=dashboardJson[dashboardId] ? dashboardJson[dashboardId]['DASHBOARD_USER'] : []
      dashUsers && dashUsers?.length>0 && dashUsers?.some(user => user._id === userId) ? isDashboardUser = true : isDashboardUser = false;
    }
    return isDashboardUser;
  }

  async getPronnelQuickFilters() {
    let quickFilterObj = {};
    quickFilterObj['filter_type'] = ['PRONNEL_FILTER'];
    if(!this.pronnelQuickFilter?.length){
    let res = await this.httpTransfer.getQuickFilters(quickFilterObj).toPromise()
    this.pronnelQuickFilter = res.result.filters || []
    return this.pronnelQuickFilter
    }
    else{
      return this.pronnelQuickFilter
    }
    let res = await this.httpTransfer.getQuickFilters(quickFilterObj).toPromise()
    this.pronnelQuickFilter = res.result.filters || []
  }


  getUpdatedDashboardJson(): Observable<any> {
    return this.dashboardJsonUpdate$.asObservable();
  }

  getUpdatedOrgUserJson(): Observable<any> {
    return this.orgUsersJsonUpdate$.asObservable();
  }

  getDashboardCustomFormIfPresent(dashboardId){
    return  this.dashboardJson[dashboardId]?.CUSTOM_FORM
  }
  getDashboardExistingCustomFormIfPresent(dashboardId){
    return  this.dashboardJson[dashboardId]?.EXISTING_CUSTOM_FORM
  }

  getDashboardBucketIfPresent(dashboardId){
    return  this.dashboardJson[dashboardId]?.BUCKET
  }

  getDashboardSprintIfPresent(dashboardId){
    return  this.dashboardJson[dashboardId]?.SPRINT
  }
  getDashboardTagIfPresent(dashboardId){
    return  this.dashboardJson[dashboardId]?.TAG_MAP
  }

  getDashboardTerminoloy(dashboardId){
    return  this.dashboardJson[dashboardId]?.DASHBOARD_INFO?.item_terminology
  }
 
  createOrUpdatePreviousActivity(workfolderId, isCreateBoard: boolean = true,dashId?) {
    if(isCreateBoard) this.messageService.setLeftMenuRefreshRequired(true)
    const clientIdObj = {
      client_id: this.customStorageService.getItem('clientId'),
      last_dashboard_id: dashId
    }
    if(workfolderId){
        this.router.navigate(['/board', dashId], isCreateBoard ? {relativeTo: this.route} : undefined);
      this.httpTransfer.postLastDashBoardActivityOfUser(workfolderId, clientIdObj).subscribe((res : any) => {      
        if(res !== undefined && res) {
        }
      });
    }
  } 

  async getDashboardCustomForms(dashboardIdArray){
    dashboardIdArray = await this.convertToJsonArray(dashboardIdArray,"CUSTOM_FORM")
    if(dashboardIdArray.length==0){return this.dashboardJson}
    let results = await this.httpTransfer.getCustomFieldsCorrespondingToDashBoard(dashboardIdArray).toPromise()
    let allBoards = results.result.data
    let connectedBoards = allBoards?.reduce((list,data)=>{return [...list,...(data?.custom_fields?.reduce((ids,field)=>{if(['MultipleBoard','Board'].includes(field?.type)){ids.push(field?.connected_board_id)};return ids},[]) || [])]},[])
    this.getAndFillData(dashboardIdArray,allBoards,'CUSTOM_FORM')
    if(connectedBoards?.length){
      await this.getAndSyncAllDashboardData(connectedBoards)
    }
    return this.dashboardJson;
  }

  async getDashboardChannels(dashboardIdArray,apiCall=false){
    if(!apiCall)dashboardIdArray = this.convertToJsonArray(dashboardIdArray,"CHANNELS");
    if(dashboardIdArray.length==0){return this.dashboardJson}
    let results = await this.httpTransfer.getChannelQuery({'dashboard_id' : dashboardIdArray}).toPromise()
    return this.getAndFillData(dashboardIdArray,results.result.channels,'CHANNELS')
  }

  async getDashboardTags(dashboardIdArray){
    dashboardIdArray = this.convertToJsonArray(dashboardIdArray,"TAG");
    if(dashboardIdArray.length==0){return this.dashboardJson}
    let results : any = await this.httpTransfer.getTagCorrespondingToDashId(dashboardIdArray).toPromise()
    let tags = results?.result?.tags?.filter(item=>!item.isDeleted);
    return this.getAndFillData(dashboardIdArray,tags,'TAG',true)
  }

  async getDashboardWorkFolder(workFolderIdArr, include_work_folder) {
    const promises = workFolderIdArr.map(async (workFolderId) => {
      /*   if (this.workFolderJson[workFolderId] && this.workFolderJson[workFolderId]['WORK_FOLDER_INFO']) {
            return;
        } */
        try {
            let res: any = await this.httpTransfer.getDashBoardForAdmin({ work_folder_id: workFolderIdArr, include_work_folder: include_work_folder }).toPromise();
            if (!this.workFolderJson[workFolderId]) {
                this.workFolderJson[workFolderId] = {};
            }
            console.log(res)
            this.workFolderJson[workFolderId]['WORK_FOLDER_INFO'] = res?.result?.dashboards;
        } catch (error) {
            console.error("Error fetching data for work folder ID:");
        }
    });
    await Promise.all(promises);
    console.log(this.workFolderJson);
    return this.workFolderJson;
}

  async getDashboardSprint(dashboardIdArray,pp?){
    dashboardIdArray = this.convertToJsonArray(dashboardIdArray,"SPRINT")
    if(dashboardIdArray.length==0){return this.dashboardJson}
    let results : any= await this.httpTransfer.getSprintCorrespondingtoDashBoard({},dashboardIdArray).toPromise()
    let sprintData = results.result?.sprintResult?.map(data=>this.getConvertDate(data));
    return this.getAndFillData(dashboardIdArray,sprintData,'SPRINT')
  }
  getConvertDate(sprintJson){
    if(sprintJson.planned_start_date!=null){
      sprintJson["planned_start_date_long"] = sprintJson["planned_start_date"]
      sprintJson["planned_start_date"] = sprintJson["startDateFormat"] = this.datepipe.transform(this.epochToDate(sprintJson.planned_start_date), 'd MMM y')
    }
    if(sprintJson.planned_end_date!=null){
      sprintJson["planned_end_date_long"] = sprintJson["planned_end_date"]
      sprintJson["planned_end_date"] = sprintJson["endDateFormat"]=this.datepipe.transform(this.epochToDate(sprintJson.planned_end_date), 'd MMM y')
    }
    return sprintJson;
  }

  async getDashboardBuckets(dashboardIdArray){
    dashboardIdArray = this.convertToJsonArray(dashboardIdArray,"BUCKET")
    if(dashboardIdArray.length==0){return this.dashboardJson};
    let results : any= await this.httpTransfer.getBucketsCorrespondingToDahBoard(dashboardIdArray).toPromise()
    let bucketsArr = results.result?.buckets.filter(item=>!item.isDeleted);
    return this.getAndFillData(dashboardIdArray,bucketsArr,'BUCKET')
  }

   async getCurrencyJson() {
     if(this.currencyArr == undefined || this.currencyArr == null ||  Object.keys(this.currencyArr).length == 0)
      {
        let results = await this.httpTransfer.getCurrency().toPromise()
        this.currencyArr = results;
        return this.currencyArr
     }
     else{
       return this.currencyArr
     }  
  } 

  async getStoredCurrencyJson(){
    if(this.currencyStoredJson == undefined || this.currencyStoredJson == null ||  Object.keys(this.currencyStoredJson).length == 0)
      {
        let currencyArr = await this.getCurrencyJson();
        this.currencyStoredJson = {}
        currencyArr.forEach(currenyJson => {
          this.currencyStoredJson[currenyJson.code] = currenyJson
        });
        return this.currencyStoredJson
     }
     else{
       return this.currencyStoredJson
     }
  }


  async getDashboardUsers(dashboardIdArray){
    if(Object.keys(this.orgUsersJson).length == 0){
      await this.getOrgUsers()
    }
    dashboardIdArray = this.convertToJsonArray(dashboardIdArray,"DASHBOARD_USER");
    if(dashboardIdArray.length==0){return this.dashboardJson};
    let res : any=await this.httpTransfer.getUsersCorrespondingToDashBoard({dashboard_id:dashboardIdArray}).toPromise()
    let dashUserjsonArr = res?.result?.reduce((list,user)=>{return [...list,...(this.orgUsersJson[user._id] ? [{...this.orgUsersJson[user._id],...user}] : [])]},[])
    return this.getAndFillData(dashboardIdArray,dashUserjsonArr,'DASHBOARD_USER')

  }

   async getAndSyncAllDashboardData(dashboardIdArray,triggerSubscribe=false,getDataList=['Tags','Sprints','Buckets','Info','CustomForms','DashboardUsers'],workFolderIds=[]){
    dashboardIdArray=dashboardIdArray.filter(n => n)
    if(dashboardIdArray.length<=0){return this.dashboardJson}
    let dashboardDetails = getDataList.includes('Info') || getDataList.includes('ActivityBoardInfo') ? this.getDashboardInformation(dashboardIdArray,workFolderIds,getDataList.includes('ActivityBoardInfo'))  : {}
    let dashboardTags =  getDataList.includes('Tags') ? this.getDashboardTags(dashboardIdArray) : {}
    let dashboardSprints = getDataList.includes('Sprints') ? this.getDashboardSprint(dashboardIdArray) : {}
    let dashboardBucket = getDataList.includes('Buckets') ? this.getDashboardBuckets(dashboardIdArray) : {}
    let dashboardUsers = getDataList.includes('DashboardUsers') ? this.getDashboardUsers(dashboardIdArray) : {}
    let dashboardCustomForm = getDataList.includes('CustomForms') ? this.getDashboardCustomForms(dashboardIdArray) : {}
    await Promise.all([dashboardTags, dashboardSprints,dashboardBucket,dashboardUsers, dashboardCustomForm ,dashboardDetails]); 
    if(triggerSubscribe) this.dashboardJsonUpdate$.next(this.dashboardJson)
        return this.dashboardJson
  }  

/*   async getAndSyncAllDashboardData(dashboardIdArray, triggerSubscribe = false, getDataList = ['Tags', 'Sprints', 'Buckets', 'Info', 'CustomForms', 'DashboardUsers']) {
    dashboardIdArray = dashboardIdArray.filter(n => n);
    if (dashboardIdArray.length <= 0) { return {}; }

    const fetchData = async (fetchFunction, dataType) => {
        try {
            return await fetchFunction();
        } catch (error) {
            console.error(`Failed to fetch ${dataType}:`, error);
            return null;
        }
    };

    const promises = [];
    if (getDataList.includes('Tags')) promises.push(fetchData(() => this.getDashboardTags(dashboardIdArray), 'Tags'));
    if (getDataList.includes('Sprints')) promises.push(fetchData(() => this.getDashboardSprint(dashboardIdArray), 'Sprints'));
    if (getDataList.includes('Buckets')) promises.push(fetchData(() => this.getDashboardBuckets(dashboardIdArray), 'Buckets'));
    if (getDataList.includes('Info')) promises.push(fetchData(() => this.getDashboardInformation(dashboardIdArray), 'Info'));
    if (getDataList.includes('DashboardUsers')) promises.push(fetchData(() => this.getDashboardUsers(dashboardIdArray), 'DashboardUsers'));
    if (getDataList.includes('CustomForms')) promises.push(fetchData(() => this.getDashboardCustomForms(dashboardIdArray), 'CustomForms'));
    const resultsArray = await Promise.all(promises);
    // Merge results
    const results = {
        Tags: getDataList.includes('Tags') ? resultsArray.shift() : null,
        Sprints: getDataList.includes('Sprints') ? resultsArray.shift() : null,
        Buckets: getDataList.includes('Buckets') ? resultsArray.shift() : null,
        Info: getDataList.includes('Info') ? resultsArray.shift() : null,
        DashboardUsers: getDataList.includes('DashboardUsers') ? resultsArray.shift() : null,
        CustomForms: getDataList.includes('CustomForms') ? resultsArray.shift() : null,
    };
    this.dashboardJson = { ...this.dashboardJson, ...results };
    if (triggerSubscribe) {
        this.dashboardJsonUpdate$.next(this.dashboardJson);
    }
    console.log(this.dashboardJson)
    return this.dashboardJson;
} */



  private checkIfDashboardDataPresent(dashboardIdArray, dashboardDataType){
    dashboardIdArray = this.convertToJsonArray(dashboardIdArray,dashboardDataType)
    let dataPresent = true
    for(let i = 0;i<dashboardIdArray.length;i++){
      let dashboardId = dashboardIdArray[i]
      if(!this.dashboardJson[dashboardId]){
        this.dashboardJson[dashboardId]= {}
      }
      if(!this.dashboardJson[dashboardId][dashboardDataType]){
        dataPresent =  false
      }
    }
    return dataPresent
  }

  public async getOrgUsers(leadId = undefined){
    if(Object.keys(this.orgUsersJson).length > 0 && !leadId){
      return this.orgUsersJson
    }
    const data = {include_bot: true}
    if(leadId){
      data['lead_id']=leadId;
    }
    let results:any = await this.httpTransfer.getListOfAllSalesUserCorrespondingToOrganisation(data).toPromise()
    let userFromServer = results['result']['responseData'];
    userFromServer.forEach((user) =>{
      this.orgUsersJson[user._id] = user
    })
    setTimeout(()=>{this.syncUserImages().then(() =>{})},1000)
    return this.orgUsersJson
  }
  getOrgUserIfPresent(){
    return this.orgUsersJson
  }

  private async syncUserImages(userIdsArr=[]){
    if(userIdsArr.length==0)userIdsArr = Object.keys(this.orgUsersJson)
    let imageResults : any = await this.httpTransfer.getUserImage({ user_id: userIdsArr }).toPromise();
    if (imageResults.status === 200) {
      let images = imageResults.result.images;
      images.forEach(async image => {
          if (image?.image_url) {
            let userId=image.user_id;
            this.orgUsersJson[userId]['image_url'] = image?.image_url;
            this.orgUsersJson[userId]['image_link'] = image?.image_url;
            let result= await this.imageToBase64Url(image?.image_url)
            result=result.toString().replace(":application/octet-stream;",":image/jpg;")
            this.orgUsersJson[userId]['image_url'] =  result;
          }
      });
    }
    this.orgUsersJsonUpdate$.next(this.orgUsersJson);
  }

  async imageToBase64Url(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve(base64data);
        return base64data
      }
    });
   }
   async getDashboardInformation(dashboardIdArray,workfolderIds=[],includeActivity=false){
    dashboardIdArray = this.convertToJsonArray(dashboardIdArray,'DASHBOARD_INFO');
    if(dashboardIdArray.length==0){return this.dashboardJson};
    let json={dashboard_id:dashboardIdArray};
    if(workfolderIds?.length>0)json['work_folder_id']=workfolderIds
    if(includeActivity)json['include_activity_type_board']=true;
    let results = await this.httpTransfer.getDashBoardForAdmin(json).toPromise()
    let dashboards = results?.result?.dashboards?.filter(item=>!item.isDeleted);
    return this.getAndFillData(dashboardIdArray,dashboards,'DASHBOARD_INFO')
  }
  
  getAndFillData(boardIds,data,key,createMap=false){
    let arrTypeFields=['DASHBOARD_INFO']
    boardIds?.forEach(id=>{
      if(!this.dashboardJson[id])this.dashboardJson[id]={}
      if(!this.dashboardJson[id][key])this.dashboardJson[id][key]=(key== arrTypeFields?.includes(key) ? {} : [])
    })
    if(data?.length>0){
      if(arrTypeFields?.includes(key)){
        data.forEach((dashjson)=>{this.dashboardJson[dashjson.dashboard_id][key]=dashjson;})
      }
      else if(key?.includes('CUSTOM_FORM')){
        data.forEach((dashjson)=>{
          this.dashboardJson[dashjson.dashboard_id]['CUSTOM_FORM']=dashjson?.custom_fields;
          this.dashboardJson[dashjson.dashboard_id]['EXISTING_CUSTOM_FORM']=dashjson?.existing_fields;
        })
      }
      else{
        boardIds?.forEach(id=>{
          this.dashboardJson[id][key]=data?.filter(val=>val?.dashboard_id?.includes(id));
          if(createMap)this.dashboardJson[id][key+'_MAP']=this.dashboardJson[id][key]?.reduce((map,val)=>{map[val?._id]=val;return map},{});
        })
      }
     
    }
    return this.dashboardJson
      
  }

  async contactTypeBoard(dashId){
    let contactTypeBoardData =[]
    let dashboardJson = await this.getDashboardCustomForms(dashId);
    dashboardJson=JSON.parse(JSON.stringify(dashboardJson))
    let customFields = (dashboardJson[dashId]['CUSTOM_FORM']) || [];
    let boardTypeArr = customFields.filter(data=>data.type=="Board" || data.type=="MultipleBoard") 
    boardTypeArr.forEach(async e=>{
      let dashboardInfo = await this.getDashboardInformation([e.connected_board_id])
      dashboardInfo = JSON.parse(JSON.stringify(dashboardInfo))
       let info = dashboardInfo[e.connected_board_id]['DASHBOARD_INFO'] || {}
      if(info?.board_type == "CONTACT"){
        let obj ={}
        obj['dashboardInfo'] = dashboardInfo[e.connected_board_id]?.DASHBOARD_INFO
        obj['customfield'] = dashboardInfo[e.connected_board_id]?.CUSTOM_FORM
        contactTypeBoardData.push(obj)
      }
  })
  return contactTypeBoardData;
  }
 
  epochToDate(epoch: any) {
    var date = new Date(epoch);
    return date;
  }
  convertToJsonArray = function(obj,fetchType?){
    obj= (obj instanceof Array) ? [...obj] : [obj]
    // filter out dash id that fetch data is present
    let filterObj=obj?.filter(dashId=>dashId && (!this.dashboardJson?.hasOwnProperty(dashId) || this.dashboardJson?.hasOwnProperty(dashId)  && !(this.dashboardJson[dashId]?.hasOwnProperty(fetchType))))
    filterObj.forEach(dashId=>{
      if(!this.dashboardJson[dashId])this.dashboardJson[dashId]={}
    })
    return filterObj
  }
  async getBucketName(bucketId, dashId) {
    let bucketArr = [];
    let bucketName = null;
    let dashboardJson  = await this.getDashboardBuckets(dashId) || this.dashboardJson;
    bucketArr = dashboardJson[dashId]?.BUCKET || [];
    bucketArr.forEach(bucket => {
    if(bucket._id === bucketId) {
      bucketName = bucket.name;
    }
   });
   return bucketName;
  }

  async getSprintName(sprintId, dashId) {
    let sprintArr = [];
    let sprintName = null;
    let dashboardJson  = await this.getDashboardSprint(dashId) || this.dashboardJson;
    sprintArr= dashboardJson[dashId]?.SPRINT || [];
    sprintArr.forEach(sprint => {
      if(sprint._id === sprintId) {
        sprintName = sprint.name;
      }
    });
    return sprintName;
  }
  clearDashboardJson(){
  this.dashboardDataInfo={'DASHBOARD_INFO':[],'TAG':[],"SPRINT":[],"BUCKET":[],"DASHBOARD_USER":[],"CUSTOM_FORM":[],"CHANNELS" : []}
  this.dashboardJson = {};
  this.orgUsersJson = {};
  this.userOrgJson=[]
  }

  async getUserOrganigations(){
    let userId=this.customStorageService.getItem('id')
    if(this.userOrgJson && this.userOrgJson.length>0){
        return this.userOrgJson
    }
    let orgsData : any= await this.httpTransfer.orglist(userId).toPromise();
    if(orgsData.status==200)this.userOrgJson=(orgsData.result.responce || [])
    return this.userOrgJson;
  }
  async getColors(){
    if(this.colorsData.length>0){
      return this.colorsData
    }
    let color : any= await this.httpTransfer.getColor().toPromise();
    this.colorsData=color.colors || [];
    return this.colorsData
  }

  handleBroadcastChannel(){
    // send request to  sender for data
    this.broadcastChannelSender.postMessage('send request');
    // sender send data to reciver
    this.broadcastChannelSender.onmessage = (event) => {
      let data={}
      data["orgUsersJson"]= this.orgUsersJson
      data["dashboardJson"] =this.dashboardJson
      data["userOrgJson"] =this.userOrgJson
      data["orgId"] = this.customStorageService.getItem('organisationid')
      data["userId"] = this.customStorageService.getItem('id')
      data["currencyArr"] = this.currencyArr
      data['colorsData']=this.colorsData
      // send data to receiver
      this.broadcastChannelReceiver.postMessage(data)
    };
    // reciver get data send by sender 
    this.broadcastChannelReceiver.onmessage = (event) => {
      if(event && event?.data?.orgId==this.customStorageService.getItem('organisationid')){
        let data =event.data
        console.log(data)
        if(data && data?.hasOwnProperty("orgUsersJson") && Object.keys(data["orgUsersJson"]).length>0 && Object.keys(this.orgUsersJson).length==0){
          this.orgUsersJson=data["orgUsersJson"]
          this.orgUsersJsonUpdate$.next(this.orgUsersJson);
        }
        // if(data && data?.hasOwnProperty("dashboardJson") && Object.keys(data["dashboardJson"]).length>0 && Object.keys(this.dashboardJson).length==0)this.dashboardJson=data["dashboardJson"]
        if(data && data?.hasOwnProperty("userOrgJson") && data["userOrgJson"]?.length>0 && this.userOrgJson?.length==0 && data["userId"] == this.customStorageService.getItem('id'))this.userOrgJson=data["userOrgJson"]
        if(data && data?.hasOwnProperty("colorsData") && data["colorsData"]?.length>0 && this.colorsData?.length==0)this.colorsData=data["colorsData"]
        if(data && data?.hasOwnProperty("currencyArr") && data["currencyArr"]?.length>0 && this.currencyArr?.length==0)this.currencyArr=data["currencyArr"]
      }
    };
  }
  handleUpdateDashboardData(){
    window.addEventListener('offline', ()=>{this.sleepTime=new Date()});
    window.addEventListener('online',  ()=>{
      let time=((new Date().getTime())-this.sleepTime.getTime())
      console.log('update',time,this.sleepTime)
      if(time>=60000){
        let dashIds=JSON.parse(JSON.stringify(Object.keys(this.dashboardJson)))
        if(dashIds.length>0){
          Object.keys(this.dashboardDataInfo).forEach(key=>this.dashboardDataInfo[key]=[])
          this.dashboardJson={}
          this.getAndSyncAllDashboardData(dashIds,true)
        }
      }
    });
  }

  getGroupByOptions(arrayOfCustomFields, itemAttributesObj={}){
    let customFieldsForGroupBy = arrayOfCustomFields.filter((customField) => this.groupByDefaultColumnTypes.includes(customField.type) && customField?.hidden!=='ALWAYS')
      customFieldsForGroupBy?.forEach(field => {
        field.isHide = true
        field.itemName = field.display_key,
        field.columnKey = field.key
        field.groupByKey = field.key
      })
      let filteredDefaultGroupBy = this.constantService.constant.DEFAULT_GROUP_BY_OPTIONS?.reduce((list,data)=>{return [...list,{...data,hidden:(itemAttributesObj[data?.columnKey]?.hidden || false),display_key:(itemAttributesObj[data?.columnKey]?.label || data?.itemName)}]},[])
      let groupByOptions = [...filteredDefaultGroupBy, ...customFieldsForGroupBy]
      return groupByOptions
  }

  getSortByOptions(arrayOfCustomFields, itemAttributesObj={}){
    let sortByOptions = Object.values(this.constantService.constant.sortByOptions)?.reduce((list,data)=>{return [...list,{...data,hidden:(itemAttributesObj[data?.sortByKey]?.hidden || false),display_key:(itemAttributesObj[data?.sortByKey]?.label || data?.display_key)}]},[])
    let modifiedCustomList=arrayOfCustomFields?.reduce((list,customField)=>{if(customField.hidden!='ALWAYS' && customField.type!='Mirror' && this.constantService.constant.allowSortByCustomType?.includes(customField.type)){list.push({...customField,'sortByKey':'custom_fields.'+customField.key})}return list},[]) || []
    let sortingCustomFields=[...sortByOptions,...modifiedCustomList]
    return sortingCustomFields;
  }
    // dashboard graph condition
   async convertColumnToStringForBoard(dashIds,column_type,value,group_by_date,leadDataObject,cyclicType){
    if(value==='null')value=null;
      let convertedValue=""
      let i = 0;
      while(!convertedValue.length && i<dashIds.length){
        let dashId = dashIds[i]
        if(!this.dashboardJson[dashId] || !Object.keys(this.dashboardJson[dashId]).length ){
          await this.getAndSyncAllDashboardData(dashIds)
        }
        switch(column_type){
          case this.constantService.constant.BUCKETCONSTANT:
            this.dashboardJson[dashId]
            ['BUCKET'].forEach(ele =>{
              if(value!=null || value!=undefined){
                if(value==ele?._id){
                  convertedValue=ele?.name?ele.name:'';
                }
              }
              else{
                convertedValue='No bucket'
              }
            })
            break;
          case this.constantService.constant.TAGCONST:
            this.dashboardJson[dashId]
            ['TAG'].forEach(ele =>{
              if(value!=null || value!=undefined){
                if(value==ele.tag_id){
                  convertedValue=ele.name?ele.name:'';
                }
              }
              else{
                convertedValue='No tags'
              }
            })
            break;
            case (this.constantService.constant.CUSTOMTYPES.BOARD || this.constantService.constant.CUSTOMTYPES.MULTIPLEBOARD):
               if(value && leadDataObject[value]){
                convertedValue=leadDataObject[value]
              }
              else convertedValue='No Lead'
            break;
            case this.constantService.constant.DATE:
                if((value!=null || value!=undefined)&&group_by_date){
                  if(value || value===0){
                   convertedValue=this.convertGroupByDateCountToDate(group_by_date,value)
                  }
                }
                else{
                  convertedValue='No date'
                }
              break;
              case 'SnapshotDate':
                if((value!=null || value!=undefined) && (cyclicType!=='' && cyclicType!='sprint')){
                  if(value || value===0){
                   convertedValue=this.convertGroupByDateCountToDate('DAY',value)
                  }
                }
                else if((value!=null || value!=undefined )&& (cyclicType!='' && cyclicType==='sprint')){
                  this.dashboardJson[dashId]
                  ['SPRINT'].forEach(ele =>{
                    if(value!=null || value!=undefined){
                      if(value==ele._id){
                        convertedValue=ele.name?ele.name:'';
                      }
                    }
              })
                }
                else{
                  cyclicType!='sprint'?convertedValue='No date':convertedValue='No sprint'
                }
              break;
            case this.constantService.constant.SPRINT:
              this.dashboardJson[dashId]
              ['SPRINT'].forEach(ele =>{
                if(value!=null || value!=undefined){
                  if(value==ele._id){
                    convertedValue=ele.name?ele.name:'';
                  }
                }
                else{
                  convertedValue='No phase'
                }
              })
              break;

            case (this.constantService.constant.CUSTOMTYPES.SINGLEUSER || this.constantService.constant.CUSTOMTYPES.MULTIUSER):
              for(let key in this.orgUsersJson ){
                if(key){
                  if(value!=null || value!=undefined){
                    if(value==this.orgUsersJson[key]['_id']){
                      convertedValue=this.orgUsersJson[key]['name']
                    }
                  }
                  else{
                    convertedValue='No user'
                  }
                }
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.CURRENCY:
                  if(value!=null || value!=undefined){
                    if(value){
                      convertedValue=value['currency']+'-'+value['amount']
                     }
                  }
                  else{
                    convertedValue='No currency'
                  }
            break;
            case this.constantService.constant.UPDATELEADTYPE.ASSIGNED:
              for(let key in this.orgUsersJson ){
                if(key){
                  if(value!=null || value!=undefined){
                    if(value==this.orgUsersJson[key]['_id']){
                      convertedValue=this.orgUsersJson[key]['name']
                    }
                  }
                  else{
                    convertedValue='No Assignee'
                  }
                }
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.NUMBER:
              if(value!=null || value!=undefined){
                  convertedValue=value?value:0;
              }
              else{
                convertedValue='No data'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.TEXT:
              if(value!=null || value!=undefined){
                  convertedValue=value?value:'';
              }
              else{
                convertedValue='No text'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.EMAIL:
              if(value!=null || value!=undefined){
                  convertedValue=value?value:'';
              }
              else{
                convertedValue='No email'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.RADIO:
              if(value!=null || value!=undefined){
                  convertedValue=value;
              }
              else{
                convertedValue='No value'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.DROPDOWN:
              if(value!=null || value!=undefined){
                  convertedValue=value;
              }
              else{
                convertedValue='No value'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.CHECKBOX:
              if(value!=null || value!=undefined){
                  convertedValue=value;
              }
              else{
                convertedValue='No value'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.RATING:
              if(value!=null || value!=undefined){
                  convertedValue=value?value:'';
              }
              else{
                convertedValue='No rating'
              }
            break;
            // case this.constantService.constant.CUSTOMTYPES.BOARD:
            //   if(value!=null || value!=undefined){
            //       convertedValue=this.dashboardJson[dashId]
            //       ['DASHBOARD_INFO'].dashboard_name?this.dashboardJson[dashId]
            //       ['DASHBOARD_INFO'].dashboard_name :'';
            //   }
            //   else{
            //     convertedValue='No board'
            //   }
            // break;
            case this.constantService.constant.CUSTOMTYPES.URL:
              if(value!=null || value!=undefined){
                  convertedValue=value;
              }
              else{
                convertedValue='No url'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.MULTI_URL:
              if(value!=null || value!=undefined){
                  convertedValue=value;
              }
              else{
                convertedValue='No url'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.MULTISELECT:
              if(value!=null || value!=undefined){
                  convertedValue=value;
              }
              else{
                convertedValue='No value'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.IPADDRESS:
              if(value!=null || value!=undefined){
                  convertedValue=value?value:'';
              }
              else{
                convertedValue='No ip address'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.COUNTRY:
              if(value!=null || value!=undefined){
                  convertedValue=value?value:'';
              }
              else{
                convertedValue='No country'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.MOBILE:
              if(value!=null || value!=undefined){
                if(value?.country_code || value.mobile_number){
                  let dialCode
                  if(value.country_code){
                    dialCode=this.currencyArr.filter(x=>value.country_code.includes(x.code))
                  }
                  convertedValue=value.country_code?dialCode[0]['dial_code']+': '+value.mobile_number:value.mobile_number;
                }
                else{
                  convertedValue='No mobile number'
                }
              }
              else{
                convertedValue='No mobile number'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.TEXTAREA:
              if(value!=null || value!=undefined){
                let replacedValue=value.replace(/<\/?[^>]+(>|$)/g, "");
                  convertedValue=value?replacedValue:'';
              }
              else{
                convertedValue='No text'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.TIME_TRACKER:
              if(value!=null || value!=undefined){
                  convertedValue=value?value:'';
              }
              else{
                convertedValue='No tracker'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.LOCATION:
              if(value!=null || value!=undefined){
                  convertedValue=value.description?value.description:'';
              }
              else{
                convertedValue='No location'
              }
            break;
            case this.constantService.constant.CUSTOMTYPES.MIRROR:
              if(value!=null || value!=undefined){
                  convertedValue=value?value:'';
              }
              else{
                convertedValue='No mirror value'
              }
            break;
            case this.constantService.constant.PRIORITYCONSTANT:
             if(value==0){
              convertedValue='Low'
             }
             else if(value==1){
              convertedValue='Medium'
             }
             else if(value==2){
              convertedValue='High'
             }
             else if(value==3){
              convertedValue='Urgent'
             }
             else convertedValue='No priority'
            break;
        }
        i++;
      }
        return convertedValue
    }
    convertGroupByDateCountToDate(group_by_date,value){
      const initialDate = group_by_date==='DAY' || group_by_date==='WEEK' || group_by_date==='DAY_OF_THE_WEEK'? new Date(1900, 0, 1): new Date(1900, 0, 0);
      let convertedValue = "";
      switch (group_by_date) {
        case "DAY":
          const current_Date_value = new Date(initialDate.getTime() + value * 86400000);
          convertedValue = current_Date_value.toDateString()
          break;
        case "MONTH":
          const years = initialDate.getFullYear() + Math.floor((initialDate.getMonth() + value) / 12);
          let months = (initialDate.getMonth() + value) % 12;
          convertedValue = new Date(years, months).toLocaleDateString("en-US", {
            month: 'short',
            year: "numeric",
          });
          break;
        case "DAY_OF_THE_WEEK":
          console.log("value",value)
          convertedValue=value===0?'Sunday':value===1?'Monday':value===2?'Tuesday':value===3?'Wednesday':value===4?'Thursday':value===5?'Friday':'Saturday'
          break;
        case "DAY_OF_THE_MONTH":
          const myears = initialDate.getFullYear() + Math.floor((initialDate.getMonth() + value) / 12);
          let ymonths = (initialDate.getMonth() + value) % 12+1;
          convertedValue = new Date(myears, ymonths).toLocaleDateString("en-US", {
            month: 'short',
          });
          break;
        case "WEEK":
          const current_Date = new Date(initialDate.getTime() + value * 604800000);
          let firstdayOfWeek = new Date(current_Date.setDate(current_Date.getDate() - current_Date.getDay()));
          let lastdayOfWeek = new Date(current_Date.setDate(current_Date.getDate() - current_Date.getDay()+6));
          convertedValue=firstdayOfWeek.toDateString()+'-'+lastdayOfWeek.toDateString();
          break;
        case "QUARTER":
          const year = initialDate.getFullYear() + ((initialDate.getMonth() + (value * 3)) / 12);
          let month = (initialDate.getMonth() + (value * 3)) % 12;
          if (month === 0) {
            month = 12;
          }
          let quater=Math.floor((month+3)/3)
          let yearConverted=Math.floor(year)
          convertedValue=String('Q'+quater+'-'+yearConverted)
          break;
        case "YEAR":
          convertedValue=String(value);
          break;
      }
      return convertedValue;
    }

    async getDashboardViews(dashId,requiredApiCall=false) {
      if(!this.viewsData[dashId] || requiredApiCall){
        let json = {'dashboard_id':[dashId]}
        let viewResponse= await this.httpTransfer.getDashboardViews(json,false).toPromise();
        this.viewsData={[dashId]:{}}
        viewResponse['result']['message']?.forEach(view=>{this.viewsData[dashId][view?._id]=view})
      }
      return this.viewsData[dashId]
    }

    async fetchAppDetails(dashId) {
      if(this.appDetails?.showAppView && this.appDetails?.appConnectionJson){
        return this.appDetails
      }
      try {
        // Fetch external apps
        const externalAppsResponse = await this.httpTransfer.getExternalAppList({'status': ["LIVE"]}).toPromise();
        const externalApps = externalAppsResponse?.result?.external_apps || [];
    
        if(externalApps?.length && dashId){
          // Fetch app connections
        const appConnectionsInput = {
          "dashboard_id": [dashId],
          'externalapp_id': externalApps.map(e => e?._id),
        };
        const appConnectionsResponse = await this.httpTransfer.getAppConnections(appConnectionsInput).toPromise();
        
        let appConnectionJson = {};
        const connections = appConnectionsResponse?.result?.externalapp_connections || [];
        connections.forEach(c => {
          if (!appConnectionJson[c?.externalapp_id]) {
            let tempObj = externalApps.filter(a=>a?._id == c?.externalapp_id) || []
            appConnectionJson[c?.externalapp_id] = { connection: [] ,appInfo : tempObj[0]}
          }
          appConnectionJson[c?.externalapp_id].connection.push(c);
        });
    
        // Fetch features
        const featuresInput = {
          'externalapp_id': externalApps.map(e => e?._id),
          'externalapp_version_id': externalApps.map(e => e?.info?.version_id),
          'type': ['BOARD_VIEW'],
        };
        const featuresResponse = await this.httpTransfer.getfeatures(featuresInput).toPromise();
        
        const connectionFeatures = featuresResponse?.result.externalapp_features.filter(m => m?.type === 'BOARD_VIEW') || [];
        connectionFeatures.forEach(f => {
          if (!appConnectionJson[f?.externalapp_id]) {
            appConnectionJson[f?.externalapp_id] = { features: [] };
          } else if (!appConnectionJson[f?.externalapp_id].features) {
            appConnectionJson[f?.externalapp_id].features = [];
          }
          appConnectionJson[f?.externalapp_id].features.push(f);
        });
    
        // Set the app details and return the result
        if (connections?.length) {
          const obj = { 'showAppView': true, 'appConnectionJson': appConnectionJson};
          this.appDetails = obj
          return obj;
        }
        }
    
      } catch (error) {
        console.error("Error fetching app details:", error);
        throw error;  // Optionally rethrow the error or handle it as needed
      }
    }
    

  }
    



