<div class="colorBody">
    <div class="w-100 colorContent">
        <div class="w-100" [ngClass]="{'selectNone': !showLabel}"><span class="f-s-r">{{'color.select' | translate}} {{'color.color' | translate}}</span></div>
        <div class="w-100 preDefineColorBox">
               <div><span (click)="selectedColor=null;colorChange.emit(selectedColor)"><app-dashboard-icon [size]="16"  [name]="'resetIcon'" [iconJson]="attributesIcons"></app-dashboard-icon></span></div>
             <ng-container *ngFor="let preColor of predefineColor">
                <div class="colorBox" [ngStyle]="{'background': preColor}" (click)="selectedColor=preColor;colorChange.emit(preColor)"></div>
             </ng-container>
        </div>
    </div>
    
    <div class="w-100 colorContent">
        <div class="w-100 d-flex align-items-center" style="gap:16px;"><span class="f-s-r">{{'color.custom' | translate}} {{'color.color' | translate}}</span>
           <input style="width:60px;height:20px;" type="color" id="favcolor" name="favcolor" [(ngModel)]="selectedColor" (ngModelChange)="colorChange.emit(selectedColor)">
        </div>
    </div>
</div>