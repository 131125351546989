import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import videojs from "video.js";
import * as adapter from "webrtc-adapter/out/adapter_no_global.js";
import * as RecordRTC from "recordrtc";
import * as Record from "videojs-record/dist/videojs.record.js";
import { HttpTransferService } from "../../../services/httpTransfer.service";
@Component({
  selector: "app-screen-recording",
  templateUrl: "./screen-recording.component.html",
  styleUrls: ["./screen-recording.component.scss"],
})
export class ScreenRecordingComponent implements OnInit {
  @Input("leadId") idx: any;
  @Input() audioFlag: any;
  @Output("recordedData") recordedData = new EventEmitter<any>();
  @Output() recordingStarted = new EventEmitter<boolean>();
  private config: any;
  private player: any;
  private plugin: any;
  private segmentNumber:number=1;
  private uploadingId :string=undefined;
  private uploadArray:any=[];
  private inputJson:any={};
  imagePath: string=undefined;
  imageDownloadUrl:string="";
  isCompleted: any;
  videoFile:any= {};
  // constructor initializes our declared vars
  constructor(elementRef: ElementRef,
    private httpTransfer: HttpTransferService,
    ) {
    this.player = false;
    
    // save reference to plugin (so it initializes)
    this.plugin = Record;

    this.config = {
      controls: true,
      width: 400,
      height: 250,
      fluid: false,
      bigPlayButton: false,
      controlBar: {
        volumePanel: false,
        fullscreenToggle: false,
      },
      plugins: {
        record: {
          videoMimeType :'video/mp4',
          audio: true,
          screen: true,
          debug: true,
          maxLength:86400,//24*60*60
          displayMilliseconds: false,
          timeSlice: 480000,// 8*60*1000 
        },
      },
    };
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    event.preventDefault();
  } 

  ngOnInit(): void {}
  stopScreenRecording() {
    // if data already uploaded on aws (case when user stop recording on browser stop button)
    if(Object.keys(this.videoFile).length>0 && this.isCompleted){
      this.recordedData.emit(this.videoFile)
    }
    this.player.record().stop();
    // this.player.record().finished();
  }

  emitScreenRecording() {
    this.recordingStarted.emit(false);
  }

  startRecording() {
    this.recordingStarted.emit(true);
    this.player.record().start();
  }
  ngAfterViewInit() {
    this.playerCallback();
  }

  playerCallback() {
    let el = "video_" + this.idx;
    this.config.plugins.record.audio = this.audioFlag;
    this.player = videojs(document.getElementById(el), this.config, () => {
      console.log(this.player)
    });
    this.player.record().getDevice();
    this.player.on("deviceReady", () => {
      // console.log('deviceReady');
      this.startRecording();
    });
    this.player.on('stopRecord',()=>{
      this.isCompleted=true;
      // console.log('stopRecording');
    })
    this.player.on("finishRecord", async () => {
      // console.log('finishRecording');
    
    });
    // error handling
    this.player.on("error", (element, error) => {
      // console.warn(error);
    });
    this.player.on("deviceError", () => {
      // console.error("device error:", this.player.deviceErrorCode);
    });
    this.player.on('timestamp',  async()=> {
      if (this.player.recordedData && this.player.recordedData.length > 0) {
        // if user stop from browser stream is false
        if(this.player.record()?.stream?.active==false)this.isCompleted=true;
        let blob = this.player.recordedData[this.player.recordedData.length-1]
        await this.updateBlobToServer(blob);
      }
  });
  }
  // use ngOnDestroy to detach event handlers and remove the player
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
      this.player = false;
    }
    document.removeEventListener('window:beforeunload', this.unloadHandler);
  }
   async updateBlobToServer(binaryData){
    if(this.segmentNumber===1){
      this.inputJson["file_name"] = binaryData.name.lastIndexOf(".") != -1 ? binaryData.name.substring(0, binaryData.name.lastIndexOf(".")) : binaryData.name;
      this.inputJson["extension"] = 'mp4'//binaryData.name.split(".").pop();
      this.inputJson["type"] = "LEAD";
      this.inputJson['file_type']='video/mp4' //binaryData.type;
      
    }
      this.inputJson['part_number'] = this.segmentNumber;
  
    
      //requesting server for upload url
      let uploadResponse:any = await this.httpTransfer.largefileUploadForOrg(undefined,this.inputJson).toPromise();
      uploadResponse = uploadResponse.result.response; 
      //uploading chunk to aws via url received from server
      let result:any = await this.httpTransfer.fileUploadOnAWSServer(uploadResponse.image_upload_url,binaryData).toPromise();
      //creating an multipart array required to mark complete of upload
      this.uploadArray.push({
        ETag:result.header.get('etag'),
        PartNumber:this.segmentNumber
       })
      
      //       
      if(this.segmentNumber===1){
        this.uploadingId = uploadResponse.upload_id;
        this.imagePath = uploadResponse.image_path;
        this.inputJson['upload_id']=this.uploadingId;
        this.inputJson['image_path']=this.imagePath;
       }

        //informing server about  part that has been succesfully uploaded to aws
        await this.httpTransfer.updateMultipartInfo(this.uploadingId,{
          completed_upload_for_part:this.segmentNumber,
      }).toPromise();
      if(this.isCompleted){
        await this.onCompleteRecording();
      }
      this.segmentNumber++;

    
   }
   async onCompleteRecording(){
    this.inputJson['parts']=this.uploadArray;
    this.inputJson['mark_completed']=true;
    let response = await  this.httpTransfer.largefileUploadForOrg(undefined,this.inputJson).toPromise();
    response=response.result?.response;
    await this.httpTransfer.updateMultipartInfo(this.uploadingId,{
      completed_upload_for_part:this.segmentNumber,
      mark_completed:true
  }).toPromise();
  
  // this.rec
  this.videoFile  = {
    image_download_url:response.image_download_url,
    path:this.inputJson['image_path'],
    file_name:this.inputJson['file_name'],
  }
  this.recordedData.emit(this.videoFile)
  this.inputJson={};
  this.segmentNumber=1;
  this.uploadingId="";
  this.imagePath="";
}
}
