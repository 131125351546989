import { Observable } from 'rxjs';
import { HttpTransferService } from './services/httpTransfer.service';
import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { CustomStorageService } from "./services/custom-storage.service";
import { ConstantService } from '../Enums/Constant.service';

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  constructor(
    private router: Router,
    private customStorageService: CustomStorageService,
    private httpTransferService: HttpTransferService,
    private constant: ConstantService ,
  ) {
    if(this.customStorageService.getItem("keyValuesPresent") != "true"){
      this.getKeyValues();
    }
  }

  getKeyValues() {
    this.httpTransferService.getKeyValuesWithoutAuth().subscribe((res : any)=>{
        if(res.status == 200){
          let response = res.result;
          response.forEach(element => {
            this.customStorageService.setItem("keyValuesPresent","true");
            this.customStorageService.setItem(element.key,element.value);          
          });
        }
      });
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    var isauthenticate = localStorage.getItem("isAuthenticated");
    if(state.url.toString().includes("?key=public")) return true; 
    let urlArrPath = state.url.split('/');
    console.log('url in auth',state.url)
    if (urlArrPath[1] == 'login' || urlArrPath[1] == 'signup') {
      if (isauthenticate) {
        this.router.navigate(['/']);
        return false;
      }else{
        return true;
      }
    }
    // if(urlArrPath[1]=='oauth'){
    //   return true;
    // }
    if (!isauthenticate  || this.customStorageService.getItem(this.constant.getAuthType()) != "true") {
        if (!isauthenticate ) {
        this.router.navigate(['/login']);
        let redirectUrl=this.customStorageService.getItem('RedirectionLink')
        this.customStorageService.removeItem('RedirectionLink')
        if(redirectUrl!=='')this.customStorageService.setItem('RedirectionLink',state.url);
        return false;
      }else{
        return true;
      }
    }
    if (this.customStorageService.getItem('token') == null) {
      this.customStorageService.setItem('token','temp_token');
      const urlArr = state.url.split('/');
      if (urlArr[1] != 'dashboard') {
        return true;
      }
      let dashId = urlArr[2].includes("?") ? urlArr[2].slice(0, urlArr[2].indexOf("?")) : urlArr[2];
      if (dashId.length < 23) {
        return true;
      }
      return this.httpTransferService.getDashBoardInfoCorrespondingToDashId(dashId).map((res : any) => {
        if(res.result.dashboards.length>0){
          return true;
        }
      })
    } else {
      return true;
    }


  }
}
