import { Component, Input, OnInit, Output, ViewChild, EventEmitter, SimpleChange, HostListener, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { CustomStorageService } from 'app/services/custom-storage.service';
import { AttributesIcons, commonIcons } from 'Enums/attributes-icons';
declare var $: any;

@Component({
  selector: 'app-create-tag',
  templateUrl: './create-tag.component.html',
  styleUrls: ['./create-tag.component.scss']
})
export class CreateTagComponent implements OnInit {
  @ViewChild('wfTag') public wfTag: NgbPopover;
  @ViewChild('colorTag') public colorTag : NgbPopover;
  @Input() dashId:number;
  @ViewChild("userform") userform: NgForm;
  @Input() confirmationAction: boolean;
  @Input() openInSetting=false;
  @Input() isGrid=false;
  @Input() customfield:boolean=false;
  tagRecieved: boolean = true;
  tagFieldArray: any[] = [];
  inputTagName: string;
  tagId: any;
  tagNameData: any;
  colorData: string = '#2e4765';
  selectedTagToBeDeleted = '';
  @Output() updateDashboardTags = new EventEmitter<any>();
  @Output() showConfirmationModal = new EventEmitter<Boolean>();
  @Output() isTagModalUpdated = new EventEmitter<boolean>();
  @Output() createTagGrid = new EventEmitter<boolean>();
  @Output() closeManageTag = new EventEmitter<boolean>();
  @Output() openWorkFolderTags = new EventEmitter<any>()
  dashboardJsonSub: any;
  dashboardUserRole: any[] = [];
  lock: boolean = false;
  itemAttributesObj: {};
  @ViewChild('searchInput', { static: false }) searchInput!: ElementRef<HTMLInputElement>;
  colorTagsRef:any;
  currentDashInfo: any={};
  selectedThemeColor: string;
  openCreateNewTagFormFlag:boolean=false;
  workFolderTags: any[]=[];
  attributesIcons = AttributesIcons;
  commonIcons = commonIcons;
  changeTagColor: boolean=false;
  getWorkFolderData:any

  constructor(private httpTransfer:HttpTransferService,
    private commonUtils: CommonUtils,
    private modalService: NgbModal,
    private dashboardUtils: DashboardUtilsService,
    private customStorageService : CustomStorageService) {
    this.dashboardJsonSub = this.dashboardUtils.getUpdatedDashboardJson().subscribe((res : any) => {
      this.tagFieldArray = JSON.parse(JSON.stringify(res[this.dashId]?.TAG || []));
    });
   }

  async ngOnInit() {
    const theme = this.customStorageService.getItem('theme');
    this.selectedThemeColor = (theme !== "undefined" && theme !== "null" && theme !== null && theme !== undefined) ? theme : 'theme-royalBlue';    
    let dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.dashId])
    this.dashboardUserRole = dashboardInfo[this.dashId]?.DASHBOARD_INFO?.role || [];
    this.tagFieldArray = JSON.parse(JSON.stringify(dashboardInfo[this.dashId]?.TAG || []));
    console.log(this.tagFieldArray)
    this.currentDashInfo = dashboardInfo[this.dashId]?.DASHBOARD_INFO || {}
    let existingCustomFields = await this.dashboardUtils.getDashboardExistingCustomFormIfPresent(this.dashId);
    if(existingCustomFields?.length) this.itemAttributesObj = this.commonUtils.handleExistingItemAttributes(existingCustomFields)
  }

  focusOnInput() {
    setTimeout(() => {
       this.searchInput?.nativeElement.focus();
    },50);
  }


  ngOnDestroy() {
    this.dashboardJsonSub?.unsubscribe();
  }

  async getTagOfCorrespondingDash(flag) {
    if(flag){
      const dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.dashId])
      let tagCorresponding=dashboardInfo[this.dashId]?.TAG_MAP || [];
      // this.tagsCorrespondingToDash=tagCorresponding
      // let newList={}
      // this.tagFieldArray.forEach(ele=>{
      //   newList[ele._id]=ele
      // })
      // this.updateDashboardTags.emit(tagCorresponding);
    }
  }

  async showWFTag(){
    if(!["WORK_FOLDER","FOLDER"].includes(this.currentDashInfo?.type)){
      let dashboardJson = await this.dashboardUtils.getAndSyncAllDashboardData([this.currentDashInfo?.work_folder_id],false,['Tags','Info'])
      let workFolderBuckets = dashboardJson[this.currentDashInfo?.work_folder_id]?.TAG || [] 
      this.getWorkFolderData= dashboardJson[this.currentDashInfo?.work_folder_id]
      let idsInBucket = new Set(this.tagFieldArray.map(item => item._id));
      this.workFolderTags = workFolderBuckets.filter(item => !idsInBucket.has(item._id));
      this.wfTag?.open()
    }
  }


  checkUserHaveEditAccessInDashboard() {    
    return this.commonUtils.checkUserHaveEditAccessInDashboard(this.dashboardUserRole, false);
  }
  createNewTagForDashboad() {
    if(this.lock) return
    this.lock = true;
    if (!this.inputTagName || this.inputTagName?.length < 3 || this.inputTagName?.length > 100) {
      this.lock = false;
      return;
    }
    var tagData = {};
    tagData["name"] = this.commonUtils.capitalizeFirstLetter(this.inputTagName);
    tagData["color"] = this.colorData;
    this.httpTransfer.createNewTag(tagData, this.dashId).subscribe(
      (res : any) => {
        if (res.status == 200) {
          this.isTagModalUpdated.emit(true);
          this.inputTagName = '';
          this.getTagOfCorrespondingDash(true);
          this.openCreateNewTagFormFlag = false;
        } 
        this.lock=false
      },
      (error) => {
        this.lock=false;
      }
    );

  }
  editTag(tag) {
    tag["isEditSelected"] = true;
    setTimeout(()=>{$(".editTagInput")?.focus()},10)
    tag["oldTagName"] = tag?.name
  }

  updatingTag(tag: any) { 
    console.log(tag)
    tag.isEditSelected = false  
    if(tag.name.length >= 3 && tag.name.length <= 100 && tag?.name!=tag?.oldTagName){
    this.httpTransfer.updatingTag({color:tag.color,name:tag.name}, this.dashId, tag.tag_id).subscribe((res) => {
        if (res.status == 200) {
          this.isTagModalUpdated.emit(true);
          this.getTagOfCorrespondingDash(true);
        }
      });
    }else{
      tag.name=tag?.oldTagName
    }
    delete tag['oldTagName']
  }

  updateTagWfToDash(wftag){
    this.httpTransfer.createNewTag({work_folder_tag_id:wftag?._id}, this.dashId).subscribe((res : any) => {
      if(res !== undefined && res) {
        this.wfTag?.close()
        this.getTagOfCorrespondingDash(true);
      }
    })
  }

  onCloseModal() {
    this.userform.resetForm();
  }
  showDeleteModal(content,tagId){

    this.selectedTagToBeDeleted = '';
    this.selectedTagToBeDeleted = tagId;
    this.modalService.open(content, { size: 'sm' });
    //this.showConfirmationModal.emit(true)
    // this.deleteTag(tagId);
  }
  hideDeleteModal(){
    this.showConfirmationModal.emit(false);
  }


  ngOnChanges(changes:SimpleChange) {    
    if(changes['confirmationAction']?.currentValue)
    this.deleteTag(this.selectedTagToBeDeleted);
  }

  deleteTag(tagId?) {
    this.httpTransfer
      .deletingTag(this.dashId,this.selectedTagToBeDeleted)
      .subscribe((res : any) => {
        if (res.status == 200) {
          this.isTagModalUpdated.emit(true);
          this.hideDeleteModal()
          this.getTagOfCorrespondingDash(true);
        }
      });
  }
  onCloseCreateTags() {
    this.closeManageTag.emit(false);
  }

  changeColor(event,item){
    this.colorTag?.close()
    if(item.color !== event){
      this.changeTagColor = true
      item.color = event
      this.httpTransfer.updatingTag({color:event}, this.dashId, item.tag_id).subscribe((res) => {
        if (res.status == 200) {
          this.isTagModalUpdated.emit(true);
          this.getTagOfCorrespondingDash(true);
        }
      });
    }
    }



}
