import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { CommonUtils } from "../../../services/CommonUtils.service";
import { DashboardUtilsService } from "../../../services/dashboard-utils.service";
import { HttpTransferService } from "../../../services/httpTransfer.service";


@Component({
  selector: "app-lead-colloborators",
  templateUrl: "./lead-colloborators.component.html",
  styleUrls: ["./lead-colloborators.component.scss"],
})
export class LeadColloboratorsComponent implements OnInit {
  @Input() dashId: any;
  @Input() leadObj: any;
  @Input() collaboratorArr: any[] = [];
  @Input() itemQueryType:string;
  @Input() isItemCopy : boolean = false;
  @Output() UpdateCount = new EventEmitter<any>();
  @Output() selectedCollaborator: EventEmitter<any> = new EventEmitter();
  searchStatus: boolean = false;
  customCall:boolean = true;
  searchText: string;
  searchDashUsers: any[];
  leadColloborators: any[] = [];
  searchListUsers: any[] = [];
  removeFromSelected: any
  OrgUsers = {};
  isReadOnly: boolean = false;
  users: any[] = [];
  dashboardJsonSub: any;

  constructor(
    private httpTransfer: HttpTransferService,
    private commonUtils: CommonUtils,
    private dashboardUtils: DashboardUtilsService
  ) {
    this.dashboardJsonSub = this.dashboardUtils.getUpdatedDashboardJson().subscribe((res : any) => {
      this.users = res[this.dashId]?.DASHBOARD_USER || [];
    });
  }

  async ngOnInit() {
  this.OrgUsers = await this.dashboardUtils.getOrgUsers();
  this.commonUtils.isDashboardReadOnly(this.dashId).then((res : any) => { 
    this.isReadOnly = res;
  });
    setTimeout(() => {
      document.getElementById("colloboratorsSearchInput")?.focus();
    }, 300);

    if(this.leadObj)this.getUser();
  }

  async getUser(){
    let orgUsersArr = [];
    orgUsersArr = Object.values(this.OrgUsers) || [];
    orgUsersArr = orgUsersArr.filter(user => !user.is_bot);
    
    let obj={}
    if(this.leadObj["lead_colloborators_users"] && this.leadObj["lead_colloborators_users"].length>0){
      let temparr=[]
      this.collaboratorArr=[]
      let externalCollaborators = this.leadObj.external_collaborator_user_ids || [];
      let externalCollaboratorIdEmailMap = new Map();
      for(let colloborators of externalCollaborators){
          externalCollaboratorIdEmailMap.set(colloborators.user_id,colloborators.email);
      }
      let occuredUserId = [];
      for(let m=0;m<this.leadObj["lead_colloborators_users"].length;m++){
         for(let i=0;i<orgUsersArr.length;i++){
          if(this.leadObj["lead_colloborators_users"][m] == orgUsersArr[i]._id){
            orgUsersArr[i].isDashUser = false;
            temparr.push(orgUsersArr[i])
            occuredUserId.push(orgUsersArr[i]);
          }
         }
        if(externalCollaboratorIdEmailMap.has(this.leadObj["lead_colloborators_users"][m]) && !occuredUserId.includes(this.leadObj["lead_colloborators_users"][m])){
          temparr.push({
            name:`External User (${externalCollaboratorIdEmailMap.get(this.leadObj["lead_colloborators_users"][m])})`,
             email:externalCollaboratorIdEmailMap.get(this.leadObj["lead_colloborators_users"][m]),
            _id:this.leadObj["lead_colloborators_users"][m],
            isDashUser:false,
            is_external_user:true
          })
        }
      }
      this.addkeyForDash(temparr)
    }
  }
  addkeyForDash(temparr){
   for(let j=0;j<temparr.length;j++){
    for(let k=0;k<this.users.length;k++){
      if(temparr[j]._id == this.users[k]._id){
        temparr[j].isDashUser = true;
      }
    }
   }
   this.collaboratorArr = temparr
   
  }
  
  onSearchUsers() {
    this.searchStatus = true;
    this.getUsersToColloborate();
  }
  getUsersToColloborate() {
    this.collaboratorArr.forEach((user) => {
      this.searchListUsers.forEach((item) => {
        if (user._id == item._id) {
          this.searchListUsers.splice(
            this.searchListUsers.findIndex((x) => x._id == item._id),
            1
          );
        }
      });
    });
  }
  filterUser(val) {
    if (this.searchText == "") {
      this.searchStatus = false;
    } else {
      this.searchStatus = true;
      this.searchDashUsers = this.searchListUsers.filter(
        (tag) => tag.name.toLowerCase().indexOf(val.toLowerCase()) !== -1 || !val.toLowerCase()
      );
    }
  }


  selectedUserList(user){
    if(user._id.includes('@')){
      user = {
        name:`External user(${user._id})`,
        email:user._id,
        isDashUser:false,
        is_external_user:true,
        _id:user._id
      }
    }
    this.collaboratorArr.push(user);
    if(this.leadObj?._id && this.itemQueryType!='TEMPLATE' && !this.isItemCopy){
      this.updateCollobrators();
    }
    else{
      let collaborator= this.collaboratorArr.map(user => user._id);
      this.selectedCollaborator.emit(collaborator)
    }
  }

  updateCollobrators() {
    var leadObj = {};
    leadObj["lead_colloborators_users"] = this.collaboratorArr.map(user => user._id);
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(leadObj, this.dashId, this.leadObj._id)
      .subscribe((res : any) => {
        if (res.status === 200) {   
          if(this.leadObj && this.leadObj?._id){this.leadObj['lead_colloborators_users']=leadObj["lead_colloborators_users"]}      
        }
      });
  }

  removeFromSelectedList(item){
    this.removeFromSelected = item; 
    for(let x=0;x<this.collaboratorArr.length;x++){
      if(this.collaboratorArr[x]._id == item._id){
       this.collaboratorArr.splice(x,1)
      }
    }
    if(this.leadObj?._id && this.itemQueryType!='TEMPLATE')
    {
      this.updateCollobrators();
    }
    else{
      for(let x=0;x<this.leadObj.lead_colloborators_users.length;x++){
        if(this.leadObj.lead_colloborators_users[x] == item._id){
          this.leadObj.lead_colloborators_users.splice(x,1)
        }
      }
    }

  }

  ngOnDestroy() {
    this.dashboardJsonSub?.unsubscribe();
  }
  

}
