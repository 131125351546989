<div class="qlc-options-container">
      <ng-container *ngIf="!viewType">
            <ng-container *ngTemplateOutlet="optionFieldsPanel"></ng-container>
      </ng-container>
      <ng-container *ngIf="['ITEMS','NOTES','USERS','ACTIVITY','BoardView']?.includes(viewType)  || viewType?.includes('File')">
            <ng-container *ngTemplateOutlet="dataSelectionPanel"></ng-container>
      </ng-container>
      <ng-container *ngIf="['embedYoutube','embedGoogleDrive','embedGoogleDocs','embedGoogleSheet','embedGoogleSlides','embedFigma','embedOtherWebside']?.includes(viewType)">
            <ng-container *ngTemplateOutlet="urlInputpanel"></ng-container>
      </ng-container>
</div>
<ng-template #optionFieldsPanel>
      <div class="options-wrapper">
            <div  class="options-fields-box">
                  <div class="options-header f-s-m">{{'Suggestions'}}</div>
                  <div  class="options-fields-box-wrapper f-m-r">
                        <div  class="options-fields optionDetailBox" (click)="showBoardSelection=true;onSelect('ITEMS')">
                              <app-dashboard-icon [size]="20"  [name]="'taskActivity'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Items</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onSelect('ACTIVITY')">
                              <app-dashboard-icon [size]="20"  [name]="'taskActivity'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Activity</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onSelect('NOTES')">
                              <app-dashboard-icon [size]="20"  [name]="'Notes'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Notes</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onSelect('USERS')">
                              <app-dashboard-icon [size]="20"  [name]="'SingleUser'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">User</span>
                        </div>
                  </div>
            </div>
            <div  class="options-fields-box">
                  <div class="options-header f-s-m">{{'Media Files'}}</div>
                  <div  class="options-fields-box-wrapper f-m-r">  
                        <div  class="options-fields optionDetailBox" (click)="uploadImageFile?.click()">
                              <input [hidden]="true"   (change)="onItemSelect('mediaUploading',{files:$event?.target?.files})" type="file" [accept]="'image/*'"  #uploadImageFile> 
                              <app-dashboard-icon [size]="20"  [name]="'SingleImage'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Image</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="uploadVideoFile?.click()">
                              <input [hidden]="true"   (change)="onItemSelect('mediaUploading',{files:$event?.target?.files})" type="file" [accept]="'video/*'"  #uploadVideoFile> 
                              <app-dashboard-icon [size]="20"  [name]="'SingleVideo'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Video</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="uploadFile?.click()">
                              <input [hidden]="true"   (change)="onItemSelect('mediaUploading',{files:$event?.target?.files})" type="file" accept="application/pdf, .xls,.xlsx,.doc,.docx ,application/zip,.rar,.7zip,application/vnd.oasis.opendocument.text"  #uploadFile> 
                              <app-dashboard-icon [size]="20"  [name]="'SingleFile'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Files</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="uploadAudioFile?.click()">
                              <input [hidden]="true"   (change)="onItemSelect('mediaUploading',{files:$event?.target?.files})" type="file" [accept]="'audio/*'"  #uploadAudioFile> 
                              <app-dashboard-icon [size]="20"  [name]="'SingleAudio'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Audio</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="showBoardSelection=true;onSelect('File')">
                              <app-dashboard-icon [size]="20"  [name]="'SingleFile'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Drive Import</span>
                        </div>
                  </div>
            </div>
            <div  class="options-fields-box">
                  <div class="options-header f-s-m">{{'Views'}}</div>
                  <div  class="options-fields-box-wrapper f-m-r">
                        <div  class="options-fields optionDetailBox" (click)="showBoardSelection=true;onSelect('BoardView')">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Board View</span>
                        </div>
                  </div>
            </div>
            <div  class="options-fields-box">
                  <div class="options-header f-s-m">{{'Embed'}}</div>
                  <div  class="options-fields-box-wrapper f-m-r">
                        <ng-container *ngFor="let embed of embedOption">
                              <div  class="options-fields optionDetailBox" (click)="viewType=embed?.type;focusOnEmbedUrlInput();">
                                    <app-dashboard-icon [size]="20"  [name]="'SingleVideo'"></app-dashboard-icon>
                                    <span class="text-overfow-hidden">{{embed?.name}}</span>
                              </div>
                        </ng-container>
                  </div>
            </div>
            <div  class="options-fields-box">
                  <div class="options-header f-s-m">{{'Colors Embed'}}</div>
                  <div  class="options-fields-box-wrapper f-m-r">
                        <div  class="options-fields optionDetailBox" [class.active-option]="colorBgPanelRef?.isOpen()" [ngbPopover]="colorPanel" #colorBgPanelRef="ngbPopover" [placement]="['top-left','top','bottom-left','bottom','auto']"  [autoClose]="'outside'">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Background Color</span>
                        </div>
                        <ng-template #colorPanel>
                              <div style="width: 190px">
                               <color-picker (colorChange)="onItemSelect('Format',{formatType:'BackgroundColor',formatDetails:[{formatKey:'background',formatValue:$event}]})"></color-picker>
                              </div>
                          </ng-template>
                        <div  class="options-fields optionDetailBox" [class.active-option]="colorTextPanelRef?.isOpen()" #colorTextPanelRef="ngbPopover" [ngbPopover]="textColorPanel"[placement]="['top-right','top','bottom-right','bottom','auto']"   [autoClose]="'outside'">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Text Color</span>
                        </div>
                        <ng-template #textColorPanel>
                              <div style="width: 190px">
                               <color-picker (colorChange)="onItemSelect('Format',{formatType:'TextColor',formatDetails:[{formatKey:'color',formatValue:$event}]})"></color-picker>
                              </div>
                          </ng-template>
                          <div  class="options-fields optionDetailBox" [class.active-option]="colorBadgePanelRef?.isOpen()" #colorBadgePanelRef="ngbPopover"  [ngbPopover]="badgeColorPanel" [placement]="['top-left','top','bottom-left','bottom','auto']"   [autoClose]="'outside'">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Badge Color</span>
                        </div>
                        <ng-template #badgeColorPanel>
                              <div style="width: 190px">
                               <color-picker (colorChange)="onItemSelect('Format',{formatType:'TextColor',formatDetails:[{formatKey:'background',formatValue:$event},{formatKey:'customClass',formatValue:'badge'}]})"></color-picker>
                              </div>
                          </ng-template>
                  </div>
            </div>
            <div  class="options-fields-box">
                  <div class="options-header f-s-m">{{'Text'}}</div>
                  <div  class="options-fields-box-wrapper f-m-r">
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',removeFormat:true})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Simple Text</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'header',formatValue:1}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Header1</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'header',formatValue:2}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Header2</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'header',formatValue:3}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Header3</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'header',formatValue:4}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Header4</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'size',formatValue:'small'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Size Small</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'size',formatValue:'large'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Size Large</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'size',formatValue:'huge'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Size Xlarge</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'bold',formatValue:true}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Bold</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'italic',formatValue:true}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Italic</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'underline',formatValue:true}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Underline</span>
                        </div>
                  </div>
            </div>
            <div  class="options-fields-box">
                  <div class="options-header f-s-m">{{'List Embed'}}</div>
                  <div  class="options-fields-box-wrapper f-m-r">
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'List',formatDetails:[{formatKey:'list',formatValue:'unchecked'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Checklist </span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'List',formatDetails:[{formatKey:'list',formatValue:'bullet'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Bulleted</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'List',formatDetails:[{formatKey:'list',formatValue:'ordered'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Numbered </span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'List',formatDetails:[{formatKey:'list',formatValue:'loweralpha'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Lower Alphabet</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'List',formatDetails:[{formatKey:'list',formatValue:'upperalpha'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Upper Alphabet</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'List',formatDetails:[{formatKey:'list',formatValue:'uppergrrek'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Greek List</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'List',formatDetails:[{formatKey:'list',formatValue:'lowerroman'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Lower Roman</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'List',formatDetails:[{formatKey:'list',formatValue:'upperroman'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span class="text-overfow-hidden">Upper Roman</span>
                        </div>
                  </div>
            </div> 
            <div  class="options-fields-box">
                  <div class="options-header f-s-m">{{'Formattings'}}</div>
                  <div  class="options-fields-box-wrapper f-m-r">
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'strike',formatValue:true}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Strikethrought</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'code',formatValue:true}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Inline Code</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'code-block',formatValue:true}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Code Block</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'script',formatValue:'sub'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Sub Text</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'script',formatValue:'super'}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Supper Text</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Format',{formatType:'Text',formatDetails:[{formatKey:'blockquote',formatValue:true}]})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Quote</span>
                        </div>
                        <div  class="options-fields optionDetailBox" (click)="onItemSelect('Table',{rowCount:3,columnCount:3})">
                              <app-dashboard-icon class="flex-align" [size]="16" [name]="'Grid'"></app-dashboard-icon>
                              <span>Table</span>
                        </div>
                  </div>
            </div>
      </div>
</ng-template>
<ng-template #dataSelectionPanel>
      <div class="search-field mb-1">
            <div class="d-flex align-items-center mb-1" *ngIf="showBoardSelection && !selectedBoardId" >
                  <app-dashboard-icon class="optionDetailBox iconBox-24" [size]="15"  [name]="'backArrow'" (click)="onSelect(null)"></app-dashboard-icon>
                  <span class="ml-1">Select Board</span>
            </div>
            <div class="search-input">
                  <input #searchInput style="border:none; width:90%" class="" [placeholder]="'placeholders.search' | translate"  [(ngModel)]="searchText" (input)="getData()">
                  <span class="m-1" style="width:10%"><i class="fa fa-search"></i></span>   
            </div>
            <div class="d-flex justify-content-end selction-pannel" *ngIf="selectedBoardId && showBoardSelection" >
                  <span class="reselect-board" (click)="selectedBoardId=null;onSelect(this.viewType)">{{'Reselect Board'}}</span>
            </div>
      </div>
      <div class="options-fields-box data-list"  infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="getData(true)" [scrollWindow]="false">
            <ng-container *ngIf="showBoardSelection && !selectedBoardId">
                  <ng-container  *ngFor="let data of dataList">
                        <div class="optionDetailBox" (click)="selectedBoardId=data?.dashboard_id;onSelect(this.viewType)">
                              <span class="text-overfow-hidden">{{data?.dashboard_name}}</span>
                        </div>
                  </ng-container>
            </ng-container>
            <ng-container *ngIf="showBoardSelection && selectedBoardId || !showBoardSelection">
                  <ng-container *ngIf="viewType=='ITEMS'">
                        <ng-container  *ngFor="let data of dataList">
                              <div class="optionDetailBox" (click)="onItemSelect('item',{id:data?._id,boardId:data?.dashboard_id})">
                                    <span class="text-overfow-hidden">
                                         {{'#'+data.seq_id+' '+data?.title}} 
                                    </span>
                              </div>
                        </ng-container>
                  </ng-container>
                  <ng-container *ngIf="viewType=='NOTES'">
                        <ng-container  *ngFor="let data of dataList">
                              <div class="optionDetailBox" (click)="onItemSelect('note',{id:data?._id})">
                                    <app-dashboard-icon [size]="15"  [name]="'Notes'"></app-dashboard-icon>
                                    <span class="text-overfow-hidden">{{data?.title}} </span>
                              </div>
                        </ng-container>
                  </ng-container>
                  <ng-container *ngIf="viewType=='ACTIVITY'">
                        <ng-container  *ngFor="let data of dataList">
                              <div class="optionDetailBox" (click)="onItemSelect('activity',{id:data?._id,boardId:data?.dashboard_id})">
                                    <app-dashboard-icon [size]="15"  [name]="'activity'"></app-dashboard-icon>
                                    <span class="text-overfow-hidden">{{data?.title}} </span>
                              </div>
                        </ng-container>
                  </ng-container>
                  <ng-container *ngIf="viewType=='USERS'">
                        <ng-container  *ngFor="let data of dataList">
                              <div class="optionDetailBox" (click)="onItemSelect('user',{id:data?._id})">
                                    <ngx-avatars [name]="data?.name" [src]="data?.image_url" size="20" initialsSize="2" sizeRatio="2.2" ></ngx-avatars>
                                    <span>
                                          <p  class="text-overfow-hidden m-0 p-0">{{data?.name}}</p>
                                          <p  class="text-overfow-hidden m-0 p-0" class="my-0 f-xs-l">{{data?.email}}</p>
                                    </span>
                              </div>
                        </ng-container>
                  </ng-container>
                  <ng-container *ngIf="viewType?.includes('File')">
                        <ng-container  *ngFor="let data of dataList">
                              <div class="optionDetailBox" (click)="onItemSelect('media',{id:data._id,file_type:data?.file_type,resizeable:true})">
                                    <app-dashboard-icon *ngIf="data?.file_type=='IMAGE'" [size]="12"  [name]="'SingleImage'"></app-dashboard-icon>
                                    <app-dashboard-icon *ngIf="data?.file_type=='VIDEO'" [size]="12"  [name]="'SingleVideo'"></app-dashboard-icon>
                                    <app-dashboard-icon *ngIf="data?.file_type=='AUDIO'" [size]="12"  [name]="'SingleAudio'"></app-dashboard-icon>
                                    <app-dashboard-icon *ngIf="data?.file_type!=='IMAGE' && data?.file_type!=='VIDEO' && data?.file_type!=='AUDIO'"   [size]="12" [name]="'SingleFile'"></app-dashboard-icon>
                                    <span class="text-overfow-hidden">{{data?.name}}</span>
                              </div>
                        </ng-container>
                  </ng-container>
                  <ng-container *ngIf="['BoardView']?.includes(viewType)">
                        <ng-container  *ngFor="let data of dataList">
                              <div class="optionDetailBox" (click)="onItemSelect('boardView',{id:data?._id,view_id:data?._id,board_id:selectedBoardId,resizeable:true})">
                                    <app-dashboard-icon  [size]="12"  [name]="data?.view_type?.charAt(0)?.toUpperCase() + data?.view_type?.slice(1).toLowerCase()"></app-dashboard-icon>
                                    <span class="text-overfow-hidden">{{data?.name}}</span>
                              </div>
                        </ng-container>
                  </ng-container>
            </ng-container>
            <ng-container *ngIf="dataList?.length==0 && !pageInfo?.isLoading">
                  <div class="d-flex justify-content-center align-items-center h-100 w-100">
                        {{'editcustom.noRecordFound' | translate}}
                  </div>
            </ng-container>
            <ng-container *ngIf="pageInfo.isScrollLoading">
                  <div class="d-flex justify-content-center align-items-center p-2">
                        <app-shimmer-loader [type]="'DefaultSpinner'" [cardCount]="15" [count]="1" [width]="'95%'" [color]="commonUtils.getCurrentThemeColor()"></app-shimmer-loader>   
                  </div>
            </ng-container>
            <ng-container *ngIf="pageInfo?.isLoading">
                  <app-shimmer-loader [type]="'CardField'" [rowGap]="0" [cardCount]="10" [count]="1" [width]="'95%'" [height]="36" [radius]="5" [header]="false" [columnGap]="0"></app-shimmer-loader>  
            </ng-container>
      </div>
</ng-template>
<ng-template #urlInputpanel>
      <div class="url-input-div ">
            <span class="mr-1 text-overfow-hidden">
                  <ng-container *ngFor="let embed of embedOption">
                        <ng-container *ngIf="viewType==embed.type">{{embed?.name}}</ng-container>
                  </ng-container>
            </span>
            <input #embedUrlInput class="border-0 w-100" style="flex: 1;" [placeholder]="'placeholders.enterYourLinkHere' | translate"  [(ngModel)]="embedUrl" #urlInputValue="ngModel" >
      </div>
      <span *ngIf="!isValidUrl(embedUrl) && ((embedUrl || urlInputValue?.invalid) && (urlInputValue?.dirty || urlInputValue?.touched))" class="text-danger">{{'form.enterCorrectURL' | translate}}</span>
      <div>
            <div class="url-buttons-wrapper">
                  <span class="button-fields commonBtn-30" (click)="viewType=null;embedUrl=null">
                        <span>Cancel</span>
                  </span>
                  <button  [ngStyle]="{'opacity':isValidUrl(embedUrl) ? '1' : '0.5'}" class="border-0 commonBtn-30 themeChild" (click)="isValidUrl(embedUrl) && onItemSelect('embed',{id:data?._id,url:embedUrl,embed_type:viewType,resizeable:true})">
                        <span>Save</span>
                  </button>
            </div> 
      </div>
</ng-template>
