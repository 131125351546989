import { MqttmessagingService } from '../../../../services/mqttmessaging.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { NgbCalendar, NgbModal, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DomSanitizer,SafeResourceUrl } from "@angular/platform-browser"; 
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { AttributesIcons } from '../../../../../Enums/attributes-icons';
import { ConstantService } from '../../../../../Enums/Constant.service';
import { MediaIcons } from '../../../../../Enums/media-icons';
import { MediaSmIcons } from '../../../../../Enums/media-sm-icons';
import { ScreenRecordingComponent } from '../../../shared-components/screen-recording/screen-recording.component';
import { AudioRecordingComponent } from '../../../shared-components/audio-recording/audio-recording.component';
import { CommonUtils } from '../../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../../services/httpTransfer.service';
import { ToasterService } from '../../../../services/toaster.service'; 
import { CustomStorageService } from '../../../../services/custom-storage.service'; 
import { Subscription } from 'rxjs';
import { BasicUtils } from 'app/services/basicUtils.service';
import { NgModel } from '@angular/forms';

declare var $: any;

@Component({
  selector: "app-edit-custom",
  changeDetection:ChangeDetectionStrategy.Default,
  templateUrl: "./editCustom.component.html",
  styleUrls: ["./editCustom.component.css"],
})
export class EditCustomComponent implements OnInit{
  docviewer:string="office"
  currentIndex: any = -1;
  showFlagForVideo: any = false;
  showLoader: Boolean = false;
  customFieldKeysArray = [];
  priority: any;
  userSelect: any;
  isFollowed: Boolean = false;
  customFieldDataOfLead = [];
  arrayOfCustomFiled = [];
  contactTypeArrayCust=[];
  selectedContactProfile:any
  selectedCustId: any;
  selectedPermissibleValue: any;
  radioInput: Boolean = false;
  checkInput: Boolean = false;
  @Input() addTitile: boolean;
  @Input() activityBoardId: any=null;
  @Input() dashId: any;
  @Input() leadId: any;
  @Input() is_deleted:boolean = false
  @Input() createCopyFlag: boolean;
  @Input() editTitle: any
  @Input() previewCustomField: any[];
  @Input() isPreview: boolean = false;
  @Input() triggeredFromCreateACopy: boolean;
  @Input() bucketId: any;
  @Input() height: any;
  @Input() tabIndex: number = 1;
  @Input() heightinpx: any;
  @Input() dashboardUserRole: any[]=[];
  @Input() tagsCorrespondingToDash: any;
  @Input() bucketArray: any;
  @Input() recordingStarted: boolean = false;
  @Input() isTemplateCall:boolean=false;
  @Input() leadObj : any;
  @Input() itemQueryType : string;
  @Input() showEmptyField : boolean=true;
  @Input()publicLeadCustomFieldData:any=undefined;
  @Input()isPublicLead:boolean=false;
  @Input() showHiddenFields: any={};
  @Input() copyToOtherBoard:boolean = false;
  @Input() dashBoardSprintArr:any=[]

  @Input() viewOnly:boolean=false;
  @Input() previewCall:boolean=false;
  @Input() addItem:boolean=false;
  @Input() createLeadCall:boolean=false
  // @Input() isItemFromHiddenOrArchiveSprint: any;
  // @Input() itemSprintId: any;
  @Output() onEdit = new EventEmitter<any>();
  @Output() onUpdate = new EventEmitter<any>();
  @Output() onNonCustomEdit = new EventEmitter<any>();
  @Output() isLeadCreated = new EventEmitter<boolean>();
  @Output() onItemOpen = new EventEmitter<any>();
  @ViewChild(ScreenRecordingComponent) screenRecordingComponent: ScreenRecordingComponent;
  @ViewChild(AudioRecordingComponent) audioRecordingComponent: AudioRecordingComponent;
  @ViewChild('mediaPermissionModal') mediaPermissionModal: TemplateRef<any>;
  @ViewChild('numberdfield') numberdfield:NgModel;
  dateField: any;
  textField: any;
  numberField: any;
  textAreaField: any;
  imageField: any;
  radioField: any;
  checkField: any;
  dropDownField: any;
  naValue: any;
  today: any;
  customFieldType: any;
  imageFileNameList = [];
  selectedFilesNameList = [];
  commentImageUrl: any;
  fileType: string;
  imageConstant: any;
  imageType: any;
  fileUrlResponse: any;
  fileUrlPath: any;
  imageFileList = [];
  fileCounts = 0;
  multipleImageList: any;
  updateCustomType: any;
  responseFilepath: any[];
  customFieldObj: {} = {};
  customFieldsArray: any[] = [];
  selectedKey: any;
  pathArrayStatus: boolean = false;
  summerNoteconfig: {
    placeholder: string;
    tabsize: number;
    height: string;
    uploadImagePath: string;
    toolbar: (string | string[])[][];
    fontNames: string[];
  };
  datePickerConfig = {
    format: "DD-MM-YYYY",
    locale: moment.locale(),
    closeOnSelect: false,
  };
  selectedSummernoteIndex: any;
  dropFileType: { Image: string; Video: string; File: string ;Audio:string };
  videoType: string;
  audioType :string
  @Output() updateLeadTitle = new EventEmitter<any>();
  labelForTextArea: any;
  documentUrl: any;
  documentFileName: any;
  videoUrl: any;
  showStatus: boolean = false;
  videoObject: any;
  nextButtonDisable: boolean = false;
  previousButtonDisable: boolean = false;
  modalRef2: BsModalRef;
  modalRefDelete: BsModalRef;
  @ViewChild("videoPlayerModal", { read: TemplateRef })
  videoPlayerModal: TemplateRef<any>;
  @ViewChild("imageEditorModal") imageEditorRef: TemplateRef<any>;
  @ViewChild("myVideo") myVideo: ElementRef;
  connectItem = false;
  typeForModalOpen: any;
  imageObject: any;
  documentObject: any;
  toBeDeleteIndex: any;
  toBeDeleteKey: any;
  toBeDeleteItem: any;
  keyCode: any;
  isModalOpen: boolean;
  @Output() onTitleChange = new EventEmitter<any>();
  @Output() showConnectedBoardFilter = new EventEmitter<any>();
  roles: { ADMIN: string; SALES: string; ACCESS: string; SUPER_ADMIN: string; DASHBOARDADMIN: string; };
  openScreenRecorderPlayer: boolean = false;
  openAudioRecorderPlayer: boolean = false;
  mqttSubscription: Subscription;
  locationConstant: string;
  ipConstant: string;
  mediaFileUploadSub: Subscription;
  selectedFileType: any;
  selectedKeyLabel: any;
  usersOfDash: any;
  isSearchItem = false;
  searchInput: any;
  currentLead: any;
  isSubTaskSearchOpen = false;
  isDependancySearchOpen = false;
  isBoardSearchOpen = false;
  selectedPeople: any[] = [];
  isEditUrl = false;
  isEdit: boolean = false;
  isReadOnly: boolean;
  attributesIcons = AttributesIcons;
  mediaIcons = MediaIcons;
  isImgExpanded = false;
  isVideoExpanded = false;
  isFileExpanded = false;
  mirrorColumnsJson = {};
  mirrorColumnsSub: Subscription;
  orgUsersJson: any;
  priorityJson: {};
  currencyArr: any[] = [];
  users: any[] = [];
  boardTypeMirrorItems: any[];
  boardTypeMirrorItemsJson: any = {};
  connectedItemsIdsArr: any[] = [];
  userList: any[]=[];
  dashboardUser: any[]=[];
  updatedKey: any;
  updatedCustomFields:any=[]
  modalRef: any;
  mediaArr: any[]=[];
  indexForPreview: any;
  MediaSmIcons = MediaSmIcons;
  createQuickItem: any;
  connectedBoardId: any;
  connectedBoardItem: any;
  connectedBoardQuickAdd:boolean=true
  currentDragKey: any;
  currencyJson: {};
  isRecordingStopped: boolean = false;
  recordingName: string = null;
  dashAccess: { is_readOnly_user: boolean; no_dashboard_user: boolean; is_collabrator_user: any; is_deleted_lead: any; dashboard_type: any; is_template_board: boolean; is_internal_board: boolean; };
  editImageFiles: any;
  itemAttributesObj: {};
  countryJson:any[]=[];
  timeManual:boolean=false;
  timeTrackerPause:boolean=false;
  audioFlag: any;
  timeTrackerList:any[]=[];
  timer:any;
  userId:string;
  // trackerWithoutEndtime:any[]=[]
  selectedTimeTracker:any;
  selectedUserForTimeTracker:string;
  startTime:any;
  endTime:any;
  multiUrlInput:any[]=[];
  enable:boolean=false;
  activeTrackerList:any[]=[]
  connectedBoardInfo:any = {}
  activeTrackerListBeenCalled: boolean = false;
  enableNewButton:boolean=true;
  timerInterval=null;
  recordingTimeForVideo:string = '00:00:00';
  url: any = '';
  urlName: any = '';
  selectedMediaFieldJson:any
  dashboardInfo: any={};
  @Input() itemConfig: any={};
  linkToOpenIframe:any;
  openFrame:false;
  showMinimumLengthE:boolean=false
  itemData: any;
  
  
  constructor(
    private httpTransfer: HttpTransferService,
    public commonUtils: CommonUtils,
    private constant: ConstantService,
    private calendar: NgbCalendar,
    private sanitizer: DomSanitizer,
    private toastService: ToasterService,
    private modalService: BsModalService,
    private modal:NgbModal,
    private dashboardUtils: DashboardUtilsService,
    public constantService: ConstantService,
    public datepipe: DatePipe,
    public MqttmessagingService: MqttmessagingService,
    private cdr: ChangeDetectorRef,
    private customStorageService:CustomStorageService,
    private basicUtils:BasicUtils,
  ) { 
    this.today = this.calendar.getToday();
    this.dateField = this.constant.getFieldTypeDate();
    this.textField = this.constant.getFieldTypeText();
    this.numberField = this.constant.getFieldTypeNumber();
    this.textAreaField = this.constant.getFieldTypTextArea();
    this.imageField = this.constant.getFieldTypeImage();
    this.radioField = this.constant.getFieldTypeRadio();
    this.checkField = this.constant.getFieldTypeCheck();
    this.dropDownField = this.constant.getFieldTypeDropDown();
    this.naValue = this.constant.getNAValue();
    this.customFieldType = this.constant.getCustomTypes();
    this.updateCustomType = this.constant.getUpdateCustomType();
    this.summerNoteconfig = this.constant.getSummerNoteConfig();
    this.dropFileType = this.constant.getDropFileType();
    this.imageConstant = this.constant.getImageConstant().toLowerCase();
    this.videoType = this.constant.getVideoConstant().toLowerCase();
    this.audioType = this.constant.getAudioConstant().toLowerCase();
    this.keyCode = this.constant.getKeyCode();
    this.roles = this.constantService.getRole();
    this.locationConstant = this.constantService.getLocationTypeConstant();
    this.ipConstant = this.constantService.getIPConstant();
    this.priorityJson = this.commonUtils.priorityJson;
    this.dashboardUtils.getCurrencyJson().then(country=>{
      if(country){
        this.countryJson=country
      }
    })
    this.listenForMqttMessages();
  }

  activityTypeKey = this.constantService.getActivityTypeKey();
  updateActivityTYpe = this.constantService.getUpdateActivityType();
  typeKey = this.constantService.getObjectTypeKey();
  leadObjectTYpe = this.constantService.getLeadObjectType();
  

  listenForMqttMessages() { 
    this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
      if (message) {
          this.updatesFromWss(message)
      }
    });
  }

  updatesFromWss(messagees) {
    let message = JSON.parse(messagees);
    const msgDashboardId = message?.additional_attributes?.dashboard_id
    if(msgDashboardId !== this.dashId) {
      if (message[ConstantService.constant.ACTIVITYTYPEKEY] == ConstantService.constant.UPDATEACTIVITYTYPE || message[ConstantService.constant.ACTIVITYTYPEKEY] == "DELETE") {
        if (message[ConstantService.constant.OBJECTTYPEKEY] ==ConstantService.constant.LEADOBJECTTYPE) {
          this.handleExternalBoardConnectedUpdateItem(message)
          this.cdr.detectChanges();
        }
        }
      }
    if (message[this.activityTypeKey] == this.updateActivityTYpe && message['additional_attributes']['details']['_id'] == this.leadId) {
      if (message[this.typeKey] == this.leadObjectTYpe) {
        this.updatedCustomFields=message['additional_attributes']?.updated_keys?.filter(item=>item?.custom_fields)
        this.updatedCustomFields=this.updatedCustomFields.length>0 ? this.updatedCustomFields[0]?.custom_fields : []
        let lead=message['additional_attributes']['details']
        if(this.updatedCustomFields?.length>0){
          this.handleLeadUpdateTypeOperation(lead);
        }else if(message['additional_attributes']?.updated_keys && message['additional_attributes']?.updated_keys?.includes('title')){
          this.editTitle = lead.title; // update item title
        }else{
          this.leadObj=this.commonUtils?.creatingJsonStructureOfLeadForTableView(lead)
        }
        this.cdr.detectChanges();
      }
    }
    if(message[this.activityTypeKey]=='ADD' && message[ConstantService.constant.OBJECTTYPEKEY]=='TimeTracker'){
      if(!message.additional_attributes.details.end_time){
        // this.activeTrackerList.push(message.additional_attributes.details)
        this.activeTrackerList=[...this.activeTrackerList,message.additional_attributes.details]
        this.cdr.detectChanges();
      }
    }
    if(message[ConstantService.constant.ACTIVITYTYPEKEY]=='UPDATE' && message[ConstantService.constant.OBJECTTYPEKEY]=='TimeTracker'){
        this.activeTrackerList=this.activeTrackerList.filter(element => element._id!=message.additional_attributes.details._id)
        this.cdr.detectChanges();
    }
  }

  async handleExternalBoardConnectedUpdateItem(message) {
    let leadDetails = message?.additional_attributes?.details;
    this.callbackFunctionForConnectedItemJson(leadDetails)    
  }

  handleLeadUpdateTypeOperation(message) {
    let isDataAlreadyPresent = this.checkIsDataPresent(message);
    if(isDataAlreadyPresent){
      this.addDataForEditLead(message);
    }else{
      this.settingDataForEditLead(message);
    } 
  }
  
  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key === this.keyCode.RIGHT_ARROW) {
      this.moveToNextIndex();
    }

    if (event.key === this.keyCode.LEFT_ARROW) {
      this.moveToPreviousIndex();
    }
  }
  
  validateLetters(val) {
    this.searchInput = val;
    if (val === '') {
      this.cdr.detectChanges();
    }
  }
  async ngOnInit() {  
    this.userId= this.customStorageService.getItem('id')
    //  this.trackerWithoutEndTime();
    await this.getCurrencyJson();
    const dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.dashId])
    this.dashboardInfo=dashboardInfo[this.dashId]?.DASHBOARD_INFO || {}
        if(!dashboardInfo || !(dashboardInfo && dashboardInfo[this.dashId]?.CUSTOM_FORM?.length>0)){
      let res=await this.httpTransfer.getCustomFieldsCorrespondingToDashBoard(this.dashId,this.leadId).toPromise()
      this.publicLeadCustomFieldData = res?.result?.data?.custom_fields
    }
    if(!this.publicLeadCustomFieldData){
      this.dashboardUtils.getDashboardUsers([this.dashId])
      this.orgUsersJson = await this.dashboardUtils.getOrgUsers();
      this.dashboardUser =  dashboardInfo[this.dashId]?.DASHBOARD_USER || {};
      this.dashboardUserRole = dashboardInfo[this.dashId]?.DASHBOARD_INFO?.role || [];
      this.users = Object.values(this.orgUsersJson);
      this.users = this.users.filter(user => user.is_bot);
    }
    if (this.isPreview) {
      let arr = [...this.previewCustomField]
      this.arrayOfCustomFiled = this.setDefaultValuesForPreview(arr) 
    } else {
      await this.getCustomFieldsCorrespondingToDash(this.publicLeadCustomFieldData?true:false);
    }
    let existingCustomFields = await this.dashboardUtils.getDashboardExistingCustomFormIfPresent(this.dashId);
    if(existingCustomFields?.length)this.itemAttributesObj = this.commonUtils.handleExistingItemAttributes(existingCustomFields)
    this.commonUtils.isDashboardReadOnly((this.activityBoardId ? this.activityBoardId : this.dashId), this.leadObj?.lead_colloborators_users || [],this.leadObj).then((res : any) => { 
      if(!this.viewOnly)this.isReadOnly = res;     
      if(this.viewOnly){
        this.isReadOnly=true;
      }
    });
    // this.commonUtils.queryActiveTimeTrackerList(this.dashId, this.userId).then(val=>{
    //   this.activeTrackerList = val;
    // })
    if(this.copyToOtherBoard){
      let validateforCustmfield=false
      validateforCustmfield=this.validateAllFields(this.leadObj?.custom_fields)
      this.isLeadCreated.emit(validateforCustmfield)
    }
    this.cdr.detectChanges();
    if(this.addItem){
      setTimeout(()=>{
        document.getElementById('contact-input-field')?.focus()
      })
    }
  }


  async getCurrencyJson() {
    this.currencyArr = await this.dashboardUtils.getCurrencyJson();
    // this.currencyJson = {}
    // this.currencyArr.forEach(currenyJson => {
    //   this.currencyJson[currenyJson.code] = currenyJson
    // });
    this.currencyJson=await this.dashboardUtils.getStoredCurrencyJson();
    this.cdr.detectChanges();
    
    
    
  }

  setDefaultValuesForPreview(previewFields) {
    previewFields.forEach(item => {
      if (item.type == this.customFieldType.CHECKBOX) {
        if (item.permissible_values.length > 0) {
          var checkArray = [];
          for (let j = 0; j < item.permissible_values.length; ++j) {
            var checkObj = {};
            checkObj["id"] = j;
            if(typeof item.permissible_values[j] == 'object' && Object.keys(item.permissible_values[j]).includes('permissible_values')) {
              item.permissible_values[j] = item.permissible_values[j]['permissible_values'];
            }
            checkObj["value"] = item.permissible_values[j];
            for (var k = 0; k < item.permissible_values[j].length; ++k) {
              for (let z = 0; z < item.default_value.length; ++z) {
                if (!checkObj["checkinput"]) {
                  if (item.permissible_values[j] == item.default_value[z] && item?.is_default_enabled) {
                    checkObj["checkinput"] = true;
                  } else {
                    checkObj["checkinput"] = false;
                  }
                }
              }
            }
            checkArray.push(checkObj);
          }
        }
        item["permissible_values"] = checkArray;
      }
      else if (item.type == this.customFieldType.RADIO) {
        if (item.permissible_values.length > 0) {
          var radioArray = [];
          for (let j = 0; j < item.permissible_values.length; ++j) {
            var radioObj = {};
            radioObj["id"] = j;
            radioObj["value"] = item.permissible_values[j];
            for (var k = 0; k < item.permissible_values[j].length; ++k) {
              console.log("item of permisiible values",item.permissible_values)
              if (item.permissible_values[j] === item.default_value) {
                radioObj["radioinput"] = true;
              } else {
                radioObj["radioinput"] = false;
              }
            }
            radioArray.push(radioObj);
          }
        }
        item["permissible_values"] = radioArray;
      } 
      else if (item.type === this.customFieldType.DROPDOWN) {
        item["p_input"] = item.is_default_enabled ? item.default_value : '';
      }
      else if (item.type == this.customFieldType.DATE) {
        item["dateinput"] = item.is_default_enabled ?  item.default_value : '';
      }
      else if (item.type == this.customFieldType.TEXTAREA) {
        item["textareainput"] = item.is_default_enabled ? item.default_value : "";
      } 
      else if (item.type == this.customFieldType.TEXT) {
        item["textinput"] = item.is_default_enabled ? item.default_value : "";
      }
      else if (item.type == this.customFieldType.NUMBER) { 
        item["numinput"] = item.is_default_enabled ? item.default_value : '';
      }
      else if (['Email','MultipleEmail'].includes(item.type)) {
        item["emailinput"] = item.is_default_enabled ? item.default_value : '';
        item['validateToEmail']=true;
      }
      else if (['Mobile','MultipleMobile'].includes(item.type)) {
        if(item.default_value)item["mobileinput"] = item.is_default_enabled ? item.default_value : {};
        if(!item.default_value)item["mobileinput"] = {};
      }
      else if (item.type == this.customFieldType.RATING) {
        item["ratingInput"] = item.is_default_enabled ? item.default_value : '';
      }
      else if (item.type == this.customFieldType.CHECKLIST) {
        item["checklistInput"] = item?.default_value;
      }
      else if (item.type == this.customFieldType.BUTTON) {
        item["buttonInput"] = item?.default_value || '';
      }
      else if (item.type == this.customFieldType.SINGLEUSER) {
        item["singleUserInput"] = item.is_default_enabled ? item.default_value : '';
      }
      else if (item.type == this.customFieldType.MULTIUSER) {
        item["multiUserInput"] = item.is_default_enabled ? item.default_value : [];
      }
      else if (item.type == this.customFieldType.CURRENCY) {
        if(item.default_value) item["currencyObj"] = item.is_default_enabled ? item.default_value : {};
        if(!item.default_value) item["currencyObj"] = {};
      }
      else if (item.type == this.customFieldType.URL) {
        item["urlInput"] = item.is_default_enabled ? item.default_value : '';
        item["isEditUrl"] = false;
      }
      else if (item.type == this.customFieldType.PERCENTAGE) {
        item["percentInput"] = item.is_default_enabled ? item.default_value : '';
      }
      else if (item.type == this.customFieldType.MULTISELECT) {
        item["multiselectInput"] = item.is_default_enabled ? item.default_value : '';
      }
      else if (item.type == this.customFieldType.COUNTRY) {
        item["country"] = item.is_default_enabled ? item.default_value : '';
      }
      else if (['Board','MultipleBoard'].includes(item.type)) {
        item["table_view"] = item?.table_view;
      }
      else {
        item["value"] = item.is_default_enabled ? item.default_value : '';
      }
    });
    return previewFields;
  }
  makeUserList(value){
    let orgUsersJson=[]
    orgUsersJson = Object.values(this.orgUsersJson)
    if(value.length>0){
      let temparr=[]
      this.userList=[]
      for(let m=0;m<value.length;m++){
         for(let j=0;j<orgUsersJson.length;j++){
          if(value[m] == orgUsersJson[j]._id){
            orgUsersJson[j].isDashUser = false;
            temparr.push(orgUsersJson[j])
          }
         }
      }
      let list = this.addkeyForDash(temparr)
       return list   
    }
  }
	async addkeyForDash(temparr){
    for(let j=0;j<temparr.length;j++){
     for(let k=0;k<this.dashboardUser.length;k++){
       if(temparr[j]._id == this.dashboardUser[k]._id){
         temparr[j].isDashUser = true;
       }
     }
    }
    this.userList = temparr
    return this.userList
  }
  ngOnDestroy() {
    this.mqttSubscription?.unsubscribe();
    this.mirrorColumnsSub?.unsubscribe();
    if(this.timerInterval) clearInterval(this.timerInterval)
  }
  async getCustomFieldsCorrespondingToDash(isPublic) {
    let res =[];
    if(!isPublic){
    let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
    res = JSON.parse(JSON.stringify(dashboardJson[this.dashId]['CUSTOM_FORM'] || []));
    }else{
      res = this.publicLeadCustomFieldData;
    }
    this.customFieldKeysArray = [];
    this.customFieldsArray = [];
    if (res.length > 0) {
      for (var i = 0; i < res.length; ++i) {
        this.customFieldKeysArray.push(res[i].key);
        this.customFieldsArray.push(res[i]);
      }
      if (!this.leadObj && this.leadId) {
        
        this.leadQueryCorrespondingToLeadSeqId();
      } else if(this.leadObj) {
        this.ifItemDataPresent(isPublic);
      } else {
        let nullLeadDataForLeadCreation = {};
        nullLeadDataForLeadCreation["custom_fields"] = undefined;
        this.settingDataForEditLead(nullLeadDataForLeadCreation);
      }
    }
    this.cdr.detectChanges();
  }
/**
 * 
  async getCustomFieldsCorrespondingToDash() {
    let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
    let res = JSON.parse(JSON.stringify(dashboardJson[this.dashId]['CUSTOM_FORM']));
    this.customFieldKeysArray = [];
    this.customFieldsArray = [];
    if (res.length > 0) {
      for (var i = 0; i < res.length; ++i) {
        this.customFieldKeysArray.push(res[i].key);
        this.customFieldsArray.push(res[i]);
      }
      if (!this.leadObj && this.leadId) {
        this.leadQueryCorrespondingToLeadSeqId();
      } else if(this.leadObj) {
        this.ifItemDataPresent();
      } else {
        let nullLeadDataForLeadCreation = {};
        nullLeadDataForLeadCreation["custom_fields"] = undefined;
        this.settingDataForEditLead(nullLeadDataForLeadCreation);
      }
    }
  }
 */
  async ifItemDataPresent(isPublic) {
    this.bucketId = this.leadObj.bucket_id;
      let isDataAlreadyPresent = this.checkIsDataPresent(this.leadObj)
      if(isDataAlreadyPresent){
        this.addDataForEditLead(this.leadObj);
      }else{
        this.settingDataForEditLead(this.leadObj);
      }
     
  }

  leadQueryCorrespondingToLeadSeqId() {
    var leadIdList = [];
    leadIdList.push(this.leadId);
    var leadObjQuery = {};
    leadObjQuery["lead_id"] = leadIdList;
    if(this.isTemplateCall || this.itemQueryType){
      leadObjQuery["item_type"]=["TEMPLATE"]
    }
    leadObjQuery["is_deleted"] = [this.is_deleted,true]
    this.httpTransfer
      .getLeadQuery(leadObjQuery, this.dashId)
      .subscribe(async (res : any) => {
        if (res.status === 200) {
          this.bucketId = res.result?.leadResponse[0]?.bucket_id;
          this.currentLead = this.commonUtils.creatingJsonStructureOfLeadForTableView(
            res.result.leadResponse[0],
            this.arrayOfCustomFiled,
            null,
            this.callbackFunctionForConnectedItems.bind(this)
          );
            let isDataAlreadyPresent = this.checkIsDataPresent(res.result.leadResponse[0])
            if(isDataAlreadyPresent){
              this.addDataForEditLead(res.result.leadResponse[0]);
            }else{
              this.settingDataForEditLead(res.result.leadResponse[0]);
            } 
          await this.commonUtils.queryConnectedBoardItems(this.connectedItemsIdsArr,this.arrayOfCustomFiled,this.callbackFunctionForConnectedItemJson.bind(this));
        }
        this.cdr.detectChanges();
      });
  }

  callbackFunctionForConnectedItems(newConnectedItemIdArray){
    if(Array.isArray(newConnectedItemIdArray)) {
      newConnectedItemIdArray = newConnectedItemIdArray.filter(e => e)
    }
    if(newConnectedItemIdArray==null ||  !Array.isArray(newConnectedItemIdArray)){
      return
    }
    
    newConnectedItemIdArray.forEach(connectedItemId => {
      if(!this.connectedItemsIdsArr.includes(connectedItemId)){
        this.connectedItemsIdsArr.push(connectedItemId)
      }
    })
  }

  callbackFunctionForConnectedItemJson(itemJson){
    itemJson = this.commonUtils.creatingJsonStructureOfLeadForTableView(itemJson);
    this.mirrorColumnsJson[itemJson._id] = itemJson;
    for(let key in this.mirrorColumnsJson[itemJson._id]['custom_fields']){
      if(this.mirrorColumnsJson[itemJson._id]['custom_fields'][key] && this.mirrorColumnsJson[itemJson._id]['custom_fields'][key]['time_spend_till_now']){
        this.mirrorColumnsJson[itemJson._id]['custom_fields'][key]['time_spend_till_now_formated']=this.commonUtils.timeConvert(this.mirrorColumnsJson[itemJson._id]['custom_fields'][key]['time_spend_till_now'])
      }
    }
    this.cdr.detectChanges();
    

  }

  settingForEditLead(data,type?){
    let customFieldDataOfLead = [];
    if(type == "new"){
      this.customFieldDataOfLead = [];
    }
    var arrayOfData = this.customFieldKeysArray;
    if (data.custom_fields != undefined) {
      for (var i = 0; i < this.customFieldKeysArray.length; ++i) {
        var dataObj = {};
        dataObj["key"] = arrayOfData[i];        
        if (
          data.custom_fields[arrayOfData[i]] === undefined ||
          data.custom_fields[arrayOfData[i]] === null ||
          data.custom_fields[arrayOfData[i]] === ""
        ) {
          dataObj["value"] = "";
        } else {
          dataObj["value"] = data.custom_fields[arrayOfData[i]];
        }
        if (Array.isArray(data.custom_fields[arrayOfData[i]])) {
          dataObj["isArray"] = true;
        } else {
          dataObj["isArray"] = false;
        }
        if(type == "new"){
          this.customFieldDataOfLead.push(dataObj);
        }else if(type == "edit"){
          customFieldDataOfLead.push(dataObj);
        }
      }
    } else {
      for (var i = 0; i < this.customFieldKeysArray.length; ++i) {
        var dataObj = {};
        dataObj["key"] = arrayOfData[i];
        this.customFieldsArray.forEach((item) => {
          if (item["key"] == arrayOfData[i]) {
            if (!item["is_default_enabled"]) {
              dataObj["value"] = "";
            } else {
              if (item.type == this.customFieldType.CHECKBOX) {
                dataObj["value"] = this.setCheckBoxDefaultForLeadCreation(item);
              } else if (
                item.default_value !== "" &&
                item.type == this.customFieldType.RADIO
              ) {
                dataObj["value"] = item.default_value;
              } else if (
                item.type === this.customFieldType.DROPDOWN &&
                item.default_value !== ""
              ) {
                dataObj["value"] = item.default_value;
              } else if (
                item.default_value != "" &&
                item.type == this.customFieldType.DATE
              ) {
                dataObj["value"] = item.default_value
              } else {
                dataObj["value"] = item.default_value;
              }
            }
            if (Array.isArray(item.default_value)) {
              dataObj["isArray"] = true;
            } else {
              dataObj["isArray"] = false;
            }
          }
        });
        if(type == "new"){
          this.customFieldDataOfLead.push(dataObj);
        }else if(type == "edit"){
          customFieldDataOfLead.push(dataObj);
        }
      }
    }
    if(type == "edit"){
      this.matchAndReplaceOnlyChangedData(customFieldDataOfLead,data);
    }
    this.cdr.detectChanges();
  }
  addDataForEditLead(data: any) { 
    this.settingForEditLead(data,"edit")
    this.getCustomFieldsForEdit("edit");
  }
  matchAndReplaceOnlyChangedData(newData: any[],newItem) {
    if (this.customFieldDataOfLead.length >= newData.length) {
      for (let index = 0; index < this.customFieldDataOfLead.length; index++) {
        let prevElement = this.customFieldDataOfLead[index];
        let newElement = newData[index];
        if (newElement.value !== prevElement.value) {
          this.customFieldDataOfLead[index] = newElement;
          if(this.currentLead.custom_fields) this.currentLead.custom_fields[newElement.key] = newItem?.custom_fields[newElement.key];
        }
      }
    } else {
      this.customFieldDataOfLead = newData
    }
  }
  checkIsDataPresent(leadData) {
    let dataPresent = true;
      for(let i=0; i<this.customFieldDataOfLead.length; i++) {
          if(leadData.custom_fields && JSON.stringify(leadData.custom_fields[this.customFieldDataOfLead[i]['key']]) == JSON.stringify(this.customFieldDataOfLead[i]['value'])) {
            dataPresent = false;
          } else {
            dataPresent = true;
            break;
          }
      }
    return dataPresent;
  }

  settingDataForEditLead(data) {
    this.settingForEditLead(data,"new")
    this.getCustomFieldsForEdit("new");
  }
  setCheckBoxDefaultForLeadCreation(customFieldObj) {
    let defaultCheckArr = [];
    if(customFieldObj.default_value!=""){
    customFieldObj.default_value.forEach((value) => {
      defaultCheckArr.push(value);
    });
  }
    return defaultCheckArr;
  }
  matchAndReplaceOnlyChangedCustomField(newData: any[]) {
    if(this.arrayOfCustomFiled.length >= newData.length){
      for (let index = 0; index < this.arrayOfCustomFiled.length; index++) {
        if(this.updatedCustomFields.includes(this.arrayOfCustomFiled[index].key)){
          let prevElement = Object.assign({}, this.arrayOfCustomFiled[index]);
          let newElement = Object.assign({}, newData[index]);
          delete prevElement.uId;
          delete prevElement.summernote;
          delete newElement.uId;
          if(JSON.stringify(newElement) != JSON.stringify(prevElement)){
            if(['Board','MultipleBoard'].includes(newData[index].type)) {
              Object.keys(newData[index] || {}).forEach(key=>{this.arrayOfCustomFiled[index][key] = newData[index][key];}) 
              // this.arrayOfCustomFiled[index].valueData = newData[index].valueData;  
            }// handle text area note here
            else if(newData[index].type==this.textAreaField){
              Object.keys(newData[index] || {}).forEach(key=>{this.arrayOfCustomFiled[index][key] = newData[index][key];}) 
              if(this.arrayOfCustomFiled[index]['summernote']) this.arrayOfCustomFiled[index]['summernote']?.updateSummernoteText(newData[index].textareainput)
            }
            else{
              this.arrayOfCustomFiled[index]= newData[index];
            }
          }
        }
      }
    }else{
      this.arrayOfCustomFiled = newData;
    }
    this.updatedKey=null;
    
  }

  async getCustomFieldsForEdit(type?) {
    let res=[];
    if(!this.publicLeadCustomFieldData){
      let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
            res=JSON.parse(JSON.stringify(dashboardJson[this.dashId]['CUSTOM_FORM'] || []))
          }else{
      res = this.publicLeadCustomFieldData;
    }
        let arrayResponseOfCustomField = [];
        let contactTypeArrayOfCustom=[]
        if (res.length > 0) {
          for (var i = 0; i < res.length; ++i) {
            var custObj = {};
            custObj["uId"] = Date.now() + i + 1;
            custObj["display_key"] = res[i].display_key;
            custObj["mandatory"] = res[i].mandatory;
            custObj["locked"] = res[i].locked;
            custObj["hidden"] = res[i].hidden;
            custObj["type"] = res[i].type;
            custObj["key"] = res[i].key;
            custObj['valueData']=this.customFieldDataOfLead[i]?.value ?  JSON.parse(JSON.stringify(this.customFieldDataOfLead[i]?.value || {})) : this.customFieldDataOfLead[i]?.value;
            if (
              custObj["type"] == this.customFieldType.TEXT &&
              custObj["key"] === this.customFieldKeysArray[i]
            ) {
              console.log("resonse",res)
              custObj["textinput"] = this.customFieldDataOfLead[i].value;
              custObj["max_length"] = res[i]?.max_length;
              custObj["min_length"] = res[i]?.min_length;
              custObj["disallowed_char_list"] = res[i]?.disallowed_char_list;
              custObj["allowed_char_list"] = res[i]?.allowed_char_list;
            }
            if (
              custObj["type"] == this.customFieldType.TEXTAREA &&
              custObj["key"] === this.customFieldKeysArray[i]
            ) {
              custObj["textareainput"] = this.customFieldDataOfLead[i].value;
            }
            if (
              custObj["type"] == this.customFieldType.NUMBER &&
              custObj["key"] === this.customFieldKeysArray[i]
            ) {
              custObj["numinput"] =this.customFieldDataOfLead[i].value;
              custObj['show_display']=true;
              custObj['error']=='';
              custObj["number_display"] ={
                "display_type":res[i]?.number_display?.display_type,
                "number_of_decimal_digits":res[i]?.number_display?.number_of_decimal_digits}
              custObj['show_display']=true
              custObj["min_number"] = res[i]?.min_number;
              custObj["max_number"] = res[i]?.max_number;
              custObj["number_of_decimal"] = res[i]?.number_of_decimal;
            }
            if (custObj["type"] == this.customFieldType.DATE &&custObj["key"] === this.customFieldKeysArray[i]) {
              custObj['dateValidationJson']=this.commonUtils.createJsonForDateValidation(res[i])
            }
            if (
              custObj["type"] == this.customFieldType.LOCATION &&
              custObj["key"] === this.customFieldKeysArray[i]
            ) {
              custObj["locationInput"] = this.customFieldDataOfLead[i].value;
            }
            if (
              custObj["type"] == this.customFieldType.IPADDRESS &&
              custObj["key"] === this.customFieldKeysArray[i]
            ) {
              custObj["ipInput"] = this.customFieldDataOfLead[i].value;
              custObj['settings'] = res[i]['settings']
            }
            if (
              custObj["type"] == this.customFieldType.DATE &&
              custObj["key"] === this.customFieldKeysArray[i]
            ) {
              if (this.customFieldDataOfLead[i].value == "") {
                this.customFieldDataOfLead[i].value = {};
                custObj["dateinput"] = "";
              } else if(Object.keys(this.customFieldDataOfLead[i].value).length === 0){
                this.customFieldDataOfLead[i].value = {};
                custObj["dateinput"] = "";
              }
              custObj["dateinput"] = this.customFieldDataOfLead[i].value;
            }
            if (['Email','MultipleEmail'].includes(custObj["type"])) {
              custObj["emailinput"] = this.customFieldDataOfLead[i].value;
              // custObj['validateToEmail']=this.customFieldDataOfLead[i].value.trim().length? this.commonUtils.ValidateEmail(this.customFieldDataOfLead[i].value.trim()) : true;
            }
            if (['Mobile','MultipleMobile'].includes(custObj["type"]) ) {
              if(this.customFieldDataOfLead[i].value == ""){
                custObj["mobileinput"] = {};
              }else{
                custObj["mobileinput"] = this.customFieldDataOfLead[i].value;
              }
            }
                        if (custObj["type"] == this.customFieldType.IMAGEFIELD ||
                custObj["type"] == this.customFieldType.MULTIIMAGE ||
                custObj["type"] == this.customFieldType.VIDEO || 
                custObj["type"] == this.customFieldType.AUDIO ||
                custObj["type"] == this.customFieldType.MULTIVIDEO ||
                custObj["type"] == this.customFieldType.MULTIAUDIO ||
                custObj["type"] == this.customFieldType.FILEUPLOAD ||
                custObj["type"] == this.customFieldType.MULTIFILEUPLOAD) {
              custObj['fileObjArr'] = [];
              
              if (this.customFieldDataOfLead[i].value) {
                if(this.customFieldDataOfLead[i].isArray) {
                  this.customFieldDataOfLead[i].value.forEach((element) => {
                    console.log(element,'1st')
                    let fileObj = {}
                    fileObj['file_path'] = element?.file_path;
                    fileObj['file_name'] = element?.file_name;
                    fileObj['extension'] = element?.extension?element?.extension:element?.file_path?.split(".").pop();
                    fileObj['image_download_url'] = element?.file_url || element?.image_download_url;
                    fileObj['size_in_bytes']=element?.size_in_bytes?this.commonUtils.bytes_to_size(element?.size_in_bytes):''
                    fileObj['type']=element.type?element.type:null
                    custObj["fileObjArr"].push(fileObj);
                  });
                } else {
                  let fileObj = {}
                  console.log(this.customFieldDataOfLead[i].value,'2st')
                  fileObj['file_path'] = this.customFieldDataOfLead[i].value.file_path;
                  fileObj['file_name'] = this.customFieldDataOfLead[i].value.file_name;
                  fileObj['extension'] =  this.customFieldDataOfLead[i].value.extension?this.customFieldDataOfLead[i].value.extension:this.customFieldDataOfLead[i]?.value?.file_path?.split(".")?.pop();
                  fileObj['image_download_url'] = this.customFieldDataOfLead[i].value.file_url || this.customFieldDataOfLead[i]?.value?.image_download_url;
                  fileObj['size_in_bytes']=this.customFieldDataOfLead[i].value?.size_in_bytes?this.commonUtils.bytes_to_size(this.customFieldDataOfLead[i].value?.size_in_bytes):'';
                  fileObj['type']=this.customFieldDataOfLead[i].value.type?this.customFieldDataOfLead[i].value.type:null
                  custObj["fileObjArr"] = [fileObj];
                }
                console.log("converted array list",custObj["fileObjArr"])
              }
            }
            if (custObj["type"] == this.customFieldType.RADIO) {
              if (res[i].permissible_values.length > 0) {
                var radioArray = [];
                for (let j = 0; j < res[i].permissible_values.length; ++j) {
                  var radioObj = {};
                  radioObj["id"] = j;
                  radioObj["value"] = res[i].permissible_values[j];
                    if (res[i].permissible_values[j].k === this.customFieldDataOfLead[i].value) {
                      radioObj["radioinput"] = true;
                    } else {
                      radioObj["radioinput"] = false;
                    }
                  
                  radioArray.push(radioObj);
                }
              }
              custObj["permissible_values"] = radioArray;
            }
            if (custObj["type"] == this.customFieldType.CHECKBOX) {
              if (res[i].permissible_values.length > 0) {
                var checkArray = [];
                for (let j = 0; j < res[i].permissible_values.length; ++j) {
                  var checkObj = {};
                  checkObj["id"] = j;
                  checkObj["value"] = res[i].permissible_values[j];
                    for (let z = 0; z < this.customFieldDataOfLead[i].value.length; ++z) {
                      if (!checkObj["checkinput"]) {
                        if (
                          res[i].permissible_values[j].k ==
                          this.customFieldDataOfLead[i].value[z]
                        ) {
                          checkObj["checkinput"] = true;
                        } else {
                          checkObj["checkinput"] = false;
                        }
                      }
                    }
                  
                  checkArray.push(checkObj);
                }
              }
              custObj["permissible_values"] = checkArray;
            }
            if (custObj["type"] == this.customFieldType.DROPDOWN) {
              custObj["permissible_values"] = res[i].permissible_values;
              custObj['p_input'] =this.customFieldDataOfLead[i].value!=='' ? this.customFieldDataOfLead[i].value : null
            }
            if(custObj["type"] == this.customFieldType.SINGLEUSER && !this.publicLeadCustomFieldData) {
              custObj['singleUserInput'] = this.customFieldDataOfLead[i].value;
              this.getSelectedPeople(this.customFieldDataOfLead[i].value);
            }
            if(custObj["type"] == this.customFieldType.MULTIUSER && !this.publicLeadCustomFieldData) {
              custObj['multiUserInput'] = this.customFieldDataOfLead[i].value;
               let userList= this.makeUserList(this.customFieldDataOfLead[i].value)
               if(userList!=undefined){
               let userSelectedList = Object.values(userList)
              custObj['selectedUserList'] = userSelectedList[1]       
               } 
               else{
                custObj['selectedUserList'] = ""
               }
              this.getSelectedPeople(this.customFieldDataOfLead[i].value);
            }
            if(custObj["type"] == this.customFieldType.URL) {
              custObj['urlInput'] = this.customFieldDataOfLead[i].value;
            }
            if(custObj["type"] == this.customFieldType.MULTI_URL) {
              custObj['enable']=[]
              custObj['enableNewButton']=true
              custObj['multiurlInput'] = Array.isArray( this.customFieldDataOfLead[i].value)? 
                  this.customFieldDataOfLead[i].value: this.customFieldDataOfLead[i].value.length > 0 ?
                  [this.customFieldDataOfLead[i].value]:
                  [];
              if(!this.customFieldDataOfLead[i].value.length){
                this.addNewMultiUrl(custObj);
                this.multiUrlCall(custObj)
              }
          }
            if(custObj["type"] == this.customFieldType.PERCENTAGE) {
              custObj['percentInput'] = this.customFieldDataOfLead[i].value;              
            }
            if(custObj["type"] == this.customFieldType.CURRENCY) {
              let currencyObj = {}
              custObj["permissible_currencies"] = this.currencyArr.filter(x=>res[i].permissible_currencies.includes(x.code));
              currencyObj['currency'] = this.customFieldDataOfLead[i].value.currency
              currencyObj['amount'] = this.customFieldDataOfLead[i].value.amount
              custObj['currencyObj'] = currencyObj
            }
            if(custObj["type"] == this.customFieldType.MAP) {
              custObj['mapInput'] = this.customFieldDataOfLead[i].value
            }
            if(custObj["type"] == this.customFieldType.MULTISELECT) {
              custObj['permissible_values'] = res[i]['permissible_values'];
              custObj['multiselectInput'] = this.customFieldDataOfLead[i].value
            }
            if(custObj["type"] == this.customFieldType.RATING) {
              custObj['ratingInput'] = this.customFieldDataOfLead[i].value ? this.customFieldDataOfLead[i].value : 0
            }
            if(custObj["type"] == this.customFieldType.CHECKLIST) {
              custObj['checklistInput'] = this.customFieldDataOfLead[i].value
            }
            if(custObj["type"] == this.customFieldType.BUTTON) {
              custObj['buttonInput'] = this.customFieldDataOfLead[i].value
            }
            if(custObj["type"] == this.customFieldType.COUNTRY) {
              custObj['country'] = this.customFieldDataOfLead[i].value!='' ? this.customFieldDataOfLead[i].value :null
            }
            if(custObj["type"] == this.customFieldType.LOCATION) {
              custObj['location'] = this.customFieldDataOfLead[i].value
            }
            if(custObj['type'] == this.customFieldType.NUMBER){
              custObj['label'] = res[i]['label']
              custObj['align'] = res[i]['align']
              custObj['show_as_duration'] = res[i]['show_as_duration']

            }
            if(custObj["type"] == this.customFieldType.TIME_TRACKER) {
              if(!this.activeTrackerListBeenCalled && !this.isPublicLead){
                this.commonUtils.queryActiveTimeTrackerList(this.dashId, this.userId).then(val=>{
                  this.activeTrackerList = val;
                })
              }
              this.activeTrackerListBeenCalled = true;
              custObj['TIME_TRACKER'] = this.customFieldDataOfLead[i].value;
              custObj['time_spend_till_now_formated']=this.commonUtils.timeConvert(custObj['TIME_TRACKER']['time_spend_till_now'])
            }
            if(['Board','MultipleBoard'].includes(custObj["type"]) && !this.publicLeadCustomFieldData) {
              console.log(custObj,res[i]['connected_board_id'])
              custObj['itemTerminology'] = this.dashboardUtils.getDashboardTerminoloy(res[i]['connected_board_id']) || {};
              custObj['boardInput'] = this.customFieldDataOfLead[i].value || [];
              custObj['connected_board_id'] = res[i]['connected_board_id'];
              custObj['searchOpen'] = false;
              custObj['searchText'] = '';
              custObj['table_view'] = res[i]['table_view'];
              custObj['embedded_view'] = res[i]['embedded_view'];
              this.connectedBoardInfo[res[i]['key']] = {
                key: res[i]['connected_board_id'],
                table_view: res[i]['table_view'] === false ? false : true,
              }
              let dashboardInfo = await this.dashboardUtils.getDashboardInformation([res[i]['connected_board_id']])
              custObj['boardName'] = dashboardInfo[res[i]['connected_board_id']]?.DASHBOARD_INFO?.dashboard_name;
              custObj['type_of_board'] = dashboardInfo[res[i]['connected_board_id']]?.DASHBOARD_INFO?.board_type;
              custObj['isConnectedBoardReadonly']=await this.commonUtils.isDashboardReadOnly(res[i]['connected_board_id'])
              // custObj['boardName'] = await this.commonUtils.getAndStoreDashboardName(res[i]['connected_board_id']);
            }
            if(custObj["type"] == this.customFieldType.MIRROR && !this.publicLeadCustomFieldData) {
              // if(res[i]['connected_board_column_type'] != this.customFieldType.BOARD) {
                custObj['connected_board_key'] = res[i]['connected_board_key'];
                custObj['connected_board_column_key'] = res[i]['connected_board_column_key'];
                custObj['connected_board_column_type'] = res[i]['connected_board_column_type'];
                custObj['connected_board_id'] = res[i]['connected_board_id'];
                custObj['connected_board_column_display_key'] = res[i]['connected_board_column_display_key'];
                
                if(res[i]?.connected_board_id) {
                  let dashboardInfo = await this.dashboardUtils.getDashboardInformation([res[i]['connected_board_id']])
                  custObj['boardName'] = dashboardInfo[res[i]['connected_board_id']]?.DASHBOARD_INFO?.dashboard_name;
                }
                // custObj['boardName'] = await this.commonUtils.getAndStoreDashboardName(res[i]['connected_board_id']);
              // } else
               if(['Board','MultipleBoard'].includes(res[i]['connected_board_column_type'])) {
                // this.getItemsForBoardTypeMirrorColumn(res[i]['connected_board_id'],res[i]['connected_board_column_key'])
              }
            }
            if(['CONTACT','ORGANIZATION'].includes(this.dashboardInfo?.board_type)){
              if(['contact_phone_f9eoa8dfuf_mmob','company_phone_number_hir9p69xrx_mob','contact_email_qiybv1ozk4_eml','contact_user_e2NB6Dm3j_url','contact_user_TJe6L0Er5_url','contact_user_q8M5zYwj2_url','contact_user_K28bcgPZL_url','contact_user_x2uQrF0e3_url','contact_user_hp77fmHtX_url','contact_user_9XfYrmFu7_url','contact_user_qTX2Jx0j2_url','logo_yAqhpCq5_simg','profile_pic_29N2eLvy_simg',
              'contact_user_e2NB6Dm3j_url','contact_user_x2uQrF0e3_url','contact_user_TJe6L0Er5_url','contact_user_hp77fmHtX_url','contact_user_9XfYrmFu7_url','contact_user_q8M5zYwj2_url','contact_user_K28bcgPZL_url','contact_user_qTX2Jx0j2_url','logo_yAqhpCq5_simg','profile_pic_29N2eLvy_simg'].includes(custObj['key'])){
                contactTypeArrayOfCustom.push(custObj)
              }
              if(['logo_yAqhpCq5_simg','profile_pic_29N2eLvy_simg'].includes(custObj['key'])){
                this.selectedContactProfile=custObj
              }
            }
            arrayResponseOfCustomField.push(custObj);
          }
          console.log("res in the edit",this.contactTypeArrayCust)
        }
        if(type == "new"){
          if(['CONTACT','ORGANIZATION'].includes(this.dashboardInfo?.board_type)){
            this.contactTypeArrayCust=contactTypeArrayOfCustom
          }
          this.arrayOfCustomFiled = arrayResponseOfCustomField.slice(0);
          this.initializeSummerNote();
          if (!this.leadId) {
            this.onValueInput(this.arrayOfCustomFiled);
          }
        }else if(type == "edit"){
          let arrayOfCustomFiled = arrayResponseOfCustomField.slice(0);
          if(['CONTACT','ORGANIZATION'].includes(this.dashboardInfo?.board_type)){
            this.matchAndReplaceOnlyChangedCustomFieldInContact(contactTypeArrayOfCustom)
          }
          this.matchAndReplaceOnlyChangedCustomField(arrayOfCustomFiled);
          if(this.triggeredFromCreateACopy){
            this.updatedCustomFieldObj();
          }
        }
        let data=this.arrayOfCustomFiled?.filter(item=>item?.hidden==='ON_EMPTY' && !item?.valueData) 
        this.itemConfig['enableShowEmptyBtn']=data?.length>0
        if(this.leadObj)
        
        this.currentLead = this.commonUtils.creatingJsonStructureOfLeadForTableView(
          this.leadObj,
          this.arrayOfCustomFiled,
          null,
          !this.publicLeadCustomFieldData ?this.callbackFunctionForConnectedItems.bind(this):null
        );
        if(!this.publicLeadCustomFieldData){
          await this.commonUtils.queryConnectedBoardItems(this.connectedItemsIdsArr,this.arrayOfCustomFiled,this.callbackFunctionForConnectedItemJson.bind(this));
        }
        this.cdr.detectChanges();
  }



  closeOtherConnectedBoardSearchField(key) {
    this.arrayOfCustomFiled.forEach(field => {
      if(['Board','MultipleBoard'].includes(field.type) && field.key != key) {
        field.searchOpen = false;
      }
    })
  }

  getSelectedPeople(usersIdArr) {
    this.selectedPeople = [];
    this.users.forEach(user => {
      if(usersIdArr.includes(user.id)) {
        this.selectedPeople.push(user);
      }
    });
  }

  changeDateFormate(dateObj) {
    const date = dateObj['year'] + '-' + dateObj['month'] + '-' + dateObj['day'];
    return date;
  }

  initializeSummerNote() {
    setTimeout(() => {
      this.arrayOfCustomFiled.forEach((element, i) => {
        if (element.type === this.customFieldType.TEXTAREA) {  
          $("#summernote_" + i).summernote({
            placeholder: 'write here...',
            disableDragAndDrop: true,
            callbacks: {
              onBlur: (e) => {
              /*   var p = e.target.parentNode.parentNode;
                if (!(e.relatedTarget && $.contains(p, e.relatedTarget))) { */
                  // real blur the editor and what r u goin to do now
                  // this.onValueInput(
                  //   this.arrayOfCustomFiled,
                  //   this.textAreaField
                  // );
                },
                onChange:(e)=>{
                  if(element.mandatory){
                    this.validationRuntime(element,this.textAreaField)
                  }
                },
                onKeydown:(e)=>{
                  if(e.key== "Tab"){e.preventDefault();}
                }

            },
            toolbar: this.summerNoteconfig.toolbar,
          });
          $("#summernote_" + i).summernote(
          "code" ,this.arrayOfCustomFiled[i].textareainput
          )
        }
        if(this.isPreview || this.isItemLocked(element.locked) || this.isReadOnly) {
          $("#summernote_" + i).summernote('disable');
        }
      });
    }, 100);
    this.showLoader = false;
  }

  selectedCustomId(id) {
    this.selectedCustId = id;
  }

  selectedPermittedId(id) {
    this.selectedPermissibleValue = id;
  }

  setDataOfRadio(event: any, data, type?, label?) {
    console.log("radio inputs",this.radioInput,this.radioField)
    for (var i = 0;i <this.arrayOfCustomFiled[this.selectedCustId]["permissible_values"].length;i++) {
      this.arrayOfCustomFiled[this.selectedCustId]["permissible_values"][i][
        "radioinput"
      ] = false;
    }
    if (
      this.arrayOfCustomFiled[this.selectedCustId]["permissible_values"][
        this.selectedPermissibleValue
      ]["radioinput"] == true
    ) {
      this.radioInput = false;
      this.arrayOfCustomFiled[this.selectedCustId]["permissible_values"][
        this.selectedPermissibleValue
      ]["radioinput"] = this.radioInput;
    }
    if (
      this.arrayOfCustomFiled[this.selectedCustId]["permissible_values"][
        this.selectedPermissibleValue
      ]["radioinput"] == false
    ) {
      this.radioInput = true;
      this.arrayOfCustomFiled[this.selectedCustId]["permissible_values"][
        this.selectedPermissibleValue
      ]["radioinput"] = this.radioInput;
    }
    this.onValueInput(data, type, label);
  }
  updatedCustomFieldObj(){
    this.customFieldObj = this.createCustomFiledObj();

    let isValidated = this.validateAllFields(this.customFieldObj);
    if(isValidated){
      this.onEdit.emit(this.customFieldObj);
    }
    this.isLeadCreated.emit(isValidated);
  }
  setDataOfCheckBox(data, type, label) {
    this.onValueInput(data, type, label);
  }

  async onValueInput(data, type?, label?,createdLeadId?,multiFieldVal?) { 
    this.updatedKey=label
    if (type != this.textAreaField) {
      this.selectedSummernoteIndex = undefined;
    }
    this.customFieldObj = this.createCustomFiledObj(type, label);
    if(Object.keys(this.customFieldObj).length==0){return}
    let isValidated = this.validateAllFields(this.customFieldObj);
    if(isValidated){
      this.onEdit.emit(this.customFieldObj);
    }
    this.isLeadCreated.emit(isValidated);
    if (this.leadId && !this.triggeredFromCreateACopy) {
      this.onUpdateField(data, type, label,createdLeadId,multiFieldVal);
    }
  }

  setDataOfPeopleType(user, type, label) {   
    //this.userList.push(user)
    this.arrayOfCustomFiled.forEach(field => {
      if(field.key === label && field.type === this.customFieldType.SINGLEUSER) {
        field.singleUserInput = user._id;
      } else if(field.key === label && field.type === this.customFieldType.MULTIUSER) {
        if(field.multiUserInput == "" || field.multiUserInput == null){
          field.multiUserInput = [];
          field.selectedUserList = [];
          field.multiUserInput.push(user._id);
          field.selectedUserList.push(user);
        } else {
          field.multiUserInput.push(user._id);
          field.selectedUserList.push(user);
        }
      }
    });
    this.onValueInput(this.arrayOfCustomFiled, type, label,false,user._id);
  }

  removeUser(index, type, label) {
   // this.userList.splice(index,1)
    this.arrayOfCustomFiled.forEach(field => {
      if(field.key === label && field.type === this.customFieldType.SINGLEUSER) {
          field.singleUserInput = null;
      } else if(field.key === label && field.type === this.customFieldType.MULTIUSER) {
        field.multiUserInput.splice(index, 1);
        field.selectedUserList.splice(index, 1)
        if(field.multiUserInput.length===0) field.multiUserInput = [], field.selectedUserList = null
      }
    });
    this.onValueInput(this.arrayOfCustomFiled, type, label);
  }




  validationRuntime(item,itemValue){
    let validateforCustmfield=false
    let tempCustomFieldObj=this.customFieldObj
    tempCustomFieldObj = this.createCustomFiledObj(item.type, itemValue);
    validateforCustmfield=this.validateAllFields(tempCustomFieldObj)
    this.isLeadCreated.emit(validateforCustmfield)
  }


  validateAllFields(currentObj) {
    let isNotValidated = false;
    if(!this.isTemplateCall) {
      this.customFieldsArray.forEach(element => {
        if(element.mandatory){
          if((currentObj[element.key]==null || currentObj[element.key]==undefined || currentObj[element.key]?.toString()=="") || (Array.isArray(currentObj[element.key]) && currentObj[element.key].length==0) || (Object.keys(currentObj[element.key])?.length==0 && currentObj[element.key]?.constructor===Object)){
            isNotValidated = true;
          }
          if(currentObj[element.key] && Object.keys(currentObj[element.key]).length>0) {
            const values = Object.values(currentObj[element.key]) || [];
            values.forEach(value => {
              if(value === undefined) {
                isNotValidated = true;
              }
            });
          }
        }
      });
    }
    if(this.isTemplateCall){
      this.customFieldsArray.forEach(element => {
        
        if(!element.is_default_enabled){
          delete element.default_value
        }
      });
    }
    if(this.editTitle == "" && !this.isTemplateCall){
      isNotValidated = true;
    }
    return !isNotValidated;
  }
  onUpdateField(data, type?, label?,createdLeadId?,multiFieldVal?) {
    this.updateChildCustomFields(data, type, label,createdLeadId,multiFieldVal);
  }

  getImageFile() {
    this.imageFileNameList = [];
    // document.getElementById("singleImageFile").click();
  }
  async updateChildCustomFields(data, type?, label?,createdLeadId?,multiFieldVal?) {
    // this.arrayOfCustomFiled = data;
    var leadObj = {};
    var custObj = {};
    var custom_fields = [];
    custObj = this.createCustomFiledObj(type, label);
    custom_fields.push(custObj);
    if(type=='MultipleBoard' && createdLeadId){
     leadObj["item_updates"]=[
        {
            "field_key": "custom_fields."+Object.keys(custObj)[0],
            "update_type": "APPEND",
            "value": [createdLeadId]
        }
    ]
    }
    else if(["MultipleImage","MultipleVideo","MultipleFile","MultiUrl","MultipleAudio","MultiUser","MultiUrl"].includes(type) && multiFieldVal?.length>0){
      leadObj["item_updates"]=[
        {
            "field_key": "custom_fields."+Object.keys(custObj)[0],
            "update_type":["MultiUrl"].includes(type) ? "PREPEND" :  "APPEND",
            "value": ["MultipleImage","MultipleVideo","MultipleFile","MultipleAudio"].includes(type) ? multiFieldVal : [multiFieldVal]
        }
    ]
    }
    else{
      leadObj["custom_fields"] = custObj;
    }
    if(this.isTemplateCall){
      leadObj["item_type"] = "TEMPLATE"
    }
    if(this.activityBoardId){
      this.onUpdate.emit(leadObj)
    }else{
      this.httpTransfer.updateLeadCorrespondingToLeadId(leadObj, this.dashId, this.leadId).subscribe((res:any)=>{
        if (res?.status === 200) {
          if (this.modalRefDelete) {
            this.modalRefDelete.hide();
            this.modalRefDelete = null;
          }
          if (this.modalRef2) {
           this.modalRef2.hide();
            this.modalRef2 = null;
          }
          if(res?.result?.message?.includes('wrong extension')){
            this.toastService.Errors(res.result?.message);
          }
        }
        this.cdr.detectChanges();
      })
    }

  }


  createCustomFiledObj(type?, label?) {
    var custObj = {};
    for (var i = 0; i < this.arrayOfCustomFiled.length; ++i) {
      if (this.arrayOfCustomFiled[i].type == this.textField) {
        if (this.arrayOfCustomFiled[i].textinput != "" || (!this.arrayOfCustomFiled[i].mandatory)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].textinput;
        }
      } 
      else if (this.arrayOfCustomFiled[i].type == this.numberField) {
        if (!Number.isNaN(this.arrayOfCustomFiled[i]?.numinput) && (this.arrayOfCustomFiled[i].numinput != "" || this.arrayOfCustomFiled[i].numinput == 0)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].numinput ? Number(this.arrayOfCustomFiled[i].numinput) : this.arrayOfCustomFiled[i].numinput;
        }
      } 
      // else if (this.arrayOfCustomFiled[i].type == this.numberField) {
      //   if (!Number.isNaN(this.arrayOfCustomFiled[i]?.numinput) && (this.arrayOfCustomFiled[i].numinput != "" || this.arrayOfCustomFiled[i].numinput == 0)) {
      //     const num = Number(this.arrayOfCustomFiled[i].numinput);
      //     let actual = null;
      //     if(Number.isNaN(num)) actual = parseFloat(this.arrayOfCustomFiled[i].numinput?.split(',').join(''))
      //     else actual = num;
      //     custObj[this.arrayOfCustomFiled[i].key] = Number.isNaN(actual) ? this.arrayOfCustomFiled[i].numinput: actual;
      //   }
      // }
      else if (this.arrayOfCustomFiled[i].type == "Button") {
        if(!(this.isItemLocked(this.arrayOfCustomFiled[i]?.locked))){
          custObj[this.arrayOfCustomFiled[i].key] = {'event':"clicked"};
        }
        } else if (this.arrayOfCustomFiled[i].type == this.locationConstant) {
        if (this.arrayOfCustomFiled[i].locationInput != "") {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].locationInput;
        }
      } else if (this.arrayOfCustomFiled[i].type == this.ipConstant) {
        if (this.arrayOfCustomFiled[i].settings === 'Manual') {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].ipInput;
        }
      } else if (this.arrayOfCustomFiled[i].type == this.dateField) {
        if(this.arrayOfCustomFiled[i].dateinput == null){
          custObj[this.arrayOfCustomFiled[i].key] = {};
        }else{
        if (Object.keys(this.arrayOfCustomFiled[i].dateinput).length>0) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].dateinput;
        }
      }
      } else if (['Email','MultipleEmail'].includes(this.arrayOfCustomFiled[i].type)) {
        // && this.commonUtils.ValidateEmail(this.arrayOfCustomFiled[i].multiEmailInput)
        if (this.arrayOfCustomFiled[i].multiEmailInput!= "") {
          console.log(this.arrayOfCustomFiled[i])
          if(this.arrayOfCustomFiled[i]?.type=='Email'){custObj[this.arrayOfCustomFiled[i].key] = this.createLeadCall? this.arrayOfCustomFiled[i]?.multiEmailInput?this.arrayOfCustomFiled[i]?.multiEmailInput[0]:this.arrayOfCustomFiled[i]?.multiEmailInput:this.arrayOfCustomFiled[i]?.multiEmailInput;}
          else {custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i]?.multiEmailInput?.filter(ele => ele !== '');}
        }
        if(this.arrayOfCustomFiled[i]?.multiEmailInput === "") {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].multiEmailInput;
        }
      } else if (['Mobile','MultipleMobile'].includes(this.arrayOfCustomFiled[i].type)) {
        if(this.arrayOfCustomFiled[i].mobileinput){
            if(this.arrayOfCustomFiled[i].mobileinput?.mobile_number){
              this.arrayOfCustomFiled[i].mobileinput.mobile_number = this.arrayOfCustomFiled[i].mobileinput?.mobile_number.toString()
            }
            custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].mobileinput;
        }
      } else if (this.arrayOfCustomFiled[i].type == this.textAreaField) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].textareainput;
      }
      else if (this.arrayOfCustomFiled[i].type == this.radioField) {
        for (let k = 0; k < this.arrayOfCustomFiled[i]["permissible_values"].length; ++k) {
          console.log("item of permisiible values",this.arrayOfCustomFiled[i]["permissible_values"][k])
            if (this.arrayOfCustomFiled[i]["permissible_values"][k]["radioinput"] == true) {
              custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i]["permissible_values"][k].value.k;
            }
        }
        if(this.selectedCustId === ""){
          custObj[this.arrayOfCustomFiled[i].key]=null
        }
      } else if (this.arrayOfCustomFiled[i].type == this.checkField) {
        var checkArray = [];
        for (var j = 0; j < this.arrayOfCustomFiled[i]["permissible_values"].length; ++j) {
          if (this.arrayOfCustomFiled[i]["permissible_values"][j]["checkinput"] == true) {
            checkArray.push(this.arrayOfCustomFiled[i]["permissible_values"][j].value.k);
          }
        }
        custObj[this.arrayOfCustomFiled[i].key] = checkArray;
      } else if (this.arrayOfCustomFiled[i].type == this.dropDownField) {
        if (this.arrayOfCustomFiled[i].p_input != -1) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].p_input;
        }
      } else if (this.arrayOfCustomFiled[i].type == this.customFieldType.IMAGEFIELD ||
        this.arrayOfCustomFiled[i].type == this.customFieldType.VIDEO ||
        this.arrayOfCustomFiled[i].type == this.customFieldType.AUDIO ||
        this.arrayOfCustomFiled[i].type == this.customFieldType.FILEUPLOAD) {
        if (this.arrayOfCustomFiled[i].fileObjArr.length == 0) {
          custObj[this.arrayOfCustomFiled[i].key] = null;
        }
        if (this.arrayOfCustomFiled[i].fileObjArr.length > 0) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].fileObjArr[0];
        }
      }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.MULTIIMAGE ||
        this.arrayOfCustomFiled[i].type == this.customFieldType.MULTIVIDEO ||
        this.arrayOfCustomFiled[i].type == this.customFieldType.MULTIAUDIO ||
        this.arrayOfCustomFiled[i].type == this.customFieldType.MULTIFILEUPLOAD) {
        if (this.arrayOfCustomFiled[i].fileObjArr.length == 0) {
          custObj[this.arrayOfCustomFiled[i].key] = null;
        }
        if (this.arrayOfCustomFiled[i].fileObjArr.length > 0) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].fileObjArr;
        }
      }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.SINGLEUSER) {
        if (this.arrayOfCustomFiled[i].singleUserInput != "" || (!this.arrayOfCustomFiled[i].mandatory)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].singleUserInput ? this.arrayOfCustomFiled[i].singleUserInput:null;
        }
      }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.MULTIUSER) {
        if (this.arrayOfCustomFiled[i].multiUserInput != "" || (!this.arrayOfCustomFiled[i].mandatory)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].multiUserInput ? this.arrayOfCustomFiled[i].multiUserInput:null;
        }
      }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.PERCENTAGE) {
        if (this.arrayOfCustomFiled[i].percentInput != "" || (!this.arrayOfCustomFiled[i].mandatory) || this.arrayOfCustomFiled[i].percentInput == 0) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].percentInput;
        }
      }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.MULTI_URL) {
        if (this.arrayOfCustomFiled[i].multiurlInput.length != 0 || (!this.arrayOfCustomFiled[i].mandatory)) {
            custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].multiurlInput;          
        }
    }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.URL) {
        if (this.arrayOfCustomFiled[i].urlInput != "" || (!this.arrayOfCustomFiled[i].mandatory)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].urlInput ? this.arrayOfCustomFiled[i].urlInput :null;          
        }
      }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.RATING) {
        if (this.arrayOfCustomFiled[i].ratingInput != "" || (!this.arrayOfCustomFiled[i].mandatory)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].ratingInput ? this.arrayOfCustomFiled[i].ratingInput:null;
        }
      }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.CHECKLIST) {
        if (this.arrayOfCustomFiled[i].checklistInput != "" || (!this.arrayOfCustomFiled[i].mandatory)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].checklistInput ? this.arrayOfCustomFiled[i].checklistInput :null;
        }
      }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.BUTTON) {
        if (this.arrayOfCustomFiled[i].buttonInput != "" || (!this.arrayOfCustomFiled[i].mandatory)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].buttonInput;
        }
      }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.COUNTRY) {
        if (this.arrayOfCustomFiled[i].country != "" || (!this.arrayOfCustomFiled[i].mandatory)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].country;
        }
      }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.LOCATION) {
        if (this.arrayOfCustomFiled[i].location != "" || (!this.arrayOfCustomFiled[i].mandatory)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].location;
        }
      }
      else if (['Board','MultipleBoard'].includes(this.arrayOfCustomFiled[i].type)) {
        if (this.arrayOfCustomFiled[i].boardInput != "" || (!this.arrayOfCustomFiled[i].mandatory)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].boardInput ? this.arrayOfCustomFiled[i].boardInput :null;
        }
      }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.MULTISELECT) {
        if (this.arrayOfCustomFiled[i].multiselectInput != "" || (!this.arrayOfCustomFiled[i].mandatory)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].multiselectInput ? this.arrayOfCustomFiled[i].multiselectInput : null;
        }
      }
      else if (this.arrayOfCustomFiled[i].type == this.customFieldType.CURRENCY) {
        if (this.arrayOfCustomFiled[i].currencyObj?.currency != "" || (!this.arrayOfCustomFiled[i].mandatory)) {
          custObj[this.arrayOfCustomFiled[i].key] = this.arrayOfCustomFiled[i].currencyObj;
        }
      }
    }

    if (this.leadId) {
      if (this.triggeredFromCreateACopy) {
        return custObj;
      } else {
          var singleCustObj = {};
          let updateFields=this.arrayOfCustomFiled.filter(item=>item.key==label)
          for (const property in custObj) {
            if (property == label) {
              singleCustObj[property] = custObj[property]!=='' ? custObj[property] : null ;
              if(updateFields.length>0 &&  JSON.stringify(singleCustObj[property])===JSON.stringify(updateFields[0]?.valueData)){
                delete singleCustObj[property]
              }
            }
          }
      }
      return singleCustObj;
      
    } else {
      return custObj;
      
    }
    
  }
  onUploadFile(fileData, fileType?, label?,isUploadWithEdit=true,filePath?) {
    if((fileType==this.customFieldType.IMAGEFIELD || fileType==this.customFieldType.MULTIIMAGE) && isUploadWithEdit){
      this.triggerOpenEditImageModal(fileData,fileType,label)
    }
    else{
      if(fileData.length>0) {
        this.selectedFileType = fileType;
        this.selectedKeyLabel = label;
        this.arrayOfCustomFiled.forEach((element) => {
          if (element.key == label) {
            element['isLoaded'] = true;
          }
        });
        this.commonUtils.onUploadFile(fileData, 'LEAD',null, this.fileUpload.bind(this), {label: label, fileType: fileType, previousFilePath: filePath});
      }
    }
  }
  fileUpload(fileArr, params,fromOptions=false) {
    console.log("params",params)
    let multiMediaArr=[]
    fileArr.forEach(fileObj => {
      let fileJson = {};
      fileJson['file_name'] = fileObj['file_name']?fileObj['file_name']:fileObj?.name;
      fileJson['file_path'] = fileObj['path']?fileObj['path']:fileObj['file_path'];
      fileJson['image_download_url'] = fileObj['image_download_url'];
      fileJson['extension'] = fileObj?.extension?fileObj.extension:fileObj['file_path']?.split(".")?.pop();
      if(fromOptions){
        fileJson['type'] = fileObj?.type;
        fileJson['size_in_bytes']=fileObj?.size_bytes?fileObj?.size_bytes:0
      }
      this.arrayOfCustomFiled.forEach((element) => {
        if ((element.type == this.customFieldType.FILEUPLOAD || element.type == this.customFieldType.IMAGEFIELD || element.type == this.customFieldType.VIDEO || element.type == this.customFieldType.AUDIO) && element.key == params.label) {
          element.fileObjArr = [];
          element.fileObjArr.push(fileJson)
          element['isLoaded'] = false;
          element['isRecordingStopped'] = false;
        }
        if ((element.type == this.customFieldType.MULTIFILEUPLOAD || element.type == this.customFieldType.MULTIIMAGE || element.type == this.customFieldType.MULTIVIDEO || element.type == this.customFieldType.MULTIAUDIO) && element.key == params.label) {
          if(params.previousFilePath){
            let index=element.fileObjArr?.findIndex(file=>file.file_path===params.previousFilePath)
            if(index>-1)element.fileObjArr[index]=fileJson
          }else{
            element.fileObjArr.push(fileJson)
            multiMediaArr.push(fileJson)
          }
          element['isLoaded'] = false;
          element['isRecordingStopped'] = false;
        }
      });
    })
    this.onValueInput(this.arrayOfCustomFiled, params.fileType, params.label,false,multiMediaArr);
  }
 
  getMultiImageFile() {
    this.imageFileNameList = [];
  }

  removeSingleImage(data, type?, key?) {
    this.arrayOfCustomFiled.forEach((element) => {
      if (element["uId"] == data.uId) {
        element.fileObjArr = [];
      }
    });
    this.onValueInput(this.arrayOfCustomFiled, data.type, data.key);
  }

  removeFile(indexVal, item, label) {
    var customFieldArr = [];
    item['isLoaded'] = true;
    if(item && Array.isArray(item["fileObjArr"])) {
      item["fileObjArr"].splice(indexVal, 1);
    } else {
      delete item['fileObjArr'];
    }
    if(!this.leadId) {
      item['isLoaded'] = false;
    }
    customFieldArr.push(item);
    this.toBeDeleteIndex = null;
    this.toBeDeleteItem = null;
    this.toBeDeleteKey = null;
    this.onValueInput(customFieldArr, item.type, label);
  }

  checkForFileType(fileData,fieldType) {
    let isFileAndFieldTypeMatch = false;
      let fileType = fileData.type.split("/")[0];
      if (
        fieldType == this.customFieldType.VIDEO ||
        fieldType == this.customFieldType.MULTIVIDEO
      ) {
        if (fileType == this.videoType) {
          isFileAndFieldTypeMatch = true;
        } else {
          isFileAndFieldTypeMatch = false;
          var message = this.constant.getCopyMessage();
          this.toastService.Errors(message.msgForCorrectFileType);
          return;
        }
      }else if (
        fieldType == this.customFieldType.AUDIO ||
        fieldType == this.customFieldType.MULTIAUDIO
      ){
        if (fileType == this.audioType) {
          isFileAndFieldTypeMatch = true;
        } else {
          isFileAndFieldTypeMatch = false;
          var message = this.constant.getCopyMessage();
          this.toastService.Errors(message.msgForCorrectFileType);
          return;
        }
      }
      
      else if (
        fieldType == this.customFieldType.IMAGEFIELD ||
        fieldType == this.customFieldType.MULTIIMAGE
      ) {
        if (fileType == this.imageConstant) {
          isFileAndFieldTypeMatch = true;
        } else {
          isFileAndFieldTypeMatch = false;
          var message = this.constant.getCopyMessage();
          this.toastService.Errors(message.msgForCorrectFileType);
          return;
        }
      } else if (
        fieldType == this.customFieldType.FILEUPLOAD ||
        fieldType == this.customFieldType.MULTIFILEUPLOAD
      ) {
        if (
          fileType != this.imageConstant &&
          fileType != this.videoType
        ) {
          isFileAndFieldTypeMatch = true;
        } else {
          isFileAndFieldTypeMatch = false;
          var message = this.constant.getCopyMessage();
          this.toastService.Errors(message.msgForCorrectFileType);
          return;
        }
      }
    return isFileAndFieldTypeMatch
  }

  onDrop(event, fileType?, label?) {
    event.preventDefault();
    this.clearDropZoneFromFields()
    let fileData = []
    let droppedFileData = event.dataTransfer.items || [];
    if(droppedFileData.length>0) {
      for(let j=0; j<droppedFileData.length; j++) {
        if(droppedFileData[j] && droppedFileData[j].getAsFile()) fileData.push(droppedFileData[j].getAsFile())
      }
    }
    this.onUploadFile(fileData, fileType, label)
  }

  clearDropZoneFromFields() {
    this.arrayOfCustomFiled.forEach(item => {
      const el = document.getElementById('drop_'+item.key);
      if(el)el.style.visibility = 'hidden'
    });
  }
  
  allowDrop(ev) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  onDragEnter(key) {
    if(this.currentDragKey !== key) {
      this.currentDragKey = key;
      this.arrayOfCustomFiled.forEach(item => {
        let el = document.getElementById('drop_'+item.key);
        if(item.key === key) {
          if(el)el.style.visibility = 'visible';
        } else {
          if(el)el.style.visibility = 'hidden';
        }
      });
    }
  }
  
  onDragLeave(key) {
    let el = document.getElementById('drop_'+key);
    if(el)el.style.visibility = 'hidden';
  }

  openDocInNewPage(item, index) {
    var length = Array.isArray(item?.fileObjArr) ? item?.fileObjArr.length : 1;
    this.nextButtonDisable = false;
    this.previousButtonDisable = false;
    if (index == length - 1) {
      this.nextButtonDisable = true;
    }
    if (index == 0) {
      this.previousButtonDisable = true;
    }
    this.documentObject = item;
    if (Array.isArray(item?.fileObjArr)) {
      item?.fileObjArr.forEach((element, i) => {
        if (index == i) {
          this.documentUrl = element.image_download_url;
        }
      });
    } else {
      this.documentUrl = item?.image_download_url;
    }
    this.currentIndex = index;
    this.showStatus = true;
  }
  setDocumentUrl(item,index) {
    this.commonUtils.downloadMediaURL(item,index)

  }
  openDeleteModal(template) {
    this.modal.open(template, { size: 'sm' });
    // this.modalRefDelete = this.modalService.show(template);
  }
  closeModal() {
    if (!this.modalRef2) {
      return;
    }

    this.modalRef2.hide();
    this.modalRef2 = null;
  }
  closeDeleteModal() {
    if (!this.modalRefDelete) {
      return;
    }
    this.isModalOpen = false;

    this.modalRefDelete.hide();
    this.modalRefDelete = null;
  }
  openImageSliderInModal(item, index) {
    var length = item.fileObjArr.length;
    this.nextButtonDisable = false;
    this.previousButtonDisable = false;
    if (index == length - 1) {
      this.nextButtonDisable = true;
    }
    if (index == 0) {
      this.previousButtonDisable = true;
    }
    this.currentIndex = index;
  }
  openVideoPlayerModal(item?, index?) {
    var length = Array.isArray(item.path) ? item.path.length : 1;
    this.nextButtonDisable = false;
    this.previousButtonDisable = false;
    if (index == length - 1) {
      this.nextButtonDisable = true;
    }
    if (index == 0) {
      this.previousButtonDisable = true;
    }
    this.videoObject = item;
    if(Array.isArray(item?.fileObjArr)) {
      item.fileObjArr.forEach((element, i) => {
        if (index == i) {
          this.videoUrl = element.image_download_url;
        }
      });
    } else {
      this.videoUrl = item.image_download_url;
    }
    this.currentIndex = index;
    setTimeout(() => {
      this.showStatus = true;
    }, 5);
  }

  moveToNextIndex() {
    this.showStatus = false;
    this.currentIndex = this.currentIndex + 1;
    //this.updatePreviewObject(this.imageObject,this.currentIndex)
  }
  moveToPreviousIndex() {
    this.showStatus = false;
    this.currentIndex = this.currentIndex - 1;
   // this.updatePreviewObject(this.imageObject,this.currentIndex)
  }

  openModalForPreview(item, i, type, template) {
    console.log("iem and extension",item,i)
    this.itemData=item;
    if(item?.fileObjArr[i].extension==='link'){
      window.open(item.path);
      return;
    }
    this.typeForModalOpen = type;
    this.mediaArr = []
    this.indexForPreview = i
    if(item.fileObjArr) { 
      item.fileObjArr.forEach(e=>{
        let obj={}
        obj['type'] = type
        obj['name'] =  e?.file_name + '.' + e?.extension
        obj['url']  =  e?.image_download_url
        obj['path'] =  e?.file_path 
        obj['size_in_bytes'] =  e?.size_in_bytes
        this.mediaArr.push(obj)
      })
    } else {
      let obj={}
      obj['type'] = type
      obj['name'] =  item?.file_name + '.' + this.commonUtils.getFileNameExtFromS3Path(item?.file_path).extension
      obj['url']  =  item?.file_url
      obj['path'] =  item?.file_path 
      obj['size_in_bytes'] =  item?.size_in_bytes 
      this.mediaArr.push(obj)
    }
    this.currentIndex = i;
    this.modalRef = this.modal.open(template, { size: 'lg' , windowClass: 'file preview-modal'}) 
    this.basicUtils.storeModlRef(this.modalRef)
  }
  openEditImageModal(data: { file: any, fileType?: any, label?: any}){
    this.editImageFiles={
      fileData : data.file,
      key : data.label,
      type:data.fileType,
      filePreviouspath: data.file[0]?.file_path || null,
      itemData:{dashId: this.dashId,leadId:this.leadId,customKey:data.label,lead:this.leadObj}
    }
    this.modalRef = this.modal.open(this.imageEditorRef, { size: 'lg' , windowClass: 'file preview-modal'}) 
    this.basicUtils.storeModlRef(this.modalRef)
  }

  triggerOpenEditImageModal(file, fileType?, label?) {
    this.openEditImageModal({ file: file, fileType: fileType, label
      : label })
  }

  setDeletedata(i, item, key) {
    this.toBeDeleteIndex = i;
    item?.fileObjArr?.forEach((ele,i)=>{
      let json={}
      json={
        file_name:ele?.file_name,
        file_path:ele?.file_path,
        extension:ele?.extension,
        image_download_url:ele?.image_download_url,
        size_in_bytes:ele?.size_in_bytes,
        type:ele?.type,
      }
      item.fileObjArr[i]=json
    })
    this.toBeDeleteItem = item;
    this.toBeDeleteKey = key;
    if (this.typeForModalOpen == this.customFieldType.MULTIFILEUPLOAD) {
      this.currentIndex + 1;
      this.openDocInNewPage(this.documentObject, this.currentIndex);
    }
  }

  async requestMediaPermission(audioFlag,fileType, label) {
    this.audioFlag = audioFlag;
    this.commonUtils.requestForRecording(audioFlag)
          .then(this.openScreenRecorder.bind(this,fileType, label))
          .catch(()=>this.modal.open(this.mediaPermissionModal, { size: 'sm' }));
          // this.modalService.show(this.mediaPermissionModal))
  }

  openScreenRecorder(fileType, label) {
    this.selectedKey = '';
    this.fileType = '';
    this.arrayOfCustomFiled.forEach(element=>{
      if (element.type == fileType && element.key == label) {
        this.selectedKey = label;
        this.fileType = fileType;
      }
    })
    if (this.openScreenRecorderPlayer) {
      this.openScreenRecorderPlayer = false;
    } else {
      this.openScreenRecorderPlayer = true;
    }
  }

  async openAudioRecorder(fileType, label) {
    this.selectedKey = '';
    this.fileType = '';
    this.arrayOfCustomFiled.forEach(element=>{ 
      if (element.type == fileType && element.key == label) {
        this.selectedKey = label;
        this.fileType = fileType;
      }
    })
    if (this.openAudioRecorderPlayer) {
      this.openAudioRecorderPlayer = false;
    } else {
      let permission = await this.commonUtils.checkMediaPermission();
      if(permission) {
        this.openAudioRecorderPlayer = true;
      } else {
        this.modal.open(this.mediaPermissionModal, { size: 'sm' });
      }
    }
  }

  stopScreenRecording(item) {
    item.isRecordingStopped = true;
    item.isLoaded = true;
    this.recordingStarted = false;
    delete this.itemConfig?.isRecordingInprogress
    this.screenRecordingComponent.stopScreenRecording();
  }

  recordingStatusChanged(event){
    this.itemConfig.isRecordingInprogress=event
  }
  uploadScreenRecording(item) {
    this.screenRecordingComponent.emitScreenRecording();
    item.isRecordingStopped = false
  }
  stopAudioRecording(item) {
    item.isLoaded=true
    this.audioRecordingComponent.stopAudioRecording();
    item.isRecordingStopped = false;
  }

 async getScreenRecordedData(data,fileType?, label?) {
  if(this.timerInterval){
    clearInterval(this.timerInterval);
    this.timerInterval=null;
  }
    this.recordingTimeForVideo = '00:00:00';
    if(!this.recordingName){
      this.arrayOfCustomFiled.forEach((element) => {
        if (element.key == label) {
          element.isRecordingStopped = true
        }
      });
      return
    }
    this.selectedFileType = fileType;
    this.selectedKeyLabel = label;
    data.file_name = this.recordingName
    this.fileUpload([data],{label: label, fileType: fileType})
    this.recordingName = null
    this.openScreenRecorderPlayer = false;
    this.openAudioRecorderPlayer = false;
  }

  public blobToFile = (theBlob: Blob, fileName:string): File => {    
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
  }

  isReadOnlyField(isLocked) {
    let isTrue = false;
    if(this.isPreview || this.isItemLocked(isLocked) || this.isReadOnly) {
      isTrue = true;
    }
    return isTrue;
  }

  onPaste(event: ClipboardEvent, fileType?, label?,) {
    let clipboardData = event.clipboardData.items[0];
    if (clipboardData.type.indexOf("image") === 0) {
      let file = null;
      file = clipboardData.getAsFile();
      if (!this.leadId) {
        if (file) {
          let multiPrevieSrcArr = [];
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.arrayOfCustomFiled.forEach((element) => {
              if (
                element.type == this.customFieldType.IMAGEFIELD &&
                this.customFieldType.IMAGEFIELD == fileType &&
                element.key == label
              ) {
                element["previewSrc"] = event.target.result;
              }
              if (
                element.type == this.customFieldType.MULTIIMAGE &&
                this.customFieldType.MULTIIMAGE == fileType &&
                element.key == label
              ) {
                let tragetDataSrc = event.target.result;
                multiPrevieSrcArr.push(tragetDataSrc);
                element["previewSrc"] = multiPrevieSrcArr;
              }
            });
          };
          reader.readAsDataURL(file);
          this.imageFileNameList = [];
          this.onUploadFile([file], fileType, label)
        }
      } else {
        this.imageFileNameList = [];
        this.onUploadFile([file], fileType, label)
      }
    }
  }

  addTitleForLeadCreation() {
    let isValidated = this.validateAllFields(this.customFieldObj);
    if(isValidated){
      this.isLeadCreated.emit(isValidated);
    }
    this.onTitleChange.emit(this.editTitle)
  }
  closeModalRefDelete(){
    this.modalRefDelete.hide();
  }

  updateLeadInfo(leadId) {
    if(this.currentLead?.title != this.editTitle && this.editTitle != "") {
    this.httpTransfer
      .updateLeadCorrespondingToLeadId({title:this.editTitle}, this.dashId, leadId)
      .subscribe((res : any) => {
        if (res.status === 200) {          
          this.updateLeadTitle.emit({'leadId':leadId,'bucketId':this.bucketId});
        }
      });
    }
  }
  phoneNumberSelected(data, type?, label?,value?){
    if(type=='MultipleMobile'){
      let copyValue=value
      copyValue?.forEach((ele,i)=>{
        if(ele==null){copyValue?.splice(i,1)}
      })
    }
    label.mobileinput=value || {}
    this.onValueInput(data, type, label.key);
  }

  emailSelected(data, type?, label?,value?){
      if(value==''){label.multiEmailInput=null}
      else{label.multiEmailInput=value}
    this.onValueInput(data, type, label.key);
  }

  getSelectedNumber(item){
    if(item && item.mobile_number){
      return item.mobile_number;
    }else{
      return ""
    }
  }
  isItemLocked(lockflag){
    if(this.publicLeadCustomFieldData){
      return true;
    }
    if(this.dashboardUserRole?.includes("DASHBOARD_ADMIN")){
      return false;
    }else{
      return lockflag;
    }
  }
  countRowsInText(ref){
    if($(ref).css('line-height') != undefined){
      let lineHeight = parseFloat($(ref).css('line-height').split('px')[0])    
      $(ref).attr('rows','0');
      $(ref).css('height','auto');
      var h1 = $(ref)[0].scrollHeight;
      let rows =Math.floor(h1/lineHeight)
      $(ref).attr('rows', rows);
    }
  }

  setItemToBoard(item, leadData, type, key,insertIdIndex=-1) {
    if(item.boardInput === "") {
      item.boardInput = [];
      item.boardInput.push(leadData._id);
    }else if(insertIdIndex>-1 && insertIdIndex<=item?.boardInput?.length){
      item.boardInput.splice(insertIdIndex,0,leadData._id)
    } 
    else if(!item?.boardInput?.includes(leadData._id)){
      item.boardInput.push(leadData._id);
    }    
    this.onValueInput(this.arrayOfCustomFiled, type, key,leadData._id)
  }

  updateItemToBoard(item, leadData, type, key) {  
    item.boardInput=leadData
    console.log(item.boardInput)
    this.onValueInput(this.arrayOfCustomFiled, type, key)
  }

  removeItemFromBoard(item, leadData, type, key) {
      let index = item.boardInput.findIndex(el => el === leadData._id);
      if(index >=0) {
        item.boardInput.splice(index,1);
        this.onValueInput(this.arrayOfCustomFiled, type, key)
      }
  }

  upateRating(data, type, label) {
    if(Array.isArray(data)) {
      const fieldArr = data.filter(field => field.key === label);
      if(fieldArr.length>0 && fieldArr[0].ratingInput!==null) {
        this.onValueInput(data, type, label)
      }
    } 
  }


  pickIp(item, label, key) {
    if(!this.isPreview && !this.isReadOnly){
    this.httpTransfer.getIpAddress().subscribe((res : any) => {
      item.ipInput = res.ip;
      this.onValueInput(this.arrayOfCustomFiled, label, key);
    });
  }
  }

  async openCreateItem(modal,item){
   this.connectedBoardItem = item
    this.connectedBoardId =  item.connected_board_id;
    item['creationtext'] = '';
    this.createQuickItem = await this.commonUtils.createQuickItemOrNot(item?.connected_board_id);
    item['create_item'] = this.createQuickItem;
    let createPermissioninBucket= await this.commonUtils.geteBucketsUserHasCreatePermission(item?.connected_board_id,this.dashboardUserRole)
    if(!this.createQuickItem || !createPermissioninBucket){
      this.connectedBoardQuickAdd=false;
      this.modal.open(modal, {size : 'lg', windowClass: 'copyTemplateLeadItem'})
    }
    setTimeout(()=>{$('.creatConnectedBoardTextarea')?.focus()},10)
  }
  createConnectedItem(item) {
    if(!item?.creationtext?.length){item['create_item']=null;return}
    let buckets = this.dashboardUtils.getDashboardBucketIfPresent(item.connected_board_id);
    let leadObj = {}
    item['create_item']=null
    leadObj["title"] = item?.creationtext;
    leadObj["bucket_id"] = buckets[0]?._id;
    leadObj["priority"] = 1;
    this.httpTransfer
      .createLeadCorrespondingToDashBoard(leadObj, item.connected_board_id)
      .subscribe((res : any) => {
        if (res.status == 200) {
          this.createQuickItem = false
          item.creationtext = ''
          this.connectCreateItemTOCurrentItem(res?.result.lead_id, item)
        }
      })
  }

  connectCreateItemTOCurrentItem(itemId, item?) {        
    let connectedItems = []; 
    if(item && item['boardInput']) {
      connectedItems = item['boardInput'] || [];
    } 
    if(!item && this.currentLead?.custom_fields) {
      connectedItems = this.currentLead?.custom_fields[this.connectedBoardItem?.key] || [];
    }
    connectedItems.push(itemId);
    let payload = {custom_fields: {}};
    if(item) payload.custom_fields[item.key] = connectedItems;
    if(!item) payload.custom_fields[this.connectedBoardItem.key] = connectedItems;
    this.httpTransfer.updateLeadCorrespondingToLeadId(payload,this.currentLead?.dashboard_id,this.currentLead._id).subscribe((res : any) => {
    })
  }

  checkForField(leadInfo,field) {
    if(leadInfo?.custom_fields && leadInfo?.custom_fields.hasOwnProperty(field.key)) {
      return true;
    } else {
      return false;
    }
  }

  addCheckListItem(field) {
    let checkListObj = {};
    if (field.checkListText.trim() != '') {
      checkListObj["summary"] = field.checkListText;
      checkListObj["state"] = "NOT_COMPLETED";
      if(field.checklistInput === "") field.checklistInput = [];
      field.checklistInput.push(checkListObj)
      this.onValueInput(this.arrayOfCustomFiled, this.customFieldType.CHECKLIST, field.key);
      field.checkListText = '';
    }
  }

  updateState(selectedIndex, field) {
    field?.checklistInput.forEach((item, index) => {
      if (selectedIndex == index) {
        if (item.state == "COMPLETED") {
          item["state"] = "NOT_COMPLETED";
        } else {
          item["state"] = "COMPLETED";
        }
      }
    });
    this.onValueInput(this.arrayOfCustomFiled, this.customFieldType.CHECKLIST, field.key)
   
  }

  deleteCheckItem(selectedIndex,field) {
    field?.checklistInput.splice(selectedIndex, 1);
    this.onValueInput(this.arrayOfCustomFiled, this.customFieldType.CHECKLIST, field.key)
   
  }

  editCheckItem(index, field) {
      field['editIndex'] = index;
      field['isEdit'] = true;
  }

  updateCheckListEdit(field) {
    this.onValueInput(this.arrayOfCustomFiled, this.customFieldType.CHECKLIST, field.key);
    field.isEdit = false;
  }
  UpdateLocation(place_id,item){
    if(place_id!=null){
      item['locationInput']={place_id}
    }
    else{
      item['locationInput']=null
    }
    this.onValueInput(this.arrayOfCustomFiled,this.customFieldType.LOCATION,item.key)
  }
  addNewMultiUrl(item){
    item.multiurlInput = ['', ...item.multiurlInput];
    item.enable[0]=true;
    item.adding=true
  }
  removeUrl(item, index){
    this.enableNewButton=true
    item.enableNewButton=true
      item.multiurlInput.splice(index, 1)
      this.onValueInput(this.arrayOfCustomFiled, this.customFieldType.MULTI_URL, item.key)
  }

  trackByFn(index){
      return index;
  }

  validateMultiUrl(item,arrayOfCustomFiled,index){
    this.enableNewButton=true
    item.enableNewButton=true
    let filteredArray=item.multiurlInput.filter(element =>element.trim().length!=0)
    item.multiurlInput=filteredArray
    let val=item.adding ? item.multiurlInput[index] : null
    this.onValueInput(arrayOfCustomFiled, this.customFieldType.MULTI_URL, item.key,false,val)
    item.enable=[]
  }

  multiUrlCall(item){
    this.enableNewButton=true
    item.enableNewButton=true
    item.multiurlInput.forEach(ele=>{
      if(ele.trim().length==0){
        this.enableNewButton=false
        item.enableNewButton=false
      }
    })
  } 
  renameObj:any = {name:''} 
  modalRefRename: BsModalRef; 
  applyRenameMedia(){
    if( this.renameObj?.name?.length < 3) return
    this.renameObj['file']['name'] = this.renameObj.name
    this.onValueInput(this.arrayOfCustomFiled, this.renameObj.type, this.renameObj.key)
    this.modalRefRename?.hide()
    setTimeout(() => {this.renameObj={name:''}}, 500)
  }

  openRenameModal(modalContent, file, item ){
    this.renameObj = {'name': file?.name, 'file': file, 'type': item.type, 'key': item?.key}
    this.modal.open(modalContent, { size: 'sm' ,windowClass: 'renameMediaModal'});
    // this.modalRefRename = this.modalService.show(modalContent, {class:"renameMediaModal" })
  }

  removeExtraSpaces(){
    this.renameObj.name = this.renameObj?.name?.replace(/\s+/g, ' ')
  }

  trackByFnList(item,index){
    return item.key
  }

  emailValidator(emailinput,item){
    item.validateToEmail=emailinput.trim().length? this.commonUtils.ValidateEmail(emailinput.trim()) : true;
  }
  
  downloadImg(url,name,extension) {
    this.commonUtils.downloadImage(url,name,extension);
  }


  handleFocusOut(event,arrayOfCustomFiled,customFieldType,item) {
    const divElement = event.currentTarget; // Get the parent div

    // Check if the event target is not inside the div
    if (!divElement.contains(event.relatedTarget)) {
      // Handle the focusout event, and call onValueInput if needed
      this.onValueInput(arrayOfCustomFiled, customFieldType, item.key);
      
    }
  }

  startTimer(){
    let startTime=new Date().getTime()
    if(!this.timerInterval)
    this.timerInterval=setInterval(() =>{
    this.recordingTimeForVideo = this.commonUtils.timeConvert(new Date().getTime()-startTime)
    },1000)
  }


  getPopOverRef(popRef){
    this.basicUtils.isPopoverOpen(popRef)
  }

  pasteUrlForMediaUpload(modalcontent) {
    this.modal.open(modalcontent, { centered: false, scrollable: true, windowClass: 'add-link-popup drive-popup,modal-position-top' });
  }
  openDrive(modalContent){
    this.modal.open(modalContent, { centered: false, scrollable: true, windowClass: 'add-link-popup drive-popup' });
  }

  uploadUrlLink(obj){
    let multiMediaArr=[];
    let fileJson = {};
    console.log("obj",obj)
    if(obj.type==='link' || obj.type==='google_drive' || obj.type==='one_drive'){
      let params={
        fileType:this.selectedFileType,
        label:this.selectedKeyLabel,
        previousFilePath:null
      }
      this.fileUpload(obj.files,params,true)
    }
    if(obj.type==='from_computer'){
      this.onUploadFile(obj?.files,this.selectedFileType,this.selectedKeyLabel)
    }
}

  setSelectedMediaField(item,type){
    this.selectedFileType = type;
    this.selectedKeyLabel = item?.key;
    this.selectedMediaFieldJson=item;
    console.log("selected fields",this.selectedFileType,this.selectedKeyLabel,this.selectedMediaFieldJson,item)
  }


  isNonHidden(item){
    return  item?.hidden==='NEVER' || item?.hidden==='ON_EMPTY' && (item?.valueData || !item?.valueData && this.showEmptyField) || item.hidden==='ALWAYS' && this.showHiddenFields?.ishidden
  }
  onNonCustomUpdate(key,val){
    if(key=='start_end_date'){
      if(val?.start_date)this.leadObj['start_date']=val?.start_date
      if(val?.end_date)this.leadObj['end_date']=val?.end_date
    }else if(key=='bucket_id'){
      this.leadObj[key]= !this.triggeredFromCreateACopy && this.leadObj && this.leadId ?  val?.destination_bucket_id : val;
    }
    else{
      this.leadObj[key]=!this.triggeredFromCreateACopy && this.leadObj && this.leadId ? val[key] : val;
    }
    this.onNonCustomEdit?.emit(this.leadObj)
  }

  openMergeIemModal(mpodalContent,item) {
    if(item?.type==='google_drive'){this.linkToOpenIframe= this.sanitizer.bypassSecurityTrustResourceUrl(item?.path?item?.path+'&embedded=true':item?.file_path+'&embedded=true')}
    if(item?.type==='link'){this.linkToOpenIframe= this.sanitizer.bypassSecurityTrustResourceUrl(item?.path?item?.path:item?.file_path)}
    console.log("linkToOpenIframe",this.linkToOpenIframe,item)
    this.modal.open(mpodalContent, { size : 'xl'});
  }

  isLinkValid(fileObj: any): boolean {
    if (fileObj?.type === 'link' || fileObj?.type === 'google_drive') {
       const path = fileObj?.file_path;
       if (path?.includes('https://docs.google.com/file') || path?.includes('https://drive.google.com/file')) {
          return true;
       } else {
          return false;
       }
    } else {
       return true;
    }
 }

 getColorFromObject(obj: any[], k): string {
  return obj.find(ele=>ele.k==k)?.c || 'white';
 }

 selectedItem(event,item){
  this.onValueInput(this.arrayOfCustomFiled, item.type, item.key)
 }

 onKeyUp(event: KeyboardEvent,item) {
  const minLength = item['min_length'];
  let bool=this.commonUtils.onKeyBoardPress(event,item)
  const inputElement = event.target as HTMLTextAreaElement;
  if (inputElement.value.length < minLength) {
    this.showMinimumLengthE=true
  }
  if (inputElement.value.length >= minLength) {
    this.showMinimumLengthE=false
  }
    if(bool){
    event.preventDefault()
  }
}

  onkeyPressForNumber(event:KeyboardEvent,item,i){
    console.log("item",item)
    item['error'] = '';
  }

validateInput(events:KeyboardEvent,event,item,i?) {
  const minValue = item?.min_number;
  const maxValue = item?.max_number;
  const maxDecimalPlaces = item?.number_of_decimal;
  item['error'] = '';
  const inputElement = events.target as HTMLTextAreaElement;
  const value = inputElement.value;
  // Clear any previous error message
  item['error'] = '';
   // Check if the input is a valid number
  if ((!(item.numinput<0 || item.numinput>0 || item.numinput==0 || item.numinput==null) || this.numberdfield.invalid) && (this.numberdfield.dirty || this.numberdfield.touched)) {
    item['error'] = `Number is required.`;
    return;
  }
  const numberValue = value==''?'':parseFloat(value);
    if (numberValue < minValue || (minValue>0 && (numberValue=='' && (item.numinput!==null || item.numinput!==0 && item.numinput!=='')))) {
      item['error'] = `Minimum value is ${minValue}.`;
      return;
    }
  if (numberValue > maxValue) {
    item['error'] = `Maximum value is ${maxValue}.`;
    return;
  }
  const decimalPlaces = (value.split('.')[1] || '').length;
  if (maxDecimalPlaces>0 && decimalPlaces && decimalPlaces > maxDecimalPlaces) {
    item['error'] = `Maximum number of decimals are ${maxDecimalPlaces}.`;
    event.preventDefault()
    return;
  }
}

fileChangeEvent(data){
  const json = {}
    json['files'] = data;
    json['type']='from_computer'
    console.log(json)
    this.uploadUrlLink(json)
}

onSaveOfConatct(){
  this.contactTypeArrayCust.forEach(ele=>{
    if(['contact_user_e2NB6Dm3j_url','contact_user_x2uQrF0e3_url','contact_user_TJe6L0Er5_url','contact_user_hp77fmHtX_url','contact_user_9XfYrmFu7_url','contact_user_q8M5zYwj2_url','contact_user_K28bcgPZL_url','contact_user_qTX2Jx0j2_url'].includes(ele.key)){
      this.onValueInput(this.arrayOfCustomFiled,this.customFieldType.URL,ele.key)
    }
  })
}

matchAndReplaceOnlyChangedCustomFieldInContact(newData: any[]) {
  if(this.contactTypeArrayCust.length >= newData.length){
    for (let index = 0; index < this.contactTypeArrayCust.length; index++) {
      if(this.updatedCustomFields.includes(this.contactTypeArrayCust[index].key)){
        let prevElement = Object.assign({}, this.contactTypeArrayCust[index]);
        let newElement = Object.assign({}, newData[index]);
        delete prevElement.uId;
        delete prevElement.summernote;
        delete newElement.uId;
        if(JSON.stringify(newElement) != JSON.stringify(prevElement)){
            this.contactTypeArrayCust[index]= newData[index];
        }
      }
    }
  }else{
    this.contactTypeArrayCust = newData;
  }
}

focusInputOfNumber(i,item){
  item.show_display = false;
  setTimeout(() => {
    const element = document.getElementById(`number_field_${i}`);
    if (element) {
      element.focus();
    }
  },300);
}

  async handledTextAssist(data,item?){
  if(data?.action=='ReplaceText'){
    this.editTitle=data.text
    if(!item)this.updateLeadInfo(this.leadId);
    else{
      // item.textareainput=data.text
      // this.onValueInput(this.arrayOfCustomFiled,item.type, item.key)
    }
  }else if(data.action=='Subtask' || data.action=='Create'){
    let res = await this.commonUtils.getDataFromFilterObjectToCreateLead({dashboard_id:[this.dashId]},this.customFieldsArray,this.bucketArray,this.dashboardUserRole);
    let json=res[0] 
    json['title']=data.text
    if(data.action=='Subtask')json['parent_id']=this.leadId;
    this.httpTransfer.createLeadCorrespondingToDashBoard(json,this.dashId).subscribe((res : any) => {
      if(res.status==200 && this.leadObj && data.action=='Subtask'){
        if(!this.leadObj.child_lead_id)this.leadObj.child_lead_id=[]
        this.leadObj.child_lead_id?.push(res.result?.lead_id)
      }
    });
  }
}

  
}

