import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ConstantService } from "../../Enums/Constant.service";
import { environment } from "../../environments/environment";
import { Day } from "./../../Enums/Day";
import { Role } from "./../../Enums/Role";
import { CustomStorageService } from "./custom-storage.service";
import { DashboardUtilsService } from "./dashboard-utils.service";
import { HttpTransferService } from "./httpTransfer.service";
import { parseISO, addSeconds } from 'date-fns';
import { TranslateService } from "@ngx-translate/core";
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';
import * as moment from "moment";
import { HttpClient } from "@angular/common/http";
import * as he from 'he'; // For ES6 modules
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: "root",
})
export class CommonUtils {
  [x: string]: {};
  checkListState: { NOT_COMPLETED: string; COMPLETED: string };
  userRoles: { //not getting from wss
    ADMIN: string; SALES: string; ACCESS: string; SUPER_ADMIN: string; DASHBOARDADMIN: string;
  };
  oneDayInMilliseconds = 86399999;
  priorityJson = {
    '0': {_id:0, label: 'L', name: 'Low',  color: '#9f9f9f'},
    '1': {_id:1, label: 'M', name: 'Medium', color: '#53b6ee'},
    '2': {_id:2, label: 'H', name: 'High', color: '#f79500'},
    '3': {_id:3, label: 'U', name: 'Urgent', color: '#f50100'},
  }
  dateKeys = ['create_date', 'start_date', 'due_date', 'last_updated']
  arrayKeys = ['starred', 'item_type', 'is_deleted', 'lead_colloborators_users', 'assigned_to', 'assigned_by_user', 'sprint_id', 'survey_form_id', 'tag_id', 'created_by', 'priority']
  SupportVideoExtension=['mp4','webm','ogg','mkv' ,'mov','link']
  SupportAudioExtension=['mp3','webm','ogg','mkv','m4a','wav','link']
  FILE_CHUNK_MAX_SIZE = 20000000;
  colorById:any;


  constructor(
    private httpTransfer: HttpTransferService,
    private constantService: ConstantService,
    public datepipe: DatePipe,
    private customStorageService: CustomStorageService,
    private dashboardUtils:DashboardUtilsService,
    private translate:TranslateService,
    private httpClient: HttpClient
  ) {
    this.checkListState = this.constantService.getChecklistStateConstant();
    this.userRoles = this.constantService.getRole()
    setTimeout(() => {document.getElementById('main-loader')?.remove()},1200);
    this.colorById = this.constantService.getColorById()
  }
  dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  mediaSizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  terminologyOptions=[
    'Items',
    'Leads',
    'Tasks',
    'Clients',
    'Employees',
    'Campaigns',
    'Projects',
    'Budgets',
    'Creatives',
    'Customer',
    'Manual'
  ];


  advanceFilterOperandJson={
    'EQUALS_TO':{display_key:"Is Equal to",key:"EQUALS_TO",identifier:['Bucket','Sprint','Tags','Priority','Text','Textarea','Numeric','Email','Mobile','Radiobutton','Dropdown','Checkbox','MultiUrl','SingleUser','MultiUser','MultiSelect','Rating','Url',"Currency",'IPAddress','Country', 'Boolean','is_deleted','ItemType','isStarred','MobileCode','MobileNumber','CurrencyCode','CurrencyAmount','MainBoard']},
    'NOT_EQUALS_TO':{display_key:"Is Not Equal to",key:"NOT_EQUALS_TO",identifier:['MultiUrl','Bucket','Sprint','Tags','Priority','Text','Textarea','Numeric','Email','Mobile','Radiobutton','Dropdown','Checkbox','SingleUser','MultiUser','MultiSelect','Rating','Url',"Currency",'IPAddress','Country','is_deleted','ItemType','isStarred','MobileCode','MobileNumber','CurrencyCode','CurrencyAmount','MainBoard']},
    'IS_ONE_OF':{display_key:"Is One Of",key:"IS_ONE_OF",identifier:['Bucket','Sprint','Tags','Priority','Radiobutton','Dropdown','Checkbox','MultiUser','MultiSelect','SingleUser','Country','Board','is_deleted','ItemType','isStarred','MobileCode','CurrencyCode','lead','MainBoard']},
    'IS_NOT_ONE_OF':{display_key:"Is Not One OF",key:"IS_NOT_ONE_OF",identifier:['Bucket','Sprint','Tags','Priority','Radiobutton','Dropdown','Checkbox','MultiUser','MultiSelect','SingleUser','Country','Board','is_deleted','ItemType','isStarred','MobileCode','CurrencyCode','lead','MainBoard']},
    'CONTAINS':{display_key:"Contains",key:"CONTAINS",identifier:['Text','Textarea','Email','MultiUrl','Mobile','Url','IPAddress']},
    'DOES_NOT_CONTAIN':{display_key:"Does Not Contains",key:"DOES_NOT_CONTAIN",identifier:['Text','MultiUrl','Textarea','Email','Mobile','Url','IPAddress']},
    'IS_EMPTY':{display_key:"Is Empty",key:"IS_EMPTY",identifier:['Sprint','Tags','Priority','Text','MultiUrl','Textarea','Numeric','Email','Mobile','Date','DateTime','Radiobutton','Dropdown','Checkbox','SingleUser','MultiUser','MultiSelect','Rating','Url',"Currency",'IPAddress','Country','Board','external_collaborator_user_ids','SingleImage','MultipleImage','SingleVideo','MultipleVideo','SingleAudio','MultipleAudio','SingleFile','MultipleFile','Location','CheckList','MobileCode','MobileNumber','CurrencyCode','CurrencyAmount','ExternalCollaborator']},
    'IS_NOT_EMPTY':{display_key:"Is Not Empty",key:"IS_NOT_EMPTY",identifier:['Bucket','Sprint','MultiUrl','Tags','Priority','Text','Textarea','Numeric','Email','Mobile','Date','DateTime','Radiobutton','Dropdown','Checkbox','SingleUser','MultiUser','MultiSelect','Rating','Url',"Currency",'IPAddress','Country','Board','external_collaborator_user_ids','SingleImage','MultipleImage','SingleVideo','MultipleVideo','SingleAudio','MultipleAudio','SingleFile','MultipleFile','Location','CheckList','MobileCode','MobileNumber','CurrencyCode','CurrencyAmount','ExternalCollaborator']},
    'IS_GREATER_THAN':{display_key:"Is Greater Than",key:"IS_GREATER_THAN",identifier:['Numeric','Date','Rating','DateTime','CurrencyAmount']}, 
    'IS_LESS_THAN':{display_key:"Is Less Than",key:"IS_LESS_THAN",identifier:['Numeric','Date','Rating','DateTime','CurrencyAmount']}, 
    'IS_GREATER_THAN_OR_EQUALS_TO':{display_key:"Is Greater Than or Equal to",key:"IS_GREATER_THAN_OR_EQUALS_TO",identifier:['Numeric','Date','Rating','DateTime','CurrencyAmount']}, 
    'IS_LESS_THAN_OR_EQUALS_TO':{display_key:"Is Less Than or Equal to",key:"IS_LESS_THAN_OR_EQUALS_TO",identifier:['Numeric','Date','Rating','DateTime','CurrencyAmount']}, 
    'RANGE':{display_key:"Between",key:"RANGE",identifier:['Numeric','Date','Rating','DateTime','CurrencyAmount']}, 
    // 'TRUE':{display_key:"Is Set",key:"TRUE",identifier:['Boolean',]}, 
    // 'FALSE':{display_key:"Is Not Set",key:"FALSE",identifier:['Boolean',]}, 

  }

  getNonCustomFields(havePhase?){
    let obj={
      "title":{display_key:"Title",key:"title",type:"Text",referer_type:"Text"},
      "bucket_id":{display_key:"Bucket",key:"bucket_id",type:"Bucket",referer_type:"Bucket"},
      "tag_id":{display_key:"Tag",key:"tag_id",type:"Tags",referer_type:"Tags"},
      "priority":{display_key:"Priority",key:"priority",type:"Priority",referer_type:"Priority"},
      "start_date":{display_key:"Start Date",key:"start_date",type:"Date",referer_type:"Date"},
      "end_date":{display_key:"End Date",key:"end_date",type:"Date",referer_type:"Date"},
      "assigned_to":{display_key:"Assignee",key:"assigned_to",type:"SingleUser",referer_type:"SingleUser"},
      "created_by":{display_key:"Created By",key:"created_by",type:"SingleUser",referer_type:"SingleUser"},
      // "updated_by":{display_key:"Updated By",key:"updated_by",type:"SingleUser"},
      "lead_colloborators_users":{display_key:"Collaborator",key:"lead_colloborators_users",type:"MultiUser",referer_type:"MultiUser"},
      "update_date":{display_key:"Last Updated",key:"update_date",type:"Date",referer_type:"Date"},
      "create_date":{display_key:"Created Time",key:"create_date",type:"DateTime",referer_type:"DateTime"},
      "_id":{display_key:"Item of Board",key:"_id",type:"lead",referer_type:"lead"},
    }

    if(havePhase){
      obj['sprint_id']={display_key:"Phase",key:"sprint_id",type:"Sprint",referer_type:"Sprint"}
    }
    return obj;
  }

  getBooleanTypeFields(){
    let obj={
      "is_temp_deleted":{display_key:"Deleted Items",key:"is_temp_deleted",type:"Boolean",referer_type:"Boolean"},
      "starred":{display_key:"Starred",key:"starred",type:"Boolean",referer_type:"Boolean"},
      "aging_time":{display_key:"Deadline Overdue",key:"aging_time",type:"Boolean",referer_type:"Boolean"},
      "item_type":{display_key:"Item Type",key:"item_type",type:"ItemType",referer_type:"ItemType"},
      "external_collaborator_user_ids":{display_key:"Have External Collaborators",key:"external_collaborator_user_ids",type:"ExternalCollaborator",referer_type:"ExternalCollaborator"},
    }
    return obj;
  }

  async getConnectedBoardJson(customArr){
    let connectedBoardIds = [];
    let connectedDashboardJson
      for(let i=0; i<customArr.length; i++) {
       if(['Mirror'].includes(customArr[i].type)) {
          if(customArr[i]?.mirror_dashboard_id) {
            connectedBoardIds.push(customArr[i]?.mirror_dashboard_id);
          }
        }
      }
      connectedBoardIds = [... new Set(connectedBoardIds)];
      connectedDashboardJson = await this.dashboardUtils.getDashboardCustomForms(connectedBoardIds);
      return connectedDashboardJson
  }

  async makeCustomArrForAdvancedFilter(customArr,jsonlistForAdvanceFilter){
    let connectedDashboardJson =await this.getConnectedBoardJson(customArr)
    customArr=JSON.parse(JSON.stringify(customArr))
    for(let ele of customArr) {
      ele['referer_type']=ele.type
      if(['Mobile','MultipleMobile'].includes(ele.type)  || (ele.type=="Mirror" && ele.mirror_column_type=="Mobile")){
        let field1=JSON.parse(JSON.stringify(ele))
        let field2=JSON.parse(JSON.stringify(ele))
        field1.key='custom_fields.'+field1.key+'.country_code'
        field1.display_key=field1.display_key+' Country'
        field1.type='MobileCode'
        field2.key='custom_fields.'+field2.key+'.mobile_number'
        field2.display_key=field2.display_key+' Number'
        field2.type='MobileNumber'
        if (!jsonlistForAdvanceFilter[field1.key]) {
          jsonlistForAdvanceFilter[field1.key] = field1;
        }  
        if (!jsonlistForAdvanceFilter[field2.key]) {
          jsonlistForAdvanceFilter[field2.key] = field2;
        }  
      }
      else if(ele.type=="Currency" || (ele.type=="Mirror" && ele.mirror_column_type=="Currency")){
        let field1=JSON.parse(JSON.stringify(ele))
        let field2=JSON.parse(JSON.stringify(ele))
        field1.key='custom_fields.'+field1.key+'.currency'
        field1.display_key=field1.display_key+' Currency Code'
        field1.type='CurrencyCode'
        field2.key='custom_fields.'+field2.key+'.amount'
        field2.display_key=field2.display_key+' Amount'
        field2.type='CurrencyAmount'
        if (!jsonlistForAdvanceFilter[field1.key]) {
          jsonlistForAdvanceFilter[field1.key] = field1;
        }  
        if (!jsonlistForAdvanceFilter[field2.key]) {
          jsonlistForAdvanceFilter[field2.key] = field2;
        }  
      }
      else if(ele.type=="Mirror"){
        if(ele.mirror_column_type=='Radiobutton' || ele.mirror_column_type=='Dropdown' || ele.mirror_column_type=='Checkbox' || ele.mirror_column_type=='MultiSelect' ){
          let connectedBoardFields=connectedDashboardJson[ele.connected_board_id]['CUSTOM_FORM']
          let matchedField=connectedBoardFields.filter(field=> field.key==ele.mirror_column_key)
          ele['permissible_values']=matchedField[0].permissible_values
        }
        ele.key='custom_fields.'+ele.key
        const key = ele.key;   
        if (!jsonlistForAdvanceFilter[key]) {
          jsonlistForAdvanceFilter[key] = ele;
        }        
      }
      else if(ele.type!="Button" && ele.type!="TimeTracker" && ele.type!="MultiUrl"){
        ele.key='custom_fields.'+ele.key
        const key = ele.key;   
        if (!jsonlistForAdvanceFilter[key]) {
          jsonlistForAdvanceFilter[key] = ele;
        }        
      }
    }
    return jsonlistForAdvanceFilter
  }

  getOperatorForFilterField(){
   let obj={
    "Bucket": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Bucket")),
    "Sprint": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Sprint")),
    "Tags": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Tags")),
    "Priority": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Priority")),
    "Text": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Text")),
    "Textarea": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Textarea")),
    "Numeric": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Numeric")),
    "Email": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Email")),
    "MobileCode": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("MobileCode")),
    "MobileNumber": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("MobileNumber")),
    "Date": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Date")),
    "Currency": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Currency")),
    "SingleFile": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("SingleFile")),
    "SingleImage": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("SingleImage")),
    "SingleVideo": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("SingleVideo")),
    "SingleAudio": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("SingleAudio")),
    "MultipleFile": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("MultipleFile")),
    "MultipleImage": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("MultipleImage")),
    "MultipleVideo": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("MultipleVideo")),
    "MultipleAudio": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("MultipleAudio")),
    "Radiobutton": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Radiobutton")),
    "Dropdown": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Dropdown")),
    "Checkbox": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Checkbox")),
    "MultiSelect": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("MultiSelect")),
    "Rating": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Rating")),
    "Url": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Url")),
    "IPAddress": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("IPAddress")),
    "SingleUser": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("SingleUser")),
    "MultiUser": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("MultiUser")),
    "Country": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Country")),
    "CurrencyCode": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("CurrencyCode")),
    "CurrencyAmount": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("CurrencyAmount")),
    "Board": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Board")),
    "MainBoard": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("MainBoard")),
    "DateTime": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("DateTime")),
    "Location": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Location")),
    "MultiUrl": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("MultiUrl")),
    "CheckList": Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("CheckList")),
    "Boolean" : Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("Boolean")),
    "MultipleEmail" : Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("MultipleEmail")),
    "MultipleMobile" : Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("MultipleMobile")),
    "ItemType" : Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("ItemType")),
    "ExternalCollaborator" : Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("ExternalCollaborator")),
    "lead" : Object.values(this.advanceFilterOperandJson).filter(e=>e.identifier.includes("lead")),
  }
  return obj
  }

  getRelativeDateTemplates(){
    let obj={
      '{{TODAY}}':{'display_key':"Today", 'key':'{{TODAY}}', 'value':{"relative_date":{ "day": 0,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}},
      '{{YESTERDAY}}':{'display_key':"Yesterday", 'key':'{{YESTERDAY}}', 'value':{"relative_date":{ "day": -1,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}},
      '{{DAY_BEFORE_YESTERDAY}}':{'display_key':"Day Before Yesterday", 'key':'{{DAY_BEFORE_YESTERDAY}}', 'value':{"relative_date":{ "day": -2,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}},
      '{{TOMMORROW}}':{'display_key':"Tommorrow", 'key':'{{TOMMORROW}}', 'value':{"relative_date":{ "day": 1,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}},
      '{{DAY_AFTER_TOMMORROW}}':{'display_key':"Day After Tommorrow", 'key':'{{DAY_AFTER_TOMMORROW}}', 'value':{"relative_date":{ "day": 2,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}},
      '{{FIRST_DAY_OF_MONTH}}':{'display_key':"First Day of Month", 'key':'{{FIRST_DAY_OF_MONTH}}', 'value':{"relative_date":{ "day": 0,"month": 0,"year": 0}, "day":{ "value":1}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}},
      '{{LAST_DAY_OF_MONTH}}':{'display_key':"Last Day of Month", 'key':'{{LAST_DAY_OF_MONTH}}', 'value':{"relative_date":{ "day": 0,"month": 0,"year": 0}, "day":{ "value":31}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}},
      '{{FIRST_DAY_OF_LAST_MONTH}}':{'display_key':"First Day of Last Month", 'key':'{{FIRST_DAY_OF_LAST_MONTH}}', 'value':{"relative_date":{ "day": 0,"month": -1,"year": 0}, "day":{ "value":1}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}},
      '{{LAST_DAY_OF_LAST_MONTH}}':{'display_key':"Last Day of Last Month", 'key':'{{LAST_DAY_OF_LAST_MONTH}}', 'value':{"relative_date":{ "day": 0,"month": -1,"year": 0}, "day":{ "value":31}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}},
    }
    return obj
  }


  getFiscalDateTemplates(){
    let obj={
      '{{MONTH}}':{'display_key':"This Month", 'key':'{{MONTH}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":false, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 1 }, "eval_period": {"relative_period": 0}}},
      '{{LAST_MONTH}}':{'display_key':"Last Month", 'key':'{{LAST_MONTH}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":false, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 1 }, "eval_period": {"relative_period": -1}}},
      '{{NEXT_MONTH}}':{'display_key':"Next Month", 'key':'{{NEXT_MONTH}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":false, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 1 }, "eval_period": {"relative_period": 1}}},
      '{{QUARTER}}':{'display_key':"This Quarter", 'key':'{{QUARTER}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":false, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 3 }, "eval_period": {"relative_period": 0}}},
      '{{LAST_QUARTER}}':{'display_key':"Last Quarter", 'key':'{{LAST_QUARTER}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":false, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 3 }, "eval_period": {"relative_period": -1}}},
      '{{NEXT_QUARTER}}':{'display_key':"Next Quarter", 'key':'{{NEXT_QUARTER}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":false, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 3 }, "eval_period": {"relative_period": 1}}},
      '{{YEAR}}':{'display_key':"This Year", 'key':'{{YEAR}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":false, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 12 }, "eval_period": {"relative_period": 0}}},
      '{{LAST_YEAR}}':{'display_key':"Last Year", 'key':'{{LAST_YEAR}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":false, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 12 }, "eval_period": {"relative_period": -1}}},
      '{{NEXT_YEAR}}':{'display_key':"Next Year", 'key':'{{NEXT_YEAR}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":false, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 12 }, "eval_period": {"relative_period": 1}}},
      '{{MONTH_TO_DATE}}':{'display_key':"This Month to Date", 'key':'{{MONTH_TO_DATE}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":true, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 1 }, "eval_period": {"relative_period": 0}}},
      '{{QUARTER_TO_DATE}}':{'display_key':"This Quarter to Date", 'key':'{{QUARTER_TO_DATE}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":true, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 3 }, "eval_period": {"relative_period": 0}}},
      '{{YEAR_TO_DATE}}':{'display_key':"This Year to Date", 'key':'{{YEAR_TO_DATE}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":true, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 12 }, "eval_period": {"relative_period": 0}}},
      '{{LAST_MONTH_TO_DATE}}':{'display_key':"Last Month to Date", 'key':'{{LAST_MONTH_TO_DATE}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":true, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 1 }, "eval_period": {"relative_period": -1}}},
      '{{LAST_QUARTER_TO_DATE}}':{'display_key':"Last Quarter to Date", 'key':'{{LAST_QUARTER_TO_DATE}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":true, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 3 }, "eval_period": {"relative_period": -1}}},
      '{{LAST_YEAR_TO_DATE}}':{'display_key':"Last Year to Date", 'key':'{{LAST_YEAR_TO_DATE}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":true, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 12 }, "eval_period": {"relative_period": -1}}},
      '{{FIS_QUARTER_TO_DATE}}':{'display_key':"Fiscal Quarter to Date", 'key':'{{FIS_QUARTER_TO_DATE}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":true, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 3 }, "eval_period": {"relative_period": 0}}},
      '{{FIS_YEAR_TO_DATE}}':{'display_key':"Fiscal Year to Date", 'key':'{{FIS_YEAR_TO_DATE}}','type':'DIRECT', 'value':{ "cyclic": true,"equivalent_period_till_today":true, "fiscal_year": {"start_day": 1,"start_month": 1 },"filter_cycle": { "month": 12 }, "eval_period": {"relative_period": 0}}},

       '{{PREV_ROLLING_WEEK}}':{'display_key':"Previous Rolling Week", 'key':'{{PREV_ROLLING_WEEK}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": -7,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": -1,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
       '{{PREV_ROLLING_MONTH}}':{'display_key':"Previous Rolling Month", 'key':'{{PREV_ROLLING_MONTH}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 0,"month": -1,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": -1,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
       '{{PREV_ROLLING_QUARTER}}':{'display_key':"Previous Rolling Quarter", 'key':'{{PREV_ROLLING_QUARTER}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 0,"month": -3,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": -1,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
       '{{PREV_ROLLING_YEAR}}':{'display_key':"Previous Rolling Year", 'key':'{{PREV_ROLLING_YEAR}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 0,"month": 0,"year": -1}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": -1,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
       '{{NEXT_ROLLING_WEEK}}':{'display_key':"Next Rolling Week", 'key':'{{NEXT_ROLLING_WEEK}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 1,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": 7,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
       '{{NEXT_ROLLING_MONTH}}':{'display_key':"Next Rolling Month", 'key':'{{NEXT_ROLLING_MONTH}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 0,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": 0,"month": 1,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
       '{{NEXT_ROLLING_QUARTER}}':{'display_key':"Next Rolling Quarter", 'key':'{{NEXT_ROLLING_QUARTER}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 0,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": 0,"month": 3,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
       '{{NEXT_ROLLING_YEAR}}':{'display_key':"Next Rolling Year", 'key':'{{NEXT_ROLLING_YEAR}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 0,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": 0,"month": 0,"year": 1}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
       '{{RYLME}}':{'display_key':"Rolling Year to Last Month End", 'key':'{{RYLME}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 0,"month": -12,"year": 0}, "day":{ "value":1}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": 0,"month": -1,"year": 0}, "day":{ "value":31}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
       '{{RQLME}}':{'display_key':"Rolling Quarter to Last Month End", 'key':'{{RQLME}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 0,"month": -3,"year": 0}, "day":{ "value":1}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": 0,"month": -1,"year": 0}, "day":{ "value":31}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
       '{{YLME}}':{'display_key':"Year to Last Month End", 'key':'{{YLME}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 0,"month": 0,"year": 0}, "day":{ "value":1}, "month": {"value": 1}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": 0,"month": -1,"year": 0}, "day":{ "value":31}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
      //  '{{YLQE}}':{'display_key':"Year to Last Quarter End", 'key':'{{YLQE}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 0,"month": 0,"year": 0}, "day":{ "value":1}, "month": {"value": 1}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": 0,"month": -1,"year": 0}, "day":{ "value":31}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
      '{{FYLME}}':{'display_key':"Fiscal Year to Last Month End", 'key':'{{FYLME}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 0,"month": 0,"year": 0}, "day":{ "value":1}, "month": {"value": 1}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": 0,"month": -1,"year": 0}, "day":{ "value":31}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
      //  '{{FYLQE}}':{'display_key':"Fiscal Year to Last Quarter End", 'key':'{{FYLQE}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": 0,"month": 0,"year": 0}, "day":{ "value":1}, "month": {"value": 1}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": 0,"month": -1,"year": 0}, "day":{ "value":31}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},
      '{{SAME_LAST_YEAR}}':{'display_key':"Same Day Last Year", 'key':'{{SAME_LAST_YEAR}}','type':'CALCULATE', 'value':{"date":{ "from":{"relative_date":{ "day": -365,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0} },"to":{"relative_date":{ "day": 0,"month": 0,"year": 0}, "day":{ "value":"{{relative_day}}"}, "month": {"value": "{{relative_month}}"}, "year": {"value": "{{relative_year}}"},"weekday_adjust": {"value": 0}}}}},

    }
    return obj
  }


  getDateTemplates(relativeDateTemplates,fiscalDateTemplates){
    let dateOptionsForSingleDate=[{display_key:'Custom Date',key:'CUSTOM'},{display_key:'Evaluated Date',key:'EVAL'}]
    let dateOptionsForRangeDate=[{display_key:'Custom Date',key:'CUSTOM'},{display_key:'Fiscal Date',key:'EVAL'}]
    let directFiscaldateTempList=[]
    let jsonOfrelativeDateTemplates = Object.values(relativeDateTemplates)
    for (let i = 0; i < jsonOfrelativeDateTemplates.length; i++) {
      dateOptionsForSingleDate.push({ display_key: jsonOfrelativeDateTemplates[i]['display_key'], key: jsonOfrelativeDateTemplates[i]['key'] })
    }

    for (let temp in fiscalDateTemplates) {
      if (fiscalDateTemplates.hasOwnProperty(temp) && fiscalDateTemplates[temp]?.type === 'DIRECT') {
        directFiscaldateTempList.push(temp)
      }
    }
    let jsonOfFiscalDateTemplates=Object.values( fiscalDateTemplates)
    for(let i=0;i<jsonOfFiscalDateTemplates.length;i++) {
      dateOptionsForRangeDate.push({display_key:jsonOfFiscalDateTemplates[i]['display_key'],key:jsonOfFiscalDateTemplates[i]['key']})
    }

    return {
      dateOptionsForSingleDate : dateOptionsForSingleDate,
      dateOptionsForRangeDate :dateOptionsForRangeDate,
      directFiscaldateTempList : directFiscaldateTempList
    }
  }

  arrayTypeCustomFields = ['Checkbox', 'Dropdown', 'MultiSelect', 'Board']

  capitalizeFirstLetter(data) {
    data = data.charAt(0).toUpperCase() + data.slice(1);
    return data;
  }


  sortingUsersInAlphabeticalOrder(res) {
    var usersOfDash = [];
    var dashUserNameList = [];
    for (var j = 0; j < res.length; ++j) {
      dashUserNameList.push(res[j].email);
    }
    var sortedDashUserNameList = dashUserNameList.sort();
    for (var k = 0; k < sortedDashUserNameList.length; ++k) {
      for (var i = 0; i < res.length; ++i) {
        if (sortedDashUserNameList[k] == res[i].email) {
          var dashUser = {};
          let color = this.getColor(res[i].name?.charAt(0).toUpperCase())
          dashUser["name"] = res[i].name;
          dashUser["id"] = res[i]._id;
          dashUser["_id"] = res[i]._id;
          dashUser["email"] = res[i].email;
          dashUser["input"] = false;
          dashUser["role"] = res[i].role;
          dashUser["initials"] = res[i].name?.match(/\b(\w)/g)?.join('').toUpperCase().slice(0,2);
          dashUser["initialColor"] = color;
          usersOfDash.push(dashUser);
        }
      }
    }
    return usersOfDash;
  }

  getColor(initialChar) {
    if(initialChar==='A') {
      return '#5EC394';
    }  else if(initialChar==='B') {
      return '#ff544c';
    } else if(initialChar==='P') {
      return '#0052cc';
    } else if(initialChar==='S') {
      return '#707070';
    } else if(initialChar==='V') {
      return '#e7971e';
    } else if(initialChar==='R') {
      return '#2e4765';
    } else{
      return '#fc035e';
    }
  }


  createNewLeadJsonFromWss(element,bucketArray?){
    let newObj = {}
      newObj["seq_id"] = element.seq_id,
      newObj["_id"] = element._id,
      newObj["comment_count"] = element.comment_count,
      newObj["title"] = element.title,
      newObj["aging_time"] = null,
      newObj["custom_fields"] = element.custom_fields,
      newObj["assigned_to"] = element.assigned_to,
      newObj["bucket_id"] = element.bucket_id,
      newObj["lead_colloborators_users"] = element.lead_colloborators_users,
      newObj["created_by"] = element.created_by,
      newObj["create_date"] = element.create_date,
      newObj["update_date"] = element.update_date,
      newObj["lead_bucket_time"] = element.lead_bucket_time,
      newObj["priority"] = element.priority.toString(),
      newObj["tag_id"] = element.tag_id,
      newObj["sprint_id"] = element.sprint_id != undefined ? element.sprint_id : null ,
      newObj["original_item"] = element.original_item, 
      newObj["duplicate_details"] = element.duplicate_details,
      newObj["dependencies"] = element.dependencies,
      newObj["end_date"] = element.end_date,
      newObj["start_date"] = element.start_date,
      newObj["unread_tagged_count"] = element.unread_tagged_count,
      newObj["starred"] = element.starred,
      newObj["is_deleted"] = element.is_temp_deleted, 
      newObj["index"] = element.index,
      bucketArray.forEach(element => {
        if(element._id == newObj["bucket_id"]) {
          newObj['bucket_name'] = element.name;
        }
      });
      if(element.start_date!='' && element.start_date?.time>0){
        newObj["startDateLabel"] = this.getHumanReadableDateAndTimeFromDate(new Date(element.start_date?.time))['date'] 
      }
      else{
        newObj["startDateLabel"] =''
      }
    //  this.getAgingTimeForHeatMap(element.start_date)["date"];
    if(element.end_date!='' && element.end_date?.time>0){
      newObj["endDateLabel"] = this.getHumanReadableDateAndTimeFromDate(new Date(element.end_date?.time))['date']
    }
    else{
      newObj["endDateLabel"] = ''
    }
      // newObj["startDateLabel"] = this.datepipe.transform(this.epochToDate(element.start_date), 'dd-MMM');
      // newObj["endDateLabel"] = this.datepipe.transform(this.epochToDate(element.end_date), 'dd-MMM');
      // newObj["date"] = "60ba101238dac910682a016c", //not getting from wss
      newObj["tagsLength"] = element?.tag_id?.length
      return newObj;
  }

  addZeroes(num) {
    const dec = num.split('.')[1]
    const len = dec && dec.length > 2 ? dec.length : 2
    return Number(num).toFixed(len)
  }

  ValidateEmail(mail) {
    var mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (mail.match(mailformat)) {
        return true
    }else{
      return false
    }
  }

  isUrlValid(userInput) {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return false;
    else
        return true;
}


isObjectsDeepEqual(obj1:any, obj2:any) {
  // Check if the objects are the same reference
  if (obj1 === obj2) {
    return true;
  }

  // Check if both objects are of type 'object' and not null
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  // Get the keys of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Recursively compare each key and its corresponding value
  for (let key of keys1) {
    if (!keys2.includes(key) || !this.isObjectsDeepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  // The objects are equal
  return true;
}

  bytes_to_size(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
    return Math.round(bytes / Math.pow(1024, i) * 100) / 100 + ' ' + sizes[i];
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve(base64data);
      }
    });
  }
  
  dataURLtoFile(dataurl, filename) { 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }    
    return new File([u8arr], filename, {type:mime});
  }

   creatingJsonStructureOfLeadForTableView(data, arrayOfCustomFiled?, selectedHeadersItems?, callbackFunctionForConnectedItems = null) {
    var eachLeadObj = {};
    eachLeadObj = data;
    eachLeadObj['seq_id_text']="#"+(data?.item_type=='ACTIVITY_COMMENT' ? 'A' : data['seq_id'])
    eachLeadObj['bucket_name'] = this.dashboardUtils.getDashboardBucketIfPresent(data.dashboard_id)?.find(bucket => bucket._id == data?.bucket_id )?.name
    eachLeadObj['bucket_color'] = this.dashboardUtils.getDashboardBucketIfPresent(data.dashboard_id)?.find(bucket => bucket._id == data?.bucket_id )?.color
    eachLeadObj["priority"] = data?.priority?.toString();
    eachLeadObj["aging_time_in_seconds"] = 0;
    if (data?.aging_time) {
      eachLeadObj["aging_time"] = data?.aging_time;
      eachLeadObj["is_aging_time"] = true;
      eachLeadObj[
        "aging_time_in_seconds"
      ] = this.getTimeDiffrenceInMilliSeconds(data?.aging_time);
    } else {
      eachLeadObj["is_aging_time"] = false;
    }
    eachLeadObj["iscreated"] = true;
    eachLeadObj["sprint_name"] = this.dashboardUtils.getDashboardSprintIfPresent(data.dashboard_id)?.find(sprint => sprint._id == data?.sprint_id )?.name
    let tags=this.dashboardUtils.getDashboardTagIfPresent(data?.dashboard_id) ||  [];
    eachLeadObj["tagsName"] = data?.tag_id?.length ? data?.tag_id?.map(tagId=>tags[tagId]?.name) : []
    eachLeadObj["createdate"] = this.epochToDate(data.create_date);
    if(data.starred?.length>0) {
      data.starred.forEach(element => {
        if(element.user_id == this.getLoggedInUserId()) {
          eachLeadObj["isStarred"] = true;
        }
      });
    }
      if(data?.start_date?.date) eachLeadObj['start_date']["dateLabel"] = this.getDisplayDate(data?.start_date)['date']
      if(data?.start_date?.time) eachLeadObj['start_date']["timeLabel"] = this.getDisplayDate(data?.start_date)["time"];
      if(data?.end_date?.date) eachLeadObj['end_date']["dateLabel"] =this.getDisplayDate(data?.end_date)['date'];
      if(data?.end_date?.time) eachLeadObj['end_date']["timeLabel"] = this.getDisplayDate(data?.end_date)["time"];
    eachLeadObj["diff"] = (new Date().getTime() - data.update_date )/1000;
    eachLeadObj["copyLinkPopUp"] = false;
    eachLeadObj["tagsPopup"] = false;
    if('checklist' in eachLeadObj) delete eachLeadObj['checklist'];
    if (data["dependencies"] && data["dependencies"]["depends_on"]) {
      eachLeadObj["dependsOnCount"] = data["dependencies"]["depends_on"].length;
    }
    if (data["dependencies"] && data["dependencies"]["dependents"]) {
      eachLeadObj["dependentsCount"] =
        data["dependencies"]["dependents"].length;
    }
    eachLeadObj["isAgingTimeOpen"] = false;
    if(data.unread_tagged_count?.length>0) {
      for(let i = 0; i < data.unread_tagged_count.length; i++) {
        if(data.unread_tagged_count[i]['user_id'] == this.getLoggedInUserId()) {
          eachLeadObj["tagged_count"] = data.unread_tagged_count[i]['count'];
        }
      }
    }
    eachLeadObj["tempObjCustom"] = {};
    if(arrayOfCustomFiled && selectedHeadersItems?.length>0) {
      for (let i = 0; i < arrayOfCustomFiled.length; i++) {
        if (
          !selectedHeadersItems.filter(
            (x) => x.itemName == arrayOfCustomFiled[i].display_key
          )["isHide"]
        ) {
          if (data.custom_fields != undefined) {
            eachLeadObj["tempObjCustom"][arrayOfCustomFiled[i].display_key] =
              data.custom_fields[arrayOfCustomFiled[i].key];
          }
        }
      }
    }
    eachLeadObj=this.calcuateCommentCount(eachLeadObj,data);
    if(arrayOfCustomFiled) this.getConnectedBoardItemsAndQuery(eachLeadObj,arrayOfCustomFiled, callbackFunctionForConnectedItems)
    return eachLeadObj;
  }

  calcuateCommentCount(eachLeadObj,data){
    eachLeadObj["readCommentDetails"]=data?.read_comment_details ? data?.read_comment_details[this.customStorageService.getItem('id')] || {} : {}
    eachLeadObj["unreadCommentDetails"]={}
    Object.keys(data?.comment_details || {}).forEach(key=>{
      eachLeadObj["unreadCommentDetails"][key]={unreadCount:data?.comment_details[key]?.count-eachLeadObj["readCommentDetails"][key.toUpperCase()]?.read_count || 0} 
      eachLeadObj['totalCommentCount']=data?.comment_details[key]?.count
      eachLeadObj['totalReadCommentCount']=eachLeadObj["readCommentDetails"][key]?.read_count ? eachLeadObj["readCommentDetails"][key]?.read_count : eachLeadObj["readCommentDetails"][key.toUpperCase()]?.read_count ? eachLeadObj["readCommentDetails"][key.toUpperCase()]?.read_count : 0 //eachLeadObj["readCommentDetails"][key.toUpperCase()]?.read_count || 0
       })
   /*  eachLeadObj['totalCommentCount']=Object.values(data?.comment_details || {})?.reduce((sum,comment)=>sum+comment['count'],0)
    eachLeadObj['totalReadCommentCount']=Object.values(eachLeadObj['readCommentDetails'] || {})?.reduce((sum,comment)=>sum+comment['read_count'],0)
  */   return eachLeadObj;
  }



  async getConnectedBoardItemsAndQuery(currentLead,arrayOfCustomFiled, callbackFunctionForConnectedItems) {
    for (let i = 0; i < arrayOfCustomFiled.length; i++) {
      if(['Board','MultipleBoard'].includes(arrayOfCustomFiled[i].type)) {
        const key = arrayOfCustomFiled[i]?.key
        if (currentLead.custom_fields && currentLead?.custom_fields[key] && currentLead?.custom_fields[key].length>0) {
          var connectedItemIds = [];
          if(currentLead?.custom_fields[key])connectedItemIds = currentLead?.custom_fields[key];
          if(callbackFunctionForConnectedItems!=null){
            callbackFunctionForConnectedItems(connectedItemIds, currentLead?._id)
          }
        }
    }
    if(arrayOfCustomFiled[i].type === this.constantService.constant.CUSTOMTYPES.MIRROR) {
      const key = arrayOfCustomFiled[i]?.key
      if (currentLead.custom_fields && currentLead?.custom_fields[key] && currentLead?.custom_fields[key].mirror_item_id?.length>0) {
        var connectedItemIds = [];
        if(currentLead?.custom_fields[key].mirror_item_id)connectedItemIds = currentLead?.custom_fields[key].mirror_item_id;
        if(callbackFunctionForConnectedItems!=null){
          callbackFunctionForConnectedItems(connectedItemIds, currentLead?._id)
        }
      }
  }
  }
}

  async queryConnectedBoardItems(connectedItemsIdsArr,customData,callbackFunctionForConnectedItemJson) {
    if(connectedItemsIdsArr.length == 0) return;
    let json={lead_id: connectedItemsIdsArr}
    let dashIds=customData?.reduce((list,data)=>{if(['Board','MultipleBoard'].includes(data?.type) && data.connected_board_id)list.push(data.connected_board_id);return list},[]) || []
    if(dashIds?.length>0)json['dashboard_id']=dashIds
    let res = await this.httpTransfer.getLeadQuery(json).toPromise()
      if (res.status === 200) {
        var mirrorItems = res.result.leadResponse || [];
        for(let i=0; i<mirrorItems.length; i++) {
          callbackFunctionForConnectedItemJson(mirrorItems[i])
        }
      }
  }

  getTimeDiffrenceInMilliSeconds(agingTime) {
    var startDate = new Date();
    // Do your operations
    var endDate = new Date(agingTime);
    var milliSeconds = (endDate.getTime() - startDate.getTime()) / 1000;
    return milliSeconds;
  }

  getCommentObject(data) {
    let commentObj = {};
    let message_data={}
    message_data['attachments'] = data?.message_data ? data?.message_data?.attachments : data?.attachments,
    message_data['text'] =  data?.message_data ? data?.message_data?.text : data.comment,
    commentObj['message_data']=message_data
    commentObj['tag_user_id'] = data.tag_user_id;
    commentObj['tag_user_id'] = data.tag_user_id;
    commentObj['create_date'] = data.create_date;
    commentObj['created_by'] = data.created_by;
    commentObj['_id'] = data._id;
    return commentObj;
  }

  initializationOfBucketOrGroupWithLeadData(data) {
    var dataObj = {}
    dataObj['group_data']=data;
    var setCreateLead = true;
    var role = this.customStorageService.getItem("role");
    dataObj["_id"] = data?._id;
    dataObj["group_id"] = data?._id;
    dataObj["name"] = data?.name;
    dataObj["current_page_no"] = 1;
    dataObj["total_records"] = 0;
    dataObj['leadIds']=[]
    if (data["istrash"]) {
      dataObj["istrash"] = true;
    }
    var userId = this.customStorageService.getItem("id");
    if (data?.create_lead?.filter == true) {
      if (data?.create_lead?.allowed_users?.length > 0) {
        for (var k = 0; k < data.create_lead.allowed_users.length; ++k) {
          if (userId == data.create_lead.allowed_users[k]) {
            setCreateLead = true;
            break;
          } else {
            setCreateLead = false;
          }
        }
      } else {
        setCreateLead = false;
      }
    }
    if (role == Role.SALES || role == Role.ADMIN) {
      if (data?.bucket_followed_users && data?.bucket_followed_users?.indexOf(userId) !== -1) {
        dataObj["bucketFollowed"] = true;
      } else {
        dataObj["bucketFollowed"] = false;
      }
    }
    dataObj["inbound"] = data?.inbound;
    dataObj["create_lead"] = setCreateLead;
    dataObj["bucket_followed_users"] = data?.bucket_followed_users;
    dataObj["leadContents"] = [];
    dataObj["is_destination"] = data?.is_destination;
    return dataObj;
  }

  getAgingTimeDisplayDate(date) {
    var dateTime = "";
    // Date.shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var day = this.dayNames[date.getDay() - 1];
    var agingdate = date.getDate();
    var month = this.monthNames[date.getMonth()];
    var dd = String(date.getDate()).padStart(2, "0");
    var year = date.getFullYear();
    let currentDate=new Date()
    dateTime = agingdate + " " + month + " " + (currentDate?.getFullYear()== year ? '' : year);
    return dateTime;
  }

  getDisplayDate(dateObj){
    let date;
    if(!dateObj?.is_time_added){
      let timeFrom1900=this.calculateTimestampSince1900(dateObj?.date)
      date=new Date(timeFrom1900)
    }else{
      date=new Date(dateObj.time)
    }
    const yyyy = date.getFullYear();
    let mm =(date.getMonth() + 1); // Months start at 0!
    let dd =date.getDate();
    const formattedDate = (dd < 10 ? '0' : '')+dd + '/' + (mm < 10 ? '0' : '')+mm + '/' + yyyy;
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let time = (hours % 12 || 12) + ":" + (minutes < 10 ? "0" + minutes : minutes) + " " + (hours >= 12 ? "PM" : "AM");
    return {date:formattedDate,time:time}
  }
  getAgingTimeDisplayTime(inputdate) {
    let date = new Date(inputdate);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    var minute = minutes < 10 ? "0" + minutes : minutes;
    let AmOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    let time = hours + ":" + minute + " " + AmOrPm;
    return time;
  }

  getLoggedInUserId() {
    var userid = this.customStorageService.getItem("id");
    return userid;
  }

  /**
   * 
   * @param date new Date()
   * @param showTime 
   * @returns 
   */
  getHumanReadableDateAndTimeFromDate(date, showTime: boolean = false, is24Hrs: boolean = true){
    let humanReadable = {"date":"", "time":""}
    humanReadable['date'] = this.getHumanReadableDate(date)
    if(showTime && date && date!="Invalid Date"){
      if(is24Hrs){
        humanReadable['time'] = date.getHours()+":"+date.getMinutes()
      }else{
        let hrs = date.getHours()
        let amOrpm= hrs>=12 ? 'PM' : 'AM'
        hrs=(hrs%12) || 12;
        humanReadable['time'] =`${(hrs<10 ? '0'+hrs : hrs)}:${(date.getMinutes()<10 ? '0'+ date.getMinutes() : date.getMinutes())} ${amOrpm}`
      }

    }
    return humanReadable 
  }
  /**
   * 
   * @param dateStr dd/mm/yyyy
   */
  getHumanReadableDateFromDDMMYYY(dateStr):string{
    if(!dateStr){
      return ""
    }
    let dateArr = dateStr?.toString().split('/');
    if(dateArr?.length<2) return ""
    dateStr = dateArr[1]+'/'+ dateArr[0]+'/'+dateArr[2];
    var givenDate = new Date(dateStr);
    return this.getHumanReadableDate(givenDate)
  }
  
  /**
   * 
   * @param givenDate new Date()
   * @returns 
   */
  getHumanReadableDate(givenDate):string{
    if(!givenDate){
      return ""
    }
    var todayDate = new Date(new Date().setHours(12, 0, 0, 0));
    var diffOfDate = (givenDate.getTime() - todayDate.getTime())/(1000*3600*24);
    diffOfDate = Math.round(diffOfDate);
    if (diffOfDate == -1) {
      return Day.Yesterday;
    }
    if (diffOfDate < -1 || diffOfDate >= 7 ) {
      return  this.getAgingTimeDisplayDate(givenDate);
    }
    if (diffOfDate == 0) {
      return Day.Today;
    }
    if (diffOfDate == 1) {
      return Day.Tomorrow;
    }
    if (diffOfDate > 1 && diffOfDate < 7){
      var givenDateDay = givenDate.getDay();
      switch (givenDateDay) {
        case 0:
          return Day.Sunday;
        case 1:
          return Day.Monday;
        case 2:
            return Day.Tuesday;
        case 3:
          return Day.Wednesday;
        case 4:
          return Day.Thursday;
        case 5:
          return Day.Friday;
        case 6:
          return Day.Saturday;
     }
    }
  }

  getAgingTimeForHeatMap(agingDate) {
    let returnDate = this.getHumanReadableDateFromDDMMYYY(agingDate);
    if(agingDate) {
      let dateArr = agingDate.toString().split('/');
      if(dateArr.length>1)
      agingDate = dateArr[1]+'/'+ dateArr['0']+'/'+dateArr[2];
    }

    let dateObj = {};
    dateObj["date"] = returnDate;
    dateObj["time"] = this.getAgingTimeDisplayTime(agingDate);
    return dateObj
  }

  epochToDate(epoch: any) {
    var date = new Date(epoch);
    return date;
  }


  decodeEntitiesWithAttributes(htmlString) {
    // Decode HTML entities within the string
    const decodedString = he.decode(htmlString);
  
    // Extract and preserve HTML attributes
    const regex = /<([a-zA-Z0-9-]+)([^>]*)>/g;
    const decodedWithAttributes = decodedString.replace(regex, (match, tag, attributes) => {
      return `<${tag}${attributes}>`;
    });
  
    return decodedWithAttributes;
  }

  getNameInitial(name: string = '') {
    return name.split(' ').map((word: string)=> word.charAt(0)).join('').toUpperCase().slice(0, 2);
  }

  checkUserHaveEditAccessInDashboard(userDashboardRoles: string[] = [], isTemplate: boolean = false) {                
    if(userDashboardRoles.includes(this.userRoles.DASHBOARDADMIN)) {
      return true
    } else if(isTemplate && this.customStorageService.getItem('role')=== this.userRoles.ADMIN) {
      return true
    }
      return false
  }

  resetFilterDataObj (filterDataObj, arrayOfCustomFiled?) {
    if("apply_filter" in filterDataObj) {
      filterDataObj["apply_filter"] = {}
    }
    if("priority" in filterDataObj) {
      filterDataObj["priority"] = [];
    }
    if("assigned_to" in filterDataObj) {
      filterDataObj["assigned_to"] = [];
    }
    if("tag_id" in filterDataObj) {
      filterDataObj["tag_id"] = [];
    }
    if("lead_id" in filterDataObj) {
      filterDataObj["lead_id"] = [];
    }
    if("sprint_id" in filterDataObj) {
      filterDataObj["sprint_id"] = [];
    }
    if("survey_form_id" in filterDataObj) {
      filterDataObj["survey_form_id"] = [];
    }
    if("created_by" in filterDataObj) {
      filterDataObj["created_by"] = [];
    }
    if("assigned_by_user" in filterDataObj) {
      filterDataObj["assigned_by_user"] = [];
    }
    if("lead_colloborators_users" in filterDataObj) {
      filterDataObj["lead_colloborators_users"] = [];
    }
    if("mentions" in filterDataObj) {
      filterDataObj["mentions"] = {status:[]};
    }
    if("starred" in filterDataObj) {
      filterDataObj["starred"] = [];
    }
    if("dashboard_id" in filterDataObj) {
      filterDataObj["dashboard_id"] = [];
    }
    if("item_type" in filterDataObj) {
      filterDataObj["item_type"] = [];
    }
    if("bucket_type" in filterDataObj) {
      delete filterDataObj["bucket_type"];
    }
    if("apply_formula" in filterDataObj) {
      delete filterDataObj['apply_formula'];
    }
    if("bucket_id" in filterDataObj) {
      filterDataObj["bucket_id"] = [];
    }
    if("is_deleted" in filterDataObj) {
      filterDataObj["is_deleted"] = [];
    }
    if("deadline_over" in filterDataObj) {
      filterDataObj["deadline_over"] = false;
    }
    if ("advanced_enable" in filterDataObj ) {
      filterDataObj["advanced_enable"] = false;
    }
    if("have_external_collaborators" in filterDataObj) {
      filterDataObj["have_external_collaborators"] = false;
    }
    if("due_date" in filterDataObj) {
      filterDataObj["due_date"]['from'] = null;
      filterDataObj["due_date"]['to'] = null;
      filterDataObj["due_date"]['isNull'] = false;
    }
    if("last_updated" in filterDataObj) {
      filterDataObj["last_updated"]['from'] = null;
      filterDataObj["last_updated"]['to'] = null;
    }
    if("create_date" in filterDataObj) {
      filterDataObj["create_date"]['from'] = null;
      filterDataObj["create_date"]['to'] = null;
    }
    if("start_date" in filterDataObj) {
      filterDataObj["start_date"]['from'] = null;
      filterDataObj["start_date"]['to'] = null;
      filterDataObj["start_date"]['isNull'] = false;
    }
    if("end_date" in filterDataObj) {
      filterDataObj["end_date"]['from'] = null;
      filterDataObj["end_date"]['to'] = null;
    }
    if("custom_column" in filterDataObj && arrayOfCustomFiled) {
      arrayOfCustomFiled.forEach(customFieldJson => {
        let customFieldObj = filterDataObj['custom_column']
        if(customFieldObj[customFieldJson.key]) {
          if(customFieldJson.type === 'Mirror') {
            if(['Checkbox','Dropdown','Radiobutton','MultiSelect', 'SingleUser','MultiUser','Bucket','Priority','Tag','Sprint','Board','Country','MultipleBoard'].includes(customFieldJson.mirror_column_type)) {
              customFieldObj[customFieldJson.key] = [];
            } else if(['Text','Email','Url','Textarea','MultiUrl','Location','Mobile','MultipleMobile','MultipleEmail'].includes(customFieldJson.mirror_column_type)) {
              customFieldObj[customFieldJson.key] = '';
            } else if(['Numeric','Date','Rating','Percentage','Currency'].includes(customFieldJson.mirror_column_type)) {
              customFieldObj[customFieldJson.key] = {
                from:null,to:null
              };
            }
          } else {
            if(['Checkbox','Dropdown','Radiobutton','MultiSelect', 'SingleUser','MultiUser','Bucket','Priority','Tag','Sprint','Board','Country','MultipleBoard'].includes(customFieldJson.type)) {
              customFieldObj[customFieldJson.key] = [];
            } else if(['Text','Email','Url','Textarea','MultiUrl','Location','Mobile','MultipleMobile','MultipleEmail'].includes(customFieldJson.type)) {
              customFieldObj[customFieldJson.key] = '';
            } else if(['Numeric','Date','Rating','Percentage','Currency'].includes(customFieldJson.type)) {
              customFieldObj[customFieldJson.key] = {
                from:null,to:null
              };
            }
          }
        }
      });
    }
    if("search_params" in filterDataObj) {
      filterDataObj["search_params"]['search_text'] = '';
    }    
    if("grouping_details" in filterDataObj) {
      filterDataObj["grouping_details"] = {};
    }    
    if("sort_params" in filterDataObj && filterDataObj.sort_params.length>0) {
      filterDataObj.sort_params[0]['sort_by'] = 'index';
      filterDataObj.sort_params[0]['order'] = 'DSC';
    }

    if('and' in filterDataObj){
      delete filterDataObj["and"];
    }
    else if('or' in filterDataObj){
      delete filterDataObj["or"]
    }
   
    return filterDataObj;
  }

  createDefaultFilterDataObj(){
    return  {
      sort_params: [{
        sort_by: 'index',
        order: 'DSC'
      }],
      lead_id:[],
      start_date: {from:undefined,to:undefined,isNull: false},
      due_date: {from:undefined,to:undefined,isNull: false},
      create_date: {from:undefined,to:undefined},
      last_updated: {from:undefined,to:undefined},
      bucket_id: [],
      survey_form_id: [],
      bucket_type:[],
      sprint_id:[],
      tag_id:[],
      item_type: [],
      starred: [],
      mentions: {status:[], items:[]},
      is_deleted: [],
      deadline_over: false,
      have_external_collaborators: false,
      search_params:{
        search_text: "",
        
      },
      grouping_details: {},
      apply_formula:{},
      custom_column:{},
      advanced_enable:false
    }
  }

  replaceIsNullToNull(arr:any[]) {
    const index = arr.indexOf('isNull') 
    if(index>-1) arr[index] = null; 
    return arr;
  }

  countAppliedFilter(filterDataObj,allItems?) {
    let count  = 0;
    let filterData =  this.cleanFilterDataObject(filterDataObj)
    let filterJson = this.getFilterJsonForQuery({},filterData)

    let advancedFilterEnable=false
    let adadvancedFilterArr=[]
    if("and" in filterJson || "or" in filterJson){
      let arr= filterJson?.and ? filterJson?.and : filterJson?.or
      adadvancedFilterArr= arr
      let hierarchyEnable= arr.find(element=>element['key']=='parent_id')
      if(hierarchyEnable){
        let newJson=arr.find(element=>('and' in element || 'or' in element))
        if(newJson?.and || newJson?.or){
          advancedFilterEnable=true
          adadvancedFilterArr= newJson?.and ? newJson?.and : newJson?.or
        }
      }
      else{
        advancedFilterEnable=true
      }

    }

    if(advancedFilterEnable){
      count=adadvancedFilterArr?.length
    }
    else if(!advancedFilterEnable){
          let keys = Object.keys(filterJson) || [];
          keys.forEach(filterKey => {
            if(filterKey != 'grouping_details' && filterKey != 'sort_params' && filterKey != 'apply_formula' && filterKey != 'dashboard_id'
            && filterKey != 'custom_column' && filterKey != 'search_params' && filterKey != 'and' && filterKey != 'timezone_offset') {
              count += 1;
            }
            if(filterKey === 'search_params' && filterJson[filterKey]?.search_text) {
              count += 1;
            }
            if(filterKey === 'dashboard_id' && allItems) {
              count += 1;
            }
            if(filterKey === 'custom_column') {
              count = this.checkCustomColumnsFilterCount(count,filterJson)
            }
          });
    }
    return count;
  }

  checkCustomColumnsFilterCount(count,filter) {
    for(const column in filter.custom_column) {
      const columnField = filter.custom_column[column];
      if(typeof columnField === 'object' && !Array.isArray(columnField) &&
      columnField !== null && Object.keys(columnField).length>0) {
        if(columnField?.from===0  || columnField?.to===0  || columnField?.from || columnField?.to) {
          count += 1;
        }
      } else 
      if(Array.isArray(columnField) && columnField.length>0) {
        count += 1;
      } else
      if((typeof columnField === 'string' || typeof columnField === 'number' || columnField === 0) && columnField && columnField != '') {
        count += 1;
      } else
      if(typeof columnField === 'boolean' && filter.custom_column) {
        count += 1;
      }
    } 
    return count;
  }

  getFilterJsonForQuery(inputJson, filterDataObj) {
    if(!filterDataObj) return inputJson
    if ("assigned_to" in filterDataObj && filterDataObj["assigned_to"].length > 0) {
      if(filterDataObj["assigned_to"].includes('isNull')) {
        inputJson["assigned_to"] =  this.replaceIsNullToNull(filterDataObj["assigned_to"]);
      }
      inputJson["assigned_to"] = filterDataObj["assigned_to"];
    }
    if ("assigned_by_user" in filterDataObj && filterDataObj["assigned_by_user"].length > 0) {
      inputJson["assigned_by_user"] = filterDataObj["assigned_by_user"];
    }
    if ("lead_id" in filterDataObj && filterDataObj["lead_id"].length > 0) {
      inputJson["lead_id"] = filterDataObj["lead_id"];
    }
    if ("created_by" in filterDataObj && filterDataObj["created_by"].length > 0) {
      inputJson["created_by"] = filterDataObj["created_by"];
    }
    if ("lead_colloborators_users" in filterDataObj && filterDataObj["lead_colloborators_users"].length > 0) {
      if(filterDataObj["lead_colloborators_users"].includes('isNull')) {
        inputJson["lead_colloborators_users"] =  this.replaceIsNullToNull(filterDataObj["lead_colloborators_users"]);
      }
      inputJson["lead_colloborators_users"] = filterDataObj["lead_colloborators_users"];
    }
    if ("priority" in filterDataObj && filterDataObj["priority"].length > 0) {
      inputJson["priority"] = filterDataObj["priority"];
    }
    if ("tag_id" in filterDataObj && filterDataObj["tag_id"].length > 0) {
      if(filterDataObj["tag_id"].includes('isNull')) {
        inputJson["tag_id"] =  this.replaceIsNullToNull(filterDataObj["tag_id"]);
      }
      inputJson["tag_id"] = filterDataObj["tag_id"];
    }
    if ("sprint_id" in filterDataObj && Array.isArray(filterDataObj["sprint_id"]) && filterDataObj["sprint_id"]?.length > 0) {
      if(filterDataObj["sprint_id"].includes('isNull')) {
        inputJson["sprint_id"] =  this.replaceIsNullToNull(filterDataObj["sprint_id"]);
      }
      inputJson["sprint_id"] = filterDataObj["sprint_id"];
    }
    if ("survey_form_id" in filterDataObj && filterDataObj["survey_form_id"].length > 0) {
      inputJson["survey_form_id"] = filterDataObj["survey_form_id"];
    }
    if ("create_date" in filterDataObj && Object.keys(filterDataObj['create_date']).length>0) {
      inputJson["create_date"] = filterDataObj.create_date
    }
    if ("start_date" in filterDataObj && Object.keys(filterDataObj['start_date']).length>0) {
      inputJson["start_date"] = filterDataObj['start_date'];
    }
    if ("due_date" in filterDataObj && Object.keys(filterDataObj['due_date']).length>0) {
      inputJson["due_date"] = filterDataObj['due_date'];
    }
    if ("last_updated" in filterDataObj && Object.keys(filterDataObj['last_updated']).length>0) {
      inputJson["last_updated"] = filterDataObj.last_updated;
    }
    if ("custom_column" in filterDataObj && Object.keys(filterDataObj['custom_column']).length>0) {
      inputJson["custom_column"] = filterDataObj["custom_column"];
    }
    if ("item_type" in filterDataObj && filterDataObj["item_type"]?.length > 0) {
      inputJson["item_type"] = filterDataObj["item_type"];
    }
    if ("starred" in filterDataObj && filterDataObj["starred"]?.length > 0) {
      inputJson["starred"] = filterDataObj["starred"];
    }
    if ("dashboard_folder_id" in filterDataObj && filterDataObj["dashboard_folder_id"]?.length > 0) {
      inputJson["dashboard_folder_id"] = filterDataObj["dashboard_folder_id"];
    }
    if ("mentions" in filterDataObj && filterDataObj["mentions"]['status']?.length > 0) {
      inputJson["mentions"] = filterDataObj["mentions"];
    }
    if ("deadline_over" in filterDataObj &&  filterDataObj["deadline_over"]) {
      inputJson["deadline_over"] = filterDataObj["deadline_over"];
    }
    if ("advanced_enable" in filterDataObj &&  filterDataObj["advanced_enable"]) {
      inputJson["advanced_enable"] = filterDataObj["advanced_enable"];
    }
    if ("is_deleted" in filterDataObj && filterDataObj["is_deleted"]?.length>0) {
      inputJson["is_deleted"] = filterDataObj["is_deleted"];
    }
    if ("have_external_collaborators" in filterDataObj && filterDataObj["have_external_collaborators"]) {
      inputJson["have_external_collaborators"] = filterDataObj["have_external_collaborators"];
    }
    if ("search_params" in filterDataObj && Object.keys(filterDataObj['search_params'])?.length>0) {
      inputJson["search_params"] = filterDataObj["search_params"];
      // if(filterDataObj["search_params"].search_text) this.filterCount = this.filterCount + 1;
    }
    if ("sort_params" in filterDataObj && Object.keys(filterDataObj['sort_params'])?.length>0) {
      inputJson["sort_params"] = filterDataObj["sort_params"];
    }
    if ("grouping_details" in filterDataObj && Object.keys(filterDataObj['grouping_details'])?.length>0) {
      inputJson["grouping_details"] = filterDataObj["grouping_details"];
    }
    if("dashboard_id" in filterDataObj && filterDataObj['dashboard_id']?.length >0){
      inputJson['dashboard_id'] = filterDataObj['dashboard_id'];
    }
    if("bucket_type" in filterDataObj && filterDataObj['bucket_type'].length>0){
      inputJson['bucket_type']=filterDataObj['bucket_type'];
    }
    if("bucket_id" in filterDataObj && filterDataObj['bucket_id'].length>0){
      inputJson['bucket_id']=filterDataObj['bucket_id'];
    }
    if("lead_id" in filterDataObj && filterDataObj['lead_id'].length>0){
      inputJson['lead_id']=filterDataObj['lead_id'];
    }
    if("apply_formula" in filterDataObj && Object.keys(filterDataObj['apply_formula']).length>0){
      inputJson['apply_formula']=filterDataObj['apply_formula'];
    }
    if("and" in filterDataObj && filterDataObj['and']?.length>0){
      inputJson['and']=filterDataObj['and'];
    }
    if("or" in filterDataObj && filterDataObj['or']?.length>0){
      inputJson['or']=filterDataObj['or'];
    }
    if('include_activities' in filterDataObj && filterDataObj?.include_activities){
      inputJson['include_activities']=filterDataObj?.include_activities
    }
    if('associated_activity_workspace_board' in filterDataObj && filterDataObj?.associated_activity_workspace_board?.length>0){
      inputJson['associated_activity_workspace_board']=filterDataObj?.associated_activity_workspace_board
    }
    if("timezone_offset" in filterDataObj){
      inputJson['timezone_offset']=filterDataObj['timezone_offset'];
    }
    return inputJson;
  }

  getTimezoneOffset(){
    return (new Date())?.getTimezoneOffset()
}

  capitalizeWords(inputString) {
    // Split the input string into words
    const words = inputString.split(' ');
    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    // Join the capitalized words back into a single string
    const capitalizedString = capitalizedWords.join(' ');
    return capitalizedString;
  }

  setDefaultFilterObj(selectedFilter, filterDataObj, arrayOfCustomFiled?) {
    if(selectedFilter == this.constantService.constant.defaultFilters.myAssigned) {
      filterDataObj['assigned_to'] = ['{{EVAL_LOGGED_IN_USER}}'];
    }
    if(selectedFilter == this.constantService.constant.defaultFilters.myCollaborated) {
      filterDataObj['lead_colloborators_users'] = ['{{EVAL_LOGGED_IN_USER}}'];
    }
    if (selectedFilter == this.constantService.constant.defaultFilters.withOutStartDate) {
      filterDataObj["start_date"]['isNull'] = true;
    }
    if (selectedFilter == this.constantService.constant.defaultFilters.withOutDueDate) {
      filterDataObj["due_date"]['isNull'] = true;
    }
    if (selectedFilter == this.constantService.constant.defaultFilters.withOutTags) {
      filterDataObj["tag_id"] = [null];
    }
    if(selectedFilter == this.constantService.constant.defaultFilters.myMentions) {
      filterDataObj['mentions']['status'] = ['UNREAD','READ'];
    }
    if(selectedFilter == this.constantService.constant.defaultFilters.assignedByMe) {
      filterDataObj['assigned_by_user'] = ['{{EVAL_LOGGED_IN_USER}}'];
    }
    if(selectedFilter == this.constantService.constant.defaultFilters.createdByMe) {
      filterDataObj['created_by'] = ['{{EVAL_LOGGED_IN_USER}}'];
    }
    if(selectedFilter == this.constantService.constant.defaultFilters.starred) {
      filterDataObj['starred'] = ['{{EVAL_LOGGED_IN_USER}}'];
    }
    if(selectedFilter == this.constantService.constant.defaultFilters.deadlineoverDue) {
      filterDataObj['deadline_over'] = true;
    }
    if(selectedFilter == this.constantService.constant.defaultFilters.clearAllFilters) {      
      filterDataObj = this.resetFilterDataObj(filterDataObj, arrayOfCustomFiled);
    }
    return filterDataObj;
  }

  converFilterObjAccordingToFilter(filter,arrayOfCustomFiled){
    let  localFilterDataObj = {
      sort_params: filter.sort_params ? filter.sort_params :[{
        sort_by: 'index',
        order: 'DSC'
      }],
      start_date: filter?.start_date ? filter?.start_date : {from:undefined,to:undefined,isNull: false},
      due_date: filter?.due_date ? filter?.due_date : {from:undefined,to:undefined,isNull: false},
      create_date: filter?.create_date ? filter?.create_date : {from:undefined,to:undefined},
      last_updated: filter?.last_updated ? filter?.last_updated : {from:undefined,to:undefined},
      lead_id : filter?.lead_id ? filter?.lead_id : [],
      assigned_to : filter?.assigned_to ? filter?.assigned_to : [],
      assigned_by_user : filter?.assigned_by_user ? filter?.assigned_by_user : [],
      created_by : filter?.created_by ? filter?.created_by : [],
      bucket_id: filter?.bucket_id ? filter?.bucket_id : [],
      bucket_type:filter?.bucket_type ? filter?.bucket_type : [],
      survey_form_id: filter?.survey_form_id ? filter?.survey_form_id : [],
      sprint_id: filter?.sprint_id ? filter?.sprint_id : [],
      tag_id:filter?.tag_id ? filter?.tag_id : [],
      priority: filter?.priority ? filter?.priority : {},
      item_type: filter?.item_type ? filter?.item_type : [],
      starred: filter?.starred ? filter?.starred : [],
      mentions: filter?.mentions ? filter?.mentions : {status:[], items:[]},
      is_deleted: filter?.is_deleted ? filter?.is_deleted : [],
      lead_colloborators_users : filter?.lead_colloborators_users ? filter?.lead_colloborators_users : [],
      deadline_over: filter?.deadline_over ? filter?.deadline_over :  false,
      have_external_collaborators: filter?.have_external_collaborators ? filter?.have_external_collaborators : false,
      search_params: filter?.search_params ? filter?.search_params : {search_text: ""},
      grouping_details: filter?.grouping_details ? filter?.grouping_details : {max_group_size: null, group_by: '', start_index: 0},
      custom_column:{},
      apply_formula:{},
      advanced_enable: filter?.advanced_enable ? filter?.advanced_enable :  false
    };
    if(filter?.include_activities){
      localFilterDataObj['include_activities']=filter?.include_activities
    }
    if(filter?.associated_activity_workspace_board?.length>0){
      localFilterDataObj['associated_activity_workspace_board']=filter?.associated_activity_workspace_board
    }
    if(filter.and || filter.or){
      let key= filter.and ? 'and' : 'or'
      localFilterDataObj[key]=filter.and ? filter.and : filter.or
    }
    if(arrayOfCustomFiled?.length){
      for(let i=0; i<arrayOfCustomFiled.length; i++) {
        if(['Checkbox','Dropdown','Radiobutton','Tag','Sprint','Country','Bucket','MultiSelect','Priority','Board'].includes(arrayOfCustomFiled[i].type)) {
          localFilterDataObj['custom_column'][arrayOfCustomFiled[i]?.key] = filter['custom_column'] && filter['custom_column'][arrayOfCustomFiled[i].key] ? filter['custom_column'][arrayOfCustomFiled[i].key] :[];
       //  filter['custom_column'][arrayOfCustomFiled[i].key] ? arrayOfCustomFiled[i]?.permissible_values.concat(filter['custom_column'][arrayOfCustomFiled[i].key]?.permissible_values) : ''
        } else if(['Numeric','Date','Rating','Percentage','Currency'].includes(arrayOfCustomFiled[i].type)) {
          localFilterDataObj['custom_column'][arrayOfCustomFiled[i]?.key] = filter['custom_column'] && filter['custom_column'][arrayOfCustomFiled[i].key] ? filter['custom_column'][arrayOfCustomFiled[i].key] : {from:null,to:null};
        } else if(['Text','Email','Url','Textarea','MultiUrl','Mobile','MultipleMobile','MultipleEmail'].includes(arrayOfCustomFiled[i].type)) {
          localFilterDataObj['custom_column'][arrayOfCustomFiled[i]?.key] =  filter['custom_column'] && filter['custom_column'][arrayOfCustomFiled[i]?.key] ? filter['custom_column'][arrayOfCustomFiled[i].key] : "";
        } else if(['SingleUser','MultiUser'].includes(arrayOfCustomFiled[i].type)) {
          localFilterDataObj['custom_column'][arrayOfCustomFiled[i]?.key] =  filter['custom_column'] && filter['custom_column'][arrayOfCustomFiled[i]?.key] ? filter['custom_column'][arrayOfCustomFiled[i].key] : [];
        }
        else if(['Mirror'].includes(arrayOfCustomFiled[i].type)) {
          if(['Checkbox','Dropdown','Radiobutton','MultiSelect','Tag','Sprint','Country','Priority','Bucket','Board','SingleUser','MultiUser'].includes(arrayOfCustomFiled[i].mirror_column_type)) {
            localFilterDataObj['custom_column'][arrayOfCustomFiled[i].key] = filter['custom_column'] && filter['custom_column'][arrayOfCustomFiled[i].key] ? filter['custom_column'][arrayOfCustomFiled[i].key] :[];;
            
          } else if(['Numeric','Date','Rating','Percentage','Currency'].includes(arrayOfCustomFiled[i].mirror_column_type)) {
            localFilterDataObj['custom_column'][arrayOfCustomFiled[i].key] = filter['custom_column'] && filter['custom_column'][arrayOfCustomFiled[i].key] ? filter['custom_column'][arrayOfCustomFiled[i].key] : {from:null,to:null};
          } else if(['Text','Email','Url','MultiUrl','Textarea','Mobile','MultipleMobile','MultipleEmail'].includes(arrayOfCustomFiled[i].mirror_column_type)) {
            localFilterDataObj['custom_column'][arrayOfCustomFiled[i].key] = filter['custom_column'] && filter['custom_column'][arrayOfCustomFiled[i]?.key] ? filter['custom_column'][arrayOfCustomFiled[i].key] : "";
          }
        } 
        
      }
    }
      return localFilterDataObj

  }

  getItemUrlForNewTab(itemSeqId, dashboardId){
    return environment.leadUrlText+dashboardId+"/"+itemSeqId
  }

  onUploadFile(event,updateCustomType, callbackFunctionOnEachUpload = null, callbackFunctionAfterAllUpload = null, callbackParams = {}) {
    if (event) {
      this.onUploadLargeFiles(event,updateCustomType, callbackFunctionOnEachUpload, callbackFunctionAfterAllUpload, callbackParams);
    }
  }


  checkFileFormat(element) {
    if (element.type.includes('doc') || element.type.includes('text')) {
      element.format = "doc";
      element["previewThumb"] = "/assets/1x/doc-icon.svg";
      element["previewUrl"] = "/assets/1x/doc-icon.svg";
      element["file_url"] = "/assets/1x/doc-icon.svg";
    }
    if (element.type.includes('pdf')) {
      element.format = "pdf";
      element["previewThumb"] = "/assets/1x/pdf.png";
      element["previewUrl"] = "/assets/1x/pdf.png";
      element["file_url"] = "/assets/1x/pdf.png";
    }
    if (element.type.includes('zip')) {
      element.format = "zip";
      element["previewThumb"] = "/assets/1x/zip.png";
      element["previewUrl"] = "/assets/1x/zip.png";
      element["file_url"] = "/assets/1x/zip.png";
    }
    if (element.type.includes('xlsx')) {
      element.format = "xlsx";
      element["previewThumb"] = "/assets/1x/xlsx.png";
      element["previewUrl"] = "/assets/1x/xlsx.png";
      element["file_url"] = "/assets/1x/xlsx.png";
    }
    if (element.type.includes('docx')) {
      element.format = "docx";
      element["previewThumb"] = "/assets/1x/docx.png";
      element["previewUrl"] = "/assets/1x/docx.png";
      element["file_url"] = "/assets/1x/docx.png";
    }
    if (element.type.includes('csv')) {
      element.format = "csv";
      element["previewThumb"] = "/assets/1x/csv.png";
      element["previewUrl"] = "/assets/1x/csv.png";
      element["file_url"] = "/assets/1x/csv.png";
    }
  return element;
  }

  cleanFilterDataObject(filterDataObj,customFields?) {
    if(!filterDataObj) return
    if(filterDataObj['custom_column'] && customFields) {
      customFields.forEach(field => {
        if(field.key in filterDataObj['custom_column']) {
          if(Array.isArray(filterDataObj['custom_column'][field.key])) {
            let tempArr = [];
            tempArr = filterDataObj['custom_column'][field.key]
            if(tempArr.length == 0) {
              delete filterDataObj['custom_column'][field.key]
            }
          } else if(filterDataObj['custom_column'][field.key] === '') {
            delete filterDataObj['custom_column'][field.key]
          } else if(filterDataObj['custom_column'][field.key]?.from === null || filterDataObj['custom_column'][field.key]?.from === undefined) {
            delete filterDataObj['custom_column'][field.key]['from']
            if(filterDataObj['custom_column'][field.key]?.to === null || filterDataObj['custom_column'][field.key]?.to === undefined){
              delete filterDataObj['custom_column'][field.key]['to']
            }
          } 
        }
      });
    }
    if(filterDataObj['last_updated'] && (filterDataObj['last_updated']['from'] === null || filterDataObj['last_updated']['from'] === undefined)) delete filterDataObj['last_updated']['from']
    if(filterDataObj['last_updated'] && (filterDataObj['last_updated']['to'] === null || filterDataObj['last_updated']['to'] === undefined)) delete filterDataObj['last_updated']['to']
    if(filterDataObj['create_date'] && (filterDataObj['create_date']['from'] === null || filterDataObj['create_date']['from'] === undefined)) delete filterDataObj['create_date']['from']
    if(filterDataObj['create_date'] && (filterDataObj['create_date']['to'] === null || filterDataObj['create_date']['to'] === undefined)) delete filterDataObj['create_date']['to']
    if(filterDataObj['start_date'] && (filterDataObj['start_date']['from'] === null || filterDataObj['start_date']['from'] === undefined)) delete filterDataObj['start_date']['from']
    if(filterDataObj['start_date'] && (filterDataObj['start_date']['to'] === null || filterDataObj['start_date']['to'] === undefined)) delete filterDataObj['start_date']['to']
    if(filterDataObj['due_date'] && (filterDataObj['due_date']['from'] === null || filterDataObj['due_date']['from'] === undefined)) delete filterDataObj['due_date']['from']
    if(filterDataObj['due_date'] && (filterDataObj['due_date']['to'] === null || filterDataObj['due_date']['to'] === undefined)) delete filterDataObj['due_date']['to']
    if(filterDataObj['start_date'] && (filterDataObj['start_date']['isNull'] !== undefined)) {
      if(filterDataObj['start_date']['isNull']){
        delete filterDataObj['start_date']['from']
        delete filterDataObj['start_date']['to']
      }
      else{
        delete filterDataObj['start_date']['isNull']
      }
    }
    if(filterDataObj['due_date'] && (filterDataObj['due_date']['isNull'] !== undefined)) {
      if(filterDataObj['due_date']['isNull']){
        delete filterDataObj['due_date']['from']
        delete filterDataObj['due_date']['to']
      }
      else{
        delete filterDataObj['due_date']['isNull']
      }
    }
    if("apply_formula" in filterDataObj && !Object.keys(filterDataObj['apply_formula']).length) delete filterDataObj['apply_formula']

    return filterDataObj;
  }

  
  getFileNameExtFromS3Path(s3PathOrs3Url){
    const fileObj = {
      fileName : '',
      extension: '',
      media_icon: ''
    };
    if(s3PathOrs3Url) {
      let fileUrlOnly = s3PathOrs3Url.split("?")[0]
      fileObj.fileName = fileUrlOnly.split("/").pop()
      fileObj.extension = fileObj.fileName.split(".").pop()
    }
    return fileObj
  }

  applyDocViewerBasedOnFileEXtension(fileUrl){
    let fileType = ["ppt", "pptx", "doc", "docx", "xls", "xlsx"]
    let fileObject = this.getFileNameExtFromS3Path(fileUrl)
     if(fileObject.extension.toLowerCase() === 'pdf'){
      return 'pdf';
     }
     else if(fileType.includes(fileObject.extension.toLowerCase())) {
      return "office";
     } else {
      return "google";
     }
  }

  getRandomString(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
    return result;
  }
  
  extractTextFromHtmlContent(htmlString) {
    var span = document.createElement('span');
    htmlString = htmlString.replaceAll("</li>"," </li>")
    htmlString = htmlString.replaceAll("<br/>"," ")
    span.innerHTML = htmlString;
    return  span.innerText || span.textContent ;
  };

  convertepoch(timestamp){
    var date = new Date(timestamp);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    let dateFormat;
    if(month<10){
      dateFormat=year + "-"+'0' + month + "-" +day;
    }
    else{
      dateFormat=year + "-" + month + "-" +day;
    }
    if(day<10){
      dateFormat=year + "-" + month + "-"+'0' +day;
    }
    if(month<10 && day<10){
      dateFormat=year + "-"+'0' + month + "-"+'0' +day;
    }
    return dateFormat;
  }


  // this method will check from storage and if not present in local query from server and check is dashboard is read only or not

  async isDashboardReadOnly(dashboardId, leadCollabratorsIdArr?,leadObj?) {
    let dashboardInfo = await this.dashboardUtils.getDashboardInformation([dashboardId])
    return this.calcuateReadOnly(dashboardInfo[dashboardId]?.DASHBOARD_INFO,leadObj)

  }
  calcuateReadOnly(dashboardInfo,leadObj?){
    if(dashboardInfo?.type && dashboardInfo?.type?.includes('TEMPLATE')) return true;
    if(leadObj && leadObj?.is_deleted) return true; 
    let externalCollaboratorId= leadObj?.external_collaborator_user_ids?.map(user => user.user_id)
    if(externalCollaboratorId && externalCollaboratorId.includes(this.customStorageService.getItem('id'))) return true; 
    if(leadObj && leadObj?.lead_colloborators_users && leadObj?.lead_colloborators_users?.includes(this.customStorageService.getItem('id'))) return false;
    let roles = dashboardInfo?.role || [];
    if(!roles || roles.length === 0) return true
    if(roles.length == 1 && roles[0] == "READ_ONLY") return true;
    return false;
  }
  async isDashboardTemplate(dashboardId) {
    let isReadOnly = false;
    const dashboardInfo = await this.dashboardUtils.getDashboardInformation([dashboardId])
    let roles = dashboardInfo[dashboardId]?.DASHBOARD_INFO?.role || [];
    let dashType = dashboardInfo[dashboardId]?.DASHBOARD_INFO?.access_type || null;
    if((dashType && dashType?.includes('TEMPLATE')) || (dashType=='INTERNAL' && roles.length == 0)) return true;
    return isReadOnly
  }
  async isLeadOrDashboardReadOnlyOrViewOny(dashboardId,leadObj?) {
    let leadCollabratorsIdArr=leadObj?.lead_colloborators_users || []
    const dashboardInfo = await this.dashboardUtils.getDashboardInformation([dashboardId])
    let roles = dashboardInfo[dashboardId]?.DASHBOARD_INFO?.role || [];
    let priorityRole = roles.length ? ( roles.includes('DASHBOARD_ADMIN') ? 'DASHBOARD_ADMIN' : roles.includes('ACCESS') ? 'ACCESS' :  roles[0] ) : false
    let dashType = dashboardInfo[dashboardId]?.DASHBOARD_INFO?.type || null;
    let json={
      'is_readOnly_user' : priorityRole ? (priorityRole == "READ_ONLY") : false,
      'no_dashboard_user' : roles?.length === 0 || !roles,
      'is_collabrator_user': !leadObj ? false : leadCollabratorsIdArr.includes(this.customStorageService.getItem('id')),
      'is_deleted_lead' : !leadObj ? false : leadObj.is_deleted,
      'dashboard_type': dashType,
      'is_template_board' : dashType?.includes('TEMPLATE'),
      'is_internal_board' :dashType=='BOARD_INTERNAL',
      'is_graph_board' :dashType=='BOARD_GRAPH',
      'dashboard_role' : priorityRole ? priorityRole : null
    }
    json['is_raedOnly']=!json['is_collabrator_user'] && (json['is_readOnly_user'] || json['no_dashboard_user']) || json['deleted_lead'] || json['is_template_board'] || (json['is_internal_board'] && json[ 'no_dashboard_user'])
    return json
  }



  storeDashboardJson(currentDashboardJson: any, dashboardId: any) {
    let dashboardJsonToBeStore = {
      dashboard_name: currentDashboardJson['dashboard_name'],
      role: currentDashboardJson['role'],
    };
    let dashboardJson = JSON.parse(this.customStorageService.getItem('DASHBOARD_DATA'));
    if(!dashboardJson) {
      dashboardJson = {}
    } 
    dashboardJson[dashboardId] = dashboardJsonToBeStore;
    this.customStorageService.setItem('DASHBOARD_DATA', JSON.stringify(dashboardJson));
    return dashboardJson
  }

  async getUserJsonFromUserId(userId) {
    let orgUsersJson = await this.dashboardUtils.getOrgUsers() || {};
    return orgUsersJson[userId]
  }

  async getDashboardUsersFromDashboardId(dashboardId) {
    let orgUsersJson = await this.dashboardUtils.getOrgUsers() || {};
    let dashData=await this.dashboardUtils.getAndSyncAllDashboardData([dashboardId],false,['DashboardUsers']) || {};
    let dashUsers=dashData[dashboardId]?.DASHBOARD_USER || []
    let dashUserjsonArr = [];
    dashUsers .forEach(user => {
      if(orgUsersJson[user._id]) {
        dashUserjsonArr.push(orgUsersJson[user._id])
      }
    })
    return dashUserjsonArr;
  }

  transformwhatsapptohtml(value: string): string {
   if(value){
    const regex = new RegExp(/([*])(?:(?=(\\?))\2.)*?\1/g, 'gi');;
    let str = value.replace(regex, (match) => `<b>${match.replace(/\*/g,'')}</b>`);
     const regex2 = new RegExp(/([_])(?:(?=(\\?))\2.)*?\1/g, 'gi');;
    str = str.replace(regex2, (match) => `<i>${match.replace(/\_/g,'')}</i>`);
    const regex3 = new RegExp(/([~])(?:(?=(\\?))\2.)*?\1/g, 'gi');;
    str = str.replace(regex3, (match) => `<strike>${match.replace(/\~/g,'')}</strike>`);
   /*   const regex4 = new RegExp(/\```([a-z\s+0-9\@]+)```/g, 'gi');;
    str = str.replace(regex4, (match) => `<tt style="font-family : monospace">${match.replace(/\```([a-z\s+0-9\@]+)```/g,'')}</tt>`);
      */
    str = str.replace(/\`{3}(.*?)\`{3}/g, `<tt style="font-family: monospace">$1</tt>`)
    return str
   }
    }

  async getAndStoreDashboardSprints(dashId): Promise<any>{
    let sprintJson  = JSON.parse(this.customStorageService.getItem('SPRINT_DATA'));
    if(!sprintJson) sprintJson = {};
    if(!sprintJson[dashId]){
      let sprintJsonArrayToBeStored = {}
      let results:any = await this.httpTransfer.getSprintCorrespondingtoDashBoard({},dashId).toPromise()
      results.result?.sprintResult.forEach((item) => {
        item["planned_start_date"] = this.datepipe.transform(this.epochToDate(item.planned_start_date), 'd MMM y')
        item["planned_end_date"] = this.datepipe.transform(this.epochToDate(item.planned_end_date), 'd MMM y')
      });
      sprintJsonArrayToBeStored = results.result?.sprintResult;
      sprintJson[dashId] = sprintJsonArrayToBeStored
      this.customStorageService.setItem('SPRINT_DATA', JSON.stringify(sprintJson));
    }
    return sprintJson[dashId];
  }

  trueTypeOf = (obj) => {
    return Object.prototype.toString.call(obj).slice(8, -1).toUpperCase();
  };
 
 async  checkForCustomFieldValueOnLead(item,leadInfo,algorithm?){
  console.log(item)
    let value ;
    let currencyJson;
    let orgUserArr;
    let mediaArr = [];
    let key = item?.key ? item?.key : item
    console.log(key)
    console.log(leadInfo)
    if(['Currency','Mobile'].includes(item.type) || ['Currency','Mobile'].includes(item.mirror_column_type)){
      currencyJson   = await this.dashboardUtils.getCurrencyJson() 
      }
      let orgUsersJson = await this.dashboardUtils.getOrgUsers(); 
       orgUserArr = Object.values(orgUsersJson);  
       if(leadInfo.custom_fields[key]!==undefined && leadInfo.custom_fields[key]!==null){
          if(item.type=='SingleUser' || item.mirror_column_type =='SingleUser'){
             let user = orgUserArr.filter(e=>e._id == leadInfo.custom_fields[key])
             value = user[0].name;
          }
          else if((item.type == 'Currency'  || item.mirror_column_type =='Currency') && leadInfo.custom_fields[key]?.currency){
            let currency = currencyJson.filter(e=>e.code == leadInfo.custom_fields[key].currency)
            value = leadInfo.custom_fields[key]?.amount!=null ? currency[0].text.split(' ').pop() + leadInfo.custom_fields[key]?.amount : currency[0].text.split(' ').pop()
          }
          else if((["Mobile","SingleMobile"].includes(item.type) || ["Mobile","SingleMobile"].includes(item.mirror_column_type)) && leadInfo.custom_fields[key]?.country_code){
            let currency = currencyJson.filter(e=>e.code == leadInfo.custom_fields[key]?.country_code)
            value = leadInfo.custom_fields[key]?.mobile_number!=null ? currency[0].dial_code + leadInfo.custom_fields[key]?.mobile_number : currency[0].dial_code
          }
          else if((item.type == 'Date' || item.mirror_column_type =='Date') && leadInfo.custom_fields[key].date){
            let date = leadInfo.custom_fields[key].is_time_added ?
            this.getHumanReadableDateAndTimeFromDate(new Date(leadInfo.custom_fields[key].time))['date'] +" "+ this.getAgingTimeForHeatMap(leadInfo.custom_fields[key].time)['time'] 
            :  leadInfo.custom_fields[key].date
            value = date
          }else if((item?.type == 'TimeTracker' || item.mirror_column_type =='TimeTracker') && leadInfo.custom_fields[key].time_spend_till_now){
            value = leadInfo.custom_fields[key].time_spend_till_now ? this.timeConvert(leadInfo.custom_fields[key].time_spend_till_now) : '00: 00: 00'
          }else if(item?.type == 'Location' || item.mirror_column_type =='Location'){
            value = leadInfo.custom_fields[key]?.description
          } 
          else if(item.type=='MultiUser' || item.mirror_column_type =='MultiUser'){
            value = value ? value : ""
            if(algorithm=="FETCH_ALL"){
              leadInfo.custom_fields[key]?.forEach(user=>{
                let name = orgUserArr.filter(e=>e._id == user)[0]?.name
                value = !value?.includes(name) ? value + " " + name : value
              })
            }
            else{
              value = algorithm=="FETCH_FIRST" ? orgUserArr.filter(e=>e._id == leadInfo.custom_fields[key][0])[0]?.name : 
              orgUserArr.filter(e=>e._id == leadInfo.custom_fields[key][leadInfo.custom_fields[key]?.length-1])[0]?.name  
            }
          }
          else if(item.type=='MultipleMobile' || item.mirror_column_type =='MultipleMobile'){
            console.log(leadInfo.custom_fields[key])
            value = value ? value : ""
            if(algorithm=="FETCH_ALL"){
              leadInfo.custom_fields[key]?.forEach(m=>{value = value+" " + m?.full_mobile_number})
            }
            else{
              value = algorithm=="FETCH_FIRST" ? leadInfo.custom_fields[key][0]?.full_mobile_number
              : algorithm=="FETCH_LAST" ? leadInfo.custom_fields[key][leadInfo.custom_fields[key]?.length-1]?.full_mobile_number
              : leadInfo.custom_fields[key][0]?.full_mobile_number;
            }
          }
         else if(['MultipleImage','MultipleVideo','MultipleFile','MultipleAudio'].includes(item.type)|| ['MultipleImage','MultipleVideo','MultipleFile','MultipleAudio'].includes(item.mirror_column_type)){
          console.log(leadInfo.custom_fields[key])
          value = algorithm=="FETCH_FIRST" ? this.checkForColumnsMedia([leadInfo.custom_fields[key][0]],item.type) : algorithm=="FETCH_LAST" ? this.checkForColumnsMedia([leadInfo.custom_fields[key][leadInfo.custom_fields[key]?.length-1]],item.type) : this.checkForColumnsMedia(leadInfo.custom_fields[key],item.type)
        }
        else if(['SingleImage','SingleVideo','SingleMultipleFile','SingleAudio'].includes(item.type)|| ['SingleImage','SingleVideo','SingleFile','SingleAudio'].includes(item.mirror_column_type)){
          value = this.checkForColumnsMedia([leadInfo.custom_fields[key]],item.type)
       }
         else if(item.type=='MultiSelect' || item.mirror_column_type =='MultiSelect' || item.type=='Checkbox' || item.mirror_column_type =='Checkbox' || item.type=='MultiUrl' || item.mirror_column_type =='MultiUrl' || item.type=='MultipleEmail' || item.mirror_column_type =='MultipleEmail'){
          value = value ? value : ""
          if(algorithm=="FETCH_ALL"){
            leadInfo.custom_fields[key]?.forEach(m=>{value =!value?.includes(m) ? value+" " + m : value})
          }
          else{
            value = algorithm=="FETCH_FIRST" ? leadInfo.custom_fields[key][0] : algorithm=="FETCH_LAST" ? leadInfo.custom_fields[key][leadInfo.custom_fields[key]?.length-1] : leadInfo.custom_fields[key][0];
          }
         }
         else if(item.type=='CheckList' || item.mirror_column_type =='CheckList'){
          value = value ? value : ""
          if(algorithm=="FETCH_ALL"){
            leadInfo.custom_fields[key]?.forEach(m=>{value= !value?.includes(m) ? value+" "+ m?.summary : value})
          }
          else{
            value = algorithm=="FETCH_FIRST" ? leadInfo.custom_fields[key][0]?.summary : algorithm=="FETCH_LAST" ? leadInfo.custom_fields[key][leadInfo.custom_fields[key]?.length-1]?.summary : leadInfo.custom_fields[key][0]?.summary;
          }
         }
          else{
            value = leadInfo.custom_fields[key]
          }
      
    }
    else{
      value = await this.checkforNonCustomValue(key ,leadInfo)
    }
     return value;
  }

  checkForColumnsMedia(value,fieldType?){
    console.log(value)
    if(fieldType){
       fieldType = fieldType.includes("Multiple") ? fieldType.split("Multiple").pop().toLowerCase() : fieldType.split("Single").pop().toLowerCase();
    }
    let arr = []
    value.forEach(m=>{
      if(m?.type!=='link'){
        let previewData = {};
        previewData["file_name"] = m.file_name;
        previewData["file_path"] =  m.file_path ? m.file_path : m.path;
        previewData["path"] =  m.file_path ? m.file_path : m.path;
        previewData["url"] =  m.file_url ? m.file_url : m.url;
        previewData["image_download_url"] =  m.file_url ? m.file_url : m.url;
        previewData["file_type"] = fieldType ? fieldType.toLowerCase() : m?.type.toLowerCase() //'image' //e.type == 'IMAGE' ? 'image' : e.type == 'VIDEO' ? 'video' : 'document';
        previewData["type"] = fieldType ? fieldType.toUpperCase() : m?.type.toUpperCase() //'image' //e.type == 'IMAGE' ? 'image' : e.type == 'VIDEO' ? 'video' : 'document';
        arr.push(previewData);
      }
    })
    console.log(arr)
    return arr;
  }

  async checkforNonCustomValue(key,leadInfo?){
    console.log(leadInfo)
    console.log(key)
    let tagsCorrespondingToDash;
    let bucketArray;
    let sprintArray;
    let orgUserArr;
    let orgUsersJson = await this.dashboardUtils.getOrgUsers(); 
    orgUserArr = Object.values(orgUsersJson);
     this.dashboardUtils.getUpdatedDashboardJson().subscribe(res => {
      bucketArray = res[leadInfo?.dashboard_id]?.BUCKET || [];
      tagsCorrespondingToDash = res[leadInfo?.dashboard_id]?.TAG || [];
      sprintArray = res[leadInfo?.dashboard_id]?.SPRINT || [];      
    });
    if(key=='phase' && leadInfo.sprint_id){
      let i = sprintArray.findIndex(data=>data._id == leadInfo.sprint_id)
      return sprintArray[i]?.name
    }
    else if((key=='assignee' || key=='assigned_to') && leadInfo.assigned_to){
      let i = orgUserArr.findIndex(data=>data._id == leadInfo.assigned_to)
      return orgUserArr[i]?.name
    }
    else if((key == 'bucketName' || key=='Bucket'  || key=='bucket_id'))
    {
      let i = bucketArray.findIndex(m=>m._id== leadInfo?.bucket_id)
      leadInfo.bucket_name = leadInfo.bucket_name ? leadInfo.bucket_name : bucketArray[i]?.name 
      return leadInfo.bucket_name
    }
    else if((key == 'priority' || key=='Priority')){
      if(leadInfo.priority=='0'){
        return 'Low'
      }
      else if(leadInfo.priority=='1'){
        return 'Medium'
      }
      if(leadInfo.priority=='2'){
        return 'High'
      }
      if(leadInfo.priority=='3'){
        return 'Urgent'
      }
    }
    else if((key == 'summary' || key =='title')){ return leadInfo.title}
    else if((key == 'startDate' || key=='start_date') && leadInfo?.start_date.date && leadInfo?.start_date.is_time_added){ return leadInfo?.start_date?.dateLabel ? leadInfo?.start_date?.dateLabel+"-"+leadInfo?.start_date?.timeLabel : leadInfo?.start_date?.date}
    else if((key == 'startDate' || key=='start_date') && leadInfo?.start_date.date && !leadInfo?.start_date.is_time_added){ return leadInfo?.start_date?.dateLabel ? leadInfo?.start_date?.dateLabel : leadInfo?.start_date?.date}
    else if((key == 'endDate'  || key =='end_date') && leadInfo?.end_date.date && leadInfo?.end_date.is_time_added){ return leadInfo?.end_date?.dateLabel ? leadInfo?.end_date?.dateLabel+"-"+leadInfo?.end_date?.timeLabel : leadInfo?.end_date?.date}
    else if((key == 'endDate' || key =='end_date') && leadInfo?.end_date.date && !leadInfo?.end_date.is_time_added){ return leadInfo?.end_date?.dateLabel ? leadInfo?.end_date?.dateLabel : leadInfo?.end_date?.date}
    else if(key == 'create_date'){ return this.getHumanReadableDateAndTimeFromDate(new Date(leadInfo?.create_date))['date']}
    else if(key == 'update_date'){ return this.getHumanReadableDateAndTimeFromDate(new Date(leadInfo?.update_date))['date']}
    else if(key =='tag_id' && leadInfo.tag_id?.length){
      let i = tagsCorrespondingToDash.findIndex(data=>data._id == leadInfo.tag_id[0])
      return tagsCorrespondingToDash[i].name;
    }
    else{return ''}
  }

  async getConnectedLeadMirrorJson(leadObj,arrOfCustomField){
    let connecteLeadIdArr = []
    Object.keys(leadObj.custom_fields).forEach(key=>{
      if(arrOfCustomField.filter(m=>m.key == key)[0]?.type == 'Board'){
        leadObj.custom_fields[key]?.forEach(e=>{
         if(!connecteLeadIdArr.includes(e)){
          connecteLeadIdArr.push(e)
         }
       })
      }
   })
   let mirrorJson = await this.getConnectedLeads(connecteLeadIdArr);
    return mirrorJson;
  }

  async getConnectedLeads(connecteLeadIdArr){
    let leadObjQuery = {}  
    let mirrorColumnsJson = {} 
    leadObjQuery["lead_id"] = connecteLeadIdArr;
    let res = await this.httpTransfer.getLeadQuery(leadObjQuery).toPromise()
          res.result?.leadResponse.forEach(itemJson=>{
            mirrorColumnsJson[itemJson._id] =  this.creatingJsonStructureOfLeadForTableView(itemJson);
           for(let key in mirrorColumnsJson[itemJson._id]['custom_fields']){
           if(mirrorColumnsJson[itemJson._id]['custom_fields'][key] && mirrorColumnsJson[itemJson._id]['custom_fields'][key]['time_spend_till_now']){
           mirrorColumnsJson[itemJson._id]['custom_fields'][key]['time_spend_till_now_formated']=this.timeConvert(mirrorColumnsJson[itemJson._id]['custom_fields'][key]['time_spend_till_now'])
           }}
          })
     return mirrorColumnsJson;     
   }



   async getMirrorForWhatsapp(item,leadInfo,selectedReceiver?,algorithm?){
    let connectedLeads = []
    let connectedBoardId;
    let value= "";
    Object.keys(leadInfo.custom_fields).forEach(key=>{
      if(item?.key == key){
        connectedLeads = leadInfo.custom_fields[key]?.mirror_item_id
        connectedBoardId = leadInfo.custom_fields[key]?.mirror_dashboard_id
      }
    })
   if(connectedBoardId && connectedLeads?.length){
    let leadArr=connectedLeads
    if(algorithm == "FETCH_LAST"){
      leadArr = []
      leadArr.push(connectedLeads[connectedLeads?.length-1])
    }
   let res = algorithm == "FETCH_LAST" ? await this.httpTransfer.getLeadQuery({"dashboard_id" : [connectedBoardId],"lead_id" : [connectedLeads[connectedLeads?.length-1]]}).toPromise() :
   algorithm == "FETCH_FIRST" ? await this.httpTransfer.getLeadQuery({"dashboard_id" : [connectedBoardId],"lead_id" : [connectedLeads[0]]}).toPromise() :
   await this.httpTransfer.getLeadQuery({"dashboard_id" : [connectedBoardId],"lead_id" : connectedLeads}).toPromise()
  //let filterLead = res.result.leadResponse.filter(e=>e._id == selectedReceiver?.id)
    let filterLead = algorithm == "FETCH_ALL" ? res.result.leadResponse  : [res.result.leadResponse[0]] 
    //console.log(filterLead)
    filterLead.forEach(async x=>{
      if(['title','sprint_id','bucket_id','tag_id','priority','start_date','end_date','assigned_to','created_by','updated_by','create_date'].includes(item.mirror_column_key)){
        value = value +" "+ this.checkforNonCustomValue(item?.mirror_column_key ,x)
      }
      else{
        let currencyJson =[]
        if(item.mirror_column_type == 'Currency' || item.mirror_column_type =='Mobile'){
          currencyJson   = await this.dashboardUtils.getCurrencyJson() 
          }
          value = value  ? value+" "+ await this.checkForCustomFieldValueOnLead(item,x,algorithm) :
          await this.checkForCustomFieldValueOnLead(item,x,algorithm) 
        } 
    })
    return value;
   }
  }

  async getAndStoreAllUserGroups(): Promise<any>{
    let groupJsonArray  = JSON.parse(this.customStorageService.getItem('USERGROUP_DATA'));
    if(!groupJsonArray?.length){
      let groupJsonArrayToBeStored: any = []
      let inputJson = {
        is_private: [true, false],
        group_members: true
      }
      let results:any = await this.httpTransfer.getGroupList(undefined, undefined,undefined,undefined,undefined,inputJson).toPromise()
      let userGroupsFromServer = results['result']['userGroups']
      // let userGroupsFromServer = [];
      userGroupsFromServer.forEach(userGroupTmp => {
        groupJsonArrayToBeStored.push({
          _id: userGroupTmp._id,
          group_name: userGroupTmp.group_name,
          member_ids: userGroupTmp?.group_members?.map(member => member?.user_id)
        })
      })
      groupJsonArray = groupJsonArrayToBeStored
      this.customStorageService.setItem('USERGROUP_DATA', JSON.stringify(groupJsonArray));
    }
    return groupJsonArray
  }
 
  /**
   * 
   * @param dashboardId 
   * @param currentBucketId 
   * @param bucketArr [ {
                  "position": {
                    "left": 534,
                    "top": 413
                  },
                  "bucket_followed_users": [],
                  "is_destination": true,
                  "isDeleted": false,
                  "_id": "62f0eefa7c953d00235e584e",
                  "name": "three",
                  "dashboard_id": "62f0eee77c953d00235e579d",
                  "aging": {
                    "is_applicable": false
                  },
                  "bucketseq": 2,
                  "update_date": 1661271195581,
                  "create_date": 1659956986577,
                  "inbound": {
                    "allowed_buckets": [{
                      "bucket": {
                        "id": "62f0eef27c953d00235e582f",
                        "filter": true,
                        "allowed_users": [],
                        "allowed_groups": [],
                        "user_type": ["ACCESS"],
                        "source_endpoint": "1",
                        "destination_endpoint": "4"
                      }
                    }],
                    "all_buckets": {
                      "filter": true,
                      "allowed_users": ["5f0d9367f1ce534225949efc"]
                    }
                  },
                  "create_lead": {
                    "filter": false
                  },
                  "__v": 0,
                  "updated_by": "5f0d9367f1ce534225949efc"
                }]
   * @param itemJson 
   * @returns 
   */
  async getAllowedBuckets(dashboardId, currentBucketId, bucketArr, itemJson ) {
    let allowedBucketIds: any = [];
    for(let j=0; j<bucketArr.length; j++) {
      let bucketJson = bucketArr[j];
      if (bucketJson.inbound?.all_buckets) {
        let allBucketAllowJson = bucketJson.inbound?.all_buckets
        var currentBucketAllowedForAll = await this.checkIfCurrentBucketIsAllowedForItem(dashboardId,
          bucketJson, itemJson, allBucketAllowJson)
        if (currentBucketAllowedForAll) {
          allowedBucketIds.push(bucketJson._id)
        }
      }

      if (bucketJson.inbound?.allowed_buckets) {
        let allowedBuckets = bucketJson.inbound?.allowed_buckets
        for (let i = 0; i < allowedBuckets.length; i++) {
          let allowedBucketJson = allowedBuckets[i]['bucket']
          if (allowedBucketJson.id === currentBucketId) {
            let currentBucketAllowed = await this.checkIfCurrentBucketIsAllowedForItem(dashboardId,
              bucketJson, itemJson, allowedBucketJson)
            if (currentBucketAllowed) {
              if (!allowedBucketIds.includes(bucketJson._id)) allowedBucketIds.push(bucketJson._id)
            } else {
              if (allowedBucketIds.includes(bucketJson._id)) {
                let index = allowedBucketIds.indexOf(bucketJson._id)
                if (index > -1) allowedBucketIds.splice(index, 1);
              }
            }
            continue
          }
        }
      }
  }
    return allowedBucketIds;
  }

  checkForFieldInMirror(mirrorItemId,currentLead,key,mirrorColumnsJson) {
    if(mirrorColumnsJson[mirrorItemId]  && mirrorColumnsJson[mirrorItemId]?.custom_fields) {
      if(mirrorColumnsJson[mirrorItemId]?.custom_fields.hasOwnProperty(currentLead?.custom_fields[key]?.mirror_column_key)
      && (mirrorColumnsJson[mirrorItemId]?.custom_fields[currentLead?.custom_fields[key]?.mirror_column_key] || mirrorColumnsJson[mirrorItemId]?.custom_fields[currentLead?.custom_fields[key]?.mirror_column_key] === 0)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
  }


  async checkIfCurrentBucketIsAllowedForItem(dashboardId,currentBucketJson,itemJson, allowedBucketJson){
    if(!allowedBucketJson){
      return false
    }
    let allBucketsFilter = allowedBucketJson.filter
    if(!allBucketsFilter){
      return true
    }
    let allowedUserIds = allowedBucketJson.allowed_users
    let allowedGroupIds =  allowedBucketJson.allowed_groups
    let allowedTypes = allowedBucketJson.user_type
    let dashboardInfo = await this.dashboardUtils.getDashboardInformation([dashboardId])
    let roles = dashboardInfo[dashboardId]?.DASHBOARD_INFO?.role || [];
    let currentUserId = this.customStorageService.getItem('id')

    if(allowedUserIds && allowedUserIds.includes(currentUserId)){
      return true
    }
    
    if(allowedTypes){
      for(let i=0;i<allowedTypes.length;i++){
        if(allowedTypes[i] === 'DASHBOARD_ADMIN' && roles.includes('DASHBOARD_ADMIN')){
          return true
        }
        else if(allowedTypes[i] === 'ACCESS' && roles.includes('ACCESS')){
          return true
        }
        else if(allowedTypes[i] === 'ITEM_ASSIGNEE' && itemJson.assigned_to == currentUserId){
          return true
        }
        else if(allowedTypes[i] === 'ITEM_REPORTER' && itemJson.created_by == currentUserId){
          return true
        }
      }
    }
    
    if(allowedGroupIds ){
      let userGroups = await this.getAndStoreAllUserGroups()
      let userGroupsIds = userGroups?.map(user => user._id) || []
      for(let i=0;i<allowedGroupIds.length;i++){
        if(userGroupsIds.includes(allowedGroupIds[i])){
          return true
        }
      }
    }
    return false
  }
  static minLengthWithTrimValidator(controll : FormControl){
    if(controll!=null && controll.value!=null){
      let value=controll.value.toString().trim();
      return (value.length<3 && controll.value!='') ? {isLengthMinimum:true} :null;
    }
    return null;
  }

  getMirrorData(value, mirrorColumnsJson){
    let items = [];
    if(!Object.keys(mirrorColumnsJson)?.length || !value?.mirror_item_id?.length) return items;
    let valueType = value?.mirror_column_type
    if(valueType == 'Bucket'){
      value?.mirror_item_id?.forEach(item => {
        if(mirrorColumnsJson[item])
          items.push({
            seq_id: mirrorColumnsJson[item]?.seq_id,
            item: item,
            value: mirrorColumnsJson[item]?.bucket_name 
          })
      })
    }
    else if(valueType == 'SingleUser'){
      value?.mirror_item_id?.forEach(item => {
        if(mirrorColumnsJson[item])
        if(mirrorColumnsJson[item]?.assigned_to){
          items.push({
            seq_id: mirrorColumnsJson[item]?.seq_id,
            item: item,
            value: mirrorColumnsJson[item]?.assigned_to 
          })
        }
      })
    }else if(valueType == "Priority"){
      value?.mirror_item_id?.forEach(item => {
        if(mirrorColumnsJson[item])
          items.push({
            item: item,
            seq_id: mirrorColumnsJson[item]?.seq_id,
            value: mirrorColumnsJson[item][value?.mirror_column_key]
          })
      })
    }else if(valueType == "Sprint"){
      value?.mirror_item_id?.forEach( item => {
        if(mirrorColumnsJson[item]){
          let phase = mirrorColumnsJson[item]?.sprint_name?.__zone_symbol__value
          if(phase)
            items.push({
              seq_id: mirrorColumnsJson[item]?.seq_id,
              item_id: item,
              value: phase
            })
        }
      })
    }else if(valueType == 'Tag'){
      value?.mirror_item_id?.forEach( item => {
        if(mirrorColumnsJson[item]){
          let tags = mirrorColumnsJson[item]?.tagsName
          if(tags.length>0)
            items.push({
              seq_id: mirrorColumnsJson[item]?.seq_id,
              item_id: item,
              value: tags
            })
        }
      }) 
    }else if(valueType == "Date"){
      if(value?.mirror_column_key == 'start_date' || value?.mirror_column_key == 'end_date' )
        value?.mirror_item_id?.forEach( item => {
          if(mirrorColumnsJson[item]){
            let date = mirrorColumnsJson[item][value?.mirror_column_key]?.time
            if(date)
              items.push({
                seq_id: mirrorColumnsJson[item]?.seq_id,
                item_id: item,
                value: date,
                dateLabel: mirrorColumnsJson[item][value?.mirror_column_key]?.dateLabel
              })
          }
        }) 
      else
        value?.mirror_item_id?.forEach( item => {
          if(mirrorColumnsJson[item]){
            let date = mirrorColumnsJson[item]?.custom_fields[value?.mirror_column_key]?.time
            if(date)
              items.push({
                seq_id: mirrorColumnsJson[item]?.seq_id,
                item_id: item,
                value: date,
                dateLabel: mirrorColumnsJson[item]?.custom_fields[value?.mirror_column_key]?.dateLabel
              })
          }
        }) 
    }else if(valueType == "Board"){
      items = value?.mirror_item_id;
    }else if(valueType == "Url"){
      value?.mirror_item_id?.forEach( item => {
        if(mirrorColumnsJson[item] && mirrorColumnsJson[item]?.custom_fields){
          let url = mirrorColumnsJson[item]?.custom_fields[value?.mirror_column_key]
          if(url)
            items.push({
              seq_id: mirrorColumnsJson[item]?.seq_id,
              item_id: item,
              value: url,
              validatedUrl: !url?.includes('http') ? 'http://' + url : url,
            })
        }
      }) 
    }else if(['Location', 'Country', 'TimeTracker'].includes(valueType)){
      value?.mirror_item_id?.forEach( item => {
        if(mirrorColumnsJson[item] && mirrorColumnsJson[item]?.custom_fields){
          let location = mirrorColumnsJson[item]?.custom_fields[value?.mirror_column_key]
          if(location)
            items.push({
              seq_id: mirrorColumnsJson[item]?.seq_id,
              item_id: item,
              value: location
            })
        }
      })     
    }else{
      value?.mirror_item_id?.forEach( item => {
        if(mirrorColumnsJson[item]){
          let mirrorData = null;
          if(mirrorColumnsJson[item][value?.mirror_column_key] || mirrorColumnsJson[item][value?.mirror_column_key] == 0)
            mirrorData = mirrorColumnsJson[item][value?.mirror_column_key];
          else if(mirrorColumnsJson[item]?.custom_fields && (mirrorColumnsJson[item]?.custom_fields[value?.mirror_column_key] || mirrorColumnsJson[item]?.custom_fields[value?.mirror_column_key] == 0))
            mirrorData = mirrorColumnsJson[item]?.custom_fields[value?.mirror_column_key];
          if((mirrorData || mirrorData == 0) && (!Array.isArray(mirrorData) || (Array.isArray(mirrorData) && mirrorData.length)))
            items.push({
              seq_id: mirrorColumnsJson[item]?.seq_id,
              item_id: item,
              value: mirrorData
            })
        }
      })
    }
    return items;
  }


  async getPossibleBucketsToWhichUserHasCreatePermission(bucketArray, dashboardUserRole){
    let userId = this.customStorageService.getItem("id")
    let userGroupIds = await this.getAndStoreAllUserGroups()
    if(userGroupIds?.length) 
      userGroupIds = userGroupIds.filter(group => group?.member_ids?.includes(userId))?.map(user => user?._id)
    let allowedBuckets = bucketArray?.filter(bucket => !bucket?.create_lead?.filter 
      || bucket?.create_lead?.allowed_users?.includes(userId) 
      || bucket?.create_lead?.user_type?.find(type => dashboardUserRole?.includes(type))
      || bucket?.create_lead?.allowed_groups?.find(groupId => userGroupIds?.includes(groupId))) 
    return allowedBuckets
  }

  async geteBucketsUserHasCreatePermission(dashId,dashboardUserRole,bucketId?){
    let bucketArray
    this.dashboardUtils.getUpdatedDashboardJson().subscribe(res => {
      bucketArray = res[dashId]?.BUCKET || [];   
    });
    let allowedBuckets = await this.getPossibleBucketsToWhichUserHasCreatePermission(bucketArray, dashboardUserRole);
    let createLeadBucketIds = allowedBuckets?.map(bucket => bucket._id);
    if (bucketId && createLeadBucketIds.includes(bucketId)){
      return true
    }
    else if(!bucketId && createLeadBucketIds.includes(bucketArray[0]?._id)){
      return true
    }
    return false
  }

  getDateObjectForLead(longDate){
    let date = moment(longDate)
    let dateJson= !(date.valueOf()) ? {} : {
      date: date.format("DD/MM/YYYY"),
      is_time_added: false,
      time: date.valueOf()
    }
    return dateJson
  }

  getSelectedEmailBody(value,selectedEmail,defaultSignatures,signatureId,signatureSeparator){
    let notNewSignature=''
    if(value){
      notNewSignature='notNewSignature'
    }
    if(!value) value = ''
    if(!value?.includes(`signature-${signatureId}`)){
      if(selectedEmail?._id && defaultSignatures?.defaultReplyEmailSignature?._id && notNewSignature=='notNewSignature'){
        value += `${signatureSeparator} <span class="signature-${signatureId}"> ${defaultSignatures?.defaultReplyEmailSignature?.signature} </span>`
      }else if(!selectedEmail && (defaultSignatures?.defaultNewEmailSignature?._id || defaultSignatures?.defaultNewEmailSignature?._id==undefined) && notNewSignature==''){ 
        value += `${signatureSeparator} <span class="signature-${signatureId}"> ${defaultSignatures?.defaultNewEmailSignature?.signature? defaultSignatures?.defaultNewEmailSignature?.signature:''} </span>`    
      }
    }
    return value;
  }

  async getDataFromFilterObjectToCreateLead(filterDataObj, customFields, bucketArray, dashboardUserRole){
    let selectedBucketId = null
    let allowedBuckets = await this.getPossibleBucketsToWhichUserHasCreatePermission(bucketArray, dashboardUserRole)
    if(!allowedBuckets?.length) 
      return [{}, 'User has no permission to create lead']
    else 
      allowedBuckets = allowedBuckets?.map(bucket => bucket._id)
    if(filterDataObj?.bucket_id?.length){
      selectedBucketId = filterDataObj?.bucket_id?.find(bucket => allowedBuckets.includes(bucket));
      if(!selectedBucketId) return [{}, "Please choose 'Add Item' to create lead"]
    }else{
      const dashboardInfo = await this.dashboardUtils.getDashboardInformation(filterDataObj.dashboard_id)
      let defaultBucket = filterDataObj.dashboard_id.length>0 ? dashboardInfo[filterDataObj.dashboard_id[0]]?.DASHBOARD_INFO?.default_bucket_id : null;
      selectedBucketId= allowedBuckets?.includes(defaultBucket) || allowedBuckets.length==0 ? defaultBucket : allowedBuckets[0]
    }
    let inputJson: any = {
      bucket_id: selectedBucketId,
      priority: 1, 
      assigned_to: null,
      ...(filterDataObj?.assigned_to?.length && {assigned_to: filterDataObj?.assigned_to[0]}),
      ...(filterDataObj?.priority?.length && {priority: filterDataObj?.priority[0]}),
      ...(filterDataObj?.sprint_id?.length && {sprint_id: filterDataObj?.sprint_id[0]}),
      ...(filterDataObj?.tag_id?.length && {tag_id: filterDataObj?.tag_id}),     
      ...(filterDataObj?.start_date && Object.keys(filterDataObj?.start_date)?.length && (filterDataObj?.start_date?.from || filterDataObj?.start_date?.to) && {
          start_date: filterDataObj?.start_date?.from ? this.getDateObjectForLead(filterDataObj?.start_date?.from) : this.getDateObjectForLead(filterDataObj?.start_date?.to)
        }),
      ...(filterDataObj?.due_date && Object.keys(filterDataObj?.due_date)?.length && (filterDataObj?.due_date?.from || filterDataObj?.due_date?.to) && {
          end_date: filterDataObj?.due_date?.from ? this.getDateObjectForLead(filterDataObj?.due_date?.from) : this.getDateObjectForLead(filterDataObj?.due_date?.to)
        }),  
    }
    if(inputJson.assigned_to && inputJson.assigned_to=="{{EVAL_LOGGED_IN_USER}}"){
      inputJson.assigned_to=this.customStorageService.getItem('id')
    }
    
    if(filterDataObj?.custom_column && Object.keys(filterDataObj?.custom_column)?.length){
      let inputCustomFields: any = {}
      customFields?.forEach(field => {
        if(field.key in filterDataObj?.custom_column){
          if(Array.isArray(filterDataObj?.custom_column[field.key]) && filterDataObj?.custom_column[field.key]?.length){ 
            inputCustomFields[field.key] = this.arrayTypeCustomFields.includes(field.type) ? [filterDataObj?.custom_column[field.key][0]] : filterDataObj?.custom_column[field.key][0]
          }else if(typeof filterDataObj?.custom_column[field.key] === 'object' && Object.keys(filterDataObj?.custom_column[field.key])?.length){
            if(field.type != 'Date')
              inputCustomFields[field.key] = filterDataObj?.custom_column[field.key]?.from ? filterDataObj?.custom_column[field.key]?.from : filterDataObj?.custom_column[field.key]?.to
            else
              inputCustomFields[field.key] = filterDataObj?.custom_column[field.key]?.from ? this.getDateObjectForLead(filterDataObj?.custom_column[field.key]?.from) : this.getDateObjectForLead(filterDataObj?.custom_column[field.key]?.to)
          }else if(field.type == 'IPAddress'){
            if(field.settings == 'AutoPickWhenCreate' || field.settings == 'AutoPickWhenUpdate')
              delete inputCustomFields[field.key] 
          }
          if(['','null','undefined','[]','{}'].includes(JSON.stringify(inputCustomFields[field.key]))) {
              delete inputCustomFields[field.key] 
          }
        }
      })
      inputJson.custom_fields = inputCustomFields;
    }

    return [inputJson, null]

  }
  async createQuickItemOrNot(dashId){
    let setquickTask =true;
    let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(dashId)
    let res = JSON.parse(JSON.stringify(dashboardJson[dashId]['CUSTOM_FORM']));
    if(res.length){
      for (var k = 0; k < res.length; k++) {
        if (
          res[k].mandatory == true && 
          (res[k].default_value === "" ||
            res[k].default_value == undefined) ||
            !res[k].is_default_enabled &&
            res[k].mandatory == true
        ) {
          setquickTask = false;
          return setquickTask
          break;
        } else {
          setquickTask = true
        }
      }
    }
    return setquickTask;
  }


isFileURLExpired(fileUrl){
  const url = new URL(fileUrl);
  const params = new URLSearchParams(url.search); 
  const creationDate = parseISO(params.get('X-Amz-Date'));
  const expiresInSecs = Number(params.get('X-Amz-Expires'));
  const expiryDate = addSeconds(creationDate, expiresInSecs);
  return expiryDate < new Date();
}

formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'  
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(decimals < 0 ? 0 : decimals))} ${this.mediaSizes[i]}`
}
//20kb 20kb-40kb 41kb
getFileChunk(file: File, chunkNumber){
  let totalFileSize = file.size;
  let byteIndex=this.FILE_CHUNK_MAX_SIZE*chunkNumber
  let byteEnd = Math.min(byteIndex+this.FILE_CHUNK_MAX_SIZE,totalFileSize)
  const chunk = file.slice(byteIndex, byteEnd);
  return chunk;
}

getFileTotalChunks(file: File){
  let totalFileSize = file.size;
  let totalChunks = Math.ceil(totalFileSize/this.FILE_CHUNK_MAX_SIZE)
  return totalChunks
}
async getFileSizeFromUrl(url){
  let res:any = await this.httpTransfer.getQueryForFileSize(url,false).toPromise()  
  return res?.size || 0
}
async onUploadLargeFiles(event,updateCustomType, callbackFunctionOnEachUpload = null, callbackFunctionAfterAllUpload = null, callbackParams = {}){
  let allUploadResult = []
  for (let file of event) {
    let orgId = this.customStorageService.getItem("organisationid");
    var inputJson = {};
    inputJson["file_name"] = file.name?.lastIndexOf(".") != -1 ? file.name?.substring(0,file.name?.lastIndexOf(".")) : file.name;
    inputJson["extension"] = file.name.split(".").pop();
    inputJson["type"] = "LEAD";
    inputJson['part_number']=1;
    inputJson['file_type']=file?.type?.length ? file.type:'application/'+inputJson["extension"];
    inputJson['media_type']=inputJson['file_type'].split("/")[0]
    if (!event) {
      return;
    }
    let uploadArray =[];
    let filePath = null;
    let totalChunks = this.getFileTotalChunks(file);
    //500kb/20 =25partts each of 20kb
    for(let i=0;i<totalChunks;i++){
      let chunk = this.getFileChunk(file,i);
      let chunkUploadResponse:any= await this.httpTransfer.largefileUploadForOrg(orgId, inputJson).toPromise();
      chunkUploadResponse = chunkUploadResponse.result.response;  
      if(i==0){
        inputJson['upload_id']=chunkUploadResponse.upload_id;;
        inputJson['image_path']=chunkUploadResponse.image_path;
        filePath= chunkUploadResponse.image_path;
      }
      let result:any = await this.httpTransfer.fileUploadOnAWSServer(chunkUploadResponse.image_upload_url,chunk).toPromise();
      uploadArray.push({
        ETag:result.header.get('etag'),
        PartNumber:inputJson['part_number']
       })
     await  this.informServerAboutUploadStatus(inputJson['upload_id'],inputJson['part_number'],false);
      inputJson['part_number']= inputJson['part_number']+1;
    }
    if(totalChunks===uploadArray.length){
      inputJson['parts']=uploadArray;
      inputJson['mark_completed']=true;
      let response = await  this.httpTransfer.largefileUploadForOrg(orgId,inputJson).toPromise();
      response=response.result?.response;
      await  this.informServerAboutUploadStatus(inputJson['upload_id'],inputJson['part_number'],true);
      if(callbackFunctionOnEachUpload!==null){
      callbackFunctionOnEachUpload(filePath,file.name,callbackParams,response.image_download_url)
      }
      allUploadResult.push({file_path: filePath,file_name: inputJson['file_name'],image_download_url: response.image_download_url, media_type : inputJson['media_type'] })
    } 
  }
  if(callbackFunctionAfterAllUpload!==null){
  callbackFunctionAfterAllUpload(allUploadResult,callbackParams);
  }
}
async informServerAboutUploadStatus(uploadId,currentUploadedpartNumber,isCompleted){
await this.httpTransfer.updateMultipartInfo(uploadId,{
  completed_upload_for_part:currentUploadedpartNumber,
  ...(isCompleted) && {mark_completed:isCompleted}
}).toPromise();
}

/*time converter*/ 
timeConvert(time){
  if(typeof(time)=='number'){
    let days=Math.floor(time/86400000);
    let remainder=time%86400000;
    let hours=Math.floor(remainder/3600000);
    let remainderOfHours=remainder%3600000;
    let minutes=Math.floor(remainderOfHours/60000);
    let remainderOfMinutes=remainderOfHours%60000;
    let seconds=Math.floor(remainderOfMinutes/1000);
    return `${days==0? '': days+':'} ${hours>=10? hours:'0'+hours} : ${minutes>=10? minutes:'0'+minutes} : ${seconds>=10? seconds:'0'+seconds}`
  }
  else{
    return '00:00:00'
  }
}

checkSupportVideoExtension(extension){
  return this.SupportVideoExtension.includes(extension)
}

handleExistingItemAttributes(existingAttributes: any[]) {
  let itemAttributesMapObj = {};
  
  existingAttributes.forEach(attribute => {
    if(attribute.key === "title" || attribute.key === "priority" || attribute.key === "assigned_to" || attribute.key === "start_end_date" || attribute.key === "tag_id"  || attribute.key === "bucket_id") {
      let itemAttributes = {};
      itemAttributes["label"] = attribute.display_key;
      itemAttributes["hidden"] = attribute.hidden;
      itemAttributesMapObj[attribute.key] = itemAttributes
    }
  });  
  return itemAttributesMapObj
}

checkSupportAudioExtension(extension){
  return this.SupportAudioExtension.includes(extension)
}

  async checkMediaPermission() {
    let isPermission = false;
    try {
      await requestMediaPermissions({ audio: true })
      isPermission = true;
    }
    catch (err){
      const { type, name, message } = err;
      if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
        isPermission = false;
      } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
        isPermission = false;
        // user didn't allow app to access camera or microphone
      } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
        isPermission = false;
        // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
        // (mostly Windows specific problem)
      } else {
        isPermission = false;
        // not all error types are handled by this library
      }
    }
      return isPermission;
  }

  // getActiveTimeTrackerList(){
  //   return this.activeTrackerList.asObservable()
  // }

  async queryActiveTimeTrackerList(dashId, userId){
    let response = await this.httpTransfer.queryTimeTracker({
      "dashboard_id":dashId,
      "for_user_id":[userId],
      "without_end_time":true
    }).toPromise().then((res:any) =>{
      return res.result.message
    })
    return response
  }

  getTypeOfVar(value){
    return Object.prototype.toString.call(value).slice(8, -1).toUpperCase()
  }
  setFavIconAndTitle(logoPath="assets/pronnel_notification& nav bar icon/1x/@pronnel_iconArtboard 16.png",title="Pronnel"){
    document.getElementById("appFavIcon")?.setAttribute("href",logoPath)
    document.getElementById("appTitle").innerText=title;
  }
  onSearchCodeCurrency(term: string, item: any) {
      return (item.code?.toLowerCase().includes(term.toLowerCase()) ||item.dial_code?.includes(term) || item.currencyCode?.toLowerCase().includes(term.toLowerCase()) || item.name?.toLowerCase().includes(term.toLowerCase()));
  }
  
  async getOrgLogoAndData(){
    let orgId=this.customStorageService.getItem('organisationid')
    let orgLogoJson=null
    let orgJson=JSON.parse(this.customStorageService.getItem('organisationData') || '{}');
    if(!this.customStorageService.getItem('organisationData') || orgJson?.logo=='' || orgJson?.organisation_id!=orgId){
      let res=await this.httpTransfer.getParticularOrgData(orgId).toPromise();
      let urlPath=null
      if(res?.logo) urlPath= await this.getBase64FromUrl(res?.logo)
      orgLogoJson={
        organisation_id:orgId,
        orgLogoName:res.name_in_short,
        overwritePronnelLogo : res.overwrite_pronnel_logo,
        logo: urlPath
      }
      this.customStorageService.setItem('organisationData',  JSON.stringify(orgLogoJson));
    }else{
      orgLogoJson=JSON.parse(this.customStorageService.getItem('organisationData') || '{}')
    }
    if(!orgLogoJson?.overwritePronnelLogo){
      orgLogoJson={
        logo:"assets/pronnel_notification& nav bar icon/1x/@pronnel_iconArtboard 16.png",
        orgLogoName:"Pronnel"
      }
    }
    if(orgLogoJson?.overwritePronnelLogo && orgLogoJson?.logo && orgLogoJson?.orgLogoName){
      this.setFavIconAndTitle(orgLogoJson?.logo,orgLogoJson?.orgLogoName)
    }
    return orgLogoJson
}

downloadImage(url,name,extension) {
  const imageUrl = url;
  this.httpClient.get(imageUrl, { responseType: 'blob' })
    .subscribe(response => {
      const blob = new Blob([response], { type: 'image/jpeg' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name+(extension ? "." : "");
      a.click();
      window.URL.revokeObjectURL(url);
    });
}


getDateText(givenDate){
  const day= givenDate.getDate()
  const month = this.monthNames[givenDate.getMonth()];
  const year = givenDate.getFullYear();
  const dateString= `${day} ${month} ${year}`
  return dateString
}

calculateEvalDate(dateObj){
  let tempRelDate=new Date()
  let relativeDate = new Date(tempRelDate.getTime())
  relativeDate.setDate(tempRelDate.getDate() + parseInt(dateObj?.relative_date?.day));
  relativeDate.setMonth(tempRelDate.getMonth() + parseInt(dateObj?.relative_date?.month));
  relativeDate.setFullYear(tempRelDate.getFullYear() + parseInt(dateObj?.relative_date?.year));

  let tempEvalDate= new Date(tempRelDate.getTime())

  // old logic- keeping this code if new code has any bug (newly updated logic is compared with backend logic)
    // if(dateObj.year.value=='{{relative_year}}'){
    //   tempEvalDate.setFullYear(tempRelDate.getFullYear())
    // }
    // else{
    //   tempEvalDate.setFullYear(dateObj.year.value);
    // }   
    // if(dateObj.month.value=='{{relative_month}}'){
    //   tempEvalDate.setMonth(tempRelDate.getMonth())
    // }
    // else{
    //   tempEvalDate.setMonth(dateObj.month.value - 1);
    // }
    // if(dateObj.day.value=='{{relative_day}}'){
    //   tempEvalDate.setDate(tempRelDate.getDate())
    // }
    // else{
    //   // tempEvalDate.setDate(dateObj.day.value);
    //   if(dateObj.month.value=='{{relative_month}}'){
    //     const currentMonth = tempEvalDate.getMonth();
    //     const daysInMonth = new Date(tempEvalDate.getFullYear(), currentMonth+ 1, 0).getDate();

    //     if (dateObj.day.value > daysInMonth) {
    //       // Limit the day to the maximum days in the current month
    //       tempEvalDate.setDate(daysInMonth);
    //     } else {
    //       tempEvalDate.setDate(dateObj.day.value);
    //     }

    //   }else {
    //     tempEvalDate.setDate(dateObj.day.value);
    //   }

    // }
    

    if (dateObj.year && dateObj.year.value==='{{relative_year}}') {
        tempEvalDate.setFullYear(tempEvalDate.getFullYear() + (dateObj.relative_date.year || 0))
      }
    
      if (dateObj.month && dateObj.month.value==='{{relative_month}}') {
        tempEvalDate.setMonth(tempEvalDate.getMonth() + (dateObj.relative_date.month  || 0));
      }
    
      if (dateObj.day && dateObj.day.value==='{{relative_day}}') {
        tempEvalDate.setDate(tempEvalDate.getDate() + (dateObj.relative_date.day  || 0));
      }
    
      if (dateObj.year && dateObj.year.value!=='{{relative_year}}') {
        tempEvalDate.setFullYear(dateObj.year.value);
      }
    
      if (dateObj.month && dateObj.month.value!=='{{relative_month}}') {
        tempEvalDate.setMonth(dateObj.month.value - 1);
      }
    
      if (dateObj.day && dateObj.day.value!=='{{relative_day}}') {
        if(dateObj.month.value=='{{relative_month}}'){
          const currentMonth = tempEvalDate.getMonth();
          const daysInMonth = new Date(tempEvalDate.getFullYear(), currentMonth+ 1, 0).getDate();
      
          if (dateObj.day.value > daysInMonth) {
            // Limit the day to the maximum days in the current month
            tempEvalDate.setDate(daysInMonth);
          } else {
            tempEvalDate.setDate(dateObj.day.value);
          }
      
        }else {
          tempEvalDate.setDate(dateObj.day.value);
        }
      }


    let weekday_adjust=dateObj.weekday_adjust
  
  if (weekday_adjust && weekday_adjust.value && weekday_adjust.value!==0){

    this.adjustWeekdayFunction(tempEvalDate,weekday_adjust)
  }

    let evalDate = tempEvalDate

    let obj={
      'relative_date':relativeDate,
      'evaluated_date':evalDate
    }
    return obj

  }


adjustWeekdayFunction(tempEvalDate,weekday_adjust){
  let currentDayOfWeek = tempEvalDate.getDay(); // 0 for Sunday, 1 for Monday, etc.
  currentDayOfWeek++
  let weekDayValue = Math.abs(weekday_adjust.value)

  if(currentDayOfWeek===weekDayValue)  return tempEvalDate.getTime();

  if(weekday_adjust.value>=1){
      let difference =0
      if(weekDayValue>currentDayOfWeek){
          difference = weekDayValue - currentDayOfWeek
      }else{
          while(currentDayOfWeek<7){
              currentDayOfWeek++
              difference++
          }
          difference = difference + weekDayValue
      }
      tempEvalDate.setDate(tempEvalDate.getDate() + difference);
  }
  else{
      let difference =0
      if(weekDayValue<currentDayOfWeek){
          difference = currentDayOfWeek -weekDayValue
      }else{
          while(currentDayOfWeek>0){
              currentDayOfWeek--
              weekDayValue--
              difference++
          }
          difference = difference + weekDayValue -1
      }
      tempEvalDate.setDate(tempEvalDate.getDate() - difference);
    }
}


calcFiscalPeriod(dateObj) {
  let currentDate=new Date()
  const { start_day, start_month } = dateObj.fiscal_year
  let year = currentDate.getMonth()<start_month? currentDate.getFullYear()-1 : currentDate.getFullYear();
  let fiscalYearStartDate =  new Date(year, start_month-1, start_day); 

  const quarterSize = dateObj.filter_cycle.month

  let currentQuarter = 0
  let startDate =  new Date(fiscalYearStartDate.getTime())
  startDate.setMonth(startDate.getMonth() + quarterSize);
  while(currentDate>=startDate){
      startDate.setMonth(startDate.getMonth() + quarterSize);
      currentQuarter++
  }
  let quarterWeNeed = currentQuarter + dateObj.eval_period.relative_period
  let monthsPassed = quarterWeNeed * quarterSize

  let quarterStart =  fiscalYearStartDate.setMonth(fiscalYearStartDate.getMonth() + monthsPassed);
  if(dateObj.equivalent_period_till_today) {
      let monthsPassed = currentQuarter * quarterSize
      let curentQuarterStartDate =  new Date(year, start_month-1, start_day); 
      curentQuarterStartDate.setMonth(curentQuarterStartDate.getMonth() + monthsPassed);
      const monthsDifference =  currentDate.getMonth() - curentQuarterStartDate.getMonth()
      fiscalYearStartDate.setMonth(fiscalYearStartDate.getMonth() + monthsDifference);
      let validMonth = fiscalYearStartDate.getMonth()
      fiscalYearStartDate.setDate(currentDate.getDate())
      if (fiscalYearStartDate.getMonth() !== validMonth) {
          fiscalYearStartDate.setDate(0);
      }
  }else{
      fiscalYearStartDate.setMonth(fiscalYearStartDate.getMonth() + quarterSize);
      fiscalYearStartDate.setDate(fiscalYearStartDate.getDate() - 1);
  }
  let quarterEnd = fiscalYearStartDate.setHours(23, 59, 0, 0)
  return {
      from: quarterStart,
      to : quarterEnd
  }
} 

isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}
getDateObj(time,isTimeAdded=false){
  let dateObj={}
  let dateWithoutTime=new Date(time)
  dateWithoutTime.setHours(0,0,1,0)
  dateObj['time']=dateWithoutTime.getTime()
  dateObj['date']=this.calculateDaysSince1900(time)
  dateObj['is_time_added']=isTimeAdded
  if(isTimeAdded){
    dateObj['time']=time
    dateObj['day_time']=time-dateWithoutTime?.getTime()
  }
  return dateObj
}

calculateDaysSince1900(timestamp, isCeil?) {
    const dateFromTimestamp = new Date(timestamp);
    // get month year and date 
    let year=dateFromTimestamp.getFullYear()
    let month=dateFromTimestamp.getMonth()+1
    let day=dateFromTimestamp.getDate()
    var days = 0;
    // Number of days in each month (non-leap year)
    var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    // Calculate days for years between 1900 and the input year
    for (var y = 1900; y < year; y++) {
        days += this.isLeapYear(y) ? 366 : 365;
    }
    // Calculate days for months of the input year
    for (var m = 1; m < month; m++) {
        days += daysInMonth[m - 1];
    }
    // Adjust for leap year if the input year is a leap year and input month is after February
    if (this.isLeapYear(year) && month > 2) {
        days++;
    }
    // Add days of the input month
    days += day - 1; // Adjust for January 1, 1900, being day 0
    return days;
}

calculateTimestampSince1900(days,bool=false){
    var year = 1900;
    var month = 1;
    var day = 1;
    var daysInMonth;
    var currentDatetime=new Date()
    while (days > 0) {
        var monthdaysList = [31, 28 + (this.isLeapYear(year) ? 1 : 0), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        daysInMonth =monthdaysList[month - 1];
        // If the remaining days are more than the days in the current month
        if (days >= daysInMonth) {
            days -= daysInMonth;
            month++;
            // If the month exceeds 12, move to the next year
            if (month > 12) {
                month = 1;
                year++;
            }
        } else {
            // If the remaining days are less than the days in the current month,
            // set the day and exit the loop
            day += days;
            days = 0;
        }
    }
    let currentDate=new Date(year,month-1,day)
    if(bool){
      currentDate.setHours(currentDatetime.getHours(),currentDatetime.getMinutes(),currentDatetime.getSeconds(),currentDatetime.getMilliseconds())
    }
    return currentDate.getTime();

}

convertDayTime(timestamp) {
  const twelveNoon = new Date(timestamp);
  twelveNoon.setHours(0, 0, 0, 0);
  return timestamp - twelveNoon.getTime()
}

htmlToPlainText(htmlText: string){
  htmlText = htmlText?.replace(/&nbsp;/g, ' ').replace(/<p>/g, '').replace(/<\/p>/g, '\n');
    htmlText = htmlText?.replace(/<[^>]+>/g,'').replace(/<\/[^>]+>/g,'');
  return htmlText;
}

 compareObjects(obj, arrListObj) {
    if(obj?.receivedTime){
      return obj?.receivedTime - arrListObj?.receivedTime
    }
    else{
      return obj?.sentTime - arrListObj?.sentTime
    }
  }
  
getHierachyChild(users,parentUserId,allRelatedChildId=[]){
  let userdata=Object.values(users || {})?.filter(user=>user['parent_users'])
  allRelatedChildId.push(parentUserId)
  userdata.forEach(user=>{
    user['parent_users']?.forEach(parent=>{
      if(parent.user_id==parentUserId){
        this.getHierachyChild(userdata,user['_id'],allRelatedChildId)
      }
    })
  })
  return allRelatedChildId
}


async chatLive(message,dashId,chatUsersArr,selectedChatUserMsgarr,selectedChannelObj,commentArr?,disableEditor?){
  let tempIndex;
  if(message[ConstantService.constant.ACTIVITYTYPEKEY]=='UPDATE' && message?.additional_attributes['updated_keys']?.length && message?.additional_attributes['updated_keys'][0]=="status"){
    let selectedIndex = selectedChatUserMsgarr.findIndex(x=>x?._id == message?.additional_attributes?.details?._id && x?.channel_id == message?.additional_attributes?.details?.channel_id && x?.lead_id == message?.additional_attributes?.details?.lead_id)
    if(selectedIndex>=0){
      selectedChatUserMsgarr[selectedIndex]['status'] = message?.additional_attributes?.details?.status
    message?.additional_attributes?.details?.status_details?.received_time ?
    selectedChatUserMsgarr[selectedIndex]['receivedTime']  = this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.received_time))['date'] ? this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.received_time))['date'] +" "+ this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.received_time)['time'] : this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.received_time)['time'] 
    : selectedChatUserMsgarr[selectedIndex]['sentTime']  = this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.sent_time))['date'] ? this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.sent_time))['date'] +" "+ this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.sent_time)['time'] : this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.sent_time)['time']   
    if(message?.additional_attributes?.details.status_details?.delivered_time) {
      selectedChatUserMsgarr[selectedIndex]['deliverTime']  = this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.delivered_time))['date'] ? this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.delivered_time))['date'] +" "+ this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.delivered_time)['time'] : this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.delivered_time)['time'] 
    }
    else  if(message?.additional_attributes?.details?.status_details?.read_time) {
      selectedChatUserMsgarr[selectedIndex]['readTime']  = this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.read_time))['date'] ? this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.read_time))['date'] +" "+ this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.read_time)['time'] : this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.read_time)['time'] 
    }
    else  if(message?.additional_attributes?.details?.status_details?.deleted_time) {
      selectedChatUserMsgarr[selectedIndex]['deletedTime']  = this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.deleted_time))['date'] ? this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.deleted_time))['date'] +" "+ this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.deleted_time)['time'] : this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.deleted_time)['time'] 
    }
    }
    let statusIndex = commentArr?.findIndex(x=>x?._id == message?.additional_attributes?.details?._id && x?.lead_id == message?.additional_attributes?.details?.lead_id && x?.channel_id == message?.additional_attributes?.details?.channel_id && x?.chat_user_id == message?.additional_attributes?.details?.chat_user_id)
    if(statusIndex>=0){
      commentArr[statusIndex].status =  message?.additional_attributes?.details?.status
      if(message?.additional_attributes?.details?.status_details?.deleted_time) {
        commentArr[statusIndex]['deletedTime']  = this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.deleted_time))['date'] ? this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.deleted_time))['date'] +" "+ this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.deleted_time)['time'] : this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.deleted_time)['time'] 
      }
    } 
  }
  else{
    message?.additional_attributes?.details?.status_details?.received_time ? 
    message.additional_attributes.details['receivedTime']  = this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.received_time))['date'] ? this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.received_time))['date'] +" "+ this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.received_time)['time'] : this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.received_time)['time'] 
    : message.additional_attributes.details['sentTime']  = this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.sent_time))['date'] ? this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.sent_time))['date'] +" "+ this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.sent_time)['time'] : this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.sent_time)['time'] 
    if(message?.additional_attributes?.details?.status_details?.delivered_time) {
     message.additional_attributes.details['deliverTime']  = this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.delivered_time))['date'] ? this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.delivered_time))['date'] +" "+ this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.delivered_time)['time'] : this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.delivered_time)['time'] 
   }
   else  if(message?.additional_attributes?.details?.status_details?.read_time) {
     message.additional_attributes.details['readTime']  = this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.read_time))['date'] ? this.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.status_details?.read_time))['date'] +" "+ this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.read_time)['time'] : this.getAgingTimeForHeatMap(message?.additional_attributes?.details?.status_details?.read_time)['time'] 
   }
   let selectedchatUser = chatUsersArr.filter(m=>m._id == message?.additional_attributes?.details?.chat_user_id)
   message.additional_attributes.details['chatUserName'] = selectedchatUser[0]?.name
   message.additional_attributes.details['profile_pic'] = selectedchatUser[0]?.profile_pic
   if(!selectedchatUser?.length){
     let res = await this.httpTransfer.getChatUserQuery({'dashboard_id' : [dashId],'channel_id':message?.additional_attributes?.details?.channel_id}).toPromise()
     let chatUser = res.result?.chatUsers?.filter(m=>m._id==message?.additional_attributes?.details?.chat_user_id)
     message.additional_attributes.details['chatUserName'] = chatUser[0]?.name
   message.additional_attributes.details['profile_pic'] = chatUser[0]?.profile_pic
   }
    if(message?.additional_attributes?.details.reply_comment_id){
     let reply = selectedChatUserMsgarr.filter(n=>n._id == message?.additional_attributes?.details.reply_comment_id)
     message.additional_attributes.details['replyMsgObj'] = reply?.length ? reply[0] : {}
     message.additional_attributes.details['rplyUserName'] = reply?.length ? reply[0] : {}
     let chatRplyUser = chatUsersArr.filter(m=>m._id== reply[0]?.chat_user_id)
     message.additional_attributes.details['rplyUserName'] = chatRplyUser[0]?.name
     message.additional_attributes.details['rplyUserProfilePic'] = chatRplyUser[0]?.profile_pic
   }
   if(message?.additional_attributes?.details?.temporary_id){
     tempIndex = selectedChatUserMsgarr.findIndex(x=>x?.temporary_id == message?.additional_attributes?.details?.temporary_id)
      delete message?.additional_attributes?.details?.temporary_id 
    }
   selectedChatUserMsgarr = selectedChatUserMsgarr?.filter(x=>x?._id != message?.additional_attributes?.details?._id)
   if(message?.additional_attributes?.details?.type == "EXTERNAL_INBOUND" && ["WHATSAPP","FACEBOOK","INSTAGRAM"].includes(selectedChannelObj?.channelType)){
       var end = Math.floor(Date.now())
       const diff = end - message?.additional_attributes?.details?.receiveTime //e.whatsapp_object.received_time;
       if(diff >= 86400000){
         disableEditor = true;
       }
       else{
         disableEditor = false
       }
   }
   if(['EXTERNAL_INBOUND','EXTERNAL_OUTBOUND']?.includes(message?.additional_attributes?.details?.type) && commentArr?.length){
    let index = commentArr.findIndex(p=>p?.message_group_id == message?.additional_attributes?.details?.message_group_id && p?.chat_user_id == message?.additional_attributes?.details?.chat_user_id && p?.channel_id == message?.additional_attributes?.details?.channel_id)
    if(index>-1){
      let obj = commentArr[index]
      Object.keys(message.additional_attributes?.details || {}).forEach(key=>{
        obj[key]=message.additional_attributes?.details[key]
      })
      if(index>0){
        commentArr.splice(index,1)
        commentArr.unshift(obj)
      }
    }
   }
   const insertIndex = selectedChatUserMsgarr.findIndex(item => this.compareObjects(message?.additional_attributes?.details, item) >= 0);
      if(tempIndex>=0){
       selectedChatUserMsgarr[tempIndex] = message?.additional_attributes?.details
     }
     else{
                // Insert the new object at the found index
          if (insertIndex === -1) {
   // If the object should be inserted at the end
         selectedChatUserMsgarr.push(message?.additional_attributes?.details);
        } else {
        selectedChatUserMsgarr.splice(insertIndex, 0, message?.additional_attributes?.details);
        }
     }
  }
  let obj = {
    'commentArr' : commentArr || [],
    'selectedChatUserMsgarr' : selectedChatUserMsgarr,
    'disableEditor' : disableEditor
  }
     return obj; 
}

isLongEvent(event: any): boolean {
  const start = new Date(event.start).getTime();
  const end = new Date(event.end).getTime();
  const duration = end - start;
  return duration >= 24 * 60 * 60 * 1000; // 24 hours in milliseconds
}

formatDate(dateObj) {
    const now = new Date(); // Current date
    let currentDate = new Date(dateObj?.time); // Convert epoch to Date object
    if (!dateObj?.is_time_added) {
      let timeFrom1900 =this.calculateTimestampSince1900(dateObj?.date)
      currentDate = new Date(timeFrom1900)
        return `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
    } else {
        // If not yesterday, today, or tomorrow, format as 'YYYY-MM-DDTHH:MM:SS'
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        return `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}T${hours}:${minutes}:${seconds}`;
    }
  }


downloadMediaURL(item,index,notProperName?){
  let documentUrl
    if(Array.isArray(item.fileObjArr)) {
      item.fileObjArr.forEach((element, i) => {
        if (index == i) {
          documentUrl = element.url?element.url:element?.image_download_url          ;
          let name= notProperName ? element?.name?.slice(0, element?.name.indexOf('.')) : element?.name ? element?.name : element?.file_name;
          let extention =notProperName ? element?.name?.split('.').pop() : element?.extension ? element?.extension :  element?.fileType
          this.downloadImage(documentUrl, name, extention);
        } ;
      });
    } else {
      documentUrl = item.url;
      let name= notProperName ? item?.name?.slice(0, item?.name.indexOf('.')) : item?.name ? item?.name : item?.file_name;
      let extention = notProperName ? item?.name?.split('.').pop() : item?.extension ? item?.extension :  item?.fileType
      this.downloadImage(documentUrl, name, extention);
    }
}


getFixedChannelKeyByType(type){
  switch (type){
      case 'TELEGRAM':
          return "contact_user_3f83k29fj1";
      case 'VIBER':
          return "contact_user_3o3oioiiw3";
      case 'LINE':
          return "contact_user_399fk00992";
      case 'FACEBOOK':
          return "contact_user_2342jf992";
      case 'INSTAGRAM':
          return "contact_user_f929jf2902";
      case 'WHATSAPP':
          return "contact_phone_f9eoa8dfuf";
      case 'GOOGLE_BUSINESS_MESSAGES':
          return "contact_user_32jf0922";
      case 'CHAT_USER_NAME':
          return "contact_name_qiybv1ozk6";
      default:
          return "";
  }
}

//import excel
downloadExcel(rowData,graphList) {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rowData);
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const blobUrl: string = window.URL.createObjectURL(data);
  // Create a download link
  const a: HTMLAnchorElement = document.createElement('a');
  a.href = blobUrl;
  a.download = `${graphList.name} data.xlsx`
  a.click();
}

//email format the data

formatCommentObject(comment){
  if(comment?.email_object?.received_time){
    comment['receivedTime']  = { 
      date: this.getHumanReadableDateAndTimeFromDate(new Date(comment?.email_object?.received_time))['date'],
      time: this.getAgingTimeForHeatMap(comment?.email_object?.received_time)['time'] 
    }
    comment['createdTime'] = undefined
  }else if(comment?.email_object?.create_date){
    comment['createdTime'] = {
      date: this.getHumanReadableDateAndTimeFromDate(new Date(comment?.email_object?.create_date))['date'],
      time: this.getAgingTimeForHeatMap(comment?.email_object?.create_date)['time'] 
    }
    comment['receivedTime'] = undefined
  }
  comment.email_object.toAddresses = comment?.email_object?.to?.map(to => to?.address).join(', ')
  comment.email_object.ccAddresses = comment?.email_object?.cc?.map(cc => cc?.address).join(', ')

  let embeddedImages = comment.email_object?.attachments?.filter(attachment => attachment?.attachment_id)
  comment.email_object.originalAttachments = comment?.email_object?.attachments?.filter(attachment => !attachment?.attachment_id || !comment?.email_object?.body.html.includes(attachment?.attachment_id)) || []
  embeddedImages?.forEach(img => {
    if(comment.email_object?.body?.html)
      comment.email_object.body.html = comment.email_object.body.html?.replace(`cid:${img?.attachment_id}`, img.url)
    if(comment.email_object?.body?.text)
      comment.email_object.body.text = comment.email_object.body.text?.replace(`cid:${img?.attachment_id}`, img.url)
  })
}

  async requestForRecording(audioFlag?){
        if(audioFlag) {
          let permission =await this.checkMediaPermission();
          return permission
        }
  }

  ensureVisible(container?,index?): void {
    const containerElement = container?.nativeElement;
    if(containerElement){
      const selectedElement = containerElement?.children[index];
      const containerRect = containerElement?.getBoundingClientRect();
      const selectedRect = selectedElement?.getBoundingClientRect();
      if (selectedRect?.bottom > containerRect?.bottom) {
        containerElement.scrollTop += selectedRect?.bottom - containerRect?.bottom;
      } else if (selectedRect?.top < containerRect?.top) {
        containerElement.scrollTop -= containerRect?.top - selectedRect?.top;
      }
    }
   
  }
  getMultiBoardDataList(boardData,boradsIds,filedKey,quiqueKey){
    let dataMap= new Map();
    boradsIds?.forEach((id)=>{
      if(boardData[id]){
        boardData[id][filedKey]?.forEach(data=>{
          let key=data[quiqueKey]
          let boardIds=[id]
          if(dataMap.has(key))boardIds=[id,...(dataMap.get(key)?.boardIds || [])];
          dataMap.set(key,{...data,boardIds:boardIds,isActivityBoardField:boardData[id]?.DASHBOARD_INFO?.type=='ACTIVITY_TYPE'})
        })
      }
    })
    return Array.from(dataMap.values())
  }
  getUniqueFieldArr(array: any[], key: string){
    const seen = new Set();
    return array.filter(item => {
      const keyValue = item[key];
      if (seen.has(keyValue)) {
        return false;
      } else {
        seen.add(keyValue);
        return true;
      }
    });
  }



  getFieldOutputTypesinApp(){
    let fieldOutputType=[{displayKey:'Text',key:'text'},
      {displayKey:'Text Array',key:'text_array'},
      {displayKey:'Number',key:'numeric'},
      {displayKey:'Date',key:'date'},
      {displayKey:'Dropdown',key:'dropdown'},
      {displayKey:'Html',key:'html'},
      {displayKey:'Multiselect',key:'multiselect'},
      {displayKey:'User',key:'user_email'},
      {displayKey:'Url',key:'url'},
      {displayKey:'Object',key:'object'},
      {displayKey:'File',key:'file'}]

    return fieldOutputType
  }


  getFieldInputTypesinApp(){
    let fieldInputType=[ {displayKey:'Text',key:'text',allowedFor:["text", "text_array"]},
    {displayKey:'Dropdown',key:'dropdown',allowedFor:["text", "text_array"]},
    {displayKey:'Email',key:'email',allowedFor:["text", "text_array", "user_email"]},
    {displayKey:'Radio',key:'radiobutton',allowedFor:["text", "text_array"]},
    {displayKey:'Mobile',key:'mobile',allowedFor:["text", "text_array", "mobile"]},
    {displayKey:'Ip',key:'ipaddress',allowedFor:["text", "text_array"]},
    {displayKey:'Url',key:'url',allowedFor:["text", "text_array"]},
    {displayKey:'Checkbox',key:'checkbox',allowedFor:["text_array"]},
    {displayKey:'Multiselect',key:'multiselect',allowedFor:["text_array"]},
    {displayKey:'MultiUrl',key:'multiurl',allowedFor:["text_array"]},
    {displayKey:'Country',key:'country',allowedFor:["text_array"]},
    {displayKey:'Number',key:'numeric',allowedFor:["numeric"]},
    {displayKey:'Rating',key:'rating',allowedFor:["numeric"]},
    {displayKey:'Single User',key:'singleuser',allowedFor:["user_email"]},
    {displayKey:'Multi User',key:'multiuser',allowedFor:["user_email"]},
    {displayKey:'Date',key:'date',allowedFor:["date"]},
    {displayKey:'Location',key:'location',allowedFor:["object"]},
    {displayKey:'Bucket',key:'bucket',allowedFor:["object"]},
    {displayKey:'Phase',key:'phase',allowedFor:["object"]},
    {displayKey:'Tag',key:'tag',allowedFor:["object"]},
    {displayKey:'Checklist',key:'checklist',allowedFor:["object"]},
    {displayKey:'Text Area',key:'textarea',allowedFor:["html"]},
    {displayKey:'Single Image',key:'singleimage',allowedFor:["file"]},
    {displayKey:'Multiple Image',key:'multipleimage',allowedFor:["file"]},
    {displayKey:'Single Video',key:'singlevideo',allowedFor:["file"]},
    {displayKey:'Multiple Video',key:'multiplevideo',allowedFor:["file"]},
    {displayKey:'Single Audio',key:'singleaudio',allowedFor:["file"]},
    {displayKey:'Multiple Audio',key:'multipleaudio',allowedFor:["file"]},
    {displayKey:'Single File',key:'singlefile',allowedFor:["file"]},
    {displayKey:'Multiple File',key:'multiplefile',allowedFor:["file"]}]
    return fieldInputType

  }
  
  mergeExternalANDFilterToLocalFilter(localFilterDataObj,externalFilterArr,keyToCheck?){

    // externalFilterArr consist all {key,op,val} object which will check parallel in AND, 
    // and keytocheck is will check for given key and replace that object ex. hierarchy

    if(localFilterDataObj['and']){
      let keyInd= keyToCheck ? localFilterDataObj['and'].findIndex(element=>element?.key==keyToCheck) : -1
      if(keyInd>-1  ){
        localFilterDataObj['and'][keyInd]=externalFilterArr[0]
      }
      else{
        let andValue= localFilterDataObj['and'].find(element=>element?.and)
        localFilterDataObj['and']=[...externalFilterArr,{and:andValue ? andValue?.and : localFilterDataObj['and']}]
      }
    }
    else{
      localFilterDataObj['and'] = externalFilterArr
    }
    return localFilterDataObj

  }

  onKeyBoardPress(event:KeyboardEvent,item){
    const maxLength = item['max_length'];
    const allowedcharArray: string[] =  item['allowed_char_list']?.split('') || [];
    const disallowedcharArray: string[] = item['disallowed_char_list']?.split('') || [];
    const inputElement = event.target as HTMLTextAreaElement;
    if (maxLength!==null && maxLength!==undefined && (inputElement?.tagName=='INPUT' && inputElement?.value?.length >= maxLength || inputElement?.tagName=='DIV' && inputElement?.textContent?.length >= maxLength) && event?.key !== 'Backspace') {
      // event.preventDefault();
      return true;
    }
    const char = event?.key?.toLowerCase();
    if (allowedcharArray?.length>0 && !allowedcharArray?.includes(char) && event.key !== 'Backspace') {
      // event.preventDefault();
      return true;
    }
    if (disallowedcharArray.length>0 && disallowedcharArray?.includes(char) && event.key !== 'Backspace') {
      // event.preventDefault();
      return true;
    }
    return false
  }

  getContrastColor(color: string): string {
    if (!color) {
      color = '#ffffff';
    }
    var luminance = (0.299 * parseInt(color.slice(1, 3), 16) +
    0.587 * parseInt(color.slice(3, 5), 16) +
    0.114 * parseInt(color.slice(5, 7), 16)) / 255;
    var textColor = (luminance > 0.5) ? '#000000' : '#ffffff';
    return textColor;
  }

 getCurrentThemeColor(){
  const theme = this.customStorageService.getItem('theme');
      let themeClass = (theme !== "undefined" && theme !== "null" && theme !== null && theme !== undefined) ? theme : 'theme-royalBlue';      
      let themeArr = this.constantService.getThemeColor();
      let findColor = themeArr.filter(t=>t?.themeClass == themeClass)
      if(findColor?.length){
        let color = findColor[0]?.color?.length<=1 ? findColor[0]?.color[0] :
        this.getMiddleColor(findColor[0]?.color[0], findColor[0]?.color[1]);
        return color;
      }
      else{
        return ''
      }
 }

 getMiddleColor(color1, color2) {
  // Convert both hex colors to RGB
  let rgb1 = this.hexToRgb(color1);
  let rgb2 = this.hexToRgb(color2);
  // Calculate the middle color by averaging each component
  let middleR = Math.round((rgb1[0] + rgb2[0]) / 2);
  let middleG = Math.round((rgb1[1] + rgb2[1]) / 2);
  let middleB = Math.round((rgb1[2] + rgb2[2]) / 2);
  // Convert the result back to hex
  return this.rgbToHex(middleR, middleG, middleB);
}

hexToRgb(hex) {
  // Remove the hash symbol if it exists
  hex = hex.replace('#', '');
  // Convert the hex values to RGB components
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16); 
  return [r, g, b];
}

rgbToHex(r, g, b) {
  // Convert RGB components back to a hex string
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
}

 getColorById(id) {
  // Generate a hash code from the ID
  let hash = 0;
  for (let i = 0; i < id.length; i++) {
      const char = id.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32-bit integer
  }
  // Ensure the hash is non-negative
  hash = Math.abs(hash);
  // Map the hash to one of the colors
  const colorIndex = hash % this.colorById.length;
  return this.colorById[colorIndex];
}
darkenAndSaturateColor(color, darkenPercent=40, saturationPercent=100) {
  // Remove the hash at the start if it's there
  let usePound = false;
  if (color[0] === "#") {
      color = color.slice(1);
      usePound = true;
  }
  // Convert the hex string into integer values
  const num = parseInt(color, 16);
  // Extract the red, green, and blue components
  let r = (num >> 16);
  let g = ((num >> 8) & 0x00FF);
  let b = (num & 0x0000FF);
  // Darken the color
  r = r - Math.floor(r * darkenPercent / 100);
  g = g - Math.floor(g * darkenPercent / 100);
  b = b - Math.floor(b * darkenPercent / 100);
  // Ensure values stay within the 0-255 range
  r = (r < 0) ? 0 : r;
  g = (g < 0) ? 0 : g;
  b = (b < 0) ? 0 : b;
  // Convert to HSL
  let rNormalized = r / 255;
  let gNormalized = g / 255;
  let bNormalized = b / 255;
  let max = Math.max(rNormalized, gNormalized, bNormalized);
  let min = Math.min(rNormalized, gNormalized, bNormalized);
  let h, s, l = (max + min) / 2;
  if (max === min) {
      h = s = 0; // achromatic
  } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
          case rNormalized: h = (gNormalized - bNormalized) / d + (gNormalized < bNormalized ? 6 : 0); break;
          case gNormalized: h = (bNormalized - rNormalized) / d + 2; break;
          case bNormalized: h = (rNormalized - gNormalized) / d + 4; break;
      }
      h /= 6;
  }
  // Adjust saturation
  s += saturationPercent / 100;
  s = s > 1 ? 1 : s;
  // Convert back to RGB
  let c = (1 - Math.abs(2 * l - 1)) * s;
  let x = c * (1 - Math.abs((h * 6) % 2 - 1));
  let m = l - c / 2;
  let r1, g1, b1;

  if (0 <= h && h < 1 / 6) {
      r1 = c; g1 = x; b1 = 0;
  } else if (1 / 6 <= h && h < 2 / 6) {
      r1 = x; g1 = c; b1 = 0;
  } else if (2 / 6 <= h && h < 3 / 6) {
      r1 = 0; g1 = c; b1 = x;
  } else if (3 / 6 <= h && h < 4 / 6) {
      r1 = 0; g1 = x; b1 = c;
  } else if (4 / 6 <= h && h < 5 / 6) {
      r1 = x; g1 = 0; b1 = c;
  } else {
      r1 = c; g1 = 0; b1 = x;
  }
  r = Math.round((r1 + m) * 255);
  g = Math.round((g1 + m) * 255);
  b = Math.round((b1 + m) * 255);
  // Convert the components back into a hex string
  return (usePound ? "#" : "") + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

  getCalculatedSortBy(sortList,sortJson){
    sortList=[...(sortList || [])]
    let sortObj={sort_by:sortJson?.sortBy || 'index',order:sortJson?.order || this.constantService.getDscOrder()}
    let index=sortList?.findIndex(sort=>sort.sort_by==sortObj?.sort_by)
    if(!sortJson?.isRemove && index>-1 && sortList[index]?.order==sortObj?.order){
      return null
    }
    if(index<0 || sortJson?.isRemove){
      sortList=sortList?.filter(key=>sortJson?.sortBy && key.sort_by!='index' && key?.sort_by!=sortObj?.sort_by)
      if(!sortJson?.isRemove)sortList.push(sortObj);
      if(!sortList?.length)sortList.push({sort_by:'index',order:this.constantService.getDscOrder()});
    }else{
      sortList[index].order=sortJson?.order
    }
    return sortList
  }

  getCalcuatedGroupBy(oldGroupBy,newGroupInfo){
    let newGroupBy = newGroupInfo?.key ? newGroupInfo?.key : 'none'
    let order= newGroupInfo?.order || 'ASC'
    let value= oldGroupBy?.group_by==newGroupBy && (['none','bucket', 'phase'].includes(oldGroupBy?.group_by) || oldGroupBy?.sort_order==order) ? null : {group_by: newGroupBy,max_group_size:  null,start_index: 0,sort_order: order}
    if(['none','bucket', 'phase']?.includes(value?.group_by)){
      delete value.sort_order
    }
    return value
  }
  getCalcuatedFormulas(res,formulasJson,customFieldList){
    let keys=Object.keys(res || {})
    if(!formulasJson || !keys.length)formulasJson={}
    keys.forEach(key=>{
      if(res[key].length==0){
        delete formulasJson[key]
        delete res[key]
      }
    })
    if(keys.length>0)Object.assign(formulasJson, res)
     // filter applied formula if key delete from item attribute
    if(formulasJson && Object.keys(formulasJson || {}).length>0){
      let filedsJson=[...this.constantService.getDefaultFormulasOPtions(),...customFieldList]?.reduce((json,data)=>{json[data.key]=data;return json},{});
      let applyFilter=Object.keys(formulasJson)?.reduce((json,key)=>{if(filedsJson[key])json[key]=formulasJson[key];return json},{});
      formulasJson=applyFilter
    }
    return formulasJson
  }
  //date field validation
  createJsonForDateValidation(res){
    const today = new Date()
    today.setHours(0,0,0,0);
    const newDate=this.calculateDaysSince1900(today)
    const dateRangeRef = res?.date_range
    const dateValidationJson={
      'date_range':{
        "date_range_type":dateRangeRef?.date_range_type ?? '',
        "start":dateRangeRef?.start ?? null,
        "end":dateRangeRef?.end ?? null,
      },
      'disable_time':res?.disable_time ?? false,
      'days_allowed':res?.days_allowed ?? null,
    }
    const dateConfig = {
      minDate: {date: null},
      maxDate: {date: null}
    }
    const newDateRangeRef = dateValidationJson.date_range
    if(newDateRangeRef['date_range_type']==='FIXED'){
      dateConfig.minDate.date = dateValidationJson.date_range.start
      dateConfig.maxDate.date = dateValidationJson.date_range.end
    }
    else if(['DAYS', 'MONTH'].includes(newDateRangeRef['date_range_type'])){
      const minDate = new Date(today);
      const maxDate = new Date(today);
      if(newDateRangeRef['date_range_type']==='DAYS'){
        minDate.setDate(today.getDate() + newDateRangeRef.start);
        maxDate.setDate(today.getDate() + newDateRangeRef.end);
      }
      else {
        minDate.setMonth(today.getMonth() + newDateRangeRef.start);
        minDate.setDate(1); // first day of the month
        maxDate.setMonth(today.getMonth() + newDateRangeRef.end + 1); //  next month
        maxDate.setDate(0); //last day of the previous month
      }
      const minDateFromStart = this.calculateDaysSince1900(minDate.getTime())
      const maxDateFromStart = this.calculateDaysSince1900(maxDate.getTime());
      dateConfig.minDate.date = (minDateFromStart && dateValidationJson?.date_range?.start!==null)?minDateFromStart: null;
      dateConfig.maxDate.date = (maxDateFromStart && dateValidationJson?.date_range?.end!==null)?maxDateFromStart : null;
    }
    if(dateConfig.maxDate.date == null) dateConfig.maxDate=null
    if(dateConfig.minDate.date == null) dateConfig.minDate=null
  
    if(dateValidationJson['days_allowed']!=null && dateValidationJson['days_allowed']!==''){
      dateConfig['exception']={
        'work_days': dateValidationJson?.days_allowed?.map((day: string)=> ({day: day?.substring(0,3),type:'WORK'}))
      }
    }
    dateValidationJson['dateConfig'] = dateConfig;
    return dateValidationJson
  }


}

