import { Component, EventEmitter, HostListener, Input, IterableDiffers, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BasicUtils } from 'app/services/basicUtils.service';
import { ConstantService } from '../../../../Enums/Constant.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { MqttmessagingService } from '../../../services/mqttmessaging.service';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit {
  @Input() itemIdList = [];
  @Input() dashId: string;
  @Input() isBlocking;
  @Input() isDuplicateOf;
  @Input() tabIndex;
  @Input() isReadOnly;
  @Input() showRemoveIcon = true;
  @Input() showBoardName = true;
  @Input() isGridEditor = false;
  @Input() currentLead : any;
  @Input() queryForSubTask :boolean=false;
  @Input() itemListforBoard:boolean=false;
  @Input() showDeletePopover:boolean=true;
  @Input() leadDataList:any=[];
  @Input() itemOpenInCurrentView:boolean=false

  setquickSubTask: boolean = false
  bucketArray: any;
  itemsList: any[] = [];
  dependancy = [ {label:"Blocking", value: true},{label:"Blocked by", value: false}]
  @Output() itemRemove = new EventEmitter<any>();
  @Output() onModalTrigger = new EventEmitter<any>();
  @Output() onItemOpen = new EventEmitter<any>();
  orgUsersJson: any={};
  iterableDiffer: any;
  dashboardName: any;
  dashboardutilsSub: any;
  itemModalRef: NgbModalRef;
  showLeadModal: boolean;
  isLoader: boolean = false;
  clickedItem: any;
  dashBoardSprint: any;
  mqttSubscription: any;
  showFullTitle: boolean;
  creationtext: any;
  @Input()isPublicLead:boolean=false
  constructor(private httpTransfer: HttpTransferService,
    private commonUtils: CommonUtils,
    private MqttmessagingService : MqttmessagingService,
    private ngbModalService: NgbModal,
    private dashboardUtils: DashboardUtilsService,
    private basicUtils:BasicUtils,
    private iterableDiffers: IterableDiffers,
    private dashboardUitls: DashboardUtilsService,
    private ConstantService : ConstantService) {
      this.iterableDiffer = iterableDiffers.find([]).create(null);
      this.listenForMqttMessages()
     }

  async ngOnInit() {
    if(!this.isPublicLead){
    this.dashboardutilsSub = this.dashboardUitls.getUpdatedDashboardJson().subscribe((res : any) => {
      this.bucketArray = res[this.dashId]?.BUCKET || [];
      this.dashBoardSprint = res[this.dashId]?.SPRINT || []
    });
  }else{
    this.showRemoveIcon=false;
  }
    if(!this.dashId && !this.isPublicLead) {
      this.getDashboardIdUsingItemQuery()
    } else {
      if(this.itemIdList?.length>0 && !this.isPublicLead)this.getRequiredData();
    }
    this.orgUsersJson = await this.dashboardUtils.getOrgUsers();
    if(this.leadDataList.length>0)this.hangleLeadStructur(this.leadDataList)
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['itemIdList']){
      if(this.itemIdList?.length==0){
        this.itemsList=[]
      }
    }
  }

  ngDoCheck() {
    if (this.itemIdList?.length > 0) {
      let changes = this.iterableDiffer?.diff(this.itemIdList);
      if (changes) {
        this.getItems();
      }
    }
    if(this.leadDataList.length>0){
      let changesData = this.iterableDiffer?.diff(this.leadDataList);
      if(changesData){
        this.hangleLeadStructur(this.leadDataList)
      }
    }
  }
  listenForMqttMessages() {
    this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
      if (message) {
          this.updatesFromWss(message)
      }
    });
  }
  updatesFromWss(messagees) {    
    let message = JSON.parse(messagees);    
    if (message[ConstantService.constant.OBJECTTYPEKEY] == ConstantService.constant.LEADOBJECTTYPE) {
      let getLead=message?.additional_attributes?.details;
      for(let i=0;i<this.itemsList.length;i++){
        if(this.itemsList[i]._id == getLead?._id){
          let leadObj = this.commonUtils.creatingJsonStructureOfLeadForTableView(getLead);
          if(this.isReadOnly) {
            leadObj['isReadOnly'] = this.isReadOnly;
          } else {
            this.commonUtils.isDashboardReadOnly(this.dashId,getLead?.lead_colloborators_users || [],leadObj).then((res : any) => {leadObj['isReadOnly'] = res;});
          }
          this.itemsList[i]= leadObj
         }
      }
    }
    if (message[ConstantService.constant.OBJECTTYPEKEY] == ConstantService.constant.COMMENTOBJECTTYPE) {
      for(let j=0;j<this.itemsList.length;j++){
        if(this.itemsList[j]._id == message?.additional_attributes?.details?.lead_id){
          this.itemsList[j].comment_count= message?.additional_attributes?.details?.comment_count
         }
      }
    }
   
  }
  getDashboardIdUsingItemQuery() {
    this.httpTransfer.getLeadQuery({lead_id:[this.itemIdList[0]]}).subscribe((res : any) => {
      this.dashId = res.result.leadResponse[0]['dashboard_id'];
      if(this.itemIdList.length>0)this.getRequiredData();
    });
  }


  async getRequiredData() {
    if(this.dashId){
    this.dashboardUitls.setdashboardBuckets([this.dashId]);
    let dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.dashId])
    this.dashboardName = dashboardInfo[this.dashId]?.DASHBOARD_INFO?.dashboard_name;
    }
  }

  getItems() {
    this.isLoader = true
    this.itemsList = [];
    let queryObj = {};
    if(this.queryForSubTask && this.isPublicLead){
      queryObj={parent_id:[this.currentLead?._id]}
     }else{
       queryObj={lead_id:this.itemIdList}
     } 
    this.httpTransfer.getLeadQuery(queryObj,this.dashId).subscribe((res : any) => {
      if (res.status === 200) {
        this.isLoader = false
        this.hangleLeadStructur(res.result.leadResponse)
      }
    });
  }
  hangleLeadStructur(leadData){
    this.itemsList = [];
    leadData.forEach(item => {
      let leadObj = this.commonUtils.creatingJsonStructureOfLeadForTableView(item);
      if(this.isReadOnly) {
        leadObj['isReadOnly'] = this.isReadOnly;
      } else {
        this.commonUtils.isDashboardReadOnly(this.dashId,item?.lead_colloborators_users || [],item).then((res : any) => { leadObj['isReadOnly'] = res;});
      }
      this.itemsList.push(leadObj);
    });
  }

  removeItem(item,i) {
    this.itemsList.splice(i,1)
    this.itemRemove.emit(item);
  }

  itemSeqClicked(item, modalRef) {
    if(!this.showBoardName || this.itemOpenInCurrentView){
      this.onItemOpen.emit(item)
    }else{
      this.clickedItem = item;
      this.showLeadModal = true
      this.itemModalRef = this.ngbModalService.open(modalRef, { size: 'xl', windowClass: 'iteminItemModal' });
      this.onModalTrigger.emit(null)
      this.basicUtils.storeModlRef(this.itemModalRef)
    }
  }
  openEditorForTitle(item){
    item['showFullTitle'] = true;
  }
  updateItemTitle(item){
    var leadObj = {};
    leadObj["title"] = item.title;
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(leadObj, this.dashId, item._id)
      .subscribe((res : any) => {
        if(res.status == 200) {
        }
      });
  }
  ngOnDestory() {
    this.dashboardutilsSub.unsubscribe();
    this.mqttSubscription?.unsubscribe();
  }



  
}
