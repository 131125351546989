import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonUtils } from '../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../services/dashboard-utils.service';
import { ConstantService } from '../../../Enums/Constant.service';
import { AttributesIcons, AttributesLxIcons } from '../../../Enums/attributes-icons';
import * as moment from 'moment';
import { HttpTransferService } from '../../services/httpTransfer.service';

@Component({
  selector: 'app-fiscal-date',
  templateUrl: './fiscal-date.component.html',
  styleUrls: ['./fiscal-date.component.scss']
})
export class FiscalDateComponent implements OnInit {

  @Input() dateObj: any;


  copiedDateObj:any


  dayOptions:any[]=[]
  monthArr:any[]=[]
  monthOptions:any[]=[]
  filterCycleOptions:any[]=[]

  selectedfiscalPeriod:any=0
  fiscalperiodOptions:any[]=[]
  fiscalStartDate: any;
  fiscalEndDate: any;
  fiscalStartDateString: any;
  fiscalEndDateString: any;

  @Output() dateUpdated = new EventEmitter()


  currentDate : any;
  currentYear :any;
  isLeap:boolean=false

  isInvalidInput:boolean=false


  constructor(
    private dashboardUtils: DashboardUtilsService,
    public commonUtils: CommonUtils,
    public constantService: ConstantService,
    private httpTransferService:HttpTransferService

  ) { }

  ngOnInit() {
    this.currentDate = new Date();
    this.makeOptionsArr()
    this.copiedDateObj=JSON.parse(JSON.stringify(this.dateObj))
    this.selectedfiscalPeriod= [-1,0,1].includes(this.copiedDateObj?.eval_period?.relative_period) ? this.copiedDateObj?.eval_period?.relative_period : 'custom'
    this.dateObjChanged()

    this.currentYear= this.currentDate.getFullYear();

    if ((this.currentYear % 4 === 0 && this.currentYear % 100 !== 0) || this.currentYear % 400 === 0) {
      this.isLeap=true
    } 
  }

  makeOptionsArr(){
    this.monthArr=this.commonUtils.monthNames

    for(let i=1;i<32;i++){
      this.dayOptions.push({'display_key':i, 'key':i})
    }
    for(let j=1;j<this.monthArr.length+1;j++){
      this.monthOptions.push({'display_key':this.monthArr[j-1], 'key': j })
    }
    this.filterCycleOptions=[
      {'display_key':'1 Month', 'key': 1 },
      {'display_key':'2 Months', 'key': 2 },
      {'display_key':'Quarterly', 'key': 3 },
      {'display_key':'4 Months', 'key': 4 },
      {'display_key':'Half Yearly', 'key': 6 },
      {'display_key':'Full year', 'key': 12 }
    ]
    this.fiscalperiodOptions=[
      {'display_key':'Previous Period', 'key': -1 },
      {'display_key':'Current Period', 'key': 0 },
      {'display_key':'Next Period', 'key': 1 },
      {'display_key':'Custom Period', 'key': 'custom' },
    ]
  }




  isThisDayAllowed(day){
    let val=true
    if([4,6, 9,11].includes(this.copiedDateObj.fiscal_year.start_month)){
      val=day<31 ? true : false
    }
    else if(this.copiedDateObj.fiscal_year.start_month==2){
      val= this.isLeap && day<30 ? true : !this.isLeap && day<29 ? true : false
    }
    return val
  }


  isThisMonthAllowed(month){
    let val=true
    if([4,6, 9,11].includes(month)){
      val= this.copiedDateObj.fiscal_year.start_day < 31  ? true :false
    }
    else if(month==2){
      val= this.copiedDateObj.fiscal_year.start_day < 29  ? true : false
    }

    return val
  }
  


  dateObjChanged(){
    let response = this.commonUtils.calcFiscalPeriod(this.copiedDateObj)
    this.fiscalStartDate= new Date(response.from)
    this.fiscalEndDate= new Date(response.to)
    this.fiscalStartDateString=this.commonUtils.getDateText(this.fiscalStartDate)
    this.fiscalEndDateString=this.commonUtils.getDateText(this.fiscalEndDate)
  }


  fiscalPeriodChange(){
    this.copiedDateObj.eval_period.relative_period = this.selectedfiscalPeriod==='custom' ? 0 : this.selectedfiscalPeriod
    this.dateObjChanged()
  } 
  
  saveFiscalDate(){
    this.dateUpdated.emit(this.copiedDateObj) 
  }


  validateRelativePeriod(){
    const val=this.copiedDateObj.eval_period.relative_period
    if (!Number.isInteger(val)) {
      this.isInvalidInput = true;
    } else {
      this.isInvalidInput = false;
    }
  }
  



}
