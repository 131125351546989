
<div *ngIf="type=='Table'">
      <ngx-skeleton-loader *ngIf="header" count="1" appearance="line" 
            animation="pulse"  
            [theme]="getListTheme('white')">
      </ngx-skeleton-loader>
      <ngx-skeleton-loader 
            [count]="count" appearance="line" 
            animation="progress" [theme]="getListTheme()">
      </ngx-skeleton-loader>
</div>
<div *ngIf="type=='Kanban'" style="display: flex;">
      <div *ngFor="let item of [].constructor(columnCount);">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 padding-8 beforesearch">
                  <ngx-skeleton-loader *ngIf="header" count="1" appearance="line" 
                        animation="progress"  
                        [theme]="{
                              position:'inherit',
                              background:'white',
                              'margin-top':'20px',
                              'border':'1px solid #3571de80',
                              'border-bottom-right-radius':'6px',
                              'border-bottom-lleft-radius':'6px',
                              height:'36px'
                        }">
                  </ngx-skeleton-loader>
                  <div style="height: 70vh; overflow-x: hidden;">
                        <ngx-skeleton-loader 
                              [count]="count" appearance="line" 
                              animation="pulse"  [theme]="{
                                    position:'inherit',
                                    background:'white',
                                    'margin-top':'0px',
                                    'border-radius':'4px',
                                    'border-top-left-radius': '10px',
                                    'border-top-right-radius': '10px',
                                    height:'102px'
                              }">
                        </ngx-skeleton-loader>
                  </div>
            </div>
      </div>
</div>
<div *ngIf="type=='Header'">
      <ngx-skeleton-loader  count="1" appearance="line" 
            animation="progress"  
            [theme]="{width:'90px',left:0}">
            </ngx-skeleton-loader>
      <br>
      <ngx-skeleton-loader 
            [count]="count" appearance="line" 
            animation="progress" [theme]="{width:width,left:0}">
      </ngx-skeleton-loader>
</div>
<div *ngIf="type=='CardField'">
      <div *ngFor="let item of [].constructor(count);">
            <ngx-skeleton-loader *ngIf="header" count="1" appearance="line" 
                  animation="progress"  
                  [theme]="{
                        width:'120px',
                        position:'inherit',
                        'margin-top':'25px'}">
            </ngx-skeleton-loader> 
            <ngx-skeleton-loader  [count]="cardCount" 
                  appearance="line" 
                  [ngStyle]="{
                        display: 'flex','flex-wrap': 'wrap',
                        gap:columnGap+'px'+' '+rowGap+'px'
                        }"
                  [animation]=animation  
                  [theme]="{
                        background:(animation=='progress' ? '' :'white'),
                        width:width,height:height+'px',
                        'border-radius':radius+'px',position:'inherit'
                        }">
            </ngx-skeleton-loader> 
      </div>
</div>
<div *ngIf="type=='Avatar' || type=='V-Avatar'" [ngStyle]="{'display': (type=='V-Avatar'? 'flex':'')}">
      <div *ngFor="let item of [].constructor(count);">
            <div [style]="'display: flex;padding:11px 0px;column-gap:'+columnGap+'px'" [ngStyle]="externalStyle">  
                  <ngx-skeleton-loader *ngIf="circleWidth>0" count="1" appearance="circle"
                        animation="progress"
                        [theme]="{
                              position:'inherit',
                              width:circleWidth+'px',
                              height:(circleHeight || circleWidth)+'px',
                              'margin': '0px',
                              'margin-top': '10px',
                              'border-radius': circleRadius+'px'
                        }">
                  </ngx-skeleton-loader>
                  <div [ngStyle]="{
                        'display': 'inline-grid',
                        'padding':'5px',
                        'row-gap':rowGap+'px',
                        'width' : '100%'
                        }">
                        <ngx-skeleton-loader *ngFor="let item of [].constructor(lineWidth.length) let i=index"
                                    appearance="line" 
                                    animation="progress" [theme]="{
                                          width:lineWidth[i],
                                          height:height+'px',
                                          left: 0,
                                          margin:'0px',
                                          display:'block',
                                          'border-radius': '6px'
                                    }">
                        </ngx-skeleton-loader>
                  </div>
            </div>        
       </div>     
</div>
<div *ngIf="type=='Boxes'" style="display: flex;flex-wrap: wrap;justify-content: space-between;row-gap: 10px;padding: 18px;" >
      <div *ngFor="let item of [].constructor(count);"  [ngStyle]="{'border': '1px solid #e1e1e2','border-radius': '10px','height':height+'px',width:width}">
            <div class="" style="border-bottom:1px solis #e1e1e1;height: 40px;padding: 5px;">
                  <ngx-skeleton-loader appearance="line"  animation="progress" [theme]="{ width:'30%',  height:17+'px', left: 0,  margin:'0px', display:'block','border-radius': '4px'}"></ngx-skeleton-loader>
            </div>
            <div style="height: calc(100% - 40px);">  
                  <ngx-skeleton-loader appearance="line"  animation="progress" [theme]="{ width:'100%',height:'100%', left: 0,  margin:'0px', display:'block','border-radius': '4px',top:'0px'}"></ngx-skeleton-loader>
            </div>        
       </div>     
</div>
<div  *ngIf="type=='Full'" style="width: 100%;height: 100%;">
      <ngx-skeleton-loader appearance="line"  animation="progress" [theme]="{ width:'100%',height:'100%', left: 0,  margin:'0px', display:'block','border-radius': '4px',top:'0px'}"></ngx-skeleton-loader>
</div>

<div *ngIf="type=='Spinner'">
      <div class="spinner-loader" [ngStyle]="{'background':'radial-gradient(farthest-side,'+ (color || 'black') +' 94%,#0000) top/8px 8px no-repeat,conic-gradient(#0000 30%,'+(color || 'black')+')','width':circleWidth+'px'}"></div>
      <style>
           .spinner-loader {
                  aspect-ratio: 1;
                  border-radius: 50%;
                  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
                  animation: l13 0.7s infinite linear;
                  }
                  @keyframes l13{ 
                  100%{transform: rotate(1turn)}
            }
      </style>
</div>
<div *ngIf="type=='DefaultSpinner'" class="spinner-border" [ngStyle]="{'width':circleWidth,'height':circleWidth,color:color}" role="status"><span class="sr-only"></span></div> 

