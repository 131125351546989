import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { ConstantService } from 'Enums/Constant.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';
import { Subscription } from 'rxjs-compat';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-include-country-selection',
  templateUrl: './include-country-selection.component.html',
  styleUrls: ['./include-country-selection.component.scss']
})
export class IncludeCountrySelectionComponent implements OnInit {
  @Input() editable: boolean = false
  @Input() fieldType:string;
  @Input() currenValue:any;
  @Input() countryJson:any=[]
  @Input() isGrid:boolean=false
  @Input() dashId:any
  @Input() selectedLeadObj:any;
  @Input() indexOfMobile:any=-1;
  @ViewChild('inputBox') inputBox: ElementRef;
  @ViewChild('container') container: ElementRef;
  @ViewChild('countryPopover') countryPopover:any;
  @Output() onValueUpdate: EventEmitter<any> = new EventEmitter();
  @ViewChild('editTemplate') editTemplate: NgbPopover;
  @ViewChild('whatsAppTemplates') whatsAppTemplates: NgbPopover;
  @Output () itemDeleted: EventEmitter<boolean> = new EventEmitter();
  @Output () addMoreEnableButtom: EventEmitter<boolean> = new EventEmitter();
  @Output() manageNumbers = new EventEmitter<boolean>()
  @Input() disableDeletion = false
  templateContentToSend=''
  value:any={};
  countryData:any={}
  countryList:any=[]
  customFieldType:any={}
  enableEditor:boolean=false
  searchText:any=''
  newNumber = ""
  editEnabled = false;
  whatsAppList=null;
  searchTextInTemplate:string=''
  copyOfWhatsAppList=null;
  orgUsersJson:any
  dashboardJsonSub: Subscription;
  sprintArr: any[]=[];
  editedText:string=''
  countryCode='';
  copyCountryCode:any=[]
  selectedIndex:any=0;
  isReadOnly:boolean=false
  valueCopy:any[];
  removePopoverRef:any;
  showWhatsappredirect:boolean=true;
  addMoreButtomEnable:boolean=false

    
  constructor(private constantService :ConstantService,private httpTransfer:HttpTransferService,private modalService: NgbModal,private dashboardUtils: DashboardUtilsService,private cdr:ChangeDetectorRef,private commonUtils:CommonUtils){
    this.customFieldType = this.constantService.getCustomTypes();
  }

  async ngOnInit() {
        this.countryCode=this.value?.country_code
    this.countryList=[]
    this.countryJson?.forEach(country=>{
      this.countryData[country?.code]=country
      this.countryList.push(country)
    })
    this.copyCountryCode=this.countryList;
    if(!this.orgUsersJson?.length){
      const orgUserJson = await this.dashboardUtils.getOrgUsers(); 
      this.orgUsersJson = Object.values(orgUserJson);
    }
    if(this.dashId){
      this.dashboardJsonSub = this.dashboardUtils.getUpdatedDashboardJson().subscribe((res : any) => {
        this.sprintArr = res[this.dashId]?.SPRINT || [];
      }) 
    }
    let userRole=await this.commonUtils.isLeadOrDashboardReadOnlyOrViewOny(this.dashId,this.selectedLeadObj);
    if((userRole?.is_readOnly_user && userRole?.is_collabrator_user))
    {this.isReadOnly = false;}
    else{
      this.isReadOnly = userRole?.is_readOnly_user;
    }
    
  }

  onValueChange(){
    if(['Mobile','MultipleMobile'].includes(this.fieldType) && this.currenValue){
      if(Array.isArray(this.currenValue)){this.valueCopy = [...this.currenValue]}else if(typeof this.currenValue == 'object'){this.valueCopy = [{...this.currenValue}]}
      else this.valueCopy = [{value: '', country_code: null}]
      this.value['country_code']=this.valueCopy[0]?.country_code
      this.value['value']=this.valueCopy[0]?.mobile_number
    }
    else if(this.fieldType==this.customFieldType?.CURRENCY && this.currenValue){
      this.value['country_code']=this.currenValue?.currency
      this.value['value']=this.currenValue?.amount
    }
    else if(this.fieldType==this.customFieldType?.COUNTRY && this.currenValue){
      this.value['country_code']=null
      this.value['value']=this.currenValue
      this.countryJson?.forEach(country=>{
        if(country.name==this.value.value)this.value['country_code']=country?.code
      }) 
    }
    else{
      this.value={}
    } 
    this.cdr.detectChanges()  
  }
  ngOnChanges(changes: SimpleChanges){
        if(changes['currenValue']){
      this.onValueChange();
    }
    this.countryCode=this.value?.country_code
    
  }

  onSearchCode(searchText){
    searchText=searchText?.toLowerCase()
    this.countryList=Object.values(this.countryData)?.filter(value=>value['name']?.toLowerCase()?.includes(searchText) || value['dial_code'].includes(searchText) || value['currencyCode']?.toLowerCase()?.includes(searchText) || value['code']?.toLowerCase()?.includes(searchText))
    this.selectedIndex=0
  }

  selectedCountry(country){
    this.value.country_code=country?.code || null
    this.value.name=country?.name || null
    this.enableEditor=true
    this.cdr.detectChanges();
    if(country?.code && this.fieldType!==this.customFieldType?.COUNTRY){
      if(!this.isGrid) //only case of kanban point focus
      setTimeout(() => {this.inputBox.nativeElement.focus()},1); 
    }else{
      this.onValueInput()
      this.enableEditor=false
    }
    if(['Mobile','MultipleMobile'].includes(this.fieldType)){this.addMoreEnableButtom.emit(true)}
    this.countryPopover?.close()
  }
  onValueInput(data?){
    if(this.value.value < 0 || this.value.value > 0 || this.value.value == 0 || this.value.value == null || this.fieldType==this.customFieldType?.COUNTRY){
        let value=null
        this.enableEditor=false
        this.value.value=this.value?.value || null
        let isUpdate=false
        if(this.fieldType==this.customFieldType?.MOBILE && (this.value.country_code!=this.currenValue?.country_code || this.value.value!=this.currenValue?.mobile_number)){
          isUpdate=true
          value= this.value?.country_code ? {country_code:this.value?.country_code,mobile_number:this.value.value} : null
        }
        else if(this.fieldType==this.customFieldType?.CURRENCY && (this.value.country_code!=this.currenValue?.currency || this.value.value!=this.currenValue?.amount)){
          isUpdate=true
          value= this.value?.country_code ? {currency:this.value?.country_code,amount:this.value?.value} : null
        }
        else if(this.fieldType==this.customFieldType?.COUNTRY && this.value.name!=this.currenValue){
          isUpdate=true
          value=this.value?.name
        }
        else if(this.fieldType == 'MultipleMobile'){
          isUpdate = true;
          value= this.value?.country_code ? {country_code:this.value?.country_code,mobile_number:this.value.value} : null
        }
        if(isUpdate){
          this.onValueUpdate.emit(value)
        }
      }
}
  copyToClipboardModern(text) {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch(err => {
        console.error('Unable to copy text to clipboard', err);
      });
  }


  getWhatsappLink(code:string, num, text?){
    let url = `https://wa.me/${code.substring(1)}${num}`
      let t = document.createElement('div')
      t.innerHTML = this.editedText
      url=`${url}?text=${encodeURIComponent(t.innerText)}`
    return url;
  }

  openTemplateEditor(event){
    this.templateContentToSend = event.message
    this.editedText=event.message
    // this.modalService.open(this.editTemplate, {size: 'l'})
    // this.whatsAppTemplates?.close()
  }

  ngOnDestroy() {
    this.dashboardJsonSub?.unsubscribe();
  }

  useTemplateData(event){
    this.openTemplateEditor(event)
  }

  getTemplateMedia(event){
    this.editedText=event
  }

  handeKeyInput(type?){
    if(type=='Add'){
      this.selectedIndex=0
      setTimeout(() => {document.getElementById('search-input')?.focus(); },1); 
      document.addEventListener('keydown',this.onKeyDown)
    }else{
      document.removeEventListener('keydown',this.onKeyDown)
    }
  }

  onKeyDown= (event: any): void => {
    if(this.selectedIndex >=0){
      switch (event.key) {
      case 'ArrowUp':
        if (this.selectedIndex > 0) {
          this.selectedIndex--;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex)
        }
        break;
      case 'ArrowDown':
        if (this.selectedIndex < this.countryList.length - 1) {
          this.selectedIndex++;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
        }
        break;
      case 'Enter':
        if (this.selectedIndex>=0 && this.selectedIndex<this.countryList.length && this.editable) {
          this.selectedCountry(this.countryList[this.selectedIndex])
        }
        break;
    }
    }
    
  }

  searchTextClear(text){
    this.searchText=''
    this.countryList=this.copyCountryCode
  }

  updateNumberField(event, index){
    this.valueCopy.splice(index, 1, event);
    this.onValueInput()
  }

  addMoreNumber(){
    this.valueCopy.push({value: undefined, country_code: undefined})
    this.addMoreEnableButtom.emit(false);
  }

  trackByFn(index){
    return index;
  }

  removeNumberField(value){
    this.newNumber=''
    this.onValueUpdate.emit(null)
  }

  removeOneNumberField(index){
    this.valueCopy.splice(index, 1);
    this.onValueInput();
  }

  addMoreEnable(){
    let addMoreEnableVariable=this.valueCopy.some(ele=> [undefined,null,''].includes(ele.country_code))
    console.log(addMoreEnableVariable)
  }

}
