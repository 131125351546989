import { Component, OnInit ,Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ConstantService } from '../../../../Enums/Constant.service';
import { NgbCalendar, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { CustomStorageService } from '../../../services/custom-storage.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { MqttConnectionService } from '../../../services/mqtt-connection.service';
import { MqttmessagingService } from '../../../services/mqttmessaging.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-time-tracking',
  templateUrl: './time-tracking.component.html',
  styleUrls: ['./time-tracking.component.scss']
})
export class TimeTrackingComponent implements OnInit, OnChanges {
  @Input() itemKey:any = null
  @Input() timeSpent: number = 0
  @Input() leadObj:any = null
  // @Input() activeTrackerList:any[]|null = null
  @Input() activeTrackerList:any[]=[]
  @Input() view: string = null
  @Input() dashId: string = null
  // @Input() isPublicLead:boolean = false;

  isReadOnly=  false
  customFieldsArray:any[]=[];
  timeManual:boolean=false;
  // timeTrackerPause:boolean=false;
  timeTrackerList:any[]=[];
  timer:any;
  orgUsersJson: any;
  dashboardUserRole:any;
  // userId:string;
  trackerWithoutEndtime:any[]=[]
  selectedTimeTracker:any;
  selectedUserForTimeTracker:string;
  startTime:any;
  endTime:any;
  customFieldType:any;
  userId:any;
  item:any;
  maxDate:any=new Date()
  mnt=moment
  leadObject:{}
  mqttSubscription: Subscription;
  timerInterval:any;
  dashboardUser:any[]=[]
  // isPublicLead:boolean = false;
  constructor(
    private httpTransfer: HttpTransferService,
    public constantService: ConstantService,
    private constant: ConstantService,
    private modal:NgbModal,
    public datepipe: DatePipe,
    private calendar: NgbCalendar,
    private dashboardUtils: DashboardUtilsService,
    public commonUtils: CommonUtils,
    private customStorageService:CustomStorageService, 
    private route: ActivatedRoute,
    private mqttConnectionService: MqttConnectionService,
    // private _mqttService: MqttService,
    private MqttmessagingService: MqttmessagingService,
    // private paramMap: ParamMap
  ) { 
    this.listenForMqttMessages()
    this.customFieldType = this.constant.getCustomTypes();
  }
  listenForMqttMessages() {
    this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
      if (message) {
        let messages=JSON.parse(message)
        if(this.dashId==messages.additional_attributes.dashboard_id && this.leadObj._id==messages.additional_attributes.updated_item?._id){
          if(messages[this.constant.constant.ACTIVITYTYPEKEY] == this.constant.constant.UPDATEACTIVITYTYPE && 
            (messages[this.constant.constant.OBJECTTYPEKEY] ==this.constant.constant.LEADOBJECTTYPE
              || messages[this.constant.constant.OBJECTTYPEKEY] ==this.constant.constant.TIMELINEOBJECTTYPE)){
              this.leadObject={...messages.additional_attributes.updated_item}
              if(!this.itemKey || !this.leadObj || !this.dashId || this.activeTrackerList===null ||this.dashboardUser===null) return;
              this.setTimeTracker()
              // this.dashboardUtils.queryActiveTimeTrackerList(this.dashId,this.userId)
            }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.keys(changes).includes('activeTrackerList') && !changes['activeTrackerList'].firstChange){
      this.setTimeTracker()
    }

  }

   async ngOnInit(){
    if(!this.itemKey || !this.leadObj || !this.dashId || this.activeTrackerList===null ||this.dashboardUser===null) return;
    this.leadObject={...this.leadObj}
    if(this.timerInterval) clearInterval(this.timerInterval)
    this.timerInterval = null;
    this.commonUtils.isDashboardReadOnly(this.dashId, (this.leadObject && this.leadObject['lead_colloborators_users']) || [] , this.leadObject).then((res : any) => { 
      this.isReadOnly = res;     
    });
    this.item = {key: this.itemKey}
    this.userId= this.customStorageService.getItem('id')
    this.orgUsersJson = await this.dashboardUtils.getOrgUsers();
    this.dashboardUser = Object.values(this.orgUsersJson);
    const dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.dashId])
    // this.dashboardUser =  dashboardInfo[this.dashId]?.DASHBOARD_USER || {};
    this.dashboardUserRole = dashboardInfo[this.dashId]?.DASHBOARD_INFO?.role || [];
    //code form here
    let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
    let res = JSON.parse(JSON.stringify(dashboardJson[this.dashId]['CUSTOM_FORM']));
    this.customFieldsArray = [];
    if (res.length > 0) {
      for (var i = 0; i < res.length; ++i) {
        this.customFieldsArray.push(res[i]);
      }
    }
    this.setTimeTracker()
  }

  //start time tracker
  createTimeTrackerStart(item){
    if(this.timerInterval)
      clearInterval(this.timerInterval);
    let startTime=new Date().getTime();
    this.startTimer(startTime,item)
    let data={
      "tracker_key":item.key,
      "action":"start",
      "lead_id":this.leadObject['_id'],
      "for_user_id":this.userId
    }
    this.httpTransfer.createTimeTracker(this.dashId,data).subscribe((res : any) =>{
      // this.commonUtils.queryActiveTimeTrackerList(this.dashId, this.userId);
    })
  }

  //stop the timer
  createTimeTrackerStop(item){
    if(this.timerInterval)
      clearInterval(this.timerInterval);
    this.timerInterval = null;
    this.timeSpent += Number(item['timerMS']);
    if(this.timeSpent !=0)
      this.totalTimeSpent(this.timeSpent,this.item)
    let user_id=item['started_by_user_id']
    let data={
      "tracker_key":item.key? item.key:item.custom_field_key,
      "action":"stop",
      "lead_id":this.leadObject['_id'],
      "for_user_id":user_id? user_id:this.userId
    }
    this.httpTransfer.createTimeTracker(this.dashId,data).subscribe((res : any) =>{
      // this.commonUtils.queryActiveTimeTrackerList(this.dashId, this.userId);
    })
  }

  //tracker details
  logDetails(item){
    this.httpTransfer.queryTimeTracker({
      "lead_id":this.leadObject['_id'],
      "dashboard_id":this.dashId,
      "tracker_key":[item.key]
      // "without_end_time":false
    }).subscribe(response =>{
      this.timeTrackerList=response['result']['message']
      for(let i = 0; i<this.timeTrackerList.length; i++){
        if(this.timeTrackerList[i]['start_time'] && this.timeTrackerList[i]['end_time']){
          let diff = this.timeTrackerList[i]['end_time'] - this.timeTrackerList[i]['start_time'];
          this.timeTrackerList[i]['time_difference']= this.commonUtils.timeConvert(diff)
        }
      }
    })
  }

  //enable or disable the buttons based on activettrackerlist sent from parent
  setTimeTracker(){
    if(this.timerInterval) clearInterval(this.timerInterval)
    this.timerInterval = null;
      this.trackerWithoutEndtime=this.activeTrackerList
      if(this.customFieldsArray.length > 0){
            let filterObj=this.activeTrackerList.filter(ele=> {
              return ele.custom_field_key.toLowerCase() == this.item.key.toLowerCase() && ele.lead_id==this.leadObj._id
            })
            this.item['pause'] = filterObj.length > 0
            if(this.item['pause']){
                this.startTimer(filterObj[0]['start_time'],this.item)
              this.item['started_by_user_id']=filterObj[0]['started_by_user_id']
            }
      }
        for(let j=0;j<this.customFieldsArray.length;j++){
          if(this.item['key']==this.customFieldsArray[j]['key']){
            if(!this.item['pause']){
              if(this.timeSpent && this.timeSpent!=0){
                this.totalTimeSpent(this.timeSpent,this.item)
              }
              else this.item['timer']= this.timeSpent ? this.commonUtils.timeConvert(this.timeSpent) : '00: 00: 00'
            }
            // if(this.arrayOfCustomFiled[i]['time_spend_till_now']=){
            // }
            if(!this.dashboardUserRole.includes('DASHBOARD_ADMIN')){
              this.selectedUserForTimeTracker=this.userId
            }
            this.item['process']=this.customFieldsArray[j]['tracker_settings']['process']
            if(this.customFieldsArray[j]['tracker_settings']['process']=='AUTOMATED' || this.customFieldsArray[j]['tracker_settings']['process']=='BOTH'){
              if(this.customFieldsArray[j]['tracker_settings']['allowed_to_start']=='ANY'){
                if(this.item['pause'] && (this.dashboardUserRole.includes('DASHBOARD_ADMIN') || this.item['started_by_user_id']==this.userId )){
                    this.item['disabled']=false
                }
                else if(!this.item['pause']){
                  this.item['disabled']=false
                }
                else this.item['disabled']=true
              }
              if(this.customFieldsArray[j]['tracker_settings']['allowed_to_start']=='ASSIGNEE'){
                console.log('condition here')
                if(this.item['pause'] && (this.dashboardUserRole.includes('DASHBOARD_ADMIN') || this.item['started_by_user_id']==this.userId )){
                    this.item['disabled']=false
                }
                else if(!this.item['pause'] && this.leadObject['assigned_to']==this.userId){
                  console.log('condition here')
                  this.item['disabled']=false
                }
                else {
                  this.item['disabled']=true
                  this.item['message']='Only assignee is allowed to start/stop tracker.'
                }
              }
              if(this.customFieldsArray[j]['tracker_settings']['allowed_to_start']=='COLLABORATOR'){
                if(this.item['pause'] && (this.dashboardUserRole.includes('DASHBOARD_ADMIN') || this.item['started_by_user_id']==this.userId )){
                    this.item['disabled']=false
                }
                else if(!this.item['pause'] && this.leadObject['lead_colloborators_users']?.includes(this.userId)){
                  this.item['disabled']=false
                }
                else {
                  this.item['disabled']=true
                  this.item['message']='Only collaborator is allowed to start/stop tracker.'
                }
              }
            }
            if(this.customFieldsArray[j]['tracker_settings']['process']=='MANUAL' || this.customFieldsArray[j]['tracker_settings']['process']=='BOTH'){
              if(this.customFieldsArray[j]['tracker_settings']['allowed_to_start']=='ANY'){
                this.item['manualdisabled']=false
              }
              if(this.customFieldsArray[j]['tracker_settings']['allowed_to_start']=='ASSIGNEE'){
                if(this.leadObject['assigned_to']==this.userId || this.dashboardUserRole.includes('DASHBOARD_ADMIN')){
                    this.item['manualdisabled']=false
                }
                else {
                  this.item['manualdisabled']=true
                  this.item['message']='Only assignee/admin can add manual session.'
                }
              }
              if(this.customFieldsArray[j]['tracker_settings']['allowed_to_start']=='COLLABORATOR'){
                if(this.leadObject['lead_colloborators_users']?.includes(this.userId) || this.dashboardUserRole.includes('DASHBOARD_ADMIN')){
                    this.item['manualdisabled']=false
                }
                else {
                  this.item['manualdisabled']=true
                  this.item['message']='Only collaborator/admin can add manual session.'
                }
              }
            }
          }
        }
  }

  //timer intervals function
  startTimer(startTime,item){
    if(!this.timerInterval)
    this.timerInterval=setInterval( () =>{
      let currentTime= new Date().getTime();
      let differTime=currentTime-startTime
      item['timerMS'] = differTime
      item['timer']=this.commonUtils.timeConvert(differTime)
      // item['timer']=`${days}D : ${hours}H : ${minutes}M : ${seconds}S`
    },1000)
  }

  //manual entry of time tracker
  manualTimerEntry(item){
    let data={
      "tracker_key":item.key,
      "start_time":this.startTime['time'],
      "end_time":this.endTime['time'],
      "lead_id":this.leadObject['_id'],
      "for_user_id":this.selectedUserForTimeTracker
    }
    this.httpTransfer.createTimeTracker(this.dashId,data).subscribe((res : any) =>{
    })
    this.endTime='';
    this.startTime=''
  }

  //delete tracker entry
  removeTimeTrackerEntry(id,forUserId){
    if(forUserId==this.userId || this.dashboardUserRole.includes('DASHBOARD_ADMIN')){
      this.httpTransfer.deleteTimeTracker(id).subscribe((res : any) =>{
        this.timeTrackerList = this.timeTrackerList.filter(ele=>ele._id!==id)
    })
    }
  }

  //convert time spent till now to standard format
  totalTimeSpent(time,item){
    item['timer']=this.commonUtils.timeConvert(time)
  }

  ngOnDestroy() {
    this.mqttSubscription?.unsubscribe();
  }
}
