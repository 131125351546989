import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { ConstantService } from '../../../../Enums/Constant.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { MqttmessagingService } from '../../../services/mqttmessaging.service';

@Component({
  selector: 'app-non-embedded-view-option',
  templateUrl: './non-embedded-view-option.component.html',
  styleUrls: ['./non-embedded-view-option.component.scss']
})
export class NonEmbeddedViewOptionComponent implements OnInit {
  @Input() leadObj:any
  // @Input() selectedBoardList:any
  @Input() dashBoardCustArr:any
  @Input() dashId:string=''
  @Input() leadId:string=''
  @Input() publicLead:boolean=false
  @Input() tabIndex: number = 1;
  @Input() dashboardUserRole:any
  @Input() showHiddenFields:any;
  @Input() dashboardInfo:any;
  @Input() nonEmbeddedListArray:any=[]
  connecteBoardIds:any[]=[];
  show = false
  @Output() onItemOpen = new EventEmitter<any>();
  @Output() showConnectedBoardFilter = new EventEmitter<any>();
  @Output() updateTheCountValue = new EventEmitter<any>();
  isEdit = false;
  isReadOnly: boolean;
  mqttSubscription: any;
  gridIsLoading:boolean=false;
  typeOfBoard:any

  constructor(private httpTransfer: HttpTransferService, private commonUtils: CommonUtils,
    private MqttmessagingService: MqttmessagingService,
    public constantService: ConstantService,
    private cd: ChangeDetectorRef,
    ) {
    this.listenForMqttMessages();

   }

   listenForMqttMessages() {
    this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
      if (message) {
          this.updatesFromWss(message)
      }
    });
  }

  updatesFromWss(messagees) {
    let message = JSON.parse(messagees);
    if (message[this.constantService.constant.ACTIVITYTYPEKEY] ==this.constantService.constant.UPDATEACTIVITYTYPE && message['additional_attributes']['details']['_id'] == this.leadObj['_id']) {
      if (message[this.constantService.constant.OBJECTTYPEKEY] == this.constantService.constant.LEADOBJECTTYPE) {
        this.handleLeadUpdateTypeOperation(message);
      }
    }
  }

  handleLeadUpdateTypeOperation(message) {
    this.leadObj = this.commonUtils.creatingJsonStructureOfLeadForTableView(message['additional_attributes']['details']);
    this.updateTheLeads(message)
  }

  updateTheLeads(message){
    let updatedCustomFields=message['additional_attributes']?.updated_keys?.filter(item=>item?.custom_fields)
    updatedCustomFields=updatedCustomFields.length>0 ? updatedCustomFields[0]?.custom_fields : []
    if(message?.activity_type=='UPDATE' && updatedCustomFields?.length>0){
      this.updateParticularConnectedBoard(updatedCustomFields,message)
    }
  }

  async ngOnInit() {}
    hardReload(){
      this.gridIsLoading=true
      // this.typeOfBoard=this.dashboardInfo[this.selectedBoardList.connected_board_id]?.DASHBOARD_INFO?.board_type;
      // if(['CONTACT','ORGANIZATION']?.includes(this.typeOfBoard)){this.gridIsLoading=true}
      // else{this.gridIsLoading=false}
      this.show = false;
      setTimeout(() => {
        this.show = true
      }, 5);
    }

  async updateParticularConnectedBoard(updatedCustomFields,message){
    await this.nonEmbeddedListArray.forEach(ele=>{
      if(updatedCustomFields?.includes(ele?.key)){
        ele['connecteBoardIds']=this.leadObj?.custom_fields[ele?.key]?this.leadObj?.custom_fields[ele?.key]:[];
        this.hardReload();
      }
    })
    this.updateTheCountValue?.emit(this.nonEmbeddedListArray)

  }

  async ngOnChanges(changes){
    if(changes.selectedBoardList){
      this.commonUtils.isDashboardReadOnly(this.dashId, this.leadObj?.lead_colloborators_users || [],this.leadObj).then((res : any) => { 
        this.isReadOnly = res
      });
     this.hardReload();

    }
  }

  ngOnDestroy() {
    this.mqttSubscription?.unsubscribe()
  } 
  

  setItemToBoard(item, leadData, type, key,insertIdIndex=-1) {
    if(item?.connecteBoardIds?.length==0) {
      item.connecteBoardIds = [];
      item?.connecteBoardIds.push(leadData._id)
    }else if(insertIdIndex>-1 && insertIdIndex<=item?.connecteBoardIds?.length){
      item?.connecteBoardIds.splice(insertIdIndex,0,leadData._id)
    } 
    else if(!item?.connecteBoardIds?.includes(leadData._id)){
      item?.connecteBoardIds.push(leadData._id)
    }   
    this.onValueInput(item)
  }

  updateItemToBoard(item, leadData, type, key) {  
    item.connecteBoardIds=leadData
    console.log(item?.connecteBoardIds)
    this.onValueInput(item)
  }

  removeItemFromBoard(item, leadData, type, key) {
      let index = item?.connecteBoardIds?.findIndex(el => el === leadData._id);
      if(index >=0) {
        item?.connecteBoardIds?.splice(index,1);
        this.onValueInput(item)
      }
  }

  isReadOnlyField(isLocked) {
    let isTrue = false;
    if(this.isItemLocked(isLocked) || this.isReadOnly) {isTrue = true;}
    return isTrue;
  }

  isItemLocked(lockflag){
    if(this.publicLead){return true;}
    if(this.dashboardUserRole?.includes("DASHBOARD_ADMIN")){return false;}
    else{return lockflag;}
  }

  onValueInput(item){
    this.httpTransfer.updateLeadCorrespondingToLeadId({custom_fields:{[item?.key]:item?.connecteBoardIds}},this.dashId, this.leadId).subscribe((res:any)=>{
      if (res?.status === 200) {}
    })
  }

  isGridLoaded(event){
    this.gridIsLoading=true
  }

  
}
