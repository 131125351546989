import { Component, EventEmitter, Input, KeyValueDiffers, OnInit, Output} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BasicUtils } from 'app/services/basicUtils.service';
import { ConstantService } from '../../../Enums/Constant.service';
import { MediaSmIcons } from '../../../Enums/media-sm-icons';
import { CommonUtils } from '../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../services/dashboard-utils.service';

@Component({
  selector: 'app-mirror-column-options',
  templateUrl: './mirror-column-options.component.html',
  styleUrls: ['./mirror-column-options.component.scss']
})
export class MirrorColumnOptionsComponent implements OnInit {
  @Input() optionsArr  = [];
  @Input() customItem;
  @Input() currentItem;
  @Input() mirrorColumnsJson = {};
  @Input() currencyJson = [];
  @Input() renderItemCount : any;
  @Input() columnView:false
  @Output() onModalOpen: EventEmitter<any> = new EventEmitter();
  @Input() customField: any={};
  orgUsersJson: any;
  priorityJson: {};
  mediaPreview: {};
  modalRef: any;
  items: any[] = []
  MediaSmIcons = MediaSmIcons;
  differ:any
  
  constructor(public commonUtils: CommonUtils,
    private ngbModalService: NgbModal,
    private dashboardUtils: DashboardUtilsService,
    public constantService: ConstantService,
    private basicUtils:BasicUtils,
    private differs: KeyValueDiffers) {
    this.differ = this.differs.find({}).create();
    this.priorityJson = this.commonUtils.priorityJson;
   }

  async ngOnInit() {
    if(this.customItem?.key) this.customItem['columnKey'] = this.customItem?.key;
    this.orgUsersJson = await this.dashboardUtils.getOrgUsers();
    if(!Object.keys(this.customField)?.length){
      this.customField = this.currentItem?.custom_fields ? this.currentItem?.custom_fields[this.customItem.columnKey] : this.currentItem[this.customItem.columnKey]
    }
    this.filteredData();
  }

  ngDoCheck(){
    const changes = this.differ.diff(this.mirrorColumnsJson);
    if (changes) {
      this.filteredData();;
    }
  }

  filteredData(){
    this.items = this.commonUtils.getMirrorData(this.customField, this.mirrorColumnsJson)
    return this.items
  }
  

  openMediaPreview(itemId, template, index?){
    let mediaArr = this.mirrorColumnsJson[itemId].custom_fields[this.customField?.mirror_column_key]
    if(Array.isArray(mediaArr))
      mediaArr?.forEach(item => {
        item.name = item.file_name + '.' + item.file_path.split('.').pop(),
        item.path = item.file_path,
        item.url = item.file_url,
        item.type = this.customField.mirror_column_type;
      })
    else
      mediaArr = [{
        name: mediaArr.file_name + '.' + mediaArr.file_path.split('.').pop(),
        path: mediaArr.file_path,
        url: mediaArr.file_url,
        type: this.customField.mirror_column_type,
      }]
    this.mediaPreview = {
      dashId: this.mirrorColumnsJson[itemId]?.dashboard_id,
      leadId: itemId,      
      index: 0,
      mediaArr: mediaArr,
      ...(index && {index: index})
    }
    this.onModalOpen.emit(null)
    this.modalRef = this.ngbModalService.open(template, { size: 'lg' , windowClass: 'file preview-modal', keyboard: false}) ;
    this.basicUtils.storeModlRef(this.modalRef)
  }

}
