<div class="container-fluid common-header " style="height:60px;">
    <div class="h-100 w-100 d-flex align-items-center" id="main">
      <div class="width-80 text-left">
        <ng-content></ng-content>
      </div>
   <!--    <div class="width-20 pl-0 justify-content-around user-profile-notif headerRightSide">
        <div class="row text-right float-right toprighticons mr-2">  -->
          <div class="userIconDiv">
            <div class="toprighticons"> 
          <!-------Notification-------->
          <!-------Logged In User Details-------->
          <app-board-header-right-section
          [profileCall]="profileCall"
          [showProfile]="true"
          [showNotification]="true"
          [homeCall]="homeCall">
          </app-board-header-right-section>
        </div>
      </div>
    </div>
</div>
