<div class="functionEditor" style="height:100%;display: flex;flex-direction: column;row-gap: 10px;">
 <div style="height:80%;border: 2px solid #ccc;border-radius: 8px;">
    <div #dynamicFunctionEditor></div>
 </div>
 <div class="bottomBar">
    <div class="bottomText">
        Click on function name and arguments to see more option
    </div>
    <div class="buttonBar">
        <button class="btnSave" (click)="checkForValidation(selectedFunction?.parameters)">Validate</button>
        <button class="btnSave" [disabled]="!isValid" [ngClass]="{'disableBtn' : !isValid}" (click)="saveFunc()">Apply</button>
    </div>
 </div>
</div>



<ng-template #myTemplate let-functionData let-index="index" let-info="info">
    <div [ngClass]="{'msg-textarea': index === 0}" class="functionContent">
        <div style="display: flex;align-items: center;" class="functionItem">
        <div class="w-100 fontSize funcParent">
<!--             <span class="nameHover" [ngbPopover]="removefunctions" #popRemove="ngbPopover" [autoClose]="'outside'" [ngbTooltip]="funcnametooltip" placement="bottom" tooltipClass="tooltipPopup">{{functionData?.display_name}} (</span>
 -->            <!--Tooltip on hover-->
            <div [class.functionUnique]="functionData.uniqueid" [attr.data-id]="functionData.uniqueid" (mouseenter)="onMouseEnter($event)"
            (mouseleave)="onMouseLeave($event)" [ngbTooltip]="functooltipDesc" [ngbPopover]="removefunctions" #popRemove="ngbPopover" [autoClose]="'outside'" tooltipClass="tooltipPopup">{{functionData?.display_name}} (</div>
            <ng-template #functooltipDesc>
                <div>
                    <div class="funcNameHover">{{functionData?.display_name}} (
                        <ng-container *ngFor="let item of functionData?.parameters;let argsIndex= index">
                           <div>{{functionData?.display_name.charAt(0).toLowerCase()}}{{argsIndex+1}}({{item?.type}})</div>
                        </ng-container>
                    )
                    </div>
<!--                     <div><{{functionData?.return_type}}></div>
 -->                    <div>{{functionData?.long_description}}</div>
                        <div>{{functionData?.example}}</div>
                </div>
            </ng-template>
            <ng-container *ngFor="let item of functionData?.parameters;let i= index">
             <ng-container *ngFor="let innerfunc of item?.funcObj;let funcObjIndex= index">
                <div class="isParam">{{functionData.display_name.charAt(0).toLowerCase()}}{{i+1}}: ({{item?.type}}) :{{item?.sub_type}}</div>
            <ng-container *ngTemplateOutlet="myTemplate; context:{ $implicit:innerfunc,index:index+1,info:{index:funcObjIndex,arr:item?.funcObj}}"></ng-container>
            </ng-container>    
            <ng-container *ngIf="!item?.funcObj?.length">
            <div style="display: flex;align-items: center;"><div class="danger argsInput" *ngIf="(item?.type!=='array' && item?.sub_type!=='array')" [ngClass]="(item?.value || item.isColumn && item?.selectedColumnObj?.value) ? 'isParam' : 'danger'" [ngbPopover]="functionParams" 
            #popParameterArgs="ngbPopover" placement="auto" [autoClose]="'outside'" [ngbTooltip]="paramTooltip" tooltipClass="tooltipPopup">{{functionData.display_name.charAt(0).toLowerCase()}}{{i+1}} : ({{item?.type}}) :{{item?.sub_type}}</div>
            <div class="danger" *ngIf="(item?.type==='array' || item?.sub_type=='array')"><span class="argsInput" [ngbTooltip]="paramTooltip" tooltipClass="tooltipPopup">{{functionData.display_name.charAt(0).toLowerCase()}}{{i+1}} : ({{item?.type}}) :{{item?.sub_type}} </span>[
                <ng-conatiner *ngFor="let innerItem of item?.params;let innerIndex=index">
                    <span>
                        <span *ngIf="innerItem.showInput && innerItem.sub_type=='string'"><input type="text" [(ngModel)]="innerItem.value" (blur)="innerItem.showInput=false"/></span>
                        <span *ngIf="innerItem.showInput && innerItem.sub_type=='number'"><input type="number" [(ngModel)]="innerItem.value" (blur)="innerItem.showInput=false"/></span>
                        <span *ngIf="!innerItem.showInput && innerItem?.value" class="paramValue arrayParamInput" (click)="paramIndex=innerIndex;" [ngbPopover]="functionParams" #popParameterInfo="ngbPopover" placement="auto" [autoClose]="'outside'">{{innerItem?.value}}
<!--                             <span class="dotsInfo" (click)="paramIndex=innerIndex;" [ngbPopover]="functionParams" #popParameterInfo="ngbPopover" placement="auto" [autoClose]="'outside'"><img _ngcontent-utx-c310="" src="../../../assets/1x/vertdots.svg" style="height:12px;width:12px;"></span>
 -->                        </span>
                        <ng-container *ngFor="let innerArrfunc of innerItem?.funcObj;let innerfuncIndex= index">
                            <span class="paramValue arrayParamInput" style="display: inline-block;">
                                <ng-container *ngTemplateOutlet="myTemplate; context:{ $implicit:innerArrfunc,index:index+1,info:{index:innerfuncIndex,arr:innerItem?.funcObj}}"></ng-container>
                            </span> 
                            </ng-container>
                        <span *ngIf="item?.params?.length!==innerIndex+1">,</span>
                    </span>
                </ng-conatiner>
                <span class="plus" (click)="paramIndex=-1;" [ngbPopover]="functionParams" #popParameterParam="ngbPopover" placement="auto" [autoClose]="'outside'">
                    <div style="cursor: pointer;width: 18px;height: 18px;border-radius: 3px;display: flex;align-items: center;justify-content: center;"><i class="fa fa-plus" style="color: #727985;line-height: normal"></i></div>
                </span>
            ]   
            </div>
            <ng-template #paramTooltip><div>{{item?.name}}</div></ng-template>
            <div *ngIf="item.showInput && (item.type=='string' || item?.sub_type=='string') && item?.name!='timeUnit'"><input type="text" [(ngModel)]="item.value" (blur)="item.showInput=false"/></div>
            <div *ngIf="item.showInput && (item.type=='number' || item?.sub_type=='number') && item?.name!='quantity'"><input type="number" [(ngModel)]="item.value" (blur)="item.showInput=false"/></div>
            <div class="innerFuncBox" *ngIf="item.showInput && item.type=='number' && item?.name=='quantity'"><div style="display:flex;height: 30px;"><span class="calculateDate" (click)="item.value=item.value-1">-</span><input type="number" style="border-radius: 0px;" [(ngModel)]="item.value"/><span class="calculateDate" (click)="item.value= +item.value+1">+</span></div></div>
            <div class="innerFuncBox" *ngIf="item.showInput && item.type=='string' && item?.name=='timeUnit'"><div style="width: 100px;height: 30px;"><ng-select [items]="dateUnitArr" bindLabel="display_key" bindValue="key" appendTo="body" [clearable]="false" [(ngModel)]="item.value"></ng-select></div></div>
            <div class="innerFuncBox" *ngIf="item.type=='boolean'"><div style="width: 100px;height: 30px;"><ng-select [items]="booleanArr" bindLabel="display_key" bindValue="key" [clearable]="false" [(ngModel)]="item.value"></ng-select></div></div>
            <span *ngIf="item.type=='date'">
                <div class="d-inline-block ml-2 mb-2 mt-2" 
                [ngbPopover]="datePopover" #popDate="ngbPopover" placement="auto" popoverClass="datePickerPopover"
                [autoClose]="'outside'" (click)="$event.stopPropagation()">
                <div class="d-inline-block" *ngIf="item.showInput">
                  <img src="../../../assets/1x/calender-ico.svg" class="calendarImg">
                </div>
                <div class="d-inline-block ml-2" *ngIf="item?.value?.date">
                  {{item?.value | dateCalcuate:"dateTime"}}
                </div> 
                </div> 
             <ng-template #datePopover>
                <date-picker 
                [dateRange]="{'from':item.value}"
                [focusDate]="item.value"
                (onDateSave)="item.value=$event;popDate.close()"
                (onDateClear)="item.value = '';popDate.close()">
                </date-picker>
            </ng-template> 
            </span>
            <div *ngIf="!item.showInput && (item?.value || item?.value>=0) && !item.isColumn && item.type!='date' && item.type!='array'"><span class="paramValue" [ngClass]="{'columnChip' : item?.selectedColumnObj?.label}">{{item?.value}}</span></div>
            <div *ngIf="!item.showInput && item.isColumn && item?.selectedColumnObj && item.type!=='boolean'" class="columnChip"><span>{{item?.selectedColumnObj?.label}}</span></div>
            <ng-template #columntooltip>{{item?.selectedColumnObj?.label}}</ng-template>
            <div class="m-1" *ngIf="functionData?.parameters?.length!==i+1">,</div></div>
            </ng-container>
            <ng-template #functionColumns>
                <app-automation-columns [dashboardVariable]="dashboardVariable" [dashVariableArr]="dashVariableArr" [filterCall]="filterCall" [customfieldType]="customfieldType" [showIndex]="showIndex" [item]="item" [dashId]="dashId" [customFieldArr]="customFieldArr" [isTriggeredNonItemField]="isTriggeredNonItemField" [catchHookReqJson]="catchHookReqJson" [triggerType]="triggerType" [columnsJson]="columnsJson" [isHeader]="false" (selectedColumn)="insertColumns($event,item)"></app-automation-columns>
              </ng-template> 
              <ng-template #functionPop>
                <div class="funContainer">
                    <app-function-popover (getFunctionsEmit)="getFunctionsEmit($event,item)"></app-function-popover>
                </div>
            </ng-template> 
            <ng-template #functionParams>
                <div class="paramPopover">
                    <ng-container *ngIf="(item?.type!=='array' && item?.sub_type!=='array' && item.type!=='boolean')">
                        <div class="paramOptionRow" [ngbPopover]="functionColumns" #popColumns="ngbPopover" placement="auto" [autoClose]="'outside'" (click)="isValid=false"><span *ngIf="!dashboardVariable && !dashVariableArr?.length">Columns</span><span *ngIf="dashboardVariable && dashVariableArr?.length">Variables</span></div>
                        <div class="paramOptionRow" [ngbPopover]="functionPop" #popFunction="ngbPopover" placement="auto" [autoClose]="'outside'" (click)="isValid=false"><span>Functions</span></div>
                        <div class="paramOptionRow" (click)="showInputforParams(functionData,i);isValid=false"><span *ngIf="!item.value || (item.isColumn && item?.selectedColumnObj)">Enter Manually</span><span *ngIf="item.value && !item.isColumn && !item?.selectedColumnObj">Edit</span></div>
                        <div class="paramOptionRow" *ngIf="item.type=='rest'" (click)="addParameForRestType(item,functionData);isValid=false" ><span>Add More Options</span></div>
                        <div class="paramOptionRow" *ngIf="item.type=='rest'" (click)="removeParameForRestType(functionData,i);isValid=false"><span>Remove</span></div>     
                    </ng-container>
                    <ng-container *ngIf="(item?.type==='array' || item?.sub_type==='array')">
                        <div class="paramOptionRow" [ngbPopover]="functionColumns" #popColumns="ngbPopover" placement="auto" [autoClose]="'outside'" (click)="isValid=false"><span>Columns</span></div>
                        <div class="paramOptionRow" [ngbPopover]="functionPop" #popFunction="ngbPopover" placement="auto" [autoClose]="'outside'" (click)="isValid=false"><span>Functions</span></div>
                        <div class="paramOptionRow" (click)="showInputforArrayTypeParams(functionData,i,'string');isValid=false"><span>Enter Text</span></div>
                        <div class="paramOptionRow" (click)="showInputforArrayTypeParams(functionData,i,'number');isValid=false"><span>Enter Number</span></div>
                        <div class="paramOptionRow" *ngIf="paramIndex>=0" (click)="deleteInputforArrayTypeParams(functionData,i);isValid=false"><span>Delete</span></div>
                    </ng-container>
                </div>
            </ng-template>    
            </ng-container>
                <ng-container><span [class.functionUnique]="functionData.uniqueid" [attr.data-id]="functionData.uniqueid">)</span></ng-container>   
            <ng-template #removefunctions>
                <div class="funcRemovePopover">
                        <div class="paramOptionRow" (click)="removefunction(info);isValid=false"><span>Remove Functions</span></div>
                </div> 
            </ng-template>
         </div>
        </div>
    </div>
</ng-template>



