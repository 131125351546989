<div class="tagComponent" [class.item-create-tag]="!openInSetting">
    <div class="scrollarea " *ngIf="tagFieldArray.length" id="popOver" #container keyboardMonitor [ngClass]="{'scrollWidth' : customfield}">
        <div class="tagListBox">
            <ng-container *ngIf="!tagRecieved && tagFieldArray.length>0 ">
                <div  class="col-12 text-center">
                    <div class="spinner-border m-1" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>  
                </div>
            </ng-container>
            <ng-container *ngIf='tagFieldArray.length>0'>
                <div class="w-100 g-0 tagNameBox" *ngFor="let item of tagFieldArray;let index=index">
                    <ng-container *ngIf="!item.is_deleted && !item.isEditSelected">
                        <div class="key-tab tag-detais" tabindex="0" id="tagName" >
                            <div  class="commonRowInnerLeft">
                                <p appOverflowCheck [ngbTooltip] class="itemName overflow-hidden text-truncate text-nowrap f-l-r">
                                    {{item.name}}
                                </p>
                            </div>
                            
                            <div class="commonRowInnerRight" *ngIf="checkUserHaveEditAccessInDashboard() && !item.isEditSelected" [class.showTagIcons]="colorTag?.isOpen()">
                                <span (click)="editTag(item)" class="key-updown cursor-pointer iconBox-30 hoverOptionIcon"><app-dashboard-icon class="appInnerIconBoxr" [size]="20"  [name]="'editOutline'" [iconJson]="commonIcons"  ></app-dashboard-icon></span>
                                <span (click)='showDeleteModal(deleteTagConfirmationModel,item.tag_id)' class="key-updown hoverOptionIcon iconBox-30 cursor-pointer"><app-dashboard-icon class="appInnerIconBox" [size]="20"  [name]="'deleteOutline'" [iconJson]="commonIcons"  ></app-dashboard-icon></span>
                                <span  class="iconBox-24 cursorPointer key-updown d-flex" [ngbPopover]="tagColor" #colorTag="ngbPopover" [placement]="['left-bottom','bottom-right','bottom','auto']"  [autoClose]="'outside'" [ngStyle]="{'background-color': item?.color ? item.color : '','color': item?.color ? commonUtils.getContrastColor(item?.color) : ''}">
                                    <app-dashboard-icon *ngIf="!item?.color" class="appInnerIconBox" [size]="20"  [name]="'paint'" [iconJson]="attributesIcons"  ></app-dashboard-icon>
                                </span>
                                <!-- <span   class="key-updown cursor-pointer iconBox-30" [ngbPopover]="tagColor" #colorTag="ngbPopover" [placement]="['left-bottom','bottom-right','bottom','auto']"  [autoClose]="'outside'" ></span> -->
                            </div>
                            
                        </div>
                       <ng-template #tagColor>
                           <div style="width: 190px">
                            <color-picker [selectedColor]="item.color" (colorChange)="changeColor($event,item)"></color-picker>
                           </div>
                       </ng-template>
                    </ng-container>
                    <!-- edit tags -->
                    <ng-container *ngIf="item.isEditSelected">
                        <div class="row editTagName" id="tagName">
                            <div  class="col-sm-9 col-9 pr-1 pl-2" id="conditionalEditInput">
                                <input type="text" placeholder="Enter Tag name!" #tagName="ngModel" id="inputTag" style="border:none;" class="editTagInput" [(ngModel)]="item.name"
                                    [ngModelOptions]="{standalone: true}" (blur)="updatingTag(item)"
                                    (clickOutside)='setTagStatus()' (keydown.enter)="updatingTag(item)" required>
                            </div>
                            <div class="col-sm-3 col-3 text-center pr-1 pl-1">
                                <button class="editBtn" (click)="updatingTag(item)" [disabled]="tagName?.value?.length<3 || tagName.value.length > 100" >{{'common.save' | translate}}</button>
                            </div>
                        </div>
                        <div *ngIf="(tagName.dirty || tagName.touched)" class="text text-danger f-s-r">
                            <div *ngIf="tagName.value.length < 3">{{'tag.tagMinChar' | translate}}</div>
                            <div *ngIf="tagName.value.length > 100">{{'tag.tagMaxChar' | translate}}</div>
                        </div>  
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    
    
    <div *ngIf='tagFieldArray.length==0' class="emptyTags">
        <div class="emptyTagsBox">
            <img class="w-70 h-70" src="../../../assets/img/icons/empty.svg"/>
            <p class="f-m-r">{{'tag.noTagAdded' | translate}}</p>
        </div>
    </div>
    
    <div class="addTagBox" *ngIf="checkUserHaveEditAccessInDashboard()" [class]="selectedThemeColor">
        <div *ngIf="openCreateNewTagFormFlag" class="createTagBox">
            <div class="dotbrdr">
                <input type="text"  #searchInput placeholder=" {{itemAttributesObj?.tag_id?.label | attributesLabel: itemAttributesObj: 'tag_id':'tag.addTag' | translate}}" #tagText="ngModel" id="inputTag" [(ngModel)]="inputTagName" 
                    [ngModelOptions]="{standalone: true}" (keydown.enter)="!isGrid && createNewTagForDashboad()"
                    [attr.autofocus]="tagRecieved" class="mx-0 key-tab f-l-r " tabindex="0">
                <ng-container *ngIf="isGrid">
                    <button  style="border-radius:8px;padding: 4px 10px;" class="btn btn-primary filterBtn ml-1" (click)="createNewTagForDashboad()" [disabled]="inputTagName?.length <3 || inputTagName?.length >100">{{'common.create' | translate}}</button>
                </ng-container>
                <div *ngIf="(tagText.dirty || tagText.touched)" class="text f-s-r helpBucketText">
                    <div *ngIf="tagText.value?.length < 3 ">
                        {{'tag.tagMinChar' | translate}}
                    </div>
                    <div *ngIf="tagText.value?.length > 100">
                        {{'tag.tagMaxChar' | translate}}
                    </div>
                </div>
            </div>
            <div style="text-align: left; display: flex; align-items: center;">
                <div class="savebtn  mt-2 themeChild" (click)="createNewTagForDashboad()">
                   <span class="f-l-r">{{'common.save' | translate}}</span>
                </div>
                <button class="cancelbtn mt-2 ml-1 f-l-r" (click)="openCreateNewTagFormFlag=false">{{'common.cancel' | translate}}</button>
             </div>
        </div>
        <div *ngIf="!openCreateNewTagFormFlag" class="d-flex flex-column align-items-center position-relative">
            <p class="mt-3 f-s-r">{{'tag.rearrangeStepsorAdd' | translate}}</p>
            <!-- itemAttributesObj?.title?.label | attributesLabel: itemAttributesObj: 'title': 'editcustom.summary' | translate -->
            <div class="commonBtn-36 themeChild" [ngbPopover]="workFoldeTag" #wfTag="ngbPopover" placement="auto"  [autoClose]="'outside'" triggers="manual">
                    <span [ngClass]="{'wfbattBtn': currentDashInfo?.type!='WORK_FOLDER'}" class=" commonBtnBox-Child-1 f-l-r" (click)="openCreateNewTagFormFlag=true;focusOnInput()">
                        <app-dashboard-icon [size]="20"  [name]="'plus'" [iconJson]="attributesIcons"></app-dashboard-icon>
                        {{'tag.addTag' | translate}}
                    </span>
                <span *ngIf="!['WORK_FOLDER','FOLDER'].includes(currentDashInfo?.type)" class="commonBtnBox-Child-2 "  (click)="showWFTag()" role="button">
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>
                </span>
            </div>
            <div class="back-tag-btn optionDetailBox" *ngIf="!openInSetting" (click)="onCloseCreateTags();$event.stopPropagation()">
                <app-dashboard-icon [size]="18" [name]="'Back'"></app-dashboard-icon>
            </div>
        </div>
    
            <!--WF Buckets-->
            <ng-template #workFoldeTag>
                <ng-container>
                   <div class="wfPopupBody">
                    <div class="f-s-m wfPopupBodyTitle">WorkFolder Tags</div>
                        <ng-container *ngIf="workFolderTags?.length">
                            <div class="wfb">
                                <ng-container *ngFor="let wfbuck of workFolderTags">
                                    <div class="wf-b f-l-r wfOptions" *ngIf="!wfbuck.isDeleted" [ngStyle]="{'background-color': wfbuck?.color ? wfbuck.color : '','color': wfbuck?.color ? commonUtils.getContrastColor(wfbuck?.color) : ''}" (click)="updateTagWfToDash(wfbuck)">{{wfbuck?.name}}</div>
                                </ng-container>
                            </div>  
                        </ng-container>
                        <ng-container *ngIf="!workFolderTags?.length">
                            <div class="f-s-r emptyBucketText">No Tags To Show</div>
                        </ng-container>
                        <div *ngIf="openInSetting" role="button" class="wManageBucketBtn"  (click)="openWorkFolderTags?.emit(getWorkFolderData)">
                            <span  class="f-s-r wManageBucketText">
                                <img src="../../../assets/common-use-icon-20dp/settings-outline-6d6e6f.svg">
                                Manage Tags
                            </span>
                        </div>
                   </div>
                </ng-container>
              </ng-template>
    </div>
</div>
 <!-- ----------delete tag confirmation model start----- -->
 <ng-template #deleteTagConfirmationModel let-modal>
    <div class="modal-body text-center py-3 f-m-r" keyboardMonitor tabindex="0">
       <p class="f-xxl-b">{{'common.confirmation' | translate}}</p> 
       <img src="../../../assets/1x/info.png">
       {{'tag.tagDeleteConfirm' | translate}}
       <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-danger rounded px-4 mr-3 key-tab" tabindex="1" (click)="deleteTag(); modal.dismiss()">{{'common.confirm' | translate}}</button>
        <button type="button" class="btn rounded cancelbtn px-4 key-tab" tabindex="1" (click)="modal.dismiss()">{{'common.cancel' | translate}}</button>
       </div>
    </div>
</ng-template>
<!-- ----------delete tag confirmation model end----- -->