import { Component, AfterViewInit, Input, ElementRef, ViewChild } from '@angular/core';

declare var google: any;

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements AfterViewInit {
  map: any//google.maps.Map;
  link:any;
  @Input() latitude = 37.7749; // Example latitude
  @Input() longitude = -122.4194; // Example longitude
  @ViewChild('mapContainer') mapContainer: ElementRef;
  
  async ngAfterViewInit() {
    let lat = this.latitude // Replace with your desired latitude
    let lng = this.longitude // Replace with your desired longitude
 /*    const { Map } = await google.maps.importLibrary("maps");
     this.map = new Map(this.mapContainer.nativeElement, {
      center: { lat: this.latitude, lng: this.longitude },
      zoom: 8,
    });  */
  
  this.link="http://www.google.com/maps/place/"+this.latitude+','+this.longitude
  
  } 
}