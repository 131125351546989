  <ng-container *ngIf="lock || searchInitial">
    <div class="d-flex justify-content-center align-items-center my-2">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only"> {{'common.loading' | translate}}...</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf='noResponse'>
    <div class="alert alert-info" role="alert">
      {{'editcustom.noRecordFound' | translate}}
    </div>
  </ng-container>

  <ng-container *ngIf="!lock && searchLeadList.length>0">
    <div class="leadListWrapper searchItemLists">
      <ng-container *ngFor="let item of searchLeadList;let i=index">
        <div class="row leadList" (click)="returnClickedItem(item,i)">
          <div class="row w-100 px-1 mx-0 itemUpperRows">
            <div class="col-md-2">
              <span [style.background]="commonUtils?.priorityJson[item.priority]?.color || 'prioritylabelL'" class="prioLabel" >{{commonUtils?.priorityJson[item.priority]?.label}}</span>
              <div class="leadTitle MontserratFont pl-1">#{{item.seq_id}}</div>   
            </div>
            <div class="col-md-3 pl-0">
              <div class="tableDiv">
                  <ngx-avatars class="mr-1" [name]="orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'" [src]="orgUsersJson[item.assigned_to]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                  <span class="styleS">{{orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'}}</span>
              </div>
            </div>
            <ng-container *ngFor="let bucket of bucketOfCorrespondingDash">
              <ng-container *ngIf="bucket._id==item.bucket_id">
                <div class="col-md-2 pl-0 styleS">{{bucket.name  | truncate : 7}}</div>
              </ng-container>
            </ng-container>
          </div>
          <div class="row w-100 mx-0 itemLowerRows">
            <div class="col-md-12 px-1" title="{{item.title}}">
              <p class="leadDescription mb-0">{{item.title | truncate : 125}}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container> 