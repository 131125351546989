import { ComponentFactoryResolver, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersComponent } from './filters.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from '../../all-reusables/shared-components/shared.components.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ReusableModule } from '../../reusable.module/reusable.module';
// import { ReusableComponentModule } from '../../reusable/reusableComponent.module';
import { AdvanceFilterComponent } from '../advance-filter/advance-filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { EvaluateDateComponent } from '../evaluate-date/evaluate-date.component';
import { FiscalDateComponent } from '../fiscal-date/fiscal-date.component';
import { FilterComponent } from 'ag-grid-community/dist/lib/components/framework/componentTypes';



@NgModule({
  declarations: [FiltersComponent,AdvanceFilterComponent,EvaluateDateComponent,FiscalDateComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    SharedComponentsModule,
    NgSelectModule,
    PopoverModule.forRoot(),
    ReusableModule,
    ReactiveFormsModule,
    // ReusableComponentModule,
    TranslateModule
  ],
  exports: [FiltersComponent, AdvanceFilterComponent,EvaluateDateComponent,FiscalDateComponent]
})
export class FiltersModule { 
}
