import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: 'app-view',
  template: `<iframe [src]="safeViewUrl" [ngStyle]="iframeStyles"></iframe>`
})
export class AppViewComponent implements OnInit {
    @Input() selectedDashId :any;
    @Input() viewUrl :any;
    safeViewUrl: SafeResourceUrl;
    iframeStyles = {
        height: 'calc(100% - 35px)',
        width: '100%'
      };
  constructor(private sanitizer : DomSanitizer){}

  ngOnInit(): void {
      console.log(this.viewUrl)
      if(this.viewUrl){
        this.safeViewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.viewUrl);
      }
  }

}