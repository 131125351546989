<div class="mainContainer">
   <div class="groupHeader" *ngIf="!addMemberOnly" >
      <div class="f-l-m groupName">{{selectedGroup?.group_name | truncate:25}}</div>
      <div *ngIf="isloggedInUserGroupAdmin && currentView!='AddMembers'" (click)="openAddMemberPopup()" class="cursorPointer f-s-r addMember">{{'groupView.addMember' | translate}}</div>
   </div>
   <div class="row">
      <div *ngIf="currentView=='GroupMember'" class="user-list-container" >
         <div *ngIf="allmembersOfSelectedGroup.length==0" style="text-align: center;">Loading......</div>
         <div *ngIf="allmembersOfSelectedGroup.length > 0" class="userListArea">
            <div *ngFor="let groupMember of allmembersOfSelectedGroup" class="teamlst">
                  <!-- <span><img src="../../../assets/1x/vishav-img.png"></span> -->
                  <div class="userDetailsBox">
                     <span class="pr-1 avatarProfile">
                        <ngx-avatars class="mr-1" [name]="groupMember.name" [src]="groupMember.image_url" size="30" initialsSize="2" ></ngx-avatars>
                     </span>
                     <!-- <span *ngIf="!user?.image" class="nameInitial text-light" [style]="{'background-color': user?.nameInitialBgColor}">{{user?.nameInitial}}</span> -->
                     <span class="UserNameText f-l-r">{{groupMember.name}}
                        <br>
                        <span class="emailIdOrTotalMembers f-s-r">{{groupMember.email}}</span>
                     </span>
                  </div>
                  <div class="userRoleAndThreeDots f-s-r">  
                     <span *ngIf="groupMember.group_admin" class="text-right adminTxt px-1">{{'groupView.admin' | translate}}</span> 
                     <span *ngIf="groupMember._id === loginUserId" (click)="removeFromGroup(groupMember)" class="text-danger cursorPointer text-right">{{'groupView.leave' | translate}}</span> 
                     <span *ngIf="isloggedInUserGroupAdmin">
                        <span role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-hidden="true" aria-haspopup="true" aria-expanded="false"><app-dashboard-icon class="iconBox-14" [size]="14" [name]="'dotsVertical'" [iconJson]="commonIcons"></app-dashboard-icon>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                           <div class="popover-body">
                              <div class=" optionDetailBoxBody">
                                 <a *ngIf="!groupMember.group_admin" class="optionDetailBox f-s-r" (click)="addOrUpdateUserInGroup(groupMember,false,true)"><app-dashboard-icon class="iconBox-20" [size]="20" [name]="'User'" [iconJson]="commonIcons"></app-dashboard-icon>{{'groupView.makeAdmin' | translate}}</a>
                                 <a *ngIf="groupMember.group_admin" class="optionDetailBox f-s-r" (click)="addOrUpdateUserInGroup(groupMember,false,true)"><app-dashboard-icon class="iconBox-20" [size]="20" [name]="'userRemove'" [iconJson]="commonIcons"></app-dashboard-icon>{{'groupView.removeAdmin' | translate}}</a>
                                 <a *ngIf="groupMember._id !== loginUserId" class="dropdown-item optionDetailBox f-s-r" (click)="removeFromGroup(groupMember)"><app-dashboard-icon class="iconBox-20" [size]="20" [name]="'userRemove'" [iconJson]="commonIcons"></app-dashboard-icon>{{'groupView.removeFromGroup' | translate}}</a>
                                 </div>
                              </div>
                           </div> 
                        </span>
                     </span>
                  </div>
            </div>
         </div>
      </div>
      <div *ngIf="currentView=='AddMembers'" class="w-100">
               <span class="w-100 " [ngClass]="{'mx-3':!openInpopover}">
                  <div *ngIf="openInpopover" class="subHeader d-flex">
                     <img (click)="currentView='GroupMember'" class="back-arrow mr-3" src="../../../assets/1x/newdash-arrow.svg " width="18px" height="18px">
                     <div class="f-m-b">
                         <span>{{'groupView.addMember' | translate}}</span>
                     </div>
                  </div>
                  <input [ngClass]="{'my-1':!openInpopover}" type="text" placeholder="{{'placeholders.searchUser' | translate}}" class="form-control f-m-r" #searchText (input)="searchUsers(searchText.value)">
               </span>
               <div class="user-list-container userContainer">
                  <ng-container *ngFor="let user of nonGroupMembersList">
                     <div class="teamlst">
                        <div class="userDetailsBox">
                           <span class="pr-1 avatarProfile">
                              <ngx-avatars class="mr-1" [name]="user.name" [src]="user.image_url" size="30" initialsSize="2" ></ngx-avatars>
                           </span>
                           <!-- <span *ngIf="!user?.image" class="nameInitial text-light" [style]="{'background-color': user?.nameInitialBgColor}">{{user?.nameInitial}}</span> -->
                           <span class="UserNameText f-m-b">{{user.name}}<br>
                              <span class="emailIdOrTotalMembers f-xs-l">{{user.email}}</span>
                           </span>
                        </div>
                        <button style="width:10;"class="float-right mx-2 mt-2 border-0 addMemberBtn" (click)="addOrUpdateUserInGroup(user,true)" [disabled]="disableAddMemberButtonUntilRes || user.newAdded">
                           <i *ngIf="!user.isAdding && !user.newAdded"><app-dashboard-icon class="iconBox-20" [size]="20" [name]="'Add'" [iconJson]="commonIcons"></app-dashboard-icon></i>
                           <i *ngIf="user.newAdded" style="color:#5EC394;" class="far fa-check-circle p-0"></i>
                           <div *ngIf="user.isAdding" class="spinner-border" role="status" style="width: 12px;height: 12px;"><span class="sr-only">Loading...</span></div> 
                        </button>
                     </div>
                  </ng-container>
                  <div *ngIf="nonGroupMembersList.length <= 0" class="text-center">
                        <div class="d-flex align-items-center justify-content-center mt-1" >
                        <div class="">
                           <img src="../../../assets/img/icons/empty.svg" />
                           <div class="nonotes text-center f-m-b">{{'groupView.noUsersFound' | translate}}</div>
                        </div>
                     </div>
                  </div>
               </div>
      </div>
      <div *ngIf="currentView=='UserRemovingConfirmation'" class="row p-2" >
         <div class="subHeader d-flex px-3">
               <img (click)="currentView='GroupMember'" class="back-arrow mr-3" src="../../../assets/1x/newdash-arrow.svg " width="18px" height="18px">
               <div class="f-m-b">
                   <span>{{'groupView.removeFromGroup' | translate}}</span>
               </div>
         </div>
         <div class="optionsField user-list-container f-s-r" style="height: auto;">
             <div class="py-0">
               <div class="m-1 pb-2" *ngIf="removingUser._id == loginUserId">
                 Are you sure want to leave  <span class="f-m-b">{{selectedGroup?.group_name | titlecase}}</span>?<br>
               </div>
               <div class="d-flex flex-row align-items-baseline align-content-stretch">
                 <input type="radio" id="collaborator" class="mr-1" name="remove-user-group" value="removeCollaborator"  (change)="manageSelectOption(true,false)"> 
                 <label for="collaborator"> {{'board.removeFromAllItem' | translate}} </label>  
              </div>
              <div class="d-flex flex-row align-items-baseline align-content-stretch">
                 <input type="radio" id="collaboratorAndUser"  class="mr-1" name="remove-user-group" value="removeCollaboratorAndAssignee" (change)="manageSelectOption(false,true)">
                 <label for="collaboratorAndUser"> {{'board.removeFromAllAssignee' | translate}} </label> 
              </div>
              <div class="d-flex flex-row align-items-baseline align-content-stretch">
                 <input type="radio" id="boardOnly"  class="mr-1" name="remove-user-group" checked value="removeUserFromBoardOnly" (change)="manageSelectOption(false,false)">
                 <label for="boardOnly"> {{'board.removeOnlyFromGroup' | translate}} </label>  
              </div>
             </div>
             <div class="pl-2 text-danger f-xs-l">
                <span *ngIf="removingUser._id !== loginUserId"> * {{'board.userIsBothMemberAndGroup' | translate}} </span>
             </div>
             <div class="submit-section mx-2 my-2 d-flex justify-content-end">
               <button type="submit" class="btn  mr-2 cancelBtn f-l-r" (click)="currentView='GroupMember'" > {{'common.cancel' | translate}}</button>
               <button type="submit" class="btn  submitBtn f-l-r" (click)="deleteMemberFromGroup(this.removeCollaborator,this.removeCollaboratorAndAssignee);currentView='GroupMember'"> {{'common.confirm' | translate}}</button>
             </div>   
         </div>
      </div>
   </div>   
</div>
   