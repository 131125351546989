import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(item: any, searchText1?: any): any {
    if (searchText1 === undefined) return item;

    return item.filter(function (item) {
      return item.name.toLowerCase().includes(searchText1.toLowerCase());
    });
  }
}
