<div class="internalChatviewBody" [ngClass]="{'InternalChatfullHeight': bottomBarCall}">
    <ng-container *ngIf="!chatArr?.length && showChat && !bottomBarCall">
        <div class="w-100 internalChat">
            <div class="w-50">Internal Chat. 🚀 Now, seamlessly communicate with your board members through dedicated chat groups. Each group automatically includes all board users, creating a collaborative space for discussions.
                To begin, simply click on button ‘new internal chat’ to initiate a chat group.</div>
           <div *ngIf="!enableInput"><button class="internalChatBtn f-xl-r" (click)="enableInput=true;">+ Internal Chat</button></div>
           <div *ngIf="enableInput" class="inputParent">
            <div class="inputbox"><input type="text" class="title" [(ngModel)]="title"/></div>
            <div class="renameBtnGrp">
            <div (click)="createInternalChat()"><span class="cursorPointer"><app-dashboard-icon [size]="22" [name]="'Tick'" [iconJson]="commonIcons"></app-dashboard-icon></span></div>
            <div (click)="enableInput=false;"><span class="cursorPointer"><app-dashboard-icon [size]="22" [name]="'Cancel'" [iconJson]="commonIcons"></app-dashboard-icon></span></div>
            </div>   
        </div>
        </div>
    </ng-container>
    
    
    <ng-container *ngIf="chatArr?.length && showChat">
        <div class="w-100 internalChatPanel">
            <div *ngIf="!bottomBarCall" class="chatLeftSideView">
                <div class="createBtn" *ngIf="!enableInput"><button class="w-100 internalChatBtn f-xl-r" (click)="renameEnable=false;title='';enableInput=true;">+ Internal Chat</button></div>
                <div *ngIf="enableInput" class="inputParent createBtn" style="width: 100%;height: 40px;justify-content: center;">
                    <div class="inputbox"><input type="text" class="title" [(ngModel)]="title"/></div>
                    <div class="renameBtnGrp">
                        <div (click)="createInternalChat()"><span class="cursorPointer"><app-dashboard-icon [size]="22" [name]="'Tick'" [iconJson]="commonIcons"></app-dashboard-icon></span></div>
                        <div (click)="enableInput=false;"><span class="cursorPointer"><app-dashboard-icon [size]="22" [name]="'Cancel'" [iconJson]="commonIcons"></app-dashboard-icon></span></div>    
                    </div>
                   </div>
                <div class="chatPanel">
                    <ng-container *ngFor="let chat of chatArr">
                    <div class="chatBox cursorPointer" [ngClass]="{'selectedChat' : selectedChatObj?._id == chat?._id}" (click)="renameEnable=false;getSelectedChat(chat)">
                        <div class="chatLeftdiv">
                            <div class="flex"><ngx-avatars class="mr-1" [name]="chat?.title" [src]="chat?.title" size="40" initialsSize="2" ></ngx-avatars></div>
                            <div class="flex f-xl-r fontColor">{{chat?.title}}</div> 
                        </div> 
                        <div class="chatRightdiv" *ngIf="selectedChatObj?._id !== chat?._id && chat?.unreadCount>0"><div class="countIcon"><span class="countMsg">{{chat?.unreadCount}}</span></div></div>
                        </div>
                    </ng-container>
                </div>
            </div>
    
            
            <div class="chatRightSideView" *ngIf="showChat" [ngClass]="{'fullWidth': bottomBarCall}">
                <div class="chatViewRightHeader">
                       <div class="headerAvatar" *ngIf="selectedChatObj">
                        <div class="flex"><ngx-avatars class="mr-1" [name]="selectedChatObj?.title" [src]="chat?.title" size="40" initialsSize="2" ></ngx-avatars></div>
                        <div *ngIf="!renameEnable" class="flex f-xl-r mt-2 fontColor">{{selectedChatObj?.title}}</div>
                        <div *ngIf="renameEnable" class="inputParent createBtn" style="width: 100%;height: 40px;justify-content: center;display: flex;align-items: center;">
                            <div class="inputbox"><input type="text" class="title" [(ngModel)]="title"/></div>
                            <div class="renameBtnGrp">
                            <div (click)="createInternalChat('update')"><span class="cursorPointer"><app-dashboard-icon [size]="22" [name]="'Tick'" [iconJson]="commonIcons"></app-dashboard-icon></span></div>
                            <div (click)="renameEnable=false;"><span class="cursorPointer"><app-dashboard-icon [size]="22" [name]="'Cancel'" [iconJson]="commonIcons"></app-dashboard-icon></span></div>
                            </div>  
                        </div>
                       </div>
                       <div class="flex" *ngIf="selectedChatObj">
                        <!--   <div class="flex">
                            <ng-container *ngFor="let login of selectedChatObj?.selectedUser;let in = index">
                              <ngx-avatars class="mr-1" [name]="login.name" [src]="login.image_url" size="15" initialsSize="2" ></ngx-avatars>
                            </ng-container>
                          </div> -->
                        <span class="infoIcon" [ngbPopover]="userPopUp" #userPopup="ngbPopover" placement="auto" container="body" [autoClose]="'outside'" (click)="searchActive=false;checkForsharedUserList()">
                            <app-dashboard-icon [size]="22" [name]="'User'" [iconJson]="commonIcons"></app-dashboard-icon>
                            <!-- <span *ngIf="selectedChatObj.channel_type !== 'SHARED'">{{selectedChatObj?.lead_colloborators_users?.length}}</span>
                            <span class="sharedUsers" *ngIf="selectedChatObj.channel_type === 'SHARED'">Board users</span> -->

                          </span>
                          <span (click)="minimizeChat()">
                          <img style="width: 20px;" src="../../../assets/1x/minimize.png">
                          </span>
                        <span class="infoIcon" [ngbPopover]="info" #popInfo="ngbPopover" container="body" [autoClose]="'outside'">
                            <img src="../../../assets/1x/vertdots.svg">
                        </span>
                        <span class="infoIcon" (click)="removefromMinimize()">
                          <img style="width: 20px;" src="../../../assets/common-use-icon-20dp/close.svg">
                      </span>
                       </div>
                </div>
                <div class="chatMsgBody" *ngIf="selectedChatObj">
                <app-chat-messages [internalChat]="true" [dashId]="selectedDashId" [disableEditor]="false" [selectedChatObj]="selectedChatObj" [selectedChatUserMsgarr]="selectedChatUserMsgarr" [selectedLeadObj]="selectedChatObj" [currentActiveTab]="'commentTab'"></app-chat-messages>
                </div>
            </div>  
        </div>     
    </ng-container>
    
</div>


<ng-template #info>
  <div class="infoBody">
    <div class="cursorPointer" (click)="enableInput=false;title=selectedChatObj?.title;renameEnable=true;">
        <i class="fa fa-pencil fa-lg deleteicon cursorPointer" aria-hidden="true"></i><span class="ml-2">Rename</span> 
      </div>
      <div class="cursorPointer" (click)="openWarningModal(deleteModal)">
        <i class="fa fa-trash-o fa-lg deleteicon cursorPointer" aria-hidden="true"></i><span class="ml-2">{{'profile.emailSetting.delete' | translate}}</span>
      </div>

  </div>
</ng-template>


<!--Warning Modal-->
<ng-template #deleteModal let-modal>
    <div class="modal-sm">
      <div class="modal-header del">
        <div class="head">
          <h4 class="modal-title">{{'common.areYouSure' | translate}}</h4>
        </div>
      </div>
      <div class="modal-body">
        <img src="../../../assets/img/icons/delete.svg" />
        <br />
        <label>You Want to delete {{selectedChatObj?.title | truncate:20}}</label>
      </div>
      <div class="modal-footer">
        <button class="btn prevbtn" (click)="modal.dismiss('Cross click')">{{'common.discard' | translate}}</button>
        <button class="btn prevbtn" (click)="deleteRule(); modal.close('Save click')">{{'common.confirm' | translate}}</button>
      </div>
    </div>
  </ng-template>

 
  <!-- <ng-template #userPopUp>
     <div class="userParent">
        <ng-container *ngIf="!searchActive">
            <div class="row searchUserBox f-m-r">
                <div *ngIf="selectedChatObj?.channel_type !== 'SHARED'" class="searchIconBox">
                  <div class="searchButtonIco">
                    <i class="fa fa-search" (click)="searchActive=true;"></i>
                  </div>
                </div>
                <div class="selectBoardUser">
                  <input type="checkbox" [checked]="selectedChatObj.channel_type === 'SHARED' ? true : false" (change)="toggleShared($event)"/>
                  <label style="margin: 0;"> {{'noteView.allDashboardUsers' | translate}}</label>
                </div>
            </div>
           <div style="height:calc(236px - 40px);overflow-y: auto;overflow-x: hidden;">
            <ng-container *ngFor="let login of selectedChatObj?.selectedUser;let i = index">
              <div class="userDetailBox">
                  <div class="userNameBox">
                    <ngx-avatars class="mr-1" [name]="login.name" [src]="login.image_url" size="30" initialsSize="2" ></ngx-avatars>
                    <div class="" style="color: #2E4765;">
                      <label class="loginLabel m-0 f-m-b"> {{login.name}}</label>
                      <p class="loginp m-0 f-xs-l">{{login.email}}</p>
                    </div>
                  </div>
                  <div>
                    <label class="exit cursorPointer" *ngIf="loginId == login?._id" (click)="open(privateNote,login)">{{'noteView.exit' | translate}}</label>
                    <label class="exit cursorPointer" *ngIf="loginId != login?._id" (click)="removeSharedUserOfNote(login,i)"><i class="fa fa-close"></i></label>
                  </div>
              </div>   
              </ng-container> 
           </div> 
        </ng-container>
          <ng-container *ngIf="searchActive">
            <div class="user-content">
                <div class="row searchUserBox" style="background:  #DDD 0% 0% no-repeat padding-box;">
                  <div class="backIcon px-2" style="width:10%;" role="button">
                    <img src="../../../assets/img/icons/backArrow.svg" (click)="searchActive=false;"/>
                  </div>
                  <div class="userSearchInput px-2" style="width:90%; color: #000">
                    <input type="text" class="searchuser" style="background: transparent;border: none;" placeholder="{{'placeholders.searchUser' | translate}}" #inputBox (input)="filterUser(inputBox.value)"/>
                  </div>
                </div>
                <div class="userDetailBox" *ngFor="let user of selectedChatObj?.searchUserList"
                  (click)="$event.stopPropagation();selectedUserForshared(user)">
                  <div class="userNameBox">
                    <div class="" >
                      <ngx-avatars class="mr-1" [name]="user.name" [src]="user.image_url" size="30" initialsSize="2" ></ngx-avatars>
                    </div>
                    <div class="" style="color: #151616;">
                      <label class="username m-0"> {{user.name}}</label>
                      <p class="useremail m-0">{{user.email}}</p>
                    </div>
                  </div>
                </div>
              </div>

          </ng-container>
     </div>
  </ng-template>  -->

  <!--Users Connections-->
  <ng-template #userPopUp>
    <div class="notesCollaboratedPopover">
      <app-user-selected
        [noteUsersCall]="true"
        (selectedUserList)="selectedUserList($event)"
        [userList]="collaboratorArr"
        >
      </app-user-selected>
      <div class="select" *ngIf="collaboratorArr.length">
        <div class="row g-0 mx-0" style="width: 100%;" *ngFor="let item of collaboratorArr">
          <div class="column1">
            <ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="30" initialsSize="2" ></ngx-avatars>
            <div class="udls">
              <span class="uname">{{item.name}}</span>
              <span class="uemail">{{item.email}}</span>
            </div>
                </div>
         <div class="column2" *ngIf="!isReadOnly && leadObj?.assigned_to != item._id">
           <img src="../../../assets/1x/cross-icon.svg" role="button" (click)="removeFromSelectedList(item)">
         </div>
        </div>
      </div>
    </div>
  </ng-template>


  <ng-template #privateNote let-modal>
    <div class="modal-sm">
      <div class="modal-header del">
        <div class="head">
          <h4 class="modal-title">{{'common.areYouSure' | translate}}</h4>
        </div>
  <!--       <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
   -->    </div>
      <div class="modal-body">
        <img src="../../../assets/img/icons/warning.svg" />
        <br />
        <div>You Want to Exit this {{this.selectedChatObj?.title}} chat</div>
      </div>
      <div class="modal-footer">
        <button class="btn prevbtn" (click)="modal.dismiss('Cross click')">{{'common.discard' | translate}}</button>
        <button class="btn prevbtn" (click)="exitUser(); modal.close('Save click')">{{'common.confirm' | translate}}</button>
      </div>
    </div>
  </ng-template>

  
