import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component,HostListener, Input, OnInit, Inject, ViewChild, QueryList, ElementRef, ViewChildren, ComponentFactoryResolver, ViewContainerRef, ComponentRef, Compiler, Injector, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChange } from '@angular/core';


@Component({
  selector: 'grid-table',
  templateUrl: './grid-table.component.html',
  styleUrls: ['./grid-table.component.scss']
})

export class GridTableComponent implements OnInit {

  @Input() columnData=[]
  @Input() tableGroupData=[]
  @Input() globalParams={}
  @Input() columnConfig={}
  @Input() gantConfig:any={}
  @Input() contextMenuConfig:any={
    enableContextMenu:false,
    contextMenuItems:[],
    contextMenuComponent:null
  }
  @Input() gridSettingConfig:any={
    keyboardAccess:false,
    allowPast:false,
    allowCopy:false,
    enableTabKeyAccess:false,
    commonHeader:false,
    ganttChart:false
    
  }
  @Input() subGridConfig:any={}
  @Input() hierarchyLevel:number=0
  @Output() onColumnResize: EventEmitter<any> = new EventEmitter();
  @Output() onColumnMove: EventEmitter<any> = new EventEmitter();
  @Output() onRowDrop: EventEmitter<any> = new EventEmitter();
  @Output() onCellValueChanged: EventEmitter<any> = new EventEmitter();
  @Output() onScrolling: EventEmitter<any> = new EventEmitter();
  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();
  @Output() onGridTableReady: EventEmitter<any> = new EventEmitter();
  @Output() onBarDrop: EventEmitter<any> = new EventEmitter();
  @Output() onGantViewportChange: EventEmitter<any> = new EventEmitter();
  @Output() onDependencyChanged: EventEmitter<any> = new EventEmitter();
  @ViewChildren("viewContainerRef",{ read: ViewContainerRef }) viewContainerRefs!: QueryList<ViewContainerRef>;
  @ViewChild("contextMenuRef",{ read: ViewContainerRef }) contextMenuRef;
  @ViewChild('viewport', { static: false }) viewport: ElementRef;
  @ViewChildren('tableRow') tableRow: QueryList<ElementRef>;
  @ViewChild('gantContainer', { static: false }) gantContainer: ElementRef;
  
  // @ViewChild('arrowElementEl') arrowElementEl!: ElementRef<SVGElement>;
  @Input() groupSelection = true;
  @Input() singleSelection = null;
  initialData={
    resizing:false,
    initialX: 0
  };
  cellComponentInstance={}
  contextMenuPopoverRef:any;
  editRowColumnData:any={}
  draggingInfo:any={
    'columnDragging':false,
    'rowDragging':false
  }
  defaultColumnConfig:any={
    width:250,
    minWidth:60,
    maxWidth:null,
    maxPinnedCount:3,
    enabledPinned:true
  }
  emptyRowIndex:number=0
  newRowDefaultColumnField:any='title'
  @Input() pinnedData:any={
    pinnedTypes:[{pinned:true,name:'pinned'},{pinned:false,name:'unpinned'}],
    categoriesColumnData:{true:[],false:[]}
  }
  previousRowTable:any={}
  renderingData:any={}
  scrollTableInfo:any={}
  enabledRowDrag:boolean=false
  createRowData:any={}
  emptyRowConfig:any={
    editableColumnKeys:['id','title'],
    focusOnColumnKey:'title'
  }
  loaderArr=[{showLoader:true},{showLoader:true},{showLoader:true},{showLoader:true}]
  tableType='MainTable'
  sectionCell:any={
    rowIndex:-2,
    columnIndex:-1,
    tableIndex:0
  }

  // gant variables

  defaultGanttConfig:any={
    tableCollapse:false,
    headerSubCellWidth:40,
    currentChartType:'Months',
    enableDarkMode:false,
    gantChartViewTypes:['Years','Quarters','Months','Weeks','Days'],
    tableViewWidth:400,
    defaultMaxTableViewWidth:400
  }
  gantHeader:any=[]
  barDragData: any={};
  arrowElement: any={};


  constructor(
    private cdr:ChangeDetectorRef,
  ){}

  ngOnInit() {
    this.initializeGrid()
    if(this.gridSettingConfig?.keyboardAccess){
      this.enableDisableKeyboardAccess()
    }
    if(this.gridSettingConfig?.ganttChart)this.createGantHeader(this.gantConfig.currentChartType)
  }
  ngOnChanges(changes:SimpleChange){
    if(changes){
      if(changes['gantConfig']){
        for(const key in this.defaultGanttConfig){
          if(!this.gantConfig?.hasOwnProperty(key))this.gantConfig[key]=this.defaultGanttConfig[key]
        }
      }
      else if(changes['columnConfig']){
        for(const key in this.defaultColumnConfig) {
          if(!this.columnConfig?.hasOwnProperty(key))this.columnConfig[key]=this.defaultColumnConfig[key]
        }
      }
    }
  }

  ngAfterViewInit() {
    this.handleRowDataVisibility()
    this.onGridTableReady.emit(this)
  }

  initializeGrid(){
    // reset params
    this.emptyRowIndex=0
    this.renderingData={}
    this.enabledRowDrag=false
    if(!this.hierarchyLevel){
      this.pinnedData.categoriesColumnData={true:[],false:[]}
      this.onUpdateColumnData()
    }
    
    for(const key in this.defaultColumnConfig) {
      if(!this.columnConfig?.hasOwnProperty(key))this.columnConfig[key]=this.defaultColumnConfig[key]
    }
    for(const key in this.defaultGanttConfig){
      if(!this.gantConfig?.hasOwnProperty(key))this.gantConfig[key]=this.defaultGanttConfig[key]
    }
    
    this.handleRowDataVisibility()
  }

  focusOnCreateRow(table,blur=false){
    try{
      let elId='createrow_'+table.tableId+'_'+table?.createRowConfig?.columnKey
      let element=this.viewport?.nativeElement?.querySelector('#'+elId)
      if(blur)element?.blur()
      setTimeout(() => {
        element=element ? element : this.viewport?.nativeElement?.querySelector('#'+elId)
        element?.focus()
      },1);
    }catch(e){
      console.log(e)
    }
    
  }

  footercreateDynamicComponent() {
    let componentJson=this.columnData.reduce((json,column)=>{if(column.footerCellRenderer){json[column?.field]=column}return json},{})// create json of column with key of columnId and data of column details (eg. { columnKey : columnDetails}) 
    let rowDataJson=this.tableGroupData.reduce((json,table)=>{(table?.bottomRowData || []).forEach(item=>json[item.rowId]=item);return json},{})
    setTimeout(() => {
      this.viewContainerRefs.forEach(viewContainer=>{
        const nativeElement= viewContainer.element.nativeElement;
        if(nativeElement && nativeElement['col-field'] && nativeElement['row-id'] && componentJson[nativeElement['col-field']] && rowDataJson[nativeElement['row-id']] && !viewContainer?.get(0)){
          const componentRef=viewContainer.createComponent(componentJson[nativeElement['col-field']]?.footerCellRenderer)
          if(!this.cellComponentInstance[nativeElement['row-id']])this.cellComponentInstance[nativeElement['row-id']]={}
          componentRef.instance["params"]={'value' : rowDataJson[nativeElement['row-id']][nativeElement['col-field']],'item':rowDataJson[nativeElement['row-id']],'field':nativeElement['col-field'],'columnData':componentJson[nativeElement['col-field']], 'editable':(componentJson[nativeElement['col-field']]?.editable || false),gridTableRef:this,globalParams:this.globalParams,columnParams:(componentJson[nativeElement['col-field']]?.columnParams || {})}
          this.cellComponentInstance[nativeElement['row-id']][nativeElement['col-field']]=componentRef.instance;
        }
      })
      this.cdr.detectChanges()
    },0);
  }

  checkToGroup(){
    return this.tableGroupData?.findIndex(item=>item?.requireScrolling && !item?.isCollapsed)

  }
  handleRowDataVisibility(delayTime=0){ 
    this.cdr.detectChanges()
    this.createDynamicComponent();
    this.footercreateDynamicComponent()
  }

  isRowDataVisibile(element,rowId){
    if(this.viewport){
      const containerRect = this.viewport?.nativeElement?.getBoundingClientRect();;
      let extraArea=900;
      const rowRect = element?.getBoundingClientRect();
      if (rowRect.top+extraArea >= containerRect.top && rowRect.bottom-extraArea <= containerRect.bottom) {
        return true
      } else {
        return false
      }
    }
    return false
  }


  onScroll(event?){
    this.handleRowDataVisibility()
    if(event){
      const element = event?.target;
      const scrollPosition = element.scrollTop;
      const maxScrollPosition = element.scrollHeight - element.clientHeight;
      if (scrollPosition+150 >= maxScrollPosition) {
        let index=this.tableGroupData?.findIndex(item=>item?.requireScrolling && !item.isCollapsed)
        if(index>-1 && !this.tableGroupData[index]?.isCollapsed){
          if(this.scrollTableInfo?.tableId!==this.tableGroupData[index]?.tableId || this.scrollTableInfo?.tableId===this.tableGroupData[index]?.tableId && this.scrollTableInfo?.rowDataCount!=this.tableGroupData[index]?.rowData?.length){
            this.scrollTableInfo={rowDataCount:(this.tableGroupData[index]?.rowData?.length || 0),tableId:this.tableGroupData[index]?.tableId}
            this.onScrolling.emit(this.tableGroupData[index]) 
          }
        }
      }
    }
  }

  createDynamicComponent() {
    let componentJson=this.columnData.reduce((json,column)=>{if(column.cellRenderer){json[column?.field]=column}return json},{})// create json of column with key of columnId and data of column details (eg. { columnKey : columnDetails}) 
    let rowDataJson=this.tableGroupData.reduce((json,table)=>{table?.rowData?.forEach(item=>json[item.rowId]=item);return json},{}) // create json of row data with key of rowId and data of row details  (eg. { rowId : rowDetails}) 
    setTimeout(() => {
      this.viewContainerRefs.forEach(viewContainer=>{
        try{
          const nativeElement= viewContainer.element.nativeElement;
          if(nativeElement && nativeElement['col-field'] && nativeElement['row-id'] && componentJson[nativeElement['col-field']] && rowDataJson[nativeElement['row-id']] && !viewContainer?.get(0)){
            const componentRef=viewContainer.createComponent(componentJson[nativeElement['col-field']]?.cellRenderer)
            if(!this.cellComponentInstance[nativeElement['row-id']])this.cellComponentInstance[nativeElement['row-id']]={}
            let onlyEnableFields= componentJson[nativeElement['col-field']]?.onlyEnabledInRow
            let onlyEditableFields= componentJson[nativeElement['col-field']]?.onlyEditableInRow
            let editable =componentJson[nativeElement['col-field']]?.editable && rowDataJson[nativeElement['row-id']].editable!=false && (!onlyEnableFields || onlyEnableFields && onlyEnableFields?.matchKeyValue?.includes(rowDataJson[nativeElement['row-id']][onlyEnableFields?.matchKey])) && (!onlyEditableFields || onlyEditableFields && onlyEditableFields?.matchKeyValue?.includes(rowDataJson[nativeElement['row-id']][onlyEditableFields?.matchKey])) || false
            componentRef.instance["params"]={'value' : rowDataJson[nativeElement['row-id']][nativeElement['col-field']],'item':rowDataJson[nativeElement['row-id']],'field':nativeElement['col-field'],'columnData':componentJson[nativeElement['col-field']], 'editable':editable,gridTableRef:this,globalParams:this.globalParams,columnParams:(componentJson[nativeElement['col-field']]?.columnParams || {})}
            this.cellComponentInstance[nativeElement['row-id']][nativeElement['col-field']]=componentRef.instance;
            this.cellComponentInstance[nativeElement['row-id']][nativeElement['col-field']].viewContainer=viewContainer
          }
        }
        catch(e){
          console.log(e)
        }
      })
      this.cdr.detectChanges()
    },0);
  }
  refreshGridTableColumnHtml(refreshColumnKeys=['*'],refreshCount=1,eachRefreshDelayTime=0){
    try{
      for (let rowId in this.cellComponentInstance){
        for(let columnField in this.cellComponentInstance[rowId]){
          if(refreshColumnKeys.includes(columnField) || refreshColumnKeys.includes('*')){
            for(let i=0;i<refreshCount;i++){
              setTimeout(() => {
                this.cellComponentInstance[rowId][columnField]?.cdr?.detectChanges()
              },eachRefreshDelayTime);
            }
          }
        }
      } 
    }
    catch(e){
      console.log(e)
    }
  }
  changedCellValue(value={}){
    let item=value['params']?.item
    item[value['field']]=value['value'] && value['value']?.hasOwnProperty(value['field'])  ? value['value'][value['field']] : value['value'];
    if(!item?.emptyRow){
        this.onCellValueChanged.emit(value)
    }else if(value['value'] && value['value'][value['field']]){
      if(item?.emptyRow){
        if(item?.isProcessing){
          return
        }
        item['isProcessing']=true
      }
      let indexOfNearItem=-1
      let table=value['currentTable'] || this.tableGroupData?.find(data=>data.rowData && data?.rowData.some(itemInfo=>itemInfo.rowId==item.rowId))
      if(table && table?.rowData){
        for(let row of table?.rowData){
          if(!row.emptyRow)indexOfNearItem++;
          if(row.rowId==item.rowId)break;
        }

      }
      let rowIndex=indexOfNearItem +1
      rowIndex=rowIndex>=0 ? rowIndex : 0
      let data={value:value['value'],field:value['params']?.field,params:value['params'],currentTableId:table?.tableId,rowIndex:rowIndex,createRow:value['createRow'],'table':table,columnInfo:value['columnInfo']}
      this.onCellValueChanged.emit(data)
    }
  } 

  addEmptyRow(item,table,addDirection='below',enableInput=true,defaultData={}){
    this.emptyRowIndex++;
    let indexOfSelectedItem=table?.rowData?.findIndex(rowItem=>rowItem?.rowId==item?.rowId)
    if(!Object.keys(defaultData)?.length)defaultData=table?.defaultEmptyRowData || {}
    if(indexOfSelectedItem>-1 || (addDirection=='below' && indexOfSelectedItem==-1)){
      let rowId=item?.rowId+"#"+this.emptyRowIndex;
      table.rowData.splice(indexOfSelectedItem+(addDirection=='below'?1:0),0, {...defaultData,'rowId':rowId,'emptyRow':true,createRowInfo:{refRowId:item?.rowId,direction:addDirection}})
      this.renderingData[rowId]=true
      this.createDynamicComponent()
      if(enableInput)this.enabledDisabledRowInputBox(this.newRowDefaultColumnField,rowId,true)
    }

    
  }


  onUpdateCell(value,columnField,item,createRow=false){
    this.enabledDisabledRowInputBox(columnField,item?.rowId,false)
    this.changedCellValue({value:{[columnField]:value},field:columnField,params:{field:columnField,item:item},createRow:createRow})
    // if(index===table?.rowData?.length-1 && value?.length)this.addEmptyRow(item,table)
    this.updateTableCell([{'rowId':item?.rowId,item:{'rowId':item?.rowId},[columnField]:value}],[columnField],['value'])
  }

  onCreateRowEdit(table,columnKey){
    let key=table?.tableId+'_'+columnKey
    let value=this.createRowData[key] || ''
    this.createRowData={}
    if(value && value?.length>0){
      let itemData=table?.rowData?.length-1>-1 ? table?.rowData[table?.rowData?.length-1] : {}
      this.addEmptyRow(itemData,table,'below',false,{...(table.defaultEmptyRowData || {}),[columnKey]:value})
      this.focusOnCreateRow(table,true) 
      this.onUpdateCell(value,columnKey,table.rowData[table.rowData.length-1],true)
    }
    
  }

  getRowDataByIndex(index,tableId,incudeEmpty=false){
    let tableData=this.tableGroupData.find(table=>table.tableId==tableId)
    let filterNonEmptyRow=tableData?.rowData || [];
    if(!incudeEmpty)filterNonEmptyRow=filterNonEmptyRow?.filter(row=>!row?.emptyRow);
    return  index<filterNonEmptyRow?.length && index>-1 ? filterNonEmptyRow[index] : null
  }
  
  enabledDisabledRowInputBox(columnField,itemId,enable=false,event?){
    if(enable){
      const selection = event ? window.getSelection() : null;
      if (selection && selection?.toString()?.length > 0) {
        // Prevent the click event from triggering
        event.stopPropagation();
        event.preventDefault();
      }else{
        let columnFieldData=this?.columnData?.find(column=>column?.field==columnField)
        if(columnFieldData?.enableEditor && columnFieldData?.editable){
          this.editRowColumnData={rowId:itemId,columnField:columnField}
        }
        setTimeout(()=>{document.getElementById(columnField+'_'+itemId)?.focus()},20)
      }
    }else{
      this.editRowColumnData={}
    }
  }

  // start column drag drop code
  onColumnDrop(event: CdkDragDrop<string[]>,pinned) {
    this.draggingInfo={}
    if(event.previousIndex==event.currentIndex && event.previousContainer.data==event.container.data)return
    if(event.currentIndex>=0){
      let isChangePinned=event.previousContainer.data[event.previousIndex]['pinned']!=pinned
      if(!event.previousContainer.data[event.previousIndex]['pinned'] && pinned && (this.columnConfig['maxPinnedCount']<=event.container.data?.length || !this.columnConfig['enabledPinned']))return;
      let currentIndex=!event.previousContainer.data[event.previousIndex]['pinned'] && pinned ? event.container.data?.length : event.currentIndex
      event.previousContainer.data[event.previousIndex]['pinned']=pinned
      transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,currentIndex);
      let columnIndex=-1
      if(!isChangePinned || !pinned){
        let dummyColumnIndex=event?.container?.data?.length-1==event?.currentIndex ? this.columnData.length : this.columnData.findIndex(column=>column.columnKey==event.container.data[event.currentIndex+1]['columnKey'])
        let oldIndex=this.columnData?.findIndex(col=>col?.columnKey==event.container.data[event.currentIndex]['columnKey'])
        this.columnData.splice(oldIndex,1)
        columnIndex=dummyColumnIndex>oldIndex ? dummyColumnIndex-1 : dummyColumnIndex
        this.columnData.splice(columnIndex,0,event.container.data[event.currentIndex])
      }
      // let column=
      let json={'newIndex':columnIndex,'column':event.container.data[event.currentIndex],'changePinned':isChangePinned,currentSequence:[...this.pinnedData?.categoriesColumnData['true'],...this.pinnedData?.categoriesColumnData['false']]}
      this.onColumnMove.emit(json)
      this.createDynamicComponent()
    }
    
  }
  // store data in draggingInfo
  onDragStart(pinned){
    this.draggingInfo['columnDragging']=true;
    this.draggingInfo['pinned']=pinned
    let totalWidth=this.columnData.reduce((width,data)=>{if(data?.pinned){width=width+data?.width}return width},0)
    this.draggingInfo['pinnedDefaultwidth']=totalWidth || 0

  }
  // handled : show minimun pinned column msg or drop here msg 
  onDragEntered(event,pinned){
    let element=event?.container?.element?.nativeElement
    this.draggingInfo['enablePinnedListInfoBox']=false;
    this.draggingInfo['enableUnpinnedListInfoBox']=false;
    if(element?.className?.includes('table-unpinned-column-wrapper') && pinned){
      this.draggingInfo['enableUnpinnedListInfoBox']=true;
    }
    else if(element?.className?.includes('table-pinned-column-wrapper') && !pinned){
      this.draggingInfo['enablePinnedListInfoBox']=true;
    }
  
  }

  rowDragEnd(e){
  }

  // end column drag and drop here

  updateTableCell(items=[{'rowId':null,item:null}],columnKeys=[],valueKeys=['value','item']){
    let rowDataJson=this.tableGroupData.reduce((json,table)=>{table?.rowData?.forEach(item=>json[item.rowId]=item);table?.bottomRowData?.forEach(item=>json[item.rowId]=item);return json},{})
    items?.forEach(item=>{
      if(rowDataJson[item?.rowId]){
        Object.keys(item?.item || {}).forEach(key=>rowDataJson[item?.rowId][key]=item[key]) // update table row data
        if(this.cellComponentInstance[item?.rowId]){
          columnKeys.forEach(columnKey=>{
            if(this.cellComponentInstance[item?.rowId][columnKey]){
              if(!this.cellComponentInstance[item?.rowId][columnKey]['params'])this.cellComponentInstance[item?.rowId][columnKey]['params']={}
              valueKeys?.forEach(valueKey=>this.cellComponentInstance[item?.rowId][columnKey]['params'][valueKey]=item[valueKey=='value' ? columnKey : valueKey]) // update params value on bases of key
              this.cellComponentInstance[item?.rowId][columnKey]?.onUpdate(this.cellComponentInstance[item?.rowId][columnKey]['params'])
            }
          })
        }
      }
    })
    this.createDynamicComponent()
  }
  updateRowInfo(oldItemId,newItemValues){
    if(this.draggingInfo?.rowDragging){
      this.draggingInfo['updateRowInfo']={'oldItemId':oldItemId,'newItemValues':newItemValues}
    }else{
      let rowDataJson=this.tableGroupData.reduce((json,table)=>{table?.rowData?.forEach(item=>json[item.rowId]=item);table?.bottomRowData?.forEach(item=>json[item.rowId]=item);return json},{})
      if(rowDataJson[oldItemId])Object.keys(newItemValues).forEach(key=>rowDataJson[oldItemId][key]=newItemValues[key]) 
      this.createDynamicComponent()
    }
    this.cdr.detectChanges()
  }
  changeRowPosition(rowData,currentIndex,currentTableId,previousTableId){
    let tableJson=this.tableGroupData.reduce((json,tableData)=>{if(tableData?.tableId==previousTableId || tableData?.tableId==currentTableId){json[tableData?.tableId]=tableData}return json},{})
    if(Object.keys(tableJson).length>0){
       if(currentIndex<0){
        tableJson[previousTableId].rowData=tableJson[previousTableId]?.rowData?.filter(row=>row.rowId!=rowData.rowId) || []
        return
       }
       let previousIndexOfRow=tableJson[previousTableId]?.rowData.findIndex(row=>row.rowId==rowData?.rowId)
       let dummyCurrentInedx=currentIndex
       if(currentIndex>0){
        let currentTableNonEmptyRows=tableJson[currentTableId]?.rowData?.filter(row=>!row?.emptyRow && row?.rowId!=rowData.rowId)
        dummyCurrentInedx=tableJson[currentTableId]?.rowData?.findIndex(row=>row?.rowId==currentTableNonEmptyRows[currentIndex-1]?.rowId)
        dummyCurrentInedx= previousIndexOfRow>-1 && previousIndexOfRow < dummyCurrentInedx && previousTableId===currentTableId ? dummyCurrentInedx : dummyCurrentInedx+1
       }
       if(previousIndexOfRow>-1){
        let moveItem=tableJson[previousTableId]?.rowData[previousIndexOfRow]
        Object.keys(rowData).forEach(key=>{moveItem[key]=rowData[key]})
        if(!this.draggingInfo['rowDragging'] || this.draggingInfo['rowDragging'] && this.draggingInfo['draggingRowId']!==rowData.rowId){
          tableJson[previousTableId].rowData=tableJson[previousTableId]?.rowData?.filter(row=>row.rowId!=rowData.rowId)
          tableJson[currentTableId]?.rowData?.splice(dummyCurrentInedx,0,moveItem)
          this.updateTableCell([{'rowId':rowData?.rowId,item:rowData,...rowData}],Object.keys(rowData))
        }  
       }else{
          tableJson[currentTableId]?.rowData?.splice(dummyCurrentInedx,0,rowData)
       }
       this.handleRowDataVisibility(1); 
    }

  }
  removeRowFromTable(rowId){
    let tableData=this.tableGroupData.find(table=>table?.rowData && table?.rowData?.some(row=>row.rowId==rowId))
    if(tableData)tableData.rowData=tableData.rowData.filter(row=>row.rowId!=rowId)
  }

  collapsedOrExpandTable(tableIds,isCollapsed){
    this.tableGroupData?.forEach(data=>{if(tableIds.includes(data?.tableId))data.isCollapsed=isCollapsed})
    this.handleRowDataVisibility(1)
    if(tableIds.length==1)this.handleScrollerPosition(tableIds[0])
  }
  handleScrollerPosition(tableId){
    try{
      let element=this.viewport?.nativeElement?.querySelector('#table_'+tableId)
      if(element?.offsetTop)this.viewport.nativeElement.scrollTop=element?.offsetTop-50
    }catch(e){
      console.log(e)
    }
  }
  onUpdateColumnData(columnData=[]){
    let isRowDragHandle=false
    this.columnData.forEach(item=>{
      let pinned=item?.pinned || false
      if(this.pinnedData?.categoriesColumnData[pinned]){
        let index=this.pinnedData?.categoriesColumnData[pinned]?.findIndex(col=>col.columnKey==item?.columnKey)
        if(index>-1){
           if(item?.hide)this.pinnedData?.categoriesColumnData[pinned]?.splice(index,1)
           else this.pinnedData.categoriesColumnData[pinned][index]=item;
        }
        else if(!item?.hide && index==-1){
          this.pinnedData.categoriesColumnData[pinned]?.push(item)
        }
      }
      if(item?.rowDragHandle)isRowDragHandle=true
    })
    this.enabledRowDrag=isRowDragHandle;
    this.createDynamicComponent()
  }


  // drop row handled here
  onItemRowDrop(event: CdkDragDrop<string[]>,droptable?) {
    if(this.draggingInfo['updateRowInfo']){
      this.draggingInfo.rowDragging=false
      this.updateRowInfo(this.draggingInfo['updateRowInfo']?.oldItemId,this.draggingInfo['updateRowInfo']?.newItemValues)
    }
    this.draggingInfo={}
    if(event?.container['group']?.tableId === event?.previousContainer['group']?.tableId && event.previousIndex == event.currentIndex || event.currentIndex<0) { return}
    let rowIdsData=droptable.rowData.reduce((rowIdList,row)=>{if(!row['emptyRow']){rowIdList.push(row.rowId)}return rowIdList},[])
    transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex,);
    let dropRow=droptable.rowData[event.currentIndex] || {}
    let filterNotEmptyRows=droptable.rowData.filter(item=>!item['emptyRow'])
    let actualIndex=filterNotEmptyRows.findIndex(item=>item['rowId']==dropRow['rowId'])
    if(droptable?.tableId===this.previousRowTable.tableId && rowIdsData.indexOf(dropRow['rowId'])==actualIndex){
      return
    }
    let json={
      currentTableId:droptable?.tableId,
      currentTableRowData:filterNotEmptyRows,
      previousTableId:this.previousRowTable?.tableId,
      previousTableRowData:this.previousRowTable?.rowData?.filter(item=>!item['emptyRow']),
      currentIndex:actualIndex,
      dropedRow:dropRow,
      previousRowTableDataWithEmptyRows:this.previousRowTable?.rowData,
      currentRowTableDataWithEmptyRows:droptable.rowData,
      dropIndex:event.currentIndex, // actula current index in table with empty rows
      dragIndex:event.previousIndex // actulal previous index in table with empty rows
    }
    this.onRowDrop.emit(json)
    this.handleRowDataVisibility();
  
  }
  onDropStart(id){
    let pare=this.viewport?.nativeElement?.parentNode?.offsetWidth
    this.draggingInfo['width']=pare
    this.draggingInfo['id']=id
    console.log(pare.offsetWidth)
  }

  // revert drop on error
  reverItemDrop(dragData){
    if(dragData){
      transferArrayItem(dragData?.currentRowTableDataWithEmptyRows,dragData?.previousRowTableDataWithEmptyRows,dragData.dropIndex,dragData.dragIndex);
    }
    
  }
  // start context menu (right click popover)
  implementContextMenu(table,itemInfo?,columnInfo?,tableIndex?){
    if(this.contextMenuConfig?.enableContextMenu && this.contextMenuConfig?.contextMenuComponent && this.contextMenuRef){
        this.contextMenuRef?.clear()
        let contextMenu=this.contextMenuRef.createComponent(this.contextMenuConfig?.contextMenuComponent)
        contextMenu.instance['params']={ gridTableRef:this, table:table, item : itemInfo,column : columnInfo,'editable':(columnInfo?.editable || false),globalParams:this.globalParams,contextMenuParams:this.contextMenuConfig?.contextMenuParams || {},tableIndex:tableIndex}
        this.cdr.detectChanges()
    }
  }

  openContextMenuPopover(popup,event,table?,item?,column?,tableIndex?){
    event.preventDefault()
    event.stopPropagation()
    this.contextMenuPopoverRef?.hide()
    if(item?.emptyRow){return}
    let yaxixs = event.pageY;
    let xaxixs = event.pageX;
    let mousePosFrmBottom = $(window).height() - event.pageY;
    let mousePosFrmRight = $(window).width() - event.pageX;
    this.contextMenuPopoverRef?.hide()
    this.contextMenuPopoverRef=popup;
    popup?.show()
    setTimeout(() => {
      let contextMenu=$(".contextmenuPopup")
      this.implementContextMenu(table,item,column,tableIndex)
      let changedTop = contextMenu.height() - mousePosFrmBottom;
      let changeRight=contextMenu.width()-mousePosFrmRight;
      let newTop=yaxixs;
      let newRight=xaxixs;
      if (changedTop>0)newTop= yaxixs-changedTop-100;
      if(changeRight>0)newRight=xaxixs-changeRight-50;
      contextMenu.css({ left: newRight, top: newTop, visibility: 'visible'})
    },1); 
   
  }
  closeContextMenuPopover(){
    this.contextMenuPopoverRef?.hide()
  }
  // end : context menu code

  // start : resize of column 
  startResize(event: MouseEvent,column): void {
    this.initialData.resizing=true
    this.initialData['initialX'] = event.clientX;
    if(column=='ganttViewport'){
      this.initialData['element']=this.viewport?.nativeElement?.querySelector('.table-wrapper')
    }else{
      this.initialData['column']=column
    }

  }
  changeBarPosition(event,item){
    if(this.gantConfig?.editable && !item.startDate.time && !item.endDate.timeng){
      if(!this.gantConfig?.moveBar?.freezBar && item?.rowId==this.gantConfig?.moveBar?.rowId || item.rowId!==this.gantConfig?.moveBar?.rowId){
        const containerRect = this.gantContainer.nativeElement.getBoundingClientRect();
        const mouseX = event.clientX - containerRect.left;
        const scrollLeft = this.gantContainer.nativeElement.scrollLeft;
        this.gantConfig.moveBar={
          rowId:item.rowId,
          mouseEvent: mouseX + scrollLeft-20
        }
      }
    }else{
      delete this.gantConfig.moveBar
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onResize(event: MouseEvent): void {
    if(this.initialData?.resizing){
      event?.stopPropagation();
      document.body.style.userSelect = 'none'; // Prevent text selection during resizing
      if(this.initialData['column']){
        const widthDiff = event.clientX - this.initialData['initialX'];
        let newWidth=(this.initialData['column']?.width || this.columnConfig['width']) + widthDiff
        if((this.columnConfig['minWidth']==null || this.columnConfig['minWidth'] && newWidth>=this.columnConfig['minWidth']) && (this.columnConfig['maxWidth']==null || this.columnConfig['maxWidth'] && newWidth<=this.columnConfig['maxWidth'])){
          this.initialData['column'].width=newWidth
          this.initialData['initialX']= event.clientX;
        }
      }else{
          const widthDiff = event.clientX - this.initialData['initialX'];
          if(this.gantConfig.tableCollapse)this.gantConfig.tableViewWidth=0
          let newWidth=this.gantConfig.tableViewWidth + widthDiff;
          if(newWidth<0)newWidth=0
          if(newWidth>this.initialData['element']?.scrollWidth)newWidth=this.initialData['element']?.scrollWidth
          if(newWidth!==this.gantConfig.tableViewWidth){
            this.gantConfig.tableViewWidth=newWidth
            this.initialData['initialX']= event.clientX;
            if(this.gantConfig.tableViewWidth==0)this.gantConfig.tableCollapse=true
            else this.gantConfig.tableCollapse=false
          }
      }
    }
    if (this.barDragData['dragStartX']){
      event?.stopPropagation();
      let left=this.calculateLeft(this.barDragData?.item?.startDate?.time,this.barDragData?.item?.endDate?.time,this.barDragData.rowId)
      let width=this.getBarWidth(this.barDragData?.item?.startDate?.time,this.barDragData?.item?.endDate?.time,this.barDragData.rowId)
      this.barDragData['dragOffsetX']=event.clientX - this.barDragData['dragStartX']
      let newLeft=left +(this.barDragData.dateType=='from' ? this.barDragData['dragOffsetX'] : 0)
      if(newLeft<(left+width)){
        this.barDragData.finalLeft=this.barDragData['dragOffsetX']
      }
      const containerRect = this.gantContainer?.nativeElement?.getBoundingClientRect();
      if(event.clientX <= containerRect.left + 80){
        this.gantContainer.nativeElement.scrollLeft -= 1;
        this.barDragData.finalLeft-=1
      }else if(event.clientX >= containerRect.right - 80){
        this.gantContainer.nativeElement.scrollLeft += 1;
        this.barDragData.finalLeft+=1
      }
      document.body.style.userSelect = 'none';
    }
    if(this.gantConfig?.setDependencyData?.dragging){
      event?.stopPropagation();
      this.onDependencyArroweMove(event)
    }

  }
  upadateReselection(tableIndex,rowIndex,colIndex,pinned){
      this.sectionCell.reselectionData={
        currentTableIndex:tableIndex,
        currentRowIndex:rowIndex,
        currentColumnIndex:colIndex,
        pinnedCell:pinned
      }
  }
  startReseection(){
    this.sectionCell.reSelection=true
    document.body.style.userSelect = 'none';
  }
  @HostListener('document:mouseup',['$event'])
  stopResize(event: MouseEvent) {
    if(this.initialData.resizing){
      event?.stopPropagation();
       this.initialData.resizing = false;
      if(this.initialData['column']){
        this.onColumnResize.emit(this.initialData['column'])
        // trigger resize
        try{
          for (let rowId in this.cellComponentInstance){
            if(this.cellComponentInstance[rowId][this.initialData['column']?.columnKey]){
              this.cellComponentInstance[rowId][this.initialData['column']?.columnKey]?.onResize(this.initialData['column'])
            }
          } 
        }catch(e){}
      }else{
        event?.stopPropagation();
        this.onGantViewportChange.emit({tableCollapse:this.gantConfig.tableCollapse,'tableViewWidth':this.gantConfig?.tableViewWidth})
      }
      this.initialData={resizing:false,initialX: 0};
      document.body.style.userSelect = 'auto'; // Restore text selection
    }
    if(this.barDragData.dragStartX){
      event?.stopPropagation();
      this.onBarResizeEnd()
    }
    if(this.gantConfig?.setDependencyData?.dragging){
      event?.stopPropagation();
      this.onDependencyArrowDrop(event)
    }
    if(this.sectionCell?.reSelection){
      event?.stopPropagation();
      if(this.sectionCell.reselectionData && this.sectionCell.tableIndex==this.sectionCell.reselectionData.currentTableIndex){
        let calculatedColumnIndex=this.sectionCell.reselectionData.currentColumnIndex +(this.sectionCell.reselectionData.pinnedCell ? 0 : this.pinnedData?.categoriesColumnData['true']?.length)
        let [colMin,colMax]=this.sectionCell.columnIndex<=this.sectionCell.deferColumnIndex ? [this.sectionCell.columnIndex,this.sectionCell.deferColumnIndex] : [this.sectionCell.deferColumnIndex,this.sectionCell.columnIndex]
        let [rowMin,rowMax]=this.sectionCell.rowIndex<=this.sectionCell.deferRowIndex ? [this.sectionCell.rowIndex,this.sectionCell.deferRowIndex] : [this.sectionCell.deferRowIndex,this.sectionCell.rowIndex]
        if(rowMax==this.sectionCell.reselectionData.currentRowIndex && colMax<calculatedColumnIndex || colMax==calculatedColumnIndex && rowMax<this.sectionCell.reselectionData.currentRowIndex){
            let rows=[]
            let combineColumnData=[...this.pinnedData?.categoriesColumnData['true'],...this.pinnedData?.categoriesColumnData['false']]?.slice(this.sectionCell.columnIndex,this.sectionCell.deferColumnIndex+1)
            this.tableGroupData[this.sectionCell.tableIndex]?.rowData?.slice(this.sectionCell.rowIndex,this.sectionCell.deferRowIndex+1).forEach(data=>{
              let jsonData=combineColumnData.reduce((arr,col)=>{return [...arr,data[col.columnKey]]},[])
              rows.push(jsonData)
            })
            this.sectionCell.deferRowIndex=this.sectionCell.reselectionData.currentRowIndex
            this.sectionCell.deferColumnIndex=calculatedColumnIndex
            if(calculatedColumnIndex==colMax){
              this.onPaste({'pasteData':rows,selfCopy:true,fetchFrom:'preRow'},this.sectionCell.tableIndex,rowMax+1,colMin,false,true)
            }else{
              this.onPaste({'pasteData':rows,selfCopy:true,fetchFrom:'preColumn'},this.sectionCell.tableIndex,rowMin,colMax+1,false,true)
            }
        }
      }
      delete this.sectionCell.reSelection
      delete this.sectionCell.reselectionData
      document.body.style.userSelect = 'auto';
    }
   
  }
  // End : resize column 

  // check onClickInstance event is function or not 
  onCellClick(onClickInstance,column,item,table,index){
    if (typeof onClickInstance === 'function') {
      // The variable is a function
      onClickInstance({column:column,item:item,table:table,rowIndex:index})
    }
  }

  enableSelection(tableIndex,rowIndex,colIndex,pinned){
    let dummyCOl=colIndex + (pinned  ? 0 : this.pinnedData?.categoriesColumnData['true']?.length)
    let columnCheck=this.sectionCell.columnIndex<=this.sectionCell.deferColumnIndex && this.sectionCell?.deferColumnIndex==dummyCOl || this.sectionCell.columnIndex>=this.sectionCell.deferColumnIndex && this.sectionCell.columnIndex==dummyCOl
    return this.sectionCell?.tableIndex==tableIndex && (this.sectionCell.rowIndex<=this.sectionCell.deferRowIndex && this.sectionCell.deferRowIndex==rowIndex && columnCheck || this.sectionCell.rowIndex>=this.sectionCell.deferRowIndex && this.sectionCell.rowIndex==rowIndex && columnCheck)
  }

  trackByFn(index:number,item:any){
    return item.rowId
  }

  showLoader(item,column){
    return item?.showLoader || column?.cellRenderer && !(column?.onlyEnabledInRow && !column?.onlyEnabledInRow?.matchKeyValue?.includes(item[column?.onlyEnabledInRow?.matchKey])) && (!this.cellComponentInstance[item?.rowId] || this.cellComponentInstance[item?.rowId] && (!this.cellComponentInstance[item?.rowId][column?.field] || this.cellComponentInstance[item?.rowId][column?.field] && !this.cellComponentInstance[item?.rowId][column?.field]?.viewContainer?.get(0)))
  }

  // checkbox change

  onCheckboxChange(column,item,tableId,checkbox,selectedFieldType='row'){
    let data={columnData:column,isSelected:checkbox,tableId:tableId}
    if(selectedFieldType=='table'){
      item?.rowData.forEach(row=>{if(!row.emptyRow)row.checked=checkbox})
      data['selectedTable']=item
    }
    else{data['selectedRow']=item}
    this.onSelectionChange.emit(data)
  }
  // disable checkbox
  disabledSelection(tableIds=[],rowIds=[]){
    this.tableGroupData.forEach(table=>{
      table.disabledCheckbox=tableIds.includes(table.tableId)
    })
  }
  // unselect selection
  unselectSection(tableIds=[],rowIds=[]){
    this.tableGroupData.forEach(table=>{
      if(tableIds?.includes(table?.tableId)){
        table.checked=false
        table?.rowData.forEach(row=>{row.checked=false})
      }
      if(rowIds?.length>0){
        table?.rowData.forEach(row=>{if(rowIds?.includes(row.rowId))row.checked=false})
      }
    })
  }

  setColumnWidth(column){
    return (column['width'] || this.columnConfig['width'])-(column?.columnKey=='title' ? this.hierarchyLevel*20 : 0)
  }

  // start : copy past code
    copyData(isCopyInClickBoars=true){
      let selectedCells =document.getElementsByClassName('defer-selection-cell')
      let data={}
      for (let i = 0; i < selectedCells.length; i++) {
        if(!data[selectedCells[i]['row-index']])data[selectedCells[i]['row-index']]=[]
        data[selectedCells[i]['row-index']]?.push(selectedCells[i].textContent)
      }
       // Convert the rows to a string
      const rowsStringArray = Object.keys(data).map(rowIndex =>data[rowIndex].join('\t'));// Use '\t' as a delimiter for tab-separated values);
      // Copy fthe cell value to the clipboard
      if(isCopyInClickBoars){
        const textarea = document.createElement('textarea');
        textarea.value =rowsStringArray.join('\n');
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
      return rowsStringArray?.join('\n');
  }

  @HostListener('document:paste', ['$event'])
  onPasteTrigger(event){
    let focusElement=["INPUT",'TEXTAREA']?.includes(document?.activeElement?.tagName) || document?.activeElement.hasAttribute('contenteditable') && document?.activeElement.getAttribute('contenteditable')=='true'
    if(!focusElement && !document.querySelector('.gridtableCell')){
      this.onPaste(event,this.sectionCell?.tableIndex,this.sectionCell?.rowIndex,this.sectionCell?.columnIndex)
    }
  }

  onPaste(event,tableIndex,pastRowIndex,pastColumnIndex,updateSelection=true,fillCheck?,createRow=false){
      const clipboardData = event.clipboardData
      let rows =event['pasteData'] || [];
      if(clipboardData){
        let pastedData =clipboardData.getData('text')// Pasted data is from Excel or another rich-text source (HTML)
        rows=pastedData.split('\n')
        event?.preventDefault();
      }
      let combineColumnData=[...this.pinnedData?.categoriesColumnData['true'],...this.pinnedData?.categoriesColumnData['false']]
      let columnKey=combineColumnData[pastColumnIndex]?.columnKey
      let table=this.tableGroupData[tableIndex]
      let lastColumnPastIndex=pastColumnIndex;
      let fillCount={}
      if(fillCheck){
        fillCount={
          fillingRowCount:this.sectionCell.deferRowIndex+1-pastRowIndex,
          fillingColumnCount:this.sectionCell.deferColumnIndex+1-pastColumnIndex
        }
        rows.forEach(rowData=>{
          while(fillCount['fillingColumnCount']>rowData?.length){
            rowData=[...rowData,...rowData]
          }
        })
        while(fillCount['fillingRowCount']>rows?.length){
          rows=[...rows,...rows]
        }
      }

      for (let i=0;i<rows?.length && (i<fillCount['fillingRowCount'] || !fillCount['fillingRowCount']);i++) {
        let j=0;
        let valuesJson={}
        let valuesFullInfoJson={}
        let columnData=event['selfCopy'] ?  rows[i] :rows[i]?.split('\t')
        columnData.forEach(columnValue=>{
          if(combineColumnData?.length>pastColumnIndex+j && (j<fillCount['fillingColumnCount'] || !fillCount['fillingColumnCount'])){
              lastColumnPastIndex=pastColumnIndex+j
              if(combineColumnData[pastColumnIndex+j]?.editable){
                valuesJson[combineColumnData[pastColumnIndex+j]?.columnKey]=columnValue
                valuesFullInfoJson[combineColumnData[pastColumnIndex+j]?.columnKey]={column:combineColumnData[pastColumnIndex+j]?.columnParams,newValue:columnValue,fetchFrom:event['fetchFrom']}   
              }
            }
          j++;
        })
        
        if(Object.keys(valuesJson).length>0){
          let itemData= (pastRowIndex+i)<table?.rowData?.length ? table?.rowData[pastRowIndex+i] : {emptyRow:true,[columnKey]:columnKey}
          this.changedCellValue({value:valuesJson,field:columnKey,params:{field:columnKey,item:itemData},currentTable:table,createRow:createRow || (pastRowIndex+i)==table?.rowData?.length-1,columnInfo:valuesFullInfoJson})
        }
      }
      if(updateSelection)this.storeCurrentColumnRowIndex({isManualSet:true},tableIndex,this.sectionCell?.rowIndex+rows?.length-1,lastColumnPastIndex,null,null)
  }

  enableDeferClass(rowIndex,colIndex,tableIndex){
    let rowValidation =this.sectionCell.deferRowIndex>=this.sectionCell.rowIndex && rowIndex>=this.sectionCell.rowIndex && rowIndex<=this.sectionCell.deferRowIndex  || this.sectionCell.deferRowIndex<this.sectionCell.rowIndex && rowIndex<=this.sectionCell.rowIndex && rowIndex>=this.sectionCell.deferRowIndex;
    let colValidation =this.sectionCell.deferColumnIndex>=this.sectionCell.columnIndex && colIndex>=this.sectionCell.columnIndex && colIndex<=this.sectionCell.deferColumnIndex  || this.sectionCell.deferColumnIndex<this.sectionCell.columnIndex && colIndex<=this.sectionCell.columnIndex && colIndex>=this.sectionCell.deferColumnIndex ;
    return this.sectionCell.tableIndex==tableIndex && rowValidation && colValidation 
  }
 
  // end copy past 

  // handle keyboard access start here

  handleKeyboardEvent= (event: any): void => {
    if(event.ctrlKey && event.key === 'c'){
      this.copyData()
    }
    if(['Escape','Enter','ArrowLeft','ArrowRight','ArrowDown','ArrowUp','Shift']?.includes(event.key) || this.gridSettingConfig?.enableTabKeyAccess && event.key=="Tab"){
      const currentFocusElement = document.activeElement || {};
      let popover=document.querySelector('.gridtableCell')
      if(popover){
        event.stopPropagation()
        if(['Escape'].includes(event.key)){
          event?.preventDefault()
          this.sectionCell['sectionCellFirstDivElementRef']?.click()
        }
      }
      else if(["INPUT",'TEXTAREA']?.includes(currentFocusElement['tagName']) || currentFocusElement['isContentEditable'] || currentFocusElement==this.sectionCell['sectionCellInputElementRef']){
        event.stopPropagation()
        if(['Escape','Enter'].includes(event.key)){
          this.setBlur();
          if(event.key=='Enter'){
            this.changeRowIndex(1)
            this.ensureVisible()
          }
        }
      }
      else{
        let focusElement=["INPUT",'TEXTAREA']?.includes(document?.activeElement?.tagName) || document?.activeElement.hasAttribute('contenteditable') && document?.activeElement.getAttribute('contenteditable')=='true'
        if (!focusElement && (event.key === 'ArrowLeft' || event.key === 'Tab' && event?.shiftKey)) {
          event?.preventDefault()
          // Handle left arrow key press
          if(this.sectionCell.columnIndex==0){
            if(this.sectionCell.rowIndex>0){
                let totalColumn=this.pinnedData?.categoriesColumnData['true']?.length+this.pinnedData?.categoriesColumnData['false']?.length
                this.sectionCell.columnIndex=totalColumn-1
                this.changeRowIndex(-1)
                this.ensureVisible()
            }
          }else if(this.sectionCell.columnIndex>0){
            this.sectionCell.columnIndex--;
            this.ensureVisible()
          }
        }else if (!focusElement && (event.key === 'ArrowRight' || event.key === 'Tab' && !event?.shiftKey)) {
          event?.preventDefault()
          // Handle right arrow key press
          let totalColumn=this.pinnedData?.categoriesColumnData['true']?.length+this.pinnedData?.categoriesColumnData['false']?.length
          if(totalColumn-1==this.sectionCell.columnIndex){
            this.sectionCell.columnIndex=0
            this.changeRowIndex(1)
          }else{
            this.sectionCell.columnIndex++
          }
          this.ensureVisible()
        } else if (!focusElement && event.key === 'ArrowDown') {
          event?.preventDefault()
          // Handle down arrow key press
          this.changeRowIndex(1)
          this.ensureVisible()
        } else if (!focusElement && event.key === 'ArrowUp') {
          event?.preventDefault()
          // Handle up arrow key press
          this.changeRowIndex(-1)
          this.ensureVisible()
        }else if(event?.key === 'Enter'){
          event?.preventDefault()
          let sectionCellRef =document.getElementsByClassName('enable-selection-cell')
          if(sectionCellRef?.length>0){
            // trigger blur first
            this.setBlur();
            // trigger focus
            this.sectionCell['sectionElementRef']=sectionCellRef[0];
            this.sectionCell['sectionCellFirstDivElementRef']= this.sectionCell['sectionElementRef']?.querySelector('.cell-renderer')?.querySelector(':nth-child(2)')?.querySelector('.cell-box') as HTMLElement;
            setTimeout(() => {
              this.sectionCell['sectionCellInputElementRef']=this.sectionCell['sectionElementRef']?.querySelector('input');
              this.sectionCell['sectionCellFirstDivElementRef']?.click()
              this.sectionCell['sectionCellInputElementRef']?.click()
              this.sectionCell['sectionCellInputElementRef']?.focus();
            },2); 
          }
        }else if(event?.key === 'Escape'){
          this.setBlur();
        }
        this.storeCurrentColumnRowIndex({'isManualSet':true},this.sectionCell.tableIndex,this.sectionCell.rowIndex,this.sectionCell.columnIndex,null,null)
        this.cdr?.detectChanges()
      }
    }
  }
  changeRowIndex(position=0){
    if(position>0){
      if(this.sectionCell.rowIndex==this.tableGroupData[this.sectionCell.tableIndex]?.totalRecordCount && this.sectionCell.tableIndex<this.tableGroupData?.length-1){
        this.sectionCell.tableIndex++;
        this.sectionCell.rowIndex=0
        return
      }
    }else if(position<0){
      if(this.sectionCell.rowIndex==-1  && this.sectionCell.tableIndex>0){
        this.sectionCell.tableIndex--;
        this.sectionCell.rowIndex=this.tableGroupData[this.sectionCell.tableIndex]?.totalRecordCount || 0
        return
      }
    }
    this.sectionCell.rowIndex=this.sectionCell.rowIndex+position;
    if(this.sectionCell.rowIndex==this.tableGroupData[this.sectionCell.tableIndex]?.totalRecordCount){
      if(this.tableGroupData[this.sectionCell.tableIndex]?.createRowConfig?.editable){
        this.focusOnCreateRow(this.tableGroupData[this.sectionCell.tableIndex])
      }
    }
    if(this.sectionCell.rowIndex<-1)this.sectionCell.rowIndex=-1
    if(this.sectionCell.columnIndex<0)this.sectionCell.columnIndex=0
  }
  setBlur(){
    this.sectionCell['sectionElementRef']?.blur();
    this.sectionCell['sectionCellInputElementRef']?.blur()
    this.sectionCell['sectionCellFirstDivElementRef']?.blur()
    delete this.sectionCell['sectionCellInputElementRef']
  }

  // handle key board access end here
  storeCurrentColumnRowIndex(event,tableIndex,rowIndex,colIndex,pinned,colref){
    if(event.ctrlKey && event.shiftKey && this.sectionCell.columnIndex>-1 || event?.isManualSet){
      this.sectionCell.deferRowIndex=rowIndex
      this.sectionCell.deferColumnIndex=colIndex +(pinned==true || pinned==null ? 0 : this.pinnedData?.categoriesColumnData['true']?.length)
    }
    else{
      this.sectionCell.columnIndex=colIndex +(pinned==true || pinned==null ? 0 : this.pinnedData?.categoriesColumnData['true']?.length)
      this.sectionCell.rowIndex=rowIndex;
      this.sectionCell.tableIndex=tableIndex
      this.sectionCell.deferRowIndex=this.sectionCell.rowIndex
      this.sectionCell.deferColumnIndex=this.sectionCell.columnIndex
      this.sectionCell['sectionElementRef']=colref
      this.sectionCell['sectionCellFirstDivElementRef']= this.sectionCell['sectionElementRef']?.querySelector('.cell-renderer')?.querySelector(':nth-child(2)')?.querySelector(':nth-child(1)') as HTMLElement;
      setTimeout(() => {
        this.sectionCell['sectionCellInputElementRef']=this.sectionCell['sectionElementRef']?.querySelector('input');
      },2);
    }
  }

  ensureVisible(){
    let sectionCellRef =this.viewport?.nativeElement?.getElementsByClassName('enable-selection-cell')
    if(sectionCellRef?.length){
      sectionCellRef[0]?.scrollIntoView({behavior:'smooth', block: "center",inline: "center"})
    }
  }

  onTableFocus(){
    console.log('j')
  }

  enableDisableKeyboardAccess(enable=true){
    if(enable)document.addEventListener('keydown',this.handleKeyboardEvent);
    else document.removeEventListener('keydown',this.handleKeyboardEvent);
  }

  ngOnDestroy() {
    document.removeEventListener('document:mousemove', this.onResize);
    document.removeEventListener('document:mouseup', this.stopResize);
    this.enableDisableKeyboardAccess(false)
  }

  // gant code start here

  onGanttScroll(event,el){
    const scrollLeft = event.target.scrollLeft;
    const maxScrollLeft = event.target.scrollWidth - event.target.clientWidth;
    if (scrollLeft > maxScrollLeft-20){
      const oldHeaderEndCell = this.gantHeader[this.gantHeader.length-1];
      this.createGantHeader(this.gantConfig.currentChartType,'Right')
      this.gantContainer.nativeElement.scrollLeft = this.calculateLeft(oldHeaderEndCell.time,null)-20
    }
    if (scrollLeft < 20){
      const oldHeaderStartCell = this.gantHeader[0];
      this.createGantHeader(this.gantConfig.currentChartType,'Left')
      this.gantContainer.nativeElement.scrollLeft = this.calculateLeft(oldHeaderStartCell.time,null)-20
    }
  }

  createGantHeader(type,direction?,showingCount=40){
    let previousFocusTime=0
    if(this.gantHeader.length){
      const centerScrollLeft = this.gantContainer.nativeElement.scrollLeft + (this.gantContainer.nativeElement.clientWidth / 2);
      let daysOffset = centerScrollLeft / this.getTimeScale()
      previousFocusTime=(this.gantHeader.length ? this.gantHeader[0]?.time : 0) + (daysOffset * 24* 60 * 60 * 1000)
    }
    if(this.gantConfig.currentChartType!==type)this.onGantViewportChange.emit({currentChartType:type})
    this.gantConfig.currentChartType=type
    type=type?.toUpperCase()
    let months:any=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov','Dec']
    let [incrementRange,baseDate]=[[-1,1],new Date()]
    if(!direction){
      this.gantConfig.zoomInOut=0
      this.gantHeader=[]
    }else{
      [incrementRange,baseDate]=direction=='Left' ? [[-1],this.gantHeader[0].time] : [[1],this.gantHeader[this.gantHeader.length-1].time]
    }
    if(type=='DAYS'){
      let time=[{subLabel:'12AM'},{subLabel:'1AM'},{subLabel:'2AM'},{subLabel:'3AM'},{subLabel:'4AM'},{subLabel:'5AM'},{subLabel:'6AM'},{subLabel:'7AM'},{subLabel:'8AM'},{subLabel:'9AM'},{subLabel:'10AM'},{subLabel:'11AM'},{subLabel:'12PM'},{subLabel:'1PM'},{subLabel:'2PM'},{subLabel:'3PM'},{subLabel:'4PM'},{subLabel:'5PM'},{subLabel:'6PM'},{subLabel:'7PM'},{subLabel:'8PM'},{subLabel:'9PM'},{subLabel:'10PM'},{subLabel:'11PM'}]
      incrementRange.forEach(range=>{
        let currentDate=new Date(baseDate)
        currentDate.setHours(0,0,0,0);
        for(let i=0;i<=showingCount;i++){
          currentDate.setDate(currentDate.getDate()+(i==0 && !direction && range>0 ? 0 : range))
          let value=currentDate.getDate()+' '+months[currentDate.getMonth()]+' '+currentDate.getFullYear()
          let data={fieldType:'Days',label:value,subValues:time,'time':currentDate.getTime(),weekday:currentDate.getDay()}
          if(range<0){
            this.gantHeader.splice(0,0,data);
          }else{
            this.gantHeader.push(data);
          }
          
        }
      })
    }
    else if(type=='WEEKS'){
      let weeksDats=['S','M','T','W','T','F','S']
      incrementRange.forEach(range=>{
        let currentDate=new Date(baseDate)
        currentDate.setHours(0,0,0,0);
        currentDate.setDate(currentDate.getDate()-(currentDate.getDay()))
        for(let i=0;i<=showingCount;i++){
          let weekDays=[]
          for(let j=0;j<7;j++){
            currentDate.setDate(currentDate.getDate()+(i==0 && j==0 && !direction && range>0 ? 0 : range))
            weekDays.push({subLabel:weeksDats[currentDate.getDay()],date:currentDate.getDate(),month:currentDate.getMonth(),year:currentDate.getFullYear(),weekday:currentDate.getDay()})
          } 
          if(range<0)weekDays.reverse()
          let value=weekDays[0].date+' '+months[weekDays[0].month]+' '+weekDays[0].year+'-'+weekDays[6].date+' '+months[weekDays[6].month]+' '+weekDays[6].year
          let data={fieldType:'Weeks',label:value,subValues: weekDays,time:currentDate.getTime()}
          if(range<0){
            this.gantHeader.splice(0,0,data);
          }else{
            this.gantHeader.push(data);
          }
        }
      })
    }
    else if(type=='MONTHS'){
      incrementRange.forEach(range=>{
        let currentDate=new Date(baseDate)
        currentDate.setHours(0,0,0,0);
        for(let i=0;i<=showingCount;i++){
          currentDate.setDate(1);
          currentDate.setMonth(currentDate.getMonth()+(i==0 && !direction && range>0 ? 0 : range))
          let year=currentDate.getFullYear()
          let isLeepYear=(year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)
          var monthdaysList = [31, 28 + (isLeepYear ? 1 : 0), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
          let subFileds=[]
          for(let j=1;j<=monthdaysList[currentDate.getMonth()];j++){
            let date=new Date(year,currentDate.getMonth(),j)
            subFileds.push({subLabel:j,weekday:date.getDay()})
          }
          let value=months[currentDate.getMonth()]+' '+currentDate.getFullYear()
          let data={fieldType:'months',label:value,subValues:subFileds,time:currentDate.getTime()}
          if(range<0){
            this.gantHeader.splice(0,0,data);
          }else{
            this.gantHeader.push(data);
          }
          
        }
      })
    }
    else if(type=='QUARTERS'){
      const monthsInQuarter = {1: [{subLabel:'Jan'}, {subLabel:'Feb'}, {subLabel:'Mar'}],2: [{subLabel:'Apr'}, {subLabel:'May'}, {subLabel:'Jun'}],3: [{subLabel:'Jul'},{subLabel:'Aug'},{subLabel:'Sep'}],4: [{subLabel:'Oct'}, {subLabel:'Nov'}, {subLabel:'Dec'}]};
      incrementRange.forEach(range=>{
        let currentDate=new Date(baseDate)
        currentDate.setHours(0,0,0,0);
        currentDate.setDate(1);
        for(let i=0;i<=showingCount;i+=4){
          currentDate.setFullYear(currentDate.getFullYear()+(i==0 && !direction && range>0 ? 0 : range))
          for(let j=1;j<=4;j++){
            let quarter=range<0 ? 5-j : j
            currentDate.setMonth(months.indexOf(monthsInQuarter[quarter][0]?.subLabel));
            let value='Q'+quarter+'('+currentDate.getFullYear()+')';
            let data={fieldType:'quarters',label:value,subValues:monthsInQuarter[quarter],time:currentDate.getTime(),quarterIndex:quarter,year:currentDate.getFullYear()}
            if(range<0){
              this.gantHeader.splice(0,0,data);
            }else{
              this.gantHeader.push(data);
            }
          }
        }
      })
    }
    else if(type=='YEARS'){
      let subFileds=[{subLabel:'Q1'},{subLabel:'Q2'},{subLabel:'Q3'},{subLabel:'Q4'}] 
      incrementRange.forEach(range=>{
        let currentDate=new Date(baseDate)
        currentDate.setHours(0,0,0,0);
        currentDate.setDate(1);
        for(let i=0;i<=showingCount;i++){
          currentDate.setFullYear(currentDate.getFullYear()+(i==0 && !direction && range>0 ? 0 : range))
          currentDate.setMonth(0)
          let data={fieldType:'years',label:currentDate.getFullYear(),subValues:subFileds,time:currentDate.getTime(),year:currentDate.getFullYear()}
          if(range<0){
            this.gantHeader.splice(0,0,data);
          }else{
            this.gantHeader.push(data);
          }
          
        }
      })
    }
    if(!direction){
      setTimeout(() => {
        this.gantContainer.nativeElement.scrollLeft=previousFocusTime>0 ? this.calculateLeft(previousFocusTime,null) : this.calculateLeft(baseDate.getTime(),null)
      },5);
    }
  }
  scrollToBar(item){
    if(item.endDate?.time>this.gantHeader[this.gantHeader.length-1].time){
      let dayes=(item.endDate?.time -this.gantHeader[this.gantHeader.length-1].time)/ (1000 * 3600 * 24)
      this.createGantHeader(this.gantConfig.currentChartType,'Right',dayes+10)
    }
    else if(item.startDate?.time<this.gantHeader[0].time){
      let dayes=(this.gantHeader[0].time-item.startDate?.time)/ (1000 * 3600 * 24)
      this.createGantHeader(this.gantConfig.currentChartType,'Left',dayes+10)
    }
    setTimeout(() => {
      this.gantContainer.nativeElement.scroll({left: this.calculateLeft(item.startDate?.time,item.endDate?.time)-40,behavior: 'smooth'});
    },2);
  }

  getBarWidth(fromDate, toDate,rowId): number {
    let diffInDays=0
    let differVal=0
    if(toDate || fromDate){
      let dummyToDate=toDate ? toDate : fromDate
      let dummyFromDate=fromDate ? fromDate : toDate
      diffInDays = (dummyToDate - dummyFromDate) / (1000 * 3600 * 24);
      differVal=diffInDays * this.getTimeScale()
    }
    return differVal+(rowId && this.barDragData.rowId==rowId && this.barDragData.finalLeft ? (this.barDragData.dateType=='from' ? -this.barDragData.finalLeft : +this.barDragData.finalLeft) : 0 ); 
  }

  calculateLeft(fromDate,toDate,rowId?): number {
    const startDate = this.gantHeader.length ? this.gantHeader[0]?.time : 0
    let dummyFromDate=fromDate ? fromDate : toDate;
    const diffInDays = ((dummyFromDate || 0) - startDate) / (1000 * 3600 * 24);
    let differVal= diffInDays * this.getTimeScale();
    return differVal + (rowId && this.barDragData.rowId==rowId && this.barDragData.finalLeft && this.barDragData.dateType=='from' ? this.barDragData.finalLeft : 0 ); 
  }

  startBarResizing(dateType,event: MouseEvent,item,element?): void {
    event?.stopPropagation();
    this.barDragData.item=item;
    this.barDragData.rowId=item.rowId
    this.barDragData.dragStartX=event.clientX;
    this.barDragData.elementRef= element
    this.barDragData.dateType=dateType
    if(this.barDragData.item && !this.barDragData.item?.startDate?.time && !this.barDragData.item?.endDate?.time && this.gantConfig?.moveBar?.rowId==this.barDragData?.rowId){
      this.barDragData.setBothDate=true
      this.barDragData.item.startDate.time =(this.gantHeader.length ? this.gantHeader[0]?.time : 0) + ((this.gantConfig?.moveBar?.mouseEvent / this.getTimeScale()) * 24* 60 * 60 * 1000)
      this.barDragData.item.endDate.time =(this.gantHeader.length ? this.gantHeader[0]?.time : 0) + (((this.gantConfig?.moveBar?.mouseEvent+30) / this.getTimeScale()) * 24* 60 * 60 * 1000)
    }
  }

  onBarResizeEnd(){
    let obj={item:this.barDragData.item}
    let dateUpdateTypes=this.barDragData.dateType ? [this.barDragData.dateType] : []
    if(this.barDragData?.setBothDate)dateUpdateTypes=['from','to']
    dateUpdateTypes.forEach(key=>{
      let left=this.calculateLeft(this.barDragData?.item?.startDate?.time,this.barDragData?.item?.endDate?.time,this.barDragData.rowId)+(key=='to' ? this.getBarWidth(this.barDragData?.item?.startDate?.time,this.barDragData?.item?.endDate?.time,this.barDragData.rowId) : 0)
      let daysOffset = left / this.getTimeScale();
      let fintalTime=(this.gantHeader.length ? this.gantHeader[0]?.time : 0) + (daysOffset * 24* 60 * 60 * 1000)
      const newDate = new Date(fintalTime);
      obj[key]={
          dateType:key,
          newTime:newDate.getTime(),
          previousTime:this.barDragData.item[key=='from' ? 'startDate' : 'endDate'].time,
          newDate:newDate
        }
      this.barDragData.item[key=='from' ? 'startDate' : 'endDate'].time=newDate.getTime()
    })
    this.onBarDrop.emit(obj)
    delete this.gantConfig.moveBar
    this.barDragData={}
    document.body.style.userSelect = 'auto';
  }

  getTimeScale(){
    let scale=1;
    if(this.gantConfig.currentChartType=='Days'){
      scale= this.getHeaderDummySubCellWidth() *24
   }else  if(this.gantConfig.currentChartType=='Weeks' || this.gantConfig.currentChartType=='Months'){
    scale= this.getHeaderDummySubCellWidth() // Adjust the multiplier based on your timeline scale
   }else if(this.gantConfig.currentChartType=='Years'){
      if(this.gantHeader?.length){
          let quarterInYear= 4// in a year has 4 quarters and each quarters has 3 months
          let totalDays=this.gantHeader.reduce((total,info)=>{total+=(this.isLeapYear(info?.year) ? 366 : 365);return total},0)
          scale=(quarterInYear*this.getHeaderDummySubCellWidth())/(totalDays/this.gantHeader.length)
      }
   }else if(this.gantConfig.currentChartType=='Quarters'){
      if(this.gantHeader?.length){
        let monthInQuater= 3// in a year has 4 quarters and each quarters has 3 months
        let totalDays=this.gantHeader.reduce((total,info)=>{if(info?.quarterIndex==1)total+=(this.isLeapYear(info?.year) ? 366 : 365);return total},0)
        scale=(monthInQuater*this.getHeaderDummySubCellWidth())/(totalDays/this.gantHeader.length)
      }
   }
   return scale
  }

  isLeapYear(year){
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)
  }

  openBarInfoPopover(event,popover,item){
    if(!item.startDate.time && !item.endDate.time){
      return
    }
    event.preventDefault()
    event.stopPropagation()
    this.gantConfig.barInfoPopoverRef?.hide()
    let mousePosFrmBottom = $(window).height() - event.pageY;
    let mousePosFrmRight = $(window).width() - event.pageX;
    this.gantConfig.barInfoPopoverRef=popover
    popover?.show()
    setTimeout(() => {
      let yaxixs = event.pageY;
      let xaxixs = event.pageX;
      let popup=$(".bar-info-popover")
      let changedTop = popup.height() - mousePosFrmBottom;
      let changeRight=popup.width()-mousePosFrmRight;
      let newTop=yaxixs;
      let newRight=xaxixs;
      if (changedTop>0)newTop= yaxixs-changedTop-100;
      if(changeRight>0)newRight=xaxixs-changeRight-50;
      popup.css({ left: newRight, top: newTop, visibility: 'visible'})
    },1);

  }


  getHeaderDummySubCellWidth(){
    return this.gantConfig?.headerSubCellWidth+ 10*this.gantConfig.zoomInOut
  }

  zoomInOut(zoom){
    let type=this.gantConfig.gantChartViewTypes || []
    if(this.gantConfig.currentChartType==type[type.length-1] && zoom>0 || this.gantConfig.currentChartType==type[0] && zoom<0){
      return
    }
    else if(zoom>0 && this.gantConfig.zoomInOut<4 || zoom<0 && this.gantConfig.zoomInOut>-1){
      this.gantConfig.zoomInOut=(this.gantConfig.zoomInOut || 0)+zoom
    }
    else{
      let index=type.indexOf(this.gantConfig.currentChartType)
      if(zoom>0 && index<type.length-1 || zoom<0 && index>0){
        this.gantConfig.currentChartType=type[index+zoom]
        this.createGantHeader(this.gantConfig.currentChartType)
      }
    }
  }

  collapseGridInGantt(event){
    this.gantConfig.tableCollapse=!this.gantConfig?.tableCollapse
    if(!this.gantConfig.tableCollapse && !this.gantConfig?.tableViewWidth)this.gantConfig.tableViewWidth=this.gantConfig?.defaultMaxTableViewWidth
    this.onGantViewportChange.emit({tableCollapse:this.gantConfig.tableCollapse,'tableViewWidth':this.gantConfig?.tableViewWidth})
  }

  // set dependency code

  getDependentItemInfoList(currentItem){
    let currentIdIndex=-1;
    let currentFount=false
    let list=[]
    for(let i=0;i<this.tableGroupData.length;i++){
      for(let j=0;j<this.tableGroupData[i].rowData.length;j++){
        let item=this.tableGroupData[i].rowData[j]
        if(!currentFount && item.rowId==currentItem.rowId){
          currentIdIndex=i+j;
          currentFount=true
        }
        if(currentItem?.dependencies?.dependents.includes(item.rowId)){
          let widthOfHorizontal=this.calculateLeft(item.startDate.time,item.endDate.time)-(this.calculateLeft(currentItem.startDate.time,currentItem.endDate.time,currentItem.rowId)+this.getBarWidth(currentItem?.startDate?.time,currentItem?.endDate?.time,currentItem.rowId));
          let checkLeft=widthOfHorizontal>0 ? true : false
          if(item.startDate.time && item.endDate.time)list.push({item:item,index:i+j,currentIdIndex:currentIdIndex,widthOfHorizontal:Math.abs(widthOfHorizontal),leftCorner:checkLeft,below:currentIdIndex>-1})
        }
      }
    }
    return list;
  }

  onMouseDown(event: MouseEvent, item) {
    event?.stopPropagation()
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const containerRect = this.gantContainer.nativeElement.getBoundingClientRect();
    const mouseX = (rect.left + rect.width / 2) - containerRect.left;
    const mouseY=(rect.top + rect.height / 2)-containerRect.top-38
    this.gantConfig.setDependencyData={}
    this.gantConfig.setDependencyData.item=item;
    this.gantConfig.setDependencyData.startX = this.gantContainer.nativeElement.scrollLeft+mouseX;
    this.gantConfig.setDependencyData.startY = this.gantContainer.nativeElement.scrollTop+mouseY;
    this.gantConfig.setDependencyData.mouseX = this.gantContainer.nativeElement.scrollLeft+mouseX;
    this.gantConfig.setDependencyData.mouseY = this.gantContainer.nativeElement.scrollTop+mouseY;
    this.gantConfig.setDependencyData.dragging = true;
    this.gantContainer.nativeElement.style.cursor = 'pointer';
    document.body.style.userSelect = 'none';
  }

  onDependencyArroweMove(event: MouseEvent) {
    event?.stopPropagation()
    const containerRect = this.gantContainer.nativeElement.getBoundingClientRect();
    this.gantConfig.setDependencyData.mouseX = this.gantContainer.nativeElement.scrollLeft+(event.clientX - containerRect.left);
    this.gantConfig.setDependencyData.mouseY = this.gantContainer.nativeElement.scrollTop+(event.clientY - containerRect.top-50);

  }

  onDependencyArrowDrop(event){
    let dropItemId=event?.target?.bar_row_id
    if(dropItemId && this.gantConfig?.setDependencyData?.item?.rowId && dropItemId!==this.gantConfig?.setDependencyData?.item?.rowId){
      let checkValidation = this.gantConfig?.dependenciesValidation ? !(event?.target?.closest('.gantt-body-row')?.classList.contains('not-allow-dependency-drop')) : true 
      if(checkValidation && (!this.gantConfig?.setDependencyData?.item?.dependencies?.depends_on || !this.gantConfig?.setDependencyData?.item?.dependencies?.depends_on?.includes(dropItemId)) && (!this.gantConfig?.setDependencyData?.item?.dependencies || !this.gantConfig?.setDependencyData?.item?.dependencies?.dependents?.includes(dropItemId))){
        if(!this.gantConfig?.setDependencyData?.item?.dependencies)this.gantConfig.setDependencyData.item.dependencies={}
        this.gantConfig.setDependencyData.item.dependencies.dependents=[...(this.gantConfig?.setDependencyData?.item?.dependencies?.dependents || []),dropItemId]
        this.onDependencyChanged.emit({params:{item:this.gantConfig?.setDependencyData?.item},addedItemId:dropItemId,newDependencies:this.gantConfig?.setDependencyData?.item?.dependencies,value:{'dependencies':this.gantConfig?.setDependencyData?.item?.dependencies}})
      }
    }
    delete this.gantConfig?.setDependencyData;
    document.body.style.userSelect = 'auto';
    this.gantContainer.nativeElement.style.cursor = 'auto';
  }

  // end dependency code

  // end gantt code 
}



