<div class="assit-container h-100 w-100">
    <div class="assist-header">
        <div class="d-flex">
            <app-dashboard-icon [size]="20"  [name]="'aiAssistBlueBg'"></app-dashboard-icon>
            <div class="ml-1">AI</div>
        </div>
        <div class="close-btn" (click)="closeModal.emit(null)">&times;</div>
    </div>
    <div class="assist-body">
        <div class="functions-wrapper">
            <div class="f-xl-sb py-2">Functions</div>
            <div class="functions-options">
                <div class="">
                    <span>Tone</span>
                    <div class="selecet-box">
                        <ng-select [items]="aiChatFunctions.TONE" bindLabel="display" bindValue="key" [clearable]="false" [(ngModel)]="seletedChatFunction.tone" [multiple]="true" placeholder="select tone"></ng-select>
                    </div>
                </div>
                <div class="">
                    <span>Length</span>
                    <div class="selecet-box">
                        <ng-select [items]="aiChatFunctions.LENGTH" bindLabel="display" bindValue="key" [clearable]="false" [(ngModel)]="seletedChatFunction.length" [multiple]="true" placeholder="select tone"></ng-select>
                    </div>
                </div>
                <div class="">
                    <span>Style</span>
                    <div class="selecet-box">
                        <ng-select [items]="aiChatFunctions.STYLE" bindLabel="display" bindValue="key" [clearable]="false" [(ngModel)]="seletedChatFunction.style" [multiple]="true" placeholder="select tone"></ng-select>
                    </div>
                </div>
                <div class="">
                    <span>Department</span>
                    <div class="selecet-box">
                        <ng-select [items]="aiChatFunctions.DEPARTMENT" bindLabel="display" bindValue="key" [clearable]="false" [(ngModel)]="seletedChatFunction.department" [multiple]="true" placeholder="select tone"></ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="ai-chat-wrapper">
            <div *ngIf="chatList?.length>0" class="ai-chat-list" #viewPort [scrollTop]="viewPort?.scrollHeight">
                <ng-container *ngFor="let data of chatList">
                    <ng-container *ngTemplateOutlet="chatBoxTemp;context:{data:data}"></ng-container>
                </ng-container>
            </div>
            <ng-container *ngIf="!chatList?.length">
                <div class="w-100 h-100 empty-chat-container">
                    <app-dashboard-icon [size]="70"  [name]="'aiAssist'"></app-dashboard-icon>
                    <span>Ask Pronnel AI</span> 
                </div>
            </ng-container>
            <div class="">
                <div class="input-div" [attr.contenteditable]="isLoading ? false : true" #editableDiv  [innerHTML]="sendText" (keydown)="onKeydown($event)" data-placeholder="Ask Pronnel AI">

                </div>
            </div>
        </div>
        <div class="options-wrapper">
            <div class="options-value">
            </div>
        </div>
    </div>
</div>

<ng-template #chatBoxTemp let-data="data">
    <div class="chart-box">
        <div class="chat-header">
            <ngx-avatars class="mr-1" [name]="data.username || data.showUserName" [src]="data.userId=='ai' ? 'assets/1x/ai-assist-bluebg.svg' : orgData[currentUserId]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
            <span>{{data.showUserName}}</span>
        </div>
        <div class="chat-body">
            <ng-container *ngIf="!data.loading">
                <p>{{data.text}}</p>
            </ng-container>
            <ng-container *ngIf="data.loading">
                <div class="spinner-border mr-2" style="width: 15px ;height: 15px;color: blue;" role="status"><span class="sr-only">
                </span></div> 
              {{'common.loading' | translate}}
            </ng-container>
        </div>
        <div *ngIf="data.userId=='ai' && !data.loading" class="f-s-r chat-sub-options">
            <span >Copy</span>
            <ng-container *ngIf="editSelectionText">
                <span>Append</span>
                <span [ngbPopover]="false && replaceCofirmation" #replaceCofirmationPopup="ngbPopover" placement="top"  [autoClose]="'outside'">Replace</span>
            </ng-container>
            <span (click)="sendMsg(chatList[data.responseId]?.text)">Regenerate</span>
            <ng-template #replaceCofirmation>
              <div style="width: 230px;">
                <div class="text-center">
                    Are to sure want to replace<br>
                    "{{selectedText  | truncate: 30}}" to "{{data.text  | truncate: 30}}" ?
                </div>
                <div class="d-flex align-items-center justify-content-center">
                    <button>Yes</button>
                    <button>No</button>
                </div>
              </div>
            </ng-template>
        </div>
    </div>
</ng-template>