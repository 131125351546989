import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberTransform'
})
export class NumberTransformPipe implements PipeTransform {

  transform(value: number | string, decimalPlaces: number = 2, action: 'FORMATTED' | 'UNFORMATTED' = 'FORMATTED'): string | number {
    if (action === 'FORMATTED') {
      return this.formatNumber(value, decimalPlaces);
    } else if (action === 'UNFORMATTED') {
      return this.unformatNumber(value,decimalPlaces);
    }
    return value;
  }

  private formatNumber(value: number | string, decimalPlaces: number): string {
    if (value === '' || value === null || value === undefined) {
      return '';
    }
    const numberValue = parseFloat(value.toString().replace(/,/g, ''));
    return new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    }).format(numberValue);
  }

  private unformatNumber(value: number | string, decimalPlaces: number): string {
    if (value === '' || value === null || value === undefined) {
      return '';
    }
    const numberValue = parseFloat(value.toString().replace(/,/g, ''));
    const formattedValue = numberValue.toFixed(decimalPlaces);
    return formattedValue;
  }
}