import { Component, Input, OnInit, TemplateRef, ViewChild, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { MediaIcons } from '../../../../Enums/media-icons';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {v4 as uuidv4} from 'uuid';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { ConstantService } from '../../../../Enums/Constant.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { BasicUtils } from 'app/services/basicUtils.service';
 
declare var $: any;
@Component({
  selector: 'app-expanded-email-view',
  templateUrl: './expanded-email-view.component.html',
  styleUrls: ['./expanded-email-view.component.scss']
})
export class ExpandedEmailViewComponent implements OnInit {
  @Input() selectedEmailObj:any = undefined
  @Input() leadInfo:any = {}
  @Input() dashId:string = null
  @Input() sprintArr:any;
  @Input() isFullyExpanded: boolean =  true;
  @Input() isThreadView: any = false;
  @Output() backToAllEmails = new EventEmitter();
  usersEmailsAllowedToReply:any[] = []
  mediaIcons = MediaIcons;
  filePreviewModal: any;
  currentIndex = -1;
  signatureSeparator = ''
  @ViewChild("replyAllPop") replyAllPop: NgbPopover;
  @ViewChild("replyPopover") replyPopover: NgbPopover;
  @ViewChild("popEmail1") popEmail1: NgbPopover; 
  @ViewChild("popEmail2") popEmail2: NgbPopover; 
  @ViewChild("popEmailR3") popEmailR3: NgbPopover;
  emailEditorEmbeddedAttachments: {} = {}
  signatureId: string = null
  selectedEmail: any = undefined
  defaultSignatures:any = null
  isReplyAll:boolean = false;
  fromEmailObj:any = {};
  userEmailsConnectedWithAliases:any[] =[]
  from: any
  summernoteConfig: any = {}
  mediaArr:any[]=[];
  dropDownData:any=undefined
  dataToSend: any[] = []
  emailSignatureForEditor: any;
  isFullScreen: boolean = false;

  constructor(public commonUtils : CommonUtils,
    private dashboardUtils: DashboardUtilsService,
    private constantService: ConstantService,
    private modalService: NgbModal,
    private basicUtils:BasicUtils,
    private httpTransfer: HttpTransferService){}

  ngOnInit(): void {
    this.signatureId = uuidv4()
    if(Array.isArray(this.selectedEmail?.subject) && this.selectedEmail?.subject?.length) this.selectedEmail.subject = this.selectedEmail?.subject[0]
    this.dashboardUtils.getUpdatedDashboardJson().subscribe(res => {
      this.sprintArr = res[this.dashId]?.SPRINT || [];
    })
    this.setEmailValues()
    this.summernoteConfig = this.constantService.getSummerNoteConfig()
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if(changes.selectedEmailObj){
      
      this.setEmailValues()  
    }
    if(this.selectedEmailObj && this.isThreadView && this.selectedEmail?.smtp_thread_id)
      this.dataToSend = [this.isThreadView[this.selectedEmail['smtp_thread_id']]] || []
  }
  
  setEmailValues(){
    // console.log("111",this.selectedEmail,this.selectedEmailObj)
    this.from = this.selectedEmailObj?.from
    this.changeEmail()
    this.selectedEmail = this.selectedEmailObj?.selectedEmail
    let obj={
      'cc':this.selectedEmail?.cc,
      'bcc':this.selectedEmail?.bcc,
      'from':this.selectedEmail?.from,
      'to':this.selectedEmail?.to,
    }
    this.dropDownData=this.selectedEmailObj?.selectedEmail
    this.dropDownData['email_object']=obj
    console.log('sleected email is', this.selectedEmailObj)
    this.defaultSignatures = this.selectedEmailObj?.defaultSignatures
    this.usersEmailsAllowedToReply = this.selectedEmailObj?.usersEmailsAllowedToReply
    this.userEmailsConnectedWithAliases = this.selectedEmailObj?.userEmailsConnectedWithAliases
  }

  openPreviewPopup(index: number, template: TemplateRef<any>,selectedEmail) {
    console.log("selectedEmail",selectedEmail,this.leadInfo)
    this.mediaArr=[]
    let type=''
    selectedEmail.forEach(e=>{
      let obj={}
        obj['type'] = e?.type
        obj['name'] =  e?.file_name
        obj['url']  =  e?.url
        obj['path'] =  e?.path 
        obj['size_in_bytes'] =  e?.size_in_bytes?this.commonUtils.bytes_to_size(e?.size_in_bytes):0
        this.mediaArr.push(obj)
    })
    this.filePreviewModal = this.modalService.open(template, { size: 'lg' , windowClass: 'file preview-modal'}) 
    this.currentIndex = index;
    this.basicUtils.storeModlRef(this.filePreviewModal)
  }


  
  onFullview(){
    this.isFullScreen = !this.isFullScreen
  }

  initializeSummerNote(value?) {
    this.isFullScreen = false
    if(!this.from)
      this.from = this.selectedEmailObj?.from
      value = this.commonUtils.getSelectedEmailBody(value,this.selectedEmail,this.defaultSignatures,this.signatureId,this.signatureSeparator)
      if(value?.length){
        this.emailSignatureForEditor = value
      }
  }

  closeAllPopups(){
    this.replyAllPop?.close()
    this.replyPopover?.close()
    this.popEmail1?.close()
    this.popEmail2?.close()
    this.popEmailR3?.close()
  }

  changeEmail(event?){
    this.fromEmailObj = this.userEmailsConnectedWithAliases?.find(item => item.id == this.from)
  }
}