<div class="functionBody">
  <div class="functionheader">
     <div class="funcBtn">Functions</div>
     <div class="w-100 funcBtn" *ngIf="!automationCall">
        <div class="funcIcon" [ngClass]="functionType=='TEXT' ? 'selectedFunction' : 'funcIcon'" (click)="functionType='TEXT';filterFunction()"><app-dashboard-icon  [name]="'newText'" [iconJson]="lxAttributesIcons" [color]="functionType=='TEXT' ? '#fff' : '#353E4E'"></app-dashboard-icon></div>
        <div class="funcIcon" [ngClass]="functionType=='NUMBER' ? 'selectedFunction' : 'funcIcon'" (click)="functionType='NUMBER';filterFunction()"><app-dashboard-icon [name]="'newNum'" [iconJson]="lxAttributesIcons" [color]="functionType=='NUMBER' ? '#fff' : '#353E4E'"></app-dashboard-icon></div>
        <div class="funcIcon" [ngClass]="functionType=='DATE' ? 'selectedFunction' : 'funcIcon'" (click)="functionType='DATE';filterFunction()"><app-dashboard-icon [name]="'newDate'" [iconJson]="lxAttributesIcons" [color]="functionType=='DATE' ? '#fff' : '#353E4E'"></app-dashboard-icon></div>
        <div class="funcIcon" [ngClass]="functionType=='ARRAY' ? 'selectedFunction' : 'funcIcon'" (click)="functionType='ARRAY';filterFunction()"><app-dashboard-icon [name]="'arrayBraces'" [iconJson]="lxAttributesIcons" [color]="functionType=='ARRAY' ? '#fff' : '#353E4E'"></app-dashboard-icon></div>
      </div>
  </div>
  <div class="w-100 funcTypeHead">
    <div class="labels" *ngIf="!searchActive">
    <label class="m-0" *ngIf="functionType=='TEXT'">String Functions</label>
    <label class="m-0" *ngIf="functionType=='NUMBER'">Number Functions</label>
    <label class="m-0" *ngIf="functionType=='DATE'">Date Functions</label>
    <label class="m-0" *ngIf="functionType=='ARRAY'">Array Functions</label>
    </div>
    <div class="labels" *ngIf="searchActive">
      <input type="text" class="searchBox" placeholder="Search" #inputBox (input)="searchFunc(inputBox.value)"/>
    </div>
    <div class="search-icon">
      <i class="fa fa-search" *ngIf="!searchActive" (click)="searchActive=true;"></i>
      <i class="fa fa-close" *ngIf="searchActive" (click)="searchFunc('');searchActive=false;"></i>
    </div>
  </div>
  <div class="funcList" *ngIf="showFunctionList">
    <ng-container *ngFor="let function of filterFunctionList">
       <div class="funcRow" (click)="getFunctionsEmit.emit(function)">
        <ng-template #functooltip>
          <div class="funcRowTooltip">
            <div class="funcDes">{{function?.long_description}}</div>
            <div class="funcExample">E.g. {{function?.example}}</div>   
          </div>
        </ng-template>
         <div class="w-100 funcName">{{function?.name}}</div>
         <div class="funcDes" [ngbTooltip]="functooltip" placement="right" container="body" tooltipClass="tooltipPopup">{{function?.short_description}}</div>
         <div class="funcExample">E.g. {{function?.example}}</div>
       </div>
    </ng-container>
  </div>
</div>