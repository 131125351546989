import { CommonModule } from "@angular/common";
import { ComponentFactoryResolver, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppViewComponent } from "./app-view.component";

@NgModule({
    declarations: [AppViewComponent],
    exports: [AppViewComponent],
    imports: [
      CommonModule,
      TranslateModule
    ]
  })
  export class AppViewModule {
    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
    getComponent() {
      return this.componentFactoryResolver.resolveComponentFactory(
        AppViewComponent
      );
    }
   }