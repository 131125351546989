import {Directive,ElementRef, Input} from "@angular/core";
import { KeyboardMonitorService } from "./services/keyboardMonitor.service";

@Directive({
  selector: "[keyboardMonitor]",
})
export class KeyboardMonitorDirective{
  constructor(private elementRef: ElementRef,private keyboardMonitor:KeyboardMonitorService,) {}
  @Input() tagPreference:any='auto'
  ngOnInit(){
    this.keyboardMonitor.addKeyboardMonitoring(this.elementRef,this.tagPreference)
  }
  ngOnDestroy(){
    this.keyboardMonitor.removeKeyboardMonitoring(this.elementRef)
  }

}
