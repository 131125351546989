import { Directive, ElementRef, Renderer2, AfterViewInit, HostListener } from '@angular/core';

@Directive({
  selector: '[appTwoLineEllipsis]'
})
export class TwoLineEllipsisDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const isWebkitLineClampSupported = 'webkitLineClamp' in document.body.style;

    if (isWebkitLineClampSupported) {
      //webkit applied browser
      this.renderer.setStyle(this.el.nativeElement, 'display', '-webkit-box');
      this.renderer.setStyle(this.el.nativeElement, '-webkit-line-clamp', '2');
      this.renderer.setStyle(this.el.nativeElement, '-webkit-box-orient', 'vertical');
      this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
      this.renderer.setStyle(this.el.nativeElement, 'text-overflow', 'ellipsis');
      this.renderer.setStyle(this.el.nativeElement, 'white-space', 'normal');
    } else {
      // mozilla and other 
      this.truncateText();
    }
  }

  truncateText() {
    const element = this.el.nativeElement;
    const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
    const maxHeight = lineHeight * 2; // For 2 lines
    let text = element.innerText;
    // trim the text until it fits within the maxHeight
    while (element.scrollHeight > maxHeight) {
      text = text.slice(0, -1);
      element.innerText = text + '...';
    }
  }

  // Adjust text on window resize
  // @HostListener('window:resize')
  // onResize() {
  //   this.truncateText();
  // }
}