
<div class="date-calender" #calenderViewport>
   <div *ngIf="showHeader"  class="f-s-r" [ngClass]="{'date-picker-header':(type!=='Appointmentform' && type!=='workSchedule'),'date-picker-appointment-header':type==='Appointmentform',}">
      <ng-container *ngIf="!startEndDatePicker">
         <ng-container *ngTemplateOutlet="dateContainer;context:{key:activeDateField,emptyText:'Date',dateIndex:0}"></ng-container>
      </ng-container>
      <ng-container *ngIf="startEndDatePicker">
         <ng-container *ngTemplateOutlet="dateContainer;context:{key:'from',emptyText:'Start date',dateIndex:0}"></ng-container>
         <div class="partision-bar"></div>
         <ng-container *ngTemplateOutlet="dateContainer;context:{key:'to',emptyText:'End date',dateIndex:1}"></ng-container>
      </ng-container>
   </div>
   <div class="date-picker-body" [ngClass]="{'date-picker-body':type!=='Appointmentform','date-appointment-picker-body':type==='Appointmentform'}">
      <ng-container *ngIf="calenderView=='Day'">
         <ng-container *ngTemplateOutlet="dayCalender"></ng-container>
      </ng-container>
      <ng-container *ngIf="calenderView=='Year'">
         <ng-container *ngTemplateOutlet="yearCalender"></ng-container>
      </ng-container>
      <ng-container *ngIf="calenderView=='Month'">
         <ng-container *ngTemplateOutlet="monthCalender"></ng-container>
      </ng-container>
   </div>
   <div *ngIf="showClearOrSaveBtn" class="date-picker-footer f-l-r">
      <div class="cancel-date-btn" [class.key-active]="portionIndex==3 && selectionIndex==1" (click)="onClear()">Clear</div>
      <div class="partision-bar"></div>
      <div class="save-date-btn" [class.key-active]="portionIndex==3 && selectionIndex==0" (click)="onSave()">Save</div>
   </div>
</div>

<ng-template #dayCalender>
   <div class="calender-header">
      <div class="left-wrapper d-flex">
         <span class="calender-month f-l-r mr-1 p-1 calender-hover-effect" [class.key-active]="portionIndex==1 && selectionIndex==-1" (click)="selectedYear=currentFocusMonthYear.year;calenderView='Month'">{{months[currentFocusMonthYear?.month]}}</span>
         <span (click)="yearList(2005)" class="year-selection-btn"  [class.key-active]="portionIndex==1 && selectionIndex==0">{{currentFocusMonthYear?.year}}</span>
      </div>
      <div class="right-wrapper" *ngIf="type!=='workSchedule'">
         <div class="today-date-btn f-s-r" (click)="goToCurrentDate()"  [class.key-active]="portionIndex==1 && selectionIndex==1">
            Today
         </div>
         <div class="change-buttons">
            <span class="arrow-btn" (click)="changePreviousOrNextMonth(-1)"  [class.key-active]="portionIndex==1 && selectionIndex==2"><i class="fas fa-chevron-left"></i></span>
            <span class="arrow-btn" (click)="changePreviousOrNextMonth(1)"  [class.key-active]="portionIndex==1 && selectionIndex==3"><i class="fas fa-chevron-right"></i></span>
         </div>
      </div>
   </div>
   <div class="calender-weeks">
      <ng-container *ngFor="let week of weeks">
         <day class="weekday-box">
            <span>{{week}}</span>
         </day>
      </ng-container>
   </div>
   <div class="calender-date">
      <ng-container *ngFor="let date of dates;let index=index">
         <div class="date-box" 
         [ngClass]="{
         'current-date':date?.monthType =='CurrentMonth' && currentDate.getDate()==date.day && currentDate.getMonth()==currentFocusMonthYear?.month && currentDate.getFullYear()==currentFocusMonthYear?.year,
         'selected-date':isSelectedDate(date,'from') || isSelectedDate(date,'to'),
         'non-current-month-date':date?.monthType !='CurrentMonth',
         'minOrMax-date-validation':isDateDisabled(date),
         'date-in-range':isDateBetweenInRange(date),
         'key-active':portionIndex==2 && selectionIndex==index 
         }"
         (mouseenter)="portionIndex==2 && selectionIndex=index"
         (click)="!isDateDisabled(date) && onSelect(date,$event)">
            <span class="date-text">
               {{date.day}}
            </span>
         </div>
      </ng-container>
   </div>
   <div class="allDayBox" *ngIf="showAllDay">
      <div class="flex"><input type="checkbox" [(ngModel)]="dateConfig.allDay" (ngModelChange)="setTimeForAllDay()"/></div>
      <div class="flex">All Day</div>
   </div>
</ng-template>

<ng-template #yearCalender>
   <div class="calender-header">
      <div class="left-wrapper">
         <span class="calender-month f-l-r" *ngIf="yearsRange?.length>0">{{yearsRange[0]+'-'+yearsRange[yearsRange?.length-1]}}</span>
      </div>
      <div class="right-wrapper">
         <div class="change-buttons">
            <span class="arrow-btn" *ngIf="yearsRange?.length>0 && yearsRange[0]!=1900" [class.key-active]="portionIndex==1 && selectionIndex==0" (click)="yearList(yearsRange[0]-35)"><i class="fas fa-chevron-left"></i></span>
            <span class="arrow-btn"  [class.key-active]="portionIndex==1 && selectionIndex==1" (click)="yearList(yearsRange[yearsRange?.length-1])" ><i class="fas fa-chevron-right"></i></span>
         </div>
      </div>
   </div>
   <div class="calender-date">
      <ng-container *ngFor="let year of yearsRange;let index=index">
         <div class="year-box" 
         [ngClass]="{'selected-date':currentFocusMonthYear.year==year,'key-active':portionIndex==2 && selectionIndex==index}"
         (mouseenter)="portionIndex==2 && selectionIndex=index"
         (click)="selectedYear=year;calenderView='Month'">
            <span class="date-text">
               {{year}}
            </span>
         </div>
      </ng-container>
   </div>
</ng-template>

<ng-template #monthCalender>
   <div class="calender-header">
      <div class="left-wrapper">
         <span class="calender-month f-l-r">{{selectedYear}}</span>
      </div>
      <div class="right-wrapper">
         <div class="change-buttons">
            <span class="arrow-btn" *ngIf="selectedYear>1900" [class.key-active]="portionIndex==1 && selectionIndex==0" (click)="selectedYear=selectedYear-1"><i class="fas fa-chevron-left"></i></span>
            <span class="arrow-btn" [class.key-active]="portionIndex==1 && selectionIndex==1" (click)="selectedYear=selectedYear+1"><i class="fas fa-chevron-right"></i></span>
         </div>
      </div>
   </div>
   <div class="calender-date">
      <ng-container *ngFor="let month of months;let index=index">
         <div class="year-box"  style="width: 80px;"
         [ngClass]="{'selected-date':currentFocusMonthYear.month==month,'key-active':portionIndex==2 && selectionIndex==index}"
         (mouseenter)="portionIndex==2 && selectionIndex=index"
         (click)="daysHandle(index,selectedYear);changeMonthYear(index)">
            <span class="date-text">
               {{month}}
            </span>
         </div>
      </ng-container>
   </div>
</ng-template>

<ng-template #dateContainer let-key="key" let-emptyText="emptyText" let-dateIndex="dateIndex">
   <div class="from-date" [class.active-date]="activeDateField==key && startEndDatePicker" [class.key-active]="portionIndex==0 && selectionIndex==dateIndex" (click)="startEndDatePicker && activeDateField=key;selectedDateRange[key]?.date && daysHandle(selectedDateRange[key]?.month,selectedDateRange[key]?.year)">
      <ng-container *ngIf="(!selectedDateRange[key] || selectedDateRange[key] && !selectedDateRange[key]?.date) && type!=='Appointmentform' && type!=='workSchedule'">
         <span >
            <img width="15" height="15" src="../../../assets/common-use-icon-20dp/calendar-6d6e6f.svg" >
         </span>
         <span>{{emptyText}}</span>
      </ng-container>
      <ng-container *ngIf="selectedDateRange[key] && selectedDateRange[key]?.date && type!=='Appointmentform' && type!=='workSchedule'">
         <span>
            {{selectedDateRange[key]?.date+'/'+(selectedDateRange[key]?.month+1)+'/'+selectedDateRange[key]?.year}}
         </span>
         <ng-container *ngIf="enableTimeEditor">
            <span *ngIf="selectedDateRange[key]?.date && !selectedDateRange[key]?.isTimeAdded" class="remove-date" (click)="removeDate(key)">
               <i class="fas fa-close p-0"></i>
            </span>

            <span *ngIf="selectedDateRange[key]?.date && selectedDateRange[key]?.isTimeAdded">
               {{'at'}}
            </span>
            <span *ngIf="selectedDateRange[key]?.date" class="add-time" [class.active-add-time]="timeSuggestionRef?.isOpen()" [ngbPopover]="timeSuggestionPopover"  #timeSuggestionRef="ngbPopover" [autoClose]="'outside'"  [placement]="['bottom','auto']" popoverClass="time-suggestion" triggers="manual">
               <input placeholder="Add Time" #timeInput (click)="!timeSuggestionRef?.isOpen() && generateTimeSuggestion(timeInput?.value);timeSuggestionRef?.open();timeSuggestionPopupRef=timeSuggestionRef" (input)="generateTimeSuggestion(timeInput?.value);!timeSuggestionRef?.isOpen() && timeSuggestionRef?.open();timeSuggestionRef?.isOpen() && timeSuggestionPopupRef=timeSuggestionRef" [(ngModel)]="selectedDateRange[key].timeSearchTxt" (blur)="selectedDateRange[key].timeSearchTxt=selectedDateRange[key]?.isTimeAdded?.timeString"/>
            </span>
            <ng-template #timeSuggestionPopover>
               <div class="time-container">
                  <ng-container *ngFor="let time of timeSuggestion;let index=index">
                     <div class="time-value" [class.key-active]="portionIndex==4 && selectionIndex==index" (mouseenter)="selectionIndex=index" (click)="onTimeSet(time,key,index);timeSuggestionPopupRef?.close()">
                        {{time?.timeString}}
                     </div>
                  </ng-container>
               </div>
            </ng-template>
         </ng-container>
         <span *ngIf="selectedDateRange[key]?.date && (selectedDateRange[key]?.isTimeAdded || !enableTimeEditor)" class="remove-date" (click)="removeDate(key)">
            <i class="fas fa-close p-0"></i>
         </span>
      </ng-container>
   </div>
</ng-template>
