<div class="leadDependency-container">
  <div class="row dependsTabs m-0">
    <div [ngClass]="isActive?'tabIsActive':''" class="col-md-6 text-center dependsTabItem MontserratFont"
      (click)="onDependsOnClick()">{{'editcustom.blocking' | translate}}</div>
    <div [ngClass]="!isActive?'tabIsActive':''" class="col-md-6 text-center dependsTabItem MontserratFont"
      (click)="onDependentItemsClick()">{{'editcustom.blockedBy' | translate}}</div>
  </div>
  <div class="dependsInput">
    <input class="border-0 pb-0 addInput MontserratFont" id="dependsOnInput" #searchInput
      (input)='validateLetters(searchInput.value)' type='text' placeholder="{{'editcustom.addInputorItem' | translate}}">
  </div>
  <ng-container *ngIf='noResponse'>
    <div class="alert alert-info" role="alert">
      {{'editcustom.noRecordFound' | translate}}
    </div>
  </ng-container>
  <ng-container *ngIf="searchLeadList.length>0 && searchInput.value !== ''">
    <div class="leadListWrapper">
      <ng-container *ngFor="let item of searchLeadList;let i=index">
        <div class="row leadList" (click)="addItem(item,isActive)" [ngClass]="{'ubrdr': item.priority == 3, 'hbrdr' : item.priority == 2, 'mbrdr' : item.priority == 1, 'lbrdr' : item.priority == 0}">
              <div class="col-md-1 pl-0">
                <span *ngIf="item.priority==0" class="prioritylabelL">L</span>
                <span *ngIf="item.priority==1" class="prioritylabelM">M</span>
                <span *ngIf="item.priority==2" class="prioritylabelH">H</span>
                <span *ngIf="item.priority==3" class="prioritylabelU">U</span>
              </div>
          <div class="col-md-0 pl-0 m-auto">
            <div class="leadTitle MontserratFont">#{{item.seq_id}}</div>
           
          </div>
          <div class="col-md-5 pl-0 m-auto" title="{{item.title}}">
          <p class="leadDescription MontserratFont mb-0">{{item.title | truncate : 28}}</p>
          </div>
          <div class="col-md-3 pl-0 m-auto">
            <div class="tableDiv">
              <ngx-avatars class="mr-1" [name]="orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'" [src]="orgUsersJson[item.assigned_to]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                <span>{{orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'}}</span>
            </div>
          </div>
          <ng-container *ngFor="let bucket of bucketOfCorrespondingDash">
            <ng-container *ngIf="bucket._id==item.bucket_id">
              <div class="col-md-2 pl-0 m-auto MontserratFont">{{bucket.name  | truncate : 7}}</div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container> 
  <ng-container *ngIf="isActive">
    <ng-container *ngIf="!dependsOnLeadsArrStatus">
      <div class="leadListWrapper">
        <ng-container *ngFor="let item of dependsOnLeadsArr;let i=index">
          <div class="row leadList" [ngClass]="{'ubrdr': item.priority == 3, 'hbrdr' : item.priority == 2, 'mbrdr' : item.priority == 1, 'lbrdr' : item.priority == 0}">
            <div class="col-md-1 pl-0 m-auto">
              <span *ngIf="item.priority==0" class="prioritylabelL">L</span>
              <span *ngIf="item.priority==1" class="prioritylabelM">M</span>
              <span *ngIf="item.priority==2" class="prioritylabelH">H</span>
              <span *ngIf="item.priority==3" class="prioritylabelU">U</span>
            </div>
            <div class="col-md-0 pl-0 m-auto" (click)="onNavigateToEditLeadPage(item)">
              <div class="leadTitle">{{item.seq_id}}</div>
              
            </div>
            <div class="col-md-4 pl-0 m-auto" title="{{item.title}}">
            <p class="leadDescription mb-0">{{item.title | truncate : 20}}</p>
          </div>
            <div class="col-md-3 pl-0 m-auto">
              <div class="tableDiv">
                <ngx-avatars class="mr-1" [name]="orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'" [src]="orgUsersJson[item.assigned_to]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                <span>{{orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'}}</span>
              </div>
            </div>
            <ng-container *ngFor="let bucket of bucketOfCorrespondingDash">
              <ng-container *ngIf="bucket._id==item.bucket_id">
                <div class="col-md-2 pl-0 m-auto MontserratFont">{{bucket.name | truncate : 7}}</div>
              </ng-container>
            </ng-container>
            <div class="col-md-1 px-0 m-auto cursorPointer" (click)="removeDependsOnItem(i)">
              <!-- <img class="checkListIcon" src="../../../assets/1x/checklist-remove-ico.svg" alt=""> -->
              &times;  
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="dependsOnLeadsArrStatus">
      <div class="leadListWrapper">
        <ng-container *ngFor="let item of dependsOnLeadsArr;let i=index">
          <div class="row leadList" [ngClass]="{'ubrdr': item.priority == 3, 'hbrdr' : item.priority == 2, 'mbrdr' : item.priority == 1, 'lbrdr' : item.priority == 0}">
            <div class="col-md-1 pl-0 m-auto">
              <span *ngIf="item.priority==0" class="prioritylabelL">L</span>
              <span *ngIf="item.priority==1" class="prioritylabelM">M</span>
              <span *ngIf="item.priority==2" class="prioritylabelH">H</span>
              <span *ngIf="item.priority==3" class="prioritylabelU">U</span>
            </div>
            <div class="col-md-0 pl-0 m-auto" (click)="onNavigateToEditLeadPage(item)">
              <div class="leadTitle">{{item.seq_id}}</div>
              
            </div>
            <div class="col-md-4 pl-0 m-auto" title="{{item.title}}">
            <p class="leadDescription mb-0">{{item.title | truncate : 20}}</p>
          </div>
            <div class="col-md-3 pl-0 m-auto">
              <div class="tableDiv">
                <ngx-avatars class="mr-1" [name]="orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'" [src]="orgUsersJson[item.assigned_to]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                <span>{{orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'}}</span>
              </div>
            </div>
            <ng-container *ngFor="let bucket of bucketOfCorrespondingDash">
              <ng-container *ngIf="bucket._id==item.bucket_id">
                <div class="col-md-2 pl-0 m-auto MontserratFont">{{bucket.name | truncate : 7}}</div>
              </ng-container>
            </ng-container>
            <div class="col-md-1 m-auto" (click)="removeDependsOnItem(i)"><img class="checkListIcon"
                src="../../../assets/1x/checklist-remove-ico.svg" alt="">
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container> 

  <ng-container *ngIf="!isActive">
    <ng-container *ngIf="!dependsOnLeadsArrStatus">
      <ng-container *ngFor="let item of dependentsLeadsArr;let i=index">
        <div class="row leadList" [ngClass]="{'ubrdr': item.priority == 3, 'hbrdr' : item.priority == 2, 'mbrdr' : item.priority == 1, 'lbrdr' : item.priority == 0}">
          <div class="col-md-1 pl-0 m-auto">
            <span *ngIf="item.priority==0" class="prioritylabelL">L</span>
            <span *ngIf="item.priority==1" class="prioritylabelM">M</span>
            <span *ngIf="item.priority==2" class="prioritylabelH">H</span>
            <span *ngIf="item.priority==3" class="prioritylabelU">U</span>
          </div>
          <div class="col-md-0 pl-0 m-auto" (click)="onNavigateToEditLeadPage(item)">
            <div class="leadTitle MontserratFont">{{item.seq_id}}</div>
            
          </div>
          <div class="col-md-4 pl-0 m-auto" title="{{item.title}}">
          <span class="leadDescription MontserratFont">{{item.title | truncate : 20}}</span>
        </div> 
          <div class="col-md-3 pl-0 m-auto">
            <div class="tableDiv">
              <ngx-avatars class="mr-1" [name]="orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'" [src]="orgUsersJson[item.assigned_to]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                <span>{{orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'}}</span>
            </div>
          </div>
          <ng-container *ngFor="let bucket of bucketOfCorrespondingDash">
            <ng-container *ngIf="bucket._id==item.bucket_id">
              <div class="col-md-2 pl-0 m-auto MontserratFont">{{bucket.name  | truncate : 7}}</div>
            </ng-container>
          </ng-container>
          <div class="col-md-1 m-auto" (click)="removeDependentsItem(i)"><img class="checkListIcon"
              src="../../../assets/1x/checklist-remove-ico.svg" alt="">
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="dependsOnLeadsArrStatus">
      <ng-container *ngFor="let item of dependentsLeadsArr;let i=index">
        <div class="row leadList" [ngClass]="{'ubrdr': item.priority == 3, 'hbrdr' : item.priority == 2, 'mbrdr' : item.priority == 1, 'lbrdr' : item.priority == 0}">
          <div class="col-md-1 pl-0 m-auto">
            <span *ngIf="item.priority==0" class="prioritylabelL">L</span>
            <span *ngIf="item.priority==1" class="prioritylabelM">M</span>
            <span *ngIf="item.priority==2" class="prioritylabelH">H</span>
            <span *ngIf="item.priority==3" class="prioritylabelU">U</span>
          </div>
          <div class="col-md-0 pl-0 m-auto" (click)="onNavigateToEditLeadPage(item)">
            <div class="leadTitle MontserratFont">{{item.seq_id}}</div>
            
          </div>
          <div class="col-md-4 pl-0 m-auto" title="{{item.title}}">
          <span class="leadDescription MontserratFont">{{item.title | truncate : 20}}</span>
          </div>
          <div class="col-md-3 pl-0 m-auto">
            <div class="tableDiv">
              <ngx-avatars class="mr-1" [name]="orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'" [src]="orgUsersJson[item.assigned_to]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                <span>{{orgUsersJson[item.assigned_to]?.name ? orgUsersJson[item.assigned_to]?.name : 'Assign'}}</span>
            </div>
          </div>
          <ng-container *ngFor="let bucket of bucketOfCorrespondingDash">
            <ng-container *ngIf="bucket._id==item.bucket_id">
              <div class="col-md-2 pl-0 m-auto MontserratFont">{{bucket.name | truncate : 7}}</div>
            </ng-container>
          </ng-container>
          <div class="col-md-1 m-auto" (click)="removeDependentsItem(i)"><img class="checkListIcon"
              src="../../../assets/1x/checklist-remove-ico.svg" alt="">
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container> 
</div>