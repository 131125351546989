<link href="https://cdn.jsdelivr.net/npm/quill@2.0.2/dist/quill.snow.css" rel="stylesheet" />


<div class="comtainer notesComponent " [ngClass]="{'con' : itemCall || bottomBarCall || noteLink,'d-block' : noteLink, 'noteFullView': noteWebLink}">
    <!-----Notes Left Header-------->
  <div class="leftPanel f-s-r" *ngIf="itemCall && !activeEdit || !itemCall && activeEdit && !bottomBarCall && !noteLink && !noteWebLink" [ngClass]="{'w-100' : itemCall}">  
    <div class="left-header" *ngIf="!itemCall" [ngClass]="{'tophead' : itemCall}">
      <div class="lefteHeaderTitle">
        <div class="noteimg">
          <img src="../../../assets/img/icons/Notes-icon.svg" />
        </div>
        <div class="note-header">
          <span class="f-xxxl-b">{{'noteView.notes' | translate}}</span>
          <p class="count">{{countNotes}} {{'noteView.notes' | translate}}</p>
        </div>
      </div>
      <div class="rightbtn">
        <button class="addnote" (click)="createdNote(); onSelect(null,null)">{{'common.add' | translate}}</button>
      </div>
    </div>

    <div class="left-header" *ngIf="itemCall && !activeEdit" [ngClass]="{'tophead' : itemCall}">
      <span class="addNoteInItem" [ngClass]="{'fixedHover': optionsPopover?.isOpen()}" [ngbPopover]="connectOption" #optionsPopover="ngbPopover" placement="bottom-right" container="body" [autoClose]="'false'"><img src="../../../assets/img/icons/Notes-icon.svg" class="newHead"/>{{'noteView.addNote' | translate}}</span>
      <ng-template #connectOption>
        <a class="dropdown-item f-s-r" (click)="createdNote(); onSelect(null,null)">Add New</a>
        <a class="dropdown-item f-s-r" (click)="openConnectNotes(connectExisting)">Connect existing</a>
      </ng-template>
      <ng-template #connectExisting let-modal>
        <div class="modal-header border-bottom" style="height: 50px;background-color: #e1ebee;position:sticky;">
          <i class="fa fa-long-arrow-left" style="font-size:38px" aria-hidden="true" (click)="modal.dismiss()"></i>
          <h4 class="modal-title ml-4" id="modal-basic-title">Connect Existing Notes</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div *ngIf="itemCall && !activeEdit || !itemCall && activeEdit" [ngClass]="{'activeItem' : itemCall}" style="overflow-y: scroll;">
          <div *ngFor="let data of displayConnectNotes" class="row2 noteField">
            <button class="noteConnect btn" (click)="connectExixtingNote(data);modal.dismiss()">Connect</button>
            <p class="f-l-b">{{data.title}}</p>
            <p>{{data.note_text | quillDeltaText}}</p>
          </div>
        </div>
      </ng-template> 
    </div>

    <div class="content-left p-0" *ngIf="itemCall && !activeEdit || !itemCall && activeEdit" [ngClass]="{'activeItem' : itemCall}">
      <div *ngIf="requestPending" class="w-100">
        <app-shimmer-loader [count]="5" [width]="'100%'" [height]="86" [type]="'Table'" [header]="false" [width]="'100%'"></app-shimmer-loader>
      </div>
      <div *ngIf="notes.length">
        <div *ngFor="let data of notes" class="row2 pr-1" 
          [ngClass]="{'active' : data._id == selectedNoteId}" (click)="(data._id != selectedNoteId) && showEditor(data,data._id)">
          <div class="vertical">
            <p class="f-l-b"  >{{data?.title}}</p>
            <i *ngIf="data._id == selectedNoteId && personalCall" class="mr-2 fa fa-close optionSelection" (click)="$event.stopPropagation();open(content)"></i>
          </div>
          <p style="line-height: 1.5em;
          height: 3em;
          overflow: hidden;" >{{data.note_text | quillDeltaText}} </p>
        </div>
      </div>
      <div *ngIf="!notes.length" style="text-align:center;">
        <div class="empty">
        <app-dashboard-icon class="iconBox-16" [name]="'Empty'" [size]="20" [iconJson]="commonIcons"></app-dashboard-icon>
        <h5 class="nonotes">{{'noteView.noNotesText' | translate}}</h5>
      </div>
      </div>
    </div>
  </div>  
    
    <!------Notes Right Side Header---------->
  <div class="overflow-hidden noteRightBox" *ngIf="personalCall || activeEdit">  
    <div class="rightPanel" *ngIf="personalCall || selectedNoteId && noteInitialized && activeEdit" >
      <ng-template *ngTemplateOutlet="rightSideContent"></ng-template>
    </div>
  </div>
</div>

<div *ngIf="!noteLink && !noteWebLink" class="showMobileViewMsg">
  <p> For better user experience open in wide screen.</p>
</div>

<ng-template #rightSideContent>
  <div class="right-header f-s-r notes-header-box" *ngIf="personalCall || selectedNoteId && noteInitialized && activeEdit && !bottomBarCall && !noteLink && !noteWebLink || selectedNoteId && (noteWebLink || noteLink || bottomBarCall || itemCall && activeEdit)" [ngClass]="{'rightActive' : itemCall}">
    <div *ngIf="noteWebLink" class="backHome" (click)="backToHome()"><span class="homeTab"><app-dashboard-icon [name]="'home'" size="20" [iconJson]="navBarIcons"></app-dashboard-icon><span class="f-xl-m homeText">{{'left-navbar.home' | translate}}</span> </span></div>
    <div *ngIf="noteWebLink" class="partition-home-note"><div class="partition-note-bar"></div></div>
    <span *ngIf="itemCall && !isFullScreen && !noteLink && !noteWebLink" class="iconBox-30 optionDetailBox" (click)="hideEditor()"><app-dashboard-icon [name]="'Back'" [size]="20" [iconJson]="attributesIcons"></app-dashboard-icon></span>
    <input [ngStyle]="{'width':(noteWebLink) ? '80%' : '85%'}" type="text" [ngClass]="noteWebLink ? 'f-xxxl-b' : 'f-xxl-b'" *ngIf="selectedNoteId" placeholder="Title" value="{{title}}" [maxlength]="40" [(ngModel)]="title" (blur)="getTitleText()"/>
    <ng-container *ngTemplateOutlet="rightOptionTemplate"></ng-container>
  </div>
  <!-- <div *ngIf="selectedNoteId && itemCall && activeEdit" [ngClass]="{'fullScreenInput' : isFullScreen}" class="notes-header-box" style="height: 40px;" [ngStyle]="{'border-bottom': (bottomBarCall || noteLink) ? '1px solid #e1e1e1' : 'none', 'padding-bottom': (bottomBarCall || noteLink) ? '8px' : '0'}">
    <input type="text" placeholder="Title" value="{{title}}" [maxlength]="40" [(ngModel)]="title" (blur)="getTitleText()" style="border: none; width: calc(100% - 80px);"/>
</div> -->

  <div class="content-right" [ngStyle]="{'border':(noteWebLink) ? 'none' : '1px solid #e1e1e1 !important'}" [class.qlc-hide-toolbar]="hideToolbar" *ngIf="personalCall || selectedNoteId && noteInitialized && activeEdit" [ngClass]="{'editor' : (itemCall && !isFullScreen),'editorOnFullScreen' : (itemCall && isFullScreen),'setForLink' : noteLink}">
    <notes-editor class="notes-editor-part" *ngIf="noteInitialized" 
      textAssist [optionFields]="{'suggestions': leadId && !isReadOnly ? [] : ['AiAssist'],'aiSuggestions':[],'editorOptions':[]}" [appendTo]="'body'" (onOptionSelect)="handledTextAssist($event)"
      [selectedNoteId]="selectedNoteId" [selectedNoteTitle]="title" (getAllDashBoardNotes)="getAllDashBoardNotes()" 
      (updateNotesList)="updateNotesList($event)"
      (onDataPreview)="openModalForPreview($event,videoPlayerModalNested)"
      (updatedNoteText)="updatedNoteText($event)" >
    </notes-editor>

    <!-- connected boards and items-->
    <div style="border-top: 1px solid #000;" *ngIf="selectedBoards.length">
      <div class="p-3">
        <span>Connected To: </span>
        <span *ngFor="let data of selectedBoards">
          <span class="connectedName"><span class="iconBox-20 boardIconBox" [ngStyle]="{'background-color': data?.color || commonUtils.getCurrentThemeColor()}"><i [class]="data?.icon" class="faicon"></i></span><span class="ml-1">{{data.dashboard_name}}</span></span>
        </span>
        <span *ngFor="let data of selectedItems">
          <span class="connectedName">#{{data.seq_id}}: {{data.title}}</span>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fullScreenNote let-modal>
  <div  class="fullScreenModal h-100 w-100 preview-modal-body d-flex flex-column">
    <ng-template *ngTemplateOutlet="rightSideContent"></ng-template>
  </div>
</ng-template>
  
  <!-------Show Information of notes--------->
  <ng-template #info>
    <div class="info f-s-r">
      <label class="noteheading f-l-b">{{'noteView.noteInfo' | translate}}</label>
      <div class="noteInfoDetail">
        <span class="detailRow">
          <b class="leftHeading">{{'common.createdDate' | translate}}</b> <span class="rightDetail">: {{createDate}}</span>
        </span>
        <span class="detailRow">
          <b class="leftHeading">{{'common.createdBy' | translate}}</b> <span class="rightDetail">: {{createdBy}}</span>
        </span>
        <span class="detailRow">
          <b class="leftHeading">{{'noteView.lastUpdatedBy' | translate}}</b> <span class="rightDetail">: {{lastUpdatedBy}}</span>
        </span>
        <span class="detailRow">
          <b class="leftHeading">{{'noteView.lastUpdated' | translate}}</b> <span class="rightDetail">: {{lastUpdatedDate}}</span>
        </span>
      </div>
    </div>
  </ng-template>

  <!-----------Show History of Note ------->
  <ng-template #history>
    <div *ngIf="!showHistory" class="viewHistoryBox f-m-r">
      <div class="view-history">
        <div class="row3">
          <Label class="historyHeading f-l-b">{{'noteView.viewHistoryVersion' | translate}}</Label>
        </div>
        <div *ngIf="notesHistoryArray.length">
          <div class="row3" *ngFor="let note of notesHistoryArray" (click)="selectHistory(note)" >
            <p>{{note.last_update_date}} , {{note.last_update_time}} by {{note.last_updated_name}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="!notesHistoryArray.length">
        <div class="row3">
          <p>{{'noteView.noHistoryText' | translate}}</p>
        </div>
      </div>
    </div>

    <div *ngIf="showHistory" class="viewHistoryBox">
      <div class="show">
        <div class="row3">
          <Label class="noteTitlteHistory">{{noteHistoryDetails.title}}</Label>
        </div>
        <div class="row3" style="width:100%;height:200px;overflow-y: scroll;">
          <p  [innerHTML]="noteHistoryDetails.note_text"></p>
        </div>
        <div class="row3" style="width:100%;height:25px;border:none;">
          <button class="btn preebtn" style="margin-left:60%;margin-right:auto;" (click)="selectHistory(note)">{{'common.back' | translate}}</button>
          <button class="btn preebtn"
            (click)="updateHistory(noteHistoryDetails); historyPopover.close();">{{'common.restore' | translate}}</button>
        </div>
      </div>
    </div>
  </ng-template>

<!---------Share note with User Pop-over------------>
  <ng-template #private>
    <div *ngIf="!searchActive" class="f-m-r">
      <div class="all-user">
        <div class="row searchUserBox">
          <div *ngIf="!selectAllUser" class="searchIconBox">
            <div class="searchButtonIco">
              <i class="fa fa-search" (click)="selectedSearch()"></i>
            </div>
          </div>
          <div class="selectBoardUser" *ngIf="!selectAllUser">
            <input type="checkbox" #alluser (click)=selectAllDashUser(alluser.value) />
            <label style="margin: 0;"> {{'noteView.allDashboardUsers' | translate}}</label>
          </div>
          <div class="selectBoardUser" *ngIf="selectAllUser">
            <input type="checkbox" #alluser (click)=makePrivateNote() checked="true" />
            <label style="margin: 0;"> {{'noteView.allDashboardUsers' | translate}}</label>
          </div>
        </div>
        <div *ngIf="!selectAllUser" class="selectedUserBox">
          <div class="userDetailBox" *ngFor="let login of loginUser">
            <div class="userNameBox">
              <ngx-avatars class="mr-1" [name]="login.name" [src]="login.image_url" size="30" initialsSize="2" ></ngx-avatars>
              <div class="" style="color: #2E4765;">
                <label class="loginLabel m-0 f-m-b"> {{login.name}}</label>
                <p class="loginp m-0 f-xs-l">{{login.email}}</p>
              </div>
            </div>
            <div class="">
              <label class="exit" (click)="open(privateNote,null)">{{'noteView.exit' | translate}}</label>
            </div>
          </div>
          <div class="userDetailBox" *ngFor="let user of selectedDashUsersList">
            <div class="userNameBox">
              <ngx-avatars class="mr-1" [name]="user.name" [src]="user.image_url" size="30" initialsSize="2" ></ngx-avatars>
              <div class="" style=" color: #2E4765;">
                <label class="username m-0"> {{user.name}}</label>
                <p class="useremail m-0">{{user.email}}</p>
              </div>
            </div>
            <div class="">
              <i class="fa fa-close" (click)="removeSharedUserOfNote(user)"></i>
            </div>
        </div>
        </div>
      </div>
    </div>
    <div *ngIf="searchActive">
      <div class="user-content">
        <div class="row searchUserBox" style="background:  #DDD 0% 0% no-repeat padding-box;">
          <div class="backIcon px-2" style="width:10%;" role="button" (click)="selectedSearch()">
            <app-dashboard-icon [name]="'Back'" [size]="20" [iconJson]="attributesIcons"></app-dashboard-icon>
          </div>
          <div class="userSearchInput px-2" style="width:90%; color: #000">
            <input type="text" class="searchuser" style="background: transparent;" placeholder="{{'placeholders.searchUser' | translate}}" #inputBox
              (input)="filterUser(inputBox.value)"/>
          </div>
        </div>
        <div class="userDetailBox" *ngFor="let user of searchUserList"
          (click)="$event.stopPropagation();selectedSharedUserForNote(user)">
          <div class="userNameBox">
            <div class="" >
              <ngx-avatars class="mr-1" [name]="user.name" [src]="user.image_url" size="30" initialsSize="2" ></ngx-avatars>
            </div>
            <div class="" style="color: #151616;">
              <label class="username m-0"> {{user.name}}</label>
              <p class="useremail m-0">{{user.email}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <!------Connected Items Pop-over Body-->
  <ng-template #items>
    <div class="main-item f-m-r">
      <div class="heading f-l-b">
        <label>{{'noteView.connectedItems' | translate :{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }}</label>
      </div>
      <div class="item-list">
        <div class="searchItemContainer">
          <!-------Search Bar------->
          <div class="row searchItemBox">
            <div class="coloumn" style="width: 10%;">
              <i class="fa fa-search " *ngIf="!showUser" style="color:#2E4765;"></i>
              <i class="fa fa-close" *ngIf="showUser"(click)="onHideSearch(searchbox.value)" style="color:#2E4765;"></i>
            </div>
            <div class="coloumn" style="width:90%; color: #2E4765">
              <input type="text" placeholder="{{'placeholders.seachPlaceholderText' | translate}}"
                class="searchitem" #searchbox [(ngModel)]="searchValue" 
                (input)="getAllItems(searchbox.value)" (click)="onSearch()"/>
            </div>
          </div>
          <!-------Show list of item when user click on search box---->
          <div *ngIf="showUser" class="showItem listOfSearchItem">
            <div class="row itemOrLead" style="border-bottom: 1px solid white;" *ngFor="let lead of leadResponse" (click)="selectItemForNote(lead);">
              <div class="coloumn c1 p-0" style="width:10%;margin-bottom: 11px;">
                <span [style.background]="commonUtils?.priorityJson[lead.priority]?.color" class="iteminitials" >{{commonUtils?.priorityJson[lead.priority]?.label}}</span>
              </div>
              <div class="coloumn c2 p-0 mb-3" style="width:90%;">
                <label class="leadid">{{lead.seq_id}}</label>
                <p class="leadtitle">{{lead.title | truncate:50}}</p>
              </div>
            </div>
          </div>
        </div>
        <!------selected connected item for note------->
        <div class="showItem" *ngIf="!showUser">
          <div class="row itemOrLead" *ngFor="let lead of getDashItems">
            <div class="coloumn c1" style="width:10%;">
              <span *ngIf= "lead.priority=='0'" style="background-color: #B2B2B2;" class="iteminitials">L</span>
              <span *ngIf= "lead.priority=='1'" class="iteminitials" style="background-color: #D5D500;">M</span>
              <span *ngIf= "lead.priority=='2'" style="background-color:#E7971E;" class="iteminitials">H</span>
              <span *ngIf= "lead.priority=='3'" style="background-color: #FF544C;;" class="iteminitials">U</span>
            </div>
            <div class="coloumn c2" style="width:85%;" (click)="openConnectItem(lead)">
              <label class="leadid">{{lead.seq_id}}</label>
              <p class="leadtitle">{{lead.title | truncate:50}}</p>
            </div>
            <div class="coloumn c3" style="width:5%;margin-left: 0px;">
              <i class="fa fa-close" (click)="deleteSelectedItemForNotes(lead)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-template>

<ng-template #email let-modal>
  <div class="modal-md f-m-r">
    <div class="modal-header">
      <div class="header">
        <div class="modal-title f-xxl-b">{{'noteView.sendAsEmail' | translate}}</div>
      </div>
    </div>
    <div class="modal-body">
      <div class="email">
        <label class="subject">To</label>
        <div class="chip" *ngFor="let skill of selectedemail let i = index">{{skill.name}}
          <span class="closebtn" (click)="removeSelectedEmail(i)">&times;</span>
        </div>
        <input class="form-control" style="width:40%;" type="text" id="emailSet"
          placeholder="Enter email and hit 'Enter' Key." [(ngModel)]="emailSet" (keydown.enter)="onEmailsSetKeydown()"
          #emailSetRef style="border:none;"
          (input)="openMentionPanel()"
          (focus)="openMentionPanel()"
          (focusout)="colseMentionPanel()"/>
          <!-- start mentions div -->
        <div class="w-100 memtionsDiv" *ngIf="isMentionPanelOpen">
          <div class="w-100 userField" *ngFor="let user of filteredSuggestedEmails">
            <div class="userDetailsBox mx-2 pt-2 cursorPointer" (click)="setSuggestedEmail(user.email)">
              <span class="pr-2" >
                  <ngx-avatars class="mr-1" [name]="user?.name" [src]="user?.image_url" size="30" initialsSize="2" ></ngx-avatars>
              </span>
              <span class="userNameText"><strong>{{user?.name}}</strong>
                  <br>
                  <span class="emailIdOrTotalMembers">{{user?.email}}</span>
              </span>
            </div>
          </div>
        </div>
        <!-- End mentions div -->
        <span class="radio">
          <input type="radio" id="html" name="fav_language" value="Board Users" (click)="sendEmailToAll($event)">
          <label for="html" style="margin-right: 10px">{{'noteView.dashboardUsers' | translate}}</label>
          <input type="radio" id="css" name="fav_language" value="Note Users" (click)="sendEmailForDashUser()">
          <label for="css">{{'noteView.noteUsers' | translate}}</label>
          <!-- <input type="radio" class="form-check-input" id="radio1" value="option1" (click)="sendEmailToAll($event)">
          Dashboard Users
          <label class="form-check-label" for="radio1"></label>
          <input type="radio" class="form-check-input" id="radio2" name="optradio" value="option2" (click)="sendEmailForDashUser()">
          Note Users
          <label class="form-check-label" for="radio2"></label> -->
        </span>
      </div>
      <div class="email-body">
        <label class="subject">{{'noteView.subject' | translate}}:</label>
        <input type="text" class="subjecttitle f-m-m" value={{selectedNoteForEmail.title}} #inputTitle />
        <p class="subject" [innerHTML]="selectedNoteForEmail.convertedData"></p>
<!--         <p class="subject">{{selectedNoteForEmail.note_text | quillDeltaText}}</p>
 -->      </div>
    </div>
    <div class="modal-footer">
      <button class="btn savebtn" (click)="modal.close('Save click')">{{'common.cancel' | translate}}</button>
      <button class="btn savebtn" *ngIf="selectedemail.length" (click)="sendEmail(inputTitle.value); modal.close('Save click')">{{'noteView.send' | translate}}</button>
      <button class="btn savebtn" style="background-color:#CCCCCC;" *ngIf="!selectedemail.length">{{'noteView.send' | translate}}</button>
    </div>
  </div>
</ng-template>

<ng-template #privateNote let-modal>
  <div class="modal-sm">
    <div class="modal-header del">
      <div class="head">
        <h4 class="modal-title">{{'common.areYouSure' | translate}}</h4>
      </div>
<!--       <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
 -->    </div>
    <div class="modal-body">
      <img src="../../../assets/img/icons/warning.svg" />
      <br />
      <label *ngIf="selectedDashUsersList.length" class="ml-4">{{'noteView.exitPrivateNote' | translate}}</label>
      <label *ngIf="!selectedDashUsersList.length" class="ml-4">{{'noteView.exitNoteWithShare' | translate}}</label>
    </div>
    <div class="modal-footer" *ngIf="selectedDashUsersList.length">
      <button class="btn prevbtn" (click)="modal.dismiss('Cross click')">{{'common.discard' | translate}}</button>
      <button class="btn prevbtn" (click)="exitUser(); modal.close('Save click')">{{'common.confirm' | translate}}</button>
    </div>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-sm f-m-m">
    <div class="modal-header del">
      <div class="head">
        <p class="modal-title f-xxl-b">{{'common.areYouSure' | translate}}</p>
      </div>
    </div>
    <div class="modal-body">
      <img src="../../../assets/img/icons/delete.svg" />
      <br />
      <label class="f-m-m">{{'noteView.noteDeleteConfirmation' | translate}}: {{title}}</label>
    </div>
    <div class="modal-footer">
      <button class="btn prevbtn" (click)="modal.dismiss('Cross click')">{{'common.discard' | translate}}</button>
      <button class="btn prevbtn" (click)="removeNoteCard(); modal.close('Save click')">{{'common.confirm' | translate}}</button>
    </div>
  </div>
</ng-template>


<ng-template #rightOptionTemplate>
  <div class="d-flex note-options-wrapper" [ngClass]="{'note-options-space': !noteWebLink}" *ngIf="personalCall && selectedNoteId || selectedNoteId" [ngClass]="{'activeRow' : itemCall}" [ngStyle]="{'margin-left': bottomBarCall ? '7rem' : null}">

    <div class="iconBox-30 optionDetailBox" (click)="hideToolbar=!hideToolbar">
      <i class="fa fa-cogs"></i>
    </div>
    <div class="iconBox-30 optionDetailBox" [ngbPopover]="info" #optionsPopover="ngbPopover" [placement]="personalCall ? 'bottom-right' : 'bottom-right'" container="body"
      [autoClose]="true" *ngIf="personalCall ||selectedNoteId!='' " [ngClass]="{'colActive' : itemCall, 'noteChildOption':noteWebLink}">
      <app-dashboard-icon class="iconBox-20" [name]="'Info'" [size]="noteWebLink ? 22 : 20"  [iconJson]="attributesIcons"></app-dashboard-icon>
    </div>
    <div class="iconBox-30 optionDetailBox" *ngIf="personalCall && selectedNoteId || selectedNoteId!='' " [ngbPopover]="history" #historyPopover="ngbPopover"
      [placement]="personalCall ? 'bottom-right' : 'bottom-right'" container="body" [autoClose]="'outside'" [ngClass]="{'colActive' : itemCall, 'noteChildOption':noteWebLink}" (click)="showHistory=false">
      <app-dashboard-icon class="iconBox-20" [name]="'History'" [size]="noteWebLink ? 22 : 20" [iconJson]="commonIcons"></app-dashboard-icon>
    </div>
    <div class="iconBox-30 optionDetailBox" [ngClass]="{'noteChildOption':noteWebLink}" [ngbPopover]="option" #optionsPopover="ngbPopover" [placement]="['bottom-right']" container="body" [autoClose]="'false'" (click)="$event.stopPropagation();">
        <app-dashboard-icon class="iconBox-20" [style]="{'visibility': optionsPopover?.isOpen() ? 'visible' : ''}" [name]="'dotsVertical'" [size]="noteWebLink ? 22 : 20" [iconJson]="commonIcons">
        <ng-template #option>
        <div class="optionDetailBoxBody" style="min-width: 200px;">
          <div class="optionDetailBox" (click)="openUsers(); optionsPopover.close(); shareUsersPopover.open()" ><span class="iconBox-20"><app-dashboard-icon [size]="20"  [name]="'usersOutline'" [iconJson]="commonIcons"></app-dashboard-icon></span>{{'noteView.shareToUsers' | translate}}<span class="float-right collaboratorAvatars"><span *ngFor="let item of collaboratorArr; let i = index" style="margin-right: -10px;">
            <span *ngIf="i<=2"><ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="14" initialsSize="2" >
            </ngx-avatars></span></span></span></div>
          <div class="optionDetailBox" (click)="openDashboard(); optionsPopover.close(); shareDashboardPopover.open()"><span class="iconBox-20"><app-dashboard-icon [size]="20"  [name]="'connected'" [iconJson]="attributesIcons"></app-dashboard-icon></span>{{'noteView.boardConnections' | translate}}<span class="float-right" *ngIf="selectedBoards.length>0">{{selectedBoards.length}}</span></div>
          <div class="optionDetailBox" (click)="openItem(); optionsPopover.close(); shareItemPopover.open()"><span class="iconBox-20"><app-dashboard-icon [size]="20"  [name]="'items'" [iconJson]="attributesIcons"></app-dashboard-icon></span>{{'noteView.itemConnections' | translate}}<span class="float-right" *ngIf="selectedItems.length>0">{{selectedItems.length}}</span></div>
          <div class="optionDetailBox" (click)="openEmail(email,noteData)"><span class="iconBox-20"><app-dashboard-icon [size]="20"  [name]="'Email'" [iconJson]="attributesIcons"></app-dashboard-icon></span>{{'noteView.sendAsEmail' | translate}}</div>
          <div class="optionDetailBox" *ngIf="!bottomBarCall && !isFullScreen" (click)="openModal(fullScreenNote)"><span class="iconBox-20"><app-dashboard-icon [size]="20"  [name]="'Maximize'" [iconJson]="commonIcons"></app-dashboard-icon></span>{{'noteView.maximize' | translate}}</div>
          <div class="optionDetailBox" *ngIf="!bottomBarCall" (click)="minimizeNote()"><span class="iconBox-20"><img src="../../../assets/1x/minimize.png"/></span>{{'noteView.minimize' | translate}}</div>
          <div class="optionDetailBox" (click)="open(content,noteData)"><span class="iconBox-20"><app-dashboard-icon [size]="20"  [name]="'Delete'" [iconJson]="attributesIcons"></app-dashboard-icon></span>{{'common.delete' | translate}}</div>
          <div class="optionDetailBox" (click)="copyWebNoteLink(noteData)"><span class="iconBox-20"><app-dashboard-icon [size]="20"  [name]="'copyIcon'" [iconJson]="attributesIcons"></app-dashboard-icon></span>{{'common.copyLink' | translate}}</div>
          <div class="optionDetailBox" (click)="openNoteInNewTab(noteData)"><span class="iconBox-20"><i class="fas fa-expand"></i></span>{{'common.openInNewTab' | translate}}</div>
        </div>
        </ng-template>
      </app-dashboard-icon>
    </div>
    <ng-container *ngIf="bottomBarCall" >
      <div *ngIf="!isFullScreen" class="optionDetailBox" (click)="openModal(fullScreenNote)"><app-dashboard-icon [size]="20"  [name]="'Maximize'" [iconJson]="commonIcons"></app-dashboard-icon>
      </div>
      <div class="iconBox-30 optionDetailBox" (click)="isFullScreen ? (closeModal(itemCall, fullScreenNote)) && minimizeNote() : minimizeNote()"><img src="../../../assets/1x/minimize.png" />
      </div> 
      <div *ngIf="!isFullScreen" class="iconBox-30 optionDetailBox"  (click)="removefromMinimize();">
        <app-dashboard-icon [size]="20"  [name]="'Cross'" [iconJson]="attributesIcons"></app-dashboard-icon>
      </div>
    </ng-container>
    <div *ngIf="isFullScreen &&(personalCall ||selectedNoteId!='' ) && !itemCall"  class="iconBox-30 optionDetailBox" 
      [autoClose]="true" [ngClass]="{'colActive' : itemCall}" (click)="closeModal(itemCall,fullScreenNote);">
      <app-dashboard-icon [size]="20"  [name]="'Cross'" [iconJson]="attributesIcons"></app-dashboard-icon>
  </div>

    <!--Users Connections-->
    <ng-template #shareUsersTemplate>
      <div class="notesCollaboratedPopover">
        <div class="d-flex">
          <app-dashboard-icon (click)="optionsPopover.open(); shareUsersPopover.close()" [name]="'Back'" [size]="20" [iconJson]="attributesIcons"></app-dashboard-icon>
          <p class="ml-2">Share to Users</p>
        </div> 
        <app-user-selected 
          [dashIdSelected]="selectedDashId"
          [noteUsersCall]="true"
          (selectedUserList)="selectedUserList($event)"
          [userList]="collaboratorArr"
          [removeFromSelected]="removeFromSelectedList"
          >
        </app-user-selected>
        <div class="select" *ngIf="collaboratorArr.length">
          <div class="row g-0 mx-0" style="width: 100%;" *ngFor="let item of collaboratorArr">
            <div class="column1">
              <ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="30" initialsSize="2" ></ngx-avatars>
              <div class="udls">
                <span class="uname">{{item.name}}</span>
                <span class="uemail">{{item.email}}</span>
              </div>
                  </div>
           <div class="column2">
             <img src="../../../assets/1x/cross-icon.svg" role="button" (click)="removeFromSelectedList(item)" style="width: 10px; height: 10px;">
           </div>
          </div>
        </div>
      </div>
    </ng-template>
    
    <!--dashboard connections-->
    <ng-template #shareDashboardTemplate>
      <div *ngIf="selectedBoards.length == 0">
      <p class="noSelected">No Dashboards to show, You can connect this note with any dashboard. To connect with a dashboard click on “Connect Dashboard"</p>
      <div style="text-align: center;"><button class="btn btn-md connect-btn"
        (click)="openCreateDashboard(); shareCreateDashboardPopover.open(); optionsPopover.close(); shareDashboardPopover.close();"><img
          class="mr-1" style="color: #fff;" src="../../assets/1x/addIcon.svg">Connect Dashboard</button>
      </div>
    </div>
      <div class="noSelected" *ngIf="selectedBoards.length>0">
        <div style="display: flex;align-items: center; justify-content: space-between;">
          <span style="font-size: 16px;"><app-dashboard-icon [name]="'Back'" [size]="20" [iconJson]="attributesIcons" (click)="optionsPopover.open(); shareDashboardPopover.close();"></app-dashboard-icon>
            Connected Boards</span>
          <span class="clear-text" (click)="openCreateDashboard(); optionsPopover.close(); shareDashboardPopover.close(); shareCreateDashboardPopover.open()" style="font-size: 12px; color: #3269CC;"><img class="mr-1" src="../../assets/1x/addButton-3571de.svg"> Connect Board</span>
          </div>
          <div class="mt-2 sprintselect-cnr scrollarea">
            <ul *ngFor="let data of selectedBoards">
              <div>
                <li class="d-flex align-items-center" style="gap:8px;">
                  <span class="iconBox-20 boardIconBox" [ngStyle]="{'background-color': data?.color || commonUtils.getCurrentThemeColor()}"><i [class]="data?.icon" class="faicon"></i></span><span class="noteName"> {{data.dashboard_name}} </span>
                  <i *ngIf="data.dashboard_id!=selectedDashId" class="mr-2 fa fa-close float-right" aria-hidden="true" (click)="onDashboardConnectionRemove(data.dashboard_id)"></i>
                </li>
              </div>
            </ul>
          </div>           
      </div>
    </ng-template>
    
    <!--item connections-->
    <ng-template #shareItemTemplate>
      <div class="noSelected">
        <div style="display: flex;align-items: center; justify-content: space-between;">
          <span style="font-size: 16px;">
            <app-dashboard-icon [name]="'Back'" [size]="20" [iconJson]="attributesIcons" (click)="optionsPopover.open(); shareItemPopover.close();"></app-dashboard-icon>Connected Items</span>
          <span *ngIf="selectedItems.length > 0" class="clear-text"
            (click)="openCreateItem(); shareCreateItemPopover.open(); optionsPopover.close(); shareItemPopover.close();"
            style="font-size: 12px; color: #3269CC;"><img class="mr-1" src="../../assets/1x/addButton-3571de.svg"> Connect
            Item</span>
        </div>
        <div *ngIf="selectedItems.length == 0">
          <p class="mt-3">No Items to show, You can connect this note with any item. To connect with a item click on “Connect Item"</p>
          <div style="text-align: center;"><button class="btn btn-md connect-btn"
              (click)="openCreateItem(); shareCreateItemPopover.open(); optionsPopover.close(); shareItemPopover.close();"><img
                class="mr-1" style="color: #fff;" src="../../assets/1x/addIcon.svg">Connect Item</button></div>
        </div>
        <div>
          <div class="mt-2 sprintselect-cnr scrollarea">
            <ul *ngFor="let data of selectedItems">
              <div>
                <li>
                  <span class="noteName">#{{data.seq_id}}- {{data.title}} </span>
                  <span class="openText" (click)="openConnectItem(data); optionsPopover.close(); shareItemPopover.close();"><i class="fas fa-expand"></i>Open</span>
                  <i class="mr-2 fa fa-close float-right" aria-hidden="true" (click)="onItemConnectionRemove(data._id)"></i>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </ng-template>

    <!--Connect Items-->
    <ng-template #shareCreateItemTemplate>
      <div style="width: 595px;">
        <div style="display: flex;align-items: center; justify-content: space-between; margin-bottom: 1rem;">
          <span style="font-size: 16px;">
            <app-dashboard-icon [name]="'Back'" [size]="20" [iconJson]="attributesIcons" (click)="shareCreateItemPopover.close(); shareItemPopover.open()"></app-dashboard-icon>Connect Items</span>
        </div>
        <div class="row" style="gap: 10px; border: none;">
          <div class="col-6">
            <span class="folder-board-text">Work Folder</span>
            <ng-select #workFolderSelect [virtualScroll]="true" [items]="folderInfo" [multiple]="false" [closeOnSelect]="true" bindLabel="dashboard_name"
              bindValue="key" (change)="onWorkFolderSelect($event)">
            </ng-select>
          </div>
          <div class="col-6">
            <span class="folder-board-text">Board</span>
            <ng-select #boardSelect [virtualScroll]="true" [items]="boardsOfFolder" [multiple]="false" [closeOnSelect]="true" bindLabel="dashboard_name"
              bindValue="key">
            </ng-select>
          </div>
        </div>
        <div class="float-right mt-5 mb-3">
          <button class="btn btn-md cancel-btn key-tab mr-3" (click)="shareCreateItemPopover.close(); shareItemPopover.open()">Cancel</button>
          <button class="btn btn-md connect-btn key-tab" [disabled]="!workFolderSelect.selectedItems.length || !boardSelect.selectedItems.length" (click)="itemCallForItemConnection(workFolderSelect.selectedItems, boardSelect.selectedItems); shareCreateItemPopover.close();">Next</button>
        </div>
      </div>
    </ng-template>


    <!-- subtask popup -->
    <ng-container *ngIf="isConnectItemOpen">
      <div class="side-subtask-filter" [class.w-100]>
          <app-subtask-connectboard-filter [currentLead]={} [dashId]="selectedNoteDashId" [isPublicLead]="publicLead" [noteId]="selectedNoteId" (showSubtaskFilter)="isConnectItemOpen=$event" (getAllDashBoardNotes)="getAllDashBoardNotes($event)" [connectedItemIds]="noteData.item_id"></app-subtask-connectboard-filter>
      </div>
  </ng-container>
    
    <!--Create dashboard connections-->
    <ng-template #shareCreateDashboardTemplate>
      <div class="noSelected">
        <div style="display: inline;">
            <app-dashboard-icon class="iconBox-16" [name]="'Back'" [size]="20" [iconJson]="attributesIcons" (click)="shareCreateDashboardPopover.close(); shareDashboardPopover.open(); "></app-dashboard-icon>
            <span style="font-size: 16px;">Connect Boards</span>
          </div>
          <div class="mt-2 sprintselect-cnr scrollarea">
            <ul *ngFor="let data of boardInfo">
              <div *ngIf="!isDashboardSelected(data.dashboard_id)">
                <li class="d-flex align-items-center" style="gap:8px;">
                  <span class="d-flex align-items-center"><input style="width: 11px;height: 11px; border:1px solid #eaeaea; margin-right: 0.5rem; padding-top: 0.1rem;" type="checkbox" (change)="onDashboardCheckboxChange($event, data.dashboard_id)"></span>
                  <span class="iconBox-20 boardIconBox" [ngStyle]="{'background-color': data?.color || commonUtils.getCurrentThemeColor()}"><i [class]="data?.icon" class="faicon"></i></span><span class="noteName"> {{data.dashboard_name}} </span>
                </li>
              </div>
            </ul>
          </div> 
          <div class="float-right mt-3 mb-3">
            <button class="btn btn-md cancel-btn key-tab mr-3" (click)="shareCreateDashboardPopover.close(); shareDashboardPopover.open()">Cancel</button>
            <button class="btn btn-md connect-btn key-tab" (click)="connectDashboards(); shareCreateDashboardPopover.close(); shareDashboardPopover.open(); ">Connect</button>
          </div>          
      </div>
    </ng-template>
    <ngb-popover #shareUsersPopover="ngbPopover" [ngbPopover]="shareUsersTemplate" [placement]="['bottom-right']" container="body" [autoClose]="'outside'" (click)="closeUsersPopover(shareUsersPopover)"></ngb-popover>
    <ngb-popover #shareDashboardPopover="ngbPopover" [ngbPopover]="shareDashboardTemplate" [placement]="['bottom-right']" container="body" [autoClose]="'outside'" (click)="closeDashboardPopover(shareDashboardPopover)"></ngb-popover>
    <ngb-popover #shareCreateDashboardPopover="ngbPopover" [ngbPopover]="shareCreateDashboardTemplate" [placement]="['bottom-right']" container="body" [autoClose]="'outside'" (click)="closeCreateDashboardPopover(shareCreateDashboardPopover)"></ngb-popover>
    <ngb-popover #shareItemPopover="ngbPopover" [ngbPopover]="shareItemTemplate" [placement]="['bottom-right']" container="body" [autoClose]="'outside'" (click)="closeItemPopover(shareItemPopover)"></ngb-popover>
    <ngb-popover #shareCreateItemPopover="ngbPopover" [ngbPopover]="shareCreateItemTemplate" [placement]="['bottom-right']" container="body" [autoClose]="'outside'" (click)="closeCreateItemPopover(shareCreateItemPopover)"></ngb-popover>

    <ng-container *ngIf="rightNotePopupOpen">
      <bottom-bar-section [showActivityView]="true" [dashId]="selectedDashId"></bottom-bar-section>
    </ng-container>
  </div>
</ng-template>

<!-- mention field (media,note) preview  -->
<ng-template #videoPlayerModalNested let-modal>
  <div *ngIf="previewData?.previewMedia?.length>0" class="previewBodyModal" style="height: 100%;max-width: 100%;position: relative;">
    <app-file-preview [mediaArr]="previewData?.previewMedia" [type]="'ITEM'" [index]="0" [dashId]="selectedDashId" (closeModal)="modal.dismiss();previewData={}"></app-file-preview>
  </div>
  <div *ngIf="previewData.data_type=='note'" class="fullScreenModal h-100 w-100 preview-modal-body">
    <app-notes-view  style="height: 100%;max-width: 100%;" [selectedNoteId]="previewData?.item?._id" [users]="users" [bottomBarCall]="true" (minimizeRight)="modal.dismiss();previewData={}"></app-notes-view>
  </div>
</ng-template>
<!-- item view -->
<ng-container *ngIf="itemPreviewData?.length">
  <item-view *ngIf="previewData.data_type!=='activity'" [itemData]="itemPreviewData" [currentBoardId]="selectedDashId" [currentViewId]="currentViewId" (closeItemView)="itemPreviewData=[]" [class]="'item-side-view'" ></item-view>
  <item-view *ngIf="previewData.data_type=='activity'" [itemData]="itemPreviewData" [itemViewType]="'RIGHT'" (closeItemView)="itemPreviewData=[]" [class]="'item-side-view'" ></item-view>
</ng-container>
