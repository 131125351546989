import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { threadId } from 'worker_threads';
import { HttpTransferService } from '../../../services/httpTransfer.service';

@Component({
  selector: 'app-single-phase',
  templateUrl: './single-phase.component.html',
  styleUrls: ['./single-phase.component.scss']
})
export class SinglePhaseComponent implements OnInit {
  @Input() dashIdSelected:string;
  @Input() selectedSprint:any[];
  @Input() isCreate:boolean;
  @Input() isUpdate:boolean;
  @Output() showPhase: EventEmitter<string> = new EventEmitter();
  @Input() addPhaseForm: FormGroup;
  phaseNameRegex = /^[a-zA-Z0-9]+[a-zA-Z0-9-_{} ]*$/;
  selectedSprintStatus: boolean=false;

  @Output() SprintID= new EventEmitter<any>()


  constructor(private httpTransfer: HttpTransferService) { }

  ngOnInit(): void {
    if(this.selectedSprint && this.selectedSprint['current_status'] && this.selectedSprint['current_status']=='STARTED'){
      this.selectedSprintStatus=!this.selectedSprintStatus
     } 
    }
  checkTime(){
    let planned_start_date = new Date(this.addPhaseForm.get('planned_start_date').value).getTime();
    let planned_end_date = new Date(this.addPhaseForm.get('planned_end_date').value).getTime();
    if(planned_end_date<planned_start_date && planned_end_date > 0 && planned_start_date > 0){
      this.addPhaseForm.get('planned_end_date').setErrors({'incorrect': true});
      this.addPhaseForm.setErrors({'incorrect': true});
    }     
  }
  onSavePhase() {  
    const phaseAddFields = {
      name: this.addPhaseForm.get('name').value,
      planned_start_date: new Date(this.addPhaseForm.get('planned_start_date').value).getTime(),
      planned_end_date: new Date(this.addPhaseForm.get('planned_end_date').value).getTime(),
    }
    if(!phaseAddFields.planned_start_date) {
      delete phaseAddFields.planned_start_date;
    }
    if(!phaseAddFields.planned_end_date) {
      delete phaseAddFields.planned_end_date;
    }
    
    if(this.addPhaseForm.valid) {
      this.httpTransfer.createPhaseCorrespondingToDashboard(this.dashIdSelected, phaseAddFields).subscribe(async (res : any) => {
        if(res.status==200) {
          this.showPhase.emit()
        }
      })
    }
  }
  onUpdatePhase() {  
    let id=this.selectedSprint['_id'];
    const phaseAddFields = {
      name: this.addPhaseForm.get('name').value,
      planned_start_date: new Date(this.addPhaseForm.get('planned_start_date').value).getTime(),
      planned_end_date: new Date(this.addPhaseForm.get('planned_end_date').value).getTime(),
    }
    /* if(!phaseAddFields.planned_start_date) {
      delete phaseAddFields.planned_start_date;
    }
    if(!phaseAddFields.planned_end_date) {
      delete phaseAddFields.planned_end_date;
    } */
    
    if(this.addPhaseForm.valid) {
      this.httpTransfer.updatePhaseCorrespondingToDashboard(this.dashIdSelected, phaseAddFields,id).subscribe(async (res : any) => {
        if(res !== undefined && res) {
          this.showPhase.emit()
        }
      })
    }
  }
}
