<div class="row">
   <div class="col-12"> 
    <div class="popoverttlbg themeBgColor f-l-b"> {{'dashHeader.notification' | translate}} 
      <span >
      <button class="btn f-s-r readAllBtn" (click)="markAsRead()">{{'dashHeader.markAllasRead' | translate}} </button>
      </span>
   </div>
 </div>
</div>
<div *ngIf="showShimmerLoader" class="notificationContainer">
  <app-shimmer-loader
  [type]="'Avatar'" [lineWidth]="['80px','auto','auto']" [height]="10" [rowGap]="6" [count]="4" [columnGap]="10"
  [externalStyle]="{'border-bottom' : 'solid 1px #C9C9C9','padding':'10px 16px'}">
  </app-shimmer-loader>
</div>
<div *ngIf="!showShimmerLoader" class="notificationContainer" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="gettingNotificationOnScroll()" [scrollWindow]="false">
  <div *ngFor="let notify of notification" #notificationScroll (click)="markAsRead(notify)" [ngStyle]="notify.status?{'background-color': ''} : {'background-color': 'aliceblue'}">
   <!-- <div class="ribbon"><span>ACTIVE</span></div> -->
    <div class="row notificationRow border-bottom">
      <div class="col-2 col-md-2 my-auto pr-0 text-right">
          <ng-container *ngIf="notify.object_type==notificationTypeObj.comment && notify.activity_type == notificationTypeObj.activityType.add">
            <img src="../../../assets/1x/comment-notice.svg" class="notificationImg" alt="">
          </ng-container>
          
          <ng-container *ngIf="((notify.object_type===notificationTypeObj.lead) ||  (notify.object_type===notificationTypeObj.item) || (notify.object_type===notificationTypeObj.dashboard) || (notify.object_type===notificationTypeObj.dashboardUser)) && notify.activity_type === notificationTypeObj.activityType.add">
            <img src="../../../assets/1x/added-notice.svg" class="notificationImg" alt="Added element">
          </ng-container>
          <ng-container *ngIf="((notify.object_type===notificationTypeObj.lead) ||  (notify.object_type===notificationTypeObj.item) || (notify.object_type===notificationTypeObj.dashboard) || (notify.object_type===notificationTypeObj.dashboardUser)) && notify.activity_type === notificationTypeObj.activityType.update">
            <img src="../../../assets/1x/update-item-notice.svg" class="notificationImg" alt="Updated element">
          </ng-container>
          <ng-container *ngIf="((notify.object_type===notificationTypeObj.lead) ||  (notify.object_type===notificationTypeObj.item) || (notify.object_type===notificationTypeObj.dashboard) || (notify.object_type===notificationTypeObj.dashboardUser)) && notify.activity_type === notificationTypeObj.activityType.delete">
            <img src="../../../assets/1x/removed-notice.svg" class="notificationImg" alt="Removed element">
          </ng-container>
          
          <ng-container *ngIf="((notify.object_type===notificationTypeObj.lead) ||  (notify.object_type===notificationTypeObj.item) || (notify.object_type===notificationTypeObj.dashboard) || (notify.object_type===notificationTypeObj.dashboardUser)) && notify.activity_type === notificationTypeObj.activityType.board_update">
            <img src="../../../assets/1x/update-item-notice.svg" class="notificationImg" alt="Updated element">
          </ng-container>
          
          <ng-container *ngIf="notify.object_type==notificationTypeObj.assignment">
            <img src="../../../assets/1x/@pronnelArtboard 3.png" class="notificationImg" alt="">
          </ng-container>
          <ng-container *ngIf="notify.object_type==notificationTypeObj.date">
            <img src="../../../assets/1x/@pronnelArtboard 4.png" class="notificationImg" alt="">
          </ng-container>
      </div>
   <div class="col-10 col-md-10 my-auto f-m-r" >
       <div class="row">
           <div class="col-12">
               <strong><span
                       *ngIf="notify.object_type===notificationTypeObj.lead || notify.object_type===notificationTypeObj.comment">#{{notify?.additional_attributes?.lead_seq_id}}
                       - </span> {{notify?.message_title}}</strong>
               <span class="float-right newNotificationTime f-xs-l">{{notify.notification_time | daysago}}</span>
               <div class="notificationMsg text-muted">{{notify.message}}</div>
           </div>
           <!-- <div class="col-1">
               <div class="round">
                   <input type="checkbox" id="checkbox" />
                   <label for="checkbox"></label>
               </div>
           </div> -->
       </div>
   </div>
    </div>
  </div>
  <!-- show loader on scroll api call -->
  <div *ngIf="showScrollLoader" class="row notificationRow">
    <div class="col-12 text-center">
      <div class="spinner-border my-2" style="width: 15px ;height: 15px;" role="status">
        <span class="sr-only">{{'common.loading' | translate}}</span>
      </div>  
    </div>
  </div>
</div>
