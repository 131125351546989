import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { DatePipe, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { AppComponent } from "./app.component";
import { MqttModule, IMqttServiceOptions } from 'ngx-mqtt';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'dev1.athmin.com',
  port: 443,
  path: '/emitter',
  protocol: 'wss',
  keepalive:30,
};
import { AppRoutingModule } from "./app.routing"; 
/*firebase*/
// import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
// import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
// import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireModule } from "@angular/fire/compat";
import { environment } from "../environments/environment";
import { CookieService } from 'ngx-cookie-service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FacebookModule, } from 'ngx-facebook';
import { MessagingService } from "./services/messaging.service";
import { BottomBarModule } from "./bottom-bar/bottom-bar.module";
import { GradientPipe } from "./gradient.pipe";
// import { AdvanceFilterComponent } from './views/advance-filter/advance-filter.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    // AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    AppRoutingModule,
    HttpClientModule,
    BottomBarModule,
    // BrowserAnimationsModule,
    FacebookModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),

  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    DatePipe,
    CookieService,
    MessagingService,
   // GradientPipe
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
