

<div class="bottomBarContentDiv" id="bottombarPanel" *ngIf="showBar" [ngClass]="{'pinnedBottomBar' : isBottombarPinned,'homebottom' : currentRoute.includes('/home/welcome')}">
  <div class="bottomRow">
    <div class="nineDotsBox" (click)="getPopRef(bottomBarPopover);currentActiveTab='';minimizeOpen=false;minimizeNoteOpen=false;minimizeItemOpen=false;minimizeChannelOpen=false;showBottomBar=true;" [ngbPopover]="bottomPopover"
      #bottomBarPopover="ngbPopover" placement="auto" [autoClose]="'outside'"><img class="m-2 cursorPointer"
        src="/assets/1x/nine-dots.png" /></div>
    <div class="IconsPanel" [ngStyle]="pinElement | gradient" #pinElement>
      <!--AI-->
      <div class="pinnedBox cursor-pointer">
        <div class="iconInner"><span><ng-container *ngTemplateOutlet="iconTemp;context:{type:({'name':'Ai'})}"></ng-container></span></div>
      </div>
      <!--Pinned Icons-->
      <div *ngFor="let data of pinnedOptions" class="iconDiv">
        <div class="iconInner" [ngbTooltip]="data" placement="top" container="body" tooltipClass="tooltipPopup" [tooltipDelay]="100000">
          <span class="pinnedItems cursorPointer"
            (click)="$event.stopPropagation();showBottomBar=false;handleOptionClick(data, calendarModal);getPopRef(bottomBarPopover)"
            [ngbPopover]="data!=='CALENDAR' && bottomPopover" #bottomBarPopover="ngbPopover" placement="auto" [autoClose]="'outside'">
            <ng-container *ngTemplateOutlet="iconTemp;context:{type:({'name':data || 'NOTES'})}"></ng-container>
            <!--{{nameValidate(data) | titlecase}}-->
          </span>
        </div>
      </div>
      <!--Minimize Bar-->
      <div class="headerRightSide">
        <div *ngIf="minimizedChatsIds && minimizedChatsIds.length" class="iconDiv">
          <div class="iconInner" [ngbTooltip]="'CHAT'" placement="top" container="body" tooltipClass="tooltipPopup" [tooltipDelay]="100000">
            <span class="pinnedItems cursorPointer d-flex align-items-center"
              (click)="showBottomBar=false;minimizeOpen=false;minimizeNoteOpen=false;minimizeItemOpen=false;minimizeChannelOpen=false;getPopRef(bottomBarPopover);handleMinimizeClick()" [ngbPopover]="bottomPopover"
              #bottomBarPopover="ngbPopover" placement="auto" [autoClose]="'outside'">
              <ng-container *ngTemplateOutlet="iconTemp;context:{type:({'name':'INTERNAL_CHATS'})}"></ng-container>
              {{minimizedChatsIds.length}}</span>
          </div>
        </div>
        <div *ngIf="minimizedNotesIds && minimizedNotesIds.length" class="iconDiv">
          <div class="iconInner" [ngbTooltip]="'NOTES'" placement="top" container="body" tooltipClass="tooltipPopup" [tooltipDelay]="100000">
            <span class="pinnedItems cursorPointer d-flex align-items-center"
              (click)="showBottomBar=false;minimizeOpen=false;minimizeNoteOpen=false;minimizeItemOpen=false;minimizeChannelOpen=false;getPopRef(bottomBarPopover);handleNoteMinimizeClick(minimizedNotesIds)"
              [ngbPopover]="bottomPopover" #bottomBarPopover="ngbPopover" placement="auto" [autoClose]="'outside'">
              <ng-container *ngTemplateOutlet="iconTemp;context:{type:({name:'NOTES'})}"></ng-container>
              {{minimizedNotesIds.length}}</span>
          </div>
        </div>
        <div *ngIf="minimizedItemIds && minimizedItemIds.length" class="iconDiv">
          <div class="iconInner" [ngbTooltip]="'ITEMS'" placement="top" container="body" tooltipClass="tooltipPopup" [tooltipDelay]="100000">
            <span class="pinnedItems cursorPointer d-flex align-items-center"
              (click)="showBottomBar=false;minimizeOpen=false;minimizeNoteOpen=false;minimizeItemOpen=false;minimizeChannelOpen=false;getPopRef(bottomBarPopover);handleItemMinimizeClick(minimizedItemIds)" [ngbPopover]="bottomPopover"
              #bottomBarPopover="ngbPopover" placement="auto" [autoClose]="'outside'">
              <ng-container *ngTemplateOutlet="iconTemp;context:{type:({name:'ITEMS'})}"></ng-container>
              {{minimizedItemIds.length}}</span>
          </div>
        </div>
        <div *ngIf="minimizedChannelIds && minimizedChannelIds.length" class="iconDiv">
          <div class="iconInner" [ngbTooltip]="'CHANNELS'" placement="top" container="body" tooltipClass="tooltipPopup" [tooltipDelay]="100000">
            <span class="pinnedItems cursorPointer d-flex align-items-center"
              (click)="showBottomBar=false;minimizeOpen=false;minimizeNoteOpen=false;minimizeItemOpen=false;minimizeChannelOpen=false;getPopRef(bottomBarPopover);handleChannelMinimizeClick(minimizedChannelIds)"
              [ngbPopover]="bottomPopover" #bottomBarPopover="ngbPopover" placement="auto" [autoClose]="'outside'">
              <ng-container *ngTemplateOutlet="iconTemp;context:{type:({name:'CHANNELS'})}"></ng-container>
              {{minimizedChannelIds.length}}</span>
          </div>
        </div>
      </div>
      <!--stack arae-->
      <div class="pinnedBox cursor-pointer" *ngIf="(stackAreaArray?.length>1 || (stackAreaArray?.length>0 && !showRight && !showRightPanelForExternalChat && !showChatInRight))">
        <div class="iconInner" [ngbPopover]="activitiesTemplate" #popActivities="ngbPopover" placement="auto"
          container="body" (click)="getPopRef(popActivities)" [autoClose]="'outside'">
          <span><img src="/assets/1x/bottom-icons/stack.svg"/>
            <ng-container *ngIf="showRight || showChatInRight || showRightPanelForExternalChat">{{stackAreaArray?.length-1}}</ng-container>
            <ng-container *ngIf="!showRight && !showChatInRight && !showRightPanelForExternalChat">{{stackAreaArray?.length}}</ng-container>
          </span>
        </div>
      </div>
      <!--pin/unpin-->
      <div class="pinnedBox cursor-pointer" style="border-left: 1px solid #fff;" (click)="isBottomBarPinned()">
        <div class="iconInner">
          <span *ngIf="!isBottombarPinned">
            <ng-container *ngTemplateOutlet="iconTemp;context:{type:({'name':'Pin'})}"></ng-container>
          </span>
          <span *ngIf="isBottombarPinned"><ng-container *ngTemplateOutlet="iconTemp;context:{type:({'name':'Unpin'})}"></ng-container></span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #bottomPopover>
<ng-container *ngIf="!minimizeOpen && !minimizeNoteOpen && !minimizeItemOpen && !minimizeChannelOpen">
  <ng-container *ngIf="!currentActiveTab">
    <ng-container *ngTemplateOutlet="quickAcess"></ng-container>
  </ng-container>
<!--Notes List-->
  <ng-container *ngIf="currentActiveTab=='NOTES'  || (currentActiveTab!='INTERNAL CHATS' && currentActiveTab!='ITEMS' && currentActiveTab!='CHANNELS' && currentActiveTab!='CALENDAR' && !minimizeOpen && minimizeNoteOpen)">
    <div style="width: 361px; height: 100%;overflow: auto;">
      <!-- <app-notes-view [bottomBarNoteCall] = true></app-notes-view> -->
      <div class="notes-container f-s-r">
        <div class="notes-header mb-1">
          <div>
            <span class="f-xxxl-b mr-2">Notes</span>
            <span *ngIf="notes.length" class="note-count">{{countNotes}}</span>
          </div>
          <div class="usrSearchBox" *ngIf="isSearchActive">
            <img style="width: 15px;" src="assets/Icons_Folder/1x/search.png">
            <input class="search-input" type="text" placeholder="Search notes..." [(ngModel)]="searchText"
            (ngModelChange)="onSearchChange($event)" />
          </div>
          <div class="rightbtn">
            <span class="mr-2" (click)="toggleSearch()">
              <img *ngIf="!isSearchActive" style="width: 15px;" src="assets/Icons_Folder/1x/search.png">
            </span>
            <button class="addnote" (click)="createdNote(); currentActiveTab='';closeBottomPop();"><img src="assets/1x/whitePlus.svg"/>Note</button>
            <img class="pl-1 cursorPointer" (click)=" currentActiveTab='';closeBottomPop();" src="../../../assets/common-use-icon-20dp/close.svg">
          </div>
        </div>
        <div class="left-contain-wrapper p-0">
          <div *ngIf="notes.length" class="h-100">
            <div *ngFor="let data of notes" class="row2 pr-1 pl-3" (click)="showNoteRight(data);currentActiveTab='';closeBottomPop(); ">
              <div class="vertical">
                <p class="f-l-b"  >{{data?.title}}</p>
              </div>
              <p style="line-height: 1.5em;
              height: 2em;
              overflow: hidden;" >{{data.note_text | quillDeltaText}} </p>
            </div>
          </div>
          <div *ngIf="!notes.length" style="text-align:center;">
            <div class="empty">
            <img src="assets/img/icons/empty.svg" />
          </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
 <!--Items Group List-->
  <ng-container *ngIf="currentActiveTab=='ITEMS'">
      <div  class="item-group-body" [ngClass]="{'item-group-expand-body' : isExpandItemGroup}">
        <div class="notes-header mb-1">
            <div class="d-flex">
              <div *ngIf="itemSettingConfig?.view" class="items-back-btn mr-1" (click)="itemSettingConfig.view=null">
                <img  src="../../../assets/1x/newdash-arrow.svg " width="18px" height="18px">
              </div>
              <span class="f-xxxl-b mr-2">
                <ng-container *ngIf="!itemSettingConfig?.view">Items</ng-container>
                <ng-container *ngIf="itemSettingConfig?.view=='filter'" >{{'dashboard.subHeader.filter' | translate}}</ng-container>
                <ng-container *ngIf="itemSettingConfig?.view=='setting'" >{{'setting.settings' | translate}}</ng-container>
              </span>
            </div>
            <div class="d-flex align-items-center rightbtn">
              <ng-container *ngIf="!itemSettingConfig?.view">
                  <div class="d-flex" style="color: #3269CC;width:100px;" (click)="enableInput=true;"><img class="mr-2" src="assets/1x/addButton-3571de.svg"/>Items Group</div>
                  <div *ngIf="enableInput" class="inputParent">
                    <div class="inputbox"><input type="text" class="title" [(ngModel)]="groupName"/></div>
                    <div class="renameBtnGrp">
                      <div (click)="createItemGroup()"><span class="cursorPointer"><img src="assets/common-use-icon-20dp/tick.svg"/></span></div>
                      <div (click)="enableInput=false;"><span class="cursorPointer"><img src="assets/common-use-icon-20dp/cancelbox.svg"/></span></div>
                    </div>  
                  </div>
              </ng-container> 
              <img class="ml-2" src="assets/common-use-icon-20dp/close.svg" (click)=" currentActiveTab='';closeBottomPop();">
            </div>
          </div>
          <div class="left-contain-wrapper d-flex itemGroupsLeftBox">
            <ng-container *ngIf="!itemSettingConfig?.view">
              <div class="listOfItemGroups">
                  <ng-container *ngFor="let data of createdItemGroups">
                    <div  (click)="changeItemGroup(data);" >
                      <span [class.active-group-item]="currentItemGroup?.group_id==data?.group_id" class="w-100 group-item d-inline-block align-middle">{{data.group_name}}</span>
                    </div>
                  </ng-container>
              </div>
              <div *ngIf="isExpandItemGroup" class="selectedItemGroupOpened">
                <div class="d-flex filter-header">
                  <div class="filter-search">
                    <input #searchInput style="border:none; width:100%;height: 100%;" class="" placeholder="'Search'"  [(ngModel)]="localFilterDataObj?.search_params.search_text" (ngModelChange)="onFilterChange()">
                  </div>
                  <div class="filter-btn" >
                    <span (click)="itemSettingConfig.view='filter'">
                      <img class="icon-library" src="assets/1x/Filter.svg" style="height: 14px; width: 14px;" *ngIf="!currentItemGroup?.filterCount">
                      <ng-container *ngIf="currentItemGroup?.filterCount">
                        <img class="icon-library" src="assets/1x/FilterFilled-3571de.svg" style="height: 14px; width: 14px;">
                        <span style="color: '#3571de'enableScrollerLoader;">{{currentItemGroup?.filterCount}}</span>
                      </ng-container>
                    </span>
                    <span (click)="itemSettingConfig.view='setting';getDasahboarData()"><i class="fas fa-cog"></i></span>
                  </div>
                </div>
                <div *ngIf="currentItemGroup && currentItemGroup?.showItem"  style="height: calc(100% - 55px);">
                  <app-board-view #boardView [filterDataObj]="currentItemGroup?.filter" [viewType]="'bottomBarView'" (onItemOpen)="onItemOpen($event);currentActiveTab='';closeBottomPop();"
                  [multipleBoardIds]="!currentItemGroup?.connected_board_id || currentItemGroup?.connected_board_id && currentItemGroup?.connected_board_id?.includes(null) ? [] : currentItemGroup?.connected_board_id" [selectedDashName]="'phase selection'">
                  </app-board-view>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="itemSettingConfig?.view=='filter'">
              <app-filters class="w-100"  [onlyAdvanceFilter]="true" 
              [showFilter]="true"
              [withoutTagsFilter]="true"
              [nonFinalBucketsFilter]="true"
              [localFilterDataObj]="localFilterDataObj"
              [arrayOfCustomFiled]="arrayOfCustomField"
              [subtaskFilter] = "true"
              [bottomBarFilter] = "true"
              (applyFilterEmit)="onFilterChange($event)"
              (resetFilterEmit)="onFilterChange(null,true)"
              ></app-filters>
            </ng-container>
            <ng-container *ngIf="itemSettingConfig?.view=='setting'">
              <div style="display: flex;flex-direction: column;" class="w-100 p-2">
              <div class="setting-body w-100">
                  <div class="chartTypeHeader mb-2 mt-2 moreSettingBorder" style="padding-bottom: 15px">
                    <div class="d-flex justify-content-between">
                      <label class="capitalizeFirstLetter">Connect board</label>
                    </div>
                    <ng-select [items]="itemSettingConfig?.boardDataList" [multiple]="true" bindLabel="dashboard_name" bindValue="dashboard_id" [virtualScroll]="true" 
                      [closeOnSelect]="true" [clearable]="false" [(ngModel)]="currentItemGroup.dummy_connected_board_id"  (ngModelChange)="onConnectedBoardChange()">
                    </ng-select>
                    <p *ngIf="currentItemGroup?.connected_board_id?.length==0" class="ml-2" style="color: red;">Connect atleast one graph specific board</p>
                    <p class="ml-2" class="f-xs-l" style="color: red;">Note: Filter and group by will be reset if board id is removed</p>
                  </div>
                   <div style="display: flex;justify-content: space-between;">
                    <span class="h-100 d-flex" style="align-items: center;">
                        <span class="f-s-r mr-0" >
                            <span class="mr-1"><input type="checkbox" [(ngModel)]="currentItemGroup.dummy_include_activities"  class="mr-1" /></span>
                            <span class="col p-0 capitalizeFirstLetter">Include activity type boards</span> 
                        </span>
                    </span>
                  </div>
              </div>
              <div  style="display: flex;justify-content: end;padding: 10px;">
                <div class="themeChild setting-update-btn" (click)="updateItemsGroupSetting()">{{'common.update' | translate}}</div>
              </div>
              </div>
            </ng-container>
          </div>
      </div>
  </ng-container>
  <!--Internal Chats-->
  <ng-container *ngIf="currentActiveTab=='INTERNAL_CHATS' || (currentActiveTab!='NOTES' && currentActiveTab!='ITEMS' && currentActiveTab!='CHANNELS' && currentActiveTab!='CALENDAR' && !minimizeNoteOpen && minimizeOpen)">
    <div style="width: 361px; height: 358px;">
      <div class="chatLeftSideView">
        <div class="leftChatContent">
          <span *ngIf="!enableInput" class="chat-header-title">Internal Chats</span>
        <div class="createBtn" *ngIf="!enableInput"><button class="internalChatBtn xl-r" (click)="renameEnable=false;title='';enableInput=true;">+ Internal Chat</button>
          <img class="ml-2" src="assets/common-use-icon-20dp/close.svg" (click)=" currentActiveTab='';closeBottomPop();">
        </div>
        <div *ngIf="enableInput" class="inputParent createBtn" style="justify-content: center; width: 100%;">
            <div class="inputbox"><input type="text" class="title" [(ngModel)]="title"/></div>
            <div class="renameBtnGrp">
                <div (click)="createInternalChat()"><span class="cursorPointer"><img src="assets/common-use-icon-20dp/tick.svg"/></span></div>
                <div (click)="enableInput=false;"><span class="cursorPointer"><img src="assets/common-use-icon-20dp/cancelbox.svg"/></span></div>    
            </div>
            <!-- <img class="ml-2" src="assets/common-use-icon-20dp/close.svg" (click)="shareNotesPopover.close();"> -->
           </div>
          </div>
        <div class="chatPanel">
            <ng-container *ngFor="let chat of chatArr">
            <div class="chatBox cursorPointer" (click)="showInternalChatRight(chat,chat._id);currentActiveTab='';closeBottomPop();">
              <div class="chatLeftdiv d-flex">                           
                <ngx-avatars class="mr-1" [name]="chat?.title" [src]="chat?.title" size="30" initialsSize="2" ></ngx-avatars>
                  <span class="flex f-xl-r fontColor">{{chat?.title}}</span> 
              </div>  
                <div class="chatRightdiv" *ngIf="selectedChatObj?._id !== chat?._id && chat?.unreadCount>0"><div class="countIcon"><span class="countMsg">{{chat?.unreadCount}}</span></div></div>
                </div>
            </ng-container>
        </div>
    </div>
    </div>
  </ng-container>
  <!--Channels-->
  <ng-container *ngIf="currentActiveTab=='CHANNELS'">
    <div style="width: 361px; height: 400px;">
      <div class="chatLeftSideView">
        <div class="leftChatContent">
          <span class="chat-header-title">Channels</span>
          <div class="cursorPointer">
            <span class="xl-r external-chat-btn" (click)="showExistingChatOptions()" [ngbPopover]="existingChatOptionsTemplate" #existingChatOptionsTemplatePopover="ngbPopover" placement="auto">+ Existing Chat</span>
            <img class="ml-3" src="assets/common-use-icon-20dp/close.svg" (click)=" currentActiveTab='';closeBottomPop();">
          </div>

          <!--Existing-->
         <ng-template #existingChatOptionsTemplate>
          <div class="noSelected">
            <div *ngIf="disableLoader">
              <div class="d-flex justify-content-center align-items-center my-2">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only"> {{'common.loading' | translate}}...</span>
                </div>
              </div>
            </div>
            <div *ngIf="!disableLoader" class="mt-2 sprintselect-cnr scrollarea">
              <ul *ngFor="let data of contactBoardInfo">
                <div>
                  <li (click)="closeBottomPop();editFilter=false;openFilterForContactBoard(data,filterModal);">
                    <img src="../../../assets/new-icon-6d6e6f-20dp/board-6d6e6f.svg" class="mr-1" style="width: 18px; height: 18px;"><span class="noteName"> {{data.dashboard_name}} </span>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </ng-template>
        </div>
        <div *ngIf="!createdChannels && !chatLeftPanelForBottombar" class="external-chat-empty-body">
          <p>Add existing channels to communicate and<br/>reply quickly.</p>
        </div>
        <div class="createChannelBody" *ngIf="createdChannels?.length">
          <ng-container *ngFor="let channel of createdChannels;let i = index">
            <div class="minChatBar">
              <div class="p-2 boardChatBox" (click)="openChatPopup()">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center" style="gap:8px">
                  <span>{{channel.name}}</span><span><img src="assets/1x/FilterFilled-3571de.svg" (click)="editFilter=true;openFilterForContactBoard(channel,filterModal);currentActiveTab='';closeBottomPop();" style="height: 14px;width:14px;" /></span>
                </div>
                <div class="d-flex align-items-center cursor-pointer" (click)="$event.stopPropagation();removeConnectBoardForChannel(i)"><span><ng-container *ngTemplateOutlet="iconTemp;context:{type:({'name':'Cross','color': '#485160'})}"></ng-container></span></div>
              </div>
              <app-chat-view [chatLeftPanelForBottombar]="true"
                [selectedDashId]="channel.dashboard_id" [filterDataObj]="channel?.filter ? channel?.filter : filterDataObj"></app-chat-view>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div> 

  </ng-container> 
</ng-container>      
  
  <ng-container *ngIf="minimizeOpen && !minimizeNoteOpen && !minimizeChannelOpen && !minimizeItemOpen">
    <ng-container *ngTemplateOutlet="minimizedChatsPopover"></ng-container>
  </ng-container>

  <ng-container *ngIf="!minimizeOpen && minimizeNoteOpen && !minimizeChannelOpen && !minimizeItemOpen">
    <ng-container *ngTemplateOutlet="minimizedNotesPopover"></ng-container>
  </ng-container>

  <ng-container *ngIf="!minimizeOpen && !minimizeNoteOpen && !minimizeChannelOpen && minimizeItemOpen">
    <ng-container *ngTemplateOutlet="minimizedItemPopover"></ng-container>
  </ng-container>

  <ng-container *ngIf="!minimizeOpen && !minimizeNoteOpen && minimizeChannelOpen && !minimizeItemOpen">
    <ng-container *ngTemplateOutlet="minimizedChannelPopover"></ng-container>
  </ng-container>

</ng-template>


  <ng-template #quickAcess>
  <div class="option-container">
    <div class="mt-2 optionselect-cnr scrollarea">
      <div class="d-flex w-100 p-2" style="justify-content: space-between; align-items: center; border-bottom: 1px solid #CED0D5;">
        <span>Quick Access</span>
        <img class="cursorPointer" src="../../../assets/common-use-icon-20dp/close.svg" (click)="closeBottomPop();">
      </div>
      <ul *ngFor="let data of bottomBarOptions">
        <div (click)="$event.stopPropagation();handleOptionClick(data.name, calendarModal);">
          <li class="d-flex align-items-center justify-content-between">
            <span class="dotsOptions">
              <ng-container *ngTemplateOutlet="iconTemp;context:{type:({name:data?.name || 'NOTES',color:'#000'})}"></ng-container>
              <span class="noteName"> {{ nameValidate(data.name) | titlecase }} </span>
            </span>
            <span *ngIf="!pinnedOptions?.includes(data.name)" (click)="$event.stopPropagation();onChangeUserSetting(data.name, true)">
              <ng-container *ngTemplateOutlet="iconTemp;context:{type:({'name':'Pin','color':'#000'})}"></ng-container>
            </span>
            <span *ngIf="pinnedOptions?.includes(data.name)" (click)="$event.stopPropagation();onChangeUserSetting(data.name, false)"><ng-container *ngTemplateOutlet="iconTemp;context:{type:({'name':'Unpin','color':'#000'})}"></ng-container></span>
            </li>
        </div>
      </ul>
    </div> 
  </div>
  </ng-template>

  <ng-template #minimizedNotesPopover>
    <div style="width: 361px; height: 430px;overflow-y: auto;overflow-x: hidden;">
    <div class="notes-container f-s-r">
      <div class="notes-header mb-1">
        <div>
          <span class="f-xxxl-b mr-2">Notes</span>
        </div>
        <div class="rightbtn">
          <img class="pl-1 cursorPointer" (click)="closeBottomPop()" src="../../../assets/common-use-icon-20dp/close.svg">
        </div>
      </div>
      <div class="left-contain-wrapper p-0">
        <div *ngIf="notes.length" class="h-100">
          <div *ngFor="let data of notes" class="row2 pr-1 pl-3" (click)="showNoteRight(data);closeBottomPop()">
            <div class="vertical">
              <p class="f-l-b"  >{{data?.title}}</p>
            </div>
            <p style="line-height: 1.5em;
            height: 2em;
            overflow: hidden;" >{{data.note_text | quillDeltaText}} </p>
          </div>
        </div>
        <div *ngIf="!notes.length" style="text-align:center;">
          <div class="empty">
          <img src="assets/img/icons/empty.svg" />
        </div>
        </div>
      </div>
    </div>
  </div>
  </ng-template>
  <ng-template #minimizedChatsPopover>
    <div  style="width: 361px; height: 358px;">
      <div class="chatLeftSideView">
        <div class="leftChatContent">
          <span *ngIf="!enableInput" style="font-size: 16px; font-weight: 500;">Internal Chats</span>
        <div class="createBtn">
          <img class="ml-2" src="assets/common-use-icon-20dp/close.svg" (click)="closeBottomPop()">
        </div>
          </div>
        <div class="chatPanel">
            <ng-container *ngFor="let chat of chatArr">
            <div class="chatBox cursorPointer" (click)="showInternalChatRight(chat);">
              <div class="chatLeftdiv d-flex">                           
                <ngx-avatars class="mr-1" [name]="chat?.title" [src]="chat?.title" size="30" initialsSize="2" ></ngx-avatars>
                  <span class="flex f-xl-r fontColor">{{chat?.title}}</span> 
              </div>  
                <div class="chatRightdiv" *ngIf="selectedChatObj?._id !== chat?._id && chat?.unreadCount>0"><div class="countIcon"><span class="countMsg">{{chat?.unreadCount}}</span></div></div>
                </div>
            </ng-container>
        </div>
    </div>
    </div>
  </ng-template>
  <ng-template #minimizedChannelPopover>
    <div class="chatBody" [ngStyle]="minimizedChannelIds.length ? { 'max-height': '550px' } : {'max-height': '358px' }">
      <div class="chatLeftSideView">
        <div class="leftChatContent">
          <span class="chat-header-title">Channels</span>
          <div class="cursorPointer">
          </div>
        </div>
        <div *ngIf="channelData.length" class="channelBody">
          <div class="h-100" *ngFor="let channel of channelData">
            <div class="p-2 h-100" (click)="openChatPopup();">
            <app-chat-view class="h-100" [chatLeftPanelForBottombar]="true"
              [selectedDashId]="channel.dashboard_id" [filterDataObj]="filterDataObj" [channelIds]="channel.channel_id"></app-chat-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #minimizedItemPopover>
    <div class="itempopBody">
      <div class="leftChatContent" style="height:40px;">
        <span style="font-size: 16px; font-weight: 500;">Items</span>
      <div class="createBtn" (click)="closeBottomPop()">
        <img class="ml-2" src="assets/common-use-icon-20dp/close.svg">
      </div>
      </div>
      <div class="w-100 itemcontent">
        <ng-container *ngFor="let lead of leadArr">
          <div class="w-100 itemBox" (click)="closeBottomPop();onItemOpen({itemId:lead?._id,boardId:lead.dashboard_id,'item':lead})">
             <div class="priorBox">
              <span class="priority"
              [style.background]="priorityJson[lead.priority]?.color">
              <span>{{priorityJson[lead.priority]?.label}}</span>
             </span>
             </div>
             <div>{{lead?.title}}</div>
          </div>
    </ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #iconTemp let-type="type">
    <ng-container *ngIf="type?.name=='NOTES'">
      <app-dashboard-icon  [name]="'Notes'" [isSvg]="true" [color]="type?.color ? type?.color : '#fff'" [size]="20"></app-dashboard-icon>
    </ng-container>
    <ng-container *ngIf="type?.name=='ITEMS'">
      <app-dashboard-icon  [name]="'items'"  [isSvg]="true" [color]="type?.color ? type?.color : '#fff'" [size]="20"></app-dashboard-icon>
    </ng-container>
    <ng-container *ngIf="type?.name=='INTERNAL_CHATS'">
      <app-dashboard-icon  [name]="'Internal_chat'" [isSvg]="true" [color]="type?.color ? type?.color : '#fff'" [size]="20"></app-dashboard-icon>
    </ng-container>
    <ng-container *ngIf="type?.name=='CHANNELS'">
      <app-dashboard-icon  [name]="'Channel'" [isSvg]="true" [color]="type?.color ? type?.color : '#fff'" [size]="20"></app-dashboard-icon>
    </ng-container>
    <ng-container *ngIf="type?.name=='CALENDAR'">
      <app-dashboard-icon  [name]="'Calendar'" [isSvg]="true" [color]="type?.color ? type?.color : '#fff'" [size]="20"></app-dashboard-icon>
    </ng-container>
    <ng-container *ngIf="type?.name=='Pin'">
      <app-dashboard-icon  [name]="'Pin'" [isSvg]="true" [color]="type?.color ? type?.color : '#fff'" [size]="20"></app-dashboard-icon>
    </ng-container>
    <ng-container *ngIf="type?.name=='Unpin'">
      <app-dashboard-icon  [name]="'Unpin'" [size]="20" [isSvg]="true" [color]="type?.color ? type?.color : '#fff'"></app-dashboard-icon>
    </ng-container>
    <ng-container *ngIf="type?.name=='Ai'">
      <app-dashboard-icon  [name]="'pronnelAi'" [size]="20" [isSvg]="true" [color]="type?.color ? type?.color : '#fff'"></app-dashboard-icon>
    </ng-container>
    <ng-container *ngIf="type?.name=='Cross'">
      <app-dashboard-icon  [name]="'Cross'" [size]="16" [isSvg]="true" [color]="type?.color ? type?.color : '#fff'"></app-dashboard-icon>
    </ng-container>
  </ng-template>

  <div class="rightPopup" *ngIf="showRight || showChatInRight || showRightPanelForExternalChat">
      <ng-container *ngIf="showRight">
        <app-notes-view class="h-100" [selectedNoteId]="noteId"
        [users]="users"
        [currentViewId] ="null" 
        [searchText]="''" 
        [bottomBarCall] = true (minimizeRight)="minimizeRightOpenView($event);showRight=false" [opnedNotes]="openedActivities">
      </app-notes-view>
      </ng-container>

      <ng-container *ngIf="showChatInRight">
        <app-internalChat-view class="w-100 h-100" [selectedChatObj]="selectedChatObj"
        [bottomBarCall] = true (minimizeRight)="minimizeRightOpenView($event);showChatInRight=false">
      </app-internalChat-view>
      </ng-container>

      <ng-container *ngIf="showRightPanelForExternalChat">
        <div class="w-100" style="height: 40px;">
          <div class="p-2 d-flex" style="justify-content: space-between; border-bottom: 1px solid #D9D9D9;">
          <span class="d-flex"><ngx-avatars class="mr-1" [name]="selectedChannelObj?.chatUserName" [src]="selectedChannelObj?.profile_pic" size="20" initialsSize="2" ></ngx-avatars>{{selectedChannelObj?.chatUserName}} </span>
          <div>
            <span class="f-s-r" (click)="minimizeChannel()"><img src="../../../assets/1x/minimize.png" class="mr-1"/></span>
            <span class="cursorPointer" (click)="removefromMinimize()"><img src="../../../assets/common-use-icon-20dp/close.svg"></span>
        </div>
        </div>
        </div>
        <app-chat-messages class="mt-1" style="height: calc(100% - 45px);" [selectedChatUserMsgarr]="selectedChatUserMsgarr" [dashId]="selectedChannelObj.dashboard_id" [selectedChatObj]="selectedChannelObj" [selectedLeadObj]="selectedLeadObj"></app-chat-messages>
      </ng-container>
      
  </div>

 <ng-template #activitiesTemplate>
  <div class="minimize-option-wrapper" style="width:220px">
    <ng-container *ngFor="let activity of stackAreaArray; let i = index">
      <ng-container *ngIf="(i<stackAreaArray?.length-1 || (!showRight && !showRightPanelForExternalChat && !showChatInRight))">
        <div (click)="handleTitleClick(i, activity);popActivities?.close()" class="minimize-option">
          <span class="d-flex align-items-center" style="gap:8px;" *ngIf="activity?.view!=='Internal Chat'"><ng-container *ngTemplateOutlet="iconTemp;context:{type:({'name':activity?.view.toUpperCase() || 'NOTES','color':'#000'})}"></ng-container>{{ activity.title }}</span>
          <span class="d-flex align-items-center" style="gap:8px;" *ngIf="activity?.view=='Internal Chat'"> <ngx-avatars class="mr-1" [name]="activity?.title" [src]="activity?.title" size="20" initialsSize="2" ></ngx-avatars>{{ activity.title }}</span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
<ng-template #calendarModal let-modal>
  <div class="modal-body" id="calendarComponent"></div>
  <!-- <app-setting-sidebar [dashIdSelected]="dashSelect" (closeModal)="modal.dismiss()"></app-setting-sidebar> -->
</ng-template> 

  <!--Filter-->
  <ng-template #filterModal let-modal>
    <app-filters class="h-100 d-flex" [selectedDashId]="selectedContactBoardId" [showFilter]="true" [withoutTagsFilter]="true"
    [nonFinalBucketsFilter]="true" [localFilterDataObj]="localFilterDataObj" [arrayOfCustomFiled]="arrayOfCustomField"
    [subtaskFilter]="true" (applyFilterEmit)="applyBoardFilter($event);modal.dismiss();" (closeFilterEmit)="modal.dismiss()"
    (resetFilterEmit)="resetFilter();"></app-filters>
  </ng-template>

<ng-container *ngIf="itemSettingConfig?.itemPreviewData?.length && itemSettingConfig?.showItemPreview">
  <item-view [showMinimizedIcon]="true" [itemData]="itemSettingConfig?.itemPreviewData"  [itemViewType]="itemSettingConfig?.itemType=='ACTIVITY_COMMENT' ? 'RIGHT' : null" (closeItemView)="itemSettingConfig.itemPreviewData=[]" [class]="'item-side-view h-100'" (minimizeRight)="minimizeRightOpenView($event)"></item-view>
</ng-container>

