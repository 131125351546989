import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ConstantService } from "../../../Enums/Constant.service";
import { MessageService } from "../../message.service";
import { CommonUtils } from "../../services/CommonUtils.service";
import { HttpTransferService } from "../../services/httpTransfer.service";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ToasterService } from "../../services/toaster.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DashboardUtilsService } from "../../services/dashboard-utils.service";

@Component({
  selector: "app-mark-duplicate-lead",
  templateUrl: "./mark-duplicate-lead.component.html",
  styleUrls: ["./mark-duplicate-lead.component.scss"],
})
export class MarkDuplicateLeadComponent implements OnInit, OnDestroy {
  @Input("dashId") dashId: any;
  @Input("leadId") leadId: any;
  @Input()  leadInfo : any
  @Input("dashInitial") dashInitial: any;
  @Input("dashInitial") model: any;
  @Input()  isDuplicateItem : boolean=false
  @Output("updateRecordsAndBucket") updateRecordsAndBucket = new EventEmitter<any>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  bucketOfCorrespondingDash: any[] = [];
  leadObj: {};
  isLeadSearched: boolean = false;
  priorityArray: any;
  bucketList: any[] = [];
  selectedBucket: any;
  showError: boolean = false;
  searchLead: string;
  duplicateOfLeadId: any;
  searchLeadList: any[];
  leadList: any[];
  subject: Subject<any> = new Subject();
  inputType = this.constantService.getInputType();
  noResponse: boolean = false;
  selectedLead: any=null;
  pageNumber: any=1;
  pageSize: any=20;
  itemTerminology:any={}

  constructor(
    private msgservice: MessageService,
    private constantService: ConstantService,
    private httpTransfer: HttpTransferService,
    public commonUtils: CommonUtils,
    private toaster:ToasterService,
    private dashboardUtils: DashboardUtilsService,

  ) {
    this.priorityArray = this.constantService.getPriorityArray();
  }

  async ngOnInit() {
    this.itemTerminology=this.dashboardUtils.getDashboardTerminoloy(this.dashId)
    this.resetMarkAsDuplicateData();
    this.debounceApiCallWithDelay();
    let inputJson = {};
    inputJson['pagination_details']= {
      start_index : 0,
      page_size: this.pageSize
    }
    this.httpTransfer.getLeadQuery(inputJson, this.dashId).subscribe((res : any) => {
      if (res.status==200){
        this.leadList=res.result.leadResponse
        res.result.leadResponse.forEach((lead)=>{
          if(lead._id!==this.leadInfo._id){
            this.searchLeadList.push(lead);
          }
        })
      } 
    });
    let data = await this.dashboardUtils.getDashboardBuckets([this.dashId])
    this.bucketOfCorrespondingDash=data[this.dashId]?.BUCKET || []
    this.bucketList= this.bucketOfCorrespondingDash.reduce((arr,bucket)=>{if(!this.isDuplicateItem && bucket?.is_destination || this.isDuplicateItem){arr.push(bucket)}return arr},[]);
  }
  ngOnDestroy() {

  }
  debounceApiCallWithDelay() {
    this.subject.pipe(debounceTime(1000)).subscribe((text) => {  
        this.searchLeadItem(text);
    });
  }
  validateLetters(val) {

    this.subject.next(val);
  }
  searchLeadItem(searchText?) {
    let inputJson = {};
    inputJson['pagination_details']= {
      start_index : 0,
      page_size: this.pageSize
    }
    this.searchLeadList = [];
    if(searchText.length>=2){
      delete inputJson['pagination_details']
      inputJson["search_params"] = {"search_text":searchText};
    }
    this.httpTransfer.getLeadQuery(inputJson, this.dashId).subscribe((res : any) => {
      for (let i = 0; i < res.result.leadResponse.length; i++) {
        if(res.result.leadResponse[i]._id!==this.leadInfo._id){
          this.searchLeadList.push(res.result.leadResponse[i]);
          
        }
      }
    });
  }
  OnSearchLeadItem(searchData : any){
    let searchValue=searchData?.term
    this.subject.next(searchValue);
    
  }
  
  moveLeadToBucket() {
    
    let inputJsonForDuplicate = {};
    let updateObj = {};
    
    if(this.isDuplicateItem){
      updateObj["bucket_id"] = this.selectedBucket._id
      updateObj["duplicate_details"] = null
    }
    else{
      inputJsonForDuplicate["duplicate_details"] = {};
      inputJsonForDuplicate["duplicate_details"]["is_marked_duplicate"] = true;
      inputJsonForDuplicate["duplicate_details"]["duplicate_item_id"] = this.selectedLead._id;
      updateObj["destination_bucket_id"] = this.selectedBucket._id;
      updateObj["duplicate_details"] = inputJsonForDuplicate["duplicate_details"];
    }
    this.httpTransfer
      .updateLeadCorrespondingToLeadId(
        updateObj,
        this.dashId,
        this.leadId
      )
      .subscribe((res : any) => {
        if (res.status == 200) {
          this.toaster.Success("Item Moved Successfully")
         
        }
      });
      this.closeModel();
  }

  resetMarkAsDuplicateData() {
    this.searchLead = "";
    this.selectedBucket = undefined;
    this.searchLeadList = [];
    this.leadObj = {};
  }
  removeLead() {
    this.leadObj = {};
    this.isLeadSearched = false;
  }
  closeModel(){
    this.close.emit();
  }

}
