import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';

@Component({
  selector: 'qlc-note-options',
  templateUrl: './qlc-note-options.component.html',
  styleUrls: ['./qlc-note-options.component.scss'],
})
export class QlcNoteOptionsComponent implements OnInit {

  @Input() viewType:any=null;
  @Output() optionSelected:EventEmitter<any> = new EventEmitter();
  @ViewChild('embedUrlInput') embedUrlInput!: ElementRef<HTMLInputElement>;
  showBoardSelection:boolean=false;
  searchText:any='';
  embedUrl:any=''
  dataList:any=[];
  pageInfo:any={
    pageSize:15,
    startIndex:0,
    isLoading:true
  }
  selectedBoardId:any;
  orgUsersJson:any=null;
  embedOption:any=[{name:'YouTube',type:'embedYoutube'},{name:'Google Drive',type:'embedGoogleDrive'},{name:'Google Docs',type:'embedGoogleDocs'},{name:'Google Sheet',type:'embedGoogleSheet'},{name:'Google Slides',type:'embedGoogleSlides'},{name:'Figma',type:'embedFigma'},{name:'Other Webside',type:'embedOtherWebside'}]
  constructor(private httpTransfer: HttpTransferService,private modalService : NgbModal,public cdr: ChangeDetectorRef,  public commonUtils: CommonUtils,    private dashboardUtils: DashboardUtilsService,) { }

  ngOnInit(): void {
    if(this.viewType)this.getData();
    document.addEventListener('keydown',this.handleEscapeKey)// Add event listener to detect 'Escape' key press
  }

  handleEscapeKey = (event: KeyboardEvent)=> {
    if (event.key === 'Escape')this.optionSelected.emit(null);
  }

  onSelect(type){
    this.viewType=type
    if(this.viewType)this.getData()
    else{
      this.selectedBoardId=null;
      this.showBoardSelection=null
    }
  }
  async getData(isScroll?){
    if(isScroll){
      if(this.pageInfo.totalRecords && this.dataList.length>=this.pageInfo.totalRecords){
        return
      }
      this.pageInfo.isScrollLoading=true
    }else{
      this.pageInfo.isLoading=true
      this.dataList=[]
      this.pageInfo.totalRecords=0
    }
    let startIndex=this.dataList.length
    if(this.selectedBoardId && this.viewType=='ITEMS' || this.viewType=='ACTIVITY'){
      let json={
        pagination_details:{start_index:startIndex,page_size:this.pageInfo.pageSize}
      }
      if(this.viewType=='ACTIVITY'){
        json['item_type']=['ACTIVITY_COMMENT']
        json['include_activities']=true;
      }
      if(this.selectedBoardId)json['dashboard_id']=[this.selectedBoardId]
      let res=await this.httpTransfer.getLeadQuery(json).toPromise();
      res?.result?.leadResponse?.forEach(item=>this.dataList.push(item))
      this.pageInfo.totalRecords=res?.result?.total_records
    }
    else if(this.viewType=='NOTES'){
      let json={
        'my_notes':true,
        pagination_details:{start_index:startIndex,page_size:this.pageInfo.pageSize}
      }
      if(this.searchText){
        json["search_params"]={"search_text":this.searchText,"search_field": ["title"]}
      }
      let res=await this.httpTransfer.getNote(json).toPromise();
      res?.result?.notes?.forEach(item=>this.dataList.push(item))
      this.pageInfo.totalRecords=res?.result?.total_records;
    }
    else if(this.viewType=='USERS'){
      if(!this.orgUsersJson)this.orgUsersJson=await this.dashboardUtils.getOrgUsers();
      this.dataList=Object.values(this.orgUsersJson || {}).filter(user=>user['name'] && user['name']?.toLowerCase()?.includes(this.searchText) || user['email'] && user['email'].toLowerCase()?.includes(this.searchText) || !this.searchText)
    }
    else if(this.selectedBoardId && this.viewType?.includes("File")){
      let json={
        pagination_details:{start_index:startIndex,page_size:this.pageInfo.pageSize},
        'dashboard_id':[this.selectedBoardId]
      }
      if(this.searchText){
        json["search_params"]={"search_text":this.searchText, "search_columns":["file_name","path"]}
      }
      let res=await this.httpTransfer.fileSystemQuery(json).toPromise() ;
      res?.result?.files?.forEach(item=>this.dataList.push(item))
      this.pageInfo.totalRecords=res?.result?.total_records
    }
    else if(!this.selectedBoardId && this.showBoardSelection){
      let json={
        get_my_boards: true,
        "type": this.viewType?.includes("File") ? ["DRIVE"] : ["BOARD"],
        "access_type":  ["PRIVATE", "INTERNAL"],
        pagination_details:{start_from:(startIndex>0 ? startIndex-1 : 0),page_size:this.pageInfo.pageSize}
      }
      if(this.searchText){
        json["search_params"]={"search_text":this.searchText}
      }
      let res=await this.httpTransfer.getDashBoardForAdmin(json).toPromise() ;
      res?.result?.dashboards?.forEach(item=>this.dataList.push(item))
      this.pageInfo.totalRecords=res?.result?.total_records
    }else if(['BoardView']?.includes(this.viewType) && this.selectedBoardId){
      let json = {'dashboard_id':[this.selectedBoardId],view_types:["KANBAN","GANTT","GRID","ACTIVITY","CALENDAR","SPLIT"]}
      let res= await this.httpTransfer.getDashboardViews(json).toPromise();
      res?.result?.message?.forEach(item=>this.dataList.push(item))
    }
    delete this.pageInfo.isLoading
    delete this.pageInfo.isScrollLoading
  }

  onItemSelect(type,json){
    this.optionSelected.emit({type:type,...json})
  }

  isValidUrl(url) {
    let regex;
    if(this.viewType=='embedYoutube'){
      regex = /^(https?\:\/\/)?(www\.youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})([&?].*)?$/;
    }
    else if(this.viewType=='embedGoogleDocs'){
      regex = /^(https?\:\/\/)?(docs\.google\.com\/document\/d\/)([a-zA-Z0-9_-]+)(\/(edit|view)([?&].*)?)?$/;
    }
    else if(this.viewType=='embedGoogleSheet'){
      regex = /^(https?\:\/\/)?(docs\.google\.com\/spreadsheets\/d\/)([a-zA-Z0-9_-]+)(\/(edit|view)([?&].*)?)?$/;
    }
    else if(this.viewType=='embedGoogleSlides'){
      regex = /^(https?\:\/\/)?(docs\.google\.com\/presentation\/d\/)([a-zA-Z0-9_-]+)(\/(edit|view)([?&].*)?)?$/;
    }
    else if(this.viewType=='embedFigma'){
      regex = /^(https?\:\/\/)?(www\.figma\.com\/(file|proto)\/)([a-zA-Z0-9]+)\/([a-zA-Z0-9_-]+)([?&].*)?$/;
    }else if(this.viewType=='embedGoogleDrive'){
      regex = /^(https?:\/\/)?(drive\.google\.com\/(file\/d\/|drive\/folders\/))([a-zA-Z0-9_-]+)(\/(view|edit|preview))?([?&].*)?$/;
    }
    else{
      regex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})([\/\w .-]*)*\/?$/;
    }

    return regex?.test(url);
  }
  focusOnEmbedUrlInput(){
   setTimeout(() => {this.embedUrlInput?.nativeElement?.focus()},2);  
  }
  ngOnDestroy(){
    document.removeEventListener('keydown',this.handleEscapeKey)
  }
}
