import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonUtils } from '../../services/CommonUtils.service';
import { HttpTransferService } from '../../services/httpTransfer.service';
import { DashboardUtilsService } from '../../services/dashboard-utils.service';


@Component({
  selector: 'app-parent-child-lead',
  templateUrl: './parent-child-lead.component.html',
  styleUrls: ['./parent-child-lead.component.scss']
})
export class ParentChildLeadComponent implements OnInit {

  @Input() dashId: string;
  @Input() leadInfoId: string;
  @Input() leadInfo: any;
  @Input() dashBoardSprint: any
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Input() addOrChangeParent : boolean=false
  dashboardLeadArr: any[] = []
  dashboardLeadArrCopy: any[];
  @Input() dashBuckets: any;
  sprintName: any;
  startIndex: number = 0;
  
  leadTotalRecords: any;
  leadPaginationPayloadObj = {};
  searchTextSubject = new Subject<any>()
  dummySubItemList : any=[]
  addedSubItemList : any =[]
  removedSubItemList : any =[]
  selectedLead : any
  itemTerminology:any={}



  constructor(
    private httpTransfer: HttpTransferService,
    public commonUtils: CommonUtils,
    private dashboardUtils: DashboardUtilsService,

  ) { }

  ngOnInit() {
    this.itemTerminology=this.dashboardUtils.getDashboardTerminoloy(this.dashId)
    this.leadPaginationPayloadObj = {
      bucket_id: null,
      pagination_details: {
        start_index: this.startIndex,
        page_size: 30
      }
    };
    this.leadPaginationPayloadObj[this.addOrChangeParent ?'possible_parent_for_item' : 'possible_child_for_item']=this.leadInfoId   
    this.getDashboardBuckets();  
    this.getParentOrChildrenOfSelectedLead();
  }

  async getDashboardBuckets() {
    let data = await this.dashboardUtils.getDashboardBuckets([this.dashId])
    this.leadPaginationPayloadObj['bucket_id'] = (data[this.dashId]?.BUCKET || []).reduce((arr,data)=>{if(!data.istrash){arr.push(data._id)}return arr},[]);
    this.getDashboardLeads()
  }

  gettingLeadDataOnScroll() {
    if (this.startIndex < this.leadTotalRecords) {
      this.leadPaginationPayloadObj['pagination_details'].start_index = this.startIndex+1
      this.getDashboardLeads()
    }
  }

  getDashboardLeads(leadSeqIdOrTitle?) {
    this.httpTransfer.getLeadQuery(this.leadPaginationPayloadObj, this.dashId).subscribe((res : any) => {
        if(this.startIndex==0)this.dashboardLeadArr=[]
        this.startIndex = this.startIndex + res.result.pagination_details.page_size;
        this.leadTotalRecords = res.result.pagination_details.total_records;
        if (res.status === 200) {
          let childId=this.dummySubItemList.map(lead=>lead._id)
          res.result.leadResponse.forEach(lead=> {
            if(lead._id!=this.leadInfoId && !childId.includes(lead._id)){
              this.dashboardLeadArr.push(lead)
            } 
          })
        }
      })
  }

  filterLead(val) {
    this.debounceApiCallWithDelay(val?.term)
  }
  addSubItemLeadInList(lead : any){
    if(lead!=null){
      const index = this.dashboardLeadArr.indexOf(lead);
      if (index !== -1)this.dashboardLeadArr.splice(index, 1);
      if(!this.leadInfo?.child_lead_id?.includes(lead._id) && this.leadInfo?.parent_id!=lead._id)this.addedSubItemList.push(lead._id)
      this.removedSubItemList=this.removedSubItemList.filter(leadId=>leadId!=lead._id)
      this.dummySubItemList.push(lead)   
    }
  }
  removeSubItemLeadInList(lead : any){
    this.dashboardLeadArr.push(lead)
    if(!this.addOrChangeParent &&  this.leadInfo?.child_lead_id?.includes(lead._id) || this.addOrChangeParent && this.leadInfo?.parent_id==lead?._id)this.removedSubItemList.push(lead._id)
    this.addedSubItemList=this.addedSubItemList?.filter(leadId=>leadId!=lead._id)
    this.dummySubItemList=this.dummySubItemList.filter(leadData=>leadData._id!=lead._id) 
  }

  debounceApiCallWithDelay(text?) {
      if(text){
        this.leadPaginationPayloadObj["search_params"] = {"search_text":text};
      }
      else{
        delete this.leadPaginationPayloadObj["search_params"]
      }
      this.startIndex = 0
      this.leadPaginationPayloadObj['pagination_details'].start_index = this.startIndex;
      this.getDashboardLeads();
  }

  UpdateChildLead() {
    this.addedSubItemList.forEach((leadId)=>{
      this.httpTransfer.updateLeadCorrespondingToLeadId({ parent_id: (this.addOrChangeParent ? leadId : this.leadInfoId)}, this.dashId,(this.addOrChangeParent ? this.leadInfoId : leadId)).subscribe((res : any) => {});
    })
    this.removedSubItemList.forEach((leadId)=>{
      this.httpTransfer.updateLeadCorrespondingToLeadId({ parent_id: "" }, this.dashId,(this.addOrChangeParent ? this.leadInfoId : leadId)).subscribe((res : any) => {});
    })
  }

  async getParentOrChildrenOfSelectedLead() {
    if(!this.addOrChangeParent && !this.leadInfo?.child_lead_id?.length || this.addOrChangeParent && !this.leadInfo?.parent_id){return}
    let json = this.addOrChangeParent ? { lead_id : [this.leadInfo.parent_id]} : { parent_id: [this.leadInfoId] }
    let res=await this.httpTransfer.getLeadQuery(json, this.dashId).toPromise()
    if (res.status === 200) {
      res.result.leadResponse?.forEach((lead)=>{this.dummySubItemList.push(lead)})
    }
    this.debounceApiCallWithDelay();
  }

  closeModel(){
    this.close.emit();
  }

}
