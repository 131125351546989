<div class="row mx-0 w-100 py-1 locationContainer" style=" align-items: center;">
    <div class="col" [ngClass]="{'fullwidth':( multiselectCall || automationCall) , 'col-8' :(!multiselectCall && !automationCall)} ">
        <div class="searchInputHolder position-relative" >
            <i *ngIf="searchInput" class="fa fa-map-marker mr-2 font1_5"  aria-hidden="true"></i> 
            <input type="search" placeholder="Search location" [(ngModel)]="searchInput" (focus)="showSuggestions= false;locationList=[]" (blur)="!showSuggestions && setLocation(searchInput)" class="fullInput" (keydown.enter)="searchLocation(searchInput);showSuggestions=true" [readonly]="isLocked">
            <span *ngIf="!isLocked && searchInput.trim() != '' " style="right: 5%;position: absolute"><i class="fa fa-search searchInputIcon font1_5" [ngClass]="{'searchEnable':searchInput.length>0}" aria-hidden="true" (click)="searchLocation(searchInput);showSuggestions=true;"></i></span>
            <span *ngIf="isLocked && searchInput.trim() != ''" style="right: 5%;position: absolute"><i class="fa fa-search searchInputIcon font1_5" [ngClass]="{'searchEnable':searchInput.length>0}" aria-hidden="true"></i></span>
            <div *ngIf="showSuggestions && !islocationsLoading" class="popover locationScroller" (blur)="showSuggestions= false" #searchDropDown>
                <ul>
                    <li *ngFor="let data of locationList" (click)="setLocation(data);showSuggestions= false">

                        <i class="fa fa-map-marker fa-2x pr-2" aria-hidden="true"></i> 
                        <div>{{data.description? data.description : data.latitude? data.latitude+', '+data.longitude : ''}}
                        </div>
                    </li>
                </ul>
                <span *ngIf="locationList.length==0" class="px-4">
                    {{'location.noLocationsAvailable' | translate}}
                </span>
            </div>
            <div *ngIf="islocationsLoading" class="popover" style="display: flex;justify-content: center; height: 50px;;min-width: 200px;">
                <div class="spinner-border" style="margin-top:10px; margin-bottom:10px" >
                  </div>  
            </div>
        </div>
    </div>
    <div class="col-4" *ngIf="!automationCall && !multiselectCall">
        <a *ngIf="currentLocation?.latitude && searchInput" class="btn btn-outline-primary" style="border-radius: 1rem;" target="_blank" rel="noopener noreferrer" href="https://maps.google.com/?q={{currentLocation.latitude}},{{currentLocation.longitude}}">Open in map</a>
        <a *ngIf="!(currentLocation?.latitude && searchInput)&& gridSelectCall" class="btn btn-outline-secondary" style="border-radius: 1rem;color: grey;">Open in map</a>
    </div>
</div>