import { Component, Input, OnInit } from '@angular/core';
import { ConstantService } from '../../../../Enums/Constant.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpTransferService } from 'app/services/httpTransfer.service';

@Component({
  selector: 'app-connect-emails',
  templateUrl: './connect-emails.component.html',
  styleUrls: ['./connect-emails.component.scss']
})
export class ConnectEmailsComponent implements OnInit {
  @Input() imageSize = 'auto'
  @Input() isCalendar:boolean=false;
  showModal = false
  createConnectionRef: NgbModalRef;
  constructor(private constant: ConstantService, private modalService: NgbModal,private httpTransfer : HttpTransferService) { }

  ngOnInit(): void {
  }

  async connectEmail(source){
    let url;
    let arr = [];
    let res = await this.httpTransfer.getEmailConnections({}).toPromise()
    res?.result?.email_connections.forEach(con => {
      con?.connection_types.forEach(m => {
        // Check if the type is already present in arr, if not, add it
        if (!arr.some(z => z?.type === m?.type)) {
          arr.push(m?.type);
        }
      });
    });
    if(!this.isCalendar){
      if(arr.includes("CALENDAR"))
      {
        url = (source == 'GOOGLE') ? this.constant.getConnectEmailGoogleUrl('EMAIL','CALENDAR') : (source == 'MICROSOFT') ? this.constant.getConnectEmailMircosoftUrl('EMAIL','CALENDAR') : this.constant.getConnectEmailZohoMailUrl();
      }
      else{
        url = (source == 'GOOGLE') ? this.constant.getConnectEmailGoogleUrl('EMAIL') : (source == 'MICROSOFT') ? this.constant.getConnectEmailMircosoftUrl('EMAIL') : this.constant.getConnectEmailZohoMailUrl();
      }
    }
    else{
      if(arr.includes("EMAIL"))
      {
        url = (source == 'GOOGLE') ? this.constant.getConnectEmailGoogleUrl('CALENDAR','EMAIL') : (source == 'MICROSOFT') ? this.constant.getConnectEmailMircosoftUrl('CALENDAR','EMAIL') : this.constant.getConnectEmailZohoMailUrl();
      }
      else{
        url = (source == 'GOOGLE') ? this.constant.getConnectEmailGoogleUrl('CALENDAR') : (source == 'MICROSOFT') ? this.constant.getConnectEmailMircosoftUrl('CALENDAR') : this.constant.getConnectEmailZohoMailUrl();
      }
    }
    console.log(url)
    window.location.replace(url);
  } 
  openImapSmtpModal(modalContent){ this.createConnectionRef = this.modalService.open(modalContent, { size: 'xl', windowClass: 'createNewConnection' });}

  closeModal(){

  }

}
