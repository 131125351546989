<div class="iconBody">

     <div class="colorPanel">
        <color-picker [selectedColor]="selectedColor" (colorChange)="selectedColor=$event"></color-picker>
     </div>

     <div class="iconPanel">
          <ng-container *ngFor="let icon of faIconsList">
              <div class="iconDiv cursor-pointer" [ngStyle]="{'background-color' : selectedColor}" (click)="selectDashboardIcon(icon?.class)"><i [class]="icon?.class" class="faIcon"></i></div>
          </ng-container>
     </div>
</div>