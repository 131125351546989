import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpTransferService } from 'app/services/httpTransfer.service';
import { editorIcons } from 'Enums/editor-icons';

@Component({
  selector: 'text-assist',
  templateUrl: './text-assist-options.component.html',
  styleUrls: ['./text-assist-options.component.scss'],
})
export class TextAssistOptionsComponent implements OnInit {

  
  @Input() selectedText:any;
  @Input() selectedTextInfo:any={};
  @Input() summarizedText:any;
  @Input() optionFields={}
  // @Input() 
  @Input() position:any={ top: '0px', left: '0px' };
  viewType:any=null;
  afterReplacedText:any
  editorIcons:any=editorIcons;
  expendEditorOptions:boolean=false
  @Output() optionSelected:EventEmitter<any> = new EventEmitter();
  constructor(private httpTransfer: HttpTransferService,private modalService : NgbModal,public cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  selectOption(type?,copyText=this.selectedText){
    this.reStoreSelectionrange()
    let json={action:type,text:this.selectedText,originText:this.selectedText}
    if(!type){
      json=null;
    }
    else if(type === 'copy'){
      navigator.clipboard.writeText(copyText);
      json=null;
    }
    else if(type === 'ReplaceText'){
      json['replaceText']=this.summarizedText;
      json['text']=this.afterReplacedText
      // json['replace_text']=this.summarizedText;
    }
    this.optionSelected.emit(json);
  }
  regenerateText(){
    this.reStoreSelectionrange()
    let json=  {
        "input_text":this.summarizedText,
        "functions":{"length": this.viewType=='SummarisedText' ? ["Summarize"] : ["Moderate"]}
      }
    this.httpTransfer.getChatCompletions(json).subscribe(res=>{
      if(res.status==200)this.summarizedText=res?.result?.message_output
      this.cdr.detectChanges();
    })
  }
  openSumrizedText(event,type){
    this.reStoreSelectionrange();
    event.stopPropagation();
    this.summarizedText=this.selectedText;
    this.cdr.detectChanges()
    this.viewType=type;
  }

  replaceText(replaceText){
    if (this.selectedTextInfo.selectedRange && this.selectedText !=this.summarizedText) {
      const selectedText = this.selectedTextInfo.selectedRange.toString();
      if(this.selectedTextInfo?.elementRef?.tagName=='TEXTAREA' || this.selectedTextInfo?.elementRef?.tagName=='INPUT'){
        const startPos = this.selectedTextInfo?.elementRef.selectionStart!;
        const endPos = this.selectedTextInfo?.elementRef.selectionEnd!;
        const value = this.selectedTextInfo?.elementRef.value;
        // Replace the selected text with the new text
        this.selectedTextInfo.elementRef.value = value.substring(0, startPos) + replaceText + value.substring(endPos);
        this.afterReplacedText=this.selectedTextInfo.elementRef.value;
      }
      else if (selectedText.length > 0) {
        this.selectedTextInfo.selectedRange.deleteContents();
        const textNode = document.createTextNode(replaceText);
        this.selectedTextInfo.selectedRange.insertNode(textNode);
        this.afterReplacedText=this.selectedTextInfo?.elementRef?.textContent;
        const newRange = document.createRange();
        newRange.setStart(textNode,0);
        newRange.setEnd(textNode,textNode?.length);
      }
      // this.selectedTextInfo.selectedRange = null;  // Reset the stored range
      this.selectOption('ReplaceText')
    }else{
      this.selectOption(null)
    }
    // this.reStoreSelectionrange()
  }

  reStoreSelectionrange(){
    if(this.selectedTextInfo?.elementRef?.tagName=='TEXTAREA' || this.selectedTextInfo?.elementRef?.tagName=='INPUT'){
      this.selectedTextInfo?.elementRef.focus();
      this.selectedTextInfo?.elementRef?.setSelectionRange(this.selectedTextInfo?.elementRef.selectionStart,this.selectedTextInfo?.elementRef.selectionEnd);
    }
    else if (this.selectedTextInfo.selectedRange) {
      let selection=window.getSelection();
        selection?.removeAllRanges()
        selection?.addRange(this.selectedTextInfo.selectedRange);
      }
  }

  // text editor

  addEditor(tag){
    this.reStoreSelectionrange();
    document.execCommand(tag, false,(tag=='foreColor' ? 'yellow' : null));
    this.optionSelected.emit(null);
    
  }
  openAiAssist(modal){
    this.reStoreSelectionrange();
    let modalRef = this.modalService.open(modal, {size : 'lg', windowClass: 'copyTemplateLeadItem'});
    modalRef.result.then((result) => {this.reStoreSelectionrange()},
    (reason) => {this.reStoreSelectionrange()});
    this.optionSelected.emit(null);
  }


}
