export const Day = {
    Sunday:'Sunday',
    Monday:'Monday',
    Tuesday:'Tuesday',
    Wednesday:'Wednesday',
    Thursday:'Thursday',
    Friday:'Friday',
    Saturday:'Saturday',
    Today:'Today',
    Tomorrow:'Tomorrow',
    Yesterday:'Yesterday',
    Expired:'Expired'
}